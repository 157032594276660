import { useState, useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import Border from "./StylingComponents/Border";
import BorderRadius from "./StylingComponents/BorderRadius";
import Color from "./StylingComponents/Color";
import Font from "./StylingComponents/Font";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";


const AnswerRadioWidget = (props) => {
  
  console.log("AnswerRadioWidget props", props);

  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  console.log("currentContentBlock", currentContentBlock);

  const currentBlock = contentBlocks?.find(block => block.contentType === "AnswerRadio");
  const answerRadioOptions = contentBlocks?.find(block => block.contentType === "AnswerRadioOptions");
  const blockId = currentBlock?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log(currentBlockStyles);
  console.log("currentBlock", currentBlock);

  return (
    <div>
      <p>Answer Radio Widget</p>
      <Color blockId={answerRadioOptions?.id} propertyName="selectedOptionColor" name="Selected Option Text" />
      <Color blockId={answerRadioOptions?.id} propertyName="selectedRadioColor" name="Selected Radio Button" />
      <Color blockId={answerRadioOptions?.id} name="Button Text" />
      <Font blockId={answerRadioOptions?.id} />
      <DynamicStylingTool/>
    </div>
  );
}

export default AnswerRadioWidget;

