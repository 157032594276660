import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Tabs, Tab, Box, Link } from '@material-ui/core';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Chip, Tooltip, Button } from '@material-ui/core';
import DrawerContext from '../store/DrawerContext';
import ModalContext from "../store/ModalContext.js";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { NewReleases } from '@material-ui/icons';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AssessmentAutomation = () => {

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      color: "#747d8c"
    },
  });

  const modalCtx = useContext(ModalContext);
  const drawerCtx                         = useContext(DrawerContext);
  const { id }                            = useParams();
	let navigate      											= useNavigate();
	const [isLoading, setIsLoading]         = useState(true);
  const [apiData, setApiData]             = useState([]);
  const [details, setDetails]               = useState([]);
  const [assessment, setAssessment]     = useState([]);
  const [Campaign, setCampaign]     = useState([]);
  const [refresh, setRefresh]             = useState(false);
  const [tabValue, setTabValue]           = useState(0);
  const afterActions                      = ["leader", "referral_leader", "referral", "member"];

  useEffect(() => {
    if (drawerCtx.open == false) setRefresh(prev => !prev);
    if (modalCtx.open == true) setRefresh(prev => !prev);
  }, [drawerCtx.open, modalCtx.open])

  const classes = useStyles();
  // console.log(id);

  useEffect(() => {

		setIsLoading(true);
		fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessments', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			// console.log(data);
			if (data.status === "success") {
				setIsLoading(false);
				setAssessment(data.data);
			}
		}).catch(err => console.error(err));

		fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/automation', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if (data.status === "success") {
				setIsLoading(false);
				setDetails(data.data);
			}
		}).catch(err => console.error(err));

 
    fetch(process.env.REACT_APP_API_URI + '/v2/integrations/assessments/activeCampaignSearch/all?type=field', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if (data.status === "success") {
				setIsLoading(false);
				setCampaign(data.data);
			}
		}).catch(err => console.error(err));

    fetch(process.env.REACT_APP_API_URI + '/v2/integrations/assessments/activeCampaignSearch/all?type=tag', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if (data.status === "success") {
				setIsLoading(false);
				setCampaign(data.data);
			}
		}).catch(err => console.error(err));

  }, [refresh]);

  // Promise.all([
  //   fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id, options).then(response => response.json()),
  //   // fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/statistics/users', options).then(response => response.json()),
  //   // fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/statistics/leaders', options).then(response => response.json()),
  //   // fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/statistics/members', options).then(response => response.json()),
  //   fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/statistics/allusers', options).then(response => response.json())
  // ]).then((data) => {
  //   console.log(data);
  //   if(data[0].status === "success") {
  //     setTitle(data[0]?.details?.title);
  //     setAssessDetails(data[0]?.details);
  //   }
  //   if(data[1].status === "success") {
  //     setAllUsersDetails(data[1]?.users);
  //     setInviteDetails(data[1]?.details);
  //   }
  //   setIsLoading(false);
  // })
  // .catch((err) => {
  //   console.log(err);
  // });

  console.log(details);

  const currentAssessmentInfo = assessment.filter(assessment => assessment.id.toString() === id.toString());
  // console.log(currentAssessmentInfo);
  
  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

  const setMyName=(id)=>{
    let newData=Campaign.filter((data)=>data.id==id);
    let newName=newData.map((data)=>data.title);
    if(newName.length===0){
      return id;
    }
    return newName[0];
  }

  function getType(type) {
    if(type.slice(0,2) == "ac"){
      if(type.slice(3,8) == 'field' && type.slice(3,8) == type.slice(3))
        return <><Chip label={type.slice(0,2)} /><span style={{textTransform: "uppercase", fontSize: "12px"}}> {type.slice(3)}</span></>;
      else if(type.slice(3,8) == 'field' && type.slice(3,8) != type.slice(3))
        return <><Chip label={type.slice(0,2)} /><span style={{textTransform: "uppercase", fontSize: "12px"}}> {type.slice(9).replace('_',' ')}</span></>;
      else
        return <><Chip label={type.slice(0,2)} /><span style={{textTransform: "uppercase", fontSize: "12px"}}> {type.slice(3)}</span></>;
    } else {
      return <>{type}</>
    }
    
  }

  const showLeaderTab = () => {

    

  }


  return (
    <>
      <div className="container questions">

        <header>
          <div data-aos="fade-up">
            <h1><span className="breadcrumb-navlink" onClick={() => { navigate("/assessments/", { replace: true }); }}>Assessments</span> &gt; {currentAssessmentInfo[0]?.title} &gt; Setup Automation</h1>
            {/* <h1>Assessments &gt; Sections</h1> */}
          </div>
          <div data-aos="fade-down" data-aos-delay="400">
            <a className="header-cta" onClick={() => {toggleDrawerHandler('create-assessment-automation', {client_id: id})}}>Create</a>
          </div>
        </header>
        <Box className="box" sx={{ borderBottom: 1, marginBottom: 10 }} component={Paper}>
              <Tabs TabIndicatorProps={{ style: { background: '#F2D466', height: '4px' } }} value={tabValue} onChange={(e, newVal) => setTabValue(newVal)} aria-label="basic tabs example">
                <Tab className="tab-title" label={<span style={{lineHeight: "13px"}}>Leader<br/><span style={{fontSize: "10px"}}>completes the assessment</span></span>} {...a11yProps(0)} />
                <Tab className="tab-title" label={<span style={{lineHeight: "13px"}}>Leader<br/><span style={{fontSize: "10px"}}>invites a member/referral</span></span>} {...a11yProps(1)} />
                <Tab className="tab-title" label={<span style={{lineHeight: "13px"}}>Referral/Member<br/><span style={{fontSize: "10px"}}>created</span></span>} {...a11yProps(2)} />
                <Tab className="tab-title" label={<span style={{lineHeight: "13px"}}>Referral/Member<br/><span style={{fontSize: "10px"}}>completes the assessment</span></span>} {...a11yProps(3)} />
              </Tabs>
            </Box>
        <TableContainer component={Paper}>
            
          <Table className="table" aria-label="simple table" data-aos="fade-up" key={tabValue}>
            <TableHead>
              <TableRow>
                <TableCell>Integration</TableCell>
                <TableCell width="40%">User's input field</TableCell>
                <TableCell align='center'>Active Campaign ID</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {details.map((data) => 
                data.client_id == id && data.section == afterActions[tabValue] &&
                <TableRow key={data?.id}>
                  <TableCell>{getType(data?.type)}</TableCell>
                  <TableCell>{data?.field_name}</TableCell>
                  <TableCell align="center"><Chip label={setMyName(data?.reference_id)} /></TableCell>
                  <TableCell width="60px" align='center'>
                    <Tooltip title="Edit"><EditIcon className="table-tool" onClick={() => {toggleDrawerHandler('edit-assessment-automation', data)}} /></Tooltip>
                    <Tooltip title="Edit"><NewReleases className="table-tool" onClick={() => {toggleDrawerHandler('edit-assessment-automation1', data)}} /></Tooltip>
                    <Tooltip title="Delete"><DeleteIcon className="table-tool" onClick={() => {toggleModalHandler('assessment-automation-delete', data)}} /></Tooltip>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default AssessmentAutomation;
