import React, { useState, useEffect, useContext } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { CircularProgress, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper, Chip, Tooltip, Button, TextField } from "@mui/material";
import CreateAssessmentSectionDrawer from "./CreateAssessmentSectionDrawer";
import AddIcon from '../../assets/img/Question Builder/PlusIcon.svg'
import QuestionMarkIcon from '../../assets/img/Question Builder/QuestionMarkIcon.svg'
import deleteIcon from '../../assets/img/Question Builder/DelIcon.svg'
import EditIcon from '../../assets/img/Question Builder/editIcon.svg'
import DragIcon from '../../assets/img/Question Builder/DragIndicator.svg'
import calculatorIcon from '../../assets/img/Question Builder/CalculatorIcon.svg'
import DrawerContext from "../../store/DrawerContext";
import SubDrawerContext from "../../store/SubDrawerContext";
import ModalContext from "../../store/ModalContext";
import BuilderContext from "../../store/BuilderContext";
import AssessmentsQuestionsTable from "../Tables/AssessmentsQuestionsTable";
import AuthContext from "../../store/AuthContext";
import GenericMsg from '../../assets/img/builder-images/genericPagesIcon.png'
import AssessmentAdditional from "../AssessmentBuilder/AssessmentAdditional";
import AssessmentAdditionalQuestionTable from "../Tables/AssessmentAdditionalQuestionTable";
import { MenuItem } from "@material-ui/core";

const QuestionsBuilderDrawer = ({ details }) => {

  const id = details?.id;
  const drawerCtx = useContext(DrawerContext);
  const subdrawerCtx = useContext(SubDrawerContext);
  const modalCtx = useContext(ModalContext);
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [questions, setQuestions] = useState([]);
  const [sections, setSections] = useState([]);
  const [additionalQuestions, setAdditionalQuestions] = useState([]);
  const [showAddSectionsForm, setShowAddSectionsForm] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [isAdditional, setIsAdditional] = useState()
  const [uniqueIds, setUniqueIds] = useState([])
  const [additionalQuestionsUniqueIds, setAdditionalQuestionsUniqueIds] = useState([])
  const [pages, setPages] = useState([])
  const [pageName, setPageName] = useState("")
  const [isUpdatedLogic, setIsUpdatedLogic] =useState(false)
  const[childrenAssessments, setChildrenAssessments]=useState([])
  const[childData, setChildData]= useState('')

  const getQuestions = (showLoading) => {
    
    showLoading && setIsLoading(true);

    const envURI = process.env.REACT_APP_API_URI;
    let options = { method: 'GET', headers: { 
      'Content-Type': 'application/json' ,
      'Authorization': 'Bearer ' + authCtx.token 
    },}
    Promise.all([
      fetch(envURI + '/v3/admin/questions/' + id , options).then(response => response.json()),
      fetch(envURI + '/v3/admin/questions/' + id + '/additionals', options).then(response => response.json()),
    ]).then((data) => {
      console.log("API data---------------------------------", data);
      if (data[0].status === "success") {
        setTitle(data[0].title);
        setIsAdditional(data[0].hasAdditional)
        setQuestions(data[0].questions);
        setSections(data[0].sections);
        builderCtx.setNumOfQuestions(data[0].questions.length)
        console.log('data[0].sections',data[0].sections)
      }
      if (data[1].status === "success") {
        setAdditionalQuestions(data[1].questions)
        console.log("Additional Questions API Data", data[1]);
      }
      if (data[0].status === "success" && data[1].status === "success") {
        showLoading && setIsLoading(false);
      }
    }).catch(err => {
      console.error(err);
      showLoading && setIsLoading(false);
    });
  }


  function toggleSubDrawerHandler(drawer, user) {
    subdrawerCtx.openDrawer();
    subdrawerCtx.setDetails(drawer, user);
  }

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  function showEditSectionModal(data) {
    toggleModalHandler("edit-assessment-section-modal", data);
  }

  const toggleCategorySubdrawer = (drawer, drawerDetails) => {
    console.log('drawerDetails', drawerDetails);
    subdrawerCtx.setDetails(drawer, drawerDetails)
    subdrawerCtx.openDrawer()
  }

  let drawerDetails = { client_id: id, customMinHeight: "auto", order: sections?.length + 1 || 1};

  
  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Item was not dropped in a valid destination
    }

    // Reorder the sections in the state based on the drag-and-drop result
    const updatedSections = [...sections];
    const [movedSection] = updatedSections.splice(result.source.index, 1); // Remove the dragged item
    updatedSections.splice(result.destination.index, 0, movedSection); // Insert it at the new position

    // Update the sections state with the reordered array
    setSections(updatedSections)
    changeSectionsOrder(updatedSections);
  };

  const changeSectionsOrder=(updatedOrder)=>{
    let payload = {
      "sectionOrder":
        updatedOrder.map((el,index)=>{
          return {
            "sectionId": el.id,
            "orderIndex": index+1
          }
        })
    }
    
    console.log("Updated Sections Order payload:", payload);
    console.log("Updated Sections Order:", updatedOrder);

    fetch(process.env.REACT_APP_API_URI+'/v3/admin/section/order',{
      method: 'PUT', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => {
      if(data.status === "success") {
       console.log("updated section order successfully", data);
      }
      }).catch((err) => {
        console.error("Failed to update section order...", err);
      });
  }

  const deleteSection =  (modal,data) => {
    // console.log('modal',modal)
    // console.log('data',data)
    modalCtx.openModal()
    modalCtx.setDetails(modal,data)
  }

  const getPageName = (pageId) => {
    const page = pages.find(page => page.id === pageId);
    console.log('PageId getPageName ',pageId)
    return page?.page_type;
  };

  const getPages = async () => {

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/assessment/${id}/pages`)
      const data = await res.json();

      if (data.status === "success") {
        builderCtx.updatePages(data.pages?.sort((a, b) => a.order_no - b.order_no))
        setPages(data.pages?.sort((a, b) => a.order_no - b.order_no));
      }
    } catch (error) {
      console.log('error getting pages : ', error)
    }
  }
  const getNoOfQuestions = (id) => {
    let totalNo = questions.filter(question => question.sectionId === id).length;
  
    return totalNo;
  };
  

  const getAssessmentChilderns = async () => {

    try {
      fetch(process.env.REACT_APP_API_URI+`/v3/admin/assessment/${id}/children`,{
         method: 'GET',
          mode: 'cors',
          headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      }).then(response => response.json())
        .then(data => {
        if(data.status === "success") {
          setChildrenAssessments(data.data)
         console.log("Assessment child data", data);
        }
      })
    } catch (error) {
      console.log('error getting children data : ', error)
    }
  }

  const handleChangeChild = (value, id) => {
   setChildData(value)
  };


  useEffect(() => {
    const showLoading = true;
    !questions?.length && getQuestions(showLoading);
    getAssessmentChilderns()
  }, [id, questions?.length]);


  useEffect(() => {
    // console.log('subdrawerCtx.pageUpdate',subdrawerCtx.pageUpdate);
    const showLoading = false;  // don't show loading as we are fetching questions in background to update them
    if (subdrawerCtx.pageUpdate.page === "questionsBuilderDrawer" || subdrawerCtx?.pageUpdate?.type === "categoryAdd" ) {
      subdrawerCtx.setPageUpdate({}); // reset page update state
      getQuestions(showLoading);
    }
    else if ( subdrawerCtx?.drawer === "question-add-subdrawer" || modalCtx?.modal === "question-delete" || modalCtx?.modal === "category-delete" ) {
      getQuestions(showLoading);
    }
    console.log('modalCtx',modalCtx)
    console.log('subdrawerCtx',subdrawerCtx)
  }, [subdrawerCtx, subdrawerCtx.pageUpdate, modalCtx]);


  useEffect(() => {
    const filteredArray = questions.filter(item1 =>
      !additionalQuestions.some(item2 => item1.id === item2.id)
    );

    console.log('filteredArray',filteredArray)
    
    const uniqueIds = Array.from(new Set(filteredArray.map(question => question.pageId)));

    // Set the unique ids state
    setUniqueIds(uniqueIds);

  }, [questions]);


  // unique pageIds of additional questions

  useEffect(() => {

    const uniqueIds = Array.from(new Set(additionalQuestions.map(question => question.pageId)));

    // Set the unique ids state
    setAdditionalQuestionsUniqueIds(uniqueIds);

  }, [additionalQuestions]);

  useEffect(() => {
    // console.log('additionalQuestionsUniqueIds',additionalQuestionsUniqueIds)
  }, [additionalQuestionsUniqueIds]);
  
  useEffect(() => {
	  getPages()
  }, []);


  useEffect(() => {
    getQuestions();
  }, [isUpdatedLogic, setIsUpdatedLogic])

  return (
    <div className={ isLoading ? "drawer questions question-builder-drawer question-builder-drawer-loading-container" : "drawer questions question-builder-drawer" }>
      {
        isLoading ? <> <CircularProgress/> </> : <>
        <div className="question-builder-content">  
        <h2 className="question-builder-heading">{details?.assessmentName}{" > Question Builder"}</h2>  
          <div className="variable-input-box">
          
            <TextField select variant="outlined" className='choose-variable-select-tag' value={childData} onChange={(e)=>{handleChangeChild(e.target.value,e.target.id)}} >
            {childrenAssessments?.map((item, idx)=>{
               return   <MenuItem className="section-name" key={idx} value={item.title}  id={item.id}>{item.title}</MenuItem>
            })}
            </TextField>
          </div>
        </div>

          <div className="sections">
            <h2>Categories</h2>
            <div className="category-box-and-add-category-container">
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="categories" direction="horizontal">
                  {(provided) => (
                    <div className="category-box" style={sections?.length == 0 ? {overflow:'hidden'} : {}} ref={provided.innerRef} {...provided.droppableProps}>
                      {
                      (sections?.map((data, index) =>
                        <Draggable key={data.id.toString()} draggableId={data.id.toString()} index={index}>
                          {(provided) => (
                            <div className="category-box-child" key={data.id} {...provided.draggableProps} ref={provided.innerRef}>
                              <div className="category-order-name-edit-container">
                                <div className="order-no-and-drag-section-name-icon">
                                  <div className="order-no">{index+1}</div>
                                  <div className="order-drag-icon" {...provided.dragHandleProps}>
                                    <img src={DragIcon} alt="" />
                                  </div>
                                  <div className="section-name" dangerouslySetInnerHTML={{__html:data?.section}}/>
                                </div>
                                <div className="edit-icon" onClick={() => showEditSectionModal(data)} >
                                    <img src={EditIcon} alt="" />
                                </div>
                              </div>
                              <div className="delIcon-calculator-and-questionIcon-container">
                                <div className="questionIcon-and-calculator">
                                  <div className="data-multiplier">
                                    <img src={QuestionMarkIcon} alt="" />
                                    <p>{getNoOfQuestions(data?.id)}</p>
                                  </div>
                                  <div className="data-calculator">
                                    <img src={calculatorIcon} alt="" />
                                    <p>{data?.multiplier}</p>
                                  </div>
                                </div>
                                <div className="delIcon" onClick={()=>{deleteSection('category-delete',sections[index])}}>
                                  <img src={deleteIcon} alt="" />
                                </div>
                              </div>
                            </div>
                          )}
                        </Draggable>
                        ))
                      }
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
              {
                !showAddSectionsForm && <div className="add-section-block" onClick={() => {
                  toggleCategorySubdrawer('add-category-subdrawer', drawerDetails)
                  console.log(drawerDetails)
                }}> <img src={AddIcon} alt="Add Icon" />
                  <h1>ADD CATEGORY</h1>
                </div>
              }
            </div>
            {showAddSectionsForm && <>
              <CreateAssessmentSectionDrawer userDetails={drawerDetails} />
              <Button className="gray-btn" style={{ margin: "-60px auto 15px" }} onClick={() => setShowAddSectionsForm(false)}>Cancel</Button>
            </>
            }
            </div>
            {
              uniqueIds.length === 0 ? <>
                <Button className="black-btn" onClick={() => {
                  // toggleSubDrawerHandler("question-add-subdrawer", { sections: sections, clientId: id });
                  if (sections?.length === 0) toggleModalHandler("show-add-sections-modal", null);
                  else toggleSubDrawerHandler("question-add-subdrawer", { sections: sections, clientId: id, questions: questions});
                }}>Add Question</Button>
              </> : <></>
            }
            {
              uniqueIds?.map((el)=>{
                return <>
                  <div className="heading-and-btn-container">
                    <div className="heading-icon-container">
                      <div className="generic-msg-icon"><img src={GenericMsg} alt="" /></div>
                      <h2>{getPageName(el)} ({el})</h2>
                    </div>
                    <Button className="black-btn" onClick={() => {
                      // toggleSubDrawerHandler("question-add-subdrawer", { sections: sections, clientId: id });
                      if (sections?.length === 0) toggleModalHandler("show-add-sections-modal", null);
                      else toggleSubDrawerHandler("question-add-subdrawer", { sections: sections, clientId: id, questions: questions, pageId: el });
                    }}>Add Question</Button>
                  </div>
                  <AssessmentsQuestionsTable questions={questions} isAdditional={isAdditional} sections={sections} additionalQuestions={additionalQuestions} pageId={el} clientId={id} uniqueIds={uniqueIds} subDrawer={true} setIsUpdatedLogic={setIsUpdatedLogic} isUpdatedLogic={isUpdatedLogic} />
                </>
              })
            }

            <h1 className="additional-question-heading">Additional Questions</h1>

            {
              additionalQuestionsUniqueIds?.map((el)=>{
                return <div className="additional-questions-table-container">
                  <div className="heading-and-btn-container">
                    <div className="heading-icon-container">
                      <div className="generic-msg-icon"><img src={GenericMsg} alt="" srcset="" /></div>
                      <h2>{getPageName(el)} ({el})</h2>
                    </div>
                    <Button className="black-btn" onClick={() => {
                    if (sections?.length === 0) toggleModalHandler("show-add-sections-modal", null);
                    else toggleSubDrawerHandler("question-add-subdrawer", { sections: sections, clientId: id, questions: additionalQuestions, pageId: el });
                    }}>Add Question</Button>
                  </div>
                  <AssessmentAdditionalQuestionTable questions={additionalQuestions} sections={sections} pageId={el} clientId={id} additionalQuestionsUniqueIds={additionalQuestionsUniqueIds} subDrawer={true} />
                </div>
              })
            }
        </>
      }
    </div>
  )
}

export default QuestionsBuilderDrawer;
