let zipStates = {
"00601": ["Adjuntas", "Puerto Rico", "PR"],
"00602": ["Aguada", "Puerto Rico", "PR"],
"00603": ["Aguadilla", "Puerto Rico", "PR"],
"00606": ["Maricao", "Puerto Rico", "PR"],
"00610": ["Anasco", "Puerto Rico", "PR"],
"00611": ["Angeles", "Puerto Rico", "PR"],
"00612": ["Arecibo", "Puerto Rico", "PR"],
"00616": ["Bajadero", "Puerto Rico", "PR"],
"00617": ["Barceloneta", "Puerto Rico", "PR"],
"00622": ["Boqueron", "Puerto Rico", "PR"],
"00623": ["Cabo Rojo", "Puerto Rico", "PR"],
"00624": ["Penuelas", "Puerto Rico", "PR"],
"00627": ["Camuy", "Puerto Rico", "PR"],
"00631": ["Castaner", "Puerto Rico", "PR"],
"00636": ["Rosario", "Puerto Rico", "PR"],
"00637": ["Sabana Grande", "Puerto Rico", "PR"],
"00638": ["Ciales", "Puerto Rico", "PR"],
"00641": ["Utuado", "Puerto Rico", "PR"],
"00646": ["Dorado", "Puerto Rico", "PR"],
"00647": ["Ensenada", "Puerto Rico", "PR"],
"00650": ["Florida", "Puerto Rico", "PR"],
"00652": ["Garrochales", "Puerto Rico", "PR"],
"00653": ["Guanica", "Puerto Rico", "PR"],
"00656": ["Guayanilla", "Puerto Rico", "PR"],
"00659": ["Hatillo", "Puerto Rico", "PR"],
"00660": ["Hormigueros", "Puerto Rico", "PR"],
"00662": ["Isabela", "Puerto Rico", "PR"],
"00664": ["Jayuya", "Puerto Rico", "PR"],
"00667": ["Lajas", "Puerto Rico", "PR"],
"00669": ["Lares", "Puerto Rico", "PR"],
"00670": ["Las Marias", "Puerto Rico", "PR"],
"00674": ["Manati", "Puerto Rico", "PR"],
"00676": ["Moca", "Puerto Rico", "PR"],
"00677": ["Rincon", "Puerto Rico", "PR"],
"00678": ["Quebradillas", "Puerto Rico", "PR"],
"00680": ["Mayaguez", "Puerto Rico", "PR"],
"00682": ["Mayaguez", "Puerto Rico", "PR"],
"00683": ["San German", "Puerto Rico", "PR"],
"00685": ["San Sebastian", "Puerto Rico", "PR"],
"00687": ["Morovis", "Puerto Rico", "PR"],
"00688": ["Sabana Hoyos", "Puerto Rico", "PR"],
"00690": ["San Antonio", "Puerto Rico", "PR"],
"00692": ["Vega Alta", "Puerto Rico", "PR"],
"00693": ["Vega Baja", "Puerto Rico", "PR"],
"00694": ["Vega Baja", "Puerto Rico", "PR"],
"00698": ["Yauco", "Puerto Rico", "PR"],
"00703": ["Aguas Buenas", "Puerto Rico", "PR"],
"00704": ["Aguirre", "Puerto Rico", "PR"],
"00705": ["Aibonito", "Puerto Rico", "PR"],
"00707": ["Maunabo", "Puerto Rico", "PR"],
"00714": ["Arroyo", "Puerto Rico", "PR"],
"00715": ["Mercedita", "Puerto Rico", "PR"],
"00716": ["Ponce", "Puerto Rico", "PR"],
"00717": ["Ponce", "Puerto Rico", "PR"],
"00718": ["Naguabo", "Puerto Rico", "PR"],
"00719": ["Naranjito", "Puerto Rico", "PR"],
"00720": ["Orocovis", "Puerto Rico", "PR"],
"00723": ["Patillas", "Puerto Rico", "PR"],
"00725": ["Caguas", "Puerto Rico", "PR"],
"00727": ["Caguas", "Puerto Rico", "PR"],
"00728": ["Ponce", "Puerto Rico", "PR"],
"00729": ["Canovanas", "Puerto Rico", "PR"],
"00730": ["Ponce", "Puerto Rico", "PR"],
"00731": ["Ponce", "Puerto Rico", "PR"],
"00735": ["Ceiba", "Puerto Rico", "PR"],
"00736": ["Cayey", "Puerto Rico", "PR"],
"00738": ["Fajardo", "Puerto Rico", "PR"],
"00739": ["Cidra", "Puerto Rico", "PR"],
"00740": ["Puerto Real", "Puerto Rico", "PR"],
"00741": ["Punta Santiago", "Puerto Rico", "PR"],
"00745": ["Rio Grande", "Puerto Rico", "PR"],
"00751": ["Salinas", "Puerto Rico", "PR"],
"00754": ["San Lorenzo", "Puerto Rico", "PR"],
"00757": ["Santa Isabel", "Puerto Rico", "PR"],
"00765": ["Vieques", "Puerto Rico", "PR"],
"00766": ["Villalba", "Puerto Rico", "PR"],
"00767": ["Yabucoa", "Puerto Rico", "PR"],
"00769": ["Coamo", "Puerto Rico", "PR"],
"00771": ["Las Piedras", "Puerto Rico", "PR"],
"00772": ["Loiza", "Puerto Rico", "PR"],
"00773": ["Luquillo", "Puerto Rico", "PR"],
"00775": ["Culebra", "Puerto Rico", "PR"],
"00777": ["Juncos", "Puerto Rico", "PR"],
"00778": ["Gurabo", "Puerto Rico", "PR"],
"00780": ["Coto Laurel", "Puerto Rico", "PR"],
"00782": ["Comerio", "Puerto Rico", "PR"],
"00783": ["Corozal", "Puerto Rico", "PR"],
"00784": ["Guayama", "Puerto Rico", "PR"],
"00786": ["La Plata", "Puerto Rico", "PR"],
"00791": ["Humacao", "Puerto Rico", "PR"],
"00794": ["Barranquitas", "Puerto Rico", "PR"],
"00795": ["Juana Diaz", "Puerto Rico", "PR"],
"00802": ["St Thomas", "Virgin Islands", "VI"],
"00820": ["Christiansted", "Virgin Islands", "VI"],
"00830": ["St John", "Virgin Islands", "VI"],
"00840": ["Frederiksted", "Virgin Islands", "VI"],
"00850": ["Kingshill", "Virgin Islands", "VI"],
"00851": ["Kingshill", "Virgin Islands", "VI"],
"00901": ["San Juan", "Puerto Rico", "PR"],
"00906": ["San Juan", "Puerto Rico", "PR"],
"00907": ["San Juan", "Puerto Rico", "PR"],
"00909": ["San Juan", "Puerto Rico", "PR"],
"00911": ["San Juan", "Puerto Rico", "PR"],
"00912": ["San Juan", "Puerto Rico", "PR"],
"00913": ["San Juan", "Puerto Rico", "PR"],
"00915": ["San Juan", "Puerto Rico", "PR"],
"00917": ["San Juan", "Puerto Rico", "PR"],
"00918": ["San Juan", "Puerto Rico", "PR"],
"00920": ["San Juan", "Puerto Rico", "PR"],
"00921": ["San Juan", "Puerto Rico", "PR"],
"00923": ["San Juan", "Puerto Rico", "PR"],
"00924": ["San Juan", "Puerto Rico", "PR"],
"00925": ["San Juan", "Puerto Rico", "PR"],
"00926": ["San Juan", "Puerto Rico", "PR"],
"00927": ["San Juan", "Puerto Rico", "PR"],
"00934": ["Fort Buchanan", "Puerto Rico", "PR"],
"00949": ["Toa Baja", "Puerto Rico", "PR"],
"00950": ["Toa Baja", "Puerto Rico", "PR"],
"00951": ["Toa Baja", "Puerto Rico", "PR"],
"00952": ["Sabana Seca", "Puerto Rico", "PR"],
"00953": ["Toa Alta", "Puerto Rico", "PR"],
"00956": ["Bayamon", "Puerto Rico", "PR"],
"00957": ["Bayamon", "Puerto Rico", "PR"],
"00959": ["Bayamon", "Puerto Rico", "PR"],
"00960": ["Bayamon", "Puerto Rico", "PR"],
"00961": ["Bayamon", "Puerto Rico", "PR"],
"00962": ["Catano", "Puerto Rico", "PR"],
"00965": ["Guaynabo", "Puerto Rico", "PR"],
"00966": ["Guaynabo", "Puerto Rico", "PR"],
"00968": ["Guaynabo", "Puerto Rico", "PR"],
"00969": ["Guaynabo", "Puerto Rico", "PR"],
"00971": ["Guaynabo", "Puerto Rico", "PR"],
"00976": ["Trujillo Alto", "Puerto Rico", "PR"],
"00979": ["Carolina", "Puerto Rico", "PR"],
"00982": ["Carolina", "Puerto Rico", "PR"],
"00983": ["Carolina", "Puerto Rico", "PR"],
"00985": ["Carolina", "Puerto Rico", "PR"],
"00987": ["Carolina", "Puerto Rico", "PR"],
"01001": ["Agawam", "Massachusetts", "MA"],
"01002": ["Amherst", "Massachusetts", "MA"],
"01003": ["Amherst", "Massachusetts", "MA"],
"01005": ["Barre", "Massachusetts", "MA"],
"01007": ["Belchertown", "Massachusetts", "MA"],
"01008": ["Blandford", "Massachusetts", "MA"],
"01009": ["Bondsville", "Massachusetts", "MA"],
"01010": ["Brimfield", "Massachusetts", "MA"],
"01011": ["Chester", "Massachusetts", "MA"],
"01012": ["Chesterfield", "Massachusetts", "MA"],
"01013": ["Chicopee", "Massachusetts", "MA"],
"01020": ["Chicopee", "Massachusetts", "MA"],
"01022": ["Chicopee", "Massachusetts", "MA"],
"01026": ["Cummington", "Massachusetts", "MA"],
"01027": ["Easthampton", "Massachusetts", "MA"],
"01028": ["East Longmeadow", "Massachusetts", "MA"],
"01029": ["East Otis", "Massachusetts", "MA"],
"01030": ["Feeding Hills", "Massachusetts", "MA"],
"01031": ["Gilbertville", "Massachusetts", "MA"],
"01032": ["Goshen", "Massachusetts", "MA"],
"01033": ["Granby", "Massachusetts", "MA"],
"01034": ["Granville", "Massachusetts", "MA"],
"01035": ["Hadley", "Massachusetts", "MA"],
"01036": ["Hampden", "Massachusetts", "MA"],
"01037": ["Hardwick", "Massachusetts", "MA"],
"01038": ["Hatfield", "Massachusetts", "MA"],
"01039": ["Haydenville", "Massachusetts", "MA"],
"01040": ["Holyoke", "Massachusetts", "MA"],
"01050": ["Huntington", "Massachusetts", "MA"],
"01053": ["Leeds", "Massachusetts", "MA"],
"01054": ["Leverett", "Massachusetts", "MA"],
"01056": ["Ludlow", "Massachusetts", "MA"],
"01057": ["Monson", "Massachusetts", "MA"],
"01060": ["Northampton", "Massachusetts", "MA"],
"01062": ["Florence", "Massachusetts", "MA"],
"01063": ["Northampton", "Massachusetts", "MA"],
"01066": ["North Hatfield", "Massachusetts", "MA"],
"01068": ["Oakham", "Massachusetts", "MA"],
"01069": ["Palmer", "Massachusetts", "MA"],
"01070": ["Plainfield", "Massachusetts", "MA"],
"01071": ["Russell", "Massachusetts", "MA"],
"01072": ["Shutesbury", "Massachusetts", "MA"],
"01073": ["Southampton", "Massachusetts", "MA"],
"01074": ["South Barre", "Massachusetts", "MA"],
"01075": ["South Hadley", "Massachusetts", "MA"],
"01077": ["Southwick", "Massachusetts", "MA"],
"01079": ["Thorndike", "Massachusetts", "MA"],
"01080": ["Three Rivers", "Massachusetts", "MA"],
"01081": ["Wales", "Massachusetts", "MA"],
"01082": ["Ware", "Massachusetts", "MA"],
"01083": ["Warren", "Massachusetts", "MA"],
"01084": ["West Chesterfield", "Massachusetts", "MA"],
"01085": ["Westfield", "Massachusetts", "MA"],
"01088": ["West Hatfield", "Massachusetts", "MA"],
"01089": ["West Springfield", "Massachusetts", "MA"],
"01092": ["West Warren", "Massachusetts", "MA"],
"01093": ["Whately", "Massachusetts", "MA"],
"01094": ["Wheelwright", "Massachusetts", "MA"],
"01095": ["Wilbraham", "Massachusetts", "MA"],
"01096": ["Williamsburg", "Massachusetts", "MA"],
"01097": ["Woronoco", "Massachusetts", "MA"],
"01098": ["Worthington", "Massachusetts", "MA"],
"01103": ["Springfield", "Massachusetts", "MA"],
"01104": ["Springfield", "Massachusetts", "MA"],
"01105": ["Springfield", "Massachusetts", "MA"],
"01106": ["Longmeadow", "Massachusetts", "MA"],
"01107": ["Springfield", "Massachusetts", "MA"],
"01108": ["Springfield", "Massachusetts", "MA"],
"01109": ["Springfield", "Massachusetts", "MA"],
"01118": ["Springfield", "Massachusetts", "MA"],
"01119": ["Springfield", "Massachusetts", "MA"],
"01128": ["Springfield", "Massachusetts", "MA"],
"01129": ["Springfield", "Massachusetts", "MA"],
"01144": ["Springfield", "Massachusetts", "MA"],
"01151": ["Indian Orchard", "Massachusetts", "MA"],
"01201": ["Pittsfield", "Massachusetts", "MA"],
"01220": ["Adams", "Massachusetts", "MA"],
"01222": ["Ashley Falls", "Massachusetts", "MA"],
"01223": ["Becket", "Massachusetts", "MA"],
"01224": ["Berkshire", "Massachusetts", "MA"],
"01225": ["Cheshire", "Massachusetts", "MA"],
"01226": ["Dalton", "Massachusetts", "MA"],
"01229": ["Glendale", "Massachusetts", "MA"],
"01230": ["Great Barrington", "Massachusetts", "MA"],
"01235": ["Hinsdale", "Massachusetts", "MA"],
"01236": ["Housatonic", "Massachusetts", "MA"],
"01237": ["Lanesborough", "Massachusetts", "MA"],
"01238": ["Lee", "Massachusetts", "MA"],
"01240": ["Lenox", "Massachusetts", "MA"],
"01242": ["Lenox Dale", "Massachusetts", "MA"],
"01243": ["Middlefield", "Massachusetts", "MA"],
"01244": ["Mill River", "Massachusetts", "MA"],
"01245": ["Monterey", "Massachusetts", "MA"],
"01247": ["North Adams", "Massachusetts", "MA"],
"01253": ["Otis", "Massachusetts", "MA"],
"01254": ["Richmond", "Massachusetts", "MA"],
"01255": ["Sandisfield", "Massachusetts", "MA"],
"01256": ["Savoy", "Massachusetts", "MA"],
"01257": ["Sheffield", "Massachusetts", "MA"],
"01258": ["South Egremont", "Massachusetts", "MA"],
"01259": ["Southfield", "Massachusetts", "MA"],
"01260": ["South Lee", "Massachusetts", "MA"],
"01262": ["Stockbridge", "Massachusetts", "MA"],
"01264": ["Tyringham", "Massachusetts", "MA"],
"01266": ["West Stockbridge", "Massachusetts", "MA"],
"01267": ["Williamstown", "Massachusetts", "MA"],
"01270": ["Windsor", "Massachusetts", "MA"],
"01301": ["Greenfield", "Massachusetts", "MA"],
"01330": ["Ashfield", "Massachusetts", "MA"],
"01331": ["Athol", "Massachusetts", "MA"],
"01337": ["Bernardston", "Massachusetts", "MA"],
"01338": ["Buckland", "Massachusetts", "MA"],
"01339": ["Charlemont", "Massachusetts", "MA"],
"01340": ["Colrain", "Massachusetts", "MA"],
"01341": ["Conway", "Massachusetts", "MA"],
"01342": ["Deerfield", "Massachusetts", "MA"],
"01343": ["Drury", "Massachusetts", "MA"],
"01344": ["Erving", "Massachusetts", "MA"],
"01346": ["Heath", "Massachusetts", "MA"],
"01347": ["Lake Pleasant", "Massachusetts", "MA"],
"01349": ["Millers Falls", "Massachusetts", "MA"],
"01350": ["Monroe Bridge", "Massachusetts", "MA"],
"01351": ["Montague", "Massachusetts", "MA"],
"01354": ["Gill", "Massachusetts", "MA"],
"01355": ["New Salem", "Massachusetts", "MA"],
"01360": ["Northfield", "Massachusetts", "MA"],
"01364": ["Orange", "Massachusetts", "MA"],
"01366": ["Petersham", "Massachusetts", "MA"],
"01367": ["Rowe", "Massachusetts", "MA"],
"01368": ["Royalston", "Massachusetts", "MA"],
"01370": ["Shelburne Falls", "Massachusetts", "MA"],
"01373": ["South Deerfield", "Massachusetts", "MA"],
"01375": ["Sunderland", "Massachusetts", "MA"],
"01376": ["Turners Falls", "Massachusetts", "MA"],
"01378": ["Warwick", "Massachusetts", "MA"],
"01379": ["Wendell", "Massachusetts", "MA"],
"01380": ["Wendell Depot", "Massachusetts", "MA"],
"01420": ["Fitchburg", "Massachusetts", "MA"],
"01430": ["Ashburnham", "Massachusetts", "MA"],
"01431": ["Ashby", "Massachusetts", "MA"],
"01432": ["Ayer", "Massachusetts", "MA"],
"01434": ["Devens", "Massachusetts", "MA"],
"01436": ["Baldwinville", "Massachusetts", "MA"],
"01438": ["East Templeton", "Massachusetts", "MA"],
"01440": ["Gardner", "Massachusetts", "MA"],
"01450": ["Groton", "Massachusetts", "MA"],
"01451": ["Harvard", "Massachusetts", "MA"],
"01452": ["Hubbardston", "Massachusetts", "MA"],
"01453": ["Leominster", "Massachusetts", "MA"],
"01460": ["Littleton", "Massachusetts", "MA"],
"01462": ["Lunenburg", "Massachusetts", "MA"],
"01463": ["Pepperell", "Massachusetts", "MA"],
"01464": ["Shirley", "Massachusetts", "MA"],
"01468": ["Templeton", "Massachusetts", "MA"],
"01469": ["Townsend", "Massachusetts", "MA"],
"01473": ["Westminster", "Massachusetts", "MA"],
"01474": ["West Townsend", "Massachusetts", "MA"],
"01475": ["Winchendon", "Massachusetts", "MA"],
"01501": ["Auburn", "Massachusetts", "MA"],
"01503": ["Berlin", "Massachusetts", "MA"],
"01504": ["Blackstone", "Massachusetts", "MA"],
"01505": ["Boylston", "Massachusetts", "MA"],
"01506": ["Brookfield", "Massachusetts", "MA"],
"01507": ["Charlton", "Massachusetts", "MA"],
"01510": ["Clinton", "Massachusetts", "MA"],
"01515": ["East Brookfield", "Massachusetts", "MA"],
"01516": ["Douglas", "Massachusetts", "MA"],
"01518": ["Fiskdale", "Massachusetts", "MA"],
"01519": ["Grafton", "Massachusetts", "MA"],
"01520": ["Holden", "Massachusetts", "MA"],
"01521": ["Holland", "Massachusetts", "MA"],
"01522": ["Jefferson", "Massachusetts", "MA"],
"01523": ["Lancaster", "Massachusetts", "MA"],
"01524": ["Leicester", "Massachusetts", "MA"],
"01525": ["Linwood", "Massachusetts", "MA"],
"01527": ["Millbury", "Massachusetts", "MA"],
"01529": ["Millville", "Massachusetts", "MA"],
"01531": ["New Braintree", "Massachusetts", "MA"],
"01532": ["Northborough", "Massachusetts", "MA"],
"01534": ["Northbridge", "Massachusetts", "MA"],
"01535": ["North Brookfield", "Massachusetts", "MA"],
"01536": ["North Grafton", "Massachusetts", "MA"],
"01537": ["North Oxford", "Massachusetts", "MA"],
"01540": ["Oxford", "Massachusetts", "MA"],
"01541": ["Princeton", "Massachusetts", "MA"],
"01542": ["Rochdale", "Massachusetts", "MA"],
"01543": ["Rutland", "Massachusetts", "MA"],
"01545": ["Shrewsbury", "Massachusetts", "MA"],
"01550": ["Southbridge", "Massachusetts", "MA"],
"01560": ["South Grafton", "Massachusetts", "MA"],
"01561": ["South Lancaster", "Massachusetts", "MA"],
"01562": ["Spencer", "Massachusetts", "MA"],
"01564": ["Sterling", "Massachusetts", "MA"],
"01566": ["Sturbridge", "Massachusetts", "MA"],
"01568": ["Upton", "Massachusetts", "MA"],
"01569": ["Uxbridge", "Massachusetts", "MA"],
"01570": ["Webster", "Massachusetts", "MA"],
"01571": ["Dudley", "Massachusetts", "MA"],
"01581": ["Westborough", "Massachusetts", "MA"],
"01583": ["West Boylston", "Massachusetts", "MA"],
"01585": ["West Brookfield", "Massachusetts", "MA"],
"01588": ["Whitinsville", "Massachusetts", "MA"],
"01590": ["Sutton", "Massachusetts", "MA"],
"01602": ["Worcester", "Massachusetts", "MA"],
"01603": ["Worcester", "Massachusetts", "MA"],
"01604": ["Worcester", "Massachusetts", "MA"],
"01605": ["Worcester", "Massachusetts", "MA"],
"01606": ["Worcester", "Massachusetts", "MA"],
"01607": ["Worcester", "Massachusetts", "MA"],
"01608": ["Worcester", "Massachusetts", "MA"],
"01609": ["Worcester", "Massachusetts", "MA"],
"01610": ["Worcester", "Massachusetts", "MA"],
"01611": ["Cherry Valley", "Massachusetts", "MA"],
"01612": ["Paxton", "Massachusetts", "MA"],
"01701": ["Framingham", "Massachusetts", "MA"],
"01702": ["Framingham", "Massachusetts", "MA"],
"01718": ["Acton", "Massachusetts", "MA"],
"01719": ["Boxborough", "Massachusetts", "MA"],
"01720": ["Acton", "Massachusetts", "MA"],
"01721": ["Ashland", "Massachusetts", "MA"],
"01730": ["Bedford", "Massachusetts", "MA"],
"01731": ["Hanscom Afb", "Massachusetts", "MA"],
"01740": ["Bolton", "Massachusetts", "MA"],
"01741": ["Carlisle", "Massachusetts", "MA"],
"01742": ["Concord", "Massachusetts", "MA"],
"01745": ["Fayville", "Massachusetts", "MA"],
"01746": ["Holliston", "Massachusetts", "MA"],
"01747": ["Hopedale", "Massachusetts", "MA"],
"01748": ["Hopkinton", "Massachusetts", "MA"],
"01749": ["Hudson", "Massachusetts", "MA"],
"01752": ["Marlborough", "Massachusetts", "MA"],
"01754": ["Maynard", "Massachusetts", "MA"],
"01756": ["Mendon", "Massachusetts", "MA"],
"01757": ["Milford", "Massachusetts", "MA"],
"01760": ["Natick", "Massachusetts", "MA"],
"01770": ["Sherborn", "Massachusetts", "MA"],
"01772": ["Southborough", "Massachusetts", "MA"],
"01773": ["Lincoln", "Massachusetts", "MA"],
"01775": ["Stow", "Massachusetts", "MA"],
"01776": ["Sudbury", "Massachusetts", "MA"],
"01778": ["Wayland", "Massachusetts", "MA"],
"01801": ["Woburn", "Massachusetts", "MA"],
"01803": ["Burlington", "Massachusetts", "MA"],
"01810": ["Andover", "Massachusetts", "MA"],
"01821": ["Billerica", "Massachusetts", "MA"],
"01824": ["Chelmsford", "Massachusetts", "MA"],
"01826": ["Dracut", "Massachusetts", "MA"],
"01827": ["Dunstable", "Massachusetts", "MA"],
"01830": ["Haverhill", "Massachusetts", "MA"],
"01832": ["Haverhill", "Massachusetts", "MA"],
"01833": ["Georgetown", "Massachusetts", "MA"],
"01834": ["Groveland", "Massachusetts", "MA"],
"01835": ["Haverhill", "Massachusetts", "MA"],
"01840": ["Lawrence", "Massachusetts", "MA"],
"01841": ["Lawrence", "Massachusetts", "MA"],
"01843": ["Lawrence", "Massachusetts", "MA"],
"01844": ["Methuen", "Massachusetts", "MA"],
"01845": ["North Andover", "Massachusetts", "MA"],
"01850": ["Lowell", "Massachusetts", "MA"],
"01851": ["Lowell", "Massachusetts", "MA"],
"01852": ["Lowell", "Massachusetts", "MA"],
"01854": ["Lowell", "Massachusetts", "MA"],
"01860": ["Merrimac", "Massachusetts", "MA"],
"01862": ["North Billerica", "Massachusetts", "MA"],
"01863": ["North Chelmsford", "Massachusetts", "MA"],
"01864": ["North Reading", "Massachusetts", "MA"],
"01867": ["Reading", "Massachusetts", "MA"],
"01876": ["Tewksbury", "Massachusetts", "MA"],
"01879": ["Tyngsboro", "Massachusetts", "MA"],
"01880": ["Wakefield", "Massachusetts", "MA"],
"01886": ["Westford", "Massachusetts", "MA"],
"01887": ["Wilmington", "Massachusetts", "MA"],
"01890": ["Winchester", "Massachusetts", "MA"],
"01901": ["Lynn", "Massachusetts", "MA"],
"01902": ["Lynn", "Massachusetts", "MA"],
"01904": ["Lynn", "Massachusetts", "MA"],
"01905": ["Lynn", "Massachusetts", "MA"],
"01906": ["Saugus", "Massachusetts", "MA"],
"01907": ["Swampscott", "Massachusetts", "MA"],
"01908": ["Nahant", "Massachusetts", "MA"],
"01913": ["Amesbury", "Massachusetts", "MA"],
"01915": ["Beverly", "Massachusetts", "MA"],
"01921": ["Boxford", "Massachusetts", "MA"],
"01922": ["Byfield", "Massachusetts", "MA"],
"01923": ["Danvers", "Massachusetts", "MA"],
"01929": ["Essex", "Massachusetts", "MA"],
"01930": ["Gloucester", "Massachusetts", "MA"],
"01937": ["Hathorne", "Massachusetts", "MA"],
"01938": ["Ipswich", "Massachusetts", "MA"],
"01940": ["Lynnfield", "Massachusetts", "MA"],
"01944": ["Manchester", "Massachusetts", "MA"],
"01945": ["Marblehead", "Massachusetts", "MA"],
"01949": ["Middleton", "Massachusetts", "MA"],
"01950": ["Newburyport", "Massachusetts", "MA"],
"01951": ["Newbury", "Massachusetts", "MA"],
"01952": ["Salisbury", "Massachusetts", "MA"],
"01960": ["Peabody", "Massachusetts", "MA"],
"01965": ["Prides Crossing", "Massachusetts", "MA"],
"01966": ["Rockport", "Massachusetts", "MA"],
"01969": ["Rowley", "Massachusetts", "MA"],
"01970": ["Salem", "Massachusetts", "MA"],
"01982": ["South Hamilton", "Massachusetts", "MA"],
"01983": ["Topsfield", "Massachusetts", "MA"],
"01984": ["Wenham", "Massachusetts", "MA"],
"01985": ["West Newbury", "Massachusetts", "MA"],
"02019": ["Bellingham", "Massachusetts", "MA"],
"02021": ["Canton", "Massachusetts", "MA"],
"02025": ["Cohasset", "Massachusetts", "MA"],
"02026": ["Dedham", "Massachusetts", "MA"],
"02030": ["Dover", "Massachusetts", "MA"],
"02032": ["East Walpole", "Massachusetts", "MA"],
"02035": ["Foxboro", "Massachusetts", "MA"],
"02038": ["Franklin", "Massachusetts", "MA"],
"02043": ["Hingham", "Massachusetts", "MA"],
"02045": ["Hull", "Massachusetts", "MA"],
"02047": ["Humarock", "Massachusetts", "MA"],
"02048": ["Mansfield", "Massachusetts", "MA"],
"02050": ["Marshfield", "Massachusetts", "MA"],
"02052": ["Medfield", "Massachusetts", "MA"],
"02053": ["Medway", "Massachusetts", "MA"],
"02054": ["Millis", "Massachusetts", "MA"],
"02056": ["Norfolk", "Massachusetts", "MA"],
"02061": ["Norwell", "Massachusetts", "MA"],
"02062": ["Norwood", "Massachusetts", "MA"],
"02066": ["Scituate", "Massachusetts", "MA"],
"02067": ["Sharon", "Massachusetts", "MA"],
"02071": ["South Walpole", "Massachusetts", "MA"],
"02072": ["Stoughton", "Massachusetts", "MA"],
"02081": ["Walpole", "Massachusetts", "MA"],
"02090": ["Westwood", "Massachusetts", "MA"],
"02093": ["Wrentham", "Massachusetts", "MA"],
"02108": ["Boston", "Massachusetts", "MA"],
"02109": ["Boston", "Massachusetts", "MA"],
"02110": ["Boston", "Massachusetts", "MA"],
"02111": ["Boston", "Massachusetts", "MA"],
"02113": ["Boston", "Massachusetts", "MA"],
"02114": ["Boston", "Massachusetts", "MA"],
"02115": ["Boston", "Massachusetts", "MA"],
"02116": ["Boston", "Massachusetts", "MA"],
"02118": ["Boston", "Massachusetts", "MA"],
"02119": ["Roxbury", "Massachusetts", "MA"],
"02120": ["Roxbury Crossing", "Massachusetts", "MA"],
"02121": ["Dorchester", "Massachusetts", "MA"],
"02122": ["Dorchester", "Massachusetts", "MA"],
"02124": ["Dorchester Center", "Massachusetts", "MA"],
"02125": ["Dorchester", "Massachusetts", "MA"],
"02126": ["Mattapan", "Massachusetts", "MA"],
"02127": ["South Boston", "Massachusetts", "MA"],
"02128": ["East Boston", "Massachusetts", "MA"],
"02129": ["Charlestown", "Massachusetts", "MA"],
"02130": ["Jamaica Plain", "Massachusetts", "MA"],
"02131": ["Roslindale", "Massachusetts", "MA"],
"02132": ["West Roxbury", "Massachusetts", "MA"],
"02133": ["Boston", "Massachusetts", "MA"],
"02134": ["Allston", "Massachusetts", "MA"],
"02135": ["Brighton", "Massachusetts", "MA"],
"02136": ["Hyde Park", "Massachusetts", "MA"],
"02138": ["Cambridge", "Massachusetts", "MA"],
"02139": ["Cambridge", "Massachusetts", "MA"],
"02140": ["Cambridge", "Massachusetts", "MA"],
"02141": ["Cambridge", "Massachusetts", "MA"],
"02142": ["Cambridge", "Massachusetts", "MA"],
"02143": ["Somerville", "Massachusetts", "MA"],
"02144": ["Somerville", "Massachusetts", "MA"],
"02145": ["Somerville", "Massachusetts", "MA"],
"02148": ["Malden", "Massachusetts", "MA"],
"02149": ["Everett", "Massachusetts", "MA"],
"02150": ["Chelsea", "Massachusetts", "MA"],
"02151": ["Revere", "Massachusetts", "MA"],
"02152": ["Winthrop", "Massachusetts", "MA"],
"02155": ["Medford", "Massachusetts", "MA"],
"02163": ["Boston", "Massachusetts", "MA"],
"02169": ["Quincy", "Massachusetts", "MA"],
"02170": ["Quincy", "Massachusetts", "MA"],
"02171": ["Quincy", "Massachusetts", "MA"],
"02176": ["Melrose", "Massachusetts", "MA"],
"02180": ["Stoneham", "Massachusetts", "MA"],
"02184": ["Braintree", "Massachusetts", "MA"],
"02186": ["Milton", "Massachusetts", "MA"],
"02188": ["Weymouth", "Massachusetts", "MA"],
"02189": ["East Weymouth", "Massachusetts", "MA"],
"02190": ["South Weymouth", "Massachusetts", "MA"],
"02191": ["North Weymouth", "Massachusetts", "MA"],
"02199": ["Boston", "Massachusetts", "MA"],
"02203": ["Boston", "Massachusetts", "MA"],
"02205": ["Boston", "Massachusetts", "MA"],
"02210": ["Boston", "Massachusetts", "MA"],
"02215": ["Boston", "Massachusetts", "MA"],
"02301": ["Brockton", "Massachusetts", "MA"],
"02302": ["Brockton", "Massachusetts", "MA"],
"02322": ["Avon", "Massachusetts", "MA"],
"02324": ["Bridgewater", "Massachusetts", "MA"],
"02325": ["Bridgewater", "Massachusetts", "MA"],
"02330": ["Carver", "Massachusetts", "MA"],
"02332": ["Duxbury", "Massachusetts", "MA"],
"02333": ["East Bridgewater", "Massachusetts", "MA"],
"02338": ["Halifax", "Massachusetts", "MA"],
"02339": ["Hanover", "Massachusetts", "MA"],
"02341": ["Hanson", "Massachusetts", "MA"],
"02343": ["Holbrook", "Massachusetts", "MA"],
"02346": ["Middleboro", "Massachusetts", "MA"],
"02347": ["Lakeville", "Massachusetts", "MA"],
"02350": ["Monponsett", "Massachusetts", "MA"],
"02351": ["Abington", "Massachusetts", "MA"],
"02356": ["North Easton", "Massachusetts", "MA"],
"02357": ["North Easton", "Massachusetts", "MA"],
"02359": ["Pembroke", "Massachusetts", "MA"],
"02360": ["Plymouth", "Massachusetts", "MA"],
"02364": ["Kingston", "Massachusetts", "MA"],
"02366": ["South Carver", "Massachusetts", "MA"],
"02367": ["Plympton", "Massachusetts", "MA"],
"02368": ["Randolph", "Massachusetts", "MA"],
"02370": ["Rockland", "Massachusetts", "MA"],
"02375": ["South Easton", "Massachusetts", "MA"],
"02379": ["West Bridgewater", "Massachusetts", "MA"],
"02382": ["Whitman", "Massachusetts", "MA"],
"02420": ["Lexington", "Massachusetts", "MA"],
"02421": ["Lexington", "Massachusetts", "MA"],
"02445": ["Brookline", "Massachusetts", "MA"],
"02446": ["Brookline", "Massachusetts", "MA"],
"02451": ["Waltham", "Massachusetts", "MA"],
"02452": ["Waltham", "Massachusetts", "MA"],
"02453": ["Waltham", "Massachusetts", "MA"],
"02457": ["Babson Park", "Massachusetts", "MA"],
"02458": ["Newton", "Massachusetts", "MA"],
"02459": ["Newton Center", "Massachusetts", "MA"],
"02460": ["Newtonville", "Massachusetts", "MA"],
"02461": ["Newton Highlands", "Massachusetts", "MA"],
"02462": ["Newton Lower Falls", "Massachusetts", "MA"],
"02464": ["Newton Upper Falls", "Massachusetts", "MA"],
"02465": ["West Newton", "Massachusetts", "MA"],
"02466": ["Auburndale", "Massachusetts", "MA"],
"02467": ["Chestnut Hill", "Massachusetts", "MA"],
"02468": ["Waban", "Massachusetts", "MA"],
"02472": ["Watertown", "Massachusetts", "MA"],
"02474": ["Arlington", "Massachusetts", "MA"],
"02476": ["Arlington", "Massachusetts", "MA"],
"02478": ["Belmont", "Massachusetts", "MA"],
"02481": ["Wellesley Hills", "Massachusetts", "MA"],
"02482": ["Wellesley", "Massachusetts", "MA"],
"02492": ["Needham", "Massachusetts", "MA"],
"02493": ["Weston", "Massachusetts", "MA"],
"02494": ["Needham Heights", "Massachusetts", "MA"],
"02532": ["Buzzards Bay", "Massachusetts", "MA"],
"02534": ["Cataumet", "Massachusetts", "MA"],
"02535": ["Chilmark", "Massachusetts", "MA"],
"02536": ["East Falmouth", "Massachusetts", "MA"],
"02537": ["East Sandwich", "Massachusetts", "MA"],
"02538": ["East Wareham", "Massachusetts", "MA"],
"02539": ["Edgartown", "Massachusetts", "MA"],
"02540": ["Falmouth", "Massachusetts", "MA"],
"02542": ["Buzzards Bay", "Massachusetts", "MA"],
"02543": ["Woods Hole", "Massachusetts", "MA"],
"02553": ["Monument Beach", "Massachusetts", "MA"],
"02554": ["Nantucket", "Massachusetts", "MA"],
"02556": ["North Falmouth", "Massachusetts", "MA"],
"02557": ["Oak Bluffs", "Massachusetts", "MA"],
"02558": ["Onset", "Massachusetts", "MA"],
"02559": ["Pocasset", "Massachusetts", "MA"],
"02561": ["Sagamore", "Massachusetts", "MA"],
"02562": ["Sagamore Beach", "Massachusetts", "MA"],
"02563": ["Sandwich", "Massachusetts", "MA"],
"02564": ["Siasconset", "Massachusetts", "MA"],
"02568": ["Vineyard Haven", "Massachusetts", "MA"],
"02571": ["Wareham", "Massachusetts", "MA"],
"02575": ["West Tisbury", "Massachusetts", "MA"],
"02576": ["West Wareham", "Massachusetts", "MA"],
"02584": ["Nantucket", "Massachusetts", "MA"],
"02601": ["Hyannis", "Massachusetts", "MA"],
"02630": ["Barnstable", "Massachusetts", "MA"],
"02631": ["Brewster", "Massachusetts", "MA"],
"02632": ["Centerville", "Massachusetts", "MA"],
"02633": ["Chatham", "Massachusetts", "MA"],
"02635": ["Cotuit", "Massachusetts", "MA"],
"02637": ["Cummaquid", "Massachusetts", "MA"],
"02638": ["Dennis", "Massachusetts", "MA"],
"02639": ["Dennis Port", "Massachusetts", "MA"],
"02641": ["East Dennis", "Massachusetts", "MA"],
"02642": ["Eastham", "Massachusetts", "MA"],
"02643": ["East Orleans", "Massachusetts", "MA"],
"02644": ["Forestdale", "Massachusetts", "MA"],
"02645": ["Harwich", "Massachusetts", "MA"],
"02646": ["Harwich Port", "Massachusetts", "MA"],
"02647": ["Hyannis Port", "Massachusetts", "MA"],
"02648": ["Marstons Mills", "Massachusetts", "MA"],
"02649": ["Mashpee", "Massachusetts", "MA"],
"02650": ["North Chatham", "Massachusetts", "MA"],
"02652": ["North Truro", "Massachusetts", "MA"],
"02653": ["Orleans", "Massachusetts", "MA"],
"02655": ["Osterville", "Massachusetts", "MA"],
"02657": ["Provincetown", "Massachusetts", "MA"],
"02659": ["South Chatham", "Massachusetts", "MA"],
"02660": ["South Dennis", "Massachusetts", "MA"],
"02663": ["South Wellfleet", "Massachusetts", "MA"],
"02664": ["South Yarmouth", "Massachusetts", "MA"],
"02666": ["Truro", "Massachusetts", "MA"],
"02667": ["Wellfleet", "Massachusetts", "MA"],
"02668": ["West Barnstable", "Massachusetts", "MA"],
"02670": ["West Dennis", "Massachusetts", "MA"],
"02671": ["West Harwich", "Massachusetts", "MA"],
"02672": ["West Hyannisport", "Massachusetts", "MA"],
"02673": ["West Yarmouth", "Massachusetts", "MA"],
"02675": ["Yarmouth Port", "Massachusetts", "MA"],
"02702": ["Assonet", "Massachusetts", "MA"],
"02703": ["Attleboro", "Massachusetts", "MA"],
"02713": ["Cuttyhunk", "Massachusetts", "MA"],
"02715": ["Dighton", "Massachusetts", "MA"],
"02717": ["East Freetown", "Massachusetts", "MA"],
"02718": ["East Taunton", "Massachusetts", "MA"],
"02719": ["Fairhaven", "Massachusetts", "MA"],
"02720": ["Fall River", "Massachusetts", "MA"],
"02721": ["Fall River", "Massachusetts", "MA"],
"02723": ["Fall River", "Massachusetts", "MA"],
"02724": ["Fall River", "Massachusetts", "MA"],
"02725": ["Somerset", "Massachusetts", "MA"],
"02726": ["Somerset", "Massachusetts", "MA"],
"02738": ["Marion", "Massachusetts", "MA"],
"02739": ["Mattapoisett", "Massachusetts", "MA"],
"02740": ["New Bedford", "Massachusetts", "MA"],
"02743": ["Acushnet", "Massachusetts", "MA"],
"02744": ["New Bedford", "Massachusetts", "MA"],
"02745": ["New Bedford", "Massachusetts", "MA"],
"02746": ["New Bedford", "Massachusetts", "MA"],
"02747": ["North Dartmouth", "Massachusetts", "MA"],
"02748": ["South Dartmouth", "Massachusetts", "MA"],
"02760": ["North Attleboro", "Massachusetts", "MA"],
"02762": ["Plainville", "Massachusetts", "MA"],
"02763": ["Attleboro Falls", "Massachusetts", "MA"],
"02764": ["North Dighton", "Massachusetts", "MA"],
"02766": ["Norton", "Massachusetts", "MA"],
"02767": ["Raynham", "Massachusetts", "MA"],
"02769": ["Rehoboth", "Massachusetts", "MA"],
"02770": ["Rochester", "Massachusetts", "MA"],
"02771": ["Seekonk", "Massachusetts", "MA"],
"02777": ["Swansea", "Massachusetts", "MA"],
"02779": ["Berkley", "Massachusetts", "MA"],
"02780": ["Taunton", "Massachusetts", "MA"],
"02790": ["Westport", "Massachusetts", "MA"],
"02791": ["Westport Point", "Massachusetts", "MA"],
"02802": ["Albion", "Rhode Island", "RI"],
"02804": ["Ashaway", "Rhode Island", "RI"],
"02806": ["Barrington", "Rhode Island", "RI"],
"02807": ["Block Island", "Rhode Island", "RI"],
"02808": ["Bradford", "Rhode Island", "RI"],
"02809": ["Bristol", "Rhode Island", "RI"],
"02812": ["Carolina", "Rhode Island", "RI"],
"02813": ["Charlestown", "Rhode Island", "RI"],
"02814": ["Chepachet", "Rhode Island", "RI"],
"02815": ["Clayville", "Rhode Island", "RI"],
"02816": ["Coventry", "Rhode Island", "RI"],
"02817": ["West Greenwich", "Rhode Island", "RI"],
"02818": ["East Greenwich", "Rhode Island", "RI"],
"02822": ["Exeter", "Rhode Island", "RI"],
"02823": ["Fiskeville", "Rhode Island", "RI"],
"02824": ["Forestdale", "Rhode Island", "RI"],
"02825": ["Foster", "Rhode Island", "RI"],
"02826": ["Glendale", "Rhode Island", "RI"],
"02827": ["Greene", "Rhode Island", "RI"],
"02828": ["Greenville", "Rhode Island", "RI"],
"02829": ["Harmony", "Rhode Island", "RI"],
"02830": ["Harrisville", "Rhode Island", "RI"],
"02831": ["Hope", "Rhode Island", "RI"],
"02832": ["Hope Valley", "Rhode Island", "RI"],
"02833": ["Hopkinton", "Rhode Island", "RI"],
"02835": ["Jamestown", "Rhode Island", "RI"],
"02836": ["Kenyon", "Rhode Island", "RI"],
"02837": ["Little Compton", "Rhode Island", "RI"],
"02838": ["Manville", "Rhode Island", "RI"],
"02839": ["Mapleville", "Rhode Island", "RI"],
"02840": ["Newport", "Rhode Island", "RI"],
"02841": ["Newport", "Rhode Island", "RI"],
"02842": ["Middletown", "Rhode Island", "RI"],
"02852": ["North Kingstown", "Rhode Island", "RI"],
"02857": ["North Scituate", "Rhode Island", "RI"],
"02858": ["Oakland", "Rhode Island", "RI"],
"02859": ["Pascoag", "Rhode Island", "RI"],
"02860": ["Pawtucket", "Rhode Island", "RI"],
"02861": ["Pawtucket", "Rhode Island", "RI"],
"02863": ["Central Falls", "Rhode Island", "RI"],
"02864": ["Cumberland", "Rhode Island", "RI"],
"02865": ["Lincoln", "Rhode Island", "RI"],
"02871": ["Portsmouth", "Rhode Island", "RI"],
"02872": ["Prudence Island", "Rhode Island", "RI"],
"02873": ["Rockville", "Rhode Island", "RI"],
"02874": ["Saunderstown", "Rhode Island", "RI"],
"02875": ["Shannock", "Rhode Island", "RI"],
"02876": ["Slatersville", "Rhode Island", "RI"],
"02878": ["Tiverton", "Rhode Island", "RI"],
"02879": ["Wakefield", "Rhode Island", "RI"],
"02881": ["Kingston", "Rhode Island", "RI"],
"02882": ["Narragansett", "Rhode Island", "RI"],
"02885": ["Warren", "Rhode Island", "RI"],
"02886": ["Warwick", "Rhode Island", "RI"],
"02888": ["Warwick", "Rhode Island", "RI"],
"02889": ["Warwick", "Rhode Island", "RI"],
"02891": ["Westerly", "Rhode Island", "RI"],
"02892": ["West Kingston", "Rhode Island", "RI"],
"02893": ["West Warwick", "Rhode Island", "RI"],
"02894": ["Wood River Junction", "Rhode Island", "RI"],
"02895": ["Woonsocket", "Rhode Island", "RI"],
"02896": ["North Smithfield", "Rhode Island", "RI"],
"02898": ["Wyoming", "Rhode Island", "RI"],
"02903": ["Providence", "Rhode Island", "RI"],
"02904": ["Providence", "Rhode Island", "RI"],
"02905": ["Providence", "Rhode Island", "RI"],
"02906": ["Providence", "Rhode Island", "RI"],
"02907": ["Providence", "Rhode Island", "RI"],
"02908": ["Providence", "Rhode Island", "RI"],
"02909": ["Providence", "Rhode Island", "RI"],
"02910": ["Cranston", "Rhode Island", "RI"],
"02911": ["North Providence", "Rhode Island", "RI"],
"02912": ["Providence", "Rhode Island", "RI"],
"02914": ["East Providence", "Rhode Island", "RI"],
"02915": ["Riverside", "Rhode Island", "RI"],
"02916": ["Rumford", "Rhode Island", "RI"],
"02917": ["Smithfield", "Rhode Island", "RI"],
"02918": ["Providence", "Rhode Island", "RI"],
"02919": ["Johnston", "Rhode Island", "RI"],
"02920": ["Cranston", "Rhode Island", "RI"],
"02921": ["Cranston", "Rhode Island", "RI"],
"03031": ["Amherst", "New Hampshire", "NH"],
"03032": ["Auburn", "New Hampshire", "NH"],
"03033": ["Brookline", "New Hampshire", "NH"],
"03034": ["Candia", "New Hampshire", "NH"],
"03036": ["Chester", "New Hampshire", "NH"],
"03037": ["Deerfield", "New Hampshire", "NH"],
"03038": ["Derry", "New Hampshire", "NH"],
"03042": ["Epping", "New Hampshire", "NH"],
"03043": ["Francestown", "New Hampshire", "NH"],
"03044": ["Fremont", "New Hampshire", "NH"],
"03045": ["Goffstown", "New Hampshire", "NH"],
"03046": ["Dunbarton", "New Hampshire", "NH"],
"03047": ["Greenfield", "New Hampshire", "NH"],
"03048": ["Greenville", "New Hampshire", "NH"],
"03049": ["Hollis", "New Hampshire", "NH"],
"03051": ["Hudson", "New Hampshire", "NH"],
"03052": ["Litchfield", "New Hampshire", "NH"],
"03053": ["Londonderry", "New Hampshire", "NH"],
"03054": ["Merrimack", "New Hampshire", "NH"],
"03055": ["Milford", "New Hampshire", "NH"],
"03057": ["Mont Vernon", "New Hampshire", "NH"],
"03060": ["Nashua", "New Hampshire", "NH"],
"03062": ["Nashua", "New Hampshire", "NH"],
"03063": ["Nashua", "New Hampshire", "NH"],
"03064": ["Nashua", "New Hampshire", "NH"],
"03070": ["New Boston", "New Hampshire", "NH"],
"03071": ["New Ipswich", "New Hampshire", "NH"],
"03076": ["Pelham", "New Hampshire", "NH"],
"03077": ["Raymond", "New Hampshire", "NH"],
"03079": ["Salem", "New Hampshire", "NH"],
"03082": ["Lyndeborough", "New Hampshire", "NH"],
"03084": ["Temple", "New Hampshire", "NH"],
"03086": ["Wilton", "New Hampshire", "NH"],
"03087": ["Windham", "New Hampshire", "NH"],
"03101": ["Manchester", "New Hampshire", "NH"],
"03102": ["Manchester", "New Hampshire", "NH"],
"03103": ["Manchester", "New Hampshire", "NH"],
"03104": ["Manchester", "New Hampshire", "NH"],
"03106": ["Hooksett", "New Hampshire", "NH"],
"03109": ["Manchester", "New Hampshire", "NH"],
"03110": ["Bedford", "New Hampshire", "NH"],
"03215": ["Waterville Valley", "New Hampshire", "NH"],
"03216": ["Andover", "New Hampshire", "NH"],
"03217": ["Ashland", "New Hampshire", "NH"],
"03218": ["Barnstead", "New Hampshire", "NH"],
"03220": ["Belmont", "New Hampshire", "NH"],
"03221": ["Bradford", "New Hampshire", "NH"],
"03222": ["Bristol", "New Hampshire", "NH"],
"03223": ["Campton", "New Hampshire", "NH"],
"03224": ["Canterbury", "New Hampshire", "NH"],
"03225": ["Center Barnstead", "New Hampshire", "NH"],
"03226": ["Center Harbor", "New Hampshire", "NH"],
"03227": ["Center Sandwich", "New Hampshire", "NH"],
"03229": ["Contoocook", "New Hampshire", "NH"],
"03230": ["Danbury", "New Hampshire", "NH"],
"03231": ["East Andover", "New Hampshire", "NH"],
"03233": ["Elkins", "New Hampshire", "NH"],
"03234": ["Epsom", "New Hampshire", "NH"],
"03235": ["Franklin", "New Hampshire", "NH"],
"03237": ["Gilmanton", "New Hampshire", "NH"],
"03238": ["Glencliff", "New Hampshire", "NH"],
"03240": ["Grafton", "New Hampshire", "NH"],
"03241": ["Hebron", "New Hampshire", "NH"],
"03242": ["Henniker", "New Hampshire", "NH"],
"03243": ["Hill", "New Hampshire", "NH"],
"03244": ["Hillsborough", "New Hampshire", "NH"],
"03245": ["Holderness", "New Hampshire", "NH"],
"03246": ["Laconia", "New Hampshire", "NH"],
"03249": ["Gilford", "New Hampshire", "NH"],
"03251": ["Lincoln", "New Hampshire", "NH"],
"03253": ["Meredith", "New Hampshire", "NH"],
"03254": ["Moultonborough", "New Hampshire", "NH"],
"03255": ["Newbury", "New Hampshire", "NH"],
"03256": ["New Hampton", "New Hampshire", "NH"],
"03257": ["New London", "New Hampshire", "NH"],
"03258": ["Chichester", "New Hampshire", "NH"],
"03259": ["North Sandwich", "New Hampshire", "NH"],
"03260": ["North Sutton", "New Hampshire", "NH"],
"03261": ["Northwood", "New Hampshire", "NH"],
"03262": ["North Woodstock", "New Hampshire", "NH"],
"03263": ["Pittsfield", "New Hampshire", "NH"],
"03264": ["Plymouth", "New Hampshire", "NH"],
"03266": ["Rumney", "New Hampshire", "NH"],
"03268": ["Salisbury", "New Hampshire", "NH"],
"03269": ["Sanbornton", "New Hampshire", "NH"],
"03273": ["South Sutton", "New Hampshire", "NH"],
"03275": ["Suncook", "New Hampshire", "NH"],
"03276": ["Tilton", "New Hampshire", "NH"],
"03278": ["Warner", "New Hampshire", "NH"],
"03279": ["Warren", "New Hampshire", "NH"],
"03280": ["Washington", "New Hampshire", "NH"],
"03281": ["Weare", "New Hampshire", "NH"],
"03282": ["Wentworth", "New Hampshire", "NH"],
"03284": ["Springfield", "New Hampshire", "NH"],
"03285": ["Thornton", "New Hampshire", "NH"],
"03287": ["Wilmot", "New Hampshire", "NH"],
"03290": ["Nottingham", "New Hampshire", "NH"],
"03291": ["West Nottingham", "New Hampshire", "NH"],
"03293": ["Woodstock", "New Hampshire", "NH"],
"03301": ["Concord", "New Hampshire", "NH"],
"03303": ["Concord", "New Hampshire", "NH"],
"03304": ["Bow", "New Hampshire", "NH"],
"03307": ["Loudon", "New Hampshire", "NH"],
"03431": ["Keene", "New Hampshire", "NH"],
"03440": ["Antrim", "New Hampshire", "NH"],
"03441": ["Ashuelot", "New Hampshire", "NH"],
"03442": ["Bennington", "New Hampshire", "NH"],
"03443": ["Chesterfield", "New Hampshire", "NH"],
"03444": ["Dublin", "New Hampshire", "NH"],
"03445": ["Sullivan", "New Hampshire", "NH"],
"03446": ["Swanzey", "New Hampshire", "NH"],
"03447": ["Fitzwilliam", "New Hampshire", "NH"],
"03448": ["Gilsum", "New Hampshire", "NH"],
"03449": ["Hancock", "New Hampshire", "NH"],
"03450": ["Harrisville", "New Hampshire", "NH"],
"03451": ["Hinsdale", "New Hampshire", "NH"],
"03452": ["Jaffrey", "New Hampshire", "NH"],
"03455": ["Marlborough", "New Hampshire", "NH"],
"03456": ["Marlow", "New Hampshire", "NH"],
"03457": ["Nelson", "New Hampshire", "NH"],
"03458": ["Peterborough", "New Hampshire", "NH"],
"03461": ["Rindge", "New Hampshire", "NH"],
"03462": ["Spofford", "New Hampshire", "NH"],
"03464": ["Stoddard", "New Hampshire", "NH"],
"03465": ["Troy", "New Hampshire", "NH"],
"03466": ["West Chesterfield", "New Hampshire", "NH"],
"03467": ["Westmoreland", "New Hampshire", "NH"],
"03470": ["Winchester", "New Hampshire", "NH"],
"03561": ["Littleton", "New Hampshire", "NH"],
"03570": ["Berlin", "New Hampshire", "NH"],
"03574": ["Bethlehem", "New Hampshire", "NH"],
"03575": ["Bretton Woods", "New Hampshire", "NH"],
"03576": ["Colebrook", "New Hampshire", "NH"],
"03579": ["Errol", "New Hampshire", "NH"],
"03580": ["Franconia", "New Hampshire", "NH"],
"03581": ["Gorham", "New Hampshire", "NH"],
"03582": ["Groveton", "New Hampshire", "NH"],
"03583": ["Jefferson", "New Hampshire", "NH"],
"03584": ["Lancaster", "New Hampshire", "NH"],
"03585": ["Lisbon", "New Hampshire", "NH"],
"03586": ["Sugar Hill", "New Hampshire", "NH"],
"03588": ["Milan", "New Hampshire", "NH"],
"03590": ["North Stratford", "New Hampshire", "NH"],
"03592": ["Pittsburg", "New Hampshire", "NH"],
"03593": ["Randolph", "New Hampshire", "NH"],
"03595": ["Twin Mountain", "New Hampshire", "NH"],
"03597": ["West Stewartstown", "New Hampshire", "NH"],
"03598": ["Whitefield", "New Hampshire", "NH"],
"03601": ["Acworth", "New Hampshire", "NH"],
"03602": ["Alstead", "New Hampshire", "NH"],
"03603": ["Charlestown", "New Hampshire", "NH"],
"03604": ["Drewsville", "New Hampshire", "NH"],
"03605": ["Lempster", "New Hampshire", "NH"],
"03607": ["South Acworth", "New Hampshire", "NH"],
"03608": ["Walpole", "New Hampshire", "NH"],
"03609": ["North Walpole", "New Hampshire", "NH"],
"03740": ["Bath", "New Hampshire", "NH"],
"03741": ["Canaan", "New Hampshire", "NH"],
"03743": ["Claremont", "New Hampshire", "NH"],
"03745": ["Cornish", "New Hampshire", "NH"],
"03748": ["Enfield", "New Hampshire", "NH"],
"03750": ["Etna", "New Hampshire", "NH"],
"03751": ["Georges Mills", "New Hampshire", "NH"],
"03752": ["Goshen", "New Hampshire", "NH"],
"03753": ["Grantham", "New Hampshire", "NH"],
"03755": ["Hanover", "New Hampshire", "NH"],
"03765": ["Haverhill", "New Hampshire", "NH"],
"03766": ["Lebanon", "New Hampshire", "NH"],
"03768": ["Lyme", "New Hampshire", "NH"],
"03770": ["Meriden", "New Hampshire", "NH"],
"03771": ["Monroe", "New Hampshire", "NH"],
"03773": ["Newport", "New Hampshire", "NH"],
"03774": ["North Haverhill", "New Hampshire", "NH"],
"03777": ["Orford", "New Hampshire", "NH"],
"03779": ["Piermont", "New Hampshire", "NH"],
"03780": ["Pike", "New Hampshire", "NH"],
"03781": ["Plainfield", "New Hampshire", "NH"],
"03782": ["Sunapee", "New Hampshire", "NH"],
"03784": ["West Lebanon", "New Hampshire", "NH"],
"03785": ["Woodsville", "New Hampshire", "NH"],
"03801": ["Portsmouth", "New Hampshire", "NH"],
"03809": ["Alton", "New Hampshire", "NH"],
"03810": ["Alton Bay", "New Hampshire", "NH"],
"03811": ["Atkinson", "New Hampshire", "NH"],
"03812": ["Bartlett", "New Hampshire", "NH"],
"03813": ["Center Conway", "New Hampshire", "NH"],
"03814": ["Center Ossipee", "New Hampshire", "NH"],
"03816": ["Center Tuftonboro", "New Hampshire", "NH"],
"03817": ["Chocorua", "New Hampshire", "NH"],
"03818": ["Conway", "New Hampshire", "NH"],
"03819": ["Danville", "New Hampshire", "NH"],
"03820": ["Dover", "New Hampshire", "NH"],
"03823": ["Madbury", "New Hampshire", "NH"],
"03824": ["Durham", "New Hampshire", "NH"],
"03825": ["Barrington", "New Hampshire", "NH"],
"03826": ["East Hampstead", "New Hampshire", "NH"],
"03827": ["East Kingston", "New Hampshire", "NH"],
"03830": ["East Wakefield", "New Hampshire", "NH"],
"03832": ["Eaton Center", "New Hampshire", "NH"],
"03833": ["Exeter", "New Hampshire", "NH"],
"03835": ["Farmington", "New Hampshire", "NH"],
"03836": ["Freedom", "New Hampshire", "NH"],
"03837": ["Gilmanton Iron Works", "New Hampshire", "NH"],
"03838": ["Glen", "New Hampshire", "NH"],
"03839": ["Rochester", "New Hampshire", "NH"],
"03840": ["Greenland", "New Hampshire", "NH"],
"03841": ["Hampstead", "New Hampshire", "NH"],
"03842": ["Hampton", "New Hampshire", "NH"],
"03844": ["Hampton Falls", "New Hampshire", "NH"],
"03845": ["Intervale", "New Hampshire", "NH"],
"03846": ["Jackson", "New Hampshire", "NH"],
"03847": ["Kearsarge", "New Hampshire", "NH"],
"03848": ["Kingston", "New Hampshire", "NH"],
"03849": ["Madison", "New Hampshire", "NH"],
"03850": ["Melvin Village", "New Hampshire", "NH"],
"03851": ["Milton", "New Hampshire", "NH"],
"03852": ["Milton Mills", "New Hampshire", "NH"],
"03853": ["Mirror Lake", "New Hampshire", "NH"],
"03854": ["New Castle", "New Hampshire", "NH"],
"03855": ["New Durham", "New Hampshire", "NH"],
"03856": ["Newfields", "New Hampshire", "NH"],
"03857": ["Newmarket", "New Hampshire", "NH"],
"03858": ["Newton", "New Hampshire", "NH"],
"03860": ["North Conway", "New Hampshire", "NH"],
"03861": ["Lee", "New Hampshire", "NH"],
"03862": ["North Hampton", "New Hampshire", "NH"],
"03864": ["Ossipee", "New Hampshire", "NH"],
"03865": ["Plaistow", "New Hampshire", "NH"],
"03867": ["Rochester", "New Hampshire", "NH"],
"03868": ["Rochester", "New Hampshire", "NH"],
"03869": ["Rollinsford", "New Hampshire", "NH"],
"03870": ["Rye", "New Hampshire", "NH"],
"03871": ["Rye Beach", "New Hampshire", "NH"],
"03872": ["Sanbornville", "New Hampshire", "NH"],
"03873": ["Sandown", "New Hampshire", "NH"],
"03874": ["Seabrook", "New Hampshire", "NH"],
"03875": ["Silver Lake", "New Hampshire", "NH"],
"03878": ["Somersworth", "New Hampshire", "NH"],
"03882": ["Effingham", "New Hampshire", "NH"],
"03883": ["South Tamworth", "New Hampshire", "NH"],
"03884": ["Strafford", "New Hampshire", "NH"],
"03885": ["Stratham", "New Hampshire", "NH"],
"03886": ["Tamworth", "New Hampshire", "NH"],
"03887": ["Union", "New Hampshire", "NH"],
"03890": ["West Ossipee", "New Hampshire", "NH"],
"03894": ["Wolfeboro", "New Hampshire", "NH"],
"03897": ["Wonalancet", "New Hampshire", "NH"],
"03901": ["Berwick", "Maine", "ME"],
"03902": ["Cape Neddick", "Maine", "ME"],
"03903": ["Eliot", "Maine", "ME"],
"03904": ["Kittery", "Maine", "ME"],
"03905": ["Kittery Point", "Maine", "ME"],
"03906": ["North Berwick", "Maine", "ME"],
"03907": ["Ogunquit", "Maine", "ME"],
"03908": ["South Berwick", "Maine", "ME"],
"03909": ["York", "Maine", "ME"],
"03910": ["York Beach", "Maine", "ME"],
"03911": ["York Harbor", "Maine", "ME"],
"04001": ["Acton", "Maine", "ME"],
"04002": ["Alfred", "Maine", "ME"],
"04003": ["Bailey Island", "Maine", "ME"],
"04005": ["Biddeford", "Maine", "ME"],
"04006": ["Biddeford Pool", "Maine", "ME"],
"04008": ["Bowdoinham", "Maine", "ME"],
"04009": ["Bridgton", "Maine", "ME"],
"04010": ["Brownfield", "Maine", "ME"],
"04011": ["Brunswick", "Maine", "ME"],
"04015": ["Casco", "Maine", "ME"],
"04017": ["Chebeague Island", "Maine", "ME"],
"04019": ["Cliff Island", "Maine", "ME"],
"04020": ["Cornish", "Maine", "ME"],
"04021": ["Cumberland Center", "Maine", "ME"],
"04022": ["Denmark", "Maine", "ME"],
"04024": ["East Baldwin", "Maine", "ME"],
"04027": ["Lebanon", "Maine", "ME"],
"04029": ["Sebago", "Maine", "ME"],
"04030": ["East Waterboro", "Maine", "ME"],
"04032": ["Freeport", "Maine", "ME"],
"04037": ["Fryeburg", "Maine", "ME"],
"04038": ["Gorham", "Maine", "ME"],
"04039": ["Gray", "Maine", "ME"],
"04040": ["Harrison", "Maine", "ME"],
"04041": ["Hiram", "Maine", "ME"],
"04042": ["Hollis Center", "Maine", "ME"],
"04043": ["Kennebunk", "Maine", "ME"],
"04046": ["Kennebunkport", "Maine", "ME"],
"04047": ["Parsonsfield", "Maine", "ME"],
"04048": ["Limerick", "Maine", "ME"],
"04049": ["Limington", "Maine", "ME"],
"04050": ["Long Island", "Maine", "ME"],
"04051": ["Lovell", "Maine", "ME"],
"04055": ["Naples", "Maine", "ME"],
"04056": ["Newfield", "Maine", "ME"],
"04057": ["North Bridgton", "Maine", "ME"],
"04061": ["North Waterboro", "Maine", "ME"],
"04062": ["Windham", "Maine", "ME"],
"04063": ["Ocean Park", "Maine", "ME"],
"04064": ["Old Orchard Beach", "Maine", "ME"],
"04066": ["Orrs Island", "Maine", "ME"],
"04068": ["Porter", "Maine", "ME"],
"04069": ["Pownal", "Maine", "ME"],
"04071": ["Raymond", "Maine", "ME"],
"04072": ["Saco", "Maine", "ME"],
"04073": ["Sanford", "Maine", "ME"],
"04074": ["Scarborough", "Maine", "ME"],
"04076": ["Shapleigh", "Maine", "ME"],
"04079": ["Harpswell", "Maine", "ME"],
"04083": ["Springvale", "Maine", "ME"],
"04084": ["Standish", "Maine", "ME"],
"04085": ["Steep Falls", "Maine", "ME"],
"04086": ["Topsham", "Maine", "ME"],
"04087": ["Waterboro", "Maine", "ME"],
"04088": ["Waterford", "Maine", "ME"],
"04090": ["Wells", "Maine", "ME"],
"04091": ["West Baldwin", "Maine", "ME"],
"04092": ["Westbrook", "Maine", "ME"],
"04093": ["Buxton", "Maine", "ME"],
"04095": ["West Newfield", "Maine", "ME"],
"04096": ["Yarmouth", "Maine", "ME"],
"04097": ["North Yarmouth", "Maine", "ME"],
"04101": ["Portland", "Maine", "ME"],
"04102": ["Portland", "Maine", "ME"],
"04103": ["Portland", "Maine", "ME"],
"04105": ["Falmouth", "Maine", "ME"],
"04106": ["South Portland", "Maine", "ME"],
"04107": ["Cape Elizabeth", "Maine", "ME"],
"04108": ["Peaks Island", "Maine", "ME"],
"04109": ["Portland", "Maine", "ME"],
"04110": ["Cumberland Foreside", "Maine", "ME"],
"04210": ["Auburn", "Maine", "ME"],
"04216": ["Andover", "Maine", "ME"],
"04217": ["Bethel", "Maine", "ME"],
"04219": ["Bryant Pond", "Maine", "ME"],
"04220": ["Buckfield", "Maine", "ME"],
"04221": ["Canton", "Maine", "ME"],
"04222": ["Durham", "Maine", "ME"],
"04224": ["Dixfield", "Maine", "ME"],
"04226": ["East Andover", "Maine", "ME"],
"04227": ["East Dixfield", "Maine", "ME"],
"04231": ["Stoneham", "Maine", "ME"],
"04234": ["East Wilton", "Maine", "ME"],
"04236": ["Greene", "Maine", "ME"],
"04237": ["Hanover", "Maine", "ME"],
"04238": ["Hebron", "Maine", "ME"],
"04239": ["Jay", "Maine", "ME"],
"04240": ["Lewiston", "Maine", "ME"],
"04250": ["Lisbon", "Maine", "ME"],
"04252": ["Lisbon Falls", "Maine", "ME"],
"04253": ["Livermore", "Maine", "ME"],
"04254": ["Livermore Falls", "Maine", "ME"],
"04255": ["Greenwood", "Maine", "ME"],
"04256": ["Mechanic Falls", "Maine", "ME"],
"04257": ["Mexico", "Maine", "ME"],
"04258": ["Minot", "Maine", "ME"],
"04259": ["Monmouth", "Maine", "ME"],
"04260": ["New Gloucester", "Maine", "ME"],
"04261": ["Newry", "Maine", "ME"],
"04263": ["Leeds", "Maine", "ME"],
"04265": ["North Monmouth", "Maine", "ME"],
"04267": ["North Waterford", "Maine", "ME"],
"04268": ["Norway", "Maine", "ME"],
"04270": ["Oxford", "Maine", "ME"],
"04271": ["Paris", "Maine", "ME"],
"04274": ["Poland", "Maine", "ME"],
"04275": ["Roxbury", "Maine", "ME"],
"04276": ["Rumford", "Maine", "ME"],
"04280": ["Sabattus", "Maine", "ME"],
"04281": ["South Paris", "Maine", "ME"],
"04282": ["Turner", "Maine", "ME"],
"04284": ["Wayne", "Maine", "ME"],
"04285": ["Weld", "Maine", "ME"],
"04286": ["West Bethel", "Maine", "ME"],
"04287": ["Bowdoin", "Maine", "ME"],
"04289": ["West Paris", "Maine", "ME"],
"04290": ["Peru", "Maine", "ME"],
"04292": ["Sumner", "Maine", "ME"],
"04294": ["Wilton", "Maine", "ME"],
"04330": ["Augusta", "Maine", "ME"],
"04341": ["Coopers Mills", "Maine", "ME"],
"04342": ["Dresden", "Maine", "ME"],
"04344": ["Farmingdale", "Maine", "ME"],
"04345": ["Gardiner", "Maine", "ME"],
"04346": ["Randolph", "Maine", "ME"],
"04347": ["Hallowell", "Maine", "ME"],
"04348": ["Jefferson", "Maine", "ME"],
"04349": ["Kents Hill", "Maine", "ME"],
"04350": ["Litchfield", "Maine", "ME"],
"04351": ["Manchester", "Maine", "ME"],
"04352": ["Mount Vernon", "Maine", "ME"],
"04353": ["Whitefield", "Maine", "ME"],
"04354": ["Palermo", "Maine", "ME"],
"04355": ["Readfield", "Maine", "ME"],
"04357": ["Richmond", "Maine", "ME"],
"04358": ["South China", "Maine", "ME"],
"04359": ["South Gardiner", "Maine", "ME"],
"04360": ["Vienna", "Maine", "ME"],
"04363": ["Windsor", "Maine", "ME"],
"04364": ["Winthrop", "Maine", "ME"],
"04401": ["Bangor", "Maine", "ME"],
"04406": ["Abbot", "Maine", "ME"],
"04408": ["Aurora", "Maine", "ME"],
"04410": ["Bradford", "Maine", "ME"],
"04411": ["Bradley", "Maine", "ME"],
"04412": ["Brewer", "Maine", "ME"],
"04413": ["Brookton", "Maine", "ME"],
"04414": ["Brownville", "Maine", "ME"],
"04415": ["Brownville Junction", "Maine", "ME"],
"04416": ["Bucksport", "Maine", "ME"],
"04417": ["Burlington", "Maine", "ME"],
"04418": ["Greenbush", "Maine", "ME"],
"04419": ["Carmel", "Maine", "ME"],
"04421": ["Castine", "Maine", "ME"],
"04422": ["Charleston", "Maine", "ME"],
"04424": ["Danforth", "Maine", "ME"],
"04426": ["Dover Foxcroft", "Maine", "ME"],
"04427": ["Corinth", "Maine", "ME"],
"04428": ["Eddington", "Maine", "ME"],
"04429": ["Holden", "Maine", "ME"],
"04430": ["East Millinocket", "Maine", "ME"],
"04431": ["East Orland", "Maine", "ME"],
"04434": ["Etna", "Maine", "ME"],
"04435": ["Exeter", "Maine", "ME"],
"04438": ["Frankfort", "Maine", "ME"],
"04441": ["Greenville", "Maine", "ME"],
"04442": ["Greenville Junction", "Maine", "ME"],
"04443": ["Guilford", "Maine", "ME"],
"04444": ["Hampden", "Maine", "ME"],
"04448": ["Howland", "Maine", "ME"],
"04449": ["Hudson", "Maine", "ME"],
"04450": ["Kenduskeag", "Maine", "ME"],
"04451": ["Kingman", "Maine", "ME"],
"04453": ["Lagrange", "Maine", "ME"],
"04454": ["Lambert Lake", "Maine", "ME"],
"04455": ["Lee", "Maine", "ME"],
"04456": ["Levant", "Maine", "ME"],
"04457": ["Lincoln", "Maine", "ME"],
"04459": ["Mattawamkeag", "Maine", "ME"],
"04460": ["Medway", "Maine", "ME"],
"04461": ["Milford", "Maine", "ME"],
"04462": ["Millinocket", "Maine", "ME"],
"04463": ["Milo", "Maine", "ME"],
"04464": ["Monson", "Maine", "ME"],
"04468": ["Old Town", "Maine", "ME"],
"04469": ["Orono", "Maine", "ME"],
"04471": ["Orient", "Maine", "ME"],
"04472": ["Orland", "Maine", "ME"],
"04473": ["Orono", "Maine", "ME"],
"04474": ["Orrington", "Maine", "ME"],
"04475": ["Passadumkeag", "Maine", "ME"],
"04476": ["Penobscot", "Maine", "ME"],
"04478": ["Rockwood", "Maine", "ME"],
"04479": ["Sangerville", "Maine", "ME"],
"04481": ["Sebec", "Maine", "ME"],
"04485": ["Shirley Mills", "Maine", "ME"],
"04487": ["Springfield", "Maine", "ME"],
"04488": ["Stetson", "Maine", "ME"],
"04489": ["Stillwater", "Maine", "ME"],
"04490": ["Topsfield", "Maine", "ME"],
"04491": ["Vanceboro", "Maine", "ME"],
"04492": ["Waite", "Maine", "ME"],
"04493": ["West Enfield", "Maine", "ME"],
"04495": ["Winn", "Maine", "ME"],
"04496": ["Winterport", "Maine", "ME"],
"04497": ["Wytopitlock", "Maine", "ME"],
"04530": ["Bath", "Maine", "ME"],
"04535": ["Alna", "Maine", "ME"],
"04537": ["Boothbay", "Maine", "ME"],
"04538": ["Boothbay Harbor", "Maine", "ME"],
"04539": ["Bristol", "Maine", "ME"],
"04541": ["Chamberlain", "Maine", "ME"],
"04543": ["Damariscotta", "Maine", "ME"],
"04544": ["East Boothbay", "Maine", "ME"],
"04547": ["Friendship", "Maine", "ME"],
"04548": ["Georgetown", "Maine", "ME"],
"04551": ["Bremen", "Maine", "ME"],
"04553": ["Newcastle", "Maine", "ME"],
"04554": ["New Harbor", "Maine", "ME"],
"04555": ["Nobleboro", "Maine", "ME"],
"04556": ["Edgecomb", "Maine", "ME"],
"04558": ["Pemaquid", "Maine", "ME"],
"04562": ["Phippsburg", "Maine", "ME"],
"04563": ["Cushing", "Maine", "ME"],
"04564": ["Round Pond", "Maine", "ME"],
"04568": ["South Bristol", "Maine", "ME"],
"04570": ["Squirrel Island", "Maine", "ME"],
"04571": ["Trevett", "Maine", "ME"],
"04572": ["Waldoboro", "Maine", "ME"],
"04573": ["Walpole", "Maine", "ME"],
"04574": ["Washington", "Maine", "ME"],
"04575": ["West Boothbay Harbor", "Maine", "ME"],
"04576": ["Southport", "Maine", "ME"],
"04578": ["Wiscasset", "Maine", "ME"],
"04579": ["Woolwich", "Maine", "ME"],
"04605": ["Ellsworth", "Maine", "ME"],
"04606": ["Addison", "Maine", "ME"],
"04607": ["Gouldsboro", "Maine", "ME"],
"04609": ["Bar Harbor", "Maine", "ME"],
"04611": ["Beals", "Maine", "ME"],
"04612": ["Bernard", "Maine", "ME"],
"04613": ["Birch Harbor", "Maine", "ME"],
"04614": ["Blue Hill", "Maine", "ME"],
"04616": ["Brooklin", "Maine", "ME"],
"04617": ["Brooksville", "Maine", "ME"],
"04619": ["Calais", "Maine", "ME"],
"04622": ["Cherryfield", "Maine", "ME"],
"04623": ["Columbia Falls", "Maine", "ME"],
"04624": ["Corea", "Maine", "ME"],
"04625": ["Cranberry Isles", "Maine", "ME"],
"04626": ["Cutler", "Maine", "ME"],
"04627": ["Deer Isle", "Maine", "ME"],
"04628": ["Dennysville", "Maine", "ME"],
"04630": ["East Machias", "Maine", "ME"],
"04631": ["Eastport", "Maine", "ME"],
"04634": ["Franklin", "Maine", "ME"],
"04635": ["Frenchboro", "Maine", "ME"],
"04640": ["Hancock", "Maine", "ME"],
"04642": ["Harborside", "Maine", "ME"],
"04643": ["Harrington", "Maine", "ME"],
"04645": ["Isle Au Haut", "Maine", "ME"],
"04646": ["Islesford", "Maine", "ME"],
"04648": ["Jonesboro", "Maine", "ME"],
"04649": ["Jonesport", "Maine", "ME"],
"04650": ["Little Deer Isle", "Maine", "ME"],
"04652": ["Lubec", "Maine", "ME"],
"04653": ["Bass Harbor", "Maine", "ME"],
"04654": ["Machias", "Maine", "ME"],
"04655": ["Machiasport", "Maine", "ME"],
"04657": ["Meddybemps", "Maine", "ME"],
"04658": ["Milbridge", "Maine", "ME"],
"04660": ["Mount Desert", "Maine", "ME"],
"04662": ["Northeast Harbor", "Maine", "ME"],
"04664": ["Sullivan", "Maine", "ME"],
"04666": ["Pembroke", "Maine", "ME"],
"04667": ["Perry", "Maine", "ME"],
"04668": ["Princeton", "Maine", "ME"],
"04669": ["Prospect Harbor", "Maine", "ME"],
"04671": ["Robbinston", "Maine", "ME"],
"04673": ["Sargentville", "Maine", "ME"],
"04674": ["Seal Cove", "Maine", "ME"],
"04675": ["Seal Harbor", "Maine", "ME"],
"04676": ["Sedgwick", "Maine", "ME"],
"04677": ["Sorrento", "Maine", "ME"],
"04679": ["Southwest Harbor", "Maine", "ME"],
"04680": ["Steuben", "Maine", "ME"],
"04681": ["Stonington", "Maine", "ME"],
"04683": ["Sunset", "Maine", "ME"],
"04684": ["Surry", "Maine", "ME"],
"04685": ["Swans Island", "Maine", "ME"],
"04686": ["Wesley", "Maine", "ME"],
"04691": ["Whiting", "Maine", "ME"],
"04693": ["Winter Harbor", "Maine", "ME"],
"04694": ["Baileyville", "Maine", "ME"],
"04730": ["Houlton", "Maine", "ME"],
"04732": ["Ashland", "Maine", "ME"],
"04733": ["Benedicta", "Maine", "ME"],
"04734": ["Blaine", "Maine", "ME"],
"04735": ["Bridgewater", "Maine", "ME"],
"04736": ["Caribou", "Maine", "ME"],
"04737": ["Clayton Lake", "Maine", "ME"],
"04739": ["Eagle Lake", "Maine", "ME"],
"04740": ["Easton", "Maine", "ME"],
"04742": ["Fort Fairfield", "Maine", "ME"],
"04743": ["Fort Kent", "Maine", "ME"],
"04745": ["Frenchville", "Maine", "ME"],
"04746": ["Grand Isle", "Maine", "ME"],
"04747": ["Island Falls", "Maine", "ME"],
"04750": ["Limestone", "Maine", "ME"],
"04756": ["Madawaska", "Maine", "ME"],
"04757": ["Mapleton", "Maine", "ME"],
"04758": ["Mars Hill", "Maine", "ME"],
"04760": ["Monticello", "Maine", "ME"],
"04761": ["New Limerick", "Maine", "ME"],
"04762": ["New Sweden", "Maine", "ME"],
"04763": ["Oakfield", "Maine", "ME"],
"04764": ["Oxbow", "Maine", "ME"],
"04765": ["Patten", "Maine", "ME"],
"04766": ["Perham", "Maine", "ME"],
"04768": ["Portage", "Maine", "ME"],
"04769": ["Presque Isle", "Maine", "ME"],
"04772": ["Saint Agatha", "Maine", "ME"],
"04773": ["Saint David", "Maine", "ME"],
"04774": ["Saint Francis", "Maine", "ME"],
"04776": ["Sherman", "Maine", "ME"],
"04777": ["Stacyville", "Maine", "ME"],
"04779": ["Sinclair", "Maine", "ME"],
"04780": ["Smyrna Mills", "Maine", "ME"],
"04781": ["Wallagrass", "Maine", "ME"],
"04783": ["Stockholm", "Maine", "ME"],
"04785": ["Van Buren", "Maine", "ME"],
"04786": ["Washburn", "Maine", "ME"],
"04787": ["Westfield", "Maine", "ME"],
"04841": ["Rockland", "Maine", "ME"],
"04843": ["Camden", "Maine", "ME"],
"04847": ["Hope", "Maine", "ME"],
"04848": ["Islesboro", "Maine", "ME"],
"04849": ["Lincolnville", "Maine", "ME"],
"04851": ["Matinicus", "Maine", "ME"],
"04852": ["Monhegan", "Maine", "ME"],
"04853": ["North Haven", "Maine", "ME"],
"04854": ["Owls Head", "Maine", "ME"],
"04855": ["Port Clyde", "Maine", "ME"],
"04856": ["Rockport", "Maine", "ME"],
"04858": ["South Thomaston", "Maine", "ME"],
"04859": ["Spruce Head", "Maine", "ME"],
"04860": ["Tenants Harbor", "Maine", "ME"],
"04861": ["Thomaston", "Maine", "ME"],
"04862": ["Union", "Maine", "ME"],
"04863": ["Vinalhaven", "Maine", "ME"],
"04864": ["Warren", "Maine", "ME"],
"04901": ["Waterville", "Maine", "ME"],
"04910": ["Albion", "Maine", "ME"],
"04911": ["Anson", "Maine", "ME"],
"04912": ["Athens", "Maine", "ME"],
"04915": ["Belfast", "Maine", "ME"],
"04917": ["Belgrade", "Maine", "ME"],
"04918": ["Belgrade Lakes", "Maine", "ME"],
"04920": ["Bingham", "Maine", "ME"],
"04921": ["Brooks", "Maine", "ME"],
"04922": ["Burnham", "Maine", "ME"],
"04923": ["Cambridge", "Maine", "ME"],
"04924": ["Canaan", "Maine", "ME"],
"04925": ["Caratunk", "Maine", "ME"],
"04926": ["China Village", "Maine", "ME"],
"04927": ["Clinton", "Maine", "ME"],
"04928": ["Corinna", "Maine", "ME"],
"04929": ["Detroit", "Maine", "ME"],
"04930": ["Dexter", "Maine", "ME"],
"04932": ["Dixmont", "Maine", "ME"],
"04936": ["Eustis", "Maine", "ME"],
"04937": ["Fairfield", "Maine", "ME"],
"04938": ["Farmington", "Maine", "ME"],
"04939": ["Garland", "Maine", "ME"],
"04940": ["Farmington Falls", "Maine", "ME"],
"04941": ["Freedom", "Maine", "ME"],
"04942": ["Harmony", "Maine", "ME"],
"04943": ["Hartland", "Maine", "ME"],
"04944": ["Hinckley", "Maine", "ME"],
"04945": ["Jackman", "Maine", "ME"],
"04947": ["Kingfield", "Maine", "ME"],
"04949": ["Liberty", "Maine", "ME"],
"04950": ["Madison", "Maine", "ME"],
"04951": ["Monroe", "Maine", "ME"],
"04952": ["Morrill", "Maine", "ME"],
"04953": ["Newport", "Maine", "ME"],
"04955": ["New Sharon", "Maine", "ME"],
"04956": ["New Vineyard", "Maine", "ME"],
"04957": ["Norridgewock", "Maine", "ME"],
"04958": ["North Anson", "Maine", "ME"],
"04961": ["New Portland", "Maine", "ME"],
"04963": ["Oakland", "Maine", "ME"],
"04964": ["Oquossoc", "Maine", "ME"],
"04965": ["Palmyra", "Maine", "ME"],
"04966": ["Phillips", "Maine", "ME"],
"04967": ["Pittsfield", "Maine", "ME"],
"04969": ["Plymouth", "Maine", "ME"],
"04970": ["Rangeley", "Maine", "ME"],
"04971": ["Saint Albans", "Maine", "ME"],
"04973": ["Searsmont", "Maine", "ME"],
"04974": ["Searsport", "Maine", "ME"],
"04975": ["Shawmut", "Maine", "ME"],
"04976": ["Skowhegan", "Maine", "ME"],
"04978": ["Smithfield", "Maine", "ME"],
"04979": ["Solon", "Maine", "ME"],
"04981": ["Stockton Springs", "Maine", "ME"],
"04982": ["Stratton", "Maine", "ME"],
"04983": ["Strong", "Maine", "ME"],
"04984": ["Temple", "Maine", "ME"],
"04985": ["West Forks", "Maine", "ME"],
"04986": ["Thorndike", "Maine", "ME"],
"04987": ["Troy", "Maine", "ME"],
"04988": ["Unity", "Maine", "ME"],
"04989": ["Vassalboro", "Maine", "ME"],
"04992": ["West Farmington", "Maine", "ME"],
"05001": ["White River Junction", "Vermont", "VT"],
"05009": ["White River Junction", "Vermont", "VT"],
"05030": ["Ascutney", "Vermont", "VT"],
"05031": ["Barnard", "Vermont", "VT"],
"05032": ["Bethel", "Vermont", "VT"],
"05033": ["Bradford", "Vermont", "VT"],
"05034": ["Bridgewater", "Vermont", "VT"],
"05035": ["Bridgewater Corners", "Vermont", "VT"],
"05036": ["Brookfield", "Vermont", "VT"],
"05037": ["Brownsville", "Vermont", "VT"],
"05038": ["Chelsea", "Vermont", "VT"],
"05039": ["Corinth", "Vermont", "VT"],
"05040": ["East Corinth", "Vermont", "VT"],
"05041": ["East Randolph", "Vermont", "VT"],
"05042": ["East Ryegate", "Vermont", "VT"],
"05043": ["East Thetford", "Vermont", "VT"],
"05045": ["Fairlee", "Vermont", "VT"],
"05046": ["Groton", "Vermont", "VT"],
"05048": ["Hartland", "Vermont", "VT"],
"05050": ["McIndoe Falls", "Vermont", "VT"],
"05051": ["Newbury", "Vermont", "VT"],
"05052": ["North Hartland", "Vermont", "VT"],
"05053": ["North Pomfret", "Vermont", "VT"],
"05055": ["Norwich", "Vermont", "VT"],
"05056": ["Plymouth", "Vermont", "VT"],
"05058": ["Post Mills", "Vermont", "VT"],
"05059": ["Quechee", "Vermont", "VT"],
"05060": ["Randolph", "Vermont", "VT"],
"05061": ["Randolph Center", "Vermont", "VT"],
"05062": ["Reading", "Vermont", "VT"],
"05065": ["Sharon", "Vermont", "VT"],
"05067": ["South Pomfret", "Vermont", "VT"],
"05068": ["South Royalton", "Vermont", "VT"],
"05069": ["South Ryegate", "Vermont", "VT"],
"05070": ["South Strafford", "Vermont", "VT"],
"05071": ["South Woodstock", "Vermont", "VT"],
"05072": ["Strafford", "Vermont", "VT"],
"05073": ["Taftsville", "Vermont", "VT"],
"05075": ["Thetford Center", "Vermont", "VT"],
"05076": ["Topsham", "Vermont", "VT"],
"05077": ["Tunbridge", "Vermont", "VT"],
"05079": ["Vershire", "Vermont", "VT"],
"05081": ["Wells River", "Vermont", "VT"],
"05083": ["West Fairlee", "Vermont", "VT"],
"05084": ["West Hartford", "Vermont", "VT"],
"05086": ["West Topsham", "Vermont", "VT"],
"05088": ["Wilder", "Vermont", "VT"],
"05089": ["Windsor", "Vermont", "VT"],
"05091": ["Woodstock", "Vermont", "VT"],
"05101": ["Bellows Falls", "Vermont", "VT"],
"05141": ["Cambridgeport", "Vermont", "VT"],
"05142": ["Cavendish", "Vermont", "VT"],
"05143": ["Chester", "Vermont", "VT"],
"05146": ["Grafton", "Vermont", "VT"],
"05148": ["Londonderry", "Vermont", "VT"],
"05149": ["Ludlow", "Vermont", "VT"],
"05150": ["North Springfield", "Vermont", "VT"],
"05151": ["Perkinsville", "Vermont", "VT"],
"05152": ["Peru", "Vermont", "VT"],
"05153": ["Proctorsville", "Vermont", "VT"],
"05154": ["Saxtons River", "Vermont", "VT"],
"05155": ["South Londonderry", "Vermont", "VT"],
"05156": ["Springfield", "Vermont", "VT"],
"05158": ["Westminster", "Vermont", "VT"],
"05161": ["Weston", "Vermont", "VT"],
"05201": ["Bennington", "Vermont", "VT"],
"05250": ["Arlington", "Vermont", "VT"],
"05251": ["Dorset", "Vermont", "VT"],
"05252": ["East Arlington", "Vermont", "VT"],
"05253": ["East Dorset", "Vermont", "VT"],
"05254": ["Manchester", "Vermont", "VT"],
"05255": ["Manchester Center", "Vermont", "VT"],
"05257": ["North Bennington", "Vermont", "VT"],
"05260": ["North Pownal", "Vermont", "VT"],
"05261": ["Pownal", "Vermont", "VT"],
"05262": ["Shaftsbury", "Vermont", "VT"],
"05301": ["Brattleboro", "Vermont", "VT"],
"05340": ["Bondville", "Vermont", "VT"],
"05341": ["East Dover", "Vermont", "VT"],
"05342": ["Jacksonville", "Vermont", "VT"],
"05343": ["Jamaica", "Vermont", "VT"],
"05344": ["Marlboro", "Vermont", "VT"],
"05345": ["Newfane", "Vermont", "VT"],
"05346": ["Putney", "Vermont", "VT"],
"05350": ["Readsboro", "Vermont", "VT"],
"05351": ["South Newfane", "Vermont", "VT"],
"05352": ["Stamford", "Vermont", "VT"],
"05353": ["Townshend", "Vermont", "VT"],
"05354": ["Vernon", "Vermont", "VT"],
"05355": ["Wardsboro", "Vermont", "VT"],
"05356": ["West Dover", "Vermont", "VT"],
"05358": ["West Halifax", "Vermont", "VT"],
"05359": ["West Townshend", "Vermont", "VT"],
"05360": ["West Wardsboro", "Vermont", "VT"],
"05361": ["Whitingham", "Vermont", "VT"],
"05362": ["Williamsville", "Vermont", "VT"],
"05363": ["Wilmington", "Vermont", "VT"],
"05401": ["Burlington", "Vermont", "VT"],
"05403": ["South Burlington", "Vermont", "VT"],
"05404": ["Winooski", "Vermont", "VT"],
"05405": ["Burlington", "Vermont", "VT"],
"05408": ["Burlington", "Vermont", "VT"],
"05439": ["Colchester", "Vermont", "VT"],
"05440": ["Alburgh", "Vermont", "VT"],
"05441": ["Bakersfield", "Vermont", "VT"],
"05442": ["Belvidere Center", "Vermont", "VT"],
"05443": ["Bristol", "Vermont", "VT"],
"05444": ["Cambridge", "Vermont", "VT"],
"05445": ["Charlotte", "Vermont", "VT"],
"05446": ["Colchester", "Vermont", "VT"],
"05447": ["East Berkshire", "Vermont", "VT"],
"05448": ["East Fairfield", "Vermont", "VT"],
"05450": ["Enosburg Falls", "Vermont", "VT"],
"05452": ["Essex Junction", "Vermont", "VT"],
"05454": ["Fairfax", "Vermont", "VT"],
"05455": ["Fairfield", "Vermont", "VT"],
"05456": ["Ferrisburgh", "Vermont", "VT"],
"05457": ["Franklin", "Vermont", "VT"],
"05458": ["Grand Isle", "Vermont", "VT"],
"05459": ["Highgate Center", "Vermont", "VT"],
"05461": ["Hinesburg", "Vermont", "VT"],
"05462": ["Huntington", "Vermont", "VT"],
"05463": ["Isle La Motte", "Vermont", "VT"],
"05464": ["Jeffersonville", "Vermont", "VT"],
"05465": ["Jericho", "Vermont", "VT"],
"05468": ["Milton", "Vermont", "VT"],
"05471": ["Montgomery Center", "Vermont", "VT"],
"05472": ["New Haven", "Vermont", "VT"],
"05473": ["North Ferrisburgh", "Vermont", "VT"],
"05474": ["North Hero", "Vermont", "VT"],
"05476": ["Richford", "Vermont", "VT"],
"05477": ["Richmond", "Vermont", "VT"],
"05478": ["Saint Albans", "Vermont", "VT"],
"05481": ["Saint Albans Bay", "Vermont", "VT"],
"05482": ["Shelburne", "Vermont", "VT"],
"05483": ["Sheldon", "Vermont", "VT"],
"05485": ["Sheldon Springs", "Vermont", "VT"],
"05486": ["South Hero", "Vermont", "VT"],
"05487": ["Starksboro", "Vermont", "VT"],
"05488": ["Swanton", "Vermont", "VT"],
"05489": ["Underhill", "Vermont", "VT"],
"05491": ["Vergennes", "Vermont", "VT"],
"05492": ["Waterville", "Vermont", "VT"],
"05494": ["Westford", "Vermont", "VT"],
"05495": ["Williston", "Vermont", "VT"],
"05602": ["Montpelier", "Vermont", "VT"],
"05640": ["Adamant", "Vermont", "VT"],
"05641": ["Barre", "Vermont", "VT"],
"05647": ["Cabot", "Vermont", "VT"],
"05648": ["Calais", "Vermont", "VT"],
"05649": ["East Barre", "Vermont", "VT"],
"05650": ["East Calais", "Vermont", "VT"],
"05651": ["East Montpelier", "Vermont", "VT"],
"05652": ["Eden", "Vermont", "VT"],
"05653": ["Eden Mills", "Vermont", "VT"],
"05654": ["Graniteville", "Vermont", "VT"],
"05655": ["Hyde Park", "Vermont", "VT"],
"05656": ["Johnson", "Vermont", "VT"],
"05658": ["Marshfield", "Vermont", "VT"],
"05660": ["Moretown", "Vermont", "VT"],
"05661": ["Morrisville", "Vermont", "VT"],
"05663": ["Northfield", "Vermont", "VT"],
"05664": ["Northfield Falls", "Vermont", "VT"],
"05666": ["North Montpelier", "Vermont", "VT"],
"05667": ["Plainfield", "Vermont", "VT"],
"05669": ["Roxbury", "Vermont", "VT"],
"05670": ["South Barre", "Vermont", "VT"],
"05672": ["Stowe", "Vermont", "VT"],
"05673": ["Waitsfield", "Vermont", "VT"],
"05674": ["Warren", "Vermont", "VT"],
"05675": ["Washington", "Vermont", "VT"],
"05676": ["Waterbury", "Vermont", "VT"],
"05677": ["Waterbury Center", "Vermont", "VT"],
"05678": ["Websterville", "Vermont", "VT"],
"05679": ["Williamstown", "Vermont", "VT"],
"05680": ["Wolcott", "Vermont", "VT"],
"05681": ["Woodbury", "Vermont", "VT"],
"05682": ["Worcester", "Vermont", "VT"],
"05701": ["Rutland", "Vermont", "VT"],
"05730": ["Belmont", "Vermont", "VT"],
"05731": ["Benson", "Vermont", "VT"],
"05732": ["Bomoseen", "Vermont", "VT"],
"05733": ["Brandon", "Vermont", "VT"],
"05734": ["Bridport", "Vermont", "VT"],
"05735": ["Castleton", "Vermont", "VT"],
"05736": ["Center Rutland", "Vermont", "VT"],
"05737": ["Chittenden", "Vermont", "VT"],
"05738": ["Cuttingsville", "Vermont", "VT"],
"05739": ["Danby", "Vermont", "VT"],
"05742": ["East Wallingford", "Vermont", "VT"],
"05743": ["Fair Haven", "Vermont", "VT"],
"05744": ["Florence", "Vermont", "VT"],
"05746": ["Gaysville", "Vermont", "VT"],
"05747": ["Granville", "Vermont", "VT"],
"05748": ["Hancock", "Vermont", "VT"],
"05751": ["Killington", "Vermont", "VT"],
"05753": ["Middlebury", "Vermont", "VT"],
"05757": ["Middletown Springs", "Vermont", "VT"],
"05758": ["Mount Holly", "Vermont", "VT"],
"05759": ["North Clarendon", "Vermont", "VT"],
"05760": ["Orwell", "Vermont", "VT"],
"05761": ["Pawlet", "Vermont", "VT"],
"05762": ["Pittsfield", "Vermont", "VT"],
"05763": ["Pittsford", "Vermont", "VT"],
"05764": ["Poultney", "Vermont", "VT"],
"05765": ["Proctor", "Vermont", "VT"],
"05766": ["Ripton", "Vermont", "VT"],
"05767": ["Rochester", "Vermont", "VT"],
"05768": ["Rupert", "Vermont", "VT"],
"05769": ["Salisbury", "Vermont", "VT"],
"05770": ["Shoreham", "Vermont", "VT"],
"05772": ["Stockbridge", "Vermont", "VT"],
"05773": ["Wallingford", "Vermont", "VT"],
"05774": ["Wells", "Vermont", "VT"],
"05775": ["West Pawlet", "Vermont", "VT"],
"05776": ["West Rupert", "Vermont", "VT"],
"05777": ["West Rutland", "Vermont", "VT"],
"05778": ["Whiting", "Vermont", "VT"],
"05819": ["Saint Johnsbury", "Vermont", "VT"],
"05820": ["Albany", "Vermont", "VT"],
"05821": ["Barnet", "Vermont", "VT"],
"05822": ["Barton", "Vermont", "VT"],
"05824": ["Concord", "Vermont", "VT"],
"05825": ["Coventry", "Vermont", "VT"],
"05826": ["Craftsbury", "Vermont", "VT"],
"05827": ["Craftsbury Common", "Vermont", "VT"],
"05828": ["Danville", "Vermont", "VT"],
"05829": ["Derby", "Vermont", "VT"],
"05830": ["Derby Line", "Vermont", "VT"],
"05832": ["East Burke", "Vermont", "VT"],
"05833": ["East Charleston", "Vermont", "VT"],
"05836": ["East Hardwick", "Vermont", "VT"],
"05837": ["East Haven", "Vermont", "VT"],
"05839": ["Glover", "Vermont", "VT"],
"05840": ["Granby", "Vermont", "VT"],
"05841": ["Greensboro", "Vermont", "VT"],
"05842": ["Greensboro Bend", "Vermont", "VT"],
"05843": ["Hardwick", "Vermont", "VT"],
"05845": ["Irasburg", "Vermont", "VT"],
"05846": ["Island Pond", "Vermont", "VT"],
"05847": ["Lowell", "Vermont", "VT"],
"05850": ["Lyndon Center", "Vermont", "VT"],
"05851": ["Lyndonville", "Vermont", "VT"],
"05853": ["Morgan", "Vermont", "VT"],
"05855": ["Newport", "Vermont", "VT"],
"05857": ["Newport Center", "Vermont", "VT"],
"05858": ["North Concord", "Vermont", "VT"],
"05859": ["North Troy", "Vermont", "VT"],
"05860": ["Orleans", "Vermont", "VT"],
"05862": ["Peacham", "Vermont", "VT"],
"05866": ["Sheffield", "Vermont", "VT"],
"05867": ["Sutton", "Vermont", "VT"],
"05868": ["Troy", "Vermont", "VT"],
"05871": ["West Burke", "Vermont", "VT"],
"05872": ["West Charleston", "Vermont", "VT"],
"05873": ["West Danville", "Vermont", "VT"],
"05874": ["Westfield", "Vermont", "VT"],
"05875": ["Barton", "Vermont", "VT"],
"05901": ["Averill", "Vermont", "VT"],
"05902": ["Beecher Falls", "Vermont", "VT"],
"05903": ["Canaan", "Vermont", "VT"],
"05904": ["Gilman", "Vermont", "VT"],
"05905": ["Guildhall", "Vermont", "VT"],
"05906": ["Lunenburg", "Vermont", "VT"],
"05907": ["Norton", "Vermont", "VT"],
"06001": ["Avon", "Connecticut", "CT"],
"06002": ["Bloomfield", "Connecticut", "CT"],
"06010": ["Bristol", "Connecticut", "CT"],
"06013": ["Burlington", "Connecticut", "CT"],
"06016": ["Broad Brook", "Connecticut", "CT"],
"06018": ["Canaan", "Connecticut", "CT"],
"06019": ["Canton", "Connecticut", "CT"],
"06020": ["Canton Center", "Connecticut", "CT"],
"06021": ["Colebrook", "Connecticut", "CT"],
"06022": ["Collinsville", "Connecticut", "CT"],
"06023": ["East Berlin", "Connecticut", "CT"],
"06024": ["East Canaan", "Connecticut", "CT"],
"06026": ["East Granby", "Connecticut", "CT"],
"06027": ["East Hartland", "Connecticut", "CT"],
"06029": ["Ellington", "Connecticut", "CT"],
"06031": ["Falls Village", "Connecticut", "CT"],
"06032": ["Farmington", "Connecticut", "CT"],
"06033": ["Glastonbury", "Connecticut", "CT"],
"06035": ["Granby", "Connecticut", "CT"],
"06037": ["Berlin", "Connecticut", "CT"],
"06039": ["Lakeville", "Connecticut", "CT"],
"06040": ["Manchester", "Connecticut", "CT"],
"06042": ["Manchester", "Connecticut", "CT"],
"06043": ["Bolton", "Connecticut", "CT"],
"06051": ["New Britain", "Connecticut", "CT"],
"06052": ["New Britain", "Connecticut", "CT"],
"06053": ["New Britain", "Connecticut", "CT"],
"06057": ["New Hartford", "Connecticut", "CT"],
"06058": ["Norfolk", "Connecticut", "CT"],
"06059": ["North Canton", "Connecticut", "CT"],
"06060": ["North Granby", "Connecticut", "CT"],
"06061": ["Pine Meadow", "Connecticut", "CT"],
"06062": ["Plainville", "Connecticut", "CT"],
"06063": ["Barkhamsted", "Connecticut", "CT"],
"06065": ["Riverton", "Connecticut", "CT"],
"06066": ["Vernon Rockville", "Connecticut", "CT"],
"06067": ["Rocky Hill", "Connecticut", "CT"],
"06068": ["Salisbury", "Connecticut", "CT"],
"06069": ["Sharon", "Connecticut", "CT"],
"06070": ["Simsbury", "Connecticut", "CT"],
"06071": ["Somers", "Connecticut", "CT"],
"06072": ["Somersville", "Connecticut", "CT"],
"06073": ["South Glastonbury", "Connecticut", "CT"],
"06074": ["South Windsor", "Connecticut", "CT"],
"06076": ["Stafford Springs", "Connecticut", "CT"],
"06078": ["Suffield", "Connecticut", "CT"],
"06079": ["Taconic", "Connecticut", "CT"],
"06080": ["Suffield", "Connecticut", "CT"],
"06081": ["Tariffville", "Connecticut", "CT"],
"06082": ["Enfield", "Connecticut", "CT"],
"06084": ["Tolland", "Connecticut", "CT"],
"06085": ["Unionville", "Connecticut", "CT"],
"06088": ["East Windsor", "Connecticut", "CT"],
"06089": ["Weatogue", "Connecticut", "CT"],
"06090": ["West Granby", "Connecticut", "CT"],
"06091": ["West Hartland", "Connecticut", "CT"],
"06092": ["West Simsbury", "Connecticut", "CT"],
"06093": ["West Suffield", "Connecticut", "CT"],
"06095": ["Windsor", "Connecticut", "CT"],
"06096": ["Windsor Locks", "Connecticut", "CT"],
"06098": ["Winsted", "Connecticut", "CT"],
"06103": ["Hartford", "Connecticut", "CT"],
"06105": ["Hartford", "Connecticut", "CT"],
"06106": ["Hartford", "Connecticut", "CT"],
"06107": ["West Hartford", "Connecticut", "CT"],
"06108": ["East Hartford", "Connecticut", "CT"],
"06109": ["Wethersfield", "Connecticut", "CT"],
"06110": ["West Hartford", "Connecticut", "CT"],
"06111": ["Newington", "Connecticut", "CT"],
"06112": ["Hartford", "Connecticut", "CT"],
"06114": ["Hartford", "Connecticut", "CT"],
"06117": ["West Hartford", "Connecticut", "CT"],
"06118": ["East Hartford", "Connecticut", "CT"],
"06119": ["West Hartford", "Connecticut", "CT"],
"06120": ["Hartford", "Connecticut", "CT"],
"06226": ["Willimantic", "Connecticut", "CT"],
"06231": ["Amston", "Connecticut", "CT"],
"06232": ["Andover", "Connecticut", "CT"],
"06234": ["Brooklyn", "Connecticut", "CT"],
"06235": ["Chaplin", "Connecticut", "CT"],
"06237": ["Columbia", "Connecticut", "CT"],
"06238": ["Coventry", "Connecticut", "CT"],
"06239": ["Danielson", "Connecticut", "CT"],
"06241": ["Dayville", "Connecticut", "CT"],
"06242": ["Eastford", "Connecticut", "CT"],
"06243": ["East Killingly", "Connecticut", "CT"],
"06247": ["Hampton", "Connecticut", "CT"],
"06248": ["Hebron", "Connecticut", "CT"],
"06249": ["Lebanon", "Connecticut", "CT"],
"06250": ["Mansfield Center", "Connecticut", "CT"],
"06254": ["North Franklin", "Connecticut", "CT"],
"06255": ["North Grosvenordale", "Connecticut", "CT"],
"06256": ["North Windham", "Connecticut", "CT"],
"06258": ["Pomfret", "Connecticut", "CT"],
"06259": ["Pomfret Center", "Connecticut", "CT"],
"06260": ["Putnam", "Connecticut", "CT"],
"06262": ["Quinebaug", "Connecticut", "CT"],
"06263": ["Rogers", "Connecticut", "CT"],
"06264": ["Scotland", "Connecticut", "CT"],
"06266": ["South Windham", "Connecticut", "CT"],
"06268": ["Storrs Mansfield", "Connecticut", "CT"],
"06269": ["Storrs Mansfield", "Connecticut", "CT"],
"06277": ["Thompson", "Connecticut", "CT"],
"06278": ["Ashford", "Connecticut", "CT"],
"06279": ["Willington", "Connecticut", "CT"],
"06280": ["Windham", "Connecticut", "CT"],
"06281": ["Woodstock", "Connecticut", "CT"],
"06282": ["Woodstock Valley", "Connecticut", "CT"],
"06320": ["New London", "Connecticut", "CT"],
"06330": ["Baltic", "Connecticut", "CT"],
"06331": ["Canterbury", "Connecticut", "CT"],
"06332": ["Central Village", "Connecticut", "CT"],
"06333": ["East Lyme", "Connecticut", "CT"],
"06334": ["Bozrah", "Connecticut", "CT"],
"06335": ["Gales Ferry", "Connecticut", "CT"],
"06336": ["Gilman", "Connecticut", "CT"],
"06338": ["Mashantucket", "Connecticut", "CT"],
"06339": ["Ledyard", "Connecticut", "CT"],
"06340": ["Groton", "Connecticut", "CT"],
"06350": ["Hanover", "Connecticut", "CT"],
"06351": ["Jewett City", "Connecticut", "CT"],
"06353": ["Montville", "Connecticut", "CT"],
"06354": ["Moosup", "Connecticut", "CT"],
"06355": ["Mystic", "Connecticut", "CT"],
"06357": ["Niantic", "Connecticut", "CT"],
"06359": ["North Stonington", "Connecticut", "CT"],
"06360": ["Norwich", "Connecticut", "CT"],
"06365": ["Preston", "Connecticut", "CT"],
"06370": ["Oakdale", "Connecticut", "CT"],
"06371": ["Old Lyme", "Connecticut", "CT"],
"06373": ["Oneco", "Connecticut", "CT"],
"06374": ["Plainfield", "Connecticut", "CT"],
"06375": ["Quaker Hill", "Connecticut", "CT"],
"06376": ["South Lyme", "Connecticut", "CT"],
"06377": ["Sterling", "Connecticut", "CT"],
"06378": ["Stonington", "Connecticut", "CT"],
"06379": ["Pawcatuck", "Connecticut", "CT"],
"06380": ["Taftville", "Connecticut", "CT"],
"06382": ["Uncasville", "Connecticut", "CT"],
"06384": ["Voluntown", "Connecticut", "CT"],
"06385": ["Waterford", "Connecticut", "CT"],
"06387": ["Wauregan", "Connecticut", "CT"],
"06389": ["Yantic", "Connecticut", "CT"],
"06390": ["Fishers Island", "New York", "NY"],
"06401": ["Ansonia", "Connecticut", "CT"],
"06403": ["Beacon Falls", "Connecticut", "CT"],
"06405": ["Branford", "Connecticut", "CT"],
"06409": ["Centerbrook", "Connecticut", "CT"],
"06410": ["Cheshire", "Connecticut", "CT"],
"06412": ["Chester", "Connecticut", "CT"],
"06413": ["Clinton", "Connecticut", "CT"],
"06414": ["Cobalt", "Connecticut", "CT"],
"06415": ["Colchester", "Connecticut", "CT"],
"06416": ["Cromwell", "Connecticut", "CT"],
"06417": ["Deep River", "Connecticut", "CT"],
"06418": ["Derby", "Connecticut", "CT"],
"06419": ["Killingworth", "Connecticut", "CT"],
"06420": ["Salem", "Connecticut", "CT"],
"06422": ["Durham", "Connecticut", "CT"],
"06423": ["East Haddam", "Connecticut", "CT"],
"06424": ["East Hampton", "Connecticut", "CT"],
"06426": ["Essex", "Connecticut", "CT"],
"06437": ["Guilford", "Connecticut", "CT"],
"06438": ["Haddam", "Connecticut", "CT"],
"06439": ["Hadlyme", "Connecticut", "CT"],
"06441": ["Higganum", "Connecticut", "CT"],
"06442": ["Ivoryton", "Connecticut", "CT"],
"06443": ["Madison", "Connecticut", "CT"],
"06444": ["Marion", "Connecticut", "CT"],
"06447": ["Marlborough", "Connecticut", "CT"],
"06450": ["Meriden", "Connecticut", "CT"],
"06451": ["Meriden", "Connecticut", "CT"],
"06455": ["Middlefield", "Connecticut", "CT"],
"06456": ["Middle Haddam", "Connecticut", "CT"],
"06457": ["Middletown", "Connecticut", "CT"],
"06459": ["Middletown", "Connecticut", "CT"],
"06460": ["Milford", "Connecticut", "CT"],
"06461": ["Milford", "Connecticut", "CT"],
"06467": ["Milldale", "Connecticut", "CT"],
"06468": ["Monroe", "Connecticut", "CT"],
"06469": ["Moodus", "Connecticut", "CT"],
"06470": ["Newtown", "Connecticut", "CT"],
"06471": ["North Branford", "Connecticut", "CT"],
"06472": ["Northford", "Connecticut", "CT"],
"06473": ["North Haven", "Connecticut", "CT"],
"06475": ["Old Saybrook", "Connecticut", "CT"],
"06477": ["Orange", "Connecticut", "CT"],
"06478": ["Oxford", "Connecticut", "CT"],
"06479": ["Plantsville", "Connecticut", "CT"],
"06480": ["Portland", "Connecticut", "CT"],
"06481": ["Rockfall", "Connecticut", "CT"],
"06482": ["Sandy Hook", "Connecticut", "CT"],
"06483": ["Seymour", "Connecticut", "CT"],
"06484": ["Shelton", "Connecticut", "CT"],
"06488": ["Southbury", "Connecticut", "CT"],
"06489": ["Southington", "Connecticut", "CT"],
"06492": ["Wallingford", "Connecticut", "CT"],
"06498": ["Westbrook", "Connecticut", "CT"],
"06510": ["New Haven", "Connecticut", "CT"],
"06511": ["New Haven", "Connecticut", "CT"],
"06512": ["East Haven", "Connecticut", "CT"],
"06513": ["New Haven", "Connecticut", "CT"],
"06514": ["Hamden", "Connecticut", "CT"],
"06515": ["New Haven", "Connecticut", "CT"],
"06516": ["West Haven", "Connecticut", "CT"],
"06517": ["Hamden", "Connecticut", "CT"],
"06518": ["Hamden", "Connecticut", "CT"],
"06519": ["New Haven", "Connecticut", "CT"],
"06524": ["Bethany", "Connecticut", "CT"],
"06525": ["Woodbridge", "Connecticut", "CT"],
"06604": ["Bridgeport", "Connecticut", "CT"],
"06605": ["Bridgeport", "Connecticut", "CT"],
"06606": ["Bridgeport", "Connecticut", "CT"],
"06607": ["Bridgeport", "Connecticut", "CT"],
"06608": ["Bridgeport", "Connecticut", "CT"],
"06610": ["Bridgeport", "Connecticut", "CT"],
"06611": ["Trumbull", "Connecticut", "CT"],
"06612": ["Easton", "Connecticut", "CT"],
"06614": ["Stratford", "Connecticut", "CT"],
"06615": ["Stratford", "Connecticut", "CT"],
"06702": ["Waterbury", "Connecticut", "CT"],
"06704": ["Waterbury", "Connecticut", "CT"],
"06705": ["Waterbury", "Connecticut", "CT"],
"06706": ["Waterbury", "Connecticut", "CT"],
"06708": ["Waterbury", "Connecticut", "CT"],
"06710": ["Waterbury", "Connecticut", "CT"],
"06712": ["Prospect", "Connecticut", "CT"],
"06716": ["Wolcott", "Connecticut", "CT"],
"06750": ["Bantam", "Connecticut", "CT"],
"06751": ["Bethlehem", "Connecticut", "CT"],
"06752": ["Bridgewater", "Connecticut", "CT"],
"06753": ["Cornwall", "Connecticut", "CT"],
"06754": ["Cornwall Bridge", "Connecticut", "CT"],
"06755": ["Gaylordsville", "Connecticut", "CT"],
"06756": ["Goshen", "Connecticut", "CT"],
"06757": ["Kent", "Connecticut", "CT"],
"06758": ["Lakeside", "Connecticut", "CT"],
"06759": ["Litchfield", "Connecticut", "CT"],
"06762": ["Middlebury", "Connecticut", "CT"],
"06763": ["Morris", "Connecticut", "CT"],
"06770": ["Naugatuck", "Connecticut", "CT"],
"06776": ["New Milford", "Connecticut", "CT"],
"06777": ["New Preston Marble Dale", "Connecticut", "CT"],
"06778": ["Northfield", "Connecticut", "CT"],
"06779": ["Oakville", "Connecticut", "CT"],
"06782": ["Plymouth", "Connecticut", "CT"],
"06783": ["Roxbury", "Connecticut", "CT"],
"06784": ["Sherman", "Connecticut", "CT"],
"06785": ["South Kent", "Connecticut", "CT"],
"06786": ["Terryville", "Connecticut", "CT"],
"06787": ["Thomaston", "Connecticut", "CT"],
"06790": ["Torrington", "Connecticut", "CT"],
"06791": ["Harwinton", "Connecticut", "CT"],
"06793": ["Washington", "Connecticut", "CT"],
"06794": ["Washington Depot", "Connecticut", "CT"],
"06795": ["Watertown", "Connecticut", "CT"],
"06796": ["West Cornwall", "Connecticut", "CT"],
"06798": ["Woodbury", "Connecticut", "CT"],
"06801": ["Bethel", "Connecticut", "CT"],
"06804": ["Brookfield", "Connecticut", "CT"],
"06807": ["Cos Cob", "Connecticut", "CT"],
"06810": ["Danbury", "Connecticut", "CT"],
"06811": ["Danbury", "Connecticut", "CT"],
"06812": ["New Fairfield", "Connecticut", "CT"],
"06820": ["Darien", "Connecticut", "CT"],
"06824": ["Fairfield", "Connecticut", "CT"],
"06825": ["Fairfield", "Connecticut", "CT"],
"06830": ["Greenwich", "Connecticut", "CT"],
"06831": ["Greenwich", "Connecticut", "CT"],
"06840": ["New Canaan", "Connecticut", "CT"],
"06850": ["Norwalk", "Connecticut", "CT"],
"06851": ["Norwalk", "Connecticut", "CT"],
"06853": ["Norwalk", "Connecticut", "CT"],
"06854": ["Norwalk", "Connecticut", "CT"],
"06855": ["Norwalk", "Connecticut", "CT"],
"06870": ["Old Greenwich", "Connecticut", "CT"],
"06877": ["Ridgefield", "Connecticut", "CT"],
"06878": ["Riverside", "Connecticut", "CT"],
"06880": ["Westport", "Connecticut", "CT"],
"06883": ["Weston", "Connecticut", "CT"],
"06890": ["Southport", "Connecticut", "CT"],
"06896": ["Redding", "Connecticut", "CT"],
"06897": ["Wilton", "Connecticut", "CT"],
"06901": ["Stamford", "Connecticut", "CT"],
"06902": ["Stamford", "Connecticut", "CT"],
"06903": ["Stamford", "Connecticut", "CT"],
"06905": ["Stamford", "Connecticut", "CT"],
"06906": ["Stamford", "Connecticut", "CT"],
"06907": ["Stamford", "Connecticut", "CT"],
"07001": ["Avenel", "New Jersey", "NJ"],
"07002": ["Bayonne", "New Jersey", "NJ"],
"07003": ["Bloomfield", "New Jersey", "NJ"],
"07004": ["Fairfield", "New Jersey", "NJ"],
"07005": ["Boonton", "New Jersey", "NJ"],
"07006": ["Caldwell", "New Jersey", "NJ"],
"07008": ["Carteret", "New Jersey", "NJ"],
"07009": ["Cedar Grove", "New Jersey", "NJ"],
"07010": ["Cliffside Park", "New Jersey", "NJ"],
"07011": ["Clifton", "New Jersey", "NJ"],
"07012": ["Clifton", "New Jersey", "NJ"],
"07013": ["Clifton", "New Jersey", "NJ"],
"07014": ["Clifton", "New Jersey", "NJ"],
"07016": ["Cranford", "New Jersey", "NJ"],
"07017": ["East Orange", "New Jersey", "NJ"],
"07018": ["East Orange", "New Jersey", "NJ"],
"07020": ["Edgewater", "New Jersey", "NJ"],
"07021": ["Essex Fells", "New Jersey", "NJ"],
"07022": ["Fairview", "New Jersey", "NJ"],
"07023": ["Fanwood", "New Jersey", "NJ"],
"07024": ["Fort Lee", "New Jersey", "NJ"],
"07026": ["Garfield", "New Jersey", "NJ"],
"07027": ["Garwood", "New Jersey", "NJ"],
"07028": ["Glen Ridge", "New Jersey", "NJ"],
"07029": ["Harrison", "New Jersey", "NJ"],
"07030": ["Hoboken", "New Jersey", "NJ"],
"07031": ["North Arlington", "New Jersey", "NJ"],
"07032": ["Kearny", "New Jersey", "NJ"],
"07033": ["Kenilworth", "New Jersey", "NJ"],
"07034": ["Lake Hiawatha", "New Jersey", "NJ"],
"07035": ["Lincoln Park", "New Jersey", "NJ"],
"07036": ["Linden", "New Jersey", "NJ"],
"07039": ["Livingston", "New Jersey", "NJ"],
"07040": ["Maplewood", "New Jersey", "NJ"],
"07041": ["Millburn", "New Jersey", "NJ"],
"07042": ["Montclair", "New Jersey", "NJ"],
"07043": ["Montclair", "New Jersey", "NJ"],
"07044": ["Verona", "New Jersey", "NJ"],
"07045": ["Montville", "New Jersey", "NJ"],
"07046": ["Mountain Lakes", "New Jersey", "NJ"],
"07047": ["North Bergen", "New Jersey", "NJ"],
"07050": ["Orange", "New Jersey", "NJ"],
"07052": ["West Orange", "New Jersey", "NJ"],
"07054": ["Parsippany", "New Jersey", "NJ"],
"07055": ["Passaic", "New Jersey", "NJ"],
"07057": ["Wallington", "New Jersey", "NJ"],
"07058": ["Pine Brook", "New Jersey", "NJ"],
"07059": ["Warren", "New Jersey", "NJ"],
"07060": ["Plainfield", "New Jersey", "NJ"],
"07062": ["Plainfield", "New Jersey", "NJ"],
"07063": ["Plainfield", "New Jersey", "NJ"],
"07064": ["Port Reading", "New Jersey", "NJ"],
"07065": ["Rahway", "New Jersey", "NJ"],
"07066": ["Clark", "New Jersey", "NJ"],
"07067": ["Colonia", "New Jersey", "NJ"],
"07068": ["Roseland", "New Jersey", "NJ"],
"07069": ["Watchung", "New Jersey", "NJ"],
"07070": ["Rutherford", "New Jersey", "NJ"],
"07071": ["Lyndhurst", "New Jersey", "NJ"],
"07072": ["Carlstadt", "New Jersey", "NJ"],
"07073": ["East Rutherford", "New Jersey", "NJ"],
"07074": ["Moonachie", "New Jersey", "NJ"],
"07075": ["Wood Ridge", "New Jersey", "NJ"],
"07076": ["Scotch Plains", "New Jersey", "NJ"],
"07077": ["Sewaren", "New Jersey", "NJ"],
"07078": ["Short Hills", "New Jersey", "NJ"],
"07079": ["South Orange", "New Jersey", "NJ"],
"07080": ["South Plainfield", "New Jersey", "NJ"],
"07081": ["Springfield", "New Jersey", "NJ"],
"07082": ["Towaco", "New Jersey", "NJ"],
"07083": ["Union", "New Jersey", "NJ"],
"07086": ["Weehawken", "New Jersey", "NJ"],
"07087": ["Union City", "New Jersey", "NJ"],
"07088": ["Vauxhall", "New Jersey", "NJ"],
"07090": ["Westfield", "New Jersey", "NJ"],
"07092": ["Mountainside", "New Jersey", "NJ"],
"07093": ["West New York", "New Jersey", "NJ"],
"07094": ["Secaucus", "New Jersey", "NJ"],
"07095": ["Woodbridge", "New Jersey", "NJ"],
"07102": ["Newark", "New Jersey", "NJ"],
"07103": ["Newark", "New Jersey", "NJ"],
"07104": ["Newark", "New Jersey", "NJ"],
"07105": ["Newark", "New Jersey", "NJ"],
"07106": ["Newark", "New Jersey", "NJ"],
"07107": ["Newark", "New Jersey", "NJ"],
"07108": ["Newark", "New Jersey", "NJ"],
"07109": ["Belleville", "New Jersey", "NJ"],
"07110": ["Nutley", "New Jersey", "NJ"],
"07111": ["Irvington", "New Jersey", "NJ"],
"07112": ["Newark", "New Jersey", "NJ"],
"07114": ["Newark", "New Jersey", "NJ"],
"07201": ["Elizabeth", "New Jersey", "NJ"],
"07202": ["Elizabeth", "New Jersey", "NJ"],
"07203": ["Roselle", "New Jersey", "NJ"],
"07204": ["Roselle Park", "New Jersey", "NJ"],
"07205": ["Hillside", "New Jersey", "NJ"],
"07206": ["Elizabethport", "New Jersey", "NJ"],
"07208": ["Elizabeth", "New Jersey", "NJ"],
"07302": ["Jersey City", "New Jersey", "NJ"],
"07304": ["Jersey City", "New Jersey", "NJ"],
"07305": ["Jersey City", "New Jersey", "NJ"],
"07306": ["Jersey City", "New Jersey", "NJ"],
"07307": ["Jersey City", "New Jersey", "NJ"],
"07310": ["Jersey City", "New Jersey", "NJ"],
"07311": ["Jersey City", "New Jersey", "NJ"],
"07401": ["Allendale", "New Jersey", "NJ"],
"07403": ["Bloomingdale", "New Jersey", "NJ"],
"07405": ["Butler", "New Jersey", "NJ"],
"07407": ["Elmwood Park", "New Jersey", "NJ"],
"07410": ["Fair Lawn", "New Jersey", "NJ"],
"07416": ["Franklin", "New Jersey", "NJ"],
"07417": ["Franklin Lakes", "New Jersey", "NJ"],
"07418": ["Glenwood", "New Jersey", "NJ"],
"07419": ["Hamburg", "New Jersey", "NJ"],
"07420": ["Haskell", "New Jersey", "NJ"],
"07421": ["Hewitt", "New Jersey", "NJ"],
"07422": ["Highland Lakes", "New Jersey", "NJ"],
"07423": ["Ho Ho Kus", "New Jersey", "NJ"],
"07424": ["Little Falls", "New Jersey", "NJ"],
"07430": ["Mahwah", "New Jersey", "NJ"],
"07432": ["Midland Park", "New Jersey", "NJ"],
"07435": ["Newfoundland", "New Jersey", "NJ"],
"07436": ["Oakland", "New Jersey", "NJ"],
"07438": ["Oak Ridge", "New Jersey", "NJ"],
"07439": ["Ogdensburg", "New Jersey", "NJ"],
"07440": ["Pequannock", "New Jersey", "NJ"],
"07442": ["Pompton Lakes", "New Jersey", "NJ"],
"07444": ["Pompton Plains", "New Jersey", "NJ"],
"07446": ["Ramsey", "New Jersey", "NJ"],
"07450": ["Ridgewood", "New Jersey", "NJ"],
"07452": ["Glen Rock", "New Jersey", "NJ"],
"07456": ["Ringwood", "New Jersey", "NJ"],
"07457": ["Riverdale", "New Jersey", "NJ"],
"07458": ["Saddle River", "New Jersey", "NJ"],
"07460": ["Stockholm", "New Jersey", "NJ"],
"07461": ["Sussex", "New Jersey", "NJ"],
"07462": ["Vernon", "New Jersey", "NJ"],
"07463": ["Waldwick", "New Jersey", "NJ"],
"07465": ["Wanaque", "New Jersey", "NJ"],
"07470": ["Wayne", "New Jersey", "NJ"],
"07480": ["West Milford", "New Jersey", "NJ"],
"07481": ["Wyckoff", "New Jersey", "NJ"],
"07501": ["Paterson", "New Jersey", "NJ"],
"07502": ["Paterson", "New Jersey", "NJ"],
"07503": ["Paterson", "New Jersey", "NJ"],
"07504": ["Paterson", "New Jersey", "NJ"],
"07505": ["Paterson", "New Jersey", "NJ"],
"07506": ["Hawthorne", "New Jersey", "NJ"],
"07508": ["Haledon", "New Jersey", "NJ"],
"07512": ["Totowa", "New Jersey", "NJ"],
"07513": ["Paterson", "New Jersey", "NJ"],
"07514": ["Paterson", "New Jersey", "NJ"],
"07522": ["Paterson", "New Jersey", "NJ"],
"07524": ["Paterson", "New Jersey", "NJ"],
"07601": ["Hackensack", "New Jersey", "NJ"],
"07603": ["Bogota", "New Jersey", "NJ"],
"07604": ["Hasbrouck Heights", "New Jersey", "NJ"],
"07605": ["Leonia", "New Jersey", "NJ"],
"07606": ["South Hackensack", "New Jersey", "NJ"],
"07607": ["Maywood", "New Jersey", "NJ"],
"07608": ["Teterboro", "New Jersey", "NJ"],
"07620": ["Alpine", "New Jersey", "NJ"],
"07621": ["Bergenfield", "New Jersey", "NJ"],
"07624": ["Closter", "New Jersey", "NJ"],
"07626": ["Cresskill", "New Jersey", "NJ"],
"07627": ["Demarest", "New Jersey", "NJ"],
"07628": ["Dumont", "New Jersey", "NJ"],
"07630": ["Emerson", "New Jersey", "NJ"],
"07631": ["Englewood", "New Jersey", "NJ"],
"07632": ["Englewood Cliffs", "New Jersey", "NJ"],
"07640": ["Harrington Park", "New Jersey", "NJ"],
"07641": ["Haworth", "New Jersey", "NJ"],
"07642": ["Hillsdale", "New Jersey", "NJ"],
"07643": ["Little Ferry", "New Jersey", "NJ"],
"07644": ["Lodi", "New Jersey", "NJ"],
"07645": ["Montvale", "New Jersey", "NJ"],
"07646": ["New Milford", "New Jersey", "NJ"],
"07647": ["Northvale", "New Jersey", "NJ"],
"07648": ["Norwood", "New Jersey", "NJ"],
"07649": ["Oradell", "New Jersey", "NJ"],
"07650": ["Palisades Park", "New Jersey", "NJ"],
"07652": ["Paramus", "New Jersey", "NJ"],
"07656": ["Park Ridge", "New Jersey", "NJ"],
"07657": ["Ridgefield", "New Jersey", "NJ"],
"07660": ["Ridgefield Park", "New Jersey", "NJ"],
"07661": ["River Edge", "New Jersey", "NJ"],
"07662": ["Rochelle Park", "New Jersey", "NJ"],
"07663": ["Saddle Brook", "New Jersey", "NJ"],
"07666": ["Teaneck", "New Jersey", "NJ"],
"07670": ["Tenafly", "New Jersey", "NJ"],
"07675": ["Westwood", "New Jersey", "NJ"],
"07676": ["Township Of Washington", "New Jersey", "NJ"],
"07677": ["Woodcliff Lake", "New Jersey", "NJ"],
"07701": ["Red Bank", "New Jersey", "NJ"],
"07702": ["Shrewsbury", "New Jersey", "NJ"],
"07703": ["Fort Monmouth", "New Jersey", "NJ"],
"07704": ["Fair Haven", "New Jersey", "NJ"],
"07711": ["Allenhurst", "New Jersey", "NJ"],
"07712": ["Asbury Park", "New Jersey", "NJ"],
"07716": ["Atlantic Highlands", "New Jersey", "NJ"],
"07717": ["Avon By The Sea", "New Jersey", "NJ"],
"07718": ["Belford", "New Jersey", "NJ"],
"07719": ["Belmar", "New Jersey", "NJ"],
"07720": ["Bradley Beach", "New Jersey", "NJ"],
"07721": ["Cliffwood", "New Jersey", "NJ"],
"07722": ["Colts Neck", "New Jersey", "NJ"],
"07723": ["Deal", "New Jersey", "NJ"],
"07724": ["Eatontown", "New Jersey", "NJ"],
"07726": ["Englishtown", "New Jersey", "NJ"],
"07727": ["Farmingdale", "New Jersey", "NJ"],
"07728": ["Freehold", "New Jersey", "NJ"],
"07730": ["Hazlet", "New Jersey", "NJ"],
"07731": ["Howell", "New Jersey", "NJ"],
"07732": ["Highlands", "New Jersey", "NJ"],
"07733": ["Holmdel", "New Jersey", "NJ"],
"07734": ["Keansburg", "New Jersey", "NJ"],
"07735": ["Keyport", "New Jersey", "NJ"],
"07737": ["Leonardo", "New Jersey", "NJ"],
"07738": ["Lincroft", "New Jersey", "NJ"],
"07739": ["Little Silver", "New Jersey", "NJ"],
"07740": ["Long Branch", "New Jersey", "NJ"],
"07746": ["Marlboro", "New Jersey", "NJ"],
"07747": ["Matawan", "New Jersey", "NJ"],
"07748": ["Middletown", "New Jersey", "NJ"],
"07750": ["Monmouth Beach", "New Jersey", "NJ"],
"07751": ["Morganville", "New Jersey", "NJ"],
"07753": ["Neptune", "New Jersey", "NJ"],
"07755": ["Oakhurst", "New Jersey", "NJ"],
"07756": ["Ocean Grove", "New Jersey", "NJ"],
"07757": ["Oceanport", "New Jersey", "NJ"],
"07758": ["Port Monmouth", "New Jersey", "NJ"],
"07760": ["Rumson", "New Jersey", "NJ"],
"07762": ["Spring Lake", "New Jersey", "NJ"],
"07764": ["West Long Branch", "New Jersey", "NJ"],
"07801": ["Dover", "New Jersey", "NJ"],
"07803": ["Mine Hill", "New Jersey", "NJ"],
"07820": ["Allamuchy", "New Jersey", "NJ"],
"07821": ["Andover", "New Jersey", "NJ"],
"07822": ["Augusta", "New Jersey", "NJ"],
"07823": ["Belvidere", "New Jersey", "NJ"],
"07825": ["Blairstown", "New Jersey", "NJ"],
"07826": ["Branchville", "New Jersey", "NJ"],
"07827": ["Montague", "New Jersey", "NJ"],
"07828": ["Budd Lake", "New Jersey", "NJ"],
"07830": ["Califon", "New Jersey", "NJ"],
"07831": ["Changewater", "New Jersey", "NJ"],
"07832": ["Columbia", "New Jersey", "NJ"],
"07833": ["Delaware", "New Jersey", "NJ"],
"07834": ["Denville", "New Jersey", "NJ"],
"07836": ["Flanders", "New Jersey", "NJ"],
"07838": ["Great Meadows", "New Jersey", "NJ"],
"07840": ["Hackettstown", "New Jersey", "NJ"],
"07842": ["Hibernia", "New Jersey", "NJ"],
"07843": ["Hopatcong", "New Jersey", "NJ"],
"07844": ["Hope", "New Jersey", "NJ"],
"07846": ["Johnsonburg", "New Jersey", "NJ"],
"07847": ["Kenvil", "New Jersey", "NJ"],
"07848": ["Lafayette", "New Jersey", "NJ"],
"07849": ["Lake Hopatcong", "New Jersey", "NJ"],
"07850": ["Landing", "New Jersey", "NJ"],
"07851": ["Layton", "New Jersey", "NJ"],
"07852": ["Ledgewood", "New Jersey", "NJ"],
"07853": ["Long Valley", "New Jersey", "NJ"],
"07856": ["Mount Arlington", "New Jersey", "NJ"],
"07857": ["Netcong", "New Jersey", "NJ"],
"07860": ["Newton", "New Jersey", "NJ"],
"07863": ["Oxford", "New Jersey", "NJ"],
"07865": ["Port Murray", "New Jersey", "NJ"],
"07866": ["Rockaway", "New Jersey", "NJ"],
"07869": ["Randolph", "New Jersey", "NJ"],
"07870": ["Schooleys Mountain", "New Jersey", "NJ"],
"07871": ["Sparta", "New Jersey", "NJ"],
"07874": ["Stanhope", "New Jersey", "NJ"],
"07876": ["Succasunna", "New Jersey", "NJ"],
"07878": ["Mount Tabor", "New Jersey", "NJ"],
"07880": ["Vienna", "New Jersey", "NJ"],
"07881": ["Wallpack Center", "New Jersey", "NJ"],
"07882": ["Washington", "New Jersey", "NJ"],
"07885": ["Wharton", "New Jersey", "NJ"],
"07901": ["Summit", "New Jersey", "NJ"],
"07920": ["Basking Ridge", "New Jersey", "NJ"],
"07921": ["Bedminster", "New Jersey", "NJ"],
"07922": ["Berkeley Heights", "New Jersey", "NJ"],
"07924": ["Bernardsville", "New Jersey", "NJ"],
"07926": ["Brookside", "New Jersey", "NJ"],
"07927": ["Cedar Knolls", "New Jersey", "NJ"],
"07928": ["Chatham", "New Jersey", "NJ"],
"07930": ["Chester", "New Jersey", "NJ"],
"07931": ["Far Hills", "New Jersey", "NJ"],
"07932": ["Florham Park", "New Jersey", "NJ"],
"07933": ["Gillette", "New Jersey", "NJ"],
"07934": ["Gladstone", "New Jersey", "NJ"],
"07935": ["Green Village", "New Jersey", "NJ"],
"07936": ["East Hanover", "New Jersey", "NJ"],
"07939": ["Lyons", "New Jersey", "NJ"],
"07940": ["Madison", "New Jersey", "NJ"],
"07945": ["Mendham", "New Jersey", "NJ"],
"07946": ["Millington", "New Jersey", "NJ"],
"07950": ["Morris Plains", "New Jersey", "NJ"],
"07960": ["Morristown", "New Jersey", "NJ"],
"07961": ["Convent Station", "New Jersey", "NJ"],
"07970": ["Mount Freedom", "New Jersey", "NJ"],
"07974": ["New Providence", "New Jersey", "NJ"],
"07976": ["New Vernon", "New Jersey", "NJ"],
"07977": ["Peapack", "New Jersey", "NJ"],
"07979": ["Pottersville", "New Jersey", "NJ"],
"07980": ["Stirling", "New Jersey", "NJ"],
"07981": ["Whippany", "New Jersey", "NJ"],
"08001": ["Alloway", "New Jersey", "NJ"],
"08002": ["Cherry Hill", "New Jersey", "NJ"],
"08003": ["Cherry Hill", "New Jersey", "NJ"],
"08004": ["Atco", "New Jersey", "NJ"],
"08005": ["Barnegat", "New Jersey", "NJ"],
"08006": ["Barnegat Light", "New Jersey", "NJ"],
"08007": ["Barrington", "New Jersey", "NJ"],
"08008": ["Beach Haven", "New Jersey", "NJ"],
"08009": ["Berlin", "New Jersey", "NJ"],
"08010": ["Beverly", "New Jersey", "NJ"],
"08011": ["Birmingham", "New Jersey", "NJ"],
"08012": ["Blackwood", "New Jersey", "NJ"],
"08014": ["Bridgeport", "New Jersey", "NJ"],
"08015": ["Browns Mills", "New Jersey", "NJ"],
"08016": ["Burlington", "New Jersey", "NJ"],
"08019": ["Chatsworth", "New Jersey", "NJ"],
"08020": ["Clarksboro", "New Jersey", "NJ"],
"08021": ["Clementon", "New Jersey", "NJ"],
"08022": ["Columbus", "New Jersey", "NJ"],
"08023": ["Deepwater", "New Jersey", "NJ"],
"08026": ["Gibbsboro", "New Jersey", "NJ"],
"08027": ["Gibbstown", "New Jersey", "NJ"],
"08028": ["Glassboro", "New Jersey", "NJ"],
"08029": ["Glendora", "New Jersey", "NJ"],
"08030": ["Gloucester City", "New Jersey", "NJ"],
"08031": ["Bellmawr", "New Jersey", "NJ"],
"08032": ["Grenloch", "New Jersey", "NJ"],
"08033": ["Haddonfield", "New Jersey", "NJ"],
"08034": ["Cherry Hill", "New Jersey", "NJ"],
"08035": ["Haddon Heights", "New Jersey", "NJ"],
"08036": ["Hainesport", "New Jersey", "NJ"],
"08037": ["Hammonton", "New Jersey", "NJ"],
"08038": ["Hancocks Bridge", "New Jersey", "NJ"],
"08039": ["Harrisonville", "New Jersey", "NJ"],
"08041": ["Jobstown", "New Jersey", "NJ"],
"08042": ["Juliustown", "New Jersey", "NJ"],
"08043": ["Voorhees", "New Jersey", "NJ"],
"08045": ["Lawnside", "New Jersey", "NJ"],
"08046": ["Willingboro", "New Jersey", "NJ"],
"08048": ["Lumberton", "New Jersey", "NJ"],
"08049": ["Magnolia", "New Jersey", "NJ"],
"08050": ["Manahawkin", "New Jersey", "NJ"],
"08051": ["Mantua", "New Jersey", "NJ"],
"08052": ["Maple Shade", "New Jersey", "NJ"],
"08053": ["Marlton", "New Jersey", "NJ"],
"08054": ["Mount Laurel", "New Jersey", "NJ"],
"08055": ["Medford", "New Jersey", "NJ"],
"08056": ["Mickleton", "New Jersey", "NJ"],
"08057": ["Moorestown", "New Jersey", "NJ"],
"08059": ["Mount Ephraim", "New Jersey", "NJ"],
"08060": ["Mount Holly", "New Jersey", "NJ"],
"08061": ["Mount Royal", "New Jersey", "NJ"],
"08062": ["Mullica Hill", "New Jersey", "NJ"],
"08063": ["National Park", "New Jersey", "NJ"],
"08064": ["New Lisbon", "New Jersey", "NJ"],
"08065": ["Palmyra", "New Jersey", "NJ"],
"08066": ["Paulsboro", "New Jersey", "NJ"],
"08067": ["Pedricktown", "New Jersey", "NJ"],
"08068": ["Pemberton", "New Jersey", "NJ"],
"08069": ["Penns Grove", "New Jersey", "NJ"],
"08070": ["Pennsville", "New Jersey", "NJ"],
"08071": ["Pitman", "New Jersey", "NJ"],
"08072": ["Quinton", "New Jersey", "NJ"],
"08073": ["Rancocas", "New Jersey", "NJ"],
"08074": ["Richwood", "New Jersey", "NJ"],
"08075": ["Riverside", "New Jersey", "NJ"],
"08077": ["Riverton", "New Jersey", "NJ"],
"08078": ["Runnemede", "New Jersey", "NJ"],
"08079": ["Salem", "New Jersey", "NJ"],
"08080": ["Sewell", "New Jersey", "NJ"],
"08081": ["Sicklerville", "New Jersey", "NJ"],
"08083": ["Somerdale", "New Jersey", "NJ"],
"08084": ["Stratford", "New Jersey", "NJ"],
"08085": ["Swedesboro", "New Jersey", "NJ"],
"08086": ["Thorofare", "New Jersey", "NJ"],
"08087": ["Tuckerton", "New Jersey", "NJ"],
"08088": ["Vincentown", "New Jersey", "NJ"],
"08089": ["Waterford Works", "New Jersey", "NJ"],
"08090": ["Wenonah", "New Jersey", "NJ"],
"08091": ["West Berlin", "New Jersey", "NJ"],
"08092": ["West Creek", "New Jersey", "NJ"],
"08093": ["Westville", "New Jersey", "NJ"],
"08094": ["Williamstown", "New Jersey", "NJ"],
"08095": ["Winslow", "New Jersey", "NJ"],
"08096": ["Woodbury", "New Jersey", "NJ"],
"08097": ["Woodbury Heights", "New Jersey", "NJ"],
"08098": ["Woodstown", "New Jersey", "NJ"],
"08102": ["Camden", "New Jersey", "NJ"],
"08103": ["Camden", "New Jersey", "NJ"],
"08104": ["Camden", "New Jersey", "NJ"],
"08105": ["Camden", "New Jersey", "NJ"],
"08106": ["Audubon", "New Jersey", "NJ"],
"08107": ["Oaklyn", "New Jersey", "NJ"],
"08108": ["Collingswood", "New Jersey", "NJ"],
"08109": ["Merchantville", "New Jersey", "NJ"],
"08110": ["Pennsauken", "New Jersey", "NJ"],
"08201": ["Absecon", "New Jersey", "NJ"],
"08202": ["Avalon", "New Jersey", "NJ"],
"08203": ["Brigantine", "New Jersey", "NJ"],
"08204": ["Cape May", "New Jersey", "NJ"],
"08205": ["Absecon", "New Jersey", "NJ"],
"08210": ["Cape May Court House", "New Jersey", "NJ"],
"08212": ["Cape May Point", "New Jersey", "NJ"],
"08215": ["Egg Harbor City", "New Jersey", "NJ"],
"08217": ["Elwood", "New Jersey", "NJ"],
"08221": ["Linwood", "New Jersey", "NJ"],
"08223": ["Marmora", "New Jersey", "NJ"],
"08224": ["New Gretna", "New Jersey", "NJ"],
"08225": ["Northfield", "New Jersey", "NJ"],
"08226": ["Ocean City", "New Jersey", "NJ"],
"08230": ["Ocean View", "New Jersey", "NJ"],
"08232": ["Pleasantville", "New Jersey", "NJ"],
"08234": ["Egg Harbor Township", "New Jersey", "NJ"],
"08240": ["Pomona", "New Jersey", "NJ"],
"08241": ["Port Republic", "New Jersey", "NJ"],
"08242": ["Rio Grande", "New Jersey", "NJ"],
"08243": ["Sea Isle City", "New Jersey", "NJ"],
"08244": ["Somers Point", "New Jersey", "NJ"],
"08245": ["South Dennis", "New Jersey", "NJ"],
"08246": ["South Seaville", "New Jersey", "NJ"],
"08247": ["Stone Harbor", "New Jersey", "NJ"],
"08248": ["Strathmere", "New Jersey", "NJ"],
"08251": ["Villas", "New Jersey", "NJ"],
"08260": ["Wildwood", "New Jersey", "NJ"],
"08270": ["Woodbine", "New Jersey", "NJ"],
"08302": ["Bridgeton", "New Jersey", "NJ"],
"08310": ["Buena", "New Jersey", "NJ"],
"08311": ["Cedarville", "New Jersey", "NJ"],
"08312": ["Clayton", "New Jersey", "NJ"],
"08314": ["Delmont", "New Jersey", "NJ"],
"08316": ["Dorchester", "New Jersey", "NJ"],
"08317": ["Dorothy", "New Jersey", "NJ"],
"08318": ["Elmer", "New Jersey", "NJ"],
"08319": ["Estell Manor", "New Jersey", "NJ"],
"08320": ["Fairton", "New Jersey", "NJ"],
"08321": ["Fortescue", "New Jersey", "NJ"],
"08322": ["Franklinville", "New Jersey", "NJ"],
"08323": ["Greenwich", "New Jersey", "NJ"],
"08324": ["Heislerville", "New Jersey", "NJ"],
"08326": ["Landisville", "New Jersey", "NJ"],
"08327": ["Leesburg", "New Jersey", "NJ"],
"08328": ["Malaga", "New Jersey", "NJ"],
"08329": ["Mauricetown", "New Jersey", "NJ"],
"08330": ["Mays Landing", "New Jersey", "NJ"],
"08332": ["Millville", "New Jersey", "NJ"],
"08340": ["Milmay", "New Jersey", "NJ"],
"08341": ["Minotola", "New Jersey", "NJ"],
"08343": ["Monroeville", "New Jersey", "NJ"],
"08344": ["Newfield", "New Jersey", "NJ"],
"08345": ["Newport", "New Jersey", "NJ"],
"08346": ["Newtonville", "New Jersey", "NJ"],
"08348": ["Port Elizabeth", "New Jersey", "NJ"],
"08349": ["Port Norris", "New Jersey", "NJ"],
"08350": ["Richland", "New Jersey", "NJ"],
"08352": ["Rosenhayn", "New Jersey", "NJ"],
"08353": ["Shiloh", "New Jersey", "NJ"],
"08360": ["Vineland", "New Jersey", "NJ"],
"08361": ["Vineland", "New Jersey", "NJ"],
"08401": ["Atlantic City", "New Jersey", "NJ"],
"08402": ["Margate City", "New Jersey", "NJ"],
"08403": ["Longport", "New Jersey", "NJ"],
"08406": ["Ventnor City", "New Jersey", "NJ"],
"08501": ["Allentown", "New Jersey", "NJ"],
"08502": ["Belle Mead", "New Jersey", "NJ"],
"08505": ["Bordentown", "New Jersey", "NJ"],
"08510": ["Millstone Township", "New Jersey", "NJ"],
"08511": ["Cookstown", "New Jersey", "NJ"],
"08512": ["Cranbury", "New Jersey", "NJ"],
"08514": ["Cream Ridge", "New Jersey", "NJ"],
"08515": ["Chesterfield", "New Jersey", "NJ"],
"08518": ["Florence", "New Jersey", "NJ"],
"08520": ["Hightstown", "New Jersey", "NJ"],
"08525": ["Hopewell", "New Jersey", "NJ"],
"08527": ["Jackson", "New Jersey", "NJ"],
"08528": ["Kingston", "New Jersey", "NJ"],
"08530": ["Lambertville", "New Jersey", "NJ"],
"08533": ["New Egypt", "New Jersey", "NJ"],
"08534": ["Pennington", "New Jersey", "NJ"],
"08535": ["Millstone Township", "New Jersey", "NJ"],
"08536": ["Plainsboro", "New Jersey", "NJ"],
"08540": ["Princeton", "New Jersey", "NJ"],
"08542": ["Princeton", "New Jersey", "NJ"],
"08544": ["Princeton", "New Jersey", "NJ"],
"08550": ["Princeton Junction", "New Jersey", "NJ"],
"08551": ["Ringoes", "New Jersey", "NJ"],
"08553": ["Rocky Hill", "New Jersey", "NJ"],
"08554": ["Roebling", "New Jersey", "NJ"],
"08555": ["Roosevelt", "New Jersey", "NJ"],
"08558": ["Skillman", "New Jersey", "NJ"],
"08559": ["Stockton", "New Jersey", "NJ"],
"08560": ["Titusville", "New Jersey", "NJ"],
"08561": ["Windsor", "New Jersey", "NJ"],
"08562": ["Wrightstown", "New Jersey", "NJ"],
"08608": ["Trenton", "New Jersey", "NJ"],
"08609": ["Trenton", "New Jersey", "NJ"],
"08610": ["Trenton", "New Jersey", "NJ"],
"08611": ["Trenton", "New Jersey", "NJ"],
"08618": ["Trenton", "New Jersey", "NJ"],
"08619": ["Trenton", "New Jersey", "NJ"],
"08620": ["Trenton", "New Jersey", "NJ"],
"08628": ["Trenton", "New Jersey", "NJ"],
"08629": ["Trenton", "New Jersey", "NJ"],
"08638": ["Trenton", "New Jersey", "NJ"],
"08640": ["Joint Base Mdl", "New Jersey", "NJ"],
"08641": ["Joint Base Mdl", "New Jersey", "NJ"],
"08648": ["Lawrence Township", "New Jersey", "NJ"],
"08690": ["Trenton", "New Jersey", "NJ"],
"08691": ["Robbinsville", "New Jersey", "NJ"],
"08701": ["Lakewood", "New Jersey", "NJ"],
"08720": ["Allenwood", "New Jersey", "NJ"],
"08721": ["Bayville", "New Jersey", "NJ"],
"08722": ["Beachwood", "New Jersey", "NJ"],
"08723": ["Brick", "New Jersey", "NJ"],
"08724": ["Brick", "New Jersey", "NJ"],
"08730": ["Brielle", "New Jersey", "NJ"],
"08731": ["Forked River", "New Jersey", "NJ"],
"08732": ["Island Heights", "New Jersey", "NJ"],
"08733": ["Lakehurst", "New Jersey", "NJ"],
"08734": ["Lanoka Harbor", "New Jersey", "NJ"],
"08735": ["Lavallette", "New Jersey", "NJ"],
"08736": ["Manasquan", "New Jersey", "NJ"],
"08738": ["Mantoloking", "New Jersey", "NJ"],
"08740": ["Ocean Gate", "New Jersey", "NJ"],
"08741": ["Pine Beach", "New Jersey", "NJ"],
"08742": ["Point Pleasant Beach", "New Jersey", "NJ"],
"08750": ["Sea Girt", "New Jersey", "NJ"],
"08751": ["Seaside Heights", "New Jersey", "NJ"],
"08752": ["Seaside Park", "New Jersey", "NJ"],
"08753": ["Toms River", "New Jersey", "NJ"],
"08755": ["Toms River", "New Jersey", "NJ"],
"08757": ["Toms River", "New Jersey", "NJ"],
"08758": ["Waretown", "New Jersey", "NJ"],
"08759": ["Manchester Township", "New Jersey", "NJ"],
"08801": ["Annandale", "New Jersey", "NJ"],
"08802": ["Asbury", "New Jersey", "NJ"],
"08804": ["Bloomsbury", "New Jersey", "NJ"],
"08805": ["Bound Brook", "New Jersey", "NJ"],
"08807": ["Bridgewater", "New Jersey", "NJ"],
"08809": ["Clinton", "New Jersey", "NJ"],
"08810": ["Dayton", "New Jersey", "NJ"],
"08812": ["Dunellen", "New Jersey", "NJ"],
"08816": ["East Brunswick", "New Jersey", "NJ"],
"08817": ["Edison", "New Jersey", "NJ"],
"08820": ["Edison", "New Jersey", "NJ"],
"08821": ["Flagtown", "New Jersey", "NJ"],
"08822": ["Flemington", "New Jersey", "NJ"],
"08823": ["Franklin Park", "New Jersey", "NJ"],
"08824": ["Kendall Park", "New Jersey", "NJ"],
"08825": ["Frenchtown", "New Jersey", "NJ"],
"08826": ["Glen Gardner", "New Jersey", "NJ"],
"08827": ["Hampton", "New Jersey", "NJ"],
"08828": ["Helmetta", "New Jersey", "NJ"],
"08829": ["High Bridge", "New Jersey", "NJ"],
"08830": ["Iselin", "New Jersey", "NJ"],
"08831": ["Monroe Township", "New Jersey", "NJ"],
"08832": ["Keasbey", "New Jersey", "NJ"],
"08833": ["Lebanon", "New Jersey", "NJ"],
"08835": ["Manville", "New Jersey", "NJ"],
"08836": ["Martinsville", "New Jersey", "NJ"],
"08837": ["Edison", "New Jersey", "NJ"],
"08840": ["Metuchen", "New Jersey", "NJ"],
"08844": ["Hillsborough", "New Jersey", "NJ"],
"08846": ["Middlesex", "New Jersey", "NJ"],
"08848": ["Milford", "New Jersey", "NJ"],
"08850": ["Milltown", "New Jersey", "NJ"],
"08852": ["Monmouth Junction", "New Jersey", "NJ"],
"08853": ["Neshanic Station", "New Jersey", "NJ"],
"08854": ["Piscataway", "New Jersey", "NJ"],
"08857": ["Old Bridge", "New Jersey", "NJ"],
"08858": ["Oldwick", "New Jersey", "NJ"],
"08859": ["Parlin", "New Jersey", "NJ"],
"08861": ["Perth Amboy", "New Jersey", "NJ"],
"08863": ["Fords", "New Jersey", "NJ"],
"08865": ["Phillipsburg", "New Jersey", "NJ"],
"08867": ["Pittstown", "New Jersey", "NJ"],
"08869": ["Raritan", "New Jersey", "NJ"],
"08872": ["Sayreville", "New Jersey", "NJ"],
"08873": ["Somerset", "New Jersey", "NJ"],
"08876": ["Somerville", "New Jersey", "NJ"],
"08879": ["South Amboy", "New Jersey", "NJ"],
"08880": ["South Bound Brook", "New Jersey", "NJ"],
"08882": ["South River", "New Jersey", "NJ"],
"08884": ["Spotswood", "New Jersey", "NJ"],
"08886": ["Stewartsville", "New Jersey", "NJ"],
"08887": ["Three Bridges", "New Jersey", "NJ"],
"08889": ["Whitehouse Station", "New Jersey", "NJ"],
"08890": ["Zarephath", "New Jersey", "NJ"],
"08901": ["New Brunswick", "New Jersey", "NJ"],
"08902": ["North Brunswick", "New Jersey", "NJ"],
"08904": ["Highland Park", "New Jersey", "NJ"],
"10001": ["New York", "New York", "NY"],
"10002": ["New York", "New York", "NY"],
"10003": ["New York", "New York", "NY"],
"10004": ["New York", "New York", "NY"],
"10005": ["New York", "New York", "NY"],
"10006": ["New York", "New York", "NY"],
"10007": ["New York", "New York", "NY"],
"10009": ["New York", "New York", "NY"],
"10010": ["New York", "New York", "NY"],
"10011": ["New York", "New York", "NY"],
"10012": ["New York", "New York", "NY"],
"10013": ["New York", "New York", "NY"],
"10014": ["New York", "New York", "NY"],
"10016": ["New York", "New York", "NY"],
"10017": ["New York", "New York", "NY"],
"10018": ["New York", "New York", "NY"],
"10019": ["New York", "New York", "NY"],
"10020": ["New York", "New York", "NY"],
"10021": ["New York", "New York", "NY"],
"10022": ["New York", "New York", "NY"],
"10023": ["New York", "New York", "NY"],
"10024": ["New York", "New York", "NY"],
"10025": ["New York", "New York", "NY"],
"10026": ["New York", "New York", "NY"],
"10027": ["New York", "New York", "NY"],
"10028": ["New York", "New York", "NY"],
"10029": ["New York", "New York", "NY"],
"10030": ["New York", "New York", "NY"],
"10031": ["New York", "New York", "NY"],
"10032": ["New York", "New York", "NY"],
"10033": ["New York", "New York", "NY"],
"10034": ["New York", "New York", "NY"],
"10035": ["New York", "New York", "NY"],
"10036": ["New York", "New York", "NY"],
"10037": ["New York", "New York", "NY"],
"10038": ["New York", "New York", "NY"],
"10039": ["New York", "New York", "NY"],
"10040": ["New York", "New York", "NY"],
"10044": ["New York", "New York", "NY"],
"10065": ["New York", "New York", "NY"],
"10069": ["New York", "New York", "NY"],
"10075": ["New York", "New York", "NY"],
"10103": ["New York", "New York", "NY"],
"10110": ["New York", "New York", "NY"],
"10111": ["New York", "New York", "NY"],
"10112": ["New York", "New York", "NY"],
"10115": ["New York", "New York", "NY"],
"10119": ["New York", "New York", "NY"],
"10128": ["New York", "New York", "NY"],
"10152": ["New York", "New York", "NY"],
"10153": ["New York", "New York", "NY"],
"10154": ["New York", "New York", "NY"],
"10162": ["New York", "New York", "NY"],
"10165": ["New York", "New York", "NY"],
"10167": ["New York", "New York", "NY"],
"10168": ["New York", "New York", "NY"],
"10169": ["New York", "New York", "NY"],
"10170": ["New York", "New York", "NY"],
"10171": ["New York", "New York", "NY"],
"10172": ["New York", "New York", "NY"],
"10173": ["New York", "New York", "NY"],
"10174": ["New York", "New York", "NY"],
"10177": ["New York", "New York", "NY"],
"10199": ["New York", "New York", "NY"],
"10271": ["New York", "New York", "NY"],
"10278": ["New York", "New York", "NY"],
"10279": ["New York", "New York", "NY"],
"10280": ["New York", "New York", "NY"],
"10282": ["New York", "New York", "NY"],
"10301": ["Staten Island", "New York", "NY"],
"10302": ["Staten Island", "New York", "NY"],
"10303": ["Staten Island", "New York", "NY"],
"10304": ["Staten Island", "New York", "NY"],
"10305": ["Staten Island", "New York", "NY"],
"10306": ["Staten Island", "New York", "NY"],
"10307": ["Staten Island", "New York", "NY"],
"10308": ["Staten Island", "New York", "NY"],
"10309": ["Staten Island", "New York", "NY"],
"10310": ["Staten Island", "New York", "NY"],
"10311": ["Staten Island", "New York", "NY"],
"10312": ["Staten Island", "New York", "NY"],
"10314": ["Staten Island", "New York", "NY"],
"10451": ["Bronx", "New York", "NY"],
"10452": ["Bronx", "New York", "NY"],
"10453": ["Bronx", "New York", "NY"],
"10454": ["Bronx", "New York", "NY"],
"10455": ["Bronx", "New York", "NY"],
"10456": ["Bronx", "New York", "NY"],
"10457": ["Bronx", "New York", "NY"],
"10458": ["Bronx", "New York", "NY"],
"10459": ["Bronx", "New York", "NY"],
"10460": ["Bronx", "New York", "NY"],
"10461": ["Bronx", "New York", "NY"],
"10462": ["Bronx", "New York", "NY"],
"10463": ["Bronx", "New York", "NY"],
"10464": ["Bronx", "New York", "NY"],
"10465": ["Bronx", "New York", "NY"],
"10466": ["Bronx", "New York", "NY"],
"10467": ["Bronx", "New York", "NY"],
"10468": ["Bronx", "New York", "NY"],
"10469": ["Bronx", "New York", "NY"],
"10470": ["Bronx", "New York", "NY"],
"10471": ["Bronx", "New York", "NY"],
"10472": ["Bronx", "New York", "NY"],
"10473": ["Bronx", "New York", "NY"],
"10474": ["Bronx", "New York", "NY"],
"10475": ["Bronx", "New York", "NY"],
"10501": ["Amawalk", "New York", "NY"],
"10502": ["Ardsley", "New York", "NY"],
"10503": ["Ardsley On Hudson", "New York", "NY"],
"10504": ["Armonk", "New York", "NY"],
"10505": ["Baldwin Place", "New York", "NY"],
"10506": ["Bedford", "New York", "NY"],
"10507": ["Bedford Hills", "New York", "NY"],
"10509": ["Brewster", "New York", "NY"],
"10510": ["Briarcliff Manor", "New York", "NY"],
"10511": ["Buchanan", "New York", "NY"],
"10512": ["Carmel", "New York", "NY"],
"10514": ["Chappaqua", "New York", "NY"],
"10516": ["Cold Spring", "New York", "NY"],
"10517": ["Crompond", "New York", "NY"],
"10518": ["Cross River", "New York", "NY"],
"10519": ["Croton Falls", "New York", "NY"],
"10520": ["Croton On Hudson", "New York", "NY"],
"10522": ["Dobbs Ferry", "New York", "NY"],
"10523": ["Elmsford", "New York", "NY"],
"10524": ["Garrison", "New York", "NY"],
"10526": ["Goldens Bridge", "New York", "NY"],
"10527": ["Granite Springs", "New York", "NY"],
"10528": ["Harrison", "New York", "NY"],
"10530": ["Hartsdale", "New York", "NY"],
"10532": ["Hawthorne", "New York", "NY"],
"10533": ["Irvington", "New York", "NY"],
"10535": ["Jefferson Valley", "New York", "NY"],
"10536": ["Katonah", "New York", "NY"],
"10537": ["Lake Peekskill", "New York", "NY"],
"10538": ["Larchmont", "New York", "NY"],
"10540": ["Lincolndale", "New York", "NY"],
"10541": ["Mahopac", "New York", "NY"],
"10542": ["Mahopac Falls", "New York", "NY"],
"10543": ["Mamaroneck", "New York", "NY"],
"10545": ["Maryknoll", "New York", "NY"],
"10546": ["Millwood", "New York", "NY"],
"10547": ["Mohegan Lake", "New York", "NY"],
"10548": ["Montrose", "New York", "NY"],
"10549": ["Mount Kisco", "New York", "NY"],
"10550": ["Mount Vernon", "New York", "NY"],
"10552": ["Mount Vernon", "New York", "NY"],
"10553": ["Mount Vernon", "New York", "NY"],
"10560": ["North Salem", "New York", "NY"],
"10562": ["Ossining", "New York", "NY"],
"10566": ["Peekskill", "New York", "NY"],
"10567": ["Cortlandt Manor", "New York", "NY"],
"10570": ["Pleasantville", "New York", "NY"],
"10573": ["Port Chester", "New York", "NY"],
"10576": ["Pound Ridge", "New York", "NY"],
"10577": ["Purchase", "New York", "NY"],
"10578": ["Purdys", "New York", "NY"],
"10579": ["Putnam Valley", "New York", "NY"],
"10580": ["Rye", "New York", "NY"],
"10583": ["Scarsdale", "New York", "NY"],
"10588": ["Shrub Oak", "New York", "NY"],
"10589": ["Somers", "New York", "NY"],
"10590": ["South Salem", "New York", "NY"],
"10591": ["Tarrytown", "New York", "NY"],
"10594": ["Thornwood", "New York", "NY"],
"10595": ["Valhalla", "New York", "NY"],
"10596": ["Verplanck", "New York", "NY"],
"10597": ["Waccabuc", "New York", "NY"],
"10598": ["Yorktown Heights", "New York", "NY"],
"10601": ["White Plains", "New York", "NY"],
"10603": ["White Plains", "New York", "NY"],
"10604": ["West Harrison", "New York", "NY"],
"10605": ["White Plains", "New York", "NY"],
"10606": ["White Plains", "New York", "NY"],
"10607": ["White Plains", "New York", "NY"],
"10701": ["Yonkers", "New York", "NY"],
"10703": ["Yonkers", "New York", "NY"],
"10704": ["Yonkers", "New York", "NY"],
"10705": ["Yonkers", "New York", "NY"],
"10706": ["Hastings On Hudson", "New York", "NY"],
"10707": ["Tuckahoe", "New York", "NY"],
"10708": ["Bronxville", "New York", "NY"],
"10709": ["Eastchester", "New York", "NY"],
"10710": ["Yonkers", "New York", "NY"],
"10801": ["New Rochelle", "New York", "NY"],
"10803": ["Pelham", "New York", "NY"],
"10804": ["New Rochelle", "New York", "NY"],
"10805": ["New Rochelle", "New York", "NY"],
"10901": ["Suffern", "New York", "NY"],
"10910": ["Arden", "New York", "NY"],
"10911": ["Bear Mountain", "New York", "NY"],
"10913": ["Blauvelt", "New York", "NY"],
"10914": ["Blooming Grove", "New York", "NY"],
"10915": ["Bullville", "New York", "NY"],
"10916": ["Campbell Hall", "New York", "NY"],
"10917": ["Central Valley", "New York", "NY"],
"10918": ["Chester", "New York", "NY"],
"10919": ["Circleville", "New York", "NY"],
"10920": ["Congers", "New York", "NY"],
"10921": ["Florida", "New York", "NY"],
"10922": ["Fort Montgomery", "New York", "NY"],
"10923": ["Garnerville", "New York", "NY"],
"10924": ["Goshen", "New York", "NY"],
"10925": ["Greenwood Lake", "New York", "NY"],
"10926": ["Harriman", "New York", "NY"],
"10927": ["Haverstraw", "New York", "NY"],
"10928": ["Highland Falls", "New York", "NY"],
"10930": ["Highland Mills", "New York", "NY"],
"10931": ["Hillburn", "New York", "NY"],
"10932": ["Howells", "New York", "NY"],
"10933": ["Johnson", "New York", "NY"],
"10940": ["Middletown", "New York", "NY"],
"10941": ["Middletown", "New York", "NY"],
"10950": ["Monroe", "New York", "NY"],
"10952": ["Monsey", "New York", "NY"],
"10953": ["Mountainville", "New York", "NY"],
"10954": ["Nanuet", "New York", "NY"],
"10956": ["New City", "New York", "NY"],
"10958": ["New Hampton", "New York", "NY"],
"10960": ["Nyack", "New York", "NY"],
"10962": ["Orangeburg", "New York", "NY"],
"10963": ["Otisville", "New York", "NY"],
"10964": ["Palisades", "New York", "NY"],
"10965": ["Pearl River", "New York", "NY"],
"10968": ["Piermont", "New York", "NY"],
"10969": ["Pine Island", "New York", "NY"],
"10970": ["Pomona", "New York", "NY"],
"10973": ["Slate Hill", "New York", "NY"],
"10974": ["Sloatsburg", "New York", "NY"],
"10975": ["Southfields", "New York", "NY"],
"10976": ["Sparkill", "New York", "NY"],
"10977": ["Spring Valley", "New York", "NY"],
"10979": ["Sterling Forest", "New York", "NY"],
"10980": ["Stony Point", "New York", "NY"],
"10983": ["Tappan", "New York", "NY"],
"10984": ["Thiells", "New York", "NY"],
"10985": ["Thompson Ridge", "New York", "NY"],
"10986": ["Tomkins Cove", "New York", "NY"],
"10987": ["Tuxedo Park", "New York", "NY"],
"10988": ["Unionville", "New York", "NY"],
"10989": ["Valley Cottage", "New York", "NY"],
"10990": ["Warwick", "New York", "NY"],
"10992": ["Washingtonville", "New York", "NY"],
"10993": ["West Haverstraw", "New York", "NY"],
"10994": ["West Nyack", "New York", "NY"],
"10996": ["West Point", "New York", "NY"],
"10998": ["Westtown", "New York", "NY"],
"11001": ["Floral Park", "New York", "NY"],
"11003": ["Elmont", "New York", "NY"],
"11004": ["Glen Oaks", "New York", "NY"],
"11005": ["Floral Park", "New York", "NY"],
"11010": ["Franklin Square", "New York", "NY"],
"11020": ["Great Neck", "New York", "NY"],
"11021": ["Great Neck", "New York", "NY"],
"11023": ["Great Neck", "New York", "NY"],
"11024": ["Great Neck", "New York", "NY"],
"11030": ["Manhasset", "New York", "NY"],
"11040": ["New Hyde Park", "New York", "NY"],
"11042": ["New Hyde Park", "New York", "NY"],
"11050": ["Port Washington", "New York", "NY"],
"11096": ["Inwood", "New York", "NY"],
"11101": ["Long Island City", "New York", "NY"],
"11102": ["Astoria", "New York", "NY"],
"11103": ["Astoria", "New York", "NY"],
"11104": ["Sunnyside", "New York", "NY"],
"11105": ["Astoria", "New York", "NY"],
"11106": ["Astoria", "New York", "NY"],
"11109": ["Long Island City", "New York", "NY"],
"11201": ["Brooklyn", "New York", "NY"],
"11203": ["Brooklyn", "New York", "NY"],
"11204": ["Brooklyn", "New York", "NY"],
"11205": ["Brooklyn", "New York", "NY"],
"11206": ["Brooklyn", "New York", "NY"],
"11207": ["Brooklyn", "New York", "NY"],
"11208": ["Brooklyn", "New York", "NY"],
"11209": ["Brooklyn", "New York", "NY"],
"11210": ["Brooklyn", "New York", "NY"],
"11211": ["Brooklyn", "New York", "NY"],
"11212": ["Brooklyn", "New York", "NY"],
"11213": ["Brooklyn", "New York", "NY"],
"11214": ["Brooklyn", "New York", "NY"],
"11215": ["Brooklyn", "New York", "NY"],
"11216": ["Brooklyn", "New York", "NY"],
"11217": ["Brooklyn", "New York", "NY"],
"11218": ["Brooklyn", "New York", "NY"],
"11219": ["Brooklyn", "New York", "NY"],
"11220": ["Brooklyn", "New York", "NY"],
"11221": ["Brooklyn", "New York", "NY"],
"11222": ["Brooklyn", "New York", "NY"],
"11223": ["Brooklyn", "New York", "NY"],
"11224": ["Brooklyn", "New York", "NY"],
"11225": ["Brooklyn", "New York", "NY"],
"11226": ["Brooklyn", "New York", "NY"],
"11228": ["Brooklyn", "New York", "NY"],
"11229": ["Brooklyn", "New York", "NY"],
"11230": ["Brooklyn", "New York", "NY"],
"11231": ["Brooklyn", "New York", "NY"],
"11232": ["Brooklyn", "New York", "NY"],
"11233": ["Brooklyn", "New York", "NY"],
"11234": ["Brooklyn", "New York", "NY"],
"11235": ["Brooklyn", "New York", "NY"],
"11236": ["Brooklyn", "New York", "NY"],
"11237": ["Brooklyn", "New York", "NY"],
"11238": ["Brooklyn", "New York", "NY"],
"11239": ["Brooklyn", "New York", "NY"],
"11249": ["Brooklyn", "New York", "NY"],
"11354": ["Flushing", "New York", "NY"],
"11355": ["Flushing", "New York", "NY"],
"11356": ["College Point", "New York", "NY"],
"11357": ["Whitestone", "New York", "NY"],
"11358": ["Flushing", "New York", "NY"],
"11359": ["Bayside", "New York", "NY"],
"11360": ["Bayside", "New York", "NY"],
"11361": ["Bayside", "New York", "NY"],
"11362": ["Little Neck", "New York", "NY"],
"11363": ["Little Neck", "New York", "NY"],
"11364": ["Oakland Gardens", "New York", "NY"],
"11365": ["Fresh Meadows", "New York", "NY"],
"11366": ["Fresh Meadows", "New York", "NY"],
"11367": ["Flushing", "New York", "NY"],
"11368": ["Corona", "New York", "NY"],
"11369": ["East Elmhurst", "New York", "NY"],
"11370": ["East Elmhurst", "New York", "NY"],
"11371": ["Flushing", "New York", "NY"],
"11372": ["Jackson Heights", "New York", "NY"],
"11373": ["Elmhurst", "New York", "NY"],
"11374": ["Rego Park", "New York", "NY"],
"11375": ["Forest Hills", "New York", "NY"],
"11377": ["Woodside", "New York", "NY"],
"11378": ["Maspeth", "New York", "NY"],
"11379": ["Middle Village", "New York", "NY"],
"11385": ["Ridgewood", "New York", "NY"],
"11411": ["Cambria Heights", "New York", "NY"],
"11412": ["Saint Albans", "New York", "NY"],
"11413": ["Springfield Gardens", "New York", "NY"],
"11414": ["Howard Beach", "New York", "NY"],
"11415": ["Kew Gardens", "New York", "NY"],
"11416": ["Ozone Park", "New York", "NY"],
"11417": ["Ozone Park", "New York", "NY"],
"11418": ["Richmond Hill", "New York", "NY"],
"11419": ["South Richmond Hill", "New York", "NY"],
"11420": ["South Ozone Park", "New York", "NY"],
"11421": ["Woodhaven", "New York", "NY"],
"11422": ["Rosedale", "New York", "NY"],
"11423": ["Hollis", "New York", "NY"],
"11424": ["Jamaica", "New York", "NY"],
"11426": ["Bellerose", "New York", "NY"],
"11427": ["Queens Village", "New York", "NY"],
"11428": ["Queens Village", "New York", "NY"],
"11429": ["Queens Village", "New York", "NY"],
"11430": ["Jamaica", "New York", "NY"],
"11432": ["Jamaica", "New York", "NY"],
"11433": ["Jamaica", "New York", "NY"],
"11434": ["Jamaica", "New York", "NY"],
"11435": ["Jamaica", "New York", "NY"],
"11436": ["Jamaica", "New York", "NY"],
"11439": ["Jamaica", "New York", "NY"],
"11451": ["Jamaica", "New York", "NY"],
"11501": ["Mineola", "New York", "NY"],
"11507": ["Albertson", "New York", "NY"],
"11509": ["Atlantic Beach", "New York", "NY"],
"11510": ["Baldwin", "New York", "NY"],
"11514": ["Carle Place", "New York", "NY"],
"11516": ["Cedarhurst", "New York", "NY"],
"11518": ["East Rockaway", "New York", "NY"],
"11520": ["Freeport", "New York", "NY"],
"11530": ["Garden City", "New York", "NY"],
"11542": ["Glen Cove", "New York", "NY"],
"11545": ["Glen Head", "New York", "NY"],
"11547": ["Glenwood Landing", "New York", "NY"],
"11548": ["Greenvale", "New York", "NY"],
"11549": ["Hempstead", "New York", "NY"],
"11550": ["Hempstead", "New York", "NY"],
"11552": ["West Hempstead", "New York", "NY"],
"11553": ["Uniondale", "New York", "NY"],
"11554": ["East Meadow", "New York", "NY"],
"11556": ["Uniondale", "New York", "NY"],
"11557": ["Hewlett", "New York", "NY"],
"11558": ["Island Park", "New York", "NY"],
"11559": ["Lawrence", "New York", "NY"],
"11560": ["Locust Valley", "New York", "NY"],
"11561": ["Long Beach", "New York", "NY"],
"11563": ["Lynbrook", "New York", "NY"],
"11565": ["Malverne", "New York", "NY"],
"11566": ["Merrick", "New York", "NY"],
"11568": ["Old Westbury", "New York", "NY"],
"11569": ["Point Lookout", "New York", "NY"],
"11570": ["Rockville Centre", "New York", "NY"],
"11572": ["Oceanside", "New York", "NY"],
"11575": ["Roosevelt", "New York", "NY"],
"11576": ["Roslyn", "New York", "NY"],
"11577": ["Roslyn Heights", "New York", "NY"],
"11579": ["Sea Cliff", "New York", "NY"],
"11580": ["Valley Stream", "New York", "NY"],
"11581": ["Valley Stream", "New York", "NY"],
"11590": ["Westbury", "New York", "NY"],
"11596": ["Williston Park", "New York", "NY"],
"11598": ["Woodmere", "New York", "NY"],
"11691": ["Far Rockaway", "New York", "NY"],
"11692": ["Arverne", "New York", "NY"],
"11693": ["Far Rockaway", "New York", "NY"],
"11694": ["Rockaway Park", "New York", "NY"],
"11697": ["Breezy Point", "New York", "NY"],
"11701": ["Amityville", "New York", "NY"],
"11702": ["Babylon", "New York", "NY"],
"11703": ["North Babylon", "New York", "NY"],
"11704": ["West Babylon", "New York", "NY"],
"11705": ["Bayport", "New York", "NY"],
"11706": ["Bay Shore", "New York", "NY"],
"11709": ["Bayville", "New York", "NY"],
"11710": ["Bellmore", "New York", "NY"],
"11713": ["Bellport", "New York", "NY"],
"11714": ["Bethpage", "New York", "NY"],
"11715": ["Blue Point", "New York", "NY"],
"11716": ["Bohemia", "New York", "NY"],
"11717": ["Brentwood", "New York", "NY"],
"11718": ["Brightwaters", "New York", "NY"],
"11719": ["Brookhaven", "New York", "NY"],
"11720": ["Centereach", "New York", "NY"],
"11721": ["Centerport", "New York", "NY"],
"11722": ["Central Islip", "New York", "NY"],
"11724": ["Cold Spring Harbor", "New York", "NY"],
"11725": ["Commack", "New York", "NY"],
"11726": ["Copiague", "New York", "NY"],
"11727": ["Coram", "New York", "NY"],
"11729": ["Deer Park", "New York", "NY"],
"11730": ["East Islip", "New York", "NY"],
"11731": ["East Northport", "New York", "NY"],
"11732": ["East Norwich", "New York", "NY"],
"11733": ["East Setauket", "New York", "NY"],
"11735": ["Farmingdale", "New York", "NY"],
"11738": ["Farmingville", "New York", "NY"],
"11739": ["Great River", "New York", "NY"],
"11740": ["Greenlawn", "New York", "NY"],
"11741": ["Holbrook", "New York", "NY"],
"11742": ["Holtsville", "New York", "NY"],
"11743": ["Huntington", "New York", "NY"],
"11746": ["Huntington Station", "New York", "NY"],
"11747": ["Melville", "New York", "NY"],
"11749": ["Islandia", "New York", "NY"],
"11751": ["Islip", "New York", "NY"],
"11752": ["Islip Terrace", "New York", "NY"],
"11753": ["Jericho", "New York", "NY"],
"11754": ["Kings Park", "New York", "NY"],
"11755": ["Lake Grove", "New York", "NY"],
"11756": ["Levittown", "New York", "NY"],
"11757": ["Lindenhurst", "New York", "NY"],
"11758": ["Massapequa", "New York", "NY"],
"11762": ["Massapequa Park", "New York", "NY"],
"11763": ["Medford", "New York", "NY"],
"11764": ["Miller Place", "New York", "NY"],
"11765": ["Mill Neck", "New York", "NY"],
"11766": ["Mount Sinai", "New York", "NY"],
"11767": ["Nesconset", "New York", "NY"],
"11768": ["Northport", "New York", "NY"],
"11769": ["Oakdale", "New York", "NY"],
"11770": ["Ocean Beach", "New York", "NY"],
"11771": ["Oyster Bay", "New York", "NY"],
"11772": ["Patchogue", "New York", "NY"],
"11776": ["Port Jefferson Station", "New York", "NY"],
"11777": ["Port Jefferson", "New York", "NY"],
"11778": ["Rocky Point", "New York", "NY"],
"11779": ["Ronkonkoma", "New York", "NY"],
"11780": ["Saint James", "New York", "NY"],
"11782": ["Sayville", "New York", "NY"],
"11783": ["Seaford", "New York", "NY"],
"11784": ["Selden", "New York", "NY"],
"11786": ["Shoreham", "New York", "NY"],
"11787": ["Smithtown", "New York", "NY"],
"11788": ["Hauppauge", "New York", "NY"],
"11789": ["Sound Beach", "New York", "NY"],
"11790": ["Stony Brook", "New York", "NY"],
"11791": ["Syosset", "New York", "NY"],
"11792": ["Wading River", "New York", "NY"],
"11793": ["Wantagh", "New York", "NY"],
"11794": ["Stony Brook", "New York", "NY"],
"11795": ["West Islip", "New York", "NY"],
"11796": ["West Sayville", "New York", "NY"],
"11797": ["Woodbury", "New York", "NY"],
"11798": ["Wyandanch", "New York", "NY"],
"11801": ["Hicksville", "New York", "NY"],
"11803": ["Plainview", "New York", "NY"],
"11804": ["Old Bethpage", "New York", "NY"],
"11901": ["Riverhead", "New York", "NY"],
"11930": ["Amagansett", "New York", "NY"],
"11931": ["Aquebogue", "New York", "NY"],
"11932": ["Bridgehampton", "New York", "NY"],
"11933": ["Calverton", "New York", "NY"],
"11934": ["Center Moriches", "New York", "NY"],
"11935": ["Cutchogue", "New York", "NY"],
"11937": ["East Hampton", "New York", "NY"],
"11939": ["East Marion", "New York", "NY"],
"11940": ["East Moriches", "New York", "NY"],
"11941": ["Eastport", "New York", "NY"],
"11942": ["East Quogue", "New York", "NY"],
"11944": ["Greenport", "New York", "NY"],
"11946": ["Hampton Bays", "New York", "NY"],
"11947": ["Jamesport", "New York", "NY"],
"11948": ["Laurel", "New York", "NY"],
"11949": ["Manorville", "New York", "NY"],
"11950": ["Mastic", "New York", "NY"],
"11951": ["Mastic Beach", "New York", "NY"],
"11952": ["Mattituck", "New York", "NY"],
"11953": ["Middle Island", "New York", "NY"],
"11954": ["Montauk", "New York", "NY"],
"11955": ["Moriches", "New York", "NY"],
"11956": ["New Suffolk", "New York", "NY"],
"11957": ["Orient", "New York", "NY"],
"11958": ["Peconic", "New York", "NY"],
"11959": ["Quogue", "New York", "NY"],
"11960": ["Remsenburg", "New York", "NY"],
"11961": ["Ridge", "New York", "NY"],
"11962": ["Sagaponack", "New York", "NY"],
"11963": ["Sag Harbor", "New York", "NY"],
"11964": ["Shelter Island", "New York", "NY"],
"11965": ["Shelter Island Heights", "New York", "NY"],
"11967": ["Shirley", "New York", "NY"],
"11968": ["Southampton", "New York", "NY"],
"11970": ["South Jamesport", "New York", "NY"],
"11971": ["Southold", "New York", "NY"],
"11972": ["Speonk", "New York", "NY"],
"11973": ["Upton", "New York", "NY"],
"11975": ["Wainscott", "New York", "NY"],
"11976": ["Water Mill", "New York", "NY"],
"11977": ["Westhampton", "New York", "NY"],
"11978": ["Westhampton Beach", "New York", "NY"],
"11980": ["Yaphank", "New York", "NY"],
"12007": ["Alcove", "New York", "NY"],
"12008": ["Alplaus", "New York", "NY"],
"12009": ["Altamont", "New York", "NY"],
"12010": ["Amsterdam", "New York", "NY"],
"12015": ["Athens", "New York", "NY"],
"12017": ["Austerlitz", "New York", "NY"],
"12018": ["Averill Park", "New York", "NY"],
"12019": ["Ballston Lake", "New York", "NY"],
"12020": ["Ballston Spa", "New York", "NY"],
"12022": ["Berlin", "New York", "NY"],
"12023": ["Berne", "New York", "NY"],
"12024": ["Brainard", "New York", "NY"],
"12025": ["Broadalbin", "New York", "NY"],
"12027": ["Burnt Hills", "New York", "NY"],
"12028": ["Buskirk", "New York", "NY"],
"12029": ["Canaan", "New York", "NY"],
"12031": ["Carlisle", "New York", "NY"],
"12032": ["Caroga Lake", "New York", "NY"],
"12033": ["Castleton On Hudson", "New York", "NY"],
"12035": ["Central Bridge", "New York", "NY"],
"12036": ["Charlotteville", "New York", "NY"],
"12037": ["Chatham", "New York", "NY"],
"12040": ["Cherry Plain", "New York", "NY"],
"12041": ["Clarksville", "New York", "NY"],
"12042": ["Climax", "New York", "NY"],
"12043": ["Cobleskill", "New York", "NY"],
"12045": ["Coeymans", "New York", "NY"],
"12046": ["Coeymans Hollow", "New York", "NY"],
"12047": ["Cohoes", "New York", "NY"],
"12051": ["Coxsackie", "New York", "NY"],
"12052": ["Cropseyville", "New York", "NY"],
"12053": ["Delanson", "New York", "NY"],
"12054": ["Delmar", "New York", "NY"],
"12056": ["Duanesburg", "New York", "NY"],
"12057": ["Eagle Bridge", "New York", "NY"],
"12058": ["Earlton", "New York", "NY"],
"12059": ["East Berne", "New York", "NY"],
"12060": ["East Chatham", "New York", "NY"],
"12061": ["East Greenbush", "New York", "NY"],
"12062": ["East Nassau", "New York", "NY"],
"12063": ["East Schodack", "New York", "NY"],
"12064": ["East Worcester", "New York", "NY"],
"12065": ["Clifton Park", "New York", "NY"],
"12066": ["Esperance", "New York", "NY"],
"12067": ["Feura Bush", "New York", "NY"],
"12068": ["Fonda", "New York", "NY"],
"12069": ["Fort Hunter", "New York", "NY"],
"12070": ["Fort Johnson", "New York", "NY"],
"12071": ["Fultonham", "New York", "NY"],
"12072": ["Fultonville", "New York", "NY"],
"12074": ["Galway", "New York", "NY"],
"12075": ["Ghent", "New York", "NY"],
"12076": ["Gilboa", "New York", "NY"],
"12077": ["Glenmont", "New York", "NY"],
"12078": ["Gloversville", "New York", "NY"],
"12082": ["Grafton", "New York", "NY"],
"12083": ["Greenville", "New York", "NY"],
"12084": ["Guilderland", "New York", "NY"],
"12085": ["Guilderland Center", "New York", "NY"],
"12086": ["Hagaman", "New York", "NY"],
"12087": ["Hannacroix", "New York", "NY"],
"12090": ["Hoosick Falls", "New York", "NY"],
"12092": ["Howes Cave", "New York", "NY"],
"12093": ["Jefferson", "New York", "NY"],
"12094": ["Johnsonville", "New York", "NY"],
"12095": ["Johnstown", "New York", "NY"],
"12106": ["Kinderhook", "New York", "NY"],
"12108": ["Lake Pleasant", "New York", "NY"],
"12110": ["Latham", "New York", "NY"],
"12115": ["Malden Bridge", "New York", "NY"],
"12116": ["Maryland", "New York", "NY"],
"12117": ["Mayfield", "New York", "NY"],
"12118": ["Mechanicville", "New York", "NY"],
"12120": ["Medusa", "New York", "NY"],
"12121": ["Melrose", "New York", "NY"],
"12122": ["Middleburgh", "New York", "NY"],
"12123": ["Nassau", "New York", "NY"],
"12124": ["New Baltimore", "New York", "NY"],
"12125": ["New Lebanon", "New York", "NY"],
"12130": ["Niverville", "New York", "NY"],
"12131": ["North Blenheim", "New York", "NY"],
"12132": ["North Chatham", "New York", "NY"],
"12134": ["Northville", "New York", "NY"],
"12136": ["Old Chatham", "New York", "NY"],
"12137": ["Pattersonville", "New York", "NY"],
"12138": ["Petersburg", "New York", "NY"],
"12139": ["Piseco", "New York", "NY"],
"12140": ["Poestenkill", "New York", "NY"],
"12143": ["Ravena", "New York", "NY"],
"12144": ["Rensselaer", "New York", "NY"],
"12147": ["Rensselaerville", "New York", "NY"],
"12148": ["Rexford", "New York", "NY"],
"12149": ["Richmondville", "New York", "NY"],
"12150": ["Rotterdam Junction", "New York", "NY"],
"12151": ["Round Lake", "New York", "NY"],
"12153": ["Sand Lake", "New York", "NY"],
"12154": ["Schaghticoke", "New York", "NY"],
"12155": ["Schenevus", "New York", "NY"],
"12156": ["Schodack Landing", "New York", "NY"],
"12157": ["Schoharie", "New York", "NY"],
"12158": ["Selkirk", "New York", "NY"],
"12159": ["Slingerlands", "New York", "NY"],
"12160": ["Sloansville", "New York", "NY"],
"12161": ["South Bethlehem", "New York", "NY"],
"12164": ["Speculator", "New York", "NY"],
"12165": ["Spencertown", "New York", "NY"],
"12166": ["Sprakers", "New York", "NY"],
"12167": ["Stamford", "New York", "NY"],
"12168": ["Stephentown", "New York", "NY"],
"12169": ["Stephentown", "New York", "NY"],
"12170": ["Stillwater", "New York", "NY"],
"12172": ["Stottville", "New York", "NY"],
"12173": ["Stuyvesant", "New York", "NY"],
"12174": ["Stuyvesant Falls", "New York", "NY"],
"12175": ["Summit", "New York", "NY"],
"12176": ["Surprise", "New York", "NY"],
"12177": ["Tribes Hill", "New York", "NY"],
"12180": ["Troy", "New York", "NY"],
"12182": ["Troy", "New York", "NY"],
"12183": ["Troy", "New York", "NY"],
"12184": ["Valatie", "New York", "NY"],
"12185": ["Valley Falls", "New York", "NY"],
"12186": ["Voorheesville", "New York", "NY"],
"12187": ["Warnerville", "New York", "NY"],
"12188": ["Waterford", "New York", "NY"],
"12189": ["Watervliet", "New York", "NY"],
"12190": ["Wells", "New York", "NY"],
"12192": ["West Coxsackie", "New York", "NY"],
"12193": ["Westerlo", "New York", "NY"],
"12194": ["West Fulton", "New York", "NY"],
"12195": ["West Lebanon", "New York", "NY"],
"12196": ["West Sand Lake", "New York", "NY"],
"12197": ["Worcester", "New York", "NY"],
"12198": ["Wynantskill", "New York", "NY"],
"12202": ["Albany", "New York", "NY"],
"12203": ["Albany", "New York", "NY"],
"12204": ["Albany", "New York", "NY"],
"12205": ["Albany", "New York", "NY"],
"12206": ["Albany", "New York", "NY"],
"12207": ["Albany", "New York", "NY"],
"12208": ["Albany", "New York", "NY"],
"12209": ["Albany", "New York", "NY"],
"12210": ["Albany", "New York", "NY"],
"12211": ["Albany", "New York", "NY"],
"12222": ["Albany", "New York", "NY"],
"12226": ["Albany", "New York", "NY"],
"12302": ["Schenectady", "New York", "NY"],
"12303": ["Schenectady", "New York", "NY"],
"12304": ["Schenectady", "New York", "NY"],
"12305": ["Schenectady", "New York", "NY"],
"12306": ["Schenectady", "New York", "NY"],
"12307": ["Schenectady", "New York", "NY"],
"12308": ["Schenectady", "New York", "NY"],
"12309": ["Schenectady", "New York", "NY"],
"12401": ["Kingston", "New York", "NY"],
"12404": ["Accord", "New York", "NY"],
"12405": ["Acra", "New York", "NY"],
"12406": ["Arkville", "New York", "NY"],
"12407": ["Ashland", "New York", "NY"],
"12409": ["Bearsville", "New York", "NY"],
"12410": ["Big Indian", "New York", "NY"],
"12411": ["Bloomington", "New York", "NY"],
"12412": ["Boiceville", "New York", "NY"],
"12413": ["Cairo", "New York", "NY"],
"12414": ["Catskill", "New York", "NY"],
"12416": ["Chichester", "New York", "NY"],
"12417": ["Connelly", "New York", "NY"],
"12418": ["Cornwallville", "New York", "NY"],
"12419": ["Cottekill", "New York", "NY"],
"12420": ["Cragsmoor", "New York", "NY"],
"12421": ["Denver", "New York", "NY"],
"12422": ["Durham", "New York", "NY"],
"12423": ["East Durham", "New York", "NY"],
"12424": ["East Jewett", "New York", "NY"],
"12427": ["Elka Park", "New York", "NY"],
"12428": ["Ellenville", "New York", "NY"],
"12429": ["Esopus", "New York", "NY"],
"12430": ["Fleischmanns", "New York", "NY"],
"12431": ["Freehold", "New York", "NY"],
"12432": ["Glasco", "New York", "NY"],
"12433": ["Glenford", "New York", "NY"],
"12434": ["Grand Gorge", "New York", "NY"],
"12435": ["Greenfield Park", "New York", "NY"],
"12436": ["Haines Falls", "New York", "NY"],
"12438": ["Halcottsville", "New York", "NY"],
"12439": ["Hensonville", "New York", "NY"],
"12440": ["High Falls", "New York", "NY"],
"12441": ["Highmount", "New York", "NY"],
"12442": ["Hunter", "New York", "NY"],
"12443": ["Hurley", "New York", "NY"],
"12444": ["Jewett", "New York", "NY"],
"12446": ["Kerhonkson", "New York", "NY"],
"12448": ["Lake Hill", "New York", "NY"],
"12449": ["Lake Katrine", "New York", "NY"],
"12450": ["Lanesville", "New York", "NY"],
"12451": ["Leeds", "New York", "NY"],
"12452": ["Lexington", "New York", "NY"],
"12453": ["Malden On Hudson", "New York", "NY"],
"12454": ["Maplecrest", "New York", "NY"],
"12455": ["Margaretville", "New York", "NY"],
"12456": ["Mount Marion", "New York", "NY"],
"12457": ["Mount Tremper", "New York", "NY"],
"12458": ["Napanoch", "New York", "NY"],
"12459": ["New Kingston", "New York", "NY"],
"12460": ["Oak Hill", "New York", "NY"],
"12461": ["Olivebridge", "New York", "NY"],
"12463": ["Palenville", "New York", "NY"],
"12464": ["Phoenicia", "New York", "NY"],
"12465": ["Pine Hill", "New York", "NY"],
"12466": ["Port Ewen", "New York", "NY"],
"12468": ["Prattsville", "New York", "NY"],
"12469": ["Preston Hollow", "New York", "NY"],
"12470": ["Purling", "New York", "NY"],
"12471": ["Rifton", "New York", "NY"],
"12472": ["Rosendale", "New York", "NY"],
"12473": ["Round Top", "New York", "NY"],
"12474": ["Roxbury", "New York", "NY"],
"12475": ["Ruby", "New York", "NY"],
"12477": ["Saugerties", "New York", "NY"],
"12480": ["Shandaken", "New York", "NY"],
"12481": ["Shokan", "New York", "NY"],
"12482": ["South Cairo", "New York", "NY"],
"12483": ["Spring Glen", "New York", "NY"],
"12484": ["Stone Ridge", "New York", "NY"],
"12485": ["Tannersville", "New York", "NY"],
"12486": ["Tillson", "New York", "NY"],
"12487": ["Ulster Park", "New York", "NY"],
"12489": ["Wawarsing", "New York", "NY"],
"12490": ["West Camp", "New York", "NY"],
"12491": ["West Hurley", "New York", "NY"],
"12492": ["West Kill", "New York", "NY"],
"12493": ["West Park", "New York", "NY"],
"12494": ["West Shokan", "New York", "NY"],
"12495": ["Willow", "New York", "NY"],
"12496": ["Windham", "New York", "NY"],
"12498": ["Woodstock", "New York", "NY"],
"12501": ["Amenia", "New York", "NY"],
"12502": ["Ancram", "New York", "NY"],
"12503": ["Ancramdale", "New York", "NY"],
"12504": ["Annandale On Hudson", "New York", "NY"],
"12507": ["Barrytown", "New York", "NY"],
"12508": ["Beacon", "New York", "NY"],
"12512": ["Chelsea", "New York", "NY"],
"12513": ["Claverack", "New York", "NY"],
"12514": ["Clinton Corners", "New York", "NY"],
"12515": ["Clintondale", "New York", "NY"],
"12516": ["Copake", "New York", "NY"],
"12517": ["Copake Falls", "New York", "NY"],
"12518": ["Cornwall", "New York", "NY"],
"12520": ["Cornwall On Hudson", "New York", "NY"],
"12521": ["Craryville", "New York", "NY"],
"12522": ["Dover Plains", "New York", "NY"],
"12523": ["Elizaville", "New York", "NY"],
"12524": ["Fishkill", "New York", "NY"],
"12525": ["Gardiner", "New York", "NY"],
"12526": ["Germantown", "New York", "NY"],
"12527": ["Glenham", "New York", "NY"],
"12528": ["Highland", "New York", "NY"],
"12529": ["Hillsdale", "New York", "NY"],
"12530": ["Hollowville", "New York", "NY"],
"12531": ["Holmes", "New York", "NY"],
"12533": ["Hopewell Junction", "New York", "NY"],
"12534": ["Hudson", "New York", "NY"],
"12538": ["Hyde Park", "New York", "NY"],
"12540": ["Lagrangeville", "New York", "NY"],
"12542": ["Marlboro", "New York", "NY"],
"12543": ["Maybrook", "New York", "NY"],
"12544": ["Mellenville", "New York", "NY"],
"12545": ["Millbrook", "New York", "NY"],
"12546": ["Millerton", "New York", "NY"],
"12547": ["Milton", "New York", "NY"],
"12548": ["Modena", "New York", "NY"],
"12549": ["Montgomery", "New York", "NY"],
"12550": ["Newburgh", "New York", "NY"],
"12553": ["New Windsor", "New York", "NY"],
"12561": ["New Paltz", "New York", "NY"],
"12563": ["Patterson", "New York", "NY"],
"12564": ["Pawling", "New York", "NY"],
"12565": ["Philmont", "New York", "NY"],
"12566": ["Pine Bush", "New York", "NY"],
"12567": ["Pine Plains", "New York", "NY"],
"12569": ["Pleasant Valley", "New York", "NY"],
"12570": ["Poughquag", "New York", "NY"],
"12571": ["Red Hook", "New York", "NY"],
"12572": ["Rhinebeck", "New York", "NY"],
"12574": ["Rhinecliff", "New York", "NY"],
"12575": ["Rock Tavern", "New York", "NY"],
"12577": ["Salisbury Mills", "New York", "NY"],
"12578": ["Salt Point", "New York", "NY"],
"12580": ["Staatsburg", "New York", "NY"],
"12581": ["Stanfordville", "New York", "NY"],
"12582": ["Stormville", "New York", "NY"],
"12583": ["Tivoli", "New York", "NY"],
"12585": ["Verbank", "New York", "NY"],
"12586": ["Walden", "New York", "NY"],
"12589": ["Wallkill", "New York", "NY"],
"12590": ["Wappingers Falls", "New York", "NY"],
"12592": ["Wassaic", "New York", "NY"],
"12594": ["Wingdale", "New York", "NY"],
"12601": ["Poughkeepsie", "New York", "NY"],
"12603": ["Poughkeepsie", "New York", "NY"],
"12604": ["Poughkeepsie", "New York", "NY"],
"12701": ["Monticello", "New York", "NY"],
"12719": ["Barryville", "New York", "NY"],
"12720": ["Bethel", "New York", "NY"],
"12721": ["Bloomingburg", "New York", "NY"],
"12722": ["Burlingham", "New York", "NY"],
"12723": ["Callicoon", "New York", "NY"],
"12724": ["Callicoon Center", "New York", "NY"],
"12725": ["Claryville", "New York", "NY"],
"12726": ["Cochecton", "New York", "NY"],
"12729": ["Cuddebackville", "New York", "NY"],
"12732": ["Eldred", "New York", "NY"],
"12733": ["Fallsburg", "New York", "NY"],
"12734": ["Ferndale", "New York", "NY"],
"12736": ["Fremont Center", "New York", "NY"],
"12737": ["Glen Spey", "New York", "NY"],
"12738": ["Glen Wild", "New York", "NY"],
"12740": ["Grahamsville", "New York", "NY"],
"12741": ["Hankins", "New York", "NY"],
"12742": ["Harris", "New York", "NY"],
"12743": ["Highland Lake", "New York", "NY"],
"12745": ["Hortonville", "New York", "NY"],
"12746": ["Huguenot", "New York", "NY"],
"12747": ["Hurleyville", "New York", "NY"],
"12748": ["Jeffersonville", "New York", "NY"],
"12749": ["Kauneonga Lake", "New York", "NY"],
"12750": ["Kenoza Lake", "New York", "NY"],
"12751": ["Kiamesha Lake", "New York", "NY"],
"12752": ["Lake Huntington", "New York", "NY"],
"12754": ["Liberty", "New York", "NY"],
"12758": ["Livingston Manor", "New York", "NY"],
"12759": ["Loch Sheldrake", "New York", "NY"],
"12760": ["Long Eddy", "New York", "NY"],
"12762": ["Mongaup Valley", "New York", "NY"],
"12763": ["Mountain Dale", "New York", "NY"],
"12764": ["Narrowsburg", "New York", "NY"],
"12765": ["Neversink", "New York", "NY"],
"12766": ["North Branch", "New York", "NY"],
"12767": ["Obernburg", "New York", "NY"],
"12768": ["Parksville", "New York", "NY"],
"12769": ["Phillipsport", "New York", "NY"],
"12770": ["Pond Eddy", "New York", "NY"],
"12771": ["Port Jervis", "New York", "NY"],
"12775": ["Rock Hill", "New York", "NY"],
"12776": ["Roscoe", "New York", "NY"],
"12777": ["Forestburgh", "New York", "NY"],
"12778": ["Smallwood", "New York", "NY"],
"12779": ["South Fallsburg", "New York", "NY"],
"12780": ["Sparrow Bush", "New York", "NY"],
"12781": ["Summitville", "New York", "NY"],
"12783": ["Swan Lake", "New York", "NY"],
"12784": ["Thompsonville", "New York", "NY"],
"12785": ["Westbrookville", "New York", "NY"],
"12786": ["White Lake", "New York", "NY"],
"12787": ["White Sulphur Springs", "New York", "NY"],
"12788": ["Woodbourne", "New York", "NY"],
"12789": ["Woodridge", "New York", "NY"],
"12790": ["Wurtsboro", "New York", "NY"],
"12791": ["Youngsville", "New York", "NY"],
"12792": ["Yulan", "New York", "NY"],
"12801": ["Glens Falls", "New York", "NY"],
"12803": ["South Glens Falls", "New York", "NY"],
"12804": ["Queensbury", "New York", "NY"],
"12808": ["Adirondack", "New York", "NY"],
"12809": ["Argyle", "New York", "NY"],
"12810": ["Athol", "New York", "NY"],
"12811": ["Bakers Mills", "New York", "NY"],
"12812": ["Blue Mountain Lake", "New York", "NY"],
"12814": ["Bolton Landing", "New York", "NY"],
"12815": ["Brant Lake", "New York", "NY"],
"12816": ["Cambridge", "New York", "NY"],
"12817": ["Chestertown", "New York", "NY"],
"12819": ["Clemons", "New York", "NY"],
"12820": ["Cleverdale", "New York", "NY"],
"12821": ["Comstock", "New York", "NY"],
"12822": ["Corinth", "New York", "NY"],
"12823": ["Cossayuna", "New York", "NY"],
"12824": ["Diamond Point", "New York", "NY"],
"12827": ["Fort Ann", "New York", "NY"],
"12828": ["Fort Edward", "New York", "NY"],
"12831": ["Gansevoort", "New York", "NY"],
"12832": ["Granville", "New York", "NY"],
"12833": ["Greenfield Center", "New York", "NY"],
"12834": ["Greenwich", "New York", "NY"],
"12835": ["Hadley", "New York", "NY"],
"12836": ["Hague", "New York", "NY"],
"12837": ["Hampton", "New York", "NY"],
"12838": ["Hartford", "New York", "NY"],
"12839": ["Hudson Falls", "New York", "NY"],
"12841": ["Huletts Landing", "New York", "NY"],
"12842": ["Indian Lake", "New York", "NY"],
"12843": ["Johnsburg", "New York", "NY"],
"12844": ["Kattskill Bay", "New York", "NY"],
"12845": ["Lake George", "New York", "NY"],
"12846": ["Lake Luzerne", "New York", "NY"],
"12847": ["Long Lake", "New York", "NY"],
"12849": ["Middle Granville", "New York", "NY"],
"12850": ["Middle Grove", "New York", "NY"],
"12851": ["Minerva", "New York", "NY"],
"12852": ["Newcomb", "New York", "NY"],
"12853": ["North Creek", "New York", "NY"],
"12854": ["North Granville", "New York", "NY"],
"12855": ["North Hudson", "New York", "NY"],
"12856": ["North River", "New York", "NY"],
"12857": ["Olmstedville", "New York", "NY"],
"12858": ["Paradox", "New York", "NY"],
"12859": ["Porter Corners", "New York", "NY"],
"12860": ["Pottersville", "New York", "NY"],
"12861": ["Putnam Station", "New York", "NY"],
"12862": ["Riparius", "New York", "NY"],
"12863": ["Rock City Falls", "New York", "NY"],
"12864": ["Sabael", "New York", "NY"],
"12865": ["Salem", "New York", "NY"],
"12866": ["Saratoga Springs", "New York", "NY"],
"12870": ["Schroon Lake", "New York", "NY"],
"12871": ["Schuylerville", "New York", "NY"],
"12872": ["Severance", "New York", "NY"],
"12873": ["Shushan", "New York", "NY"],
"12874": ["Silver Bay", "New York", "NY"],
"12878": ["Stony Creek", "New York", "NY"],
"12883": ["Ticonderoga", "New York", "NY"],
"12884": ["Victory Mills", "New York", "NY"],
"12885": ["Warrensburg", "New York", "NY"],
"12886": ["Wevertown", "New York", "NY"],
"12887": ["Whitehall", "New York", "NY"],
"12901": ["Plattsburgh", "New York", "NY"],
"12903": ["Plattsburgh", "New York", "NY"],
"12910": ["Altona", "New York", "NY"],
"12911": ["Keeseville", "New York", "NY"],
"12912": ["Au Sable Forks", "New York", "NY"],
"12913": ["Bloomingdale", "New York", "NY"],
"12914": ["Bombay", "New York", "NY"],
"12915": ["Brainardsville", "New York", "NY"],
"12916": ["Brushton", "New York", "NY"],
"12917": ["Burke", "New York", "NY"],
"12918": ["Cadyville", "New York", "NY"],
"12919": ["Champlain", "New York", "NY"],
"12920": ["Chateaugay", "New York", "NY"],
"12921": ["Chazy", "New York", "NY"],
"12922": ["Childwold", "New York", "NY"],
"12923": ["Churubusco", "New York", "NY"],
"12924": ["Keeseville", "New York", "NY"],
"12926": ["Constable", "New York", "NY"],
"12927": ["Cranberry Lake", "New York", "NY"],
"12928": ["Crown Point", "New York", "NY"],
"12929": ["Dannemora", "New York", "NY"],
"12930": ["Dickinson Center", "New York", "NY"],
"12932": ["Elizabethtown", "New York", "NY"],
"12933": ["Ellenburg", "New York", "NY"],
"12934": ["Ellenburg Center", "New York", "NY"],
"12935": ["Ellenburg Depot", "New York", "NY"],
"12936": ["Essex", "New York", "NY"],
"12937": ["Fort Covington", "New York", "NY"],
"12939": ["Gabriels", "New York", "NY"],
"12941": ["Jay", "New York", "NY"],
"12942": ["Keene", "New York", "NY"],
"12943": ["Keene Valley", "New York", "NY"],
"12944": ["Keeseville", "New York", "NY"],
"12945": ["Lake Clear", "New York", "NY"],
"12946": ["Lake Placid", "New York", "NY"],
"12950": ["Lewis", "New York", "NY"],
"12952": ["Lyon Mountain", "New York", "NY"],
"12953": ["Malone", "New York", "NY"],
"12955": ["Lyon Mountain", "New York", "NY"],
"12956": ["Mineville", "New York", "NY"],
"12957": ["Moira", "New York", "NY"],
"12958": ["Mooers", "New York", "NY"],
"12959": ["Mooers Forks", "New York", "NY"],
"12960": ["Moriah", "New York", "NY"],
"12961": ["Moriah Center", "New York", "NY"],
"12962": ["Morrisonville", "New York", "NY"],
"12964": ["New Russia", "New York", "NY"],
"12965": ["Nicholville", "New York", "NY"],
"12966": ["North Bangor", "New York", "NY"],
"12967": ["North Lawrence", "New York", "NY"],
"12969": ["Owls Head", "New York", "NY"],
"12970": ["Paul Smiths", "New York", "NY"],
"12972": ["Peru", "New York", "NY"],
"12973": ["Piercefield", "New York", "NY"],
"12974": ["Port Henry", "New York", "NY"],
"12975": ["Port Kent", "New York", "NY"],
"12976": ["Rainbow Lake", "New York", "NY"],
"12977": ["Ray Brook", "New York", "NY"],
"12978": ["Redford", "New York", "NY"],
"12979": ["Rouses Point", "New York", "NY"],
"12980": ["Saint Regis Falls", "New York", "NY"],
"12981": ["Saranac", "New York", "NY"],
"12983": ["Saranac Lake", "New York", "NY"],
"12985": ["Schuyler Falls", "New York", "NY"],
"12986": ["Tupper Lake", "New York", "NY"],
"12987": ["Upper Jay", "New York", "NY"],
"12989": ["Vermontville", "New York", "NY"],
"12992": ["West Chazy", "New York", "NY"],
"12993": ["Westport", "New York", "NY"],
"12996": ["Willsboro", "New York", "NY"],
"12997": ["Wilmington", "New York", "NY"],
"12998": ["Witherbee", "New York", "NY"],
"13020": ["Apulia Station", "New York", "NY"],
"13021": ["Auburn", "New York", "NY"],
"13024": ["Auburn", "New York", "NY"],
"13026": ["Aurora", "New York", "NY"],
"13027": ["Baldwinsville", "New York", "NY"],
"13028": ["Bernhards Bay", "New York", "NY"],
"13029": ["Brewerton", "New York", "NY"],
"13030": ["Bridgeport", "New York", "NY"],
"13031": ["Camillus", "New York", "NY"],
"13032": ["Canastota", "New York", "NY"],
"13033": ["Cato", "New York", "NY"],
"13034": ["Cayuga", "New York", "NY"],
"13035": ["Cazenovia", "New York", "NY"],
"13036": ["Central Square", "New York", "NY"],
"13037": ["Chittenango", "New York", "NY"],
"13039": ["Cicero", "New York", "NY"],
"13040": ["Cincinnatus", "New York", "NY"],
"13041": ["Clay", "New York", "NY"],
"13042": ["Cleveland", "New York", "NY"],
"13044": ["Constantia", "New York", "NY"],
"13045": ["Cortland", "New York", "NY"],
"13051": ["Delphi Falls", "New York", "NY"],
"13052": ["De Ruyter", "New York", "NY"],
"13053": ["Dryden", "New York", "NY"],
"13054": ["Durhamville", "New York", "NY"],
"13057": ["East Syracuse", "New York", "NY"],
"13060": ["Elbridge", "New York", "NY"],
"13061": ["Erieville", "New York", "NY"],
"13062": ["Etna", "New York", "NY"],
"13063": ["Fabius", "New York", "NY"],
"13065": ["Fayette", "New York", "NY"],
"13066": ["Fayetteville", "New York", "NY"],
"13068": ["Freeville", "New York", "NY"],
"13069": ["Fulton", "New York", "NY"],
"13071": ["Genoa", "New York", "NY"],
"13072": ["Georgetown", "New York", "NY"],
"13073": ["Groton", "New York", "NY"],
"13074": ["Hannibal", "New York", "NY"],
"13076": ["Hastings", "New York", "NY"],
"13077": ["Homer", "New York", "NY"],
"13078": ["Jamesville", "New York", "NY"],
"13080": ["Jordan", "New York", "NY"],
"13081": ["King Ferry", "New York", "NY"],
"13082": ["Kirkville", "New York", "NY"],
"13083": ["Lacona", "New York", "NY"],
"13084": ["La Fayette", "New York", "NY"],
"13087": ["Little York", "New York", "NY"],
"13088": ["Liverpool", "New York", "NY"],
"13090": ["Liverpool", "New York", "NY"],
"13092": ["Locke", "New York", "NY"],
"13101": ["McGraw", "New York", "NY"],
"13102": ["McLean", "New York", "NY"],
"13103": ["Mallory", "New York", "NY"],
"13104": ["Manlius", "New York", "NY"],
"13108": ["Marcellus", "New York", "NY"],
"13110": ["Marietta", "New York", "NY"],
"13111": ["Martville", "New York", "NY"],
"13112": ["Memphis", "New York", "NY"],
"13113": ["Meridian", "New York", "NY"],
"13114": ["Mexico", "New York", "NY"],
"13115": ["Minetto", "New York", "NY"],
"13116": ["Minoa", "New York", "NY"],
"13117": ["Montezuma", "New York", "NY"],
"13118": ["Moravia", "New York", "NY"],
"13119": ["Mottville", "New York", "NY"],
"13120": ["Nedrow", "New York", "NY"],
"13121": ["New Haven", "New York", "NY"],
"13122": ["New Woodstock", "New York", "NY"],
"13123": ["North Bay", "New York", "NY"],
"13124": ["North Pitcher", "New York", "NY"],
"13126": ["Oswego", "New York", "NY"],
"13131": ["Parish", "New York", "NY"],
"13132": ["Pennellville", "New York", "NY"],
"13134": ["Peterboro", "New York", "NY"],
"13135": ["Phoenix", "New York", "NY"],
"13136": ["Pitcher", "New York", "NY"],
"13138": ["Pompey", "New York", "NY"],
"13140": ["Port Byron", "New York", "NY"],
"13141": ["Preble", "New York", "NY"],
"13142": ["Pulaski", "New York", "NY"],
"13143": ["Red Creek", "New York", "NY"],
"13144": ["Richland", "New York", "NY"],
"13145": ["Sandy Creek", "New York", "NY"],
"13146": ["Savannah", "New York", "NY"],
"13147": ["Scipio Center", "New York", "NY"],
"13148": ["Seneca Falls", "New York", "NY"],
"13152": ["Skaneateles", "New York", "NY"],
"13153": ["Skaneateles Falls", "New York", "NY"],
"13154": ["South Butler", "New York", "NY"],
"13155": ["South Otselic", "New York", "NY"],
"13156": ["Sterling", "New York", "NY"],
"13157": ["Sylvan Beach", "New York", "NY"],
"13158": ["Truxton", "New York", "NY"],
"13159": ["Tully", "New York", "NY"],
"13160": ["Union Springs", "New York", "NY"],
"13162": ["Verona Beach", "New York", "NY"],
"13163": ["Wampsville", "New York", "NY"],
"13164": ["Warners", "New York", "NY"],
"13165": ["Waterloo", "New York", "NY"],
"13166": ["Weedsport", "New York", "NY"],
"13167": ["West Monroe", "New York", "NY"],
"13202": ["Syracuse", "New York", "NY"],
"13203": ["Syracuse", "New York", "NY"],
"13204": ["Syracuse", "New York", "NY"],
"13205": ["Syracuse", "New York", "NY"],
"13206": ["Syracuse", "New York", "NY"],
"13207": ["Syracuse", "New York", "NY"],
"13208": ["Syracuse", "New York", "NY"],
"13209": ["Syracuse", "New York", "NY"],
"13210": ["Syracuse", "New York", "NY"],
"13211": ["Syracuse", "New York", "NY"],
"13212": ["Syracuse", "New York", "NY"],
"13214": ["Syracuse", "New York", "NY"],
"13215": ["Syracuse", "New York", "NY"],
"13219": ["Syracuse", "New York", "NY"],
"13224": ["Syracuse", "New York", "NY"],
"13244": ["Syracuse", "New York", "NY"],
"13290": ["Syracuse", "New York", "NY"],
"13301": ["Alder Creek", "New York", "NY"],
"13302": ["Altmar", "New York", "NY"],
"13303": ["Ava", "New York", "NY"],
"13304": ["Barneveld", "New York", "NY"],
"13305": ["Beaver Falls", "New York", "NY"],
"13308": ["Blossvale", "New York", "NY"],
"13309": ["Boonville", "New York", "NY"],
"13310": ["Bouckville", "New York", "NY"],
"13312": ["Brantingham", "New York", "NY"],
"13313": ["Bridgewater", "New York", "NY"],
"13314": ["Brookfield", "New York", "NY"],
"13315": ["Burlington Flats", "New York", "NY"],
"13316": ["Camden", "New York", "NY"],
"13317": ["Canajoharie", "New York", "NY"],
"13318": ["Cassville", "New York", "NY"],
"13319": ["Chadwicks", "New York", "NY"],
"13320": ["Cherry Valley", "New York", "NY"],
"13321": ["Clark Mills", "New York", "NY"],
"13322": ["Clayville", "New York", "NY"],
"13323": ["Clinton", "New York", "NY"],
"13324": ["Cold Brook", "New York", "NY"],
"13325": ["Constableville", "New York", "NY"],
"13326": ["Cooperstown", "New York", "NY"],
"13327": ["Croghan", "New York", "NY"],
"13328": ["Deansboro", "New York", "NY"],
"13329": ["Dolgeville", "New York", "NY"],
"13331": ["Eagle Bay", "New York", "NY"],
"13332": ["Earlville", "New York", "NY"],
"13333": ["East Springfield", "New York", "NY"],
"13334": ["Eaton", "New York", "NY"],
"13335": ["Edmeston", "New York", "NY"],
"13337": ["Fly Creek", "New York", "NY"],
"13338": ["Forestport", "New York", "NY"],
"13339": ["Fort Plain", "New York", "NY"],
"13340": ["Frankfort", "New York", "NY"],
"13342": ["Garrattsville", "New York", "NY"],
"13343": ["Glenfield", "New York", "NY"],
"13345": ["Greig", "New York", "NY"],
"13346": ["Hamilton", "New York", "NY"],
"13348": ["Hartwick", "New York", "NY"],
"13350": ["Herkimer", "New York", "NY"],
"13352": ["Hinckley", "New York", "NY"],
"13353": ["Hoffmeister", "New York", "NY"],
"13354": ["Holland Patent", "New York", "NY"],
"13355": ["Hubbardsville", "New York", "NY"],
"13357": ["Ilion", "New York", "NY"],
"13360": ["Inlet", "New York", "NY"],
"13361": ["Jordanville", "New York", "NY"],
"13363": ["Lee Center", "New York", "NY"],
"13364": ["Leonardsville", "New York", "NY"],
"13365": ["Little Falls", "New York", "NY"],
"13367": ["Lowville", "New York", "NY"],
"13368": ["Lyons Falls", "New York", "NY"],
"13402": ["Madison", "New York", "NY"],
"13403": ["Marcy", "New York", "NY"],
"13404": ["Martinsburg", "New York", "NY"],
"13406": ["Middleville", "New York", "NY"],
"13407": ["Mohawk", "New York", "NY"],
"13408": ["Morrisville", "New York", "NY"],
"13409": ["Munnsville", "New York", "NY"],
"13410": ["Nelliston", "New York", "NY"],
"13411": ["New Berlin", "New York", "NY"],
"13413": ["New Hartford", "New York", "NY"],
"13415": ["New Lisbon", "New York", "NY"],
"13416": ["Newport", "New York", "NY"],
"13417": ["New York Mills", "New York", "NY"],
"13418": ["North Brookfield", "New York", "NY"],
"13420": ["Old Forge", "New York", "NY"],
"13421": ["Oneida", "New York", "NY"],
"13424": ["Oriskany", "New York", "NY"],
"13425": ["Oriskany Falls", "New York", "NY"],
"13428": ["Palatine Bridge", "New York", "NY"],
"13431": ["Poland", "New York", "NY"],
"13433": ["Port Leyden", "New York", "NY"],
"13435": ["Prospect", "New York", "NY"],
"13436": ["Raquette Lake", "New York", "NY"],
"13437": ["Redfield", "New York", "NY"],
"13438": ["Remsen", "New York", "NY"],
"13439": ["Richfield Springs", "New York", "NY"],
"13440": ["Rome", "New York", "NY"],
"13441": ["Rome", "New York", "NY"],
"13450": ["Roseboom", "New York", "NY"],
"13452": ["Saint Johnsville", "New York", "NY"],
"13454": ["Salisbury Center", "New York", "NY"],
"13455": ["Sangerfield", "New York", "NY"],
"13456": ["Sauquoit", "New York", "NY"],
"13457": ["Schuyler Lake", "New York", "NY"],
"13459": ["Sharon Springs", "New York", "NY"],
"13460": ["Sherburne", "New York", "NY"],
"13461": ["Sherrill", "New York", "NY"],
"13464": ["Smyrna", "New York", "NY"],
"13468": ["Springfield Center", "New York", "NY"],
"13469": ["Stittville", "New York", "NY"],
"13470": ["Stratford", "New York", "NY"],
"13471": ["Taberg", "New York", "NY"],
"13472": ["Thendara", "New York", "NY"],
"13473": ["Turin", "New York", "NY"],
"13475": ["Van Hornesville", "New York", "NY"],
"13476": ["Vernon", "New York", "NY"],
"13477": ["Vernon Center", "New York", "NY"],
"13478": ["Verona", "New York", "NY"],
"13480": ["Waterville", "New York", "NY"],
"13482": ["West Burlington", "New York", "NY"],
"13483": ["Westdale", "New York", "NY"],
"13484": ["West Eaton", "New York", "NY"],
"13485": ["West Edmeston", "New York", "NY"],
"13486": ["Westernville", "New York", "NY"],
"13488": ["Westford", "New York", "NY"],
"13489": ["West Leyden", "New York", "NY"],
"13490": ["Westmoreland", "New York", "NY"],
"13491": ["West Winfield", "New York", "NY"],
"13492": ["Whitesboro", "New York", "NY"],
"13493": ["Williamstown", "New York", "NY"],
"13494": ["Woodgate", "New York", "NY"],
"13495": ["Yorkville", "New York", "NY"],
"13501": ["Utica", "New York", "NY"],
"13502": ["Utica", "New York", "NY"],
"13601": ["Watertown", "New York", "NY"],
"13602": ["Fort Drum", "New York", "NY"],
"13603": ["Watertown", "New York", "NY"],
"13605": ["Adams", "New York", "NY"],
"13606": ["Adams Center", "New York", "NY"],
"13607": ["Alexandria Bay", "New York", "NY"],
"13608": ["Antwerp", "New York", "NY"],
"13611": ["Belleville", "New York", "NY"],
"13612": ["Black River", "New York", "NY"],
"13613": ["Brasher Falls", "New York", "NY"],
"13614": ["Brier Hill", "New York", "NY"],
"13615": ["Brownville", "New York", "NY"],
"13616": ["Calcium", "New York", "NY"],
"13617": ["Canton", "New York", "NY"],
"13618": ["Cape Vincent", "New York", "NY"],
"13619": ["Carthage", "New York", "NY"],
"13620": ["Castorland", "New York", "NY"],
"13621": ["Chase Mills", "New York", "NY"],
"13622": ["Chaumont", "New York", "NY"],
"13623": ["Chippewa Bay", "New York", "NY"],
"13624": ["Clayton", "New York", "NY"],
"13625": ["Colton", "New York", "NY"],
"13626": ["Copenhagen", "New York", "NY"],
"13628": ["Deferiet", "New York", "NY"],
"13630": ["De Kalb Junction", "New York", "NY"],
"13632": ["Depauville", "New York", "NY"],
"13633": ["De Peyster", "New York", "NY"],
"13634": ["Dexter", "New York", "NY"],
"13635": ["Edwards", "New York", "NY"],
"13636": ["Ellisburg", "New York", "NY"],
"13637": ["Evans Mills", "New York", "NY"],
"13638": ["Felts Mills", "New York", "NY"],
"13639": ["Fine", "New York", "NY"],
"13640": ["Wellesley Island", "New York", "NY"],
"13641": ["Fishers Landing", "New York", "NY"],
"13642": ["Gouverneur", "New York", "NY"],
"13643": ["Great Bend", "New York", "NY"],
"13645": ["Hailesboro", "New York", "NY"],
"13646": ["Hammond", "New York", "NY"],
"13647": ["Hannawa Falls", "New York", "NY"],
"13648": ["Harrisville", "New York", "NY"],
"13649": ["Helena", "New York", "NY"],
"13650": ["Henderson", "New York", "NY"],
"13651": ["Henderson Harbor", "New York", "NY"],
"13652": ["Hermon", "New York", "NY"],
"13654": ["Heuvelton", "New York", "NY"],
"13655": ["Hogansburg", "New York", "NY"],
"13656": ["La Fargeville", "New York", "NY"],
"13658": ["Lisbon", "New York", "NY"],
"13659": ["Lorraine", "New York", "NY"],
"13660": ["Madrid", "New York", "NY"],
"13661": ["Mannsville", "New York", "NY"],
"13662": ["Massena", "New York", "NY"],
"13664": ["Morristown", "New York", "NY"],
"13665": ["Natural Bridge", "New York", "NY"],
"13666": ["Newton Falls", "New York", "NY"],
"13667": ["Norfolk", "New York", "NY"],
"13668": ["Norwood", "New York", "NY"],
"13669": ["Ogdensburg", "New York", "NY"],
"13670": ["Oswegatchie", "New York", "NY"],
"13672": ["Parishville", "New York", "NY"],
"13673": ["Philadelphia", "New York", "NY"],
"13674": ["Pierrepont Manor", "New York", "NY"],
"13675": ["Plessis", "New York", "NY"],
"13676": ["Potsdam", "New York", "NY"],
"13678": ["Raymondville", "New York", "NY"],
"13679": ["Redwood", "New York", "NY"],
"13680": ["Rensselaer Falls", "New York", "NY"],
"13681": ["Richville", "New York", "NY"],
"13682": ["Rodman", "New York", "NY"],
"13683": ["Rooseveltown", "New York", "NY"],
"13684": ["Russell", "New York", "NY"],
"13685": ["Sackets Harbor", "New York", "NY"],
"13687": ["South Colton", "New York", "NY"],
"13690": ["Star Lake", "New York", "NY"],
"13691": ["Theresa", "New York", "NY"],
"13692": ["Thousand Island Park", "New York", "NY"],
"13693": ["Three Mile Bay", "New York", "NY"],
"13694": ["Waddington", "New York", "NY"],
"13695": ["Wanakena", "New York", "NY"],
"13696": ["West Stockholm", "New York", "NY"],
"13697": ["Winthrop", "New York", "NY"],
"13699": ["Potsdam", "New York", "NY"],
"13730": ["Afton", "New York", "NY"],
"13731": ["Andes", "New York", "NY"],
"13732": ["Apalachin", "New York", "NY"],
"13733": ["Bainbridge", "New York", "NY"],
"13734": ["Barton", "New York", "NY"],
"13736": ["Berkshire", "New York", "NY"],
"13738": ["Blodgett Mills", "New York", "NY"],
"13739": ["Bloomville", "New York", "NY"],
"13740": ["Bovina Center", "New York", "NY"],
"13743": ["Candor", "New York", "NY"],
"13744": ["Castle Creek", "New York", "NY"],
"13746": ["Chenango Forks", "New York", "NY"],
"13747": ["Colliersville", "New York", "NY"],
"13748": ["Conklin", "New York", "NY"],
"13750": ["Davenport", "New York", "NY"],
"13751": ["Davenport Center", "New York", "NY"],
"13752": ["Delancey", "New York", "NY"],
"13753": ["Delhi", "New York", "NY"],
"13754": ["Deposit", "New York", "NY"],
"13755": ["Downsville", "New York", "NY"],
"13756": ["East Branch", "New York", "NY"],
"13757": ["East Meredith", "New York", "NY"],
"13760": ["Endicott", "New York", "NY"],
"13774": ["Fishs Eddy", "New York", "NY"],
"13775": ["Franklin", "New York", "NY"],
"13776": ["Gilbertsville", "New York", "NY"],
"13777": ["Glen Aubrey", "New York", "NY"],
"13778": ["Greene", "New York", "NY"],
"13780": ["Guilford", "New York", "NY"],
"13782": ["Hamden", "New York", "NY"],
"13783": ["Hancock", "New York", "NY"],
"13784": ["Harford", "New York", "NY"],
"13786": ["Harpersfield", "New York", "NY"],
"13787": ["Harpursville", "New York", "NY"],
"13788": ["Hobart", "New York", "NY"],
"13790": ["Johnson City", "New York", "NY"],
"13795": ["Kirkwood", "New York", "NY"],
"13796": ["Laurens", "New York", "NY"],
"13797": ["Lisle", "New York", "NY"],
"13801": ["McDonough", "New York", "NY"],
"13802": ["Maine", "ME", "New York", "NY"],
"13803": ["Marathon", "New York", "NY"],
"13804": ["Masonville", "New York", "NY"],
"13806": ["Meridale", "New York", "NY"],
"13807": ["Milford", "New York", "NY"],
"13808": ["Morris", "New York", "NY"],
"13809": ["Mount Upton", "New York", "NY"],
"13810": ["Mount Vision", "New York", "NY"],
"13811": ["Newark Valley", "New York", "NY"],
"13812": ["Nichols", "New York", "NY"],
"13813": ["Nineveh", "New York", "NY"],
"13814": ["North Norwich", "New York", "NY"],
"13815": ["Norwich", "New York", "NY"],
"13820": ["Oneonta", "New York", "NY"],
"13825": ["Otego", "New York", "NY"],
"13826": ["Ouaquaga", "New York", "NY"],
"13827": ["Owego", "New York", "NY"],
"13830": ["Oxford", "New York", "NY"],
"13832": ["Plymouth", "New York", "NY"],
"13833": ["Port Crane", "New York", "NY"],
"13834": ["Portlandville", "New York", "NY"],
"13835": ["Richford", "New York", "NY"],
"13838": ["Sidney", "New York", "NY"],
"13839": ["Sidney Center", "New York", "NY"],
"13841": ["Smithville Flats", "New York", "NY"],
"13842": ["South Kortright", "New York", "NY"],
"13843": ["South New Berlin", "New York", "NY"],
"13844": ["South Plymouth", "New York", "NY"],
"13846": ["Treadwell", "New York", "NY"],
"13847": ["Trout Creek", "New York", "NY"],
"13849": ["Unadilla", "New York", "NY"],
"13850": ["Vestal", "New York", "NY"],
"13856": ["Walton", "New York", "NY"],
"13859": ["Wells Bridge", "New York", "NY"],
"13860": ["West Davenport", "New York", "NY"],
"13861": ["West Oneonta", "New York", "NY"],
"13862": ["Whitney Point", "New York", "NY"],
"13863": ["Willet", "New York", "NY"],
"13864": ["Willseyville", "New York", "NY"],
"13865": ["Windsor", "New York", "NY"],
"13901": ["Binghamton", "New York", "NY"],
"13902": ["Binghamton", "New York", "NY"],
"13903": ["Binghamton", "New York", "NY"],
"13904": ["Binghamton", "New York", "NY"],
"13905": ["Binghamton", "New York", "NY"],
"14001": ["Akron", "New York", "NY"],
"14004": ["Alden", "New York", "NY"],
"14005": ["Alexander", "New York", "NY"],
"14006": ["Angola", "New York", "NY"],
"14008": ["Appleton", "New York", "NY"],
"14009": ["Arcade", "New York", "NY"],
"14011": ["Attica", "New York", "NY"],
"14012": ["Barker", "New York", "NY"],
"14013": ["Basom", "New York", "NY"],
"14020": ["Batavia", "New York", "NY"],
"14024": ["Bliss", "New York", "NY"],
"14025": ["Boston", "New York", "NY"],
"14026": ["Bowmansville", "New York", "NY"],
"14027": ["Brant", "New York", "NY"],
"14028": ["Burt", "New York", "NY"],
"14029": ["Centerville", "New York", "NY"],
"14030": ["Chaffee", "New York", "NY"],
"14031": ["Clarence", "New York", "NY"],
"14032": ["Clarence Center", "New York", "NY"],
"14033": ["Colden", "New York", "NY"],
"14034": ["Collins", "New York", "NY"],
"14035": ["Collins Center", "New York", "NY"],
"14036": ["Corfu", "New York", "NY"],
"14037": ["Cowlesville", "New York", "NY"],
"14039": ["Dale", "New York", "NY"],
"14040": ["Darien Center", "New York", "NY"],
"14041": ["Dayton", "New York", "NY"],
"14042": ["Delevan", "New York", "NY"],
"14043": ["Depew", "New York", "NY"],
"14047": ["Derby", "New York", "NY"],
"14048": ["Dunkirk", "New York", "NY"],
"14051": ["East Amherst", "New York", "NY"],
"14052": ["East Aurora", "New York", "NY"],
"14054": ["East Bethany", "New York", "NY"],
"14055": ["East Concord", "New York", "NY"],
"14056": ["East Pembroke", "New York", "NY"],
"14057": ["Eden", "New York", "NY"],
"14058": ["Elba", "New York", "NY"],
"14059": ["Elma", "New York", "NY"],
"14060": ["Farmersville Station", "New York", "NY"],
"14061": ["Farnham", "New York", "NY"],
"14062": ["Forestville", "New York", "NY"],
"14063": ["Fredonia", "New York", "NY"],
"14065": ["Freedom", "New York", "NY"],
"14066": ["Gainesville", "New York", "NY"],
"14067": ["Gasport", "New York", "NY"],
"14068": ["Getzville", "New York", "NY"],
"14069": ["Glenwood", "New York", "NY"],
"14070": ["Gowanda", "New York", "NY"],
"14072": ["Grand Island", "New York", "NY"],
"14075": ["Hamburg", "New York", "NY"],
"14080": ["Holland", "New York", "NY"],
"14081": ["Irving", "New York", "NY"],
"14082": ["Java Center", "New York", "NY"],
"14083": ["Java Village", "New York", "NY"],
"14085": ["Lake View", "New York", "NY"],
"14086": ["Lancaster", "New York", "NY"],
"14091": ["Lawtons", "New York", "NY"],
"14092": ["Lewiston", "New York", "NY"],
"14094": ["Lockport", "New York", "NY"],
"14098": ["Lyndonville", "New York", "NY"],
"14101": ["Machias", "New York", "NY"],
"14102": ["Marilla", "New York", "NY"],
"14103": ["Medina", "New York", "NY"],
"14105": ["Middleport", "New York", "NY"],
"14108": ["Newfane", "New York", "NY"],
"14109": ["Niagara University", "New York", "NY"],
"14111": ["North Collins", "New York", "NY"],
"14112": ["North Evans", "New York", "NY"],
"14113": ["North Java", "New York", "NY"],
"14120": ["North Tonawanda", "New York", "NY"],
"14125": ["Oakfield", "New York", "NY"],
"14126": ["Olcott", "New York", "NY"],
"14127": ["Orchard Park", "New York", "NY"],
"14129": ["Perrysburg", "New York", "NY"],
"14130": ["Pike", "New York", "NY"],
"14131": ["Ransomville", "New York", "NY"],
"14132": ["Sanborn", "New York", "NY"],
"14134": ["Sardinia", "New York", "NY"],
"14135": ["Sheridan", "New York", "NY"],
"14136": ["Silver Creek", "New York", "NY"],
"14138": ["South Dayton", "New York", "NY"],
"14139": ["South Wales", "New York", "NY"],
"14141": ["Springville", "New York", "NY"],
"14143": ["Stafford", "New York", "NY"],
"14145": ["Strykersville", "New York", "NY"],
"14150": ["Tonawanda", "New York", "NY"],
"14166": ["Van Buren Point", "New York", "NY"],
"14167": ["Varysburg", "New York", "NY"],
"14168": ["Versailles", "New York", "NY"],
"14169": ["Wales Center", "New York", "NY"],
"14170": ["West Falls", "New York", "NY"],
"14171": ["West Valley", "New York", "NY"],
"14172": ["Wilson", "New York", "NY"],
"14173": ["Yorkshire", "New York", "NY"],
"14174": ["Youngstown", "New York", "NY"],
"14201": ["Buffalo", "New York", "NY"],
"14202": ["Buffalo", "New York", "NY"],
"14203": ["Buffalo", "New York", "NY"],
"14204": ["Buffalo", "New York", "NY"],
"14206": ["Buffalo", "New York", "NY"],
"14207": ["Buffalo", "New York", "NY"],
"14208": ["Buffalo", "New York", "NY"],
"14209": ["Buffalo", "New York", "NY"],
"14210": ["Buffalo", "New York", "NY"],
"14211": ["Buffalo", "New York", "NY"],
"14212": ["Buffalo", "New York", "NY"],
"14213": ["Buffalo", "New York", "NY"],
"14214": ["Buffalo", "New York", "NY"],
"14215": ["Buffalo", "New York", "NY"],
"14216": ["Buffalo", "New York", "NY"],
"14217": ["Buffalo", "New York", "NY"],
"14218": ["Buffalo", "New York", "NY"],
"14219": ["Buffalo", "New York", "NY"],
"14220": ["Buffalo", "New York", "NY"],
"14221": ["Buffalo", "New York", "NY"],
"14222": ["Buffalo", "New York", "NY"],
"14223": ["Buffalo", "New York", "NY"],
"14224": ["Buffalo", "New York", "NY"],
"14225": ["Buffalo", "New York", "NY"],
"14226": ["Buffalo", "New York", "NY"],
"14227": ["Buffalo", "New York", "NY"],
"14228": ["Buffalo", "New York", "NY"],
"14260": ["Buffalo", "New York", "NY"],
"14261": ["Buffalo", "New York", "NY"],
"14301": ["Niagara Falls", "New York", "NY"],
"14302": ["Niagara Falls", "New York", "NY"],
"14303": ["Niagara Falls", "New York", "NY"],
"14304": ["Niagara Falls", "New York", "NY"],
"14305": ["Niagara Falls", "New York", "NY"],
"14411": ["Albion", "New York", "NY"],
"14413": ["Alton", "New York", "NY"],
"14414": ["Avon", "New York", "NY"],
"14415": ["Bellona", "New York", "NY"],
"14416": ["Bergen", "New York", "NY"],
"14418": ["Branchport", "New York", "NY"],
"14420": ["Brockport", "New York", "NY"],
"14422": ["Byron", "New York", "NY"],
"14423": ["Caledonia", "New York", "NY"],
"14424": ["Canandaigua", "New York", "NY"],
"14425": ["Farmington", "New York", "NY"],
"14427": ["Castile", "New York", "NY"],
"14428": ["Churchville", "New York", "NY"],
"14432": ["Clifton Springs", "New York", "NY"],
"14433": ["Clyde", "New York", "NY"],
"14435": ["Conesus", "New York", "NY"],
"14437": ["Dansville", "New York", "NY"],
"14441": ["Dresden", "New York", "NY"],
"14445": ["East Rochester", "New York", "NY"],
"14450": ["Fairport", "New York", "NY"],
"14453": ["Fishers", "New York", "NY"],
"14454": ["Geneseo", "New York", "NY"],
"14456": ["Geneva", "New York", "NY"],
"14462": ["Groveland", "New York", "NY"],
"14463": ["Hall", "New York", "NY"],
"14464": ["Hamlin", "New York", "NY"],
"14466": ["Hemlock", "New York", "NY"],
"14467": ["Henrietta", "New York", "NY"],
"14468": ["Hilton", "New York", "NY"],
"14469": ["Bloomfield", "New York", "NY"],
"14470": ["Holley", "New York", "NY"],
"14471": ["Honeoye", "New York", "NY"],
"14472": ["Honeoye Falls", "New York", "NY"],
"14475": ["Ionia", "New York", "NY"],
"14476": ["Kendall", "New York", "NY"],
"14477": ["Kent", "New York", "NY"],
"14478": ["Keuka Park", "New York", "NY"],
"14479": ["Knowlesville", "New York", "NY"],
"14480": ["Lakeville", "New York", "NY"],
"14481": ["Leicester", "New York", "NY"],
"14482": ["Le Roy", "New York", "NY"],
"14485": ["Lima", "New York", "NY"],
"14486": ["Linwood", "New York", "NY"],
"14487": ["Livonia", "New York", "NY"],
"14489": ["Lyons", "New York", "NY"],
"14502": ["Macedon", "New York", "NY"],
"14504": ["Manchester", "New York", "NY"],
"14505": ["Marion", "New York", "NY"],
"14506": ["Mendon", "New York", "NY"],
"14507": ["Middlesex", "New York", "NY"],
"14510": ["Mount Morris", "New York", "NY"],
"14511": ["Mumford", "New York", "NY"],
"14512": ["Naples", "New York", "NY"],
"14513": ["Newark", "New York", "NY"],
"14514": ["North Chili", "New York", "NY"],
"14515": ["North Greece", "New York", "NY"],
"14516": ["North Rose", "New York", "NY"],
"14517": ["Nunda", "New York", "NY"],
"14519": ["Ontario", "New York", "NY"],
"14521": ["Ovid", "New York", "NY"],
"14522": ["Palmyra", "New York", "NY"],
"14525": ["Pavilion", "New York", "NY"],
"14526": ["Penfield", "New York", "NY"],
"14527": ["Penn Yan", "New York", "NY"],
"14529": ["Perkinsville", "New York", "NY"],
"14530": ["Perry", "New York", "NY"],
"14532": ["Phelps", "New York", "NY"],
"14533": ["Piffard", "New York", "NY"],
"14534": ["Pittsford", "New York", "NY"],
"14536": ["Portageville", "New York", "NY"],
"14537": ["Port Gibson", "New York", "NY"],
"14539": ["Retsof", "New York", "NY"],
"14541": ["Romulus", "New York", "NY"],
"14542": ["Rose", "New York", "NY"],
"14543": ["Rush", "New York", "NY"],
"14544": ["Rushville", "New York", "NY"],
"14545": ["Scottsburg", "New York", "NY"],
"14546": ["Scottsville", "New York", "NY"],
"14548": ["Shortsville", "New York", "NY"],
"14549": ["Silver Lake", "New York", "NY"],
"14550": ["Silver Springs", "New York", "NY"],
"14551": ["Sodus", "New York", "NY"],
"14555": ["Sodus Point", "New York", "NY"],
"14556": ["Sonyea", "New York", "NY"],
"14557": ["South Byron", "New York", "NY"],
"14559": ["Spencerport", "New York", "NY"],
"14560": ["Springwater", "New York", "NY"],
"14561": ["Stanley", "New York", "NY"],
"14564": ["Victor", "New York", "NY"],
"14568": ["Walworth", "New York", "NY"],
"14569": ["Warsaw", "New York", "NY"],
"14571": ["Waterport", "New York", "NY"],
"14572": ["Wayland", "New York", "NY"],
"14580": ["Webster", "New York", "NY"],
"14585": ["West Bloomfield", "New York", "NY"],
"14586": ["West Henrietta", "New York", "NY"],
"14588": ["Willard", "New York", "NY"],
"14589": ["Williamson", "New York", "NY"],
"14590": ["Wolcott", "New York", "NY"],
"14591": ["Wyoming", "New York", "NY"],
"14592": ["York", "New York", "NY"],
"14604": ["Rochester", "New York", "NY"],
"14605": ["Rochester", "New York", "NY"],
"14606": ["Rochester", "New York", "NY"],
"14607": ["Rochester", "New York", "NY"],
"14608": ["Rochester", "New York", "NY"],
"14609": ["Rochester", "New York", "NY"],
"14610": ["Rochester", "New York", "NY"],
"14611": ["Rochester", "New York", "NY"],
"14612": ["Rochester", "New York", "NY"],
"14613": ["Rochester", "New York", "NY"],
"14614": ["Rochester", "New York", "NY"],
"14615": ["Rochester", "New York", "NY"],
"14616": ["Rochester", "New York", "NY"],
"14617": ["Rochester", "New York", "NY"],
"14618": ["Rochester", "New York", "NY"],
"14619": ["Rochester", "New York", "NY"],
"14620": ["Rochester", "New York", "NY"],
"14621": ["Rochester", "New York", "NY"],
"14622": ["Rochester", "New York", "NY"],
"14623": ["Rochester", "New York", "NY"],
"14624": ["Rochester", "New York", "NY"],
"14625": ["Rochester", "New York", "NY"],
"14626": ["Rochester", "New York", "NY"],
"14627": ["Rochester", "New York", "NY"],
"14642": ["Rochester", "New York", "NY"],
"14701": ["Jamestown", "New York", "NY"],
"14706": ["Allegany", "New York", "NY"],
"14707": ["Allentown", "New York", "NY"],
"14708": ["Alma", "New York", "NY"],
"14709": ["Angelica", "New York", "NY"],
"14710": ["Ashville", "New York", "NY"],
"14711": ["Belfast", "New York", "NY"],
"14712": ["Bemus Point", "New York", "NY"],
"14714": ["Black Creek", "New York", "NY"],
"14715": ["Bolivar", "New York", "NY"],
"14716": ["Brocton", "New York", "NY"],
"14717": ["Caneadea", "New York", "NY"],
"14718": ["Cassadaga", "New York", "NY"],
"14719": ["Cattaraugus", "New York", "NY"],
"14720": ["Celoron", "New York", "NY"],
"14721": ["Ceres", "New York", "NY"],
"14722": ["Chautauqua", "New York", "NY"],
"14723": ["Cherry Creek", "New York", "NY"],
"14724": ["Clymer", "New York", "NY"],
"14726": ["Conewango Valley", "New York", "NY"],
"14727": ["Cuba", "New York", "NY"],
"14728": ["Dewittville", "New York", "NY"],
"14729": ["East Otto", "New York", "NY"],
"14731": ["Ellicottville", "New York", "NY"],
"14732": ["Ellington", "New York", "NY"],
"14733": ["Falconer", "New York", "NY"],
"14735": ["Fillmore", "New York", "NY"],
"14736": ["Findley Lake", "New York", "NY"],
"14737": ["Franklinville", "New York", "NY"],
"14738": ["Frewsburg", "New York", "NY"],
"14739": ["Friendship", "New York", "NY"],
"14740": ["Gerry", "New York", "NY"],
"14741": ["Great Valley", "New York", "NY"],
"14742": ["Greenhurst", "New York", "NY"],
"14743": ["Hinsdale", "New York", "NY"],
"14744": ["Houghton", "New York", "NY"],
"14747": ["Kennedy", "New York", "NY"],
"14748": ["Kill Buck", "New York", "NY"],
"14750": ["Lakewood", "New York", "NY"],
"14752": ["Lily Dale", "New York", "NY"],
"14753": ["Limestone", "New York", "NY"],
"14754": ["Little Genesee", "New York", "NY"],
"14755": ["Little Valley", "New York", "NY"],
"14756": ["Maple Springs", "New York", "NY"],
"14757": ["Mayville", "New York", "NY"],
"14760": ["Olean", "New York", "NY"],
"14767": ["Panama", "New York", "NY"],
"14769": ["Portland", "New York", "NY"],
"14770": ["Portville", "New York", "NY"],
"14772": ["Randolph", "New York", "NY"],
"14774": ["Richburg", "New York", "NY"],
"14775": ["Ripley", "New York", "NY"],
"14777": ["Rushford", "New York", "NY"],
"14778": ["Saint Bonaventure", "New York", "NY"],
"14779": ["Salamanca", "New York", "NY"],
"14781": ["Sherman", "New York", "NY"],
"14782": ["Sinclairville", "New York", "NY"],
"14783": ["Steamburg", "New York", "NY"],
"14784": ["Stockton", "New York", "NY"],
"14785": ["Stow", "New York", "NY"],
"14787": ["Westfield", "New York", "NY"],
"14788": ["Westons Mills", "New York", "NY"],
"14801": ["Addison", "New York", "NY"],
"14802": ["Alfred", "New York", "NY"],
"14803": ["Alfred Station", "New York", "NY"],
"14804": ["Almond", "New York", "NY"],
"14805": ["Alpine", "New York", "NY"],
"14806": ["Andover", "New York", "NY"],
"14807": ["Arkport", "New York", "NY"],
"14808": ["Atlanta", "New York", "NY"],
"14809": ["Avoca", "New York", "NY"],
"14810": ["Bath", "New York", "NY"],
"14812": ["Beaver Dams", "New York", "NY"],
"14813": ["Belmont", "New York", "NY"],
"14814": ["Big Flats", "New York", "NY"],
"14815": ["Bradford", "New York", "NY"],
"14816": ["Breesport", "New York", "NY"],
"14817": ["Brooktondale", "New York", "NY"],
"14818": ["Burdett", "New York", "NY"],
"14819": ["Cameron", "New York", "NY"],
"14820": ["Cameron Mills", "New York", "NY"],
"14821": ["Campbell", "New York", "NY"],
"14822": ["Canaseraga", "New York", "NY"],
"14823": ["Canisteo", "New York", "NY"],
"14824": ["Cayuta", "New York", "NY"],
"14825": ["Chemung", "New York", "NY"],
"14826": ["Cohocton", "New York", "NY"],
"14827": ["Coopers Plains", "New York", "NY"],
"14830": ["Corning", "New York", "NY"],
"14836": ["Dalton", "New York", "NY"],
"14837": ["Dundee", "New York", "NY"],
"14838": ["Erin", "New York", "NY"],
"14839": ["Greenwood", "New York", "NY"],
"14840": ["Hammondsport", "New York", "NY"],
"14841": ["Hector", "New York", "NY"],
"14842": ["Himrod", "New York", "NY"],
"14843": ["Hornell", "New York", "NY"],
"14845": ["Horseheads", "New York", "NY"],
"14846": ["Hunt", "New York", "NY"],
"14847": ["Interlaken", "New York", "NY"],
"14850": ["Ithaca", "New York", "NY"],
"14853": ["Ithaca", "New York", "NY"],
"14854": ["Jacksonville", "New York", "NY"],
"14855": ["Jasper", "New York", "NY"],
"14856": ["Kanona", "New York", "NY"],
"14858": ["Lindley", "New York", "NY"],
"14859": ["Lockwood", "New York", "NY"],
"14860": ["Lodi", "New York", "NY"],
"14861": ["Lowman", "New York", "NY"],
"14864": ["Millport", "New York", "NY"],
"14865": ["Montour Falls", "New York", "NY"],
"14867": ["Newfield", "New York", "NY"],
"14869": ["Odessa", "New York", "NY"],
"14870": ["Painted Post", "New York", "NY"],
"14871": ["Pine City", "New York", "NY"],
"14872": ["Pine Valley", "New York", "NY"],
"14873": ["Prattsburgh", "New York", "NY"],
"14874": ["Pulteney", "New York", "NY"],
"14877": ["Rexville", "New York", "NY"],
"14878": ["Rock Stream", "New York", "NY"],
"14879": ["Savona", "New York", "NY"],
"14880": ["Scio", "New York", "NY"],
"14881": ["Slaterville Springs", "New York", "NY"],
"14882": ["Lansing", "New York", "NY"],
"14883": ["Spencer", "New York", "NY"],
"14884": ["Swain", "New York", "NY"],
"14885": ["Troupsburg", "New York", "NY"],
"14886": ["Trumansburg", "New York", "NY"],
"14889": ["Van Etten", "New York", "NY"],
"14891": ["Watkins Glen", "New York", "NY"],
"14892": ["Waverly", "New York", "NY"],
"14893": ["Wayne", "New York", "NY"],
"14894": ["Wellsburg", "New York", "NY"],
"14895": ["Wellsville", "New York", "NY"],
"14897": ["Whitesville", "New York", "NY"],
"14898": ["Woodhull", "New York", "NY"],
"14901": ["Elmira", "New York", "NY"],
"14903": ["Elmira", "New York", "NY"],
"14904": ["Elmira", "New York", "NY"],
"14905": ["Elmira", "New York", "NY"],
"15001": ["Aliquippa", "Pennsylvania", "PA"],
"15003": ["Ambridge", "Pennsylvania", "PA"],
"15004": ["Atlasburg", "Pennsylvania", "PA"],
"15005": ["Baden", "Pennsylvania", "PA"],
"15006": ["Bairdford", "Pennsylvania", "PA"],
"15007": ["Bakerstown", "Pennsylvania", "PA"],
"15009": ["Beaver", "Pennsylvania", "PA"],
"15010": ["Beaver Falls", "Pennsylvania", "PA"],
"15012": ["Belle Vernon", "Pennsylvania", "PA"],
"15014": ["Brackenridge", "Pennsylvania", "PA"],
"15015": ["Bradfordwoods", "Pennsylvania", "PA"],
"15017": ["Bridgeville", "Pennsylvania", "PA"],
"15018": ["Buena Vista", "Pennsylvania", "PA"],
"15019": ["Bulger", "Pennsylvania", "PA"],
"15020": ["Bunola", "Pennsylvania", "PA"],
"15021": ["Burgettstown", "Pennsylvania", "PA"],
"15022": ["Charleroi", "Pennsylvania", "PA"],
"15024": ["Cheswick", "Pennsylvania", "PA"],
"15025": ["Clairton", "Pennsylvania", "PA"],
"15026": ["Clinton", "Pennsylvania", "PA"],
"15027": ["Conway", "Pennsylvania", "PA"],
"15028": ["Coulters", "Pennsylvania", "PA"],
"15030": ["Creighton", "Pennsylvania", "PA"],
"15031": ["Cuddy", "Pennsylvania", "PA"],
"15033": ["Donora", "Pennsylvania", "PA"],
"15034": ["Dravosburg", "Pennsylvania", "PA"],
"15035": ["East McKeesport", "Pennsylvania", "PA"],
"15037": ["Elizabeth", "Pennsylvania", "PA"],
"15038": ["Elrama", "Pennsylvania", "PA"],
"15042": ["Freedom", "Pennsylvania", "PA"],
"15043": ["Georgetown", "Pennsylvania", "PA"],
"15044": ["Gibsonia", "Pennsylvania", "PA"],
"15045": ["Glassport", "Pennsylvania", "PA"],
"15046": ["Crescent", "Pennsylvania", "PA"],
"15047": ["Greenock", "Pennsylvania", "PA"],
"15049": ["Harwick", "Pennsylvania", "PA"],
"15050": ["Hookstown", "Pennsylvania", "PA"],
"15051": ["Indianola", "Pennsylvania", "PA"],
"15052": ["Industry", "Pennsylvania", "PA"],
"15053": ["Joffre", "Pennsylvania", "PA"],
"15054": ["Langeloth", "Pennsylvania", "PA"],
"15055": ["Lawrence", "Pennsylvania", "PA"],
"15056": ["Leetsdale", "Pennsylvania", "PA"],
"15057": ["McDonald", "Pennsylvania", "PA"],
"15059": ["Midland", "Pennsylvania", "PA"],
"15060": ["Midway", "Pennsylvania", "PA"],
"15061": ["Monaca", "Pennsylvania", "PA"],
"15062": ["Monessen", "Pennsylvania", "PA"],
"15063": ["Monongahela", "Pennsylvania", "PA"],
"15064": ["Morgan", "Pennsylvania", "PA"],
"15065": ["Natrona Heights", "Pennsylvania", "PA"],
"15066": ["New Brighton", "Pennsylvania", "PA"],
"15067": ["New Eagle", "Pennsylvania", "PA"],
"15068": ["New Kensington", "Pennsylvania", "PA"],
"15071": ["Oakdale", "Pennsylvania", "PA"],
"15072": ["Pricedale", "Pennsylvania", "PA"],
"15074": ["Rochester", "Pennsylvania", "PA"],
"15075": ["Rural Ridge", "Pennsylvania", "PA"],
"15076": ["Russellton", "Pennsylvania", "PA"],
"15077": ["Shippingport", "Pennsylvania", "PA"],
"15078": ["Slovan", "Pennsylvania", "PA"],
"15081": ["South Heights", "Pennsylvania", "PA"],
"15082": ["Sturgeon", "Pennsylvania", "PA"],
"15083": ["Sutersville", "Pennsylvania", "PA"],
"15084": ["Tarentum", "Pennsylvania", "PA"],
"15085": ["Trafford", "Pennsylvania", "PA"],
"15086": ["Warrendale", "Pennsylvania", "PA"],
"15087": ["Webster", "Pennsylvania", "PA"],
"15088": ["West Elizabeth", "Pennsylvania", "PA"],
"15089": ["West Newton", "Pennsylvania", "PA"],
"15090": ["Wexford", "Pennsylvania", "PA"],
"15101": ["Allison Park", "Pennsylvania", "PA"],
"15102": ["Bethel Park", "Pennsylvania", "PA"],
"15104": ["Braddock", "Pennsylvania", "PA"],
"15106": ["Carnegie", "Pennsylvania", "PA"],
"15108": ["Coraopolis", "Pennsylvania", "PA"],
"15110": ["Duquesne", "Pennsylvania", "PA"],
"15112": ["East Pittsburgh", "Pennsylvania", "PA"],
"15116": ["Glenshaw", "Pennsylvania", "PA"],
"15120": ["Homestead", "Pennsylvania", "PA"],
"15122": ["West Mifflin", "Pennsylvania", "PA"],
"15123": ["West Mifflin", "Pennsylvania", "PA"],
"15126": ["Imperial", "Pennsylvania", "PA"],
"15129": ["South Park", "Pennsylvania", "PA"],
"15131": ["Mckeesport", "Pennsylvania", "PA"],
"15132": ["Mckeesport", "Pennsylvania", "PA"],
"15133": ["Mckeesport", "Pennsylvania", "PA"],
"15135": ["Mckeesport", "Pennsylvania", "PA"],
"15136": ["McKees Rocks", "Pennsylvania", "PA"],
"15137": ["North Versailles", "Pennsylvania", "PA"],
"15139": ["Oakmont", "Pennsylvania", "PA"],
"15140": ["Pitcairn", "Pennsylvania", "PA"],
"15142": ["Presto", "Pennsylvania", "PA"],
"15143": ["Sewickley", "Pennsylvania", "PA"],
"15144": ["Springdale", "Pennsylvania", "PA"],
"15145": ["Turtle Creek", "Pennsylvania", "PA"],
"15146": ["Monroeville", "Pennsylvania", "PA"],
"15147": ["Verona", "Pennsylvania", "PA"],
"15148": ["Wilmerding", "Pennsylvania", "PA"],
"15201": ["Pittsburgh", "Pennsylvania", "PA"],
"15202": ["Pittsburgh", "Pennsylvania", "PA"],
"15203": ["Pittsburgh", "Pennsylvania", "PA"],
"15204": ["Pittsburgh", "Pennsylvania", "PA"],
"15205": ["Pittsburgh", "Pennsylvania", "PA"],
"15206": ["Pittsburgh", "Pennsylvania", "PA"],
"15207": ["Pittsburgh", "Pennsylvania", "PA"],
"15208": ["Pittsburgh", "Pennsylvania", "PA"],
"15209": ["Pittsburgh", "Pennsylvania", "PA"],
"15210": ["Pittsburgh", "Pennsylvania", "PA"],
"15211": ["Pittsburgh", "Pennsylvania", "PA"],
"15212": ["Pittsburgh", "Pennsylvania", "PA"],
"15213": ["Pittsburgh", "Pennsylvania", "PA"],
"15214": ["Pittsburgh", "Pennsylvania", "PA"],
"15215": ["Pittsburgh", "Pennsylvania", "PA"],
"15216": ["Pittsburgh", "Pennsylvania", "PA"],
"15217": ["Pittsburgh", "Pennsylvania", "PA"],
"15218": ["Pittsburgh", "Pennsylvania", "PA"],
"15219": ["Pittsburgh", "Pennsylvania", "PA"],
"15220": ["Pittsburgh", "Pennsylvania", "PA"],
"15221": ["Pittsburgh", "Pennsylvania", "PA"],
"15222": ["Pittsburgh", "Pennsylvania", "PA"],
"15223": ["Pittsburgh", "Pennsylvania", "PA"],
"15224": ["Pittsburgh", "Pennsylvania", "PA"],
"15225": ["Pittsburgh", "Pennsylvania", "PA"],
"15226": ["Pittsburgh", "Pennsylvania", "PA"],
"15227": ["Pittsburgh", "Pennsylvania", "PA"],
"15228": ["Pittsburgh", "Pennsylvania", "PA"],
"15229": ["Pittsburgh", "Pennsylvania", "PA"],
"15232": ["Pittsburgh", "Pennsylvania", "PA"],
"15233": ["Pittsburgh", "Pennsylvania", "PA"],
"15234": ["Pittsburgh", "Pennsylvania", "PA"],
"15235": ["Pittsburgh", "Pennsylvania", "PA"],
"15236": ["Pittsburgh", "Pennsylvania", "PA"],
"15237": ["Pittsburgh", "Pennsylvania", "PA"],
"15238": ["Pittsburgh", "Pennsylvania", "PA"],
"15239": ["Pittsburgh", "Pennsylvania", "PA"],
"15241": ["Pittsburgh", "Pennsylvania", "PA"],
"15243": ["Pittsburgh", "Pennsylvania", "PA"],
"15260": ["Pittsburgh", "Pennsylvania", "PA"],
"15275": ["Pittsburgh", "Pennsylvania", "PA"],
"15282": ["Pittsburgh", "Pennsylvania", "PA"],
"15290": ["Pittsburgh", "Pennsylvania", "PA"],
"15301": ["Washington", "Pennsylvania", "PA"],
"15310": ["Aleppo", "Pennsylvania", "PA"],
"15311": ["Amity", "Pennsylvania", "PA"],
"15312": ["Avella", "Pennsylvania", "PA"],
"15313": ["Beallsville", "Pennsylvania", "PA"],
"15314": ["Bentleyville", "Pennsylvania", "PA"],
"15315": ["Bobtown", "Pennsylvania", "PA"],
"15316": ["Brave", "Pennsylvania", "PA"],
"15317": ["Canonsburg", "Pennsylvania", "PA"],
"15320": ["Carmichaels", "Pennsylvania", "PA"],
"15321": ["Cecil", "Pennsylvania", "PA"],
"15322": ["Clarksville", "Pennsylvania", "PA"],
"15323": ["Claysville", "Pennsylvania", "PA"],
"15324": ["Cokeburg", "Pennsylvania", "PA"],
"15325": ["Crucible", "Pennsylvania", "PA"],
"15327": ["Dilliner", "Pennsylvania", "PA"],
"15329": ["Prosperity", "Pennsylvania", "PA"],
"15330": ["Eighty Four", "Pennsylvania", "PA"],
"15331": ["Ellsworth", "Pennsylvania", "PA"],
"15332": ["Finleyville", "Pennsylvania", "PA"],
"15333": ["Fredericktown", "Pennsylvania", "PA"],
"15334": ["Garards Fort", "Pennsylvania", "PA"],
"15337": ["Graysville", "Pennsylvania", "PA"],
"15338": ["Greensboro", "Pennsylvania", "PA"],
"15340": ["Hickory", "Pennsylvania", "PA"],
"15341": ["Holbrook", "Pennsylvania", "PA"],
"15342": ["Houston", "Pennsylvania", "PA"],
"15344": ["Jefferson", "Pennsylvania", "PA"],
"15345": ["Marianna", "Pennsylvania", "PA"],
"15346": ["Mather", "Pennsylvania", "PA"],
"15347": ["Meadow Lands", "Pennsylvania", "PA"],
"15348": ["Millsboro", "Pennsylvania", "PA"],
"15349": ["Mount Morris", "Pennsylvania", "PA"],
"15350": ["Muse", "Pennsylvania", "PA"],
"15351": ["Nemacolin", "Pennsylvania", "PA"],
"15352": ["New Freeport", "Pennsylvania", "PA"],
"15353": ["Nineveh", "Pennsylvania", "PA"],
"15357": ["Rices Landing", "Pennsylvania", "PA"],
"15358": ["Richeyville", "Pennsylvania", "PA"],
"15359": ["Rogersville", "Pennsylvania", "PA"],
"15360": ["Scenery Hill", "Pennsylvania", "PA"],
"15361": ["Southview", "Pennsylvania", "PA"],
"15362": ["Spraggs", "Pennsylvania", "PA"],
"15363": ["Strabane", "Pennsylvania", "PA"],
"15364": ["Sycamore", "Pennsylvania", "PA"],
"15365": ["Taylorstown", "Pennsylvania", "PA"],
"15366": ["Van Voorhis", "Pennsylvania", "PA"],
"15367": ["Venetia", "Pennsylvania", "PA"],
"15368": ["Vestaburg", "Pennsylvania", "PA"],
"15370": ["Waynesburg", "Pennsylvania", "PA"],
"15376": ["West Alexander", "Pennsylvania", "PA"],
"15377": ["West Finley", "Pennsylvania", "PA"],
"15378": ["Westland", "Pennsylvania", "PA"],
"15379": ["West Middletown", "Pennsylvania", "PA"],
"15380": ["Wind Ridge", "Pennsylvania", "PA"],
"15401": ["Uniontown", "Pennsylvania", "PA"],
"15410": ["Adah", "Pennsylvania", "PA"],
"15411": ["Addison", "Pennsylvania", "PA"],
"15412": ["Allenport", "Pennsylvania", "PA"],
"15413": ["Allison", "Pennsylvania", "PA"],
"15416": ["Brownfield", "Pennsylvania", "PA"],
"15417": ["Brownsville", "Pennsylvania", "PA"],
"15419": ["California", "Pennsylvania", "PA"],
"15420": ["Cardale", "Pennsylvania", "PA"],
"15421": ["Chalk Hill", "Pennsylvania", "PA"],
"15422": ["Chestnut Ridge", "Pennsylvania", "PA"],
"15423": ["Coal Center", "Pennsylvania", "PA"],
"15424": ["Confluence", "Pennsylvania", "PA"],
"15425": ["Connellsville", "Pennsylvania", "PA"],
"15427": ["Daisytown", "Pennsylvania", "PA"],
"15428": ["Dawson", "Pennsylvania", "PA"],
"15429": ["Denbo", "Pennsylvania", "PA"],
"15430": ["Dickerson Run", "Pennsylvania", "PA"],
"15431": ["Dunbar", "Pennsylvania", "PA"],
"15432": ["Dunlevy", "Pennsylvania", "PA"],
"15433": ["East Millsboro", "Pennsylvania", "PA"],
"15434": ["Elco", "Pennsylvania", "PA"],
"15435": ["Fairbank", "Pennsylvania", "PA"],
"15436": ["Fairchance", "Pennsylvania", "PA"],
"15437": ["Farmington", "Pennsylvania", "PA"],
"15438": ["Fayette City", "Pennsylvania", "PA"],
"15440": ["Gibbon Glade", "Pennsylvania", "PA"],
"15442": ["Grindstone", "Pennsylvania", "PA"],
"15443": ["Hibbs", "Pennsylvania", "PA"],
"15444": ["Hiller", "Pennsylvania", "PA"],
"15445": ["Hopwood", "Pennsylvania", "PA"],
"15446": ["Indian Head", "Pennsylvania", "PA"],
"15447": ["Isabella", "Pennsylvania", "PA"],
"15448": ["Jacobs Creek", "Pennsylvania", "PA"],
"15449": ["Keisterville", "Pennsylvania", "PA"],
"15450": ["La Belle", "Pennsylvania", "PA"],
"15451": ["Lake Lynn", "Pennsylvania", "PA"],
"15454": ["Leckrone", "Pennsylvania", "PA"],
"15455": ["Leisenring", "Pennsylvania", "PA"],
"15456": ["Lemont Furnace", "Pennsylvania", "PA"],
"15458": ["McClellandtown", "Pennsylvania", "PA"],
"15459": ["Markleysburg", "Pennsylvania", "PA"],
"15460": ["Martin", "Pennsylvania", "PA"],
"15461": ["Masontown", "Pennsylvania", "PA"],
"15462": ["Melcroft", "Pennsylvania", "PA"],
"15463": ["Merrittstown", "Pennsylvania", "PA"],
"15464": ["Mill Run", "Pennsylvania", "PA"],
"15465": ["Mount Braddock", "Pennsylvania", "PA"],
"15466": ["Newell", "Pennsylvania", "PA"],
"15467": ["New Geneva", "Pennsylvania", "PA"],
"15468": ["New Salem", "Pennsylvania", "PA"],
"15469": ["Normalville", "Pennsylvania", "PA"],
"15470": ["Ohiopyle", "Pennsylvania", "PA"],
"15472": ["Oliver", "Pennsylvania", "PA"],
"15473": ["Perryopolis", "Pennsylvania", "PA"],
"15474": ["Point Marion", "Pennsylvania", "PA"],
"15475": ["Republic", "Pennsylvania", "PA"],
"15476": ["Ronco", "Pennsylvania", "PA"],
"15477": ["Roscoe", "Pennsylvania", "PA"],
"15478": ["Smithfield", "Pennsylvania", "PA"],
"15479": ["Smithton", "Pennsylvania", "PA"],
"15480": ["Smock", "Pennsylvania", "PA"],
"15482": ["Star Junction", "Pennsylvania", "PA"],
"15483": ["Stockdale", "Pennsylvania", "PA"],
"15484": ["Uledi", "Pennsylvania", "PA"],
"15486": ["Vanderbilt", "Pennsylvania", "PA"],
"15488": ["Waltersburg", "Pennsylvania", "PA"],
"15489": ["West Leisenring", "Pennsylvania", "PA"],
"15490": ["White", "Pennsylvania", "PA"],
"15492": ["Wickhaven", "Pennsylvania", "PA"],
"15501": ["Somerset", "Pennsylvania", "PA"],
"15502": ["Hidden Valley", "Pennsylvania", "PA"],
"15510": ["Somerset", "Pennsylvania", "PA"],
"15520": ["Acosta", "Pennsylvania", "PA"],
"15521": ["Alum Bank", "Pennsylvania", "PA"],
"15522": ["Bedford", "Pennsylvania", "PA"],
"15530": ["Berlin", "Pennsylvania", "PA"],
"15531": ["Boswell", "Pennsylvania", "PA"],
"15532": ["Boynton", "Pennsylvania", "PA"],
"15533": ["Breezewood", "Pennsylvania", "PA"],
"15534": ["Buffalo Mills", "Pennsylvania", "PA"],
"15535": ["Clearville", "Pennsylvania", "PA"],
"15536": ["Crystal Spring", "Pennsylvania", "PA"],
"15537": ["Everett", "Pennsylvania", "PA"],
"15538": ["Fairhope", "Pennsylvania", "PA"],
"15539": ["Fishertown", "Pennsylvania", "PA"],
"15540": ["Fort Hill", "Pennsylvania", "PA"],
"15541": ["Friedens", "Pennsylvania", "PA"],
"15542": ["Garrett", "Pennsylvania", "PA"],
"15544": ["Gray", "Pennsylvania", "PA"],
"15545": ["Hyndman", "Pennsylvania", "PA"],
"15546": ["Jenners", "Pennsylvania", "PA"],
"15547": ["Jennerstown", "Pennsylvania", "PA"],
"15550": ["Manns Choice", "Pennsylvania", "PA"],
"15551": ["Markleton", "Pennsylvania", "PA"],
"15552": ["Meyersdale", "Pennsylvania", "PA"],
"15554": ["New Paris", "Pennsylvania", "PA"],
"15555": ["Quecreek", "Pennsylvania", "PA"],
"15557": ["Rockwood", "Pennsylvania", "PA"],
"15558": ["Salisbury", "Pennsylvania", "PA"],
"15559": ["Schellsburg", "Pennsylvania", "PA"],
"15560": ["Shanksville", "Pennsylvania", "PA"],
"15561": ["Sipesville", "Pennsylvania", "PA"],
"15562": ["Springs", "Pennsylvania", "PA"],
"15563": ["Stoystown", "Pennsylvania", "PA"],
"15564": ["Wellersburg", "Pennsylvania", "PA"],
"15565": ["West Salisbury", "Pennsylvania", "PA"],
"15601": ["Greensburg", "Pennsylvania", "PA"],
"15610": ["Acme", "Pennsylvania", "PA"],
"15611": ["Adamsburg", "Pennsylvania", "PA"],
"15612": ["Alverton", "Pennsylvania", "PA"],
"15613": ["Apollo", "Pennsylvania", "PA"],
"15615": ["Ardara", "Pennsylvania", "PA"],
"15616": ["Armbrust", "Pennsylvania", "PA"],
"15617": ["Arona", "Pennsylvania", "PA"],
"15618": ["Avonmore", "Pennsylvania", "PA"],
"15619": ["Bovard", "Pennsylvania", "PA"],
"15620": ["Bradenville", "Pennsylvania", "PA"],
"15622": ["Champion", "Pennsylvania", "PA"],
"15623": ["Claridge", "Pennsylvania", "PA"],
"15624": ["Crabtree", "Pennsylvania", "PA"],
"15625": ["Darragh", "Pennsylvania", "PA"],
"15626": ["Delmont", "Pennsylvania", "PA"],
"15627": ["Derry", "Pennsylvania", "PA"],
"15628": ["Donegal", "Pennsylvania", "PA"],
"15629": ["East Vandergrift", "Pennsylvania", "PA"],
"15631": ["Everson", "Pennsylvania", "PA"],
"15632": ["Export", "Pennsylvania", "PA"],
"15633": ["Forbes Road", "Pennsylvania", "PA"],
"15634": ["Grapeville", "Pennsylvania", "PA"],
"15635": ["Hannastown", "Pennsylvania", "PA"],
"15636": ["Harrison City", "Pennsylvania", "PA"],
"15637": ["Herminie", "Pennsylvania", "PA"],
"15638": ["Hostetter", "Pennsylvania", "PA"],
"15639": ["Hunker", "Pennsylvania", "PA"],
"15640": ["Hutchinson", "Pennsylvania", "PA"],
"15641": ["Hyde Park", "Pennsylvania", "PA"],
"15642": ["Irwin", "Pennsylvania", "PA"],
"15644": ["Jeannette", "Pennsylvania", "PA"],
"15646": ["Jones Mills", "Pennsylvania", "PA"],
"15647": ["Larimer", "Pennsylvania", "PA"],
"15650": ["Latrobe", "Pennsylvania", "PA"],
"15655": ["Laughlintown", "Pennsylvania", "PA"],
"15656": ["Leechburg", "Pennsylvania", "PA"],
"15658": ["Ligonier", "Pennsylvania", "PA"],
"15660": ["Lowber", "Pennsylvania", "PA"],
"15661": ["Loyalhanna", "Pennsylvania", "PA"],
"15662": ["Luxor", "Pennsylvania", "PA"],
"15663": ["Madison", "Pennsylvania", "PA"],
"15665": ["Manor", "Pennsylvania", "PA"],
"15666": ["Mount Pleasant", "Pennsylvania", "PA"],
"15668": ["Murrysville", "Pennsylvania", "PA"],
"15670": ["New Alexandria", "Pennsylvania", "PA"],
"15671": ["New Derry", "Pennsylvania", "PA"],
"15672": ["New Stanton", "Pennsylvania", "PA"],
"15673": ["North Apollo", "Pennsylvania", "PA"],
"15674": ["Norvelt", "Pennsylvania", "PA"],
"15675": ["Penn", "Pennsylvania", "PA"],
"15676": ["Pleasant Unity", "Pennsylvania", "PA"],
"15677": ["Rector", "Pennsylvania", "PA"],
"15678": ["Rillton", "Pennsylvania", "PA"],
"15679": ["Ruffs Dale", "Pennsylvania", "PA"],
"15680": ["Salina", "Pennsylvania", "PA"],
"15681": ["Saltsburg", "Pennsylvania", "PA"],
"15682": ["Schenley", "Pennsylvania", "PA"],
"15683": ["Scottdale", "Pennsylvania", "PA"],
"15684": ["Slickville", "Pennsylvania", "PA"],
"15686": ["Spring Church", "Pennsylvania", "PA"],
"15687": ["Stahlstown", "Pennsylvania", "PA"],
"15688": ["Tarrs", "Pennsylvania", "PA"],
"15689": ["United", "Pennsylvania", "PA"],
"15690": ["Vandergrift", "Pennsylvania", "PA"],
"15691": ["Wendel", "Pennsylvania", "PA"],
"15692": ["Westmoreland City", "Pennsylvania", "PA"],
"15693": ["Whitney", "Pennsylvania", "PA"],
"15695": ["Wyano", "Pennsylvania", "PA"],
"15696": ["Youngstown", "Pennsylvania", "PA"],
"15697": ["Youngwood", "Pennsylvania", "PA"],
"15698": ["Yukon", "Pennsylvania", "PA"],
"15701": ["Indiana", "Pennsylvania", "PA"],
"15705": ["Indiana", "Pennsylvania", "PA"],
"15710": ["Alverda", "Pennsylvania", "PA"],
"15711": ["Anita", "Pennsylvania", "PA"],
"15712": ["Arcadia", "Pennsylvania", "PA"],
"15713": ["Aultman", "Pennsylvania", "PA"],
"15714": ["Northern Cambria", "Pennsylvania", "PA"],
"15715": ["Big Run", "Pennsylvania", "PA"],
"15716": ["Black Lick", "Pennsylvania", "PA"],
"15717": ["Blairsville", "Pennsylvania", "PA"],
"15720": ["Brush Valley", "Pennsylvania", "PA"],
"15721": ["Burnside", "Pennsylvania", "PA"],
"15722": ["Carrolltown", "Pennsylvania", "PA"],
"15723": ["Chambersville", "Pennsylvania", "PA"],
"15724": ["Cherry Tree", "Pennsylvania", "PA"],
"15725": ["Clarksburg", "Pennsylvania", "PA"],
"15727": ["Clune", "Pennsylvania", "PA"],
"15728": ["Clymer", "Pennsylvania", "PA"],
"15729": ["Commodore", "Pennsylvania", "PA"],
"15730": ["Coolspring", "Pennsylvania", "PA"],
"15731": ["Coral", "Pennsylvania", "PA"],
"15732": ["Creekside", "Pennsylvania", "PA"],
"15733": ["De Lancey", "Pennsylvania", "PA"],
"15734": ["Dixonville", "Pennsylvania", "PA"],
"15736": ["Elderton", "Pennsylvania", "PA"],
"15737": ["Elmora", "Pennsylvania", "PA"],
"15738": ["Emeigh", "Pennsylvania", "PA"],
"15739": ["Ernest", "Pennsylvania", "PA"],
"15741": ["Gipsy", "Pennsylvania", "PA"],
"15742": ["Glen Campbell", "Pennsylvania", "PA"],
"15744": ["Hamilton", "Pennsylvania", "PA"],
"15745": ["Heilwood", "Pennsylvania", "PA"],
"15746": ["Hillsdale", "Pennsylvania", "PA"],
"15747": ["Home", "Pennsylvania", "PA"],
"15748": ["Homer City", "Pennsylvania", "PA"],
"15750": ["Josephine", "Pennsylvania", "PA"],
"15752": ["Kent", "Pennsylvania", "PA"],
"15753": ["La Jose", "Pennsylvania", "PA"],
"15754": ["Lucernemines", "Pennsylvania", "PA"],
"15756": ["McIntyre", "Pennsylvania", "PA"],
"15757": ["Mahaffey", "Pennsylvania", "PA"],
"15759": ["Marion Center", "Pennsylvania", "PA"],
"15760": ["Marsteller", "Pennsylvania", "PA"],
"15761": ["Mentcle", "Pennsylvania", "PA"],
"15762": ["Nicktown", "Pennsylvania", "PA"],
"15763": ["Northpoint", "Pennsylvania", "PA"],
"15764": ["Oliveburg", "Pennsylvania", "PA"],
"15765": ["Penn Run", "Pennsylvania", "PA"],
"15767": ["Punxsutawney", "Pennsylvania", "PA"],
"15770": ["Ringgold", "Pennsylvania", "PA"],
"15771": ["Rochester Mills", "Pennsylvania", "PA"],
"15772": ["Rossiter", "Pennsylvania", "PA"],
"15773": ["Saint Benedict", "Pennsylvania", "PA"],
"15774": ["Shelocta", "Pennsylvania", "PA"],
"15775": ["Spangler", "Pennsylvania", "PA"],
"15776": ["Sprankle Mills", "Pennsylvania", "PA"],
"15777": ["Starford", "Pennsylvania", "PA"],
"15778": ["Timblin", "Pennsylvania", "PA"],
"15779": ["Torrance", "Pennsylvania", "PA"],
"15780": ["Valier", "Pennsylvania", "PA"],
"15781": ["Walston", "Pennsylvania", "PA"],
"15783": ["West Lebanon", "Pennsylvania", "PA"],
"15784": ["Worthville", "Pennsylvania", "PA"],
"15801": ["Du Bois", "Pennsylvania", "PA"],
"15821": ["Benezett", "Pennsylvania", "PA"],
"15823": ["Brockport", "Pennsylvania", "PA"],
"15824": ["Brockway", "Pennsylvania", "PA"],
"15825": ["Brookville", "Pennsylvania", "PA"],
"15827": ["Byrnedale", "Pennsylvania", "PA"],
"15828": ["Clarington", "Pennsylvania", "PA"],
"15829": ["Corsica", "Pennsylvania", "PA"],
"15831": ["Dagus Mines", "Pennsylvania", "PA"],
"15832": ["Driftwood", "Pennsylvania", "PA"],
"15834": ["Emporium", "Pennsylvania", "PA"],
"15840": ["Falls Creek", "Pennsylvania", "PA"],
"15841": ["Force", "Pennsylvania", "PA"],
"15845": ["Johnsonburg", "Pennsylvania", "PA"],
"15846": ["Kersey", "Pennsylvania", "PA"],
"15847": ["Knox Dale", "Pennsylvania", "PA"],
"15848": ["Luthersburg", "Pennsylvania", "PA"],
"15849": ["Penfield", "Pennsylvania", "PA"],
"15851": ["Reynoldsville", "Pennsylvania", "PA"],
"15853": ["Ridgway", "Pennsylvania", "PA"],
"15856": ["Rockton", "Pennsylvania", "PA"],
"15857": ["Saint Marys", "Pennsylvania", "PA"],
"15860": ["Sigel", "Pennsylvania", "PA"],
"15861": ["Sinnamahoning", "Pennsylvania", "PA"],
"15863": ["Stump Creek", "Pennsylvania", "PA"],
"15864": ["Summerville", "Pennsylvania", "PA"],
"15865": ["Sykesville", "Pennsylvania", "PA"],
"15866": ["Troutville", "Pennsylvania", "PA"],
"15868": ["Weedville", "Pennsylvania", "PA"],
"15870": ["Wilcox", "Pennsylvania", "PA"],
"15901": ["Johnstown", "Pennsylvania", "PA"],
"15902": ["Johnstown", "Pennsylvania", "PA"],
"15904": ["Johnstown", "Pennsylvania", "PA"],
"15905": ["Johnstown", "Pennsylvania", "PA"],
"15906": ["Johnstown", "Pennsylvania", "PA"],
"15909": ["Johnstown", "Pennsylvania", "PA"],
"15920": ["Armagh", "Pennsylvania", "PA"],
"15921": ["Beaverdale", "Pennsylvania", "PA"],
"15922": ["Belsano", "Pennsylvania", "PA"],
"15923": ["Bolivar", "Pennsylvania", "PA"],
"15924": ["Cairnbrook", "Pennsylvania", "PA"],
"15925": ["Cassandra", "Pennsylvania", "PA"],
"15926": ["Central City", "Pennsylvania", "PA"],
"15927": ["Colver", "Pennsylvania", "PA"],
"15928": ["Davidsville", "Pennsylvania", "PA"],
"15929": ["Dilltown", "Pennsylvania", "PA"],
"15930": ["Dunlo", "Pennsylvania", "PA"],
"15931": ["Ebensburg", "Pennsylvania", "PA"],
"15934": ["Elton", "Pennsylvania", "PA"],
"15935": ["Hollsopple", "Pennsylvania", "PA"],
"15936": ["Hooversville", "Pennsylvania", "PA"],
"15937": ["Jerome", "Pennsylvania", "PA"],
"15938": ["Lilly", "Pennsylvania", "PA"],
"15940": ["Loretto", "Pennsylvania", "PA"],
"15942": ["Mineral Point", "Pennsylvania", "PA"],
"15943": ["Nanty Glo", "Pennsylvania", "PA"],
"15944": ["New Florence", "Pennsylvania", "PA"],
"15945": ["Parkhill", "Pennsylvania", "PA"],
"15946": ["Portage", "Pennsylvania", "PA"],
"15948": ["Revloc", "Pennsylvania", "PA"],
"15949": ["Robinson", "Pennsylvania", "PA"],
"15951": ["Saint Michael", "Pennsylvania", "PA"],
"15952": ["Salix", "Pennsylvania", "PA"],
"15953": ["Seanor", "Pennsylvania", "PA"],
"15954": ["Seward", "Pennsylvania", "PA"],
"15955": ["Sidman", "Pennsylvania", "PA"],
"15956": ["South Fork", "Pennsylvania", "PA"],
"15957": ["Strongstown", "Pennsylvania", "PA"],
"15958": ["Summerhill", "Pennsylvania", "PA"],
"15960": ["Twin Rocks", "Pennsylvania", "PA"],
"15961": ["Vintondale", "Pennsylvania", "PA"],
"15962": ["Wilmore", "Pennsylvania", "PA"],
"15963": ["Windber", "Pennsylvania", "PA"],
"16001": ["Butler", "Pennsylvania", "PA"],
"16002": ["Butler", "Pennsylvania", "PA"],
"16020": ["Boyers", "Pennsylvania", "PA"],
"16022": ["Bruin", "Pennsylvania", "PA"],
"16023": ["Cabot", "Pennsylvania", "PA"],
"16024": ["Callery", "Pennsylvania", "PA"],
"16025": ["Chicora", "Pennsylvania", "PA"],
"16027": ["Connoquenessing", "Pennsylvania", "PA"],
"16028": ["East Brady", "Pennsylvania", "PA"],
"16029": ["East Butler", "Pennsylvania", "PA"],
"16030": ["Eau Claire", "Pennsylvania", "PA"],
"16033": ["Evans City", "Pennsylvania", "PA"],
"16034": ["Fenelton", "Pennsylvania", "PA"],
"16035": ["Forestville", "Pennsylvania", "PA"],
"16036": ["Foxburg", "Pennsylvania", "PA"],
"16037": ["Harmony", "Pennsylvania", "PA"],
"16038": ["Harrisville", "Pennsylvania", "PA"],
"16040": ["Hilliards", "Pennsylvania", "PA"],
"16041": ["Karns City", "Pennsylvania", "PA"],
"16045": ["Lyndora", "Pennsylvania", "PA"],
"16046": ["Mars", "Pennsylvania", "PA"],
"16048": ["North Washington", "Pennsylvania", "PA"],
"16049": ["Parker", "Pennsylvania", "PA"],
"16050": ["Petrolia", "Pennsylvania", "PA"],
"16051": ["Portersville", "Pennsylvania", "PA"],
"16052": ["Prospect", "Pennsylvania", "PA"],
"16053": ["Renfrew", "Pennsylvania", "PA"],
"16054": ["Saint Petersburg", "Pennsylvania", "PA"],
"16055": ["Sarver", "Pennsylvania", "PA"],
"16056": ["Saxonburg", "Pennsylvania", "PA"],
"16057": ["Slippery Rock", "Pennsylvania", "PA"],
"16059": ["Valencia", "Pennsylvania", "PA"],
"16061": ["West Sunbury", "Pennsylvania", "PA"],
"16063": ["Zelienople", "Pennsylvania", "PA"],
"16066": ["Cranberry Township", "Pennsylvania", "PA"],
"16101": ["New Castle", "Pennsylvania", "PA"],
"16102": ["New Castle", "Pennsylvania", "PA"],
"16105": ["New Castle", "Pennsylvania", "PA"],
"16110": ["Adamsville", "Pennsylvania", "PA"],
"16111": ["Atlantic", "Pennsylvania", "PA"],
"16112": ["Bessemer", "Pennsylvania", "PA"],
"16113": ["Clark", "Pennsylvania", "PA"],
"16114": ["Clarks Mills", "Pennsylvania", "PA"],
"16115": ["Darlington", "Pennsylvania", "PA"],
"16116": ["Edinburg", "Pennsylvania", "PA"],
"16117": ["Ellwood City", "Pennsylvania", "PA"],
"16120": ["Enon Valley", "Pennsylvania", "PA"],
"16121": ["Farrell", "Pennsylvania", "PA"],
"16123": ["Fombell", "Pennsylvania", "PA"],
"16124": ["Fredonia", "Pennsylvania", "PA"],
"16125": ["Greenville", "Pennsylvania", "PA"],
"16127": ["Grove City", "Pennsylvania", "PA"],
"16130": ["Hadley", "Pennsylvania", "PA"],
"16131": ["Hartstown", "Pennsylvania", "PA"],
"16132": ["Hillsville", "Pennsylvania", "PA"],
"16133": ["Jackson Center", "Pennsylvania", "PA"],
"16134": ["Jamestown", "Pennsylvania", "PA"],
"16136": ["Koppel", "Pennsylvania", "PA"],
"16137": ["Mercer", "Pennsylvania", "PA"],
"16140": ["New Bedford", "Pennsylvania", "PA"],
"16141": ["New Galilee", "Pennsylvania", "PA"],
"16142": ["New Wilmington", "Pennsylvania", "PA"],
"16143": ["Pulaski", "Pennsylvania", "PA"],
"16145": ["Sandy Lake", "Pennsylvania", "PA"],
"16146": ["Sharon", "Pennsylvania", "PA"],
"16148": ["Hermitage", "Pennsylvania", "PA"],
"16150": ["Sharpsville", "Pennsylvania", "PA"],
"16151": ["Sheakleyville", "Pennsylvania", "PA"],
"16153": ["Stoneboro", "Pennsylvania", "PA"],
"16154": ["Transfer", "Pennsylvania", "PA"],
"16155": ["Villa Maria", "Pennsylvania", "PA"],
"16156": ["Volant", "Pennsylvania", "PA"],
"16157": ["Wampum", "Pennsylvania", "PA"],
"16159": ["West Middlesex", "Pennsylvania", "PA"],
"16160": ["West Pittsburg", "Pennsylvania", "PA"],
"16161": ["Wheatland", "Pennsylvania", "PA"],
"16172": ["New Wilmington", "Pennsylvania", "PA"],
"16201": ["Kittanning", "Pennsylvania", "PA"],
"16210": ["Adrian", "Pennsylvania", "PA"],
"16211": ["Beyer", "Pennsylvania", "PA"],
"16212": ["Cadogan", "Pennsylvania", "PA"],
"16213": ["Callensburg", "Pennsylvania", "PA"],
"16214": ["Clarion", "Pennsylvania", "PA"],
"16217": ["Cooksburg", "Pennsylvania", "PA"],
"16218": ["Cowansville", "Pennsylvania", "PA"],
"16220": ["Crown", "Pennsylvania", "PA"],
"16222": ["Dayton", "Pennsylvania", "PA"],
"16223": ["Distant", "Pennsylvania", "PA"],
"16224": ["Fairmount City", "Pennsylvania", "PA"],
"16226": ["Ford City", "Pennsylvania", "PA"],
"16228": ["Ford Cliff", "Pennsylvania", "PA"],
"16229": ["Freeport", "Pennsylvania", "PA"],
"16230": ["Hawthorn", "Pennsylvania", "PA"],
"16232": ["Knox", "Pennsylvania", "PA"],
"16233": ["Leeper", "Pennsylvania", "PA"],
"16235": ["Lucinda", "Pennsylvania", "PA"],
"16236": ["McGrann", "Pennsylvania", "PA"],
"16238": ["Manorville", "Pennsylvania", "PA"],
"16239": ["Marienville", "Pennsylvania", "PA"],
"16240": ["Mayport", "Pennsylvania", "PA"],
"16242": ["New Bethlehem", "Pennsylvania", "PA"],
"16244": ["Nu Mine", "Pennsylvania", "PA"],
"16245": ["Oak Ridge", "Pennsylvania", "PA"],
"16246": ["Plumville", "Pennsylvania", "PA"],
"16248": ["Rimersburg", "Pennsylvania", "PA"],
"16249": ["Rural Valley", "Pennsylvania", "PA"],
"16250": ["Sagamore", "Pennsylvania", "PA"],
"16253": ["Seminole", "Pennsylvania", "PA"],
"16254": ["Shippenville", "Pennsylvania", "PA"],
"16255": ["Sligo", "Pennsylvania", "PA"],
"16256": ["Smicksburg", "Pennsylvania", "PA"],
"16258": ["Strattanville", "Pennsylvania", "PA"],
"16259": ["Templeton", "Pennsylvania", "PA"],
"16260": ["Vowinckel", "Pennsylvania", "PA"],
"16262": ["Worthington", "Pennsylvania", "PA"],
"16263": ["Yatesboro", "Pennsylvania", "PA"],
"16301": ["Oil City", "Pennsylvania", "PA"],
"16311": ["Carlton", "Pennsylvania", "PA"],
"16312": ["Chandlers Valley", "Pennsylvania", "PA"],
"16313": ["Clarendon", "Pennsylvania", "PA"],
"16314": ["Cochranton", "Pennsylvania", "PA"],
"16316": ["Conneaut Lake", "Pennsylvania", "PA"],
"16317": ["Cooperstown", "Pennsylvania", "PA"],
"16319": ["Cranberry", "Pennsylvania", "PA"],
"16321": ["East Hickory", "Pennsylvania", "PA"],
"16322": ["Endeavor", "Pennsylvania", "PA"],
"16323": ["Franklin", "Pennsylvania", "PA"],
"16326": ["Fryburg", "Pennsylvania", "PA"],
"16327": ["Guys Mills", "Pennsylvania", "PA"],
"16328": ["Hydetown", "Pennsylvania", "PA"],
"16329": ["Irvine", "Pennsylvania", "PA"],
"16331": ["Kossuth", "Pennsylvania", "PA"],
"16332": ["Lickingville", "Pennsylvania", "PA"],
"16333": ["Ludlow", "Pennsylvania", "PA"],
"16334": ["Marble", "Pennsylvania", "PA"],
"16335": ["Meadville", "Pennsylvania", "PA"],
"16340": ["Pittsfield", "Pennsylvania", "PA"],
"16341": ["Pleasantville", "Pennsylvania", "PA"],
"16342": ["Polk", "Pennsylvania", "PA"],
"16343": ["Reno", "Pennsylvania", "PA"],
"16344": ["Rouseville", "Pennsylvania", "PA"],
"16345": ["Russell", "Pennsylvania", "PA"],
"16346": ["Seneca", "Pennsylvania", "PA"],
"16347": ["Sheffield", "Pennsylvania", "PA"],
"16350": ["Sugar Grove", "Pennsylvania", "PA"],
"16351": ["Tidioute", "Pennsylvania", "PA"],
"16352": ["Tiona", "Pennsylvania", "PA"],
"16353": ["Tionesta", "Pennsylvania", "PA"],
"16354": ["Titusville", "Pennsylvania", "PA"],
"16360": ["Townville", "Pennsylvania", "PA"],
"16361": ["Tylersburg", "Pennsylvania", "PA"],
"16362": ["Utica", "Pennsylvania", "PA"],
"16364": ["Venus", "Pennsylvania", "PA"],
"16365": ["Warren", "Pennsylvania", "PA"],
"16370": ["West Hickory", "Pennsylvania", "PA"],
"16371": ["Youngsville", "Pennsylvania", "PA"],
"16372": ["Clintonville", "Pennsylvania", "PA"],
"16373": ["Emlenton", "Pennsylvania", "PA"],
"16374": ["Kennerdell", "Pennsylvania", "PA"],
"16401": ["Albion", "Pennsylvania", "PA"],
"16402": ["Bear Lake", "Pennsylvania", "PA"],
"16403": ["Cambridge Springs", "Pennsylvania", "PA"],
"16404": ["Centerville", "Pennsylvania", "PA"],
"16405": ["Columbus", "Pennsylvania", "PA"],
"16406": ["Conneautville", "Pennsylvania", "PA"],
"16407": ["Corry", "Pennsylvania", "PA"],
"16410": ["Cranesville", "Pennsylvania", "PA"],
"16411": ["East Springfield", "Pennsylvania", "PA"],
"16412": ["Edinboro", "Pennsylvania", "PA"],
"16415": ["Fairview", "Pennsylvania", "PA"],
"16416": ["Garland", "Pennsylvania", "PA"],
"16417": ["Girard", "Pennsylvania", "PA"],
"16420": ["Grand Valley", "Pennsylvania", "PA"],
"16421": ["Harborcreek", "Pennsylvania", "PA"],
"16422": ["Harmonsburg", "Pennsylvania", "PA"],
"16423": ["Lake City", "Pennsylvania", "PA"],
"16424": ["Linesville", "Pennsylvania", "PA"],
"16426": ["McKean", "Pennsylvania", "PA"],
"16427": ["Mill Village", "Pennsylvania", "PA"],
"16428": ["North East", "Pennsylvania", "PA"],
"16430": ["North Springfield", "Pennsylvania", "PA"],
"16433": ["Saegertown", "Pennsylvania", "PA"],
"16434": ["Spartansburg", "Pennsylvania", "PA"],
"16435": ["Springboro", "Pennsylvania", "PA"],
"16436": ["Spring Creek", "Pennsylvania", "PA"],
"16438": ["Union City", "Pennsylvania", "PA"],
"16440": ["Venango", "Pennsylvania", "PA"],
"16441": ["Waterford", "Pennsylvania", "PA"],
"16442": ["Wattsburg", "Pennsylvania", "PA"],
"16443": ["West Springfield", "Pennsylvania", "PA"],
"16444": ["Edinboro", "Pennsylvania", "PA"],
"16475": ["Albion", "Pennsylvania", "PA"],
"16501": ["Erie", "Pennsylvania", "PA"],
"16502": ["Erie", "Pennsylvania", "PA"],
"16503": ["Erie", "Pennsylvania", "PA"],
"16504": ["Erie", "Pennsylvania", "PA"],
"16505": ["Erie", "Pennsylvania", "PA"],
"16506": ["Erie", "Pennsylvania", "PA"],
"16507": ["Erie", "Pennsylvania", "PA"],
"16508": ["Erie", "Pennsylvania", "PA"],
"16509": ["Erie", "Pennsylvania", "PA"],
"16510": ["Erie", "Pennsylvania", "PA"],
"16511": ["Erie", "Pennsylvania", "PA"],
"16546": ["Erie", "Pennsylvania", "PA"],
"16550": ["Erie", "Pennsylvania", "PA"],
"16563": ["Erie", "Pennsylvania", "PA"],
"16601": ["Altoona", "Pennsylvania", "PA"],
"16602": ["Altoona", "Pennsylvania", "PA"],
"16611": ["Alexandria", "Pennsylvania", "PA"],
"16613": ["Ashville", "Pennsylvania", "PA"],
"16616": ["Beccaria", "Pennsylvania", "PA"],
"16617": ["Bellwood", "Pennsylvania", "PA"],
"16619": ["Blandburg", "Pennsylvania", "PA"],
"16620": ["Brisbin", "Pennsylvania", "PA"],
"16621": ["Broad Top", "Pennsylvania", "PA"],
"16622": ["Calvin", "Pennsylvania", "PA"],
"16623": ["Cassville", "Pennsylvania", "PA"],
"16624": ["Chest Springs", "Pennsylvania", "PA"],
"16625": ["Claysburg", "Pennsylvania", "PA"],
"16627": ["Coalport", "Pennsylvania", "PA"],
"16630": ["Cresson", "Pennsylvania", "PA"],
"16631": ["Curryville", "Pennsylvania", "PA"],
"16633": ["Defiance", "Pennsylvania", "PA"],
"16634": ["Dudley", "Pennsylvania", "PA"],
"16635": ["Duncansville", "Pennsylvania", "PA"],
"16636": ["Dysart", "Pennsylvania", "PA"],
"16637": ["East Freedom", "Pennsylvania", "PA"],
"16638": ["Entriken", "Pennsylvania", "PA"],
"16639": ["Fallentimber", "Pennsylvania", "PA"],
"16640": ["Flinton", "Pennsylvania", "PA"],
"16641": ["Gallitzin", "Pennsylvania", "PA"],
"16645": ["Glen Hope", "Pennsylvania", "PA"],
"16646": ["Hastings", "Pennsylvania", "PA"],
"16647": ["Hesston", "Pennsylvania", "PA"],
"16648": ["Hollidaysburg", "Pennsylvania", "PA"],
"16650": ["Hopewell", "Pennsylvania", "PA"],
"16651": ["Houtzdale", "Pennsylvania", "PA"],
"16652": ["Huntingdon", "Pennsylvania", "PA"],
"16654": ["Huntingdon", "Pennsylvania", "PA"],
"16655": ["Imler", "Pennsylvania", "PA"],
"16656": ["Irvona", "Pennsylvania", "PA"],
"16657": ["James Creek", "Pennsylvania", "PA"],
"16659": ["Loysburg", "Pennsylvania", "PA"],
"16661": ["Madera", "Pennsylvania", "PA"],
"16662": ["Martinsburg", "Pennsylvania", "PA"],
"16664": ["New Enterprise", "Pennsylvania", "PA"],
"16665": ["Newry", "Pennsylvania", "PA"],
"16666": ["Osceola Mills", "Pennsylvania", "PA"],
"16667": ["Osterburg", "Pennsylvania", "PA"],
"16668": ["Patton", "Pennsylvania", "PA"],
"16669": ["Petersburg", "Pennsylvania", "PA"],
"16670": ["Queen", "Pennsylvania", "PA"],
"16671": ["Ramey", "Pennsylvania", "PA"],
"16672": ["Riddlesburg", "Pennsylvania", "PA"],
"16673": ["Roaring Spring", "Pennsylvania", "PA"],
"16674": ["Robertsdale", "Pennsylvania", "PA"],
"16675": ["Saint Boniface", "Pennsylvania", "PA"],
"16677": ["Sandy Ridge", "Pennsylvania", "PA"],
"16678": ["Saxton", "Pennsylvania", "PA"],
"16679": ["Six Mile Run", "Pennsylvania", "PA"],
"16680": ["Smithmill", "Pennsylvania", "PA"],
"16682": ["Sproul", "Pennsylvania", "PA"],
"16683": ["Spruce Creek", "Pennsylvania", "PA"],
"16684": ["Tipton", "Pennsylvania", "PA"],
"16685": ["Todd", "Pennsylvania", "PA"],
"16686": ["Tyrone", "Pennsylvania", "PA"],
"16689": ["Waterfall", "Pennsylvania", "PA"],
"16691": ["Wells Tannery", "Pennsylvania", "PA"],
"16692": ["Westover", "Pennsylvania", "PA"],
"16693": ["Williamsburg", "Pennsylvania", "PA"],
"16694": ["Wood", "Pennsylvania", "PA"],
"16695": ["Woodbury", "Pennsylvania", "PA"],
"16699": ["Cresson", "Pennsylvania", "PA"],
"16701": ["Bradford", "Pennsylvania", "PA"],
"16720": ["Austin", "Pennsylvania", "PA"],
"16724": ["Crosby", "Pennsylvania", "PA"],
"16725": ["Custer City", "Pennsylvania", "PA"],
"16726": ["Cyclone", "Pennsylvania", "PA"],
"16727": ["Derrick City", "Pennsylvania", "PA"],
"16729": ["Duke Center", "Pennsylvania", "PA"],
"16730": ["East Smethport", "Pennsylvania", "PA"],
"16731": ["Eldred", "Pennsylvania", "PA"],
"16732": ["Gifford", "Pennsylvania", "PA"],
"16733": ["Hazel Hurst", "Pennsylvania", "PA"],
"16734": ["James City", "Pennsylvania", "PA"],
"16735": ["Kane", "Pennsylvania", "PA"],
"16738": ["Lewis Run", "Pennsylvania", "PA"],
"16740": ["Mount Jewett", "Pennsylvania", "PA"],
"16743": ["Port Allegany", "Pennsylvania", "PA"],
"16744": ["Rew", "Pennsylvania", "PA"],
"16745": ["Rixford", "Pennsylvania", "PA"],
"16746": ["Roulette", "Pennsylvania", "PA"],
"16748": ["Shinglehouse", "Pennsylvania", "PA"],
"16749": ["Smethport", "Pennsylvania", "PA"],
"16750": ["Turtlepoint", "Pennsylvania", "PA"],
"16801": ["State College", "Pennsylvania", "PA"],
"16802": ["University Park", "Pennsylvania", "PA"],
"16803": ["State College", "Pennsylvania", "PA"],
"16820": ["Aaronsburg", "Pennsylvania", "PA"],
"16821": ["Allport", "Pennsylvania", "PA"],
"16822": ["Beech Creek", "Pennsylvania", "PA"],
"16823": ["Bellefonte", "Pennsylvania", "PA"],
"16825": ["Bigler", "Pennsylvania", "PA"],
"16826": ["Blanchard", "Pennsylvania", "PA"],
"16827": ["Boalsburg", "Pennsylvania", "PA"],
"16828": ["Centre Hall", "Pennsylvania", "PA"],
"16829": ["Clarence", "Pennsylvania", "PA"],
"16830": ["Clearfield", "Pennsylvania", "PA"],
"16832": ["Coburn", "Pennsylvania", "PA"],
"16833": ["Curwensville", "Pennsylvania", "PA"],
"16834": ["Drifting", "Pennsylvania", "PA"],
"16835": ["Fleming", "Pennsylvania", "PA"],
"16836": ["Frenchville", "Pennsylvania", "PA"],
"16837": ["Glen Richey", "Pennsylvania", "PA"],
"16838": ["Grampian", "Pennsylvania", "PA"],
"16839": ["Grassflat", "Pennsylvania", "PA"],
"16840": ["Hawk Run", "Pennsylvania", "PA"],
"16841": ["Howard", "Pennsylvania", "PA"],
"16843": ["Hyde", "Pennsylvania", "PA"],
"16844": ["Julian", "Pennsylvania", "PA"],
"16845": ["Karthaus", "Pennsylvania", "PA"],
"16847": ["Kylertown", "Pennsylvania", "PA"],
"16848": ["Lamar", "Pennsylvania", "PA"],
"16849": ["Lanse", "Pennsylvania", "PA"],
"16851": ["Lemont", "Pennsylvania", "PA"],
"16852": ["Madisonburg", "Pennsylvania", "PA"],
"16853": ["Milesburg", "Pennsylvania", "PA"],
"16854": ["Millheim", "Pennsylvania", "PA"],
"16855": ["Mineral Springs", "Pennsylvania", "PA"],
"16856": ["Mingoville", "Pennsylvania", "PA"],
"16858": ["Morrisdale", "Pennsylvania", "PA"],
"16859": ["Moshannon", "Pennsylvania", "PA"],
"16860": ["Munson", "Pennsylvania", "PA"],
"16861": ["New Millport", "Pennsylvania", "PA"],
"16863": ["Olanta", "Pennsylvania", "PA"],
"16864": ["Orviston", "Pennsylvania", "PA"],
"16865": ["Pennsylvania Furnace", "Pennsylvania", "PA"],
"16866": ["Philipsburg", "Pennsylvania", "PA"],
"16868": ["Pine Grove Mills", "Pennsylvania", "PA"],
"16870": ["Port Matilda", "Pennsylvania", "PA"],
"16871": ["Pottersdale", "Pennsylvania", "PA"],
"16872": ["Rebersburg", "Pennsylvania", "PA"],
"16874": ["Snow Shoe", "Pennsylvania", "PA"],
"16875": ["Spring Mills", "Pennsylvania", "PA"],
"16876": ["Wallaceton", "Pennsylvania", "PA"],
"16877": ["Warriors Mark", "Pennsylvania", "PA"],
"16878": ["West Decatur", "Pennsylvania", "PA"],
"16879": ["Winburne", "Pennsylvania", "PA"],
"16881": ["Woodland", "Pennsylvania", "PA"],
"16882": ["Woodward", "Pennsylvania", "PA"],
"16901": ["Wellsboro", "Pennsylvania", "PA"],
"16910": ["Alba", "Pennsylvania", "PA"],
"16911": ["Arnot", "Pennsylvania", "PA"],
"16912": ["Blossburg", "Pennsylvania", "PA"],
"16914": ["Columbia Cross Roads", "Pennsylvania", "PA"],
"16915": ["Coudersport", "Pennsylvania", "PA"],
"16917": ["Covington", "Pennsylvania", "PA"],
"16920": ["Elkland", "Pennsylvania", "PA"],
"16921": ["Gaines", "Pennsylvania", "PA"],
"16922": ["Galeton", "Pennsylvania", "PA"],
"16923": ["Genesee", "Pennsylvania", "PA"],
"16925": ["Gillett", "Pennsylvania", "PA"],
"16926": ["Granville Summit", "Pennsylvania", "PA"],
"16927": ["Harrison Valley", "Pennsylvania", "PA"],
"16928": ["Knoxville", "Pennsylvania", "PA"],
"16929": ["Lawrenceville", "Pennsylvania", "PA"],
"16930": ["Liberty", "Pennsylvania", "PA"],
"16932": ["Mainesburg", "Pennsylvania", "PA"],
"16933": ["Mansfield", "Pennsylvania", "PA"],
"16935": ["Middlebury Center", "Pennsylvania", "PA"],
"16936": ["Millerton", "Pennsylvania", "PA"],
"16937": ["Mills", "Pennsylvania", "PA"],
"16938": ["Morris", "Pennsylvania", "PA"],
"16939": ["Morris Run", "Pennsylvania", "PA"],
"16940": ["Nelson", "Pennsylvania", "PA"],
"16941": ["Genesee", "Pennsylvania", "PA"],
"16942": ["Osceola", "Pennsylvania", "PA"],
"16943": ["Sabinsville", "Pennsylvania", "PA"],
"16946": ["Tioga", "Pennsylvania", "PA"],
"16947": ["Troy", "Pennsylvania", "PA"],
"16948": ["Ulysses", "Pennsylvania", "PA"],
"16950": ["Westfield", "Pennsylvania", "PA"],
"17002": ["Allensville", "Pennsylvania", "PA"],
"17003": ["Annville", "Pennsylvania", "PA"],
"17004": ["Belleville", "Pennsylvania", "PA"],
"17005": ["Berrysburg", "Pennsylvania", "PA"],
"17006": ["Blain", "Pennsylvania", "PA"],
"17007": ["Boiling Springs", "Pennsylvania", "PA"],
"17009": ["Burnham", "Pennsylvania", "PA"],
"17010": ["Campbelltown", "Pennsylvania", "PA"],
"17011": ["Camp Hill", "Pennsylvania", "PA"],
"17013": ["Carlisle", "Pennsylvania", "PA"],
"17014": ["Cocolamus", "Pennsylvania", "PA"],
"17015": ["Carlisle", "Pennsylvania", "PA"],
"17016": ["Cornwall", "Pennsylvania", "PA"],
"17017": ["Dalmatia", "Pennsylvania", "PA"],
"17018": ["Dauphin", "Pennsylvania", "PA"],
"17019": ["Dillsburg", "Pennsylvania", "PA"],
"17020": ["Duncannon", "Pennsylvania", "PA"],
"17021": ["East Waterford", "Pennsylvania", "PA"],
"17022": ["Elizabethtown", "Pennsylvania", "PA"],
"17023": ["Elizabethville", "Pennsylvania", "PA"],
"17024": ["Elliottsburg", "Pennsylvania", "PA"],
"17025": ["Enola", "Pennsylvania", "PA"],
"17026": ["Fredericksburg", "Pennsylvania", "PA"],
"17027": ["Grantham", "Pennsylvania", "PA"],
"17028": ["Grantville", "Pennsylvania", "PA"],
"17029": ["Granville", "Pennsylvania", "PA"],
"17030": ["Gratz", "Pennsylvania", "PA"],
"17032": ["Halifax", "Pennsylvania", "PA"],
"17033": ["Hershey", "Pennsylvania", "PA"],
"17034": ["Highspire", "Pennsylvania", "PA"],
"17035": ["Honey Grove", "Pennsylvania", "PA"],
"17036": ["Hummelstown", "Pennsylvania", "PA"],
"17037": ["Ickesburg", "Pennsylvania", "PA"],
"17038": ["Jonestown", "Pennsylvania", "PA"],
"17039": ["Kleinfeltersville", "Pennsylvania", "PA"],
"17040": ["Landisburg", "Pennsylvania", "PA"],
"17041": ["Lawn", "Pennsylvania", "PA"],
"17042": ["Lebanon", "Pennsylvania", "PA"],
"17043": ["Lemoyne", "Pennsylvania", "PA"],
"17044": ["Lewistown", "Pennsylvania", "PA"],
"17045": ["Liverpool", "Pennsylvania", "PA"],
"17046": ["Lebanon", "Pennsylvania", "PA"],
"17047": ["Loysville", "Pennsylvania", "PA"],
"17048": ["Lykens", "Pennsylvania", "PA"],
"17049": ["McAlisterville", "Pennsylvania", "PA"],
"17050": ["Mechanicsburg", "Pennsylvania", "PA"],
"17051": ["McVeytown", "Pennsylvania", "PA"],
"17052": ["Mapleton Depot", "Pennsylvania", "PA"],
"17053": ["Marysville", "Pennsylvania", "PA"],
"17054": ["Mattawana", "Pennsylvania", "PA"],
"17055": ["Mechanicsburg", "Pennsylvania", "PA"],
"17056": ["Mexico", "Pennsylvania", "PA"],
"17057": ["Middletown", "Pennsylvania", "PA"],
"17058": ["Mifflin", "Pennsylvania", "PA"],
"17059": ["Mifflintown", "Pennsylvania", "PA"],
"17060": ["Mill Creek", "Pennsylvania", "PA"],
"17061": ["Millersburg", "Pennsylvania", "PA"],
"17062": ["Millerstown", "Pennsylvania", "PA"],
"17063": ["Milroy", "Pennsylvania", "PA"],
"17064": ["Mount Gretna", "Pennsylvania", "PA"],
"17065": ["Mount Holly Springs", "Pennsylvania", "PA"],
"17066": ["Mount Union", "Pennsylvania", "PA"],
"17067": ["Myerstown", "Pennsylvania", "PA"],
"17068": ["New Bloomfield", "Pennsylvania", "PA"],
"17069": ["New Buffalo", "Pennsylvania", "PA"],
"17070": ["New Cumberland", "Pennsylvania", "PA"],
"17071": ["New Germantown", "Pennsylvania", "PA"],
"17073": ["Newmanstown", "Pennsylvania", "PA"],
"17074": ["Newport", "Pennsylvania", "PA"],
"17075": ["Newton Hamilton", "Pennsylvania", "PA"],
"17076": ["Oakland Mills", "Pennsylvania", "PA"],
"17077": ["Ono", "Pennsylvania", "PA"],
"17078": ["Palmyra", "Pennsylvania", "PA"],
"17080": ["Pillow", "Pennsylvania", "PA"],
"17081": ["Plainfield", "Pennsylvania", "PA"],
"17082": ["Port Royal", "Pennsylvania", "PA"],
"17084": ["Reedsville", "Pennsylvania", "PA"],
"17086": ["Richfield", "Pennsylvania", "PA"],
"17087": ["Richland", "Pennsylvania", "PA"],
"17088": ["Schaefferstown", "Pennsylvania", "PA"],
"17090": ["Shermans Dale", "Pennsylvania", "PA"],
"17093": ["Summerdale", "Pennsylvania", "PA"],
"17094": ["Thompsontown", "Pennsylvania", "PA"],
"17097": ["Wiconisco", "Pennsylvania", "PA"],
"17098": ["Williamstown", "Pennsylvania", "PA"],
"17099": ["Yeagertown", "Pennsylvania", "PA"],
"17101": ["Harrisburg", "Pennsylvania", "PA"],
"17102": ["Harrisburg", "Pennsylvania", "PA"],
"17103": ["Harrisburg", "Pennsylvania", "PA"],
"17104": ["Harrisburg", "Pennsylvania", "PA"],
"17109": ["Harrisburg", "Pennsylvania", "PA"],
"17110": ["Harrisburg", "Pennsylvania", "PA"],
"17111": ["Harrisburg", "Pennsylvania", "PA"],
"17112": ["Harrisburg", "Pennsylvania", "PA"],
"17113": ["Harrisburg", "Pennsylvania", "PA"],
"17120": ["Harrisburg", "Pennsylvania", "PA"],
"17201": ["Chambersburg", "Pennsylvania", "PA"],
"17202": ["Chambersburg", "Pennsylvania", "PA"],
"17210": ["Amberson", "Pennsylvania", "PA"],
"17211": ["Artemas", "Pennsylvania", "PA"],
"17212": ["Big Cove Tannery", "Pennsylvania", "PA"],
"17213": ["Blairs Mills", "Pennsylvania", "PA"],
"17214": ["Blue Ridge Summit", "Pennsylvania", "PA"],
"17215": ["Burnt Cabins", "Pennsylvania", "PA"],
"17217": ["Concord", "Pennsylvania", "PA"],
"17219": ["Doylesburg", "Pennsylvania", "PA"],
"17220": ["Dry Run", "Pennsylvania", "PA"],
"17221": ["Fannettsburg", "Pennsylvania", "PA"],
"17222": ["Fayetteville", "Pennsylvania", "PA"],
"17223": ["Fort Littleton", "Pennsylvania", "PA"],
"17224": ["Fort Loudon", "Pennsylvania", "PA"],
"17225": ["Greencastle", "Pennsylvania", "PA"],
"17228": ["Harrisonville", "Pennsylvania", "PA"],
"17229": ["Hustontown", "Pennsylvania", "PA"],
"17232": ["Lurgan", "Pennsylvania", "PA"],
"17233": ["McConnellsburg", "Pennsylvania", "PA"],
"17235": ["Marion", "Pennsylvania", "PA"],
"17236": ["Mercersburg", "Pennsylvania", "PA"],
"17237": ["Mont Alto", "Pennsylvania", "PA"],
"17238": ["Needmore", "Pennsylvania", "PA"],
"17239": ["Neelyton", "Pennsylvania", "PA"],
"17240": ["Newburg", "Pennsylvania", "PA"],
"17241": ["Newville", "Pennsylvania", "PA"],
"17243": ["Orbisonia", "Pennsylvania", "PA"],
"17244": ["Orrstown", "Pennsylvania", "PA"],
"17246": ["Pleasant Hall", "Pennsylvania", "PA"],
"17247": ["Quincy", "Pennsylvania", "PA"],
"17249": ["Rockhill Furnace", "Pennsylvania", "PA"],
"17250": ["Rouzerville", "Pennsylvania", "PA"],
"17251": ["Roxbury", "Pennsylvania", "PA"],
"17252": ["Saint Thomas", "Pennsylvania", "PA"],
"17253": ["Saltillo", "Pennsylvania", "PA"],
"17254": ["Scotland", "Pennsylvania", "PA"],
"17255": ["Shade Gap", "Pennsylvania", "PA"],
"17257": ["Shippensburg", "Pennsylvania", "PA"],
"17260": ["Shirleysburg", "Pennsylvania", "PA"],
"17261": ["South Mountain", "Pennsylvania", "PA"],
"17262": ["Spring Run", "Pennsylvania", "PA"],
"17263": ["State Line", "Pennsylvania", "PA"],
"17264": ["Three Springs", "Pennsylvania", "PA"],
"17265": ["Upperstrasburg", "Pennsylvania", "PA"],
"17266": ["Walnut Bottom", "Pennsylvania", "PA"],
"17267": ["Warfordsburg", "Pennsylvania", "PA"],
"17268": ["Waynesboro", "Pennsylvania", "PA"],
"17271": ["Willow Hill", "Pennsylvania", "PA"],
"17301": ["Abbottstown", "Pennsylvania", "PA"],
"17302": ["Airville", "Pennsylvania", "PA"],
"17303": ["Arendtsville", "Pennsylvania", "PA"],
"17304": ["Aspers", "Pennsylvania", "PA"],
"17306": ["Bendersville", "Pennsylvania", "PA"],
"17307": ["Biglerville", "Pennsylvania", "PA"],
"17309": ["Brogue", "Pennsylvania", "PA"],
"17311": ["Codorus", "Pennsylvania", "PA"],
"17313": ["Dallastown", "Pennsylvania", "PA"],
"17314": ["Delta", "Pennsylvania", "PA"],
"17315": ["Dover", "Pennsylvania", "PA"],
"17316": ["East Berlin", "Pennsylvania", "PA"],
"17317": ["East Prospect", "Pennsylvania", "PA"],
"17318": ["Emigsville", "Pennsylvania", "PA"],
"17319": ["Etters", "Pennsylvania", "PA"],
"17320": ["Fairfield", "Pennsylvania", "PA"],
"17321": ["Fawn Grove", "Pennsylvania", "PA"],
"17322": ["Felton", "Pennsylvania", "PA"],
"17323": ["Franklintown", "Pennsylvania", "PA"],
"17324": ["Gardners", "Pennsylvania", "PA"],
"17325": ["Gettysburg", "Pennsylvania", "PA"],
"17327": ["Glen Rock", "Pennsylvania", "PA"],
"17329": ["Glenville", "Pennsylvania", "PA"],
"17331": ["Hanover", "Pennsylvania", "PA"],
"17339": ["Lewisberry", "Pennsylvania", "PA"],
"17340": ["Littlestown", "Pennsylvania", "PA"],
"17342": ["Loganville", "Pennsylvania", "PA"],
"17343": ["McKnightstown", "Pennsylvania", "PA"],
"17344": ["McSherrystown", "Pennsylvania", "PA"],
"17345": ["Manchester", "Pennsylvania", "PA"],
"17347": ["Mount Wolf", "Pennsylvania", "PA"],
"17349": ["New Freedom", "Pennsylvania", "PA"],
"17350": ["New Oxford", "Pennsylvania", "PA"],
"17352": ["New Park", "Pennsylvania", "PA"],
"17353": ["Orrtanna", "Pennsylvania", "PA"],
"17355": ["Railroad", "Pennsylvania", "PA"],
"17356": ["Red Lion", "Pennsylvania", "PA"],
"17360": ["Seven Valleys", "Pennsylvania", "PA"],
"17361": ["Shrewsbury", "Pennsylvania", "PA"],
"17362": ["Spring Grove", "Pennsylvania", "PA"],
"17363": ["Stewartstown", "Pennsylvania", "PA"],
"17364": ["Thomasville", "Pennsylvania", "PA"],
"17365": ["Wellsville", "Pennsylvania", "PA"],
"17366": ["Windsor", "Pennsylvania", "PA"],
"17368": ["Wrightsville", "Pennsylvania", "PA"],
"17370": ["York Haven", "Pennsylvania", "PA"],
"17371": ["York New Salem", "Pennsylvania", "PA"],
"17372": ["York Springs", "Pennsylvania", "PA"],
"17401": ["York", "Pennsylvania", "PA"],
"17402": ["York", "Pennsylvania", "PA"],
"17403": ["York", "Pennsylvania", "PA"],
"17404": ["York", "Pennsylvania", "PA"],
"17406": ["York", "Pennsylvania", "PA"],
"17407": ["York", "Pennsylvania", "PA"],
"17408": ["York", "Pennsylvania", "PA"],
"17501": ["Akron", "Pennsylvania", "PA"],
"17502": ["Bainbridge", "Pennsylvania", "PA"],
"17504": ["Bausman", "Pennsylvania", "PA"],
"17505": ["Bird In Hand", "Pennsylvania", "PA"],
"17506": ["Blue Ball", "Pennsylvania", "PA"],
"17507": ["Bowmansville", "Pennsylvania", "PA"],
"17508": ["Brownstown", "Pennsylvania", "PA"],
"17509": ["Christiana", "Pennsylvania", "PA"],
"17512": ["Columbia", "Pennsylvania", "PA"],
"17516": ["Conestoga", "Pennsylvania", "PA"],
"17517": ["Denver", "Pennsylvania", "PA"],
"17518": ["Drumore", "Pennsylvania", "PA"],
"17519": ["East Earl", "Pennsylvania", "PA"],
"17520": ["East Petersburg", "Pennsylvania", "PA"],
"17522": ["Ephrata", "Pennsylvania", "PA"],
"17527": ["Gap", "Pennsylvania", "PA"],
"17529": ["Gordonville", "Pennsylvania", "PA"],
"17532": ["Holtwood", "Pennsylvania", "PA"],
"17534": ["Intercourse", "Pennsylvania", "PA"],
"17535": ["Kinzers", "Pennsylvania", "PA"],
"17536": ["Kirkwood", "Pennsylvania", "PA"],
"17538": ["Landisville", "Pennsylvania", "PA"],
"17540": ["Leola", "Pennsylvania", "PA"],
"17543": ["Lititz", "Pennsylvania", "PA"],
"17545": ["Manheim", "Pennsylvania", "PA"],
"17547": ["Marietta", "Pennsylvania", "PA"],
"17550": ["Maytown", "Pennsylvania", "PA"],
"17551": ["Millersville", "Pennsylvania", "PA"],
"17552": ["Mount Joy", "Pennsylvania", "PA"],
"17554": ["Mountville", "Pennsylvania", "PA"],
"17555": ["Narvon", "Pennsylvania", "PA"],
"17557": ["New Holland", "Pennsylvania", "PA"],
"17560": ["New Providence", "Pennsylvania", "PA"],
"17562": ["Paradise", "Pennsylvania", "PA"],
"17563": ["Peach Bottom", "Pennsylvania", "PA"],
"17565": ["Pequea", "Pennsylvania", "PA"],
"17566": ["Quarryville", "Pennsylvania", "PA"],
"17568": ["Refton", "Pennsylvania", "PA"],
"17569": ["Reinholds", "Pennsylvania", "PA"],
"17570": ["Rheems", "Pennsylvania", "PA"],
"17572": ["Ronks", "Pennsylvania", "PA"],
"17576": ["Smoketown", "Pennsylvania", "PA"],
"17578": ["Stevens", "Pennsylvania", "PA"],
"17579": ["Strasburg", "Pennsylvania", "PA"],
"17581": ["Terre Hill", "Pennsylvania", "PA"],
"17582": ["Washington Boro", "Pennsylvania", "PA"],
"17584": ["Willow Street", "Pennsylvania", "PA"],
"17601": ["Lancaster", "Pennsylvania", "PA"],
"17602": ["Lancaster", "Pennsylvania", "PA"],
"17603": ["Lancaster", "Pennsylvania", "PA"],
"17606": ["Lancaster", "Pennsylvania", "PA"],
"17701": ["Williamsport", "Pennsylvania", "PA"],
"17702": ["Williamsport", "Pennsylvania", "PA"],
"17720": ["Antes Fort", "Pennsylvania", "PA"],
"17721": ["Avis", "Pennsylvania", "PA"],
"17723": ["Cammal", "Pennsylvania", "PA"],
"17724": ["Canton", "Pennsylvania", "PA"],
"17727": ["Cedar Run", "Pennsylvania", "PA"],
"17728": ["Cogan Station", "Pennsylvania", "PA"],
"17729": ["Cross Fork", "Pennsylvania", "PA"],
"17730": ["Dewart", "Pennsylvania", "PA"],
"17731": ["Eagles Mere", "Pennsylvania", "PA"],
"17737": ["Hughesville", "Pennsylvania", "PA"],
"17739": ["Jersey Mills", "Pennsylvania", "PA"],
"17740": ["Jersey Shore", "Pennsylvania", "PA"],
"17742": ["Lairdsville", "Pennsylvania", "PA"],
"17744": ["Linden", "Pennsylvania", "PA"],
"17745": ["Lock Haven", "Pennsylvania", "PA"],
"17747": ["Loganton", "Pennsylvania", "PA"],
"17748": ["McElhattan", "Pennsylvania", "PA"],
"17749": ["McEwensville", "Pennsylvania", "PA"],
"17750": ["Mackeyville", "Pennsylvania", "PA"],
"17751": ["Mill Hall", "Pennsylvania", "PA"],
"17752": ["Montgomery", "Pennsylvania", "PA"],
"17754": ["Montoursville", "Pennsylvania", "PA"],
"17756": ["Muncy", "Pennsylvania", "PA"],
"17758": ["Muncy Valley", "Pennsylvania", "PA"],
"17760": ["North Bend", "Pennsylvania", "PA"],
"17762": ["Picture Rocks", "Pennsylvania", "PA"],
"17763": ["Ralston", "Pennsylvania", "PA"],
"17764": ["Renovo", "Pennsylvania", "PA"],
"17765": ["Roaring Branch", "Pennsylvania", "PA"],
"17768": ["Shunk", "Pennsylvania", "PA"],
"17769": ["Slate Run", "Pennsylvania", "PA"],
"17771": ["Trout Run", "Pennsylvania", "PA"],
"17772": ["Turbotville", "Pennsylvania", "PA"],
"17774": ["Unityville", "Pennsylvania", "PA"],
"17776": ["Waterville", "Pennsylvania", "PA"],
"17777": ["Watsontown", "Pennsylvania", "PA"],
"17778": ["Westport", "Pennsylvania", "PA"],
"17779": ["Woolrich", "Pennsylvania", "PA"],
"17801": ["Sunbury", "Pennsylvania", "PA"],
"17810": ["Allenwood", "Pennsylvania", "PA"],
"17812": ["Beaver Springs", "Pennsylvania", "PA"],
"17813": ["Beavertown", "Pennsylvania", "PA"],
"17814": ["Benton", "Pennsylvania", "PA"],
"17815": ["Bloomsburg", "Pennsylvania", "PA"],
"17820": ["Catawissa", "Pennsylvania", "PA"],
"17821": ["Danville", "Pennsylvania", "PA"],
"17822": ["Danville", "Pennsylvania", "PA"],
"17823": ["Dornsife", "Pennsylvania", "PA"],
"17824": ["Elysburg", "Pennsylvania", "PA"],
"17827": ["Freeburg", "Pennsylvania", "PA"],
"17829": ["Hartleton", "Pennsylvania", "PA"],
"17830": ["Herndon", "Pennsylvania", "PA"],
"17832": ["Marion Heights", "Pennsylvania", "PA"],
"17834": ["Kulpmont", "Pennsylvania", "PA"],
"17835": ["Laurelton", "Pennsylvania", "PA"],
"17836": ["Leck Kill", "Pennsylvania", "PA"],
"17837": ["Lewisburg", "Pennsylvania", "PA"],
"17840": ["Locust Gap", "Pennsylvania", "PA"],
"17841": ["McClure", "Pennsylvania", "PA"],
"17842": ["Middleburg", "Pennsylvania", "PA"],
"17844": ["Mifflinburg", "Pennsylvania", "PA"],
"17845": ["Millmont", "Pennsylvania", "PA"],
"17846": ["Millville", "Pennsylvania", "PA"],
"17847": ["Milton", "Pennsylvania", "PA"],
"17850": ["Montandon", "Pennsylvania", "PA"],
"17851": ["Mount Carmel", "Pennsylvania", "PA"],
"17853": ["Mount Pleasant Mills", "Pennsylvania", "PA"],
"17855": ["New Berlin", "Pennsylvania", "PA"],
"17856": ["New Columbia", "Pennsylvania", "PA"],
"17857": ["Northumberland", "Pennsylvania", "PA"],
"17858": ["Numidia", "Pennsylvania", "PA"],
"17859": ["Orangeville", "Pennsylvania", "PA"],
"17860": ["Paxinos", "Pennsylvania", "PA"],
"17861": ["Paxtonville", "Pennsylvania", "PA"],
"17862": ["Penns Creek", "Pennsylvania", "PA"],
"17864": ["Port Trevorton", "Pennsylvania", "PA"],
"17865": ["Potts Grove", "Pennsylvania", "PA"],
"17866": ["Coal Township", "Pennsylvania", "PA"],
"17867": ["Rebuck", "Pennsylvania", "PA"],
"17868": ["Riverside", "Pennsylvania", "PA"],
"17870": ["Selinsgrove", "Pennsylvania", "PA"],
"17872": ["Shamokin", "Pennsylvania", "PA"],
"17876": ["Shamokin Dam", "Pennsylvania", "PA"],
"17878": ["Stillwater", "Pennsylvania", "PA"],
"17880": ["Swengel", "Pennsylvania", "PA"],
"17881": ["Trevorton", "Pennsylvania", "PA"],
"17884": ["Washingtonville", "Pennsylvania", "PA"],
"17885": ["Weikert", "Pennsylvania", "PA"],
"17886": ["West Milton", "Pennsylvania", "PA"],
"17887": ["White Deer", "Pennsylvania", "PA"],
"17888": ["Wilburton", "Pennsylvania", "PA"],
"17889": ["Winfield", "Pennsylvania", "PA"],
"17901": ["Pottsville", "Pennsylvania", "PA"],
"17920": ["Aristes", "Pennsylvania", "PA"],
"17921": ["Ashland", "Pennsylvania", "PA"],
"17922": ["Auburn", "Pennsylvania", "PA"],
"17923": ["Branchdale", "Pennsylvania", "PA"],
"17925": ["Brockton", "Pennsylvania", "PA"],
"17929": ["Cressona", "Pennsylvania", "PA"],
"17930": ["Cumbola", "Pennsylvania", "PA"],
"17931": ["Frackville", "Pennsylvania", "PA"],
"17933": ["Friedensburg", "Pennsylvania", "PA"],
"17934": ["Gilberton", "Pennsylvania", "PA"],
"17935": ["Girardville", "Pennsylvania", "PA"],
"17936": ["Gordon", "Pennsylvania", "PA"],
"17938": ["Hegins", "Pennsylvania", "PA"],
"17941": ["Klingerstown", "Pennsylvania", "PA"],
"17943": ["Lavelle", "Pennsylvania", "PA"],
"17944": ["Llewellyn", "Pennsylvania", "PA"],
"17945": ["Locustdale", "Pennsylvania", "PA"],
"17946": ["Lost Creek", "Pennsylvania", "PA"],
"17948": ["Mahanoy City", "Pennsylvania", "PA"],
"17949": ["Mahanoy Plane", "Pennsylvania", "PA"],
"17951": ["Mar Lin", "Pennsylvania", "PA"],
"17952": ["Mary D", "Pennsylvania", "PA"],
"17953": ["Middleport", "Pennsylvania", "PA"],
"17954": ["Minersville", "Pennsylvania", "PA"],
"17957": ["Muir", "Pennsylvania", "PA"],
"17959": ["New Philadelphia", "Pennsylvania", "PA"],
"17960": ["New Ringgold", "Pennsylvania", "PA"],
"17961": ["Orwigsburg", "Pennsylvania", "PA"],
"17963": ["Pine Grove", "Pennsylvania", "PA"],
"17964": ["Pitman", "Pennsylvania", "PA"],
"17965": ["Port Carbon", "Pennsylvania", "PA"],
"17967": ["Ringtown", "Pennsylvania", "PA"],
"17968": ["Sacramento", "Pennsylvania", "PA"],
"17970": ["Saint Clair", "Pennsylvania", "PA"],
"17972": ["Schuylkill Haven", "Pennsylvania", "PA"],
"17974": ["Seltzer", "Pennsylvania", "PA"],
"17976": ["Shenandoah", "Pennsylvania", "PA"],
"17978": ["Spring Glen", "Pennsylvania", "PA"],
"17979": ["Summit Station", "Pennsylvania", "PA"],
"17980": ["Tower City", "Pennsylvania", "PA"],
"17981": ["Tremont", "Pennsylvania", "PA"],
"17982": ["Tuscarora", "Pennsylvania", "PA"],
"17983": ["Valley View", "Pennsylvania", "PA"],
"17985": ["Zion Grove", "Pennsylvania", "PA"],
"18011": ["Alburtis", "Pennsylvania", "PA"],
"18012": ["Aquashicola", "Pennsylvania", "PA"],
"18013": ["Bangor", "Pennsylvania", "PA"],
"18014": ["Bath", "Pennsylvania", "PA"],
"18015": ["Bethlehem", "Pennsylvania", "PA"],
"18017": ["Bethlehem", "Pennsylvania", "PA"],
"18018": ["Bethlehem", "Pennsylvania", "PA"],
"18020": ["Bethlehem", "Pennsylvania", "PA"],
"18030": ["Bowmanstown", "Pennsylvania", "PA"],
"18031": ["Breinigsville", "Pennsylvania", "PA"],
"18032": ["Catasauqua", "Pennsylvania", "PA"],
"18034": ["Center Valley", "Pennsylvania", "PA"],
"18035": ["Cherryville", "Pennsylvania", "PA"],
"18036": ["Coopersburg", "Pennsylvania", "PA"],
"18037": ["Coplay", "Pennsylvania", "PA"],
"18038": ["Danielsville", "Pennsylvania", "PA"],
"18040": ["Easton", "Pennsylvania", "PA"],
"18041": ["East Greenville", "Pennsylvania", "PA"],
"18042": ["Easton", "Pennsylvania", "PA"],
"18045": ["Easton", "Pennsylvania", "PA"],
"18046": ["East Texas", "Pennsylvania", "PA"],
"18049": ["Emmaus", "Pennsylvania", "PA"],
"18051": ["Fogelsville", "Pennsylvania", "PA"],
"18052": ["Whitehall", "Pennsylvania", "PA"],
"18053": ["Germansville", "Pennsylvania", "PA"],
"18054": ["Green Lane", "Pennsylvania", "PA"],
"18055": ["Hellertown", "Pennsylvania", "PA"],
"18056": ["Hereford", "Pennsylvania", "PA"],
"18058": ["Kunkletown", "Pennsylvania", "PA"],
"18059": ["Laurys Station", "Pennsylvania", "PA"],
"18062": ["Macungie", "Pennsylvania", "PA"],
"18063": ["Martins Creek", "Pennsylvania", "PA"],
"18064": ["Nazareth", "Pennsylvania", "PA"],
"18065": ["Neffs", "Pennsylvania", "PA"],
"18066": ["New Tripoli", "Pennsylvania", "PA"],
"18067": ["Northampton", "Pennsylvania", "PA"],
"18069": ["Orefield", "Pennsylvania", "PA"],
"18070": ["Palm", "Pennsylvania", "PA"],
"18071": ["Palmerton", "Pennsylvania", "PA"],
"18072": ["Pen Argyl", "Pennsylvania", "PA"],
"18073": ["Pennsburg", "Pennsylvania", "PA"],
"18074": ["Perkiomenville", "Pennsylvania", "PA"],
"18076": ["Red Hill", "Pennsylvania", "PA"],
"18077": ["Riegelsville", "Pennsylvania", "PA"],
"18078": ["Schnecksville", "Pennsylvania", "PA"],
"18079": ["Slatedale", "Pennsylvania", "PA"],
"18080": ["Slatington", "Pennsylvania", "PA"],
"18081": ["Springtown", "Pennsylvania", "PA"],
"18083": ["Stockertown", "Pennsylvania", "PA"],
"18085": ["Tatamy", "Pennsylvania", "PA"],
"18086": ["Treichlers", "Pennsylvania", "PA"],
"18087": ["Trexlertown", "Pennsylvania", "PA"],
"18088": ["Walnutport", "Pennsylvania", "PA"],
"18091": ["Wind Gap", "Pennsylvania", "PA"],
"18092": ["Zionsville", "Pennsylvania", "PA"],
"18101": ["Allentown", "Pennsylvania", "PA"],
"18102": ["Allentown", "Pennsylvania", "PA"],
"18103": ["Allentown", "Pennsylvania", "PA"],
"18104": ["Allentown", "Pennsylvania", "PA"],
"18106": ["Allentown", "Pennsylvania", "PA"],
"18109": ["Allentown", "Pennsylvania", "PA"],
"18195": ["Allentown", "Pennsylvania", "PA"],
"18201": ["Hazleton", "Pennsylvania", "PA"],
"18202": ["Hazleton", "Pennsylvania", "PA"],
"18210": ["Albrightsville", "Pennsylvania", "PA"],
"18211": ["Andreas", "Pennsylvania", "PA"],
"18212": ["Ashfield", "Pennsylvania", "PA"],
"18214": ["Barnesville", "Pennsylvania", "PA"],
"18216": ["Beaver Meadows", "Pennsylvania", "PA"],
"18218": ["Coaldale", "Pennsylvania", "PA"],
"18219": ["Conyngham", "Pennsylvania", "PA"],
"18220": ["Delano", "Pennsylvania", "PA"],
"18221": ["Drifton", "Pennsylvania", "PA"],
"18222": ["Drums", "Pennsylvania", "PA"],
"18223": ["Ebervale", "Pennsylvania", "PA"],
"18224": ["Freeland", "Pennsylvania", "PA"],
"18225": ["Harleigh", "Pennsylvania", "PA"],
"18229": ["Jim Thorpe", "Pennsylvania", "PA"],
"18230": ["Junedale", "Pennsylvania", "PA"],
"18231": ["Kelayres", "Pennsylvania", "PA"],
"18232": ["Lansford", "Pennsylvania", "PA"],
"18234": ["Lattimer Mines", "Pennsylvania", "PA"],
"18235": ["Lehighton", "Pennsylvania", "PA"],
"18237": ["Mcadoo", "Pennsylvania", "PA"],
"18239": ["Milnesville", "Pennsylvania", "PA"],
"18240": ["Nesquehoning", "Pennsylvania", "PA"],
"18241": ["Nuremberg", "Pennsylvania", "PA"],
"18242": ["Oneida", "Pennsylvania", "PA"],
"18244": ["Parryville", "Pennsylvania", "PA"],
"18245": ["Quakake", "Pennsylvania", "PA"],
"18246": ["Rock Glen", "Pennsylvania", "PA"],
"18248": ["Sheppton", "Pennsylvania", "PA"],
"18249": ["Sugarloaf", "Pennsylvania", "PA"],
"18250": ["Summit Hill", "Pennsylvania", "PA"],
"18252": ["Tamaqua", "Pennsylvania", "PA"],
"18254": ["Tresckow", "Pennsylvania", "PA"],
"18255": ["Weatherly", "Pennsylvania", "PA"],
"18256": ["Weston", "Pennsylvania", "PA"],
"18301": ["East Stroudsburg", "Pennsylvania", "PA"],
"18302": ["East Stroudsburg", "Pennsylvania", "PA"],
"18321": ["Bartonsville", "Pennsylvania", "PA"],
"18322": ["Brodheadsville", "Pennsylvania", "PA"],
"18323": ["Buck Hill Falls", "Pennsylvania", "PA"],
"18324": ["Bushkill", "Pennsylvania", "PA"],
"18325": ["Canadensis", "Pennsylvania", "PA"],
"18326": ["Cresco", "Pennsylvania", "PA"],
"18327": ["Delaware Water Gap", "Pennsylvania", "PA"],
"18328": ["Dingmans Ferry", "Pennsylvania", "PA"],
"18330": ["Effort", "Pennsylvania", "PA"],
"18331": ["Gilbert", "Pennsylvania", "PA"],
"18332": ["Henryville", "Pennsylvania", "PA"],
"18333": ["Kresgeville", "Pennsylvania", "PA"],
"18334": ["Long Pond", "Pennsylvania", "PA"],
"18335": ["Marshalls Creek", "Pennsylvania", "PA"],
"18336": ["Matamoras", "Pennsylvania", "PA"],
"18337": ["Milford", "Pennsylvania", "PA"],
"18340": ["Millrift", "Pennsylvania", "PA"],
"18342": ["Mountainhome", "Pennsylvania", "PA"],
"18343": ["Mount Bethel", "Pennsylvania", "PA"],
"18344": ["Mount Pocono", "Pennsylvania", "PA"],
"18346": ["Pocono Summit", "Pennsylvania", "PA"],
"18347": ["Pocono Lake", "Pennsylvania", "PA"],
"18349": ["Pocono Manor", "Pennsylvania", "PA"],
"18350": ["Pocono Pines", "Pennsylvania", "PA"],
"18351": ["Portland", "Pennsylvania", "PA"],
"18353": ["Saylorsburg", "Pennsylvania", "PA"],
"18354": ["Sciota", "Pennsylvania", "PA"],
"18355": ["Scotrun", "Pennsylvania", "PA"],
"18356": ["Shawnee On Delaware", "Pennsylvania", "PA"],
"18357": ["Skytop", "Pennsylvania", "PA"],
"18360": ["Stroudsburg", "Pennsylvania", "PA"],
"18370": ["Swiftwater", "Pennsylvania", "PA"],
"18371": ["Tamiment", "Pennsylvania", "PA"],
"18372": ["Tannersville", "Pennsylvania", "PA"],
"18403": ["Archbald", "Pennsylvania", "PA"],
"18405": ["Beach Lake", "Pennsylvania", "PA"],
"18407": ["Carbondale", "Pennsylvania", "PA"],
"18411": ["Clarks Summit", "Pennsylvania", "PA"],
"18413": ["Clifford", "Pennsylvania", "PA"],
"18414": ["Dalton", "Pennsylvania", "PA"],
"18415": ["Damascus", "Pennsylvania", "PA"],
"18417": ["Equinunk", "Pennsylvania", "PA"],
"18419": ["Factoryville", "Pennsylvania", "PA"],
"18420": ["Fleetville", "Pennsylvania", "PA"],
"18421": ["Forest City", "Pennsylvania", "PA"],
"18424": ["Gouldsboro", "Pennsylvania", "PA"],
"18425": ["Greeley", "Pennsylvania", "PA"],
"18426": ["Greentown", "Pennsylvania", "PA"],
"18428": ["Hawley", "Pennsylvania", "PA"],
"18430": ["Herrick Center", "Pennsylvania", "PA"],
"18431": ["Honesdale", "Pennsylvania", "PA"],
"18433": ["Jermyn", "Pennsylvania", "PA"],
"18434": ["Jessup", "Pennsylvania", "PA"],
"18435": ["Lackawaxen", "Pennsylvania", "PA"],
"18436": ["Lake Ariel", "Pennsylvania", "PA"],
"18437": ["Lake Como", "Pennsylvania", "PA"],
"18438": ["Lakeville", "Pennsylvania", "PA"],
"18439": ["Lakewood", "Pennsylvania", "PA"],
"18440": ["La Plume", "Pennsylvania", "PA"],
"18441": ["Lenoxville", "Pennsylvania", "PA"],
"18443": ["Milanville", "Pennsylvania", "PA"],
"18444": ["Moscow", "Pennsylvania", "PA"],
"18445": ["Newfoundland", "Pennsylvania", "PA"],
"18446": ["Nicholson", "Pennsylvania", "PA"],
"18447": ["Olyphant", "Pennsylvania", "PA"],
"18451": ["Paupack", "Pennsylvania", "PA"],
"18452": ["Peckville", "Pennsylvania", "PA"],
"18453": ["Pleasant Mount", "Pennsylvania", "PA"],
"18454": ["Poyntelle", "Pennsylvania", "PA"],
"18455": ["Preston Park", "Pennsylvania", "PA"],
"18456": ["Prompton", "Pennsylvania", "PA"],
"18457": ["Rowland", "Pennsylvania", "PA"],
"18458": ["Shohola", "Pennsylvania", "PA"],
"18460": ["South Sterling", "Pennsylvania", "PA"],
"18461": ["Starlight", "Pennsylvania", "PA"],
"18462": ["Starrucca", "Pennsylvania", "PA"],
"18463": ["Sterling", "Pennsylvania", "PA"],
"18464": ["Tafton", "Pennsylvania", "PA"],
"18465": ["Thompson", "Pennsylvania", "PA"],
"18466": ["Tobyhanna", "Pennsylvania", "PA"],
"18469": ["Tyler Hill", "Pennsylvania", "PA"],
"18470": ["Union Dale", "Pennsylvania", "PA"],
"18471": ["Waverly", "Pennsylvania", "PA"],
"18472": ["Waymart", "Pennsylvania", "PA"],
"18473": ["White Mills", "Pennsylvania", "PA"],
"18503": ["Scranton", "Pennsylvania", "PA"],
"18504": ["Scranton", "Pennsylvania", "PA"],
"18505": ["Scranton", "Pennsylvania", "PA"],
"18507": ["Moosic", "Pennsylvania", "PA"],
"18508": ["Scranton", "Pennsylvania", "PA"],
"18509": ["Scranton", "Pennsylvania", "PA"],
"18510": ["Scranton", "Pennsylvania", "PA"],
"18512": ["Scranton", "Pennsylvania", "PA"],
"18517": ["Taylor", "Pennsylvania", "PA"],
"18518": ["Old Forge", "Pennsylvania", "PA"],
"18519": ["Scranton", "Pennsylvania", "PA"],
"18602": ["Bear Creek", "Pennsylvania", "PA"],
"18603": ["Berwick", "Pennsylvania", "PA"],
"18610": ["Blakeslee", "Pennsylvania", "PA"],
"18612": ["Dallas", "Pennsylvania", "PA"],
"18614": ["Dushore", "Pennsylvania", "PA"],
"18615": ["Falls", "Pennsylvania", "PA"],
"18616": ["Forksville", "Pennsylvania", "PA"],
"18617": ["Glen Lyon", "Pennsylvania", "PA"],
"18618": ["Harveys Lake", "Pennsylvania", "PA"],
"18619": ["Hillsgrove", "Pennsylvania", "PA"],
"18621": ["Hunlock Creek", "Pennsylvania", "PA"],
"18622": ["Huntington Mills", "Pennsylvania", "PA"],
"18623": ["Laceyville", "Pennsylvania", "PA"],
"18624": ["Lake Harmony", "Pennsylvania", "PA"],
"18625": ["Lake Winola", "Pennsylvania", "PA"],
"18626": ["Laporte", "Pennsylvania", "PA"],
"18627": ["Lehman", "Pennsylvania", "PA"],
"18628": ["Lopez", "Pennsylvania", "PA"],
"18629": ["Mehoopany", "Pennsylvania", "PA"],
"18630": ["Meshoppen", "Pennsylvania", "PA"],
"18631": ["Mifflinville", "Pennsylvania", "PA"],
"18632": ["Mildred", "Pennsylvania", "PA"],
"18634": ["Nanticoke", "Pennsylvania", "PA"],
"18635": ["Nescopeck", "Pennsylvania", "PA"],
"18636": ["Noxen", "Pennsylvania", "PA"],
"18640": ["Pittston", "Pennsylvania", "PA"],
"18641": ["Pittston", "Pennsylvania", "PA"],
"18642": ["Duryea", "Pennsylvania", "PA"],
"18643": ["Pittston", "Pennsylvania", "PA"],
"18644": ["Wyoming", "Pennsylvania", "PA"],
"18651": ["Plymouth", "Pennsylvania", "PA"],
"18655": ["Shickshinny", "Pennsylvania", "PA"],
"18656": ["Sweet Valley", "Pennsylvania", "PA"],
"18657": ["Tunkhannock", "Pennsylvania", "PA"],
"18660": ["Wapwallopen", "Pennsylvania", "PA"],
"18661": ["White Haven", "Pennsylvania", "PA"],
"18701": ["Wilkes Barre", "Pennsylvania", "PA"],
"18702": ["Wilkes Barre", "Pennsylvania", "PA"],
"18704": ["Kingston", "Pennsylvania", "PA"],
"18705": ["Wilkes Barre", "Pennsylvania", "PA"],
"18706": ["Wilkes Barre", "Pennsylvania", "PA"],
"18707": ["Mountain Top", "Pennsylvania", "PA"],
"18708": ["Shavertown", "Pennsylvania", "PA"],
"18709": ["Luzerne", "Pennsylvania", "PA"],
"18801": ["Montrose", "Pennsylvania", "PA"],
"18810": ["Athens", "Pennsylvania", "PA"],
"18812": ["Brackney", "Pennsylvania", "PA"],
"18814": ["Burlington", "Pennsylvania", "PA"],
"18816": ["Dimock", "Pennsylvania", "PA"],
"18817": ["East Smithfield", "Pennsylvania", "PA"],
"18818": ["Friendsville", "Pennsylvania", "PA"],
"18820": ["Gibson", "Pennsylvania", "PA"],
"18821": ["Great Bend", "Pennsylvania", "PA"],
"18822": ["Hallstead", "Pennsylvania", "PA"],
"18823": ["Harford", "Pennsylvania", "PA"],
"18824": ["Hop Bottom", "Pennsylvania", "PA"],
"18825": ["Jackson", "Pennsylvania", "PA"],
"18826": ["Kingsley", "Pennsylvania", "PA"],
"18828": ["Lawton", "Pennsylvania", "PA"],
"18829": ["Le Raysville", "Pennsylvania", "PA"],
"18830": ["Little Meadows", "Pennsylvania", "PA"],
"18831": ["Milan", "Pennsylvania", "PA"],
"18832": ["Monroeton", "Pennsylvania", "PA"],
"18833": ["New Albany", "Pennsylvania", "PA"],
"18834": ["New Milford", "Pennsylvania", "PA"],
"18837": ["Rome", "Pennsylvania", "PA"],
"18840": ["Sayre", "Pennsylvania", "PA"],
"18842": ["South Gibson", "Pennsylvania", "PA"],
"18843": ["South Montrose", "Pennsylvania", "PA"],
"18844": ["Springville", "Pennsylvania", "PA"],
"18845": ["Stevensville", "Pennsylvania", "PA"],
"18846": ["Sugar Run", "Pennsylvania", "PA"],
"18847": ["Susquehanna", "Pennsylvania", "PA"],
"18848": ["Towanda", "Pennsylvania", "PA"],
"18850": ["Ulster", "Pennsylvania", "PA"],
"18851": ["Warren Center", "Pennsylvania", "PA"],
"18853": ["Wyalusing", "Pennsylvania", "PA"],
"18854": ["Wysox", "Pennsylvania", "PA"],
"18901": ["Doylestown", "Pennsylvania", "PA"],
"18902": ["Doylestown", "Pennsylvania", "PA"],
"18912": ["Buckingham", "Pennsylvania", "PA"],
"18913": ["Carversville", "Pennsylvania", "PA"],
"18914": ["Chalfont", "Pennsylvania", "PA"],
"18915": ["Colmar", "Pennsylvania", "PA"],
"18917": ["Dublin", "Pennsylvania", "PA"],
"18920": ["Erwinna", "Pennsylvania", "PA"],
"18921": ["Ferndale", "Pennsylvania", "PA"],
"18923": ["Fountainville", "Pennsylvania", "PA"],
"18925": ["Furlong", "Pennsylvania", "PA"],
"18927": ["Hilltown", "Pennsylvania", "PA"],
"18929": ["Jamison", "Pennsylvania", "PA"],
"18930": ["Kintnersville", "Pennsylvania", "PA"],
"18932": ["Line Lexington", "Pennsylvania", "PA"],
"18933": ["Lumberville", "Pennsylvania", "PA"],
"18935": ["Milford Square", "Pennsylvania", "PA"],
"18936": ["Montgomeryville", "Pennsylvania", "PA"],
"18938": ["New Hope", "Pennsylvania", "PA"],
"18940": ["Newtown", "Pennsylvania", "PA"],
"18942": ["Ottsville", "Pennsylvania", "PA"],
"18944": ["Perkasie", "Pennsylvania", "PA"],
"18946": ["Pineville", "Pennsylvania", "PA"],
"18947": ["Pipersville", "Pennsylvania", "PA"],
"18950": ["Point Pleasant", "Pennsylvania", "PA"],
"18951": ["Quakertown", "Pennsylvania", "PA"],
"18954": ["Richboro", "Pennsylvania", "PA"],
"18955": ["Richlandtown", "Pennsylvania", "PA"],
"18960": ["Sellersville", "Pennsylvania", "PA"],
"18962": ["Silverdale", "Pennsylvania", "PA"],
"18964": ["Souderton", "Pennsylvania", "PA"],
"18966": ["Southampton", "Pennsylvania", "PA"],
"18969": ["Telford", "Pennsylvania", "PA"],
"18970": ["Trumbauersville", "Pennsylvania", "PA"],
"18972": ["Upper Black Eddy", "Pennsylvania", "PA"],
"18974": ["Warminster", "Pennsylvania", "PA"],
"18976": ["Warrington", "Pennsylvania", "PA"],
"18977": ["Washington Crossing", "Pennsylvania", "PA"],
"18980": ["Wycombe", "Pennsylvania", "PA"],
"19001": ["Abington", "Pennsylvania", "PA"],
"19002": ["Ambler", "Pennsylvania", "PA"],
"19003": ["Ardmore", "Pennsylvania", "PA"],
"19004": ["Bala Cynwyd", "Pennsylvania", "PA"],
"19006": ["Huntingdon Valley", "Pennsylvania", "PA"],
"19007": ["Bristol", "Pennsylvania", "PA"],
"19008": ["Broomall", "Pennsylvania", "PA"],
"19009": ["Bryn Athyn", "Pennsylvania", "PA"],
"19010": ["Bryn Mawr", "Pennsylvania", "PA"],
"19012": ["Cheltenham", "Pennsylvania", "PA"],
"19013": ["Chester", "Pennsylvania", "PA"],
"19014": ["Aston", "Pennsylvania", "PA"],
"19015": ["Brookhaven", "Pennsylvania", "PA"],
"19017": ["Chester Heights", "Pennsylvania", "PA"],
"19018": ["Clifton Heights", "Pennsylvania", "PA"],
"19020": ["Bensalem", "Pennsylvania", "PA"],
"19021": ["Croydon", "Pennsylvania", "PA"],
"19022": ["Crum Lynne", "Pennsylvania", "PA"],
"19023": ["Darby", "Pennsylvania", "PA"],
"19025": ["Dresher", "Pennsylvania", "PA"],
"19026": ["Drexel Hill", "Pennsylvania", "PA"],
"19027": ["Elkins Park", "Pennsylvania", "PA"],
"19029": ["Essington", "Pennsylvania", "PA"],
"19030": ["Fairless Hills", "Pennsylvania", "PA"],
"19031": ["Flourtown", "Pennsylvania", "PA"],
"19032": ["Folcroft", "Pennsylvania", "PA"],
"19033": ["Folsom", "Pennsylvania", "PA"],
"19034": ["Fort Washington", "Pennsylvania", "PA"],
"19035": ["Gladwyne", "Pennsylvania", "PA"],
"19036": ["Glenolden", "Pennsylvania", "PA"],
"19038": ["Glenside", "Pennsylvania", "PA"],
"19040": ["Hatboro", "Pennsylvania", "PA"],
"19041": ["Haverford", "Pennsylvania", "PA"],
"19043": ["Holmes", "Pennsylvania", "PA"],
"19044": ["Horsham", "Pennsylvania", "PA"],
"19046": ["Jenkintown", "Pennsylvania", "PA"],
"19047": ["Langhorne", "Pennsylvania", "PA"],
"19050": ["Lansdowne", "Pennsylvania", "PA"],
"19052": ["Lenni", "Pennsylvania", "PA"],
"19053": ["Feasterville Trevose", "Pennsylvania", "PA"],
"19054": ["Levittown", "Pennsylvania", "PA"],
"19055": ["Levittown", "Pennsylvania", "PA"],
"19056": ["Levittown", "Pennsylvania", "PA"],
"19057": ["Levittown", "Pennsylvania", "PA"],
"19060": ["Garnet Valley", "Pennsylvania", "PA"],
"19061": ["Marcus Hook", "Pennsylvania", "PA"],
"19063": ["Media", "Pennsylvania", "PA"],
"19064": ["Springfield", "Pennsylvania", "PA"],
"19066": ["Merion Station", "Pennsylvania", "PA"],
"19067": ["Morrisville", "Pennsylvania", "PA"],
"19070": ["Morton", "Pennsylvania", "PA"],
"19072": ["Narberth", "Pennsylvania", "PA"],
"19073": ["Newtown Square", "Pennsylvania", "PA"],
"19074": ["Norwood", "Pennsylvania", "PA"],
"19075": ["Oreland", "Pennsylvania", "PA"],
"19076": ["Prospect Park", "Pennsylvania", "PA"],
"19078": ["Ridley Park", "Pennsylvania", "PA"],
"19079": ["Sharon Hill", "Pennsylvania", "PA"],
"19081": ["Swarthmore", "Pennsylvania", "PA"],
"19082": ["Upper Darby", "Pennsylvania", "PA"],
"19083": ["Havertown", "Pennsylvania", "PA"],
"19085": ["Villanova", "Pennsylvania", "PA"],
"19086": ["Wallingford", "Pennsylvania", "PA"],
"19087": ["Wayne", "Pennsylvania", "PA"],
"19090": ["Willow Grove", "Pennsylvania", "PA"],
"19094": ["Woodlyn", "Pennsylvania", "PA"],
"19095": ["Wyncote", "Pennsylvania", "PA"],
"19096": ["Wynnewood", "Pennsylvania", "PA"],
"19102": ["Philadelphia", "Pennsylvania", "PA"],
"19103": ["Philadelphia", "Pennsylvania", "PA"],
"19104": ["Philadelphia", "Pennsylvania", "PA"],
"19106": ["Philadelphia", "Pennsylvania", "PA"],
"19107": ["Philadelphia", "Pennsylvania", "PA"],
"19108": ["Philadelphia", "Pennsylvania", "PA"],
"19109": ["Philadelphia", "Pennsylvania", "PA"],
"19111": ["Philadelphia", "Pennsylvania", "PA"],
"19112": ["Philadelphia", "Pennsylvania", "PA"],
"19113": ["Philadelphia", "Pennsylvania", "PA"],
"19114": ["Philadelphia", "Pennsylvania", "PA"],
"19115": ["Philadelphia", "Pennsylvania", "PA"],
"19116": ["Philadelphia", "Pennsylvania", "PA"],
"19118": ["Philadelphia", "Pennsylvania", "PA"],
"19119": ["Philadelphia", "Pennsylvania", "PA"],
"19120": ["Philadelphia", "Pennsylvania", "PA"],
"19121": ["Philadelphia", "Pennsylvania", "PA"],
"19122": ["Philadelphia", "Pennsylvania", "PA"],
"19123": ["Philadelphia", "Pennsylvania", "PA"],
"19124": ["Philadelphia", "Pennsylvania", "PA"],
"19125": ["Philadelphia", "Pennsylvania", "PA"],
"19126": ["Philadelphia", "Pennsylvania", "PA"],
"19127": ["Philadelphia", "Pennsylvania", "PA"],
"19128": ["Philadelphia", "Pennsylvania", "PA"],
"19129": ["Philadelphia", "Pennsylvania", "PA"],
"19130": ["Philadelphia", "Pennsylvania", "PA"],
"19131": ["Philadelphia", "Pennsylvania", "PA"],
"19132": ["Philadelphia", "Pennsylvania", "PA"],
"19133": ["Philadelphia", "Pennsylvania", "PA"],
"19134": ["Philadelphia", "Pennsylvania", "PA"],
"19135": ["Philadelphia", "Pennsylvania", "PA"],
"19136": ["Philadelphia", "Pennsylvania", "PA"],
"19137": ["Philadelphia", "Pennsylvania", "PA"],
"19138": ["Philadelphia", "Pennsylvania", "PA"],
"19139": ["Philadelphia", "Pennsylvania", "PA"],
"19140": ["Philadelphia", "Pennsylvania", "PA"],
"19141": ["Philadelphia", "Pennsylvania", "PA"],
"19142": ["Philadelphia", "Pennsylvania", "PA"],
"19143": ["Philadelphia", "Pennsylvania", "PA"],
"19144": ["Philadelphia", "Pennsylvania", "PA"],
"19145": ["Philadelphia", "Pennsylvania", "PA"],
"19146": ["Philadelphia", "Pennsylvania", "PA"],
"19147": ["Philadelphia", "Pennsylvania", "PA"],
"19148": ["Philadelphia", "Pennsylvania", "PA"],
"19149": ["Philadelphia", "Pennsylvania", "PA"],
"19150": ["Philadelphia", "Pennsylvania", "PA"],
"19151": ["Philadelphia", "Pennsylvania", "PA"],
"19152": ["Philadelphia", "Pennsylvania", "PA"],
"19153": ["Philadelphia", "Pennsylvania", "PA"],
"19154": ["Philadelphia", "Pennsylvania", "PA"],
"19301": ["Paoli", "Pennsylvania", "PA"],
"19310": ["Atglen", "Pennsylvania", "PA"],
"19311": ["Avondale", "Pennsylvania", "PA"],
"19312": ["Berwyn", "Pennsylvania", "PA"],
"19316": ["Brandamore", "Pennsylvania", "PA"],
"19317": ["Chadds Ford", "Pennsylvania", "PA"],
"19319": ["Cheyney", "Pennsylvania", "PA"],
"19320": ["Coatesville", "Pennsylvania", "PA"],
"19330": ["Cochranville", "Pennsylvania", "PA"],
"19333": ["Devon", "Pennsylvania", "PA"],
"19335": ["Downingtown", "Pennsylvania", "PA"],
"19341": ["Exton", "Pennsylvania", "PA"],
"19342": ["Glen Mills", "Pennsylvania", "PA"],
"19343": ["Glenmoore", "Pennsylvania", "PA"],
"19344": ["Honey Brook", "Pennsylvania", "PA"],
"19345": ["Immaculata", "Pennsylvania", "PA"],
"19348": ["Kennett Square", "Pennsylvania", "PA"],
"19350": ["Landenberg", "Pennsylvania", "PA"],
"19352": ["Lincoln University", "Pennsylvania", "PA"],
"19355": ["Malvern", "Pennsylvania", "PA"],
"19358": ["Modena", "Pennsylvania", "PA"],
"19362": ["Nottingham", "Pennsylvania", "PA"],
"19363": ["Oxford", "Pennsylvania", "PA"],
"19365": ["Parkesburg", "Pennsylvania", "PA"],
"19367": ["Pomeroy", "Pennsylvania", "PA"],
"19369": ["Sadsburyville", "Pennsylvania", "PA"],
"19372": ["Thorndale", "Pennsylvania", "PA"],
"19373": ["Thornton", "Pennsylvania", "PA"],
"19374": ["Toughkenamon", "Pennsylvania", "PA"],
"19375": ["Unionville", "Pennsylvania", "PA"],
"19380": ["West Chester", "Pennsylvania", "PA"],
"19382": ["West Chester", "Pennsylvania", "PA"],
"19383": ["West Chester", "Pennsylvania", "PA"],
"19390": ["West Grove", "Pennsylvania", "PA"],
"19401": ["Norristown", "Pennsylvania", "PA"],
"19403": ["Norristown", "Pennsylvania", "PA"],
"19405": ["Bridgeport", "Pennsylvania", "PA"],
"19406": ["King Of Prussia", "Pennsylvania", "PA"],
"19422": ["Blue Bell", "Pennsylvania", "PA"],
"19425": ["Chester Springs", "Pennsylvania", "PA"],
"19426": ["Collegeville", "Pennsylvania", "PA"],
"19428": ["Conshohocken", "Pennsylvania", "PA"],
"19435": ["Frederick", "Pennsylvania", "PA"],
"19436": ["Gwynedd", "Pennsylvania", "PA"],
"19437": ["Gwynedd Valley", "Pennsylvania", "PA"],
"19438": ["Harleysville", "Pennsylvania", "PA"],
"19440": ["Hatfield", "Pennsylvania", "PA"],
"19442": ["Kimberton", "Pennsylvania", "PA"],
"19444": ["Lafayette Hill", "Pennsylvania", "PA"],
"19446": ["Lansdale", "Pennsylvania", "PA"],
"19453": ["Mont Clare", "Pennsylvania", "PA"],
"19454": ["North Wales", "Pennsylvania", "PA"],
"19456": ["Oaks", "Pennsylvania", "PA"],
"19457": ["Parker Ford", "Pennsylvania", "PA"],
"19460": ["Phoenixville", "Pennsylvania", "PA"],
"19462": ["Plymouth Meeting", "Pennsylvania", "PA"],
"19464": ["Pottstown", "Pennsylvania", "PA"],
"19465": ["Pottstown", "Pennsylvania", "PA"],
"19468": ["Royersford", "Pennsylvania", "PA"],
"19472": ["Sassamansville", "Pennsylvania", "PA"],
"19473": ["Schwenksville", "Pennsylvania", "PA"],
"19474": ["Skippack", "Pennsylvania", "PA"],
"19475": ["Spring City", "Pennsylvania", "PA"],
"19477": ["Spring House", "Pennsylvania", "PA"],
"19490": ["Worcester", "Pennsylvania", "PA"],
"19492": ["Zieglerville", "Pennsylvania", "PA"],
"19501": ["Adamstown", "Pennsylvania", "PA"],
"19503": ["Bally", "Pennsylvania", "PA"],
"19504": ["Barto", "Pennsylvania", "PA"],
"19505": ["Bechtelsville", "Pennsylvania", "PA"],
"19506": ["Bernville", "Pennsylvania", "PA"],
"19507": ["Bethel", "Pennsylvania", "PA"],
"19508": ["Birdsboro", "Pennsylvania", "PA"],
"19510": ["Blandon", "Pennsylvania", "PA"],
"19511": ["Bowers", "Pennsylvania", "PA"],
"19512": ["Boyertown", "Pennsylvania", "PA"],
"19516": ["Centerport", "Pennsylvania", "PA"],
"19518": ["Douglassville", "Pennsylvania", "PA"],
"19520": ["Elverson", "Pennsylvania", "PA"],
"19522": ["Fleetwood", "Pennsylvania", "PA"],
"19523": ["Geigertown", "Pennsylvania", "PA"],
"19525": ["Gilbertsville", "Pennsylvania", "PA"],
"19526": ["Hamburg", "Pennsylvania", "PA"],
"19529": ["Kempton", "Pennsylvania", "PA"],
"19530": ["Kutztown", "Pennsylvania", "PA"],
"19533": ["Leesport", "Pennsylvania", "PA"],
"19534": ["Lenhartsville", "Pennsylvania", "PA"],
"19535": ["Limekiln", "Pennsylvania", "PA"],
"19536": ["Lyon Station", "Pennsylvania", "PA"],
"19538": ["Maxatawny", "Pennsylvania", "PA"],
"19539": ["Mertztown", "Pennsylvania", "PA"],
"19540": ["Mohnton", "Pennsylvania", "PA"],
"19541": ["Mohrsville", "Pennsylvania", "PA"],
"19543": ["Morgantown", "Pennsylvania", "PA"],
"19544": ["Mount Aetna", "Pennsylvania", "PA"],
"19545": ["New Berlinville", "Pennsylvania", "PA"],
"19547": ["Oley", "Pennsylvania", "PA"],
"19549": ["Port Clinton", "Pennsylvania", "PA"],
"19550": ["Rehrersburg", "Pennsylvania", "PA"],
"19551": ["Robesonia", "Pennsylvania", "PA"],
"19554": ["Shartlesville", "Pennsylvania", "PA"],
"19555": ["Shoemakersville", "Pennsylvania", "PA"],
"19559": ["Strausstown", "Pennsylvania", "PA"],
"19560": ["Temple", "Pennsylvania", "PA"],
"19562": ["Topton", "Pennsylvania", "PA"],
"19564": ["Virginville", "Pennsylvania", "PA"],
"19565": ["Wernersville", "Pennsylvania", "PA"],
"19567": ["Womelsdorf", "Pennsylvania", "PA"],
"19601": ["Reading", "Pennsylvania", "PA"],
"19602": ["Reading", "Pennsylvania", "PA"],
"19604": ["Reading", "Pennsylvania", "PA"],
"19605": ["Reading", "Pennsylvania", "PA"],
"19606": ["Reading", "Pennsylvania", "PA"],
"19607": ["Reading", "Pennsylvania", "PA"],
"19608": ["Reading", "Pennsylvania", "PA"],
"19609": ["Reading", "Pennsylvania", "PA"],
"19610": ["Reading", "Pennsylvania", "PA"],
"19611": ["Reading", "Pennsylvania", "PA"],
"19701": ["Bear", "Delaware", "DE"],
"19702": ["Newark", "Delaware", "DE"],
"19703": ["Claymont", "Delaware", "DE"],
"19706": ["Delaware City", "Delaware", "DE"],
"19707": ["Hockessin", "Delaware", "DE"],
"19709": ["Middletown", "Delaware", "DE"],
"19710": ["Montchanin", "Delaware", "DE"],
"19711": ["Newark", "Delaware", "DE"],
"19713": ["Newark", "Delaware", "DE"],
"19716": ["Newark", "Delaware", "DE"],
"19717": ["Newark", "Delaware", "DE"],
"19720": ["New Castle", "Delaware", "DE"],
"19730": ["Odessa", "Delaware", "DE"],
"19731": ["Port Penn", "Delaware", "DE"],
"19732": ["Rockland", "Delaware", "DE"],
"19733": ["Saint Georges", "Delaware", "DE"],
"19734": ["Townsend", "Delaware", "DE"],
"19735": ["Winterthur", "Delaware", "DE"],
"19736": ["Yorklyn", "Delaware", "DE"],
"19801": ["Wilmington", "Delaware", "DE"],
"19802": ["Wilmington", "Delaware", "DE"],
"19803": ["Wilmington", "Delaware", "DE"],
"19804": ["Wilmington", "Delaware", "DE"],
"19805": ["Wilmington", "Delaware", "DE"],
"19806": ["Wilmington", "Delaware", "DE"],
"19807": ["Wilmington", "Delaware", "DE"],
"19808": ["Wilmington", "Delaware", "DE"],
"19809": ["Wilmington", "Delaware", "DE"],
"19810": ["Wilmington", "Delaware", "DE"],
"19901": ["Dover", "Delaware", "DE"],
"19902": ["Dover Afb", "Delaware", "DE"],
"19904": ["Dover", "Delaware", "DE"],
"19930": ["Bethany Beach", "Delaware", "DE"],
"19931": ["Bethel", "Delaware", "DE"],
"19933": ["Bridgeville", "Delaware", "DE"],
"19934": ["Camden Wyoming", "Delaware", "DE"],
"19936": ["Cheswold", "Delaware", "DE"],
"19938": ["Clayton", "Delaware", "DE"],
"19939": ["Dagsboro", "Delaware", "DE"],
"19940": ["Delmar", "Delaware", "DE"],
"19941": ["Ellendale", "Delaware", "DE"],
"19943": ["Felton", "Delaware", "DE"],
"19944": ["Fenwick Island", "Delaware", "DE"],
"19945": ["Frankford", "Delaware", "DE"],
"19946": ["Frederica", "Delaware", "DE"],
"19947": ["Georgetown", "Delaware", "DE"],
"19950": ["Greenwood", "Delaware", "DE"],
"19951": ["Harbeson", "Delaware", "DE"],
"19952": ["Harrington", "Delaware", "DE"],
"19953": ["Hartly", "Delaware", "DE"],
"19954": ["Houston", "Delaware", "DE"],
"19955": ["Kenton", "Delaware", "DE"],
"19956": ["Laurel", "Delaware", "DE"],
"19958": ["Lewes", "Delaware", "DE"],
"19960": ["Lincoln", "Delaware", "DE"],
"19962": ["Magnolia", "Delaware", "DE"],
"19963": ["Milford", "Delaware", "DE"],
"19964": ["Marydel", "Delaware", "DE"],
"19966": ["Millsboro", "Delaware", "DE"],
"19967": ["Millville", "Delaware", "DE"],
"19968": ["Milton", "Delaware", "DE"],
"19970": ["Ocean View", "Delaware", "DE"],
"19971": ["Rehoboth Beach", "Delaware", "DE"],
"19973": ["Seaford", "Delaware", "DE"],
"19975": ["Selbyville", "Delaware", "DE"],
"19977": ["Smyrna", "Delaware", "DE"],
"19979": ["Viola", "Delaware", "DE"],
"19980": ["Woodside", "Delaware", "DE"],
"20001": ["Washington", "District of Columbia", "DC"],
"20002": ["Washington", "District of Columbia", "DC"],
"20003": ["Washington", "District of Columbia", "DC"],
"20004": ["Washington", "District of Columbia", "DC"],
"20005": ["Washington", "District of Columbia", "DC"],
"20006": ["Washington", "District of Columbia", "DC"],
"20007": ["Washington", "District of Columbia", "DC"],
"20008": ["Washington", "District of Columbia", "DC"],
"20009": ["Washington", "District of Columbia", "DC"],
"20010": ["Washington", "District of Columbia", "DC"],
"20011": ["Washington", "District of Columbia", "DC"],
"20012": ["Washington", "District of Columbia", "DC"],
"20015": ["Washington", "District of Columbia", "DC"],
"20016": ["Washington", "District of Columbia", "DC"],
"20017": ["Washington", "District of Columbia", "DC"],
"20018": ["Washington", "District of Columbia", "DC"],
"20019": ["Washington", "District of Columbia", "DC"],
"20020": ["Washington", "District of Columbia", "DC"],
"20024": ["Washington", "District of Columbia", "DC"],
"20032": ["Washington", "District of Columbia", "DC"],
"20036": ["Washington", "District of Columbia", "DC"],
"20037": ["Washington", "District of Columbia", "DC"],
"20045": ["Washington", "District of Columbia", "DC"],
"20052": ["Washington", "District of Columbia", "DC"],
"20057": ["Washington", "District of Columbia", "DC"],
"20059": ["Washington", "District of Columbia", "DC"],
"20064": ["Washington", "District of Columbia", "DC"],
"20105": ["Aldie", "Virginia", "VA"],
"20106": ["Amissville", "Virginia", "VA"],
"20109": ["Manassas", "Virginia", "VA"],
"20110": ["Manassas", "Virginia", "VA"],
"20111": ["Manassas", "Virginia", "VA"],
"20112": ["Manassas", "Virginia", "VA"],
"20115": ["Marshall", "Virginia", "VA"],
"20117": ["Middleburg", "Virginia", "VA"],
"20119": ["Catlett", "Virginia", "VA"],
"20120": ["Centreville", "Virginia", "VA"],
"20121": ["Centreville", "Virginia", "VA"],
"20124": ["Clifton", "Virginia", "VA"],
"20129": ["Paeonian Springs", "Virginia", "VA"],
"20130": ["Paris", "Virginia", "VA"],
"20132": ["Purcellville", "Virginia", "VA"],
"20135": ["Bluemont", "Virginia", "VA"],
"20136": ["Bristow", "Virginia", "VA"],
"20137": ["Broad Run", "Virginia", "VA"],
"20141": ["Round Hill", "Virginia", "VA"],
"20143": ["Catharpin", "Virginia", "VA"],
"20144": ["Delaplane", "Virginia", "VA"],
"20147": ["Ashburn", "Virginia", "VA"],
"20148": ["Ashburn", "Virginia", "VA"],
"20151": ["Chantilly", "Virginia", "VA"],
"20152": ["Chantilly", "Virginia", "VA"],
"20155": ["Gainesville", "Virginia", "VA"],
"20158": ["Hamilton", "Virginia", "VA"],
"20164": ["Sterling", "Virginia", "VA"],
"20165": ["Sterling", "Virginia", "VA"],
"20166": ["Sterling", "Virginia", "VA"],
"20169": ["Haymarket", "Virginia", "VA"],
"20170": ["Herndon", "Virginia", "VA"],
"20171": ["Herndon", "Virginia", "VA"],
"20175": ["Leesburg", "Virginia", "VA"],
"20176": ["Leesburg", "Virginia", "VA"],
"20180": ["Lovettsville", "Virginia", "VA"],
"20181": ["Nokesville", "Virginia", "VA"],
"20184": ["Upperville", "Virginia", "VA"],
"20186": ["Warrenton", "Virginia", "VA"],
"20187": ["Warrenton", "Virginia", "VA"],
"20190": ["Reston", "Virginia", "VA"],
"20191": ["Reston", "Virginia", "VA"],
"20194": ["Reston", "Virginia", "VA"],
"20197": ["Waterford", "Virginia", "VA"],
"20198": ["The Plains", "Virginia", "VA"],
"20204": ["Washington", "District of Columbia", "DC"],
"20220": ["Washington", "District of Columbia", "DC"],
"20230": ["Washington", "District of Columbia", "DC"],
"20240": ["Washington", "District of Columbia", "DC"],
"20245": ["Washington", "District of Columbia", "DC"],
"20250": ["Washington", "District of Columbia", "DC"],
"20260": ["Washington", "District of Columbia", "DC"],
"20317": ["Washington", "District of Columbia", "DC"],
"20319": ["Washington", "District of Columbia", "DC"],
"20373": ["Naval Anacost Annex", "District of Columbia", "DC"],
"20388": ["Washington Navy Yard", "District of Columbia", "DC"],
"20390": ["Washington", "District of Columbia", "DC"],
"20408": ["Washington", "District of Columbia", "DC"],
"20415": ["Washington", "District of Columbia", "DC"],
"20418": ["Washington", "District of Columbia", "DC"],
"20422": ["Washington", "District of Columbia", "DC"],
"20427": ["Washington", "District of Columbia", "DC"],
"20431": ["Washington", "District of Columbia", "DC"],
"20510": ["Washington", "District of Columbia", "DC"],
"20515": ["Washington", "District of Columbia", "DC"],
"20520": ["Washington", "District of Columbia", "DC"],
"20530": ["Washington", "District of Columbia", "DC"],
"20535": ["Washington", "District of Columbia", "DC"],
"20540": ["Washington", "District of Columbia", "DC"],
"20542": ["Washington", "District of Columbia", "DC"],
"20551": ["Washington", "District of Columbia", "DC"],
"20560": ["Washington", "District of Columbia", "DC"],
"20565": ["Washington", "District of Columbia", "DC"],
"20566": ["Washington", "District of Columbia", "DC"],
"20591": ["Washington", "District of Columbia", "DC"],
"20601": ["Waldorf", "Maryland", "MD"],
"20602": ["Waldorf", "Maryland", "MD"],
"20603": ["Waldorf", "Maryland", "MD"],
"20606": ["Abell", "Maryland", "MD"],
"20607": ["Accokeek", "Maryland", "MD"],
"20608": ["Aquasco", "Maryland", "MD"],
"20609": ["Avenue", "Maryland", "MD"],
"20611": ["Bel Alton", "Maryland", "MD"],
"20612": ["Benedict", "Maryland", "MD"],
"20613": ["Brandywine", "Maryland", "MD"],
"20615": ["Broomes Island", "Maryland", "MD"],
"20616": ["Bryans Road", "Maryland", "MD"],
"20617": ["Bryantown", "Maryland", "MD"],
"20618": ["Bushwood", "Maryland", "MD"],
"20619": ["California", "Maryland", "MD"],
"20620": ["Callaway", "Maryland", "MD"],
"20621": ["Chaptico", "Maryland", "MD"],
"20622": ["Charlotte Hall", "Maryland", "MD"],
"20623": ["Cheltenham", "Maryland", "MD"],
"20624": ["Clements", "Maryland", "MD"],
"20625": ["Cobb Island", "Maryland", "MD"],
"20626": ["Coltons Point", "Maryland", "MD"],
"20628": ["Dameron", "Maryland", "MD"],
"20629": ["Dowell", "Maryland", "MD"],
"20630": ["Drayden", "Maryland", "MD"],
"20632": ["Faulkner", "Maryland", "MD"],
"20634": ["Great Mills", "Maryland", "MD"],
"20636": ["Hollywood", "Maryland", "MD"],
"20637": ["Hughesville", "Maryland", "MD"],
"20639": ["Huntingtown", "Maryland", "MD"],
"20640": ["Indian Head", "Maryland", "MD"],
"20645": ["Issue", "Maryland", "MD"],
"20646": ["La Plata", "Maryland", "MD"],
"20650": ["Leonardtown", "Maryland", "MD"],
"20653": ["Lexington Park", "Maryland", "MD"],
"20656": ["Loveville", "Maryland", "MD"],
"20657": ["Lusby", "Maryland", "MD"],
"20658": ["Marbury", "Maryland", "MD"],
"20659": ["Mechanicsville", "Maryland", "MD"],
"20660": ["Morganza", "Maryland", "MD"],
"20661": ["Mount Victoria", "Maryland", "MD"],
"20662": ["Nanjemoy", "Maryland", "MD"],
"20664": ["Newburg", "Maryland", "MD"],
"20667": ["Park Hall", "Maryland", "MD"],
"20670": ["Patuxent River", "Maryland", "MD"],
"20674": ["Piney Point", "Maryland", "MD"],
"20675": ["Pomfret", "Maryland", "MD"],
"20676": ["Port Republic", "Maryland", "MD"],
"20677": ["Port Tobacco", "Maryland", "MD"],
"20678": ["Prince Frederick", "Maryland", "MD"],
"20680": ["Ridge", "Maryland", "MD"],
"20684": ["Saint Inigoes", "Maryland", "MD"],
"20685": ["Saint Leonard", "Maryland", "MD"],
"20686": ["Saint Marys City", "Maryland", "MD"],
"20687": ["Scotland", "Maryland", "MD"],
"20688": ["Solomons", "Maryland", "MD"],
"20689": ["Sunderland", "Maryland", "MD"],
"20690": ["Tall Timbers", "Maryland", "MD"],
"20692": ["Valley Lee", "Maryland", "MD"],
"20693": ["Welcome", "Maryland", "MD"],
"20695": ["White Plains", "Maryland", "MD"],
"20701": ["Annapolis Junction", "Maryland", "MD"],
"20705": ["Beltsville", "Maryland", "MD"],
"20706": ["Lanham", "Maryland", "MD"],
"20707": ["Laurel", "Maryland", "MD"],
"20708": ["Laurel", "Maryland", "MD"],
"20710": ["Bladensburg", "Maryland", "MD"],
"20711": ["Lothian", "Maryland", "MD"],
"20712": ["Mount Rainier", "Maryland", "MD"],
"20714": ["North Beach", "Maryland", "MD"],
"20715": ["Bowie", "Maryland", "MD"],
"20716": ["Bowie", "Maryland", "MD"],
"20720": ["Bowie", "Maryland", "MD"],
"20721": ["Bowie", "Maryland", "MD"],
"20722": ["Brentwood", "Maryland", "MD"],
"20723": ["Laurel", "Maryland", "MD"],
"20724": ["Laurel", "Maryland", "MD"],
"20732": ["Chesapeake Beach", "Maryland", "MD"],
"20733": ["Churchton", "Maryland", "MD"],
"20735": ["Clinton", "Maryland", "MD"],
"20736": ["Owings", "Maryland", "MD"],
"20737": ["Riverdale", "Maryland", "MD"],
"20740": ["College Park", "Maryland", "MD"],
"20742": ["College Park", "Maryland", "MD"],
"20743": ["Capitol Heights", "Maryland", "MD"],
"20744": ["Fort Washington", "Maryland", "MD"],
"20745": ["Oxon Hill", "Maryland", "MD"],
"20746": ["Suitland", "Maryland", "MD"],
"20747": ["District Heights", "Maryland", "MD"],
"20748": ["Temple Hills", "Maryland", "MD"],
"20751": ["Deale", "Maryland", "MD"],
"20754": ["Dunkirk", "Maryland", "MD"],
"20755": ["Fort George G Meade", "Maryland", "MD"],
"20758": ["Friendship", "Maryland", "MD"],
"20759": ["Fulton", "Maryland", "MD"],
"20762": ["Andrews Air Force Base", "Maryland", "MD"],
"20763": ["Savage", "Maryland", "MD"],
"20764": ["Shady Side", "Maryland", "MD"],
"20765": ["Galesville", "Maryland", "MD"],
"20769": ["Glenn Dale", "Maryland", "MD"],
"20770": ["Greenbelt", "Maryland", "MD"],
"20771": ["Greenbelt", "Maryland", "MD"],
"20772": ["Upper Marlboro", "Maryland", "MD"],
"20774": ["Upper Marlboro", "Maryland", "MD"],
"20776": ["Harwood", "Maryland", "MD"],
"20777": ["Highland", "Maryland", "MD"],
"20778": ["West River", "Maryland", "MD"],
"20779": ["Tracys Landing", "Maryland", "MD"],
"20781": ["Hyattsville", "Maryland", "MD"],
"20782": ["Hyattsville", "Maryland", "MD"],
"20783": ["Hyattsville", "Maryland", "MD"],
"20784": ["Hyattsville", "Maryland", "MD"],
"20785": ["Hyattsville", "Maryland", "MD"],
"20794": ["Jessup", "Maryland", "MD"],
"20812": ["Glen Echo", "Maryland", "MD"],
"20814": ["Bethesda", "Maryland", "MD"],
"20815": ["Chevy Chase", "Maryland", "MD"],
"20816": ["Bethesda", "Maryland", "MD"],
"20817": ["Bethesda", "Maryland", "MD"],
"20818": ["Cabin John", "Maryland", "MD"],
"20832": ["Olney", "Maryland", "MD"],
"20833": ["Brookeville", "Maryland", "MD"],
"20837": ["Poolesville", "Maryland", "MD"],
"20838": ["Barnesville", "Maryland", "MD"],
"20839": ["Beallsville", "Maryland", "MD"],
"20841": ["Boyds", "Maryland", "MD"],
"20842": ["Dickerson", "Maryland", "MD"],
"20850": ["Rockville", "Maryland", "MD"],
"20851": ["Rockville", "Maryland", "MD"],
"20852": ["Rockville", "Maryland", "MD"],
"20853": ["Rockville", "Maryland", "MD"],
"20854": ["Potomac", "Maryland", "MD"],
"20855": ["Derwood", "Maryland", "MD"],
"20860": ["Sandy Spring", "Maryland", "MD"],
"20861": ["Ashton", "Maryland", "MD"],
"20862": ["Brinklow", "Maryland", "MD"],
"20866": ["Burtonsville", "Maryland", "MD"],
"20868": ["Spencerville", "Maryland", "MD"],
"20871": ["Clarksburg", "Maryland", "MD"],
"20872": ["Damascus", "Maryland", "MD"],
"20874": ["Germantown", "Maryland", "MD"],
"20876": ["Germantown", "Maryland", "MD"],
"20877": ["Gaithersburg", "Maryland", "MD"],
"20878": ["Gaithersburg", "Maryland", "MD"],
"20879": ["Gaithersburg", "Maryland", "MD"],
"20880": ["Washington Grove", "Maryland", "MD"],
"20882": ["Gaithersburg", "Maryland", "MD"],
"20886": ["Montgomery Village", "Maryland", "MD"],
"20889": ["Bethesda", "Maryland", "MD"],
"20892": ["Bethesda", "Maryland", "MD"],
"20894": ["Bethesda", "Maryland", "MD"],
"20895": ["Kensington", "Maryland", "MD"],
"20896": ["Garrett Park", "Maryland", "MD"],
"20899": ["Gaithersburg", "Maryland", "MD"],
"20901": ["Silver Spring", "Maryland", "MD"],
"20902": ["Silver Spring", "Maryland", "MD"],
"20903": ["Silver Spring", "Maryland", "MD"],
"20904": ["Silver Spring", "Maryland", "MD"],
"20905": ["Silver Spring", "Maryland", "MD"],
"20906": ["Silver Spring", "Maryland", "MD"],
"20910": ["Silver Spring", "Maryland", "MD"],
"20912": ["Takoma Park", "Maryland", "MD"],
"21001": ["Aberdeen", "Maryland", "MD"],
"21005": ["Aberdeen Proving Ground", "Maryland", "MD"],
"21009": ["Abingdon", "Maryland", "MD"],
"21010": ["Gunpowder", "Maryland", "MD"],
"21012": ["Arnold", "Maryland", "MD"],
"21013": ["Baldwin", "Maryland", "MD"],
"21014": ["Bel Air", "Maryland", "MD"],
"21015": ["Bel Air", "Maryland", "MD"],
"21017": ["Belcamp", "Maryland", "MD"],
"21023": ["Butler", "Maryland", "MD"],
"21028": ["Churchville", "Maryland", "MD"],
"21029": ["Clarksville", "Maryland", "MD"],
"21030": ["Cockeysville", "Maryland", "MD"],
"21031": ["Hunt Valley", "Maryland", "MD"],
"21032": ["Crownsville", "Maryland", "MD"],
"21034": ["Darlington", "Maryland", "MD"],
"21035": ["Davidsonville", "Maryland", "MD"],
"21036": ["Dayton", "Maryland", "MD"],
"21037": ["Edgewater", "Maryland", "MD"],
"21040": ["Edgewood", "Maryland", "MD"],
"21042": ["Ellicott City", "Maryland", "MD"],
"21043": ["Ellicott City", "Maryland", "MD"],
"21044": ["Columbia", "Maryland", "MD"],
"21045": ["Columbia", "Maryland", "MD"],
"21046": ["Columbia", "Maryland", "MD"],
"21047": ["Fallston", "Maryland", "MD"],
"21048": ["Finksburg", "Maryland", "MD"],
"21050": ["Forest Hill", "Maryland", "MD"],
"21051": ["Fork", "Maryland", "MD"],
"21052": ["Fort Howard", "Maryland", "MD"],
"21053": ["Freeland", "Maryland", "MD"],
"21054": ["Gambrills", "Maryland", "MD"],
"21056": ["Gibson Island", "Maryland", "MD"],
"21057": ["Glen Arm", "Maryland", "MD"],
"21060": ["Glen Burnie", "Maryland", "MD"],
"21061": ["Glen Burnie", "Maryland", "MD"],
"21071": ["Glyndon", "Maryland", "MD"],
"21074": ["Hampstead", "Maryland", "MD"],
"21075": ["Elkridge", "Maryland", "MD"],
"21076": ["Hanover", "Maryland", "MD"],
"21077": ["Harmans", "Maryland", "MD"],
"21078": ["Havre De Grace", "Maryland", "MD"],
"21082": ["Hydes", "Maryland", "MD"],
"21084": ["Jarrettsville", "Maryland", "MD"],
"21085": ["Joppa", "Maryland", "MD"],
"21087": ["Kingsville", "Maryland", "MD"],
"21090": ["Linthicum Heights", "Maryland", "MD"],
"21093": ["Lutherville Timonium", "Maryland", "MD"],
"21102": ["Manchester", "Maryland", "MD"],
"21104": ["Marriottsville", "Maryland", "MD"],
"21105": ["Maryland Line", "Maryland", "MD"],
"21108": ["Millersville", "Maryland", "MD"],
"21111": ["Monkton", "Maryland", "MD"],
"21113": ["Odenton", "Maryland", "MD"],
"21114": ["Crofton", "Maryland", "MD"],
"21117": ["Owings Mills", "Maryland", "MD"],
"21120": ["Parkton", "Maryland", "MD"],
"21122": ["Pasadena", "Maryland", "MD"],
"21128": ["Perry Hall", "Maryland", "MD"],
"21130": ["Perryman", "Maryland", "MD"],
"21131": ["Phoenix", "Maryland", "MD"],
"21132": ["Pylesville", "Maryland", "MD"],
"21133": ["Randallstown", "Maryland", "MD"],
"21136": ["Reisterstown", "Maryland", "MD"],
"21140": ["Riva", "Maryland", "MD"],
"21144": ["Severn", "Maryland", "MD"],
"21146": ["Severna Park", "Maryland", "MD"],
"21152": ["Sparks Glencoe", "Maryland", "MD"],
"21153": ["Stevenson", "Maryland", "MD"],
"21154": ["Street", "Maryland", "MD"],
"21155": ["Upperco", "Maryland", "MD"],
"21156": ["Upper Falls", "Maryland", "MD"],
"21157": ["Westminster", "Maryland", "MD"],
"21158": ["Westminster", "Maryland", "MD"],
"21160": ["Whiteford", "Maryland", "MD"],
"21161": ["White Hall", "Maryland", "MD"],
"21162": ["White Marsh", "Maryland", "MD"],
"21163": ["Woodstock", "Maryland", "MD"],
"21201": ["Baltimore", "Maryland", "MD"],
"21202": ["Baltimore", "Maryland", "MD"],
"21204": ["Towson", "Maryland", "MD"],
"21205": ["Baltimore", "Maryland", "MD"],
"21206": ["Baltimore", "Maryland", "MD"],
"21207": ["Gwynn Oak", "Maryland", "MD"],
"21208": ["Pikesville", "Maryland", "MD"],
"21209": ["Baltimore", "Maryland", "MD"],
"21210": ["Baltimore", "Maryland", "MD"],
"21211": ["Baltimore", "Maryland", "MD"],
"21212": ["Baltimore", "Maryland", "MD"],
"21213": ["Baltimore", "Maryland", "MD"],
"21214": ["Baltimore", "Maryland", "MD"],
"21215": ["Baltimore", "Maryland", "MD"],
"21216": ["Baltimore", "Maryland", "MD"],
"21217": ["Baltimore", "Maryland", "MD"],
"21218": ["Baltimore", "Maryland", "MD"],
"21219": ["Sparrows Point", "Maryland", "MD"],
"21220": ["Middle River", "Maryland", "MD"],
"21221": ["Essex", "Maryland", "MD"],
"21222": ["Dundalk", "Maryland", "MD"],
"21223": ["Baltimore", "Maryland", "MD"],
"21224": ["Baltimore", "Maryland", "MD"],
"21225": ["Brooklyn", "Maryland", "MD"],
"21226": ["Curtis Bay", "Maryland", "MD"],
"21227": ["Halethorpe", "Maryland", "MD"],
"21228": ["Catonsville", "Maryland", "MD"],
"21229": ["Baltimore", "Maryland", "MD"],
"21230": ["Baltimore", "Maryland", "MD"],
"21231": ["Baltimore", "Maryland", "MD"],
"21233": ["Baltimore", "Maryland", "MD"],
"21234": ["Parkville", "Maryland", "MD"],
"21236": ["Nottingham", "Maryland", "MD"],
"21237": ["Rosedale", "Maryland", "MD"],
"21239": ["Baltimore", "Maryland", "MD"],
"21240": ["Baltimore", "Maryland", "MD"],
"21244": ["Windsor Mill", "Maryland", "MD"],
"21250": ["Baltimore", "Maryland", "MD"],
"21251": ["Baltimore", "Maryland", "MD"],
"21252": ["Towson", "Maryland", "MD"],
"21285": ["Towson", "Maryland", "MD"],
"21286": ["Towson", "Maryland", "MD"],
"21287": ["Baltimore", "Maryland", "MD"],
"21401": ["Annapolis", "Maryland", "MD"],
"21402": ["Annapolis", "Maryland", "MD"],
"21403": ["Annapolis", "Maryland", "MD"],
"21405": ["Annapolis", "Maryland", "MD"],
"21409": ["Annapolis", "Maryland", "MD"],
"21502": ["Cumberland", "Maryland", "MD"],
"21520": ["Accident", "Maryland", "MD"],
"21521": ["Barton", "Maryland", "MD"],
"21523": ["Bloomington", "Maryland", "MD"],
"21524": ["Corriganville", "Maryland", "MD"],
"21529": ["Ellerslie", "Maryland", "MD"],
"21530": ["Flintstone", "Maryland", "MD"],
"21531": ["Friendsville", "Maryland", "MD"],
"21532": ["Frostburg", "Maryland", "MD"],
"21536": ["Grantsville", "Maryland", "MD"],
"21538": ["Kitzmiller", "Maryland", "MD"],
"21539": ["Lonaconing", "Maryland", "MD"],
"21540": ["Luke", "Maryland", "MD"],
"21541": ["McHenry", "Maryland", "MD"],
"21542": ["Midland", "Maryland", "MD"],
"21543": ["Midlothian", "Maryland", "MD"],
"21545": ["Mount Savage", "Maryland", "MD"],
"21550": ["Oakland", "Maryland", "MD"],
"21555": ["Oldtown", "Maryland", "MD"],
"21557": ["Rawlings", "Maryland", "MD"],
"21560": ["Spring Gap", "Maryland", "MD"],
"21561": ["Swanton", "Maryland", "MD"],
"21562": ["Westernport", "Maryland", "MD"],
"21601": ["Easton", "Maryland", "MD"],
"21607": ["Barclay", "Maryland", "MD"],
"21610": ["Betterton", "Maryland", "MD"],
"21612": ["Bozman", "Maryland", "MD"],
"21613": ["Cambridge", "Maryland", "MD"],
"21617": ["Centreville", "Maryland", "MD"],
"21619": ["Chester", "Maryland", "MD"],
"21620": ["Chestertown", "Maryland", "MD"],
"21622": ["Church Creek", "Maryland", "MD"],
"21623": ["Church Hill", "Maryland", "MD"],
"21624": ["Claiborne", "Maryland", "MD"],
"21625": ["Cordova", "Maryland", "MD"],
"21626": ["Crapo", "Maryland", "MD"],
"21627": ["Crocheron", "Maryland", "MD"],
"21628": ["Crumpton", "Maryland", "MD"],
"21629": ["Denton", "Maryland", "MD"],
"21631": ["East New Market", "Maryland", "MD"],
"21632": ["Federalsburg", "Maryland", "MD"],
"21634": ["Fishing Creek", "Maryland", "MD"],
"21635": ["Galena", "Maryland", "MD"],
"21636": ["Goldsboro", "Maryland", "MD"],
"21638": ["Grasonville", "Maryland", "MD"],
"21639": ["Greensboro", "Maryland", "MD"],
"21640": ["Henderson", "Maryland", "MD"],
"21641": ["Hillsboro", "Maryland", "MD"],
"21643": ["Hurlock", "Maryland", "MD"],
"21644": ["Ingleside", "Maryland", "MD"],
"21645": ["Kennedyville", "Maryland", "MD"],
"21647": ["Mcdaniel", "Maryland", "MD"],
"21648": ["Madison", "Maryland", "MD"],
"21649": ["Marydel", "Maryland", "MD"],
"21650": ["Massey", "Maryland", "MD"],
"21651": ["Millington", "Maryland", "MD"],
"21652": ["Neavitt", "Maryland", "MD"],
"21653": ["Newcomb", "Maryland", "MD"],
"21654": ["Oxford", "Maryland", "MD"],
"21655": ["Preston", "Maryland", "MD"],
"21657": ["Queen Anne", "Maryland", "MD"],
"21658": ["Queenstown", "Maryland", "MD"],
"21659": ["Rhodesdale", "Maryland", "MD"],
"21660": ["Ridgely", "Maryland", "MD"],
"21661": ["Rock Hall", "Maryland", "MD"],
"21662": ["Royal Oak", "Maryland", "MD"],
"21663": ["Saint Michaels", "Maryland", "MD"],
"21664": ["Secretary", "Maryland", "MD"],
"21665": ["Sherwood", "Maryland", "MD"],
"21666": ["Stevensville", "Maryland", "MD"],
"21667": ["Still Pond", "Maryland", "MD"],
"21668": ["Sudlersville", "Maryland", "MD"],
"21669": ["Taylors Island", "Maryland", "MD"],
"21671": ["Tilghman", "Maryland", "MD"],
"21672": ["Toddville", "Maryland", "MD"],
"21673": ["Trappe", "Maryland", "MD"],
"21675": ["Wingate", "Maryland", "MD"],
"21676": ["Wittman", "Maryland", "MD"],
"21677": ["Woolford", "Maryland", "MD"],
"21678": ["Worton", "Maryland", "MD"],
"21679": ["Wye Mills", "Maryland", "MD"],
"21701": ["Frederick", "Maryland", "MD"],
"21702": ["Frederick", "Maryland", "MD"],
"21703": ["Frederick", "Maryland", "MD"],
"21704": ["Frederick", "Maryland", "MD"],
"21705": ["Frederick", "Maryland", "MD"],
"21710": ["Adamstown", "Maryland", "MD"],
"21711": ["Big Pool", "Maryland", "MD"],
"21713": ["Boonsboro", "Maryland", "MD"],
"21714": ["Braddock Heights", "Maryland", "MD"],
"21715": ["Brownsville", "Maryland", "MD"],
"21716": ["Brunswick", "Maryland", "MD"],
"21717": ["Buckeystown", "Maryland", "MD"],
"21718": ["Burkittsville", "Maryland", "MD"],
"21719": ["Cascade", "Maryland", "MD"],
"21722": ["Clear Spring", "Maryland", "MD"],
"21723": ["Cooksville", "Maryland", "MD"],
"21727": ["Emmitsburg", "Maryland", "MD"],
"21733": ["Fairplay", "Maryland", "MD"],
"21734": ["Funkstown", "Maryland", "MD"],
"21737": ["Glenelg", "Maryland", "MD"],
"21738": ["Glenwood", "Maryland", "MD"],
"21740": ["Hagerstown", "Maryland", "MD"],
"21742": ["Hagerstown", "Maryland", "MD"],
"21746": ["Hagerstown", "Maryland", "MD"],
"21750": ["Hancock", "Maryland", "MD"],
"21754": ["Ijamsville", "Maryland", "MD"],
"21755": ["Jefferson", "Maryland", "MD"],
"21756": ["Keedysville", "Maryland", "MD"],
"21757": ["Keymar", "Maryland", "MD"],
"21758": ["Knoxville", "Maryland", "MD"],
"21762": ["Libertytown", "Maryland", "MD"],
"21766": ["Little Orleans", "Maryland", "MD"],
"21767": ["Maugansville", "Maryland", "MD"],
"21769": ["Middletown", "Maryland", "MD"],
"21770": ["Monrovia", "Maryland", "MD"],
"21771": ["Mount Airy", "Maryland", "MD"],
"21773": ["Myersville", "Maryland", "MD"],
"21774": ["New Market", "Maryland", "MD"],
"21776": ["New Windsor", "Maryland", "MD"],
"21777": ["Point Of Rocks", "Maryland", "MD"],
"21778": ["Rocky Ridge", "Maryland", "MD"],
"21779": ["Rohrersville", "Maryland", "MD"],
"21780": ["Sabillasville", "Maryland", "MD"],
"21781": ["Saint James", "Maryland", "MD"],
"21782": ["Sharpsburg", "Maryland", "MD"],
"21783": ["Smithsburg", "Maryland", "MD"],
"21784": ["Sykesville", "Maryland", "MD"],
"21787": ["Taneytown", "Maryland", "MD"],
"21788": ["Thurmont", "Maryland", "MD"],
"21790": ["Tuscarora", "Maryland", "MD"],
"21791": ["Union Bridge", "Maryland", "MD"],
"21793": ["Walkersville", "Maryland", "MD"],
"21794": ["West Friendship", "Maryland", "MD"],
"21795": ["Williamsport", "Maryland", "MD"],
"21797": ["Woodbine", "Maryland", "MD"],
"21798": ["Woodsboro", "Maryland", "MD"],
"21801": ["Salisbury", "Maryland", "MD"],
"21804": ["Salisbury", "Maryland", "MD"],
"21810": ["Allen", "Maryland", "MD"],
"21811": ["Berlin", "Maryland", "MD"],
"21813": ["Bishopville", "Maryland", "MD"],
"21814": ["Bivalve", "Maryland", "MD"],
"21817": ["Crisfield", "Maryland", "MD"],
"21821": ["Deal Island", "Maryland", "MD"],
"21822": ["Eden", "Maryland", "MD"],
"21824": ["Ewell", "Maryland", "MD"],
"21826": ["Fruitland", "Maryland", "MD"],
"21829": ["Girdletree", "Maryland", "MD"],
"21830": ["Hebron", "Maryland", "MD"],
"21835": ["Linkwood", "Maryland", "MD"],
"21837": ["Mardela Springs", "Maryland", "MD"],
"21838": ["Marion Station", "Maryland", "MD"],
"21840": ["Nanticoke", "Maryland", "MD"],
"21841": ["Newark", "Maryland", "MD"],
"21842": ["Ocean City", "Maryland", "MD"],
"21849": ["Parsonsburg", "Maryland", "MD"],
"21850": ["Pittsville", "Maryland", "MD"],
"21851": ["Pocomoke City", "Maryland", "MD"],
"21853": ["Princess Anne", "Maryland", "MD"],
"21856": ["Quantico", "Maryland", "MD"],
"21861": ["Sharptown", "Maryland", "MD"],
"21862": ["Showell", "Maryland", "MD"],
"21863": ["Snow Hill", "Maryland", "MD"],
"21864": ["Stockton", "Maryland", "MD"],
"21865": ["Tyaskin", "Maryland", "MD"],
"21866": ["Tylerton", "Maryland", "MD"],
"21867": ["Upper Fairmount", "Maryland", "MD"],
"21869": ["Vienna", "Maryland", "MD"],
"21871": ["Westover", "Maryland", "MD"],
"21872": ["Whaleyville", "Maryland", "MD"],
"21874": ["Willards", "Maryland", "MD"],
"21875": ["Delmar", "Maryland", "MD"],
"21890": ["Westover", "Maryland", "MD"],
"21901": ["North East", "Maryland", "MD"],
"21902": ["Perry Point", "Maryland", "MD"],
"21903": ["Perryville", "Maryland", "MD"],
"21904": ["Port Deposit", "Maryland", "MD"],
"21911": ["Rising Sun", "Maryland", "MD"],
"21912": ["Warwick", "Maryland", "MD"],
"21913": ["Cecilton", "Maryland", "MD"],
"21914": ["Charlestown", "Maryland", "MD"],
"21915": ["Chesapeake City", "Maryland", "MD"],
"21917": ["Colora", "Maryland", "MD"],
"21918": ["Conowingo", "Maryland", "MD"],
"21919": ["Earleville", "Maryland", "MD"],
"21920": ["Elk Mills", "Maryland", "MD"],
"21921": ["Elkton", "Maryland", "MD"],
"21930": ["Georgetown", "Maryland", "MD"],
"22003": ["Annandale", "Virginia", "VA"],
"22015": ["Burke", "Virginia", "VA"],
"22025": ["Dumfries", "Virginia", "VA"],
"22026": ["Dumfries", "Virginia", "VA"],
"22027": ["Dunn Loring", "Virginia", "VA"],
"22030": ["Fairfax", "Virginia", "VA"],
"22031": ["Fairfax", "Virginia", "VA"],
"22032": ["Fairfax", "Virginia", "VA"],
"22033": ["Fairfax", "Virginia", "VA"],
"22035": ["Fairfax", "Virginia", "VA"],
"22039": ["Fairfax Station", "Virginia", "VA"],
"22041": ["Falls Church", "Virginia", "VA"],
"22042": ["Falls Church", "Virginia", "VA"],
"22043": ["Falls Church", "Virginia", "VA"],
"22044": ["Falls Church", "Virginia", "VA"],
"22046": ["Falls Church", "Virginia", "VA"],
"22060": ["Fort Belvoir", "Virginia", "VA"],
"22066": ["Great Falls", "Virginia", "VA"],
"22079": ["Lorton", "Virginia", "VA"],
"22101": ["McLean", "Virginia", "VA"],
"22102": ["McLean", "Virginia", "VA"],
"22124": ["Oakton", "Virginia", "VA"],
"22125": ["Occoquan", "Virginia", "VA"],
"22134": ["Quantico", "Virginia", "VA"],
"22150": ["Springfield", "Virginia", "VA"],
"22151": ["Springfield", "Virginia", "VA"],
"22152": ["Springfield", "Virginia", "VA"],
"22153": ["Springfield", "Virginia", "VA"],
"22172": ["Triangle", "Virginia", "VA"],
"22180": ["Vienna", "Virginia", "VA"],
"22181": ["Vienna", "Virginia", "VA"],
"22182": ["Vienna", "Virginia", "VA"],
"22191": ["Woodbridge", "Virginia", "VA"],
"22192": ["Woodbridge", "Virginia", "VA"],
"22193": ["Woodbridge", "Virginia", "VA"],
"22201": ["Arlington", "Virginia", "VA"],
"22202": ["Arlington", "Virginia", "VA"],
"22203": ["Arlington", "Virginia", "VA"],
"22204": ["Arlington", "Virginia", "VA"],
"22205": ["Arlington", "Virginia", "VA"],
"22206": ["Arlington", "Virginia", "VA"],
"22207": ["Arlington", "Virginia", "VA"],
"22209": ["Arlington", "Virginia", "VA"],
"22211": ["Fort Myer", "Virginia", "VA"],
"22213": ["Arlington", "Virginia", "VA"],
"22214": ["Arlington", "Virginia", "VA"],
"22301": ["Alexandria", "Virginia", "VA"],
"22302": ["Alexandria", "Virginia", "VA"],
"22303": ["Alexandria", "Virginia", "VA"],
"22304": ["Alexandria", "Virginia", "VA"],
"22305": ["Alexandria", "Virginia", "VA"],
"22306": ["Alexandria", "Virginia", "VA"],
"22307": ["Alexandria", "Virginia", "VA"],
"22308": ["Alexandria", "Virginia", "VA"],
"22309": ["Alexandria", "Virginia", "VA"],
"22310": ["Alexandria", "Virginia", "VA"],
"22311": ["Alexandria", "Virginia", "VA"],
"22312": ["Alexandria", "Virginia", "VA"],
"22314": ["Alexandria", "Virginia", "VA"],
"22315": ["Alexandria", "Virginia", "VA"],
"22401": ["Fredericksburg", "Virginia", "VA"],
"22405": ["Fredericksburg", "Virginia", "VA"],
"22406": ["Fredericksburg", "Virginia", "VA"],
"22407": ["Fredericksburg", "Virginia", "VA"],
"22408": ["Fredericksburg", "Virginia", "VA"],
"22427": ["Bowling Green", "Virginia", "VA"],
"22432": ["Burgess", "Virginia", "VA"],
"22433": ["Burr Hill", "Virginia", "VA"],
"22435": ["Callao", "Virginia", "VA"],
"22436": ["Caret", "Virginia", "VA"],
"22437": ["Center Cross", "Virginia", "VA"],
"22438": ["Champlain", "Virginia", "VA"],
"22443": ["Colonial Beach", "Virginia", "VA"],
"22448": ["Dahlgren", "Virginia", "VA"],
"22454": ["Dunnsville", "Virginia", "VA"],
"22460": ["Farnham", "Virginia", "VA"],
"22469": ["Hague", "Virginia", "VA"],
"22472": ["Haynesville", "Virginia", "VA"],
"22473": ["Heathsville", "Virginia", "VA"],
"22476": ["Hustle", "Virginia", "VA"],
"22480": ["Irvington", "Virginia", "VA"],
"22482": ["Kilmarnock", "Virginia", "VA"],
"22485": ["King George", "Virginia", "VA"],
"22488": ["Kinsale", "Virginia", "VA"],
"22503": ["Lancaster", "Virginia", "VA"],
"22504": ["Laneview", "Virginia", "VA"],
"22508": ["Locust Grove", "Virginia", "VA"],
"22509": ["Loretto", "Virginia", "VA"],
"22511": ["Lottsburg", "Virginia", "VA"],
"22514": ["Milford", "Virginia", "VA"],
"22520": ["Montross", "Virginia", "VA"],
"22529": ["Oldhams", "Virginia", "VA"],
"22534": ["Partlow", "Virginia", "VA"],
"22535": ["Port Royal", "Virginia", "VA"],
"22538": ["Rappahannock Academy", "Virginia", "VA"],
"22539": ["Reedville", "Virginia", "VA"],
"22542": ["Rhoadesville", "Virginia", "VA"],
"22546": ["Ruther Glen", "Virginia", "VA"],
"22548": ["Sharps", "Virginia", "VA"],
"22551": ["Spotsylvania", "Virginia", "VA"],
"22553": ["Spotsylvania", "Virginia", "VA"],
"22554": ["Stafford", "Virginia", "VA"],
"22556": ["Stafford", "Virginia", "VA"],
"22560": ["Tappahannock", "Virginia", "VA"],
"22567": ["Unionville", "Virginia", "VA"],
"22572": ["Warsaw", "Virginia", "VA"],
"22576": ["Weems", "Virginia", "VA"],
"22578": ["White Stone", "Virginia", "VA"],
"22580": ["Woodford", "Virginia", "VA"],
"22601": ["Winchester", "Virginia", "VA"],
"22602": ["Winchester", "Virginia", "VA"],
"22603": ["Winchester", "Virginia", "VA"],
"22610": ["Bentonville", "Virginia", "VA"],
"22611": ["Berryville", "Virginia", "VA"],
"22620": ["Boyce", "Virginia", "VA"],
"22623": ["Chester Gap", "Virginia", "VA"],
"22624": ["Clear Brook", "Virginia", "VA"],
"22625": ["Cross Junction", "Virginia", "VA"],
"22627": ["Flint Hill", "Virginia", "VA"],
"22630": ["Front Royal", "Virginia", "VA"],
"22637": ["Gore", "Virginia", "VA"],
"22639": ["Hume", "Virginia", "VA"],
"22640": ["Huntly", "Virginia", "VA"],
"22641": ["Strasburg", "Virginia", "VA"],
"22642": ["Linden", "Virginia", "VA"],
"22643": ["Markham", "Virginia", "VA"],
"22644": ["Maurertown", "Virginia", "VA"],
"22645": ["Middletown", "Virginia", "VA"],
"22646": ["Millwood", "Virginia", "VA"],
"22650": ["Rileyville", "Virginia", "VA"],
"22652": ["Fort Valley", "Virginia", "VA"],
"22654": ["Star Tannery", "Virginia", "VA"],
"22655": ["Stephens City", "Virginia", "VA"],
"22656": ["Stephenson", "Virginia", "VA"],
"22657": ["Strasburg", "Virginia", "VA"],
"22660": ["Toms Brook", "Virginia", "VA"],
"22663": ["White Post", "Virginia", "VA"],
"22664": ["Woodstock", "Virginia", "VA"],
"22701": ["Culpeper", "Virginia", "VA"],
"22709": ["Aroda", "Virginia", "VA"],
"22711": ["Banco", "Virginia", "VA"],
"22712": ["Bealeton", "Virginia", "VA"],
"22713": ["Boston", "Virginia", "VA"],
"22714": ["Brandy Station", "Virginia", "VA"],
"22715": ["Brightwood", "Virginia", "VA"],
"22716": ["Castleton", "Virginia", "VA"],
"22718": ["Elkwood", "Virginia", "VA"],
"22719": ["Etlan", "Virginia", "VA"],
"22720": ["Goldvein", "Virginia", "VA"],
"22722": ["Haywood", "Virginia", "VA"],
"22723": ["Hood", "Virginia", "VA"],
"22724": ["Jeffersonton", "Virginia", "VA"],
"22725": ["Leon", "Virginia", "VA"],
"22726": ["Lignum", "Virginia", "VA"],
"22727": ["Madison", "Virginia", "VA"],
"22728": ["Midland", "Virginia", "VA"],
"22729": ["Mitchells", "Virginia", "VA"],
"22730": ["Oakpark", "Virginia", "VA"],
"22731": ["Pratts", "Virginia", "VA"],
"22732": ["Radiant", "Virginia", "VA"],
"22733": ["Rapidan", "Virginia", "VA"],
"22734": ["Remington", "Virginia", "VA"],
"22735": ["Reva", "Virginia", "VA"],
"22736": ["Richardsville", "Virginia", "VA"],
"22737": ["Rixeyville", "Virginia", "VA"],
"22738": ["Rochelle", "Virginia", "VA"],
"22740": ["Sperryville", "Virginia", "VA"],
"22741": ["Stevensburg", "Virginia", "VA"],
"22742": ["Sumerduck", "Virginia", "VA"],
"22743": ["Syria", "Virginia", "VA"],
"22746": ["Viewtown", "Virginia", "VA"],
"22747": ["Washington", "Virginia", "VA"],
"22749": ["Woodville", "Virginia", "VA"],
"22801": ["Harrisonburg", "Virginia", "VA"],
"22802": ["Harrisonburg", "Virginia", "VA"],
"22807": ["Harrisonburg", "Virginia", "VA"],
"22810": ["Basye", "Virginia", "VA"],
"22811": ["Bergton", "Virginia", "VA"],
"22812": ["Bridgewater", "Virginia", "VA"],
"22815": ["Broadway", "Virginia", "VA"],
"22820": ["Criders", "Virginia", "VA"],
"22821": ["Dayton", "Virginia", "VA"],
"22824": ["Edinburg", "Virginia", "VA"],
"22827": ["Elkton", "Virginia", "VA"],
"22830": ["Fulks Run", "Virginia", "VA"],
"22831": ["Hinton", "Virginia", "VA"],
"22832": ["Keezletown", "Virginia", "VA"],
"22834": ["Linville", "Virginia", "VA"],
"22835": ["Luray", "Virginia", "VA"],
"22840": ["McGaheysville", "Virginia", "VA"],
"22841": ["Mount Crawford", "Virginia", "VA"],
"22842": ["Mount Jackson", "Virginia", "VA"],
"22843": ["Mount Solon", "Virginia", "VA"],
"22844": ["New Market", "Virginia", "VA"],
"22845": ["Orkney Springs", "Virginia", "VA"],
"22846": ["Penn Laird", "Virginia", "VA"],
"22847": ["Quicksburg", "Virginia", "VA"],
"22849": ["Shenandoah", "Virginia", "VA"],
"22850": ["Singers Glen", "Virginia", "VA"],
"22851": ["Stanley", "Virginia", "VA"],
"22853": ["Timberville", "Virginia", "VA"],
"22901": ["Charlottesville", "Virginia", "VA"],
"22902": ["Charlottesville", "Virginia", "VA"],
"22903": ["Charlottesville", "Virginia", "VA"],
"22904": ["Charlottesville", "Virginia", "VA"],
"22908": ["Charlottesville", "Virginia", "VA"],
"22911": ["Charlottesville", "Virginia", "VA"],
"22920": ["Afton", "Virginia", "VA"],
"22922": ["Arrington", "Virginia", "VA"],
"22923": ["Barboursville", "Virginia", "VA"],
"22931": ["Covesville", "Virginia", "VA"],
"22932": ["Crozet", "Virginia", "VA"],
"22935": ["Dyke", "Virginia", "VA"],
"22936": ["Earlysville", "Virginia", "VA"],
"22937": ["Esmont", "Virginia", "VA"],
"22938": ["Faber", "Virginia", "VA"],
"22939": ["Fishersville", "Virginia", "VA"],
"22940": ["Free Union", "Virginia", "VA"],
"22942": ["Gordonsville", "Virginia", "VA"],
"22943": ["Greenwood", "Virginia", "VA"],
"22946": ["Keene", "Virginia", "VA"],
"22947": ["Keswick", "Virginia", "VA"],
"22948": ["Locust Dale", "Virginia", "VA"],
"22949": ["Lovingston", "Virginia", "VA"],
"22952": ["Lyndhurst", "Virginia", "VA"],
"22957": ["Montpelier Station", "Virginia", "VA"],
"22958": ["Nellysford", "Virginia", "VA"],
"22959": ["North Garden", "Virginia", "VA"],
"22960": ["Orange", "Virginia", "VA"],
"22963": ["Palmyra", "Virginia", "VA"],
"22964": ["Piney River", "Virginia", "VA"],
"22967": ["Roseland", "Virginia", "VA"],
"22968": ["Ruckersville", "Virginia", "VA"],
"22969": ["Schuyler", "Virginia", "VA"],
"22971": ["Shipman", "Virginia", "VA"],
"22972": ["Somerset", "Virginia", "VA"],
"22973": ["Stanardsville", "Virginia", "VA"],
"22974": ["Troy", "Virginia", "VA"],
"22976": ["Tyro", "Virginia", "VA"],
"22980": ["Waynesboro", "Virginia", "VA"],
"22989": ["Woodberry Forest", "Virginia", "VA"],
"23002": ["Amelia Court House", "Virginia", "VA"],
"23004": ["Arvonia", "Virginia", "VA"],
"23005": ["Ashland", "Virginia", "VA"],
"23009": ["Aylett", "Virginia", "VA"],
"23011": ["Barhamsville", "Virginia", "VA"],
"23015": ["Beaverdam", "Virginia", "VA"],
"23021": ["Bohannon", "Virginia", "VA"],
"23022": ["Bremo Bluff", "Virginia", "VA"],
"23023": ["Bruington", "Virginia", "VA"],
"23024": ["Bumpass", "Virginia", "VA"],
"23025": ["Cardinal", "Virginia", "VA"],
"23027": ["Cartersville", "Virginia", "VA"],
"23030": ["Charles City", "Virginia", "VA"],
"23032": ["Church View", "Virginia", "VA"],
"23035": ["Cobbs Creek", "Virginia", "VA"],
"23038": ["Columbia", "Virginia", "VA"],
"23039": ["Crozier", "Virginia", "VA"],
"23040": ["Cumberland", "Virginia", "VA"],
"23043": ["Deltaville", "Virginia", "VA"],
"23045": ["Diggs", "Virginia", "VA"],
"23047": ["Doswell", "Virginia", "VA"],
"23050": ["Dutton", "Virginia", "VA"],
"23055": ["Fork Union", "Virginia", "VA"],
"23056": ["Foster", "Virginia", "VA"],
"23059": ["Glen Allen", "Virginia", "VA"],
"23060": ["Glen Allen", "Virginia", "VA"],
"23061": ["Gloucester", "Virginia", "VA"],
"23062": ["Gloucester Point", "Virginia", "VA"],
"23063": ["Goochland", "Virginia", "VA"],
"23065": ["Gum Spring", "Virginia", "VA"],
"23066": ["Gwynn", "Virginia", "VA"],
"23068": ["Hallieford", "Virginia", "VA"],
"23069": ["Hanover", "Virginia", "VA"],
"23070": ["Hardyville", "Virginia", "VA"],
"23071": ["Hartfield", "Virginia", "VA"],
"23072": ["Hayes", "Virginia", "VA"],
"23075": ["Henrico", "Virginia", "VA"],
"23076": ["Hudgins", "Virginia", "VA"],
"23079": ["Jamaica", "Virginia", "VA"],
"23081": ["Jamestown", "Virginia", "VA"],
"23083": ["Jetersville", "Virginia", "VA"],
"23084": ["Kents Store", "Virginia", "VA"],
"23085": ["King And Queen Court House", "Virginia", "VA"],
"23086": ["King William", "Virginia", "VA"],
"23089": ["Lanexa", "Virginia", "VA"],
"23091": ["Little Plymouth", "Virginia", "VA"],
"23092": ["Locust Hill", "Virginia", "VA"],
"23093": ["Louisa", "Virginia", "VA"],
"23102": ["Maidens", "Virginia", "VA"],
"23103": ["Manakin Sabot", "Virginia", "VA"],
"23106": ["Manquin", "Virginia", "VA"],
"23108": ["Mascot", "Virginia", "VA"],
"23109": ["Mathews", "Virginia", "VA"],
"23110": ["Mattaponi", "Virginia", "VA"],
"23111": ["Mechanicsville", "Virginia", "VA"],
"23112": ["Midlothian", "Virginia", "VA"],
"23113": ["Midlothian", "Virginia", "VA"],
"23114": ["Midlothian", "Virginia", "VA"],
"23116": ["Mechanicsville", "Virginia", "VA"],
"23117": ["Mineral", "Virginia", "VA"],
"23119": ["Moon", "Virginia", "VA"],
"23120": ["Moseley", "Virginia", "VA"],
"23123": ["New Canton", "Virginia", "VA"],
"23124": ["New Kent", "Virginia", "VA"],
"23126": ["Newtown", "Virginia", "VA"],
"23128": ["North", "Virginia", "VA"],
"23129": ["Oilville", "Virginia", "VA"],
"23130": ["Onemo", "Virginia", "VA"],
"23138": ["Port Haywood", "Virginia", "VA"],
"23139": ["Powhatan", "Virginia", "VA"],
"23140": ["Providence Forge", "Virginia", "VA"],
"23141": ["Quinton", "Virginia", "VA"],
"23146": ["Rockville", "Virginia", "VA"],
"23147": ["Ruthville", "Virginia", "VA"],
"23148": ["Saint Stephens Church", "Virginia", "VA"],
"23149": ["Saluda", "Virginia", "VA"],
"23150": ["Sandston", "Virginia", "VA"],
"23153": ["Sandy Hook", "Virginia", "VA"],
"23156": ["Shacklefords", "Virginia", "VA"],
"23160": ["State Farm", "Virginia", "VA"],
"23161": ["Stevensville", "Virginia", "VA"],
"23163": ["Susan", "Virginia", "VA"],
"23168": ["Toano", "Virginia", "VA"],
"23169": ["Topping", "Virginia", "VA"],
"23173": ["Richmond", "Virginia", "VA"],
"23175": ["Urbanna", "Virginia", "VA"],
"23176": ["Wake", "Virginia", "VA"],
"23177": ["Walkerton", "Virginia", "VA"],
"23180": ["Water View", "Virginia", "VA"],
"23181": ["West Point", "Virginia", "VA"],
"23185": ["Williamsburg", "Virginia", "VA"],
"23188": ["Williamsburg", "Virginia", "VA"],
"23192": ["Montpelier", "Virginia", "VA"],
"23219": ["Richmond", "Virginia", "VA"],
"23220": ["Richmond", "Virginia", "VA"],
"23221": ["Richmond", "Virginia", "VA"],
"23222": ["Richmond", "Virginia", "VA"],
"23223": ["Richmond", "Virginia", "VA"],
"23224": ["Richmond", "Virginia", "VA"],
"23225": ["Richmond", "Virginia", "VA"],
"23226": ["Richmond", "Virginia", "VA"],
"23227": ["Richmond", "Virginia", "VA"],
"23228": ["Henrico", "Virginia", "VA"],
"23229": ["Henrico", "Virginia", "VA"],
"23230": ["Richmond", "Virginia", "VA"],
"23231": ["Henrico", "Virginia", "VA"],
"23233": ["Henrico", "Virginia", "VA"],
"23234": ["Richmond", "Virginia", "VA"],
"23235": ["Richmond", "Virginia", "VA"],
"23236": ["Richmond", "Virginia", "VA"],
"23237": ["Richmond", "Virginia", "VA"],
"23238": ["Henrico", "Virginia", "VA"],
"23250": ["Richmond", "Virginia", "VA"],
"23284": ["Richmond", "Virginia", "VA"],
"23294": ["Henrico", "Virginia", "VA"],
"23298": ["Richmond", "Virginia", "VA"],
"23301": ["Accomac", "Virginia", "VA"],
"23302": ["Assawoman", "Virginia", "VA"],
"23303": ["Atlantic", "Virginia", "VA"],
"23304": ["Battery Park", "Virginia", "VA"],
"23306": ["Belle Haven", "Virginia", "VA"],
"23307": ["Birdsnest", "Virginia", "VA"],
"23308": ["Bloxom", "Virginia", "VA"],
"23310": ["Cape Charles", "Virginia", "VA"],
"23314": ["Carrollton", "Virginia", "VA"],
"23315": ["Carrsville", "Virginia", "VA"],
"23316": ["Cheriton", "Virginia", "VA"],
"23320": ["Chesapeake", "Virginia", "VA"],
"23321": ["Chesapeake", "Virginia", "VA"],
"23322": ["Chesapeake", "Virginia", "VA"],
"23323": ["Chesapeake", "Virginia", "VA"],
"23324": ["Chesapeake", "Virginia", "VA"],
"23325": ["Chesapeake", "Virginia", "VA"],
"23336": ["Chincoteague Island", "Virginia", "VA"],
"23337": ["Wallops Island", "Virginia", "VA"],
"23347": ["Eastville", "Virginia", "VA"],
"23350": ["Exmore", "Virginia", "VA"],
"23354": ["Franktown", "Virginia", "VA"],
"23356": ["Greenbackville", "Virginia", "VA"],
"23357": ["Greenbush", "Virginia", "VA"],
"23358": ["Hacksneck", "Virginia", "VA"],
"23359": ["Hallwood", "Virginia", "VA"],
"23389": ["Harborton", "Virginia", "VA"],
"23395": ["Horntown", "Virginia", "VA"],
"23398": ["Jamesville", "Virginia", "VA"],
"23401": ["Keller", "Virginia", "VA"],
"23405": ["Machipongo", "Virginia", "VA"],
"23407": ["Mappsville", "Virginia", "VA"],
"23408": ["Marionville", "Virginia", "VA"],
"23409": ["Mears", "Virginia", "VA"],
"23410": ["Melfa", "Virginia", "VA"],
"23413": ["Nassawadox", "Virginia", "VA"],
"23414": ["Nelsonia", "Virginia", "VA"],
"23415": ["New Church", "Virginia", "VA"],
"23416": ["Oak Hall", "Virginia", "VA"],
"23417": ["Onancock", "Virginia", "VA"],
"23418": ["Onley", "Virginia", "VA"],
"23420": ["Painter", "Virginia", "VA"],
"23421": ["Parksley", "Virginia", "VA"],
"23422": ["Pungoteague", "Virginia", "VA"],
"23423": ["Quinby", "Virginia", "VA"],
"23426": ["Sanford", "Virginia", "VA"],
"23427": ["Saxis", "Virginia", "VA"],
"23430": ["Smithfield", "Virginia", "VA"],
"23432": ["Suffolk", "Virginia", "VA"],
"23433": ["Suffolk", "Virginia", "VA"],
"23434": ["Suffolk", "Virginia", "VA"],
"23435": ["Suffolk", "Virginia", "VA"],
"23436": ["Suffolk", "Virginia", "VA"],
"23437": ["Suffolk", "Virginia", "VA"],
"23438": ["Suffolk", "Virginia", "VA"],
"23440": ["Tangier", "Virginia", "VA"],
"23441": ["Tasley", "Virginia", "VA"],
"23442": ["Temperanceville", "Virginia", "VA"],
"23451": ["Virginia Beach", "Virginia", "VA"],
"23452": ["Virginia Beach", "Virginia", "VA"],
"23453": ["Virginia Beach", "Virginia", "VA"],
"23454": ["Virginia Beach", "Virginia", "VA"],
"23455": ["Virginia Beach", "Virginia", "VA"],
"23456": ["Virginia Beach", "Virginia", "VA"],
"23457": ["Virginia Beach", "Virginia", "VA"],
"23459": ["Virginia Beach", "Virginia", "VA"],
"23460": ["Virginia Beach", "Virginia", "VA"],
"23461": ["Virginia Beach", "Virginia", "VA"],
"23462": ["Virginia Beach", "Virginia", "VA"],
"23464": ["Virginia Beach", "Virginia", "VA"],
"23480": ["Wachapreague", "Virginia", "VA"],
"23486": ["Willis Wharf", "Virginia", "VA"],
"23487": ["Windsor", "Virginia", "VA"],
"23488": ["Withams", "Virginia", "VA"],
"23502": ["Norfolk", "Virginia", "VA"],
"23503": ["Norfolk", "Virginia", "VA"],
"23504": ["Norfolk", "Virginia", "VA"],
"23505": ["Norfolk", "Virginia", "VA"],
"23507": ["Norfolk", "Virginia", "VA"],
"23508": ["Norfolk", "Virginia", "VA"],
"23509": ["Norfolk", "Virginia", "VA"],
"23510": ["Norfolk", "Virginia", "VA"],
"23511": ["Norfolk", "Virginia", "VA"],
"23513": ["Norfolk", "Virginia", "VA"],
"23517": ["Norfolk", "Virginia", "VA"],
"23518": ["Norfolk", "Virginia", "VA"],
"23523": ["Norfolk", "Virginia", "VA"],
"23551": ["Norfolk", "Virginia", "VA"],
"23601": ["Newport News", "Virginia", "VA"],
"23602": ["Newport News", "Virginia", "VA"],
"23603": ["Newport News", "Virginia", "VA"],
"23604": ["Fort Eustis", "Virginia", "VA"],
"23605": ["Newport News", "Virginia", "VA"],
"23606": ["Newport News", "Virginia", "VA"],
"23607": ["Newport News", "Virginia", "VA"],
"23608": ["Newport News", "Virginia", "VA"],
"23651": ["Fort Monroe", "Virginia", "VA"],
"23661": ["Hampton", "Virginia", "VA"],
"23662": ["Poquoson", "Virginia", "VA"],
"23663": ["Hampton", "Virginia", "VA"],
"23664": ["Hampton", "Virginia", "VA"],
"23665": ["Hampton", "Virginia", "VA"],
"23666": ["Hampton", "Virginia", "VA"],
"23667": ["Hampton", "Virginia", "VA"],
"23668": ["Hampton", "Virginia", "VA"],
"23669": ["Hampton", "Virginia", "VA"],
"23681": ["Hampton", "Virginia", "VA"],
"23690": ["Yorktown", "Virginia", "VA"],
"23691": ["Yorktown", "Virginia", "VA"],
"23692": ["Yorktown", "Virginia", "VA"],
"23693": ["Yorktown", "Virginia", "VA"],
"23696": ["Seaford", "Virginia", "VA"],
"23701": ["Portsmouth", "Virginia", "VA"],
"23702": ["Portsmouth", "Virginia", "VA"],
"23703": ["Portsmouth", "Virginia", "VA"],
"23704": ["Portsmouth", "Virginia", "VA"],
"23707": ["Portsmouth", "Virginia", "VA"],
"23708": ["Portsmouth", "Virginia", "VA"],
"23709": ["Portsmouth", "Virginia", "VA"],
"23801": ["Fort Lee", "Virginia", "VA"],
"23803": ["Petersburg", "Virginia", "VA"],
"23805": ["Petersburg", "Virginia", "VA"],
"23821": ["Alberta", "Virginia", "VA"],
"23824": ["Blackstone", "Virginia", "VA"],
"23827": ["Boykins", "Virginia", "VA"],
"23828": ["Branchville", "Virginia", "VA"],
"23829": ["Capron", "Virginia", "VA"],
"23830": ["Carson", "Virginia", "VA"],
"23831": ["Chester", "Virginia", "VA"],
"23832": ["Chesterfield", "Virginia", "VA"],
"23833": ["Church Road", "Virginia", "VA"],
"23834": ["Colonial Heights", "Virginia", "VA"],
"23836": ["Chester", "Virginia", "VA"],
"23837": ["Courtland", "Virginia", "VA"],
"23838": ["Chesterfield", "Virginia", "VA"],
"23839": ["Dendron", "Virginia", "VA"],
"23840": ["Dewitt", "Virginia", "VA"],
"23841": ["Dinwiddie", "Virginia", "VA"],
"23842": ["Disputanta", "Virginia", "VA"],
"23843": ["Dolphin", "Virginia", "VA"],
"23844": ["Drewryville", "Virginia", "VA"],
"23845": ["Ebony", "Virginia", "VA"],
"23846": ["Elberon", "Virginia", "VA"],
"23847": ["Emporia", "Virginia", "VA"],
"23850": ["Ford", "Virginia", "VA"],
"23851": ["Franklin", "Virginia", "VA"],
"23856": ["Freeman", "Virginia", "VA"],
"23857": ["Gasburg", "Virginia", "VA"],
"23860": ["Hopewell", "Virginia", "VA"],
"23866": ["Ivor", "Virginia", "VA"],
"23867": ["Jarratt", "Virginia", "VA"],
"23868": ["Lawrenceville", "Virginia", "VA"],
"23870": ["Jarratt", "Virginia", "VA"],
"23872": ["McKenney", "Virginia", "VA"],
"23874": ["Newsoms", "Virginia", "VA"],
"23875": ["Prince George", "Virginia", "VA"],
"23876": ["Rawlings", "Virginia", "VA"],
"23878": ["Sedley", "Virginia", "VA"],
"23879": ["Skippers", "Virginia", "VA"],
"23881": ["Spring Grove", "Virginia", "VA"],
"23882": ["Stony Creek", "Virginia", "VA"],
"23883": ["Surry", "Virginia", "VA"],
"23884": ["Sussex", "Virginia", "VA"],
"23885": ["Sutherland", "Virginia", "VA"],
"23887": ["Valentines", "Virginia", "VA"],
"23888": ["Wakefield", "Virginia", "VA"],
"23889": ["Warfield", "Virginia", "VA"],
"23890": ["Waverly", "Virginia", "VA"],
"23891": ["Waverly", "Virginia", "VA"],
"23893": ["White Plains", "Virginia", "VA"],
"23894": ["Wilsons", "Virginia", "VA"],
"23897": ["Yale", "Virginia", "VA"],
"23898": ["Zuni", "Virginia", "VA"],
"23899": ["Claremont", "Virginia", "VA"],
"23901": ["Farmville", "Virginia", "VA"],
"23909": ["Farmville", "Virginia", "VA"],
"23915": ["Baskerville", "Virginia", "VA"],
"23917": ["Boydton", "Virginia", "VA"],
"23919": ["Bracey", "Virginia", "VA"],
"23920": ["Brodnax", "Virginia", "VA"],
"23921": ["Buckingham", "Virginia", "VA"],
"23922": ["Burkeville", "Virginia", "VA"],
"23923": ["Charlotte Court House", "Virginia", "VA"],
"23924": ["Chase City", "Virginia", "VA"],
"23927": ["Clarksville", "Virginia", "VA"],
"23930": ["Crewe", "Virginia", "VA"],
"23934": ["Cullen", "Virginia", "VA"],
"23936": ["Dillwyn", "Virginia", "VA"],
"23937": ["Drakes Branch", "Virginia", "VA"],
"23938": ["Dundas", "Virginia", "VA"],
"23942": ["Green Bay", "Virginia", "VA"],
"23943": ["Hampden Sydney", "Virginia", "VA"],
"23944": ["Kenbridge", "Virginia", "VA"],
"23947": ["Keysville", "Virginia", "VA"],
"23950": ["La Crosse", "Virginia", "VA"],
"23952": ["Lunenburg", "Virginia", "VA"],
"23954": ["Meherrin", "Virginia", "VA"],
"23955": ["Nottoway", "Virginia", "VA"],
"23958": ["Pamplin", "Virginia", "VA"],
"23959": ["Phenix", "Virginia", "VA"],
"23960": ["Prospect", "Virginia", "VA"],
"23962": ["Randolph", "Virginia", "VA"],
"23963": ["Red House", "Virginia", "VA"],
"23964": ["Red Oak", "Virginia", "VA"],
"23966": ["Rice", "Virginia", "VA"],
"23967": ["Saxe", "Virginia", "VA"],
"23968": ["Skipwith", "Virginia", "VA"],
"23970": ["South Hill", "Virginia", "VA"],
"23974": ["Victoria", "Virginia", "VA"],
"23976": ["Wylliesburg", "Virginia", "VA"],
"24011": ["Roanoke", "Virginia", "VA"],
"24012": ["Roanoke", "Virginia", "VA"],
"24013": ["Roanoke", "Virginia", "VA"],
"24014": ["Roanoke", "Virginia", "VA"],
"24015": ["Roanoke", "Virginia", "VA"],
"24016": ["Roanoke", "Virginia", "VA"],
"24017": ["Roanoke", "Virginia", "VA"],
"24018": ["Roanoke", "Virginia", "VA"],
"24019": ["Roanoke", "Virginia", "VA"],
"24020": ["Roanoke", "Virginia", "VA"],
"24022": ["Roanoke", "Virginia", "VA"],
"24053": ["Ararat", "Virginia", "VA"],
"24054": ["Axton", "Virginia", "VA"],
"24055": ["Bassett", "Virginia", "VA"],
"24058": ["Belspring", "Virginia", "VA"],
"24059": ["Bent Mountain", "Virginia", "VA"],
"24060": ["Blacksburg", "Virginia", "VA"],
"24061": ["Blacksburg", "Virginia", "VA"],
"24064": ["Blue Ridge", "Virginia", "VA"],
"24065": ["Boones Mill", "Virginia", "VA"],
"24066": ["Buchanan", "Virginia", "VA"],
"24067": ["Callaway", "Virginia", "VA"],
"24069": ["Cascade", "Virginia", "VA"],
"24070": ["Catawba", "Virginia", "VA"],
"24072": ["Check", "Virginia", "VA"],
"24073": ["Christiansburg", "Virginia", "VA"],
"24076": ["Claudville", "Virginia", "VA"],
"24077": ["Cloverdale", "Virginia", "VA"],
"24078": ["Collinsville", "Virginia", "VA"],
"24079": ["Copper Hill", "Virginia", "VA"],
"24082": ["Critz", "Virginia", "VA"],
"24083": ["Daleville", "Virginia", "VA"],
"24084": ["Dublin", "Virginia", "VA"],
"24085": ["Eagle Rock", "Virginia", "VA"],
"24086": ["Eggleston", "Virginia", "VA"],
"24087": ["Elliston", "Virginia", "VA"],
"24088": ["Ferrum", "Virginia", "VA"],
"24089": ["Fieldale", "Virginia", "VA"],
"24090": ["Fincastle", "Virginia", "VA"],
"24091": ["Floyd", "Virginia", "VA"],
"24092": ["Glade Hill", "Virginia", "VA"],
"24093": ["Glen Lyn", "Virginia", "VA"],
"24095": ["Goodview", "Virginia", "VA"],
"24101": ["Hardy", "Virginia", "VA"],
"24102": ["Henry", "Virginia", "VA"],
"24104": ["Huddleston", "Virginia", "VA"],
"24105": ["Indian Valley", "Virginia", "VA"],
"24112": ["Martinsville", "Virginia", "VA"],
"24120": ["Meadows Of Dan", "Virginia", "VA"],
"24121": ["Moneta", "Virginia", "VA"],
"24122": ["Montvale", "Virginia", "VA"],
"24124": ["Narrows", "Virginia", "VA"],
"24127": ["New Castle", "Virginia", "VA"],
"24128": ["Newport", "Virginia", "VA"],
"24129": ["New River", "Virginia", "VA"],
"24131": ["Paint Bank", "Virginia", "VA"],
"24132": ["Parrott", "Virginia", "VA"],
"24133": ["Patrick Springs", "Virginia", "VA"],
"24134": ["Pearisburg", "Virginia", "VA"],
"24136": ["Pembroke", "Virginia", "VA"],
"24137": ["Penhook", "Virginia", "VA"],
"24138": ["Pilot", "Virginia", "VA"],
"24139": ["Pittsville", "Virginia", "VA"],
"24141": ["Radford", "Virginia", "VA"],
"24142": ["Radford", "Virginia", "VA"],
"24147": ["Rich Creek", "Virginia", "VA"],
"24148": ["Ridgeway", "Virginia", "VA"],
"24149": ["Riner", "Virginia", "VA"],
"24150": ["Ripplemead", "Virginia", "VA"],
"24151": ["Rocky Mount", "Virginia", "VA"],
"24153": ["Salem", "Virginia", "VA"],
"24161": ["Sandy Level", "Virginia", "VA"],
"24162": ["Shawsville", "Virginia", "VA"],
"24165": ["Spencer", "Virginia", "VA"],
"24167": ["Staffordsville", "Virginia", "VA"],
"24168": ["Stanleytown", "Virginia", "VA"],
"24171": ["Stuart", "Virginia", "VA"],
"24174": ["Thaxton", "Virginia", "VA"],
"24175": ["Troutville", "Virginia", "VA"],
"24176": ["Union Hall", "Virginia", "VA"],
"24179": ["Vinton", "Virginia", "VA"],
"24184": ["Wirtz", "Virginia", "VA"],
"24185": ["Woolwine", "Virginia", "VA"],
"24201": ["Bristol", "Virginia", "VA"],
"24202": ["Bristol", "Virginia", "VA"],
"24210": ["Abingdon", "Virginia", "VA"],
"24211": ["Abingdon", "Virginia", "VA"],
"24216": ["Appalachia", "Virginia", "VA"],
"24217": ["Bee", "Virginia", "VA"],
"24219": ["Big Stone Gap", "Virginia", "VA"],
"24220": ["Birchleaf", "Virginia", "VA"],
"24221": ["Blackwater", "Virginia", "VA"],
"24224": ["Castlewood", "Virginia", "VA"],
"24225": ["Cleveland", "Virginia", "VA"],
"24226": ["Clinchco", "Virginia", "VA"],
"24228": ["Clintwood", "Virginia", "VA"],
"24230": ["Coeburn", "Virginia", "VA"],
"24236": ["Damascus", "Virginia", "VA"],
"24237": ["Dante", "Virginia", "VA"],
"24239": ["Davenport", "Virginia", "VA"],
"24243": ["Dryden", "Virginia", "VA"],
"24244": ["Duffield", "Virginia", "VA"],
"24245": ["Dungannon", "Virginia", "VA"],
"24246": ["East Stone Gap", "Virginia", "VA"],
"24248": ["Ewing", "Virginia", "VA"],
"24250": ["Fort Blackmore", "Virginia", "VA"],
"24251": ["Gate City", "Virginia", "VA"],
"24256": ["Haysi", "Virginia", "VA"],
"24258": ["Hiltons", "Virginia", "VA"],
"24260": ["Honaker", "Virginia", "VA"],
"24263": ["Jonesville", "Virginia", "VA"],
"24265": ["Keokee", "Virginia", "VA"],
"24266": ["Lebanon", "Virginia", "VA"],
"24269": ["McClure", "Virginia", "VA"],
"24270": ["Mendota", "Virginia", "VA"],
"24271": ["Nickelsville", "Virginia", "VA"],
"24272": ["Nora", "Virginia", "VA"],
"24273": ["Norton", "Virginia", "VA"],
"24277": ["Pennington Gap", "Virginia", "VA"],
"24279": ["Pound", "Virginia", "VA"],
"24280": ["Rosedale", "Virginia", "VA"],
"24281": ["Rose Hill", "Virginia", "VA"],
"24282": ["Saint Charles", "Virginia", "VA"],
"24283": ["Saint Paul", "Virginia", "VA"],
"24290": ["Weber City", "Virginia", "VA"],
"24292": ["Whitetop", "Virginia", "VA"],
"24293": ["Wise", "Virginia", "VA"],
"24301": ["Pulaski", "Virginia", "VA"],
"24311": ["Atkins", "Virginia", "VA"],
"24312": ["Austinville", "Virginia", "VA"],
"24313": ["Barren Springs", "Virginia", "VA"],
"24314": ["Bastian", "Virginia", "VA"],
"24315": ["Bland", "Virginia", "VA"],
"24316": ["Broadford", "Virginia", "VA"],
"24317": ["Cana", "Virginia", "VA"],
"24318": ["Ceres", "Virginia", "VA"],
"24319": ["Chilhowie", "Virginia", "VA"],
"24322": ["Cripple Creek", "Virginia", "VA"],
"24323": ["Crockett", "Virginia", "VA"],
"24324": ["Draper", "Virginia", "VA"],
"24325": ["Dugspur", "Virginia", "VA"],
"24326": ["Elk Creek", "Virginia", "VA"],
"24327": ["Emory", "Virginia", "VA"],
"24328": ["Fancy Gap", "Virginia", "VA"],
"24330": ["Fries", "Virginia", "VA"],
"24333": ["Galax", "Virginia", "VA"],
"24340": ["Glade Spring", "Virginia", "VA"],
"24343": ["Hillsville", "Virginia", "VA"],
"24347": ["Hiwassee", "Virginia", "VA"],
"24348": ["Independence", "Virginia", "VA"],
"24350": ["Ivanhoe", "Virginia", "VA"],
"24351": ["Lambsburg", "Virginia", "VA"],
"24352": ["Laurel Fork", "Virginia", "VA"],
"24354": ["Marion", "Virginia", "VA"],
"24360": ["Max Meadows", "Virginia", "VA"],
"24361": ["Meadowview", "Virginia", "VA"],
"24363": ["Mouth Of Wilson", "Virginia", "VA"],
"24366": ["Rocky Gap", "Virginia", "VA"],
"24368": ["Rural Retreat", "Virginia", "VA"],
"24370": ["Saltville", "Virginia", "VA"],
"24374": ["Speedwell", "Virginia", "VA"],
"24375": ["Sugar Grove", "Virginia", "VA"],
"24377": ["Tannersville", "Virginia", "VA"],
"24378": ["Troutdale", "Virginia", "VA"],
"24380": ["Willis", "Virginia", "VA"],
"24381": ["Woodlawn", "Virginia", "VA"],
"24382": ["Wytheville", "Virginia", "VA"],
"24401": ["Staunton", "Virginia", "VA"],
"24411": ["Augusta Springs", "Virginia", "VA"],
"24412": ["Bacova", "Virginia", "VA"],
"24413": ["Blue Grass", "Virginia", "VA"],
"24415": ["Brownsburg", "Virginia", "VA"],
"24416": ["Buena Vista", "Virginia", "VA"],
"24421": ["Churchville", "Virginia", "VA"],
"24422": ["Clifton Forge", "Virginia", "VA"],
"24426": ["Covington", "Virginia", "VA"],
"24430": ["Craigsville", "Virginia", "VA"],
"24431": ["Crimora", "Virginia", "VA"],
"24432": ["Deerfield", "Virginia", "VA"],
"24433": ["Doe Hill", "Virginia", "VA"],
"24435": ["Fairfield", "Virginia", "VA"],
"24437": ["Fort Defiance", "Virginia", "VA"],
"24439": ["Goshen", "Virginia", "VA"],
"24440": ["Greenville", "Virginia", "VA"],
"24441": ["Grottoes", "Virginia", "VA"],
"24442": ["Head Waters", "Virginia", "VA"],
"24445": ["Hot Springs", "Virginia", "VA"],
"24448": ["Iron Gate", "Virginia", "VA"],
"24450": ["Lexington", "Virginia", "VA"],
"24457": ["Low Moor", "Virginia", "VA"],
"24458": ["McDowell", "Virginia", "VA"],
"24459": ["Middlebrook", "Virginia", "VA"],
"24460": ["Millboro", "Virginia", "VA"],
"24464": ["Montebello", "Virginia", "VA"],
"24465": ["Monterey", "Virginia", "VA"],
"24467": ["Mount Sidney", "Virginia", "VA"],
"24469": ["New Hope", "Virginia", "VA"],
"24471": ["Port Republic", "Virginia", "VA"],
"24472": ["Raphine", "Virginia", "VA"],
"24473": ["Rockbridge Baths", "Virginia", "VA"],
"24474": ["Selma", "Virginia", "VA"],
"24476": ["Steeles Tavern", "Virginia", "VA"],
"24477": ["Stuarts Draft", "Virginia", "VA"],
"24479": ["Swoope", "Virginia", "VA"],
"24482": ["Verona", "Virginia", "VA"],
"24483": ["Vesuvius", "Virginia", "VA"],
"24484": ["Warm Springs", "Virginia", "VA"],
"24485": ["West Augusta", "Virginia", "VA"],
"24486": ["Weyers Cave", "Virginia", "VA"],
"24487": ["Williamsville", "Virginia", "VA"],
"24501": ["Lynchburg", "Virginia", "VA"],
"24502": ["Lynchburg", "Virginia", "VA"],
"24503": ["Lynchburg", "Virginia", "VA"],
"24504": ["Lynchburg", "Virginia", "VA"],
"24515": ["Lynchburg", "Virginia", "VA"],
"24517": ["Altavista", "Virginia", "VA"],
"24520": ["Alton", "Virginia", "VA"],
"24521": ["Amherst", "Virginia", "VA"],
"24522": ["Appomattox", "Virginia", "VA"],
"24523": ["Bedford", "Virginia", "VA"],
"24526": ["Big Island", "Virginia", "VA"],
"24527": ["Blairs", "Virginia", "VA"],
"24528": ["Brookneal", "Virginia", "VA"],
"24529": ["Buffalo Junction", "Virginia", "VA"],
"24530": ["Callands", "Virginia", "VA"],
"24531": ["Chatham", "Virginia", "VA"],
"24534": ["Clover", "Virginia", "VA"],
"24536": ["Coleman Falls", "Virginia", "VA"],
"24538": ["Concord", "Virginia", "VA"],
"24539": ["Crystal Hill", "Virginia", "VA"],
"24540": ["Danville", "Virginia", "VA"],
"24541": ["Danville", "Virginia", "VA"],
"24549": ["Dry Fork", "Virginia", "VA"],
"24550": ["Evington", "Virginia", "VA"],
"24551": ["Forest", "Virginia", "VA"],
"24553": ["Gladstone", "Virginia", "VA"],
"24554": ["Gladys", "Virginia", "VA"],
"24555": ["Glasgow", "Virginia", "VA"],
"24556": ["Goode", "Virginia", "VA"],
"24557": ["Gretna", "Virginia", "VA"],
"24558": ["Halifax", "Virginia", "VA"],
"24562": ["Howardsville", "Virginia", "VA"],
"24563": ["Hurt", "Virginia", "VA"],
"24565": ["Java", "Virginia", "VA"],
"24566": ["Keeling", "Virginia", "VA"],
"24569": ["Long Island", "Virginia", "VA"],
"24570": ["Lowry", "Virginia", "VA"],
"24571": ["Lynch Station", "Virginia", "VA"],
"24572": ["Madison Heights", "Virginia", "VA"],
"24574": ["Monroe", "Virginia", "VA"],
"24577": ["Nathalie", "Virginia", "VA"],
"24578": ["Natural Bridge", "Virginia", "VA"],
"24579": ["Natural Bridge Station", "Virginia", "VA"],
"24580": ["Nelson", "Virginia", "VA"],
"24581": ["Norwood", "Virginia", "VA"],
"24586": ["Ringgold", "Virginia", "VA"],
"24588": ["Rustburg", "Virginia", "VA"],
"24589": ["Scottsburg", "Virginia", "VA"],
"24590": ["Scottsville", "Virginia", "VA"],
"24592": ["South Boston", "Virginia", "VA"],
"24593": ["Spout Spring", "Virginia", "VA"],
"24594": ["Sutherlin", "Virginia", "VA"],
"24595": ["Sweet Briar", "Virginia", "VA"],
"24597": ["Vernon Hill", "Virginia", "VA"],
"24598": ["Virgilina", "Virginia", "VA"],
"24599": ["Wingina", "Virginia", "VA"],
"24601": ["Amonate", "Virginia", "VA"],
"24602": ["Bandy", "Virginia", "VA"],
"24603": ["Big Rock", "Virginia", "VA"],
"24604": ["Bishop", "Virginia", "VA"],
"24605": ["Bluefield", "Virginia", "VA"],
"24606": ["Boissevain", "Virginia", "VA"],
"24607": ["Breaks", "Virginia", "VA"],
"24609": ["Cedar Bluff", "Virginia", "VA"],
"24612": ["Doran", "Virginia", "VA"],
"24613": ["Falls Mills", "Virginia", "VA"],
"24614": ["Grundy", "Virginia", "VA"],
"24620": ["Hurley", "Virginia", "VA"],
"24622": ["Jewell Ridge", "Virginia", "VA"],
"24630": ["North Tazewell", "Virginia", "VA"],
"24631": ["Oakwood", "Virginia", "VA"],
"24634": ["Pilgrims Knob", "Virginia", "VA"],
"24635": ["Pocahontas", "Virginia", "VA"],
"24637": ["Pounding Mill", "Virginia", "VA"],
"24639": ["Raven", "Virginia", "VA"],
"24641": ["Richlands", "Virginia", "VA"],
"24646": ["Rowe", "Virginia", "VA"],
"24649": ["Swords Creek", "Virginia", "VA"],
"24651": ["Tazewell", "Virginia", "VA"],
"24656": ["Vansant", "Virginia", "VA"],
"24657": ["Whitewood", "Virginia", "VA"],
"24701": ["Bluefield", "West Virginia", "WV"],
"24712": ["Athens", "West Virginia", "WV"],
"24714": ["Beeson", "West Virginia", "WV"],
"24715": ["Bramwell", "West Virginia", "WV"],
"24716": ["Bud", "West Virginia", "WV"],
"24719": ["Covel", "West Virginia", "WV"],
"24724": ["Freeman", "West Virginia", "WV"],
"24726": ["Herndon", "West Virginia", "WV"],
"24729": ["Hiawatha", "West Virginia", "WV"],
"24731": ["Kegley", "West Virginia", "WV"],
"24733": ["Lashmeet", "West Virginia", "WV"],
"24736": ["Matoaka", "West Virginia", "WV"],
"24737": ["Montcalm", "West Virginia", "WV"],
"24738": ["Nemours", "West Virginia", "WV"],
"24739": ["Princeton", "West Virginia", "WV"],
"24740": ["Princeton", "West Virginia", "WV"],
"24747": ["Rock", "West Virginia", "WV"],
"24801": ["Welch", "West Virginia", "WV"],
"24808": ["Anawalt", "West Virginia", "WV"],
"24811": ["Avondale", "West Virginia", "WV"],
"24813": ["Bartley", "West Virginia", "WV"],
"24815": ["Berwind", "West Virginia", "WV"],
"24816": ["Big Sandy", "West Virginia", "WV"],
"24817": ["Bradshaw", "West Virginia", "WV"],
"24818": ["Brenton", "West Virginia", "WV"],
"24822": ["Clear Fork", "West Virginia", "WV"],
"24823": ["Coal Mountain", "West Virginia", "WV"],
"24826": ["Cucumber", "West Virginia", "WV"],
"24827": ["Cyclone", "West Virginia", "WV"],
"24828": ["Davy", "West Virginia", "WV"],
"24830": ["Elbert", "West Virginia", "WV"],
"24831": ["Elkhorn", "West Virginia", "WV"],
"24834": ["Fanrock", "West Virginia", "WV"],
"24836": ["Gary", "West Virginia", "WV"],
"24839": ["Hanover", "West Virginia", "WV"],
"24843": ["Hensley", "West Virginia", "WV"],
"24844": ["Iaeger", "West Virginia", "WV"],
"24845": ["Ikes Fork", "West Virginia", "WV"],
"24846": ["Isaban", "West Virginia", "WV"],
"24847": ["Itmann", "West Virginia", "WV"],
"24848": ["Jenkinjones", "West Virginia", "WV"],
"24849": ["Jesse", "West Virginia", "WV"],
"24850": ["Jolo", "West Virginia", "WV"],
"24851": ["Justice", "West Virginia", "WV"],
"24853": ["Kimball", "West Virginia", "WV"],
"24854": ["Kopperston", "West Virginia", "WV"],
"24855": ["Kyle", "West Virginia", "WV"],
"24857": ["Lynco", "West Virginia", "WV"],
"24860": ["Matheny", "West Virginia", "WV"],
"24861": ["Maybeury", "West Virginia", "WV"],
"24862": ["Mohawk", "West Virginia", "WV"],
"24866": ["Newhall", "West Virginia", "WV"],
"24867": ["New Richmond", "West Virginia", "WV"],
"24868": ["Northfork", "West Virginia", "WV"],
"24869": ["North Spring", "West Virginia", "WV"],
"24870": ["Oceana", "West Virginia", "WV"],
"24871": ["Pageton", "West Virginia", "WV"],
"24872": ["Panther", "West Virginia", "WV"],
"24873": ["Paynesville", "West Virginia", "WV"],
"24874": ["Pineville", "West Virginia", "WV"],
"24878": ["Premier", "West Virginia", "WV"],
"24879": ["Raysal", "West Virginia", "WV"],
"24880": ["Rock View", "West Virginia", "WV"],
"24881": ["Roderfield", "West Virginia", "WV"],
"24882": ["Simon", "West Virginia", "WV"],
"24884": ["Squire", "West Virginia", "WV"],
"24887": ["Switchback", "West Virginia", "WV"],
"24888": ["Thorpe", "West Virginia", "WV"],
"24892": ["War", "West Virginia", "WV"],
"24894": ["Warriormine", "West Virginia", "WV"],
"24895": ["Wilcoe", "West Virginia", "WV"],
"24898": ["Wyoming", "West Virginia", "WV"],
"24901": ["Lewisburg", "West Virginia", "WV"],
"24910": ["Alderson", "West Virginia", "WV"],
"24915": ["Arbovale", "West Virginia", "WV"],
"24916": ["Asbury", "West Virginia", "WV"],
"24918": ["Ballard", "West Virginia", "WV"],
"24920": ["Bartow", "West Virginia", "WV"],
"24924": ["Buckeye", "West Virginia", "WV"],
"24925": ["Caldwell", "West Virginia", "WV"],
"24927": ["Cass", "West Virginia", "WV"],
"24931": ["Crawley", "West Virginia", "WV"],
"24934": ["Dunmore", "West Virginia", "WV"],
"24935": ["Forest Hill", "West Virginia", "WV"],
"24938": ["Frankford", "West Virginia", "WV"],
"24941": ["Gap Mills", "West Virginia", "WV"],
"24943": ["Grassy Meadows", "West Virginia", "WV"],
"24944": ["Green Bank", "West Virginia", "WV"],
"24945": ["Greenville", "West Virginia", "WV"],
"24946": ["Hillsboro", "West Virginia", "WV"],
"24951": ["Lindside", "West Virginia", "WV"],
"24954": ["Marlinton", "West Virginia", "WV"],
"24957": ["Maxwelton", "West Virginia", "WV"],
"24962": ["Pence Springs", "West Virginia", "WV"],
"24963": ["Peterstown", "West Virginia", "WV"],
"24966": ["Renick", "West Virginia", "WV"],
"24970": ["Ronceverte", "West Virginia", "WV"],
"24974": ["Secondcreek", "West Virginia", "WV"],
"24976": ["Sinks Grove", "West Virginia", "WV"],
"24977": ["Smoot", "West Virginia", "WV"],
"24981": ["Talcott", "West Virginia", "WV"],
"24983": ["Union", "West Virginia", "WV"],
"24984": ["Waiteville", "West Virginia", "WV"],
"24985": ["Wayside", "West Virginia", "WV"],
"24986": ["White Sulphur Springs", "West Virginia", "WV"],
"24991": ["Williamsburg", "West Virginia", "WV"],
"24993": ["Wolfcreek", "West Virginia", "WV"],
"25002": ["Alloy", "West Virginia", "WV"],
"25003": ["Alum Creek", "West Virginia", "WV"],
"25005": ["Amma", "West Virginia", "WV"],
"25007": ["Arnett", "West Virginia", "WV"],
"25008": ["Artie", "West Virginia", "WV"],
"25009": ["Ashford", "West Virginia", "WV"],
"25011": ["Bancroft", "West Virginia", "WV"],
"25015": ["Belle", "West Virginia", "WV"],
"25019": ["Bickmore", "West Virginia", "WV"],
"25021": ["Bim", "West Virginia", "WV"],
"25022": ["Blair", "West Virginia", "WV"],
"25024": ["Bloomingrose", "West Virginia", "WV"],
"25025": ["Blount", "West Virginia", "WV"],
"25028": ["Bob White", "West Virginia", "WV"],
"25030": ["Bomont", "West Virginia", "WV"],
"25031": ["Boomer", "West Virginia", "WV"],
"25033": ["Buffalo", "West Virginia", "WV"],
"25035": ["Cabin Creek", "West Virginia", "WV"],
"25036": ["Cannelton", "West Virginia", "WV"],
"25039": ["Cedar Grove", "West Virginia", "WV"],
"25040": ["Charlton Heights", "West Virginia", "WV"],
"25043": ["Clay", "West Virginia", "WV"],
"25044": ["Clear Creek", "West Virginia", "WV"],
"25045": ["Clendenin", "West Virginia", "WV"],
"25047": ["Clothier", "West Virginia", "WV"],
"25048": ["Colcord", "West Virginia", "WV"],
"25049": ["Comfort", "West Virginia", "WV"],
"25051": ["Costa", "West Virginia", "WV"],
"25053": ["Danville", "West Virginia", "WV"],
"25054": ["Dawes", "West Virginia", "WV"],
"25057": ["Deep Water", "West Virginia", "WV"],
"25059": ["Dixie", "West Virginia", "WV"],
"25060": ["Dorothy", "West Virginia", "WV"],
"25061": ["Drybranch", "West Virginia", "WV"],
"25062": ["Dry Creek", "West Virginia", "WV"],
"25063": ["Duck", "West Virginia", "WV"],
"25064": ["Dunbar", "West Virginia", "WV"],
"25067": ["East Bank", "West Virginia", "WV"],
"25070": ["Eleanor", "West Virginia", "WV"],
"25071": ["Elkview", "West Virginia", "WV"],
"25075": ["Eskdale", "West Virginia", "WV"],
"25076": ["Ethel", "West Virginia", "WV"],
"25081": ["Foster", "West Virginia", "WV"],
"25082": ["Fraziers Bottom", "West Virginia", "WV"],
"25083": ["Gallagher", "West Virginia", "WV"],
"25085": ["Gauley Bridge", "West Virginia", "WV"],
"25086": ["Glasgow", "West Virginia", "WV"],
"25088": ["Glen", "West Virginia", "WV"],
"25090": ["Glen Ferris", "West Virginia", "WV"],
"25093": ["Gordon", "West Virginia", "WV"],
"25102": ["Handley", "West Virginia", "WV"],
"25103": ["Hansford", "West Virginia", "WV"],
"25106": ["Henderson", "West Virginia", "WV"],
"25107": ["Hernshaw", "West Virginia", "WV"],
"25108": ["Hewett", "West Virginia", "WV"],
"25109": ["Hometown", "West Virginia", "WV"],
"25110": ["Hugheston", "West Virginia", "WV"],
"25111": ["Indore", "West Virginia", "WV"],
"25112": ["Institute", "West Virginia", "WV"],
"25113": ["Ivydale", "West Virginia", "WV"],
"25114": ["Jeffrey", "West Virginia", "WV"],
"25115": ["Kanawha Falls", "West Virginia", "WV"],
"25118": ["Kimberly", "West Virginia", "WV"],
"25119": ["Kincaid", "West Virginia", "WV"],
"25121": ["Lake", "West Virginia", "WV"],
"25123": ["Leon", "West Virginia", "WV"],
"25124": ["Liberty", "West Virginia", "WV"],
"25125": ["Lizemores", "West Virginia", "WV"],
"25126": ["London", "West Virginia", "WV"],
"25130": ["Madison", "West Virginia", "WV"],
"25132": ["Mammoth", "West Virginia", "WV"],
"25133": ["Maysel", "West Virginia", "WV"],
"25134": ["Miami", "West Virginia", "WV"],
"25136": ["Montgomery", "West Virginia", "WV"],
"25139": ["Mount Carbon", "West Virginia", "WV"],
"25140": ["Naoma", "West Virginia", "WV"],
"25141": ["Nebo", "West Virginia", "WV"],
"25142": ["Nellis", "West Virginia", "WV"],
"25143": ["Nitro", "West Virginia", "WV"],
"25148": ["Orgas", "West Virginia", "WV"],
"25149": ["Ottawa", "West Virginia", "WV"],
"25152": ["Page", "West Virginia", "WV"],
"25154": ["Peytona", "West Virginia", "WV"],
"25156": ["Pinch", "West Virginia", "WV"],
"25159": ["Poca", "West Virginia", "WV"],
"25160": ["Pond Gap", "West Virginia", "WV"],
"25161": ["Powellton", "West Virginia", "WV"],
"25162": ["Pratt", "West Virginia", "WV"],
"25164": ["Procious", "West Virginia", "WV"],
"25165": ["Racine", "West Virginia", "WV"],
"25168": ["Red House", "West Virginia", "WV"],
"25169": ["Ridgeview", "West Virginia", "WV"],
"25173": ["Robson", "West Virginia", "WV"],
"25174": ["Rock Creek", "West Virginia", "WV"],
"25177": ["Saint Albans", "West Virginia", "WV"],
"25180": ["Saxon", "West Virginia", "WV"],
"25181": ["Seth", "West Virginia", "WV"],
"25183": ["Sharples", "West Virginia", "WV"],
"25185": ["Mount Olive", "West Virginia", "WV"],
"25186": ["Smithers", "West Virginia", "WV"],
"25187": ["Southside", "West Virginia", "WV"],
"25193": ["Sylvester", "West Virginia", "WV"],
"25201": ["Tad", "West Virginia", "WV"],
"25202": ["Tornado", "West Virginia", "WV"],
"25203": ["Turtle Creek", "West Virginia", "WV"],
"25204": ["Twilight", "West Virginia", "WV"],
"25205": ["Uneeda", "West Virginia", "WV"],
"25206": ["Van", "West Virginia", "WV"],
"25208": ["Wharton", "West Virginia", "WV"],
"25209": ["Whitesville", "West Virginia", "WV"],
"25211": ["Widen", "West Virginia", "WV"],
"25213": ["Winfield", "West Virginia", "WV"],
"25214": ["Winifrede", "West Virginia", "WV"],
"25231": ["Advent", "West Virginia", "WV"],
"25234": ["Arnoldsburg", "West Virginia", "WV"],
"25235": ["Chloe", "West Virginia", "WV"],
"25239": ["Cottageville", "West Virginia", "WV"],
"25241": ["Evans", "West Virginia", "WV"],
"25243": ["Gandeeville", "West Virginia", "WV"],
"25244": ["Gay", "West Virginia", "WV"],
"25245": ["Given", "West Virginia", "WV"],
"25247": ["Hartford", "West Virginia", "WV"],
"25248": ["Kenna", "West Virginia", "WV"],
"25251": ["Left Hand", "West Virginia", "WV"],
"25252": ["Le Roy", "West Virginia", "WV"],
"25253": ["Letart", "West Virginia", "WV"],
"25259": ["Looneyville", "West Virginia", "WV"],
"25260": ["Mason", "West Virginia", "WV"],
"25261": ["Millstone", "West Virginia", "WV"],
"25262": ["Millwood", "West Virginia", "WV"],
"25264": ["Mount Alto", "West Virginia", "WV"],
"25265": ["New Haven", "West Virginia", "WV"],
"25266": ["Newton", "West Virginia", "WV"],
"25267": ["Normantown", "West Virginia", "WV"],
"25268": ["Orma", "West Virginia", "WV"],
"25270": ["Reedy", "West Virginia", "WV"],
"25271": ["Ripley", "West Virginia", "WV"],
"25275": ["Sandyville", "West Virginia", "WV"],
"25276": ["Spencer", "West Virginia", "WV"],
"25285": ["Wallback", "West Virginia", "WV"],
"25286": ["Walton", "West Virginia", "WV"],
"25287": ["West Columbia", "West Virginia", "WV"],
"25301": ["Charleston", "West Virginia", "WV"],
"25302": ["Charleston", "West Virginia", "WV"],
"25303": ["South Charleston", "West Virginia", "WV"],
"25304": ["Charleston", "West Virginia", "WV"],
"25305": ["Charleston", "West Virginia", "WV"],
"25306": ["Charleston", "West Virginia", "WV"],
"25309": ["South Charleston", "West Virginia", "WV"],
"25311": ["Charleston", "West Virginia", "WV"],
"25312": ["Charleston", "West Virginia", "WV"],
"25313": ["Charleston", "West Virginia", "WV"],
"25314": ["Charleston", "West Virginia", "WV"],
"25315": ["Charleston", "West Virginia", "WV"],
"25320": ["Charleston", "West Virginia", "WV"],
"25387": ["Charleston", "West Virginia", "WV"],
"25401": ["Martinsburg", "West Virginia", "WV"],
"25403": ["Martinsburg", "West Virginia", "WV"],
"25404": ["Martinsburg", "West Virginia", "WV"],
"25405": ["Martinsburg", "West Virginia", "WV"],
"25411": ["Berkeley Springs", "West Virginia", "WV"],
"25413": ["Bunker Hill", "West Virginia", "WV"],
"25414": ["Charles Town", "West Virginia", "WV"],
"25419": ["Falling Waters", "West Virginia", "WV"],
"25420": ["Gerrardstown", "West Virginia", "WV"],
"25421": ["Glengary", "West Virginia", "WV"],
"25422": ["Great Cacapon", "West Virginia", "WV"],
"25425": ["Harpers Ferry", "West Virginia", "WV"],
"25427": ["Hedgesville", "West Virginia", "WV"],
"25428": ["Inwood", "West Virginia", "WV"],
"25430": ["Kearneysville", "West Virginia", "WV"],
"25431": ["Levels", "West Virginia", "WV"],
"25432": ["Millville", "West Virginia", "WV"],
"25434": ["Paw Paw", "West Virginia", "WV"],
"25437": ["Points", "West Virginia", "WV"],
"25438": ["Ranson", "West Virginia", "WV"],
"25442": ["Shenandoah Junction", "West Virginia", "WV"],
"25443": ["Shepherdstown", "West Virginia", "WV"],
"25444": ["Slanesville", "West Virginia", "WV"],
"25446": ["Summit Point", "West Virginia", "WV"],
"25501": ["Alkol", "West Virginia", "WV"],
"25502": ["Apple Grove", "West Virginia", "WV"],
"25503": ["Ashton", "West Virginia", "WV"],
"25504": ["Barboursville", "West Virginia", "WV"],
"25505": ["Big Creek", "West Virginia", "WV"],
"25506": ["Branchland", "West Virginia", "WV"],
"25507": ["Ceredo", "West Virginia", "WV"],
"25508": ["Chapmanville", "West Virginia", "WV"],
"25510": ["Culloden", "West Virginia", "WV"],
"25511": ["Dunlow", "West Virginia", "WV"],
"25512": ["East Lynn", "West Virginia", "WV"],
"25514": ["Fort Gay", "West Virginia", "WV"],
"25515": ["Gallipolis Ferry", "West Virginia", "WV"],
"25517": ["Genoa", "West Virginia", "WV"],
"25520": ["Glenwood", "West Virginia", "WV"],
"25521": ["Griffithsville", "West Virginia", "WV"],
"25523": ["Hamlin", "West Virginia", "WV"],
"25524": ["Harts", "West Virginia", "WV"],
"25526": ["Hurricane", "West Virginia", "WV"],
"25529": ["Julian", "West Virginia", "WV"],
"25530": ["Kenova", "West Virginia", "WV"],
"25534": ["Kiahsville", "West Virginia", "WV"],
"25535": ["Lavalette", "West Virginia", "WV"],
"25537": ["Lesage", "West Virginia", "WV"],
"25540": ["Midkiff", "West Virginia", "WV"],
"25541": ["Milton", "West Virginia", "WV"],
"25544": ["Myra", "West Virginia", "WV"],
"25545": ["Ona", "West Virginia", "WV"],
"25547": ["Pecks Mill", "West Virginia", "WV"],
"25550": ["Point Pleasant", "West Virginia", "WV"],
"25555": ["Prichard", "West Virginia", "WV"],
"25557": ["Ranger", "West Virginia", "WV"],
"25559": ["Salt Rock", "West Virginia", "WV"],
"25560": ["Scott Depot", "West Virginia", "WV"],
"25564": ["Sod", "West Virginia", "WV"],
"25565": ["Spurlockville", "West Virginia", "WV"],
"25567": ["Sumerco", "West Virginia", "WV"],
"25570": ["Wayne", "West Virginia", "WV"],
"25571": ["West Hamlin", "West Virginia", "WV"],
"25573": ["Yawkey", "West Virginia", "WV"],
"25601": ["Logan", "West Virginia", "WV"],
"25606": ["Accoville", "West Virginia", "WV"],
"25607": ["Amherstdale", "West Virginia", "WV"],
"25608": ["Baisden", "West Virginia", "WV"],
"25611": ["Bruno", "West Virginia", "WV"],
"25612": ["Chauncey", "West Virginia", "WV"],
"25617": ["Davin", "West Virginia", "WV"],
"25621": ["Gilbert", "West Virginia", "WV"],
"25624": ["Henlawson", "West Virginia", "WV"],
"25625": ["Holden", "West Virginia", "WV"],
"25628": ["Kistler", "West Virginia", "WV"],
"25630": ["Lorado", "West Virginia", "WV"],
"25632": ["Lyburn", "West Virginia", "WV"],
"25634": ["Mallory", "West Virginia", "WV"],
"25635": ["Man", "West Virginia", "WV"],
"25637": ["Mount Gay", "West Virginia", "WV"],
"25638": ["Omar", "West Virginia", "WV"],
"25639": ["Peach Creek", "West Virginia", "WV"],
"25644": ["Sarah Ann", "West Virginia", "WV"],
"25646": ["Stollings", "West Virginia", "WV"],
"25647": ["Switzer", "West Virginia", "WV"],
"25649": ["Verdunville", "West Virginia", "WV"],
"25650": ["Verner", "West Virginia", "WV"],
"25651": ["Wharncliffe", "West Virginia", "WV"],
"25652": ["Whitman", "West Virginia", "WV"],
"25653": ["Wilkinson", "West Virginia", "WV"],
"25654": ["Yolyn", "West Virginia", "WV"],
"25661": ["Williamson", "West Virginia", "WV"],
"25666": ["Breeden", "West Virginia", "WV"],
"25667": ["Chattaroy", "West Virginia", "WV"],
"25669": ["Crum", "West Virginia", "WV"],
"25670": ["Delbarton", "West Virginia", "WV"],
"25671": ["Dingess", "West Virginia", "WV"],
"25672": ["Edgarton", "West Virginia", "WV"],
"25674": ["Kermit", "West Virginia", "WV"],
"25676": ["Lenore", "West Virginia", "WV"],
"25678": ["Matewan", "West Virginia", "WV"],
"25685": ["Naugatuck", "West Virginia", "WV"],
"25688": ["North Matewan", "West Virginia", "WV"],
"25690": ["Ragland", "West Virginia", "WV"],
"25692": ["Red Jacket", "West Virginia", "WV"],
"25696": ["Varney", "West Virginia", "WV"],
"25699": ["Wilsondale", "West Virginia", "WV"],
"25701": ["Huntington", "West Virginia", "WV"],
"25702": ["Huntington", "West Virginia", "WV"],
"25703": ["Huntington", "West Virginia", "WV"],
"25704": ["Huntington", "West Virginia", "WV"],
"25705": ["Huntington", "West Virginia", "WV"],
"25755": ["Huntington", "West Virginia", "WV"],
"25801": ["Beckley", "West Virginia", "WV"],
"25810": ["Allen Junction", "West Virginia", "WV"],
"25811": ["Amigo", "West Virginia", "WV"],
"25812": ["Ansted", "West Virginia", "WV"],
"25813": ["Beaver", "West Virginia", "WV"],
"25817": ["Bolt", "West Virginia", "WV"],
"25818": ["Bradley", "West Virginia", "WV"],
"25820": ["Camp Creek", "West Virginia", "WV"],
"25823": ["Coal City", "West Virginia", "WV"],
"25825": ["Cool Ridge", "West Virginia", "WV"],
"25826": ["Corinne", "West Virginia", "WV"],
"25827": ["Crab Orchard", "West Virginia", "WV"],
"25831": ["Danese", "West Virginia", "WV"],
"25832": ["Daniels", "West Virginia", "WV"],
"25836": ["Eccles", "West Virginia", "WV"],
"25837": ["Edmond", "West Virginia", "WV"],
"25839": ["Fairdale", "West Virginia", "WV"],
"25840": ["Fayetteville", "West Virginia", "WV"],
"25841": ["Flat Top", "West Virginia", "WV"],
"25843": ["Ghent", "West Virginia", "WV"],
"25844": ["Glen Daniel", "West Virginia", "WV"],
"25845": ["Glen Fork", "West Virginia", "WV"],
"25846": ["Glen Jean", "West Virginia", "WV"],
"25848": ["Glen Rogers", "West Virginia", "WV"],
"25849": ["Glen White", "West Virginia", "WV"],
"25851": ["Harper", "West Virginia", "WV"],
"25853": ["Helen", "West Virginia", "WV"],
"25854": ["Hico", "West Virginia", "WV"],
"25855": ["Hilltop", "West Virginia", "WV"],
"25857": ["Josephine", "West Virginia", "WV"],
"25862": ["Lansing", "West Virginia", "WV"],
"25864": ["Layland", "West Virginia", "WV"],
"25865": ["Lester", "West Virginia", "WV"],
"25866": ["Lochgelly", "West Virginia", "WV"],
"25868": ["Lookout", "West Virginia", "WV"],
"25870": ["Maben", "West Virginia", "WV"],
"25871": ["Mabscott", "West Virginia", "WV"],
"25873": ["MacArthur", "West Virginia", "WV"],
"25875": ["McGraws", "West Virginia", "WV"],
"25876": ["Saulsville", "West Virginia", "WV"],
"25878": ["Midway", "West Virginia", "WV"],
"25879": ["Minden", "West Virginia", "WV"],
"25880": ["Mount Hope", "West Virginia", "WV"],
"25882": ["Mullens", "West Virginia", "WV"],
"25901": ["Oak Hill", "West Virginia", "WV"],
"25902": ["Odd", "West Virginia", "WV"],
"25904": ["Pax", "West Virginia", "WV"],
"25907": ["Prince", "West Virginia", "WV"],
"25908": ["Princewick", "West Virginia", "WV"],
"25909": ["Prosperity", "West Virginia", "WV"],
"25911": ["Raleigh", "West Virginia", "WV"],
"25913": ["Ravencliff", "West Virginia", "WV"],
"25915": ["Rhodell", "West Virginia", "WV"],
"25916": ["Sabine", "West Virginia", "WV"],
"25917": ["Scarbro", "West Virginia", "WV"],
"25918": ["Shady Spring", "West Virginia", "WV"],
"25920": ["Slab Fork", "West Virginia", "WV"],
"25921": ["Sophia", "West Virginia", "WV"],
"25922": ["Spanishburg", "West Virginia", "WV"],
"25928": ["Stephenson", "West Virginia", "WV"],
"25932": ["Surveyor", "West Virginia", "WV"],
"25936": ["Thurmond", "West Virginia", "WV"],
"25938": ["Victor", "West Virginia", "WV"],
"25942": ["Winona", "West Virginia", "WV"],
"25943": ["Wyco", "West Virginia", "WV"],
"25951": ["Hinton", "West Virginia", "WV"],
"25958": ["Charmco", "West Virginia", "WV"],
"25962": ["Rainelle", "West Virginia", "WV"],
"25966": ["Green Sulphur Springs", "West Virginia", "WV"],
"25969": ["Jumping Branch", "West Virginia", "WV"],
"25971": ["Lerona", "West Virginia", "WV"],
"25972": ["Leslie", "West Virginia", "WV"],
"25976": ["Meadow Bridge", "West Virginia", "WV"],
"25977": ["Meadow Creek", "West Virginia", "WV"],
"25978": ["Nimitz", "West Virginia", "WV"],
"25979": ["Pipestem", "West Virginia", "WV"],
"25981": ["Quinwood", "West Virginia", "WV"],
"25984": ["Rupert", "West Virginia", "WV"],
"25985": ["Sandstone", "West Virginia", "WV"],
"25986": ["Spring Dale", "West Virginia", "WV"],
"25989": ["White Oak", "West Virginia", "WV"],
"26003": ["Wheeling", "West Virginia", "WV"],
"26030": ["Beech Bottom", "West Virginia", "WV"],
"26031": ["Benwood", "West Virginia", "WV"],
"26032": ["Bethany", "West Virginia", "WV"],
"26033": ["Cameron", "West Virginia", "WV"],
"26034": ["Chester", "West Virginia", "WV"],
"26035": ["Colliers", "West Virginia", "WV"],
"26036": ["Dallas", "West Virginia", "WV"],
"26037": ["Follansbee", "West Virginia", "WV"],
"26038": ["Glen Dale", "West Virginia", "WV"],
"26039": ["Glen Easton", "West Virginia", "WV"],
"26040": ["Mcmechen", "West Virginia", "WV"],
"26041": ["Moundsville", "West Virginia", "WV"],
"26047": ["New Cumberland", "West Virginia", "WV"],
"26050": ["Newell", "West Virginia", "WV"],
"26055": ["Proctor", "West Virginia", "WV"],
"26056": ["New Manchester", "West Virginia", "WV"],
"26058": ["Short Creek", "West Virginia", "WV"],
"26059": ["Triadelphia", "West Virginia", "WV"],
"26060": ["Valley Grove", "West Virginia", "WV"],
"26062": ["Weirton", "West Virginia", "WV"],
"26070": ["Wellsburg", "West Virginia", "WV"],
"26074": ["West Liberty", "West Virginia", "WV"],
"26075": ["Windsor Heights", "West Virginia", "WV"],
"26101": ["Parkersburg", "West Virginia", "WV"],
"26104": ["Parkersburg", "West Virginia", "WV"],
"26105": ["Vienna", "West Virginia", "WV"],
"26133": ["Belleville", "West Virginia", "WV"],
"26134": ["Belmont", "West Virginia", "WV"],
"26136": ["Big Bend", "West Virginia", "WV"],
"26137": ["Big Springs", "West Virginia", "WV"],
"26138": ["Brohard", "West Virginia", "WV"],
"26141": ["Creston", "West Virginia", "WV"],
"26142": ["Davisville", "West Virginia", "WV"],
"26143": ["Elizabeth", "West Virginia", "WV"],
"26146": ["Friendly", "West Virginia", "WV"],
"26147": ["Grantsville", "West Virginia", "WV"],
"26148": ["Macfarlan", "West Virginia", "WV"],
"26149": ["Middlebourne", "West Virginia", "WV"],
"26150": ["Mineral Wells", "West Virginia", "WV"],
"26151": ["Mount Zion", "West Virginia", "WV"],
"26152": ["Munday", "West Virginia", "WV"],
"26155": ["New Martinsville", "West Virginia", "WV"],
"26159": ["Paden City", "West Virginia", "WV"],
"26160": ["Palestine", "West Virginia", "WV"],
"26161": ["Petroleum", "West Virginia", "WV"],
"26162": ["Porters Falls", "West Virginia", "WV"],
"26164": ["Ravenswood", "West Virginia", "WV"],
"26167": ["Reader", "West Virginia", "WV"],
"26169": ["Rockport", "West Virginia", "WV"],
"26170": ["Saint Marys", "West Virginia", "WV"],
"26175": ["Sistersville", "West Virginia", "WV"],
"26178": ["Smithville", "West Virginia", "WV"],
"26180": ["Walker", "West Virginia", "WV"],
"26181": ["Washington", "West Virginia", "WV"],
"26184": ["Waverly", "West Virginia", "WV"],
"26187": ["Williamstown", "West Virginia", "WV"],
"26201": ["Buckhannon", "West Virginia", "WV"],
"26202": ["Fenwick", "West Virginia", "WV"],
"26203": ["Erbacon", "West Virginia", "WV"],
"26205": ["Craigsville", "West Virginia", "WV"],
"26206": ["Cowen", "West Virginia", "WV"],
"26208": ["Camden On Gauley", "West Virginia", "WV"],
"26209": ["Snowshoe", "West Virginia", "WV"],
"26210": ["Adrian", "West Virginia", "WV"],
"26215": ["Cleveland", "West Virginia", "WV"],
"26217": ["Diana", "West Virginia", "WV"],
"26218": ["French Creek", "West Virginia", "WV"],
"26222": ["Hacker Valley", "West Virginia", "WV"],
"26224": ["Helvetia", "West Virginia", "WV"],
"26228": ["Kanawha Head", "West Virginia", "WV"],
"26230": ["Pickens", "West Virginia", "WV"],
"26234": ["Rock Cave", "West Virginia", "WV"],
"26236": ["Selbyville", "West Virginia", "WV"],
"26237": ["Tallmansville", "West Virginia", "WV"],
"26238": ["Volga", "West Virginia", "WV"],
"26241": ["Elkins", "West Virginia", "WV"],
"26250": ["Belington", "West Virginia", "WV"],
"26253": ["Beverly", "West Virginia", "WV"],
"26254": ["Bowden", "West Virginia", "WV"],
"26257": ["Coalton", "West Virginia", "WV"],
"26259": ["Dailey", "West Virginia", "WV"],
"26260": ["Davis", "West Virginia", "WV"],
"26261": ["Richwood", "West Virginia", "WV"],
"26263": ["Dryfork", "West Virginia", "WV"],
"26264": ["Durbin", "West Virginia", "WV"],
"26266": ["Upperglade", "West Virginia", "WV"],
"26267": ["Ellamore", "West Virginia", "WV"],
"26268": ["Glady", "West Virginia", "WV"],
"26269": ["Hambleton", "West Virginia", "WV"],
"26270": ["Harman", "West Virginia", "WV"],
"26271": ["Hendricks", "West Virginia", "WV"],
"26273": ["Huttonsville", "West Virginia", "WV"],
"26275": ["Junior", "West Virginia", "WV"],
"26276": ["Kerens", "West Virginia", "WV"],
"26278": ["Mabie", "West Virginia", "WV"],
"26280": ["Mill Creek", "West Virginia", "WV"],
"26282": ["Monterville", "West Virginia", "WV"],
"26283": ["Montrose", "West Virginia", "WV"],
"26285": ["Norton", "West Virginia", "WV"],
"26287": ["Parsons", "West Virginia", "WV"],
"26288": ["Webster Springs", "West Virginia", "WV"],
"26289": ["Red Creek", "West Virginia", "WV"],
"26291": ["Slatyfork", "West Virginia", "WV"],
"26292": ["Thomas", "West Virginia", "WV"],
"26293": ["Valley Bend", "West Virginia", "WV"],
"26294": ["Valley Head", "West Virginia", "WV"],
"26296": ["Whitmer", "West Virginia", "WV"],
"26298": ["Bergoo", "West Virginia", "WV"],
"26301": ["Clarksburg", "West Virginia", "WV"],
"26320": ["Alma", "West Virginia", "WV"],
"26321": ["Alum Bridge", "West Virginia", "WV"],
"26323": ["Anmoore", "West Virginia", "WV"],
"26325": ["Auburn", "West Virginia", "WV"],
"26327": ["Berea", "West Virginia", "WV"],
"26330": ["Bridgeport", "West Virginia", "WV"],
"26335": ["Burnsville", "West Virginia", "WV"],
"26337": ["Cairo", "West Virginia", "WV"],
"26338": ["Camden", "West Virginia", "WV"],
"26339": ["Center Point", "West Virginia", "WV"],
"26342": ["Coxs Mills", "West Virginia", "WV"],
"26343": ["Crawford", "West Virginia", "WV"],
"26346": ["Ellenboro", "West Virginia", "WV"],
"26347": ["Flemington", "West Virginia", "WV"],
"26348": ["Folsom", "West Virginia", "WV"],
"26349": ["Galloway", "West Virginia", "WV"],
"26351": ["Glenville", "West Virginia", "WV"],
"26354": ["Grafton", "West Virginia", "WV"],
"26361": ["Gypsy", "West Virginia", "WV"],
"26362": ["Harrisville", "West Virginia", "WV"],
"26366": ["Haywood", "West Virginia", "WV"],
"26369": ["Hepzibah", "West Virginia", "WV"],
"26372": ["Horner", "West Virginia", "WV"],
"26374": ["Independence", "West Virginia", "WV"],
"26376": ["Ireland", "West Virginia", "WV"],
"26377": ["Jacksonburg", "West Virginia", "WV"],
"26378": ["Jane Lew", "West Virginia", "WV"],
"26384": ["Linn", "West Virginia", "WV"],
"26385": ["Lost Creek", "West Virginia", "WV"],
"26386": ["Lumberport", "West Virginia", "WV"],
"26404": ["Meadowbrook", "West Virginia", "WV"],
"26405": ["Moatsville", "West Virginia", "WV"],
"26408": ["Mount Clare", "West Virginia", "WV"],
"26410": ["Newburg", "West Virginia", "WV"],
"26411": ["New Milton", "West Virginia", "WV"],
"26412": ["Orlando", "West Virginia", "WV"],
"26415": ["Pennsboro", "West Virginia", "WV"],
"26416": ["Philippi", "West Virginia", "WV"],
"26419": ["Pine Grove", "West Virginia", "WV"],
"26421": ["Pullman", "West Virginia", "WV"],
"26422": ["Reynoldsville", "West Virginia", "WV"],
"26424": ["Rosemont", "West Virginia", "WV"],
"26425": ["Rowlesburg", "West Virginia", "WV"],
"26426": ["Salem", "West Virginia", "WV"],
"26430": ["Sand Fork", "West Virginia", "WV"],
"26431": ["Shinnston", "West Virginia", "WV"],
"26435": ["Simpson", "West Virginia", "WV"],
"26436": ["Smithburg", "West Virginia", "WV"],
"26437": ["Smithfield", "West Virginia", "WV"],
"26438": ["Spelter", "West Virginia", "WV"],
"26440": ["Thornton", "West Virginia", "WV"],
"26443": ["Troy", "West Virginia", "WV"],
"26444": ["Tunnelton", "West Virginia", "WV"],
"26447": ["Walkersville", "West Virginia", "WV"],
"26448": ["Wallace", "West Virginia", "WV"],
"26451": ["West Milford", "West Virginia", "WV"],
"26452": ["Weston", "West Virginia", "WV"],
"26456": ["West Union", "West Virginia", "WV"],
"26463": ["Wyatt", "West Virginia", "WV"],
"26501": ["Morgantown", "West Virginia", "WV"],
"26505": ["Morgantown", "West Virginia", "WV"],
"26506": ["Morgantown", "West Virginia", "WV"],
"26508": ["Morgantown", "West Virginia", "WV"],
"26519": ["Albright", "West Virginia", "WV"],
"26520": ["Arthurdale", "West Virginia", "WV"],
"26521": ["Blacksville", "West Virginia", "WV"],
"26524": ["Bretz", "West Virginia", "WV"],
"26525": ["Bruceton Mills", "West Virginia", "WV"],
"26534": ["Granville", "West Virginia", "WV"],
"26537": ["Kingwood", "West Virginia", "WV"],
"26541": ["Maidsville", "West Virginia", "WV"],
"26542": ["Masontown", "West Virginia", "WV"],
"26543": ["Osage", "West Virginia", "WV"],
"26544": ["Pentress", "West Virginia", "WV"],
"26546": ["Pursglove", "West Virginia", "WV"],
"26547": ["Reedsville", "West Virginia", "WV"],
"26554": ["Fairmont", "West Virginia", "WV"],
"26559": ["Barrackville", "West Virginia", "WV"],
"26560": ["Baxter", "West Virginia", "WV"],
"26562": ["Burton", "West Virginia", "WV"],
"26563": ["Carolina", "West Virginia", "WV"],
"26568": ["Enterprise", "West Virginia", "WV"],
"26570": ["Fairview", "West Virginia", "WV"],
"26571": ["Farmington", "West Virginia", "WV"],
"26572": ["Four States", "West Virginia", "WV"],
"26574": ["Grant Town", "West Virginia", "WV"],
"26575": ["Hundred", "West Virginia", "WV"],
"26576": ["Idamay", "West Virginia", "WV"],
"26581": ["Littleton", "West Virginia", "WV"],
"26582": ["Mannington", "West Virginia", "WV"],
"26585": ["Metz", "West Virginia", "WV"],
"26586": ["Montana Mines", "West Virginia", "WV"],
"26587": ["Rachel", "West Virginia", "WV"],
"26588": ["Rivesville", "West Virginia", "WV"],
"26590": ["Wana", "West Virginia", "WV"],
"26591": ["Worthington", "West Virginia", "WV"],
"26601": ["Sutton", "West Virginia", "WV"],
"26610": ["Birch River", "West Virginia", "WV"],
"26611": ["Cedarville", "West Virginia", "WV"],
"26615": ["Copen", "West Virginia", "WV"],
"26617": ["Dille", "West Virginia", "WV"],
"26619": ["Exchange", "West Virginia", "WV"],
"26621": ["Flatwoods", "West Virginia", "WV"],
"26623": ["Frametown", "West Virginia", "WV"],
"26624": ["Gassaway", "West Virginia", "WV"],
"26627": ["Heaters", "West Virginia", "WV"],
"26629": ["Little Birch", "West Virginia", "WV"],
"26631": ["Napier", "West Virginia", "WV"],
"26636": ["Rosedale", "West Virginia", "WV"],
"26638": ["Shock", "West Virginia", "WV"],
"26651": ["Summersville", "West Virginia", "WV"],
"26656": ["Belva", "West Virginia", "WV"],
"26660": ["Calvin", "West Virginia", "WV"],
"26662": ["Canvas", "West Virginia", "WV"],
"26667": ["Drennen", "West Virginia", "WV"],
"26671": ["Gilboa", "West Virginia", "WV"],
"26676": ["Leivasy", "West Virginia", "WV"],
"26678": ["Mount Lookout", "West Virginia", "WV"],
"26679": ["Mount Nebo", "West Virginia", "WV"],
"26680": ["Nallen", "West Virginia", "WV"],
"26681": ["Nettie", "West Virginia", "WV"],
"26684": ["Pool", "West Virginia", "WV"],
"26690": ["Swiss", "West Virginia", "WV"],
"26691": ["Tioga", "West Virginia", "WV"],
"26704": ["Augusta", "West Virginia", "WV"],
"26705": ["Aurora", "West Virginia", "WV"],
"26707": ["Bayard", "West Virginia", "WV"],
"26710": ["Burlington", "West Virginia", "WV"],
"26711": ["Capon Bridge", "West Virginia", "WV"],
"26714": ["Delray", "West Virginia", "WV"],
"26716": ["Eglon", "West Virginia", "WV"],
"26717": ["Elk Garden", "West Virginia", "WV"],
"26719": ["Fort Ashby", "West Virginia", "WV"],
"26720": ["Gormania", "West Virginia", "WV"],
"26722": ["Green Spring", "West Virginia", "WV"],
"26726": ["Keyser", "West Virginia", "WV"],
"26731": ["Lahmansville", "West Virginia", "WV"],
"26739": ["Mount Storm", "West Virginia", "WV"],
"26743": ["New Creek", "West Virginia", "WV"],
"26750": ["Piedmont", "West Virginia", "WV"],
"26753": ["Ridgeley", "West Virginia", "WV"],
"26755": ["Rio", "West Virginia", "WV"],
"26757": ["Romney", "West Virginia", "WV"],
"26761": ["Shanks", "West Virginia", "WV"],
"26763": ["Springfield", "West Virginia", "WV"],
"26764": ["Terra Alta", "West Virginia", "WV"],
"26767": ["Wiley Ford", "West Virginia", "WV"],
"26801": ["Baker", "West Virginia", "WV"],
"26802": ["Brandywine", "West Virginia", "WV"],
"26804": ["Circleville", "West Virginia", "WV"],
"26807": ["Franklin", "West Virginia", "WV"],
"26808": ["High View", "West Virginia", "WV"],
"26810": ["Lost City", "West Virginia", "WV"],
"26812": ["Mathias", "West Virginia", "WV"],
"26814": ["Riverton", "West Virginia", "WV"],
"26815": ["Sugar Grove", "West Virginia", "WV"],
"26817": ["Bloomery", "West Virginia", "WV"],
"26818": ["Fisher", "West Virginia", "WV"],
"26823": ["Capon Springs", "West Virginia", "WV"],
"26833": ["Maysville", "West Virginia", "WV"],
"26836": ["Moorefield", "West Virginia", "WV"],
"26838": ["Milam", "West Virginia", "WV"],
"26845": ["Old Fields", "West Virginia", "WV"],
"26847": ["Petersburg", "West Virginia", "WV"],
"26851": ["Wardensville", "West Virginia", "WV"],
"26852": ["Purgitsville", "West Virginia", "WV"],
"26855": ["Cabins", "West Virginia", "WV"],
"26865": ["Yellow Spring", "West Virginia", "WV"],
"26866": ["Upper Tract", "West Virginia", "WV"],
"26884": ["Seneca Rocks", "West Virginia", "WV"],
"26886": ["Onego", "West Virginia", "WV"],
"27006": ["Advance", "North Carolina", "NC"],
"27007": ["Ararat", "North Carolina", "NC"],
"27009": ["Belews Creek", "North Carolina", "NC"],
"27011": ["Boonville", "North Carolina", "NC"],
"27012": ["Clemmons", "North Carolina", "NC"],
"27013": ["Cleveland", "North Carolina", "NC"],
"27014": ["Cooleemee", "North Carolina", "NC"],
"27016": ["Danbury", "North Carolina", "NC"],
"27017": ["Dobson", "North Carolina", "NC"],
"27018": ["East Bend", "North Carolina", "NC"],
"27019": ["Germanton", "North Carolina", "NC"],
"27020": ["Hamptonville", "North Carolina", "NC"],
"27021": ["King", "North Carolina", "NC"],
"27022": ["Lawsonville", "North Carolina", "NC"],
"27023": ["Lewisville", "North Carolina", "NC"],
"27024": ["Lowgap", "North Carolina", "NC"],
"27025": ["Madison", "North Carolina", "NC"],
"27027": ["Mayodan", "North Carolina", "NC"],
"27028": ["Mocksville", "North Carolina", "NC"],
"27030": ["Mount Airy", "North Carolina", "NC"],
"27040": ["Pfafftown", "North Carolina", "NC"],
"27041": ["Pilot Mountain", "North Carolina", "NC"],
"27042": ["Pine Hall", "North Carolina", "NC"],
"27043": ["Pinnacle", "North Carolina", "NC"],
"27045": ["Rural Hall", "North Carolina", "NC"],
"27046": ["Sandy Ridge", "North Carolina", "NC"],
"27047": ["Siloam", "North Carolina", "NC"],
"27048": ["Stoneville", "North Carolina", "NC"],
"27050": ["Tobaccoville", "North Carolina", "NC"],
"27051": ["Walkertown", "North Carolina", "NC"],
"27052": ["Walnut Cove", "North Carolina", "NC"],
"27053": ["Westfield", "North Carolina", "NC"],
"27054": ["Woodleaf", "North Carolina", "NC"],
"27055": ["Yadkinville", "North Carolina", "NC"],
"27101": ["Winston Salem", "North Carolina", "NC"],
"27103": ["Winston Salem", "North Carolina", "NC"],
"27104": ["Winston Salem", "North Carolina", "NC"],
"27105": ["Winston Salem", "North Carolina", "NC"],
"27106": ["Winston Salem", "North Carolina", "NC"],
"27107": ["Winston Salem", "North Carolina", "NC"],
"27109": ["Winston Salem", "North Carolina", "NC"],
"27110": ["Winston Salem", "North Carolina", "NC"],
"27127": ["Winston Salem", "North Carolina", "NC"],
"27201": ["Alamance", "North Carolina", "NC"],
"27202": ["Altamahaw", "North Carolina", "NC"],
"27203": ["Asheboro", "North Carolina", "NC"],
"27205": ["Asheboro", "North Carolina", "NC"],
"27207": ["Bear Creek", "North Carolina", "NC"],
"27208": ["Bennett", "North Carolina", "NC"],
"27209": ["Biscoe", "North Carolina", "NC"],
"27212": ["Blanch", "North Carolina", "NC"],
"27213": ["Bonlee", "North Carolina", "NC"],
"27214": ["Browns Summit", "North Carolina", "NC"],
"27215": ["Burlington", "North Carolina", "NC"],
"27217": ["Burlington", "North Carolina", "NC"],
"27229": ["Candor", "North Carolina", "NC"],
"27231": ["Cedar Grove", "North Carolina", "NC"],
"27233": ["Climax", "North Carolina", "NC"],
"27235": ["Colfax", "North Carolina", "NC"],
"27239": ["Denton", "North Carolina", "NC"],
"27242": ["Eagle Springs", "North Carolina", "NC"],
"27243": ["Efland", "North Carolina", "NC"],
"27244": ["Elon", "North Carolina", "NC"],
"27247": ["Ether", "North Carolina", "NC"],
"27248": ["Franklinville", "North Carolina", "NC"],
"27249": ["Gibsonville", "North Carolina", "NC"],
"27252": ["Goldston", "North Carolina", "NC"],
"27253": ["Graham", "North Carolina", "NC"],
"27256": ["Gulf", "North Carolina", "NC"],
"27258": ["Haw River", "North Carolina", "NC"],
"27259": ["Highfalls", "North Carolina", "NC"],
"27260": ["High Point", "North Carolina", "NC"],
"27262": ["High Point", "North Carolina", "NC"],
"27263": ["High Point", "North Carolina", "NC"],
"27265": ["High Point", "North Carolina", "NC"],
"27268": ["High Point", "North Carolina", "NC"],
"27278": ["Hillsborough", "North Carolina", "NC"],
"27281": ["Jackson Springs", "North Carolina", "NC"],
"27282": ["Jamestown", "North Carolina", "NC"],
"27283": ["Julian", "North Carolina", "NC"],
"27284": ["Kernersville", "North Carolina", "NC"],
"27288": ["Eden", "North Carolina", "NC"],
"27291": ["Leasburg", "North Carolina", "NC"],
"27292": ["Lexington", "North Carolina", "NC"],
"27295": ["Lexington", "North Carolina", "NC"],
"27298": ["Liberty", "North Carolina", "NC"],
"27299": ["Linwood", "North Carolina", "NC"],
"27301": ["McLeansville", "North Carolina", "NC"],
"27302": ["Mebane", "North Carolina", "NC"],
"27305": ["Milton", "North Carolina", "NC"],
"27306": ["Mount Gilead", "North Carolina", "NC"],
"27310": ["Oak Ridge", "North Carolina", "NC"],
"27311": ["Pelham", "North Carolina", "NC"],
"27312": ["Pittsboro", "North Carolina", "NC"],
"27313": ["Pleasant Garden", "North Carolina", "NC"],
"27314": ["Prospect Hill", "North Carolina", "NC"],
"27315": ["Providence", "North Carolina", "NC"],
"27316": ["Ramseur", "North Carolina", "NC"],
"27317": ["Randleman", "North Carolina", "NC"],
"27320": ["Reidsville", "North Carolina", "NC"],
"27325": ["Robbins", "North Carolina", "NC"],
"27326": ["Ruffin", "North Carolina", "NC"],
"27330": ["Sanford", "North Carolina", "NC"],
"27332": ["Sanford", "North Carolina", "NC"],
"27340": ["Saxapahaw", "North Carolina", "NC"],
"27341": ["Seagrove", "North Carolina", "NC"],
"27342": ["Sedalia", "North Carolina", "NC"],
"27343": ["Semora", "North Carolina", "NC"],
"27344": ["Siler City", "North Carolina", "NC"],
"27349": ["Snow Camp", "North Carolina", "NC"],
"27350": ["Sophia", "North Carolina", "NC"],
"27351": ["Southmont", "North Carolina", "NC"],
"27355": ["Staley", "North Carolina", "NC"],
"27356": ["Star", "North Carolina", "NC"],
"27357": ["Stokesdale", "North Carolina", "NC"],
"27358": ["Summerfield", "North Carolina", "NC"],
"27360": ["Thomasville", "North Carolina", "NC"],
"27370": ["Trinity", "North Carolina", "NC"],
"27371": ["Troy", "North Carolina", "NC"],
"27374": ["Welcome", "North Carolina", "NC"],
"27376": ["West End", "North Carolina", "NC"],
"27377": ["Whitsett", "North Carolina", "NC"],
"27379": ["Yanceyville", "North Carolina", "NC"],
"27401": ["Greensboro", "North Carolina", "NC"],
"27403": ["Greensboro", "North Carolina", "NC"],
"27405": ["Greensboro", "North Carolina", "NC"],
"27406": ["Greensboro", "North Carolina", "NC"],
"27407": ["Greensboro", "North Carolina", "NC"],
"27408": ["Greensboro", "North Carolina", "NC"],
"27409": ["Greensboro", "North Carolina", "NC"],
"27410": ["Greensboro", "North Carolina", "NC"],
"27411": ["Greensboro", "North Carolina", "NC"],
"27412": ["Greensboro", "North Carolina", "NC"],
"27455": ["Greensboro", "North Carolina", "NC"],
"27501": ["Angier", "North Carolina", "NC"],
"27502": ["Apex", "North Carolina", "NC"],
"27503": ["Bahama", "North Carolina", "NC"],
"27504": ["Benson", "North Carolina", "NC"],
"27505": ["Broadway", "North Carolina", "NC"],
"27506": ["Buies Creek", "North Carolina", "NC"],
"27507": ["Bullock", "North Carolina", "NC"],
"27508": ["Bunn", "North Carolina", "NC"],
"27509": ["Butner", "North Carolina", "NC"],
"27510": ["Carrboro", "North Carolina", "NC"],
"27511": ["Cary", "North Carolina", "NC"],
"27513": ["Cary", "North Carolina", "NC"],
"27514": ["Chapel Hill", "North Carolina", "NC"],
"27516": ["Chapel Hill", "North Carolina", "NC"],
"27517": ["Chapel Hill", "North Carolina", "NC"],
"27518": ["Cary", "North Carolina", "NC"],
"27519": ["Cary", "North Carolina", "NC"],
"27520": ["Clayton", "North Carolina", "NC"],
"27521": ["Coats", "North Carolina", "NC"],
"27522": ["Creedmoor", "North Carolina", "NC"],
"27523": ["Apex", "North Carolina", "NC"],
"27524": ["Four Oaks", "North Carolina", "NC"],
"27525": ["Franklinton", "North Carolina", "NC"],
"27526": ["Fuquay Varina", "North Carolina", "NC"],
"27527": ["Clayton", "North Carolina", "NC"],
"27529": ["Garner", "North Carolina", "NC"],
"27530": ["Goldsboro", "North Carolina", "NC"],
"27531": ["Goldsboro", "North Carolina", "NC"],
"27533": ["Goldsboro", "North Carolina", "NC"],
"27534": ["Goldsboro", "North Carolina", "NC"],
"27536": ["Henderson", "North Carolina", "NC"],
"27537": ["Henderson", "North Carolina", "NC"],
"27539": ["Apex", "North Carolina", "NC"],
"27540": ["Holly Springs", "North Carolina", "NC"],
"27541": ["Hurdle Mills", "North Carolina", "NC"],
"27542": ["Kenly", "North Carolina", "NC"],
"27544": ["Kittrell", "North Carolina", "NC"],
"27545": ["Knightdale", "North Carolina", "NC"],
"27546": ["Lillington", "North Carolina", "NC"],
"27549": ["Louisburg", "North Carolina", "NC"],
"27551": ["Macon", "North Carolina", "NC"],
"27553": ["Manson", "North Carolina", "NC"],
"27555": ["Micro", "North Carolina", "NC"],
"27556": ["Middleburg", "North Carolina", "NC"],
"27557": ["Middlesex", "North Carolina", "NC"],
"27559": ["Moncure", "North Carolina", "NC"],
"27560": ["Morrisville", "North Carolina", "NC"],
"27562": ["New Hill", "North Carolina", "NC"],
"27563": ["Norlina", "North Carolina", "NC"],
"27565": ["Oxford", "North Carolina", "NC"],
"27568": ["Pine Level", "North Carolina", "NC"],
"27569": ["Princeton", "North Carolina", "NC"],
"27570": ["Ridgeway", "North Carolina", "NC"],
"27571": ["Rolesville", "North Carolina", "NC"],
"27572": ["Rougemont", "North Carolina", "NC"],
"27573": ["Roxboro", "North Carolina", "NC"],
"27574": ["Roxboro", "North Carolina", "NC"],
"27576": ["Selma", "North Carolina", "NC"],
"27577": ["Smithfield", "North Carolina", "NC"],
"27581": ["Stem", "North Carolina", "NC"],
"27582": ["Stovall", "North Carolina", "NC"],
"27583": ["Timberlake", "North Carolina", "NC"],
"27584": ["Townsville", "North Carolina", "NC"],
"27587": ["Wake Forest", "North Carolina", "NC"],
"27589": ["Warrenton", "North Carolina", "NC"],
"27591": ["Wendell", "North Carolina", "NC"],
"27592": ["Willow Spring", "North Carolina", "NC"],
"27593": ["Wilsons Mills", "North Carolina", "NC"],
"27594": ["Wise", "North Carolina", "NC"],
"27596": ["Youngsville", "North Carolina", "NC"],
"27597": ["Zebulon", "North Carolina", "NC"],
"27599": ["Chapel Hill", "North Carolina", "NC"],
"27601": ["Raleigh", "North Carolina", "NC"],
"27603": ["Raleigh", "North Carolina", "NC"],
"27604": ["Raleigh", "North Carolina", "NC"],
"27605": ["Raleigh", "North Carolina", "NC"],
"27606": ["Raleigh", "North Carolina", "NC"],
"27607": ["Raleigh", "North Carolina", "NC"],
"27608": ["Raleigh", "North Carolina", "NC"],
"27609": ["Raleigh", "North Carolina", "NC"],
"27610": ["Raleigh", "North Carolina", "NC"],
"27612": ["Raleigh", "North Carolina", "NC"],
"27613": ["Raleigh", "North Carolina", "NC"],
"27614": ["Raleigh", "North Carolina", "NC"],
"27615": ["Raleigh", "North Carolina", "NC"],
"27616": ["Raleigh", "North Carolina", "NC"],
"27617": ["Raleigh", "North Carolina", "NC"],
"27695": ["Raleigh", "North Carolina", "NC"],
"27697": ["Raleigh", "North Carolina", "NC"],
"27701": ["Durham", "North Carolina", "NC"],
"27703": ["Durham", "North Carolina", "NC"],
"27704": ["Durham", "North Carolina", "NC"],
"27705": ["Durham", "North Carolina", "NC"],
"27707": ["Durham", "North Carolina", "NC"],
"27708": ["Durham", "North Carolina", "NC"],
"27709": ["Durham", "North Carolina", "NC"],
"27712": ["Durham", "North Carolina", "NC"],
"27713": ["Durham", "North Carolina", "NC"],
"27801": ["Rocky Mount", "North Carolina", "NC"],
"27803": ["Rocky Mount", "North Carolina", "NC"],
"27804": ["Rocky Mount", "North Carolina", "NC"],
"27805": ["Aulander", "North Carolina", "NC"],
"27806": ["Aurora", "North Carolina", "NC"],
"27807": ["Bailey", "North Carolina", "NC"],
"27808": ["Bath", "North Carolina", "NC"],
"27809": ["Battleboro", "North Carolina", "NC"],
"27810": ["Belhaven", "North Carolina", "NC"],
"27812": ["Bethel", "North Carolina", "NC"],
"27813": ["Black Creek", "North Carolina", "NC"],
"27814": ["Blounts Creek", "North Carolina", "NC"],
"27815": ["Rocky Mount", "North Carolina", "NC"],
"27816": ["Castalia", "North Carolina", "NC"],
"27817": ["Chocowinity", "North Carolina", "NC"],
"27818": ["Como", "North Carolina", "NC"],
"27819": ["Conetoe", "North Carolina", "NC"],
"27820": ["Conway", "North Carolina", "NC"],
"27821": ["Edward", "North Carolina", "NC"],
"27822": ["Elm City", "North Carolina", "NC"],
"27823": ["Enfield", "North Carolina", "NC"],
"27824": ["Engelhard", "North Carolina", "NC"],
"27825": ["Everetts", "North Carolina", "NC"],
"27826": ["Fairfield", "North Carolina", "NC"],
"27827": ["Falkland", "North Carolina", "NC"],
"27828": ["Farmville", "North Carolina", "NC"],
"27829": ["Fountain", "North Carolina", "NC"],
"27830": ["Fremont", "North Carolina", "NC"],
"27831": ["Garysburg", "North Carolina", "NC"],
"27832": ["Gaston", "North Carolina", "NC"],
"27834": ["Greenville", "North Carolina", "NC"],
"27837": ["Grimesland", "North Carolina", "NC"],
"27839": ["Halifax", "North Carolina", "NC"],
"27840": ["Hamilton", "North Carolina", "NC"],
"27841": ["Hassell", "North Carolina", "NC"],
"27842": ["Henrico", "North Carolina", "NC"],
"27843": ["Hobgood", "North Carolina", "NC"],
"27844": ["Hollister", "North Carolina", "NC"],
"27845": ["Jackson", "North Carolina", "NC"],
"27846": ["Jamesville", "North Carolina", "NC"],
"27847": ["Kelford", "North Carolina", "NC"],
"27849": ["Lewiston Woodville", "North Carolina", "NC"],
"27850": ["Littleton", "North Carolina", "NC"],
"27851": ["Lucama", "North Carolina", "NC"],
"27852": ["Macclesfield", "North Carolina", "NC"],
"27853": ["Margarettsville", "North Carolina", "NC"],
"27855": ["Murfreesboro", "North Carolina", "NC"],
"27856": ["Nashville", "North Carolina", "NC"],
"27857": ["Oak City", "North Carolina", "NC"],
"27858": ["Greenville", "North Carolina", "NC"],
"27860": ["Pantego", "North Carolina", "NC"],
"27861": ["Parmele", "North Carolina", "NC"],
"27862": ["Pendleton", "North Carolina", "NC"],
"27863": ["Pikeville", "North Carolina", "NC"],
"27864": ["Pinetops", "North Carolina", "NC"],
"27865": ["Pinetown", "North Carolina", "NC"],
"27866": ["Pleasant Hill", "North Carolina", "NC"],
"27869": ["Rich Square", "North Carolina", "NC"],
"27870": ["Roanoke Rapids", "North Carolina", "NC"],
"27871": ["Robersonville", "North Carolina", "NC"],
"27872": ["Roxobel", "North Carolina", "NC"],
"27873": ["Saratoga", "North Carolina", "NC"],
"27874": ["Scotland Neck", "North Carolina", "NC"],
"27875": ["Scranton", "North Carolina", "NC"],
"27876": ["Seaboard", "North Carolina", "NC"],
"27877": ["Severn", "North Carolina", "NC"],
"27878": ["Sharpsburg", "North Carolina", "NC"],
"27879": ["Simpson", "North Carolina", "NC"],
"27880": ["Sims", "North Carolina", "NC"],
"27881": ["Speed", "North Carolina", "NC"],
"27882": ["Spring Hope", "North Carolina", "NC"],
"27883": ["Stantonsburg", "North Carolina", "NC"],
"27884": ["Stokes", "North Carolina", "NC"],
"27885": ["Swanquarter", "North Carolina", "NC"],
"27886": ["Tarboro", "North Carolina", "NC"],
"27888": ["Walstonburg", "North Carolina", "NC"],
"27889": ["Washington", "North Carolina", "NC"],
"27890": ["Weldon", "North Carolina", "NC"],
"27891": ["Whitakers", "North Carolina", "NC"],
"27892": ["Williamston", "North Carolina", "NC"],
"27893": ["Wilson", "North Carolina", "NC"],
"27896": ["Wilson", "North Carolina", "NC"],
"27897": ["Woodland", "North Carolina", "NC"],
"27909": ["Elizabeth City", "North Carolina", "NC"],
"27910": ["Ahoskie", "North Carolina", "NC"],
"27915": ["Avon", "North Carolina", "NC"],
"27916": ["Aydlett", "North Carolina", "NC"],
"27917": ["Barco", "North Carolina", "NC"],
"27919": ["Belvidere", "North Carolina", "NC"],
"27920": ["Buxton", "North Carolina", "NC"],
"27921": ["Camden", "North Carolina", "NC"],
"27922": ["Cofield", "North Carolina", "NC"],
"27923": ["Coinjock", "North Carolina", "NC"],
"27924": ["Colerain", "North Carolina", "NC"],
"27925": ["Columbia", "North Carolina", "NC"],
"27926": ["Corapeake", "North Carolina", "NC"],
"27927": ["Corolla", "North Carolina", "NC"],
"27928": ["Creswell", "North Carolina", "NC"],
"27929": ["Currituck", "North Carolina", "NC"],
"27932": ["Edenton", "North Carolina", "NC"],
"27935": ["Eure", "North Carolina", "NC"],
"27936": ["Frisco", "North Carolina", "NC"],
"27937": ["Gates", "North Carolina", "NC"],
"27938": ["Gatesville", "North Carolina", "NC"],
"27939": ["Grandy", "North Carolina", "NC"],
"27941": ["Harbinger", "North Carolina", "NC"],
"27942": ["Harrellsville", "North Carolina", "NC"],
"27943": ["Hatteras", "North Carolina", "NC"],
"27944": ["Hertford", "North Carolina", "NC"],
"27946": ["Hobbsville", "North Carolina", "NC"],
"27947": ["Jarvisburg", "North Carolina", "NC"],
"27948": ["Kill Devil Hills", "North Carolina", "NC"],
"27949": ["Kitty Hawk", "North Carolina", "NC"],
"27950": ["Knotts Island", "North Carolina", "NC"],
"27953": ["Manns Harbor", "North Carolina", "NC"],
"27954": ["Manteo", "North Carolina", "NC"],
"27956": ["Maple", "North Carolina", "NC"],
"27957": ["Merry Hill", "North Carolina", "NC"],
"27958": ["Moyock", "North Carolina", "NC"],
"27959": ["Nags Head", "North Carolina", "NC"],
"27960": ["Ocracoke", "North Carolina", "NC"],
"27962": ["Plymouth", "North Carolina", "NC"],
"27964": ["Point Harbor", "North Carolina", "NC"],
"27965": ["Poplar Branch", "North Carolina", "NC"],
"27966": ["Powells Point", "North Carolina", "NC"],
"27967": ["Powellsville", "North Carolina", "NC"],
"27968": ["Rodanthe", "North Carolina", "NC"],
"27969": ["Roduco", "North Carolina", "NC"],
"27970": ["Roper", "North Carolina", "NC"],
"27972": ["Salvo", "North Carolina", "NC"],
"27973": ["Shawboro", "North Carolina", "NC"],
"27974": ["Shiloh", "North Carolina", "NC"],
"27976": ["South Mills", "North Carolina", "NC"],
"27978": ["Stumpy Point", "North Carolina", "NC"],
"27979": ["Sunbury", "North Carolina", "NC"],
"27980": ["Tyner", "North Carolina", "NC"],
"27981": ["Wanchese", "North Carolina", "NC"],
"27982": ["Waves", "North Carolina", "NC"],
"27983": ["Windsor", "North Carolina", "NC"],
"27985": ["Winfall", "North Carolina", "NC"],
"27986": ["Winton", "North Carolina", "NC"],
"28001": ["Albemarle", "North Carolina", "NC"],
"28006": ["Alexis", "North Carolina", "NC"],
"28007": ["Ansonville", "North Carolina", "NC"],
"28009": ["Badin", "North Carolina", "NC"],
"28012": ["Belmont", "North Carolina", "NC"],
"28016": ["Bessemer City", "North Carolina", "NC"],
"28017": ["Boiling Springs", "North Carolina", "NC"],
"28018": ["Bostic", "North Carolina", "NC"],
"28019": ["Caroleen", "North Carolina", "NC"],
"28020": ["Casar", "North Carolina", "NC"],
"28021": ["Cherryville", "North Carolina", "NC"],
"28023": ["China Grove", "North Carolina", "NC"],
"28024": ["Cliffside", "North Carolina", "NC"],
"28025": ["Concord", "North Carolina", "NC"],
"28027": ["Concord", "North Carolina", "NC"],
"28031": ["Cornelius", "North Carolina", "NC"],
"28032": ["Cramerton", "North Carolina", "NC"],
"28033": ["Crouse", "North Carolina", "NC"],
"28034": ["Dallas", "North Carolina", "NC"],
"28036": ["Davidson", "North Carolina", "NC"],
"28037": ["Denver", "North Carolina", "NC"],
"28039": ["East Spencer", "North Carolina", "NC"],
"28040": ["Ellenboro", "North Carolina", "NC"],
"28041": ["Faith", "North Carolina", "NC"],
"28042": ["Fallston", "North Carolina", "NC"],
"28043": ["Forest City", "North Carolina", "NC"],
"28052": ["Gastonia", "North Carolina", "NC"],
"28054": ["Gastonia", "North Carolina", "NC"],
"28056": ["Gastonia", "North Carolina", "NC"],
"28071": ["Gold Hill", "North Carolina", "NC"],
"28072": ["Granite Quarry", "North Carolina", "NC"],
"28073": ["Grover", "North Carolina", "NC"],
"28074": ["Harris", "North Carolina", "NC"],
"28075": ["Harrisburg", "North Carolina", "NC"],
"28076": ["Henrietta", "North Carolina", "NC"],
"28077": ["High Shoals", "North Carolina", "NC"],
"28078": ["Huntersville", "North Carolina", "NC"],
"28079": ["Indian Trail", "North Carolina", "NC"],
"28080": ["Iron Station", "North Carolina", "NC"],
"28081": ["Kannapolis", "North Carolina", "NC"],
"28083": ["Kannapolis", "North Carolina", "NC"],
"28086": ["Kings Mountain", "North Carolina", "NC"],
"28088": ["Landis", "North Carolina", "NC"],
"28089": ["Lattimore", "North Carolina", "NC"],
"28090": ["Lawndale", "North Carolina", "NC"],
"28091": ["Lilesville", "North Carolina", "NC"],
"28092": ["Lincolnton", "North Carolina", "NC"],
"28097": ["Locust", "North Carolina", "NC"],
"28098": ["Lowell", "North Carolina", "NC"],
"28101": ["McAdenville", "North Carolina", "NC"],
"28102": ["McFarlan", "North Carolina", "NC"],
"28103": ["Marshville", "North Carolina", "NC"],
"28104": ["Matthews", "North Carolina", "NC"],
"28105": ["Matthews", "North Carolina", "NC"],
"28107": ["Midland", "North Carolina", "NC"],
"28108": ["Mineral Springs", "North Carolina", "NC"],
"28109": ["Misenheimer", "North Carolina", "NC"],
"28110": ["Monroe", "North Carolina", "NC"],
"28112": ["Monroe", "North Carolina", "NC"],
"28114": ["Mooresboro", "North Carolina", "NC"],
"28115": ["Mooresville", "North Carolina", "NC"],
"28117": ["Mooresville", "North Carolina", "NC"],
"28119": ["Morven", "North Carolina", "NC"],
"28120": ["Mount Holly", "North Carolina", "NC"],
"28124": ["Mount Pleasant", "North Carolina", "NC"],
"28125": ["Mount Ulla", "North Carolina", "NC"],
"28127": ["New London", "North Carolina", "NC"],
"28128": ["Norwood", "North Carolina", "NC"],
"28129": ["Oakboro", "North Carolina", "NC"],
"28133": ["Peachland", "North Carolina", "NC"],
"28134": ["Pineville", "North Carolina", "NC"],
"28135": ["Polkton", "North Carolina", "NC"],
"28136": ["Polkville", "North Carolina", "NC"],
"28137": ["Richfield", "North Carolina", "NC"],
"28138": ["Rockwell", "North Carolina", "NC"],
"28139": ["Rutherfordton", "North Carolina", "NC"],
"28144": ["Salisbury", "North Carolina", "NC"],
"28146": ["Salisbury", "North Carolina", "NC"],
"28147": ["Salisbury", "North Carolina", "NC"],
"28150": ["Shelby", "North Carolina", "NC"],
"28152": ["Shelby", "North Carolina", "NC"],
"28159": ["Spencer", "North Carolina", "NC"],
"28160": ["Spindale", "North Carolina", "NC"],
"28163": ["Stanfield", "North Carolina", "NC"],
"28164": ["Stanley", "North Carolina", "NC"],
"28166": ["Troutman", "North Carolina", "NC"],
"28167": ["Union Mills", "North Carolina", "NC"],
"28168": ["Vale", "North Carolina", "NC"],
"28169": ["Waco", "North Carolina", "NC"],
"28170": ["Wadesboro", "North Carolina", "NC"],
"28173": ["Waxhaw", "North Carolina", "NC"],
"28174": ["Wingate", "North Carolina", "NC"],
"28202": ["Charlotte", "North Carolina", "NC"],
"28203": ["Charlotte", "North Carolina", "NC"],
"28204": ["Charlotte", "North Carolina", "NC"],
"28205": ["Charlotte", "North Carolina", "NC"],
"28206": ["Charlotte", "North Carolina", "NC"],
"28207": ["Charlotte", "North Carolina", "NC"],
"28208": ["Charlotte", "North Carolina", "NC"],
"28209": ["Charlotte", "North Carolina", "NC"],
"28210": ["Charlotte", "North Carolina", "NC"],
"28211": ["Charlotte", "North Carolina", "NC"],
"28212": ["Charlotte", "North Carolina", "NC"],
"28213": ["Charlotte", "North Carolina", "NC"],
"28214": ["Charlotte", "North Carolina", "NC"],
"28215": ["Charlotte", "North Carolina", "NC"],
"28216": ["Charlotte", "North Carolina", "NC"],
"28217": ["Charlotte", "North Carolina", "NC"],
"28223": ["Charlotte", "North Carolina", "NC"],
"28226": ["Charlotte", "North Carolina", "NC"],
"28227": ["Charlotte", "North Carolina", "NC"],
"28244": ["Charlotte", "North Carolina", "NC"],
"28262": ["Charlotte", "North Carolina", "NC"],
"28269": ["Charlotte", "North Carolina", "NC"],
"28270": ["Charlotte", "North Carolina", "NC"],
"28273": ["Charlotte", "North Carolina", "NC"],
"28274": ["Charlotte", "North Carolina", "NC"],
"28277": ["Charlotte", "North Carolina", "NC"],
"28278": ["Charlotte", "North Carolina", "NC"],
"28280": ["Charlotte", "North Carolina", "NC"],
"28282": ["Charlotte", "North Carolina", "NC"],
"28301": ["Fayetteville", "North Carolina", "NC"],
"28303": ["Fayetteville", "North Carolina", "NC"],
"28304": ["Fayetteville", "North Carolina", "NC"],
"28305": ["Fayetteville", "North Carolina", "NC"],
"28306": ["Fayetteville", "North Carolina", "NC"],
"28307": ["Fort Bragg", "North Carolina", "NC"],
"28308": ["Pope Army Airfield", "North Carolina", "NC"],
"28310": ["Fort Bragg", "North Carolina", "NC"],
"28311": ["Fayetteville", "North Carolina", "NC"],
"28312": ["Fayetteville", "North Carolina", "NC"],
"28314": ["Fayetteville", "North Carolina", "NC"],
"28315": ["Aberdeen", "North Carolina", "NC"],
"28318": ["Autryville", "North Carolina", "NC"],
"28320": ["Bladenboro", "North Carolina", "NC"],
"28323": ["Bunnlevel", "North Carolina", "NC"],
"28325": ["Calypso", "North Carolina", "NC"],
"28326": ["Cameron", "North Carolina", "NC"],
"28327": ["Carthage", "North Carolina", "NC"],
"28328": ["Clinton", "North Carolina", "NC"],
"28330": ["Cordova", "North Carolina", "NC"],
"28331": ["Cumberland", "North Carolina", "NC"],
"28332": ["Dublin", "North Carolina", "NC"],
"28333": ["Dudley", "North Carolina", "NC"],
"28334": ["Dunn", "North Carolina", "NC"],
"28337": ["Elizabethtown", "North Carolina", "NC"],
"28338": ["Ellerbe", "North Carolina", "NC"],
"28339": ["Erwin", "North Carolina", "NC"],
"28340": ["Fairmont", "North Carolina", "NC"],
"28341": ["Faison", "North Carolina", "NC"],
"28342": ["Falcon", "North Carolina", "NC"],
"28343": ["Gibson", "North Carolina", "NC"],
"28344": ["Godwin", "North Carolina", "NC"],
"28345": ["Hamlet", "North Carolina", "NC"],
"28347": ["Hoffman", "North Carolina", "NC"],
"28348": ["Hope Mills", "North Carolina", "NC"],
"28349": ["Kenansville", "North Carolina", "NC"],
"28350": ["Lakeview", "North Carolina", "NC"],
"28351": ["Laurel Hill", "North Carolina", "NC"],
"28352": ["Laurinburg", "North Carolina", "NC"],
"28355": ["Lemon Springs", "North Carolina", "NC"],
"28356": ["Linden", "North Carolina", "NC"],
"28357": ["Lumber Bridge", "North Carolina", "NC"],
"28358": ["Lumberton", "North Carolina", "NC"],
"28359": ["Lumberton", "North Carolina", "NC"],
"28360": ["Lumberton", "North Carolina", "NC"],
"28362": ["Marietta", "North Carolina", "NC"],
"28363": ["Marston", "North Carolina", "NC"],
"28364": ["Maxton", "North Carolina", "NC"],
"28365": ["Mount Olive", "North Carolina", "NC"],
"28366": ["Newton Grove", "North Carolina", "NC"],
"28367": ["Norman", "North Carolina", "NC"],
"28368": ["Olivia", "North Carolina", "NC"],
"28369": ["Orrum", "North Carolina", "NC"],
"28371": ["Parkton", "North Carolina", "NC"],
"28372": ["Pembroke", "North Carolina", "NC"],
"28373": ["Pinebluff", "North Carolina", "NC"],
"28374": ["Pinehurst", "North Carolina", "NC"],
"28375": ["Proctorville", "North Carolina", "NC"],
"28376": ["Raeford", "North Carolina", "NC"],
"28377": ["Red Springs", "North Carolina", "NC"],
"28378": ["Rex", "North Carolina", "NC"],
"28379": ["Rockingham", "North Carolina", "NC"],
"28382": ["Roseboro", "North Carolina", "NC"],
"28383": ["Rowland", "North Carolina", "NC"],
"28384": ["Saint Pauls", "North Carolina", "NC"],
"28385": ["Salemburg", "North Carolina", "NC"],
"28386": ["Shannon", "North Carolina", "NC"],
"28387": ["Southern Pines", "North Carolina", "NC"],
"28390": ["Spring Lake", "North Carolina", "NC"],
"28391": ["Stedman", "North Carolina", "NC"],
"28392": ["Tar Heel", "North Carolina", "NC"],
"28393": ["Turkey", "North Carolina", "NC"],
"28394": ["Vass", "North Carolina", "NC"],
"28395": ["Wade", "North Carolina", "NC"],
"28396": ["Wagram", "North Carolina", "NC"],
"28398": ["Warsaw", "North Carolina", "NC"],
"28399": ["White Oak", "North Carolina", "NC"],
"28401": ["Wilmington", "North Carolina", "NC"],
"28403": ["Wilmington", "North Carolina", "NC"],
"28405": ["Wilmington", "North Carolina", "NC"],
"28409": ["Wilmington", "North Carolina", "NC"],
"28411": ["Wilmington", "North Carolina", "NC"],
"28412": ["Wilmington", "North Carolina", "NC"],
"28420": ["Ash", "North Carolina", "NC"],
"28421": ["Atkinson", "North Carolina", "NC"],
"28422": ["Bolivia", "North Carolina", "NC"],
"28423": ["Bolton", "North Carolina", "NC"],
"28424": ["Brunswick", "North Carolina", "NC"],
"28425": ["Burgaw", "North Carolina", "NC"],
"28428": ["Carolina Beach", "North Carolina", "NC"],
"28429": ["Castle Hayne", "North Carolina", "NC"],
"28430": ["Cerro Gordo", "North Carolina", "NC"],
"28431": ["Chadbourn", "North Carolina", "NC"],
"28432": ["Clarendon", "North Carolina", "NC"],
"28433": ["Clarkton", "North Carolina", "NC"],
"28434": ["Council", "North Carolina", "NC"],
"28435": ["Currie", "North Carolina", "NC"],
"28436": ["Delco", "North Carolina", "NC"],
"28438": ["Evergreen", "North Carolina", "NC"],
"28439": ["Fair Bluff", "North Carolina", "NC"],
"28441": ["Garland", "North Carolina", "NC"],
"28442": ["Hallsboro", "North Carolina", "NC"],
"28443": ["Hampstead", "North Carolina", "NC"],
"28444": ["Harrells", "North Carolina", "NC"],
"28445": ["Holly Ridge", "North Carolina", "NC"],
"28447": ["Ivanhoe", "North Carolina", "NC"],
"28448": ["Kelly", "North Carolina", "NC"],
"28449": ["Kure Beach", "North Carolina", "NC"],
"28450": ["Lake Waccamaw", "North Carolina", "NC"],
"28451": ["Leland", "North Carolina", "NC"],
"28452": ["Longwood", "North Carolina", "NC"],
"28453": ["Magnolia", "North Carolina", "NC"],
"28454": ["Maple Hill", "North Carolina", "NC"],
"28455": ["Nakina", "North Carolina", "NC"],
"28456": ["Riegelwood", "North Carolina", "NC"],
"28457": ["Rocky Point", "North Carolina", "NC"],
"28458": ["Rose Hill", "North Carolina", "NC"],
"28460": ["Sneads Ferry", "North Carolina", "NC"],
"28461": ["Southport", "North Carolina", "NC"],
"28462": ["Supply", "North Carolina", "NC"],
"28463": ["Tabor City", "North Carolina", "NC"],
"28464": ["Teachey", "North Carolina", "NC"],
"28465": ["Oak Island", "North Carolina", "NC"],
"28466": ["Wallace", "North Carolina", "NC"],
"28467": ["Calabash", "North Carolina", "NC"],
"28468": ["Sunset Beach", "North Carolina", "NC"],
"28469": ["Ocean Isle Beach", "North Carolina", "NC"],
"28470": ["Shallotte", "North Carolina", "NC"],
"28472": ["Whiteville", "North Carolina", "NC"],
"28478": ["Willard", "North Carolina", "NC"],
"28479": ["Winnabow", "North Carolina", "NC"],
"28480": ["Wrightsville Beach", "North Carolina", "NC"],
"28501": ["Kinston", "North Carolina", "NC"],
"28504": ["Kinston", "North Carolina", "NC"],
"28508": ["Albertson", "North Carolina", "NC"],
"28509": ["Alliance", "North Carolina", "NC"],
"28510": ["Arapahoe", "North Carolina", "NC"],
"28511": ["Atlantic", "North Carolina", "NC"],
"28512": ["Atlantic Beach", "North Carolina", "NC"],
"28513": ["Ayden", "North Carolina", "NC"],
"28515": ["Bayboro", "North Carolina", "NC"],
"28516": ["Beaufort", "North Carolina", "NC"],
"28518": ["Beulaville", "North Carolina", "NC"],
"28519": ["Bridgeton", "North Carolina", "NC"],
"28520": ["Cedar Island", "North Carolina", "NC"],
"28521": ["Chinquapin", "North Carolina", "NC"],
"28523": ["Cove City", "North Carolina", "NC"],
"28524": ["Davis", "North Carolina", "NC"],
"28525": ["Deep Run", "North Carolina", "NC"],
"28526": ["Dover", "North Carolina", "NC"],
"28527": ["Ernul", "North Carolina", "NC"],
"28528": ["Gloucester", "North Carolina", "NC"],
"28529": ["Grantsboro", "North Carolina", "NC"],
"28530": ["Grifton", "North Carolina", "NC"],
"28531": ["Harkers Island", "North Carolina", "NC"],
"28532": ["Havelock", "North Carolina", "NC"],
"28533": ["Cherry Point", "North Carolina", "NC"],
"28537": ["Hobucken", "North Carolina", "NC"],
"28538": ["Hookerton", "North Carolina", "NC"],
"28539": ["Hubert", "North Carolina", "NC"],
"28540": ["Jacksonville", "North Carolina", "NC"],
"28542": ["Camp Lejeune", "North Carolina", "NC"],
"28543": ["Tarawa Terrace", "North Carolina", "NC"],
"28544": ["Midway Park", "North Carolina", "NC"],
"28546": ["Jacksonville", "North Carolina", "NC"],
"28547": ["Camp Lejeune", "North Carolina", "NC"],
"28551": ["La Grange", "North Carolina", "NC"],
"28552": ["Lowland", "North Carolina", "NC"],
"28553": ["Marshallberg", "North Carolina", "NC"],
"28554": ["Maury", "North Carolina", "NC"],
"28555": ["Maysville", "North Carolina", "NC"],
"28556": ["Merritt", "North Carolina", "NC"],
"28557": ["Morehead City", "North Carolina", "NC"],
"28560": ["New Bern", "North Carolina", "NC"],
"28562": ["New Bern", "North Carolina", "NC"],
"28570": ["Newport", "North Carolina", "NC"],
"28571": ["Oriental", "North Carolina", "NC"],
"28572": ["Pink Hill", "North Carolina", "NC"],
"28573": ["Pollocksville", "North Carolina", "NC"],
"28574": ["Richlands", "North Carolina", "NC"],
"28575": ["Salter Path", "North Carolina", "NC"],
"28577": ["Sealevel", "North Carolina", "NC"],
"28578": ["Seven Springs", "North Carolina", "NC"],
"28579": ["Smyrna", "North Carolina", "NC"],
"28580": ["Snow Hill", "North Carolina", "NC"],
"28581": ["Stacy", "North Carolina", "NC"],
"28582": ["Stella", "North Carolina", "NC"],
"28584": ["Swansboro", "North Carolina", "NC"],
"28585": ["Trenton", "North Carolina", "NC"],
"28586": ["Vanceboro", "North Carolina", "NC"],
"28587": ["Vandemere", "North Carolina", "NC"],
"28589": ["Williston", "North Carolina", "NC"],
"28590": ["Winterville", "North Carolina", "NC"],
"28594": ["Emerald Isle", "North Carolina", "NC"],
"28601": ["Hickory", "North Carolina", "NC"],
"28602": ["Hickory", "North Carolina", "NC"],
"28604": ["Banner Elk", "North Carolina", "NC"],
"28605": ["Blowing Rock", "North Carolina", "NC"],
"28606": ["Boomer", "North Carolina", "NC"],
"28607": ["Boone", "North Carolina", "NC"],
"28609": ["Catawba", "North Carolina", "NC"],
"28610": ["Claremont", "North Carolina", "NC"],
"28611": ["Collettsville", "North Carolina", "NC"],
"28612": ["Connelly Springs", "North Carolina", "NC"],
"28613": ["Conover", "North Carolina", "NC"],
"28615": ["Creston", "North Carolina", "NC"],
"28616": ["Crossnore", "North Carolina", "NC"],
"28617": ["Crumpler", "North Carolina", "NC"],
"28618": ["Deep Gap", "North Carolina", "NC"],
"28619": ["Drexel", "North Carolina", "NC"],
"28621": ["Elkin", "North Carolina", "NC"],
"28622": ["Elk Park", "North Carolina", "NC"],
"28623": ["Ennice", "North Carolina", "NC"],
"28624": ["Ferguson", "North Carolina", "NC"],
"28625": ["Statesville", "North Carolina", "NC"],
"28626": ["Fleetwood", "North Carolina", "NC"],
"28627": ["Glade Valley", "North Carolina", "NC"],
"28628": ["Glen Alpine", "North Carolina", "NC"],
"28629": ["Glendale Springs", "North Carolina", "NC"],
"28630": ["Granite Falls", "North Carolina", "NC"],
"28631": ["Grassy Creek", "North Carolina", "NC"],
"28634": ["Harmony", "North Carolina", "NC"],
"28635": ["Hays", "North Carolina", "NC"],
"28636": ["Hiddenite", "North Carolina", "NC"],
"28637": ["Hildebran", "North Carolina", "NC"],
"28638": ["Hudson", "North Carolina", "NC"],
"28640": ["Jefferson", "North Carolina", "NC"],
"28641": ["Jonas Ridge", "North Carolina", "NC"],
"28642": ["Jonesville", "North Carolina", "NC"],
"28643": ["Lansing", "North Carolina", "NC"],
"28644": ["Laurel Springs", "North Carolina", "NC"],
"28645": ["Lenoir", "North Carolina", "NC"],
"28646": ["Linville", "North Carolina", "NC"],
"28649": ["McGrady", "North Carolina", "NC"],
"28650": ["Maiden", "North Carolina", "NC"],
"28651": ["Millers Creek", "North Carolina", "NC"],
"28652": ["Minneapolis", "North Carolina", "NC"],
"28653": ["Montezuma", "North Carolina", "NC"],
"28654": ["Moravian Falls", "North Carolina", "NC"],
"28655": ["Morganton", "North Carolina", "NC"],
"28657": ["Newland", "North Carolina", "NC"],
"28658": ["Newton", "North Carolina", "NC"],
"28659": ["North Wilkesboro", "North Carolina", "NC"],
"28660": ["Olin", "North Carolina", "NC"],
"28662": ["Pineola", "North Carolina", "NC"],
"28663": ["Piney Creek", "North Carolina", "NC"],
"28664": ["Plumtree", "North Carolina", "NC"],
"28665": ["Purlear", "North Carolina", "NC"],
"28666": ["Icard", "North Carolina", "NC"],
"28667": ["Rhodhiss", "North Carolina", "NC"],
"28668": ["Roaring Gap", "North Carolina", "NC"],
"28669": ["Roaring River", "North Carolina", "NC"],
"28670": ["Ronda", "North Carolina", "NC"],
"28671": ["Rutherford College", "North Carolina", "NC"],
"28672": ["Scottville", "North Carolina", "NC"],
"28673": ["Sherrills Ford", "North Carolina", "NC"],
"28675": ["Sparta", "North Carolina", "NC"],
"28676": ["State Road", "North Carolina", "NC"],
"28677": ["Statesville", "North Carolina", "NC"],
"28678": ["Stony Point", "North Carolina", "NC"],
"28679": ["Sugar Grove", "North Carolina", "NC"],
"28681": ["Taylorsville", "North Carolina", "NC"],
"28682": ["Terrell", "North Carolina", "NC"],
"28683": ["Thurmond", "North Carolina", "NC"],
"28684": ["Todd", "North Carolina", "NC"],
"28685": ["Traphill", "North Carolina", "NC"],
"28689": ["Union Grove", "North Carolina", "NC"],
"28690": ["Valdese", "North Carolina", "NC"],
"28692": ["Vilas", "North Carolina", "NC"],
"28693": ["Warrensville", "North Carolina", "NC"],
"28694": ["West Jefferson", "North Carolina", "NC"],
"28697": ["Wilkesboro", "North Carolina", "NC"],
"28698": ["Zionville", "North Carolina", "NC"],
"28701": ["Alexander", "North Carolina", "NC"],
"28702": ["Almond", "North Carolina", "NC"],
"28704": ["Arden", "North Carolina", "NC"],
"28705": ["Bakersville", "North Carolina", "NC"],
"28707": ["Balsam", "North Carolina", "NC"],
"28708": ["Balsam Grove", "North Carolina", "NC"],
"28709": ["Barnardsville", "North Carolina", "NC"],
"28710": ["Bat Cave", "North Carolina", "NC"],
"28711": ["Black Mountain", "North Carolina", "NC"],
"28712": ["Brevard", "North Carolina", "NC"],
"28713": ["Bryson City", "North Carolina", "NC"],
"28714": ["Burnsville", "North Carolina", "NC"],
"28715": ["Candler", "North Carolina", "NC"],
"28716": ["Canton", "North Carolina", "NC"],
"28717": ["Cashiers", "North Carolina", "NC"],
"28718": ["Cedar Mountain", "North Carolina", "NC"],
"28719": ["Cherokee", "North Carolina", "NC"],
"28720": ["Chimney Rock", "North Carolina", "NC"],
"28721": ["Clyde", "North Carolina", "NC"],
"28722": ["Columbus", "North Carolina", "NC"],
"28723": ["Cullowhee", "North Carolina", "NC"],
"28725": ["Dillsboro", "North Carolina", "NC"],
"28726": ["East Flat Rock", "North Carolina", "NC"],
"28729": ["Etowah", "North Carolina", "NC"],
"28730": ["Fairview", "North Carolina", "NC"],
"28731": ["Flat Rock", "North Carolina", "NC"],
"28732": ["Fletcher", "North Carolina", "NC"],
"28733": ["Fontana Dam", "North Carolina", "NC"],
"28734": ["Franklin", "North Carolina", "NC"],
"28735": ["Gerton", "North Carolina", "NC"],
"28736": ["Glenville", "North Carolina", "NC"],
"28739": ["Hendersonville", "North Carolina", "NC"],
"28740": ["Green Mountain", "North Carolina", "NC"],
"28741": ["Highlands", "North Carolina", "NC"],
"28742": ["Horse Shoe", "North Carolina", "NC"],
"28743": ["Hot Springs", "North Carolina", "NC"],
"28745": ["Lake Junaluska", "North Carolina", "NC"],
"28746": ["Lake Lure", "North Carolina", "NC"],
"28747": ["Lake Toxaway", "North Carolina", "NC"],
"28748": ["Leicester", "North Carolina", "NC"],
"28749": ["Little Switzerland", "North Carolina", "NC"],
"28751": ["Maggie Valley", "North Carolina", "NC"],
"28752": ["Marion", "North Carolina", "NC"],
"28753": ["Marshall", "North Carolina", "NC"],
"28754": ["Mars Hill", "North Carolina", "NC"],
"28755": ["Micaville", "North Carolina", "NC"],
"28756": ["Mill Spring", "North Carolina", "NC"],
"28757": ["Montreat", "North Carolina", "NC"],
"28758": ["Mountain Home", "North Carolina", "NC"],
"28759": ["Mills River", "North Carolina", "NC"],
"28761": ["Nebo", "North Carolina", "NC"],
"28762": ["Old Fort", "North Carolina", "NC"],
"28763": ["Otto", "North Carolina", "NC"],
"28766": ["Penrose", "North Carolina", "NC"],
"28768": ["Pisgah Forest", "North Carolina", "NC"],
"28770": ["Ridgecrest", "North Carolina", "NC"],
"28771": ["Robbinsville", "North Carolina", "NC"],
"28772": ["Rosman", "North Carolina", "NC"],
"28773": ["Saluda", "North Carolina", "NC"],
"28774": ["Sapphire", "North Carolina", "NC"],
"28775": ["Scaly Mountain", "North Carolina", "NC"],
"28777": ["Spruce Pine", "North Carolina", "NC"],
"28778": ["Swannanoa", "North Carolina", "NC"],
"28779": ["Sylva", "North Carolina", "NC"],
"28781": ["Topton", "North Carolina", "NC"],
"28782": ["Tryon", "North Carolina", "NC"],
"28783": ["Tuckasegee", "North Carolina", "NC"],
"28785": ["Waynesville", "North Carolina", "NC"],
"28786": ["Waynesville", "North Carolina", "NC"],
"28787": ["Weaverville", "North Carolina", "NC"],
"28788": ["Webster", "North Carolina", "NC"],
"28789": ["Whittier", "North Carolina", "NC"],
"28790": ["Zirconia", "North Carolina", "NC"],
"28791": ["Hendersonville", "North Carolina", "NC"],
"28792": ["Hendersonville", "North Carolina", "NC"],
"28801": ["Asheville", "North Carolina", "NC"],
"28803": ["Asheville", "North Carolina", "NC"],
"28804": ["Asheville", "North Carolina", "NC"],
"28805": ["Asheville", "North Carolina", "NC"],
"28806": ["Asheville", "North Carolina", "NC"],
"28901": ["Andrews", "North Carolina", "NC"],
"28902": ["Brasstown", "North Carolina", "NC"],
"28904": ["Hayesville", "North Carolina", "NC"],
"28905": ["Marble", "North Carolina", "NC"],
"28906": ["Murphy", "North Carolina", "NC"],
"28909": ["Warne", "North Carolina", "NC"],
"29001": ["Alcolu", "South Carolina", "SC"],
"29003": ["Bamberg", "South Carolina", "SC"],
"29006": ["Batesburg", "South Carolina", "SC"],
"29009": ["Bethune", "South Carolina", "SC"],
"29010": ["Bishopville", "South Carolina", "SC"],
"29014": ["Blackstock", "South Carolina", "SC"],
"29015": ["Blair", "South Carolina", "SC"],
"29016": ["Blythewood", "South Carolina", "SC"],
"29018": ["Bowman", "South Carolina", "SC"],
"29020": ["Camden", "South Carolina", "SC"],
"29030": ["Cameron", "South Carolina", "SC"],
"29031": ["Carlisle", "South Carolina", "SC"],
"29032": ["Cassatt", "South Carolina", "SC"],
"29033": ["Cayce", "South Carolina", "SC"],
"29036": ["Chapin", "South Carolina", "SC"],
"29037": ["Chappells", "South Carolina", "SC"],
"29038": ["Cope", "South Carolina", "SC"],
"29039": ["Cordova", "South Carolina", "SC"],
"29040": ["Dalzell", "South Carolina", "SC"],
"29042": ["Denmark", "South Carolina", "SC"],
"29044": ["Eastover", "South Carolina", "SC"],
"29045": ["Elgin", "South Carolina", "SC"],
"29046": ["Elliott", "South Carolina", "SC"],
"29047": ["Elloree", "South Carolina", "SC"],
"29048": ["Eutawville", "South Carolina", "SC"],
"29051": ["Gable", "South Carolina", "SC"],
"29052": ["Gadsden", "South Carolina", "SC"],
"29053": ["Gaston", "South Carolina", "SC"],
"29054": ["Gilbert", "South Carolina", "SC"],
"29055": ["Great Falls", "South Carolina", "SC"],
"29056": ["Greeleyville", "South Carolina", "SC"],
"29058": ["Heath Springs", "South Carolina", "SC"],
"29059": ["Holly Hill", "South Carolina", "SC"],
"29061": ["Hopkins", "South Carolina", "SC"],
"29062": ["Horatio", "South Carolina", "SC"],
"29063": ["Irmo", "South Carolina", "SC"],
"29065": ["Jenkinsville", "South Carolina", "SC"],
"29067": ["Kershaw", "South Carolina", "SC"],
"29069": ["Lamar", "South Carolina", "SC"],
"29070": ["Leesville", "South Carolina", "SC"],
"29072": ["Lexington", "South Carolina", "SC"],
"29073": ["Lexington", "South Carolina", "SC"],
"29074": ["Liberty Hill", "South Carolina", "SC"],
"29075": ["Little Mountain", "South Carolina", "SC"],
"29078": ["Lugoff", "South Carolina", "SC"],
"29079": ["Lydia", "South Carolina", "SC"],
"29080": ["Lynchburg", "South Carolina", "SC"],
"29081": ["Ehrhardt", "South Carolina", "SC"],
"29082": ["Lodge", "South Carolina", "SC"],
"29101": ["McBee", "South Carolina", "SC"],
"29102": ["Manning", "South Carolina", "SC"],
"29104": ["Mayesville", "South Carolina", "SC"],
"29105": ["Monetta", "South Carolina", "SC"],
"29107": ["Neeses", "South Carolina", "SC"],
"29108": ["Newberry", "South Carolina", "SC"],
"29111": ["New Zion", "South Carolina", "SC"],
"29112": ["North", "South Carolina", "SC"],
"29113": ["Norway", "South Carolina", "SC"],
"29114": ["Olanta", "South Carolina", "SC"],
"29115": ["Orangeburg", "South Carolina", "SC"],
"29117": ["Orangeburg", "South Carolina", "SC"],
"29118": ["Orangeburg", "South Carolina", "SC"],
"29122": ["Peak", "South Carolina", "SC"],
"29123": ["Pelion", "South Carolina", "SC"],
"29125": ["Pinewood", "South Carolina", "SC"],
"29126": ["Pomaria", "South Carolina", "SC"],
"29127": ["Prosperity", "South Carolina", "SC"],
"29128": ["Rembert", "South Carolina", "SC"],
"29129": ["Ridge Spring", "South Carolina", "SC"],
"29130": ["Ridgeway", "South Carolina", "SC"],
"29133": ["Rowesville", "South Carolina", "SC"],
"29135": ["Saint Matthews", "South Carolina", "SC"],
"29137": ["Salley", "South Carolina", "SC"],
"29138": ["Saluda", "South Carolina", "SC"],
"29142": ["Santee", "South Carolina", "SC"],
"29145": ["Silverstreet", "South Carolina", "SC"],
"29146": ["Springfield", "South Carolina", "SC"],
"29148": ["Summerton", "South Carolina", "SC"],
"29150": ["Sumter", "South Carolina", "SC"],
"29152": ["Shaw Afb", "South Carolina", "SC"],
"29153": ["Sumter", "South Carolina", "SC"],
"29154": ["Sumter", "South Carolina", "SC"],
"29160": ["Swansea", "South Carolina", "SC"],
"29161": ["Timmonsville", "South Carolina", "SC"],
"29162": ["Turbeville", "South Carolina", "SC"],
"29163": ["Vance", "South Carolina", "SC"],
"29164": ["Wagener", "South Carolina", "SC"],
"29166": ["Ward", "South Carolina", "SC"],
"29168": ["Wedgefield", "South Carolina", "SC"],
"29169": ["West Columbia", "South Carolina", "SC"],
"29170": ["West Columbia", "South Carolina", "SC"],
"29172": ["West Columbia", "South Carolina", "SC"],
"29175": ["Westville", "South Carolina", "SC"],
"29177": ["White Rock", "South Carolina", "SC"],
"29178": ["Whitmire", "South Carolina", "SC"],
"29180": ["Winnsboro", "South Carolina", "SC"],
"29201": ["Columbia", "South Carolina", "SC"],
"29203": ["Columbia", "South Carolina", "SC"],
"29204": ["Columbia", "South Carolina", "SC"],
"29205": ["Columbia", "South Carolina", "SC"],
"29206": ["Columbia", "South Carolina", "SC"],
"29207": ["Columbia", "South Carolina", "SC"],
"29208": ["Columbia", "South Carolina", "SC"],
"29209": ["Columbia", "South Carolina", "SC"],
"29210": ["Columbia", "South Carolina", "SC"],
"29212": ["Columbia", "South Carolina", "SC"],
"29223": ["Columbia", "South Carolina", "SC"],
"29225": ["Columbia", "South Carolina", "SC"],
"29229": ["Columbia", "South Carolina", "SC"],
"29301": ["Spartanburg", "South Carolina", "SC"],
"29302": ["Spartanburg", "South Carolina", "SC"],
"29303": ["Spartanburg", "South Carolina", "SC"],
"29306": ["Spartanburg", "South Carolina", "SC"],
"29307": ["Spartanburg", "South Carolina", "SC"],
"29316": ["Boiling Springs", "South Carolina", "SC"],
"29320": ["Arcadia", "South Carolina", "SC"],
"29321": ["Buffalo", "South Carolina", "SC"],
"29322": ["Campobello", "South Carolina", "SC"],
"29323": ["Chesnee", "South Carolina", "SC"],
"29324": ["Clifton", "South Carolina", "SC"],
"29325": ["Clinton", "South Carolina", "SC"],
"29329": ["Converse", "South Carolina", "SC"],
"29330": ["Cowpens", "South Carolina", "SC"],
"29331": ["Cross Anchor", "South Carolina", "SC"],
"29332": ["Cross Hill", "South Carolina", "SC"],
"29333": ["Drayton", "South Carolina", "SC"],
"29334": ["Duncan", "South Carolina", "SC"],
"29335": ["Enoree", "South Carolina", "SC"],
"29338": ["Fingerville", "South Carolina", "SC"],
"29340": ["Gaffney", "South Carolina", "SC"],
"29341": ["Gaffney", "South Carolina", "SC"],
"29346": ["Glendale", "South Carolina", "SC"],
"29349": ["Inman", "South Carolina", "SC"],
"29351": ["Joanna", "South Carolina", "SC"],
"29353": ["Jonesville", "South Carolina", "SC"],
"29355": ["Kinards", "South Carolina", "SC"],
"29356": ["Landrum", "South Carolina", "SC"],
"29360": ["Laurens", "South Carolina", "SC"],
"29364": ["Lockhart", "South Carolina", "SC"],
"29365": ["Lyman", "South Carolina", "SC"],
"29368": ["Mayo", "South Carolina", "SC"],
"29369": ["Moore", "South Carolina", "SC"],
"29370": ["Mountville", "South Carolina", "SC"],
"29372": ["Pacolet", "South Carolina", "SC"],
"29373": ["Pacolet Mills", "South Carolina", "SC"],
"29374": ["Pauline", "South Carolina", "SC"],
"29375": ["Reidville", "South Carolina", "SC"],
"29376": ["Roebuck", "South Carolina", "SC"],
"29377": ["Startex", "South Carolina", "SC"],
"29378": ["Una", "South Carolina", "SC"],
"29379": ["Union", "South Carolina", "SC"],
"29384": ["Waterloo", "South Carolina", "SC"],
"29385": ["Wellford", "South Carolina", "SC"],
"29388": ["Woodruff", "South Carolina", "SC"],
"29401": ["Charleston", "South Carolina", "SC"],
"29403": ["Charleston", "South Carolina", "SC"],
"29404": ["Charleston Afb", "South Carolina", "SC"],
"29405": ["North Charleston", "South Carolina", "SC"],
"29406": ["Charleston", "South Carolina", "SC"],
"29407": ["Charleston", "South Carolina", "SC"],
"29409": ["Charleston", "South Carolina", "SC"],
"29410": ["Hanahan", "South Carolina", "SC"],
"29412": ["Charleston", "South Carolina", "SC"],
"29414": ["Charleston", "South Carolina", "SC"],
"29418": ["North Charleston", "South Carolina", "SC"],
"29420": ["North Charleston", "South Carolina", "SC"],
"29424": ["Charleston", "South Carolina", "SC"],
"29425": ["Charleston", "South Carolina", "SC"],
"29426": ["Adams Run", "South Carolina", "SC"],
"29429": ["Awendaw", "South Carolina", "SC"],
"29431": ["Bonneau", "South Carolina", "SC"],
"29432": ["Branchville", "South Carolina", "SC"],
"29434": ["Cordesville", "South Carolina", "SC"],
"29435": ["Cottageville", "South Carolina", "SC"],
"29436": ["Cross", "South Carolina", "SC"],
"29437": ["Dorchester", "South Carolina", "SC"],
"29438": ["Edisto Island", "South Carolina", "SC"],
"29439": ["Folly Beach", "South Carolina", "SC"],
"29440": ["Georgetown", "South Carolina", "SC"],
"29445": ["Goose Creek", "South Carolina", "SC"],
"29446": ["Green Pond", "South Carolina", "SC"],
"29448": ["Harleyville", "South Carolina", "SC"],
"29449": ["Hollywood", "South Carolina", "SC"],
"29450": ["Huger", "South Carolina", "SC"],
"29451": ["Isle Of Palms", "South Carolina", "SC"],
"29452": ["Jacksonboro", "South Carolina", "SC"],
"29453": ["Jamestown", "South Carolina", "SC"],
"29455": ["Johns Island", "South Carolina", "SC"],
"29456": ["Ladson", "South Carolina", "SC"],
"29458": ["McClellanville", "South Carolina", "SC"],
"29461": ["Moncks Corner", "South Carolina", "SC"],
"29464": ["Mount Pleasant", "South Carolina", "SC"],
"29466": ["Mount Pleasant", "South Carolina", "SC"],
"29468": ["Pineville", "South Carolina", "SC"],
"29469": ["Pinopolis", "South Carolina", "SC"],
"29470": ["Ravenel", "South Carolina", "SC"],
"29471": ["Reevesville", "South Carolina", "SC"],
"29472": ["Ridgeville", "South Carolina", "SC"],
"29474": ["Round O", "South Carolina", "SC"],
"29475": ["Ruffin", "South Carolina", "SC"],
"29477": ["Saint George", "South Carolina", "SC"],
"29479": ["Saint Stephen", "South Carolina", "SC"],
"29481": ["Smoaks", "South Carolina", "SC"],
"29482": ["Sullivans Island", "South Carolina", "SC"],
"29483": ["Summerville", "South Carolina", "SC"],
"29485": ["Summerville", "South Carolina", "SC"],
"29486": ["Summerville", "South Carolina", "SC"],
"29487": ["Wadmalaw Island", "South Carolina", "SC"],
"29488": ["Walterboro", "South Carolina", "SC"],
"29492": ["Charleston", "South Carolina", "SC"],
"29493": ["Williams", "South Carolina", "SC"],
"29501": ["Florence", "South Carolina", "SC"],
"29505": ["Florence", "South Carolina", "SC"],
"29506": ["Florence", "South Carolina", "SC"],
"29510": ["Andrews", "South Carolina", "SC"],
"29511": ["Aynor", "South Carolina", "SC"],
"29512": ["Bennettsville", "South Carolina", "SC"],
"29516": ["Blenheim", "South Carolina", "SC"],
"29518": ["Cades", "South Carolina", "SC"],
"29519": ["Centenary", "South Carolina", "SC"],
"29520": ["Cheraw", "South Carolina", "SC"],
"29525": ["Clio", "South Carolina", "SC"],
"29526": ["Conway", "South Carolina", "SC"],
"29527": ["Conway", "South Carolina", "SC"],
"29530": ["Coward", "South Carolina", "SC"],
"29532": ["Darlington", "South Carolina", "SC"],
"29536": ["Dillon", "South Carolina", "SC"],
"29540": ["Darlington", "South Carolina", "SC"],
"29541": ["Effingham", "South Carolina", "SC"],
"29543": ["Fork", "South Carolina", "SC"],
"29544": ["Galivants Ferry", "South Carolina", "SC"],
"29545": ["Green Sea", "South Carolina", "SC"],
"29546": ["Gresham", "South Carolina", "SC"],
"29547": ["Hamer", "South Carolina", "SC"],
"29550": ["Hartsville", "South Carolina", "SC"],
"29554": ["Hemingway", "South Carolina", "SC"],
"29555": ["Johnsonville", "South Carolina", "SC"],
"29556": ["Kingstree", "South Carolina", "SC"],
"29560": ["Lake City", "South Carolina", "SC"],
"29563": ["Lake View", "South Carolina", "SC"],
"29564": ["Lane", "South Carolina", "SC"],
"29565": ["Latta", "South Carolina", "SC"],
"29566": ["Little River", "South Carolina", "SC"],
"29567": ["Little Rock", "South Carolina", "SC"],
"29568": ["Longs", "South Carolina", "SC"],
"29569": ["Loris", "South Carolina", "SC"],
"29570": ["McColl", "South Carolina", "SC"],
"29571": ["Marion", "South Carolina", "SC"],
"29572": ["Myrtle Beach", "South Carolina", "SC"],
"29574": ["Mullins", "South Carolina", "SC"],
"29575": ["Myrtle Beach", "South Carolina", "SC"],
"29576": ["Murrells Inlet", "South Carolina", "SC"],
"29577": ["Myrtle Beach", "South Carolina", "SC"],
"29579": ["Myrtle Beach", "South Carolina", "SC"],
"29580": ["Nesmith", "South Carolina", "SC"],
"29581": ["Nichols", "South Carolina", "SC"],
"29582": ["North Myrtle Beach", "South Carolina", "SC"],
"29583": ["Pamplico", "South Carolina", "SC"],
"29584": ["Patrick", "South Carolina", "SC"],
"29585": ["Pawleys Island", "South Carolina", "SC"],
"29588": ["Myrtle Beach", "South Carolina", "SC"],
"29589": ["Rains", "South Carolina", "SC"],
"29590": ["Salters", "South Carolina", "SC"],
"29591": ["Scranton", "South Carolina", "SC"],
"29592": ["Sellers", "South Carolina", "SC"],
"29593": ["Society Hill", "South Carolina", "SC"],
"29594": ["Tatum", "South Carolina", "SC"],
"29596": ["Wallace", "South Carolina", "SC"],
"29601": ["Greenville", "South Carolina", "SC"],
"29605": ["Greenville", "South Carolina", "SC"],
"29607": ["Greenville", "South Carolina", "SC"],
"29609": ["Greenville", "South Carolina", "SC"],
"29611": ["Greenville", "South Carolina", "SC"],
"29613": ["Greenville", "South Carolina", "SC"],
"29614": ["Greenville", "South Carolina", "SC"],
"29615": ["Greenville", "South Carolina", "SC"],
"29617": ["Greenville", "South Carolina", "SC"],
"29620": ["Abbeville", "South Carolina", "SC"],
"29621": ["Anderson", "South Carolina", "SC"],
"29624": ["Anderson", "South Carolina", "SC"],
"29625": ["Anderson", "South Carolina", "SC"],
"29626": ["Anderson", "South Carolina", "SC"],
"29627": ["Belton", "South Carolina", "SC"],
"29628": ["Calhoun Falls", "South Carolina", "SC"],
"29630": ["Central", "South Carolina", "SC"],
"29631": ["Clemson", "South Carolina", "SC"],
"29634": ["Clemson", "South Carolina", "SC"],
"29635": ["Cleveland", "South Carolina", "SC"],
"29638": ["Donalds", "South Carolina", "SC"],
"29639": ["Due West", "South Carolina", "SC"],
"29640": ["Easley", "South Carolina", "SC"],
"29642": ["Easley", "South Carolina", "SC"],
"29643": ["Fair Play", "South Carolina", "SC"],
"29644": ["Fountain Inn", "South Carolina", "SC"],
"29645": ["Gray Court", "South Carolina", "SC"],
"29646": ["Greenwood", "South Carolina", "SC"],
"29649": ["Greenwood", "South Carolina", "SC"],
"29650": ["Greer", "South Carolina", "SC"],
"29651": ["Greer", "South Carolina", "SC"],
"29653": ["Hodges", "South Carolina", "SC"],
"29654": ["Honea Path", "South Carolina", "SC"],
"29655": ["Iva", "South Carolina", "SC"],
"29657": ["Liberty", "South Carolina", "SC"],
"29658": ["Long Creek", "South Carolina", "SC"],
"29659": ["Lowndesville", "South Carolina", "SC"],
"29661": ["Marietta", "South Carolina", "SC"],
"29662": ["Mauldin", "South Carolina", "SC"],
"29664": ["Mountain Rest", "South Carolina", "SC"],
"29665": ["Newry", "South Carolina", "SC"],
"29666": ["Ninety Six", "South Carolina", "SC"],
"29667": ["Norris", "South Carolina", "SC"],
"29669": ["Pelzer", "South Carolina", "SC"],
"29670": ["Pendleton", "South Carolina", "SC"],
"29671": ["Pickens", "South Carolina", "SC"],
"29672": ["Seneca", "South Carolina", "SC"],
"29673": ["Piedmont", "South Carolina", "SC"],
"29676": ["Salem", "South Carolina", "SC"],
"29678": ["Seneca", "South Carolina", "SC"],
"29680": ["Simpsonville", "South Carolina", "SC"],
"29681": ["Simpsonville", "South Carolina", "SC"],
"29682": ["Six Mile", "South Carolina", "SC"],
"29683": ["Slater", "South Carolina", "SC"],
"29684": ["Starr", "South Carolina", "SC"],
"29685": ["Sunset", "South Carolina", "SC"],
"29686": ["Tamassee", "South Carolina", "SC"],
"29687": ["Taylors", "South Carolina", "SC"],
"29689": ["Townville", "South Carolina", "SC"],
"29690": ["Travelers Rest", "South Carolina", "SC"],
"29691": ["Walhalla", "South Carolina", "SC"],
"29692": ["Ware Shoals", "South Carolina", "SC"],
"29693": ["Westminster", "South Carolina", "SC"],
"29696": ["West Union", "South Carolina", "SC"],
"29697": ["Williamston", "South Carolina", "SC"],
"29702": ["Blacksburg", "South Carolina", "SC"],
"29704": ["Catawba", "South Carolina", "SC"],
"29706": ["Chester", "South Carolina", "SC"],
"29707": ["Fort Mill", "South Carolina", "SC"],
"29708": ["Fort Mill", "South Carolina", "SC"],
"29709": ["Chesterfield", "South Carolina", "SC"],
"29710": ["Clover", "South Carolina", "SC"],
"29712": ["Edgemoor", "South Carolina", "SC"],
"29714": ["Fort Lawn", "South Carolina", "SC"],
"29715": ["Fort Mill", "South Carolina", "SC"],
"29717": ["Hickory Grove", "South Carolina", "SC"],
"29718": ["Jefferson", "South Carolina", "SC"],
"29720": ["Lancaster", "South Carolina", "SC"],
"29724": ["Lando", "South Carolina", "SC"],
"29726": ["McConnells", "South Carolina", "SC"],
"29727": ["Mount Croghan", "South Carolina", "SC"],
"29728": ["Pageland", "South Carolina", "SC"],
"29729": ["Richburg", "South Carolina", "SC"],
"29730": ["Rock Hill", "South Carolina", "SC"],
"29732": ["Rock Hill", "South Carolina", "SC"],
"29733": ["Rock Hill", "South Carolina", "SC"],
"29741": ["Ruby", "South Carolina", "SC"],
"29742": ["Sharon", "South Carolina", "SC"],
"29743": ["Smyrna", "South Carolina", "SC"],
"29745": ["York", "South Carolina", "SC"],
"29801": ["Aiken", "South Carolina", "SC"],
"29803": ["Aiken", "South Carolina", "SC"],
"29805": ["Aiken", "South Carolina", "SC"],
"29809": ["New Ellenton", "South Carolina", "SC"],
"29810": ["Allendale", "South Carolina", "SC"],
"29812": ["Barnwell", "South Carolina", "SC"],
"29816": ["Bath", "South Carolina", "SC"],
"29817": ["Blackville", "South Carolina", "SC"],
"29819": ["Bradley", "South Carolina", "SC"],
"29821": ["Clarks Hill", "South Carolina", "SC"],
"29824": ["Edgefield", "South Carolina", "SC"],
"29826": ["Elko", "South Carolina", "SC"],
"29827": ["Fairfax", "South Carolina", "SC"],
"29828": ["Gloverville", "South Carolina", "SC"],
"29829": ["Graniteville", "South Carolina", "SC"],
"29831": ["Jackson", "South Carolina", "SC"],
"29832": ["Johnston", "South Carolina", "SC"],
"29834": ["Langley", "South Carolina", "SC"],
"29835": ["McCormick", "South Carolina", "SC"],
"29836": ["Martin", "South Carolina", "SC"],
"29838": ["Modoc", "South Carolina", "SC"],
"29840": ["Mount Carmel", "South Carolina", "SC"],
"29841": ["North Augusta", "South Carolina", "SC"],
"29842": ["Beech Island", "South Carolina", "SC"],
"29843": ["Olar", "South Carolina", "SC"],
"29844": ["Parksville", "South Carolina", "SC"],
"29845": ["Plum Branch", "South Carolina", "SC"],
"29847": ["Trenton", "South Carolina", "SC"],
"29848": ["Troy", "South Carolina", "SC"],
"29849": ["Ulmer", "South Carolina", "SC"],
"29851": ["Warrenville", "South Carolina", "SC"],
"29853": ["Williston", "South Carolina", "SC"],
"29856": ["Windsor", "South Carolina", "SC"],
"29860": ["North Augusta", "South Carolina", "SC"],
"29899": ["McCormick", "South Carolina", "SC"],
"29902": ["Beaufort", "South Carolina", "SC"],
"29904": ["Beaufort", "South Carolina", "SC"],
"29905": ["Parris Island", "South Carolina", "SC"],
"29906": ["Beaufort", "South Carolina", "SC"],
"29907": ["Beaufort", "South Carolina", "SC"],
"29909": ["Okatie", "South Carolina", "SC"],
"29910": ["Bluffton", "South Carolina", "SC"],
"29911": ["Brunson", "South Carolina", "SC"],
"29912": ["Coosawhatchie", "South Carolina", "SC"],
"29915": ["Daufuskie Island", "South Carolina", "SC"],
"29916": ["Early Branch", "South Carolina", "SC"],
"29918": ["Estill", "South Carolina", "SC"],
"29920": ["Saint Helena Island", "South Carolina", "SC"],
"29921": ["Furman", "South Carolina", "SC"],
"29922": ["Garnett", "South Carolina", "SC"],
"29923": ["Gifford", "South Carolina", "SC"],
"29924": ["Hampton", "South Carolina", "SC"],
"29926": ["Hilton Head Island", "South Carolina", "SC"],
"29927": ["Hardeeville", "South Carolina", "SC"],
"29928": ["Hilton Head Island", "South Carolina", "SC"],
"29929": ["Islandton", "South Carolina", "SC"],
"29932": ["Luray", "South Carolina", "SC"],
"29934": ["Pineland", "South Carolina", "SC"],
"29935": ["Port Royal", "South Carolina", "SC"],
"29936": ["Ridgeland", "South Carolina", "SC"],
"29939": ["Scotia", "South Carolina", "SC"],
"29940": ["Seabrook", "South Carolina", "SC"],
"29941": ["Sheldon", "South Carolina", "SC"],
"29943": ["Tillman", "South Carolina", "SC"],
"29944": ["Varnville", "South Carolina", "SC"],
"29945": ["Yemassee", "South Carolina", "SC"],
"30002": ["Avondale Estates", "Georgia", "GA"],
"30004": ["Alpharetta", "Georgia", "GA"],
"30005": ["Alpharetta", "Georgia", "GA"],
"30008": ["Marietta", "Georgia", "GA"],
"30009": ["Alpharetta", "Georgia", "GA"],
"30011": ["Auburn", "Georgia", "GA"],
"30012": ["Conyers", "Georgia", "GA"],
"30013": ["Conyers", "Georgia", "GA"],
"30014": ["Covington", "Georgia", "GA"],
"30016": ["Covington", "Georgia", "GA"],
"30017": ["Grayson", "Georgia", "GA"],
"30018": ["Jersey", "Georgia", "GA"],
"30019": ["Dacula", "Georgia", "GA"],
"30021": ["Clarkston", "Georgia", "GA"],
"30022": ["Alpharetta", "Georgia", "GA"],
"30024": ["Suwanee", "Georgia", "GA"],
"30025": ["Social Circle", "Georgia", "GA"],
"30028": ["Cumming", "Georgia", "GA"],
"30030": ["Decatur", "Georgia", "GA"],
"30032": ["Decatur", "Georgia", "GA"],
"30033": ["Decatur", "Georgia", "GA"],
"30034": ["Decatur", "Georgia", "GA"],
"30035": ["Decatur", "Georgia", "GA"],
"30038": ["Lithonia", "Georgia", "GA"],
"30039": ["Snellville", "Georgia", "GA"],
"30040": ["Cumming", "Georgia", "GA"],
"30041": ["Cumming", "Georgia", "GA"],
"30043": ["Lawrenceville", "Georgia", "GA"],
"30044": ["Lawrenceville", "Georgia", "GA"],
"30045": ["Lawrenceville", "Georgia", "GA"],
"30046": ["Lawrenceville", "Georgia", "GA"],
"30047": ["Lilburn", "Georgia", "GA"],
"30052": ["Loganville", "Georgia", "GA"],
"30054": ["Oxford", "Georgia", "GA"],
"30055": ["Mansfield", "Georgia", "GA"],
"30056": ["Newborn", "Georgia", "GA"],
"30058": ["Lithonia", "Georgia", "GA"],
"30060": ["Marietta", "Georgia", "GA"],
"30062": ["Marietta", "Georgia", "GA"],
"30064": ["Marietta", "Georgia", "GA"],
"30066": ["Marietta", "Georgia", "GA"],
"30067": ["Marietta", "Georgia", "GA"],
"30068": ["Marietta", "Georgia", "GA"],
"30070": ["Porterdale", "Georgia", "GA"],
"30071": ["Norcross", "Georgia", "GA"],
"30072": ["Pine Lake", "Georgia", "GA"],
"30075": ["Roswell", "Georgia", "GA"],
"30076": ["Roswell", "Georgia", "GA"],
"30078": ["Snellville", "Georgia", "GA"],
"30079": ["Scottdale", "Georgia", "GA"],
"30080": ["Smyrna", "Georgia", "GA"],
"30082": ["Smyrna", "Georgia", "GA"],
"30083": ["Stone Mountain", "Georgia", "GA"],
"30084": ["Tucker", "Georgia", "GA"],
"30087": ["Stone Mountain", "Georgia", "GA"],
"30088": ["Stone Mountain", "Georgia", "GA"],
"30090": ["Marietta", "Georgia", "GA"],
"30092": ["Peachtree Corners", "Georgia", "GA"],
"30093": ["Norcross", "Georgia", "GA"],
"30094": ["Conyers", "Georgia", "GA"],
"30096": ["Duluth", "Georgia", "GA"],
"30097": ["Duluth", "Georgia", "GA"],
"30101": ["Acworth", "Georgia", "GA"],
"30102": ["Acworth", "Georgia", "GA"],
"30103": ["Adairsville", "Georgia", "GA"],
"30104": ["Aragon", "Georgia", "GA"],
"30105": ["Armuchee", "Georgia", "GA"],
"30106": ["Austell", "Georgia", "GA"],
"30107": ["Ball Ground", "Georgia", "GA"],
"30108": ["Bowdon", "Georgia", "GA"],
"30110": ["Bremen", "Georgia", "GA"],
"30111": ["Clarkdale", "Georgia", "GA"],
"30113": ["Buchanan", "Georgia", "GA"],
"30114": ["Canton", "Georgia", "GA"],
"30115": ["Canton", "Georgia", "GA"],
"30116": ["Carrollton", "Georgia", "GA"],
"30117": ["Carrollton", "Georgia", "GA"],
"30118": ["Carrollton", "Georgia", "GA"],
"30120": ["Cartersville", "Georgia", "GA"],
"30121": ["Cartersville", "Georgia", "GA"],
"30122": ["Lithia Springs", "Georgia", "GA"],
"30124": ["Cave Spring", "Georgia", "GA"],
"30125": ["Cedartown", "Georgia", "GA"],
"30126": ["Mableton", "Georgia", "GA"],
"30127": ["Powder Springs", "Georgia", "GA"],
"30132": ["Dallas", "Georgia", "GA"],
"30134": ["Douglasville", "Georgia", "GA"],
"30135": ["Douglasville", "Georgia", "GA"],
"30137": ["Emerson", "Georgia", "GA"],
"30139": ["Fairmount", "Georgia", "GA"],
"30141": ["Hiram", "Georgia", "GA"],
"30143": ["Jasper", "Georgia", "GA"],
"30144": ["Kennesaw", "Georgia", "GA"],
"30145": ["Kingston", "Georgia", "GA"],
"30147": ["Lindale", "Georgia", "GA"],
"30148": ["Marble Hill", "Georgia", "GA"],
"30149": ["Mount Berry", "Georgia", "GA"],
"30152": ["Kennesaw", "Georgia", "GA"],
"30153": ["Rockmart", "Georgia", "GA"],
"30157": ["Dallas", "Georgia", "GA"],
"30161": ["Rome", "Georgia", "GA"],
"30165": ["Rome", "Georgia", "GA"],
"30168": ["Austell", "Georgia", "GA"],
"30170": ["Roopville", "Georgia", "GA"],
"30171": ["Rydal", "Georgia", "GA"],
"30173": ["Silver Creek", "Georgia", "GA"],
"30175": ["Talking Rock", "Georgia", "GA"],
"30176": ["Tallapoosa", "Georgia", "GA"],
"30177": ["Tate", "Georgia", "GA"],
"30178": ["Taylorsville", "Georgia", "GA"],
"30179": ["Temple", "Georgia", "GA"],
"30180": ["Villa Rica", "Georgia", "GA"],
"30182": ["Waco", "Georgia", "GA"],
"30183": ["Waleska", "Georgia", "GA"],
"30184": ["White", "Georgia", "GA"],
"30185": ["Whitesburg", "Georgia", "GA"],
"30187": ["Winston", "Georgia", "GA"],
"30188": ["Woodstock", "Georgia", "GA"],
"30189": ["Woodstock", "Georgia", "GA"],
"30204": ["Barnesville", "Georgia", "GA"],
"30205": ["Brooks", "Georgia", "GA"],
"30206": ["Concord", "Georgia", "GA"],
"30213": ["Fairburn", "Georgia", "GA"],
"30214": ["Fayetteville", "Georgia", "GA"],
"30215": ["Fayetteville", "Georgia", "GA"],
"30216": ["Flovilla", "Georgia", "GA"],
"30217": ["Franklin", "Georgia", "GA"],
"30218": ["Gay", "Georgia", "GA"],
"30220": ["Grantville", "Georgia", "GA"],
"30222": ["Greenville", "Georgia", "GA"],
"30223": ["Griffin", "Georgia", "GA"],
"30224": ["Griffin", "Georgia", "GA"],
"30228": ["Hampton", "Georgia", "GA"],
"30229": ["Haralson", "Georgia", "GA"],
"30230": ["Hogansville", "Georgia", "GA"],
"30233": ["Jackson", "Georgia", "GA"],
"30234": ["Jenkinsburg", "Georgia", "GA"],
"30236": ["Jonesboro", "Georgia", "GA"],
"30238": ["Jonesboro", "Georgia", "GA"],
"30240": ["Lagrange", "Georgia", "GA"],
"30241": ["Lagrange", "Georgia", "GA"],
"30248": ["Locust Grove", "Georgia", "GA"],
"30250": ["Lovejoy", "Georgia", "GA"],
"30251": ["Luthersville", "Georgia", "GA"],
"30252": ["Mcdonough", "Georgia", "GA"],
"30253": ["Mcdonough", "Georgia", "GA"],
"30256": ["Meansville", "Georgia", "GA"],
"30257": ["Milner", "Georgia", "GA"],
"30258": ["Molena", "Georgia", "GA"],
"30259": ["Moreland", "Georgia", "GA"],
"30260": ["Morrow", "Georgia", "GA"],
"30263": ["Newnan", "Georgia", "GA"],
"30265": ["Newnan", "Georgia", "GA"],
"30268": ["Palmetto", "Georgia", "GA"],
"30269": ["Peachtree City", "Georgia", "GA"],
"30272": ["Red Oak", "Georgia", "GA"],
"30273": ["Rex", "Georgia", "GA"],
"30274": ["Riverdale", "Georgia", "GA"],
"30275": ["Sargent", "Georgia", "GA"],
"30276": ["Senoia", "Georgia", "GA"],
"30277": ["Sharpsburg", "Georgia", "GA"],
"30281": ["Stockbridge", "Georgia", "GA"],
"30284": ["Sunny Side", "Georgia", "GA"],
"30285": ["The Rock", "Georgia", "GA"],
"30286": ["Thomaston", "Georgia", "GA"],
"30288": ["Conley", "Georgia", "GA"],
"30289": ["Turin", "Georgia", "GA"],
"30290": ["Tyrone", "Georgia", "GA"],
"30291": ["Union City", "Georgia", "GA"],
"30292": ["Williamson", "Georgia", "GA"],
"30293": ["Woodbury", "Georgia", "GA"],
"30294": ["Ellenwood", "Georgia", "GA"],
"30295": ["Zebulon", "Georgia", "GA"],
"30296": ["Riverdale", "Georgia", "GA"],
"30297": ["Forest Park", "Georgia", "GA"],
"30303": ["Atlanta", "Georgia", "GA"],
"30305": ["Atlanta", "Georgia", "GA"],
"30306": ["Atlanta", "Georgia", "GA"],
"30307": ["Atlanta", "Georgia", "GA"],
"30308": ["Atlanta", "Georgia", "GA"],
"30309": ["Atlanta", "Georgia", "GA"],
"30310": ["Atlanta", "Georgia", "GA"],
"30311": ["Atlanta", "Georgia", "GA"],
"30312": ["Atlanta", "Georgia", "GA"],
"30313": ["Atlanta", "Georgia", "GA"],
"30314": ["Atlanta", "Georgia", "GA"],
"30315": ["Atlanta", "Georgia", "GA"],
"30316": ["Atlanta", "Georgia", "GA"],
"30317": ["Atlanta", "Georgia", "GA"],
"30318": ["Atlanta", "Georgia", "GA"],
"30319": ["Atlanta", "Georgia", "GA"],
"30322": ["Atlanta", "Georgia", "GA"],
"30324": ["Atlanta", "Georgia", "GA"],
"30326": ["Atlanta", "Georgia", "GA"],
"30327": ["Atlanta", "Georgia", "GA"],
"30328": ["Atlanta", "Georgia", "GA"],
"30329": ["Atlanta", "Georgia", "GA"],
"30331": ["Atlanta", "Georgia", "GA"],
"30332": ["Atlanta", "Georgia", "GA"],
"30334": ["Atlanta", "Georgia", "GA"],
"30336": ["Atlanta", "Georgia", "GA"],
"30337": ["Atlanta", "Georgia", "GA"],
"30338": ["Atlanta", "Georgia", "GA"],
"30339": ["Atlanta", "Georgia", "GA"],
"30340": ["Atlanta", "Georgia", "GA"],
"30341": ["Atlanta", "Georgia", "GA"],
"30342": ["Atlanta", "Georgia", "GA"],
"30344": ["Atlanta", "Georgia", "GA"],
"30345": ["Atlanta", "Georgia", "GA"],
"30346": ["Atlanta", "Georgia", "GA"],
"30349": ["Atlanta", "Georgia", "GA"],
"30350": ["Atlanta", "Georgia", "GA"],
"30354": ["Atlanta", "Georgia", "GA"],
"30360": ["Atlanta", "Georgia", "GA"],
"30363": ["Atlanta", "Georgia", "GA"],
"30401": ["Swainsboro", "Georgia", "GA"],
"30410": ["Ailey", "Georgia", "GA"],
"30411": ["Alamo", "Georgia", "GA"],
"30412": ["Alston", "Georgia", "GA"],
"30413": ["Bartow", "Georgia", "GA"],
"30414": ["Bellville", "Georgia", "GA"],
"30415": ["Brooklet", "Georgia", "GA"],
"30417": ["Claxton", "Georgia", "GA"],
"30420": ["Cobbtown", "Georgia", "GA"],
"30421": ["Collins", "Georgia", "GA"],
"30423": ["Daisy", "Georgia", "GA"],
"30425": ["Garfield", "Georgia", "GA"],
"30426": ["Girard", "Georgia", "GA"],
"30427": ["Glennville", "Georgia", "GA"],
"30428": ["Glenwood", "Georgia", "GA"],
"30429": ["Hagan", "Georgia", "GA"],
"30434": ["Louisville", "Georgia", "GA"],
"30436": ["Lyons", "Georgia", "GA"],
"30439": ["Metter", "Georgia", "GA"],
"30441": ["Midville", "Georgia", "GA"],
"30442": ["Millen", "Georgia", "GA"],
"30445": ["Mount Vernon", "Georgia", "GA"],
"30446": ["Newington", "Georgia", "GA"],
"30448": ["Nunez", "Georgia", "GA"],
"30449": ["Oliver", "Georgia", "GA"],
"30450": ["Portal", "Georgia", "GA"],
"30451": ["Pulaski", "Georgia", "GA"],
"30452": ["Register", "Georgia", "GA"],
"30453": ["Reidsville", "Georgia", "GA"],
"30454": ["Rockledge", "Georgia", "GA"],
"30455": ["Rocky Ford", "Georgia", "GA"],
"30456": ["Sardis", "Georgia", "GA"],
"30457": ["Soperton", "Georgia", "GA"],
"30458": ["Statesboro", "Georgia", "GA"],
"30460": ["Statesboro", "Georgia", "GA"],
"30461": ["Statesboro", "Georgia", "GA"],
"30464": ["Stillmore", "Georgia", "GA"],
"30467": ["Sylvania", "Georgia", "GA"],
"30470": ["Tarrytown", "Georgia", "GA"],
"30471": ["Twin City", "Georgia", "GA"],
"30473": ["Uvalda", "Georgia", "GA"],
"30474": ["Vidalia", "Georgia", "GA"],
"30477": ["Wadley", "Georgia", "GA"],
"30501": ["Gainesville", "Georgia", "GA"],
"30504": ["Gainesville", "Georgia", "GA"],
"30506": ["Gainesville", "Georgia", "GA"],
"30507": ["Gainesville", "Georgia", "GA"],
"30510": ["Alto", "Georgia", "GA"],
"30511": ["Baldwin", "Georgia", "GA"],
"30512": ["Blairsville", "Georgia", "GA"],
"30513": ["Blue Ridge", "Georgia", "GA"],
"30516": ["Bowersville", "Georgia", "GA"],
"30517": ["Braselton", "Georgia", "GA"],
"30518": ["Buford", "Georgia", "GA"],
"30519": ["Buford", "Georgia", "GA"],
"30520": ["Canon", "Georgia", "GA"],
"30521": ["Carnesville", "Georgia", "GA"],
"30522": ["Cherry Log", "Georgia", "GA"],
"30523": ["Clarkesville", "Georgia", "GA"],
"30525": ["Clayton", "Georgia", "GA"],
"30527": ["Clermont", "Georgia", "GA"],
"30528": ["Cleveland", "Georgia", "GA"],
"30529": ["Commerce", "Georgia", "GA"],
"30530": ["Commerce", "Georgia", "GA"],
"30531": ["Cornelia", "Georgia", "GA"],
"30533": ["Dahlonega", "Georgia", "GA"],
"30534": ["Dawsonville", "Georgia", "GA"],
"30535": ["Demorest", "Georgia", "GA"],
"30536": ["Ellijay", "Georgia", "GA"],
"30537": ["Dillard", "Georgia", "GA"],
"30538": ["Eastanollee", "Georgia", "GA"],
"30539": ["East Ellijay", "Georgia", "GA"],
"30540": ["Ellijay", "Georgia", "GA"],
"30541": ["Epworth", "Georgia", "GA"],
"30542": ["Flowery Branch", "Georgia", "GA"],
"30543": ["Gillsville", "Georgia", "GA"],
"30545": ["Helen", "Georgia", "GA"],
"30546": ["Hiawassee", "Georgia", "GA"],
"30547": ["Homer", "Georgia", "GA"],
"30548": ["Hoschton", "Georgia", "GA"],
"30549": ["Jefferson", "Georgia", "GA"],
"30552": ["Lakemont", "Georgia", "GA"],
"30553": ["Lavonia", "Georgia", "GA"],
"30554": ["Lula", "Georgia", "GA"],
"30555": ["McCaysville", "Georgia", "GA"],
"30557": ["Martin", "Georgia", "GA"],
"30558": ["Maysville", "Georgia", "GA"],
"30559": ["Mineral Bluff", "Georgia", "GA"],
"30560": ["Morganton", "Georgia", "GA"],
"30562": ["Mountain City", "Georgia", "GA"],
"30563": ["Mount Airy", "Georgia", "GA"],
"30564": ["Murrayville", "Georgia", "GA"],
"30565": ["Nicholson", "Georgia", "GA"],
"30566": ["Oakwood", "Georgia", "GA"],
"30567": ["Pendergrass", "Georgia", "GA"],
"30568": ["Rabun Gap", "Georgia", "GA"],
"30571": ["Sautee Nacoochee", "Georgia", "GA"],
"30572": ["Suches", "Georgia", "GA"],
"30573": ["Tallulah Falls", "Georgia", "GA"],
"30575": ["Talmo", "Georgia", "GA"],
"30576": ["Tiger", "Georgia", "GA"],
"30577": ["Toccoa", "Georgia", "GA"],
"30581": ["Wiley", "Georgia", "GA"],
"30582": ["Young Harris", "Georgia", "GA"],
"30597": ["Dahlonega", "Georgia", "GA"],
"30598": ["Toccoa Falls", "Georgia", "GA"],
"30601": ["Athens", "Georgia", "GA"],
"30602": ["Athens", "Georgia", "GA"],
"30605": ["Athens", "Georgia", "GA"],
"30606": ["Athens", "Georgia", "GA"],
"30607": ["Athens", "Georgia", "GA"],
"30609": ["Athens", "Georgia", "GA"],
"30619": ["Arnoldsville", "Georgia", "GA"],
"30620": ["Bethlehem", "Georgia", "GA"],
"30621": ["Bishop", "Georgia", "GA"],
"30622": ["Bogart", "Georgia", "GA"],
"30623": ["Bostwick", "Georgia", "GA"],
"30624": ["Bowman", "Georgia", "GA"],
"30625": ["Buckhead", "Georgia", "GA"],
"30627": ["Carlton", "Georgia", "GA"],
"30628": ["Colbert", "Georgia", "GA"],
"30629": ["Comer", "Georgia", "GA"],
"30630": ["Crawford", "Georgia", "GA"],
"30631": ["Crawfordville", "Georgia", "GA"],
"30633": ["Danielsville", "Georgia", "GA"],
"30634": ["Dewy Rose", "Georgia", "GA"],
"30635": ["Elberton", "Georgia", "GA"],
"30639": ["Franklin Springs", "Georgia", "GA"],
"30641": ["Good Hope", "Georgia", "GA"],
"30642": ["Greensboro", "Georgia", "GA"],
"30643": ["Hartwell", "Georgia", "GA"],
"30646": ["Hull", "Georgia", "GA"],
"30648": ["Lexington", "Georgia", "GA"],
"30650": ["Madison", "Georgia", "GA"],
"30655": ["Monroe", "Georgia", "GA"],
"30656": ["Monroe", "Georgia", "GA"],
"30660": ["Rayle", "Georgia", "GA"],
"30662": ["Royston", "Georgia", "GA"],
"30663": ["Rutledge", "Georgia", "GA"],
"30664": ["Sharon", "Georgia", "GA"],
"30665": ["Siloam", "Georgia", "GA"],
"30666": ["Statham", "Georgia", "GA"],
"30667": ["Stephens", "Georgia", "GA"],
"30668": ["Tignall", "Georgia", "GA"],
"30669": ["Union Point", "Georgia", "GA"],
"30673": ["Washington", "Georgia", "GA"],
"30677": ["Watkinsville", "Georgia", "GA"],
"30678": ["White Plains", "Georgia", "GA"],
"30680": ["Winder", "Georgia", "GA"],
"30683": ["Winterville", "Georgia", "GA"],
"30701": ["Calhoun", "Georgia", "GA"],
"30705": ["Chatsworth", "Georgia", "GA"],
"30707": ["Chickamauga", "Georgia", "GA"],
"30708": ["Cisco", "Georgia", "GA"],
"30710": ["Cohutta", "Georgia", "GA"],
"30711": ["Crandall", "Georgia", "GA"],
"30720": ["Dalton", "Georgia", "GA"],
"30721": ["Dalton", "Georgia", "GA"],
"30724": ["Eton", "Georgia", "GA"],
"30725": ["Flintstone", "Georgia", "GA"],
"30726": ["Graysville", "Georgia", "GA"],
"30728": ["La Fayette", "Georgia", "GA"],
"30730": ["Lyerly", "Georgia", "GA"],
"30731": ["Menlo", "Georgia", "GA"],
"30733": ["Plainville", "Georgia", "GA"],
"30734": ["Ranger", "Georgia", "GA"],
"30735": ["Resaca", "Georgia", "GA"],
"30736": ["Ringgold", "Georgia", "GA"],
"30738": ["Rising Fawn", "Georgia", "GA"],
"30739": ["Rock Spring", "Georgia", "GA"],
"30740": ["Rocky Face", "Georgia", "GA"],
"30741": ["Rossville", "Georgia", "GA"],
"30742": ["Fort Oglethorpe", "Georgia", "GA"],
"30746": ["Sugar Valley", "Georgia", "GA"],
"30747": ["Summerville", "Georgia", "GA"],
"30750": ["Lookout Mountain", "Georgia", "GA"],
"30751": ["Tennga", "Georgia", "GA"],
"30752": ["Trenton", "Georgia", "GA"],
"30753": ["Trion", "Georgia", "GA"],
"30755": ["Tunnel Hill", "Georgia", "GA"],
"30756": ["Varnell", "Georgia", "GA"],
"30757": ["Wildwood", "Georgia", "GA"],
"30802": ["Appling", "Georgia", "GA"],
"30803": ["Avera", "Georgia", "GA"],
"30805": ["Blythe", "Georgia", "GA"],
"30807": ["Camak", "Georgia", "GA"],
"30808": ["Dearing", "Georgia", "GA"],
"30809": ["Evans", "Georgia", "GA"],
"30810": ["Gibson", "Georgia", "GA"],
"30812": ["Gracewood", "Georgia", "GA"],
"30813": ["Grovetown", "Georgia", "GA"],
"30814": ["Harlem", "Georgia", "GA"],
"30815": ["Hephzibah", "Georgia", "GA"],
"30816": ["Keysville", "Georgia", "GA"],
"30817": ["Lincolnton", "Georgia", "GA"],
"30818": ["Matthews", "Georgia", "GA"],
"30820": ["Mitchell", "Georgia", "GA"],
"30821": ["Norwood", "Georgia", "GA"],
"30822": ["Perkins", "Georgia", "GA"],
"30823": ["Stapleton", "Georgia", "GA"],
"30824": ["Thomson", "Georgia", "GA"],
"30828": ["Warrenton", "Georgia", "GA"],
"30830": ["Waynesboro", "Georgia", "GA"],
"30833": ["Wrens", "Georgia", "GA"],
"30901": ["Augusta", "Georgia", "GA"],
"30904": ["Augusta", "Georgia", "GA"],
"30905": ["Augusta", "Georgia", "GA"],
"30906": ["Augusta", "Georgia", "GA"],
"30907": ["Augusta", "Georgia", "GA"],
"30909": ["Augusta", "Georgia", "GA"],
"30912": ["Augusta", "Georgia", "GA"],
"31001": ["Abbeville", "Georgia", "GA"],
"31002": ["Adrian", "Georgia", "GA"],
"31003": ["Allentown", "Georgia", "GA"],
"31004": ["Bolingbroke", "Georgia", "GA"],
"31005": ["Bonaire", "Georgia", "GA"],
"31006": ["Butler", "Georgia", "GA"],
"31007": ["Byromville", "Georgia", "GA"],
"31008": ["Byron", "Georgia", "GA"],
"31009": ["Cadwell", "Georgia", "GA"],
"31011": ["Chauncey", "Georgia", "GA"],
"31012": ["Chester", "Georgia", "GA"],
"31014": ["Cochran", "Georgia", "GA"],
"31015": ["Cordele", "Georgia", "GA"],
"31016": ["Culloden", "Georgia", "GA"],
"31017": ["Danville", "Georgia", "GA"],
"31018": ["Davisboro", "Georgia", "GA"],
"31019": ["Dexter", "Georgia", "GA"],
"31020": ["Dry Branch", "Georgia", "GA"],
"31021": ["Dublin", "Georgia", "GA"],
"31022": ["Dudley", "Georgia", "GA"],
"31023": ["Eastman", "Georgia", "GA"],
"31024": ["Eatonton", "Georgia", "GA"],
"31025": ["Elko", "Georgia", "GA"],
"31027": ["East Dublin", "Georgia", "GA"],
"31028": ["Centerville", "Georgia", "GA"],
"31029": ["Forsyth", "Georgia", "GA"],
"31030": ["Fort Valley", "Georgia", "GA"],
"31031": ["Gordon", "Georgia", "GA"],
"31032": ["Gray", "Georgia", "GA"],
"31033": ["Haddock", "Georgia", "GA"],
"31034": ["Hardwick", "Georgia", "GA"],
"31035": ["Harrison", "Georgia", "GA"],
"31036": ["Hawkinsville", "Georgia", "GA"],
"31037": ["McRae Helena", "Georgia", "GA"],
"31038": ["Hillsboro", "Georgia", "GA"],
"31039": ["Howard", "Georgia", "GA"],
"31041": ["Ideal", "Georgia", "GA"],
"31042": ["Irwinton", "Georgia", "GA"],
"31044": ["Jeffersonville", "Georgia", "GA"],
"31045": ["Jewell", "Georgia", "GA"],
"31046": ["Juliette", "Georgia", "GA"],
"31047": ["Kathleen", "Georgia", "GA"],
"31049": ["Kite", "Georgia", "GA"],
"31050": ["Knoxville", "Georgia", "GA"],
"31051": ["Lilly", "Georgia", "GA"],
"31052": ["Lizella", "Georgia", "GA"],
"31054": ["McIntyre", "Georgia", "GA"],
"31055": ["McRae Helena", "Georgia", "GA"],
"31057": ["Marshallville", "Georgia", "GA"],
"31058": ["Mauk", "Georgia", "GA"],
"31060": ["Milan", "Georgia", "GA"],
"31061": ["Milledgeville", "Georgia", "GA"],
"31062": ["Milledgeville", "Georgia", "GA"],
"31063": ["Montezuma", "Georgia", "GA"],
"31064": ["Monticello", "Georgia", "GA"],
"31065": ["Montrose", "Georgia", "GA"],
"31066": ["Musella", "Georgia", "GA"],
"31067": ["Oconee", "Georgia", "GA"],
"31068": ["Oglethorpe", "Georgia", "GA"],
"31069": ["Perry", "Georgia", "GA"],
"31070": ["Pinehurst", "Georgia", "GA"],
"31071": ["Pineview", "Georgia", "GA"],
"31072": ["Pitts", "Georgia", "GA"],
"31075": ["Rentz", "Georgia", "GA"],
"31076": ["Reynolds", "Georgia", "GA"],
"31077": ["Rhine", "Georgia", "GA"],
"31078": ["Roberta", "Georgia", "GA"],
"31079": ["Rochelle", "Georgia", "GA"],
"31081": ["Rupert", "Georgia", "GA"],
"31082": ["Sandersville", "Georgia", "GA"],
"31083": ["Scotland", "Georgia", "GA"],
"31084": ["Seville", "Georgia", "GA"],
"31085": ["Shady Dale", "Georgia", "GA"],
"31087": ["Sparta", "Georgia", "GA"],
"31088": ["Warner Robins", "Georgia", "GA"],
"31089": ["Tennille", "Georgia", "GA"],
"31090": ["Toomsboro", "Georgia", "GA"],
"31091": ["Unadilla", "Georgia", "GA"],
"31092": ["Vienna", "Georgia", "GA"],
"31093": ["Warner Robins", "Georgia", "GA"],
"31094": ["Warthen", "Georgia", "GA"],
"31096": ["Wrightsville", "Georgia", "GA"],
"31097": ["Yatesville", "Georgia", "GA"],
"31098": ["Warner Robins", "Georgia", "GA"],
"31201": ["Macon", "Georgia", "GA"],
"31204": ["Macon", "Georgia", "GA"],
"31206": ["Macon", "Georgia", "GA"],
"31207": ["Macon", "Georgia", "GA"],
"31210": ["Macon", "Georgia", "GA"],
"31211": ["Macon", "Georgia", "GA"],
"31213": ["Macon", "Georgia", "GA"],
"31216": ["Macon", "Georgia", "GA"],
"31217": ["Macon", "Georgia", "GA"],
"31220": ["Macon", "Georgia", "GA"],
"31301": ["Allenhurst", "Georgia", "GA"],
"31302": ["Bloomingdale", "Georgia", "GA"],
"31303": ["Clyo", "Georgia", "GA"],
"31305": ["Darien", "Georgia", "GA"],
"31307": ["Eden", "Georgia", "GA"],
"31308": ["Ellabell", "Georgia", "GA"],
"31309": ["Fleming", "Georgia", "GA"],
"31312": ["Guyton", "Georgia", "GA"],
"31313": ["Hinesville", "Georgia", "GA"],
"31314": ["Fort Stewart", "Georgia", "GA"],
"31315": ["Fort Stewart", "Georgia", "GA"],
"31316": ["Ludowici", "Georgia", "GA"],
"31318": ["Meldrim", "Georgia", "GA"],
"31320": ["Midway", "Georgia", "GA"],
"31321": ["Pembroke", "Georgia", "GA"],
"31322": ["Pooler", "Georgia", "GA"],
"31323": ["Riceboro", "Georgia", "GA"],
"31324": ["Richmond Hill", "Georgia", "GA"],
"31326": ["Rincon", "Georgia", "GA"],
"31327": ["Sapelo Island", "Georgia", "GA"],
"31328": ["Tybee Island", "Georgia", "GA"],
"31329": ["Springfield", "Georgia", "GA"],
"31331": ["Townsend", "Georgia", "GA"],
"31333": ["Walthourville", "Georgia", "GA"],
"31401": ["Savannah", "Georgia", "GA"],
"31404": ["Savannah", "Georgia", "GA"],
"31405": ["Savannah", "Georgia", "GA"],
"31406": ["Savannah", "Georgia", "GA"],
"31407": ["Port Wentworth", "Georgia", "GA"],
"31408": ["Savannah", "Georgia", "GA"],
"31409": ["Savannah", "Georgia", "GA"],
"31410": ["Savannah", "Georgia", "GA"],
"31411": ["Savannah", "Georgia", "GA"],
"31415": ["Savannah", "Georgia", "GA"],
"31419": ["Savannah", "Georgia", "GA"],
"31421": ["Savannah", "Georgia", "GA"],
"31501": ["Waycross", "Georgia", "GA"],
"31503": ["Waycross", "Georgia", "GA"],
"31510": ["Alma", "Georgia", "GA"],
"31512": ["Ambrose", "Georgia", "GA"],
"31513": ["Baxley", "Georgia", "GA"],
"31516": ["Blackshear", "Georgia", "GA"],
"31518": ["Bristol", "Georgia", "GA"],
"31519": ["Broxton", "Georgia", "GA"],
"31520": ["Brunswick", "Georgia", "GA"],
"31522": ["Saint Simons Island", "Georgia", "GA"],
"31523": ["Brunswick", "Georgia", "GA"],
"31524": ["Brunswick", "Georgia", "GA"],
"31525": ["Brunswick", "Georgia", "GA"],
"31527": ["Jekyll Island", "Georgia", "GA"],
"31532": ["Denton", "Georgia", "GA"],
"31533": ["Douglas", "Georgia", "GA"],
"31535": ["Douglas", "Georgia", "GA"],
"31537": ["Folkston", "Georgia", "GA"],
"31539": ["Hazlehurst", "Georgia", "GA"],
"31542": ["Hoboken", "Georgia", "GA"],
"31543": ["Hortense", "Georgia", "GA"],
"31544": ["Jacksonville", "Georgia", "GA"],
"31545": ["Jesup", "Georgia", "GA"],
"31546": ["Jesup", "Georgia", "GA"],
"31547": ["Kings Bay", "Georgia", "GA"],
"31548": ["Kingsland", "Georgia", "GA"],
"31549": ["Lumber City", "Georgia", "GA"],
"31550": ["Manor", "Georgia", "GA"],
"31551": ["Mershon", "Georgia", "GA"],
"31552": ["Millwood", "Georgia", "GA"],
"31553": ["Nahunta", "Georgia", "GA"],
"31554": ["Nicholls", "Georgia", "GA"],
"31555": ["Odum", "Georgia", "GA"],
"31556": ["Offerman", "Georgia", "GA"],
"31557": ["Patterson", "Georgia", "GA"],
"31558": ["Saint Marys", "Georgia", "GA"],
"31560": ["Screven", "Georgia", "GA"],
"31561": ["Sea Island", "Georgia", "GA"],
"31562": ["Saint George", "Georgia", "GA"],
"31563": ["Surrency", "Georgia", "GA"],
"31564": ["Waresboro", "Georgia", "GA"],
"31565": ["Waverly", "Georgia", "GA"],
"31566": ["Waynesville", "Georgia", "GA"],
"31567": ["West Green", "Georgia", "GA"],
"31568": ["White Oak", "Georgia", "GA"],
"31569": ["Woodbine", "Georgia", "GA"],
"31599": ["Jesup", "Georgia", "GA"],
"31601": ["Valdosta", "Georgia", "GA"],
"31602": ["Valdosta", "Georgia", "GA"],
"31605": ["Valdosta", "Georgia", "GA"],
"31606": ["Valdosta", "Georgia", "GA"],
"31620": ["Adel", "Georgia", "GA"],
"31622": ["Alapaha", "Georgia", "GA"],
"31623": ["Argyle", "Georgia", "GA"],
"31624": ["Axson", "Georgia", "GA"],
"31625": ["Barney", "Georgia", "GA"],
"31626": ["Boston", "Georgia", "GA"],
"31627": ["Cecil", "Georgia", "GA"],
"31629": ["Dixie", "Georgia", "GA"],
"31630": ["Du Pont", "Georgia", "GA"],
"31631": ["Fargo", "Georgia", "GA"],
"31632": ["Hahira", "Georgia", "GA"],
"31634": ["Homerville", "Georgia", "GA"],
"31635": ["Lakeland", "Georgia", "GA"],
"31636": ["Lake Park", "Georgia", "GA"],
"31637": ["Lenox", "Georgia", "GA"],
"31638": ["Morven", "Georgia", "GA"],
"31639": ["Nashville", "Georgia", "GA"],
"31641": ["Naylor", "Georgia", "GA"],
"31642": ["Pearson", "Georgia", "GA"],
"31643": ["Quitman", "Georgia", "GA"],
"31645": ["Ray City", "Georgia", "GA"],
"31647": ["Sparks", "Georgia", "GA"],
"31648": ["Statenville", "Georgia", "GA"],
"31649": ["Stockton", "Georgia", "GA"],
"31650": ["Willacoochee", "Georgia", "GA"],
"31698": ["Valdosta", "Georgia", "GA"],
"31699": ["Moody Afb", "Georgia", "GA"],
"31701": ["Albany", "Georgia", "GA"],
"31704": ["Albany", "Georgia", "GA"],
"31705": ["Albany", "Georgia", "GA"],
"31707": ["Albany", "Georgia", "GA"],
"31709": ["Americus", "Georgia", "GA"],
"31711": ["Andersonville", "Georgia", "GA"],
"31712": ["Arabi", "Georgia", "GA"],
"31714": ["Ashburn", "Georgia", "GA"],
"31716": ["Baconton", "Georgia", "GA"],
"31719": ["Americus", "Georgia", "GA"],
"31720": ["Barwick", "Georgia", "GA"],
"31721": ["Albany", "Georgia", "GA"],
"31722": ["Berlin", "Georgia", "GA"],
"31727": ["Brookfield", "Georgia", "GA"],
"31730": ["Camilla", "Georgia", "GA"],
"31733": ["Chula", "Georgia", "GA"],
"31735": ["Cobb", "Georgia", "GA"],
"31738": ["Coolidge", "Georgia", "GA"],
"31743": ["De Soto", "Georgia", "GA"],
"31744": ["Doerun", "Georgia", "GA"],
"31747": ["Ellenton", "Georgia", "GA"],
"31749": ["Enigma", "Georgia", "GA"],
"31750": ["Fitzgerald", "Georgia", "GA"],
"31753": ["Funston", "Georgia", "GA"],
"31756": ["Hartsfield", "Georgia", "GA"],
"31757": ["Thomasville", "Georgia", "GA"],
"31763": ["Leesburg", "Georgia", "GA"],
"31764": ["Leslie", "Georgia", "GA"],
"31765": ["Meigs", "Georgia", "GA"],
"31768": ["Moultrie", "Georgia", "GA"],
"31771": ["Norman Park", "Georgia", "GA"],
"31772": ["Oakfield", "Georgia", "GA"],
"31773": ["Ochlocknee", "Georgia", "GA"],
"31774": ["Ocilla", "Georgia", "GA"],
"31775": ["Omega", "Georgia", "GA"],
"31778": ["Pavo", "Georgia", "GA"],
"31779": ["Pelham", "Georgia", "GA"],
"31780": ["Plains", "Georgia", "GA"],
"31781": ["Poulan", "Georgia", "GA"],
"31783": ["Rebecca", "Georgia", "GA"],
"31784": ["Sale City", "Georgia", "GA"],
"31787": ["Smithville", "Georgia", "GA"],
"31788": ["Moultrie", "Georgia", "GA"],
"31789": ["Sumner", "Georgia", "GA"],
"31790": ["Sycamore", "Georgia", "GA"],
"31791": ["Sylvester", "Georgia", "GA"],
"31792": ["Thomasville", "Georgia", "GA"],
"31793": ["Tifton", "Georgia", "GA"],
"31794": ["Tifton", "Georgia", "GA"],
"31795": ["Ty Ty", "Georgia", "GA"],
"31796": ["Warwick", "Georgia", "GA"],
"31798": ["Wray", "Georgia", "GA"],
"31801": ["Box Springs", "Georgia", "GA"],
"31803": ["Buena Vista", "Georgia", "GA"],
"31804": ["Cataula", "Georgia", "GA"],
"31805": ["Cusseta", "Georgia", "GA"],
"31806": ["Ellaville", "Georgia", "GA"],
"31807": ["Ellerslie", "Georgia", "GA"],
"31808": ["Fortson", "Georgia", "GA"],
"31810": ["Geneva", "Georgia", "GA"],
"31811": ["Hamilton", "Georgia", "GA"],
"31812": ["Junction City", "Georgia", "GA"],
"31814": ["Louvale", "Georgia", "GA"],
"31815": ["Lumpkin", "Georgia", "GA"],
"31816": ["Manchester", "Georgia", "GA"],
"31820": ["Midland", "Georgia", "GA"],
"31821": ["Omaha", "Georgia", "GA"],
"31822": ["Pine Mountain", "Georgia", "GA"],
"31823": ["Pine Mountain Valley", "Georgia", "GA"],
"31824": ["Preston", "Georgia", "GA"],
"31825": ["Richland", "Georgia", "GA"],
"31826": ["Shiloh", "Georgia", "GA"],
"31827": ["Talbotton", "Georgia", "GA"],
"31829": ["Upatoi", "Georgia", "GA"],
"31830": ["Warm Springs", "Georgia", "GA"],
"31831": ["Waverly Hall", "Georgia", "GA"],
"31832": ["Weston", "Georgia", "GA"],
"31833": ["West Point", "Georgia", "GA"],
"31836": ["Woodland", "Georgia", "GA"],
"31901": ["Columbus", "Georgia", "GA"],
"31903": ["Columbus", "Georgia", "GA"],
"31904": ["Columbus", "Georgia", "GA"],
"31905": ["Fort Benning", "Georgia", "GA"],
"31906": ["Columbus", "Georgia", "GA"],
"31907": ["Columbus", "Georgia", "GA"],
"31909": ["Columbus", "Georgia", "GA"],
"32003": ["Fleming Island", "Florida", "FL"],
"32008": ["Branford", "Florida", "FL"],
"32009": ["Bryceville", "Florida", "FL"],
"32011": ["Callahan", "Florida", "FL"],
"32013": ["Day", "Florida", "FL"],
"32024": ["Lake City", "Florida", "FL"],
"32025": ["Lake City", "Florida", "FL"],
"32033": ["Elkton", "Florida", "FL"],
"32034": ["Fernandina Beach", "Florida", "FL"],
"32038": ["Fort White", "Florida", "FL"],
"32040": ["Glen Saint Mary", "Florida", "FL"],
"32043": ["Green Cove Springs", "Florida", "FL"],
"32044": ["Hampton", "Florida", "FL"],
"32046": ["Hilliard", "Florida", "FL"],
"32052": ["Jasper", "Florida", "FL"],
"32053": ["Jennings", "Florida", "FL"],
"32054": ["Lake Butler", "Florida", "FL"],
"32055": ["Lake City", "Florida", "FL"],
"32058": ["Lawtey", "Florida", "FL"],
"32059": ["Lee", "Florida", "FL"],
"32060": ["Live Oak", "Florida", "FL"],
"32061": ["Lulu", "Florida", "FL"],
"32062": ["McAlpin", "Florida", "FL"],
"32063": ["Macclenny", "Florida", "FL"],
"32064": ["Live Oak", "Florida", "FL"],
"32065": ["Orange Park", "Florida", "FL"],
"32066": ["Mayo", "Florida", "FL"],
"32068": ["Middleburg", "Florida", "FL"],
"32071": ["O'Brien", "Florida", "FL"],
"32073": ["Orange Park", "Florida", "FL"],
"32079": ["Penney Farms", "Florida", "FL"],
"32080": ["Saint Augustine", "Florida", "FL"],
"32081": ["Ponte Vedra", "Florida", "FL"],
"32082": ["Ponte Vedra Beach", "Florida", "FL"],
"32083": ["Raiford", "Florida", "FL"],
"32084": ["Saint Augustine", "Florida", "FL"],
"32086": ["Saint Augustine", "Florida", "FL"],
"32087": ["Sanderson", "Florida", "FL"],
"32091": ["Starke", "Florida", "FL"],
"32092": ["Saint Augustine", "Florida", "FL"],
"32094": ["Wellborn", "Florida", "FL"],
"32095": ["Saint Augustine", "Florida", "FL"],
"32096": ["White Springs", "Florida", "FL"],
"32097": ["Yulee", "Florida", "FL"],
"32102": ["Astor", "Florida", "FL"],
"32110": ["Bunnell", "Florida", "FL"],
"32112": ["Crescent City", "Florida", "FL"],
"32113": ["Citra", "Florida", "FL"],
"32114": ["Daytona Beach", "Florida", "FL"],
"32117": ["Daytona Beach", "Florida", "FL"],
"32118": ["Daytona Beach", "Florida", "FL"],
"32119": ["Daytona Beach", "Florida", "FL"],
"32124": ["Daytona Beach", "Florida", "FL"],
"32127": ["Port Orange", "Florida", "FL"],
"32128": ["Port Orange", "Florida", "FL"],
"32129": ["Port Orange", "Florida", "FL"],
"32130": ["De Leon Springs", "Florida", "FL"],
"32131": ["East Palatka", "Florida", "FL"],
"32132": ["Edgewater", "Florida", "FL"],
"32133": ["Eastlake Weir", "Florida", "FL"],
"32134": ["Fort McCoy", "Florida", "FL"],
"32136": ["Flagler Beach", "Florida", "FL"],
"32137": ["Palm Coast", "Florida", "FL"],
"32139": ["Georgetown", "Florida", "FL"],
"32140": ["Florahome", "Florida", "FL"],
"32141": ["Edgewater", "Florida", "FL"],
"32145": ["Hastings", "Florida", "FL"],
"32147": ["Hollister", "Florida", "FL"],
"32148": ["Interlachen", "Florida", "FL"],
"32157": ["Lake Como", "Florida", "FL"],
"32159": ["Lady Lake", "Florida", "FL"],
"32162": ["The Villages", "Florida", "FL"],
"32163": ["The Villages", "Florida", "FL"],
"32164": ["Palm Coast", "Florida", "FL"],
"32168": ["New Smyrna Beach", "Florida", "FL"],
"32169": ["New Smyrna Beach", "Florida", "FL"],
"32174": ["Ormond Beach", "Florida", "FL"],
"32176": ["Ormond Beach", "Florida", "FL"],
"32177": ["Palatka", "Florida", "FL"],
"32179": ["Ocklawaha", "Florida", "FL"],
"32180": ["Pierson", "Florida", "FL"],
"32181": ["Pomona Park", "Florida", "FL"],
"32182": ["Orange Springs", "Florida", "FL"],
"32187": ["San Mateo", "Florida", "FL"],
"32189": ["Satsuma", "Florida", "FL"],
"32190": ["Seville", "Florida", "FL"],
"32192": ["Sparr", "Florida", "FL"],
"32193": ["Welaka", "Florida", "FL"],
"32195": ["Weirsdale", "Florida", "FL"],
"32202": ["Jacksonville", "Florida", "FL"],
"32203": ["Jacksonville", "Florida", "FL"],
"32204": ["Jacksonville", "Florida", "FL"],
"32205": ["Jacksonville", "Florida", "FL"],
"32206": ["Jacksonville", "Florida", "FL"],
"32207": ["Jacksonville", "Florida", "FL"],
"32208": ["Jacksonville", "Florida", "FL"],
"32209": ["Jacksonville", "Florida", "FL"],
"32210": ["Jacksonville", "Florida", "FL"],
"32211": ["Jacksonville", "Florida", "FL"],
"32212": ["Jacksonville", "Florida", "FL"],
"32214": ["Jacksonville", "Florida", "FL"],
"32216": ["Jacksonville", "Florida", "FL"],
"32217": ["Jacksonville", "Florida", "FL"],
"32218": ["Jacksonville", "Florida", "FL"],
"32219": ["Jacksonville", "Florida", "FL"],
"32220": ["Jacksonville", "Florida", "FL"],
"32221": ["Jacksonville", "Florida", "FL"],
"32222": ["Jacksonville", "Florida", "FL"],
"32223": ["Jacksonville", "Florida", "FL"],
"32224": ["Jacksonville", "Florida", "FL"],
"32225": ["Jacksonville", "Florida", "FL"],
"32226": ["Jacksonville", "Florida", "FL"],
"32227": ["Jacksonville", "Florida", "FL"],
"32228": ["Jacksonville", "Florida", "FL"],
"32233": ["Atlantic Beach", "Florida", "FL"],
"32234": ["Jacksonville", "Florida", "FL"],
"32244": ["Jacksonville", "Florida", "FL"],
"32246": ["Jacksonville", "Florida", "FL"],
"32250": ["Jacksonville Beach", "Florida", "FL"],
"32254": ["Jacksonville", "Florida", "FL"],
"32256": ["Jacksonville", "Florida", "FL"],
"32257": ["Jacksonville", "Florida", "FL"],
"32258": ["Jacksonville", "Florida", "FL"],
"32259": ["Saint Johns", "Florida", "FL"],
"32266": ["Neptune Beach", "Florida", "FL"],
"32277": ["Jacksonville", "Florida", "FL"],
"32301": ["Tallahassee", "Florida", "FL"],
"32303": ["Tallahassee", "Florida", "FL"],
"32304": ["Tallahassee", "Florida", "FL"],
"32305": ["Tallahassee", "Florida", "FL"],
"32306": ["Tallahassee", "Florida", "FL"],
"32307": ["Tallahassee", "Florida", "FL"],
"32308": ["Tallahassee", "Florida", "FL"],
"32309": ["Tallahassee", "Florida", "FL"],
"32310": ["Tallahassee", "Florida", "FL"],
"32311": ["Tallahassee", "Florida", "FL"],
"32312": ["Tallahassee", "Florida", "FL"],
"32313": ["Tallahassee", "Florida", "FL"],
"32317": ["Tallahassee", "Florida", "FL"],
"32320": ["Apalachicola", "Florida", "FL"],
"32321": ["Bristol", "Florida", "FL"],
"32322": ["Carrabelle", "Florida", "FL"],
"32323": ["Lanark Village", "Florida", "FL"],
"32324": ["Chattahoochee", "Florida", "FL"],
"32327": ["Crawfordville", "Florida", "FL"],
"32328": ["Eastpoint", "Florida", "FL"],
"32330": ["Greensboro", "Florida", "FL"],
"32331": ["Greenville", "Florida", "FL"],
"32332": ["Gretna", "Florida", "FL"],
"32333": ["Havana", "Florida", "FL"],
"32334": ["Hosford", "Florida", "FL"],
"32336": ["Lamont", "Florida", "FL"],
"32337": ["Lloyd", "Florida", "FL"],
"32340": ["Madison", "Florida", "FL"],
"32343": ["Midway", "Florida", "FL"],
"32344": ["Monticello", "Florida", "FL"],
"32346": ["Panacea", "Florida", "FL"],
"32347": ["Perry", "Florida", "FL"],
"32348": ["Perry", "Florida", "FL"],
"32350": ["Pinetta", "Florida", "FL"],
"32351": ["Quincy", "Florida", "FL"],
"32352": ["Quincy", "Florida", "FL"],
"32355": ["Saint Marks", "Florida", "FL"],
"32356": ["Salem", "Florida", "FL"],
"32358": ["Sopchoppy", "Florida", "FL"],
"32359": ["Steinhatchee", "Florida", "FL"],
"32360": ["Telogia", "Florida", "FL"],
"32361": ["Wacissa", "Florida", "FL"],
"32399": ["Tallahassee", "Florida", "FL"],
"32401": ["Panama City", "Florida", "FL"],
"32403": ["Panama City", "Florida", "FL"],
"32404": ["Panama City", "Florida", "FL"],
"32405": ["Panama City", "Florida", "FL"],
"32407": ["Panama City Beach", "Florida", "FL"],
"32408": ["Panama City", "Florida", "FL"],
"32409": ["Panama City", "Florida", "FL"],
"32410": ["Mexico Beach", "Florida", "FL"],
"32413": ["Panama City Beach", "Florida", "FL"],
"32420": ["Alford", "Florida", "FL"],
"32421": ["Altha", "Florida", "FL"],
"32423": ["Bascom", "Florida", "FL"],
"32424": ["Blountstown", "Florida", "FL"],
"32425": ["Bonifay", "Florida", "FL"],
"32426": ["Campbellton", "Florida", "FL"],
"32427": ["Caryville", "Florida", "FL"],
"32428": ["Chipley", "Florida", "FL"],
"32430": ["Clarksville", "Florida", "FL"],
"32431": ["Cottondale", "Florida", "FL"],
"32432": ["Cypress", "Florida", "FL"],
"32433": ["Defuniak Springs", "Florida", "FL"],
"32435": ["Defuniak Springs", "Florida", "FL"],
"32437": ["Ebro", "Florida", "FL"],
"32438": ["Fountain", "Florida", "FL"],
"32439": ["Freeport", "Florida", "FL"],
"32440": ["Graceville", "Florida", "FL"],
"32442": ["Grand Ridge", "Florida", "FL"],
"32443": ["Greenwood", "Florida", "FL"],
"32444": ["Lynn Haven", "Florida", "FL"],
"32445": ["Malone", "Florida", "FL"],
"32446": ["Marianna", "Florida", "FL"],
"32448": ["Marianna", "Florida", "FL"],
"32449": ["Wewahitchka", "Florida", "FL"],
"32455": ["Ponce De Leon", "Florida", "FL"],
"32456": ["Port Saint Joe", "Florida", "FL"],
"32459": ["Santa Rosa Beach", "Florida", "FL"],
"32460": ["Sneads", "Florida", "FL"],
"32461": ["Inlet Beach", "Florida", "FL"],
"32462": ["Vernon", "Florida", "FL"],
"32463": ["Wausau", "Florida", "FL"],
"32464": ["Westville", "Florida", "FL"],
"32465": ["Wewahitchka", "Florida", "FL"],
"32466": ["Youngstown", "Florida", "FL"],
"32501": ["Pensacola", "Florida", "FL"],
"32502": ["Pensacola", "Florida", "FL"],
"32503": ["Pensacola", "Florida", "FL"],
"32504": ["Pensacola", "Florida", "FL"],
"32505": ["Pensacola", "Florida", "FL"],
"32506": ["Pensacola", "Florida", "FL"],
"32507": ["Pensacola", "Florida", "FL"],
"32508": ["Pensacola", "Florida", "FL"],
"32509": ["Pensacola", "Florida", "FL"],
"32511": ["Pensacola", "Florida", "FL"],
"32512": ["Pensacola", "Florida", "FL"],
"32514": ["Pensacola", "Florida", "FL"],
"32526": ["Pensacola", "Florida", "FL"],
"32530": ["Bagdad", "Florida", "FL"],
"32531": ["Baker", "Florida", "FL"],
"32533": ["Cantonment", "Florida", "FL"],
"32534": ["Pensacola", "Florida", "FL"],
"32535": ["Century", "Florida", "FL"],
"32536": ["Crestview", "Florida", "FL"],
"32539": ["Crestview", "Florida", "FL"],
"32541": ["Destin", "Florida", "FL"],
"32542": ["Eglin Afb", "Florida", "FL"],
"32544": ["Hurlburt Field", "Florida", "FL"],
"32547": ["Fort Walton Beach", "Florida", "FL"],
"32548": ["Fort Walton Beach", "Florida", "FL"],
"32550": ["Miramar Beach", "Florida", "FL"],
"32561": ["Gulf Breeze", "Florida", "FL"],
"32563": ["Gulf Breeze", "Florida", "FL"],
"32564": ["Holt", "Florida", "FL"],
"32565": ["Jay", "Florida", "FL"],
"32566": ["Navarre", "Florida", "FL"],
"32567": ["Laurel Hill", "Florida", "FL"],
"32568": ["McDavid", "Florida", "FL"],
"32569": ["Mary Esther", "Florida", "FL"],
"32570": ["Milton", "Florida", "FL"],
"32571": ["Milton", "Florida", "FL"],
"32577": ["Molino", "Florida", "FL"],
"32578": ["Niceville", "Florida", "FL"],
"32579": ["Shalimar", "Florida", "FL"],
"32580": ["Valparaiso", "Florida", "FL"],
"32583": ["Milton", "Florida", "FL"],
"32601": ["Gainesville", "Florida", "FL"],
"32603": ["Gainesville", "Florida", "FL"],
"32605": ["Gainesville", "Florida", "FL"],
"32606": ["Gainesville", "Florida", "FL"],
"32607": ["Gainesville", "Florida", "FL"],
"32608": ["Gainesville", "Florida", "FL"],
"32609": ["Gainesville", "Florida", "FL"],
"32610": ["Gainesville", "Florida", "FL"],
"32612": ["Gainesville", "Florida", "FL"],
"32615": ["Alachua", "Florida", "FL"],
"32616": ["Alachua", "Florida", "FL"],
"32617": ["Anthony", "Florida", "FL"],
"32618": ["Archer", "Florida", "FL"],
"32619": ["Bell", "Florida", "FL"],
"32621": ["Bronson", "Florida", "FL"],
"32622": ["Brooker", "Florida", "FL"],
"32625": ["Cedar Key", "Florida", "FL"],
"32626": ["Chiefland", "Florida", "FL"],
"32628": ["Cross City", "Florida", "FL"],
"32631": ["Earleton", "Florida", "FL"],
"32634": ["Fairfield", "Florida", "FL"],
"32639": ["Gulf Hammock", "Florida", "FL"],
"32640": ["Hawthorne", "Florida", "FL"],
"32641": ["Gainesville", "Florida", "FL"],
"32643": ["High Springs", "Florida", "FL"],
"32648": ["Horseshoe Beach", "Florida", "FL"],
"32653": ["Gainesville", "Florida", "FL"],
"32656": ["Keystone Heights", "Florida", "FL"],
"32658": ["La Crosse", "Florida", "FL"],
"32664": ["McIntosh", "Florida", "FL"],
"32666": ["Melrose", "Florida", "FL"],
"32667": ["Micanopy", "Florida", "FL"],
"32668": ["Morriston", "Florida", "FL"],
"32669": ["Newberry", "Florida", "FL"],
"32680": ["Old Town", "Florida", "FL"],
"32681": ["Orange Lake", "Florida", "FL"],
"32683": ["Otter Creek", "Florida", "FL"],
"32686": ["Reddick", "Florida", "FL"],
"32692": ["Suwannee", "Florida", "FL"],
"32693": ["Trenton", "Florida", "FL"],
"32694": ["Waldo", "Florida", "FL"],
"32696": ["Williston", "Florida", "FL"],
"32697": ["Worthington Springs", "Florida", "FL"],
"32701": ["Altamonte Springs", "Florida", "FL"],
"32702": ["Altoona", "Florida", "FL"],
"32703": ["Apopka", "Florida", "FL"],
"32707": ["Casselberry", "Florida", "FL"],
"32708": ["Winter Springs", "Florida", "FL"],
"32709": ["Christmas", "Florida", "FL"],
"32712": ["Apopka", "Florida", "FL"],
"32713": ["Debary", "Florida", "FL"],
"32714": ["Altamonte Springs", "Florida", "FL"],
"32720": ["Deland", "Florida", "FL"],
"32723": ["Deland", "Florida", "FL"],
"32724": ["Deland", "Florida", "FL"],
"32725": ["Deltona", "Florida", "FL"],
"32726": ["Eustis", "Florida", "FL"],
"32730": ["Casselberry", "Florida", "FL"],
"32732": ["Geneva", "Florida", "FL"],
"32735": ["Grand Island", "Florida", "FL"],
"32736": ["Eustis", "Florida", "FL"],
"32738": ["Deltona", "Florida", "FL"],
"32744": ["Lake Helen", "Florida", "FL"],
"32746": ["Lake Mary", "Florida", "FL"],
"32750": ["Longwood", "Florida", "FL"],
"32751": ["Maitland", "Florida", "FL"],
"32754": ["Mims", "Florida", "FL"],
"32757": ["Mount Dora", "Florida", "FL"],
"32759": ["Oak Hill", "Florida", "FL"],
"32763": ["Orange City", "Florida", "FL"],
"32764": ["Osteen", "Florida", "FL"],
"32765": ["Oviedo", "Florida", "FL"],
"32766": ["Oviedo", "Florida", "FL"],
"32767": ["Paisley", "Florida", "FL"],
"32768": ["Plymouth", "Florida", "FL"],
"32771": ["Sanford", "Florida", "FL"],
"32773": ["Sanford", "Florida", "FL"],
"32775": ["Scottsmoor", "Florida", "FL"],
"32776": ["Sorrento", "Florida", "FL"],
"32778": ["Tavares", "Florida", "FL"],
"32779": ["Longwood", "Florida", "FL"],
"32780": ["Titusville", "Florida", "FL"],
"32784": ["Umatilla", "Florida", "FL"],
"32789": ["Winter Park", "Florida", "FL"],
"32792": ["Winter Park", "Florida", "FL"],
"32796": ["Titusville", "Florida", "FL"],
"32798": ["Zellwood", "Florida", "FL"],
"32801": ["Orlando", "Florida", "FL"],
"32803": ["Orlando", "Florida", "FL"],
"32804": ["Orlando", "Florida", "FL"],
"32805": ["Orlando", "Florida", "FL"],
"32806": ["Orlando", "Florida", "FL"],
"32807": ["Orlando", "Florida", "FL"],
"32808": ["Orlando", "Florida", "FL"],
"32809": ["Orlando", "Florida", "FL"],
"32810": ["Orlando", "Florida", "FL"],
"32811": ["Orlando", "Florida", "FL"],
"32812": ["Orlando", "Florida", "FL"],
"32814": ["Orlando", "Florida", "FL"],
"32816": ["Orlando", "Florida", "FL"],
"32817": ["Orlando", "Florida", "FL"],
"32818": ["Orlando", "Florida", "FL"],
"32819": ["Orlando", "Florida", "FL"],
"32820": ["Orlando", "Florida", "FL"],
"32821": ["Orlando", "Florida", "FL"],
"32822": ["Orlando", "Florida", "FL"],
"32824": ["Orlando", "Florida", "FL"],
"32825": ["Orlando", "Florida", "FL"],
"32826": ["Orlando", "Florida", "FL"],
"32827": ["Orlando", "Florida", "FL"],
"32828": ["Orlando", "Florida", "FL"],
"32829": ["Orlando", "Florida", "FL"],
"32830": ["Orlando", "Florida", "FL"],
"32831": ["Orlando", "Florida", "FL"],
"32832": ["Orlando", "Florida", "FL"],
"32833": ["Orlando", "Florida", "FL"],
"32835": ["Orlando", "Florida", "FL"],
"32836": ["Orlando", "Florida", "FL"],
"32837": ["Orlando", "Florida", "FL"],
"32839": ["Orlando", "Florida", "FL"],
"32899": ["Orlando", "Florida", "FL"],
"32901": ["Melbourne", "Florida", "FL"],
"32903": ["Indialantic", "Florida", "FL"],
"32904": ["Melbourne", "Florida", "FL"],
"32905": ["Palm Bay", "Florida", "FL"],
"32907": ["Palm Bay", "Florida", "FL"],
"32908": ["Palm Bay", "Florida", "FL"],
"32909": ["Palm Bay", "Florida", "FL"],
"32920": ["Cape Canaveral", "Florida", "FL"],
"32922": ["Cocoa", "Florida", "FL"],
"32925": ["Patrick Afb", "Florida", "FL"],
"32926": ["Cocoa", "Florida", "FL"],
"32927": ["Cocoa", "Florida", "FL"],
"32931": ["Cocoa Beach", "Florida", "FL"],
"32934": ["Melbourne", "Florida", "FL"],
"32935": ["Melbourne", "Florida", "FL"],
"32937": ["Satellite Beach", "Florida", "FL"],
"32940": ["Melbourne", "Florida", "FL"],
"32948": ["Fellsmere", "Florida", "FL"],
"32949": ["Grant", "Florida", "FL"],
"32950": ["Malabar", "Florida", "FL"],
"32951": ["Melbourne Beach", "Florida", "FL"],
"32952": ["Merritt Island", "Florida", "FL"],
"32953": ["Merritt Island", "Florida", "FL"],
"32955": ["Rockledge", "Florida", "FL"],
"32958": ["Sebastian", "Florida", "FL"],
"32959": ["Sharpes", "Florida", "FL"],
"32960": ["Vero Beach", "Florida", "FL"],
"32962": ["Vero Beach", "Florida", "FL"],
"32963": ["Vero Beach", "Florida", "FL"],
"32966": ["Vero Beach", "Florida", "FL"],
"32967": ["Vero Beach", "Florida", "FL"],
"32968": ["Vero Beach", "Florida", "FL"],
"32970": ["Wabasso", "Florida", "FL"],
"32976": ["Sebastian", "Florida", "FL"],
"33001": ["Long Key", "Florida", "FL"],
"33004": ["Dania", "Florida", "FL"],
"33009": ["Hallandale", "Florida", "FL"],
"33010": ["Hialeah", "Florida", "FL"],
"33012": ["Hialeah", "Florida", "FL"],
"33013": ["Hialeah", "Florida", "FL"],
"33014": ["Hialeah", "Florida", "FL"],
"33015": ["Hialeah", "Florida", "FL"],
"33016": ["Hialeah", "Florida", "FL"],
"33018": ["Hialeah", "Florida", "FL"],
"33019": ["Hollywood", "Florida", "FL"],
"33020": ["Hollywood", "Florida", "FL"],
"33021": ["Hollywood", "Florida", "FL"],
"33022": ["Hollywood", "Florida", "FL"],
"33023": ["Hollywood", "Florida", "FL"],
"33024": ["Hollywood", "Florida", "FL"],
"33025": ["Hollywood", "Florida", "FL"],
"33026": ["Hollywood", "Florida", "FL"],
"33027": ["Hollywood", "Florida", "FL"],
"33028": ["Pembroke Pines", "Florida", "FL"],
"33029": ["Hollywood", "Florida", "FL"],
"33030": ["Homestead", "Florida", "FL"],
"33031": ["Homestead", "Florida", "FL"],
"33032": ["Homestead", "Florida", "FL"],
"33033": ["Homestead", "Florida", "FL"],
"33034": ["Homestead", "Florida", "FL"],
"33035": ["Homestead", "Florida", "FL"],
"33036": ["Islamorada", "Florida", "FL"],
"33037": ["Key Largo", "Florida", "FL"],
"33039": ["Homestead", "Florida", "FL"],
"33040": ["Key West", "Florida", "FL"],
"33042": ["Summerland Key", "Florida", "FL"],
"33043": ["Big Pine Key", "Florida", "FL"],
"33050": ["Marathon", "Florida", "FL"],
"33051": ["Key Colony Beach", "Florida", "FL"],
"33054": ["Opa Locka", "Florida", "FL"],
"33055": ["Opa Locka", "Florida", "FL"],
"33056": ["Miami Gardens", "Florida", "FL"],
"33060": ["Pompano Beach", "Florida", "FL"],
"33062": ["Pompano Beach", "Florida", "FL"],
"33063": ["Pompano Beach", "Florida", "FL"],
"33064": ["Pompano Beach", "Florida", "FL"],
"33065": ["Coral Springs", "Florida", "FL"],
"33066": ["Pompano Beach", "Florida", "FL"],
"33067": ["Pompano Beach", "Florida", "FL"],
"33068": ["Pompano Beach", "Florida", "FL"],
"33069": ["Pompano Beach", "Florida", "FL"],
"33070": ["Tavernier", "Florida", "FL"],
"33071": ["Coral Springs", "Florida", "FL"],
"33073": ["Pompano Beach", "Florida", "FL"],
"33076": ["Pompano Beach", "Florida", "FL"],
"33101": ["Miami", "Florida", "FL"],
"33109": ["Miami Beach", "Florida", "FL"],
"33122": ["Miami", "Florida", "FL"],
"33125": ["Miami", "Florida", "FL"],
"33126": ["Miami", "Florida", "FL"],
"33127": ["Miami", "Florida", "FL"],
"33128": ["Miami", "Florida", "FL"],
"33129": ["Miami", "Florida", "FL"],
"33130": ["Miami", "Florida", "FL"],
"33131": ["Miami", "Florida", "FL"],
"33132": ["Miami", "Florida", "FL"],
"33133": ["Miami", "Florida", "FL"],
"33134": ["Miami", "Florida", "FL"],
"33135": ["Miami", "Florida", "FL"],
"33136": ["Miami", "Florida", "FL"],
"33137": ["Miami", "Florida", "FL"],
"33138": ["Miami", "Florida", "FL"],
"33139": ["Miami Beach", "Florida", "FL"],
"33140": ["Miami Beach", "Florida", "FL"],
"33141": ["Miami Beach", "Florida", "FL"],
"33142": ["Miami", "Florida", "FL"],
"33143": ["Miami", "Florida", "FL"],
"33144": ["Miami", "Florida", "FL"],
"33145": ["Miami", "Florida", "FL"],
"33146": ["Miami", "Florida", "FL"],
"33147": ["Miami", "Florida", "FL"],
"33149": ["Key Biscayne", "Florida", "FL"],
"33150": ["Miami", "Florida", "FL"],
"33154": ["Miami Beach", "Florida", "FL"],
"33155": ["Miami", "Florida", "FL"],
"33156": ["Miami", "Florida", "FL"],
"33157": ["Miami", "Florida", "FL"],
"33158": ["Miami", "Florida", "FL"],
"33160": ["North Miami Beach", "Florida", "FL"],
"33161": ["Miami", "Florida", "FL"],
"33162": ["Miami", "Florida", "FL"],
"33165": ["Miami", "Florida", "FL"],
"33166": ["Miami", "Florida", "FL"],
"33167": ["Miami", "Florida", "FL"],
"33168": ["Miami", "Florida", "FL"],
"33169": ["Miami", "Florida", "FL"],
"33170": ["Miami", "Florida", "FL"],
"33172": ["Miami", "Florida", "FL"],
"33173": ["Miami", "Florida", "FL"],
"33174": ["Miami", "Florida", "FL"],
"33175": ["Miami", "Florida", "FL"],
"33176": ["Miami", "Florida", "FL"],
"33177": ["Miami", "Florida", "FL"],
"33178": ["Miami", "Florida", "FL"],
"33179": ["Miami", "Florida", "FL"],
"33180": ["Miami", "Florida", "FL"],
"33181": ["Miami", "Florida", "FL"],
"33182": ["Miami", "Florida", "FL"],
"33183": ["Miami", "Florida", "FL"],
"33184": ["Miami", "Florida", "FL"],
"33185": ["Miami", "Florida", "FL"],
"33186": ["Miami", "Florida", "FL"],
"33187": ["Miami", "Florida", "FL"],
"33189": ["Miami", "Florida", "FL"],
"33190": ["Miami", "Florida", "FL"],
"33193": ["Miami", "Florida", "FL"],
"33194": ["Miami", "Florida", "FL"],
"33196": ["Miami", "Florida", "FL"],
"33301": ["Fort Lauderdale", "Florida", "FL"],
"33304": ["Fort Lauderdale", "Florida", "FL"],
"33305": ["Fort Lauderdale", "Florida", "FL"],
"33306": ["Fort Lauderdale", "Florida", "FL"],
"33308": ["Fort Lauderdale", "Florida", "FL"],
"33309": ["Fort Lauderdale", "Florida", "FL"],
"33311": ["Fort Lauderdale", "Florida", "FL"],
"33312": ["Fort Lauderdale", "Florida", "FL"],
"33313": ["Fort Lauderdale", "Florida", "FL"],
"33314": ["Fort Lauderdale", "Florida", "FL"],
"33315": ["Fort Lauderdale", "Florida", "FL"],
"33316": ["Fort Lauderdale", "Florida", "FL"],
"33317": ["Fort Lauderdale", "Florida", "FL"],
"33319": ["Fort Lauderdale", "Florida", "FL"],
"33321": ["Fort Lauderdale", "Florida", "FL"],
"33322": ["Fort Lauderdale", "Florida", "FL"],
"33323": ["Fort Lauderdale", "Florida", "FL"],
"33324": ["Fort Lauderdale", "Florida", "FL"],
"33325": ["Fort Lauderdale", "Florida", "FL"],
"33326": ["Fort Lauderdale", "Florida", "FL"],
"33327": ["Fort Lauderdale", "Florida", "FL"],
"33328": ["Fort Lauderdale", "Florida", "FL"],
"33330": ["Fort Lauderdale", "Florida", "FL"],
"33331": ["Fort Lauderdale", "Florida", "FL"],
"33332": ["Fort Lauderdale", "Florida", "FL"],
"33334": ["Fort Lauderdale", "Florida", "FL"],
"33351": ["Fort Lauderdale", "Florida", "FL"],
"33388": ["Plantation", "Florida", "FL"],
"33401": ["West Palm Beach", "Florida", "FL"],
"33403": ["West Palm Beach", "Florida", "FL"],
"33404": ["West Palm Beach", "Florida", "FL"],
"33405": ["West Palm Beach", "Florida", "FL"],
"33406": ["West Palm Beach", "Florida", "FL"],
"33407": ["West Palm Beach", "Florida", "FL"],
"33408": ["North Palm Beach", "Florida", "FL"],
"33409": ["West Palm Beach", "Florida", "FL"],
"33410": ["Palm Beach Gardens", "Florida", "FL"],
"33411": ["West Palm Beach", "Florida", "FL"],
"33412": ["West Palm Beach", "Florida", "FL"],
"33413": ["West Palm Beach", "Florida", "FL"],
"33414": ["Wellington", "Florida", "FL"],
"33415": ["West Palm Beach", "Florida", "FL"],
"33417": ["West Palm Beach", "Florida", "FL"],
"33418": ["Palm Beach Gardens", "Florida", "FL"],
"33426": ["Boynton Beach", "Florida", "FL"],
"33428": ["Boca Raton", "Florida", "FL"],
"33430": ["Belle Glade", "Florida", "FL"],
"33431": ["Boca Raton", "Florida", "FL"],
"33432": ["Boca Raton", "Florida", "FL"],
"33433": ["Boca Raton", "Florida", "FL"],
"33434": ["Boca Raton", "Florida", "FL"],
"33435": ["Boynton Beach", "Florida", "FL"],
"33436": ["Boynton Beach", "Florida", "FL"],
"33437": ["Boynton Beach", "Florida", "FL"],
"33438": ["Canal Point", "Florida", "FL"],
"33440": ["Clewiston", "Florida", "FL"],
"33441": ["Deerfield Beach", "Florida", "FL"],
"33442": ["Deerfield Beach", "Florida", "FL"],
"33444": ["Delray Beach", "Florida", "FL"],
"33445": ["Delray Beach", "Florida", "FL"],
"33446": ["Delray Beach", "Florida", "FL"],
"33449": ["Lake Worth", "Florida", "FL"],
"33455": ["Hobe Sound", "Florida", "FL"],
"33458": ["Jupiter", "Florida", "FL"],
"33460": ["Lake Worth Beach", "Florida", "FL"],
"33461": ["Lake Worth", "Florida", "FL"],
"33462": ["Lake Worth", "Florida", "FL"],
"33463": ["Lake Worth", "Florida", "FL"],
"33467": ["Lake Worth", "Florida", "FL"],
"33469": ["Jupiter", "Florida", "FL"],
"33470": ["Loxahatchee", "Florida", "FL"],
"33471": ["Moore Haven", "Florida", "FL"],
"33472": ["Boynton Beach", "Florida", "FL"],
"33473": ["Boynton Beach", "Florida", "FL"],
"33476": ["Pahokee", "Florida", "FL"],
"33477": ["Jupiter", "Florida", "FL"],
"33478": ["Jupiter", "Florida", "FL"],
"33480": ["Palm Beach", "Florida", "FL"],
"33483": ["Delray Beach", "Florida", "FL"],
"33484": ["Delray Beach", "Florida", "FL"],
"33486": ["Boca Raton", "Florida", "FL"],
"33487": ["Boca Raton", "Florida", "FL"],
"33493": ["South Bay", "Florida", "FL"],
"33496": ["Boca Raton", "Florida", "FL"],
"33498": ["Boca Raton", "Florida", "FL"],
"33503": ["Balm", "Florida", "FL"],
"33510": ["Brandon", "Florida", "FL"],
"33511": ["Brandon", "Florida", "FL"],
"33513": ["Bushnell", "Florida", "FL"],
"33514": ["Center Hill", "Florida", "FL"],
"33521": ["Coleman", "Florida", "FL"],
"33523": ["Dade City", "Florida", "FL"],
"33525": ["Dade City", "Florida", "FL"],
"33527": ["Dover", "Florida", "FL"],
"33530": ["Durant", "Florida", "FL"],
"33534": ["Gibsonton", "Florida", "FL"],
"33538": ["Lake Panasoffkee", "Florida", "FL"],
"33540": ["Zephyrhills", "Florida", "FL"],
"33541": ["Zephyrhills", "Florida", "FL"],
"33542": ["Zephyrhills", "Florida", "FL"],
"33543": ["Wesley Chapel", "Florida", "FL"],
"33544": ["Wesley Chapel", "Florida", "FL"],
"33545": ["Wesley Chapel", "Florida", "FL"],
"33547": ["Lithia", "Florida", "FL"],
"33548": ["Lutz", "Florida", "FL"],
"33549": ["Lutz", "Florida", "FL"],
"33550": ["Mango", "Florida", "FL"],
"33556": ["Odessa", "Florida", "FL"],
"33558": ["Lutz", "Florida", "FL"],
"33559": ["Lutz", "Florida", "FL"],
"33563": ["Plant City", "Florida", "FL"],
"33565": ["Plant City", "Florida", "FL"],
"33566": ["Plant City", "Florida", "FL"],
"33567": ["Plant City", "Florida", "FL"],
"33569": ["Riverview", "Florida", "FL"],
"33570": ["Ruskin", "Florida", "FL"],
"33572": ["Apollo Beach", "Florida", "FL"],
"33573": ["Sun City Center", "Florida", "FL"],
"33574": ["Saint Leo", "Florida", "FL"],
"33576": ["San Antonio", "Florida", "FL"],
"33578": ["Riverview", "Florida", "FL"],
"33579": ["Riverview", "Florida", "FL"],
"33584": ["Seffner", "Florida", "FL"],
"33585": ["Sumterville", "Florida", "FL"],
"33592": ["Thonotosassa", "Florida", "FL"],
"33594": ["Valrico", "Florida", "FL"],
"33596": ["Valrico", "Florida", "FL"],
"33597": ["Webster", "Florida", "FL"],
"33598": ["Wimauma", "Florida", "FL"],
"33602": ["Tampa", "Florida", "FL"],
"33603": ["Tampa", "Florida", "FL"],
"33604": ["Tampa", "Florida", "FL"],
"33605": ["Tampa", "Florida", "FL"],
"33606": ["Tampa", "Florida", "FL"],
"33607": ["Tampa", "Florida", "FL"],
"33609": ["Tampa", "Florida", "FL"],
"33610": ["Tampa", "Florida", "FL"],
"33611": ["Tampa", "Florida", "FL"],
"33612": ["Tampa", "Florida", "FL"],
"33613": ["Tampa", "Florida", "FL"],
"33614": ["Tampa", "Florida", "FL"],
"33615": ["Tampa", "Florida", "FL"],
"33616": ["Tampa", "Florida", "FL"],
"33617": ["Tampa", "Florida", "FL"],
"33618": ["Tampa", "Florida", "FL"],
"33619": ["Tampa", "Florida", "FL"],
"33620": ["Tampa", "Florida", "FL"],
"33621": ["Tampa", "Florida", "FL"],
"33624": ["Tampa", "Florida", "FL"],
"33625": ["Tampa", "Florida", "FL"],
"33626": ["Tampa", "Florida", "FL"],
"33629": ["Tampa", "Florida", "FL"],
"33634": ["Tampa", "Florida", "FL"],
"33635": ["Tampa", "Florida", "FL"],
"33637": ["Tampa", "Florida", "FL"],
"33647": ["Tampa", "Florida", "FL"],
"33701": ["Saint Petersburg", "Florida", "FL"],
"33702": ["Saint Petersburg", "Florida", "FL"],
"33703": ["Saint Petersburg", "Florida", "FL"],
"33704": ["Saint Petersburg", "Florida", "FL"],
"33705": ["Saint Petersburg", "Florida", "FL"],
"33706": ["Saint Petersburg", "Florida", "FL"],
"33707": ["Saint Petersburg", "Florida", "FL"],
"33708": ["Saint Petersburg", "Florida", "FL"],
"33709": ["Saint Petersburg", "Florida", "FL"],
"33710": ["Saint Petersburg", "Florida", "FL"],
"33711": ["Saint Petersburg", "Florida", "FL"],
"33712": ["Saint Petersburg", "Florida", "FL"],
"33713": ["Saint Petersburg", "Florida", "FL"],
"33714": ["Saint Petersburg", "Florida", "FL"],
"33715": ["Saint Petersburg", "Florida", "FL"],
"33716": ["Saint Petersburg", "Florida", "FL"],
"33744": ["Bay Pines", "Florida", "FL"],
"33755": ["Clearwater", "Florida", "FL"],
"33756": ["Clearwater", "Florida", "FL"],
"33759": ["Clearwater", "Florida", "FL"],
"33760": ["Clearwater", "Florida", "FL"],
"33761": ["Clearwater", "Florida", "FL"],
"33762": ["Clearwater", "Florida", "FL"],
"33763": ["Clearwater", "Florida", "FL"],
"33764": ["Clearwater", "Florida", "FL"],
"33765": ["Clearwater", "Florida", "FL"],
"33767": ["Clearwater Beach", "Florida", "FL"],
"33770": ["Largo", "Florida", "FL"],
"33771": ["Largo", "Florida", "FL"],
"33772": ["Seminole", "Florida", "FL"],
"33773": ["Largo", "Florida", "FL"],
"33774": ["Largo", "Florida", "FL"],
"33776": ["Seminole", "Florida", "FL"],
"33777": ["Seminole", "Florida", "FL"],
"33778": ["Largo", "Florida", "FL"],
"33781": ["Pinellas Park", "Florida", "FL"],
"33782": ["Pinellas Park", "Florida", "FL"],
"33785": ["Indian Rocks Beach", "Florida", "FL"],
"33786": ["Belleair Beach", "Florida", "FL"],
"33801": ["Lakeland", "Florida", "FL"],
"33803": ["Lakeland", "Florida", "FL"],
"33805": ["Lakeland", "Florida", "FL"],
"33809": ["Lakeland", "Florida", "FL"],
"33810": ["Lakeland", "Florida", "FL"],
"33811": ["Lakeland", "Florida", "FL"],
"33812": ["Lakeland", "Florida", "FL"],
"33813": ["Lakeland", "Florida", "FL"],
"33815": ["Lakeland", "Florida", "FL"],
"33823": ["Auburndale", "Florida", "FL"],
"33825": ["Avon Park", "Florida", "FL"],
"33827": ["Babson Park", "Florida", "FL"],
"33830": ["Bartow", "Florida", "FL"],
"33834": ["Bowling Green", "Florida", "FL"],
"33835": ["Bradley", "Florida", "FL"],
"33837": ["Davenport", "Florida", "FL"],
"33838": ["Dundee", "Florida", "FL"],
"33839": ["Eagle Lake", "Florida", "FL"],
"33840": ["Eaton Park", "Florida", "FL"],
"33841": ["Fort Meade", "Florida", "FL"],
"33843": ["Frostproof", "Florida", "FL"],
"33844": ["Haines City", "Florida", "FL"],
"33847": ["Homeland", "Florida", "FL"],
"33848": ["Intercession City", "Florida", "FL"],
"33849": ["Kathleen", "Florida", "FL"],
"33850": ["Lake Alfred", "Florida", "FL"],
"33851": ["Lake Hamilton", "Florida", "FL"],
"33852": ["Lake Placid", "Florida", "FL"],
"33853": ["Lake Wales", "Florida", "FL"],
"33854": ["Lakeshore", "Florida", "FL"],
"33855": ["Indian Lake Estates", "Florida", "FL"],
"33856": ["Nalcrest", "Florida", "FL"],
"33857": ["Lorida", "Florida", "FL"],
"33858": ["Loughman", "Florida", "FL"],
"33859": ["Lake Wales", "Florida", "FL"],
"33860": ["Mulberry", "Florida", "FL"],
"33865": ["Ona", "Florida", "FL"],
"33867": ["River Ranch", "Florida", "FL"],
"33868": ["Polk City", "Florida", "FL"],
"33870": ["Sebring", "Florida", "FL"],
"33872": ["Sebring", "Florida", "FL"],
"33873": ["Wauchula", "Florida", "FL"],
"33875": ["Sebring", "Florida", "FL"],
"33876": ["Sebring", "Florida", "FL"],
"33877": ["Waverly", "Florida", "FL"],
"33880": ["Winter Haven", "Florida", "FL"],
"33881": ["Winter Haven", "Florida", "FL"],
"33884": ["Winter Haven", "Florida", "FL"],
"33890": ["Zolfo Springs", "Florida", "FL"],
"33896": ["Davenport", "Florida", "FL"],
"33897": ["Davenport", "Florida", "FL"],
"33898": ["Lake Wales", "Florida", "FL"],
"33901": ["Fort Myers", "Florida", "FL"],
"33903": ["North Fort Myers", "Florida", "FL"],
"33904": ["Cape Coral", "Florida", "FL"],
"33905": ["Fort Myers", "Florida", "FL"],
"33907": ["Fort Myers", "Florida", "FL"],
"33908": ["Fort Myers", "Florida", "FL"],
"33909": ["Cape Coral", "Florida", "FL"],
"33912": ["Fort Myers", "Florida", "FL"],
"33913": ["Fort Myers", "Florida", "FL"],
"33914": ["Cape Coral", "Florida", "FL"],
"33916": ["Fort Myers", "Florida", "FL"],
"33917": ["North Fort Myers", "Florida", "FL"],
"33919": ["Fort Myers", "Florida", "FL"],
"33920": ["Alva", "Florida", "FL"],
"33921": ["Boca Grande", "Florida", "FL"],
"33922": ["Bokeelia", "Florida", "FL"],
"33924": ["Captiva", "Florida", "FL"],
"33928": ["Estero", "Florida", "FL"],
"33930": ["Felda", "Florida", "FL"],
"33931": ["Fort Myers Beach", "Florida", "FL"],
"33935": ["Labelle", "Florida", "FL"],
"33936": ["Lehigh Acres", "Florida", "FL"],
"33944": ["Palmdale", "Florida", "FL"],
"33946": ["Placida", "Florida", "FL"],
"33947": ["Rotonda West", "Florida", "FL"],
"33948": ["Port Charlotte", "Florida", "FL"],
"33950": ["Punta Gorda", "Florida", "FL"],
"33952": ["Port Charlotte", "Florida", "FL"],
"33953": ["Port Charlotte", "Florida", "FL"],
"33954": ["Port Charlotte", "Florida", "FL"],
"33955": ["Punta Gorda", "Florida", "FL"],
"33956": ["Saint James City", "Florida", "FL"],
"33957": ["Sanibel", "Florida", "FL"],
"33960": ["Venus", "Florida", "FL"],
"33965": ["Fort Myers", "Florida", "FL"],
"33966": ["Fort Myers", "Florida", "FL"],
"33967": ["Fort Myers", "Florida", "FL"],
"33971": ["Lehigh Acres", "Florida", "FL"],
"33972": ["Lehigh Acres", "Florida", "FL"],
"33973": ["Lehigh Acres", "Florida", "FL"],
"33974": ["Lehigh Acres", "Florida", "FL"],
"33976": ["Lehigh Acres", "Florida", "FL"],
"33980": ["Punta Gorda", "Florida", "FL"],
"33981": ["Port Charlotte", "Florida", "FL"],
"33982": ["Punta Gorda", "Florida", "FL"],
"33983": ["Punta Gorda", "Florida", "FL"],
"33990": ["Cape Coral", "Florida", "FL"],
"33991": ["Cape Coral", "Florida", "FL"],
"33993": ["Cape Coral", "Florida", "FL"],
"34101": ["Naples", "Florida", "FL"],
"34102": ["Naples", "Florida", "FL"],
"34103": ["Naples", "Florida", "FL"],
"34104": ["Naples", "Florida", "FL"],
"34105": ["Naples", "Florida", "FL"],
"34108": ["Naples", "Florida", "FL"],
"34109": ["Naples", "Florida", "FL"],
"34110": ["Naples", "Florida", "FL"],
"34112": ["Naples", "Florida", "FL"],
"34113": ["Naples", "Florida", "FL"],
"34114": ["Naples", "Florida", "FL"],
"34116": ["Naples", "Florida", "FL"],
"34117": ["Naples", "Florida", "FL"],
"34119": ["Naples", "Florida", "FL"],
"34120": ["Naples", "Florida", "FL"],
"34134": ["Bonita Springs", "Florida", "FL"],
"34135": ["Bonita Springs", "Florida", "FL"],
"34137": ["Copeland", "Florida", "FL"],
"34138": ["Chokoloskee", "Florida", "FL"],
"34139": ["Everglades City", "Florida", "FL"],
"34140": ["Goodland", "Florida", "FL"],
"34141": ["Ochopee", "Florida", "FL"],
"34142": ["Immokalee", "Florida", "FL"],
"34145": ["Marco Island", "Florida", "FL"],
"34201": ["Bradenton", "Florida", "FL"],
"34202": ["Bradenton", "Florida", "FL"],
"34203": ["Bradenton", "Florida", "FL"],
"34205": ["Bradenton", "Florida", "FL"],
"34207": ["Bradenton", "Florida", "FL"],
"34208": ["Bradenton", "Florida", "FL"],
"34209": ["Bradenton", "Florida", "FL"],
"34210": ["Bradenton", "Florida", "FL"],
"34211": ["Bradenton", "Florida", "FL"],
"34212": ["Bradenton", "Florida", "FL"],
"34215": ["Cortez", "Florida", "FL"],
"34216": ["Anna Maria", "Florida", "FL"],
"34217": ["Bradenton Beach", "Florida", "FL"],
"34219": ["Parrish", "Florida", "FL"],
"34221": ["Palmetto", "Florida", "FL"],
"34222": ["Ellenton", "Florida", "FL"],
"34223": ["Englewood", "Florida", "FL"],
"34224": ["Englewood", "Florida", "FL"],
"34228": ["Longboat Key", "Florida", "FL"],
"34229": ["Osprey", "Florida", "FL"],
"34231": ["Sarasota", "Florida", "FL"],
"34232": ["Sarasota", "Florida", "FL"],
"34233": ["Sarasota", "Florida", "FL"],
"34234": ["Sarasota", "Florida", "FL"],
"34235": ["Sarasota", "Florida", "FL"],
"34236": ["Sarasota", "Florida", "FL"],
"34237": ["Sarasota", "Florida", "FL"],
"34238": ["Sarasota", "Florida", "FL"],
"34239": ["Sarasota", "Florida", "FL"],
"34240": ["Sarasota", "Florida", "FL"],
"34241": ["Sarasota", "Florida", "FL"],
"34242": ["Sarasota", "Florida", "FL"],
"34243": ["Sarasota", "Florida", "FL"],
"34250": ["Terra Ceia", "Florida", "FL"],
"34251": ["Myakka City", "Florida", "FL"],
"34266": ["Arcadia", "Florida", "FL"],
"34267": ["Fort Ogden", "Florida", "FL"],
"34268": ["Nocatee", "Florida", "FL"],
"34269": ["Arcadia", "Florida", "FL"],
"34275": ["Nokomis", "Florida", "FL"],
"34285": ["Venice", "Florida", "FL"],
"34286": ["North Port", "Florida", "FL"],
"34287": ["North Port", "Florida", "FL"],
"34288": ["North Port", "Florida", "FL"],
"34289": ["North Port", "Florida", "FL"],
"34291": ["North Port", "Florida", "FL"],
"34292": ["Venice", "Florida", "FL"],
"34293": ["Venice", "Florida", "FL"],
"34420": ["Belleview", "Florida", "FL"],
"34428": ["Crystal River", "Florida", "FL"],
"34429": ["Crystal River", "Florida", "FL"],
"34431": ["Dunnellon", "Florida", "FL"],
"34432": ["Dunnellon", "Florida", "FL"],
"34433": ["Dunnellon", "Florida", "FL"],
"34434": ["Dunnellon", "Florida", "FL"],
"34436": ["Floral City", "Florida", "FL"],
"34442": ["Hernando", "Florida", "FL"],
"34445": ["Holder", "Florida", "FL"],
"34446": ["Homosassa", "Florida", "FL"],
"34448": ["Homosassa", "Florida", "FL"],
"34449": ["Inglis", "Florida", "FL"],
"34450": ["Inverness", "Florida", "FL"],
"34452": ["Inverness", "Florida", "FL"],
"34453": ["Inverness", "Florida", "FL"],
"34461": ["Lecanto", "Florida", "FL"],
"34465": ["Beverly Hills", "Florida", "FL"],
"34470": ["Ocala", "Florida", "FL"],
"34471": ["Ocala", "Florida", "FL"],
"34472": ["Ocala", "Florida", "FL"],
"34473": ["Ocala", "Florida", "FL"],
"34474": ["Ocala", "Florida", "FL"],
"34475": ["Ocala", "Florida", "FL"],
"34476": ["Ocala", "Florida", "FL"],
"34479": ["Ocala", "Florida", "FL"],
"34480": ["Ocala", "Florida", "FL"],
"34481": ["Ocala", "Florida", "FL"],
"34482": ["Ocala", "Florida", "FL"],
"34484": ["Oxford", "Florida", "FL"],
"34487": ["Homosassa", "Florida", "FL"],
"34488": ["Silver Springs", "Florida", "FL"],
"34491": ["Summerfield", "Florida", "FL"],
"34498": ["Yankeetown", "Florida", "FL"],
"34601": ["Brooksville", "Florida", "FL"],
"34602": ["Brooksville", "Florida", "FL"],
"34604": ["Brooksville", "Florida", "FL"],
"34606": ["Spring Hill", "Florida", "FL"],
"34607": ["Spring Hill", "Florida", "FL"],
"34608": ["Spring Hill", "Florida", "FL"],
"34609": ["Spring Hill", "Florida", "FL"],
"34610": ["Spring Hill", "Florida", "FL"],
"34613": ["Brooksville", "Florida", "FL"],
"34614": ["Brooksville", "Florida", "FL"],
"34637": ["Land O'Lakes", "Florida", "FL"],
"34638": ["Land O'Lakes", "Florida", "FL"],
"34639": ["Land O'Lakes", "Florida", "FL"],
"34652": ["New Port Richey", "Florida", "FL"],
"34653": ["New Port Richey", "Florida", "FL"],
"34654": ["New Port Richey", "Florida", "FL"],
"34655": ["New Port Richey", "Florida", "FL"],
"34661": ["Nobleton", "Florida", "FL"],
"34667": ["Hudson", "Florida", "FL"],
"34668": ["Port Richey", "Florida", "FL"],
"34669": ["Hudson", "Florida", "FL"],
"34677": ["Oldsmar", "Florida", "FL"],
"34679": ["Aripeka", "Florida", "FL"],
"34681": ["Crystal Beach", "Florida", "FL"],
"34683": ["Palm Harbor", "Florida", "FL"],
"34684": ["Palm Harbor", "Florida", "FL"],
"34685": ["Palm Harbor", "Florida", "FL"],
"34688": ["Tarpon Springs", "Florida", "FL"],
"34689": ["Tarpon Springs", "Florida", "FL"],
"34690": ["Holiday", "Florida", "FL"],
"34691": ["Holiday", "Florida", "FL"],
"34695": ["Safety Harbor", "Florida", "FL"],
"34698": ["Dunedin", "Florida", "FL"],
"34705": ["Astatula", "Florida", "FL"],
"34711": ["Clermont", "Florida", "FL"],
"34714": ["Clermont", "Florida", "FL"],
"34715": ["Clermont", "Florida", "FL"],
"34729": ["Ferndale", "Florida", "FL"],
"34731": ["Fruitland Park", "Florida", "FL"],
"34734": ["Gotha", "Florida", "FL"],
"34736": ["Groveland", "Florida", "FL"],
"34737": ["Howey In The Hills", "Florida", "FL"],
"34739": ["Kenansville", "Florida", "FL"],
"34741": ["Kissimmee", "Florida", "FL"],
"34743": ["Kissimmee", "Florida", "FL"],
"34744": ["Kissimmee", "Florida", "FL"],
"34746": ["Kissimmee", "Florida", "FL"],
"34747": ["Kissimmee", "Florida", "FL"],
"34748": ["Leesburg", "Florida", "FL"],
"34753": ["Mascotte", "Florida", "FL"],
"34756": ["Montverde", "Florida", "FL"],
"34758": ["Kissimmee", "Florida", "FL"],
"34759": ["Kissimmee", "Florida", "FL"],
"34760": ["Oakland", "Florida", "FL"],
"34761": ["Ocoee", "Florida", "FL"],
"34762": ["Okahumpka", "Florida", "FL"],
"34769": ["Saint Cloud", "Florida", "FL"],
"34771": ["Saint Cloud", "Florida", "FL"],
"34772": ["Saint Cloud", "Florida", "FL"],
"34773": ["Saint Cloud", "Florida", "FL"],
"34785": ["Wildwood", "Florida", "FL"],
"34786": ["Windermere", "Florida", "FL"],
"34787": ["Winter Garden", "Florida", "FL"],
"34788": ["Leesburg", "Florida", "FL"],
"34797": ["Yalaha", "Florida", "FL"],
"34945": ["Fort Pierce", "Florida", "FL"],
"34946": ["Fort Pierce", "Florida", "FL"],
"34947": ["Fort Pierce", "Florida", "FL"],
"34949": ["Fort Pierce", "Florida", "FL"],
"34950": ["Fort Pierce", "Florida", "FL"],
"34951": ["Fort Pierce", "Florida", "FL"],
"34952": ["Port Saint Lucie", "Florida", "FL"],
"34953": ["Port Saint Lucie", "Florida", "FL"],
"34956": ["Indiantown", "Florida", "FL"],
"34957": ["Jensen Beach", "Florida", "FL"],
"34972": ["Okeechobee", "Florida", "FL"],
"34974": ["Okeechobee", "Florida", "FL"],
"34981": ["Fort Pierce", "Florida", "FL"],
"34982": ["Fort Pierce", "Florida", "FL"],
"34983": ["Port Saint Lucie", "Florida", "FL"],
"34984": ["Port Saint Lucie", "Florida", "FL"],
"34986": ["Port Saint Lucie", "Florida", "FL"],
"34987": ["Port Saint Lucie", "Florida", "FL"],
"34990": ["Palm City", "Florida", "FL"],
"34994": ["Stuart", "Florida", "FL"],
"34996": ["Stuart", "Florida", "FL"],
"34997": ["Stuart", "Florida", "FL"],
"35004": ["Moody", "Alabama", "AL"],
"35005": ["Adamsville", "Alabama", "AL"],
"35006": ["Adger", "Alabama", "AL"],
"35007": ["Alabaster", "Alabama", "AL"],
"35010": ["Alexander City", "Alabama", "AL"],
"35013": ["Allgood", "Alabama", "AL"],
"35014": ["Alpine", "Alabama", "AL"],
"35016": ["Arab", "Alabama", "AL"],
"35019": ["Baileyton", "Alabama", "AL"],
"35020": ["Bessemer", "Alabama", "AL"],
"35022": ["Bessemer", "Alabama", "AL"],
"35023": ["Bessemer", "Alabama", "AL"],
"35031": ["Blountsville", "Alabama", "AL"],
"35032": ["Bon Air", "Alabama", "AL"],
"35033": ["Bremen", "Alabama", "AL"],
"35034": ["Brent", "Alabama", "AL"],
"35035": ["Brierfield", "Alabama", "AL"],
"35036": ["Brookside", "Alabama", "AL"],
"35040": ["Calera", "Alabama", "AL"],
"35042": ["Centreville", "Alabama", "AL"],
"35043": ["Chelsea", "Alabama", "AL"],
"35044": ["Childersburg", "Alabama", "AL"],
"35045": ["Clanton", "Alabama", "AL"],
"35046": ["Clanton", "Alabama", "AL"],
"35049": ["Cleveland", "Alabama", "AL"],
"35051": ["Columbiana", "Alabama", "AL"],
"35052": ["Cook Springs", "Alabama", "AL"],
"35053": ["Crane Hill", "Alabama", "AL"],
"35054": ["Cropwell", "Alabama", "AL"],
"35055": ["Cullman", "Alabama", "AL"],
"35057": ["Cullman", "Alabama", "AL"],
"35058": ["Cullman", "Alabama", "AL"],
"35060": ["Docena", "Alabama", "AL"],
"35061": ["Dolomite", "Alabama", "AL"],
"35062": ["Dora", "Alabama", "AL"],
"35063": ["Empire", "Alabama", "AL"],
"35064": ["Fairfield", "Alabama", "AL"],
"35068": ["Fultondale", "Alabama", "AL"],
"35070": ["Garden City", "Alabama", "AL"],
"35071": ["Gardendale", "Alabama", "AL"],
"35072": ["Goodwater", "Alabama", "AL"],
"35073": ["Graysville", "Alabama", "AL"],
"35074": ["Green Pond", "Alabama", "AL"],
"35077": ["Hanceville", "Alabama", "AL"],
"35078": ["Harpersville", "Alabama", "AL"],
"35079": ["Hayden", "Alabama", "AL"],
"35080": ["Helena", "Alabama", "AL"],
"35082": ["Hollins", "Alabama", "AL"],
"35083": ["Holly Pond", "Alabama", "AL"],
"35085": ["Jemison", "Alabama", "AL"],
"35087": ["Joppa", "Alabama", "AL"],
"35089": ["Kellyton", "Alabama", "AL"],
"35091": ["Kimberly", "Alabama", "AL"],
"35094": ["Leeds", "Alabama", "AL"],
"35096": ["Lincoln", "Alabama", "AL"],
"35097": ["Locust Fork", "Alabama", "AL"],
"35098": ["Logan", "Alabama", "AL"],
"35111": ["McCalla", "Alabama", "AL"],
"35112": ["Margaret", "Alabama", "AL"],
"35114": ["Alabaster", "Alabama", "AL"],
"35115": ["Montevallo", "Alabama", "AL"],
"35116": ["Morris", "Alabama", "AL"],
"35117": ["Mount Olive", "Alabama", "AL"],
"35118": ["Mulga", "Alabama", "AL"],
"35119": ["New Castle", "Alabama", "AL"],
"35120": ["Odenville", "Alabama", "AL"],
"35121": ["Oneonta", "Alabama", "AL"],
"35124": ["Pelham", "Alabama", "AL"],
"35125": ["Pell City", "Alabama", "AL"],
"35126": ["Pinson", "Alabama", "AL"],
"35127": ["Pleasant Grove", "Alabama", "AL"],
"35128": ["Pell City", "Alabama", "AL"],
"35130": ["Quinton", "Alabama", "AL"],
"35131": ["Ragland", "Alabama", "AL"],
"35133": ["Remlap", "Alabama", "AL"],
"35135": ["Riverside", "Alabama", "AL"],
"35136": ["Rockford", "Alabama", "AL"],
"35143": ["Shelby", "Alabama", "AL"],
"35146": ["Springville", "Alabama", "AL"],
"35147": ["Sterrett", "Alabama", "AL"],
"35148": ["Sumiton", "Alabama", "AL"],
"35149": ["Sycamore", "Alabama", "AL"],
"35150": ["Sylacauga", "Alabama", "AL"],
"35151": ["Sylacauga", "Alabama", "AL"],
"35160": ["Talladega", "Alabama", "AL"],
"35171": ["Thorsby", "Alabama", "AL"],
"35172": ["Trafford", "Alabama", "AL"],
"35173": ["Trussville", "Alabama", "AL"],
"35175": ["Union Grove", "Alabama", "AL"],
"35176": ["Vandiver", "Alabama", "AL"],
"35178": ["Vincent", "Alabama", "AL"],
"35179": ["Vinemont", "Alabama", "AL"],
"35180": ["Warrior", "Alabama", "AL"],
"35181": ["Watson", "Alabama", "AL"],
"35183": ["Weogufka", "Alabama", "AL"],
"35184": ["West Blocton", "Alabama", "AL"],
"35185": ["Westover", "Alabama", "AL"],
"35186": ["Wilsonville", "Alabama", "AL"],
"35188": ["Woodstock", "Alabama", "AL"],
"35203": ["Birmingham", "Alabama", "AL"],
"35204": ["Birmingham", "Alabama", "AL"],
"35205": ["Birmingham", "Alabama", "AL"],
"35206": ["Birmingham", "Alabama", "AL"],
"35207": ["Birmingham", "Alabama", "AL"],
"35208": ["Birmingham", "Alabama", "AL"],
"35209": ["Birmingham", "Alabama", "AL"],
"35210": ["Birmingham", "Alabama", "AL"],
"35211": ["Birmingham", "Alabama", "AL"],
"35212": ["Birmingham", "Alabama", "AL"],
"35213": ["Birmingham", "Alabama", "AL"],
"35214": ["Birmingham", "Alabama", "AL"],
"35215": ["Birmingham", "Alabama", "AL"],
"35216": ["Birmingham", "Alabama", "AL"],
"35217": ["Birmingham", "Alabama", "AL"],
"35218": ["Birmingham", "Alabama", "AL"],
"35221": ["Birmingham", "Alabama", "AL"],
"35222": ["Birmingham", "Alabama", "AL"],
"35223": ["Birmingham", "Alabama", "AL"],
"35224": ["Birmingham", "Alabama", "AL"],
"35226": ["Birmingham", "Alabama", "AL"],
"35228": ["Birmingham", "Alabama", "AL"],
"35229": ["Birmingham", "Alabama", "AL"],
"35233": ["Birmingham", "Alabama", "AL"],
"35234": ["Birmingham", "Alabama", "AL"],
"35235": ["Birmingham", "Alabama", "AL"],
"35242": ["Birmingham", "Alabama", "AL"],
"35243": ["Birmingham", "Alabama", "AL"],
"35244": ["Birmingham", "Alabama", "AL"],
"35249": ["Birmingham", "Alabama", "AL"],
"35254": ["Birmingham", "Alabama", "AL"],
"35401": ["Tuscaloosa", "Alabama", "AL"],
"35404": ["Tuscaloosa", "Alabama", "AL"],
"35405": ["Tuscaloosa", "Alabama", "AL"],
"35406": ["Tuscaloosa", "Alabama", "AL"],
"35441": ["Akron", "Alabama", "AL"],
"35442": ["Aliceville", "Alabama", "AL"],
"35443": ["Boligee", "Alabama", "AL"],
"35444": ["Brookwood", "Alabama", "AL"],
"35446": ["Buhl", "Alabama", "AL"],
"35447": ["Carrollton", "Alabama", "AL"],
"35452": ["Coker", "Alabama", "AL"],
"35453": ["Cottondale", "Alabama", "AL"],
"35456": ["Duncanville", "Alabama", "AL"],
"35457": ["Echola", "Alabama", "AL"],
"35458": ["Elrod", "Alabama", "AL"],
"35459": ["Emelle", "Alabama", "AL"],
"35460": ["Epes", "Alabama", "AL"],
"35461": ["Ethelsville", "Alabama", "AL"],
"35462": ["Eutaw", "Alabama", "AL"],
"35463": ["Fosters", "Alabama", "AL"],
"35464": ["Gainesville", "Alabama", "AL"],
"35466": ["Gordo", "Alabama", "AL"],
"35469": ["Knoxville", "Alabama", "AL"],
"35470": ["Livingston", "Alabama", "AL"],
"35473": ["Northport", "Alabama", "AL"],
"35474": ["Moundville", "Alabama", "AL"],
"35475": ["Northport", "Alabama", "AL"],
"35476": ["Northport", "Alabama", "AL"],
"35477": ["Panola", "Alabama", "AL"],
"35480": ["Ralph", "Alabama", "AL"],
"35481": ["Reform", "Alabama", "AL"],
"35487": ["Tuscaloosa", "Alabama", "AL"],
"35490": ["Vance", "Alabama", "AL"],
"35491": ["West Greene", "Alabama", "AL"],
"35501": ["Jasper", "Alabama", "AL"],
"35503": ["Jasper", "Alabama", "AL"],
"35504": ["Jasper", "Alabama", "AL"],
"35540": ["Addison", "Alabama", "AL"],
"35541": ["Arley", "Alabama", "AL"],
"35542": ["Bankston", "Alabama", "AL"],
"35543": ["Bear Creek", "Alabama", "AL"],
"35544": ["Beaverton", "Alabama", "AL"],
"35545": ["Belk", "Alabama", "AL"],
"35546": ["Berry", "Alabama", "AL"],
"35548": ["Brilliant", "Alabama", "AL"],
"35549": ["Carbon Hill", "Alabama", "AL"],
"35550": ["Cordova", "Alabama", "AL"],
"35552": ["Detroit", "Alabama", "AL"],
"35553": ["Double Springs", "Alabama", "AL"],
"35554": ["Eldridge", "Alabama", "AL"],
"35555": ["Fayette", "Alabama", "AL"],
"35559": ["Glen Allen", "Alabama", "AL"],
"35563": ["Guin", "Alabama", "AL"],
"35564": ["Hackleburg", "Alabama", "AL"],
"35565": ["Haleyville", "Alabama", "AL"],
"35570": ["Hamilton", "Alabama", "AL"],
"35571": ["Hodges", "Alabama", "AL"],
"35572": ["Houston", "Alabama", "AL"],
"35573": ["Kansas", "Alabama", "AL"],
"35574": ["Kennedy", "Alabama", "AL"],
"35575": ["Lynn", "Alabama", "AL"],
"35576": ["Millport", "Alabama", "AL"],
"35577": ["Natural Bridge", "Alabama", "AL"],
"35578": ["Nauvoo", "Alabama", "AL"],
"35579": ["Oakman", "Alabama", "AL"],
"35580": ["Parrish", "Alabama", "AL"],
"35581": ["Phil Campbell", "Alabama", "AL"],
"35582": ["Red Bay", "Alabama", "AL"],
"35584": ["Sipsey", "Alabama", "AL"],
"35585": ["Spruce Pine", "Alabama", "AL"],
"35586": ["Sulligent", "Alabama", "AL"],
"35587": ["Townley", "Alabama", "AL"],
"35592": ["Vernon", "Alabama", "AL"],
"35593": ["Vina", "Alabama", "AL"],
"35594": ["Winfield", "Alabama", "AL"],
"35601": ["Decatur", "Alabama", "AL"],
"35603": ["Decatur", "Alabama", "AL"],
"35610": ["Anderson", "Alabama", "AL"],
"35611": ["Athens", "Alabama", "AL"],
"35613": ["Athens", "Alabama", "AL"],
"35614": ["Athens", "Alabama", "AL"],
"35615": ["Belle Mina", "Alabama", "AL"],
"35616": ["Cherokee", "Alabama", "AL"],
"35618": ["Courtland", "Alabama", "AL"],
"35619": ["Danville", "Alabama", "AL"],
"35620": ["Elkmont", "Alabama", "AL"],
"35621": ["Eva", "Alabama", "AL"],
"35622": ["Falkville", "Alabama", "AL"],
"35630": ["Florence", "Alabama", "AL"],
"35632": ["Florence", "Alabama", "AL"],
"35633": ["Florence", "Alabama", "AL"],
"35634": ["Florence", "Alabama", "AL"],
"35640": ["Hartselle", "Alabama", "AL"],
"35643": ["Hillsboro", "Alabama", "AL"],
"35645": ["Killen", "Alabama", "AL"],
"35646": ["Leighton", "Alabama", "AL"],
"35647": ["Lester", "Alabama", "AL"],
"35648": ["Lexington", "Alabama", "AL"],
"35649": ["Mooresville", "Alabama", "AL"],
"35650": ["Moulton", "Alabama", "AL"],
"35651": ["Mount Hope", "Alabama", "AL"],
"35652": ["Rogersville", "Alabama", "AL"],
"35653": ["Russellville", "Alabama", "AL"],
"35654": ["Russellville", "Alabama", "AL"],
"35660": ["Sheffield", "Alabama", "AL"],
"35661": ["Muscle Shoals", "Alabama", "AL"],
"35670": ["Somerville", "Alabama", "AL"],
"35671": ["Tanner", "Alabama", "AL"],
"35672": ["Town Creek", "Alabama", "AL"],
"35673": ["Trinity", "Alabama", "AL"],
"35674": ["Tuscumbia", "Alabama", "AL"],
"35677": ["Waterloo", "Alabama", "AL"],
"35739": ["Ardmore", "Alabama", "AL"],
"35740": ["Bridgeport", "Alabama", "AL"],
"35741": ["Brownsboro", "Alabama", "AL"],
"35744": ["Dutton", "Alabama", "AL"],
"35745": ["Estillfork", "Alabama", "AL"],
"35746": ["Fackler", "Alabama", "AL"],
"35747": ["Grant", "Alabama", "AL"],
"35748": ["Gurley", "Alabama", "AL"],
"35749": ["Harvest", "Alabama", "AL"],
"35750": ["Hazel Green", "Alabama", "AL"],
"35751": ["Hollytree", "Alabama", "AL"],
"35752": ["Hollywood", "Alabama", "AL"],
"35754": ["Laceys Spring", "Alabama", "AL"],
"35755": ["Langston", "Alabama", "AL"],
"35756": ["Madison", "Alabama", "AL"],
"35757": ["Madison", "Alabama", "AL"],
"35758": ["Madison", "Alabama", "AL"],
"35759": ["Meridianville", "Alabama", "AL"],
"35760": ["New Hope", "Alabama", "AL"],
"35761": ["New Market", "Alabama", "AL"],
"35763": ["Owens Cross Roads", "Alabama", "AL"],
"35764": ["Paint Rock", "Alabama", "AL"],
"35765": ["Pisgah", "Alabama", "AL"],
"35766": ["Princeton", "Alabama", "AL"],
"35768": ["Scottsboro", "Alabama", "AL"],
"35769": ["Scottsboro", "Alabama", "AL"],
"35771": ["Section", "Alabama", "AL"],
"35772": ["Stevenson", "Alabama", "AL"],
"35773": ["Toney", "Alabama", "AL"],
"35774": ["Trenton", "Alabama", "AL"],
"35775": ["Valhermoso Springs", "Alabama", "AL"],
"35776": ["Woodville", "Alabama", "AL"],
"35801": ["Huntsville", "Alabama", "AL"],
"35802": ["Huntsville", "Alabama", "AL"],
"35803": ["Huntsville", "Alabama", "AL"],
"35805": ["Huntsville", "Alabama", "AL"],
"35806": ["Huntsville", "Alabama", "AL"],
"35808": ["Huntsville", "Alabama", "AL"],
"35810": ["Huntsville", "Alabama", "AL"],
"35811": ["Huntsville", "Alabama", "AL"],
"35816": ["Huntsville", "Alabama", "AL"],
"35824": ["Huntsville", "Alabama", "AL"],
"35896": ["Huntsville", "Alabama", "AL"],
"35901": ["Gadsden", "Alabama", "AL"],
"35903": ["Gadsden", "Alabama", "AL"],
"35904": ["Gadsden", "Alabama", "AL"],
"35905": ["Gadsden", "Alabama", "AL"],
"35906": ["Rainbow City", "Alabama", "AL"],
"35907": ["Gadsden", "Alabama", "AL"],
"35950": ["Albertville", "Alabama", "AL"],
"35951": ["Albertville", "Alabama", "AL"],
"35952": ["Altoona", "Alabama", "AL"],
"35953": ["Ashville", "Alabama", "AL"],
"35954": ["Attalla", "Alabama", "AL"],
"35956": ["Boaz", "Alabama", "AL"],
"35957": ["Boaz", "Alabama", "AL"],
"35958": ["Bryant", "Alabama", "AL"],
"35959": ["Cedar Bluff", "Alabama", "AL"],
"35960": ["Centre", "Alabama", "AL"],
"35961": ["Collinsville", "Alabama", "AL"],
"35962": ["Crossville", "Alabama", "AL"],
"35963": ["Dawson", "Alabama", "AL"],
"35966": ["Flat Rock", "Alabama", "AL"],
"35967": ["Fort Payne", "Alabama", "AL"],
"35968": ["Fort Payne", "Alabama", "AL"],
"35971": ["Fyffe", "Alabama", "AL"],
"35972": ["Gallant", "Alabama", "AL"],
"35973": ["Gaylesville", "Alabama", "AL"],
"35974": ["Geraldine", "Alabama", "AL"],
"35975": ["Groveoak", "Alabama", "AL"],
"35976": ["Guntersville", "Alabama", "AL"],
"35978": ["Henagar", "Alabama", "AL"],
"35979": ["Higdon", "Alabama", "AL"],
"35980": ["Horton", "Alabama", "AL"],
"35981": ["Ider", "Alabama", "AL"],
"35983": ["Leesburg", "Alabama", "AL"],
"35984": ["Mentone", "Alabama", "AL"],
"35986": ["Rainsville", "Alabama", "AL"],
"35987": ["Steele", "Alabama", "AL"],
"35988": ["Sylvania", "Alabama", "AL"],
"35989": ["Valley Head", "Alabama", "AL"],
"35990": ["Walnut Grove", "Alabama", "AL"],
"36003": ["Autaugaville", "Alabama", "AL"],
"36005": ["Banks", "Alabama", "AL"],
"36006": ["Billingsley", "Alabama", "AL"],
"36008": ["Booth", "Alabama", "AL"],
"36009": ["Brantley", "Alabama", "AL"],
"36010": ["Brundidge", "Alabama", "AL"],
"36013": ["Cecil", "Alabama", "AL"],
"36016": ["Clayton", "Alabama", "AL"],
"36017": ["Clio", "Alabama", "AL"],
"36020": ["Coosada", "Alabama", "AL"],
"36022": ["Deatsville", "Alabama", "AL"],
"36024": ["Eclectic", "Alabama", "AL"],
"36025": ["Elmore", "Alabama", "AL"],
"36026": ["Equality", "Alabama", "AL"],
"36027": ["Eufaula", "Alabama", "AL"],
"36028": ["Dozier", "Alabama", "AL"],
"36029": ["Fitzpatrick", "Alabama", "AL"],
"36030": ["Forest Home", "Alabama", "AL"],
"36031": ["Fort Davis", "Alabama", "AL"],
"36032": ["Fort Deposit", "Alabama", "AL"],
"36033": ["Georgiana", "Alabama", "AL"],
"36034": ["Glenwood", "Alabama", "AL"],
"36035": ["Goshen", "Alabama", "AL"],
"36036": ["Grady", "Alabama", "AL"],
"36037": ["Greenville", "Alabama", "AL"],
"36038": ["Gantt", "Alabama", "AL"],
"36039": ["Hardaway", "Alabama", "AL"],
"36040": ["Hayneville", "Alabama", "AL"],
"36041": ["Highland Home", "Alabama", "AL"],
"36042": ["Honoraville", "Alabama", "AL"],
"36043": ["Hope Hull", "Alabama", "AL"],
"36046": ["Lapine", "Alabama", "AL"],
"36047": ["Letohatchee", "Alabama", "AL"],
"36048": ["Louisville", "Alabama", "AL"],
"36049": ["Luverne", "Alabama", "AL"],
"36051": ["Marbury", "Alabama", "AL"],
"36052": ["Mathews", "Alabama", "AL"],
"36053": ["Midway", "Alabama", "AL"],
"36054": ["Millbrook", "Alabama", "AL"],
"36057": ["Mount Meigs", "Alabama", "AL"],
"36064": ["Pike Road", "Alabama", "AL"],
"36066": ["Prattville", "Alabama", "AL"],
"36067": ["Prattville", "Alabama", "AL"],
"36069": ["Ramer", "Alabama", "AL"],
"36071": ["Rutledge", "Alabama", "AL"],
"36075": ["Shorter", "Alabama", "AL"],
"36078": ["Tallassee", "Alabama", "AL"],
"36079": ["Troy", "Alabama", "AL"],
"36080": ["Titus", "Alabama", "AL"],
"36081": ["Troy", "Alabama", "AL"],
"36082": ["Troy", "Alabama", "AL"],
"36083": ["Tuskegee", "Alabama", "AL"],
"36088": ["Tuskegee Institute", "Alabama", "AL"],
"36089": ["Union Springs", "Alabama", "AL"],
"36091": ["Verbena", "Alabama", "AL"],
"36092": ["Wetumpka", "Alabama", "AL"],
"36093": ["Wetumpka", "Alabama", "AL"],
"36101": ["Montgomery", "Alabama", "AL"],
"36104": ["Montgomery", "Alabama", "AL"],
"36105": ["Montgomery", "Alabama", "AL"],
"36106": ["Montgomery", "Alabama", "AL"],
"36107": ["Montgomery", "Alabama", "AL"],
"36108": ["Montgomery", "Alabama", "AL"],
"36109": ["Montgomery", "Alabama", "AL"],
"36110": ["Montgomery", "Alabama", "AL"],
"36111": ["Montgomery", "Alabama", "AL"],
"36112": ["Montgomery", "Alabama", "AL"],
"36113": ["Montgomery", "Alabama", "AL"],
"36114": ["Montgomery", "Alabama", "AL"],
"36115": ["Montgomery", "Alabama", "AL"],
"36116": ["Montgomery", "Alabama", "AL"],
"36117": ["Montgomery", "Alabama", "AL"],
"36130": ["Montgomery", "Alabama", "AL"],
"36201": ["Anniston", "Alabama", "AL"],
"36203": ["Oxford", "Alabama", "AL"],
"36205": ["Anniston", "Alabama", "AL"],
"36206": ["Anniston", "Alabama", "AL"],
"36207": ["Anniston", "Alabama", "AL"],
"36250": ["Alexandria", "Alabama", "AL"],
"36251": ["Ashland", "Alabama", "AL"],
"36255": ["Cragford", "Alabama", "AL"],
"36256": ["Daviston", "Alabama", "AL"],
"36258": ["Delta", "Alabama", "AL"],
"36260": ["Eastaboga", "Alabama", "AL"],
"36262": ["Fruithurst", "Alabama", "AL"],
"36263": ["Graham", "Alabama", "AL"],
"36264": ["Heflin", "Alabama", "AL"],
"36265": ["Jacksonville", "Alabama", "AL"],
"36266": ["Lineville", "Alabama", "AL"],
"36267": ["Millerville", "Alabama", "AL"],
"36268": ["Munford", "Alabama", "AL"],
"36269": ["Muscadine", "Alabama", "AL"],
"36271": ["Ohatchee", "Alabama", "AL"],
"36272": ["Piedmont", "Alabama", "AL"],
"36273": ["Ranburne", "Alabama", "AL"],
"36274": ["Roanoke", "Alabama", "AL"],
"36276": ["Wadley", "Alabama", "AL"],
"36277": ["Weaver", "Alabama", "AL"],
"36278": ["Wedowee", "Alabama", "AL"],
"36279": ["Wellington", "Alabama", "AL"],
"36280": ["Woodland", "Alabama", "AL"],
"36301": ["Dothan", "Alabama", "AL"],
"36303": ["Dothan", "Alabama", "AL"],
"36305": ["Dothan", "Alabama", "AL"],
"36310": ["Abbeville", "Alabama", "AL"],
"36311": ["Ariton", "Alabama", "AL"],
"36312": ["Ashford", "Alabama", "AL"],
"36313": ["Bellwood", "Alabama", "AL"],
"36314": ["Black", "Alabama", "AL"],
"36316": ["Chancellor", "Alabama", "AL"],
"36317": ["Clopton", "Alabama", "AL"],
"36318": ["Coffee Springs", "Alabama", "AL"],
"36319": ["Columbia", "Alabama", "AL"],
"36320": ["Cottonwood", "Alabama", "AL"],
"36321": ["Cowarts", "Alabama", "AL"],
"36322": ["Daleville", "Alabama", "AL"],
"36323": ["Elba", "Alabama", "AL"],
"36330": ["Enterprise", "Alabama", "AL"],
"36340": ["Geneva", "Alabama", "AL"],
"36343": ["Gordon", "Alabama", "AL"],
"36344": ["Hartford", "Alabama", "AL"],
"36345": ["Headland", "Alabama", "AL"],
"36346": ["Jack", "Alabama", "AL"],
"36350": ["Midland City", "Alabama", "AL"],
"36351": ["New Brockton", "Alabama", "AL"],
"36352": ["Newton", "Alabama", "AL"],
"36353": ["Newville", "Alabama", "AL"],
"36360": ["Ozark", "Alabama", "AL"],
"36362": ["Fort Rucker", "Alabama", "AL"],
"36370": ["Pansey", "Alabama", "AL"],
"36371": ["Pinckard", "Alabama", "AL"],
"36373": ["Shorterville", "Alabama", "AL"],
"36374": ["Skipperville", "Alabama", "AL"],
"36375": ["Slocomb", "Alabama", "AL"],
"36376": ["Webb", "Alabama", "AL"],
"36401": ["Evergreen", "Alabama", "AL"],
"36420": ["Andalusia", "Alabama", "AL"],
"36421": ["Andalusia", "Alabama", "AL"],
"36425": ["Beatrice", "Alabama", "AL"],
"36426": ["Brewton", "Alabama", "AL"],
"36432": ["Castleberry", "Alabama", "AL"],
"36435": ["Coy", "Alabama", "AL"],
"36436": ["Dickinson", "Alabama", "AL"],
"36439": ["Excel", "Alabama", "AL"],
"36441": ["Flomaton", "Alabama", "AL"],
"36442": ["Florala", "Alabama", "AL"],
"36444": ["Franklin", "Alabama", "AL"],
"36445": ["Frisco City", "Alabama", "AL"],
"36446": ["Fulton", "Alabama", "AL"],
"36451": ["Grove Hill", "Alabama", "AL"],
"36453": ["Kinston", "Alabama", "AL"],
"36454": ["Lenox", "Alabama", "AL"],
"36455": ["Lockhart", "Alabama", "AL"],
"36456": ["McKenzie", "Alabama", "AL"],
"36460": ["Monroeville", "Alabama", "AL"],
"36467": ["Opp", "Alabama", "AL"],
"36470": ["Perdue Hill", "Alabama", "AL"],
"36471": ["Peterman", "Alabama", "AL"],
"36473": ["Range", "Alabama", "AL"],
"36474": ["Red Level", "Alabama", "AL"],
"36475": ["Repton", "Alabama", "AL"],
"36476": ["River Falls", "Alabama", "AL"],
"36477": ["Samson", "Alabama", "AL"],
"36480": ["Uriah", "Alabama", "AL"],
"36481": ["Vredenburgh", "Alabama", "AL"],
"36482": ["Whatley", "Alabama", "AL"],
"36483": ["Wing", "Alabama", "AL"],
"36502": ["Atmore", "Alabama", "AL"],
"36503": ["Atmore", "Alabama", "AL"],
"36505": ["Axis", "Alabama", "AL"],
"36507": ["Bay Minette", "Alabama", "AL"],
"36509": ["Bayou La Batre", "Alabama", "AL"],
"36511": ["Bon Secour", "Alabama", "AL"],
"36512": ["Bucks", "Alabama", "AL"],
"36513": ["Calvert", "Alabama", "AL"],
"36518": ["Chatom", "Alabama", "AL"],
"36521": ["Chunchula", "Alabama", "AL"],
"36522": ["Citronelle", "Alabama", "AL"],
"36523": ["Coden", "Alabama", "AL"],
"36524": ["Coffeeville", "Alabama", "AL"],
"36525": ["Creola", "Alabama", "AL"],
"36526": ["Daphne", "Alabama", "AL"],
"36527": ["Daphne", "Alabama", "AL"],
"36528": ["Dauphin Island", "Alabama", "AL"],
"36529": ["Deer Park", "Alabama", "AL"],
"36530": ["Elberta", "Alabama", "AL"],
"36532": ["Fairhope", "Alabama", "AL"],
"36535": ["Foley", "Alabama", "AL"],
"36538": ["Frankville", "Alabama", "AL"],
"36539": ["Fruitdale", "Alabama", "AL"],
"36540": ["Gainestown", "Alabama", "AL"],
"36541": ["Grand Bay", "Alabama", "AL"],
"36542": ["Gulf Shores", "Alabama", "AL"],
"36543": ["Huxford", "Alabama", "AL"],
"36544": ["Irvington", "Alabama", "AL"],
"36545": ["Jackson", "Alabama", "AL"],
"36547": ["Gulf Shores", "Alabama", "AL"],
"36548": ["Leroy", "Alabama", "AL"],
"36549": ["Lillian", "Alabama", "AL"],
"36550": ["Little River", "Alabama", "AL"],
"36551": ["Loxley", "Alabama", "AL"],
"36553": ["McIntosh", "Alabama", "AL"],
"36555": ["Magnolia Springs", "Alabama", "AL"],
"36556": ["Malcolm", "Alabama", "AL"],
"36558": ["Millry", "Alabama", "AL"],
"36559": ["Montrose", "Alabama", "AL"],
"36560": ["Mount Vernon", "Alabama", "AL"],
"36561": ["Orange Beach", "Alabama", "AL"],
"36562": ["Perdido", "Alabama", "AL"],
"36564": ["Point Clear", "Alabama", "AL"],
"36567": ["Robertsdale", "Alabama", "AL"],
"36568": ["Saint Elmo", "Alabama", "AL"],
"36569": ["Saint Stephens", "Alabama", "AL"],
"36571": ["Saraland", "Alabama", "AL"],
"36572": ["Satsuma", "Alabama", "AL"],
"36574": ["Seminole", "Alabama", "AL"],
"36575": ["Semmes", "Alabama", "AL"],
"36576": ["Silverhill", "Alabama", "AL"],
"36578": ["Stapleton", "Alabama", "AL"],
"36579": ["Stockton", "Alabama", "AL"],
"36580": ["Summerdale", "Alabama", "AL"],
"36581": ["Sunflower", "Alabama", "AL"],
"36582": ["Theodore", "Alabama", "AL"],
"36583": ["Tibbie", "Alabama", "AL"],
"36584": ["Vinegar Bend", "Alabama", "AL"],
"36585": ["Wagarville", "Alabama", "AL"],
"36587": ["Wilmer", "Alabama", "AL"],
"36602": ["Mobile", "Alabama", "AL"],
"36603": ["Mobile", "Alabama", "AL"],
"36604": ["Mobile", "Alabama", "AL"],
"36605": ["Mobile", "Alabama", "AL"],
"36606": ["Mobile", "Alabama", "AL"],
"36607": ["Mobile", "Alabama", "AL"],
"36608": ["Mobile", "Alabama", "AL"],
"36609": ["Mobile", "Alabama", "AL"],
"36610": ["Mobile", "Alabama", "AL"],
"36611": ["Mobile", "Alabama", "AL"],
"36612": ["Mobile", "Alabama", "AL"],
"36613": ["Eight Mile", "Alabama", "AL"],
"36615": ["Mobile", "Alabama", "AL"],
"36617": ["Mobile", "Alabama", "AL"],
"36618": ["Mobile", "Alabama", "AL"],
"36619": ["Mobile", "Alabama", "AL"],
"36652": ["Mobile", "Alabama", "AL"],
"36663": ["Mobile", "Alabama", "AL"],
"36688": ["Mobile", "Alabama", "AL"],
"36693": ["Mobile", "Alabama", "AL"],
"36695": ["Mobile", "Alabama", "AL"],
"36701": ["Selma", "Alabama", "AL"],
"36703": ["Selma", "Alabama", "AL"],
"36720": ["Alberta", "Alabama", "AL"],
"36722": ["Arlington", "Alabama", "AL"],
"36723": ["Boykin", "Alabama", "AL"],
"36726": ["Camden", "Alabama", "AL"],
"36727": ["Campbell", "Alabama", "AL"],
"36728": ["Catherine", "Alabama", "AL"],
"36732": ["Demopolis", "Alabama", "AL"],
"36736": ["Dixons Mills", "Alabama", "AL"],
"36738": ["Faunsdale", "Alabama", "AL"],
"36740": ["Forkland", "Alabama", "AL"],
"36741": ["Furman", "Alabama", "AL"],
"36742": ["Gallion", "Alabama", "AL"],
"36744": ["Greensboro", "Alabama", "AL"],
"36748": ["Linden", "Alabama", "AL"],
"36749": ["Jones", "Alabama", "AL"],
"36750": ["Maplesville", "Alabama", "AL"],
"36751": ["Lower Peach Tree", "Alabama", "AL"],
"36752": ["Lowndesboro", "Alabama", "AL"],
"36753": ["McWilliams", "Alabama", "AL"],
"36754": ["Magnolia", "Alabama", "AL"],
"36756": ["Marion", "Alabama", "AL"],
"36758": ["Plantersville", "Alabama", "AL"],
"36759": ["Marion Junction", "Alabama", "AL"],
"36761": ["Minter", "Alabama", "AL"],
"36763": ["Myrtlewood", "Alabama", "AL"],
"36764": ["Nanafalia", "Alabama", "AL"],
"36765": ["Newbern", "Alabama", "AL"],
"36766": ["Oak Hill", "Alabama", "AL"],
"36767": ["Orrville", "Alabama", "AL"],
"36768": ["Pine Apple", "Alabama", "AL"],
"36769": ["Pine Hill", "Alabama", "AL"],
"36773": ["Safford", "Alabama", "AL"],
"36775": ["Sardis", "Alabama", "AL"],
"36776": ["Sawyerville", "Alabama", "AL"],
"36782": ["Sweet Water", "Alabama", "AL"],
"36783": ["Thomaston", "Alabama", "AL"],
"36784": ["Thomasville", "Alabama", "AL"],
"36785": ["Tyler", "Alabama", "AL"],
"36786": ["Uniontown", "Alabama", "AL"],
"36790": ["Stanton", "Alabama", "AL"],
"36792": ["Randolph", "Alabama", "AL"],
"36793": ["Lawley", "Alabama", "AL"],
"36801": ["Opelika", "Alabama", "AL"],
"36804": ["Opelika", "Alabama", "AL"],
"36830": ["Auburn", "Alabama", "AL"],
"36832": ["Auburn", "Alabama", "AL"],
"36849": ["Auburn University", "Alabama", "AL"],
"36850": ["Camp Hill", "Alabama", "AL"],
"36851": ["Cottonton", "Alabama", "AL"],
"36852": ["Cusseta", "Alabama", "AL"],
"36853": ["Dadeville", "Alabama", "AL"],
"36854": ["Valley", "Alabama", "AL"],
"36855": ["Five Points", "Alabama", "AL"],
"36856": ["Fort Mitchell", "Alabama", "AL"],
"36858": ["Hatchechubbee", "Alabama", "AL"],
"36860": ["Hurtsboro", "Alabama", "AL"],
"36861": ["Jacksons Gap", "Alabama", "AL"],
"36862": ["Lafayette", "Alabama", "AL"],
"36863": ["Lanett", "Alabama", "AL"],
"36865": ["Loachapoka", "Alabama", "AL"],
"36866": ["Notasulga", "Alabama", "AL"],
"36867": ["Phenix City", "Alabama", "AL"],
"36869": ["Phenix City", "Alabama", "AL"],
"36870": ["Phenix City", "Alabama", "AL"],
"36871": ["Pittsview", "Alabama", "AL"],
"36874": ["Salem", "Alabama", "AL"],
"36875": ["Seale", "Alabama", "AL"],
"36877": ["Smiths Station", "Alabama", "AL"],
"36879": ["Waverly", "Alabama", "AL"],
"36901": ["Bellamy", "Alabama", "AL"],
"36904": ["Butler", "Alabama", "AL"],
"36907": ["Cuba", "Alabama", "AL"],
"36908": ["Gilbertown", "Alabama", "AL"],
"36910": ["Jachin", "Alabama", "AL"],
"36912": ["Lisman", "Alabama", "AL"],
"36913": ["Melvin", "Alabama", "AL"],
"36915": ["Needham", "Alabama", "AL"],
"36916": ["Pennington", "Alabama", "AL"],
"36919": ["Silas", "Alabama", "AL"],
"36921": ["Toxey", "Alabama", "AL"],
"36922": ["Ward", "Alabama", "AL"],
"36925": ["York", "Alabama", "AL"],
"37010": ["Adams", "Tennessee", "TN"],
"37012": ["Alexandria", "Tennessee", "TN"],
"37013": ["Antioch", "Tennessee", "TN"],
"37014": ["Arrington", "Tennessee", "TN"],
"37015": ["Ashland City", "Tennessee", "TN"],
"37016": ["Auburntown", "Tennessee", "TN"],
"37018": ["Beechgrove", "Tennessee", "TN"],
"37019": ["Belfast", "Tennessee", "TN"],
"37020": ["Bell Buckle", "Tennessee", "TN"],
"37022": ["Bethpage", "Tennessee", "TN"],
"37023": ["Big Rock", "Tennessee", "TN"],
"37025": ["Bon Aqua", "Tennessee", "TN"],
"37026": ["Bradyville", "Tennessee", "TN"],
"37027": ["Brentwood", "Tennessee", "TN"],
"37028": ["Bumpus Mills", "Tennessee", "TN"],
"37029": ["Burns", "Tennessee", "TN"],
"37030": ["Carthage", "Tennessee", "TN"],
"37031": ["Castalian Springs", "Tennessee", "TN"],
"37032": ["Cedar Hill", "Tennessee", "TN"],
"37033": ["Centerville", "Tennessee", "TN"],
"37034": ["Chapel Hill", "Tennessee", "TN"],
"37035": ["Chapmansboro", "Tennessee", "TN"],
"37036": ["Charlotte", "Tennessee", "TN"],
"37037": ["Christiana", "Tennessee", "TN"],
"37040": ["Clarksville", "Tennessee", "TN"],
"37042": ["Clarksville", "Tennessee", "TN"],
"37043": ["Clarksville", "Tennessee", "TN"],
"37046": ["College Grove", "Tennessee", "TN"],
"37047": ["Cornersville", "Tennessee", "TN"],
"37048": ["Cottontown", "Tennessee", "TN"],
"37049": ["Cross Plains", "Tennessee", "TN"],
"37050": ["Cumberland City", "Tennessee", "TN"],
"37051": ["Cumberland Furnace", "Tennessee", "TN"],
"37052": ["Cunningham", "Tennessee", "TN"],
"37055": ["Dickson", "Tennessee", "TN"],
"37057": ["Dixon Springs", "Tennessee", "TN"],
"37058": ["Dover", "Tennessee", "TN"],
"37059": ["Dowelltown", "Tennessee", "TN"],
"37060": ["Eagleville", "Tennessee", "TN"],
"37061": ["Erin", "Tennessee", "TN"],
"37062": ["Fairview", "Tennessee", "TN"],
"37064": ["Franklin", "Tennessee", "TN"],
"37066": ["Gallatin", "Tennessee", "TN"],
"37067": ["Franklin", "Tennessee", "TN"],
"37069": ["Franklin", "Tennessee", "TN"],
"37072": ["Goodlettsville", "Tennessee", "TN"],
"37073": ["Greenbrier", "Tennessee", "TN"],
"37074": ["Hartsville", "Tennessee", "TN"],
"37075": ["Hendersonville", "Tennessee", "TN"],
"37076": ["Hermitage", "Tennessee", "TN"],
"37078": ["Hurricane Mills", "Tennessee", "TN"],
"37079": ["Indian Mound", "Tennessee", "TN"],
"37080": ["Joelton", "Tennessee", "TN"],
"37082": ["Kingston Springs", "Tennessee", "TN"],
"37083": ["Lafayette", "Tennessee", "TN"],
"37085": ["Lascassas", "Tennessee", "TN"],
"37086": ["La Vergne", "Tennessee", "TN"],
"37087": ["Lebanon", "Tennessee", "TN"],
"37090": ["Lebanon", "Tennessee", "TN"],
"37091": ["Lewisburg", "Tennessee", "TN"],
"37095": ["Liberty", "Tennessee", "TN"],
"37096": ["Linden", "Tennessee", "TN"],
"37097": ["Lobelville", "Tennessee", "TN"],
"37098": ["Lyles", "Tennessee", "TN"],
"37101": ["McEwen", "Tennessee", "TN"],
"37110": ["Mcminnville", "Tennessee", "TN"],
"37115": ["Madison", "Tennessee", "TN"],
"37118": ["Milton", "Tennessee", "TN"],
"37122": ["Mount Juliet", "Tennessee", "TN"],
"37127": ["Murfreesboro", "Tennessee", "TN"],
"37128": ["Murfreesboro", "Tennessee", "TN"],
"37129": ["Murfreesboro", "Tennessee", "TN"],
"37130": ["Murfreesboro", "Tennessee", "TN"],
"37132": ["Murfreesboro", "Tennessee", "TN"],
"37134": ["New Johnsonville", "Tennessee", "TN"],
"37135": ["Nolensville", "Tennessee", "TN"],
"37137": ["Nunnelly", "Tennessee", "TN"],
"37138": ["Old Hickory", "Tennessee", "TN"],
"37140": ["Only", "Tennessee", "TN"],
"37141": ["Orlinda", "Tennessee", "TN"],
"37142": ["Palmyra", "Tennessee", "TN"],
"37143": ["Pegram", "Tennessee", "TN"],
"37144": ["Petersburg", "Tennessee", "TN"],
"37145": ["Pleasant Shade", "Tennessee", "TN"],
"37146": ["Pleasant View", "Tennessee", "TN"],
"37148": ["Portland", "Tennessee", "TN"],
"37149": ["Readyville", "Tennessee", "TN"],
"37150": ["Red Boiling Springs", "Tennessee", "TN"],
"37151": ["Riddleton", "Tennessee", "TN"],
"37153": ["Rockvale", "Tennessee", "TN"],
"37160": ["Shelbyville", "Tennessee", "TN"],
"37165": ["Slayden", "Tennessee", "TN"],
"37166": ["Smithville", "Tennessee", "TN"],
"37167": ["Smyrna", "Tennessee", "TN"],
"37171": ["Southside", "Tennessee", "TN"],
"37172": ["Springfield", "Tennessee", "TN"],
"37174": ["Spring Hill", "Tennessee", "TN"],
"37175": ["Stewart", "Tennessee", "TN"],
"37178": ["Tennessee Ridge", "Tennessee", "TN"],
"37179": ["Thompsons Station", "Tennessee", "TN"],
"37180": ["Unionville", "Tennessee", "TN"],
"37181": ["Vanleer", "Tennessee", "TN"],
"37183": ["Wartrace", "Tennessee", "TN"],
"37184": ["Watertown", "Tennessee", "TN"],
"37185": ["Waverly", "Tennessee", "TN"],
"37186": ["Westmoreland", "Tennessee", "TN"],
"37187": ["White Bluff", "Tennessee", "TN"],
"37188": ["White House", "Tennessee", "TN"],
"37189": ["Whites Creek", "Tennessee", "TN"],
"37190": ["Woodbury", "Tennessee", "TN"],
"37191": ["Woodlawn", "Tennessee", "TN"],
"37201": ["Nashville", "Tennessee", "TN"],
"37203": ["Nashville", "Tennessee", "TN"],
"37204": ["Nashville", "Tennessee", "TN"],
"37205": ["Nashville", "Tennessee", "TN"],
"37206": ["Nashville", "Tennessee", "TN"],
"37207": ["Nashville", "Tennessee", "TN"],
"37208": ["Nashville", "Tennessee", "TN"],
"37209": ["Nashville", "Tennessee", "TN"],
"37210": ["Nashville", "Tennessee", "TN"],
"37211": ["Nashville", "Tennessee", "TN"],
"37212": ["Nashville", "Tennessee", "TN"],
"37213": ["Nashville", "Tennessee", "TN"],
"37214": ["Nashville", "Tennessee", "TN"],
"37215": ["Nashville", "Tennessee", "TN"],
"37216": ["Nashville", "Tennessee", "TN"],
"37217": ["Nashville", "Tennessee", "TN"],
"37218": ["Nashville", "Tennessee", "TN"],
"37219": ["Nashville", "Tennessee", "TN"],
"37220": ["Nashville", "Tennessee", "TN"],
"37221": ["Nashville", "Tennessee", "TN"],
"37228": ["Nashville", "Tennessee", "TN"],
"37232": ["Nashville", "Tennessee", "TN"],
"37238": ["Nashville", "Tennessee", "TN"],
"37301": ["Altamont", "Tennessee", "TN"],
"37302": ["Apison", "Tennessee", "TN"],
"37303": ["Athens", "Tennessee", "TN"],
"37305": ["Beersheba Springs", "Tennessee", "TN"],
"37306": ["Belvidere", "Tennessee", "TN"],
"37307": ["Benton", "Tennessee", "TN"],
"37308": ["Birchwood", "Tennessee", "TN"],
"37309": ["Calhoun", "Tennessee", "TN"],
"37310": ["Charleston", "Tennessee", "TN"],
"37311": ["Cleveland", "Tennessee", "TN"],
"37312": ["Cleveland", "Tennessee", "TN"],
"37313": ["Coalmont", "Tennessee", "TN"],
"37315": ["Collegedale", "Tennessee", "TN"],
"37316": ["Conasauga", "Tennessee", "TN"],
"37317": ["Copperhill", "Tennessee", "TN"],
"37318": ["Cowan", "Tennessee", "TN"],
"37321": ["Dayton", "Tennessee", "TN"],
"37322": ["Decatur", "Tennessee", "TN"],
"37323": ["Cleveland", "Tennessee", "TN"],
"37324": ["Decherd", "Tennessee", "TN"],
"37325": ["Delano", "Tennessee", "TN"],
"37326": ["Ducktown", "Tennessee", "TN"],
"37327": ["Dunlap", "Tennessee", "TN"],
"37328": ["Elora", "Tennessee", "TN"],
"37329": ["Englewood", "Tennessee", "TN"],
"37330": ["Estill Springs", "Tennessee", "TN"],
"37331": ["Etowah", "Tennessee", "TN"],
"37332": ["Evensville", "Tennessee", "TN"],
"37333": ["Farner", "Tennessee", "TN"],
"37334": ["Fayetteville", "Tennessee", "TN"],
"37335": ["Flintville", "Tennessee", "TN"],
"37336": ["Georgetown", "Tennessee", "TN"],
"37337": ["Grandview", "Tennessee", "TN"],
"37338": ["Graysville", "Tennessee", "TN"],
"37339": ["Gruetli Laager", "Tennessee", "TN"],
"37340": ["Guild", "Tennessee", "TN"],
"37341": ["Harrison", "Tennessee", "TN"],
"37342": ["Hillsboro", "Tennessee", "TN"],
"37343": ["Hixson", "Tennessee", "TN"],
"37345": ["Huntland", "Tennessee", "TN"],
"37347": ["Jasper", "Tennessee", "TN"],
"37348": ["Kelso", "Tennessee", "TN"],
"37350": ["Lookout Mountain", "Tennessee", "TN"],
"37351": ["Lupton City", "Tennessee", "TN"],
"37352": ["Lynchburg", "Tennessee", "TN"],
"37353": ["McDonald", "Tennessee", "TN"],
"37354": ["Madisonville", "Tennessee", "TN"],
"37355": ["Manchester", "Tennessee", "TN"],
"37356": ["Monteagle", "Tennessee", "TN"],
"37357": ["Morrison", "Tennessee", "TN"],
"37359": ["Mulberry", "Tennessee", "TN"],
"37360": ["Normandy", "Tennessee", "TN"],
"37361": ["Ocoee", "Tennessee", "TN"],
"37362": ["Old Fort", "Tennessee", "TN"],
"37363": ["Ooltewah", "Tennessee", "TN"],
"37365": ["Palmer", "Tennessee", "TN"],
"37366": ["Pelham", "Tennessee", "TN"],
"37367": ["Pikeville", "Tennessee", "TN"],
"37369": ["Reliance", "Tennessee", "TN"],
"37370": ["Riceville", "Tennessee", "TN"],
"37373": ["Sale Creek", "Tennessee", "TN"],
"37374": ["Sequatchie", "Tennessee", "TN"],
"37375": ["Sewanee", "Tennessee", "TN"],
"37376": ["Sherwood", "Tennessee", "TN"],
"37377": ["Signal Mountain", "Tennessee", "TN"],
"37379": ["Soddy Daisy", "Tennessee", "TN"],
"37380": ["South Pittsburg", "Tennessee", "TN"],
"37381": ["Spring City", "Tennessee", "TN"],
"37383": ["Sewanee", "Tennessee", "TN"],
"37385": ["Tellico Plains", "Tennessee", "TN"],
"37387": ["Tracy City", "Tennessee", "TN"],
"37388": ["Tullahoma", "Tennessee", "TN"],
"37391": ["Turtletown", "Tennessee", "TN"],
"37394": ["Viola", "Tennessee", "TN"],
"37396": ["Whiteside", "Tennessee", "TN"],
"37397": ["Whitwell", "Tennessee", "TN"],
"37398": ["Winchester", "Tennessee", "TN"],
"37402": ["Chattanooga", "Tennessee", "TN"],
"37403": ["Chattanooga", "Tennessee", "TN"],
"37404": ["Chattanooga", "Tennessee", "TN"],
"37405": ["Chattanooga", "Tennessee", "TN"],
"37406": ["Chattanooga", "Tennessee", "TN"],
"37407": ["Chattanooga", "Tennessee", "TN"],
"37408": ["Chattanooga", "Tennessee", "TN"],
"37409": ["Chattanooga", "Tennessee", "TN"],
"37410": ["Chattanooga", "Tennessee", "TN"],
"37411": ["Chattanooga", "Tennessee", "TN"],
"37412": ["Chattanooga", "Tennessee", "TN"],
"37415": ["Chattanooga", "Tennessee", "TN"],
"37416": ["Chattanooga", "Tennessee", "TN"],
"37419": ["Chattanooga", "Tennessee", "TN"],
"37421": ["Chattanooga", "Tennessee", "TN"],
"37450": ["Chattanooga", "Tennessee", "TN"],
"37601": ["Johnson City", "Tennessee", "TN"],
"37604": ["Johnson City", "Tennessee", "TN"],
"37614": ["Johnson City", "Tennessee", "TN"],
"37615": ["Johnson City", "Tennessee", "TN"],
"37616": ["Afton", "Tennessee", "TN"],
"37617": ["Blountville", "Tennessee", "TN"],
"37618": ["Bluff City", "Tennessee", "TN"],
"37620": ["Bristol", "Tennessee", "TN"],
"37640": ["Butler", "Tennessee", "TN"],
"37641": ["Chuckey", "Tennessee", "TN"],
"37642": ["Church Hill", "Tennessee", "TN"],
"37643": ["Elizabethton", "Tennessee", "TN"],
"37645": ["Mount Carmel", "Tennessee", "TN"],
"37650": ["Erwin", "Tennessee", "TN"],
"37656": ["Fall Branch", "Tennessee", "TN"],
"37657": ["Flag Pond", "Tennessee", "TN"],
"37658": ["Hampton", "Tennessee", "TN"],
"37659": ["Jonesborough", "Tennessee", "TN"],
"37660": ["Kingsport", "Tennessee", "TN"],
"37663": ["Kingsport", "Tennessee", "TN"],
"37664": ["Kingsport", "Tennessee", "TN"],
"37665": ["Kingsport", "Tennessee", "TN"],
"37680": ["Laurel Bloomery", "Tennessee", "TN"],
"37681": ["Limestone", "Tennessee", "TN"],
"37682": ["Milligan College", "Tennessee", "TN"],
"37683": ["Mountain City", "Tennessee", "TN"],
"37686": ["Piney Flats", "Tennessee", "TN"],
"37687": ["Roan Mountain", "Tennessee", "TN"],
"37688": ["Shady Valley", "Tennessee", "TN"],
"37690": ["Telford", "Tennessee", "TN"],
"37691": ["Trade", "Tennessee", "TN"],
"37692": ["Unicoi", "Tennessee", "TN"],
"37694": ["Watauga", "Tennessee", "TN"],
"37701": ["Alcoa", "Tennessee", "TN"],
"37705": ["Andersonville", "Tennessee", "TN"],
"37708": ["Bean Station", "Tennessee", "TN"],
"37709": ["Blaine", "Tennessee", "TN"],
"37710": ["Briceville", "Tennessee", "TN"],
"37711": ["Bulls Gap", "Tennessee", "TN"],
"37713": ["Bybee", "Tennessee", "TN"],
"37714": ["Caryville", "Tennessee", "TN"],
"37715": ["Clairfield", "Tennessee", "TN"],
"37716": ["Clinton", "Tennessee", "TN"],
"37719": ["Coalfield", "Tennessee", "TN"],
"37721": ["Corryton", "Tennessee", "TN"],
"37722": ["Cosby", "Tennessee", "TN"],
"37723": ["Crab Orchard", "Tennessee", "TN"],
"37724": ["Cumberland Gap", "Tennessee", "TN"],
"37725": ["Dandridge", "Tennessee", "TN"],
"37726": ["Deer Lodge", "Tennessee", "TN"],
"37727": ["Del Rio", "Tennessee", "TN"],
"37729": ["Duff", "Tennessee", "TN"],
"37730": ["Eagan", "Tennessee", "TN"],
"37731": ["Eidson", "Tennessee", "TN"],
"37732": ["Elgin", "Tennessee", "TN"],
"37733": ["Rugby", "Tennessee", "TN"],
"37737": ["Friendsville", "Tennessee", "TN"],
"37738": ["Gatlinburg", "Tennessee", "TN"],
"37742": ["Greenback", "Tennessee", "TN"],
"37743": ["Greeneville", "Tennessee", "TN"],
"37745": ["Greeneville", "Tennessee", "TN"],
"37748": ["Harriman", "Tennessee", "TN"],
"37752": ["Harrogate", "Tennessee", "TN"],
"37753": ["Hartford", "Tennessee", "TN"],
"37754": ["Heiskell", "Tennessee", "TN"],
"37755": ["Helenwood", "Tennessee", "TN"],
"37756": ["Huntsville", "Tennessee", "TN"],
"37757": ["Jacksboro", "Tennessee", "TN"],
"37760": ["Jefferson City", "Tennessee", "TN"],
"37762": ["Jellico", "Tennessee", "TN"],
"37763": ["Kingston", "Tennessee", "TN"],
"37764": ["Kodak", "Tennessee", "TN"],
"37765": ["Kyles Ford", "Tennessee", "TN"],
"37766": ["La Follette", "Tennessee", "TN"],
"37769": ["Rocky Top", "Tennessee", "TN"],
"37770": ["Lancing", "Tennessee", "TN"],
"37771": ["Lenoir City", "Tennessee", "TN"],
"37772": ["Lenoir City", "Tennessee", "TN"],
"37774": ["Loudon", "Tennessee", "TN"],
"37777": ["Louisville", "Tennessee", "TN"],
"37779": ["Luttrell", "Tennessee", "TN"],
"37801": ["Maryville", "Tennessee", "TN"],
"37803": ["Maryville", "Tennessee", "TN"],
"37804": ["Maryville", "Tennessee", "TN"],
"37806": ["Mascot", "Tennessee", "TN"],
"37807": ["Maynardville", "Tennessee", "TN"],
"37809": ["Midway", "Tennessee", "TN"],
"37810": ["Mohawk", "Tennessee", "TN"],
"37811": ["Mooresburg", "Tennessee", "TN"],
"37813": ["Morristown", "Tennessee", "TN"],
"37814": ["Morristown", "Tennessee", "TN"],
"37818": ["Mosheim", "Tennessee", "TN"],
"37819": ["Newcomb", "Tennessee", "TN"],
"37820": ["New Market", "Tennessee", "TN"],
"37821": ["Newport", "Tennessee", "TN"],
"37825": ["New Tazewell", "Tennessee", "TN"],
"37826": ["Niota", "Tennessee", "TN"],
"37828": ["Norris", "Tennessee", "TN"],
"37829": ["Oakdale", "Tennessee", "TN"],
"37830": ["Oak Ridge", "Tennessee", "TN"],
"37840": ["Oliver Springs", "Tennessee", "TN"],
"37841": ["Oneida", "Tennessee", "TN"],
"37843": ["Parrottsville", "Tennessee", "TN"],
"37845": ["Petros", "Tennessee", "TN"],
"37846": ["Philadelphia", "Tennessee", "TN"],
"37847": ["Pioneer", "Tennessee", "TN"],
"37848": ["Powder Springs", "Tennessee", "TN"],
"37849": ["Powell", "Tennessee", "TN"],
"37851": ["Pruden", "Tennessee", "TN"],
"37852": ["Robbins", "Tennessee", "TN"],
"37853": ["Rockford", "Tennessee", "TN"],
"37854": ["Rockwood", "Tennessee", "TN"],
"37857": ["Rogersville", "Tennessee", "TN"],
"37860": ["Russellville", "Tennessee", "TN"],
"37861": ["Rutledge", "Tennessee", "TN"],
"37862": ["Sevierville", "Tennessee", "TN"],
"37863": ["Pigeon Forge", "Tennessee", "TN"],
"37865": ["Seymour", "Tennessee", "TN"],
"37866": ["Sharps Chapel", "Tennessee", "TN"],
"37869": ["Sneedville", "Tennessee", "TN"],
"37870": ["Speedwell", "Tennessee", "TN"],
"37871": ["Strawberry Plains", "Tennessee", "TN"],
"37872": ["Sunbright", "Tennessee", "TN"],
"37873": ["Surgoinsville", "Tennessee", "TN"],
"37874": ["Sweetwater", "Tennessee", "TN"],
"37876": ["Sevierville", "Tennessee", "TN"],
"37877": ["Talbott", "Tennessee", "TN"],
"37878": ["Tallassee", "Tennessee", "TN"],
"37879": ["Tazewell", "Tennessee", "TN"],
"37880": ["Ten Mile", "Tennessee", "TN"],
"37881": ["Thorn Hill", "Tennessee", "TN"],
"37882": ["Townsend", "Tennessee", "TN"],
"37885": ["Vonore", "Tennessee", "TN"],
"37886": ["Walland", "Tennessee", "TN"],
"37887": ["Wartburg", "Tennessee", "TN"],
"37888": ["Washburn", "Tennessee", "TN"],
"37890": ["White Pine", "Tennessee", "TN"],
"37891": ["Whitesburg", "Tennessee", "TN"],
"37892": ["Winfield", "Tennessee", "TN"],
"37902": ["Knoxville", "Tennessee", "TN"],
"37909": ["Knoxville", "Tennessee", "TN"],
"37912": ["Knoxville", "Tennessee", "TN"],
"37914": ["Knoxville", "Tennessee", "TN"],
"37915": ["Knoxville", "Tennessee", "TN"],
"37916": ["Knoxville", "Tennessee", "TN"],
"37917": ["Knoxville", "Tennessee", "TN"],
"37918": ["Knoxville", "Tennessee", "TN"],
"37919": ["Knoxville", "Tennessee", "TN"],
"37920": ["Knoxville", "Tennessee", "TN"],
"37921": ["Knoxville", "Tennessee", "TN"],
"37922": ["Knoxville", "Tennessee", "TN"],
"37923": ["Knoxville", "Tennessee", "TN"],
"37924": ["Knoxville", "Tennessee", "TN"],
"37929": ["Knoxville", "Tennessee", "TN"],
"37931": ["Knoxville", "Tennessee", "TN"],
"37932": ["Knoxville", "Tennessee", "TN"],
"37934": ["Farragut", "Tennessee", "TN"],
"37938": ["Knoxville", "Tennessee", "TN"],
"38001": ["Alamo", "Tennessee", "TN"],
"38002": ["Arlington", "Tennessee", "TN"],
"38004": ["Atoka", "Tennessee", "TN"],
"38006": ["Bells", "Tennessee", "TN"],
"38007": ["Bogota", "Tennessee", "TN"],
"38008": ["Bolivar", "Tennessee", "TN"],
"38011": ["Brighton", "Tennessee", "TN"],
"38012": ["Brownsville", "Tennessee", "TN"],
"38015": ["Burlison", "Tennessee", "TN"],
"38016": ["Cordova", "Tennessee", "TN"],
"38017": ["Collierville", "Tennessee", "TN"],
"38018": ["Cordova", "Tennessee", "TN"],
"38019": ["Covington", "Tennessee", "TN"],
"38021": ["Crockett Mills", "Tennessee", "TN"],
"38023": ["Drummonds", "Tennessee", "TN"],
"38024": ["Dyersburg", "Tennessee", "TN"],
"38028": ["Eads", "Tennessee", "TN"],
"38029": ["Ellendale", "Tennessee", "TN"],
"38030": ["Finley", "Tennessee", "TN"],
"38034": ["Friendship", "Tennessee", "TN"],
"38036": ["Gallaway", "Tennessee", "TN"],
"38037": ["Gates", "Tennessee", "TN"],
"38039": ["Grand Junction", "Tennessee", "TN"],
"38040": ["Halls", "Tennessee", "TN"],
"38041": ["Henning", "Tennessee", "TN"],
"38042": ["Hickory Valley", "Tennessee", "TN"],
"38044": ["Hornsby", "Tennessee", "TN"],
"38046": ["La Grange", "Tennessee", "TN"],
"38047": ["Lenox", "Tennessee", "TN"],
"38049": ["Mason", "Tennessee", "TN"],
"38050": ["Maury City", "Tennessee", "TN"],
"38052": ["Middleton", "Tennessee", "TN"],
"38053": ["Millington", "Tennessee", "TN"],
"38054": ["Millington", "Tennessee", "TN"],
"38057": ["Moscow", "Tennessee", "TN"],
"38058": ["Munford", "Tennessee", "TN"],
"38059": ["Newbern", "Tennessee", "TN"],
"38060": ["Oakland", "Tennessee", "TN"],
"38061": ["Pocahontas", "Tennessee", "TN"],
"38063": ["Ripley", "Tennessee", "TN"],
"38066": ["Rossville", "Tennessee", "TN"],
"38067": ["Saulsbury", "Tennessee", "TN"],
"38068": ["Somerville", "Tennessee", "TN"],
"38069": ["Stanton", "Tennessee", "TN"],
"38070": ["Tigrett", "Tennessee", "TN"],
"38075": ["Whiteville", "Tennessee", "TN"],
"38076": ["Williston", "Tennessee", "TN"],
"38077": ["Wynnburg", "Tennessee", "TN"],
"38079": ["Tiptonville", "Tennessee", "TN"],
"38080": ["Ridgely", "Tennessee", "TN"],
"38103": ["Memphis", "Tennessee", "TN"],
"38104": ["Memphis", "Tennessee", "TN"],
"38105": ["Memphis", "Tennessee", "TN"],
"38106": ["Memphis", "Tennessee", "TN"],
"38107": ["Memphis", "Tennessee", "TN"],
"38108": ["Memphis", "Tennessee", "TN"],
"38109": ["Memphis", "Tennessee", "TN"],
"38111": ["Memphis", "Tennessee", "TN"],
"38112": ["Memphis", "Tennessee", "TN"],
"38113": ["Memphis", "Tennessee", "TN"],
"38114": ["Memphis", "Tennessee", "TN"],
"38115": ["Memphis", "Tennessee", "TN"],
"38116": ["Memphis", "Tennessee", "TN"],
"38117": ["Memphis", "Tennessee", "TN"],
"38118": ["Memphis", "Tennessee", "TN"],
"38119": ["Memphis", "Tennessee", "TN"],
"38120": ["Memphis", "Tennessee", "TN"],
"38122": ["Memphis", "Tennessee", "TN"],
"38125": ["Memphis", "Tennessee", "TN"],
"38126": ["Memphis", "Tennessee", "TN"],
"38127": ["Memphis", "Tennessee", "TN"],
"38128": ["Memphis", "Tennessee", "TN"],
"38131": ["Memphis", "Tennessee", "TN"],
"38132": ["Memphis", "Tennessee", "TN"],
"38133": ["Memphis", "Tennessee", "TN"],
"38134": ["Memphis", "Tennessee", "TN"],
"38135": ["Memphis", "Tennessee", "TN"],
"38138": ["Germantown", "Tennessee", "TN"],
"38139": ["Germantown", "Tennessee", "TN"],
"38141": ["Memphis", "Tennessee", "TN"],
"38152": ["Memphis", "Tennessee", "TN"],
"38201": ["McKenzie", "Tennessee", "TN"],
"38220": ["Atwood", "Tennessee", "TN"],
"38221": ["Big Sandy", "Tennessee", "TN"],
"38222": ["Buchanan", "Tennessee", "TN"],
"38224": ["Cottage Grove", "Tennessee", "TN"],
"38225": ["Dresden", "Tennessee", "TN"],
"38226": ["Dukedom", "Tennessee", "TN"],
"38229": ["Gleason", "Tennessee", "TN"],
"38230": ["Greenfield", "Tennessee", "TN"],
"38231": ["Henry", "Tennessee", "TN"],
"38232": ["Hornbeak", "Tennessee", "TN"],
"38233": ["Kenton", "Tennessee", "TN"],
"38235": ["McLemoresville", "Tennessee", "TN"],
"38236": ["Mansfield", "Tennessee", "TN"],
"38237": ["Martin", "Tennessee", "TN"],
"38240": ["Obion", "Tennessee", "TN"],
"38241": ["Palmersville", "Tennessee", "TN"],
"38242": ["Paris", "Tennessee", "TN"],
"38251": ["Puryear", "Tennessee", "TN"],
"38253": ["Rives", "Tennessee", "TN"],
"38254": ["Samburg", "Tennessee", "TN"],
"38255": ["Sharon", "Tennessee", "TN"],
"38256": ["Springville", "Tennessee", "TN"],
"38257": ["South Fulton", "Tennessee", "TN"],
"38258": ["Trezevant", "Tennessee", "TN"],
"38259": ["Trimble", "Tennessee", "TN"],
"38260": ["Troy", "Tennessee", "TN"],
"38261": ["Union City", "Tennessee", "TN"],
"38301": ["Jackson", "Tennessee", "TN"],
"38305": ["Jackson", "Tennessee", "TN"],
"38310": ["Adamsville", "Tennessee", "TN"],
"38311": ["Bath Springs", "Tennessee", "TN"],
"38313": ["Beech Bluff", "Tennessee", "TN"],
"38315": ["Bethel Springs", "Tennessee", "TN"],
"38316": ["Bradford", "Tennessee", "TN"],
"38317": ["Bruceton", "Tennessee", "TN"],
"38318": ["Buena Vista", "Tennessee", "TN"],
"38320": ["Camden", "Tennessee", "TN"],
"38321": ["Cedar Grove", "Tennessee", "TN"],
"38326": ["Counce", "Tennessee", "TN"],
"38327": ["Crump", "Tennessee", "TN"],
"38328": ["Darden", "Tennessee", "TN"],
"38329": ["Decaturville", "Tennessee", "TN"],
"38330": ["Dyer", "Tennessee", "TN"],
"38332": ["Enville", "Tennessee", "TN"],
"38333": ["Eva", "Tennessee", "TN"],
"38334": ["Finger", "Tennessee", "TN"],
"38337": ["Gadsden", "Tennessee", "TN"],
"38338": ["Gibson", "Tennessee", "TN"],
"38339": ["Guys", "Tennessee", "TN"],
"38340": ["Henderson", "Tennessee", "TN"],
"38341": ["Holladay", "Tennessee", "TN"],
"38342": ["Hollow Rock", "Tennessee", "TN"],
"38343": ["Humboldt", "Tennessee", "TN"],
"38344": ["Huntingdon", "Tennessee", "TN"],
"38345": ["Huron", "Tennessee", "TN"],
"38347": ["Jacks Creek", "Tennessee", "TN"],
"38348": ["Lavinia", "Tennessee", "TN"],
"38351": ["Lexington", "Tennessee", "TN"],
"38352": ["Luray", "Tennessee", "TN"],
"38355": ["Medina", "Tennessee", "TN"],
"38356": ["Medon", "Tennessee", "TN"],
"38357": ["Michie", "Tennessee", "TN"],
"38358": ["Milan", "Tennessee", "TN"],
"38359": ["Milledgeville", "Tennessee", "TN"],
"38361": ["Morris Chapel", "Tennessee", "TN"],
"38362": ["Oakfield", "Tennessee", "TN"],
"38363": ["Parsons", "Tennessee", "TN"],
"38365": ["Pickwick Dam", "Tennessee", "TN"],
"38366": ["Pinson", "Tennessee", "TN"],
"38367": ["Ramer", "Tennessee", "TN"],
"38368": ["Reagan", "Tennessee", "TN"],
"38369": ["Rutherford", "Tennessee", "TN"],
"38370": ["Saltillo", "Tennessee", "TN"],
"38371": ["Sardis", "Tennessee", "TN"],
"38372": ["Savannah", "Tennessee", "TN"],
"38374": ["Scotts Hill", "Tennessee", "TN"],
"38375": ["Selmer", "Tennessee", "TN"],
"38376": ["Shiloh", "Tennessee", "TN"],
"38379": ["Stantonville", "Tennessee", "TN"],
"38380": ["Sugar Tree", "Tennessee", "TN"],
"38381": ["Toone", "Tennessee", "TN"],
"38382": ["Trenton", "Tennessee", "TN"],
"38387": ["Westport", "Tennessee", "TN"],
"38388": ["Wildersville", "Tennessee", "TN"],
"38389": ["Yorkville", "Tennessee", "TN"],
"38390": ["Yuma", "Tennessee", "TN"],
"38391": ["Denmark", "Tennessee", "TN"],
"38392": ["Mercer", "Tennessee", "TN"],
"38401": ["Columbia", "Tennessee", "TN"],
"38425": ["Clifton", "Tennessee", "TN"],
"38449": ["Ardmore", "Tennessee", "TN"],
"38450": ["Collinwood", "Tennessee", "TN"],
"38451": ["Culleoka", "Tennessee", "TN"],
"38452": ["Cypress Inn", "Tennessee", "TN"],
"38453": ["Dellrose", "Tennessee", "TN"],
"38454": ["Duck River", "Tennessee", "TN"],
"38455": ["Elkton", "Tennessee", "TN"],
"38456": ["Ethridge", "Tennessee", "TN"],
"38457": ["Five Points", "Tennessee", "TN"],
"38459": ["Frankewing", "Tennessee", "TN"],
"38460": ["Goodspring", "Tennessee", "TN"],
"38461": ["Hampshire", "Tennessee", "TN"],
"38462": ["Hohenwald", "Tennessee", "TN"],
"38463": ["Iron City", "Tennessee", "TN"],
"38464": ["Lawrenceburg", "Tennessee", "TN"],
"38468": ["Leoma", "Tennessee", "TN"],
"38469": ["Loretto", "Tennessee", "TN"],
"38471": ["Lutts", "Tennessee", "TN"],
"38472": ["Lynnville", "Tennessee", "TN"],
"38473": ["Minor Hill", "Tennessee", "TN"],
"38474": ["Mount Pleasant", "Tennessee", "TN"],
"38475": ["Olivehill", "Tennessee", "TN"],
"38476": ["Primm Springs", "Tennessee", "TN"],
"38477": ["Prospect", "Tennessee", "TN"],
"38478": ["Pulaski", "Tennessee", "TN"],
"38481": ["Saint Joseph", "Tennessee", "TN"],
"38482": ["Santa Fe", "Tennessee", "TN"],
"38483": ["Summertown", "Tennessee", "TN"],
"38485": ["Waynesboro", "Tennessee", "TN"],
"38486": ["Westpoint", "Tennessee", "TN"],
"38487": ["Williamsport", "Tennessee", "TN"],
"38488": ["Taft", "Tennessee", "TN"],
"38501": ["Cookeville", "Tennessee", "TN"],
"38503": ["Cookeville", "Tennessee", "TN"],
"38504": ["Allardt", "Tennessee", "TN"],
"38505": ["Cookeville", "Tennessee", "TN"],
"38506": ["Cookeville", "Tennessee", "TN"],
"38541": ["Allons", "Tennessee", "TN"],
"38542": ["Allred", "Tennessee", "TN"],
"38543": ["Alpine", "Tennessee", "TN"],
"38544": ["Baxter", "Tennessee", "TN"],
"38545": ["Bloomington Springs", "Tennessee", "TN"],
"38547": ["Brush Creek", "Tennessee", "TN"],
"38548": ["Buffalo Valley", "Tennessee", "TN"],
"38549": ["Byrdstown", "Tennessee", "TN"],
"38550": ["Campaign", "Tennessee", "TN"],
"38551": ["Celina", "Tennessee", "TN"],
"38552": ["Chestnut Mound", "Tennessee", "TN"],
"38553": ["Clarkrange", "Tennessee", "TN"],
"38554": ["Crawford", "Tennessee", "TN"],
"38555": ["Crossville", "Tennessee", "TN"],
"38556": ["Jamestown", "Tennessee", "TN"],
"38558": ["Crossville", "Tennessee", "TN"],
"38559": ["Doyle", "Tennessee", "TN"],
"38560": ["Elmwood", "Tennessee", "TN"],
"38562": ["Gainesboro", "Tennessee", "TN"],
"38563": ["Gordonsville", "Tennessee", "TN"],
"38564": ["Granville", "Tennessee", "TN"],
"38565": ["Grimsley", "Tennessee", "TN"],
"38567": ["Hickman", "Tennessee", "TN"],
"38568": ["Hilham", "Tennessee", "TN"],
"38569": ["Lancaster", "Tennessee", "TN"],
"38570": ["Livingston", "Tennessee", "TN"],
"38571": ["Crossville", "Tennessee", "TN"],
"38572": ["Crossville", "Tennessee", "TN"],
"38573": ["Monroe", "Tennessee", "TN"],
"38574": ["Monterey", "Tennessee", "TN"],
"38575": ["Moss", "Tennessee", "TN"],
"38577": ["Pall Mall", "Tennessee", "TN"],
"38578": ["Pleasant Hill", "Tennessee", "TN"],
"38579": ["Quebeck", "Tennessee", "TN"],
"38580": ["Rickman", "Tennessee", "TN"],
"38581": ["Rock Island", "Tennessee", "TN"],
"38582": ["Silver Point", "Tennessee", "TN"],
"38583": ["Sparta", "Tennessee", "TN"],
"38585": ["Spencer", "Tennessee", "TN"],
"38587": ["Walling", "Tennessee", "TN"],
"38588": ["Whitleyville", "Tennessee", "TN"],
"38589": ["Wilder", "Tennessee", "TN"],
"38601": ["Abbeville", "Mississippi", "MS"],
"38603": ["Ashland", "Mississippi", "MS"],
"38606": ["Batesville", "Mississippi", "MS"],
"38610": ["Blue Mountain", "Mississippi", "MS"],
"38611": ["Byhalia", "Mississippi", "MS"],
"38614": ["Clarksdale", "Mississippi", "MS"],
"38617": ["Coahoma", "Mississippi", "MS"],
"38618": ["Coldwater", "Mississippi", "MS"],
"38619": ["Como", "Mississippi", "MS"],
"38620": ["Courtland", "Mississippi", "MS"],
"38621": ["Crenshaw", "Mississippi", "MS"],
"38622": ["Crowder", "Mississippi", "MS"],
"38623": ["Darling", "Mississippi", "MS"],
"38625": ["Dumas", "Mississippi", "MS"],
"38626": ["Dundee", "Mississippi", "MS"],
"38627": ["Etta", "Mississippi", "MS"],
"38629": ["Falkner", "Mississippi", "MS"],
"38631": ["Friars Point", "Mississippi", "MS"],
"38632": ["Hernando", "Mississippi", "MS"],
"38633": ["Hickory Flat", "Mississippi", "MS"],
"38635": ["Holly Springs", "Mississippi", "MS"],
"38637": ["Horn Lake", "Mississippi", "MS"],
"38639": ["Jonestown", "Mississippi", "MS"],
"38641": ["Lake Cormorant", "Mississippi", "MS"],
"38642": ["Lamar", "Mississippi", "MS"],
"38643": ["Lambert", "Mississippi", "MS"],
"38644": ["Lula", "Mississippi", "MS"],
"38645": ["Lyon", "Mississippi", "MS"],
"38646": ["Marks", "Mississippi", "MS"],
"38647": ["Michigan City", "Mississippi", "MS"],
"38650": ["Myrtle", "Mississippi", "MS"],
"38651": ["Nesbit", "Mississippi", "MS"],
"38652": ["New Albany", "Mississippi", "MS"],
"38654": ["Olive Branch", "Mississippi", "MS"],
"38655": ["Oxford", "Mississippi", "MS"],
"38658": ["Pope", "Mississippi", "MS"],
"38659": ["Potts Camp", "Mississippi", "MS"],
"38661": ["Red Banks", "Mississippi", "MS"],
"38663": ["Ripley", "Mississippi", "MS"],
"38664": ["Robinsonville", "Mississippi", "MS"],
"38665": ["Sarah", "Mississippi", "MS"],
"38666": ["Sardis", "Mississippi", "MS"],
"38668": ["Senatobia", "Mississippi", "MS"],
"38670": ["Sledge", "Mississippi", "MS"],
"38671": ["Southaven", "Mississippi", "MS"],
"38672": ["Southaven", "Mississippi", "MS"],
"38673": ["Taylor", "Mississippi", "MS"],
"38674": ["Tiplersville", "Mississippi", "MS"],
"38676": ["Tunica", "Mississippi", "MS"],
"38677": ["University", "Mississippi", "MS"],
"38680": ["Walls", "Mississippi", "MS"],
"38683": ["Walnut", "Mississippi", "MS"],
"38685": ["Waterford", "Mississippi", "MS"],
"38701": ["Greenville", "Mississippi", "MS"],
"38703": ["Greenville", "Mississippi", "MS"],
"38720": ["Alligator", "Mississippi", "MS"],
"38721": ["Anguilla", "Mississippi", "MS"],
"38722": ["Arcola", "Mississippi", "MS"],
"38723": ["Avon", "Mississippi", "MS"],
"38725": ["Benoit", "Mississippi", "MS"],
"38726": ["Beulah", "Mississippi", "MS"],
"38730": ["Boyle", "Mississippi", "MS"],
"38731": ["Chatham", "Mississippi", "MS"],
"38732": ["Cleveland", "Mississippi", "MS"],
"38733": ["Cleveland", "Mississippi", "MS"],
"38736": ["Doddsville", "Mississippi", "MS"],
"38737": ["Drew", "Mississippi", "MS"],
"38738": ["Parchman", "Mississippi", "MS"],
"38740": ["Duncan", "Mississippi", "MS"],
"38744": ["Glen Allan", "Mississippi", "MS"],
"38745": ["Grace", "Mississippi", "MS"],
"38746": ["Gunnison", "Mississippi", "MS"],
"38748": ["Hollandale", "Mississippi", "MS"],
"38751": ["Indianola", "Mississippi", "MS"],
"38753": ["Inverness", "Mississippi", "MS"],
"38754": ["Isola", "Mississippi", "MS"],
"38756": ["Leland", "Mississippi", "MS"],
"38759": ["Merigold", "Mississippi", "MS"],
"38760": ["Metcalfe", "Mississippi", "MS"],
"38761": ["Moorhead", "Mississippi", "MS"],
"38762": ["Mound Bayou", "Mississippi", "MS"],
"38764": ["Pace", "Mississippi", "MS"],
"38765": ["Panther Burn", "Mississippi", "MS"],
"38767": ["Rena Lara", "Mississippi", "MS"],
"38768": ["Rome", "Mississippi", "MS"],
"38769": ["Rosedale", "Mississippi", "MS"],
"38771": ["Ruleville", "Mississippi", "MS"],
"38772": ["Scott", "Mississippi", "MS"],
"38773": ["Shaw", "Mississippi", "MS"],
"38774": ["Shelby", "Mississippi", "MS"],
"38776": ["Stoneville", "Mississippi", "MS"],
"38778": ["Sunflower", "Mississippi", "MS"],
"38781": ["Winstonville", "Mississippi", "MS"],
"38801": ["Tupelo", "Mississippi", "MS"],
"38804": ["Tupelo", "Mississippi", "MS"],
"38821": ["Amory", "Mississippi", "MS"],
"38824": ["Baldwyn", "Mississippi", "MS"],
"38826": ["Belden", "Mississippi", "MS"],
"38827": ["Belmont", "Mississippi", "MS"],
"38828": ["Blue Springs", "Mississippi", "MS"],
"38829": ["Booneville", "Mississippi", "MS"],
"38833": ["Burnsville", "Mississippi", "MS"],
"38834": ["Corinth", "Mississippi", "MS"],
"38838": ["Dennis", "Mississippi", "MS"],
"38839": ["Derma", "Mississippi", "MS"],
"38841": ["Ecru", "Mississippi", "MS"],
"38843": ["Fulton", "Mississippi", "MS"],
"38844": ["Gattman", "Mississippi", "MS"],
"38846": ["Glen", "Mississippi", "MS"],
"38847": ["Golden", "Mississippi", "MS"],
"38848": ["Greenwood Springs", "Mississippi", "MS"],
"38849": ["Guntown", "Mississippi", "MS"],
"38850": ["Houlka", "Mississippi", "MS"],
"38851": ["Houston", "Mississippi", "MS"],
"38852": ["Iuka", "Mississippi", "MS"],
"38855": ["Mantachie", "Mississippi", "MS"],
"38856": ["Marietta", "Mississippi", "MS"],
"38857": ["Mooreville", "Mississippi", "MS"],
"38858": ["Nettleton", "Mississippi", "MS"],
"38859": ["New Site", "Mississippi", "MS"],
"38860": ["Okolona", "Mississippi", "MS"],
"38862": ["Plantersville", "Mississippi", "MS"],
"38863": ["Pontotoc", "Mississippi", "MS"],
"38864": ["Randolph", "Mississippi", "MS"],
"38865": ["Rienzi", "Mississippi", "MS"],
"38866": ["Saltillo", "Mississippi", "MS"],
"38868": ["Shannon", "Mississippi", "MS"],
"38869": ["Sherman", "Mississippi", "MS"],
"38870": ["Smithville", "Mississippi", "MS"],
"38871": ["Thaxton", "Mississippi", "MS"],
"38873": ["Tishomingo", "Mississippi", "MS"],
"38876": ["Tremont", "Mississippi", "MS"],
"38878": ["Vardaman", "Mississippi", "MS"],
"38879": ["Verona", "Mississippi", "MS"],
"38901": ["Grenada", "Mississippi", "MS"],
"38913": ["Banner", "Mississippi", "MS"],
"38914": ["Big Creek", "Mississippi", "MS"],
"38915": ["Bruce", "Mississippi", "MS"],
"38916": ["Calhoun City", "Mississippi", "MS"],
"38917": ["Carrollton", "Mississippi", "MS"],
"38920": ["Cascilla", "Mississippi", "MS"],
"38921": ["Charleston", "Mississippi", "MS"],
"38922": ["Coffeeville", "Mississippi", "MS"],
"38923": ["Coila", "Mississippi", "MS"],
"38924": ["Cruger", "Mississippi", "MS"],
"38925": ["Duck Hill", "Mississippi", "MS"],
"38927": ["Enid", "Mississippi", "MS"],
"38928": ["Glendora", "Mississippi", "MS"],
"38929": ["Gore Springs", "Mississippi", "MS"],
"38930": ["Greenwood", "Mississippi", "MS"],
"38940": ["Holcomb", "Mississippi", "MS"],
"38941": ["Itta Bena", "Mississippi", "MS"],
"38943": ["McCarley", "Mississippi", "MS"],
"38944": ["Minter City", "Mississippi", "MS"],
"38946": ["Morgan City", "Mississippi", "MS"],
"38947": ["North Carrollton", "Mississippi", "MS"],
"38948": ["Oakland", "Mississippi", "MS"],
"38949": ["Paris", "Mississippi", "MS"],
"38950": ["Philipp", "Mississippi", "MS"],
"38951": ["Pittsboro", "Mississippi", "MS"],
"38952": ["Schlater", "Mississippi", "MS"],
"38953": ["Scobey", "Mississippi", "MS"],
"38954": ["Sidon", "Mississippi", "MS"],
"38957": ["Sumner", "Mississippi", "MS"],
"38961": ["Tillatoba", "Mississippi", "MS"],
"38962": ["Tippo", "Mississippi", "MS"],
"38963": ["Tutwiler", "Mississippi", "MS"],
"38964": ["Vance", "Mississippi", "MS"],
"38965": ["Water Valley", "Mississippi", "MS"],
"38966": ["Webb", "Mississippi", "MS"],
"38967": ["Winona", "Mississippi", "MS"],
"39038": ["Belzoni", "Mississippi", "MS"],
"39039": ["Benton", "Mississippi", "MS"],
"39040": ["Bentonia", "Mississippi", "MS"],
"39041": ["Bolton", "Mississippi", "MS"],
"39042": ["Brandon", "Mississippi", "MS"],
"39044": ["Braxton", "Mississippi", "MS"],
"39045": ["Camden", "Mississippi", "MS"],
"39046": ["Canton", "Mississippi", "MS"],
"39047": ["Brandon", "Mississippi", "MS"],
"39051": ["Carthage", "Mississippi", "MS"],
"39054": ["Cary", "Mississippi", "MS"],
"39056": ["Clinton", "Mississippi", "MS"],
"39057": ["Conehatta", "Mississippi", "MS"],
"39058": ["Clinton", "Mississippi", "MS"],
"39059": ["Crystal Springs", "Mississippi", "MS"],
"39061": ["Delta City", "Mississippi", "MS"],
"39062": ["D Lo", "Mississippi", "MS"],
"39063": ["Durant", "Mississippi", "MS"],
"39066": ["Edwards", "Mississippi", "MS"],
"39067": ["Ethel", "Mississippi", "MS"],
"39069": ["Fayette", "Mississippi", "MS"],
"39071": ["Flora", "Mississippi", "MS"],
"39073": ["Florence", "Mississippi", "MS"],
"39074": ["Forest", "Mississippi", "MS"],
"39078": ["Georgetown", "Mississippi", "MS"],
"39079": ["Goodman", "Mississippi", "MS"],
"39082": ["Harrisville", "Mississippi", "MS"],
"39083": ["Hazlehurst", "Mississippi", "MS"],
"39086": ["Hermanville", "Mississippi", "MS"],
"39088": ["Holly Bluff", "Mississippi", "MS"],
"39090": ["Kosciusko", "Mississippi", "MS"],
"39092": ["Lake", "Mississippi", "MS"],
"39094": ["Lena", "Mississippi", "MS"],
"39095": ["Lexington", "Mississippi", "MS"],
"39096": ["Lorman", "Mississippi", "MS"],
"39097": ["Louise", "Mississippi", "MS"],
"39108": ["McCool", "Mississippi", "MS"],
"39110": ["Madison", "Mississippi", "MS"],
"39111": ["Magee", "Mississippi", "MS"],
"39113": ["Mayersville", "Mississippi", "MS"],
"39114": ["Mendenhall", "Mississippi", "MS"],
"39115": ["Midnight", "Mississippi", "MS"],
"39116": ["Mize", "Mississippi", "MS"],
"39117": ["Morton", "Mississippi", "MS"],
"39119": ["Mount Olive", "Mississippi", "MS"],
"39120": ["Natchez", "Mississippi", "MS"],
"39140": ["Newhebron", "Mississippi", "MS"],
"39144": ["Pattison", "Mississippi", "MS"],
"39145": ["Pelahatchie", "Mississippi", "MS"],
"39146": ["Pickens", "Mississippi", "MS"],
"39148": ["Piney Woods", "Mississippi", "MS"],
"39149": ["Pinola", "Mississippi", "MS"],
"39150": ["Port Gibson", "Mississippi", "MS"],
"39152": ["Pulaski", "Mississippi", "MS"],
"39153": ["Raleigh", "Mississippi", "MS"],
"39154": ["Raymond", "Mississippi", "MS"],
"39156": ["Redwood", "Mississippi", "MS"],
"39157": ["Ridgeland", "Mississippi", "MS"],
"39159": ["Rolling Fork", "Mississippi", "MS"],
"39160": ["Sallis", "Mississippi", "MS"],
"39162": ["Satartia", "Mississippi", "MS"],
"39166": ["Silver City", "Mississippi", "MS"],
"39167": ["Star", "Mississippi", "MS"],
"39168": ["Taylorsville", "Mississippi", "MS"],
"39169": ["Tchula", "Mississippi", "MS"],
"39170": ["Terry", "Mississippi", "MS"],
"39173": ["Tinsley", "Mississippi", "MS"],
"39174": ["Tougaloo", "Mississippi", "MS"],
"39175": ["Utica", "Mississippi", "MS"],
"39176": ["Vaiden", "Mississippi", "MS"],
"39177": ["Valley Park", "Mississippi", "MS"],
"39179": ["Vaughan", "Mississippi", "MS"],
"39180": ["Vicksburg", "Mississippi", "MS"],
"39183": ["Vicksburg", "Mississippi", "MS"],
"39189": ["Walnut Grove", "Mississippi", "MS"],
"39191": ["Wesson", "Mississippi", "MS"],
"39192": ["West", "Mississippi", "MS"],
"39193": ["Whitfield", "Mississippi", "MS"],
"39194": ["Yazoo City", "Mississippi", "MS"],
"39201": ["Jackson", "Mississippi", "MS"],
"39202": ["Jackson", "Mississippi", "MS"],
"39203": ["Jackson", "Mississippi", "MS"],
"39204": ["Jackson", "Mississippi", "MS"],
"39206": ["Jackson", "Mississippi", "MS"],
"39208": ["Pearl", "Mississippi", "MS"],
"39209": ["Jackson", "Mississippi", "MS"],
"39210": ["Jackson", "Mississippi", "MS"],
"39211": ["Jackson", "Mississippi", "MS"],
"39212": ["Jackson", "Mississippi", "MS"],
"39213": ["Jackson", "Mississippi", "MS"],
"39216": ["Jackson", "Mississippi", "MS"],
"39217": ["Jackson", "Mississippi", "MS"],
"39218": ["Richland", "Mississippi", "MS"],
"39232": ["Flowood", "Mississippi", "MS"],
"39269": ["Jackson", "Mississippi", "MS"],
"39272": ["Byram", "Mississippi", "MS"],
"39301": ["Meridian", "Mississippi", "MS"],
"39305": ["Meridian", "Mississippi", "MS"],
"39307": ["Meridian", "Mississippi", "MS"],
"39309": ["Meridian", "Mississippi", "MS"],
"39320": ["Bailey", "Mississippi", "MS"],
"39322": ["Buckatunna", "Mississippi", "MS"],
"39323": ["Chunky", "Mississippi", "MS"],
"39324": ["Clara", "Mississippi", "MS"],
"39325": ["Collinsville", "Mississippi", "MS"],
"39326": ["Daleville", "Mississippi", "MS"],
"39327": ["Decatur", "Mississippi", "MS"],
"39328": ["De Kalb", "Mississippi", "MS"],
"39330": ["Enterprise", "Mississippi", "MS"],
"39332": ["Hickory", "Mississippi", "MS"],
"39335": ["Lauderdale", "Mississippi", "MS"],
"39336": ["Lawrence", "Mississippi", "MS"],
"39337": ["Little Rock", "Mississippi", "MS"],
"39338": ["Louin", "Mississippi", "MS"],
"39339": ["Louisville", "Mississippi", "MS"],
"39341": ["Macon", "Mississippi", "MS"],
"39342": ["Marion", "Mississippi", "MS"],
"39345": ["Newton", "Mississippi", "MS"],
"39346": ["Noxapater", "Mississippi", "MS"],
"39347": ["Pachuta", "Mississippi", "MS"],
"39348": ["Paulding", "Mississippi", "MS"],
"39350": ["Philadelphia", "Mississippi", "MS"],
"39352": ["Porterville", "Mississippi", "MS"],
"39354": ["Preston", "Mississippi", "MS"],
"39355": ["Quitman", "Mississippi", "MS"],
"39356": ["Rose Hill", "Mississippi", "MS"],
"39358": ["Scooba", "Mississippi", "MS"],
"39359": ["Sebastopol", "Mississippi", "MS"],
"39360": ["Shubuta", "Mississippi", "MS"],
"39361": ["Shuqualak", "Mississippi", "MS"],
"39362": ["State Line", "Mississippi", "MS"],
"39363": ["Stonewall", "Mississippi", "MS"],
"39364": ["Toomsuba", "Mississippi", "MS"],
"39365": ["Union", "Mississippi", "MS"],
"39366": ["Vossburg", "Mississippi", "MS"],
"39367": ["Waynesboro", "Mississippi", "MS"],
"39401": ["Hattiesburg", "Mississippi", "MS"],
"39402": ["Hattiesburg", "Mississippi", "MS"],
"39406": ["Hattiesburg", "Mississippi", "MS"],
"39421": ["Bassfield", "Mississippi", "MS"],
"39422": ["Bay Springs", "Mississippi", "MS"],
"39423": ["Beaumont", "Mississippi", "MS"],
"39425": ["Brooklyn", "Mississippi", "MS"],
"39426": ["Carriere", "Mississippi", "MS"],
"39427": ["Carson", "Mississippi", "MS"],
"39428": ["Collins", "Mississippi", "MS"],
"39429": ["Columbia", "Mississippi", "MS"],
"39437": ["Ellisville", "Mississippi", "MS"],
"39439": ["Heidelberg", "Mississippi", "MS"],
"39440": ["Laurel", "Mississippi", "MS"],
"39443": ["Laurel", "Mississippi", "MS"],
"39451": ["Leakesville", "Mississippi", "MS"],
"39452": ["Lucedale", "Mississippi", "MS"],
"39455": ["Lumberton", "Mississippi", "MS"],
"39456": ["McLain", "Mississippi", "MS"],
"39459": ["Moselle", "Mississippi", "MS"],
"39461": ["Neely", "Mississippi", "MS"],
"39462": ["New Augusta", "Mississippi", "MS"],
"39464": ["Ovett", "Mississippi", "MS"],
"39465": ["Petal", "Mississippi", "MS"],
"39466": ["Picayune", "Mississippi", "MS"],
"39470": ["Poplarville", "Mississippi", "MS"],
"39474": ["Prentiss", "Mississippi", "MS"],
"39475": ["Purvis", "Mississippi", "MS"],
"39476": ["Richton", "Mississippi", "MS"],
"39477": ["Sandersville", "Mississippi", "MS"],
"39478": ["Sandy Hook", "Mississippi", "MS"],
"39479": ["Seminary", "Mississippi", "MS"],
"39480": ["Soso", "Mississippi", "MS"],
"39481": ["Stringer", "Mississippi", "MS"],
"39482": ["Sumrall", "Mississippi", "MS"],
"39483": ["Foxworth", "Mississippi", "MS"],
"39501": ["Gulfport", "Mississippi", "MS"],
"39503": ["Gulfport", "Mississippi", "MS"],
"39507": ["Gulfport", "Mississippi", "MS"],
"39520": ["Bay Saint Louis", "Mississippi", "MS"],
"39525": ["Diamondhead", "Mississippi", "MS"],
"39529": ["Stennis Space Center", "Mississippi", "MS"],
"39530": ["Biloxi", "Mississippi", "MS"],
"39531": ["Biloxi", "Mississippi", "MS"],
"39532": ["Biloxi", "Mississippi", "MS"],
"39534": ["Biloxi", "Mississippi", "MS"],
"39540": ["Diberville", "Mississippi", "MS"],
"39553": ["Gautier", "Mississippi", "MS"],
"39556": ["Kiln", "Mississippi", "MS"],
"39560": ["Long Beach", "Mississippi", "MS"],
"39561": ["McHenry", "Mississippi", "MS"],
"39562": ["Moss Point", "Mississippi", "MS"],
"39563": ["Moss Point", "Mississippi", "MS"],
"39564": ["Ocean Springs", "Mississippi", "MS"],
"39565": ["Vancleave", "Mississippi", "MS"],
"39567": ["Pascagoula", "Mississippi", "MS"],
"39571": ["Pass Christian", "Mississippi", "MS"],
"39572": ["Pearlington", "Mississippi", "MS"],
"39573": ["Perkinston", "Mississippi", "MS"],
"39574": ["Saucier", "Mississippi", "MS"],
"39576": ["Waveland", "Mississippi", "MS"],
"39577": ["Wiggins", "Mississippi", "MS"],
"39581": ["Pascagoula", "Mississippi", "MS"],
"39595": ["Pascagoula", "Mississippi", "MS"],
"39601": ["Brookhaven", "Mississippi", "MS"],
"39629": ["Bogue Chitto", "Mississippi", "MS"],
"39630": ["Bude", "Mississippi", "MS"],
"39631": ["Centreville", "Mississippi", "MS"],
"39633": ["Crosby", "Mississippi", "MS"],
"39635": ["Fernwood", "Mississippi", "MS"],
"39638": ["Gloster", "Mississippi", "MS"],
"39641": ["Jayess", "Mississippi", "MS"],
"39643": ["Kokomo", "Mississippi", "MS"],
"39645": ["Liberty", "Mississippi", "MS"],
"39647": ["McCall Creek", "Mississippi", "MS"],
"39648": ["Mccomb", "Mississippi", "MS"],
"39652": ["Magnolia", "Mississippi", "MS"],
"39653": ["Meadville", "Mississippi", "MS"],
"39654": ["Monticello", "Mississippi", "MS"],
"39656": ["Oak Vale", "Mississippi", "MS"],
"39657": ["Osyka", "Mississippi", "MS"],
"39661": ["Roxie", "Mississippi", "MS"],
"39662": ["Ruth", "Mississippi", "MS"],
"39663": ["Silver Creek", "Mississippi", "MS"],
"39664": ["Smithdale", "Mississippi", "MS"],
"39665": ["Sontag", "Mississippi", "MS"],
"39666": ["Summit", "Mississippi", "MS"],
"39667": ["Tylertown", "Mississippi", "MS"],
"39668": ["Union Church", "Mississippi", "MS"],
"39669": ["Woodville", "Mississippi", "MS"],
"39701": ["Columbus", "Mississippi", "MS"],
"39702": ["Columbus", "Mississippi", "MS"],
"39705": ["Columbus", "Mississippi", "MS"],
"39730": ["Aberdeen", "Mississippi", "MS"],
"39735": ["Ackerman", "Mississippi", "MS"],
"39736": ["Artesia", "Mississippi", "MS"],
"39737": ["Bellefontaine", "Mississippi", "MS"],
"39739": ["Brooksville", "Mississippi", "MS"],
"39740": ["Caledonia", "Mississippi", "MS"],
"39741": ["Cedarbluff", "Mississippi", "MS"],
"39743": ["Crawford", "Mississippi", "MS"],
"39744": ["Eupora", "Mississippi", "MS"],
"39745": ["French Camp", "Mississippi", "MS"],
"39746": ["Hamilton", "Mississippi", "MS"],
"39747": ["Kilmichael", "Mississippi", "MS"],
"39750": ["Maben", "Mississippi", "MS"],
"39751": ["Mantee", "Mississippi", "MS"],
"39752": ["Mathiston", "Mississippi", "MS"],
"39753": ["Mayhew", "Mississippi", "MS"],
"39755": ["Pheba", "Mississippi", "MS"],
"39756": ["Prairie", "Mississippi", "MS"],
"39759": ["Starkville", "Mississippi", "MS"],
"39762": ["Mississippi State", "Mississippi", "MS"],
"39766": ["Steens", "Mississippi", "MS"],
"39767": ["Stewart", "Mississippi", "MS"],
"39769": ["Sturgis", "Mississippi", "MS"],
"39771": ["Walthall", "Mississippi", "MS"],
"39772": ["Weir", "Mississippi", "MS"],
"39773": ["West Point", "Mississippi", "MS"],
"39776": ["Woodland", "Mississippi", "MS"],
"39813": ["Arlington", "Georgia", "GA"],
"39815": ["Attapulgus", "Georgia", "GA"],
"39817": ["Bainbridge", "Georgia", "GA"],
"39819": ["Bainbridge", "Georgia", "GA"],
"39823": ["Blakely", "Georgia", "GA"],
"39824": ["Bluffton", "Georgia", "GA"],
"39825": ["Brinson", "Georgia", "GA"],
"39826": ["Bronwood", "Georgia", "GA"],
"39827": ["Cairo", "Georgia", "GA"],
"39828": ["Cairo", "Georgia", "GA"],
"39832": ["Cedar Springs", "Georgia", "GA"],
"39834": ["Climax", "Georgia", "GA"],
"39836": ["Coleman", "Georgia", "GA"],
"39837": ["Colquitt", "Georgia", "GA"],
"39840": ["Cuthbert", "Georgia", "GA"],
"39841": ["Damascus", "Georgia", "GA"],
"39842": ["Dawson", "Georgia", "GA"],
"39845": ["Donalsonville", "Georgia", "GA"],
"39846": ["Edison", "Georgia", "GA"],
"39851": ["Fort Gaines", "Georgia", "GA"],
"39854": ["Georgetown", "Georgia", "GA"],
"39859": ["Iron City", "Georgia", "GA"],
"39861": ["Jakin", "Georgia", "GA"],
"39862": ["Leary", "Georgia", "GA"],
"39866": ["Morgan", "Georgia", "GA"],
"39867": ["Morris", "Georgia", "GA"],
"39870": ["Newton", "Georgia", "GA"],
"39877": ["Parrott", "Georgia", "GA"],
"39885": ["Sasser", "Georgia", "GA"],
"39886": ["Shellman", "Georgia", "GA"],
"39897": ["Whigham", "Georgia", "GA"],
"40003": ["Bagdad", "Kentucky", "KY"],
"40004": ["Bardstown", "Kentucky", "KY"],
"40006": ["Bedford", "Kentucky", "KY"],
"40007": ["Bethlehem", "Kentucky", "KY"],
"40008": ["Bloomfield", "Kentucky", "KY"],
"40009": ["Bradfordsville", "Kentucky", "KY"],
"40010": ["Buckner", "Kentucky", "KY"],
"40011": ["Campbellsburg", "Kentucky", "KY"],
"40012": ["Chaplin", "Kentucky", "KY"],
"40013": ["Coxs Creek", "Kentucky", "KY"],
"40014": ["Crestwood", "Kentucky", "KY"],
"40019": ["Eminence", "Kentucky", "KY"],
"40020": ["Fairfield", "Kentucky", "KY"],
"40022": ["Finchville", "Kentucky", "KY"],
"40023": ["Fisherville", "Kentucky", "KY"],
"40025": ["Glenview", "Kentucky", "KY"],
"40026": ["Goshen", "Kentucky", "KY"],
"40031": ["La Grange", "Kentucky", "KY"],
"40033": ["Lebanon", "Kentucky", "KY"],
"40036": ["Lockport", "Kentucky", "KY"],
"40037": ["Loretto", "Kentucky", "KY"],
"40040": ["Mackville", "Kentucky", "KY"],
"40041": ["Masonic Home", "Kentucky", "KY"],
"40045": ["Milton", "Kentucky", "KY"],
"40046": ["Mount Eden", "Kentucky", "KY"],
"40047": ["Mount Washington", "Kentucky", "KY"],
"40049": ["Nerinx", "Kentucky", "KY"],
"40050": ["New Castle", "Kentucky", "KY"],
"40051": ["New Haven", "Kentucky", "KY"],
"40052": ["New Hope", "Kentucky", "KY"],
"40055": ["Pendleton", "Kentucky", "KY"],
"40056": ["Pewee Valley", "Kentucky", "KY"],
"40057": ["Pleasureville", "Kentucky", "KY"],
"40058": ["Port Royal", "Kentucky", "KY"],
"40059": ["Prospect", "Kentucky", "KY"],
"40060": ["Raywick", "Kentucky", "KY"],
"40061": ["Saint Catharine", "Kentucky", "KY"],
"40062": ["Saint Francis", "Kentucky", "KY"],
"40063": ["Saint Mary", "Kentucky", "KY"],
"40065": ["Shelbyville", "Kentucky", "KY"],
"40067": ["Simpsonville", "Kentucky", "KY"],
"40068": ["Smithfield", "Kentucky", "KY"],
"40069": ["Springfield", "Kentucky", "KY"],
"40070": ["Sulphur", "Kentucky", "KY"],
"40071": ["Taylorsville", "Kentucky", "KY"],
"40075": ["Turners Station", "Kentucky", "KY"],
"40076": ["Waddy", "Kentucky", "KY"],
"40077": ["Westport", "Kentucky", "KY"],
"40078": ["Willisburg", "Kentucky", "KY"],
"40104": ["Battletown", "Kentucky", "KY"],
"40107": ["Boston", "Kentucky", "KY"],
"40108": ["Brandenburg", "Kentucky", "KY"],
"40109": ["Brooks", "Kentucky", "KY"],
"40110": ["Clermont", "Kentucky", "KY"],
"40111": ["Cloverport", "Kentucky", "KY"],
"40115": ["Custer", "Kentucky", "KY"],
"40117": ["Ekron", "Kentucky", "KY"],
"40118": ["Fairdale", "Kentucky", "KY"],
"40119": ["Falls Of Rough", "Kentucky", "KY"],
"40121": ["Fort Knox", "Kentucky", "KY"],
"40140": ["Garfield", "Kentucky", "KY"],
"40142": ["Guston", "Kentucky", "KY"],
"40143": ["Hardinsburg", "Kentucky", "KY"],
"40144": ["Harned", "Kentucky", "KY"],
"40145": ["Hudson", "Kentucky", "KY"],
"40146": ["Irvington", "Kentucky", "KY"],
"40150": ["Lebanon Junction", "Kentucky", "KY"],
"40152": ["McDaniels", "Kentucky", "KY"],
"40155": ["Muldraugh", "Kentucky", "KY"],
"40157": ["Payneville", "Kentucky", "KY"],
"40160": ["Radcliff", "Kentucky", "KY"],
"40161": ["Rhodelia", "Kentucky", "KY"],
"40162": ["Rineyville", "Kentucky", "KY"],
"40165": ["Shepherdsville", "Kentucky", "KY"],
"40170": ["Stephensport", "Kentucky", "KY"],
"40171": ["Union Star", "Kentucky", "KY"],
"40175": ["Vine Grove", "Kentucky", "KY"],
"40176": ["Webster", "Kentucky", "KY"],
"40177": ["West Point", "Kentucky", "KY"],
"40178": ["Westview", "Kentucky", "KY"],
"40202": ["Louisville", "Kentucky", "KY"],
"40203": ["Louisville", "Kentucky", "KY"],
"40204": ["Louisville", "Kentucky", "KY"],
"40205": ["Louisville", "Kentucky", "KY"],
"40206": ["Louisville", "Kentucky", "KY"],
"40207": ["Louisville", "Kentucky", "KY"],
"40208": ["Louisville", "Kentucky", "KY"],
"40209": ["Louisville", "Kentucky", "KY"],
"40210": ["Louisville", "Kentucky", "KY"],
"40211": ["Louisville", "Kentucky", "KY"],
"40212": ["Louisville", "Kentucky", "KY"],
"40213": ["Louisville", "Kentucky", "KY"],
"40214": ["Louisville", "Kentucky", "KY"],
"40215": ["Louisville", "Kentucky", "KY"],
"40216": ["Louisville", "Kentucky", "KY"],
"40217": ["Louisville", "Kentucky", "KY"],
"40218": ["Louisville", "Kentucky", "KY"],
"40219": ["Louisville", "Kentucky", "KY"],
"40220": ["Louisville", "Kentucky", "KY"],
"40221": ["Louisville", "Kentucky", "KY"],
"40222": ["Louisville", "Kentucky", "KY"],
"40223": ["Louisville", "Kentucky", "KY"],
"40228": ["Louisville", "Kentucky", "KY"],
"40229": ["Louisville", "Kentucky", "KY"],
"40231": ["Louisville", "Kentucky", "KY"],
"40241": ["Louisville", "Kentucky", "KY"],
"40242": ["Louisville", "Kentucky", "KY"],
"40243": ["Louisville", "Kentucky", "KY"],
"40245": ["Louisville", "Kentucky", "KY"],
"40258": ["Louisville", "Kentucky", "KY"],
"40272": ["Louisville", "Kentucky", "KY"],
"40280": ["Louisville", "Kentucky", "KY"],
"40291": ["Louisville", "Kentucky", "KY"],
"40299": ["Louisville", "Kentucky", "KY"],
"40310": ["Burgin", "Kentucky", "KY"],
"40311": ["Carlisle", "Kentucky", "KY"],
"40312": ["Clay City", "Kentucky", "KY"],
"40313": ["Clearfield", "Kentucky", "KY"],
"40316": ["Denniston", "Kentucky", "KY"],
"40322": ["Frenchburg", "Kentucky", "KY"],
"40324": ["Georgetown", "Kentucky", "KY"],
"40328": ["Gravel Switch", "Kentucky", "KY"],
"40330": ["Harrodsburg", "Kentucky", "KY"],
"40334": ["Hope", "Kentucky", "KY"],
"40336": ["Irvine", "Kentucky", "KY"],
"40337": ["Jeffersonville", "Kentucky", "KY"],
"40339": ["Keene", "Kentucky", "KY"],
"40342": ["Lawrenceburg", "Kentucky", "KY"],
"40346": ["Means", "Kentucky", "KY"],
"40347": ["Midway", "Kentucky", "KY"],
"40348": ["Millersburg", "Kentucky", "KY"],
"40350": ["Moorefield", "Kentucky", "KY"],
"40351": ["Morehead", "Kentucky", "KY"],
"40353": ["Mount Sterling", "Kentucky", "KY"],
"40355": ["New Liberty", "Kentucky", "KY"],
"40356": ["Nicholasville", "Kentucky", "KY"],
"40357": ["North Middletown", "Kentucky", "KY"],
"40358": ["Olympia", "Kentucky", "KY"],
"40359": ["Owenton", "Kentucky", "KY"],
"40360": ["Owingsville", "Kentucky", "KY"],
"40361": ["Paris", "Kentucky", "KY"],
"40363": ["Perry Park", "Kentucky", "KY"],
"40370": ["Sadieville", "Kentucky", "KY"],
"40371": ["Salt Lick", "Kentucky", "KY"],
"40372": ["Salvisa", "Kentucky", "KY"],
"40374": ["Sharpsburg", "Kentucky", "KY"],
"40376": ["Slade", "Kentucky", "KY"],
"40379": ["Stamping Ground", "Kentucky", "KY"],
"40380": ["Stanton", "Kentucky", "KY"],
"40383": ["Versailles", "Kentucky", "KY"],
"40385": ["Waco", "Kentucky", "KY"],
"40387": ["Wellington", "Kentucky", "KY"],
"40390": ["Wilmore", "Kentucky", "KY"],
"40391": ["Winchester", "Kentucky", "KY"],
"40402": ["Annville", "Kentucky", "KY"],
"40403": ["Berea", "Kentucky", "KY"],
"40404": ["Berea", "Kentucky", "KY"],
"40409": ["Brodhead", "Kentucky", "KY"],
"40419": ["Crab Orchard", "Kentucky", "KY"],
"40422": ["Danville", "Kentucky", "KY"],
"40434": ["Gray Hawk", "Kentucky", "KY"],
"40437": ["Hustonville", "Kentucky", "KY"],
"40440": ["Junction City", "Kentucky", "KY"],
"40442": ["Kings Mountain", "Kentucky", "KY"],
"40444": ["Lancaster", "Kentucky", "KY"],
"40445": ["Livingston", "Kentucky", "KY"],
"40447": ["McKee", "Kentucky", "KY"],
"40448": ["McKinney", "Kentucky", "KY"],
"40456": ["Mount Vernon", "Kentucky", "KY"],
"40460": ["Orlando", "Kentucky", "KY"],
"40461": ["Paint Lick", "Kentucky", "KY"],
"40464": ["Parksville", "Kentucky", "KY"],
"40468": ["Perryville", "Kentucky", "KY"],
"40472": ["Ravenna", "Kentucky", "KY"],
"40475": ["Richmond", "Kentucky", "KY"],
"40481": ["Sandgap", "Kentucky", "KY"],
"40484": ["Stanford", "Kentucky", "KY"],
"40486": ["Tyner", "Kentucky", "KY"],
"40489": ["Waynesburg", "Kentucky", "KY"],
"40502": ["Lexington", "Kentucky", "KY"],
"40503": ["Lexington", "Kentucky", "KY"],
"40504": ["Lexington", "Kentucky", "KY"],
"40505": ["Lexington", "Kentucky", "KY"],
"40507": ["Lexington", "Kentucky", "KY"],
"40508": ["Lexington", "Kentucky", "KY"],
"40509": ["Lexington", "Kentucky", "KY"],
"40510": ["Lexington", "Kentucky", "KY"],
"40511": ["Lexington", "Kentucky", "KY"],
"40513": ["Lexington", "Kentucky", "KY"],
"40514": ["Lexington", "Kentucky", "KY"],
"40515": ["Lexington", "Kentucky", "KY"],
"40516": ["Lexington", "Kentucky", "KY"],
"40517": ["Lexington", "Kentucky", "KY"],
"40526": ["Lexington", "Kentucky", "KY"],
"40536": ["Lexington", "Kentucky", "KY"],
"40601": ["Frankfort", "Kentucky", "KY"],
"40701": ["Corbin", "Kentucky", "KY"],
"40729": ["East Bernstadt", "Kentucky", "KY"],
"40734": ["Gray", "Kentucky", "KY"],
"40737": ["Keavy", "Kentucky", "KY"],
"40740": ["Lily", "Kentucky", "KY"],
"40741": ["London", "Kentucky", "KY"],
"40744": ["London", "Kentucky", "KY"],
"40759": ["Rockholds", "Kentucky", "KY"],
"40763": ["Siler", "Kentucky", "KY"],
"40769": ["Williamsburg", "Kentucky", "KY"],
"40771": ["Woodbine", "Kentucky", "KY"],
"40801": ["Ages Brookside", "Kentucky", "KY"],
"40803": ["Asher", "Kentucky", "KY"],
"40806": ["Baxter", "Kentucky", "KY"],
"40807": ["Benham", "Kentucky", "KY"],
"40808": ["Big Laurel", "Kentucky", "KY"],
"40810": ["Bledsoe", "Kentucky", "KY"],
"40813": ["Calvin", "Kentucky", "KY"],
"40815": ["Cawood", "Kentucky", "KY"],
"40816": ["Chappell", "Kentucky", "KY"],
"40818": ["Coalgood", "Kentucky", "KY"],
"40819": ["Coldiron", "Kentucky", "KY"],
"40820": ["Cranks", "Kentucky", "KY"],
"40823": ["Cumberland", "Kentucky", "KY"],
"40824": ["Dayhoit", "Kentucky", "KY"],
"40826": ["Eolia", "Kentucky", "KY"],
"40827": ["Essie", "Kentucky", "KY"],
"40828": ["Evarts", "Kentucky", "KY"],
"40829": ["Grays Knob", "Kentucky", "KY"],
"40830": ["Gulston", "Kentucky", "KY"],
"40831": ["Harlan", "Kentucky", "KY"],
"40840": ["Helton", "Kentucky", "KY"],
"40843": ["Holmes Mill", "Kentucky", "KY"],
"40844": ["Hoskinston", "Kentucky", "KY"],
"40845": ["Hulen", "Kentucky", "KY"],
"40847": ["Kenvir", "Kentucky", "KY"],
"40849": ["Lejunior", "Kentucky", "KY"],
"40854": ["Loyall", "Kentucky", "KY"],
"40855": ["Lynch", "Kentucky", "KY"],
"40856": ["Miracle", "Kentucky", "KY"],
"40858": ["Mozelle", "Kentucky", "KY"],
"40862": ["Partridge", "Kentucky", "KY"],
"40863": ["Pathfork", "Kentucky", "KY"],
"40865": ["Putney", "Kentucky", "KY"],
"40868": ["Stinnett", "Kentucky", "KY"],
"40870": ["Totz", "Kentucky", "KY"],
"40873": ["Wallins Creek", "Kentucky", "KY"],
"40874": ["Warbranch", "Kentucky", "KY"],
"40902": ["Arjay", "Kentucky", "KY"],
"40903": ["Artemus", "Kentucky", "KY"],
"40906": ["Barbourville", "Kentucky", "KY"],
"40913": ["Beverly", "Kentucky", "KY"],
"40914": ["Big Creek", "Kentucky", "KY"],
"40915": ["Bimble", "Kentucky", "KY"],
"40921": ["Bryants Store", "Kentucky", "KY"],
"40923": ["Cannon", "Kentucky", "KY"],
"40927": ["Closplint", "Kentucky", "KY"],
"40935": ["Flat Lick", "Kentucky", "KY"],
"40939": ["Fourmile", "Kentucky", "KY"],
"40940": ["Frakes", "Kentucky", "KY"],
"40941": ["Garrard", "Kentucky", "KY"],
"40943": ["Girdler", "Kentucky", "KY"],
"40946": ["Green Road", "Kentucky", "KY"],
"40949": ["Heidrick", "Kentucky", "KY"],
"40953": ["Hinkle", "Kentucky", "KY"],
"40958": ["Kettle Island", "Kentucky", "KY"],
"40962": ["Manchester", "Kentucky", "KY"],
"40964": ["Mary Alice", "Kentucky", "KY"],
"40965": ["Middlesboro", "Kentucky", "KY"],
"40972": ["Oneida", "Kentucky", "KY"],
"40977": ["Pineville", "Kentucky", "KY"],
"40979": ["Roark", "Kentucky", "KY"],
"40981": ["Saul", "Kentucky", "KY"],
"40982": ["Scalf", "Kentucky", "KY"],
"40983": ["Sextons Creek", "Kentucky", "KY"],
"40988": ["Stoney Fork", "Kentucky", "KY"],
"40995": ["Trosper", "Kentucky", "KY"],
"40997": ["Walker", "Kentucky", "KY"],
"41001": ["Alexandria", "Kentucky", "KY"],
"41002": ["Augusta", "Kentucky", "KY"],
"41003": ["Berry", "Kentucky", "KY"],
"41004": ["Brooksville", "Kentucky", "KY"],
"41005": ["Burlington", "Kentucky", "KY"],
"41006": ["Butler", "Kentucky", "KY"],
"41007": ["California", "Kentucky", "KY"],
"41008": ["Carrollton", "Kentucky", "KY"],
"41010": ["Corinth", "Kentucky", "KY"],
"41011": ["Covington", "Kentucky", "KY"],
"41014": ["Covington", "Kentucky", "KY"],
"41015": ["Latonia", "Kentucky", "KY"],
"41016": ["Covington", "Kentucky", "KY"],
"41017": ["Ft Mitchell", "Kentucky", "KY"],
"41018": ["Erlanger", "Kentucky", "KY"],
"41030": ["Crittenden", "Kentucky", "KY"],
"41031": ["Cynthiana", "Kentucky", "KY"],
"41033": ["De Mossville", "Kentucky", "KY"],
"41034": ["Dover", "Kentucky", "KY"],
"41035": ["Dry Ridge", "Kentucky", "KY"],
"41039": ["Ewing", "Kentucky", "KY"],
"41040": ["Falmouth", "Kentucky", "KY"],
"41041": ["Flemingsburg", "Kentucky", "KY"],
"41042": ["Florence", "Kentucky", "KY"],
"41043": ["Foster", "Kentucky", "KY"],
"41044": ["Germantown", "Kentucky", "KY"],
"41045": ["Ghent", "Kentucky", "KY"],
"41046": ["Glencoe", "Kentucky", "KY"],
"41048": ["Hebron", "Kentucky", "KY"],
"41049": ["Hillsboro", "Kentucky", "KY"],
"41051": ["Independence", "Kentucky", "KY"],
"41052": ["Jonesville", "Kentucky", "KY"],
"41055": ["Mayslick", "Kentucky", "KY"],
"41056": ["Maysville", "Kentucky", "KY"],
"41059": ["Melbourne", "Kentucky", "KY"],
"41063": ["Morning View", "Kentucky", "KY"],
"41064": ["Mount Olivet", "Kentucky", "KY"],
"41071": ["Newport", "Kentucky", "KY"],
"41073": ["Bellevue", "Kentucky", "KY"],
"41074": ["Dayton", "Kentucky", "KY"],
"41075": ["Fort Thomas", "Kentucky", "KY"],
"41076": ["Newport", "Kentucky", "KY"],
"41080": ["Petersburg", "Kentucky", "KY"],
"41083": ["Sanders", "Kentucky", "KY"],
"41085": ["Silver Grove", "Kentucky", "KY"],
"41086": ["Sparta", "Kentucky", "KY"],
"41091": ["Union", "Kentucky", "KY"],
"41092": ["Verona", "Kentucky", "KY"],
"41093": ["Wallingford", "Kentucky", "KY"],
"41094": ["Walton", "Kentucky", "KY"],
"41095": ["Warsaw", "Kentucky", "KY"],
"41097": ["Williamstown", "Kentucky", "KY"],
"41098": ["Worthville", "Kentucky", "KY"],
"41101": ["Ashland", "Kentucky", "KY"],
"41102": ["Ashland", "Kentucky", "KY"],
"41121": ["Argillite", "Kentucky", "KY"],
"41124": ["Blaine", "Kentucky", "KY"],
"41129": ["Catlettsburg", "Kentucky", "KY"],
"41132": ["Denton", "Kentucky", "KY"],
"41135": ["Emerson", "Kentucky", "KY"],
"41139": ["Flatwoods", "Kentucky", "KY"],
"41141": ["Garrison", "Kentucky", "KY"],
"41142": ["Grahn", "Kentucky", "KY"],
"41143": ["Grayson", "Kentucky", "KY"],
"41144": ["Greenup", "Kentucky", "KY"],
"41146": ["Hitchins", "Kentucky", "KY"],
"41149": ["Isonville", "Kentucky", "KY"],
"41159": ["Martha", "Kentucky", "KY"],
"41164": ["Olive Hill", "Kentucky", "KY"],
"41166": ["Quincy", "Kentucky", "KY"],
"41168": ["Rush", "Kentucky", "KY"],
"41169": ["Russell", "Kentucky", "KY"],
"41171": ["Sandy Hook", "Kentucky", "KY"],
"41174": ["South Portsmouth", "Kentucky", "KY"],
"41175": ["South Shore", "Kentucky", "KY"],
"41179": ["Vanceburg", "Kentucky", "KY"],
"41180": ["Webbville", "Kentucky", "KY"],
"41181": ["Willard", "Kentucky", "KY"],
"41183": ["Worthington", "Kentucky", "KY"],
"41189": ["Tollesboro", "Kentucky", "KY"],
"41201": ["Adams", "Kentucky", "KY"],
"41203": ["Beauty", "Kentucky", "KY"],
"41204": ["Boons Camp", "Kentucky", "KY"],
"41214": ["Debord", "Kentucky", "KY"],
"41216": ["East Point", "Kentucky", "KY"],
"41219": ["Flatgap", "Kentucky", "KY"],
"41222": ["Hagerhill", "Kentucky", "KY"],
"41224": ["Inez", "Kentucky", "KY"],
"41226": ["Keaton", "Kentucky", "KY"],
"41230": ["Louisa", "Kentucky", "KY"],
"41231": ["Lovely", "Kentucky", "KY"],
"41232": ["Lowmansville", "Kentucky", "KY"],
"41234": ["Meally", "Kentucky", "KY"],
"41238": ["Oil Springs", "Kentucky", "KY"],
"41240": ["Paintsville", "Kentucky", "KY"],
"41250": ["Pilgrim", "Kentucky", "KY"],
"41254": ["River", "Kentucky", "KY"],
"41255": ["Sitka", "Kentucky", "KY"],
"41256": ["Staffordsville", "Kentucky", "KY"],
"41257": ["Stambaugh", "Kentucky", "KY"],
"41260": ["Thelma", "Kentucky", "KY"],
"41262": ["Tomahawk", "Kentucky", "KY"],
"41263": ["Tutor Key", "Kentucky", "KY"],
"41264": ["Ulysses", "Kentucky", "KY"],
"41265": ["Van Lear", "Kentucky", "KY"],
"41267": ["Warfield", "Kentucky", "KY"],
"41268": ["West Van Lear", "Kentucky", "KY"],
"41271": ["Williamsport", "Kentucky", "KY"],
"41274": ["Wittensville", "Kentucky", "KY"],
"41301": ["Campton", "Kentucky", "KY"],
"41310": ["Bays", "Kentucky", "KY"],
"41311": ["Beattyville", "Kentucky", "KY"],
"41314": ["Booneville", "Kentucky", "KY"],
"41317": ["Clayhole", "Kentucky", "KY"],
"41332": ["Hazel Green", "Kentucky", "KY"],
"41339": ["Jackson", "Kentucky", "KY"],
"41348": ["Lost Creek", "Kentucky", "KY"],
"41351": ["Mistletoe", "Kentucky", "KY"],
"41352": ["Mize", "Kentucky", "KY"],
"41360": ["Pine Ridge", "Kentucky", "KY"],
"41364": ["Ricetown", "Kentucky", "KY"],
"41365": ["Rogers", "Kentucky", "KY"],
"41366": ["Rousseau", "Kentucky", "KY"],
"41367": ["Rowdy", "Kentucky", "KY"],
"41385": ["Vancleve", "Kentucky", "KY"],
"41386": ["Vincent", "Kentucky", "KY"],
"41390": ["Whick", "Kentucky", "KY"],
"41397": ["Zoe", "Kentucky", "KY"],
"41408": ["Cannel City", "Kentucky", "KY"],
"41421": ["Elkfork", "Kentucky", "KY"],
"41425": ["Ezel", "Kentucky", "KY"],
"41464": ["Royalton", "Kentucky", "KY"],
"41465": ["Salyersville", "Kentucky", "KY"],
"41472": ["West Liberty", "Kentucky", "KY"],
"41501": ["Pikeville", "Kentucky", "KY"],
"41503": ["South Williamson", "Kentucky", "KY"],
"41512": ["Ashcamp", "Kentucky", "KY"],
"41513": ["Belcher", "Kentucky", "KY"],
"41514": ["Belfry", "Kentucky", "KY"],
"41517": ["Burdine", "Kentucky", "KY"],
"41519": ["Canada", "Kentucky", "KY"],
"41522": ["Elkhorn City", "Kentucky", "KY"],
"41524": ["Fedscreek", "Kentucky", "KY"],
"41526": ["Fords Branch", "Kentucky", "KY"],
"41527": ["Forest Hills", "Kentucky", "KY"],
"41528": ["Freeburn", "Kentucky", "KY"],
"41531": ["Hardy", "Kentucky", "KY"],
"41534": ["Hellier", "Kentucky", "KY"],
"41535": ["Huddy", "Kentucky", "KY"],
"41537": ["Jenkins", "Kentucky", "KY"],
"41538": ["Jonancy", "Kentucky", "KY"],
"41539": ["Kimper", "Kentucky", "KY"],
"41540": ["Lick Creek", "Kentucky", "KY"],
"41543": ["McAndrews", "Kentucky", "KY"],
"41544": ["McCarr", "Kentucky", "KY"],
"41547": ["Majestic", "Kentucky", "KY"],
"41548": ["Mouthcard", "Kentucky", "KY"],
"41553": ["Phelps", "Kentucky", "KY"],
"41554": ["Phyllis", "Kentucky", "KY"],
"41555": ["Pinsonfork", "Kentucky", "KY"],
"41557": ["Raccoon", "Kentucky", "KY"],
"41558": ["Ransom", "Kentucky", "KY"],
"41559": ["Regina", "Kentucky", "KY"],
"41560": ["Robinson Creek", "Kentucky", "KY"],
"41562": ["Shelbiana", "Kentucky", "KY"],
"41563": ["Shelby Gap", "Kentucky", "KY"],
"41564": ["Sidney", "Kentucky", "KY"],
"41566": ["Steele", "Kentucky", "KY"],
"41567": ["Stone", "Kentucky", "KY"],
"41568": ["Stopover", "Kentucky", "KY"],
"41571": ["Varney", "Kentucky", "KY"],
"41572": ["Virgie", "Kentucky", "KY"],
"41601": ["Allen", "Kentucky", "KY"],
"41602": ["Auxier", "Kentucky", "KY"],
"41603": ["Banner", "Kentucky", "KY"],
"41604": ["Beaver", "Kentucky", "KY"],
"41605": ["Betsy Layne", "Kentucky", "KY"],
"41606": ["Bevinsville", "Kentucky", "KY"],
"41607": ["Blue River", "Kentucky", "KY"],
"41612": ["Bypro", "Kentucky", "KY"],
"41615": ["Dana", "Kentucky", "KY"],
"41616": ["David", "Kentucky", "KY"],
"41619": ["Drift", "Kentucky", "KY"],
"41621": ["Dwale", "Kentucky", "KY"],
"41622": ["Eastern", "Kentucky", "KY"],
"41630": ["Garrett", "Kentucky", "KY"],
"41631": ["Grethel", "Kentucky", "KY"],
"41632": ["Gunlock", "Kentucky", "KY"],
"41635": ["Harold", "Kentucky", "KY"],
"41636": ["Hi Hat", "Kentucky", "KY"],
"41640": ["Hueysville", "Kentucky", "KY"],
"41642": ["Ivel", "Kentucky", "KY"],
"41643": ["Lackey", "Kentucky", "KY"],
"41645": ["Langley", "Kentucky", "KY"],
"41647": ["McDowell", "Kentucky", "KY"],
"41649": ["Martin", "Kentucky", "KY"],
"41650": ["Melvin", "Kentucky", "KY"],
"41651": ["Minnie", "Kentucky", "KY"],
"41653": ["Prestonsburg", "Kentucky", "KY"],
"41655": ["Printer", "Kentucky", "KY"],
"41659": ["Stanville", "Kentucky", "KY"],
"41660": ["Teaberry", "Kentucky", "KY"],
"41663": ["Tram", "Kentucky", "KY"],
"41666": ["Wayland", "Kentucky", "KY"],
"41667": ["Weeksbury", "Kentucky", "KY"],
"41669": ["Wheelwright", "Kentucky", "KY"],
"41701": ["Hazard", "Kentucky", "KY"],
"41712": ["Ary", "Kentucky", "KY"],
"41713": ["Avawam", "Kentucky", "KY"],
"41714": ["Bear Branch", "Kentucky", "KY"],
"41719": ["Bonnyman", "Kentucky", "KY"],
"41721": ["Buckhorn", "Kentucky", "KY"],
"41722": ["Bulan", "Kentucky", "KY"],
"41723": ["Busy", "Kentucky", "KY"],
"41725": ["Carrie", "Kentucky", "KY"],
"41727": ["Chavies", "Kentucky", "KY"],
"41729": ["Combs", "Kentucky", "KY"],
"41731": ["Cornettsville", "Kentucky", "KY"],
"41735": ["Delphia", "Kentucky", "KY"],
"41739": ["Dwarf", "Kentucky", "KY"],
"41740": ["Emmalena", "Kentucky", "KY"],
"41745": ["Gays Creek", "Kentucky", "KY"],
"41746": ["Happy", "Kentucky", "KY"],
"41749": ["Hyden", "Kentucky", "KY"],
"41751": ["Jeff", "Kentucky", "KY"],
"41754": ["Krypton", "Kentucky", "KY"],
"41759": ["Sassafras", "Kentucky", "KY"],
"41760": ["Scuddy", "Kentucky", "KY"],
"41762": ["Sizerock", "Kentucky", "KY"],
"41763": ["Slemp", "Kentucky", "KY"],
"41764": ["Smilax", "Kentucky", "KY"],
"41766": ["Thousandsticks", "Kentucky", "KY"],
"41772": ["Vest", "Kentucky", "KY"],
"41773": ["Vicco", "Kentucky", "KY"],
"41774": ["Viper", "Kentucky", "KY"],
"41775": ["Wendover", "Kentucky", "KY"],
"41776": ["Wooton", "Kentucky", "KY"],
"41777": ["Yeaddiss", "Kentucky", "KY"],
"41778": ["Yerkes", "Kentucky", "KY"],
"41804": ["Blackey", "Kentucky", "KY"],
"41810": ["Cromona", "Kentucky", "KY"],
"41812": ["Deane", "Kentucky", "KY"],
"41815": ["Ermine", "Kentucky", "KY"],
"41817": ["Garner", "Kentucky", "KY"],
"41819": ["Gordon", "Kentucky", "KY"],
"41821": ["Hallie", "Kentucky", "KY"],
"41822": ["Hindman", "Kentucky", "KY"],
"41824": ["Isom", "Kentucky", "KY"],
"41825": ["Jackhorn", "Kentucky", "KY"],
"41826": ["Jeremiah", "Kentucky", "KY"],
"41828": ["Kite", "Kentucky", "KY"],
"41831": ["Leburn", "Kentucky", "KY"],
"41832": ["Letcher", "Kentucky", "KY"],
"41833": ["Linefork", "Kentucky", "KY"],
"41834": ["Littcarr", "Kentucky", "KY"],
"41835": ["McRoberts", "Kentucky", "KY"],
"41836": ["Mallie", "Kentucky", "KY"],
"41837": ["Mayking", "Kentucky", "KY"],
"41838": ["Millstone", "Kentucky", "KY"],
"41839": ["Mousie", "Kentucky", "KY"],
"41840": ["Neon", "Kentucky", "KY"],
"41843": ["Pine Top", "Kentucky", "KY"],
"41844": ["Pippa Passes", "Kentucky", "KY"],
"41845": ["Premium", "Kentucky", "KY"],
"41847": ["Redfox", "Kentucky", "KY"],
"41848": ["Roxana", "Kentucky", "KY"],
"41849": ["Seco", "Kentucky", "KY"],
"41855": ["Thornton", "Kentucky", "KY"],
"41858": ["Whitesburg", "Kentucky", "KY"],
"41859": ["Dema", "Kentucky", "KY"],
"41861": ["Raven", "Kentucky", "KY"],
"41862": ["Topmost", "Kentucky", "KY"],
"42001": ["Paducah", "Kentucky", "KY"],
"42003": ["Paducah", "Kentucky", "KY"],
"42020": ["Almo", "Kentucky", "KY"],
"42021": ["Arlington", "Kentucky", "KY"],
"42022": ["Bandana", "Kentucky", "KY"],
"42023": ["Bardwell", "Kentucky", "KY"],
"42024": ["Barlow", "Kentucky", "KY"],
"42025": ["Benton", "Kentucky", "KY"],
"42027": ["Boaz", "Kentucky", "KY"],
"42028": ["Burna", "Kentucky", "KY"],
"42029": ["Calvert City", "Kentucky", "KY"],
"42031": ["Clinton", "Kentucky", "KY"],
"42032": ["Columbus", "Kentucky", "KY"],
"42035": ["Cunningham", "Kentucky", "KY"],
"42036": ["Dexter", "Kentucky", "KY"],
"42037": ["Dycusburg", "Kentucky", "KY"],
"42038": ["Eddyville", "Kentucky", "KY"],
"42039": ["Fancy Farm", "Kentucky", "KY"],
"42040": ["Farmington", "Kentucky", "KY"],
"42041": ["Fulton", "Kentucky", "KY"],
"42044": ["Gilbertsville", "Kentucky", "KY"],
"42045": ["Grand Rivers", "Kentucky", "KY"],
"42047": ["Hampton", "Kentucky", "KY"],
"42048": ["Hardin", "Kentucky", "KY"],
"42049": ["Hazel", "Kentucky", "KY"],
"42050": ["Hickman", "Kentucky", "KY"],
"42051": ["Hickory", "Kentucky", "KY"],
"42053": ["Kevil", "Kentucky", "KY"],
"42054": ["Kirksey", "Kentucky", "KY"],
"42055": ["Kuttawa", "Kentucky", "KY"],
"42056": ["La Center", "Kentucky", "KY"],
"42058": ["Ledbetter", "Kentucky", "KY"],
"42060": ["Lovelaceville", "Kentucky", "KY"],
"42061": ["Lowes", "Kentucky", "KY"],
"42064": ["Marion", "Kentucky", "KY"],
"42066": ["Mayfield", "Kentucky", "KY"],
"42069": ["Melber", "Kentucky", "KY"],
"42071": ["Murray", "Kentucky", "KY"],
"42076": ["New Concord", "Kentucky", "KY"],
"42078": ["Salem", "Kentucky", "KY"],
"42079": ["Sedalia", "Kentucky", "KY"],
"42081": ["Smithland", "Kentucky", "KY"],
"42082": ["Symsonia", "Kentucky", "KY"],
"42083": ["Tiline", "Kentucky", "KY"],
"42085": ["Water Valley", "Kentucky", "KY"],
"42086": ["West Paducah", "Kentucky", "KY"],
"42087": ["Wickliffe", "Kentucky", "KY"],
"42088": ["Wingo", "Kentucky", "KY"],
"42101": ["Bowling Green", "Kentucky", "KY"],
"42103": ["Bowling Green", "Kentucky", "KY"],
"42104": ["Bowling Green", "Kentucky", "KY"],
"42120": ["Adolphus", "Kentucky", "KY"],
"42122": ["Alvaton", "Kentucky", "KY"],
"42123": ["Austin", "Kentucky", "KY"],
"42124": ["Beaumont", "Kentucky", "KY"],
"42127": ["Cave City", "Kentucky", "KY"],
"42129": ["Edmonton", "Kentucky", "KY"],
"42130": ["Eighty Eight", "Kentucky", "KY"],
"42131": ["Etoile", "Kentucky", "KY"],
"42133": ["Fountain Run", "Kentucky", "KY"],
"42134": ["Franklin", "Kentucky", "KY"],
"42140": ["Gamaliel", "Kentucky", "KY"],
"42141": ["Glasgow", "Kentucky", "KY"],
"42151": ["Hestand", "Kentucky", "KY"],
"42153": ["Holland", "Kentucky", "KY"],
"42154": ["Knob Lick", "Kentucky", "KY"],
"42156": ["Lucas", "Kentucky", "KY"],
"42157": ["Mount Hermon", "Kentucky", "KY"],
"42159": ["Oakland", "Kentucky", "KY"],
"42160": ["Park City", "Kentucky", "KY"],
"42163": ["Rocky Hill", "Kentucky", "KY"],
"42164": ["Scottsville", "Kentucky", "KY"],
"42166": ["Summer Shade", "Kentucky", "KY"],
"42167": ["Tompkinsville", "Kentucky", "KY"],
"42170": ["Woodburn", "Kentucky", "KY"],
"42171": ["Smiths Grove", "Kentucky", "KY"],
"42202": ["Adairville", "Kentucky", "KY"],
"42204": ["Allensville", "Kentucky", "KY"],
"42206": ["Auburn", "Kentucky", "KY"],
"42207": ["Bee Spring", "Kentucky", "KY"],
"42210": ["Brownsville", "Kentucky", "KY"],
"42211": ["Cadiz", "Kentucky", "KY"],
"42214": ["Center", "Kentucky", "KY"],
"42215": ["Cerulean", "Kentucky", "KY"],
"42217": ["Crofton", "Kentucky", "KY"],
"42220": ["Elkton", "Kentucky", "KY"],
"42223": ["Fort Campbell", "Kentucky", "KY"],
"42232": ["Gracey", "Kentucky", "KY"],
"42234": ["Guthrie", "Kentucky", "KY"],
"42236": ["Herndon", "Kentucky", "KY"],
"42240": ["Hopkinsville", "Kentucky", "KY"],
"42252": ["Jetson", "Kentucky", "KY"],
"42254": ["La Fayette", "Kentucky", "KY"],
"42256": ["Lewisburg", "Kentucky", "KY"],
"42259": ["Mammoth Cave", "Kentucky", "KY"],
"42261": ["Morgantown", "Kentucky", "KY"],
"42262": ["Oak Grove", "Kentucky", "KY"],
"42265": ["Olmstead", "Kentucky", "KY"],
"42266": ["Pembroke", "Kentucky", "KY"],
"42273": ["Rochester", "Kentucky", "KY"],
"42274": ["Rockfield", "Kentucky", "KY"],
"42275": ["Roundhill", "Kentucky", "KY"],
"42276": ["Russellville", "Kentucky", "KY"],
"42280": ["Sharon Grove", "Kentucky", "KY"],
"42285": ["Sweeden", "Kentucky", "KY"],
"42286": ["Trenton", "Kentucky", "KY"],
"42301": ["Owensboro", "Kentucky", "KY"],
"42303": ["Owensboro", "Kentucky", "KY"],
"42320": ["Beaver Dam", "Kentucky", "KY"],
"42321": ["Beech Creek", "Kentucky", "KY"],
"42322": ["Beech Grove", "Kentucky", "KY"],
"42323": ["Beechmont", "Kentucky", "KY"],
"42324": ["Belton", "Kentucky", "KY"],
"42325": ["Bremen", "Kentucky", "KY"],
"42326": ["Browder", "Kentucky", "KY"],
"42327": ["Calhoun", "Kentucky", "KY"],
"42328": ["Centertown", "Kentucky", "KY"],
"42330": ["Central City", "Kentucky", "KY"],
"42332": ["Cleaton", "Kentucky", "KY"],
"42333": ["Cromwell", "Kentucky", "KY"],
"42337": ["Drakesboro", "Kentucky", "KY"],
"42338": ["Dundee", "Kentucky", "KY"],
"42339": ["Dunmor", "Kentucky", "KY"],
"42343": ["Fordsville", "Kentucky", "KY"],
"42344": ["Graham", "Kentucky", "KY"],
"42345": ["Greenville", "Kentucky", "KY"],
"42347": ["Hartford", "Kentucky", "KY"],
"42348": ["Hawesville", "Kentucky", "KY"],
"42349": ["Horse Branch", "Kentucky", "KY"],
"42350": ["Island", "Kentucky", "KY"],
"42351": ["Lewisport", "Kentucky", "KY"],
"42352": ["Livermore", "Kentucky", "KY"],
"42354": ["McHenry", "Kentucky", "KY"],
"42355": ["Maceo", "Kentucky", "KY"],
"42356": ["Maple Mount", "Kentucky", "KY"],
"42361": ["Olaton", "Kentucky", "KY"],
"42366": ["Philpot", "Kentucky", "KY"],
"42367": ["Powderly", "Kentucky", "KY"],
"42368": ["Reynolds Station", "Kentucky", "KY"],
"42369": ["Rockport", "Kentucky", "KY"],
"42370": ["Rosine", "Kentucky", "KY"],
"42371": ["Rumsey", "Kentucky", "KY"],
"42372": ["Sacramento", "Kentucky", "KY"],
"42374": ["South Carrollton", "Kentucky", "KY"],
"42376": ["Utica", "Kentucky", "KY"],
"42378": ["Whitesville", "Kentucky", "KY"],
"42404": ["Clay", "Kentucky", "KY"],
"42406": ["Corydon", "Kentucky", "KY"],
"42408": ["Dawson Springs", "Kentucky", "KY"],
"42409": ["Dixon", "Kentucky", "KY"],
"42410": ["Earlington", "Kentucky", "KY"],
"42411": ["Fredonia", "Kentucky", "KY"],
"42413": ["Hanson", "Kentucky", "KY"],
"42420": ["Henderson", "Kentucky", "KY"],
"42431": ["Madisonville", "Kentucky", "KY"],
"42436": ["Manitou", "Kentucky", "KY"],
"42437": ["Morganfield", "Kentucky", "KY"],
"42440": ["Mortons Gap", "Kentucky", "KY"],
"42441": ["Nebo", "Kentucky", "KY"],
"42442": ["Nortonville", "Kentucky", "KY"],
"42444": ["Poole", "Kentucky", "KY"],
"42445": ["Princeton", "Kentucky", "KY"],
"42450": ["Providence", "Kentucky", "KY"],
"42451": ["Reed", "Kentucky", "KY"],
"42452": ["Robards", "Kentucky", "KY"],
"42453": ["Saint Charles", "Kentucky", "KY"],
"42455": ["Sebree", "Kentucky", "KY"],
"42456": ["Slaughters", "Kentucky", "KY"],
"42458": ["Spottsville", "Kentucky", "KY"],
"42459": ["Sturgis", "Kentucky", "KY"],
"42461": ["Uniontown", "Kentucky", "KY"],
"42462": ["Waverly", "Kentucky", "KY"],
"42463": ["Wheatcroft", "Kentucky", "KY"],
"42464": ["White Plains", "Kentucky", "KY"],
"42501": ["Somerset", "Kentucky", "KY"],
"42503": ["Somerset", "Kentucky", "KY"],
"42516": ["Bethelridge", "Kentucky", "KY"],
"42518": ["Bronston", "Kentucky", "KY"],
"42519": ["Burnside", "Kentucky", "KY"],
"42528": ["Dunnville", "Kentucky", "KY"],
"42533": ["Ferguson", "Kentucky", "KY"],
"42539": ["Liberty", "Kentucky", "KY"],
"42541": ["Middleburg", "Kentucky", "KY"],
"42544": ["Nancy", "Kentucky", "KY"],
"42553": ["Science Hill", "Kentucky", "KY"],
"42565": ["Windsor", "Kentucky", "KY"],
"42566": ["Yosemite", "Kentucky", "KY"],
"42567": ["Eubank", "Kentucky", "KY"],
"42602": ["Albany", "Kentucky", "KY"],
"42603": ["Alpha", "Kentucky", "KY"],
"42629": ["Jamestown", "Kentucky", "KY"],
"42631": ["Marshes Siding", "Kentucky", "KY"],
"42633": ["Monticello", "Kentucky", "KY"],
"42634": ["Parkers Lake", "Kentucky", "KY"],
"42635": ["Pine Knot", "Kentucky", "KY"],
"42638": ["Revelo", "Kentucky", "KY"],
"42642": ["Russell Springs", "Kentucky", "KY"],
"42647": ["Stearns", "Kentucky", "KY"],
"42649": ["Strunk", "Kentucky", "KY"],
"42653": ["Whitley City", "Kentucky", "KY"],
"42701": ["Elizabethtown", "Kentucky", "KY"],
"42712": ["Big Clifty", "Kentucky", "KY"],
"42713": ["Bonnieville", "Kentucky", "KY"],
"42715": ["Breeding", "Kentucky", "KY"],
"42716": ["Buffalo", "Kentucky", "KY"],
"42717": ["Burkesville", "Kentucky", "KY"],
"42718": ["Campbellsville", "Kentucky", "KY"],
"42721": ["Caneyville", "Kentucky", "KY"],
"42722": ["Canmer", "Kentucky", "KY"],
"42724": ["Cecilia", "Kentucky", "KY"],
"42726": ["Clarkson", "Kentucky", "KY"],
"42728": ["Columbia", "Kentucky", "KY"],
"42729": ["Cub Run", "Kentucky", "KY"],
"42732": ["Eastview", "Kentucky", "KY"],
"42733": ["Elk Horn", "Kentucky", "KY"],
"42740": ["Glendale", "Kentucky", "KY"],
"42741": ["Glens Fork", "Kentucky", "KY"],
"42742": ["Gradyville", "Kentucky", "KY"],
"42743": ["Greensburg", "Kentucky", "KY"],
"42746": ["Hardyville", "Kentucky", "KY"],
"42748": ["Hodgenville", "Kentucky", "KY"],
"42749": ["Horse Cave", "Kentucky", "KY"],
"42753": ["Knifley", "Kentucky", "KY"],
"42754": ["Leitchfield", "Kentucky", "KY"],
"42757": ["Magnolia", "Kentucky", "KY"],
"42758": ["Mannsville", "Kentucky", "KY"],
"42759": ["Marrowbone", "Kentucky", "KY"],
"42762": ["Millwood", "Kentucky", "KY"],
"42764": ["Mount Sherman", "Kentucky", "KY"],
"42765": ["Munfordville", "Kentucky", "KY"],
"42776": ["Sonora", "Kentucky", "KY"],
"42782": ["Summersville", "Kentucky", "KY"],
"42784": ["Upton", "Kentucky", "KY"],
"42788": ["White Mills", "Kentucky", "KY"],
"43001": ["Alexandria", "Ohio", "OH"],
"43002": ["Amlin", "Ohio", "OH"],
"43003": ["Ashley", "Ohio", "OH"],
"43004": ["Blacklick", "Ohio", "OH"],
"43005": ["Bladensburg", "Ohio", "OH"],
"43006": ["Brinkhaven", "Ohio", "OH"],
"43007": ["Broadway", "Ohio", "OH"],
"43008": ["Buckeye Lake", "Ohio", "OH"],
"43009": ["Cable", "Ohio", "OH"],
"43010": ["Catawba", "Ohio", "OH"],
"43011": ["Centerburg", "Ohio", "OH"],
"43013": ["Croton", "Ohio", "OH"],
"43014": ["Danville", "Ohio", "OH"],
"43015": ["Delaware", "Ohio", "OH"],
"43016": ["Dublin", "Ohio", "OH"],
"43017": ["Dublin", "Ohio", "OH"],
"43018": ["Etna", "Ohio", "OH"],
"43019": ["Fredericktown", "Ohio", "OH"],
"43021": ["Galena", "Ohio", "OH"],
"43022": ["Gambier", "Ohio", "OH"],
"43023": ["Granville", "Ohio", "OH"],
"43025": ["Hebron", "Ohio", "OH"],
"43026": ["Hilliard", "Ohio", "OH"],
"43027": ["Homer", "Ohio", "OH"],
"43028": ["Howard", "Ohio", "OH"],
"43029": ["Irwin", "Ohio", "OH"],
"43030": ["Jacksontown", "Ohio", "OH"],
"43031": ["Johnstown", "Ohio", "OH"],
"43032": ["Kilbourne", "Ohio", "OH"],
"43033": ["Kirkersville", "Ohio", "OH"],
"43035": ["Lewis Center", "Ohio", "OH"],
"43036": ["Magnetic Springs", "Ohio", "OH"],
"43037": ["Martinsburg", "Ohio", "OH"],
"43040": ["Marysville", "Ohio", "OH"],
"43044": ["Mechanicsburg", "Ohio", "OH"],
"43045": ["Milford Center", "Ohio", "OH"],
"43046": ["Millersport", "Ohio", "OH"],
"43047": ["Mingo", "Ohio", "OH"],
"43050": ["Mount Vernon", "Ohio", "OH"],
"43054": ["New Albany", "Ohio", "OH"],
"43055": ["Newark", "Ohio", "OH"],
"43056": ["Heath", "Ohio", "OH"],
"43060": ["North Lewisburg", "Ohio", "OH"],
"43061": ["Ostrander", "Ohio", "OH"],
"43062": ["Pataskala", "Ohio", "OH"],
"43064": ["Plain City", "Ohio", "OH"],
"43065": ["Powell", "Ohio", "OH"],
"43066": ["Radnor", "Ohio", "OH"],
"43067": ["Raymond", "Ohio", "OH"],
"43068": ["Reynoldsburg", "Ohio", "OH"],
"43070": ["Rosewood", "Ohio", "OH"],
"43071": ["Saint Louisville", "Ohio", "OH"],
"43072": ["Saint Paris", "Ohio", "OH"],
"43074": ["Sunbury", "Ohio", "OH"],
"43076": ["Thornville", "Ohio", "OH"],
"43077": ["Unionville Center", "Ohio", "OH"],
"43078": ["Urbana", "Ohio", "OH"],
"43080": ["Utica", "Ohio", "OH"],
"43081": ["Westerville", "Ohio", "OH"],
"43082": ["Westerville", "Ohio", "OH"],
"43084": ["Woodstock", "Ohio", "OH"],
"43085": ["Columbus", "Ohio", "OH"],
"43101": ["Adelphi", "Ohio", "OH"],
"43102": ["Amanda", "Ohio", "OH"],
"43103": ["Ashville", "Ohio", "OH"],
"43105": ["Baltimore", "Ohio", "OH"],
"43106": ["Bloomingburg", "Ohio", "OH"],
"43107": ["Bremen", "Ohio", "OH"],
"43109": ["Brice", "Ohio", "OH"],
"43110": ["Canal Winchester", "Ohio", "OH"],
"43111": ["Carbon Hill", "Ohio", "OH"],
"43112": ["Carroll", "Ohio", "OH"],
"43113": ["Circleville", "Ohio", "OH"],
"43115": ["Clarksburg", "Ohio", "OH"],
"43116": ["Commercial Point", "Ohio", "OH"],
"43117": ["Derby", "Ohio", "OH"],
"43119": ["Galloway", "Ohio", "OH"],
"43123": ["Grove City", "Ohio", "OH"],
"43125": ["Groveport", "Ohio", "OH"],
"43126": ["Harrisburg", "Ohio", "OH"],
"43127": ["Haydenville", "Ohio", "OH"],
"43128": ["Jeffersonville", "Ohio", "OH"],
"43130": ["Lancaster", "Ohio", "OH"],
"43135": ["Laurelville", "Ohio", "OH"],
"43136": ["Lithopolis", "Ohio", "OH"],
"43137": ["Lockbourne", "Ohio", "OH"],
"43138": ["Logan", "Ohio", "OH"],
"43140": ["London", "Ohio", "OH"],
"43142": ["Milledgeville", "Ohio", "OH"],
"43143": ["Mount Sterling", "Ohio", "OH"],
"43144": ["Murray City", "Ohio", "OH"],
"43145": ["New Holland", "Ohio", "OH"],
"43146": ["Orient", "Ohio", "OH"],
"43147": ["Pickerington", "Ohio", "OH"],
"43148": ["Pleasantville", "Ohio", "OH"],
"43149": ["Rockbridge", "Ohio", "OH"],
"43150": ["Rushville", "Ohio", "OH"],
"43151": ["Sedalia", "Ohio", "OH"],
"43152": ["South Bloomingville", "Ohio", "OH"],
"43153": ["South Solon", "Ohio", "OH"],
"43154": ["Stoutsville", "Ohio", "OH"],
"43155": ["Sugar Grove", "Ohio", "OH"],
"43156": ["Tarlton", "Ohio", "OH"],
"43157": ["Thurston", "Ohio", "OH"],
"43158": ["Union Furnace", "Ohio", "OH"],
"43160": ["Washington Court House", "Ohio", "OH"],
"43162": ["West Jefferson", "Ohio", "OH"],
"43164": ["Williamsport", "Ohio", "OH"],
"43201": ["Columbus", "Ohio", "OH"],
"43202": ["Columbus", "Ohio", "OH"],
"43203": ["Columbus", "Ohio", "OH"],
"43204": ["Columbus", "Ohio", "OH"],
"43205": ["Columbus", "Ohio", "OH"],
"43206": ["Columbus", "Ohio", "OH"],
"43207": ["Columbus", "Ohio", "OH"],
"43209": ["Columbus", "Ohio", "OH"],
"43210": ["Columbus", "Ohio", "OH"],
"43211": ["Columbus", "Ohio", "OH"],
"43212": ["Columbus", "Ohio", "OH"],
"43213": ["Columbus", "Ohio", "OH"],
"43214": ["Columbus", "Ohio", "OH"],
"43215": ["Columbus", "Ohio", "OH"],
"43217": ["Columbus", "Ohio", "OH"],
"43218": ["Columbus", "Ohio", "OH"],
"43219": ["Columbus", "Ohio", "OH"],
"43220": ["Columbus", "Ohio", "OH"],
"43221": ["Columbus", "Ohio", "OH"],
"43222": ["Columbus", "Ohio", "OH"],
"43223": ["Columbus", "Ohio", "OH"],
"43224": ["Columbus", "Ohio", "OH"],
"43227": ["Columbus", "Ohio", "OH"],
"43228": ["Columbus", "Ohio", "OH"],
"43229": ["Columbus", "Ohio", "OH"],
"43230": ["Columbus", "Ohio", "OH"],
"43231": ["Columbus", "Ohio", "OH"],
"43232": ["Columbus", "Ohio", "OH"],
"43235": ["Columbus", "Ohio", "OH"],
"43240": ["Columbus", "Ohio", "OH"],
"43302": ["Marion", "Ohio", "OH"],
"43310": ["Belle Center", "Ohio", "OH"],
"43311": ["Bellefontaine", "Ohio", "OH"],
"43314": ["Caledonia", "Ohio", "OH"],
"43315": ["Cardington", "Ohio", "OH"],
"43316": ["Carey", "Ohio", "OH"],
"43317": ["Chesterville", "Ohio", "OH"],
"43318": ["De Graff", "Ohio", "OH"],
"43319": ["East Liberty", "Ohio", "OH"],
"43320": ["Edison", "Ohio", "OH"],
"43321": ["Fulton", "Ohio", "OH"],
"43322": ["Green Camp", "Ohio", "OH"],
"43323": ["Harpster", "Ohio", "OH"],
"43324": ["Huntsville", "Ohio", "OH"],
"43325": ["Iberia", "Ohio", "OH"],
"43326": ["Kenton", "Ohio", "OH"],
"43330": ["Kirby", "Ohio", "OH"],
"43331": ["Lakeview", "Ohio", "OH"],
"43332": ["La Rue", "Ohio", "OH"],
"43333": ["Lewistown", "Ohio", "OH"],
"43334": ["Marengo", "Ohio", "OH"],
"43336": ["Middleburg", "Ohio", "OH"],
"43337": ["Morral", "Ohio", "OH"],
"43338": ["Mount Gilead", "Ohio", "OH"],
"43340": ["Mount Victory", "Ohio", "OH"],
"43341": ["New Bloomington", "Ohio", "OH"],
"43342": ["Prospect", "Ohio", "OH"],
"43343": ["Quincy", "Ohio", "OH"],
"43344": ["Richwood", "Ohio", "OH"],
"43345": ["Ridgeway", "Ohio", "OH"],
"43346": ["Roundhead", "Ohio", "OH"],
"43347": ["Rushsylvania", "Ohio", "OH"],
"43348": ["Russells Point", "Ohio", "OH"],
"43350": ["Sparta", "Ohio", "OH"],
"43351": ["Upper Sandusky", "Ohio", "OH"],
"43356": ["Waldo", "Ohio", "OH"],
"43357": ["West Liberty", "Ohio", "OH"],
"43358": ["West Mansfield", "Ohio", "OH"],
"43359": ["Wharton", "Ohio", "OH"],
"43360": ["Zanesfield", "Ohio", "OH"],
"43402": ["Bowling Green", "Ohio", "OH"],
"43403": ["Bowling Green", "Ohio", "OH"],
"43406": ["Bradner", "Ohio", "OH"],
"43407": ["Burgoon", "Ohio", "OH"],
"43408": ["Clay Center", "Ohio", "OH"],
"43410": ["Clyde", "Ohio", "OH"],
"43412": ["Curtice", "Ohio", "OH"],
"43413": ["Cygnet", "Ohio", "OH"],
"43414": ["Dunbridge", "Ohio", "OH"],
"43416": ["Elmore", "Ohio", "OH"],
"43420": ["Fremont", "Ohio", "OH"],
"43430": ["Genoa", "Ohio", "OH"],
"43431": ["Gibsonburg", "Ohio", "OH"],
"43432": ["Graytown", "Ohio", "OH"],
"43433": ["Gypsum", "Ohio", "OH"],
"43434": ["Harbor View", "Ohio", "OH"],
"43435": ["Helena", "Ohio", "OH"],
"43436": ["Isle Saint George", "Ohio", "OH"],
"43437": ["Jerry City", "Ohio", "OH"],
"43438": ["Kelleys Island", "Ohio", "OH"],
"43439": ["Lacarne", "Ohio", "OH"],
"43440": ["Lakeside Marblehead", "Ohio", "OH"],
"43442": ["Lindsey", "Ohio", "OH"],
"43443": ["Luckey", "Ohio", "OH"],
"43445": ["Martin", "Ohio", "OH"],
"43446": ["Middle Bass", "Ohio", "OH"],
"43447": ["Millbury", "Ohio", "OH"],
"43449": ["Oak Harbor", "Ohio", "OH"],
"43450": ["Pemberville", "Ohio", "OH"],
"43451": ["Portage", "Ohio", "OH"],
"43452": ["Port Clinton", "Ohio", "OH"],
"43456": ["Put In Bay", "Ohio", "OH"],
"43457": ["Risingsun", "Ohio", "OH"],
"43458": ["Rocky Ridge", "Ohio", "OH"],
"43460": ["Rossford", "Ohio", "OH"],
"43462": ["Rudolph", "Ohio", "OH"],
"43463": ["Stony Ridge", "Ohio", "OH"],
"43464": ["Vickery", "Ohio", "OH"],
"43465": ["Walbridge", "Ohio", "OH"],
"43466": ["Wayne", "Ohio", "OH"],
"43467": ["West Millgrove", "Ohio", "OH"],
"43468": ["Williston", "Ohio", "OH"],
"43469": ["Woodville", "Ohio", "OH"],
"43501": ["Alvordton", "Ohio", "OH"],
"43502": ["Archbold", "Ohio", "OH"],
"43504": ["Berkey", "Ohio", "OH"],
"43505": ["Blakeslee", "Ohio", "OH"],
"43506": ["Bryan", "Ohio", "OH"],
"43510": ["Colton", "Ohio", "OH"],
"43511": ["Custar", "Ohio", "OH"],
"43512": ["Defiance", "Ohio", "OH"],
"43515": ["Delta", "Ohio", "OH"],
"43516": ["Deshler", "Ohio", "OH"],
"43517": ["Edgerton", "Ohio", "OH"],
"43518": ["Edon", "Ohio", "OH"],
"43519": ["Evansport", "Ohio", "OH"],
"43520": ["Farmer", "Ohio", "OH"],
"43521": ["Fayette", "Ohio", "OH"],
"43522": ["Grand Rapids", "Ohio", "OH"],
"43523": ["Grelton", "Ohio", "OH"],
"43524": ["Hamler", "Ohio", "OH"],
"43525": ["Haskins", "Ohio", "OH"],
"43526": ["Hicksville", "Ohio", "OH"],
"43527": ["Holgate", "Ohio", "OH"],
"43528": ["Holland", "Ohio", "OH"],
"43529": ["Hoytville", "Ohio", "OH"],
"43530": ["Jewell", "Ohio", "OH"],
"43531": ["Kunkle", "Ohio", "OH"],
"43532": ["Liberty Center", "Ohio", "OH"],
"43533": ["Lyons", "Ohio", "OH"],
"43534": ["McClure", "Ohio", "OH"],
"43535": ["Malinta", "Ohio", "OH"],
"43536": ["Mark Center", "Ohio", "OH"],
"43537": ["Maumee", "Ohio", "OH"],
"43540": ["Metamora", "Ohio", "OH"],
"43541": ["Milton Center", "Ohio", "OH"],
"43542": ["Monclova", "Ohio", "OH"],
"43543": ["Montpelier", "Ohio", "OH"],
"43545": ["Napoleon", "Ohio", "OH"],
"43547": ["Neapolis", "Ohio", "OH"],
"43548": ["New Bavaria", "Ohio", "OH"],
"43549": ["Ney", "Ohio", "OH"],
"43551": ["Perrysburg", "Ohio", "OH"],
"43553": ["Pettisville", "Ohio", "OH"],
"43554": ["Pioneer", "Ohio", "OH"],
"43555": ["Ridgeville Corners", "Ohio", "OH"],
"43556": ["Sherwood", "Ohio", "OH"],
"43557": ["Stryker", "Ohio", "OH"],
"43558": ["Swanton", "Ohio", "OH"],
"43560": ["Sylvania", "Ohio", "OH"],
"43565": ["Tontogany", "Ohio", "OH"],
"43566": ["Waterville", "Ohio", "OH"],
"43567": ["Wauseon", "Ohio", "OH"],
"43569": ["Weston", "Ohio", "OH"],
"43570": ["West Unity", "Ohio", "OH"],
"43571": ["Whitehouse", "Ohio", "OH"],
"43604": ["Toledo", "Ohio", "OH"],
"43605": ["Toledo", "Ohio", "OH"],
"43606": ["Toledo", "Ohio", "OH"],
"43607": ["Toledo", "Ohio", "OH"],
"43608": ["Toledo", "Ohio", "OH"],
"43609": ["Toledo", "Ohio", "OH"],
"43610": ["Toledo", "Ohio", "OH"],
"43611": ["Toledo", "Ohio", "OH"],
"43612": ["Toledo", "Ohio", "OH"],
"43613": ["Toledo", "Ohio", "OH"],
"43614": ["Toledo", "Ohio", "OH"],
"43615": ["Toledo", "Ohio", "OH"],
"43616": ["Oregon", "Ohio", "OH"],
"43617": ["Toledo", "Ohio", "OH"],
"43619": ["Northwood", "Ohio", "OH"],
"43620": ["Toledo", "Ohio", "OH"],
"43623": ["Toledo", "Ohio", "OH"],
"43701": ["Zanesville", "Ohio", "OH"],
"43711": ["Ava", "Ohio", "OH"],
"43713": ["Barnesville", "Ohio", "OH"],
"43716": ["Beallsville", "Ohio", "OH"],
"43717": ["Belle Valley", "Ohio", "OH"],
"43718": ["Belmont", "Ohio", "OH"],
"43719": ["Bethesda", "Ohio", "OH"],
"43720": ["Blue Rock", "Ohio", "OH"],
"43721": ["Brownsville", "Ohio", "OH"],
"43722": ["Buffalo", "Ohio", "OH"],
"43723": ["Byesville", "Ohio", "OH"],
"43724": ["Caldwell", "Ohio", "OH"],
"43725": ["Cambridge", "Ohio", "OH"],
"43727": ["Chandlersville", "Ohio", "OH"],
"43728": ["Chesterhill", "Ohio", "OH"],
"43730": ["Corning", "Ohio", "OH"],
"43731": ["Crooksville", "Ohio", "OH"],
"43732": ["Cumberland", "Ohio", "OH"],
"43733": ["Derwent", "Ohio", "OH"],
"43734": ["Duncan Falls", "Ohio", "OH"],
"43735": ["East Fultonham", "Ohio", "OH"],
"43736": ["Fairview", "Ohio", "OH"],
"43738": ["Fultonham", "Ohio", "OH"],
"43739": ["Glenford", "Ohio", "OH"],
"43740": ["Gratiot", "Ohio", "OH"],
"43746": ["Hopewell", "Ohio", "OH"],
"43747": ["Jerusalem", "Ohio", "OH"],
"43748": ["Junction City", "Ohio", "OH"],
"43749": ["Kimbolton", "Ohio", "OH"],
"43750": ["Kipling", "Ohio", "OH"],
"43754": ["Lewisville", "Ohio", "OH"],
"43755": ["Lore City", "Ohio", "OH"],
"43756": ["Mcconnelsville", "Ohio", "OH"],
"43758": ["Malta", "Ohio", "OH"],
"43759": ["Morristown", "Ohio", "OH"],
"43760": ["Mount Perry", "Ohio", "OH"],
"43761": ["Moxahala", "Ohio", "OH"],
"43762": ["New Concord", "Ohio", "OH"],
"43764": ["New Lexington", "Ohio", "OH"],
"43766": ["New Straitsville", "Ohio", "OH"],
"43767": ["Norwich", "Ohio", "OH"],
"43768": ["Old Washington", "Ohio", "OH"],
"43771": ["Philo", "Ohio", "OH"],
"43772": ["Pleasant City", "Ohio", "OH"],
"43773": ["Quaker City", "Ohio", "OH"],
"43777": ["Roseville", "Ohio", "OH"],
"43778": ["Salesville", "Ohio", "OH"],
"43779": ["Sarahsville", "Ohio", "OH"],
"43780": ["Senecaville", "Ohio", "OH"],
"43782": ["Shawnee", "Ohio", "OH"],
"43783": ["Somerset", "Ohio", "OH"],
"43786": ["Stafford", "Ohio", "OH"],
"43787": ["Stockport", "Ohio", "OH"],
"43788": ["Summerfield", "Ohio", "OH"],
"43791": ["White Cottage", "Ohio", "OH"],
"43793": ["Woodsfield", "Ohio", "OH"],
"43802": ["Adamsville", "Ohio", "OH"],
"43804": ["Baltic", "Ohio", "OH"],
"43805": ["Blissfield", "Ohio", "OH"],
"43811": ["Conesville", "Ohio", "OH"],
"43812": ["Coshocton", "Ohio", "OH"],
"43821": ["Dresden", "Ohio", "OH"],
"43822": ["Frazeysburg", "Ohio", "OH"],
"43824": ["Fresno", "Ohio", "OH"],
"43830": ["Nashport", "Ohio", "OH"],
"43832": ["Newcomerstown", "Ohio", "OH"],
"43836": ["Plainfield", "Ohio", "OH"],
"43837": ["Port Washington", "Ohio", "OH"],
"43840": ["Stone Creek", "Ohio", "OH"],
"43842": ["Trinway", "Ohio", "OH"],
"43843": ["Walhonding", "Ohio", "OH"],
"43844": ["Warsaw", "Ohio", "OH"],
"43845": ["West Lafayette", "Ohio", "OH"],
"43901": ["Adena", "Ohio", "OH"],
"43902": ["Alledonia", "Ohio", "OH"],
"43903": ["Amsterdam", "Ohio", "OH"],
"43905": ["Barton", "Ohio", "OH"],
"43906": ["Bellaire", "Ohio", "OH"],
"43907": ["Cadiz", "Ohio", "OH"],
"43908": ["Bergholz", "Ohio", "OH"],
"43909": ["Blaine", "Ohio", "OH"],
"43910": ["Bloomingdale", "Ohio", "OH"],
"43912": ["Bridgeport", "Ohio", "OH"],
"43913": ["Brilliant", "Ohio", "OH"],
"43914": ["Cameron", "Ohio", "OH"],
"43915": ["Clarington", "Ohio", "OH"],
"43917": ["Dillonvale", "Ohio", "OH"],
"43920": ["East Liverpool", "Ohio", "OH"],
"43925": ["East Springfield", "Ohio", "OH"],
"43926": ["Empire", "Ohio", "OH"],
"43928": ["Glencoe", "Ohio", "OH"],
"43930": ["Hammondsville", "Ohio", "OH"],
"43931": ["Hannibal", "Ohio", "OH"],
"43932": ["Irondale", "Ohio", "OH"],
"43933": ["Jacobsburg", "Ohio", "OH"],
"43934": ["Lansing", "Ohio", "OH"],
"43935": ["Martins Ferry", "Ohio", "OH"],
"43938": ["Mingo Junction", "Ohio", "OH"],
"43939": ["Mount Pleasant", "Ohio", "OH"],
"43940": ["Neffs", "Ohio", "OH"],
"43942": ["Powhatan Point", "Ohio", "OH"],
"43943": ["Rayland", "Ohio", "OH"],
"43944": ["Richmond", "Ohio", "OH"],
"43945": ["Salineville", "Ohio", "OH"],
"43946": ["Sardis", "Ohio", "OH"],
"43947": ["Shadyside", "Ohio", "OH"],
"43948": ["Smithfield", "Ohio", "OH"],
"43950": ["Saint Clairsville", "Ohio", "OH"],
"43951": ["Lafferty", "Ohio", "OH"],
"43952": ["Steubenville", "Ohio", "OH"],
"43953": ["Steubenville", "Ohio", "OH"],
"43961": ["Stratton", "Ohio", "OH"],
"43962": ["Summitville", "Ohio", "OH"],
"43963": ["Tiltonsville", "Ohio", "OH"],
"43964": ["Toronto", "Ohio", "OH"],
"43967": ["Warnock", "Ohio", "OH"],
"43968": ["Wellsville", "Ohio", "OH"],
"43971": ["Yorkville", "Ohio", "OH"],
"43972": ["Bannock", "Ohio", "OH"],
"43973": ["Freeport", "Ohio", "OH"],
"43974": ["Harrisville", "Ohio", "OH"],
"43976": ["Hopedale", "Ohio", "OH"],
"43977": ["Flushing", "Ohio", "OH"],
"43981": ["New Athens", "Ohio", "OH"],
"43983": ["Piedmont", "Ohio", "OH"],
"43985": ["Holloway", "Ohio", "OH"],
"43986": ["Jewett", "Ohio", "OH"],
"43988": ["Scio", "Ohio", "OH"],
"44001": ["Amherst", "Ohio", "OH"],
"44003": ["Andover", "Ohio", "OH"],
"44004": ["Ashtabula", "Ohio", "OH"],
"44010": ["Austinburg", "Ohio", "OH"],
"44011": ["Avon", "Ohio", "OH"],
"44012": ["Avon Lake", "Ohio", "OH"],
"44017": ["Berea", "Ohio", "OH"],
"44021": ["Burton", "Ohio", "OH"],
"44022": ["Chagrin Falls", "Ohio", "OH"],
"44023": ["Chagrin Falls", "Ohio", "OH"],
"44024": ["Chardon", "Ohio", "OH"],
"44026": ["Chesterland", "Ohio", "OH"],
"44028": ["Columbia Station", "Ohio", "OH"],
"44030": ["Conneaut", "Ohio", "OH"],
"44032": ["Dorset", "Ohio", "OH"],
"44035": ["Elyria", "Ohio", "OH"],
"44039": ["North Ridgeville", "Ohio", "OH"],
"44040": ["Gates Mills", "Ohio", "OH"],
"44041": ["Geneva", "Ohio", "OH"],
"44044": ["Grafton", "Ohio", "OH"],
"44045": ["Grand River", "Ohio", "OH"],
"44046": ["Huntsburg", "Ohio", "OH"],
"44047": ["Jefferson", "Ohio", "OH"],
"44048": ["Kingsville", "Ohio", "OH"],
"44049": ["Kipton", "Ohio", "OH"],
"44050": ["Lagrange", "Ohio", "OH"],
"44052": ["Lorain", "Ohio", "OH"],
"44053": ["Lorain", "Ohio", "OH"],
"44054": ["Sheffield Lake", "Ohio", "OH"],
"44055": ["Lorain", "Ohio", "OH"],
"44056": ["Macedonia", "Ohio", "OH"],
"44057": ["Madison", "Ohio", "OH"],
"44060": ["Mentor", "Ohio", "OH"],
"44062": ["Middlefield", "Ohio", "OH"],
"44064": ["Montville", "Ohio", "OH"],
"44065": ["Newbury", "Ohio", "OH"],
"44067": ["Northfield", "Ohio", "OH"],
"44068": ["North Kingsville", "Ohio", "OH"],
"44070": ["North Olmsted", "Ohio", "OH"],
"44072": ["Novelty", "Ohio", "OH"],
"44074": ["Oberlin", "Ohio", "OH"],
"44076": ["Orwell", "Ohio", "OH"],
"44077": ["Painesville", "Ohio", "OH"],
"44080": ["Parkman", "Ohio", "OH"],
"44081": ["Perry", "Ohio", "OH"],
"44082": ["Pierpont", "Ohio", "OH"],
"44084": ["Rock Creek", "Ohio", "OH"],
"44085": ["Rome", "Ohio", "OH"],
"44086": ["Thompson", "Ohio", "OH"],
"44087": ["Twinsburg", "Ohio", "OH"],
"44089": ["Vermilion", "Ohio", "OH"],
"44090": ["Wellington", "Ohio", "OH"],
"44092": ["Wickliffe", "Ohio", "OH"],
"44093": ["Williamsfield", "Ohio", "OH"],
"44094": ["Willoughby", "Ohio", "OH"],
"44095": ["Eastlake", "Ohio", "OH"],
"44099": ["Windsor", "Ohio", "OH"],
"44101": ["Cleveland", "Ohio", "OH"],
"44102": ["Cleveland", "Ohio", "OH"],
"44103": ["Cleveland", "Ohio", "OH"],
"44104": ["Cleveland", "Ohio", "OH"],
"44105": ["Cleveland", "Ohio", "OH"],
"44106": ["Cleveland", "Ohio", "OH"],
"44107": ["Lakewood", "Ohio", "OH"],
"44108": ["Cleveland", "Ohio", "OH"],
"44109": ["Cleveland", "Ohio", "OH"],
"44110": ["Cleveland", "Ohio", "OH"],
"44111": ["Cleveland", "Ohio", "OH"],
"44112": ["Cleveland", "Ohio", "OH"],
"44113": ["Cleveland", "Ohio", "OH"],
"44114": ["Cleveland", "Ohio", "OH"],
"44115": ["Cleveland", "Ohio", "OH"],
"44116": ["Rocky River", "Ohio", "OH"],
"44117": ["Euclid", "Ohio", "OH"],
"44118": ["Cleveland", "Ohio", "OH"],
"44119": ["Cleveland", "Ohio", "OH"],
"44120": ["Cleveland", "Ohio", "OH"],
"44121": ["Cleveland", "Ohio", "OH"],
"44122": ["Beachwood", "Ohio", "OH"],
"44123": ["Euclid", "Ohio", "OH"],
"44124": ["Cleveland", "Ohio", "OH"],
"44125": ["Cleveland", "Ohio", "OH"],
"44126": ["Cleveland", "Ohio", "OH"],
"44127": ["Cleveland", "Ohio", "OH"],
"44128": ["Cleveland", "Ohio", "OH"],
"44129": ["Cleveland", "Ohio", "OH"],
"44130": ["Cleveland", "Ohio", "OH"],
"44131": ["Independence", "Ohio", "OH"],
"44132": ["Euclid", "Ohio", "OH"],
"44133": ["North Royalton", "Ohio", "OH"],
"44134": ["Cleveland", "Ohio", "OH"],
"44135": ["Cleveland", "Ohio", "OH"],
"44136": ["Strongsville", "Ohio", "OH"],
"44137": ["Maple Heights", "Ohio", "OH"],
"44138": ["Olmsted Falls", "Ohio", "OH"],
"44139": ["Solon", "Ohio", "OH"],
"44140": ["Bay Village", "Ohio", "OH"],
"44141": ["Brecksville", "Ohio", "OH"],
"44142": ["Brookpark", "Ohio", "OH"],
"44143": ["Cleveland", "Ohio", "OH"],
"44144": ["Cleveland", "Ohio", "OH"],
"44145": ["Westlake", "Ohio", "OH"],
"44146": ["Bedford", "Ohio", "OH"],
"44147": ["Broadview Heights", "Ohio", "OH"],
"44149": ["Strongsville", "Ohio", "OH"],
"44201": ["Atwater", "Ohio", "OH"],
"44202": ["Aurora", "Ohio", "OH"],
"44203": ["Barberton", "Ohio", "OH"],
"44212": ["Brunswick", "Ohio", "OH"],
"44214": ["Burbank", "Ohio", "OH"],
"44215": ["Chippewa Lake", "Ohio", "OH"],
"44216": ["Clinton", "Ohio", "OH"],
"44217": ["Creston", "Ohio", "OH"],
"44221": ["Cuyahoga Falls", "Ohio", "OH"],
"44223": ["Cuyahoga Falls", "Ohio", "OH"],
"44224": ["Stow", "Ohio", "OH"],
"44230": ["Doylestown", "Ohio", "OH"],
"44231": ["Garrettsville", "Ohio", "OH"],
"44232": ["Green", "Ohio", "OH"],
"44233": ["Hinckley", "Ohio", "OH"],
"44234": ["Hiram", "Ohio", "OH"],
"44235": ["Homerville", "Ohio", "OH"],
"44236": ["Hudson", "Ohio", "OH"],
"44240": ["Kent", "Ohio", "OH"],
"44241": ["Streetsboro", "Ohio", "OH"],
"44243": ["Kent", "Ohio", "OH"],
"44250": ["Lakemore", "Ohio", "OH"],
"44251": ["Westfield Center", "Ohio", "OH"],
"44253": ["Litchfield", "Ohio", "OH"],
"44254": ["Lodi", "Ohio", "OH"],
"44255": ["Mantua", "Ohio", "OH"],
"44256": ["Medina", "Ohio", "OH"],
"44260": ["Mogadore", "Ohio", "OH"],
"44262": ["Munroe Falls", "Ohio", "OH"],
"44264": ["Peninsula", "Ohio", "OH"],
"44266": ["Ravenna", "Ohio", "OH"],
"44270": ["Rittman", "Ohio", "OH"],
"44272": ["Rootstown", "Ohio", "OH"],
"44273": ["Seville", "Ohio", "OH"],
"44274": ["Sharon Center", "Ohio", "OH"],
"44275": ["Spencer", "Ohio", "OH"],
"44276": ["Sterling", "Ohio", "OH"],
"44278": ["Tallmadge", "Ohio", "OH"],
"44280": ["Valley City", "Ohio", "OH"],
"44281": ["Wadsworth", "Ohio", "OH"],
"44285": ["Wayland", "Ohio", "OH"],
"44286": ["Richfield", "Ohio", "OH"],
"44287": ["West Salem", "Ohio", "OH"],
"44288": ["Windham", "Ohio", "OH"],
"44301": ["Akron", "Ohio", "OH"],
"44302": ["Akron", "Ohio", "OH"],
"44303": ["Akron", "Ohio", "OH"],
"44304": ["Akron", "Ohio", "OH"],
"44305": ["Akron", "Ohio", "OH"],
"44306": ["Akron", "Ohio", "OH"],
"44307": ["Akron", "Ohio", "OH"],
"44308": ["Akron", "Ohio", "OH"],
"44310": ["Akron", "Ohio", "OH"],
"44311": ["Akron", "Ohio", "OH"],
"44312": ["Akron", "Ohio", "OH"],
"44313": ["Akron", "Ohio", "OH"],
"44314": ["Akron", "Ohio", "OH"],
"44319": ["Akron", "Ohio", "OH"],
"44320": ["Akron", "Ohio", "OH"],
"44321": ["Akron", "Ohio", "OH"],
"44325": ["Akron", "Ohio", "OH"],
"44333": ["Akron", "Ohio", "OH"],
"44401": ["Berlin Center", "Ohio", "OH"],
"44402": ["Bristolville", "Ohio", "OH"],
"44403": ["Brookfield", "Ohio", "OH"],
"44404": ["Burghill", "Ohio", "OH"],
"44405": ["Campbell", "Ohio", "OH"],
"44406": ["Canfield", "Ohio", "OH"],
"44408": ["Columbiana", "Ohio", "OH"],
"44410": ["Cortland", "Ohio", "OH"],
"44411": ["Deerfield", "Ohio", "OH"],
"44412": ["Diamond", "Ohio", "OH"],
"44413": ["East Palestine", "Ohio", "OH"],
"44415": ["Elkton", "Ohio", "OH"],
"44417": ["Farmdale", "Ohio", "OH"],
"44418": ["Fowler", "Ohio", "OH"],
"44420": ["Girard", "Ohio", "OH"],
"44423": ["Hanoverton", "Ohio", "OH"],
"44424": ["Hartford", "Ohio", "OH"],
"44425": ["Hubbard", "Ohio", "OH"],
"44427": ["Kensington", "Ohio", "OH"],
"44428": ["Kinsman", "Ohio", "OH"],
"44429": ["Lake Milton", "Ohio", "OH"],
"44430": ["Leavittsburg", "Ohio", "OH"],
"44431": ["Leetonia", "Ohio", "OH"],
"44432": ["Lisbon", "Ohio", "OH"],
"44436": ["Lowellville", "Ohio", "OH"],
"44437": ["McDonald", "Ohio", "OH"],
"44438": ["Masury", "Ohio", "OH"],
"44439": ["Mesopotamia", "Ohio", "OH"],
"44440": ["Mineral Ridge", "Ohio", "OH"],
"44441": ["Negley", "Ohio", "OH"],
"44442": ["New Middletown", "Ohio", "OH"],
"44443": ["New Springfield", "Ohio", "OH"],
"44444": ["Newton Falls", "Ohio", "OH"],
"44445": ["New Waterford", "Ohio", "OH"],
"44446": ["Niles", "Ohio", "OH"],
"44449": ["North Benton", "Ohio", "OH"],
"44450": ["North Bloomfield", "Ohio", "OH"],
"44451": ["North Jackson", "Ohio", "OH"],
"44452": ["North Lima", "Ohio", "OH"],
"44454": ["Petersburg", "Ohio", "OH"],
"44455": ["Rogers", "Ohio", "OH"],
"44460": ["Salem", "Ohio", "OH"],
"44470": ["Southington", "Ohio", "OH"],
"44471": ["Struthers", "Ohio", "OH"],
"44473": ["Vienna", "Ohio", "OH"],
"44481": ["Warren", "Ohio", "OH"],
"44483": ["Warren", "Ohio", "OH"],
"44484": ["Warren", "Ohio", "OH"],
"44485": ["Warren", "Ohio", "OH"],
"44490": ["Washingtonville", "Ohio", "OH"],
"44491": ["West Farmington", "Ohio", "OH"],
"44493": ["Winona", "Ohio", "OH"],
"44502": ["Youngstown", "Ohio", "OH"],
"44503": ["Youngstown", "Ohio", "OH"],
"44504": ["Youngstown", "Ohio", "OH"],
"44505": ["Youngstown", "Ohio", "OH"],
"44506": ["Youngstown", "Ohio", "OH"],
"44507": ["Youngstown", "Ohio", "OH"],
"44509": ["Youngstown", "Ohio", "OH"],
"44510": ["Youngstown", "Ohio", "OH"],
"44511": ["Youngstown", "Ohio", "OH"],
"44512": ["Youngstown", "Ohio", "OH"],
"44514": ["Youngstown", "Ohio", "OH"],
"44515": ["Youngstown", "Ohio", "OH"],
"44555": ["Youngstown", "Ohio", "OH"],
"44601": ["Alliance", "Ohio", "OH"],
"44606": ["Apple Creek", "Ohio", "OH"],
"44607": ["Augusta", "Ohio", "OH"],
"44608": ["Beach City", "Ohio", "OH"],
"44609": ["Beloit", "Ohio", "OH"],
"44610": ["Berlin", "Ohio", "OH"],
"44611": ["Big Prairie", "Ohio", "OH"],
"44612": ["Bolivar", "Ohio", "OH"],
"44613": ["Brewster", "Ohio", "OH"],
"44614": ["Canal Fulton", "Ohio", "OH"],
"44615": ["Carrollton", "Ohio", "OH"],
"44618": ["Dalton", "Ohio", "OH"],
"44619": ["Damascus", "Ohio", "OH"],
"44620": ["Dellroy", "Ohio", "OH"],
"44621": ["Dennison", "Ohio", "OH"],
"44622": ["Dover", "Ohio", "OH"],
"44624": ["Dundee", "Ohio", "OH"],
"44625": ["East Rochester", "Ohio", "OH"],
"44626": ["East Sparta", "Ohio", "OH"],
"44627": ["Fredericksburg", "Ohio", "OH"],
"44628": ["Glenmont", "Ohio", "OH"],
"44629": ["Gnadenhutten", "Ohio", "OH"],
"44630": ["Greentown", "Ohio", "OH"],
"44632": ["Hartville", "Ohio", "OH"],
"44633": ["Holmesville", "Ohio", "OH"],
"44634": ["Homeworth", "Ohio", "OH"],
"44637": ["Killbuck", "Ohio", "OH"],
"44638": ["Lakeville", "Ohio", "OH"],
"44639": ["Leesville", "Ohio", "OH"],
"44640": ["Limaville", "Ohio", "OH"],
"44641": ["Louisville", "Ohio", "OH"],
"44643": ["Magnolia", "Ohio", "OH"],
"44644": ["Malvern", "Ohio", "OH"],
"44645": ["Marshallville", "Ohio", "OH"],
"44646": ["Massillon", "Ohio", "OH"],
"44647": ["Massillon", "Ohio", "OH"],
"44651": ["Mechanicstown", "Ohio", "OH"],
"44652": ["Middlebranch", "Ohio", "OH"],
"44653": ["Midvale", "Ohio", "OH"],
"44654": ["Millersburg", "Ohio", "OH"],
"44656": ["Mineral City", "Ohio", "OH"],
"44657": ["Minerva", "Ohio", "OH"],
"44659": ["Mount Eaton", "Ohio", "OH"],
"44660": ["Mount Hope", "Ohio", "OH"],
"44661": ["Nashville", "Ohio", "OH"],
"44662": ["Navarre", "Ohio", "OH"],
"44663": ["New Philadelphia", "Ohio", "OH"],
"44665": ["North Georgetown", "Ohio", "OH"],
"44666": ["North Lawrence", "Ohio", "OH"],
"44667": ["Orrville", "Ohio", "OH"],
"44669": ["Paris", "Ohio", "OH"],
"44670": ["Robertsville", "Ohio", "OH"],
"44671": ["Sandyville", "Ohio", "OH"],
"44672": ["Sebring", "Ohio", "OH"],
"44675": ["Sherrodsville", "Ohio", "OH"],
"44676": ["Shreve", "Ohio", "OH"],
"44677": ["Smithville", "Ohio", "OH"],
"44678": ["Somerdale", "Ohio", "OH"],
"44680": ["Strasburg", "Ohio", "OH"],
"44681": ["Sugarcreek", "Ohio", "OH"],
"44682": ["Tuscarawas", "Ohio", "OH"],
"44683": ["Uhrichsville", "Ohio", "OH"],
"44685": ["Uniontown", "Ohio", "OH"],
"44687": ["Walnut Creek", "Ohio", "OH"],
"44688": ["Waynesburg", "Ohio", "OH"],
"44689": ["Wilmot", "Ohio", "OH"],
"44690": ["Winesburg", "Ohio", "OH"],
"44691": ["Wooster", "Ohio", "OH"],
"44693": ["Deersville", "Ohio", "OH"],
"44695": ["Bowerston", "Ohio", "OH"],
"44697": ["Zoar", "Ohio", "OH"],
"44699": ["Tippecanoe", "Ohio", "OH"],
"44702": ["Canton", "Ohio", "OH"],
"44703": ["Canton", "Ohio", "OH"],
"44704": ["Canton", "Ohio", "OH"],
"44705": ["Canton", "Ohio", "OH"],
"44706": ["Canton", "Ohio", "OH"],
"44707": ["Canton", "Ohio", "OH"],
"44708": ["Canton", "Ohio", "OH"],
"44709": ["Canton", "Ohio", "OH"],
"44710": ["Canton", "Ohio", "OH"],
"44714": ["Canton", "Ohio", "OH"],
"44718": ["Canton", "Ohio", "OH"],
"44720": ["North Canton", "Ohio", "OH"],
"44721": ["Canton", "Ohio", "OH"],
"44730": ["East Canton", "Ohio", "OH"],
"44802": ["Alvada", "Ohio", "OH"],
"44804": ["Arcadia", "Ohio", "OH"],
"44805": ["Ashland", "Ohio", "OH"],
"44807": ["Attica", "Ohio", "OH"],
"44809": ["Bascom", "Ohio", "OH"],
"44811": ["Bellevue", "Ohio", "OH"],
"44813": ["Bellville", "Ohio", "OH"],
"44814": ["Berlin Heights", "Ohio", "OH"],
"44815": ["Bettsville", "Ohio", "OH"],
"44817": ["Bloomdale", "Ohio", "OH"],
"44818": ["Bloomville", "Ohio", "OH"],
"44820": ["Bucyrus", "Ohio", "OH"],
"44822": ["Butler", "Ohio", "OH"],
"44824": ["Castalia", "Ohio", "OH"],
"44825": ["Chatfield", "Ohio", "OH"],
"44826": ["Collins", "Ohio", "OH"],
"44827": ["Crestline", "Ohio", "OH"],
"44828": ["Flat Rock", "Ohio", "OH"],
"44830": ["Fostoria", "Ohio", "OH"],
"44833": ["Galion", "Ohio", "OH"],
"44836": ["Green Springs", "Ohio", "OH"],
"44837": ["Greenwich", "Ohio", "OH"],
"44838": ["Hayesville", "Ohio", "OH"],
"44839": ["Huron", "Ohio", "OH"],
"44840": ["Jeromesville", "Ohio", "OH"],
"44841": ["Kansas", "Ohio", "OH"],
"44842": ["Loudonville", "Ohio", "OH"],
"44843": ["Lucas", "Ohio", "OH"],
"44844": ["McCutchenville", "Ohio", "OH"],
"44846": ["Milan", "Ohio", "OH"],
"44847": ["Monroeville", "Ohio", "OH"],
"44849": ["Nevada", "Ohio", "OH"],
"44850": ["New Haven", "Ohio", "OH"],
"44851": ["New London", "Ohio", "OH"],
"44853": ["New Riegel", "Ohio", "OH"],
"44854": ["New Washington", "Ohio", "OH"],
"44855": ["North Fairfield", "Ohio", "OH"],
"44856": ["North Robinson", "Ohio", "OH"],
"44857": ["Norwalk", "Ohio", "OH"],
"44859": ["Nova", "Ohio", "OH"],
"44861": ["Old Fort", "Ohio", "OH"],
"44864": ["Perrysville", "Ohio", "OH"],
"44865": ["Plymouth", "Ohio", "OH"],
"44866": ["Polk", "Ohio", "OH"],
"44867": ["Republic", "Ohio", "OH"],
"44870": ["Sandusky", "Ohio", "OH"],
"44874": ["Savannah", "Ohio", "OH"],
"44875": ["Shelby", "Ohio", "OH"],
"44878": ["Shiloh", "Ohio", "OH"],
"44880": ["Sullivan", "Ohio", "OH"],
"44881": ["Sulphur Springs", "Ohio", "OH"],
"44882": ["Sycamore", "Ohio", "OH"],
"44883": ["Tiffin", "Ohio", "OH"],
"44887": ["Tiro", "Ohio", "OH"],
"44889": ["Wakeman", "Ohio", "OH"],
"44890": ["Willard", "Ohio", "OH"],
"44901": ["Mansfield", "Ohio", "OH"],
"44902": ["Mansfield", "Ohio", "OH"],
"44903": ["Mansfield", "Ohio", "OH"],
"44904": ["Mansfield", "Ohio", "OH"],
"44905": ["Mansfield", "Ohio", "OH"],
"44906": ["Mansfield", "Ohio", "OH"],
"44907": ["Mansfield", "Ohio", "OH"],
"45001": ["Addyston", "Ohio", "OH"],
"45002": ["Cleves", "Ohio", "OH"],
"45003": ["College Corner", "Ohio", "OH"],
"45005": ["Franklin", "Ohio", "OH"],
"45011": ["Hamilton", "Ohio", "OH"],
"45013": ["Hamilton", "Ohio", "OH"],
"45014": ["Fairfield", "Ohio", "OH"],
"45015": ["Hamilton", "Ohio", "OH"],
"45030": ["Harrison", "Ohio", "OH"],
"45032": ["Harveysburg", "Ohio", "OH"],
"45033": ["Hooven", "Ohio", "OH"],
"45034": ["Kings Mills", "Ohio", "OH"],
"45036": ["Lebanon", "Ohio", "OH"],
"45039": ["Maineville", "Ohio", "OH"],
"45040": ["Mason", "Ohio", "OH"],
"45041": ["Miamitown", "Ohio", "OH"],
"45042": ["Middletown", "Ohio", "OH"],
"45044": ["Middletown", "Ohio", "OH"],
"45050": ["Monroe", "Ohio", "OH"],
"45051": ["Mount Saint Joseph", "Ohio", "OH"],
"45052": ["North Bend", "Ohio", "OH"],
"45053": ["Okeana", "Ohio", "OH"],
"45054": ["Oregonia", "Ohio", "OH"],
"45055": ["Overpeck", "Ohio", "OH"],
"45056": ["Oxford", "Ohio", "OH"],
"45062": ["Seven Mile", "Ohio", "OH"],
"45064": ["Somerville", "Ohio", "OH"],
"45065": ["South Lebanon", "Ohio", "OH"],
"45066": ["Springboro", "Ohio", "OH"],
"45067": ["Trenton", "Ohio", "OH"],
"45068": ["Waynesville", "Ohio", "OH"],
"45069": ["West Chester", "Ohio", "OH"],
"45070": ["West Elkton", "Ohio", "OH"],
"45101": ["Aberdeen", "Ohio", "OH"],
"45102": ["Amelia", "Ohio", "OH"],
"45103": ["Batavia", "Ohio", "OH"],
"45105": ["Bentonville", "Ohio", "OH"],
"45106": ["Bethel", "Ohio", "OH"],
"45107": ["Blanchester", "Ohio", "OH"],
"45111": ["Camp Dennison", "Ohio", "OH"],
"45112": ["Chilo", "Ohio", "OH"],
"45113": ["Clarksville", "Ohio", "OH"],
"45115": ["Decatur", "Ohio", "OH"],
"45118": ["Fayetteville", "Ohio", "OH"],
"45119": ["Feesburg", "Ohio", "OH"],
"45120": ["Felicity", "Ohio", "OH"],
"45121": ["Georgetown", "Ohio", "OH"],
"45122": ["Goshen", "Ohio", "OH"],
"45123": ["Greenfield", "Ohio", "OH"],
"45130": ["Hamersville", "Ohio", "OH"],
"45131": ["Higginsport", "Ohio", "OH"],
"45132": ["Highland", "Ohio", "OH"],
"45133": ["Hillsboro", "Ohio", "OH"],
"45135": ["Leesburg", "Ohio", "OH"],
"45140": ["Loveland", "Ohio", "OH"],
"45142": ["Lynchburg", "Ohio", "OH"],
"45144": ["Manchester", "Ohio", "OH"],
"45146": ["Martinsville", "Ohio", "OH"],
"45147": ["Miamiville", "Ohio", "OH"],
"45148": ["Midland", "Ohio", "OH"],
"45150": ["Milford", "Ohio", "OH"],
"45152": ["Morrow", "Ohio", "OH"],
"45153": ["Moscow", "Ohio", "OH"],
"45154": ["Mount Orab", "Ohio", "OH"],
"45155": ["Mowrystown", "Ohio", "OH"],
"45156": ["Neville", "Ohio", "OH"],
"45157": ["New Richmond", "Ohio", "OH"],
"45158": ["Newtonsville", "Ohio", "OH"],
"45159": ["New Vienna", "Ohio", "OH"],
"45160": ["Owensville", "Ohio", "OH"],
"45162": ["Pleasant Plain", "Ohio", "OH"],
"45164": ["Port William", "Ohio", "OH"],
"45166": ["Reesville", "Ohio", "OH"],
"45167": ["Ripley", "Ohio", "OH"],
"45168": ["Russellville", "Ohio", "OH"],
"45169": ["Sabina", "Ohio", "OH"],
"45171": ["Sardinia", "Ohio", "OH"],
"45172": ["Sinking Spring", "Ohio", "OH"],
"45174": ["Terrace Park", "Ohio", "OH"],
"45176": ["Williamsburg", "Ohio", "OH"],
"45177": ["Wilmington", "Ohio", "OH"],
"45202": ["Cincinnati", "Ohio", "OH"],
"45203": ["Cincinnati", "Ohio", "OH"],
"45204": ["Cincinnati", "Ohio", "OH"],
"45205": ["Cincinnati", "Ohio", "OH"],
"45206": ["Cincinnati", "Ohio", "OH"],
"45207": ["Cincinnati", "Ohio", "OH"],
"45208": ["Cincinnati", "Ohio", "OH"],
"45209": ["Cincinnati", "Ohio", "OH"],
"45211": ["Cincinnati", "Ohio", "OH"],
"45212": ["Cincinnati", "Ohio", "OH"],
"45213": ["Cincinnati", "Ohio", "OH"],
"45214": ["Cincinnati", "Ohio", "OH"],
"45215": ["Cincinnati", "Ohio", "OH"],
"45216": ["Cincinnati", "Ohio", "OH"],
"45217": ["Cincinnati", "Ohio", "OH"],
"45218": ["Cincinnati", "Ohio", "OH"],
"45219": ["Cincinnati", "Ohio", "OH"],
"45220": ["Cincinnati", "Ohio", "OH"],
"45223": ["Cincinnati", "Ohio", "OH"],
"45224": ["Cincinnati", "Ohio", "OH"],
"45225": ["Cincinnati", "Ohio", "OH"],
"45226": ["Cincinnati", "Ohio", "OH"],
"45227": ["Cincinnati", "Ohio", "OH"],
"45229": ["Cincinnati", "Ohio", "OH"],
"45230": ["Cincinnati", "Ohio", "OH"],
"45231": ["Cincinnati", "Ohio", "OH"],
"45232": ["Cincinnati", "Ohio", "OH"],
"45233": ["Cincinnati", "Ohio", "OH"],
"45236": ["Cincinnati", "Ohio", "OH"],
"45237": ["Cincinnati", "Ohio", "OH"],
"45238": ["Cincinnati", "Ohio", "OH"],
"45239": ["Cincinnati", "Ohio", "OH"],
"45240": ["Cincinnati", "Ohio", "OH"],
"45241": ["Cincinnati", "Ohio", "OH"],
"45242": ["Cincinnati", "Ohio", "OH"],
"45243": ["Cincinnati", "Ohio", "OH"],
"45244": ["Cincinnati", "Ohio", "OH"],
"45245": ["Cincinnati", "Ohio", "OH"],
"45246": ["Cincinnati", "Ohio", "OH"],
"45247": ["Cincinnati", "Ohio", "OH"],
"45248": ["Cincinnati", "Ohio", "OH"],
"45249": ["Cincinnati", "Ohio", "OH"],
"45251": ["Cincinnati", "Ohio", "OH"],
"45252": ["Cincinnati", "Ohio", "OH"],
"45255": ["Cincinnati", "Ohio", "OH"],
"45267": ["Cincinnati", "Ohio", "OH"],
"45301": ["Alpha", "Ohio", "OH"],
"45302": ["Anna", "Ohio", "OH"],
"45303": ["Ansonia", "Ohio", "OH"],
"45304": ["Arcanum", "Ohio", "OH"],
"45305": ["Bellbrook", "Ohio", "OH"],
"45306": ["Botkins", "Ohio", "OH"],
"45307": ["Bowersville", "Ohio", "OH"],
"45308": ["Bradford", "Ohio", "OH"],
"45309": ["Brookville", "Ohio", "OH"],
"45310": ["Burkettsville", "Ohio", "OH"],
"45311": ["Camden", "Ohio", "OH"],
"45312": ["Casstown", "Ohio", "OH"],
"45314": ["Cedarville", "Ohio", "OH"],
"45315": ["Clayton", "Ohio", "OH"],
"45316": ["Clifton", "Ohio", "OH"],
"45317": ["Conover", "Ohio", "OH"],
"45318": ["Covington", "Ohio", "OH"],
"45319": ["Donnelsville", "Ohio", "OH"],
"45320": ["Eaton", "Ohio", "OH"],
"45321": ["Eldorado", "Ohio", "OH"],
"45322": ["Englewood", "Ohio", "OH"],
"45323": ["Enon", "Ohio", "OH"],
"45324": ["Fairborn", "Ohio", "OH"],
"45325": ["Farmersville", "Ohio", "OH"],
"45326": ["Fletcher", "Ohio", "OH"],
"45327": ["Germantown", "Ohio", "OH"],
"45328": ["Gettysburg", "Ohio", "OH"],
"45330": ["Gratis", "Ohio", "OH"],
"45331": ["Greenville", "Ohio", "OH"],
"45332": ["Hollansburg", "Ohio", "OH"],
"45333": ["Houston", "Ohio", "OH"],
"45334": ["Jackson Center", "Ohio", "OH"],
"45335": ["Jamestown", "Ohio", "OH"],
"45336": ["Kettlersville", "Ohio", "OH"],
"45337": ["Laura", "Ohio", "OH"],
"45338": ["Lewisburg", "Ohio", "OH"],
"45339": ["Ludlow Falls", "Ohio", "OH"],
"45340": ["Maplewood", "Ohio", "OH"],
"45341": ["Medway", "Ohio", "OH"],
"45342": ["Miamisburg", "Ohio", "OH"],
"45344": ["New Carlisle", "Ohio", "OH"],
"45345": ["New Lebanon", "Ohio", "OH"],
"45346": ["New Madison", "Ohio", "OH"],
"45347": ["New Paris", "Ohio", "OH"],
"45348": ["New Weston", "Ohio", "OH"],
"45349": ["North Hampton", "Ohio", "OH"],
"45350": ["North Star", "Ohio", "OH"],
"45351": ["Osgood", "Ohio", "OH"],
"45352": ["Palestine", "Ohio", "OH"],
"45353": ["Pemberton", "Ohio", "OH"],
"45354": ["Phillipsburg", "Ohio", "OH"],
"45356": ["Piqua", "Ohio", "OH"],
"45358": ["Pitsburg", "Ohio", "OH"],
"45359": ["Pleasant Hill", "Ohio", "OH"],
"45360": ["Port Jefferson", "Ohio", "OH"],
"45361": ["Potsdam", "Ohio", "OH"],
"45362": ["Rossburg", "Ohio", "OH"],
"45363": ["Russia", "Ohio", "OH"],
"45365": ["Sidney", "Ohio", "OH"],
"45368": ["South Charleston", "Ohio", "OH"],
"45369": ["South Vienna", "Ohio", "OH"],
"45370": ["Spring Valley", "Ohio", "OH"],
"45371": ["Tipp City", "Ohio", "OH"],
"45372": ["Tremont City", "Ohio", "OH"],
"45373": ["Troy", "Ohio", "OH"],
"45377": ["Vandalia", "Ohio", "OH"],
"45378": ["Verona", "Ohio", "OH"],
"45380": ["Versailles", "Ohio", "OH"],
"45381": ["West Alexandria", "Ohio", "OH"],
"45382": ["West Manchester", "Ohio", "OH"],
"45383": ["West Milton", "Ohio", "OH"],
"45384": ["Wilberforce", "Ohio", "OH"],
"45385": ["Xenia", "Ohio", "OH"],
"45387": ["Yellow Springs", "Ohio", "OH"],
"45388": ["Yorkshire", "Ohio", "OH"],
"45389": ["Christiansburg", "Ohio", "OH"],
"45390": ["Union City", "Ohio", "OH"],
"45402": ["Dayton", "Ohio", "OH"],
"45403": ["Dayton", "Ohio", "OH"],
"45404": ["Dayton", "Ohio", "OH"],
"45405": ["Dayton", "Ohio", "OH"],
"45406": ["Dayton", "Ohio", "OH"],
"45409": ["Dayton", "Ohio", "OH"],
"45410": ["Dayton", "Ohio", "OH"],
"45414": ["Dayton", "Ohio", "OH"],
"45415": ["Dayton", "Ohio", "OH"],
"45416": ["Dayton", "Ohio", "OH"],
"45417": ["Dayton", "Ohio", "OH"],
"45419": ["Dayton", "Ohio", "OH"],
"45420": ["Dayton", "Ohio", "OH"],
"45423": ["Dayton", "Ohio", "OH"],
"45424": ["Dayton", "Ohio", "OH"],
"45426": ["Dayton", "Ohio", "OH"],
"45428": ["Dayton", "Ohio", "OH"],
"45429": ["Dayton", "Ohio", "OH"],
"45430": ["Dayton", "Ohio", "OH"],
"45431": ["Dayton", "Ohio", "OH"],
"45432": ["Dayton", "Ohio", "OH"],
"45433": ["Dayton", "Ohio", "OH"],
"45434": ["Dayton", "Ohio", "OH"],
"45435": ["Dayton", "Ohio", "OH"],
"45439": ["Dayton", "Ohio", "OH"],
"45440": ["Dayton", "Ohio", "OH"],
"45449": ["Dayton", "Ohio", "OH"],
"45458": ["Dayton", "Ohio", "OH"],
"45459": ["Dayton", "Ohio", "OH"],
"45469": ["Dayton", "Ohio", "OH"],
"45501": ["Springfield", "Ohio", "OH"],
"45502": ["Springfield", "Ohio", "OH"],
"45503": ["Springfield", "Ohio", "OH"],
"45504": ["Springfield", "Ohio", "OH"],
"45505": ["Springfield", "Ohio", "OH"],
"45506": ["Springfield", "Ohio", "OH"],
"45601": ["Chillicothe", "Ohio", "OH"],
"45612": ["Bainbridge", "Ohio", "OH"],
"45613": ["Beaver", "Ohio", "OH"],
"45614": ["Bidwell", "Ohio", "OH"],
"45616": ["Blue Creek", "Ohio", "OH"],
"45617": ["Bourneville", "Ohio", "OH"],
"45618": ["Cherry Fork", "Ohio", "OH"],
"45619": ["Chesapeake", "Ohio", "OH"],
"45620": ["Cheshire", "Ohio", "OH"],
"45621": ["Coalton", "Ohio", "OH"],
"45622": ["Creola", "Ohio", "OH"],
"45623": ["Crown City", "Ohio", "OH"],
"45624": ["Cynthiana", "Ohio", "OH"],
"45628": ["Frankfort", "Ohio", "OH"],
"45629": ["Franklin Furnace", "Ohio", "OH"],
"45630": ["Friendship", "Ohio", "OH"],
"45631": ["Gallipolis", "Ohio", "OH"],
"45634": ["Hamden", "Ohio", "OH"],
"45636": ["Haverhill", "Ohio", "OH"],
"45638": ["Ironton", "Ohio", "OH"],
"45640": ["Jackson", "Ohio", "OH"],
"45642": ["Jasper", "Ohio", "OH"],
"45644": ["Kingston", "Ohio", "OH"],
"45645": ["Kitts Hill", "Ohio", "OH"],
"45646": ["Latham", "Ohio", "OH"],
"45647": ["Londonderry", "Ohio", "OH"],
"45648": ["Lucasville", "Ohio", "OH"],
"45650": ["Lynx", "Ohio", "OH"],
"45651": ["McArthur", "Ohio", "OH"],
"45652": ["McDermott", "Ohio", "OH"],
"45653": ["Minford", "Ohio", "OH"],
"45654": ["New Plymouth", "Ohio", "OH"],
"45656": ["Oak Hill", "Ohio", "OH"],
"45657": ["Otway", "Ohio", "OH"],
"45658": ["Patriot", "Ohio", "OH"],
"45659": ["Pedro", "Ohio", "OH"],
"45660": ["Peebles", "Ohio", "OH"],
"45661": ["Piketon", "Ohio", "OH"],
"45662": ["Portsmouth", "Ohio", "OH"],
"45663": ["West Portsmouth", "Ohio", "OH"],
"45669": ["Proctorville", "Ohio", "OH"],
"45671": ["Rarden", "Ohio", "OH"],
"45672": ["Ray", "Ohio", "OH"],
"45673": ["Richmond Dale", "Ohio", "OH"],
"45674": ["Rio Grande", "Ohio", "OH"],
"45677": ["Scioto Furnace", "Ohio", "OH"],
"45678": ["Scottown", "Ohio", "OH"],
"45679": ["Seaman", "Ohio", "OH"],
"45680": ["South Point", "Ohio", "OH"],
"45681": ["South Salem", "Ohio", "OH"],
"45682": ["South Webster", "Ohio", "OH"],
"45684": ["Stout", "Ohio", "OH"],
"45685": ["Thurman", "Ohio", "OH"],
"45686": ["Vinton", "Ohio", "OH"],
"45688": ["Waterloo", "Ohio", "OH"],
"45690": ["Waverly", "Ohio", "OH"],
"45692": ["Wellston", "Ohio", "OH"],
"45693": ["West Union", "Ohio", "OH"],
"45694": ["Wheelersburg", "Ohio", "OH"],
"45695": ["Wilkesville", "Ohio", "OH"],
"45696": ["Willow Wood", "Ohio", "OH"],
"45697": ["Winchester", "Ohio", "OH"],
"45698": ["Zaleski", "Ohio", "OH"],
"45699": ["Lucasville", "Ohio", "OH"],
"45701": ["Athens", "Ohio", "OH"],
"45710": ["Albany", "Ohio", "OH"],
"45711": ["Amesville", "Ohio", "OH"],
"45714": ["Belpre", "Ohio", "OH"],
"45715": ["Beverly", "Ohio", "OH"],
"45716": ["Buchtel", "Ohio", "OH"],
"45719": ["Chauncey", "Ohio", "OH"],
"45720": ["Chester", "Ohio", "OH"],
"45721": ["Coal Run", "Ohio", "OH"],
"45723": ["Coolville", "Ohio", "OH"],
"45724": ["Cutler", "Ohio", "OH"],
"45727": ["Dexter City", "Ohio", "OH"],
"45729": ["Fleming", "Ohio", "OH"],
"45732": ["Glouster", "Ohio", "OH"],
"45734": ["Graysville", "Ohio", "OH"],
"45735": ["Guysville", "Ohio", "OH"],
"45739": ["Hockingport", "Ohio", "OH"],
"45740": ["Jacksonville", "Ohio", "OH"],
"45741": ["Langsville", "Ohio", "OH"],
"45742": ["Little Hocking", "Ohio", "OH"],
"45743": ["Long Bottom", "Ohio", "OH"],
"45744": ["Lowell", "Ohio", "OH"],
"45745": ["Lower Salem", "Ohio", "OH"],
"45746": ["Macksburg", "Ohio", "OH"],
"45750": ["Marietta", "Ohio", "OH"],
"45760": ["Middleport", "Ohio", "OH"],
"45761": ["Millfield", "Ohio", "OH"],
"45764": ["Nelsonville", "Ohio", "OH"],
"45766": ["New Marshfield", "Ohio", "OH"],
"45767": ["New Matamoras", "Ohio", "OH"],
"45768": ["Newport", "Ohio", "OH"],
"45769": ["Pomeroy", "Ohio", "OH"],
"45770": ["Portland", "Ohio", "OH"],
"45771": ["Racine", "Ohio", "OH"],
"45772": ["Reedsville", "Ohio", "OH"],
"45773": ["Reno", "Ohio", "OH"],
"45775": ["Rutland", "Ohio", "OH"],
"45776": ["Shade", "Ohio", "OH"],
"45778": ["Stewart", "Ohio", "OH"],
"45779": ["Syracuse", "Ohio", "OH"],
"45780": ["The Plains", "Ohio", "OH"],
"45782": ["Trimble", "Ohio", "OH"],
"45783": ["Tuppers Plains", "Ohio", "OH"],
"45784": ["Vincent", "Ohio", "OH"],
"45786": ["Waterford", "Ohio", "OH"],
"45788": ["Whipple", "Ohio", "OH"],
"45789": ["Wingett Run", "Ohio", "OH"],
"45801": ["Lima", "Ohio", "OH"],
"45804": ["Lima", "Ohio", "OH"],
"45805": ["Lima", "Ohio", "OH"],
"45806": ["Lima", "Ohio", "OH"],
"45807": ["Lima", "Ohio", "OH"],
"45808": ["Beaverdam", "Ohio", "OH"],
"45809": ["Gomer", "Ohio", "OH"],
"45810": ["Ada", "Ohio", "OH"],
"45812": ["Alger", "Ohio", "OH"],
"45813": ["Antwerp", "Ohio", "OH"],
"45814": ["Arlington", "Ohio", "OH"],
"45815": ["Belmore", "Ohio", "OH"],
"45816": ["Benton Ridge", "Ohio", "OH"],
"45817": ["Bluffton", "Ohio", "OH"],
"45819": ["Buckland", "Ohio", "OH"],
"45820": ["Cairo", "Ohio", "OH"],
"45821": ["Cecil", "Ohio", "OH"],
"45822": ["Celina", "Ohio", "OH"],
"45826": ["Chickasaw", "Ohio", "OH"],
"45827": ["Cloverdale", "Ohio", "OH"],
"45828": ["Coldwater", "Ohio", "OH"],
"45830": ["Columbus Grove", "Ohio", "OH"],
"45831": ["Continental", "Ohio", "OH"],
"45832": ["Convoy", "Ohio", "OH"],
"45833": ["Delphos", "Ohio", "OH"],
"45835": ["Dola", "Ohio", "OH"],
"45836": ["Dunkirk", "Ohio", "OH"],
"45838": ["Elgin", "Ohio", "OH"],
"45840": ["Findlay", "Ohio", "OH"],
"45841": ["Jenera", "Ohio", "OH"],
"45843": ["Forest", "Ohio", "OH"],
"45844": ["Fort Jennings", "Ohio", "OH"],
"45845": ["Fort Loramie", "Ohio", "OH"],
"45846": ["Fort Recovery", "Ohio", "OH"],
"45848": ["Glandorf", "Ohio", "OH"],
"45849": ["Grover Hill", "Ohio", "OH"],
"45850": ["Harrod", "Ohio", "OH"],
"45851": ["Haviland", "Ohio", "OH"],
"45853": ["Kalida", "Ohio", "OH"],
"45854": ["Lafayette", "Ohio", "OH"],
"45855": ["Latty", "Ohio", "OH"],
"45856": ["Leipsic", "Ohio", "OH"],
"45858": ["McComb", "Ohio", "OH"],
"45859": ["McGuffey", "Ohio", "OH"],
"45860": ["Maria Stein", "Ohio", "OH"],
"45861": ["Melrose", "Ohio", "OH"],
"45862": ["Mendon", "Ohio", "OH"],
"45863": ["Middle Point", "Ohio", "OH"],
"45864": ["Miller City", "Ohio", "OH"],
"45865": ["Minster", "Ohio", "OH"],
"45866": ["Montezuma", "Ohio", "OH"],
"45867": ["Mount Blanchard", "Ohio", "OH"],
"45868": ["Mount Cory", "Ohio", "OH"],
"45869": ["New Bremen", "Ohio", "OH"],
"45870": ["New Hampshire", "NH", "Ohio", "OH"],
"45871": ["New Knoxville", "Ohio", "OH"],
"45872": ["North Baltimore", "Ohio", "OH"],
"45873": ["Oakwood", "Ohio", "OH"],
"45874": ["Ohio City", "Ohio", "OH"],
"45875": ["Ottawa", "Ohio", "OH"],
"45876": ["Ottoville", "Ohio", "OH"],
"45877": ["Pandora", "Ohio", "OH"],
"45879": ["Paulding", "Ohio", "OH"],
"45880": ["Payne", "Ohio", "OH"],
"45881": ["Rawson", "Ohio", "OH"],
"45882": ["Rockford", "Ohio", "OH"],
"45883": ["Saint Henry", "Ohio", "OH"],
"45884": ["Saint Johns", "Ohio", "OH"],
"45885": ["Saint Marys", "Ohio", "OH"],
"45886": ["Scott", "Ohio", "OH"],
"45887": ["Spencerville", "Ohio", "OH"],
"45888": ["Uniopolis", "Ohio", "OH"],
"45889": ["Van Buren", "Ohio", "OH"],
"45890": ["Vanlue", "Ohio", "OH"],
"45891": ["Van Wert", "Ohio", "OH"],
"45893": ["Vaughnsville", "Ohio", "OH"],
"45894": ["Venedocia", "Ohio", "OH"],
"45895": ["Wapakoneta", "Ohio", "OH"],
"45896": ["Waynesfield", "Ohio", "OH"],
"45897": ["Williamstown", "Ohio", "OH"],
"45898": ["Willshire", "Ohio", "OH"],
"45899": ["Wren", "Ohio", "OH"],
"46001": ["Alexandria", "Indiana", "IN"],
"46011": ["Anderson", "Indiana", "IN"],
"46012": ["Anderson", "Indiana", "IN"],
"46013": ["Anderson", "Indiana", "IN"],
"46016": ["Anderson", "Indiana", "IN"],
"46017": ["Anderson", "Indiana", "IN"],
"46030": ["Arcadia", "Indiana", "IN"],
"46031": ["Atlanta", "Indiana", "IN"],
"46032": ["Carmel", "Indiana", "IN"],
"46033": ["Carmel", "Indiana", "IN"],
"46034": ["Cicero", "Indiana", "IN"],
"46035": ["Colfax", "Indiana", "IN"],
"46036": ["Elwood", "Indiana", "IN"],
"46037": ["Fishers", "Indiana", "IN"],
"46038": ["Fishers", "Indiana", "IN"],
"46039": ["Forest", "Indiana", "IN"],
"46040": ["Fortville", "Indiana", "IN"],
"46041": ["Frankfort", "Indiana", "IN"],
"46044": ["Frankton", "Indiana", "IN"],
"46045": ["Goldsmith", "Indiana", "IN"],
"46047": ["Hobbs", "Indiana", "IN"],
"46048": ["Ingalls", "Indiana", "IN"],
"46049": ["Kempton", "Indiana", "IN"],
"46050": ["Kirklin", "Indiana", "IN"],
"46051": ["Lapel", "Indiana", "IN"],
"46052": ["Lebanon", "Indiana", "IN"],
"46055": ["Mccordsville", "Indiana", "IN"],
"46056": ["Markleville", "Indiana", "IN"],
"46057": ["Michigantown", "Indiana", "IN"],
"46058": ["Mulberry", "Indiana", "IN"],
"46060": ["Noblesville", "Indiana", "IN"],
"46062": ["Noblesville", "Indiana", "IN"],
"46063": ["Orestes", "Indiana", "IN"],
"46064": ["Pendleton", "Indiana", "IN"],
"46065": ["Rossville", "Indiana", "IN"],
"46068": ["Sharpsville", "Indiana", "IN"],
"46069": ["Sheridan", "Indiana", "IN"],
"46070": ["Summitville", "Indiana", "IN"],
"46071": ["Thorntown", "Indiana", "IN"],
"46072": ["Tipton", "Indiana", "IN"],
"46074": ["Westfield", "Indiana", "IN"],
"46075": ["Whitestown", "Indiana", "IN"],
"46076": ["Windfall", "Indiana", "IN"],
"46077": ["Zionsville", "Indiana", "IN"],
"46102": ["Advance", "Indiana", "IN"],
"46103": ["Amo", "Indiana", "IN"],
"46104": ["Arlington", "Indiana", "IN"],
"46105": ["Bainbridge", "Indiana", "IN"],
"46106": ["Bargersville", "Indiana", "IN"],
"46107": ["Beech Grove", "Indiana", "IN"],
"46110": ["Boggstown", "Indiana", "IN"],
"46111": ["Brooklyn", "Indiana", "IN"],
"46112": ["Brownsburg", "Indiana", "IN"],
"46113": ["Camby", "Indiana", "IN"],
"46115": ["Carthage", "Indiana", "IN"],
"46117": ["Charlottesville", "Indiana", "IN"],
"46118": ["Clayton", "Indiana", "IN"],
"46120": ["Cloverdale", "Indiana", "IN"],
"46121": ["Coatesville", "Indiana", "IN"],
"46122": ["Danville", "Indiana", "IN"],
"46123": ["Avon", "Indiana", "IN"],
"46124": ["Edinburgh", "Indiana", "IN"],
"46125": ["Eminence", "Indiana", "IN"],
"46126": ["Fairland", "Indiana", "IN"],
"46127": ["Falmouth", "Indiana", "IN"],
"46128": ["Fillmore", "Indiana", "IN"],
"46130": ["Fountaintown", "Indiana", "IN"],
"46131": ["Franklin", "Indiana", "IN"],
"46133": ["Glenwood", "Indiana", "IN"],
"46135": ["Greencastle", "Indiana", "IN"],
"46140": ["Greenfield", "Indiana", "IN"],
"46142": ["Greenwood", "Indiana", "IN"],
"46143": ["Greenwood", "Indiana", "IN"],
"46144": ["Gwynneville", "Indiana", "IN"],
"46146": ["Homer", "Indiana", "IN"],
"46147": ["Jamestown", "Indiana", "IN"],
"46148": ["Knightstown", "Indiana", "IN"],
"46149": ["Lizton", "Indiana", "IN"],
"46150": ["Manilla", "Indiana", "IN"],
"46151": ["Martinsville", "Indiana", "IN"],
"46154": ["Maxwell", "Indiana", "IN"],
"46155": ["Mays", "Indiana", "IN"],
"46156": ["Milroy", "Indiana", "IN"],
"46157": ["Monrovia", "Indiana", "IN"],
"46158": ["Mooresville", "Indiana", "IN"],
"46160": ["Morgantown", "Indiana", "IN"],
"46161": ["Morristown", "Indiana", "IN"],
"46162": ["Needham", "Indiana", "IN"],
"46163": ["New Palestine", "Indiana", "IN"],
"46164": ["Nineveh", "Indiana", "IN"],
"46165": ["North Salem", "Indiana", "IN"],
"46166": ["Paragon", "Indiana", "IN"],
"46167": ["Pittsboro", "Indiana", "IN"],
"46168": ["Plainfield", "Indiana", "IN"],
"46170": ["Putnamville", "Indiana", "IN"],
"46171": ["Reelsville", "Indiana", "IN"],
"46172": ["Roachdale", "Indiana", "IN"],
"46173": ["Rushville", "Indiana", "IN"],
"46175": ["Russellville", "Indiana", "IN"],
"46176": ["Shelbyville", "Indiana", "IN"],
"46180": ["Stilesville", "Indiana", "IN"],
"46181": ["Trafalgar", "Indiana", "IN"],
"46182": ["Waldron", "Indiana", "IN"],
"46183": ["West Newton", "Indiana", "IN"],
"46184": ["Whiteland", "Indiana", "IN"],
"46186": ["Wilkinson", "Indiana", "IN"],
"46201": ["Indianapolis", "Indiana", "IN"],
"46202": ["Indianapolis", "Indiana", "IN"],
"46203": ["Indianapolis", "Indiana", "IN"],
"46204": ["Indianapolis", "Indiana", "IN"],
"46205": ["Indianapolis", "Indiana", "IN"],
"46206": ["Indianapolis", "Indiana", "IN"],
"46208": ["Indianapolis", "Indiana", "IN"],
"46214": ["Indianapolis", "Indiana", "IN"],
"46216": ["Indianapolis", "Indiana", "IN"],
"46217": ["Indianapolis", "Indiana", "IN"],
"46218": ["Indianapolis", "Indiana", "IN"],
"46219": ["Indianapolis", "Indiana", "IN"],
"46220": ["Indianapolis", "Indiana", "IN"],
"46221": ["Indianapolis", "Indiana", "IN"],
"46222": ["Indianapolis", "Indiana", "IN"],
"46224": ["Indianapolis", "Indiana", "IN"],
"46225": ["Indianapolis", "Indiana", "IN"],
"46226": ["Indianapolis", "Indiana", "IN"],
"46227": ["Indianapolis", "Indiana", "IN"],
"46228": ["Indianapolis", "Indiana", "IN"],
"46229": ["Indianapolis", "Indiana", "IN"],
"46231": ["Indianapolis", "Indiana", "IN"],
"46234": ["Indianapolis", "Indiana", "IN"],
"46235": ["Indianapolis", "Indiana", "IN"],
"46236": ["Indianapolis", "Indiana", "IN"],
"46237": ["Indianapolis", "Indiana", "IN"],
"46239": ["Indianapolis", "Indiana", "IN"],
"46240": ["Indianapolis", "Indiana", "IN"],
"46241": ["Indianapolis", "Indiana", "IN"],
"46250": ["Indianapolis", "Indiana", "IN"],
"46254": ["Indianapolis", "Indiana", "IN"],
"46256": ["Indianapolis", "Indiana", "IN"],
"46259": ["Indianapolis", "Indiana", "IN"],
"46260": ["Indianapolis", "Indiana", "IN"],
"46268": ["Indianapolis", "Indiana", "IN"],
"46278": ["Indianapolis", "Indiana", "IN"],
"46280": ["Indianapolis", "Indiana", "IN"],
"46290": ["Indianapolis", "Indiana", "IN"],
"46301": ["Beverly Shores", "Indiana", "IN"],
"46302": ["Boone Grove", "Indiana", "IN"],
"46303": ["Cedar Lake", "Indiana", "IN"],
"46304": ["Chesterton", "Indiana", "IN"],
"46307": ["Crown Point", "Indiana", "IN"],
"46310": ["Demotte", "Indiana", "IN"],
"46311": ["Dyer", "Indiana", "IN"],
"46312": ["East Chicago", "Indiana", "IN"],
"46319": ["Griffith", "Indiana", "IN"],
"46320": ["Hammond", "Indiana", "IN"],
"46321": ["Munster", "Indiana", "IN"],
"46322": ["Highland", "Indiana", "IN"],
"46323": ["Hammond", "Indiana", "IN"],
"46324": ["Hammond", "Indiana", "IN"],
"46327": ["Hammond", "Indiana", "IN"],
"46340": ["Hanna", "Indiana", "IN"],
"46341": ["Hebron", "Indiana", "IN"],
"46342": ["Hobart", "Indiana", "IN"],
"46345": ["Kingsbury", "Indiana", "IN"],
"46346": ["Kingsford Heights", "Indiana", "IN"],
"46347": ["Kouts", "Indiana", "IN"],
"46348": ["La Crosse", "Indiana", "IN"],
"46349": ["Lake Village", "Indiana", "IN"],
"46350": ["La Porte", "Indiana", "IN"],
"46356": ["Lowell", "Indiana", "IN"],
"46360": ["Michigan City", "Indiana", "IN"],
"46365": ["Mill Creek", "Indiana", "IN"],
"46366": ["North Judson", "Indiana", "IN"],
"46368": ["Portage", "Indiana", "IN"],
"46371": ["Rolling Prairie", "Indiana", "IN"],
"46373": ["Saint John", "Indiana", "IN"],
"46374": ["San Pierre", "Indiana", "IN"],
"46375": ["Schererville", "Indiana", "IN"],
"46376": ["Schneider", "Indiana", "IN"],
"46377": ["Shelby", "Indiana", "IN"],
"46379": ["Sumava Resorts", "Indiana", "IN"],
"46381": ["Thayer", "Indiana", "IN"],
"46382": ["Union Mills", "Indiana", "IN"],
"46383": ["Valparaiso", "Indiana", "IN"],
"46385": ["Valparaiso", "Indiana", "IN"],
"46390": ["Wanatah", "Indiana", "IN"],
"46391": ["Westville", "Indiana", "IN"],
"46392": ["Wheatfield", "Indiana", "IN"],
"46393": ["Wheeler", "Indiana", "IN"],
"46394": ["Whiting", "Indiana", "IN"],
"46402": ["Gary", "Indiana", "IN"],
"46403": ["Gary", "Indiana", "IN"],
"46404": ["Gary", "Indiana", "IN"],
"46405": ["Lake Station", "Indiana", "IN"],
"46406": ["Gary", "Indiana", "IN"],
"46407": ["Gary", "Indiana", "IN"],
"46408": ["Gary", "Indiana", "IN"],
"46409": ["Gary", "Indiana", "IN"],
"46410": ["Merrillville", "Indiana", "IN"],
"46501": ["Argos", "Indiana", "IN"],
"46502": ["Atwood", "Indiana", "IN"],
"46504": ["Bourbon", "Indiana", "IN"],
"46506": ["Bremen", "Indiana", "IN"],
"46507": ["Bristol", "Indiana", "IN"],
"46508": ["Burket", "Indiana", "IN"],
"46510": ["Claypool", "Indiana", "IN"],
"46511": ["Culver", "Indiana", "IN"],
"46513": ["Donaldson", "Indiana", "IN"],
"46514": ["Elkhart", "Indiana", "IN"],
"46516": ["Elkhart", "Indiana", "IN"],
"46517": ["Elkhart", "Indiana", "IN"],
"46524": ["Etna Green", "Indiana", "IN"],
"46526": ["Goshen", "Indiana", "IN"],
"46528": ["Goshen", "Indiana", "IN"],
"46530": ["Granger", "Indiana", "IN"],
"46531": ["Grovertown", "Indiana", "IN"],
"46532": ["Hamlet", "Indiana", "IN"],
"46534": ["Knox", "Indiana", "IN"],
"46536": ["Lakeville", "Indiana", "IN"],
"46537": ["Lapaz", "Indiana", "IN"],
"46538": ["Leesburg", "Indiana", "IN"],
"46539": ["Mentone", "Indiana", "IN"],
"46540": ["Middlebury", "Indiana", "IN"],
"46542": ["Milford", "Indiana", "IN"],
"46543": ["Millersburg", "Indiana", "IN"],
"46544": ["Mishawaka", "Indiana", "IN"],
"46545": ["Mishawaka", "Indiana", "IN"],
"46550": ["Nappanee", "Indiana", "IN"],
"46552": ["New Carlisle", "Indiana", "IN"],
"46553": ["New Paris", "Indiana", "IN"],
"46554": ["North Liberty", "Indiana", "IN"],
"46555": ["North Webster", "Indiana", "IN"],
"46556": ["Notre Dame", "Indiana", "IN"],
"46561": ["Osceola", "Indiana", "IN"],
"46562": ["Pierceton", "Indiana", "IN"],
"46563": ["Plymouth", "Indiana", "IN"],
"46565": ["Shipshewana", "Indiana", "IN"],
"46567": ["Syracuse", "Indiana", "IN"],
"46570": ["Tippecanoe", "Indiana", "IN"],
"46571": ["Topeka", "Indiana", "IN"],
"46572": ["Tyner", "Indiana", "IN"],
"46573": ["Wakarusa", "Indiana", "IN"],
"46574": ["Walkerton", "Indiana", "IN"],
"46580": ["Warsaw", "Indiana", "IN"],
"46582": ["Warsaw", "Indiana", "IN"],
"46590": ["Winona Lake", "Indiana", "IN"],
"46595": ["Wyatt", "Indiana", "IN"],
"46601": ["South Bend", "Indiana", "IN"],
"46613": ["South Bend", "Indiana", "IN"],
"46614": ["South Bend", "Indiana", "IN"],
"46615": ["South Bend", "Indiana", "IN"],
"46616": ["South Bend", "Indiana", "IN"],
"46617": ["South Bend", "Indiana", "IN"],
"46619": ["South Bend", "Indiana", "IN"],
"46624": ["South Bend", "Indiana", "IN"],
"46628": ["South Bend", "Indiana", "IN"],
"46635": ["South Bend", "Indiana", "IN"],
"46637": ["South Bend", "Indiana", "IN"],
"46701": ["Albion", "Indiana", "IN"],
"46702": ["Andrews", "Indiana", "IN"],
"46703": ["Angola", "Indiana", "IN"],
"46704": ["Arcola", "Indiana", "IN"],
"46705": ["Ashley", "Indiana", "IN"],
"46706": ["Auburn", "Indiana", "IN"],
"46710": ["Avilla", "Indiana", "IN"],
"46711": ["Berne", "Indiana", "IN"],
"46714": ["Bluffton", "Indiana", "IN"],
"46721": ["Butler", "Indiana", "IN"],
"46723": ["Churubusco", "Indiana", "IN"],
"46725": ["Columbia City", "Indiana", "IN"],
"46730": ["Corunna", "Indiana", "IN"],
"46731": ["Craigville", "Indiana", "IN"],
"46732": ["Cromwell", "Indiana", "IN"],
"46733": ["Decatur", "Indiana", "IN"],
"46737": ["Fremont", "Indiana", "IN"],
"46738": ["Garrett", "Indiana", "IN"],
"46740": ["Geneva", "Indiana", "IN"],
"46741": ["Grabill", "Indiana", "IN"],
"46742": ["Hamilton", "Indiana", "IN"],
"46743": ["Harlan", "Indiana", "IN"],
"46745": ["Hoagland", "Indiana", "IN"],
"46746": ["Howe", "Indiana", "IN"],
"46747": ["Hudson", "Indiana", "IN"],
"46748": ["Huntertown", "Indiana", "IN"],
"46750": ["Huntington", "Indiana", "IN"],
"46755": ["Kendallville", "Indiana", "IN"],
"46759": ["Keystone", "Indiana", "IN"],
"46760": ["Kimmell", "Indiana", "IN"],
"46761": ["Lagrange", "Indiana", "IN"],
"46763": ["Laotto", "Indiana", "IN"],
"46764": ["Larwill", "Indiana", "IN"],
"46765": ["Leo", "Indiana", "IN"],
"46766": ["Liberty Center", "Indiana", "IN"],
"46767": ["Ligonier", "Indiana", "IN"],
"46770": ["Markle", "Indiana", "IN"],
"46771": ["Mongo", "Indiana", "IN"],
"46772": ["Monroe", "Indiana", "IN"],
"46773": ["Monroeville", "Indiana", "IN"],
"46774": ["New Haven", "Indiana", "IN"],
"46776": ["Orland", "Indiana", "IN"],
"46777": ["Ossian", "Indiana", "IN"],
"46778": ["Petroleum", "Indiana", "IN"],
"46779": ["Pleasant Lake", "Indiana", "IN"],
"46781": ["Poneto", "Indiana", "IN"],
"46783": ["Roanoke", "Indiana", "IN"],
"46784": ["Rome City", "Indiana", "IN"],
"46785": ["Saint Joe", "Indiana", "IN"],
"46786": ["South Milford", "Indiana", "IN"],
"46787": ["South Whitley", "Indiana", "IN"],
"46788": ["Spencerville", "Indiana", "IN"],
"46789": ["Stroh", "Indiana", "IN"],
"46791": ["Uniondale", "Indiana", "IN"],
"46792": ["Warren", "Indiana", "IN"],
"46793": ["Waterloo", "Indiana", "IN"],
"46794": ["Wawaka", "Indiana", "IN"],
"46795": ["Wolcottville", "Indiana", "IN"],
"46796": ["Wolflake", "Indiana", "IN"],
"46797": ["Woodburn", "Indiana", "IN"],
"46798": ["Yoder", "Indiana", "IN"],
"46799": ["Zanesville", "Indiana", "IN"],
"46802": ["Fort Wayne", "Indiana", "IN"],
"46803": ["Fort Wayne", "Indiana", "IN"],
"46804": ["Fort Wayne", "Indiana", "IN"],
"46805": ["Fort Wayne", "Indiana", "IN"],
"46806": ["Fort Wayne", "Indiana", "IN"],
"46807": ["Fort Wayne", "Indiana", "IN"],
"46808": ["Fort Wayne", "Indiana", "IN"],
"46809": ["Fort Wayne", "Indiana", "IN"],
"46814": ["Fort Wayne", "Indiana", "IN"],
"46815": ["Fort Wayne", "Indiana", "IN"],
"46816": ["Fort Wayne", "Indiana", "IN"],
"46818": ["Fort Wayne", "Indiana", "IN"],
"46819": ["Fort Wayne", "Indiana", "IN"],
"46825": ["Fort Wayne", "Indiana", "IN"],
"46835": ["Fort Wayne", "Indiana", "IN"],
"46845": ["Fort Wayne", "Indiana", "IN"],
"46901": ["Kokomo", "Indiana", "IN"],
"46902": ["Kokomo", "Indiana", "IN"],
"46910": ["Akron", "Indiana", "IN"],
"46911": ["Amboy", "Indiana", "IN"],
"46913": ["Bringhurst", "Indiana", "IN"],
"46914": ["Bunker Hill", "Indiana", "IN"],
"46915": ["Burlington", "Indiana", "IN"],
"46917": ["Camden", "Indiana", "IN"],
"46919": ["Converse", "Indiana", "IN"],
"46920": ["Cutler", "Indiana", "IN"],
"46922": ["Delong", "Indiana", "IN"],
"46923": ["Delphi", "Indiana", "IN"],
"46926": ["Denver", "Indiana", "IN"],
"46928": ["Fairmount", "Indiana", "IN"],
"46929": ["Flora", "Indiana", "IN"],
"46930": ["Fowlerton", "Indiana", "IN"],
"46931": ["Fulton", "Indiana", "IN"],
"46932": ["Galveston", "Indiana", "IN"],
"46933": ["Gas City", "Indiana", "IN"],
"46936": ["Greentown", "Indiana", "IN"],
"46937": ["Hemlock", "Indiana", "IN"],
"46938": ["Jonesboro", "Indiana", "IN"],
"46939": ["Kewanna", "Indiana", "IN"],
"46940": ["La Fontaine", "Indiana", "IN"],
"46941": ["Lagro", "Indiana", "IN"],
"46943": ["Laketon", "Indiana", "IN"],
"46945": ["Leiters Ford", "Indiana", "IN"],
"46946": ["Liberty Mills", "Indiana", "IN"],
"46947": ["Logansport", "Indiana", "IN"],
"46950": ["Lucerne", "Indiana", "IN"],
"46951": ["Macy", "Indiana", "IN"],
"46952": ["Marion", "Indiana", "IN"],
"46953": ["Marion", "Indiana", "IN"],
"46957": ["Matthews", "Indiana", "IN"],
"46958": ["Mexico", "Indiana", "IN"],
"46959": ["Miami", "Indiana", "IN"],
"46960": ["Monterey", "Indiana", "IN"],
"46961": ["New Waverly", "Indiana", "IN"],
"46962": ["North Manchester", "Indiana", "IN"],
"46965": ["Oakford", "Indiana", "IN"],
"46967": ["Onward", "Indiana", "IN"],
"46968": ["Ora", "Indiana", "IN"],
"46970": ["Peru", "Indiana", "IN"],
"46971": ["Grissom Arb", "Indiana", "IN"],
"46974": ["Roann", "Indiana", "IN"],
"46975": ["Rochester", "Indiana", "IN"],
"46978": ["Royal Center", "Indiana", "IN"],
"46979": ["Russiaville", "Indiana", "IN"],
"46980": ["Servia", "Indiana", "IN"],
"46982": ["Silver Lake", "Indiana", "IN"],
"46984": ["Somerset", "Indiana", "IN"],
"46985": ["Star City", "Indiana", "IN"],
"46986": ["Swayzee", "Indiana", "IN"],
"46987": ["Sweetser", "Indiana", "IN"],
"46988": ["Twelve Mile", "Indiana", "IN"],
"46989": ["Upland", "Indiana", "IN"],
"46990": ["Urbana", "Indiana", "IN"],
"46991": ["Van Buren", "Indiana", "IN"],
"46992": ["Wabash", "Indiana", "IN"],
"46994": ["Walton", "Indiana", "IN"],
"46996": ["Winamac", "Indiana", "IN"],
"46998": ["Young America", "Indiana", "IN"],
"47001": ["Aurora", "Indiana", "IN"],
"47003": ["West College Corner", "Indiana", "IN"],
"47006": ["Batesville", "Indiana", "IN"],
"47010": ["Bath", "Indiana", "IN"],
"47011": ["Bennington", "Indiana", "IN"],
"47012": ["Brookville", "Indiana", "IN"],
"47016": ["Cedar Grove", "Indiana", "IN"],
"47017": ["Cross Plains", "Indiana", "IN"],
"47018": ["Dillsboro", "Indiana", "IN"],
"47020": ["Florence", "Indiana", "IN"],
"47021": ["Friendship", "Indiana", "IN"],
"47022": ["Guilford", "Indiana", "IN"],
"47023": ["Holton", "Indiana", "IN"],
"47024": ["Laurel", "Indiana", "IN"],
"47025": ["Lawrenceburg", "Indiana", "IN"],
"47030": ["Metamora", "Indiana", "IN"],
"47031": ["Milan", "Indiana", "IN"],
"47032": ["Moores Hill", "Indiana", "IN"],
"47033": ["Morris", "Indiana", "IN"],
"47034": ["Napoleon", "Indiana", "IN"],
"47035": ["New Trenton", "Indiana", "IN"],
"47036": ["Oldenburg", "Indiana", "IN"],
"47037": ["Osgood", "Indiana", "IN"],
"47038": ["Patriot", "Indiana", "IN"],
"47040": ["Rising Sun", "Indiana", "IN"],
"47041": ["Sunman", "Indiana", "IN"],
"47042": ["Versailles", "Indiana", "IN"],
"47043": ["Vevay", "Indiana", "IN"],
"47060": ["West Harrison", "Indiana", "IN"],
"47102": ["Austin", "Indiana", "IN"],
"47104": ["Bethlehem", "Indiana", "IN"],
"47106": ["Borden", "Indiana", "IN"],
"47108": ["Campbellsburg", "Indiana", "IN"],
"47110": ["Central", "Indiana", "IN"],
"47111": ["Charlestown", "Indiana", "IN"],
"47112": ["Corydon", "Indiana", "IN"],
"47114": ["Crandall", "Indiana", "IN"],
"47115": ["Depauw", "Indiana", "IN"],
"47116": ["Eckerty", "Indiana", "IN"],
"47117": ["Elizabeth", "Indiana", "IN"],
"47118": ["English", "Indiana", "IN"],
"47119": ["Floyds Knobs", "Indiana", "IN"],
"47120": ["Fredericksburg", "Indiana", "IN"],
"47122": ["Georgetown", "Indiana", "IN"],
"47123": ["Grantsburg", "Indiana", "IN"],
"47124": ["Greenville", "Indiana", "IN"],
"47125": ["Hardinsburg", "Indiana", "IN"],
"47126": ["Henryville", "Indiana", "IN"],
"47129": ["Clarksville", "Indiana", "IN"],
"47130": ["Jeffersonville", "Indiana", "IN"],
"47135": ["Laconia", "Indiana", "IN"],
"47136": ["Lanesville", "Indiana", "IN"],
"47137": ["Leavenworth", "Indiana", "IN"],
"47138": ["Lexington", "Indiana", "IN"],
"47140": ["Marengo", "Indiana", "IN"],
"47141": ["Marysville", "Indiana", "IN"],
"47142": ["Mauckport", "Indiana", "IN"],
"47143": ["Memphis", "Indiana", "IN"],
"47145": ["Milltown", "Indiana", "IN"],
"47147": ["Nabb", "Indiana", "IN"],
"47150": ["New Albany", "Indiana", "IN"],
"47160": ["New Middletown", "Indiana", "IN"],
"47161": ["New Salisbury", "Indiana", "IN"],
"47162": ["New Washington", "Indiana", "IN"],
"47163": ["Otisco", "Indiana", "IN"],
"47164": ["Palmyra", "Indiana", "IN"],
"47165": ["Pekin", "Indiana", "IN"],
"47166": ["Ramsey", "Indiana", "IN"],
"47167": ["Salem", "Indiana", "IN"],
"47170": ["Scottsburg", "Indiana", "IN"],
"47172": ["Sellersburg", "Indiana", "IN"],
"47174": ["Sulphur", "Indiana", "IN"],
"47175": ["Taswell", "Indiana", "IN"],
"47177": ["Underwood", "Indiana", "IN"],
"47201": ["Columbus", "Indiana", "IN"],
"47203": ["Columbus", "Indiana", "IN"],
"47220": ["Brownstown", "Indiana", "IN"],
"47223": ["Butlerville", "Indiana", "IN"],
"47224": ["Canaan", "Indiana", "IN"],
"47225": ["Clarksburg", "Indiana", "IN"],
"47226": ["Clifford", "Indiana", "IN"],
"47227": ["Commiskey", "Indiana", "IN"],
"47229": ["Crothersville", "Indiana", "IN"],
"47230": ["Deputy", "Indiana", "IN"],
"47231": ["Dupont", "Indiana", "IN"],
"47232": ["Elizabethtown", "Indiana", "IN"],
"47234": ["Flat Rock", "Indiana", "IN"],
"47235": ["Freetown", "Indiana", "IN"],
"47236": ["Grammer", "Indiana", "IN"],
"47240": ["Greensburg", "Indiana", "IN"],
"47243": ["Hanover", "Indiana", "IN"],
"47244": ["Hartsville", "Indiana", "IN"],
"47246": ["Hope", "Indiana", "IN"],
"47247": ["Jonesville", "Indiana", "IN"],
"47250": ["Madison", "Indiana", "IN"],
"47260": ["Medora", "Indiana", "IN"],
"47263": ["New Point", "Indiana", "IN"],
"47264": ["Norman", "Indiana", "IN"],
"47265": ["North Vernon", "Indiana", "IN"],
"47270": ["Paris Crossing", "Indiana", "IN"],
"47272": ["Saint Paul", "Indiana", "IN"],
"47273": ["Scipio", "Indiana", "IN"],
"47274": ["Seymour", "Indiana", "IN"],
"47280": ["Taylorsville", "Indiana", "IN"],
"47281": ["Vallonia", "Indiana", "IN"],
"47282": ["Vernon", "Indiana", "IN"],
"47283": ["Westport", "Indiana", "IN"],
"47302": ["Muncie", "Indiana", "IN"],
"47303": ["Muncie", "Indiana", "IN"],
"47304": ["Muncie", "Indiana", "IN"],
"47305": ["Muncie", "Indiana", "IN"],
"47306": ["Muncie", "Indiana", "IN"],
"47320": ["Albany", "Indiana", "IN"],
"47324": ["Boston", "Indiana", "IN"],
"47325": ["Brownsville", "Indiana", "IN"],
"47326": ["Bryant", "Indiana", "IN"],
"47327": ["Cambridge City", "Indiana", "IN"],
"47330": ["Centerville", "Indiana", "IN"],
"47331": ["Connersville", "Indiana", "IN"],
"47334": ["Daleville", "Indiana", "IN"],
"47335": ["Dublin", "Indiana", "IN"],
"47336": ["Dunkirk", "Indiana", "IN"],
"47337": ["Dunreith", "Indiana", "IN"],
"47338": ["Eaton", "Indiana", "IN"],
"47339": ["Economy", "Indiana", "IN"],
"47340": ["Farmland", "Indiana", "IN"],
"47341": ["Fountain City", "Indiana", "IN"],
"47342": ["Gaston", "Indiana", "IN"],
"47344": ["Greensboro", "Indiana", "IN"],
"47345": ["Greens Fork", "Indiana", "IN"],
"47346": ["Hagerstown", "Indiana", "IN"],
"47348": ["Hartford City", "Indiana", "IN"],
"47351": ["Kennard", "Indiana", "IN"],
"47352": ["Lewisville", "Indiana", "IN"],
"47353": ["Liberty", "Indiana", "IN"],
"47354": ["Losantville", "Indiana", "IN"],
"47355": ["Lynn", "Indiana", "IN"],
"47356": ["Middletown", "Indiana", "IN"],
"47357": ["Milton", "Indiana", "IN"],
"47358": ["Modoc", "Indiana", "IN"],
"47359": ["Montpelier", "Indiana", "IN"],
"47360": ["Mooreland", "Indiana", "IN"],
"47361": ["Mount Summit", "Indiana", "IN"],
"47362": ["New Castle", "Indiana", "IN"],
"47366": ["New Lisbon", "Indiana", "IN"],
"47367": ["Oakville", "Indiana", "IN"],
"47368": ["Parker City", "Indiana", "IN"],
"47369": ["Pennville", "Indiana", "IN"],
"47370": ["Pershing", "Indiana", "IN"],
"47371": ["Portland", "Indiana", "IN"],
"47373": ["Redkey", "Indiana", "IN"],
"47374": ["Richmond", "Indiana", "IN"],
"47380": ["Ridgeville", "Indiana", "IN"],
"47381": ["Salamonia", "Indiana", "IN"],
"47382": ["Saratoga", "Indiana", "IN"],
"47383": ["Selma", "Indiana", "IN"],
"47384": ["Shirley", "Indiana", "IN"],
"47385": ["Spiceland", "Indiana", "IN"],
"47386": ["Springport", "Indiana", "IN"],
"47387": ["Straughn", "Indiana", "IN"],
"47388": ["Sulphur Springs", "Indiana", "IN"],
"47390": ["Union City", "Indiana", "IN"],
"47392": ["Webster", "Indiana", "IN"],
"47393": ["Williamsburg", "Indiana", "IN"],
"47394": ["Winchester", "Indiana", "IN"],
"47396": ["Yorktown", "Indiana", "IN"],
"47401": ["Bloomington", "Indiana", "IN"],
"47403": ["Bloomington", "Indiana", "IN"],
"47404": ["Bloomington", "Indiana", "IN"],
"47405": ["Bloomington", "Indiana", "IN"],
"47406": ["Bloomington", "Indiana", "IN"],
"47408": ["Bloomington", "Indiana", "IN"],
"47420": ["Avoca", "Indiana", "IN"],
"47421": ["Bedford", "Indiana", "IN"],
"47424": ["Bloomfield", "Indiana", "IN"],
"47427": ["Coal City", "Indiana", "IN"],
"47429": ["Ellettsville", "Indiana", "IN"],
"47431": ["Freedom", "Indiana", "IN"],
"47432": ["French Lick", "Indiana", "IN"],
"47433": ["Gosport", "Indiana", "IN"],
"47434": ["Harrodsburg", "Indiana", "IN"],
"47435": ["Helmsburg", "Indiana", "IN"],
"47436": ["Heltonville", "Indiana", "IN"],
"47437": ["Huron", "Indiana", "IN"],
"47438": ["Jasonville", "Indiana", "IN"],
"47441": ["Linton", "Indiana", "IN"],
"47443": ["Lyons", "Indiana", "IN"],
"47445": ["Midland", "Indiana", "IN"],
"47446": ["Mitchell", "Indiana", "IN"],
"47448": ["Nashville", "Indiana", "IN"],
"47449": ["Newberry", "Indiana", "IN"],
"47451": ["Oolitic", "Indiana", "IN"],
"47452": ["Orleans", "Indiana", "IN"],
"47453": ["Owensburg", "Indiana", "IN"],
"47454": ["Paoli", "Indiana", "IN"],
"47455": ["Patricksburg", "Indiana", "IN"],
"47456": ["Quincy", "Indiana", "IN"],
"47457": ["Scotland", "Indiana", "IN"],
"47458": ["Smithville", "Indiana", "IN"],
"47459": ["Solsberry", "Indiana", "IN"],
"47460": ["Spencer", "Indiana", "IN"],
"47462": ["Springville", "Indiana", "IN"],
"47464": ["Stinesville", "Indiana", "IN"],
"47465": ["Switz City", "Indiana", "IN"],
"47467": ["Tunnelton", "Indiana", "IN"],
"47468": ["Unionville", "Indiana", "IN"],
"47469": ["West Baden Springs", "Indiana", "IN"],
"47470": ["Williams", "Indiana", "IN"],
"47471": ["Worthington", "Indiana", "IN"],
"47501": ["Washington", "Indiana", "IN"],
"47512": ["Bicknell", "Indiana", "IN"],
"47513": ["Birdseye", "Indiana", "IN"],
"47514": ["Branchville", "Indiana", "IN"],
"47515": ["Bristow", "Indiana", "IN"],
"47516": ["Bruceville", "Indiana", "IN"],
"47519": ["Cannelburg", "Indiana", "IN"],
"47520": ["Cannelton", "Indiana", "IN"],
"47521": ["Celestine", "Indiana", "IN"],
"47522": ["Crane", "Indiana", "IN"],
"47523": ["Dale", "Indiana", "IN"],
"47524": ["Decker", "Indiana", "IN"],
"47525": ["Derby", "Indiana", "IN"],
"47527": ["Dubois", "Indiana", "IN"],
"47528": ["Edwardsport", "Indiana", "IN"],
"47529": ["Elnora", "Indiana", "IN"],
"47531": ["Evanston", "Indiana", "IN"],
"47532": ["Ferdinand", "Indiana", "IN"],
"47535": ["Freelandville", "Indiana", "IN"],
"47537": ["Gentryville", "Indiana", "IN"],
"47541": ["Holland", "Indiana", "IN"],
"47542": ["Huntingburg", "Indiana", "IN"],
"47546": ["Jasper", "Indiana", "IN"],
"47550": ["Lamar", "Indiana", "IN"],
"47551": ["Leopold", "Indiana", "IN"],
"47552": ["Lincoln City", "Indiana", "IN"],
"47553": ["Loogootee", "Indiana", "IN"],
"47557": ["Monroe City", "Indiana", "IN"],
"47558": ["Montgomery", "Indiana", "IN"],
"47561": ["Oaktown", "Indiana", "IN"],
"47562": ["Odon", "Indiana", "IN"],
"47564": ["Otwell", "Indiana", "IN"],
"47567": ["Petersburg", "Indiana", "IN"],
"47568": ["Plainville", "Indiana", "IN"],
"47573": ["Ragsdale", "Indiana", "IN"],
"47574": ["Rome", "Indiana", "IN"],
"47575": ["Saint Anthony", "Indiana", "IN"],
"47576": ["Saint Croix", "Indiana", "IN"],
"47577": ["Saint Meinrad", "Indiana", "IN"],
"47578": ["Sandborn", "Indiana", "IN"],
"47579": ["Santa Claus", "Indiana", "IN"],
"47580": ["Schnellville", "Indiana", "IN"],
"47581": ["Shoals", "Indiana", "IN"],
"47584": ["Spurgeon", "Indiana", "IN"],
"47585": ["Stendal", "Indiana", "IN"],
"47586": ["Tell City", "Indiana", "IN"],
"47588": ["Troy", "Indiana", "IN"],
"47590": ["Velpen", "Indiana", "IN"],
"47591": ["Vincennes", "Indiana", "IN"],
"47596": ["Westphalia", "Indiana", "IN"],
"47597": ["Wheatland", "Indiana", "IN"],
"47598": ["Winslow", "Indiana", "IN"],
"47601": ["Boonville", "Indiana", "IN"],
"47610": ["Chandler", "Indiana", "IN"],
"47611": ["Chrisney", "Indiana", "IN"],
"47612": ["Cynthiana", "Indiana", "IN"],
"47613": ["Elberfeld", "Indiana", "IN"],
"47615": ["Grandview", "Indiana", "IN"],
"47616": ["Griffin", "Indiana", "IN"],
"47619": ["Lynnville", "Indiana", "IN"],
"47620": ["Mount Vernon", "Indiana", "IN"],
"47630": ["Newburgh", "Indiana", "IN"],
"47631": ["New Harmony", "Indiana", "IN"],
"47633": ["Poseyville", "Indiana", "IN"],
"47634": ["Richland", "Indiana", "IN"],
"47635": ["Rockport", "Indiana", "IN"],
"47637": ["Tennyson", "Indiana", "IN"],
"47638": ["Wadesville", "Indiana", "IN"],
"47639": ["Haubstadt", "Indiana", "IN"],
"47640": ["Hazleton", "Indiana", "IN"],
"47648": ["Fort Branch", "Indiana", "IN"],
"47649": ["Francisco", "Indiana", "IN"],
"47654": ["Mackey", "Indiana", "IN"],
"47660": ["Oakland City", "Indiana", "IN"],
"47665": ["Owensville", "Indiana", "IN"],
"47666": ["Patoka", "Indiana", "IN"],
"47670": ["Princeton", "Indiana", "IN"],
"47683": ["Somerville", "Indiana", "IN"],
"47708": ["Evansville", "Indiana", "IN"],
"47710": ["Evansville", "Indiana", "IN"],
"47711": ["Evansville", "Indiana", "IN"],
"47712": ["Evansville", "Indiana", "IN"],
"47713": ["Evansville", "Indiana", "IN"],
"47714": ["Evansville", "Indiana", "IN"],
"47715": ["Evansville", "Indiana", "IN"],
"47720": ["Evansville", "Indiana", "IN"],
"47725": ["Evansville", "Indiana", "IN"],
"47802": ["Terre Haute", "Indiana", "IN"],
"47803": ["Terre Haute", "Indiana", "IN"],
"47804": ["Terre Haute", "Indiana", "IN"],
"47805": ["Terre Haute", "Indiana", "IN"],
"47807": ["Terre Haute", "Indiana", "IN"],
"47809": ["Terre Haute", "Indiana", "IN"],
"47831": ["Blanford", "Indiana", "IN"],
"47832": ["Bloomingdale", "Indiana", "IN"],
"47833": ["Bowling Green", "Indiana", "IN"],
"47834": ["Brazil", "Indiana", "IN"],
"47836": ["Bridgeton", "Indiana", "IN"],
"47837": ["Carbon", "Indiana", "IN"],
"47838": ["Carlisle", "Indiana", "IN"],
"47840": ["Centerpoint", "Indiana", "IN"],
"47841": ["Clay City", "Indiana", "IN"],
"47842": ["Clinton", "Indiana", "IN"],
"47845": ["Coalmont", "Indiana", "IN"],
"47846": ["Cory", "Indiana", "IN"],
"47847": ["Dana", "Indiana", "IN"],
"47848": ["Dugger", "Indiana", "IN"],
"47849": ["Fairbanks", "Indiana", "IN"],
"47850": ["Farmersburg", "Indiana", "IN"],
"47851": ["Fontanet", "Indiana", "IN"],
"47853": ["Harmony", "Indiana", "IN"],
"47854": ["Hillsdale", "Indiana", "IN"],
"47855": ["Hymera", "Indiana", "IN"],
"47857": ["Knightsville", "Indiana", "IN"],
"47858": ["Lewis", "Indiana", "IN"],
"47859": ["Marshall", "Indiana", "IN"],
"47860": ["Mecca", "Indiana", "IN"],
"47861": ["Merom", "Indiana", "IN"],
"47862": ["Montezuma", "Indiana", "IN"],
"47863": ["New Goshen", "Indiana", "IN"],
"47865": ["Paxton", "Indiana", "IN"],
"47866": ["Pimento", "Indiana", "IN"],
"47868": ["Poland", "Indiana", "IN"],
"47869": ["Prairie Creek", "Indiana", "IN"],
"47870": ["Prairieton", "Indiana", "IN"],
"47871": ["Riley", "Indiana", "IN"],
"47872": ["Rockville", "Indiana", "IN"],
"47874": ["Rosedale", "Indiana", "IN"],
"47875": ["Saint Bernice", "Indiana", "IN"],
"47876": ["Saint Mary Of The Woods", "Indiana", "IN"],
"47879": ["Shelburn", "Indiana", "IN"],
"47880": ["Shepardsville", "Indiana", "IN"],
"47881": ["Staunton", "Indiana", "IN"],
"47882": ["Sullivan", "Indiana", "IN"],
"47884": ["Universal", "Indiana", "IN"],
"47885": ["West Terre Haute", "Indiana", "IN"],
"47901": ["Lafayette", "Indiana", "IN"],
"47904": ["Lafayette", "Indiana", "IN"],
"47905": ["Lafayette", "Indiana", "IN"],
"47906": ["West Lafayette", "Indiana", "IN"],
"47907": ["West Lafayette", "Indiana", "IN"],
"47909": ["Lafayette", "Indiana", "IN"],
"47916": ["Alamo", "Indiana", "IN"],
"47917": ["Ambia", "Indiana", "IN"],
"47918": ["Attica", "Indiana", "IN"],
"47920": ["Battle Ground", "Indiana", "IN"],
"47921": ["Boswell", "Indiana", "IN"],
"47922": ["Brook", "Indiana", "IN"],
"47923": ["Brookston", "Indiana", "IN"],
"47924": ["Buck Creek", "Indiana", "IN"],
"47925": ["Buffalo", "Indiana", "IN"],
"47926": ["Burnettsville", "Indiana", "IN"],
"47928": ["Cayuga", "Indiana", "IN"],
"47929": ["Chalmers", "Indiana", "IN"],
"47930": ["Clarks Hill", "Indiana", "IN"],
"47932": ["Covington", "Indiana", "IN"],
"47933": ["Crawfordsville", "Indiana", "IN"],
"47940": ["Darlington", "Indiana", "IN"],
"47941": ["Dayton", "Indiana", "IN"],
"47942": ["Earl Park", "Indiana", "IN"],
"47943": ["Fair Oaks", "Indiana", "IN"],
"47944": ["Fowler", "Indiana", "IN"],
"47946": ["Francesville", "Indiana", "IN"],
"47948": ["Goodland", "Indiana", "IN"],
"47949": ["Hillsboro", "Indiana", "IN"],
"47950": ["Idaville", "Indiana", "IN"],
"47951": ["Kentland", "Indiana", "IN"],
"47952": ["Kingman", "Indiana", "IN"],
"47954": ["Ladoga", "Indiana", "IN"],
"47955": ["Linden", "Indiana", "IN"],
"47957": ["Medaryville", "Indiana", "IN"],
"47958": ["Mellott", "Indiana", "IN"],
"47959": ["Monon", "Indiana", "IN"],
"47960": ["Monticello", "Indiana", "IN"],
"47963": ["Morocco", "Indiana", "IN"],
"47964": ["Mount Ayr", "Indiana", "IN"],
"47965": ["New Market", "Indiana", "IN"],
"47966": ["Newport", "Indiana", "IN"],
"47967": ["New Richmond", "Indiana", "IN"],
"47968": ["New Ross", "Indiana", "IN"],
"47969": ["Newtown", "Indiana", "IN"],
"47970": ["Otterbein", "Indiana", "IN"],
"47971": ["Oxford", "Indiana", "IN"],
"47974": ["Perrysville", "Indiana", "IN"],
"47975": ["Pine Village", "Indiana", "IN"],
"47977": ["Remington", "Indiana", "IN"],
"47978": ["Rensselaer", "Indiana", "IN"],
"47980": ["Reynolds", "Indiana", "IN"],
"47981": ["Romney", "Indiana", "IN"],
"47982": ["State Line", "Indiana", "IN"],
"47983": ["Stockwell", "Indiana", "IN"],
"47986": ["Templeton", "Indiana", "IN"],
"47987": ["Veedersburg", "Indiana", "IN"],
"47989": ["Waveland", "Indiana", "IN"],
"47990": ["Waynetown", "Indiana", "IN"],
"47991": ["West Lebanon", "Indiana", "IN"],
"47992": ["Westpoint", "Indiana", "IN"],
"47993": ["Williamsport", "Indiana", "IN"],
"47994": ["Wingate", "Indiana", "IN"],
"47995": ["Wolcott", "Indiana", "IN"],
"47997": ["Yeoman", "Indiana", "IN"],
"48001": ["Algonac", "Michigan", "MI"],
"48002": ["Allenton", "Michigan", "MI"],
"48003": ["Almont", "Michigan", "MI"],
"48005": ["Armada", "Michigan", "MI"],
"48006": ["Avoca", "Michigan", "MI"],
"48009": ["Birmingham", "Michigan", "MI"],
"48014": ["Capac", "Michigan", "MI"],
"48015": ["Center Line", "Michigan", "MI"],
"48017": ["Clawson", "Michigan", "MI"],
"48021": ["Eastpointe", "Michigan", "MI"],
"48022": ["Emmett", "Michigan", "MI"],
"48023": ["Fair Haven", "Michigan", "MI"],
"48025": ["Franklin", "Michigan", "MI"],
"48026": ["Fraser", "Michigan", "MI"],
"48027": ["Goodells", "Michigan", "MI"],
"48028": ["Harsens Island", "Michigan", "MI"],
"48030": ["Hazel Park", "Michigan", "MI"],
"48032": ["Jeddo", "Michigan", "MI"],
"48033": ["Southfield", "Michigan", "MI"],
"48034": ["Southfield", "Michigan", "MI"],
"48035": ["Clinton Township", "Michigan", "MI"],
"48036": ["Clinton Township", "Michigan", "MI"],
"48038": ["Clinton Township", "Michigan", "MI"],
"48039": ["Marine City", "Michigan", "MI"],
"48040": ["Marysville", "Michigan", "MI"],
"48041": ["Memphis", "Michigan", "MI"],
"48042": ["Macomb", "Michigan", "MI"],
"48043": ["Mount Clemens", "Michigan", "MI"],
"48044": ["Macomb", "Michigan", "MI"],
"48045": ["Harrison Township", "Michigan", "MI"],
"48047": ["New Baltimore", "Michigan", "MI"],
"48048": ["New Haven", "Michigan", "MI"],
"48049": ["North Street", "Michigan", "MI"],
"48050": ["New Haven", "Michigan", "MI"],
"48051": ["New Baltimore", "Michigan", "MI"],
"48054": ["East China", "Michigan", "MI"],
"48059": ["Fort Gratiot", "Michigan", "MI"],
"48060": ["Port Huron", "Michigan", "MI"],
"48062": ["Richmond", "Michigan", "MI"],
"48063": ["Columbus", "Michigan", "MI"],
"48064": ["Casco", "Michigan", "MI"],
"48065": ["Romeo", "Michigan", "MI"],
"48066": ["Roseville", "Michigan", "MI"],
"48067": ["Royal Oak", "Michigan", "MI"],
"48069": ["Pleasant Ridge", "Michigan", "MI"],
"48070": ["Huntington Woods", "Michigan", "MI"],
"48071": ["Madison Heights", "Michigan", "MI"],
"48072": ["Berkley", "Michigan", "MI"],
"48073": ["Royal Oak", "Michigan", "MI"],
"48074": ["Smiths Creek", "Michigan", "MI"],
"48075": ["Southfield", "Michigan", "MI"],
"48076": ["Southfield", "Michigan", "MI"],
"48079": ["Saint Clair", "Michigan", "MI"],
"48080": ["Saint Clair Shores", "Michigan", "MI"],
"48081": ["Saint Clair Shores", "Michigan", "MI"],
"48082": ["Saint Clair Shores", "Michigan", "MI"],
"48083": ["Troy", "Michigan", "MI"],
"48084": ["Troy", "Michigan", "MI"],
"48085": ["Troy", "Michigan", "MI"],
"48088": ["Warren", "Michigan", "MI"],
"48089": ["Warren", "Michigan", "MI"],
"48091": ["Warren", "Michigan", "MI"],
"48092": ["Warren", "Michigan", "MI"],
"48093": ["Warren", "Michigan", "MI"],
"48094": ["Washington", "Michigan", "MI"],
"48095": ["Washington", "Michigan", "MI"],
"48096": ["Ray", "Michigan", "MI"],
"48097": ["Yale", "Michigan", "MI"],
"48098": ["Troy", "Michigan", "MI"],
"48101": ["Allen Park", "Michigan", "MI"],
"48103": ["Ann Arbor", "Michigan", "MI"],
"48104": ["Ann Arbor", "Michigan", "MI"],
"48105": ["Ann Arbor", "Michigan", "MI"],
"48108": ["Ann Arbor", "Michigan", "MI"],
"48109": ["Ann Arbor", "Michigan", "MI"],
"48111": ["Belleville", "Michigan", "MI"],
"48114": ["Brighton", "Michigan", "MI"],
"48116": ["Brighton", "Michigan", "MI"],
"48117": ["Carleton", "Michigan", "MI"],
"48118": ["Chelsea", "Michigan", "MI"],
"48120": ["Dearborn", "Michigan", "MI"],
"48122": ["Melvindale", "Michigan", "MI"],
"48124": ["Dearborn", "Michigan", "MI"],
"48125": ["Dearborn Heights", "Michigan", "MI"],
"48126": ["Dearborn", "Michigan", "MI"],
"48127": ["Dearborn Heights", "Michigan", "MI"],
"48128": ["Dearborn", "Michigan", "MI"],
"48130": ["Dexter", "Michigan", "MI"],
"48131": ["Dundee", "Michigan", "MI"],
"48133": ["Erie", "Michigan", "MI"],
"48134": ["Flat Rock", "Michigan", "MI"],
"48135": ["Garden City", "Michigan", "MI"],
"48137": ["Gregory", "Michigan", "MI"],
"48138": ["Grosse Ile", "Michigan", "MI"],
"48139": ["Hamburg", "Michigan", "MI"],
"48140": ["Ida", "Michigan", "MI"],
"48141": ["Inkster", "Michigan", "MI"],
"48143": ["Lakeland", "Michigan", "MI"],
"48144": ["Lambertville", "Michigan", "MI"],
"48145": ["La Salle", "Michigan", "MI"],
"48146": ["Lincoln Park", "Michigan", "MI"],
"48150": ["Livonia", "Michigan", "MI"],
"48152": ["Livonia", "Michigan", "MI"],
"48154": ["Livonia", "Michigan", "MI"],
"48157": ["Luna Pier", "Michigan", "MI"],
"48158": ["Manchester", "Michigan", "MI"],
"48159": ["Maybee", "Michigan", "MI"],
"48160": ["Milan", "Michigan", "MI"],
"48161": ["Monroe", "Michigan", "MI"],
"48162": ["Monroe", "Michigan", "MI"],
"48164": ["New Boston", "Michigan", "MI"],
"48165": ["New Hudson", "Michigan", "MI"],
"48166": ["Newport", "Michigan", "MI"],
"48167": ["Northville", "Michigan", "MI"],
"48168": ["Northville", "Michigan", "MI"],
"48169": ["Pinckney", "Michigan", "MI"],
"48170": ["Plymouth", "Michigan", "MI"],
"48173": ["Rockwood", "Michigan", "MI"],
"48174": ["Romulus", "Michigan", "MI"],
"48176": ["Saline", "Michigan", "MI"],
"48177": ["Samaria", "Michigan", "MI"],
"48178": ["South Lyon", "Michigan", "MI"],
"48179": ["South Rockwood", "Michigan", "MI"],
"48180": ["Taylor", "Michigan", "MI"],
"48182": ["Temperance", "Michigan", "MI"],
"48183": ["Trenton", "Michigan", "MI"],
"48184": ["Wayne", "Michigan", "MI"],
"48185": ["Westland", "Michigan", "MI"],
"48186": ["Westland", "Michigan", "MI"],
"48187": ["Canton", "Michigan", "MI"],
"48188": ["Canton", "Michigan", "MI"],
"48189": ["Whitmore Lake", "Michigan", "MI"],
"48190": ["Whittaker", "Michigan", "MI"],
"48191": ["Willis", "Michigan", "MI"],
"48192": ["Wyandotte", "Michigan", "MI"],
"48193": ["Riverview", "Michigan", "MI"],
"48195": ["Southgate", "Michigan", "MI"],
"48197": ["Ypsilanti", "Michigan", "MI"],
"48198": ["Ypsilanti", "Michigan", "MI"],
"48201": ["Detroit", "Michigan", "MI"],
"48202": ["Detroit", "Michigan", "MI"],
"48203": ["Highland Park", "Michigan", "MI"],
"48204": ["Detroit", "Michigan", "MI"],
"48205": ["Detroit", "Michigan", "MI"],
"48206": ["Detroit", "Michigan", "MI"],
"48207": ["Detroit", "Michigan", "MI"],
"48208": ["Detroit", "Michigan", "MI"],
"48209": ["Detroit", "Michigan", "MI"],
"48210": ["Detroit", "Michigan", "MI"],
"48211": ["Detroit", "Michigan", "MI"],
"48212": ["Hamtramck", "Michigan", "MI"],
"48213": ["Detroit", "Michigan", "MI"],
"48214": ["Detroit", "Michigan", "MI"],
"48215": ["Detroit", "Michigan", "MI"],
"48216": ["Detroit", "Michigan", "MI"],
"48217": ["Detroit", "Michigan", "MI"],
"48218": ["River Rouge", "Michigan", "MI"],
"48219": ["Detroit", "Michigan", "MI"],
"48220": ["Ferndale", "Michigan", "MI"],
"48221": ["Detroit", "Michigan", "MI"],
"48223": ["Detroit", "Michigan", "MI"],
"48224": ["Detroit", "Michigan", "MI"],
"48225": ["Harper Woods", "Michigan", "MI"],
"48226": ["Detroit", "Michigan", "MI"],
"48227": ["Detroit", "Michigan", "MI"],
"48228": ["Detroit", "Michigan", "MI"],
"48229": ["Ecorse", "Michigan", "MI"],
"48230": ["Grosse Pointe", "Michigan", "MI"],
"48233": ["Detroit", "Michigan", "MI"],
"48234": ["Detroit", "Michigan", "MI"],
"48235": ["Detroit", "Michigan", "MI"],
"48236": ["Grosse Pointe", "Michigan", "MI"],
"48237": ["Oak Park", "Michigan", "MI"],
"48238": ["Detroit", "Michigan", "MI"],
"48239": ["Redford", "Michigan", "MI"],
"48240": ["Redford", "Michigan", "MI"],
"48242": ["Detroit", "Michigan", "MI"],
"48243": ["Detroit", "Michigan", "MI"],
"48301": ["Bloomfield Hills", "Michigan", "MI"],
"48302": ["Bloomfield Hills", "Michigan", "MI"],
"48304": ["Bloomfield Hills", "Michigan", "MI"],
"48306": ["Rochester", "Michigan", "MI"],
"48307": ["Rochester", "Michigan", "MI"],
"48309": ["Rochester", "Michigan", "MI"],
"48310": ["Sterling Heights", "Michigan", "MI"],
"48312": ["Sterling Heights", "Michigan", "MI"],
"48313": ["Sterling Heights", "Michigan", "MI"],
"48314": ["Sterling Heights", "Michigan", "MI"],
"48315": ["Utica", "Michigan", "MI"],
"48316": ["Utica", "Michigan", "MI"],
"48317": ["Utica", "Michigan", "MI"],
"48320": ["Keego Harbor", "Michigan", "MI"],
"48322": ["West Bloomfield", "Michigan", "MI"],
"48323": ["West Bloomfield", "Michigan", "MI"],
"48324": ["West Bloomfield", "Michigan", "MI"],
"48326": ["Auburn Hills", "Michigan", "MI"],
"48327": ["Waterford", "Michigan", "MI"],
"48328": ["Waterford", "Michigan", "MI"],
"48329": ["Waterford", "Michigan", "MI"],
"48331": ["Farmington", "Michigan", "MI"],
"48334": ["Farmington", "Michigan", "MI"],
"48335": ["Farmington", "Michigan", "MI"],
"48336": ["Farmington", "Michigan", "MI"],
"48340": ["Pontiac", "Michigan", "MI"],
"48341": ["Pontiac", "Michigan", "MI"],
"48342": ["Pontiac", "Michigan", "MI"],
"48346": ["Clarkston", "Michigan", "MI"],
"48348": ["Clarkston", "Michigan", "MI"],
"48350": ["Davisburg", "Michigan", "MI"],
"48353": ["Hartland", "Michigan", "MI"],
"48356": ["Highland", "Michigan", "MI"],
"48357": ["Highland", "Michigan", "MI"],
"48359": ["Lake Orion", "Michigan", "MI"],
"48360": ["Lake Orion", "Michigan", "MI"],
"48362": ["Lake Orion", "Michigan", "MI"],
"48363": ["Oakland", "Michigan", "MI"],
"48367": ["Leonard", "Michigan", "MI"],
"48370": ["Oxford", "Michigan", "MI"],
"48371": ["Oxford", "Michigan", "MI"],
"48374": ["Novi", "Michigan", "MI"],
"48375": ["Novi", "Michigan", "MI"],
"48377": ["Novi", "Michigan", "MI"],
"48380": ["Milford", "Michigan", "MI"],
"48381": ["Milford", "Michigan", "MI"],
"48382": ["Commerce Township", "Michigan", "MI"],
"48383": ["White Lake", "Michigan", "MI"],
"48386": ["White Lake", "Michigan", "MI"],
"48390": ["Walled Lake", "Michigan", "MI"],
"48393": ["Wixom", "Michigan", "MI"],
"48401": ["Applegate", "Michigan", "MI"],
"48410": ["Argyle", "Michigan", "MI"],
"48411": ["Atlas", "Michigan", "MI"],
"48412": ["Attica", "Michigan", "MI"],
"48413": ["Bad Axe", "Michigan", "MI"],
"48414": ["Bancroft", "Michigan", "MI"],
"48415": ["Birch Run", "Michigan", "MI"],
"48416": ["Brown City", "Michigan", "MI"],
"48417": ["Burt", "Michigan", "MI"],
"48418": ["Byron", "Michigan", "MI"],
"48419": ["Carsonville", "Michigan", "MI"],
"48420": ["Clio", "Michigan", "MI"],
"48421": ["Columbiaville", "Michigan", "MI"],
"48422": ["Croswell", "Michigan", "MI"],
"48423": ["Davison", "Michigan", "MI"],
"48426": ["Decker", "Michigan", "MI"],
"48427": ["Deckerville", "Michigan", "MI"],
"48428": ["Dryden", "Michigan", "MI"],
"48429": ["Durand", "Michigan", "MI"],
"48430": ["Fenton", "Michigan", "MI"],
"48432": ["Filion", "Michigan", "MI"],
"48433": ["Flushing", "Michigan", "MI"],
"48434": ["Forestville", "Michigan", "MI"],
"48435": ["Fostoria", "Michigan", "MI"],
"48436": ["Gaines", "Michigan", "MI"],
"48437": ["Genesee", "Michigan", "MI"],
"48438": ["Goodrich", "Michigan", "MI"],
"48439": ["Grand Blanc", "Michigan", "MI"],
"48440": ["Hadley", "Michigan", "MI"],
"48441": ["Harbor Beach", "Michigan", "MI"],
"48442": ["Holly", "Michigan", "MI"],
"48444": ["Imlay City", "Michigan", "MI"],
"48445": ["Kinde", "Michigan", "MI"],
"48446": ["Lapeer", "Michigan", "MI"],
"48449": ["Lennon", "Michigan", "MI"],
"48450": ["Lexington", "Michigan", "MI"],
"48451": ["Linden", "Michigan", "MI"],
"48453": ["Marlette", "Michigan", "MI"],
"48454": ["Melvin", "Michigan", "MI"],
"48455": ["Metamora", "Michigan", "MI"],
"48456": ["Minden City", "Michigan", "MI"],
"48457": ["Montrose", "Michigan", "MI"],
"48458": ["Mount Morris", "Michigan", "MI"],
"48460": ["New Lothrop", "Michigan", "MI"],
"48461": ["North Branch", "Michigan", "MI"],
"48462": ["Ortonville", "Michigan", "MI"],
"48463": ["Otisville", "Michigan", "MI"],
"48464": ["Otter Lake", "Michigan", "MI"],
"48465": ["Palms", "Michigan", "MI"],
"48466": ["Peck", "Michigan", "MI"],
"48467": ["Port Austin", "Michigan", "MI"],
"48468": ["Port Hope", "Michigan", "MI"],
"48469": ["Port Sanilac", "Michigan", "MI"],
"48470": ["Ruth", "Michigan", "MI"],
"48471": ["Sandusky", "Michigan", "MI"],
"48472": ["Snover", "Michigan", "MI"],
"48473": ["Swartz Creek", "Michigan", "MI"],
"48475": ["Ubly", "Michigan", "MI"],
"48476": ["Vernon", "Michigan", "MI"],
"48502": ["Flint", "Michigan", "MI"],
"48503": ["Flint", "Michigan", "MI"],
"48504": ["Flint", "Michigan", "MI"],
"48505": ["Flint", "Michigan", "MI"],
"48506": ["Flint", "Michigan", "MI"],
"48507": ["Flint", "Michigan", "MI"],
"48509": ["Burton", "Michigan", "MI"],
"48519": ["Burton", "Michigan", "MI"],
"48529": ["Burton", "Michigan", "MI"],
"48532": ["Flint", "Michigan", "MI"],
"48601": ["Saginaw", "Michigan", "MI"],
"48602": ["Saginaw", "Michigan", "MI"],
"48603": ["Saginaw", "Michigan", "MI"],
"48604": ["Saginaw", "Michigan", "MI"],
"48607": ["Saginaw", "Michigan", "MI"],
"48609": ["Saginaw", "Michigan", "MI"],
"48610": ["Alger", "Michigan", "MI"],
"48611": ["Auburn", "Michigan", "MI"],
"48612": ["Beaverton", "Michigan", "MI"],
"48613": ["Bentley", "Michigan", "MI"],
"48614": ["Brant", "Michigan", "MI"],
"48615": ["Breckenridge", "Michigan", "MI"],
"48616": ["Chesaning", "Michigan", "MI"],
"48617": ["Clare", "Michigan", "MI"],
"48618": ["Coleman", "Michigan", "MI"],
"48619": ["Comins", "Michigan", "MI"],
"48620": ["Edenville", "Michigan", "MI"],
"48621": ["Fairview", "Michigan", "MI"],
"48622": ["Farwell", "Michigan", "MI"],
"48623": ["Freeland", "Michigan", "MI"],
"48624": ["Gladwin", "Michigan", "MI"],
"48625": ["Harrison", "Michigan", "MI"],
"48626": ["Hemlock", "Michigan", "MI"],
"48627": ["Higgins Lake", "Michigan", "MI"],
"48628": ["Hope", "Michigan", "MI"],
"48629": ["Houghton Lake", "Michigan", "MI"],
"48630": ["Houghton Lake Heights", "Michigan", "MI"],
"48631": ["Kawkawlin", "Michigan", "MI"],
"48632": ["Lake", "Michigan", "MI"],
"48633": ["Lake George", "Michigan", "MI"],
"48634": ["Linwood", "Michigan", "MI"],
"48635": ["Lupton", "Michigan", "MI"],
"48636": ["Luzerne", "Michigan", "MI"],
"48637": ["Merrill", "Michigan", "MI"],
"48638": ["Saginaw", "Michigan", "MI"],
"48640": ["Midland", "Michigan", "MI"],
"48642": ["Midland", "Michigan", "MI"],
"48647": ["Mio", "Michigan", "MI"],
"48649": ["Oakley", "Michigan", "MI"],
"48650": ["Pinconning", "Michigan", "MI"],
"48651": ["Prudenville", "Michigan", "MI"],
"48652": ["Rhodes", "Michigan", "MI"],
"48653": ["Roscommon", "Michigan", "MI"],
"48654": ["Rose City", "Michigan", "MI"],
"48655": ["Saint Charles", "Michigan", "MI"],
"48656": ["Saint Helen", "Michigan", "MI"],
"48657": ["Sanford", "Michigan", "MI"],
"48658": ["Standish", "Michigan", "MI"],
"48659": ["Sterling", "Michigan", "MI"],
"48661": ["West Branch", "Michigan", "MI"],
"48662": ["Wheeler", "Michigan", "MI"],
"48701": ["Akron", "Michigan", "MI"],
"48703": ["Au Gres", "Michigan", "MI"],
"48705": ["Barton City", "Michigan", "MI"],
"48706": ["Bay City", "Michigan", "MI"],
"48708": ["Bay City", "Michigan", "MI"],
"48710": ["University Center", "Michigan", "MI"],
"48720": ["Bay Port", "Michigan", "MI"],
"48721": ["Black River", "Michigan", "MI"],
"48722": ["Bridgeport", "Michigan", "MI"],
"48723": ["Caro", "Michigan", "MI"],
"48724": ["Carrollton", "Michigan", "MI"],
"48725": ["Caseville", "Michigan", "MI"],
"48726": ["Cass City", "Michigan", "MI"],
"48727": ["Clifford", "Michigan", "MI"],
"48728": ["Curran", "Michigan", "MI"],
"48729": ["Deford", "Michigan", "MI"],
"48730": ["East Tawas", "Michigan", "MI"],
"48731": ["Elkton", "Michigan", "MI"],
"48732": ["Essexville", "Michigan", "MI"],
"48733": ["Fairgrove", "Michigan", "MI"],
"48734": ["Frankenmuth", "Michigan", "MI"],
"48735": ["Gagetown", "Michigan", "MI"],
"48737": ["Glennie", "Michigan", "MI"],
"48738": ["Greenbush", "Michigan", "MI"],
"48739": ["Hale", "Michigan", "MI"],
"48740": ["Harrisville", "Michigan", "MI"],
"48741": ["Kingston", "Michigan", "MI"],
"48742": ["Lincoln", "Michigan", "MI"],
"48743": ["Long Lake", "Michigan", "MI"],
"48744": ["Mayville", "Michigan", "MI"],
"48745": ["Mikado", "Michigan", "MI"],
"48746": ["Millington", "Michigan", "MI"],
"48747": ["Munger", "Michigan", "MI"],
"48748": ["National City", "Michigan", "MI"],
"48749": ["Omer", "Michigan", "MI"],
"48750": ["Oscoda", "Michigan", "MI"],
"48754": ["Owendale", "Michigan", "MI"],
"48755": ["Pigeon", "Michigan", "MI"],
"48756": ["Prescott", "Michigan", "MI"],
"48757": ["Reese", "Michigan", "MI"],
"48759": ["Sebewaing", "Michigan", "MI"],
"48760": ["Silverwood", "Michigan", "MI"],
"48761": ["South Branch", "Michigan", "MI"],
"48762": ["Spruce", "Michigan", "MI"],
"48763": ["Tawas City", "Michigan", "MI"],
"48765": ["Turner", "Michigan", "MI"],
"48766": ["Twining", "Michigan", "MI"],
"48767": ["Unionville", "Michigan", "MI"],
"48768": ["Vassar", "Michigan", "MI"],
"48770": ["Whittemore", "Michigan", "MI"],
"48801": ["Alma", "Michigan", "MI"],
"48806": ["Ashley", "Michigan", "MI"],
"48807": ["Bannister", "Michigan", "MI"],
"48808": ["Bath", "Michigan", "MI"],
"48809": ["Belding", "Michigan", "MI"],
"48811": ["Carson City", "Michigan", "MI"],
"48813": ["Charlotte", "Michigan", "MI"],
"48815": ["Clarksville", "Michigan", "MI"],
"48816": ["Cohoctah", "Michigan", "MI"],
"48817": ["Corunna", "Michigan", "MI"],
"48818": ["Crystal", "Michigan", "MI"],
"48819": ["Dansville", "Michigan", "MI"],
"48820": ["Dewitt", "Michigan", "MI"],
"48821": ["Dimondale", "Michigan", "MI"],
"48822": ["Eagle", "Michigan", "MI"],
"48823": ["East Lansing", "Michigan", "MI"],
"48824": ["East Lansing", "Michigan", "MI"],
"48825": ["East Lansing", "Michigan", "MI"],
"48827": ["Eaton Rapids", "Michigan", "MI"],
"48829": ["Edmore", "Michigan", "MI"],
"48831": ["Elsie", "Michigan", "MI"],
"48832": ["Elwell", "Michigan", "MI"],
"48834": ["Fenwick", "Michigan", "MI"],
"48835": ["Fowler", "Michigan", "MI"],
"48836": ["Fowlerville", "Michigan", "MI"],
"48837": ["Grand Ledge", "Michigan", "MI"],
"48838": ["Greenville", "Michigan", "MI"],
"48840": ["Haslett", "Michigan", "MI"],
"48841": ["Henderson", "Michigan", "MI"],
"48842": ["Holt", "Michigan", "MI"],
"48843": ["Howell", "Michigan", "MI"],
"48845": ["Hubbardston", "Michigan", "MI"],
"48846": ["Ionia", "Michigan", "MI"],
"48847": ["Ithaca", "Michigan", "MI"],
"48848": ["Laingsburg", "Michigan", "MI"],
"48849": ["Lake Odessa", "Michigan", "MI"],
"48850": ["Lakeview", "Michigan", "MI"],
"48851": ["Lyons", "Michigan", "MI"],
"48852": ["Mcbrides", "Michigan", "MI"],
"48853": ["Maple Rapids", "Michigan", "MI"],
"48854": ["Mason", "Michigan", "MI"],
"48855": ["Howell", "Michigan", "MI"],
"48856": ["Middleton", "Michigan", "MI"],
"48857": ["Morrice", "Michigan", "MI"],
"48858": ["Mount Pleasant", "Michigan", "MI"],
"48859": ["Mount Pleasant", "Michigan", "MI"],
"48860": ["Muir", "Michigan", "MI"],
"48861": ["Mulliken", "Michigan", "MI"],
"48862": ["North Star", "Michigan", "MI"],
"48864": ["Okemos", "Michigan", "MI"],
"48865": ["Orleans", "Michigan", "MI"],
"48866": ["Ovid", "Michigan", "MI"],
"48867": ["Owosso", "Michigan", "MI"],
"48870": ["Palo", "Michigan", "MI"],
"48871": ["Perrinton", "Michigan", "MI"],
"48872": ["Perry", "Michigan", "MI"],
"48873": ["Pewamo", "Michigan", "MI"],
"48874": ["Pompeii", "Michigan", "MI"],
"48875": ["Portland", "Michigan", "MI"],
"48876": ["Potterville", "Michigan", "MI"],
"48877": ["Riverdale", "Michigan", "MI"],
"48878": ["Rosebush", "Michigan", "MI"],
"48879": ["Saint Johns", "Michigan", "MI"],
"48880": ["Saint Louis", "Michigan", "MI"],
"48881": ["Saranac", "Michigan", "MI"],
"48883": ["Shepherd", "Michigan", "MI"],
"48884": ["Sheridan", "Michigan", "MI"],
"48885": ["Sidney", "Michigan", "MI"],
"48886": ["Six Lakes", "Michigan", "MI"],
"48888": ["Stanton", "Michigan", "MI"],
"48889": ["Sumner", "Michigan", "MI"],
"48890": ["Sunfield", "Michigan", "MI"],
"48891": ["Vestaburg", "Michigan", "MI"],
"48892": ["Webberville", "Michigan", "MI"],
"48893": ["Weidman", "Michigan", "MI"],
"48894": ["Westphalia", "Michigan", "MI"],
"48895": ["Williamston", "Michigan", "MI"],
"48896": ["Winn", "Michigan", "MI"],
"48897": ["Woodland", "Michigan", "MI"],
"48906": ["Lansing", "Michigan", "MI"],
"48910": ["Lansing", "Michigan", "MI"],
"48911": ["Lansing", "Michigan", "MI"],
"48912": ["Lansing", "Michigan", "MI"],
"48915": ["Lansing", "Michigan", "MI"],
"48917": ["Lansing", "Michigan", "MI"],
"48933": ["Lansing", "Michigan", "MI"],
"49001": ["Kalamazoo", "Michigan", "MI"],
"49002": ["Portage", "Michigan", "MI"],
"49004": ["Kalamazoo", "Michigan", "MI"],
"49006": ["Kalamazoo", "Michigan", "MI"],
"49007": ["Kalamazoo", "Michigan", "MI"],
"49008": ["Kalamazoo", "Michigan", "MI"],
"49009": ["Kalamazoo", "Michigan", "MI"],
"49010": ["Allegan", "Michigan", "MI"],
"49011": ["Athens", "Michigan", "MI"],
"49012": ["Augusta", "Michigan", "MI"],
"49013": ["Bangor", "Michigan", "MI"],
"49014": ["Battle Creek", "Michigan", "MI"],
"49015": ["Battle Creek", "Michigan", "MI"],
"49017": ["Battle Creek", "Michigan", "MI"],
"49021": ["Bellevue", "Michigan", "MI"],
"49022": ["Benton Harbor", "Michigan", "MI"],
"49024": ["Portage", "Michigan", "MI"],
"49026": ["Bloomingdale", "Michigan", "MI"],
"49027": ["Breedsville", "Michigan", "MI"],
"49028": ["Bronson", "Michigan", "MI"],
"49029": ["Burlington", "Michigan", "MI"],
"49030": ["Burr Oak", "Michigan", "MI"],
"49031": ["Cassopolis", "Michigan", "MI"],
"49032": ["Centreville", "Michigan", "MI"],
"49033": ["Ceresco", "Michigan", "MI"],
"49034": ["Climax", "Michigan", "MI"],
"49036": ["Coldwater", "Michigan", "MI"],
"49037": ["Battle Creek", "Michigan", "MI"],
"49038": ["Coloma", "Michigan", "MI"],
"49040": ["Colon", "Michigan", "MI"],
"49042": ["Constantine", "Michigan", "MI"],
"49043": ["Covert", "Michigan", "MI"],
"49045": ["Decatur", "Michigan", "MI"],
"49046": ["Delton", "Michigan", "MI"],
"49047": ["Dowagiac", "Michigan", "MI"],
"49048": ["Kalamazoo", "Michigan", "MI"],
"49050": ["Dowling", "Michigan", "MI"],
"49051": ["East Leroy", "Michigan", "MI"],
"49052": ["Fulton", "Michigan", "MI"],
"49053": ["Galesburg", "Michigan", "MI"],
"49055": ["Gobles", "Michigan", "MI"],
"49056": ["Grand Junction", "Michigan", "MI"],
"49057": ["Hartford", "Michigan", "MI"],
"49058": ["Hastings", "Michigan", "MI"],
"49060": ["Hickory Corners", "Michigan", "MI"],
"49061": ["Jones", "Michigan", "MI"],
"49063": ["Lacota", "Michigan", "MI"],
"49064": ["Lawrence", "Michigan", "MI"],
"49065": ["Lawton", "Michigan", "MI"],
"49066": ["Leonidas", "Michigan", "MI"],
"49067": ["Marcellus", "Michigan", "MI"],
"49068": ["Marshall", "Michigan", "MI"],
"49070": ["Martin", "Michigan", "MI"],
"49071": ["Mattawan", "Michigan", "MI"],
"49072": ["Mendon", "Michigan", "MI"],
"49073": ["Nashville", "Michigan", "MI"],
"49074": ["Nazareth", "Michigan", "MI"],
"49075": ["Nottawa", "Michigan", "MI"],
"49076": ["Olivet", "Michigan", "MI"],
"49078": ["Otsego", "Michigan", "MI"],
"49079": ["Paw Paw", "Michigan", "MI"],
"49080": ["Plainwell", "Michigan", "MI"],
"49082": ["Quincy", "Michigan", "MI"],
"49083": ["Richland", "Michigan", "MI"],
"49084": ["Riverside", "Michigan", "MI"],
"49085": ["Saint Joseph", "Michigan", "MI"],
"49087": ["Schoolcraft", "Michigan", "MI"],
"49088": ["Scotts", "Michigan", "MI"],
"49089": ["Sherwood", "Michigan", "MI"],
"49090": ["South Haven", "Michigan", "MI"],
"49091": ["Sturgis", "Michigan", "MI"],
"49092": ["Tekonsha", "Michigan", "MI"],
"49093": ["Three Rivers", "Michigan", "MI"],
"49094": ["Union City", "Michigan", "MI"],
"49095": ["Vandalia", "Michigan", "MI"],
"49096": ["Vermontville", "Michigan", "MI"],
"49097": ["Vicksburg", "Michigan", "MI"],
"49098": ["Watervliet", "Michigan", "MI"],
"49099": ["White Pigeon", "Michigan", "MI"],
"49101": ["Baroda", "Michigan", "MI"],
"49102": ["Berrien Center", "Michigan", "MI"],
"49103": ["Berrien Springs", "Michigan", "MI"],
"49104": ["Berrien Springs", "Michigan", "MI"],
"49106": ["Bridgman", "Michigan", "MI"],
"49107": ["Buchanan", "Michigan", "MI"],
"49111": ["Eau Claire", "Michigan", "MI"],
"49112": ["Edwardsburg", "Michigan", "MI"],
"49113": ["Galien", "Michigan", "MI"],
"49115": ["Harbert", "Michigan", "MI"],
"49116": ["Lakeside", "Michigan", "MI"],
"49117": ["New Buffalo", "Michigan", "MI"],
"49119": ["New Troy", "Michigan", "MI"],
"49120": ["Niles", "Michigan", "MI"],
"49125": ["Sawyer", "Michigan", "MI"],
"49126": ["Sodus", "Michigan", "MI"],
"49127": ["Stevensville", "Michigan", "MI"],
"49128": ["Three Oaks", "Michigan", "MI"],
"49129": ["Union Pier", "Michigan", "MI"],
"49130": ["Union", "Michigan", "MI"],
"49201": ["Jackson", "Michigan", "MI"],
"49202": ["Jackson", "Michigan", "MI"],
"49203": ["Jackson", "Michigan", "MI"],
"49220": ["Addison", "Michigan", "MI"],
"49221": ["Adrian", "Michigan", "MI"],
"49224": ["Albion", "Michigan", "MI"],
"49227": ["Allen", "Michigan", "MI"],
"49228": ["Blissfield", "Michigan", "MI"],
"49229": ["Britton", "Michigan", "MI"],
"49230": ["Brooklyn", "Michigan", "MI"],
"49232": ["Camden", "Michigan", "MI"],
"49233": ["Cement City", "Michigan", "MI"],
"49234": ["Clarklake", "Michigan", "MI"],
"49235": ["Clayton", "Michigan", "MI"],
"49236": ["Clinton", "Michigan", "MI"],
"49237": ["Concord", "Michigan", "MI"],
"49238": ["Deerfield", "Michigan", "MI"],
"49240": ["Grass Lake", "Michigan", "MI"],
"49241": ["Hanover", "Michigan", "MI"],
"49242": ["Hillsdale", "Michigan", "MI"],
"49245": ["Homer", "Michigan", "MI"],
"49246": ["Horton", "Michigan", "MI"],
"49247": ["Hudson", "Michigan", "MI"],
"49248": ["Jasper", "Michigan", "MI"],
"49249": ["Jerome", "Michigan", "MI"],
"49250": ["Jonesville", "Michigan", "MI"],
"49251": ["Leslie", "Michigan", "MI"],
"49252": ["Litchfield", "Michigan", "MI"],
"49253": ["Manitou Beach", "Michigan", "MI"],
"49254": ["Michigan Center", "Michigan", "MI"],
"49255": ["Montgomery", "Michigan", "MI"],
"49256": ["Morenci", "Michigan", "MI"],
"49258": ["Mosherville", "Michigan", "MI"],
"49259": ["Munith", "Michigan", "MI"],
"49261": ["Napoleon", "Michigan", "MI"],
"49262": ["North Adams", "Michigan", "MI"],
"49263": ["Norvell", "Michigan", "MI"],
"49264": ["Onondaga", "Michigan", "MI"],
"49265": ["Onsted", "Michigan", "MI"],
"49266": ["Osseo", "Michigan", "MI"],
"49267": ["Ottawa Lake", "Michigan", "MI"],
"49268": ["Palmyra", "Michigan", "MI"],
"49269": ["Parma", "Michigan", "MI"],
"49270": ["Petersburg", "Michigan", "MI"],
"49271": ["Pittsford", "Michigan", "MI"],
"49272": ["Pleasant Lake", "Michigan", "MI"],
"49274": ["Reading", "Michigan", "MI"],
"49276": ["Riga", "Michigan", "MI"],
"49277": ["Rives Junction", "Michigan", "MI"],
"49279": ["Sand Creek", "Michigan", "MI"],
"49282": ["Somerset Center", "Michigan", "MI"],
"49283": ["Spring Arbor", "Michigan", "MI"],
"49284": ["Springport", "Michigan", "MI"],
"49285": ["Stockbridge", "Michigan", "MI"],
"49286": ["Tecumseh", "Michigan", "MI"],
"49287": ["Tipton", "Michigan", "MI"],
"49288": ["Waldron", "Michigan", "MI"],
"49289": ["Weston", "Michigan", "MI"],
"49301": ["Ada", "Michigan", "MI"],
"49302": ["Alto", "Michigan", "MI"],
"49303": ["Bailey", "Michigan", "MI"],
"49304": ["Baldwin", "Michigan", "MI"],
"49305": ["Barryton", "Michigan", "MI"],
"49306": ["Belmont", "Michigan", "MI"],
"49307": ["Big Rapids", "Michigan", "MI"],
"49309": ["Bitely", "Michigan", "MI"],
"49310": ["Blanchard", "Michigan", "MI"],
"49312": ["Brohman", "Michigan", "MI"],
"49315": ["Byron Center", "Michigan", "MI"],
"49316": ["Caledonia", "Michigan", "MI"],
"49318": ["Casnovia", "Michigan", "MI"],
"49319": ["Cedar Springs", "Michigan", "MI"],
"49320": ["Chippewa Lake", "Michigan", "MI"],
"49321": ["Comstock Park", "Michigan", "MI"],
"49322": ["Coral", "Michigan", "MI"],
"49323": ["Dorr", "Michigan", "MI"],
"49325": ["Freeport", "Michigan", "MI"],
"49326": ["Gowen", "Michigan", "MI"],
"49327": ["Grant", "Michigan", "MI"],
"49328": ["Hopkins", "Michigan", "MI"],
"49329": ["Howard City", "Michigan", "MI"],
"49330": ["Kent City", "Michigan", "MI"],
"49331": ["Lowell", "Michigan", "MI"],
"49332": ["Mecosta", "Michigan", "MI"],
"49333": ["Middleville", "Michigan", "MI"],
"49335": ["Moline", "Michigan", "MI"],
"49336": ["Morley", "Michigan", "MI"],
"49337": ["Newaygo", "Michigan", "MI"],
"49338": ["Paris", "Michigan", "MI"],
"49339": ["Pierson", "Michigan", "MI"],
"49340": ["Remus", "Michigan", "MI"],
"49341": ["Rockford", "Michigan", "MI"],
"49342": ["Rodney", "Michigan", "MI"],
"49343": ["Sand Lake", "Michigan", "MI"],
"49344": ["Shelbyville", "Michigan", "MI"],
"49345": ["Sparta", "Michigan", "MI"],
"49346": ["Stanwood", "Michigan", "MI"],
"49347": ["Trufant", "Michigan", "MI"],
"49348": ["Wayland", "Michigan", "MI"],
"49349": ["White Cloud", "Michigan", "MI"],
"49401": ["Allendale", "Michigan", "MI"],
"49402": ["Branch", "Michigan", "MI"],
"49403": ["Conklin", "Michigan", "MI"],
"49404": ["Coopersville", "Michigan", "MI"],
"49405": ["Custer", "Michigan", "MI"],
"49406": ["Douglas", "Michigan", "MI"],
"49408": ["Fennville", "Michigan", "MI"],
"49409": ["Ferrysburg", "Michigan", "MI"],
"49410": ["Fountain", "Michigan", "MI"],
"49411": ["Free Soil", "Michigan", "MI"],
"49412": ["Fremont", "Michigan", "MI"],
"49415": ["Fruitport", "Michigan", "MI"],
"49417": ["Grand Haven", "Michigan", "MI"],
"49418": ["Grandville", "Michigan", "MI"],
"49419": ["Hamilton", "Michigan", "MI"],
"49420": ["Hart", "Michigan", "MI"],
"49421": ["Hesperia", "Michigan", "MI"],
"49423": ["Holland", "Michigan", "MI"],
"49424": ["Holland", "Michigan", "MI"],
"49425": ["Holton", "Michigan", "MI"],
"49426": ["Hudsonville", "Michigan", "MI"],
"49428": ["Jenison", "Michigan", "MI"],
"49430": ["Lamont", "Michigan", "MI"],
"49431": ["Ludington", "Michigan", "MI"],
"49434": ["Macatawa", "Michigan", "MI"],
"49435": ["Marne", "Michigan", "MI"],
"49436": ["Mears", "Michigan", "MI"],
"49437": ["Montague", "Michigan", "MI"],
"49440": ["Muskegon", "Michigan", "MI"],
"49441": ["Muskegon", "Michigan", "MI"],
"49442": ["Muskegon", "Michigan", "MI"],
"49443": ["Muskegon", "Michigan", "MI"],
"49444": ["Muskegon", "Michigan", "MI"],
"49445": ["Muskegon", "Michigan", "MI"],
"49446": ["New Era", "Michigan", "MI"],
"49448": ["Nunica", "Michigan", "MI"],
"49449": ["Pentwater", "Michigan", "MI"],
"49450": ["Pullman", "Michigan", "MI"],
"49451": ["Ravenna", "Michigan", "MI"],
"49452": ["Rothbury", "Michigan", "MI"],
"49453": ["Saugatuck", "Michigan", "MI"],
"49454": ["Scottville", "Michigan", "MI"],
"49455": ["Shelby", "Michigan", "MI"],
"49456": ["Spring Lake", "Michigan", "MI"],
"49457": ["Twin Lake", "Michigan", "MI"],
"49458": ["Walhalla", "Michigan", "MI"],
"49459": ["Walkerville", "Michigan", "MI"],
"49460": ["West Olive", "Michigan", "MI"],
"49461": ["Whitehall", "Michigan", "MI"],
"49464": ["Zeeland", "Michigan", "MI"],
"49503": ["Grand Rapids", "Michigan", "MI"],
"49504": ["Grand Rapids", "Michigan", "MI"],
"49505": ["Grand Rapids", "Michigan", "MI"],
"49506": ["Grand Rapids", "Michigan", "MI"],
"49507": ["Grand Rapids", "Michigan", "MI"],
"49508": ["Grand Rapids", "Michigan", "MI"],
"49509": ["Wyoming", "Michigan", "MI"],
"49512": ["Grand Rapids", "Michigan", "MI"],
"49519": ["Wyoming", "Michigan", "MI"],
"49525": ["Grand Rapids", "Michigan", "MI"],
"49534": ["Grand Rapids", "Michigan", "MI"],
"49544": ["Grand Rapids", "Michigan", "MI"],
"49546": ["Grand Rapids", "Michigan", "MI"],
"49548": ["Grand Rapids", "Michigan", "MI"],
"49601": ["Cadillac", "Michigan", "MI"],
"49611": ["Alba", "Michigan", "MI"],
"49612": ["Alden", "Michigan", "MI"],
"49613": ["Arcadia", "Michigan", "MI"],
"49614": ["Bear Lake", "Michigan", "MI"],
"49615": ["Bellaire", "Michigan", "MI"],
"49616": ["Benzonia", "Michigan", "MI"],
"49617": ["Beulah", "Michigan", "MI"],
"49618": ["Boon", "Michigan", "MI"],
"49619": ["Brethren", "Michigan", "MI"],
"49620": ["Buckley", "Michigan", "MI"],
"49621": ["Cedar", "Michigan", "MI"],
"49622": ["Central Lake", "Michigan", "MI"],
"49623": ["Chase", "Michigan", "MI"],
"49625": ["Copemish", "Michigan", "MI"],
"49626": ["Eastlake", "Michigan", "MI"],
"49627": ["Eastport", "Michigan", "MI"],
"49628": ["Elberta", "Michigan", "MI"],
"49629": ["Elk Rapids", "Michigan", "MI"],
"49630": ["Empire", "Michigan", "MI"],
"49631": ["Evart", "Michigan", "MI"],
"49632": ["Falmouth", "Michigan", "MI"],
"49633": ["Fife Lake", "Michigan", "MI"],
"49634": ["Filer City", "Michigan", "MI"],
"49635": ["Frankfort", "Michigan", "MI"],
"49636": ["Glen Arbor", "Michigan", "MI"],
"49637": ["Grawn", "Michigan", "MI"],
"49638": ["Harrietta", "Michigan", "MI"],
"49639": ["Hersey", "Michigan", "MI"],
"49640": ["Honor", "Michigan", "MI"],
"49642": ["Idlewild", "Michigan", "MI"],
"49643": ["Interlochen", "Michigan", "MI"],
"49644": ["Irons", "Michigan", "MI"],
"49645": ["Kaleva", "Michigan", "MI"],
"49646": ["Kalkaska", "Michigan", "MI"],
"49648": ["Kewadin", "Michigan", "MI"],
"49649": ["Kingsley", "Michigan", "MI"],
"49650": ["Lake Ann", "Michigan", "MI"],
"49651": ["Lake City", "Michigan", "MI"],
"49653": ["Lake Leelanau", "Michigan", "MI"],
"49654": ["Leland", "Michigan", "MI"],
"49655": ["Leroy", "Michigan", "MI"],
"49656": ["Luther", "Michigan", "MI"],
"49657": ["McBain", "Michigan", "MI"],
"49659": ["Mancelona", "Michigan", "MI"],
"49660": ["Manistee", "Michigan", "MI"],
"49663": ["Manton", "Michigan", "MI"],
"49664": ["Maple City", "Michigan", "MI"],
"49665": ["Marion", "Michigan", "MI"],
"49667": ["Merritt", "Michigan", "MI"],
"49668": ["Mesick", "Michigan", "MI"],
"49670": ["Northport", "Michigan", "MI"],
"49674": ["Omena", "Michigan", "MI"],
"49675": ["Onekama", "Michigan", "MI"],
"49676": ["Rapid City", "Michigan", "MI"],
"49677": ["Reed City", "Michigan", "MI"],
"49679": ["Sears", "Michigan", "MI"],
"49680": ["South Boardman", "Michigan", "MI"],
"49682": ["Suttons Bay", "Michigan", "MI"],
"49683": ["Thompsonville", "Michigan", "MI"],
"49684": ["Traverse City", "Michigan", "MI"],
"49685": ["Traverse City", "Michigan", "MI"],
"49686": ["Traverse City", "Michigan", "MI"],
"49688": ["Tustin", "Michigan", "MI"],
"49689": ["Wellston", "Michigan", "MI"],
"49690": ["Williamsburg", "Michigan", "MI"],
"49696": ["Traverse City", "Michigan", "MI"],
"49701": ["Mackinaw City", "Michigan", "MI"],
"49705": ["Afton", "Michigan", "MI"],
"49706": ["Alanson", "Michigan", "MI"],
"49707": ["Alpena", "Michigan", "MI"],
"49709": ["Atlanta", "Michigan", "MI"],
"49710": ["Barbeau", "Michigan", "MI"],
"49712": ["Boyne City", "Michigan", "MI"],
"49713": ["Boyne Falls", "Michigan", "MI"],
"49715": ["Brimley", "Michigan", "MI"],
"49716": ["Brutus", "Michigan", "MI"],
"49717": ["Burt Lake", "Michigan", "MI"],
"49718": ["Carp Lake", "Michigan", "MI"],
"49719": ["Cedarville", "Michigan", "MI"],
"49720": ["Charlevoix", "Michigan", "MI"],
"49721": ["Cheboygan", "Michigan", "MI"],
"49722": ["Conway", "Michigan", "MI"],
"49724": ["Dafter", "Michigan", "MI"],
"49725": ["De Tour Village", "Michigan", "MI"],
"49726": ["Drummond Island", "Michigan", "MI"],
"49727": ["East Jordan", "Michigan", "MI"],
"49728": ["Eckerman", "Michigan", "MI"],
"49729": ["Ellsworth", "Michigan", "MI"],
"49730": ["Elmira", "Michigan", "MI"],
"49733": ["Frederic", "Michigan", "MI"],
"49735": ["Gaylord", "Michigan", "MI"],
"49736": ["Goetzville", "Michigan", "MI"],
"49738": ["Grayling", "Michigan", "MI"],
"49740": ["Harbor Springs", "Michigan", "MI"],
"49743": ["Hawks", "Michigan", "MI"],
"49744": ["Herron", "Michigan", "MI"],
"49745": ["Hessel", "Michigan", "MI"],
"49746": ["Hillman", "Michigan", "MI"],
"49747": ["Hubbard Lake", "Michigan", "MI"],
"49748": ["Hulbert", "Michigan", "MI"],
"49749": ["Indian River", "Michigan", "MI"],
"49751": ["Johannesburg", "Michigan", "MI"],
"49752": ["Kinross", "Michigan", "MI"],
"49753": ["Lachine", "Michigan", "MI"],
"49755": ["Levering", "Michigan", "MI"],
"49756": ["Lewiston", "Michigan", "MI"],
"49757": ["Mackinac Island", "Michigan", "MI"],
"49759": ["Millersburg", "Michigan", "MI"],
"49760": ["Moran", "Michigan", "MI"],
"49762": ["Naubinway", "Michigan", "MI"],
"49764": ["Oden", "Michigan", "MI"],
"49765": ["Onaway", "Michigan", "MI"],
"49766": ["Ossineke", "Michigan", "MI"],
"49768": ["Paradise", "Michigan", "MI"],
"49769": ["Pellston", "Michigan", "MI"],
"49770": ["Petoskey", "Michigan", "MI"],
"49774": ["Pickford", "Michigan", "MI"],
"49775": ["Pointe Aux Pins", "Michigan", "MI"],
"49776": ["Posen", "Michigan", "MI"],
"49777": ["Presque Isle", "Michigan", "MI"],
"49779": ["Rogers City", "Michigan", "MI"],
"49780": ["Rudyard", "Michigan", "MI"],
"49781": ["Saint Ignace", "Michigan", "MI"],
"49782": ["Beaver Island", "Michigan", "MI"],
"49783": ["Sault Sainte Marie", "Michigan", "MI"],
"49784": ["Kincheloe", "Michigan", "MI"],
"49788": ["Kincheloe", "Michigan", "MI"],
"49791": ["Topinabee", "Michigan", "MI"],
"49793": ["Trout Lake", "Michigan", "MI"],
"49795": ["Vanderbilt", "Michigan", "MI"],
"49796": ["Walloon Lake", "Michigan", "MI"],
"49799": ["Wolverine", "Michigan", "MI"],
"49801": ["Iron Mountain", "Michigan", "MI"],
"49802": ["Kingsford", "Michigan", "MI"],
"49805": ["Allouez", "Michigan", "MI"],
"49806": ["Au Train", "Michigan", "MI"],
"49807": ["Bark River", "Michigan", "MI"],
"49808": ["Big Bay", "Michigan", "MI"],
"49812": ["Carney", "Michigan", "MI"],
"49814": ["Champion", "Michigan", "MI"],
"49815": ["Channing", "Michigan", "MI"],
"49816": ["Chatham", "Michigan", "MI"],
"49817": ["Cooks", "Michigan", "MI"],
"49818": ["Cornell", "Michigan", "MI"],
"49820": ["Curtis", "Michigan", "MI"],
"49821": ["Daggett", "Michigan", "MI"],
"49822": ["Deerton", "Michigan", "MI"],
"49825": ["Eben Junction", "Michigan", "MI"],
"49826": ["Rumely", "Michigan", "MI"],
"49827": ["Engadine", "Michigan", "MI"],
"49829": ["Escanaba", "Michigan", "MI"],
"49831": ["Felch", "Michigan", "MI"],
"49833": ["Little Lake", "Michigan", "MI"],
"49834": ["Foster City", "Michigan", "MI"],
"49835": ["Garden", "Michigan", "MI"],
"49836": ["Germfask", "Michigan", "MI"],
"49837": ["Gladstone", "Michigan", "MI"],
"49838": ["Gould City", "Michigan", "MI"],
"49839": ["Grand Marais", "Michigan", "MI"],
"49840": ["Gulliver", "Michigan", "MI"],
"49841": ["Gwinn", "Michigan", "MI"],
"49847": ["Hermansville", "Michigan", "MI"],
"49848": ["Ingalls", "Michigan", "MI"],
"49849": ["Ishpeming", "Michigan", "MI"],
"49852": ["Loretto", "Michigan", "MI"],
"49853": ["McMillan", "Michigan", "MI"],
"49854": ["Manistique", "Michigan", "MI"],
"49855": ["Marquette", "Michigan", "MI"],
"49858": ["Menominee", "Michigan", "MI"],
"49861": ["Michigamme", "Michigan", "MI"],
"49862": ["Munising", "Michigan", "MI"],
"49863": ["Nadeau", "Michigan", "MI"],
"49864": ["Nahma", "Michigan", "MI"],
"49866": ["Negaunee", "Michigan", "MI"],
"49868": ["Newberry", "Michigan", "MI"],
"49870": ["Norway", "Michigan", "MI"],
"49871": ["Palmer", "Michigan", "MI"],
"49872": ["Perkins", "Michigan", "MI"],
"49873": ["Perronville", "Michigan", "MI"],
"49874": ["Powers", "Michigan", "MI"],
"49876": ["Quinnesec", "Michigan", "MI"],
"49877": ["Ralph", "Michigan", "MI"],
"49878": ["Rapid River", "Michigan", "MI"],
"49879": ["Republic", "Michigan", "MI"],
"49880": ["Rock", "Michigan", "MI"],
"49881": ["Sagola", "Michigan", "MI"],
"49883": ["Seney", "Michigan", "MI"],
"49884": ["Shingleton", "Michigan", "MI"],
"49885": ["Skandia", "Michigan", "MI"],
"49886": ["Spalding", "Michigan", "MI"],
"49887": ["Stephenson", "Michigan", "MI"],
"49891": ["Trenary", "Michigan", "MI"],
"49892": ["Vulcan", "Michigan", "MI"],
"49893": ["Wallace", "Michigan", "MI"],
"49894": ["Wells", "Michigan", "MI"],
"49895": ["Wetmore", "Michigan", "MI"],
"49896": ["Wilson", "Michigan", "MI"],
"49901": ["Ahmeek", "Michigan", "MI"],
"49902": ["Alpha", "Michigan", "MI"],
"49903": ["Amasa", "Michigan", "MI"],
"49905": ["Atlantic Mine", "Michigan", "MI"],
"49908": ["Baraga", "Michigan", "MI"],
"49910": ["Bergland", "Michigan", "MI"],
"49911": ["Bessemer", "Michigan", "MI"],
"49912": ["Bruce Crossing", "Michigan", "MI"],
"49913": ["Calumet", "Michigan", "MI"],
"49915": ["Caspian", "Michigan", "MI"],
"49916": ["Chassell", "Michigan", "MI"],
"49917": ["Copper City", "Michigan", "MI"],
"49918": ["Copper Harbor", "Michigan", "MI"],
"49919": ["Covington", "Michigan", "MI"],
"49920": ["Crystal Falls", "Michigan", "MI"],
"49921": ["Dodgeville", "Michigan", "MI"],
"49922": ["Dollar Bay", "Michigan", "MI"],
"49925": ["Ewen", "Michigan", "MI"],
"49927": ["Gaastra", "Michigan", "MI"],
"49929": ["Greenland", "Michigan", "MI"],
"49930": ["Hancock", "Michigan", "MI"],
"49931": ["Houghton", "Michigan", "MI"],
"49934": ["Hubbell", "Michigan", "MI"],
"49935": ["Iron River", "Michigan", "MI"],
"49938": ["Ironwood", "Michigan", "MI"],
"49942": ["Kearsarge", "Michigan", "MI"],
"49945": ["Lake Linden", "Michigan", "MI"],
"49946": ["Lanse", "Michigan", "MI"],
"49947": ["Marenisco", "Michigan", "MI"],
"49948": ["Mass City", "Michigan", "MI"],
"49950": ["Mohawk", "Michigan", "MI"],
"49952": ["Nisula", "Michigan", "MI"],
"49953": ["Ontonagon", "Michigan", "MI"],
"49955": ["Painesdale", "Michigan", "MI"],
"49958": ["Pelkie", "Michigan", "MI"],
"49959": ["Ramsay", "Michigan", "MI"],
"49960": ["Rockland", "Michigan", "MI"],
"49961": ["Sidnaw", "Michigan", "MI"],
"49962": ["Skanee", "Michigan", "MI"],
"49963": ["South Range", "Michigan", "MI"],
"49965": ["Toivola", "Michigan", "MI"],
"49967": ["Trout Creek", "Michigan", "MI"],
"49968": ["Wakefield", "Michigan", "MI"],
"49969": ["Watersmeet", "Michigan", "MI"],
"49970": ["Watton", "Michigan", "MI"],
"49971": ["White Pine", "Michigan", "MI"],
"50001": ["Ackworth", "Iowa", "IA"],
"50002": ["Adair", "Iowa", "IA"],
"50003": ["Adel", "Iowa", "IA"],
"50005": ["Albion", "Iowa", "IA"],
"50006": ["Alden", "Iowa", "IA"],
"50007": ["Alleman", "Iowa", "IA"],
"50008": ["Allerton", "Iowa", "IA"],
"50009": ["Altoona", "Iowa", "IA"],
"50010": ["Ames", "Iowa", "IA"],
"50012": ["Ames", "Iowa", "IA"],
"50013": ["Ames", "Iowa", "IA"],
"50014": ["Ames", "Iowa", "IA"],
"50020": ["Anita", "Iowa", "IA"],
"50021": ["Ankeny", "Iowa", "IA"],
"50022": ["Atlantic", "Iowa", "IA"],
"50023": ["Ankeny", "Iowa", "IA"],
"50025": ["Audubon", "Iowa", "IA"],
"50026": ["Bagley", "Iowa", "IA"],
"50027": ["Barnes City", "Iowa", "IA"],
"50028": ["Baxter", "Iowa", "IA"],
"50029": ["Bayard", "Iowa", "IA"],
"50031": ["Beaver", "Iowa", "IA"],
"50032": ["Berwick", "Iowa", "IA"],
"50033": ["Bevington", "Iowa", "IA"],
"50034": ["Blairsburg", "Iowa", "IA"],
"50035": ["Bondurant", "Iowa", "IA"],
"50036": ["Boone", "Iowa", "IA"],
"50038": ["Booneville", "Iowa", "IA"],
"50039": ["Bouton", "Iowa", "IA"],
"50040": ["Boxholm", "Iowa", "IA"],
"50041": ["Bradford", "Iowa", "IA"],
"50042": ["Brayton", "Iowa", "IA"],
"50044": ["Bussey", "Iowa", "IA"],
"50046": ["Cambridge", "Iowa", "IA"],
"50047": ["Carlisle", "Iowa", "IA"],
"50048": ["Casey", "Iowa", "IA"],
"50049": ["Chariton", "Iowa", "IA"],
"50050": ["Churdan", "Iowa", "IA"],
"50051": ["Clemons", "Iowa", "IA"],
"50052": ["Clio", "Iowa", "IA"],
"50054": ["Colfax", "Iowa", "IA"],
"50055": ["Collins", "Iowa", "IA"],
"50056": ["Colo", "Iowa", "IA"],
"50057": ["Columbia", "Iowa", "IA"],
"50058": ["Coon Rapids", "Iowa", "IA"],
"50060": ["Corydon", "Iowa", "IA"],
"50061": ["Cumming", "Iowa", "IA"],
"50062": ["Melcher Dallas", "Iowa", "IA"],
"50063": ["Dallas Center", "Iowa", "IA"],
"50064": ["Dana", "Iowa", "IA"],
"50065": ["Davis City", "Iowa", "IA"],
"50066": ["Dawson", "Iowa", "IA"],
"50067": ["Decatur", "Iowa", "IA"],
"50068": ["Derby", "Iowa", "IA"],
"50069": ["De Soto", "Iowa", "IA"],
"50070": ["Dexter", "Iowa", "IA"],
"50071": ["Dows", "Iowa", "IA"],
"50072": ["Earlham", "Iowa", "IA"],
"50073": ["Elkhart", "Iowa", "IA"],
"50074": ["Ellston", "Iowa", "IA"],
"50075": ["Ellsworth", "Iowa", "IA"],
"50076": ["Exira", "Iowa", "IA"],
"50078": ["Ferguson", "Iowa", "IA"],
"50101": ["Galt", "Iowa", "IA"],
"50102": ["Garden City", "Iowa", "IA"],
"50103": ["Garden Grove", "Iowa", "IA"],
"50104": ["Gibson", "Iowa", "IA"],
"50105": ["Gilbert", "Iowa", "IA"],
"50106": ["Gilman", "Iowa", "IA"],
"50107": ["Grand Junction", "Iowa", "IA"],
"50108": ["Grand River", "Iowa", "IA"],
"50109": ["Granger", "Iowa", "IA"],
"50110": ["Gray", "Iowa", "IA"],
"50111": ["Grimes", "Iowa", "IA"],
"50112": ["Grinnell", "Iowa", "IA"],
"50115": ["Guthrie Center", "Iowa", "IA"],
"50116": ["Hamilton", "Iowa", "IA"],
"50117": ["Hamlin", "Iowa", "IA"],
"50118": ["Hartford", "Iowa", "IA"],
"50119": ["Harvey", "Iowa", "IA"],
"50120": ["Haverhill", "Iowa", "IA"],
"50122": ["Hubbard", "Iowa", "IA"],
"50123": ["Humeston", "Iowa", "IA"],
"50124": ["Huxley", "Iowa", "IA"],
"50125": ["Indianola", "Iowa", "IA"],
"50126": ["Iowa Falls", "Iowa", "IA"],
"50127": ["Ira", "Iowa", "IA"],
"50128": ["Jamaica", "Iowa", "IA"],
"50129": ["Jefferson", "Iowa", "IA"],
"50130": ["Jewell", "Iowa", "IA"],
"50131": ["Johnston", "Iowa", "IA"],
"50132": ["Kamrar", "Iowa", "IA"],
"50133": ["Kellerton", "Iowa", "IA"],
"50134": ["Kelley", "Iowa", "IA"],
"50135": ["Kellogg", "Iowa", "IA"],
"50136": ["Keswick", "Iowa", "IA"],
"50137": ["Killduff", "Iowa", "IA"],
"50138": ["Knoxville", "Iowa", "IA"],
"50139": ["Lacona", "Iowa", "IA"],
"50140": ["Lamoni", "Iowa", "IA"],
"50141": ["Laurel", "Iowa", "IA"],
"50142": ["Le Grand", "Iowa", "IA"],
"50143": ["Leighton", "Iowa", "IA"],
"50144": ["Leon", "Iowa", "IA"],
"50145": ["Liberty Center", "Iowa", "IA"],
"50146": ["Linden", "Iowa", "IA"],
"50147": ["Lineville", "Iowa", "IA"],
"50148": ["Liscomb", "Iowa", "IA"],
"50149": ["Lorimor", "Iowa", "IA"],
"50150": ["Lovilia", "Iowa", "IA"],
"50151": ["Lucas", "Iowa", "IA"],
"50152": ["Luther", "Iowa", "IA"],
"50153": ["Lynnville", "Iowa", "IA"],
"50154": ["McCallsburg", "Iowa", "IA"],
"50155": ["Macksburg", "Iowa", "IA"],
"50156": ["Madrid", "Iowa", "IA"],
"50157": ["Malcom", "Iowa", "IA"],
"50158": ["Marshalltown", "Iowa", "IA"],
"50160": ["Martensdale", "Iowa", "IA"],
"50161": ["Maxwell", "Iowa", "IA"],
"50162": ["Melbourne", "Iowa", "IA"],
"50163": ["Melcher Dallas", "Iowa", "IA"],
"50164": ["Menlo", "Iowa", "IA"],
"50165": ["Millerton", "Iowa", "IA"],
"50166": ["Milo", "Iowa", "IA"],
"50167": ["Minburn", "Iowa", "IA"],
"50168": ["Mingo", "Iowa", "IA"],
"50169": ["Mitchellville", "Iowa", "IA"],
"50170": ["Monroe", "Iowa", "IA"],
"50171": ["Montezuma", "Iowa", "IA"],
"50173": ["Montour", "Iowa", "IA"],
"50174": ["Murray", "Iowa", "IA"],
"50201": ["Nevada", "Iowa", "IA"],
"50206": ["New Providence", "Iowa", "IA"],
"50207": ["New Sharon", "Iowa", "IA"],
"50208": ["Newton", "Iowa", "IA"],
"50210": ["New Virginia", "Iowa", "IA"],
"50211": ["Norwalk", "Iowa", "IA"],
"50212": ["Ogden", "Iowa", "IA"],
"50213": ["Osceola", "Iowa", "IA"],
"50214": ["Otley", "Iowa", "IA"],
"50216": ["Panora", "Iowa", "IA"],
"50217": ["Paton", "Iowa", "IA"],
"50218": ["Patterson", "Iowa", "IA"],
"50219": ["Pella", "Iowa", "IA"],
"50220": ["Perry", "Iowa", "IA"],
"50222": ["Peru", "Iowa", "IA"],
"50223": ["Pilot Mound", "Iowa", "IA"],
"50225": ["Pleasantville", "Iowa", "IA"],
"50226": ["Polk City", "Iowa", "IA"],
"50227": ["Popejoy", "Iowa", "IA"],
"50228": ["Prairie City", "Iowa", "IA"],
"50229": ["Prole", "Iowa", "IA"],
"50230": ["Radcliffe", "Iowa", "IA"],
"50231": ["Randall", "Iowa", "IA"],
"50232": ["Reasnor", "Iowa", "IA"],
"50233": ["Redfield", "Iowa", "IA"],
"50234": ["Rhodes", "Iowa", "IA"],
"50235": ["Rippey", "Iowa", "IA"],
"50236": ["Roland", "Iowa", "IA"],
"50237": ["Runnells", "Iowa", "IA"],
"50238": ["Russell", "Iowa", "IA"],
"50239": ["Saint Anthony", "Iowa", "IA"],
"50240": ["Saint Charles", "Iowa", "IA"],
"50241": ["Saint Marys", "Iowa", "IA"],
"50242": ["Searsboro", "Iowa", "IA"],
"50243": ["Sheldahl", "Iowa", "IA"],
"50244": ["Slater", "Iowa", "IA"],
"50246": ["Stanhope", "Iowa", "IA"],
"50247": ["State Center", "Iowa", "IA"],
"50248": ["Story City", "Iowa", "IA"],
"50249": ["Stratford", "Iowa", "IA"],
"50250": ["Stuart", "Iowa", "IA"],
"50251": ["Sully", "Iowa", "IA"],
"50252": ["Swan", "Iowa", "IA"],
"50254": ["Thayer", "Iowa", "IA"],
"50255": ["Thornburg", "Iowa", "IA"],
"50256": ["Tracy", "Iowa", "IA"],
"50257": ["Truro", "Iowa", "IA"],
"50258": ["Union", "Iowa", "IA"],
"50261": ["Van Meter", "Iowa", "IA"],
"50262": ["Van Wert", "Iowa", "IA"],
"50263": ["Waukee", "Iowa", "IA"],
"50264": ["Weldon", "Iowa", "IA"],
"50265": ["West Des Moines", "Iowa", "IA"],
"50266": ["West Des Moines", "Iowa", "IA"],
"50268": ["What Cheer", "Iowa", "IA"],
"50269": ["Whitten", "Iowa", "IA"],
"50271": ["Williams", "Iowa", "IA"],
"50272": ["Williamson", "Iowa", "IA"],
"50273": ["Winterset", "Iowa", "IA"],
"50274": ["Wiota", "Iowa", "IA"],
"50275": ["Woodburn", "Iowa", "IA"],
"50276": ["Woodward", "Iowa", "IA"],
"50277": ["Yale", "Iowa", "IA"],
"50278": ["Zearing", "Iowa", "IA"],
"50309": ["Des Moines", "Iowa", "IA"],
"50310": ["Des Moines", "Iowa", "IA"],
"50311": ["Des Moines", "Iowa", "IA"],
"50312": ["Des Moines", "Iowa", "IA"],
"50313": ["Des Moines", "Iowa", "IA"],
"50314": ["Des Moines", "Iowa", "IA"],
"50315": ["Des Moines", "Iowa", "IA"],
"50316": ["Des Moines", "Iowa", "IA"],
"50317": ["Des Moines", "Iowa", "IA"],
"50319": ["Des Moines", "Iowa", "IA"],
"50320": ["Des Moines", "Iowa", "IA"],
"50321": ["Des Moines", "Iowa", "IA"],
"50322": ["Urbandale", "Iowa", "IA"],
"50323": ["Urbandale", "Iowa", "IA"],
"50324": ["Windsor Heights", "Iowa", "IA"],
"50325": ["Clive", "Iowa", "IA"],
"50327": ["Pleasant Hill", "Iowa", "IA"],
"50401": ["Mason City", "Iowa", "IA"],
"50420": ["Alexander", "Iowa", "IA"],
"50421": ["Belmond", "Iowa", "IA"],
"50423": ["Britt", "Iowa", "IA"],
"50424": ["Buffalo Center", "Iowa", "IA"],
"50426": ["Carpenter", "Iowa", "IA"],
"50427": ["Chapin", "Iowa", "IA"],
"50428": ["Clear Lake", "Iowa", "IA"],
"50430": ["Corwith", "Iowa", "IA"],
"50431": ["Coulter", "Iowa", "IA"],
"50432": ["Crystal Lake", "Iowa", "IA"],
"50433": ["Dougherty", "Iowa", "IA"],
"50434": ["Fertile", "Iowa", "IA"],
"50435": ["Floyd", "Iowa", "IA"],
"50436": ["Forest City", "Iowa", "IA"],
"50438": ["Garner", "Iowa", "IA"],
"50439": ["Goodell", "Iowa", "IA"],
"50440": ["Grafton", "Iowa", "IA"],
"50441": ["Hampton", "Iowa", "IA"],
"50444": ["Hanlontown", "Iowa", "IA"],
"50446": ["Joice", "Iowa", "IA"],
"50447": ["Kanawha", "Iowa", "IA"],
"50448": ["Kensett", "Iowa", "IA"],
"50449": ["Klemme", "Iowa", "IA"],
"50450": ["Lake Mills", "Iowa", "IA"],
"50451": ["Lakota", "Iowa", "IA"],
"50452": ["Latimer", "Iowa", "IA"],
"50453": ["Leland", "Iowa", "IA"],
"50454": ["Little Cedar", "Iowa", "IA"],
"50455": ["McIntire", "Iowa", "IA"],
"50456": ["Manly", "Iowa", "IA"],
"50457": ["Meservey", "Iowa", "IA"],
"50458": ["Nora Springs", "Iowa", "IA"],
"50459": ["Northwood", "Iowa", "IA"],
"50460": ["Orchard", "Iowa", "IA"],
"50461": ["Osage", "Iowa", "IA"],
"50464": ["Plymouth", "Iowa", "IA"],
"50465": ["Rake", "Iowa", "IA"],
"50466": ["Riceville", "Iowa", "IA"],
"50467": ["Rock Falls", "Iowa", "IA"],
"50468": ["Rockford", "Iowa", "IA"],
"50469": ["Rockwell", "Iowa", "IA"],
"50470": ["Rowan", "Iowa", "IA"],
"50471": ["Rudd", "Iowa", "IA"],
"50472": ["Saint Ansgar", "Iowa", "IA"],
"50473": ["Scarville", "Iowa", "IA"],
"50475": ["Sheffield", "Iowa", "IA"],
"50476": ["Stacyville", "Iowa", "IA"],
"50477": ["Swaledale", "Iowa", "IA"],
"50478": ["Thompson", "Iowa", "IA"],
"50479": ["Thornton", "Iowa", "IA"],
"50480": ["Titonka", "Iowa", "IA"],
"50482": ["Ventura", "Iowa", "IA"],
"50483": ["Wesley", "Iowa", "IA"],
"50484": ["Woden", "Iowa", "IA"],
"50501": ["Fort Dodge", "Iowa", "IA"],
"50510": ["Albert City", "Iowa", "IA"],
"50511": ["Algona", "Iowa", "IA"],
"50514": ["Armstrong", "Iowa", "IA"],
"50515": ["Ayrshire", "Iowa", "IA"],
"50516": ["Badger", "Iowa", "IA"],
"50517": ["Bancroft", "Iowa", "IA"],
"50518": ["Barnum", "Iowa", "IA"],
"50519": ["Bode", "Iowa", "IA"],
"50520": ["Bradgate", "Iowa", "IA"],
"50521": ["Burnside", "Iowa", "IA"],
"50522": ["Burt", "Iowa", "IA"],
"50523": ["Callender", "Iowa", "IA"],
"50524": ["Clare", "Iowa", "IA"],
"50525": ["Clarion", "Iowa", "IA"],
"50527": ["Curlew", "Iowa", "IA"],
"50528": ["Cylinder", "Iowa", "IA"],
"50529": ["Dakota City", "Iowa", "IA"],
"50530": ["Dayton", "Iowa", "IA"],
"50531": ["Dolliver", "Iowa", "IA"],
"50532": ["Duncombe", "Iowa", "IA"],
"50533": ["Eagle Grove", "Iowa", "IA"],
"50535": ["Early", "Iowa", "IA"],
"50536": ["Emmetsburg", "Iowa", "IA"],
"50538": ["Farnhamville", "Iowa", "IA"],
"50539": ["Fenton", "Iowa", "IA"],
"50540": ["Fonda", "Iowa", "IA"],
"50541": ["Gilmore City", "Iowa", "IA"],
"50542": ["Goldfield", "Iowa", "IA"],
"50543": ["Gowrie", "Iowa", "IA"],
"50544": ["Harcourt", "Iowa", "IA"],
"50545": ["Hardy", "Iowa", "IA"],
"50546": ["Havelock", "Iowa", "IA"],
"50548": ["Humboldt", "Iowa", "IA"],
"50551": ["Jolley", "Iowa", "IA"],
"50552": ["Knierim", "Iowa", "IA"],
"50554": ["Laurens", "Iowa", "IA"],
"50556": ["Ledyard", "Iowa", "IA"],
"50557": ["Lehigh", "Iowa", "IA"],
"50558": ["Livermore", "Iowa", "IA"],
"50559": ["Lone Rock", "Iowa", "IA"],
"50560": ["Lu Verne", "Iowa", "IA"],
"50561": ["Lytton", "Iowa", "IA"],
"50562": ["Mallard", "Iowa", "IA"],
"50563": ["Manson", "Iowa", "IA"],
"50565": ["Marathon", "Iowa", "IA"],
"50566": ["Moorland", "Iowa", "IA"],
"50567": ["Nemaha", "Iowa", "IA"],
"50568": ["Newell", "Iowa", "IA"],
"50569": ["Otho", "Iowa", "IA"],
"50570": ["Ottosen", "Iowa", "IA"],
"50571": ["Palmer", "Iowa", "IA"],
"50573": ["Plover", "Iowa", "IA"],
"50574": ["Pocahontas", "Iowa", "IA"],
"50575": ["Pomeroy", "Iowa", "IA"],
"50576": ["Rembrandt", "Iowa", "IA"],
"50577": ["Renwick", "Iowa", "IA"],
"50578": ["Ringsted", "Iowa", "IA"],
"50579": ["Rockwell City", "Iowa", "IA"],
"50581": ["Rolfe", "Iowa", "IA"],
"50582": ["Rutland", "Iowa", "IA"],
"50583": ["Sac City", "Iowa", "IA"],
"50585": ["Sioux Rapids", "Iowa", "IA"],
"50586": ["Somers", "Iowa", "IA"],
"50588": ["Storm Lake", "Iowa", "IA"],
"50590": ["Swea City", "Iowa", "IA"],
"50591": ["Thor", "Iowa", "IA"],
"50592": ["Truesdale", "Iowa", "IA"],
"50593": ["Varina", "Iowa", "IA"],
"50594": ["Vincent", "Iowa", "IA"],
"50595": ["Webster City", "Iowa", "IA"],
"50597": ["West Bend", "Iowa", "IA"],
"50598": ["Whittemore", "Iowa", "IA"],
"50599": ["Woolstock", "Iowa", "IA"],
"50601": ["Ackley", "Iowa", "IA"],
"50602": ["Allison", "Iowa", "IA"],
"50603": ["Alta Vista", "Iowa", "IA"],
"50604": ["Aplington", "Iowa", "IA"],
"50605": ["Aredale", "Iowa", "IA"],
"50606": ["Arlington", "Iowa", "IA"],
"50607": ["Aurora", "Iowa", "IA"],
"50608": ["Austinville", "Iowa", "IA"],
"50609": ["Beaman", "Iowa", "IA"],
"50611": ["Bristow", "Iowa", "IA"],
"50612": ["Buckingham", "Iowa", "IA"],
"50613": ["Cedar Falls", "Iowa", "IA"],
"50616": ["Charles City", "Iowa", "IA"],
"50619": ["Clarksville", "Iowa", "IA"],
"50620": ["Colwell", "Iowa", "IA"],
"50621": ["Conrad", "Iowa", "IA"],
"50622": ["Denver", "Iowa", "IA"],
"50623": ["Dewar", "Iowa", "IA"],
"50624": ["Dike", "Iowa", "IA"],
"50625": ["Dumont", "Iowa", "IA"],
"50626": ["Dunkerton", "Iowa", "IA"],
"50627": ["Eldora", "Iowa", "IA"],
"50628": ["Elma", "Iowa", "IA"],
"50629": ["Fairbank", "Iowa", "IA"],
"50630": ["Fredericksburg", "Iowa", "IA"],
"50631": ["Frederika", "Iowa", "IA"],
"50632": ["Garwin", "Iowa", "IA"],
"50633": ["Geneva", "Iowa", "IA"],
"50634": ["Gilbertville", "Iowa", "IA"],
"50635": ["Gladbrook", "Iowa", "IA"],
"50636": ["Greene", "Iowa", "IA"],
"50638": ["Grundy Center", "Iowa", "IA"],
"50641": ["Hazleton", "Iowa", "IA"],
"50642": ["Holland", "Iowa", "IA"],
"50643": ["Hudson", "Iowa", "IA"],
"50644": ["Independence", "Iowa", "IA"],
"50645": ["Ionia", "Iowa", "IA"],
"50647": ["Janesville", "Iowa", "IA"],
"50648": ["Jesup", "Iowa", "IA"],
"50649": ["Kesley", "Iowa", "IA"],
"50650": ["Lamont", "Iowa", "IA"],
"50651": ["La Porte City", "Iowa", "IA"],
"50652": ["Lincoln", "Iowa", "IA"],
"50653": ["Marble Rock", "Iowa", "IA"],
"50654": ["Masonville", "Iowa", "IA"],
"50655": ["Maynard", "Iowa", "IA"],
"50657": ["Morrison", "Iowa", "IA"],
"50658": ["Nashua", "Iowa", "IA"],
"50659": ["New Hampton", "Iowa", "IA"],
"50660": ["New Hartford", "Iowa", "IA"],
"50662": ["Oelwein", "Iowa", "IA"],
"50664": ["Oran", "Iowa", "IA"],
"50665": ["Parkersburg", "Iowa", "IA"],
"50666": ["Plainfield", "Iowa", "IA"],
"50667": ["Raymond", "Iowa", "IA"],
"50668": ["Readlyn", "Iowa", "IA"],
"50669": ["Reinbeck", "Iowa", "IA"],
"50670": ["Shell Rock", "Iowa", "IA"],
"50671": ["Stanley", "Iowa", "IA"],
"50672": ["Steamboat Rock", "Iowa", "IA"],
"50673": ["Stout", "Iowa", "IA"],
"50674": ["Sumner", "Iowa", "IA"],
"50675": ["Traer", "Iowa", "IA"],
"50676": ["Tripoli", "Iowa", "IA"],
"50677": ["Waverly", "Iowa", "IA"],
"50680": ["Wellsburg", "Iowa", "IA"],
"50681": ["Westgate", "Iowa", "IA"],
"50682": ["Winthrop", "Iowa", "IA"],
"50701": ["Waterloo", "Iowa", "IA"],
"50702": ["Waterloo", "Iowa", "IA"],
"50703": ["Waterloo", "Iowa", "IA"],
"50707": ["Evansdale", "Iowa", "IA"],
"50801": ["Creston", "Iowa", "IA"],
"50830": ["Afton", "Iowa", "IA"],
"50831": ["Arispe", "Iowa", "IA"],
"50833": ["Bedford", "Iowa", "IA"],
"50835": ["Benton", "Iowa", "IA"],
"50836": ["Blockton", "Iowa", "IA"],
"50837": ["Bridgewater", "Iowa", "IA"],
"50839": ["Carbon", "Iowa", "IA"],
"50840": ["Clearfield", "Iowa", "IA"],
"50841": ["Corning", "Iowa", "IA"],
"50842": ["Cromwell", "Iowa", "IA"],
"50843": ["Cumberland", "Iowa", "IA"],
"50845": ["Diagonal", "Iowa", "IA"],
"50846": ["Fontanelle", "Iowa", "IA"],
"50847": ["Grant", "Iowa", "IA"],
"50848": ["Gravity", "Iowa", "IA"],
"50849": ["Greenfield", "Iowa", "IA"],
"50851": ["Lenox", "Iowa", "IA"],
"50853": ["Massena", "Iowa", "IA"],
"50854": ["Mount Ayr", "Iowa", "IA"],
"50857": ["Nodaway", "Iowa", "IA"],
"50858": ["Orient", "Iowa", "IA"],
"50859": ["Prescott", "Iowa", "IA"],
"50860": ["Redding", "Iowa", "IA"],
"50861": ["Shannon City", "Iowa", "IA"],
"50862": ["Sharpsburg", "Iowa", "IA"],
"50863": ["Tingley", "Iowa", "IA"],
"50864": ["Villisca", "Iowa", "IA"],
"51001": ["Akron", "Iowa", "IA"],
"51002": ["Alta", "Iowa", "IA"],
"51003": ["Alton", "Iowa", "IA"],
"51004": ["Anthon", "Iowa", "IA"],
"51005": ["Aurelia", "Iowa", "IA"],
"51006": ["Battle Creek", "Iowa", "IA"],
"51007": ["Bronson", "Iowa", "IA"],
"51008": ["Brunsville", "Iowa", "IA"],
"51009": ["Calumet", "Iowa", "IA"],
"51010": ["Castana", "Iowa", "IA"],
"51011": ["Chatsworth", "Iowa", "IA"],
"51012": ["Cherokee", "Iowa", "IA"],
"51014": ["Cleghorn", "Iowa", "IA"],
"51015": ["Climbing Hill", "Iowa", "IA"],
"51016": ["Correctionville", "Iowa", "IA"],
"51018": ["Cushing", "Iowa", "IA"],
"51019": ["Danbury", "Iowa", "IA"],
"51020": ["Galva", "Iowa", "IA"],
"51022": ["Granville", "Iowa", "IA"],
"51023": ["Hawarden", "Iowa", "IA"],
"51024": ["Hinton", "Iowa", "IA"],
"51025": ["Holstein", "Iowa", "IA"],
"51026": ["Hornick", "Iowa", "IA"],
"51027": ["Ireton", "Iowa", "IA"],
"51028": ["Kingsley", "Iowa", "IA"],
"51029": ["Larrabee", "Iowa", "IA"],
"51030": ["Lawton", "Iowa", "IA"],
"51031": ["Le Mars", "Iowa", "IA"],
"51033": ["Linn Grove", "Iowa", "IA"],
"51034": ["Mapleton", "Iowa", "IA"],
"51035": ["Marcus", "Iowa", "IA"],
"51036": ["Maurice", "Iowa", "IA"],
"51037": ["Meriden", "Iowa", "IA"],
"51038": ["Merrill", "Iowa", "IA"],
"51039": ["Moville", "Iowa", "IA"],
"51040": ["Onawa", "Iowa", "IA"],
"51041": ["Orange City", "Iowa", "IA"],
"51044": ["Oto", "Iowa", "IA"],
"51045": ["Oyens", "Iowa", "IA"],
"51046": ["Paullina", "Iowa", "IA"],
"51047": ["Peterson", "Iowa", "IA"],
"51048": ["Pierson", "Iowa", "IA"],
"51049": ["Quimby", "Iowa", "IA"],
"51050": ["Remsen", "Iowa", "IA"],
"51051": ["Rodney", "Iowa", "IA"],
"51052": ["Salix", "Iowa", "IA"],
"51053": ["Schaller", "Iowa", "IA"],
"51054": ["Sergeant Bluff", "Iowa", "IA"],
"51055": ["Sloan", "Iowa", "IA"],
"51056": ["Smithland", "Iowa", "IA"],
"51058": ["Sutherland", "Iowa", "IA"],
"51060": ["Ute", "Iowa", "IA"],
"51061": ["Washta", "Iowa", "IA"],
"51062": ["Westfield", "Iowa", "IA"],
"51063": ["Whiting", "Iowa", "IA"],
"51101": ["Sioux City", "Iowa", "IA"],
"51103": ["Sioux City", "Iowa", "IA"],
"51104": ["Sioux City", "Iowa", "IA"],
"51105": ["Sioux City", "Iowa", "IA"],
"51106": ["Sioux City", "Iowa", "IA"],
"51108": ["Sioux City", "Iowa", "IA"],
"51109": ["Sioux City", "Iowa", "IA"],
"51111": ["Sioux City", "Iowa", "IA"],
"51201": ["Sheldon", "Iowa", "IA"],
"51230": ["Alvord", "Iowa", "IA"],
"51231": ["Archer", "Iowa", "IA"],
"51232": ["Ashton", "Iowa", "IA"],
"51234": ["Boyden", "Iowa", "IA"],
"51235": ["Doon", "Iowa", "IA"],
"51237": ["George", "Iowa", "IA"],
"51238": ["Hospers", "Iowa", "IA"],
"51239": ["Hull", "Iowa", "IA"],
"51240": ["Inwood", "Iowa", "IA"],
"51241": ["Larchwood", "Iowa", "IA"],
"51242": ["Lester", "Iowa", "IA"],
"51243": ["Little Rock", "Iowa", "IA"],
"51244": ["Matlock", "Iowa", "IA"],
"51245": ["Primghar", "Iowa", "IA"],
"51246": ["Rock Rapids", "Iowa", "IA"],
"51247": ["Rock Valley", "Iowa", "IA"],
"51248": ["Sanborn", "Iowa", "IA"],
"51249": ["Sibley", "Iowa", "IA"],
"51250": ["Sioux Center", "Iowa", "IA"],
"51301": ["Spencer", "Iowa", "IA"],
"51331": ["Arnolds Park", "Iowa", "IA"],
"51333": ["Dickens", "Iowa", "IA"],
"51334": ["Estherville", "Iowa", "IA"],
"51338": ["Everly", "Iowa", "IA"],
"51340": ["Fostoria", "Iowa", "IA"],
"51341": ["Gillett Grove", "Iowa", "IA"],
"51342": ["Graettinger", "Iowa", "IA"],
"51343": ["Greenville", "Iowa", "IA"],
"51345": ["Harris", "Iowa", "IA"],
"51346": ["Hartley", "Iowa", "IA"],
"51347": ["Lake Park", "Iowa", "IA"],
"51350": ["Melvin", "Iowa", "IA"],
"51351": ["Milford", "Iowa", "IA"],
"51354": ["Ocheyedan", "Iowa", "IA"],
"51355": ["Okoboji", "Iowa", "IA"],
"51357": ["Royal", "Iowa", "IA"],
"51358": ["Ruthven", "Iowa", "IA"],
"51360": ["Spirit Lake", "Iowa", "IA"],
"51363": ["Superior", "Iowa", "IA"],
"51364": ["Terril", "Iowa", "IA"],
"51365": ["Wallingford", "Iowa", "IA"],
"51366": ["Webb", "Iowa", "IA"],
"51401": ["Carroll", "Iowa", "IA"],
"51430": ["Arcadia", "Iowa", "IA"],
"51431": ["Arthur", "Iowa", "IA"],
"51432": ["Aspinwall", "Iowa", "IA"],
"51433": ["Auburn", "Iowa", "IA"],
"51436": ["Breda", "Iowa", "IA"],
"51439": ["Charter Oak", "Iowa", "IA"],
"51440": ["Dedham", "Iowa", "IA"],
"51441": ["Deloit", "Iowa", "IA"],
"51442": ["Denison", "Iowa", "IA"],
"51443": ["Glidden", "Iowa", "IA"],
"51444": ["Halbur", "Iowa", "IA"],
"51445": ["Ida Grove", "Iowa", "IA"],
"51446": ["Irwin", "Iowa", "IA"],
"51447": ["Kirkman", "Iowa", "IA"],
"51448": ["Kiron", "Iowa", "IA"],
"51449": ["Lake City", "Iowa", "IA"],
"51450": ["Lake View", "Iowa", "IA"],
"51451": ["Lanesboro", "Iowa", "IA"],
"51452": ["Lidderdale", "Iowa", "IA"],
"51453": ["Lohrville", "Iowa", "IA"],
"51454": ["Manilla", "Iowa", "IA"],
"51455": ["Manning", "Iowa", "IA"],
"51458": ["Odebolt", "Iowa", "IA"],
"51459": ["Ralston", "Iowa", "IA"],
"51460": ["Ricketts", "Iowa", "IA"],
"51461": ["Schleswig", "Iowa", "IA"],
"51462": ["Scranton", "Iowa", "IA"],
"51463": ["Templeton", "Iowa", "IA"],
"51465": ["Vail", "Iowa", "IA"],
"51466": ["Wall Lake", "Iowa", "IA"],
"51467": ["Westside", "Iowa", "IA"],
"51501": ["Council Bluffs", "Iowa", "IA"],
"51503": ["Council Bluffs", "Iowa", "IA"],
"51510": ["Carter Lake", "Iowa", "IA"],
"51520": ["Arion", "Iowa", "IA"],
"51521": ["Avoca", "Iowa", "IA"],
"51523": ["Blencoe", "Iowa", "IA"],
"51525": ["Carson", "Iowa", "IA"],
"51526": ["Crescent", "Iowa", "IA"],
"51527": ["Defiance", "Iowa", "IA"],
"51528": ["Dow City", "Iowa", "IA"],
"51529": ["Dunlap", "Iowa", "IA"],
"51530": ["Earling", "Iowa", "IA"],
"51531": ["Elk Horn", "Iowa", "IA"],
"51532": ["Elliott", "Iowa", "IA"],
"51533": ["Emerson", "Iowa", "IA"],
"51534": ["Glenwood", "Iowa", "IA"],
"51535": ["Griswold", "Iowa", "IA"],
"51536": ["Hancock", "Iowa", "IA"],
"51537": ["Harlan", "Iowa", "IA"],
"51540": ["Hastings", "Iowa", "IA"],
"51541": ["Henderson", "Iowa", "IA"],
"51542": ["Honey Creek", "Iowa", "IA"],
"51543": ["Kimballton", "Iowa", "IA"],
"51544": ["Lewis", "Iowa", "IA"],
"51545": ["Little Sioux", "Iowa", "IA"],
"51546": ["Logan", "Iowa", "IA"],
"51548": ["McClelland", "Iowa", "IA"],
"51549": ["Macedonia", "Iowa", "IA"],
"51550": ["Magnolia", "Iowa", "IA"],
"51551": ["Malvern", "Iowa", "IA"],
"51552": ["Marne", "Iowa", "IA"],
"51553": ["Minden", "Iowa", "IA"],
"51554": ["Mineola", "Iowa", "IA"],
"51555": ["Missouri Valley", "Iowa", "IA"],
"51556": ["Modale", "Iowa", "IA"],
"51557": ["Mondamin", "Iowa", "IA"],
"51558": ["Moorhead", "Iowa", "IA"],
"51559": ["Neola", "Iowa", "IA"],
"51560": ["Oakland", "Iowa", "IA"],
"51561": ["Pacific Junction", "Iowa", "IA"],
"51562": ["Panama", "Iowa", "IA"],
"51563": ["Persia", "Iowa", "IA"],
"51564": ["Pisgah", "Iowa", "IA"],
"51565": ["Portsmouth", "Iowa", "IA"],
"51566": ["Red Oak", "Iowa", "IA"],
"51570": ["Shelby", "Iowa", "IA"],
"51571": ["Silver City", "Iowa", "IA"],
"51572": ["Soldier", "Iowa", "IA"],
"51573": ["Stanton", "Iowa", "IA"],
"51575": ["Treynor", "Iowa", "IA"],
"51576": ["Underwood", "Iowa", "IA"],
"51577": ["Walnut", "Iowa", "IA"],
"51578": ["Westphalia", "Iowa", "IA"],
"51579": ["Woodbine", "Iowa", "IA"],
"51601": ["Shenandoah", "Iowa", "IA"],
"51630": ["Blanchard", "Iowa", "IA"],
"51631": ["Braddyville", "Iowa", "IA"],
"51632": ["Clarinda", "Iowa", "IA"],
"51636": ["Coin", "Iowa", "IA"],
"51637": ["College Springs", "Iowa", "IA"],
"51638": ["Essex", "Iowa", "IA"],
"51639": ["Farragut", "Iowa", "IA"],
"51640": ["Hamburg", "Iowa", "IA"],
"51645": ["Imogene", "Iowa", "IA"],
"51646": ["New Market", "Iowa", "IA"],
"51647": ["Northboro", "Iowa", "IA"],
"51648": ["Percival", "Iowa", "IA"],
"51649": ["Randolph", "Iowa", "IA"],
"51650": ["Riverton", "Iowa", "IA"],
"51651": ["Shambaugh", "Iowa", "IA"],
"51652": ["Sidney", "Iowa", "IA"],
"51653": ["Tabor", "Iowa", "IA"],
"51654": ["Thurman", "Iowa", "IA"],
"51656": ["Yorktown", "Iowa", "IA"],
"52001": ["Dubuque", "Iowa", "IA"],
"52002": ["Dubuque", "Iowa", "IA"],
"52003": ["Dubuque", "Iowa", "IA"],
"52030": ["Andrew", "Iowa", "IA"],
"52031": ["Bellevue", "Iowa", "IA"],
"52032": ["Bernard", "Iowa", "IA"],
"52033": ["Cascade", "Iowa", "IA"],
"52035": ["Colesburg", "Iowa", "IA"],
"52036": ["Delaware", "Iowa", "IA"],
"52037": ["Delmar", "Iowa", "IA"],
"52038": ["Dundee", "Iowa", "IA"],
"52039": ["Durango", "Iowa", "IA"],
"52040": ["Dyersville", "Iowa", "IA"],
"52041": ["Earlville", "Iowa", "IA"],
"52042": ["Edgewood", "Iowa", "IA"],
"52043": ["Elkader", "Iowa", "IA"],
"52044": ["Elkport", "Iowa", "IA"],
"52045": ["Epworth", "Iowa", "IA"],
"52046": ["Farley", "Iowa", "IA"],
"52047": ["Farmersburg", "Iowa", "IA"],
"52048": ["Garber", "Iowa", "IA"],
"52049": ["Garnavillo", "Iowa", "IA"],
"52050": ["Greeley", "Iowa", "IA"],
"52052": ["Guttenberg", "Iowa", "IA"],
"52053": ["Holy Cross", "Iowa", "IA"],
"52054": ["La Motte", "Iowa", "IA"],
"52056": ["Luxemburg", "Iowa", "IA"],
"52057": ["Manchester", "Iowa", "IA"],
"52060": ["Maquoketa", "Iowa", "IA"],
"52064": ["Miles", "Iowa", "IA"],
"52065": ["New Vienna", "Iowa", "IA"],
"52066": ["North Buena Vista", "Iowa", "IA"],
"52068": ["Peosta", "Iowa", "IA"],
"52069": ["Preston", "Iowa", "IA"],
"52070": ["Sabula", "Iowa", "IA"],
"52071": ["Saint Donatus", "Iowa", "IA"],
"52072": ["Saint Olaf", "Iowa", "IA"],
"52073": ["Sherrill", "Iowa", "IA"],
"52074": ["Spragueville", "Iowa", "IA"],
"52075": ["Springbrook", "Iowa", "IA"],
"52076": ["Strawberry Point", "Iowa", "IA"],
"52077": ["Volga", "Iowa", "IA"],
"52078": ["Worthington", "Iowa", "IA"],
"52079": ["Zwingle", "Iowa", "IA"],
"52101": ["Decorah", "Iowa", "IA"],
"52132": ["Calmar", "Iowa", "IA"],
"52133": ["Castalia", "Iowa", "IA"],
"52134": ["Chester", "Iowa", "IA"],
"52135": ["Clermont", "Iowa", "IA"],
"52136": ["Cresco", "Iowa", "IA"],
"52140": ["Dorchester", "Iowa", "IA"],
"52141": ["Elgin", "Iowa", "IA"],
"52142": ["Fayette", "Iowa", "IA"],
"52144": ["Fort Atkinson", "Iowa", "IA"],
"52146": ["Harpers Ferry", "Iowa", "IA"],
"52147": ["Hawkeye", "Iowa", "IA"],
"52151": ["Lansing", "Iowa", "IA"],
"52154": ["Lawler", "Iowa", "IA"],
"52155": ["Lime Springs", "Iowa", "IA"],
"52156": ["Luana", "Iowa", "IA"],
"52157": ["McGregor", "Iowa", "IA"],
"52158": ["Marquette", "Iowa", "IA"],
"52159": ["Monona", "Iowa", "IA"],
"52160": ["New Albin", "Iowa", "IA"],
"52161": ["Ossian", "Iowa", "IA"],
"52162": ["Postville", "Iowa", "IA"],
"52163": ["Protivin", "Iowa", "IA"],
"52164": ["Randalia", "Iowa", "IA"],
"52165": ["Ridgeway", "Iowa", "IA"],
"52166": ["Saint Lucas", "Iowa", "IA"],
"52168": ["Spillville", "Iowa", "IA"],
"52169": ["Wadena", "Iowa", "IA"],
"52170": ["Waterville", "Iowa", "IA"],
"52171": ["Waucoma", "Iowa", "IA"],
"52172": ["Waukon", "Iowa", "IA"],
"52175": ["West Union", "Iowa", "IA"],
"52201": ["Ainsworth", "Iowa", "IA"],
"52202": ["Alburnett", "Iowa", "IA"],
"52203": ["Amana", "Iowa", "IA"],
"52205": ["Anamosa", "Iowa", "IA"],
"52206": ["Atkins", "Iowa", "IA"],
"52207": ["Baldwin", "Iowa", "IA"],
"52208": ["Belle Plaine", "Iowa", "IA"],
"52209": ["Blairstown", "Iowa", "IA"],
"52210": ["Brandon", "Iowa", "IA"],
"52211": ["Brooklyn", "Iowa", "IA"],
"52212": ["Center Junction", "Iowa", "IA"],
"52213": ["Center Point", "Iowa", "IA"],
"52214": ["Central City", "Iowa", "IA"],
"52215": ["Chelsea", "Iowa", "IA"],
"52216": ["Clarence", "Iowa", "IA"],
"52217": ["Clutier", "Iowa", "IA"],
"52218": ["Coggon", "Iowa", "IA"],
"52219": ["Prairieburg", "Iowa", "IA"],
"52220": ["Conroy", "Iowa", "IA"],
"52221": ["Guernsey", "Iowa", "IA"],
"52222": ["Deep River", "Iowa", "IA"],
"52223": ["Delhi", "Iowa", "IA"],
"52224": ["Dysart", "Iowa", "IA"],
"52225": ["Elberon", "Iowa", "IA"],
"52227": ["Ely", "Iowa", "IA"],
"52228": ["Fairfax", "Iowa", "IA"],
"52229": ["Garrison", "Iowa", "IA"],
"52231": ["Harper", "Iowa", "IA"],
"52232": ["Hartwick", "Iowa", "IA"],
"52233": ["Hiawatha", "Iowa", "IA"],
"52235": ["Hills", "Iowa", "IA"],
"52236": ["Homestead", "Iowa", "IA"],
"52237": ["Hopkinton", "Iowa", "IA"],
"52240": ["Iowa City", "Iowa", "IA"],
"52241": ["Coralville", "Iowa", "IA"],
"52242": ["Iowa City", "Iowa", "IA"],
"52245": ["Iowa City", "Iowa", "IA"],
"52246": ["Iowa City", "Iowa", "IA"],
"52247": ["Kalona", "Iowa", "IA"],
"52248": ["Keota", "Iowa", "IA"],
"52249": ["Keystone", "Iowa", "IA"],
"52251": ["Ladora", "Iowa", "IA"],
"52252": ["Langworthy", "Iowa", "IA"],
"52253": ["Lisbon", "Iowa", "IA"],
"52254": ["Lost Nation", "Iowa", "IA"],
"52255": ["Lowden", "Iowa", "IA"],
"52257": ["Luzerne", "Iowa", "IA"],
"52301": ["Marengo", "Iowa", "IA"],
"52302": ["Marion", "Iowa", "IA"],
"52305": ["Martelle", "Iowa", "IA"],
"52306": ["Mechanicsville", "Iowa", "IA"],
"52307": ["Middle Amana", "Iowa", "IA"],
"52308": ["Millersburg", "Iowa", "IA"],
"52309": ["Monmouth", "Iowa", "IA"],
"52310": ["Monticello", "Iowa", "IA"],
"52312": ["Morley", "Iowa", "IA"],
"52313": ["Mount Auburn", "Iowa", "IA"],
"52314": ["Mount Vernon", "Iowa", "IA"],
"52315": ["Newhall", "Iowa", "IA"],
"52316": ["North English", "Iowa", "IA"],
"52317": ["North Liberty", "Iowa", "IA"],
"52318": ["Norway", "Iowa", "IA"],
"52320": ["Olin", "Iowa", "IA"],
"52321": ["Onslow", "Iowa", "IA"],
"52322": ["Oxford", "Iowa", "IA"],
"52323": ["Oxford Junction", "Iowa", "IA"],
"52324": ["Palo", "Iowa", "IA"],
"52325": ["Parnell", "Iowa", "IA"],
"52326": ["Quasqueton", "Iowa", "IA"],
"52327": ["Riverside", "Iowa", "IA"],
"52328": ["Robins", "Iowa", "IA"],
"52329": ["Rowley", "Iowa", "IA"],
"52330": ["Ryan", "Iowa", "IA"],
"52332": ["Shellsburg", "Iowa", "IA"],
"52333": ["Solon", "Iowa", "IA"],
"52334": ["South Amana", "Iowa", "IA"],
"52335": ["South English", "Iowa", "IA"],
"52336": ["Springville", "Iowa", "IA"],
"52337": ["Stanwood", "Iowa", "IA"],
"52338": ["Swisher", "Iowa", "IA"],
"52339": ["Tama", "Iowa", "IA"],
"52340": ["Tiffin", "Iowa", "IA"],
"52341": ["Toddville", "Iowa", "IA"],
"52342": ["Toledo", "Iowa", "IA"],
"52344": ["Troy Mills", "Iowa", "IA"],
"52345": ["Urbana", "Iowa", "IA"],
"52346": ["Van Horne", "Iowa", "IA"],
"52347": ["Victor", "Iowa", "IA"],
"52348": ["Vining", "Iowa", "IA"],
"52349": ["Vinton", "Iowa", "IA"],
"52351": ["Walford", "Iowa", "IA"],
"52352": ["Walker", "Iowa", "IA"],
"52353": ["Washington", "Iowa", "IA"],
"52354": ["Watkins", "Iowa", "IA"],
"52355": ["Webster", "Iowa", "IA"],
"52356": ["Wellman", "Iowa", "IA"],
"52358": ["West Branch", "Iowa", "IA"],
"52359": ["West Chester", "Iowa", "IA"],
"52361": ["Williamsburg", "Iowa", "IA"],
"52362": ["Wyoming", "Iowa", "IA"],
"52401": ["Cedar Rapids", "Iowa", "IA"],
"52402": ["Cedar Rapids", "Iowa", "IA"],
"52403": ["Cedar Rapids", "Iowa", "IA"],
"52404": ["Cedar Rapids", "Iowa", "IA"],
"52405": ["Cedar Rapids", "Iowa", "IA"],
"52411": ["Cedar Rapids", "Iowa", "IA"],
"52501": ["Ottumwa", "Iowa", "IA"],
"52530": ["Agency", "Iowa", "IA"],
"52531": ["Albia", "Iowa", "IA"],
"52533": ["Batavia", "Iowa", "IA"],
"52534": ["Beacon", "Iowa", "IA"],
"52535": ["Birmingham", "Iowa", "IA"],
"52536": ["Blakesburg", "Iowa", "IA"],
"52537": ["Bloomfield", "Iowa", "IA"],
"52540": ["Brighton", "Iowa", "IA"],
"52542": ["Cantril", "Iowa", "IA"],
"52543": ["Cedar", "Iowa", "IA"],
"52544": ["Centerville", "Iowa", "IA"],
"52548": ["Chillicothe", "Iowa", "IA"],
"52549": ["Cincinnati", "Iowa", "IA"],
"52550": ["Delta", "Iowa", "IA"],
"52551": ["Douds", "Iowa", "IA"],
"52552": ["Drakesville", "Iowa", "IA"],
"52553": ["Eddyville", "Iowa", "IA"],
"52554": ["Eldon", "Iowa", "IA"],
"52555": ["Exline", "Iowa", "IA"],
"52556": ["Fairfield", "Iowa", "IA"],
"52557": ["Fairfield", "Iowa", "IA"],
"52560": ["Floris", "Iowa", "IA"],
"52561": ["Fremont", "Iowa", "IA"],
"52562": ["Hayesville", "Iowa", "IA"],
"52563": ["Hedrick", "Iowa", "IA"],
"52565": ["Keosauqua", "Iowa", "IA"],
"52566": ["Kirkville", "Iowa", "IA"],
"52567": ["Libertyville", "Iowa", "IA"],
"52568": ["Martinsburg", "Iowa", "IA"],
"52569": ["Melrose", "Iowa", "IA"],
"52570": ["Milton", "Iowa", "IA"],
"52571": ["Moravia", "Iowa", "IA"],
"52572": ["Moulton", "Iowa", "IA"],
"52573": ["Mount Sterling", "Iowa", "IA"],
"52574": ["Mystic", "Iowa", "IA"],
"52576": ["Ollie", "Iowa", "IA"],
"52577": ["Oskaloosa", "Iowa", "IA"],
"52580": ["Packwood", "Iowa", "IA"],
"52581": ["Plano", "Iowa", "IA"],
"52583": ["Promise City", "Iowa", "IA"],
"52584": ["Pulaski", "Iowa", "IA"],
"52585": ["Richland", "Iowa", "IA"],
"52586": ["Rose Hill", "Iowa", "IA"],
"52588": ["Selma", "Iowa", "IA"],
"52590": ["Seymour", "Iowa", "IA"],
"52591": ["Sigourney", "Iowa", "IA"],
"52593": ["Udell", "Iowa", "IA"],
"52594": ["Unionville", "Iowa", "IA"],
"52595": ["University Park", "Iowa", "IA"],
"52601": ["Burlington", "Iowa", "IA"],
"52619": ["Argyle", "Iowa", "IA"],
"52620": ["Bonaparte", "Iowa", "IA"],
"52621": ["Crawfordsville", "Iowa", "IA"],
"52623": ["Danville", "Iowa", "IA"],
"52624": ["Denmark", "Iowa", "IA"],
"52625": ["Donnellson", "Iowa", "IA"],
"52626": ["Farmington", "Iowa", "IA"],
"52627": ["Fort Madison", "Iowa", "IA"],
"52630": ["Hillsboro", "Iowa", "IA"],
"52631": ["Houghton", "Iowa", "IA"],
"52632": ["Keokuk", "Iowa", "IA"],
"52635": ["Lockridge", "Iowa", "IA"],
"52637": ["Mediapolis", "Iowa", "IA"],
"52638": ["Middletown", "Iowa", "IA"],
"52639": ["Montrose", "Iowa", "IA"],
"52640": ["Morning Sun", "Iowa", "IA"],
"52641": ["Mount Pleasant", "Iowa", "IA"],
"52644": ["Mount Union", "Iowa", "IA"],
"52645": ["New London", "Iowa", "IA"],
"52646": ["Oakville", "Iowa", "IA"],
"52647": ["Olds", "Iowa", "IA"],
"52649": ["Salem", "Iowa", "IA"],
"52650": ["Sperry", "Iowa", "IA"],
"52651": ["Stockport", "Iowa", "IA"],
"52652": ["Swedesburg", "Iowa", "IA"],
"52653": ["Wapello", "Iowa", "IA"],
"52654": ["Wayland", "Iowa", "IA"],
"52655": ["West Burlington", "Iowa", "IA"],
"52656": ["West Point", "Iowa", "IA"],
"52657": ["Saint Paul", "Iowa", "IA"],
"52658": ["Wever", "Iowa", "IA"],
"52659": ["Winfield", "Iowa", "IA"],
"52660": ["Yarmouth", "Iowa", "IA"],
"52701": ["Andover", "Iowa", "IA"],
"52720": ["Atalissa", "Iowa", "IA"],
"52721": ["Bennett", "Iowa", "IA"],
"52722": ["Bettendorf", "Iowa", "IA"],
"52726": ["Blue Grass", "Iowa", "IA"],
"52727": ["Bryant", "Iowa", "IA"],
"52728": ["Buffalo", "Iowa", "IA"],
"52729": ["Calamus", "Iowa", "IA"],
"52730": ["Camanche", "Iowa", "IA"],
"52731": ["Charlotte", "Iowa", "IA"],
"52732": ["Clinton", "Iowa", "IA"],
"52737": ["Columbus City", "Iowa", "IA"],
"52738": ["Columbus Junction", "Iowa", "IA"],
"52739": ["Conesville", "Iowa", "IA"],
"52742": ["De Witt", "Iowa", "IA"],
"52745": ["Dixon", "Iowa", "IA"],
"52746": ["Donahue", "Iowa", "IA"],
"52747": ["Durant", "Iowa", "IA"],
"52748": ["Eldridge", "Iowa", "IA"],
"52749": ["Fruitland", "Iowa", "IA"],
"52750": ["Goose Lake", "Iowa", "IA"],
"52751": ["Grand Mound", "Iowa", "IA"],
"52752": ["Grandview", "Iowa", "IA"],
"52753": ["Le Claire", "Iowa", "IA"],
"52754": ["Letts", "Iowa", "IA"],
"52755": ["Lone Tree", "Iowa", "IA"],
"52756": ["Long Grove", "Iowa", "IA"],
"52757": ["Low Moor", "Iowa", "IA"],
"52758": ["McCausland", "Iowa", "IA"],
"52759": ["Montpelier", "Iowa", "IA"],
"52760": ["Moscow", "Iowa", "IA"],
"52761": ["Muscatine", "Iowa", "IA"],
"52765": ["New Liberty", "Iowa", "IA"],
"52766": ["Nichols", "Iowa", "IA"],
"52767": ["Pleasant Valley", "Iowa", "IA"],
"52768": ["Princeton", "Iowa", "IA"],
"52769": ["Stockton", "Iowa", "IA"],
"52771": ["Teeds Grove", "Iowa", "IA"],
"52772": ["Tipton", "Iowa", "IA"],
"52773": ["Walcott", "Iowa", "IA"],
"52774": ["Welton", "Iowa", "IA"],
"52776": ["West Liberty", "Iowa", "IA"],
"52777": ["Wheatland", "Iowa", "IA"],
"52778": ["Wilton", "Iowa", "IA"],
"52801": ["Davenport", "Iowa", "IA"],
"52802": ["Davenport", "Iowa", "IA"],
"52803": ["Davenport", "Iowa", "IA"],
"52804": ["Davenport", "Iowa", "IA"],
"52806": ["Davenport", "Iowa", "IA"],
"52807": ["Davenport", "Iowa", "IA"],
"53001": ["Adell", "Wisconsin", "WI"],
"53002": ["Allenton", "Wisconsin", "WI"],
"53003": ["Ashippun", "Wisconsin", "WI"],
"53004": ["Belgium", "Wisconsin", "WI"],
"53005": ["Brookfield", "Wisconsin", "WI"],
"53006": ["Brownsville", "Wisconsin", "WI"],
"53007": ["Butler", "Wisconsin", "WI"],
"53010": ["Campbellsport", "Wisconsin", "WI"],
"53011": ["Cascade", "Wisconsin", "WI"],
"53012": ["Cedarburg", "Wisconsin", "WI"],
"53013": ["Cedar Grove", "Wisconsin", "WI"],
"53014": ["Chilton", "Wisconsin", "WI"],
"53015": ["Cleveland", "Wisconsin", "WI"],
"53016": ["Clyman", "Wisconsin", "WI"],
"53017": ["Colgate", "Wisconsin", "WI"],
"53018": ["Delafield", "Wisconsin", "WI"],
"53019": ["Eden", "Wisconsin", "WI"],
"53020": ["Elkhart Lake", "Wisconsin", "WI"],
"53021": ["Fredonia", "Wisconsin", "WI"],
"53022": ["Germantown", "Wisconsin", "WI"],
"53023": ["Glenbeulah", "Wisconsin", "WI"],
"53024": ["Grafton", "Wisconsin", "WI"],
"53027": ["Hartford", "Wisconsin", "WI"],
"53029": ["Hartland", "Wisconsin", "WI"],
"53031": ["Hingham", "Wisconsin", "WI"],
"53032": ["Horicon", "Wisconsin", "WI"],
"53033": ["Hubertus", "Wisconsin", "WI"],
"53034": ["Hustisford", "Wisconsin", "WI"],
"53035": ["Iron Ridge", "Wisconsin", "WI"],
"53036": ["Ixonia", "Wisconsin", "WI"],
"53037": ["Jackson", "Wisconsin", "WI"],
"53038": ["Johnson Creek", "Wisconsin", "WI"],
"53039": ["Juneau", "Wisconsin", "WI"],
"53040": ["Kewaskum", "Wisconsin", "WI"],
"53042": ["Kiel", "Wisconsin", "WI"],
"53044": ["Kohler", "Wisconsin", "WI"],
"53045": ["Brookfield", "Wisconsin", "WI"],
"53046": ["Lannon", "Wisconsin", "WI"],
"53047": ["Lebanon", "Wisconsin", "WI"],
"53048": ["Lomira", "Wisconsin", "WI"],
"53049": ["Malone", "Wisconsin", "WI"],
"53050": ["Mayville", "Wisconsin", "WI"],
"53051": ["Menomonee Falls", "Wisconsin", "WI"],
"53057": ["Mount Calvary", "Wisconsin", "WI"],
"53058": ["Nashotah", "Wisconsin", "WI"],
"53059": ["Neosho", "Wisconsin", "WI"],
"53061": ["New Holstein", "Wisconsin", "WI"],
"53063": ["Newton", "Wisconsin", "WI"],
"53065": ["Oakfield", "Wisconsin", "WI"],
"53066": ["Oconomowoc", "Wisconsin", "WI"],
"53069": ["Okauchee", "Wisconsin", "WI"],
"53070": ["Oostburg", "Wisconsin", "WI"],
"53072": ["Pewaukee", "Wisconsin", "WI"],
"53073": ["Plymouth", "Wisconsin", "WI"],
"53074": ["Port Washington", "Wisconsin", "WI"],
"53075": ["Random Lake", "Wisconsin", "WI"],
"53076": ["Richfield", "Wisconsin", "WI"],
"53078": ["Rubicon", "Wisconsin", "WI"],
"53079": ["Saint Cloud", "Wisconsin", "WI"],
"53080": ["Saukville", "Wisconsin", "WI"],
"53081": ["Sheboygan", "Wisconsin", "WI"],
"53083": ["Sheboygan", "Wisconsin", "WI"],
"53085": ["Sheboygan Falls", "Wisconsin", "WI"],
"53086": ["Slinger", "Wisconsin", "WI"],
"53088": ["Stockbridge", "Wisconsin", "WI"],
"53089": ["Sussex", "Wisconsin", "WI"],
"53090": ["West Bend", "Wisconsin", "WI"],
"53091": ["Theresa", "Wisconsin", "WI"],
"53092": ["Mequon", "Wisconsin", "WI"],
"53093": ["Waldo", "Wisconsin", "WI"],
"53094": ["Watertown", "Wisconsin", "WI"],
"53095": ["West Bend", "Wisconsin", "WI"],
"53097": ["Mequon", "Wisconsin", "WI"],
"53098": ["Watertown", "Wisconsin", "WI"],
"53099": ["Woodland", "Wisconsin", "WI"],
"53103": ["Big Bend", "Wisconsin", "WI"],
"53104": ["Bristol", "Wisconsin", "WI"],
"53105": ["Burlington", "Wisconsin", "WI"],
"53108": ["Caledonia", "Wisconsin", "WI"],
"53110": ["Cudahy", "Wisconsin", "WI"],
"53114": ["Darien", "Wisconsin", "WI"],
"53115": ["Delavan", "Wisconsin", "WI"],
"53118": ["Dousman", "Wisconsin", "WI"],
"53119": ["Eagle", "Wisconsin", "WI"],
"53120": ["East Troy", "Wisconsin", "WI"],
"53121": ["Elkhorn", "Wisconsin", "WI"],
"53122": ["Elm Grove", "Wisconsin", "WI"],
"53125": ["Fontana", "Wisconsin", "WI"],
"53126": ["Franksville", "Wisconsin", "WI"],
"53127": ["Genesee Depot", "Wisconsin", "WI"],
"53128": ["Genoa City", "Wisconsin", "WI"],
"53129": ["Greendale", "Wisconsin", "WI"],
"53130": ["Hales Corners", "Wisconsin", "WI"],
"53132": ["Franklin", "Wisconsin", "WI"],
"53137": ["Helenville", "Wisconsin", "WI"],
"53139": ["Kansasville", "Wisconsin", "WI"],
"53140": ["Kenosha", "Wisconsin", "WI"],
"53142": ["Kenosha", "Wisconsin", "WI"],
"53143": ["Kenosha", "Wisconsin", "WI"],
"53144": ["Kenosha", "Wisconsin", "WI"],
"53146": ["New Berlin", "Wisconsin", "WI"],
"53147": ["Lake Geneva", "Wisconsin", "WI"],
"53149": ["Mukwonago", "Wisconsin", "WI"],
"53150": ["Muskego", "Wisconsin", "WI"],
"53151": ["New Berlin", "Wisconsin", "WI"],
"53153": ["North Prairie", "Wisconsin", "WI"],
"53154": ["Oak Creek", "Wisconsin", "WI"],
"53156": ["Palmyra", "Wisconsin", "WI"],
"53157": ["Pell Lake", "Wisconsin", "WI"],
"53158": ["Pleasant Prairie", "Wisconsin", "WI"],
"53167": ["Rochester", "Wisconsin", "WI"],
"53168": ["Salem", "Wisconsin", "WI"],
"53170": ["Silver Lake", "Wisconsin", "WI"],
"53172": ["South Milwaukee", "Wisconsin", "WI"],
"53176": ["Springfield", "Wisconsin", "WI"],
"53177": ["Sturtevant", "Wisconsin", "WI"],
"53178": ["Sullivan", "Wisconsin", "WI"],
"53179": ["Trevor", "Wisconsin", "WI"],
"53181": ["Twin Lakes", "Wisconsin", "WI"],
"53182": ["Union Grove", "Wisconsin", "WI"],
"53183": ["Wales", "Wisconsin", "WI"],
"53184": ["Walworth", "Wisconsin", "WI"],
"53185": ["Waterford", "Wisconsin", "WI"],
"53186": ["Waukesha", "Wisconsin", "WI"],
"53188": ["Waukesha", "Wisconsin", "WI"],
"53189": ["Waukesha", "Wisconsin", "WI"],
"53190": ["Whitewater", "Wisconsin", "WI"],
"53191": ["Williams Bay", "Wisconsin", "WI"],
"53192": ["Wilmot", "Wisconsin", "WI"],
"53195": ["Zenda", "Wisconsin", "WI"],
"53202": ["Milwaukee", "Wisconsin", "WI"],
"53203": ["Milwaukee", "Wisconsin", "WI"],
"53204": ["Milwaukee", "Wisconsin", "WI"],
"53205": ["Milwaukee", "Wisconsin", "WI"],
"53206": ["Milwaukee", "Wisconsin", "WI"],
"53207": ["Milwaukee", "Wisconsin", "WI"],
"53208": ["Milwaukee", "Wisconsin", "WI"],
"53209": ["Milwaukee", "Wisconsin", "WI"],
"53210": ["Milwaukee", "Wisconsin", "WI"],
"53211": ["Milwaukee", "Wisconsin", "WI"],
"53212": ["Milwaukee", "Wisconsin", "WI"],
"53213": ["Milwaukee", "Wisconsin", "WI"],
"53214": ["Milwaukee", "Wisconsin", "WI"],
"53215": ["Milwaukee", "Wisconsin", "WI"],
"53216": ["Milwaukee", "Wisconsin", "WI"],
"53217": ["Milwaukee", "Wisconsin", "WI"],
"53218": ["Milwaukee", "Wisconsin", "WI"],
"53219": ["Milwaukee", "Wisconsin", "WI"],
"53220": ["Milwaukee", "Wisconsin", "WI"],
"53221": ["Milwaukee", "Wisconsin", "WI"],
"53222": ["Milwaukee", "Wisconsin", "WI"],
"53223": ["Milwaukee", "Wisconsin", "WI"],
"53224": ["Milwaukee", "Wisconsin", "WI"],
"53225": ["Milwaukee", "Wisconsin", "WI"],
"53226": ["Milwaukee", "Wisconsin", "WI"],
"53227": ["Milwaukee", "Wisconsin", "WI"],
"53228": ["Milwaukee", "Wisconsin", "WI"],
"53233": ["Milwaukee", "Wisconsin", "WI"],
"53235": ["Saint Francis", "Wisconsin", "WI"],
"53295": ["Milwaukee", "Wisconsin", "WI"],
"53402": ["Racine", "Wisconsin", "WI"],
"53403": ["Racine", "Wisconsin", "WI"],
"53404": ["Racine", "Wisconsin", "WI"],
"53405": ["Racine", "Wisconsin", "WI"],
"53406": ["Racine", "Wisconsin", "WI"],
"53501": ["Afton", "Wisconsin", "WI"],
"53502": ["Albany", "Wisconsin", "WI"],
"53503": ["Arena", "Wisconsin", "WI"],
"53504": ["Argyle", "Wisconsin", "WI"],
"53505": ["Avalon", "Wisconsin", "WI"],
"53506": ["Avoca", "Wisconsin", "WI"],
"53507": ["Barneveld", "Wisconsin", "WI"],
"53508": ["Belleville", "Wisconsin", "WI"],
"53510": ["Belmont", "Wisconsin", "WI"],
"53511": ["Beloit", "Wisconsin", "WI"],
"53515": ["Black Earth", "Wisconsin", "WI"],
"53516": ["Blanchardville", "Wisconsin", "WI"],
"53517": ["Blue Mounds", "Wisconsin", "WI"],
"53518": ["Blue River", "Wisconsin", "WI"],
"53520": ["Brodhead", "Wisconsin", "WI"],
"53521": ["Brooklyn", "Wisconsin", "WI"],
"53522": ["Browntown", "Wisconsin", "WI"],
"53523": ["Cambridge", "Wisconsin", "WI"],
"53525": ["Clinton", "Wisconsin", "WI"],
"53526": ["Cobb", "Wisconsin", "WI"],
"53527": ["Cottage Grove", "Wisconsin", "WI"],
"53528": ["Cross Plains", "Wisconsin", "WI"],
"53529": ["Dane", "Wisconsin", "WI"],
"53530": ["Darlington", "Wisconsin", "WI"],
"53531": ["Deerfield", "Wisconsin", "WI"],
"53532": ["Deforest", "Wisconsin", "WI"],
"53533": ["Dodgeville", "Wisconsin", "WI"],
"53534": ["Edgerton", "Wisconsin", "WI"],
"53536": ["Evansville", "Wisconsin", "WI"],
"53537": ["Footville", "Wisconsin", "WI"],
"53538": ["Fort Atkinson", "Wisconsin", "WI"],
"53540": ["Gotham", "Wisconsin", "WI"],
"53541": ["Gratiot", "Wisconsin", "WI"],
"53543": ["Highland", "Wisconsin", "WI"],
"53544": ["Hollandale", "Wisconsin", "WI"],
"53545": ["Janesville", "Wisconsin", "WI"],
"53546": ["Janesville", "Wisconsin", "WI"],
"53548": ["Janesville", "Wisconsin", "WI"],
"53549": ["Jefferson", "Wisconsin", "WI"],
"53550": ["Juda", "Wisconsin", "WI"],
"53551": ["Lake Mills", "Wisconsin", "WI"],
"53553": ["Linden", "Wisconsin", "WI"],
"53554": ["Livingston", "Wisconsin", "WI"],
"53555": ["Lodi", "Wisconsin", "WI"],
"53556": ["Lone Rock", "Wisconsin", "WI"],
"53557": ["Lowell", "Wisconsin", "WI"],
"53558": ["Mcfarland", "Wisconsin", "WI"],
"53559": ["Marshall", "Wisconsin", "WI"],
"53560": ["Mazomanie", "Wisconsin", "WI"],
"53561": ["Merrimac", "Wisconsin", "WI"],
"53562": ["Middleton", "Wisconsin", "WI"],
"53563": ["Milton", "Wisconsin", "WI"],
"53565": ["Mineral Point", "Wisconsin", "WI"],
"53566": ["Monroe", "Wisconsin", "WI"],
"53569": ["Montfort", "Wisconsin", "WI"],
"53570": ["Monticello", "Wisconsin", "WI"],
"53571": ["Morrisonville", "Wisconsin", "WI"],
"53572": ["Mount Horeb", "Wisconsin", "WI"],
"53573": ["Muscoda", "Wisconsin", "WI"],
"53574": ["New Glarus", "Wisconsin", "WI"],
"53575": ["Oregon", "Wisconsin", "WI"],
"53576": ["Orfordville", "Wisconsin", "WI"],
"53577": ["Plain", "Wisconsin", "WI"],
"53578": ["Prairie Du Sac", "Wisconsin", "WI"],
"53579": ["Reeseville", "Wisconsin", "WI"],
"53580": ["Rewey", "Wisconsin", "WI"],
"53581": ["Richland Center", "Wisconsin", "WI"],
"53582": ["Ridgeway", "Wisconsin", "WI"],
"53583": ["Sauk City", "Wisconsin", "WI"],
"53584": ["Sextonville", "Wisconsin", "WI"],
"53585": ["Sharon", "Wisconsin", "WI"],
"53586": ["Shullsburg", "Wisconsin", "WI"],
"53587": ["South Wayne", "Wisconsin", "WI"],
"53588": ["Spring Green", "Wisconsin", "WI"],
"53589": ["Stoughton", "Wisconsin", "WI"],
"53590": ["Sun Prairie", "Wisconsin", "WI"],
"53593": ["Verona", "Wisconsin", "WI"],
"53594": ["Waterloo", "Wisconsin", "WI"],
"53597": ["Waunakee", "Wisconsin", "WI"],
"53598": ["Windsor", "Wisconsin", "WI"],
"53599": ["Woodford", "Wisconsin", "WI"],
"53703": ["Madison", "Wisconsin", "WI"],
"53704": ["Madison", "Wisconsin", "WI"],
"53705": ["Madison", "Wisconsin", "WI"],
"53706": ["Madison", "Wisconsin", "WI"],
"53711": ["Madison", "Wisconsin", "WI"],
"53713": ["Madison", "Wisconsin", "WI"],
"53714": ["Madison", "Wisconsin", "WI"],
"53715": ["Madison", "Wisconsin", "WI"],
"53716": ["Madison", "Wisconsin", "WI"],
"53717": ["Madison", "Wisconsin", "WI"],
"53718": ["Madison", "Wisconsin", "WI"],
"53719": ["Madison", "Wisconsin", "WI"],
"53726": ["Madison", "Wisconsin", "WI"],
"53792": ["Madison", "Wisconsin", "WI"],
"53801": ["Bagley", "Wisconsin", "WI"],
"53802": ["Beetown", "Wisconsin", "WI"],
"53803": ["Benton", "Wisconsin", "WI"],
"53804": ["Bloomington", "Wisconsin", "WI"],
"53805": ["Boscobel", "Wisconsin", "WI"],
"53806": ["Cassville", "Wisconsin", "WI"],
"53807": ["Cuba City", "Wisconsin", "WI"],
"53808": ["Dickeyville", "Wisconsin", "WI"],
"53809": ["Fennimore", "Wisconsin", "WI"],
"53810": ["Glen Haven", "Wisconsin", "WI"],
"53811": ["Hazel Green", "Wisconsin", "WI"],
"53812": ["Kieler", "Wisconsin", "WI"],
"53813": ["Lancaster", "Wisconsin", "WI"],
"53816": ["Mount Hope", "Wisconsin", "WI"],
"53817": ["Patch Grove", "Wisconsin", "WI"],
"53818": ["Platteville", "Wisconsin", "WI"],
"53820": ["Potosi", "Wisconsin", "WI"],
"53821": ["Prairie Du Chien", "Wisconsin", "WI"],
"53825": ["Stitzer", "Wisconsin", "WI"],
"53826": ["Wauzeka", "Wisconsin", "WI"],
"53827": ["Woodman", "Wisconsin", "WI"],
"53901": ["Portage", "Wisconsin", "WI"],
"53910": ["Adams", "Wisconsin", "WI"],
"53911": ["Arlington", "Wisconsin", "WI"],
"53913": ["Baraboo", "Wisconsin", "WI"],
"53916": ["Beaver Dam", "Wisconsin", "WI"],
"53919": ["Brandon", "Wisconsin", "WI"],
"53920": ["Briggsville", "Wisconsin", "WI"],
"53922": ["Burnett", "Wisconsin", "WI"],
"53923": ["Cambria", "Wisconsin", "WI"],
"53924": ["Cazenovia", "Wisconsin", "WI"],
"53925": ["Columbus", "Wisconsin", "WI"],
"53926": ["Dalton", "Wisconsin", "WI"],
"53928": ["Doylestown", "Wisconsin", "WI"],
"53929": ["Elroy", "Wisconsin", "WI"],
"53930": ["Endeavor", "Wisconsin", "WI"],
"53931": ["Fairwater", "Wisconsin", "WI"],
"53932": ["Fall River", "Wisconsin", "WI"],
"53933": ["Fox Lake", "Wisconsin", "WI"],
"53934": ["Friendship", "Wisconsin", "WI"],
"53935": ["Friesland", "Wisconsin", "WI"],
"53936": ["Grand Marsh", "Wisconsin", "WI"],
"53937": ["Hillpoint", "Wisconsin", "WI"],
"53939": ["Kingston", "Wisconsin", "WI"],
"53940": ["Lake Delton", "Wisconsin", "WI"],
"53941": ["La Valle", "Wisconsin", "WI"],
"53942": ["Lime Ridge", "Wisconsin", "WI"],
"53943": ["Loganville", "Wisconsin", "WI"],
"53944": ["Lyndon Station", "Wisconsin", "WI"],
"53946": ["Markesan", "Wisconsin", "WI"],
"53947": ["Marquette", "Wisconsin", "WI"],
"53948": ["Mauston", "Wisconsin", "WI"],
"53949": ["Montello", "Wisconsin", "WI"],
"53950": ["New Lisbon", "Wisconsin", "WI"],
"53951": ["North Freedom", "Wisconsin", "WI"],
"53952": ["Oxford", "Wisconsin", "WI"],
"53953": ["Packwaukee", "Wisconsin", "WI"],
"53954": ["Pardeeville", "Wisconsin", "WI"],
"53955": ["Poynette", "Wisconsin", "WI"],
"53956": ["Randolph", "Wisconsin", "WI"],
"53959": ["Reedsburg", "Wisconsin", "WI"],
"53960": ["Rio", "Wisconsin", "WI"],
"53961": ["Rock Springs", "Wisconsin", "WI"],
"53962": ["Union Center", "Wisconsin", "WI"],
"53963": ["Waupun", "Wisconsin", "WI"],
"53964": ["Westfield", "Wisconsin", "WI"],
"53965": ["Wisconsin Dells", "Wisconsin", "WI"],
"53968": ["Wonewoc", "Wisconsin", "WI"],
"53969": ["Wyocena", "Wisconsin", "WI"],
"54001": ["Amery", "Wisconsin", "WI"],
"54002": ["Baldwin", "Wisconsin", "WI"],
"54003": ["Beldenville", "Wisconsin", "WI"],
"54004": ["Clayton", "Wisconsin", "WI"],
"54005": ["Clear Lake", "Wisconsin", "WI"],
"54006": ["Cushing", "Wisconsin", "WI"],
"54007": ["Deer Park", "Wisconsin", "WI"],
"54009": ["Dresser", "Wisconsin", "WI"],
"54011": ["Ellsworth", "Wisconsin", "WI"],
"54013": ["Glenwood City", "Wisconsin", "WI"],
"54014": ["Hager City", "Wisconsin", "WI"],
"54015": ["Hammond", "Wisconsin", "WI"],
"54016": ["Hudson", "Wisconsin", "WI"],
"54017": ["New Richmond", "Wisconsin", "WI"],
"54020": ["Osceola", "Wisconsin", "WI"],
"54021": ["Prescott", "Wisconsin", "WI"],
"54022": ["River Falls", "Wisconsin", "WI"],
"54023": ["Roberts", "Wisconsin", "WI"],
"54024": ["Saint Croix Falls", "Wisconsin", "WI"],
"54025": ["Somerset", "Wisconsin", "WI"],
"54026": ["Star Prairie", "Wisconsin", "WI"],
"54027": ["Wilson", "Wisconsin", "WI"],
"54028": ["Woodville", "Wisconsin", "WI"],
"54082": ["Houlton", "Wisconsin", "WI"],
"54101": ["Abrams", "Wisconsin", "WI"],
"54102": ["Amberg", "Wisconsin", "WI"],
"54103": ["Armstrong Creek", "Wisconsin", "WI"],
"54104": ["Athelstane", "Wisconsin", "WI"],
"54106": ["Black Creek", "Wisconsin", "WI"],
"54107": ["Bonduel", "Wisconsin", "WI"],
"54110": ["Brillion", "Wisconsin", "WI"],
"54111": ["Cecil", "Wisconsin", "WI"],
"54112": ["Coleman", "Wisconsin", "WI"],
"54113": ["Combined Locks", "Wisconsin", "WI"],
"54114": ["Crivitz", "Wisconsin", "WI"],
"54115": ["De Pere", "Wisconsin", "WI"],
"54119": ["Dunbar", "Wisconsin", "WI"],
"54120": ["Fence", "Wisconsin", "WI"],
"54121": ["Florence", "Wisconsin", "WI"],
"54123": ["Forest Junction", "Wisconsin", "WI"],
"54124": ["Gillett", "Wisconsin", "WI"],
"54125": ["Goodman", "Wisconsin", "WI"],
"54126": ["Greenleaf", "Wisconsin", "WI"],
"54127": ["Green Valley", "Wisconsin", "WI"],
"54128": ["Gresham", "Wisconsin", "WI"],
"54129": ["Hilbert", "Wisconsin", "WI"],
"54130": ["Kaukauna", "Wisconsin", "WI"],
"54135": ["Keshena", "Wisconsin", "WI"],
"54136": ["Kimberly", "Wisconsin", "WI"],
"54137": ["Krakow", "Wisconsin", "WI"],
"54138": ["Lakewood", "Wisconsin", "WI"],
"54139": ["Lena", "Wisconsin", "WI"],
"54140": ["Little Chute", "Wisconsin", "WI"],
"54141": ["Little Suamico", "Wisconsin", "WI"],
"54143": ["Marinette", "Wisconsin", "WI"],
"54149": ["Mountain", "Wisconsin", "WI"],
"54150": ["Neopit", "Wisconsin", "WI"],
"54151": ["Niagara", "Wisconsin", "WI"],
"54152": ["Nichols", "Wisconsin", "WI"],
"54153": ["Oconto", "Wisconsin", "WI"],
"54154": ["Oconto Falls", "Wisconsin", "WI"],
"54155": ["Oneida", "Wisconsin", "WI"],
"54156": ["Pembine", "Wisconsin", "WI"],
"54157": ["Peshtigo", "Wisconsin", "WI"],
"54159": ["Porterfield", "Wisconsin", "WI"],
"54160": ["Potter", "Wisconsin", "WI"],
"54161": ["Pound", "Wisconsin", "WI"],
"54162": ["Pulaski", "Wisconsin", "WI"],
"54165": ["Seymour", "Wisconsin", "WI"],
"54166": ["Shawano", "Wisconsin", "WI"],
"54169": ["Sherwood", "Wisconsin", "WI"],
"54170": ["Shiocton", "Wisconsin", "WI"],
"54171": ["Sobieski", "Wisconsin", "WI"],
"54173": ["Suamico", "Wisconsin", "WI"],
"54174": ["Suring", "Wisconsin", "WI"],
"54175": ["Townsend", "Wisconsin", "WI"],
"54177": ["Wausaukee", "Wisconsin", "WI"],
"54180": ["Wrightstown", "Wisconsin", "WI"],
"54201": ["Algoma", "Wisconsin", "WI"],
"54202": ["Baileys Harbor", "Wisconsin", "WI"],
"54204": ["Brussels", "Wisconsin", "WI"],
"54205": ["Casco", "Wisconsin", "WI"],
"54207": ["Collins", "Wisconsin", "WI"],
"54208": ["Denmark", "Wisconsin", "WI"],
"54209": ["Egg Harbor", "Wisconsin", "WI"],
"54210": ["Ellison Bay", "Wisconsin", "WI"],
"54211": ["Ephraim", "Wisconsin", "WI"],
"54212": ["Fish Creek", "Wisconsin", "WI"],
"54213": ["Forestville", "Wisconsin", "WI"],
"54214": ["Francis Creek", "Wisconsin", "WI"],
"54215": ["Kellnersville", "Wisconsin", "WI"],
"54216": ["Kewaunee", "Wisconsin", "WI"],
"54217": ["Luxemburg", "Wisconsin", "WI"],
"54220": ["Manitowoc", "Wisconsin", "WI"],
"54227": ["Maribel", "Wisconsin", "WI"],
"54228": ["Mishicot", "Wisconsin", "WI"],
"54229": ["New Franken", "Wisconsin", "WI"],
"54230": ["Reedsville", "Wisconsin", "WI"],
"54232": ["Saint Nazianz", "Wisconsin", "WI"],
"54234": ["Sister Bay", "Wisconsin", "WI"],
"54235": ["Sturgeon Bay", "Wisconsin", "WI"],
"54241": ["Two Rivers", "Wisconsin", "WI"],
"54245": ["Valders", "Wisconsin", "WI"],
"54246": ["Washington Island", "Wisconsin", "WI"],
"54247": ["Whitelaw", "Wisconsin", "WI"],
"54301": ["Green Bay", "Wisconsin", "WI"],
"54302": ["Green Bay", "Wisconsin", "WI"],
"54303": ["Green Bay", "Wisconsin", "WI"],
"54304": ["Green Bay", "Wisconsin", "WI"],
"54307": ["Green Bay", "Wisconsin", "WI"],
"54311": ["Green Bay", "Wisconsin", "WI"],
"54313": ["Green Bay", "Wisconsin", "WI"],
"54401": ["Wausau", "Wisconsin", "WI"],
"54403": ["Wausau", "Wisconsin", "WI"],
"54405": ["Abbotsford", "Wisconsin", "WI"],
"54406": ["Amherst", "Wisconsin", "WI"],
"54407": ["Amherst Junction", "Wisconsin", "WI"],
"54408": ["Aniwa", "Wisconsin", "WI"],
"54409": ["Antigo", "Wisconsin", "WI"],
"54410": ["Arpin", "Wisconsin", "WI"],
"54411": ["Athens", "Wisconsin", "WI"],
"54412": ["Auburndale", "Wisconsin", "WI"],
"54413": ["Babcock", "Wisconsin", "WI"],
"54414": ["Birnamwood", "Wisconsin", "WI"],
"54416": ["Bowler", "Wisconsin", "WI"],
"54417": ["Brokaw", "Wisconsin", "WI"],
"54418": ["Bryant", "Wisconsin", "WI"],
"54420": ["Chili", "Wisconsin", "WI"],
"54421": ["Colby", "Wisconsin", "WI"],
"54422": ["Curtiss", "Wisconsin", "WI"],
"54423": ["Custer", "Wisconsin", "WI"],
"54424": ["Deerbrook", "Wisconsin", "WI"],
"54425": ["Dorchester", "Wisconsin", "WI"],
"54426": ["Edgar", "Wisconsin", "WI"],
"54427": ["Eland", "Wisconsin", "WI"],
"54428": ["Elcho", "Wisconsin", "WI"],
"54430": ["Elton", "Wisconsin", "WI"],
"54433": ["Gilman", "Wisconsin", "WI"],
"54435": ["Gleason", "Wisconsin", "WI"],
"54436": ["Granton", "Wisconsin", "WI"],
"54437": ["Greenwood", "Wisconsin", "WI"],
"54440": ["Hatley", "Wisconsin", "WI"],
"54441": ["Hewitt", "Wisconsin", "WI"],
"54442": ["Irma", "Wisconsin", "WI"],
"54443": ["Junction City", "Wisconsin", "WI"],
"54446": ["Loyal", "Wisconsin", "WI"],
"54447": ["Lublin", "Wisconsin", "WI"],
"54448": ["Marathon", "Wisconsin", "WI"],
"54449": ["Marshfield", "Wisconsin", "WI"],
"54450": ["Mattoon", "Wisconsin", "WI"],
"54451": ["Medford", "Wisconsin", "WI"],
"54452": ["Merrill", "Wisconsin", "WI"],
"54454": ["Milladore", "Wisconsin", "WI"],
"54455": ["Mosinee", "Wisconsin", "WI"],
"54456": ["Neillsville", "Wisconsin", "WI"],
"54457": ["Nekoosa", "Wisconsin", "WI"],
"54458": ["Nelsonville", "Wisconsin", "WI"],
"54459": ["Ogema", "Wisconsin", "WI"],
"54460": ["Owen", "Wisconsin", "WI"],
"54462": ["Pearson", "Wisconsin", "WI"],
"54463": ["Pelican Lake", "Wisconsin", "WI"],
"54464": ["Phlox", "Wisconsin", "WI"],
"54465": ["Pickerel", "Wisconsin", "WI"],
"54466": ["Pittsville", "Wisconsin", "WI"],
"54467": ["Plover", "Wisconsin", "WI"],
"54469": ["Port Edwards", "Wisconsin", "WI"],
"54470": ["Rib Lake", "Wisconsin", "WI"],
"54471": ["Ringle", "Wisconsin", "WI"],
"54473": ["Rosholt", "Wisconsin", "WI"],
"54474": ["Rothschild", "Wisconsin", "WI"],
"54475": ["Rudolph", "Wisconsin", "WI"],
"54476": ["Schofield", "Wisconsin", "WI"],
"54479": ["Spencer", "Wisconsin", "WI"],
"54480": ["Stetsonville", "Wisconsin", "WI"],
"54481": ["Stevens Point", "Wisconsin", "WI"],
"54482": ["Stevens Point", "Wisconsin", "WI"],
"54484": ["Stratford", "Wisconsin", "WI"],
"54485": ["Summit Lake", "Wisconsin", "WI"],
"54486": ["Tigerton", "Wisconsin", "WI"],
"54487": ["Tomahawk", "Wisconsin", "WI"],
"54488": ["Unity", "Wisconsin", "WI"],
"54489": ["Vesper", "Wisconsin", "WI"],
"54490": ["Westboro", "Wisconsin", "WI"],
"54491": ["White Lake", "Wisconsin", "WI"],
"54493": ["Willard", "Wisconsin", "WI"],
"54494": ["Wisconsin Rapids", "Wisconsin", "WI"],
"54495": ["Wisconsin Rapids", "Wisconsin", "WI"],
"54498": ["Withee", "Wisconsin", "WI"],
"54499": ["Wittenberg", "Wisconsin", "WI"],
"54501": ["Rhinelander", "Wisconsin", "WI"],
"54511": ["Argonne", "Wisconsin", "WI"],
"54512": ["Boulder Junction", "Wisconsin", "WI"],
"54513": ["Brantwood", "Wisconsin", "WI"],
"54514": ["Butternut", "Wisconsin", "WI"],
"54515": ["Catawba", "Wisconsin", "WI"],
"54517": ["Clam Lake", "Wisconsin", "WI"],
"54519": ["Conover", "Wisconsin", "WI"],
"54520": ["Crandon", "Wisconsin", "WI"],
"54521": ["Eagle River", "Wisconsin", "WI"],
"54524": ["Fifield", "Wisconsin", "WI"],
"54525": ["Gile", "Wisconsin", "WI"],
"54526": ["Glen Flora", "Wisconsin", "WI"],
"54527": ["Glidden", "Wisconsin", "WI"],
"54529": ["Harshaw", "Wisconsin", "WI"],
"54530": ["Hawkins", "Wisconsin", "WI"],
"54531": ["Hazelhurst", "Wisconsin", "WI"],
"54534": ["Hurley", "Wisconsin", "WI"],
"54536": ["Iron Belt", "Wisconsin", "WI"],
"54537": ["Kennan", "Wisconsin", "WI"],
"54538": ["Lac Du Flambeau", "Wisconsin", "WI"],
"54539": ["Lake Tomahawk", "Wisconsin", "WI"],
"54540": ["Land O'Lakes", "Wisconsin", "WI"],
"54541": ["Laona", "Wisconsin", "WI"],
"54542": ["Long Lake", "Wisconsin", "WI"],
"54545": ["Manitowish Waters", "Wisconsin", "WI"],
"54546": ["Mellen", "Wisconsin", "WI"],
"54547": ["Mercer", "Wisconsin", "WI"],
"54548": ["Minocqua", "Wisconsin", "WI"],
"54550": ["Montreal", "Wisconsin", "WI"],
"54552": ["Park Falls", "Wisconsin", "WI"],
"54554": ["Phelps", "Wisconsin", "WI"],
"54555": ["Phillips", "Wisconsin", "WI"],
"54556": ["Prentice", "Wisconsin", "WI"],
"54557": ["Presque Isle", "Wisconsin", "WI"],
"54558": ["Saint Germain", "Wisconsin", "WI"],
"54559": ["Saxon", "Wisconsin", "WI"],
"54560": ["Sayner", "Wisconsin", "WI"],
"54561": ["Star Lake", "Wisconsin", "WI"],
"54562": ["Three Lakes", "Wisconsin", "WI"],
"54563": ["Tony", "Wisconsin", "WI"],
"54564": ["Tripoli", "Wisconsin", "WI"],
"54565": ["Upson", "Wisconsin", "WI"],
"54566": ["Wabeno", "Wisconsin", "WI"],
"54568": ["Woodruff", "Wisconsin", "WI"],
"54601": ["La Crosse", "Wisconsin", "WI"],
"54603": ["La Crosse", "Wisconsin", "WI"],
"54610": ["Alma", "Wisconsin", "WI"],
"54611": ["Alma Center", "Wisconsin", "WI"],
"54612": ["Arcadia", "Wisconsin", "WI"],
"54613": ["Arkdale", "Wisconsin", "WI"],
"54614": ["Bangor", "Wisconsin", "WI"],
"54615": ["Black River Falls", "Wisconsin", "WI"],
"54616": ["Blair", "Wisconsin", "WI"],
"54618": ["Camp Douglas", "Wisconsin", "WI"],
"54619": ["Cashton", "Wisconsin", "WI"],
"54621": ["Chaseburg", "Wisconsin", "WI"],
"54622": ["Cochrane", "Wisconsin", "WI"],
"54623": ["Coon Valley", "Wisconsin", "WI"],
"54624": ["De Soto", "Wisconsin", "WI"],
"54625": ["Dodge", "Wisconsin", "WI"],
"54626": ["Eastman", "Wisconsin", "WI"],
"54627": ["Ettrick", "Wisconsin", "WI"],
"54628": ["Ferryville", "Wisconsin", "WI"],
"54629": ["Fountain City", "Wisconsin", "WI"],
"54630": ["Galesville", "Wisconsin", "WI"],
"54631": ["Gays Mills", "Wisconsin", "WI"],
"54632": ["Genoa", "Wisconsin", "WI"],
"54634": ["Hillsboro", "Wisconsin", "WI"],
"54635": ["Hixton", "Wisconsin", "WI"],
"54636": ["Holmen", "Wisconsin", "WI"],
"54637": ["Hustler", "Wisconsin", "WI"],
"54638": ["Kendall", "Wisconsin", "WI"],
"54639": ["La Farge", "Wisconsin", "WI"],
"54642": ["Melrose", "Wisconsin", "WI"],
"54643": ["Millston", "Wisconsin", "WI"],
"54644": ["Mindoro", "Wisconsin", "WI"],
"54645": ["Mount Sterling", "Wisconsin", "WI"],
"54646": ["Necedah", "Wisconsin", "WI"],
"54648": ["Norwalk", "Wisconsin", "WI"],
"54650": ["Onalaska", "Wisconsin", "WI"],
"54651": ["Ontario", "Wisconsin", "WI"],
"54652": ["Readstown", "Wisconsin", "WI"],
"54653": ["Rockland", "Wisconsin", "WI"],
"54654": ["Seneca", "Wisconsin", "WI"],
"54655": ["Soldiers Grove", "Wisconsin", "WI"],
"54656": ["Sparta", "Wisconsin", "WI"],
"54657": ["Steuben", "Wisconsin", "WI"],
"54658": ["Stoddard", "Wisconsin", "WI"],
"54659": ["Taylor", "Wisconsin", "WI"],
"54660": ["Tomah", "Wisconsin", "WI"],
"54661": ["Trempealeau", "Wisconsin", "WI"],
"54664": ["Viola", "Wisconsin", "WI"],
"54665": ["Viroqua", "Wisconsin", "WI"],
"54666": ["Warrens", "Wisconsin", "WI"],
"54667": ["Westby", "Wisconsin", "WI"],
"54669": ["West Salem", "Wisconsin", "WI"],
"54670": ["Wilton", "Wisconsin", "WI"],
"54701": ["Eau Claire", "Wisconsin", "WI"],
"54703": ["Eau Claire", "Wisconsin", "WI"],
"54720": ["Altoona", "Wisconsin", "WI"],
"54721": ["Arkansaw", "Wisconsin", "WI"],
"54722": ["Augusta", "Wisconsin", "WI"],
"54723": ["Bay City", "Wisconsin", "WI"],
"54724": ["Bloomer", "Wisconsin", "WI"],
"54725": ["Boyceville", "Wisconsin", "WI"],
"54726": ["Boyd", "Wisconsin", "WI"],
"54727": ["Cadott", "Wisconsin", "WI"],
"54728": ["Chetek", "Wisconsin", "WI"],
"54729": ["Chippewa Falls", "Wisconsin", "WI"],
"54730": ["Colfax", "Wisconsin", "WI"],
"54731": ["Conrath", "Wisconsin", "WI"],
"54732": ["Cornell", "Wisconsin", "WI"],
"54733": ["Dallas", "Wisconsin", "WI"],
"54734": ["Downing", "Wisconsin", "WI"],
"54736": ["Durand", "Wisconsin", "WI"],
"54737": ["Eau Galle", "Wisconsin", "WI"],
"54738": ["Eleva", "Wisconsin", "WI"],
"54739": ["Elk Mound", "Wisconsin", "WI"],
"54740": ["Elmwood", "Wisconsin", "WI"],
"54741": ["Fairchild", "Wisconsin", "WI"],
"54742": ["Fall Creek", "Wisconsin", "WI"],
"54745": ["Holcombe", "Wisconsin", "WI"],
"54746": ["Humbird", "Wisconsin", "WI"],
"54747": ["Independence", "Wisconsin", "WI"],
"54748": ["Jim Falls", "Wisconsin", "WI"],
"54749": ["Knapp", "Wisconsin", "WI"],
"54750": ["Maiden Rock", "Wisconsin", "WI"],
"54751": ["Menomonie", "Wisconsin", "WI"],
"54754": ["Merrillan", "Wisconsin", "WI"],
"54755": ["Mondovi", "Wisconsin", "WI"],
"54756": ["Nelson", "Wisconsin", "WI"],
"54757": ["New Auburn", "Wisconsin", "WI"],
"54758": ["Osseo", "Wisconsin", "WI"],
"54759": ["Pepin", "Wisconsin", "WI"],
"54760": ["Pigeon Falls", "Wisconsin", "WI"],
"54761": ["Plum City", "Wisconsin", "WI"],
"54762": ["Prairie Farm", "Wisconsin", "WI"],
"54763": ["Ridgeland", "Wisconsin", "WI"],
"54766": ["Sheldon", "Wisconsin", "WI"],
"54767": ["Spring Valley", "Wisconsin", "WI"],
"54768": ["Stanley", "Wisconsin", "WI"],
"54769": ["Stockholm", "Wisconsin", "WI"],
"54770": ["Strum", "Wisconsin", "WI"],
"54771": ["Thorp", "Wisconsin", "WI"],
"54772": ["Wheeler", "Wisconsin", "WI"],
"54773": ["Whitehall", "Wisconsin", "WI"],
"54801": ["Spooner", "Wisconsin", "WI"],
"54805": ["Almena", "Wisconsin", "WI"],
"54806": ["Ashland", "Wisconsin", "WI"],
"54810": ["Balsam Lake", "Wisconsin", "WI"],
"54812": ["Barron", "Wisconsin", "WI"],
"54813": ["Barronett", "Wisconsin", "WI"],
"54814": ["Bayfield", "Wisconsin", "WI"],
"54817": ["Birchwood", "Wisconsin", "WI"],
"54819": ["Bruce", "Wisconsin", "WI"],
"54820": ["Brule", "Wisconsin", "WI"],
"54821": ["Cable", "Wisconsin", "WI"],
"54822": ["Cameron", "Wisconsin", "WI"],
"54824": ["Centuria", "Wisconsin", "WI"],
"54826": ["Comstock", "Wisconsin", "WI"],
"54827": ["Cornucopia", "Wisconsin", "WI"],
"54828": ["Couderay", "Wisconsin", "WI"],
"54829": ["Cumberland", "Wisconsin", "WI"],
"54830": ["Danbury", "Wisconsin", "WI"],
"54832": ["Drummond", "Wisconsin", "WI"],
"54835": ["Exeland", "Wisconsin", "WI"],
"54836": ["Foxboro", "Wisconsin", "WI"],
"54837": ["Frederic", "Wisconsin", "WI"],
"54838": ["Gordon", "Wisconsin", "WI"],
"54839": ["Grand View", "Wisconsin", "WI"],
"54840": ["Grantsburg", "Wisconsin", "WI"],
"54841": ["Haugen", "Wisconsin", "WI"],
"54842": ["Hawthorne", "Wisconsin", "WI"],
"54843": ["Hayward", "Wisconsin", "WI"],
"54844": ["Herbster", "Wisconsin", "WI"],
"54845": ["Hertel", "Wisconsin", "WI"],
"54846": ["High Bridge", "Wisconsin", "WI"],
"54847": ["Iron River", "Wisconsin", "WI"],
"54848": ["Ladysmith", "Wisconsin", "WI"],
"54849": ["Lake Nebagamon", "Wisconsin", "WI"],
"54850": ["La Pointe", "Wisconsin", "WI"],
"54853": ["Luck", "Wisconsin", "WI"],
"54854": ["Maple", "Wisconsin", "WI"],
"54855": ["Marengo", "Wisconsin", "WI"],
"54856": ["Mason", "Wisconsin", "WI"],
"54857": ["Mikana", "Wisconsin", "WI"],
"54858": ["Milltown", "Wisconsin", "WI"],
"54859": ["Minong", "Wisconsin", "WI"],
"54861": ["Odanah", "Wisconsin", "WI"],
"54862": ["Ojibwa", "Wisconsin", "WI"],
"54864": ["Poplar", "Wisconsin", "WI"],
"54865": ["Port Wing", "Wisconsin", "WI"],
"54867": ["Radisson", "Wisconsin", "WI"],
"54868": ["Rice Lake", "Wisconsin", "WI"],
"54870": ["Sarona", "Wisconsin", "WI"],
"54871": ["Shell Lake", "Wisconsin", "WI"],
"54872": ["Siren", "Wisconsin", "WI"],
"54873": ["Solon Springs", "Wisconsin", "WI"],
"54874": ["South Range", "Wisconsin", "WI"],
"54875": ["Springbrook", "Wisconsin", "WI"],
"54876": ["Stone Lake", "Wisconsin", "WI"],
"54880": ["Superior", "Wisconsin", "WI"],
"54888": ["Trego", "Wisconsin", "WI"],
"54889": ["Turtle Lake", "Wisconsin", "WI"],
"54891": ["Washburn", "Wisconsin", "WI"],
"54893": ["Webster", "Wisconsin", "WI"],
"54895": ["Weyerhaeuser", "Wisconsin", "WI"],
"54896": ["Winter", "Wisconsin", "WI"],
"54901": ["Oshkosh", "Wisconsin", "WI"],
"54902": ["Oshkosh", "Wisconsin", "WI"],
"54904": ["Oshkosh", "Wisconsin", "WI"],
"54909": ["Almond", "Wisconsin", "WI"],
"54911": ["Appleton", "Wisconsin", "WI"],
"54913": ["Appleton", "Wisconsin", "WI"],
"54914": ["Appleton", "Wisconsin", "WI"],
"54915": ["Appleton", "Wisconsin", "WI"],
"54921": ["Bancroft", "Wisconsin", "WI"],
"54922": ["Bear Creek", "Wisconsin", "WI"],
"54923": ["Berlin", "Wisconsin", "WI"],
"54926": ["Big Falls", "Wisconsin", "WI"],
"54927": ["Butte Des Morts", "Wisconsin", "WI"],
"54928": ["Caroline", "Wisconsin", "WI"],
"54929": ["Clintonville", "Wisconsin", "WI"],
"54930": ["Coloma", "Wisconsin", "WI"],
"54931": ["Dale", "Wisconsin", "WI"],
"54932": ["Eldorado", "Wisconsin", "WI"],
"54933": ["Embarrass", "Wisconsin", "WI"],
"54935": ["Fond Du Lac", "Wisconsin", "WI"],
"54937": ["Fond Du Lac", "Wisconsin", "WI"],
"54940": ["Fremont", "Wisconsin", "WI"],
"54941": ["Green Lake", "Wisconsin", "WI"],
"54942": ["Greenville", "Wisconsin", "WI"],
"54943": ["Hancock", "Wisconsin", "WI"],
"54944": ["Hortonville", "Wisconsin", "WI"],
"54945": ["Iola", "Wisconsin", "WI"],
"54946": ["King", "Wisconsin", "WI"],
"54947": ["Larsen", "Wisconsin", "WI"],
"54948": ["Leopolis", "Wisconsin", "WI"],
"54949": ["Manawa", "Wisconsin", "WI"],
"54950": ["Marion", "Wisconsin", "WI"],
"54952": ["Menasha", "Wisconsin", "WI"],
"54956": ["Neenah", "Wisconsin", "WI"],
"54960": ["Neshkoro", "Wisconsin", "WI"],
"54961": ["New London", "Wisconsin", "WI"],
"54962": ["Ogdensburg", "Wisconsin", "WI"],
"54963": ["Omro", "Wisconsin", "WI"],
"54964": ["Pickett", "Wisconsin", "WI"],
"54965": ["Pine River", "Wisconsin", "WI"],
"54966": ["Plainfield", "Wisconsin", "WI"],
"54967": ["Poy Sippi", "Wisconsin", "WI"],
"54968": ["Princeton", "Wisconsin", "WI"],
"54970": ["Redgranite", "Wisconsin", "WI"],
"54971": ["Ripon", "Wisconsin", "WI"],
"54974": ["Rosendale", "Wisconsin", "WI"],
"54977": ["Scandinavia", "Wisconsin", "WI"],
"54978": ["Tilleda", "Wisconsin", "WI"],
"54979": ["Van Dyne", "Wisconsin", "WI"],
"54980": ["Waukau", "Wisconsin", "WI"],
"54981": ["Waupaca", "Wisconsin", "WI"],
"54982": ["Wautoma", "Wisconsin", "WI"],
"54983": ["Weyauwega", "Wisconsin", "WI"],
"54984": ["Wild Rose", "Wisconsin", "WI"],
"54985": ["Winnebago", "Wisconsin", "WI"],
"54986": ["Winneconne", "Wisconsin", "WI"],
"55001": ["Afton", "Minnesota", "MN"],
"55003": ["Bayport", "Minnesota", "MN"],
"55005": ["Bethel", "Minnesota", "MN"],
"55006": ["Braham", "Minnesota", "MN"],
"55007": ["Brook Park", "Minnesota", "MN"],
"55008": ["Cambridge", "Minnesota", "MN"],
"55009": ["Cannon Falls", "Minnesota", "MN"],
"55011": ["Cedar", "Minnesota", "MN"],
"55012": ["Center City", "Minnesota", "MN"],
"55013": ["Chisago City", "Minnesota", "MN"],
"55014": ["Circle Pines", "Minnesota", "MN"],
"55016": ["Cottage Grove", "Minnesota", "MN"],
"55017": ["Dalbo", "Minnesota", "MN"],
"55018": ["Dennison", "Minnesota", "MN"],
"55019": ["Dundas", "Minnesota", "MN"],
"55020": ["Elko New Market", "Minnesota", "MN"],
"55021": ["Faribault", "Minnesota", "MN"],
"55024": ["Farmington", "Minnesota", "MN"],
"55025": ["Forest Lake", "Minnesota", "MN"],
"55026": ["Frontenac", "Minnesota", "MN"],
"55027": ["Goodhue", "Minnesota", "MN"],
"55029": ["Grandy", "Minnesota", "MN"],
"55030": ["Grasston", "Minnesota", "MN"],
"55031": ["Hampton", "Minnesota", "MN"],
"55032": ["Harris", "Minnesota", "MN"],
"55033": ["Hastings", "Minnesota", "MN"],
"55036": ["Henriette", "Minnesota", "MN"],
"55037": ["Hinckley", "Minnesota", "MN"],
"55038": ["Hugo", "Minnesota", "MN"],
"55040": ["Isanti", "Minnesota", "MN"],
"55041": ["Lake City", "Minnesota", "MN"],
"55042": ["Lake Elmo", "Minnesota", "MN"],
"55043": ["Lakeland", "Minnesota", "MN"],
"55044": ["Lakeville", "Minnesota", "MN"],
"55045": ["Lindstrom", "Minnesota", "MN"],
"55046": ["Lonsdale", "Minnesota", "MN"],
"55047": ["Marine On Saint Croix", "Minnesota", "MN"],
"55049": ["Medford", "Minnesota", "MN"],
"55051": ["Mora", "Minnesota", "MN"],
"55052": ["Morristown", "Minnesota", "MN"],
"55053": ["Nerstrand", "Minnesota", "MN"],
"55054": ["Elko New Market", "Minnesota", "MN"],
"55055": ["Newport", "Minnesota", "MN"],
"55056": ["North Branch", "Minnesota", "MN"],
"55057": ["Northfield", "Minnesota", "MN"],
"55060": ["Owatonna", "Minnesota", "MN"],
"55063": ["Pine City", "Minnesota", "MN"],
"55065": ["Randolph", "Minnesota", "MN"],
"55066": ["Red Wing", "Minnesota", "MN"],
"55068": ["Rosemount", "Minnesota", "MN"],
"55069": ["Rush City", "Minnesota", "MN"],
"55070": ["Saint Francis", "Minnesota", "MN"],
"55071": ["Saint Paul Park", "Minnesota", "MN"],
"55072": ["Sandstone", "Minnesota", "MN"],
"55073": ["Scandia", "Minnesota", "MN"],
"55074": ["Shafer", "Minnesota", "MN"],
"55075": ["South Saint Paul", "Minnesota", "MN"],
"55076": ["Inver Grove Heights", "Minnesota", "MN"],
"55077": ["Inver Grove Heights", "Minnesota", "MN"],
"55079": ["Stacy", "Minnesota", "MN"],
"55080": ["Stanchfield", "Minnesota", "MN"],
"55082": ["Stillwater", "Minnesota", "MN"],
"55084": ["Taylors Falls", "Minnesota", "MN"],
"55085": ["Vermillion", "Minnesota", "MN"],
"55087": ["Warsaw", "Minnesota", "MN"],
"55088": ["Webster", "Minnesota", "MN"],
"55089": ["Welch", "Minnesota", "MN"],
"55090": ["Willernie", "Minnesota", "MN"],
"55092": ["Wyoming", "Minnesota", "MN"],
"55101": ["Saint Paul", "Minnesota", "MN"],
"55102": ["Saint Paul", "Minnesota", "MN"],
"55103": ["Saint Paul", "Minnesota", "MN"],
"55104": ["Saint Paul", "Minnesota", "MN"],
"55105": ["Saint Paul", "Minnesota", "MN"],
"55106": ["Saint Paul", "Minnesota", "MN"],
"55107": ["Saint Paul", "Minnesota", "MN"],
"55108": ["Saint Paul", "Minnesota", "MN"],
"55109": ["Saint Paul", "Minnesota", "MN"],
"55110": ["Saint Paul", "Minnesota", "MN"],
"55111": ["Saint Paul", "Minnesota", "MN"],
"55112": ["Saint Paul", "Minnesota", "MN"],
"55113": ["Saint Paul", "Minnesota", "MN"],
"55114": ["Saint Paul", "Minnesota", "MN"],
"55115": ["Saint Paul", "Minnesota", "MN"],
"55116": ["Saint Paul", "Minnesota", "MN"],
"55117": ["Saint Paul", "Minnesota", "MN"],
"55118": ["Saint Paul", "Minnesota", "MN"],
"55119": ["Saint Paul", "Minnesota", "MN"],
"55120": ["Saint Paul", "Minnesota", "MN"],
"55121": ["Saint Paul", "Minnesota", "MN"],
"55122": ["Saint Paul", "Minnesota", "MN"],
"55123": ["Saint Paul", "Minnesota", "MN"],
"55124": ["Saint Paul", "Minnesota", "MN"],
"55125": ["Saint Paul", "Minnesota", "MN"],
"55126": ["Saint Paul", "Minnesota", "MN"],
"55127": ["Saint Paul", "Minnesota", "MN"],
"55128": ["Saint Paul", "Minnesota", "MN"],
"55129": ["Saint Paul", "Minnesota", "MN"],
"55130": ["Saint Paul", "Minnesota", "MN"],
"55150": ["Mendota", "Minnesota", "MN"],
"55155": ["Saint Paul", "Minnesota", "MN"],
"55301": ["Albertville", "Minnesota", "MN"],
"55302": ["Annandale", "Minnesota", "MN"],
"55303": ["Anoka", "Minnesota", "MN"],
"55304": ["Andover", "Minnesota", "MN"],
"55305": ["Hopkins", "Minnesota", "MN"],
"55306": ["Burnsville", "Minnesota", "MN"],
"55307": ["Arlington", "Minnesota", "MN"],
"55308": ["Becker", "Minnesota", "MN"],
"55309": ["Big Lake", "Minnesota", "MN"],
"55310": ["Bird Island", "Minnesota", "MN"],
"55311": ["Maple Grove", "Minnesota", "MN"],
"55312": ["Brownton", "Minnesota", "MN"],
"55313": ["Buffalo", "Minnesota", "MN"],
"55314": ["Buffalo Lake", "Minnesota", "MN"],
"55315": ["Carver", "Minnesota", "MN"],
"55316": ["Champlin", "Minnesota", "MN"],
"55317": ["Chanhassen", "Minnesota", "MN"],
"55318": ["Chaska", "Minnesota", "MN"],
"55319": ["Clear Lake", "Minnesota", "MN"],
"55320": ["Clearwater", "Minnesota", "MN"],
"55321": ["Cokato", "Minnesota", "MN"],
"55322": ["Cologne", "Minnesota", "MN"],
"55324": ["Darwin", "Minnesota", "MN"],
"55325": ["Dassel", "Minnesota", "MN"],
"55327": ["Dayton", "Minnesota", "MN"],
"55328": ["Delano", "Minnesota", "MN"],
"55329": ["Eden Valley", "Minnesota", "MN"],
"55330": ["Elk River", "Minnesota", "MN"],
"55331": ["Excelsior", "Minnesota", "MN"],
"55332": ["Fairfax", "Minnesota", "MN"],
"55333": ["Franklin", "Minnesota", "MN"],
"55334": ["Gaylord", "Minnesota", "MN"],
"55335": ["Gibbon", "Minnesota", "MN"],
"55336": ["Glencoe", "Minnesota", "MN"],
"55337": ["Burnsville", "Minnesota", "MN"],
"55338": ["Green Isle", "Minnesota", "MN"],
"55339": ["Hamburg", "Minnesota", "MN"],
"55340": ["Hamel", "Minnesota", "MN"],
"55341": ["Hanover", "Minnesota", "MN"],
"55342": ["Hector", "Minnesota", "MN"],
"55343": ["Hopkins", "Minnesota", "MN"],
"55344": ["Eden Prairie", "Minnesota", "MN"],
"55345": ["Minnetonka", "Minnesota", "MN"],
"55346": ["Eden Prairie", "Minnesota", "MN"],
"55347": ["Eden Prairie", "Minnesota", "MN"],
"55349": ["Howard Lake", "Minnesota", "MN"],
"55350": ["Hutchinson", "Minnesota", "MN"],
"55352": ["Jordan", "Minnesota", "MN"],
"55353": ["Kimball", "Minnesota", "MN"],
"55354": ["Lester Prairie", "Minnesota", "MN"],
"55355": ["Litchfield", "Minnesota", "MN"],
"55356": ["Long Lake", "Minnesota", "MN"],
"55357": ["Loretto", "Minnesota", "MN"],
"55358": ["Maple Lake", "Minnesota", "MN"],
"55359": ["Maple Plain", "Minnesota", "MN"],
"55360": ["Mayer", "Minnesota", "MN"],
"55362": ["Monticello", "Minnesota", "MN"],
"55363": ["Montrose", "Minnesota", "MN"],
"55364": ["Mound", "Minnesota", "MN"],
"55366": ["New Auburn", "Minnesota", "MN"],
"55367": ["New Germany", "Minnesota", "MN"],
"55368": ["Norwood Young America", "Minnesota", "MN"],
"55369": ["Osseo", "Minnesota", "MN"],
"55370": ["Plato", "Minnesota", "MN"],
"55371": ["Princeton", "Minnesota", "MN"],
"55372": ["Prior Lake", "Minnesota", "MN"],
"55373": ["Rockford", "Minnesota", "MN"],
"55374": ["Rogers", "Minnesota", "MN"],
"55375": ["Saint Bonifacius", "Minnesota", "MN"],
"55376": ["Saint Michael", "Minnesota", "MN"],
"55378": ["Savage", "Minnesota", "MN"],
"55379": ["Shakopee", "Minnesota", "MN"],
"55381": ["Silver Lake", "Minnesota", "MN"],
"55382": ["South Haven", "Minnesota", "MN"],
"55384": ["Spring Park", "Minnesota", "MN"],
"55385": ["Stewart", "Minnesota", "MN"],
"55386": ["Victoria", "Minnesota", "MN"],
"55387": ["Waconia", "Minnesota", "MN"],
"55388": ["Watertown", "Minnesota", "MN"],
"55389": ["Watkins", "Minnesota", "MN"],
"55390": ["Waverly", "Minnesota", "MN"],
"55391": ["Wayzata", "Minnesota", "MN"],
"55395": ["Winsted", "Minnesota", "MN"],
"55396": ["Winthrop", "Minnesota", "MN"],
"55397": ["Young America", "Minnesota", "MN"],
"55398": ["Zimmerman", "Minnesota", "MN"],
"55401": ["Minneapolis", "Minnesota", "MN"],
"55402": ["Minneapolis", "Minnesota", "MN"],
"55403": ["Minneapolis", "Minnesota", "MN"],
"55404": ["Minneapolis", "Minnesota", "MN"],
"55405": ["Minneapolis", "Minnesota", "MN"],
"55406": ["Minneapolis", "Minnesota", "MN"],
"55407": ["Minneapolis", "Minnesota", "MN"],
"55408": ["Minneapolis", "Minnesota", "MN"],
"55409": ["Minneapolis", "Minnesota", "MN"],
"55410": ["Minneapolis", "Minnesota", "MN"],
"55411": ["Minneapolis", "Minnesota", "MN"],
"55412": ["Minneapolis", "Minnesota", "MN"],
"55413": ["Minneapolis", "Minnesota", "MN"],
"55414": ["Minneapolis", "Minnesota", "MN"],
"55415": ["Minneapolis", "Minnesota", "MN"],
"55416": ["Minneapolis", "Minnesota", "MN"],
"55417": ["Minneapolis", "Minnesota", "MN"],
"55418": ["Minneapolis", "Minnesota", "MN"],
"55419": ["Minneapolis", "Minnesota", "MN"],
"55420": ["Minneapolis", "Minnesota", "MN"],
"55421": ["Minneapolis", "Minnesota", "MN"],
"55422": ["Minneapolis", "Minnesota", "MN"],
"55423": ["Minneapolis", "Minnesota", "MN"],
"55424": ["Minneapolis", "Minnesota", "MN"],
"55425": ["Minneapolis", "Minnesota", "MN"],
"55426": ["Minneapolis", "Minnesota", "MN"],
"55427": ["Minneapolis", "Minnesota", "MN"],
"55428": ["Minneapolis", "Minnesota", "MN"],
"55429": ["Minneapolis", "Minnesota", "MN"],
"55430": ["Minneapolis", "Minnesota", "MN"],
"55431": ["Minneapolis", "Minnesota", "MN"],
"55432": ["Minneapolis", "Minnesota", "MN"],
"55433": ["Minneapolis", "Minnesota", "MN"],
"55434": ["Minneapolis", "Minnesota", "MN"],
"55435": ["Minneapolis", "Minnesota", "MN"],
"55436": ["Minneapolis", "Minnesota", "MN"],
"55437": ["Minneapolis", "Minnesota", "MN"],
"55438": ["Minneapolis", "Minnesota", "MN"],
"55439": ["Minneapolis", "Minnesota", "MN"],
"55441": ["Minneapolis", "Minnesota", "MN"],
"55442": ["Minneapolis", "Minnesota", "MN"],
"55443": ["Minneapolis", "Minnesota", "MN"],
"55444": ["Minneapolis", "Minnesota", "MN"],
"55445": ["Minneapolis", "Minnesota", "MN"],
"55446": ["Minneapolis", "Minnesota", "MN"],
"55447": ["Minneapolis", "Minnesota", "MN"],
"55448": ["Minneapolis", "Minnesota", "MN"],
"55449": ["Minneapolis", "Minnesota", "MN"],
"55450": ["Minneapolis", "Minnesota", "MN"],
"55454": ["Minneapolis", "Minnesota", "MN"],
"55455": ["Minneapolis", "Minnesota", "MN"],
"55601": ["Beaver Bay", "Minnesota", "MN"],
"55602": ["Brimson", "Minnesota", "MN"],
"55603": ["Finland", "Minnesota", "MN"],
"55604": ["Grand Marais", "Minnesota", "MN"],
"55605": ["Grand Portage", "Minnesota", "MN"],
"55606": ["Hovland", "Minnesota", "MN"],
"55607": ["Isabella", "Minnesota", "MN"],
"55609": ["Knife River", "Minnesota", "MN"],
"55612": ["Lutsen", "Minnesota", "MN"],
"55613": ["Schroeder", "Minnesota", "MN"],
"55614": ["Silver Bay", "Minnesota", "MN"],
"55615": ["Tofte", "Minnesota", "MN"],
"55616": ["Two Harbors", "Minnesota", "MN"],
"55702": ["Alborn", "Minnesota", "MN"],
"55703": ["Angora", "Minnesota", "MN"],
"55704": ["Askov", "Minnesota", "MN"],
"55705": ["Aurora", "Minnesota", "MN"],
"55706": ["Babbitt", "Minnesota", "MN"],
"55707": ["Barnum", "Minnesota", "MN"],
"55708": ["Biwabik", "Minnesota", "MN"],
"55709": ["Bovey", "Minnesota", "MN"],
"55710": ["Britt", "Minnesota", "MN"],
"55711": ["Brookston", "Minnesota", "MN"],
"55712": ["Bruno", "Minnesota", "MN"],
"55713": ["Buhl", "Minnesota", "MN"],
"55716": ["Calumet", "Minnesota", "MN"],
"55717": ["Canyon", "Minnesota", "MN"],
"55718": ["Carlton", "Minnesota", "MN"],
"55719": ["Chisholm", "Minnesota", "MN"],
"55720": ["Cloquet", "Minnesota", "MN"],
"55721": ["Cohasset", "Minnesota", "MN"],
"55722": ["Coleraine", "Minnesota", "MN"],
"55723": ["Cook", "Minnesota", "MN"],
"55724": ["Cotton", "Minnesota", "MN"],
"55725": ["Crane Lake", "Minnesota", "MN"],
"55726": ["Cromwell", "Minnesota", "MN"],
"55731": ["Ely", "Minnesota", "MN"],
"55732": ["Embarrass", "Minnesota", "MN"],
"55733": ["Esko", "Minnesota", "MN"],
"55734": ["Eveleth", "Minnesota", "MN"],
"55735": ["Finlayson", "Minnesota", "MN"],
"55736": ["Floodwood", "Minnesota", "MN"],
"55738": ["Forbes", "Minnesota", "MN"],
"55741": ["Gilbert", "Minnesota", "MN"],
"55742": ["Goodland", "Minnesota", "MN"],
"55744": ["Grand Rapids", "Minnesota", "MN"],
"55746": ["Hibbing", "Minnesota", "MN"],
"55748": ["Hill City", "Minnesota", "MN"],
"55749": ["Holyoke", "Minnesota", "MN"],
"55750": ["Hoyt Lakes", "Minnesota", "MN"],
"55751": ["Iron", "Minnesota", "MN"],
"55752": ["Jacobson", "Minnesota", "MN"],
"55753": ["Keewatin", "Minnesota", "MN"],
"55756": ["Kerrick", "Minnesota", "MN"],
"55757": ["Kettle River", "Minnesota", "MN"],
"55758": ["Kinney", "Minnesota", "MN"],
"55760": ["Mcgregor", "Minnesota", "MN"],
"55763": ["Makinen", "Minnesota", "MN"],
"55764": ["Marble", "Minnesota", "MN"],
"55765": ["Meadowlands", "Minnesota", "MN"],
"55767": ["Moose Lake", "Minnesota", "MN"],
"55768": ["Mountain Iron", "Minnesota", "MN"],
"55769": ["Nashwauk", "Minnesota", "MN"],
"55771": ["Orr", "Minnesota", "MN"],
"55772": ["Nett Lake", "Minnesota", "MN"],
"55775": ["Pengilly", "Minnesota", "MN"],
"55779": ["Saginaw", "Minnesota", "MN"],
"55780": ["Sawyer", "Minnesota", "MN"],
"55781": ["Side Lake", "Minnesota", "MN"],
"55782": ["Soudan", "Minnesota", "MN"],
"55783": ["Sturgeon Lake", "Minnesota", "MN"],
"55784": ["Swan River", "Minnesota", "MN"],
"55785": ["Swatara", "Minnesota", "MN"],
"55786": ["Taconite", "Minnesota", "MN"],
"55787": ["Tamarack", "Minnesota", "MN"],
"55790": ["Tower", "Minnesota", "MN"],
"55792": ["Virginia", "Minnesota", "MN"],
"55793": ["Warba", "Minnesota", "MN"],
"55795": ["Willow River", "Minnesota", "MN"],
"55796": ["Winton", "Minnesota", "MN"],
"55797": ["Wrenshall", "Minnesota", "MN"],
"55798": ["Wright", "Minnesota", "MN"],
"55802": ["Duluth", "Minnesota", "MN"],
"55803": ["Duluth", "Minnesota", "MN"],
"55804": ["Duluth", "Minnesota", "MN"],
"55805": ["Duluth", "Minnesota", "MN"],
"55806": ["Duluth", "Minnesota", "MN"],
"55807": ["Duluth", "Minnesota", "MN"],
"55808": ["Duluth", "Minnesota", "MN"],
"55810": ["Duluth", "Minnesota", "MN"],
"55811": ["Duluth", "Minnesota", "MN"],
"55812": ["Duluth", "Minnesota", "MN"],
"55814": ["Duluth", "Minnesota", "MN"],
"55901": ["Rochester", "Minnesota", "MN"],
"55902": ["Rochester", "Minnesota", "MN"],
"55903": ["Rochester", "Minnesota", "MN"],
"55904": ["Rochester", "Minnesota", "MN"],
"55905": ["Rochester", "Minnesota", "MN"],
"55906": ["Rochester", "Minnesota", "MN"],
"55909": ["Adams", "Minnesota", "MN"],
"55910": ["Altura", "Minnesota", "MN"],
"55912": ["Austin", "Minnesota", "MN"],
"55917": ["Blooming Prairie", "Minnesota", "MN"],
"55918": ["Brownsdale", "Minnesota", "MN"],
"55919": ["Brownsville", "Minnesota", "MN"],
"55920": ["Byron", "Minnesota", "MN"],
"55921": ["Caledonia", "Minnesota", "MN"],
"55922": ["Canton", "Minnesota", "MN"],
"55923": ["Chatfield", "Minnesota", "MN"],
"55924": ["Claremont", "Minnesota", "MN"],
"55925": ["Dakota", "Minnesota", "MN"],
"55926": ["Dexter", "Minnesota", "MN"],
"55927": ["Dodge Center", "Minnesota", "MN"],
"55929": ["Dover", "Minnesota", "MN"],
"55931": ["Eitzen", "Minnesota", "MN"],
"55932": ["Elgin", "Minnesota", "MN"],
"55933": ["Elkton", "Minnesota", "MN"],
"55934": ["Eyota", "Minnesota", "MN"],
"55935": ["Fountain", "Minnesota", "MN"],
"55936": ["Grand Meadow", "Minnesota", "MN"],
"55939": ["Harmony", "Minnesota", "MN"],
"55940": ["Hayfield", "Minnesota", "MN"],
"55941": ["Hokah", "Minnesota", "MN"],
"55943": ["Houston", "Minnesota", "MN"],
"55944": ["Kasson", "Minnesota", "MN"],
"55945": ["Kellogg", "Minnesota", "MN"],
"55946": ["Kenyon", "Minnesota", "MN"],
"55947": ["La Crescent", "Minnesota", "MN"],
"55949": ["Lanesboro", "Minnesota", "MN"],
"55950": ["Lansing", "Minnesota", "MN"],
"55951": ["Le Roy", "Minnesota", "MN"],
"55952": ["Lewiston", "Minnesota", "MN"],
"55953": ["Lyle", "Minnesota", "MN"],
"55954": ["Mabel", "Minnesota", "MN"],
"55955": ["Mantorville", "Minnesota", "MN"],
"55956": ["Mazeppa", "Minnesota", "MN"],
"55957": ["Millville", "Minnesota", "MN"],
"55959": ["Minnesota City", "Minnesota", "MN"],
"55960": ["Oronoco", "Minnesota", "MN"],
"55961": ["Ostrander", "Minnesota", "MN"],
"55962": ["Peterson", "Minnesota", "MN"],
"55963": ["Pine Island", "Minnesota", "MN"],
"55964": ["Plainview", "Minnesota", "MN"],
"55965": ["Preston", "Minnesota", "MN"],
"55967": ["Racine", "Minnesota", "MN"],
"55968": ["Reads Landing", "Minnesota", "MN"],
"55969": ["Rollingstone", "Minnesota", "MN"],
"55970": ["Rose Creek", "Minnesota", "MN"],
"55971": ["Rushford", "Minnesota", "MN"],
"55972": ["Saint Charles", "Minnesota", "MN"],
"55973": ["Sargeant", "Minnesota", "MN"],
"55974": ["Spring Grove", "Minnesota", "MN"],
"55975": ["Spring Valley", "Minnesota", "MN"],
"55976": ["Stewartville", "Minnesota", "MN"],
"55977": ["Taopi", "Minnesota", "MN"],
"55979": ["Utica", "Minnesota", "MN"],
"55981": ["Wabasha", "Minnesota", "MN"],
"55982": ["Waltham", "Minnesota", "MN"],
"55983": ["Wanamingo", "Minnesota", "MN"],
"55985": ["West Concord", "Minnesota", "MN"],
"55987": ["Winona", "Minnesota", "MN"],
"55990": ["Wykoff", "Minnesota", "MN"],
"55991": ["Zumbro Falls", "Minnesota", "MN"],
"55992": ["Zumbrota", "Minnesota", "MN"],
"56001": ["Mankato", "Minnesota", "MN"],
"56003": ["Mankato", "Minnesota", "MN"],
"56007": ["Albert Lea", "Minnesota", "MN"],
"56009": ["Alden", "Minnesota", "MN"],
"56010": ["Amboy", "Minnesota", "MN"],
"56011": ["Belle Plaine", "Minnesota", "MN"],
"56013": ["Blue Earth", "Minnesota", "MN"],
"56014": ["Bricelyn", "Minnesota", "MN"],
"56016": ["Clarks Grove", "Minnesota", "MN"],
"56017": ["Cleveland", "Minnesota", "MN"],
"56019": ["Comfrey", "Minnesota", "MN"],
"56020": ["Conger", "Minnesota", "MN"],
"56021": ["Courtland", "Minnesota", "MN"],
"56022": ["Darfur", "Minnesota", "MN"],
"56023": ["Delavan", "Minnesota", "MN"],
"56024": ["Eagle Lake", "Minnesota", "MN"],
"56025": ["Easton", "Minnesota", "MN"],
"56026": ["Ellendale", "Minnesota", "MN"],
"56027": ["Elmore", "Minnesota", "MN"],
"56028": ["Elysian", "Minnesota", "MN"],
"56029": ["Emmons", "Minnesota", "MN"],
"56030": ["Essig", "Minnesota", "MN"],
"56031": ["Fairmont", "Minnesota", "MN"],
"56032": ["Freeborn", "Minnesota", "MN"],
"56033": ["Frost", "Minnesota", "MN"],
"56034": ["Garden City", "Minnesota", "MN"],
"56035": ["Geneva", "Minnesota", "MN"],
"56036": ["Glenville", "Minnesota", "MN"],
"56037": ["Good Thunder", "Minnesota", "MN"],
"56039": ["Granada", "Minnesota", "MN"],
"56041": ["Hanska", "Minnesota", "MN"],
"56042": ["Hartland", "Minnesota", "MN"],
"56043": ["Hayward", "Minnesota", "MN"],
"56044": ["Henderson", "Minnesota", "MN"],
"56045": ["Hollandale", "Minnesota", "MN"],
"56046": ["Hope", "Minnesota", "MN"],
"56048": ["Janesville", "Minnesota", "MN"],
"56050": ["Kasota", "Minnesota", "MN"],
"56051": ["Kiester", "Minnesota", "MN"],
"56052": ["Kilkenny", "Minnesota", "MN"],
"56054": ["Lafayette", "Minnesota", "MN"],
"56055": ["Lake Crystal", "Minnesota", "MN"],
"56056": ["La Salle", "Minnesota", "MN"],
"56057": ["Le Center", "Minnesota", "MN"],
"56058": ["Le Sueur", "Minnesota", "MN"],
"56060": ["Lewisville", "Minnesota", "MN"],
"56062": ["Madelia", "Minnesota", "MN"],
"56063": ["Madison Lake", "Minnesota", "MN"],
"56065": ["Mapleton", "Minnesota", "MN"],
"56068": ["Minnesota Lake", "Minnesota", "MN"],
"56069": ["Montgomery", "Minnesota", "MN"],
"56071": ["New Prague", "Minnesota", "MN"],
"56072": ["New Richland", "Minnesota", "MN"],
"56073": ["New Ulm", "Minnesota", "MN"],
"56074": ["Nicollet", "Minnesota", "MN"],
"56075": ["Northrop", "Minnesota", "MN"],
"56078": ["Pemberton", "Minnesota", "MN"],
"56080": ["Saint Clair", "Minnesota", "MN"],
"56081": ["Saint James", "Minnesota", "MN"],
"56082": ["Saint Peter", "Minnesota", "MN"],
"56083": ["Sanborn", "Minnesota", "MN"],
"56085": ["Sleepy Eye", "Minnesota", "MN"],
"56087": ["Springfield", "Minnesota", "MN"],
"56088": ["Truman", "Minnesota", "MN"],
"56089": ["Twin Lakes", "Minnesota", "MN"],
"56090": ["Vernon Center", "Minnesota", "MN"],
"56091": ["Waldorf", "Minnesota", "MN"],
"56093": ["Waseca", "Minnesota", "MN"],
"56096": ["Waterville", "Minnesota", "MN"],
"56097": ["Wells", "Minnesota", "MN"],
"56098": ["Winnebago", "Minnesota", "MN"],
"56101": ["Windom", "Minnesota", "MN"],
"56110": ["Adrian", "Minnesota", "MN"],
"56111": ["Alpha", "Minnesota", "MN"],
"56113": ["Arco", "Minnesota", "MN"],
"56114": ["Avoca", "Minnesota", "MN"],
"56115": ["Balaton", "Minnesota", "MN"],
"56116": ["Beaver Creek", "Minnesota", "MN"],
"56117": ["Bigelow", "Minnesota", "MN"],
"56118": ["Bingham Lake", "Minnesota", "MN"],
"56119": ["Brewster", "Minnesota", "MN"],
"56120": ["Butterfield", "Minnesota", "MN"],
"56121": ["Ceylon", "Minnesota", "MN"],
"56122": ["Chandler", "Minnesota", "MN"],
"56123": ["Currie", "Minnesota", "MN"],
"56125": ["Dovray", "Minnesota", "MN"],
"56127": ["Dunnell", "Minnesota", "MN"],
"56128": ["Edgerton", "Minnesota", "MN"],
"56129": ["Ellsworth", "Minnesota", "MN"],
"56131": ["Fulda", "Minnesota", "MN"],
"56132": ["Garvin", "Minnesota", "MN"],
"56134": ["Hardwick", "Minnesota", "MN"],
"56136": ["Hendricks", "Minnesota", "MN"],
"56137": ["Heron Lake", "Minnesota", "MN"],
"56138": ["Hills", "Minnesota", "MN"],
"56139": ["Holland", "Minnesota", "MN"],
"56140": ["Ihlen", "Minnesota", "MN"],
"56141": ["Iona", "Minnesota", "MN"],
"56142": ["Ivanhoe", "Minnesota", "MN"],
"56143": ["Jackson", "Minnesota", "MN"],
"56144": ["Jasper", "Minnesota", "MN"],
"56145": ["Jeffers", "Minnesota", "MN"],
"56146": ["Kanaranzi", "Minnesota", "MN"],
"56147": ["Kenneth", "Minnesota", "MN"],
"56149": ["Lake Benton", "Minnesota", "MN"],
"56150": ["Lakefield", "Minnesota", "MN"],
"56151": ["Lake Wilson", "Minnesota", "MN"],
"56152": ["Lamberton", "Minnesota", "MN"],
"56153": ["Leota", "Minnesota", "MN"],
"56155": ["Lismore", "Minnesota", "MN"],
"56156": ["Luverne", "Minnesota", "MN"],
"56157": ["Lynd", "Minnesota", "MN"],
"56158": ["Magnolia", "Minnesota", "MN"],
"56159": ["Mountain Lake", "Minnesota", "MN"],
"56160": ["Odin", "Minnesota", "MN"],
"56161": ["Okabena", "Minnesota", "MN"],
"56162": ["Ormsby", "Minnesota", "MN"],
"56164": ["Pipestone", "Minnesota", "MN"],
"56165": ["Reading", "Minnesota", "MN"],
"56166": ["Revere", "Minnesota", "MN"],
"56167": ["Round Lake", "Minnesota", "MN"],
"56168": ["Rushmore", "Minnesota", "MN"],
"56169": ["Russell", "Minnesota", "MN"],
"56170": ["Ruthton", "Minnesota", "MN"],
"56171": ["Sherburn", "Minnesota", "MN"],
"56172": ["Slayton", "Minnesota", "MN"],
"56173": ["Steen", "Minnesota", "MN"],
"56174": ["Storden", "Minnesota", "MN"],
"56175": ["Tracy", "Minnesota", "MN"],
"56176": ["Trimont", "Minnesota", "MN"],
"56178": ["Tyler", "Minnesota", "MN"],
"56180": ["Walnut Grove", "Minnesota", "MN"],
"56181": ["Welcome", "Minnesota", "MN"],
"56183": ["Westbrook", "Minnesota", "MN"],
"56185": ["Wilmont", "Minnesota", "MN"],
"56186": ["Woodstock", "Minnesota", "MN"],
"56187": ["Worthington", "Minnesota", "MN"],
"56201": ["Willmar", "Minnesota", "MN"],
"56207": ["Alberta", "Minnesota", "MN"],
"56208": ["Appleton", "Minnesota", "MN"],
"56209": ["Atwater", "Minnesota", "MN"],
"56210": ["Barry", "Minnesota", "MN"],
"56211": ["Beardsley", "Minnesota", "MN"],
"56212": ["Bellingham", "Minnesota", "MN"],
"56214": ["Belview", "Minnesota", "MN"],
"56215": ["Benson", "Minnesota", "MN"],
"56216": ["Blomkest", "Minnesota", "MN"],
"56218": ["Boyd", "Minnesota", "MN"],
"56219": ["Browns Valley", "Minnesota", "MN"],
"56220": ["Canby", "Minnesota", "MN"],
"56221": ["Chokio", "Minnesota", "MN"],
"56222": ["Clara City", "Minnesota", "MN"],
"56223": ["Clarkfield", "Minnesota", "MN"],
"56224": ["Clements", "Minnesota", "MN"],
"56225": ["Clinton", "Minnesota", "MN"],
"56226": ["Clontarf", "Minnesota", "MN"],
"56227": ["Correll", "Minnesota", "MN"],
"56228": ["Cosmos", "Minnesota", "MN"],
"56229": ["Cottonwood", "Minnesota", "MN"],
"56230": ["Danube", "Minnesota", "MN"],
"56231": ["Danvers", "Minnesota", "MN"],
"56232": ["Dawson", "Minnesota", "MN"],
"56235": ["Donnelly", "Minnesota", "MN"],
"56236": ["Dumont", "Minnesota", "MN"],
"56237": ["Echo", "Minnesota", "MN"],
"56239": ["Ghent", "Minnesota", "MN"],
"56240": ["Graceville", "Minnesota", "MN"],
"56241": ["Granite Falls", "Minnesota", "MN"],
"56243": ["Grove City", "Minnesota", "MN"],
"56244": ["Hancock", "Minnesota", "MN"],
"56245": ["Hanley Falls", "Minnesota", "MN"],
"56248": ["Herman", "Minnesota", "MN"],
"56249": ["Holloway", "Minnesota", "MN"],
"56251": ["Kandiyohi", "Minnesota", "MN"],
"56252": ["Kerkhoven", "Minnesota", "MN"],
"56253": ["Lake Lillian", "Minnesota", "MN"],
"56255": ["Lucan", "Minnesota", "MN"],
"56256": ["Madison", "Minnesota", "MN"],
"56257": ["Marietta", "Minnesota", "MN"],
"56258": ["Marshall", "Minnesota", "MN"],
"56260": ["Maynard", "Minnesota", "MN"],
"56262": ["Milan", "Minnesota", "MN"],
"56263": ["Milroy", "Minnesota", "MN"],
"56264": ["Minneota", "Minnesota", "MN"],
"56265": ["Montevideo", "Minnesota", "MN"],
"56266": ["Morgan", "Minnesota", "MN"],
"56267": ["Morris", "Minnesota", "MN"],
"56270": ["Morton", "Minnesota", "MN"],
"56271": ["Murdock", "Minnesota", "MN"],
"56273": ["New London", "Minnesota", "MN"],
"56274": ["Norcross", "Minnesota", "MN"],
"56276": ["Odessa", "Minnesota", "MN"],
"56277": ["Olivia", "Minnesota", "MN"],
"56278": ["Ortonville", "Minnesota", "MN"],
"56279": ["Pennock", "Minnesota", "MN"],
"56280": ["Porter", "Minnesota", "MN"],
"56281": ["Prinsburg", "Minnesota", "MN"],
"56282": ["Raymond", "Minnesota", "MN"],
"56283": ["Redwood Falls", "Minnesota", "MN"],
"56284": ["Renville", "Minnesota", "MN"],
"56285": ["Sacred Heart", "Minnesota", "MN"],
"56287": ["Seaforth", "Minnesota", "MN"],
"56288": ["Spicer", "Minnesota", "MN"],
"56289": ["Sunburg", "Minnesota", "MN"],
"56291": ["Taunton", "Minnesota", "MN"],
"56292": ["Vesta", "Minnesota", "MN"],
"56293": ["Wabasso", "Minnesota", "MN"],
"56294": ["Wanda", "Minnesota", "MN"],
"56295": ["Watson", "Minnesota", "MN"],
"56296": ["Wheaton", "Minnesota", "MN"],
"56297": ["Wood Lake", "Minnesota", "MN"],
"56301": ["Saint Cloud", "Minnesota", "MN"],
"56303": ["Saint Cloud", "Minnesota", "MN"],
"56304": ["Saint Cloud", "Minnesota", "MN"],
"56307": ["Albany", "Minnesota", "MN"],
"56308": ["Alexandria", "Minnesota", "MN"],
"56309": ["Ashby", "Minnesota", "MN"],
"56310": ["Avon", "Minnesota", "MN"],
"56311": ["Barrett", "Minnesota", "MN"],
"56312": ["Belgrade", "Minnesota", "MN"],
"56313": ["Bock", "Minnesota", "MN"],
"56314": ["Bowlus", "Minnesota", "MN"],
"56315": ["Brandon", "Minnesota", "MN"],
"56316": ["Brooten", "Minnesota", "MN"],
"56318": ["Burtrum", "Minnesota", "MN"],
"56319": ["Carlos", "Minnesota", "MN"],
"56320": ["Cold Spring", "Minnesota", "MN"],
"56321": ["Collegeville", "Minnesota", "MN"],
"56323": ["Cyrus", "Minnesota", "MN"],
"56324": ["Dalton", "Minnesota", "MN"],
"56325": ["Elrosa", "Minnesota", "MN"],
"56326": ["Evansville", "Minnesota", "MN"],
"56327": ["Farwell", "Minnesota", "MN"],
"56328": ["Flensburg", "Minnesota", "MN"],
"56329": ["Foley", "Minnesota", "MN"],
"56330": ["Foreston", "Minnesota", "MN"],
"56331": ["Freeport", "Minnesota", "MN"],
"56332": ["Garfield", "Minnesota", "MN"],
"56334": ["Glenwood", "Minnesota", "MN"],
"56335": ["Greenwald", "Minnesota", "MN"],
"56336": ["Grey Eagle", "Minnesota", "MN"],
"56338": ["Hillman", "Minnesota", "MN"],
"56339": ["Hoffman", "Minnesota", "MN"],
"56340": ["Holdingford", "Minnesota", "MN"],
"56342": ["Isle", "Minnesota", "MN"],
"56343": ["Kensington", "Minnesota", "MN"],
"56345": ["Little Falls", "Minnesota", "MN"],
"56347": ["Long Prairie", "Minnesota", "MN"],
"56349": ["Lowry", "Minnesota", "MN"],
"56350": ["McGrath", "Minnesota", "MN"],
"56352": ["Melrose", "Minnesota", "MN"],
"56353": ["Milaca", "Minnesota", "MN"],
"56354": ["Miltona", "Minnesota", "MN"],
"56355": ["Nelson", "Minnesota", "MN"],
"56356": ["New Munich", "Minnesota", "MN"],
"56357": ["Oak Park", "Minnesota", "MN"],
"56358": ["Ogilvie", "Minnesota", "MN"],
"56359": ["Onamia", "Minnesota", "MN"],
"56360": ["Osakis", "Minnesota", "MN"],
"56361": ["Parkers Prairie", "Minnesota", "MN"],
"56362": ["Paynesville", "Minnesota", "MN"],
"56363": ["Pease", "Minnesota", "MN"],
"56364": ["Pierz", "Minnesota", "MN"],
"56367": ["Rice", "Minnesota", "MN"],
"56368": ["Richmond", "Minnesota", "MN"],
"56369": ["Rockville", "Minnesota", "MN"],
"56371": ["Roscoe", "Minnesota", "MN"],
"56373": ["Royalton", "Minnesota", "MN"],
"56374": ["Saint Joseph", "Minnesota", "MN"],
"56375": ["Saint Stephen", "Minnesota", "MN"],
"56376": ["Saint Martin", "Minnesota", "MN"],
"56377": ["Sartell", "Minnesota", "MN"],
"56378": ["Sauk Centre", "Minnesota", "MN"],
"56379": ["Sauk Rapids", "Minnesota", "MN"],
"56381": ["Starbuck", "Minnesota", "MN"],
"56382": ["Swanville", "Minnesota", "MN"],
"56384": ["Upsala", "Minnesota", "MN"],
"56385": ["Villard", "Minnesota", "MN"],
"56386": ["Wahkon", "Minnesota", "MN"],
"56387": ["Waite Park", "Minnesota", "MN"],
"56389": ["West Union", "Minnesota", "MN"],
"56401": ["Brainerd", "Minnesota", "MN"],
"56425": ["Baxter", "Minnesota", "MN"],
"56431": ["Aitkin", "Minnesota", "MN"],
"56433": ["Akeley", "Minnesota", "MN"],
"56434": ["Aldrich", "Minnesota", "MN"],
"56435": ["Backus", "Minnesota", "MN"],
"56437": ["Bertha", "Minnesota", "MN"],
"56438": ["Browerville", "Minnesota", "MN"],
"56440": ["Clarissa", "Minnesota", "MN"],
"56441": ["Crosby", "Minnesota", "MN"],
"56442": ["Crosslake", "Minnesota", "MN"],
"56443": ["Cushing", "Minnesota", "MN"],
"56444": ["Deerwood", "Minnesota", "MN"],
"56446": ["Eagle Bend", "Minnesota", "MN"],
"56447": ["Emily", "Minnesota", "MN"],
"56448": ["Fifty Lakes", "Minnesota", "MN"],
"56449": ["Fort Ripley", "Minnesota", "MN"],
"56450": ["Garrison", "Minnesota", "MN"],
"56452": ["Hackensack", "Minnesota", "MN"],
"56453": ["Hewitt", "Minnesota", "MN"],
"56455": ["Ironton", "Minnesota", "MN"],
"56458": ["Lake George", "Minnesota", "MN"],
"56461": ["Laporte", "Minnesota", "MN"],
"56464": ["Menahga", "Minnesota", "MN"],
"56465": ["Merrifield", "Minnesota", "MN"],
"56466": ["Motley", "Minnesota", "MN"],
"56467": ["Nevis", "Minnesota", "MN"],
"56468": ["Nisswa", "Minnesota", "MN"],
"56469": ["Palisade", "Minnesota", "MN"],
"56470": ["Park Rapids", "Minnesota", "MN"],
"56472": ["Pequot Lakes", "Minnesota", "MN"],
"56473": ["Pillager", "Minnesota", "MN"],
"56474": ["Pine River", "Minnesota", "MN"],
"56475": ["Randall", "Minnesota", "MN"],
"56477": ["Sebeka", "Minnesota", "MN"],
"56479": ["Staples", "Minnesota", "MN"],
"56481": ["Verndale", "Minnesota", "MN"],
"56482": ["Wadena", "Minnesota", "MN"],
"56484": ["Walker", "Minnesota", "MN"],
"56501": ["Detroit Lakes", "Minnesota", "MN"],
"56510": ["Ada", "Minnesota", "MN"],
"56511": ["Audubon", "Minnesota", "MN"],
"56514": ["Barnesville", "Minnesota", "MN"],
"56515": ["Battle Lake", "Minnesota", "MN"],
"56516": ["Bejou", "Minnesota", "MN"],
"56517": ["Beltrami", "Minnesota", "MN"],
"56518": ["Bluffton", "Minnesota", "MN"],
"56519": ["Borup", "Minnesota", "MN"],
"56520": ["Breckenridge", "Minnesota", "MN"],
"56521": ["Callaway", "Minnesota", "MN"],
"56522": ["Campbell", "Minnesota", "MN"],
"56523": ["Climax", "Minnesota", "MN"],
"56524": ["Clitherall", "Minnesota", "MN"],
"56525": ["Comstock", "Minnesota", "MN"],
"56527": ["Deer Creek", "Minnesota", "MN"],
"56528": ["Dent", "Minnesota", "MN"],
"56529": ["Dilworth", "Minnesota", "MN"],
"56531": ["Elbow Lake", "Minnesota", "MN"],
"56533": ["Elizabeth", "Minnesota", "MN"],
"56534": ["Erhard", "Minnesota", "MN"],
"56535": ["Erskine", "Minnesota", "MN"],
"56536": ["Felton", "Minnesota", "MN"],
"56537": ["Fergus Falls", "Minnesota", "MN"],
"56540": ["Fertile", "Minnesota", "MN"],
"56541": ["Flom", "Minnesota", "MN"],
"56542": ["Fosston", "Minnesota", "MN"],
"56543": ["Foxhome", "Minnesota", "MN"],
"56544": ["Frazee", "Minnesota", "MN"],
"56545": ["Gary", "Minnesota", "MN"],
"56546": ["Georgetown", "Minnesota", "MN"],
"56547": ["Glyndon", "Minnesota", "MN"],
"56548": ["Halstad", "Minnesota", "MN"],
"56549": ["Hawley", "Minnesota", "MN"],
"56550": ["Hendrum", "Minnesota", "MN"],
"56551": ["Henning", "Minnesota", "MN"],
"56552": ["Hitterdal", "Minnesota", "MN"],
"56553": ["Kent", "Minnesota", "MN"],
"56554": ["Lake Park", "Minnesota", "MN"],
"56556": ["Mcintosh", "Minnesota", "MN"],
"56557": ["Mahnomen", "Minnesota", "MN"],
"56560": ["Moorhead", "Minnesota", "MN"],
"56562": ["Moorhead", "Minnesota", "MN"],
"56563": ["Moorhead", "Minnesota", "MN"],
"56565": ["Nashua", "Minnesota", "MN"],
"56566": ["Naytahwaush", "Minnesota", "MN"],
"56567": ["New York Mills", "Minnesota", "MN"],
"56568": ["Nielsville", "Minnesota", "MN"],
"56569": ["Ogema", "Minnesota", "MN"],
"56570": ["Osage", "Minnesota", "MN"],
"56571": ["Ottertail", "Minnesota", "MN"],
"56572": ["Pelican Rapids", "Minnesota", "MN"],
"56573": ["Perham", "Minnesota", "MN"],
"56574": ["Perley", "Minnesota", "MN"],
"56575": ["Ponsford", "Minnesota", "MN"],
"56576": ["Richville", "Minnesota", "MN"],
"56578": ["Rochert", "Minnesota", "MN"],
"56579": ["Rothsay", "Minnesota", "MN"],
"56580": ["Sabin", "Minnesota", "MN"],
"56581": ["Shelly", "Minnesota", "MN"],
"56583": ["Tintah", "Minnesota", "MN"],
"56584": ["Twin Valley", "Minnesota", "MN"],
"56585": ["Ulen", "Minnesota", "MN"],
"56586": ["Underwood", "Minnesota", "MN"],
"56587": ["Vergas", "Minnesota", "MN"],
"56588": ["Vining", "Minnesota", "MN"],
"56589": ["Waubun", "Minnesota", "MN"],
"56590": ["Wendell", "Minnesota", "MN"],
"56591": ["White Earth", "Minnesota", "MN"],
"56592": ["Winger", "Minnesota", "MN"],
"56594": ["Wolverton", "Minnesota", "MN"],
"56601": ["Bemidji", "Minnesota", "MN"],
"56621": ["Bagley", "Minnesota", "MN"],
"56623": ["Baudette", "Minnesota", "MN"],
"56626": ["Bena", "Minnesota", "MN"],
"56627": ["Big Falls", "Minnesota", "MN"],
"56628": ["Bigfork", "Minnesota", "MN"],
"56629": ["Birchdale", "Minnesota", "MN"],
"56630": ["Blackduck", "Minnesota", "MN"],
"56633": ["Cass Lake", "Minnesota", "MN"],
"56634": ["Clearbrook", "Minnesota", "MN"],
"56636": ["Deer River", "Minnesota", "MN"],
"56637": ["Talmoon", "Minnesota", "MN"],
"56639": ["Effie", "Minnesota", "MN"],
"56641": ["Federal Dam", "Minnesota", "MN"],
"56644": ["Gonvick", "Minnesota", "MN"],
"56646": ["Gully", "Minnesota", "MN"],
"56647": ["Hines", "Minnesota", "MN"],
"56649": ["International Falls", "Minnesota", "MN"],
"56650": ["Kelliher", "Minnesota", "MN"],
"56651": ["Lengby", "Minnesota", "MN"],
"56652": ["Leonard", "Minnesota", "MN"],
"56653": ["Littlefork", "Minnesota", "MN"],
"56654": ["Loman", "Minnesota", "MN"],
"56655": ["Longville", "Minnesota", "MN"],
"56657": ["Marcell", "Minnesota", "MN"],
"56658": ["Margie", "Minnesota", "MN"],
"56659": ["Max", "Minnesota", "MN"],
"56660": ["Mizpah", "Minnesota", "MN"],
"56661": ["Northome", "Minnesota", "MN"],
"56662": ["Outing", "Minnesota", "MN"],
"56663": ["Pennington", "Minnesota", "MN"],
"56666": ["Ponemah", "Minnesota", "MN"],
"56667": ["Puposky", "Minnesota", "MN"],
"56668": ["Ranier", "Minnesota", "MN"],
"56669": ["Kabetogama", "Minnesota", "MN"],
"56670": ["Redby", "Minnesota", "MN"],
"56671": ["Redlake", "Minnesota", "MN"],
"56672": ["Remer", "Minnesota", "MN"],
"56673": ["Roosevelt", "Minnesota", "MN"],
"56676": ["Shevlin", "Minnesota", "MN"],
"56678": ["Solway", "Minnesota", "MN"],
"56680": ["Spring Lake", "Minnesota", "MN"],
"56681": ["Squaw Lake", "Minnesota", "MN"],
"56683": ["Tenstrike", "Minnesota", "MN"],
"56684": ["Trail", "Minnesota", "MN"],
"56685": ["Waskish", "Minnesota", "MN"],
"56686": ["Williams", "Minnesota", "MN"],
"56688": ["Wirt", "Minnesota", "MN"],
"56701": ["Thief River Falls", "Minnesota", "MN"],
"56710": ["Alvarado", "Minnesota", "MN"],
"56711": ["Angle Inlet", "Minnesota", "MN"],
"56713": ["Argyle", "Minnesota", "MN"],
"56714": ["Badger", "Minnesota", "MN"],
"56715": ["Brooks", "Minnesota", "MN"],
"56716": ["Crookston", "Minnesota", "MN"],
"56720": ["Donaldson", "Minnesota", "MN"],
"56721": ["East Grand Forks", "Minnesota", "MN"],
"56722": ["Euclid", "Minnesota", "MN"],
"56723": ["Fisher", "Minnesota", "MN"],
"56724": ["Gatzke", "Minnesota", "MN"],
"56725": ["Goodridge", "Minnesota", "MN"],
"56726": ["Greenbush", "Minnesota", "MN"],
"56727": ["Grygla", "Minnesota", "MN"],
"56728": ["Hallock", "Minnesota", "MN"],
"56729": ["Halma", "Minnesota", "MN"],
"56731": ["Humboldt", "Minnesota", "MN"],
"56732": ["Karlstad", "Minnesota", "MN"],
"56733": ["Kennedy", "Minnesota", "MN"],
"56734": ["Lake Bronson", "Minnesota", "MN"],
"56735": ["Lancaster", "Minnesota", "MN"],
"56736": ["Mentor", "Minnesota", "MN"],
"56737": ["Middle River", "Minnesota", "MN"],
"56738": ["Newfolden", "Minnesota", "MN"],
"56742": ["Oklee", "Minnesota", "MN"],
"56744": ["Oslo", "Minnesota", "MN"],
"56748": ["Plummer", "Minnesota", "MN"],
"56750": ["Red Lake Falls", "Minnesota", "MN"],
"56751": ["Roseau", "Minnesota", "MN"],
"56754": ["Saint Hilaire", "Minnesota", "MN"],
"56755": ["Saint Vincent", "Minnesota", "MN"],
"56756": ["Salol", "Minnesota", "MN"],
"56757": ["Stephen", "Minnesota", "MN"],
"56758": ["Strandquist", "Minnesota", "MN"],
"56759": ["Strathcona", "Minnesota", "MN"],
"56760": ["Viking", "Minnesota", "MN"],
"56761": ["Wannaska", "Minnesota", "MN"],
"56762": ["Warren", "Minnesota", "MN"],
"56763": ["Warroad", "Minnesota", "MN"],
"57001": ["Alcester", "South Dakota", "SD"],
"57002": ["Aurora", "South Dakota", "SD"],
"57003": ["Baltic", "South Dakota", "SD"],
"57004": ["Beresford", "South Dakota", "SD"],
"57005": ["Brandon", "South Dakota", "SD"],
"57006": ["Brookings", "South Dakota", "SD"],
"57007": ["Brookings", "South Dakota", "SD"],
"57010": ["Burbank", "South Dakota", "SD"],
"57012": ["Canistota", "South Dakota", "SD"],
"57013": ["Canton", "South Dakota", "SD"],
"57014": ["Centerville", "South Dakota", "SD"],
"57015": ["Chancellor", "South Dakota", "SD"],
"57016": ["Chester", "South Dakota", "SD"],
"57017": ["Colman", "South Dakota", "SD"],
"57018": ["Colton", "South Dakota", "SD"],
"57020": ["Crooks", "South Dakota", "SD"],
"57021": ["Davis", "South Dakota", "SD"],
"57022": ["Dell Rapids", "South Dakota", "SD"],
"57024": ["Egan", "South Dakota", "SD"],
"57025": ["Elk Point", "South Dakota", "SD"],
"57026": ["Elkton", "South Dakota", "SD"],
"57027": ["Fairview", "South Dakota", "SD"],
"57028": ["Flandreau", "South Dakota", "SD"],
"57029": ["Freeman", "South Dakota", "SD"],
"57030": ["Garretson", "South Dakota", "SD"],
"57031": ["Gayville", "South Dakota", "SD"],
"57032": ["Harrisburg", "South Dakota", "SD"],
"57033": ["Hartford", "South Dakota", "SD"],
"57034": ["Hudson", "South Dakota", "SD"],
"57035": ["Humboldt", "South Dakota", "SD"],
"57036": ["Hurley", "South Dakota", "SD"],
"57037": ["Irene", "South Dakota", "SD"],
"57038": ["Jefferson", "South Dakota", "SD"],
"57039": ["Lennox", "South Dakota", "SD"],
"57040": ["Lesterville", "South Dakota", "SD"],
"57041": ["Lyons", "South Dakota", "SD"],
"57042": ["Madison", "South Dakota", "SD"],
"57043": ["Marion", "South Dakota", "SD"],
"57045": ["Menno", "South Dakota", "SD"],
"57046": ["Mission Hill", "South Dakota", "SD"],
"57047": ["Monroe", "South Dakota", "SD"],
"57048": ["Montrose", "South Dakota", "SD"],
"57049": ["North Sioux City", "South Dakota", "SD"],
"57050": ["Nunda", "South Dakota", "SD"],
"57051": ["Oldham", "South Dakota", "SD"],
"57052": ["Olivet", "South Dakota", "SD"],
"57053": ["Parker", "South Dakota", "SD"],
"57054": ["Ramona", "South Dakota", "SD"],
"57055": ["Renner", "South Dakota", "SD"],
"57057": ["Rutland", "South Dakota", "SD"],
"57058": ["Salem", "South Dakota", "SD"],
"57059": ["Scotland", "South Dakota", "SD"],
"57061": ["Sinai", "South Dakota", "SD"],
"57062": ["Springfield", "South Dakota", "SD"],
"57063": ["Tabor", "South Dakota", "SD"],
"57064": ["Tea", "South Dakota", "SD"],
"57065": ["Trent", "South Dakota", "SD"],
"57066": ["Tyndall", "South Dakota", "SD"],
"57067": ["Utica", "South Dakota", "SD"],
"57068": ["Valley Springs", "South Dakota", "SD"],
"57069": ["Vermillion", "South Dakota", "SD"],
"57070": ["Viborg", "South Dakota", "SD"],
"57071": ["Volga", "South Dakota", "SD"],
"57072": ["Volin", "South Dakota", "SD"],
"57073": ["Wakonda", "South Dakota", "SD"],
"57075": ["Wentworth", "South Dakota", "SD"],
"57076": ["Winfred", "South Dakota", "SD"],
"57077": ["Worthing", "South Dakota", "SD"],
"57078": ["Yankton", "South Dakota", "SD"],
"57103": ["Sioux Falls", "South Dakota", "SD"],
"57104": ["Sioux Falls", "South Dakota", "SD"],
"57105": ["Sioux Falls", "South Dakota", "SD"],
"57106": ["Sioux Falls", "South Dakota", "SD"],
"57107": ["Sioux Falls", "South Dakota", "SD"],
"57108": ["Sioux Falls", "South Dakota", "SD"],
"57110": ["Sioux Falls", "South Dakota", "SD"],
"57117": ["Sioux Falls", "South Dakota", "SD"],
"57197": ["Sioux Falls", "South Dakota", "SD"],
"57201": ["Watertown", "South Dakota", "SD"],
"57212": ["Arlington", "South Dakota", "SD"],
"57213": ["Astoria", "South Dakota", "SD"],
"57214": ["Badger", "South Dakota", "SD"],
"57216": ["Big Stone City", "South Dakota", "SD"],
"57217": ["Bradley", "South Dakota", "SD"],
"57218": ["Brandt", "South Dakota", "SD"],
"57219": ["Bristol", "South Dakota", "SD"],
"57220": ["Bruce", "South Dakota", "SD"],
"57221": ["Bryant", "South Dakota", "SD"],
"57223": ["Castlewood", "South Dakota", "SD"],
"57224": ["Claire City", "South Dakota", "SD"],
"57225": ["Clark", "South Dakota", "SD"],
"57226": ["Clear Lake", "South Dakota", "SD"],
"57227": ["Corona", "South Dakota", "SD"],
"57231": ["De Smet", "South Dakota", "SD"],
"57232": ["Eden", "South Dakota", "SD"],
"57233": ["Erwin", "South Dakota", "SD"],
"57234": ["Estelline", "South Dakota", "SD"],
"57235": ["Florence", "South Dakota", "SD"],
"57236": ["Garden City", "South Dakota", "SD"],
"57237": ["Gary", "South Dakota", "SD"],
"57238": ["Goodwin", "South Dakota", "SD"],
"57239": ["Grenville", "South Dakota", "SD"],
"57241": ["Hayti", "South Dakota", "SD"],
"57242": ["Hazel", "South Dakota", "SD"],
"57243": ["Henry", "South Dakota", "SD"],
"57245": ["Kranzburg", "South Dakota", "SD"],
"57246": ["Labolt", "South Dakota", "SD"],
"57247": ["Lake City", "South Dakota", "SD"],
"57248": ["Lake Norden", "South Dakota", "SD"],
"57249": ["Lake Preston", "South Dakota", "SD"],
"57251": ["Marvin", "South Dakota", "SD"],
"57252": ["Milbank", "South Dakota", "SD"],
"57255": ["New Effington", "South Dakota", "SD"],
"57256": ["Ortley", "South Dakota", "SD"],
"57257": ["Peever", "South Dakota", "SD"],
"57258": ["Raymond", "South Dakota", "SD"],
"57259": ["Revillo", "South Dakota", "SD"],
"57260": ["Rosholt", "South Dakota", "SD"],
"57261": ["Roslyn", "South Dakota", "SD"],
"57262": ["Sisseton", "South Dakota", "SD"],
"57263": ["South Shore", "South Dakota", "SD"],
"57264": ["Stockholm", "South Dakota", "SD"],
"57265": ["Strandburg", "South Dakota", "SD"],
"57266": ["Summit", "South Dakota", "SD"],
"57268": ["Toronto", "South Dakota", "SD"],
"57269": ["Twin Brooks", "South Dakota", "SD"],
"57270": ["Veblen", "South Dakota", "SD"],
"57271": ["Vienna", "South Dakota", "SD"],
"57272": ["Wallace", "South Dakota", "SD"],
"57273": ["Waubay", "South Dakota", "SD"],
"57274": ["Webster", "South Dakota", "SD"],
"57276": ["White", "South Dakota", "SD"],
"57278": ["Willow Lake", "South Dakota", "SD"],
"57279": ["Wilmot", "South Dakota", "SD"],
"57301": ["Mitchell", "South Dakota", "SD"],
"57311": ["Alexandria", "South Dakota", "SD"],
"57312": ["Alpena", "South Dakota", "SD"],
"57313": ["Armour", "South Dakota", "SD"],
"57314": ["Artesian", "South Dakota", "SD"],
"57315": ["Avon", "South Dakota", "SD"],
"57317": ["Bonesteel", "South Dakota", "SD"],
"57319": ["Bridgewater", "South Dakota", "SD"],
"57321": ["Canova", "South Dakota", "SD"],
"57322": ["Carpenter", "South Dakota", "SD"],
"57323": ["Carthage", "South Dakota", "SD"],
"57324": ["Cavour", "South Dakota", "SD"],
"57325": ["Chamberlain", "South Dakota", "SD"],
"57328": ["Corsica", "South Dakota", "SD"],
"57329": ["Dante", "South Dakota", "SD"],
"57330": ["Delmont", "South Dakota", "SD"],
"57331": ["Dimock", "South Dakota", "SD"],
"57332": ["Emery", "South Dakota", "SD"],
"57334": ["Ethan", "South Dakota", "SD"],
"57335": ["Fairfax", "South Dakota", "SD"],
"57337": ["Fedora", "South Dakota", "SD"],
"57339": ["Fort Thompson", "South Dakota", "SD"],
"57340": ["Fulton", "South Dakota", "SD"],
"57341": ["Gann Valley", "South Dakota", "SD"],
"57342": ["Geddes", "South Dakota", "SD"],
"57344": ["Harrison", "South Dakota", "SD"],
"57345": ["Highmore", "South Dakota", "SD"],
"57346": ["Stephan", "South Dakota", "SD"],
"57348": ["Hitchcock", "South Dakota", "SD"],
"57349": ["Howard", "South Dakota", "SD"],
"57350": ["Huron", "South Dakota", "SD"],
"57353": ["Iroquois", "South Dakota", "SD"],
"57354": ["Kaylor", "South Dakota", "SD"],
"57355": ["Kimball", "South Dakota", "SD"],
"57356": ["Lake Andes", "South Dakota", "SD"],
"57358": ["Lane", "South Dakota", "SD"],
"57359": ["Letcher", "South Dakota", "SD"],
"57361": ["Marty", "South Dakota", "SD"],
"57362": ["Miller", "South Dakota", "SD"],
"57363": ["Mount Vernon", "South Dakota", "SD"],
"57364": ["New Holland", "South Dakota", "SD"],
"57365": ["Oacoma", "South Dakota", "SD"],
"57366": ["Parkston", "South Dakota", "SD"],
"57367": ["Pickstown", "South Dakota", "SD"],
"57368": ["Plankinton", "South Dakota", "SD"],
"57369": ["Platte", "South Dakota", "SD"],
"57370": ["Pukwana", "South Dakota", "SD"],
"57371": ["Ree Heights", "South Dakota", "SD"],
"57373": ["Saint Lawrence", "South Dakota", "SD"],
"57374": ["Spencer", "South Dakota", "SD"],
"57375": ["Stickney", "South Dakota", "SD"],
"57376": ["Tripp", "South Dakota", "SD"],
"57379": ["Virgil", "South Dakota", "SD"],
"57380": ["Wagner", "South Dakota", "SD"],
"57381": ["Wessington", "South Dakota", "SD"],
"57382": ["Wessington Springs", "South Dakota", "SD"],
"57383": ["White Lake", "South Dakota", "SD"],
"57384": ["Wolsey", "South Dakota", "SD"],
"57385": ["Woonsocket", "South Dakota", "SD"],
"57386": ["Yale", "South Dakota", "SD"],
"57401": ["Aberdeen", "South Dakota", "SD"],
"57420": ["Akaska", "South Dakota", "SD"],
"57421": ["Amherst", "South Dakota", "SD"],
"57422": ["Andover", "South Dakota", "SD"],
"57424": ["Ashton", "South Dakota", "SD"],
"57426": ["Barnard", "South Dakota", "SD"],
"57427": ["Bath", "South Dakota", "SD"],
"57428": ["Bowdle", "South Dakota", "SD"],
"57429": ["Brentford", "South Dakota", "SD"],
"57430": ["Britton", "South Dakota", "SD"],
"57432": ["Claremont", "South Dakota", "SD"],
"57433": ["Columbia", "South Dakota", "SD"],
"57434": ["Conde", "South Dakota", "SD"],
"57435": ["Cresbard", "South Dakota", "SD"],
"57436": ["Doland", "South Dakota", "SD"],
"57437": ["Eureka", "South Dakota", "SD"],
"57438": ["Faulkton", "South Dakota", "SD"],
"57439": ["Ferney", "South Dakota", "SD"],
"57440": ["Frankfort", "South Dakota", "SD"],
"57441": ["Frederick", "South Dakota", "SD"],
"57442": ["Gettysburg", "South Dakota", "SD"],
"57445": ["Groton", "South Dakota", "SD"],
"57446": ["Hecla", "South Dakota", "SD"],
"57448": ["Hosmer", "South Dakota", "SD"],
"57449": ["Houghton", "South Dakota", "SD"],
"57450": ["Hoven", "South Dakota", "SD"],
"57451": ["Ipswich", "South Dakota", "SD"],
"57452": ["Java", "South Dakota", "SD"],
"57454": ["Langford", "South Dakota", "SD"],
"57455": ["Lebanon", "South Dakota", "SD"],
"57456": ["Leola", "South Dakota", "SD"],
"57457": ["Long Lake", "South Dakota", "SD"],
"57460": ["Mansfield", "South Dakota", "SD"],
"57461": ["Mellette", "South Dakota", "SD"],
"57465": ["Northville", "South Dakota", "SD"],
"57466": ["Onaka", "South Dakota", "SD"],
"57467": ["Orient", "South Dakota", "SD"],
"57468": ["Pierpont", "South Dakota", "SD"],
"57469": ["Redfield", "South Dakota", "SD"],
"57470": ["Rockham", "South Dakota", "SD"],
"57471": ["Roscoe", "South Dakota", "SD"],
"57472": ["Selby", "South Dakota", "SD"],
"57473": ["Seneca", "South Dakota", "SD"],
"57474": ["Stratford", "South Dakota", "SD"],
"57475": ["Tolstoy", "South Dakota", "SD"],
"57476": ["Tulare", "South Dakota", "SD"],
"57477": ["Turton", "South Dakota", "SD"],
"57479": ["Warner", "South Dakota", "SD"],
"57481": ["Westport", "South Dakota", "SD"],
"57501": ["Pierre", "South Dakota", "SD"],
"57520": ["Agar", "South Dakota", "SD"],
"57521": ["Belvidere", "South Dakota", "SD"],
"57522": ["Blunt", "South Dakota", "SD"],
"57523": ["Burke", "South Dakota", "SD"],
"57528": ["Colome", "South Dakota", "SD"],
"57529": ["Dallas", "South Dakota", "SD"],
"57531": ["Draper", "South Dakota", "SD"],
"57532": ["Fort Pierre", "South Dakota", "SD"],
"57533": ["Gregory", "South Dakota", "SD"],
"57534": ["Hamill", "South Dakota", "SD"],
"57536": ["Harrold", "South Dakota", "SD"],
"57537": ["Hayes", "South Dakota", "SD"],
"57538": ["Herrick", "South Dakota", "SD"],
"57540": ["Holabird", "South Dakota", "SD"],
"57541": ["Ideal", "South Dakota", "SD"],
"57543": ["Kadoka", "South Dakota", "SD"],
"57544": ["Kennebec", "South Dakota", "SD"],
"57547": ["Long Valley", "South Dakota", "SD"],
"57548": ["Lower Brule", "South Dakota", "SD"],
"57551": ["Martin", "South Dakota", "SD"],
"57552": ["Midland", "South Dakota", "SD"],
"57553": ["Milesville", "South Dakota", "SD"],
"57555": ["Mission", "South Dakota", "SD"],
"57559": ["Murdo", "South Dakota", "SD"],
"57560": ["Norris", "South Dakota", "SD"],
"57562": ["Okaton", "South Dakota", "SD"],
"57563": ["Okreek", "South Dakota", "SD"],
"57564": ["Onida", "South Dakota", "SD"],
"57566": ["Parmelee", "South Dakota", "SD"],
"57567": ["Philip", "South Dakota", "SD"],
"57568": ["Presho", "South Dakota", "SD"],
"57569": ["Reliance", "South Dakota", "SD"],
"57570": ["Rosebud", "South Dakota", "SD"],
"57571": ["Saint Charles", "South Dakota", "SD"],
"57572": ["Saint Francis", "South Dakota", "SD"],
"57574": ["Tuthill", "South Dakota", "SD"],
"57576": ["Vivian", "South Dakota", "SD"],
"57577": ["Wanblee", "South Dakota", "SD"],
"57579": ["White River", "South Dakota", "SD"],
"57580": ["Winner", "South Dakota", "SD"],
"57584": ["Witten", "South Dakota", "SD"],
"57585": ["Wood", "South Dakota", "SD"],
"57601": ["Mobridge", "South Dakota", "SD"],
"57620": ["Bison", "South Dakota", "SD"],
"57621": ["Bullhead", "South Dakota", "SD"],
"57622": ["Cherry Creek", "South Dakota", "SD"],
"57623": ["Dupree", "South Dakota", "SD"],
"57625": ["Eagle Butte", "South Dakota", "SD"],
"57626": ["Faith", "South Dakota", "SD"],
"57630": ["Glencross", "South Dakota", "SD"],
"57631": ["Glenham", "South Dakota", "SD"],
"57632": ["Herreid", "South Dakota", "SD"],
"57633": ["Isabel", "South Dakota", "SD"],
"57634": ["Keldron", "South Dakota", "SD"],
"57636": ["Lantry", "South Dakota", "SD"],
"57638": ["Lemmon", "South Dakota", "SD"],
"57639": ["Little Eagle", "South Dakota", "SD"],
"57640": ["Lodgepole", "South Dakota", "SD"],
"57641": ["McIntosh", "South Dakota", "SD"],
"57642": ["McLaughlin", "South Dakota", "SD"],
"57644": ["Meadow", "South Dakota", "SD"],
"57645": ["Morristown", "South Dakota", "SD"],
"57646": ["Mound City", "South Dakota", "SD"],
"57648": ["Pollock", "South Dakota", "SD"],
"57649": ["Prairie City", "South Dakota", "SD"],
"57650": ["Ralph", "South Dakota", "SD"],
"57651": ["Reva", "South Dakota", "SD"],
"57652": ["Ridgeview", "South Dakota", "SD"],
"57656": ["Timber Lake", "South Dakota", "SD"],
"57657": ["Trail City", "South Dakota", "SD"],
"57658": ["Wakpala", "South Dakota", "SD"],
"57659": ["Walker", "South Dakota", "SD"],
"57660": ["Watauga", "South Dakota", "SD"],
"57661": ["Whitehorse", "South Dakota", "SD"],
"57701": ["Rapid City", "South Dakota", "SD"],
"57702": ["Rapid City", "South Dakota", "SD"],
"57703": ["Rapid City", "South Dakota", "SD"],
"57706": ["Ellsworth Afb", "South Dakota", "SD"],
"57714": ["Allen", "South Dakota", "SD"],
"57716": ["Batesland", "South Dakota", "SD"],
"57717": ["Belle Fourche", "South Dakota", "SD"],
"57718": ["Black Hawk", "South Dakota", "SD"],
"57719": ["Box Elder", "South Dakota", "SD"],
"57720": ["Buffalo", "South Dakota", "SD"],
"57722": ["Buffalo Gap", "South Dakota", "SD"],
"57724": ["Camp Crook", "South Dakota", "SD"],
"57725": ["Caputa", "South Dakota", "SD"],
"57730": ["Custer", "South Dakota", "SD"],
"57732": ["Deadwood", "South Dakota", "SD"],
"57735": ["Edgemont", "South Dakota", "SD"],
"57737": ["Enning", "South Dakota", "SD"],
"57738": ["Fairburn", "South Dakota", "SD"],
"57741": ["Fort Meade", "South Dakota", "SD"],
"57744": ["Hermosa", "South Dakota", "SD"],
"57745": ["Hill City", "South Dakota", "SD"],
"57747": ["Hot Springs", "South Dakota", "SD"],
"57748": ["Howes", "South Dakota", "SD"],
"57750": ["Interior", "South Dakota", "SD"],
"57751": ["Keystone", "South Dakota", "SD"],
"57752": ["Kyle", "South Dakota", "SD"],
"57754": ["Lead", "South Dakota", "SD"],
"57755": ["Ludlow", "South Dakota", "SD"],
"57756": ["Manderson", "South Dakota", "SD"],
"57758": ["Mud Butte", "South Dakota", "SD"],
"57759": ["Nemo", "South Dakota", "SD"],
"57760": ["Newell", "South Dakota", "SD"],
"57761": ["New Underwood", "South Dakota", "SD"],
"57762": ["Nisland", "South Dakota", "SD"],
"57763": ["Oelrichs", "South Dakota", "SD"],
"57764": ["Oglala", "South Dakota", "SD"],
"57766": ["Oral", "South Dakota", "SD"],
"57767": ["Owanka", "South Dakota", "SD"],
"57769": ["Piedmont", "South Dakota", "SD"],
"57770": ["Pine Ridge", "South Dakota", "SD"],
"57772": ["Porcupine", "South Dakota", "SD"],
"57773": ["Pringle", "South Dakota", "SD"],
"57775": ["Quinn", "South Dakota", "SD"],
"57776": ["Redig", "South Dakota", "SD"],
"57779": ["Saint Onge", "South Dakota", "SD"],
"57780": ["Scenic", "South Dakota", "SD"],
"57782": ["Smithwick", "South Dakota", "SD"],
"57783": ["Spearfish", "South Dakota", "SD"],
"57785": ["Sturgis", "South Dakota", "SD"],
"57787": ["Union Center", "South Dakota", "SD"],
"57788": ["Vale", "South Dakota", "SD"],
"57790": ["Wall", "South Dakota", "SD"],
"57791": ["Wasta", "South Dakota", "SD"],
"57792": ["White Owl", "South Dakota", "SD"],
"57793": ["Whitewood", "South Dakota", "SD"],
"57794": ["Wounded Knee", "South Dakota", "SD"],
"57799": ["Spearfish", "South Dakota", "SD"],
"58001": ["Abercrombie", "North Dakota", "ND"],
"58002": ["Absaraka", "North Dakota", "ND"],
"58004": ["Amenia", "North Dakota", "ND"],
"58005": ["Argusville", "North Dakota", "ND"],
"58006": ["Arthur", "North Dakota", "ND"],
"58007": ["Ayr", "North Dakota", "ND"],
"58008": ["Barney", "North Dakota", "ND"],
"58009": ["Blanchard", "North Dakota", "ND"],
"58011": ["Buffalo", "North Dakota", "ND"],
"58012": ["Casselton", "North Dakota", "ND"],
"58013": ["Cayuga", "North Dakota", "ND"],
"58015": ["Christine", "North Dakota", "ND"],
"58016": ["Clifford", "North Dakota", "ND"],
"58017": ["Cogswell", "North Dakota", "ND"],
"58018": ["Colfax", "North Dakota", "ND"],
"58021": ["Davenport", "North Dakota", "ND"],
"58027": ["Enderlin", "North Dakota", "ND"],
"58029": ["Erie", "North Dakota", "ND"],
"58030": ["Fairmount", "North Dakota", "ND"],
"58031": ["Fingal", "North Dakota", "ND"],
"58032": ["Forman", "North Dakota", "ND"],
"58033": ["Fort Ransom", "North Dakota", "ND"],
"58035": ["Galesburg", "North Dakota", "ND"],
"58036": ["Gardner", "North Dakota", "ND"],
"58038": ["Grandin", "North Dakota", "ND"],
"58040": ["Gwinner", "North Dakota", "ND"],
"58041": ["Hankinson", "North Dakota", "ND"],
"58042": ["Harwood", "North Dakota", "ND"],
"58043": ["Havana", "North Dakota", "ND"],
"58045": ["Hillsboro", "North Dakota", "ND"],
"58046": ["Hope", "North Dakota", "ND"],
"58047": ["Horace", "North Dakota", "ND"],
"58048": ["Hunter", "North Dakota", "ND"],
"58049": ["Kathryn", "North Dakota", "ND"],
"58051": ["Kindred", "North Dakota", "ND"],
"58052": ["Leonard", "North Dakota", "ND"],
"58053": ["Lidgerwood", "North Dakota", "ND"],
"58054": ["Lisbon", "North Dakota", "ND"],
"58056": ["Luverne", "North Dakota", "ND"],
"58057": ["Mcleod", "North Dakota", "ND"],
"58058": ["Mantador", "North Dakota", "ND"],
"58059": ["Mapleton", "North Dakota", "ND"],
"58060": ["Milnor", "North Dakota", "ND"],
"58061": ["Mooreton", "North Dakota", "ND"],
"58062": ["Nome", "North Dakota", "ND"],
"58063": ["Oriska", "North Dakota", "ND"],
"58064": ["Page", "North Dakota", "ND"],
"58065": ["Pillsbury", "North Dakota", "ND"],
"58067": ["Rutland", "North Dakota", "ND"],
"58068": ["Sheldon", "North Dakota", "ND"],
"58069": ["Stirum", "North Dakota", "ND"],
"58071": ["Tower City", "North Dakota", "ND"],
"58072": ["Valley City", "North Dakota", "ND"],
"58074": ["Wahpeton", "North Dakota", "ND"],
"58075": ["Wahpeton", "North Dakota", "ND"],
"58076": ["Wahpeton", "North Dakota", "ND"],
"58077": ["Walcott", "North Dakota", "ND"],
"58078": ["West Fargo", "North Dakota", "ND"],
"58079": ["Wheatland", "North Dakota", "ND"],
"58081": ["Wyndmere", "North Dakota", "ND"],
"58102": ["Fargo", "North Dakota", "ND"],
"58103": ["Fargo", "North Dakota", "ND"],
"58104": ["Fargo", "North Dakota", "ND"],
"58105": ["Fargo", "North Dakota", "ND"],
"58201": ["Grand Forks", "North Dakota", "ND"],
"58202": ["Grand Forks", "North Dakota", "ND"],
"58203": ["Grand Forks", "North Dakota", "ND"],
"58204": ["Grand Forks Afb", "North Dakota", "ND"],
"58205": ["Grand Forks Afb", "North Dakota", "ND"],
"58210": ["Adams", "North Dakota", "ND"],
"58212": ["Aneta", "North Dakota", "ND"],
"58214": ["Arvilla", "North Dakota", "ND"],
"58216": ["Bathgate", "North Dakota", "ND"],
"58218": ["Buxton", "North Dakota", "ND"],
"58219": ["Caledonia", "North Dakota", "ND"],
"58220": ["Cavalier", "North Dakota", "ND"],
"58222": ["Crystal", "North Dakota", "ND"],
"58223": ["Cummings", "North Dakota", "ND"],
"58224": ["Dahlen", "North Dakota", "ND"],
"58225": ["Drayton", "North Dakota", "ND"],
"58227": ["Edinburg", "North Dakota", "ND"],
"58228": ["Emerado", "North Dakota", "ND"],
"58229": ["Fairdale", "North Dakota", "ND"],
"58230": ["Finley", "North Dakota", "ND"],
"58231": ["Fordville", "North Dakota", "ND"],
"58233": ["Forest River", "North Dakota", "ND"],
"58235": ["Gilby", "North Dakota", "ND"],
"58236": ["Glasston", "North Dakota", "ND"],
"58237": ["Grafton", "North Dakota", "ND"],
"58238": ["Hamilton", "North Dakota", "ND"],
"58239": ["Hannah", "North Dakota", "ND"],
"58240": ["Hatton", "North Dakota", "ND"],
"58241": ["Hensel", "North Dakota", "ND"],
"58243": ["Hoople", "North Dakota", "ND"],
"58244": ["Inkster", "North Dakota", "ND"],
"58249": ["Langdon", "North Dakota", "ND"],
"58250": ["Lankin", "North Dakota", "ND"],
"58251": ["Larimore", "North Dakota", "ND"],
"58254": ["Mcville", "North Dakota", "ND"],
"58255": ["Maida", "North Dakota", "ND"],
"58256": ["Manvel", "North Dakota", "ND"],
"58257": ["Mayville", "North Dakota", "ND"],
"58258": ["Mekinock", "North Dakota", "ND"],
"58259": ["Michigan", "North Dakota", "ND"],
"58260": ["Milton", "North Dakota", "ND"],
"58261": ["Minto", "North Dakota", "ND"],
"58262": ["Mountain", "North Dakota", "ND"],
"58265": ["Neche", "North Dakota", "ND"],
"58266": ["Niagara", "North Dakota", "ND"],
"58267": ["Northwood", "North Dakota", "ND"],
"58269": ["Osnabrock", "North Dakota", "ND"],
"58270": ["Park River", "North Dakota", "ND"],
"58271": ["Pembina", "North Dakota", "ND"],
"58272": ["Petersburg", "North Dakota", "ND"],
"58273": ["Pisek", "North Dakota", "ND"],
"58274": ["Portland", "North Dakota", "ND"],
"58275": ["Reynolds", "North Dakota", "ND"],
"58276": ["Saint Thomas", "North Dakota", "ND"],
"58277": ["Sharon", "North Dakota", "ND"],
"58278": ["Thompson", "North Dakota", "ND"],
"58281": ["Wales", "North Dakota", "ND"],
"58282": ["Walhalla", "North Dakota", "ND"],
"58301": ["Devils Lake", "North Dakota", "ND"],
"58310": ["Agate", "North Dakota", "ND"],
"58311": ["Alsen", "North Dakota", "ND"],
"58313": ["Balta", "North Dakota", "ND"],
"58316": ["Belcourt", "North Dakota", "ND"],
"58317": ["Bisbee", "North Dakota", "ND"],
"58318": ["Bottineau", "North Dakota", "ND"],
"58321": ["Brocket", "North Dakota", "ND"],
"58323": ["Calvin", "North Dakota", "ND"],
"58324": ["Cando", "North Dakota", "ND"],
"58325": ["Churchs Ferry", "North Dakota", "ND"],
"58327": ["Crary", "North Dakota", "ND"],
"58329": ["Dunseith", "North Dakota", "ND"],
"58330": ["Edmore", "North Dakota", "ND"],
"58331": ["Egeland", "North Dakota", "ND"],
"58332": ["Esmond", "North Dakota", "ND"],
"58335": ["Fort Totten", "North Dakota", "ND"],
"58338": ["Hampden", "North Dakota", "ND"],
"58339": ["Hansboro", "North Dakota", "ND"],
"58341": ["Harvey", "North Dakota", "ND"],
"58343": ["Knox", "North Dakota", "ND"],
"58344": ["Lakota", "North Dakota", "ND"],
"58345": ["Lawton", "North Dakota", "ND"],
"58346": ["Leeds", "North Dakota", "ND"],
"58348": ["Maddock", "North Dakota", "ND"],
"58351": ["Minnewaukan", "North Dakota", "ND"],
"58352": ["Munich", "North Dakota", "ND"],
"58353": ["Mylo", "North Dakota", "ND"],
"58355": ["Nekoma", "North Dakota", "ND"],
"58356": ["New Rockford", "North Dakota", "ND"],
"58357": ["Oberon", "North Dakota", "ND"],
"58361": ["Pekin", "North Dakota", "ND"],
"58362": ["Penn", "North Dakota", "ND"],
"58363": ["Perth", "North Dakota", "ND"],
"58365": ["Rocklake", "North Dakota", "ND"],
"58366": ["Rolette", "North Dakota", "ND"],
"58367": ["Rolla", "North Dakota", "ND"],
"58368": ["Rugby", "North Dakota", "ND"],
"58369": ["Saint John", "North Dakota", "ND"],
"58370": ["Saint Michael", "North Dakota", "ND"],
"58372": ["Sarles", "North Dakota", "ND"],
"58374": ["Sheyenne", "North Dakota", "ND"],
"58377": ["Starkweather", "North Dakota", "ND"],
"58379": ["Tokio", "North Dakota", "ND"],
"58380": ["Tolna", "North Dakota", "ND"],
"58381": ["Warwick", "North Dakota", "ND"],
"58382": ["Webster", "North Dakota", "ND"],
"58384": ["Willow City", "North Dakota", "ND"],
"58385": ["Wolford", "North Dakota", "ND"],
"58386": ["York", "North Dakota", "ND"],
"58401": ["Jamestown", "North Dakota", "ND"],
"58402": ["Jamestown", "North Dakota", "ND"],
"58405": ["Jamestown", "North Dakota", "ND"],
"58413": ["Ashley", "North Dakota", "ND"],
"58415": ["Berlin", "North Dakota", "ND"],
"58416": ["Binford", "North Dakota", "ND"],
"58418": ["Bowdon", "North Dakota", "ND"],
"58420": ["Buchanan", "North Dakota", "ND"],
"58421": ["Carrington", "North Dakota", "ND"],
"58422": ["Cathay", "North Dakota", "ND"],
"58423": ["Chaseley", "North Dakota", "ND"],
"58424": ["Cleveland", "North Dakota", "ND"],
"58425": ["Cooperstown", "North Dakota", "ND"],
"58426": ["Courtenay", "North Dakota", "ND"],
"58428": ["Dawson", "North Dakota", "ND"],
"58429": ["Dazey", "North Dakota", "ND"],
"58430": ["Denhoff", "North Dakota", "ND"],
"58431": ["Dickey", "North Dakota", "ND"],
"58433": ["Edgeley", "North Dakota", "ND"],
"58436": ["Ellendale", "North Dakota", "ND"],
"58438": ["Fessenden", "North Dakota", "ND"],
"58439": ["Forbes", "North Dakota", "ND"],
"58440": ["Fredonia", "North Dakota", "ND"],
"58441": ["Fullerton", "North Dakota", "ND"],
"58442": ["Gackle", "North Dakota", "ND"],
"58443": ["Glenfield", "North Dakota", "ND"],
"58444": ["Goodrich", "North Dakota", "ND"],
"58445": ["Grace City", "North Dakota", "ND"],
"58448": ["Hannaford", "North Dakota", "ND"],
"58451": ["Hurdsfield", "North Dakota", "ND"],
"58452": ["Jessie", "North Dakota", "ND"],
"58454": ["Jud", "North Dakota", "ND"],
"58455": ["Kensal", "North Dakota", "ND"],
"58456": ["Kulm", "North Dakota", "ND"],
"58458": ["Lamoure", "North Dakota", "ND"],
"58460": ["Lehr", "North Dakota", "ND"],
"58461": ["Litchville", "North Dakota", "ND"],
"58463": ["Mcclusky", "North Dakota", "ND"],
"58464": ["Mchenry", "North Dakota", "ND"],
"58466": ["Marion", "North Dakota", "ND"],
"58467": ["Medina", "North Dakota", "ND"],
"58472": ["Montpelier", "North Dakota", "ND"],
"58474": ["Oakes", "North Dakota", "ND"],
"58475": ["Pettibone", "North Dakota", "ND"],
"58476": ["Pingree", "North Dakota", "ND"],
"58477": ["Regan", "North Dakota", "ND"],
"58478": ["Robinson", "North Dakota", "ND"],
"58479": ["Rogers", "North Dakota", "ND"],
"58480": ["Sanborn", "North Dakota", "ND"],
"58481": ["Spiritwood", "North Dakota", "ND"],
"58482": ["Steele", "North Dakota", "ND"],
"58483": ["Streeter", "North Dakota", "ND"],
"58484": ["Sutton", "North Dakota", "ND"],
"58486": ["Sykeston", "North Dakota", "ND"],
"58487": ["Tappen", "North Dakota", "ND"],
"58488": ["Tuttle", "North Dakota", "ND"],
"58490": ["Verona", "North Dakota", "ND"],
"58492": ["Wimbledon", "North Dakota", "ND"],
"58494": ["Wing", "North Dakota", "ND"],
"58495": ["Wishek", "North Dakota", "ND"],
"58496": ["Woodworth", "North Dakota", "ND"],
"58497": ["Ypsilanti", "North Dakota", "ND"],
"58501": ["Bismarck", "North Dakota", "ND"],
"58503": ["Bismarck", "North Dakota", "ND"],
"58504": ["Bismarck", "North Dakota", "ND"],
"58505": ["Bismarck", "North Dakota", "ND"],
"58520": ["Almont", "North Dakota", "ND"],
"58521": ["Baldwin", "North Dakota", "ND"],
"58523": ["Beulah", "North Dakota", "ND"],
"58524": ["Braddock", "North Dakota", "ND"],
"58528": ["Cannon Ball", "North Dakota", "ND"],
"58529": ["Carson", "North Dakota", "ND"],
"58530": ["Center", "North Dakota", "ND"],
"58531": ["Coleharbor", "North Dakota", "ND"],
"58532": ["Driscoll", "North Dakota", "ND"],
"58533": ["Elgin", "North Dakota", "ND"],
"58535": ["Flasher", "North Dakota", "ND"],
"58538": ["Fort Yates", "North Dakota", "ND"],
"58540": ["Garrison", "North Dakota", "ND"],
"58541": ["Golden Valley", "North Dakota", "ND"],
"58542": ["Hague", "North Dakota", "ND"],
"58544": ["Hazelton", "North Dakota", "ND"],
"58545": ["Hazen", "North Dakota", "ND"],
"58549": ["Kintyre", "North Dakota", "ND"],
"58552": ["Linton", "North Dakota", "ND"],
"58554": ["Mandan", "North Dakota", "ND"],
"58558": ["Menoken", "North Dakota", "ND"],
"58559": ["Mercer", "North Dakota", "ND"],
"58560": ["Moffit", "North Dakota", "ND"],
"58561": ["Napoleon", "North Dakota", "ND"],
"58562": ["New Leipzig", "North Dakota", "ND"],
"58563": ["New Salem", "North Dakota", "ND"],
"58564": ["Raleigh", "North Dakota", "ND"],
"58565": ["Riverdale", "North Dakota", "ND"],
"58566": ["Saint Anthony", "North Dakota", "ND"],
"58568": ["Selfridge", "North Dakota", "ND"],
"58569": ["Shields", "North Dakota", "ND"],
"58570": ["Solen", "North Dakota", "ND"],
"58571": ["Stanton", "North Dakota", "ND"],
"58572": ["Sterling", "North Dakota", "ND"],
"58573": ["Strasburg", "North Dakota", "ND"],
"58575": ["Turtle Lake", "North Dakota", "ND"],
"58576": ["Underwood", "North Dakota", "ND"],
"58577": ["Washburn", "North Dakota", "ND"],
"58579": ["Wilton", "North Dakota", "ND"],
"58580": ["Zap", "North Dakota", "ND"],
"58581": ["Zeeland", "North Dakota", "ND"],
"58601": ["Dickinson", "North Dakota", "ND"],
"58620": ["Amidon", "North Dakota", "ND"],
"58621": ["Beach", "North Dakota", "ND"],
"58622": ["Belfield", "North Dakota", "ND"],
"58623": ["Bowman", "North Dakota", "ND"],
"58625": ["Dodge", "North Dakota", "ND"],
"58626": ["Dunn Center", "North Dakota", "ND"],
"58627": ["Fairfield", "North Dakota", "ND"],
"58630": ["Gladstone", "North Dakota", "ND"],
"58631": ["Glen Ullin", "North Dakota", "ND"],
"58632": ["Golva", "North Dakota", "ND"],
"58634": ["Grassy Butte", "North Dakota", "ND"],
"58636": ["Halliday", "North Dakota", "ND"],
"58638": ["Hebron", "North Dakota", "ND"],
"58639": ["Hettinger", "North Dakota", "ND"],
"58640": ["Killdeer", "North Dakota", "ND"],
"58641": ["Lefor", "North Dakota", "ND"],
"58642": ["Manning", "North Dakota", "ND"],
"58643": ["Marmarth", "North Dakota", "ND"],
"58644": ["Marshall", "North Dakota", "ND"],
"58645": ["Medora", "North Dakota", "ND"],
"58646": ["Mott", "North Dakota", "ND"],
"58647": ["New England", "North Dakota", "ND"],
"58649": ["Reeder", "North Dakota", "ND"],
"58650": ["Regent", "North Dakota", "ND"],
"58651": ["Rhame", "North Dakota", "ND"],
"58652": ["Richardton", "North Dakota", "ND"],
"58653": ["Scranton", "North Dakota", "ND"],
"58654": ["Sentinel Butte", "North Dakota", "ND"],
"58655": ["South Heart", "North Dakota", "ND"],
"58656": ["Taylor", "North Dakota", "ND"],
"58701": ["Minot", "North Dakota", "ND"],
"58703": ["Minot", "North Dakota", "ND"],
"58704": ["Minot Afb", "North Dakota", "ND"],
"58705": ["Minot Afb", "North Dakota", "ND"],
"58707": ["Minot", "North Dakota", "ND"],
"58710": ["Anamoose", "North Dakota", "ND"],
"58711": ["Antler", "North Dakota", "ND"],
"58712": ["Balfour", "North Dakota", "ND"],
"58713": ["Bantry", "North Dakota", "ND"],
"58716": ["Benedict", "North Dakota", "ND"],
"58718": ["Berthold", "North Dakota", "ND"],
"58721": ["Bowbells", "North Dakota", "ND"],
"58722": ["Burlington", "North Dakota", "ND"],
"58723": ["Butte", "North Dakota", "ND"],
"58725": ["Carpio", "North Dakota", "ND"],
"58727": ["Columbus", "North Dakota", "ND"],
"58730": ["Crosby", "North Dakota", "ND"],
"58731": ["Deering", "North Dakota", "ND"],
"58733": ["Des Lacs", "North Dakota", "ND"],
"58734": ["Donnybrook", "North Dakota", "ND"],
"58735": ["Douglas", "North Dakota", "ND"],
"58736": ["Drake", "North Dakota", "ND"],
"58737": ["Flaxton", "North Dakota", "ND"],
"58740": ["Glenburn", "North Dakota", "ND"],
"58741": ["Granville", "North Dakota", "ND"],
"58744": ["Karlsruhe", "North Dakota", "ND"],
"58746": ["Kenmare", "North Dakota", "ND"],
"58748": ["Kramer", "North Dakota", "ND"],
"58750": ["Lansford", "North Dakota", "ND"],
"58752": ["Lignite", "North Dakota", "ND"],
"58755": ["Mcgregor", "North Dakota", "ND"],
"58756": ["Makoti", "North Dakota", "ND"],
"58757": ["Mandaree", "North Dakota", "ND"],
"58758": ["Martin", "North Dakota", "ND"],
"58759": ["Max", "North Dakota", "ND"],
"58760": ["Maxbass", "North Dakota", "ND"],
"58761": ["Mohall", "North Dakota", "ND"],
"58762": ["Newburg", "North Dakota", "ND"],
"58763": ["New Town", "North Dakota", "ND"],
"58765": ["Noonan", "North Dakota", "ND"],
"58768": ["Norwich", "North Dakota", "ND"],
"58769": ["Palermo", "North Dakota", "ND"],
"58770": ["Parshall", "North Dakota", "ND"],
"58771": ["Plaza", "North Dakota", "ND"],
"58772": ["Portal", "North Dakota", "ND"],
"58773": ["Powers Lake", "North Dakota", "ND"],
"58775": ["Roseglen", "North Dakota", "ND"],
"58776": ["Ross", "North Dakota", "ND"],
"58778": ["Ruso", "North Dakota", "ND"],
"58779": ["Ryder", "North Dakota", "ND"],
"58781": ["Sawyer", "North Dakota", "ND"],
"58782": ["Sherwood", "North Dakota", "ND"],
"58783": ["Souris", "North Dakota", "ND"],
"58784": ["Stanley", "North Dakota", "ND"],
"58785": ["Surrey", "North Dakota", "ND"],
"58787": ["Tolley", "North Dakota", "ND"],
"58788": ["Towner", "North Dakota", "ND"],
"58789": ["Upham", "North Dakota", "ND"],
"58790": ["Velva", "North Dakota", "ND"],
"58792": ["Voltaire", "North Dakota", "ND"],
"58793": ["Westhope", "North Dakota", "ND"],
"58794": ["White Earth", "North Dakota", "ND"],
"58795": ["Wildrose", "North Dakota", "ND"],
"58801": ["Williston", "North Dakota", "ND"],
"58830": ["Alamo", "North Dakota", "ND"],
"58831": ["Alexander", "North Dakota", "ND"],
"58833": ["Ambrose", "North Dakota", "ND"],
"58835": ["Arnegard", "North Dakota", "ND"],
"58838": ["Cartwright", "North Dakota", "ND"],
"58843": ["Epping", "North Dakota", "ND"],
"58844": ["Fortuna", "North Dakota", "ND"],
"58845": ["Grenora", "North Dakota", "ND"],
"58847": ["Keene", "North Dakota", "ND"],
"58849": ["Ray", "North Dakota", "ND"],
"58852": ["Tioga", "North Dakota", "ND"],
"58853": ["Trenton", "North Dakota", "ND"],
"58854": ["Watford City", "North Dakota", "ND"],
"58856": ["Zahl", "North Dakota", "ND"],
"59001": ["Absarokee", "Montana", "MT"],
"59002": ["Acton", "Montana", "MT"],
"59003": ["Ashland", "Montana", "MT"],
"59006": ["Ballantine", "Montana", "MT"],
"59007": ["Bearcreek", "Montana", "MT"],
"59008": ["Belfry", "Montana", "MT"],
"59010": ["Bighorn", "Montana", "MT"],
"59011": ["Big Timber", "Montana", "MT"],
"59012": ["Birney", "Montana", "MT"],
"59013": ["Boyd", "Montana", "MT"],
"59014": ["Bridger", "Montana", "MT"],
"59015": ["Broadview", "Montana", "MT"],
"59016": ["Busby", "Montana", "MT"],
"59018": ["Clyde Park", "Montana", "MT"],
"59019": ["Columbus", "Montana", "MT"],
"59020": ["Cooke City", "Montana", "MT"],
"59022": ["Crow Agency", "Montana", "MT"],
"59024": ["Custer", "Montana", "MT"],
"59025": ["Decker", "Montana", "MT"],
"59026": ["Edgar", "Montana", "MT"],
"59027": ["Emigrant", "Montana", "MT"],
"59028": ["Fishtail", "Montana", "MT"],
"59029": ["Fromberg", "Montana", "MT"],
"59030": ["Gardiner", "Montana", "MT"],
"59031": ["Garryowen", "Montana", "MT"],
"59032": ["Grass Range", "Montana", "MT"],
"59033": ["Greycliff", "Montana", "MT"],
"59034": ["Hardin", "Montana", "MT"],
"59035": ["Fort Smith", "Montana", "MT"],
"59036": ["Harlowton", "Montana", "MT"],
"59037": ["Huntley", "Montana", "MT"],
"59038": ["Hysham", "Montana", "MT"],
"59039": ["Ingomar", "Montana", "MT"],
"59041": ["Joliet", "Montana", "MT"],
"59043": ["Lame Deer", "Montana", "MT"],
"59044": ["Laurel", "Montana", "MT"],
"59046": ["Lavina", "Montana", "MT"],
"59047": ["Livingston", "Montana", "MT"],
"59050": ["Lodge Grass", "Montana", "MT"],
"59052": ["McLeod", "Montana", "MT"],
"59053": ["Martinsdale", "Montana", "MT"],
"59054": ["Melstone", "Montana", "MT"],
"59055": ["Melville", "Montana", "MT"],
"59057": ["Molt", "Montana", "MT"],
"59058": ["Mosby", "Montana", "MT"],
"59059": ["Musselshell", "Montana", "MT"],
"59061": ["Nye", "Montana", "MT"],
"59062": ["Otter", "Montana", "MT"],
"59063": ["Park City", "Montana", "MT"],
"59064": ["Pompeys Pillar", "Montana", "MT"],
"59065": ["Pray", "Montana", "MT"],
"59066": ["Pryor", "Montana", "MT"],
"59067": ["Rapelje", "Montana", "MT"],
"59068": ["Red Lodge", "Montana", "MT"],
"59069": ["Reed Point", "Montana", "MT"],
"59070": ["Roberts", "Montana", "MT"],
"59071": ["Roscoe", "Montana", "MT"],
"59072": ["Roundup", "Montana", "MT"],
"59074": ["Ryegate", "Montana", "MT"],
"59075": ["Saint Xavier", "Montana", "MT"],
"59076": ["Sanders", "Montana", "MT"],
"59077": ["Sand Springs", "Montana", "MT"],
"59078": ["Shawmut", "Montana", "MT"],
"59079": ["Shepherd", "Montana", "MT"],
"59081": ["Silver Gate", "Montana", "MT"],
"59082": ["Springdale", "Montana", "MT"],
"59083": ["Sumatra", "Montana", "MT"],
"59085": ["Two Dot", "Montana", "MT"],
"59086": ["Wilsall", "Montana", "MT"],
"59087": ["Winnett", "Montana", "MT"],
"59088": ["Worden", "Montana", "MT"],
"59089": ["Wyola", "Montana", "MT"],
"59101": ["Billings", "Montana", "MT"],
"59102": ["Billings", "Montana", "MT"],
"59105": ["Billings", "Montana", "MT"],
"59106": ["Billings", "Montana", "MT"],
"59201": ["Wolf Point", "Montana", "MT"],
"59211": ["Antelope", "Montana", "MT"],
"59212": ["Bainville", "Montana", "MT"],
"59213": ["Brockton", "Montana", "MT"],
"59214": ["Brockway", "Montana", "MT"],
"59215": ["Circle", "Montana", "MT"],
"59217": ["Crane", "Montana", "MT"],
"59218": ["Culbertson", "Montana", "MT"],
"59219": ["Dagmar", "Montana", "MT"],
"59221": ["Fairview", "Montana", "MT"],
"59222": ["Flaxville", "Montana", "MT"],
"59223": ["Fort Peck", "Montana", "MT"],
"59225": ["Frazer", "Montana", "MT"],
"59226": ["Froid", "Montana", "MT"],
"59230": ["Glasgow", "Montana", "MT"],
"59231": ["Saint Marie", "Montana", "MT"],
"59240": ["Glentana", "Montana", "MT"],
"59241": ["Hinsdale", "Montana", "MT"],
"59242": ["Homestead", "Montana", "MT"],
"59243": ["Lambert", "Montana", "MT"],
"59244": ["Larslan", "Montana", "MT"],
"59247": ["Medicine Lake", "Montana", "MT"],
"59248": ["Nashua", "Montana", "MT"],
"59250": ["Opheim", "Montana", "MT"],
"59252": ["Outlook", "Montana", "MT"],
"59253": ["Peerless", "Montana", "MT"],
"59254": ["Plentywood", "Montana", "MT"],
"59255": ["Poplar", "Montana", "MT"],
"59256": ["Raymond", "Montana", "MT"],
"59257": ["Redstone", "Montana", "MT"],
"59258": ["Reserve", "Montana", "MT"],
"59259": ["Richey", "Montana", "MT"],
"59260": ["Richland", "Montana", "MT"],
"59261": ["Saco", "Montana", "MT"],
"59262": ["Savage", "Montana", "MT"],
"59263": ["Scobey", "Montana", "MT"],
"59270": ["Sidney", "Montana", "MT"],
"59273": ["Vandalia", "Montana", "MT"],
"59274": ["Vida", "Montana", "MT"],
"59275": ["Westby", "Montana", "MT"],
"59276": ["Whitetail", "Montana", "MT"],
"59301": ["Miles City", "Montana", "MT"],
"59311": ["Alzada", "Montana", "MT"],
"59312": ["Angela", "Montana", "MT"],
"59313": ["Baker", "Montana", "MT"],
"59314": ["Biddle", "Montana", "MT"],
"59315": ["Bloomfield", "Montana", "MT"],
"59316": ["Boyes", "Montana", "MT"],
"59317": ["Broadus", "Montana", "MT"],
"59318": ["Brusett", "Montana", "MT"],
"59319": ["Capitol", "Montana", "MT"],
"59322": ["Cohagen", "Montana", "MT"],
"59323": ["Colstrip", "Montana", "MT"],
"59324": ["Ekalaka", "Montana", "MT"],
"59326": ["Fallon", "Montana", "MT"],
"59327": ["Forsyth", "Montana", "MT"],
"59330": ["Glendive", "Montana", "MT"],
"59332": ["Hammond", "Montana", "MT"],
"59333": ["Hathaway", "Montana", "MT"],
"59336": ["Ismay", "Montana", "MT"],
"59337": ["Jordan", "Montana", "MT"],
"59338": ["Kinsey", "Montana", "MT"],
"59339": ["Lindsay", "Montana", "MT"],
"59343": ["Olive", "Montana", "MT"],
"59344": ["Plevna", "Montana", "MT"],
"59345": ["Powderville", "Montana", "MT"],
"59347": ["Rosebud", "Montana", "MT"],
"59349": ["Terry", "Montana", "MT"],
"59351": ["Volborg", "Montana", "MT"],
"59353": ["Wibaux", "Montana", "MT"],
"59354": ["Willard", "Montana", "MT"],
"59401": ["Great Falls", "Montana", "MT"],
"59404": ["Great Falls", "Montana", "MT"],
"59405": ["Great Falls", "Montana", "MT"],
"59410": ["Augusta", "Montana", "MT"],
"59411": ["Babb", "Montana", "MT"],
"59412": ["Belt", "Montana", "MT"],
"59414": ["Black Eagle", "Montana", "MT"],
"59416": ["Brady", "Montana", "MT"],
"59417": ["Browning", "Montana", "MT"],
"59418": ["Buffalo", "Montana", "MT"],
"59419": ["Bynum", "Montana", "MT"],
"59420": ["Carter", "Montana", "MT"],
"59421": ["Cascade", "Montana", "MT"],
"59422": ["Choteau", "Montana", "MT"],
"59424": ["Coffee Creek", "Montana", "MT"],
"59425": ["Conrad", "Montana", "MT"],
"59427": ["Cut Bank", "Montana", "MT"],
"59430": ["Denton", "Montana", "MT"],
"59432": ["Dupuyer", "Montana", "MT"],
"59433": ["Dutton", "Montana", "MT"],
"59434": ["East Glacier Park", "Montana", "MT"],
"59435": ["Ethridge", "Montana", "MT"],
"59436": ["Fairfield", "Montana", "MT"],
"59440": ["Floweree", "Montana", "MT"],
"59441": ["Forest Grove", "Montana", "MT"],
"59442": ["Fort Benton", "Montana", "MT"],
"59443": ["Fort Shaw", "Montana", "MT"],
"59444": ["Galata", "Montana", "MT"],
"59446": ["Geraldine", "Montana", "MT"],
"59447": ["Geyser", "Montana", "MT"],
"59448": ["Heart Butte", "Montana", "MT"],
"59450": ["Highwood", "Montana", "MT"],
"59451": ["Hilger", "Montana", "MT"],
"59452": ["Hobson", "Montana", "MT"],
"59453": ["Judith Gap", "Montana", "MT"],
"59454": ["Kevin", "Montana", "MT"],
"59456": ["Ledger", "Montana", "MT"],
"59457": ["Lewistown", "Montana", "MT"],
"59460": ["Loma", "Montana", "MT"],
"59461": ["Lothair", "Montana", "MT"],
"59462": ["Moccasin", "Montana", "MT"],
"59463": ["Monarch", "Montana", "MT"],
"59464": ["Moore", "Montana", "MT"],
"59465": ["Neihart", "Montana", "MT"],
"59466": ["Oilmont", "Montana", "MT"],
"59467": ["Pendroy", "Montana", "MT"],
"59468": ["Power", "Montana", "MT"],
"59469": ["Raynesford", "Montana", "MT"],
"59471": ["Roy", "Montana", "MT"],
"59472": ["Sand Coulee", "Montana", "MT"],
"59474": ["Shelby", "Montana", "MT"],
"59477": ["Simms", "Montana", "MT"],
"59479": ["Stanford", "Montana", "MT"],
"59480": ["Stockett", "Montana", "MT"],
"59482": ["Sunburst", "Montana", "MT"],
"59483": ["Sun River", "Montana", "MT"],
"59484": ["Sweet Grass", "Montana", "MT"],
"59485": ["Ulm", "Montana", "MT"],
"59486": ["Valier", "Montana", "MT"],
"59487": ["Vaughn", "Montana", "MT"],
"59489": ["Winifred", "Montana", "MT"],
"59501": ["Havre", "Montana", "MT"],
"59520": ["Big Sandy", "Montana", "MT"],
"59521": ["Box Elder", "Montana", "MT"],
"59522": ["Chester", "Montana", "MT"],
"59523": ["Chinook", "Montana", "MT"],
"59524": ["Dodson", "Montana", "MT"],
"59525": ["Gildford", "Montana", "MT"],
"59526": ["Harlem", "Montana", "MT"],
"59527": ["Hays", "Montana", "MT"],
"59528": ["Hingham", "Montana", "MT"],
"59529": ["Hogeland", "Montana", "MT"],
"59530": ["Inverness", "Montana", "MT"],
"59531": ["Joplin", "Montana", "MT"],
"59532": ["Kremlin", "Montana", "MT"],
"59535": ["Lloyd", "Montana", "MT"],
"59537": ["Loring", "Montana", "MT"],
"59538": ["Malta", "Montana", "MT"],
"59540": ["Rudyard", "Montana", "MT"],
"59542": ["Turner", "Montana", "MT"],
"59544": ["Whitewater", "Montana", "MT"],
"59545": ["Whitlash", "Montana", "MT"],
"59546": ["Zortman", "Montana", "MT"],
"59547": ["Zurich", "Montana", "MT"],
"59601": ["Helena", "Montana", "MT"],
"59602": ["Helena", "Montana", "MT"],
"59623": ["Helena", "Montana", "MT"],
"59625": ["Helena", "Montana", "MT"],
"59631": ["Basin", "Montana", "MT"],
"59632": ["Boulder", "Montana", "MT"],
"59633": ["Canyon Creek", "Montana", "MT"],
"59634": ["Clancy", "Montana", "MT"],
"59635": ["East Helena", "Montana", "MT"],
"59636": ["Fort Harrison", "Montana", "MT"],
"59638": ["Jefferson City", "Montana", "MT"],
"59639": ["Lincoln", "Montana", "MT"],
"59640": ["Marysville", "Montana", "MT"],
"59642": ["Ringling", "Montana", "MT"],
"59643": ["Toston", "Montana", "MT"],
"59644": ["Townsend", "Montana", "MT"],
"59645": ["White Sulphur Springs", "Montana", "MT"],
"59647": ["Winston", "Montana", "MT"],
"59648": ["Wolf Creek", "Montana", "MT"],
"59701": ["Butte", "Montana", "MT"],
"59710": ["Alder", "Montana", "MT"],
"59711": ["Anaconda", "Montana", "MT"],
"59713": ["Avon", "Montana", "MT"],
"59714": ["Belgrade", "Montana", "MT"],
"59715": ["Bozeman", "Montana", "MT"],
"59716": ["Big Sky", "Montana", "MT"],
"59718": ["Bozeman", "Montana", "MT"],
"59720": ["Cameron", "Montana", "MT"],
"59721": ["Cardwell", "Montana", "MT"],
"59722": ["Deer Lodge", "Montana", "MT"],
"59724": ["Dell", "Montana", "MT"],
"59725": ["Dillon", "Montana", "MT"],
"59727": ["Divide", "Montana", "MT"],
"59728": ["Elliston", "Montana", "MT"],
"59729": ["Ennis", "Montana", "MT"],
"59730": ["Gallatin Gateway", "Montana", "MT"],
"59731": ["Garrison", "Montana", "MT"],
"59732": ["Glen", "Montana", "MT"],
"59733": ["Gold Creek", "Montana", "MT"],
"59735": ["Harrison", "Montana", "MT"],
"59736": ["Jackson", "Montana", "MT"],
"59739": ["Lima", "Montana", "MT"],
"59740": ["McAllister", "Montana", "MT"],
"59741": ["Manhattan", "Montana", "MT"],
"59743": ["Melrose", "Montana", "MT"],
"59745": ["Norris", "Montana", "MT"],
"59746": ["Polaris", "Montana", "MT"],
"59747": ["Pony", "Montana", "MT"],
"59748": ["Ramsay", "Montana", "MT"],
"59749": ["Sheridan", "Montana", "MT"],
"59750": ["Butte", "Montana", "MT"],
"59751": ["Silver Star", "Montana", "MT"],
"59752": ["Three Forks", "Montana", "MT"],
"59754": ["Twin Bridges", "Montana", "MT"],
"59755": ["Virginia City", "Montana", "MT"],
"59756": ["Warm Springs", "Montana", "MT"],
"59758": ["West Yellowstone", "Montana", "MT"],
"59759": ["Whitehall", "Montana", "MT"],
"59760": ["Willow Creek", "Montana", "MT"],
"59761": ["Wisdom", "Montana", "MT"],
"59762": ["Wise River", "Montana", "MT"],
"59801": ["Missoula", "Montana", "MT"],
"59802": ["Missoula", "Montana", "MT"],
"59803": ["Missoula", "Montana", "MT"],
"59804": ["Missoula", "Montana", "MT"],
"59808": ["Missoula", "Montana", "MT"],
"59812": ["Missoula", "Montana", "MT"],
"59820": ["Alberton", "Montana", "MT"],
"59821": ["Arlee", "Montana", "MT"],
"59823": ["Bonner", "Montana", "MT"],
"59824": ["Charlo", "Montana", "MT"],
"59825": ["Clinton", "Montana", "MT"],
"59826": ["Condon", "Montana", "MT"],
"59827": ["Conner", "Montana", "MT"],
"59828": ["Corvallis", "Montana", "MT"],
"59829": ["Darby", "Montana", "MT"],
"59830": ["De Borgia", "Montana", "MT"],
"59831": ["Dixon", "Montana", "MT"],
"59832": ["Drummond", "Montana", "MT"],
"59833": ["Florence", "Montana", "MT"],
"59834": ["Frenchtown", "Montana", "MT"],
"59837": ["Hall", "Montana", "MT"],
"59840": ["Hamilton", "Montana", "MT"],
"59841": ["Pinesdale", "Montana", "MT"],
"59842": ["Haugan", "Montana", "MT"],
"59843": ["Helmville", "Montana", "MT"],
"59844": ["Heron", "Montana", "MT"],
"59845": ["Hot Springs", "Montana", "MT"],
"59846": ["Huson", "Montana", "MT"],
"59847": ["Lolo", "Montana", "MT"],
"59848": ["Lonepine", "Montana", "MT"],
"59851": ["Milltown", "Montana", "MT"],
"59853": ["Noxon", "Montana", "MT"],
"59854": ["Ovando", "Montana", "MT"],
"59855": ["Pablo", "Montana", "MT"],
"59856": ["Paradise", "Montana", "MT"],
"59858": ["Philipsburg", "Montana", "MT"],
"59859": ["Plains", "Montana", "MT"],
"59860": ["Polson", "Montana", "MT"],
"59863": ["Ravalli", "Montana", "MT"],
"59864": ["Ronan", "Montana", "MT"],
"59865": ["Saint Ignatius", "Montana", "MT"],
"59866": ["Saint Regis", "Montana", "MT"],
"59867": ["Saltese", "Montana", "MT"],
"59868": ["Seeley Lake", "Montana", "MT"],
"59870": ["Stevensville", "Montana", "MT"],
"59871": ["Sula", "Montana", "MT"],
"59872": ["Superior", "Montana", "MT"],
"59873": ["Thompson Falls", "Montana", "MT"],
"59874": ["Trout Creek", "Montana", "MT"],
"59875": ["Victor", "Montana", "MT"],
"59901": ["Kalispell", "Montana", "MT"],
"59910": ["Big Arm", "Montana", "MT"],
"59911": ["Bigfork", "Montana", "MT"],
"59912": ["Columbia Falls", "Montana", "MT"],
"59913": ["Coram", "Montana", "MT"],
"59914": ["Dayton", "Montana", "MT"],
"59915": ["Elmo", "Montana", "MT"],
"59916": ["Essex", "Montana", "MT"],
"59917": ["Eureka", "Montana", "MT"],
"59918": ["Fortine", "Montana", "MT"],
"59919": ["Hungry Horse", "Montana", "MT"],
"59920": ["Kila", "Montana", "MT"],
"59922": ["Lakeside", "Montana", "MT"],
"59923": ["Libby", "Montana", "MT"],
"59925": ["Marion", "Montana", "MT"],
"59926": ["Martin City", "Montana", "MT"],
"59927": ["Olney", "Montana", "MT"],
"59928": ["Polebridge", "Montana", "MT"],
"59929": ["Proctor", "Montana", "MT"],
"59930": ["Rexford", "Montana", "MT"],
"59931": ["Rollins", "Montana", "MT"],
"59932": ["Somers", "Montana", "MT"],
"59933": ["Stryker", "Montana", "MT"],
"59934": ["Trego", "Montana", "MT"],
"59935": ["Troy", "Montana", "MT"],
"59936": ["West Glacier", "Montana", "MT"],
"59937": ["Whitefish", "Montana", "MT"],
"60002": ["Antioch", "Illinois", "IL"],
"60004": ["Arlington Heights", "Illinois", "IL"],
"60005": ["Arlington Heights", "Illinois", "IL"],
"60007": ["Elk Grove Village", "Illinois", "IL"],
"60008": ["Rolling Meadows", "Illinois", "IL"],
"60010": ["Barrington", "Illinois", "IL"],
"60012": ["Crystal Lake", "Illinois", "IL"],
"60013": ["Cary", "Illinois", "IL"],
"60014": ["Crystal Lake", "Illinois", "IL"],
"60015": ["Deerfield", "Illinois", "IL"],
"60016": ["Des Plaines", "Illinois", "IL"],
"60018": ["Des Plaines", "Illinois", "IL"],
"60020": ["Fox Lake", "Illinois", "IL"],
"60021": ["Fox River Grove", "Illinois", "IL"],
"60022": ["Glencoe", "Illinois", "IL"],
"60025": ["Glenview", "Illinois", "IL"],
"60026": ["Glenview", "Illinois", "IL"],
"60029": ["Golf", "Illinois", "IL"],
"60030": ["Grayslake", "Illinois", "IL"],
"60031": ["Gurnee", "Illinois", "IL"],
"60033": ["Harvard", "Illinois", "IL"],
"60034": ["Hebron", "Illinois", "IL"],
"60035": ["Highland Park", "Illinois", "IL"],
"60040": ["Highwood", "Illinois", "IL"],
"60041": ["Ingleside", "Illinois", "IL"],
"60042": ["Island Lake", "Illinois", "IL"],
"60043": ["Kenilworth", "Illinois", "IL"],
"60044": ["Lake Bluff", "Illinois", "IL"],
"60045": ["Lake Forest", "Illinois", "IL"],
"60046": ["Lake Villa", "Illinois", "IL"],
"60047": ["Lake Zurich", "Illinois", "IL"],
"60048": ["Libertyville", "Illinois", "IL"],
"60050": ["Mchenry", "Illinois", "IL"],
"60051": ["Mchenry", "Illinois", "IL"],
"60053": ["Morton Grove", "Illinois", "IL"],
"60056": ["Mount Prospect", "Illinois", "IL"],
"60060": ["Mundelein", "Illinois", "IL"],
"60061": ["Vernon Hills", "Illinois", "IL"],
"60062": ["Northbrook", "Illinois", "IL"],
"60064": ["North Chicago", "Illinois", "IL"],
"60067": ["Palatine", "Illinois", "IL"],
"60068": ["Park Ridge", "Illinois", "IL"],
"60069": ["Lincolnshire", "Illinois", "IL"],
"60070": ["Prospect Heights", "Illinois", "IL"],
"60071": ["Richmond", "Illinois", "IL"],
"60072": ["Ringwood", "Illinois", "IL"],
"60073": ["Round Lake", "Illinois", "IL"],
"60074": ["Palatine", "Illinois", "IL"],
"60075": ["Russell", "Illinois", "IL"],
"60076": ["Skokie", "Illinois", "IL"],
"60077": ["Skokie", "Illinois", "IL"],
"60081": ["Spring Grove", "Illinois", "IL"],
"60083": ["Wadsworth", "Illinois", "IL"],
"60084": ["Wauconda", "Illinois", "IL"],
"60085": ["Waukegan", "Illinois", "IL"],
"60087": ["Waukegan", "Illinois", "IL"],
"60088": ["Great Lakes", "Illinois", "IL"],
"60089": ["Buffalo Grove", "Illinois", "IL"],
"60090": ["Wheeling", "Illinois", "IL"],
"60091": ["Wilmette", "Illinois", "IL"],
"60093": ["Winnetka", "Illinois", "IL"],
"60096": ["Winthrop Harbor", "Illinois", "IL"],
"60097": ["Wonder Lake", "Illinois", "IL"],
"60098": ["Woodstock", "Illinois", "IL"],
"60099": ["Zion", "Illinois", "IL"],
"60101": ["Addison", "Illinois", "IL"],
"60102": ["Algonquin", "Illinois", "IL"],
"60103": ["Bartlett", "Illinois", "IL"],
"60104": ["Bellwood", "Illinois", "IL"],
"60106": ["Bensenville", "Illinois", "IL"],
"60107": ["Streamwood", "Illinois", "IL"],
"60108": ["Bloomingdale", "Illinois", "IL"],
"60109": ["Burlington", "Illinois", "IL"],
"60110": ["Carpentersville", "Illinois", "IL"],
"60111": ["Clare", "Illinois", "IL"],
"60112": ["Cortland", "Illinois", "IL"],
"60113": ["Creston", "Illinois", "IL"],
"60115": ["Dekalb", "Illinois", "IL"],
"60118": ["Dundee", "Illinois", "IL"],
"60119": ["Elburn", "Illinois", "IL"],
"60120": ["Elgin", "Illinois", "IL"],
"60123": ["Elgin", "Illinois", "IL"],
"60124": ["Elgin", "Illinois", "IL"],
"60126": ["Elmhurst", "Illinois", "IL"],
"60129": ["Esmond", "Illinois", "IL"],
"60130": ["Forest Park", "Illinois", "IL"],
"60131": ["Franklin Park", "Illinois", "IL"],
"60133": ["Hanover Park", "Illinois", "IL"],
"60134": ["Geneva", "Illinois", "IL"],
"60135": ["Genoa", "Illinois", "IL"],
"60136": ["Gilberts", "Illinois", "IL"],
"60137": ["Glen Ellyn", "Illinois", "IL"],
"60139": ["Glendale Heights", "Illinois", "IL"],
"60140": ["Hampshire", "Illinois", "IL"],
"60141": ["Hines", "Illinois", "IL"],
"60142": ["Huntley", "Illinois", "IL"],
"60143": ["Itasca", "Illinois", "IL"],
"60144": ["Kaneville", "Illinois", "IL"],
"60145": ["Kingston", "Illinois", "IL"],
"60146": ["Kirkland", "Illinois", "IL"],
"60148": ["Lombard", "Illinois", "IL"],
"60150": ["Malta", "Illinois", "IL"],
"60151": ["Maple Park", "Illinois", "IL"],
"60152": ["Marengo", "Illinois", "IL"],
"60153": ["Maywood", "Illinois", "IL"],
"60154": ["Westchester", "Illinois", "IL"],
"60155": ["Broadview", "Illinois", "IL"],
"60156": ["Lake In The Hills", "Illinois", "IL"],
"60157": ["Medinah", "Illinois", "IL"],
"60160": ["Melrose Park", "Illinois", "IL"],
"60162": ["Hillside", "Illinois", "IL"],
"60163": ["Berkeley", "Illinois", "IL"],
"60164": ["Melrose Park", "Illinois", "IL"],
"60165": ["Stone Park", "Illinois", "IL"],
"60169": ["Hoffman Estates", "Illinois", "IL"],
"60171": ["River Grove", "Illinois", "IL"],
"60172": ["Roselle", "Illinois", "IL"],
"60173": ["Schaumburg", "Illinois", "IL"],
"60174": ["Saint Charles", "Illinois", "IL"],
"60175": ["Saint Charles", "Illinois", "IL"],
"60176": ["Schiller Park", "Illinois", "IL"],
"60177": ["South Elgin", "Illinois", "IL"],
"60178": ["Sycamore", "Illinois", "IL"],
"60180": ["Union", "Illinois", "IL"],
"60181": ["Villa Park", "Illinois", "IL"],
"60183": ["Wasco", "Illinois", "IL"],
"60184": ["Wayne", "Illinois", "IL"],
"60185": ["West Chicago", "Illinois", "IL"],
"60187": ["Wheaton", "Illinois", "IL"],
"60188": ["Carol Stream", "Illinois", "IL"],
"60189": ["Wheaton", "Illinois", "IL"],
"60190": ["Winfield", "Illinois", "IL"],
"60191": ["Wood Dale", "Illinois", "IL"],
"60192": ["Hoffman Estates", "Illinois", "IL"],
"60193": ["Schaumburg", "Illinois", "IL"],
"60194": ["Schaumburg", "Illinois", "IL"],
"60195": ["Schaumburg", "Illinois", "IL"],
"60201": ["Evanston", "Illinois", "IL"],
"60202": ["Evanston", "Illinois", "IL"],
"60203": ["Evanston", "Illinois", "IL"],
"60208": ["Evanston", "Illinois", "IL"],
"60301": ["Oak Park", "Illinois", "IL"],
"60302": ["Oak Park", "Illinois", "IL"],
"60304": ["Oak Park", "Illinois", "IL"],
"60305": ["River Forest", "Illinois", "IL"],
"60401": ["Beecher", "Illinois", "IL"],
"60402": ["Berwyn", "Illinois", "IL"],
"60403": ["Crest Hill", "Illinois", "IL"],
"60404": ["Shorewood", "Illinois", "IL"],
"60406": ["Blue Island", "Illinois", "IL"],
"60407": ["Braceville", "Illinois", "IL"],
"60408": ["Braidwood", "Illinois", "IL"],
"60409": ["Calumet City", "Illinois", "IL"],
"60410": ["Channahon", "Illinois", "IL"],
"60411": ["Chicago Heights", "Illinois", "IL"],
"60415": ["Chicago Ridge", "Illinois", "IL"],
"60416": ["Coal City", "Illinois", "IL"],
"60417": ["Crete", "Illinois", "IL"],
"60418": ["Crestwood", "Illinois", "IL"],
"60419": ["Dolton", "Illinois", "IL"],
"60420": ["Dwight", "Illinois", "IL"],
"60421": ["Elwood", "Illinois", "IL"],
"60422": ["Flossmoor", "Illinois", "IL"],
"60423": ["Frankfort", "Illinois", "IL"],
"60424": ["Gardner", "Illinois", "IL"],
"60425": ["Glenwood", "Illinois", "IL"],
"60426": ["Harvey", "Illinois", "IL"],
"60428": ["Markham", "Illinois", "IL"],
"60429": ["Hazel Crest", "Illinois", "IL"],
"60430": ["Homewood", "Illinois", "IL"],
"60431": ["Joliet", "Illinois", "IL"],
"60432": ["Joliet", "Illinois", "IL"],
"60433": ["Joliet", "Illinois", "IL"],
"60435": ["Joliet", "Illinois", "IL"],
"60436": ["Joliet", "Illinois", "IL"],
"60437": ["Kinsman", "Illinois", "IL"],
"60438": ["Lansing", "Illinois", "IL"],
"60439": ["Lemont", "Illinois", "IL"],
"60440": ["Bolingbrook", "Illinois", "IL"],
"60441": ["Lockport", "Illinois", "IL"],
"60442": ["Manhattan", "Illinois", "IL"],
"60443": ["Matteson", "Illinois", "IL"],
"60444": ["Mazon", "Illinois", "IL"],
"60445": ["Midlothian", "Illinois", "IL"],
"60446": ["Romeoville", "Illinois", "IL"],
"60447": ["Minooka", "Illinois", "IL"],
"60448": ["Mokena", "Illinois", "IL"],
"60449": ["Monee", "Illinois", "IL"],
"60450": ["Morris", "Illinois", "IL"],
"60451": ["New Lenox", "Illinois", "IL"],
"60452": ["Oak Forest", "Illinois", "IL"],
"60453": ["Oak Lawn", "Illinois", "IL"],
"60455": ["Bridgeview", "Illinois", "IL"],
"60456": ["Hometown", "Illinois", "IL"],
"60457": ["Hickory Hills", "Illinois", "IL"],
"60458": ["Justice", "Illinois", "IL"],
"60459": ["Burbank", "Illinois", "IL"],
"60460": ["Odell", "Illinois", "IL"],
"60461": ["Olympia Fields", "Illinois", "IL"],
"60462": ["Orland Park", "Illinois", "IL"],
"60463": ["Palos Heights", "Illinois", "IL"],
"60464": ["Palos Park", "Illinois", "IL"],
"60465": ["Palos Hills", "Illinois", "IL"],
"60466": ["Park Forest", "Illinois", "IL"],
"60467": ["Orland Park", "Illinois", "IL"],
"60468": ["Peotone", "Illinois", "IL"],
"60469": ["Posen", "Illinois", "IL"],
"60470": ["Ransom", "Illinois", "IL"],
"60471": ["Richton Park", "Illinois", "IL"],
"60472": ["Robbins", "Illinois", "IL"],
"60473": ["South Holland", "Illinois", "IL"],
"60474": ["South Wilmington", "Illinois", "IL"],
"60475": ["Steger", "Illinois", "IL"],
"60476": ["Thornton", "Illinois", "IL"],
"60477": ["Tinley Park", "Illinois", "IL"],
"60478": ["Country Club Hills", "Illinois", "IL"],
"60479": ["Verona", "Illinois", "IL"],
"60480": ["Willow Springs", "Illinois", "IL"],
"60481": ["Wilmington", "Illinois", "IL"],
"60482": ["Worth", "Illinois", "IL"],
"60484": ["University Park", "Illinois", "IL"],
"60487": ["Tinley Park", "Illinois", "IL"],
"60490": ["Bolingbrook", "Illinois", "IL"],
"60491": ["Homer Glen", "Illinois", "IL"],
"60501": ["Summit Argo", "Illinois", "IL"],
"60502": ["Aurora", "Illinois", "IL"],
"60503": ["Aurora", "Illinois", "IL"],
"60504": ["Aurora", "Illinois", "IL"],
"60505": ["Aurora", "Illinois", "IL"],
"60506": ["Aurora", "Illinois", "IL"],
"60510": ["Batavia", "Illinois", "IL"],
"60511": ["Big Rock", "Illinois", "IL"],
"60512": ["Bristol", "Illinois", "IL"],
"60513": ["Brookfield", "Illinois", "IL"],
"60514": ["Clarendon Hills", "Illinois", "IL"],
"60515": ["Downers Grove", "Illinois", "IL"],
"60516": ["Downers Grove", "Illinois", "IL"],
"60517": ["Woodridge", "Illinois", "IL"],
"60518": ["Earlville", "Illinois", "IL"],
"60519": ["Eola", "Illinois", "IL"],
"60520": ["Hinckley", "Illinois", "IL"],
"60521": ["Hinsdale", "Illinois", "IL"],
"60523": ["Oak Brook", "Illinois", "IL"],
"60525": ["La Grange", "Illinois", "IL"],
"60526": ["La Grange Park", "Illinois", "IL"],
"60527": ["Willowbrook", "Illinois", "IL"],
"60530": ["Lee", "Illinois", "IL"],
"60531": ["Leland", "Illinois", "IL"],
"60532": ["Lisle", "Illinois", "IL"],
"60534": ["Lyons", "Illinois", "IL"],
"60536": ["Millbrook", "Illinois", "IL"],
"60537": ["Millington", "Illinois", "IL"],
"60538": ["Montgomery", "Illinois", "IL"],
"60539": ["Mooseheart", "Illinois", "IL"],
"60540": ["Naperville", "Illinois", "IL"],
"60541": ["Newark", "Illinois", "IL"],
"60542": ["North Aurora", "Illinois", "IL"],
"60543": ["Oswego", "Illinois", "IL"],
"60544": ["Plainfield", "Illinois", "IL"],
"60545": ["Plano", "Illinois", "IL"],
"60546": ["Riverside", "Illinois", "IL"],
"60548": ["Sandwich", "Illinois", "IL"],
"60549": ["Serena", "Illinois", "IL"],
"60550": ["Shabbona", "Illinois", "IL"],
"60551": ["Sheridan", "Illinois", "IL"],
"60552": ["Somonauk", "Illinois", "IL"],
"60553": ["Steward", "Illinois", "IL"],
"60554": ["Sugar Grove", "Illinois", "IL"],
"60555": ["Warrenville", "Illinois", "IL"],
"60556": ["Waterman", "Illinois", "IL"],
"60557": ["Wedron", "Illinois", "IL"],
"60558": ["Western Springs", "Illinois", "IL"],
"60559": ["Westmont", "Illinois", "IL"],
"60560": ["Yorkville", "Illinois", "IL"],
"60561": ["Darien", "Illinois", "IL"],
"60563": ["Naperville", "Illinois", "IL"],
"60564": ["Naperville", "Illinois", "IL"],
"60565": ["Naperville", "Illinois", "IL"],
"60585": ["Plainfield", "Illinois", "IL"],
"60586": ["Plainfield", "Illinois", "IL"],
"60601": ["Chicago", "Illinois", "IL"],
"60602": ["Chicago", "Illinois", "IL"],
"60603": ["Chicago", "Illinois", "IL"],
"60604": ["Chicago", "Illinois", "IL"],
"60605": ["Chicago", "Illinois", "IL"],
"60606": ["Chicago", "Illinois", "IL"],
"60607": ["Chicago", "Illinois", "IL"],
"60608": ["Chicago", "Illinois", "IL"],
"60609": ["Chicago", "Illinois", "IL"],
"60610": ["Chicago", "Illinois", "IL"],
"60611": ["Chicago", "Illinois", "IL"],
"60612": ["Chicago", "Illinois", "IL"],
"60613": ["Chicago", "Illinois", "IL"],
"60614": ["Chicago", "Illinois", "IL"],
"60615": ["Chicago", "Illinois", "IL"],
"60616": ["Chicago", "Illinois", "IL"],
"60617": ["Chicago", "Illinois", "IL"],
"60618": ["Chicago", "Illinois", "IL"],
"60619": ["Chicago", "Illinois", "IL"],
"60620": ["Chicago", "Illinois", "IL"],
"60621": ["Chicago", "Illinois", "IL"],
"60622": ["Chicago", "Illinois", "IL"],
"60623": ["Chicago", "Illinois", "IL"],
"60624": ["Chicago", "Illinois", "IL"],
"60625": ["Chicago", "Illinois", "IL"],
"60626": ["Chicago", "Illinois", "IL"],
"60628": ["Chicago", "Illinois", "IL"],
"60629": ["Chicago", "Illinois", "IL"],
"60630": ["Chicago", "Illinois", "IL"],
"60631": ["Chicago", "Illinois", "IL"],
"60632": ["Chicago", "Illinois", "IL"],
"60633": ["Chicago", "Illinois", "IL"],
"60634": ["Chicago", "Illinois", "IL"],
"60636": ["Chicago", "Illinois", "IL"],
"60637": ["Chicago", "Illinois", "IL"],
"60638": ["Chicago", "Illinois", "IL"],
"60639": ["Chicago", "Illinois", "IL"],
"60640": ["Chicago", "Illinois", "IL"],
"60641": ["Chicago", "Illinois", "IL"],
"60642": ["Chicago", "Illinois", "IL"],
"60643": ["Chicago", "Illinois", "IL"],
"60644": ["Chicago", "Illinois", "IL"],
"60645": ["Chicago", "Illinois", "IL"],
"60646": ["Chicago", "Illinois", "IL"],
"60647": ["Chicago", "Illinois", "IL"],
"60649": ["Chicago", "Illinois", "IL"],
"60651": ["Chicago", "Illinois", "IL"],
"60652": ["Chicago", "Illinois", "IL"],
"60653": ["Chicago", "Illinois", "IL"],
"60654": ["Chicago", "Illinois", "IL"],
"60655": ["Chicago", "Illinois", "IL"],
"60656": ["Chicago", "Illinois", "IL"],
"60657": ["Chicago", "Illinois", "IL"],
"60659": ["Chicago", "Illinois", "IL"],
"60660": ["Chicago", "Illinois", "IL"],
"60661": ["Chicago", "Illinois", "IL"],
"60706": ["Harwood Heights", "Illinois", "IL"],
"60707": ["Elmwood Park", "Illinois", "IL"],
"60712": ["Lincolnwood", "Illinois", "IL"],
"60714": ["Niles", "Illinois", "IL"],
"60803": ["Alsip", "Illinois", "IL"],
"60804": ["Cicero", "Illinois", "IL"],
"60805": ["Evergreen Park", "Illinois", "IL"],
"60827": ["Riverdale", "Illinois", "IL"],
"60901": ["Kankakee", "Illinois", "IL"],
"60910": ["Aroma Park", "Illinois", "IL"],
"60911": ["Ashkum", "Illinois", "IL"],
"60912": ["Beaverville", "Illinois", "IL"],
"60913": ["Bonfield", "Illinois", "IL"],
"60914": ["Bourbonnais", "Illinois", "IL"],
"60915": ["Bradley", "Illinois", "IL"],
"60917": ["Buckingham", "Illinois", "IL"],
"60918": ["Buckley", "Illinois", "IL"],
"60919": ["Cabery", "Illinois", "IL"],
"60920": ["Campus", "Illinois", "IL"],
"60921": ["Chatsworth", "Illinois", "IL"],
"60922": ["Chebanse", "Illinois", "IL"],
"60924": ["Cissna Park", "Illinois", "IL"],
"60926": ["Claytonville", "Illinois", "IL"],
"60927": ["Clifton", "Illinois", "IL"],
"60928": ["Crescent City", "Illinois", "IL"],
"60929": ["Cullom", "Illinois", "IL"],
"60930": ["Danforth", "Illinois", "IL"],
"60931": ["Donovan", "Illinois", "IL"],
"60932": ["East Lynn", "Illinois", "IL"],
"60933": ["Elliott", "Illinois", "IL"],
"60934": ["Emington", "Illinois", "IL"],
"60935": ["Essex", "Illinois", "IL"],
"60936": ["Gibson City", "Illinois", "IL"],
"60938": ["Gilman", "Illinois", "IL"],
"60939": ["Goodwine", "Illinois", "IL"],
"60940": ["Grant Park", "Illinois", "IL"],
"60941": ["Herscher", "Illinois", "IL"],
"60942": ["Hoopeston", "Illinois", "IL"],
"60945": ["Iroquois", "Illinois", "IL"],
"60946": ["Kempton", "Illinois", "IL"],
"60948": ["Loda", "Illinois", "IL"],
"60949": ["Ludlow", "Illinois", "IL"],
"60950": ["Manteno", "Illinois", "IL"],
"60951": ["Martinton", "Illinois", "IL"],
"60952": ["Melvin", "Illinois", "IL"],
"60953": ["Milford", "Illinois", "IL"],
"60954": ["Momence", "Illinois", "IL"],
"60955": ["Onarga", "Illinois", "IL"],
"60956": ["Papineau", "Illinois", "IL"],
"60957": ["Paxton", "Illinois", "IL"],
"60958": ["Pembroke Township", "Illinois", "IL"],
"60959": ["Piper City", "Illinois", "IL"],
"60960": ["Rankin", "Illinois", "IL"],
"60961": ["Reddick", "Illinois", "IL"],
"60962": ["Roberts", "Illinois", "IL"],
"60963": ["Rossville", "Illinois", "IL"],
"60964": ["Saint Anne", "Illinois", "IL"],
"60966": ["Sheldon", "Illinois", "IL"],
"60967": ["Stockland", "Illinois", "IL"],
"60968": ["Thawville", "Illinois", "IL"],
"60969": ["Union Hill", "Illinois", "IL"],
"60970": ["Watseka", "Illinois", "IL"],
"60973": ["Wellington", "Illinois", "IL"],
"60974": ["Woodland", "Illinois", "IL"],
"61001": ["Apple River", "Illinois", "IL"],
"61006": ["Ashton", "Illinois", "IL"],
"61007": ["Baileyville", "Illinois", "IL"],
"61008": ["Belvidere", "Illinois", "IL"],
"61010": ["Byron", "Illinois", "IL"],
"61011": ["Caledonia", "Illinois", "IL"],
"61012": ["Capron", "Illinois", "IL"],
"61013": ["Cedarville", "Illinois", "IL"],
"61014": ["Chadwick", "Illinois", "IL"],
"61015": ["Chana", "Illinois", "IL"],
"61016": ["Cherry Valley", "Illinois", "IL"],
"61018": ["Dakota", "Illinois", "IL"],
"61019": ["Davis", "Illinois", "IL"],
"61020": ["Davis Junction", "Illinois", "IL"],
"61021": ["Dixon", "Illinois", "IL"],
"61024": ["Durand", "Illinois", "IL"],
"61025": ["East Dubuque", "Illinois", "IL"],
"61027": ["Eleroy", "Illinois", "IL"],
"61028": ["Elizabeth", "Illinois", "IL"],
"61030": ["Forreston", "Illinois", "IL"],
"61031": ["Franklin Grove", "Illinois", "IL"],
"61032": ["Freeport", "Illinois", "IL"],
"61036": ["Galena", "Illinois", "IL"],
"61037": ["Galt", "Illinois", "IL"],
"61038": ["Garden Prairie", "Illinois", "IL"],
"61039": ["German Valley", "Illinois", "IL"],
"61041": ["Hanover", "Illinois", "IL"],
"61042": ["Harmon", "Illinois", "IL"],
"61043": ["Holcomb", "Illinois", "IL"],
"61044": ["Kent", "Illinois", "IL"],
"61046": ["Lanark", "Illinois", "IL"],
"61047": ["Leaf River", "Illinois", "IL"],
"61048": ["Lena", "Illinois", "IL"],
"61049": ["Lindenwood", "Illinois", "IL"],
"61050": ["McConnell", "Illinois", "IL"],
"61051": ["Milledgeville", "Illinois", "IL"],
"61052": ["Monroe Center", "Illinois", "IL"],
"61053": ["Mount Carroll", "Illinois", "IL"],
"61054": ["Mount Morris", "Illinois", "IL"],
"61057": ["Nachusa", "Illinois", "IL"],
"61059": ["Nora", "Illinois", "IL"],
"61060": ["Orangeville", "Illinois", "IL"],
"61061": ["Oregon", "Illinois", "IL"],
"61062": ["Pearl City", "Illinois", "IL"],
"61063": ["Pecatonica", "Illinois", "IL"],
"61064": ["Polo", "Illinois", "IL"],
"61065": ["Poplar Grove", "Illinois", "IL"],
"61067": ["Ridott", "Illinois", "IL"],
"61068": ["Rochelle", "Illinois", "IL"],
"61070": ["Rock City", "Illinois", "IL"],
"61071": ["Rock Falls", "Illinois", "IL"],
"61072": ["Rockton", "Illinois", "IL"],
"61073": ["Roscoe", "Illinois", "IL"],
"61074": ["Savanna", "Illinois", "IL"],
"61075": ["Scales Mound", "Illinois", "IL"],
"61077": ["Seward", "Illinois", "IL"],
"61078": ["Shannon", "Illinois", "IL"],
"61079": ["Shirland", "Illinois", "IL"],
"61080": ["South Beloit", "Illinois", "IL"],
"61081": ["Sterling", "Illinois", "IL"],
"61084": ["Stillman Valley", "Illinois", "IL"],
"61085": ["Stockton", "Illinois", "IL"],
"61087": ["Warren", "Illinois", "IL"],
"61088": ["Winnebago", "Illinois", "IL"],
"61089": ["Winslow", "Illinois", "IL"],
"61091": ["Woosung", "Illinois", "IL"],
"61101": ["Rockford", "Illinois", "IL"],
"61102": ["Rockford", "Illinois", "IL"],
"61103": ["Rockford", "Illinois", "IL"],
"61104": ["Rockford", "Illinois", "IL"],
"61107": ["Rockford", "Illinois", "IL"],
"61108": ["Rockford", "Illinois", "IL"],
"61109": ["Rockford", "Illinois", "IL"],
"61111": ["Loves Park", "Illinois", "IL"],
"61112": ["Rockford", "Illinois", "IL"],
"61114": ["Rockford", "Illinois", "IL"],
"61115": ["Machesney Park", "Illinois", "IL"],
"61201": ["Rock Island", "Illinois", "IL"],
"61230": ["Albany", "Illinois", "IL"],
"61231": ["Aledo", "Illinois", "IL"],
"61232": ["Andalusia", "Illinois", "IL"],
"61233": ["Andover", "Illinois", "IL"],
"61234": ["Annawan", "Illinois", "IL"],
"61235": ["Atkinson", "Illinois", "IL"],
"61236": ["Barstow", "Illinois", "IL"],
"61237": ["Buffalo Prairie", "Illinois", "IL"],
"61238": ["Cambridge", "Illinois", "IL"],
"61239": ["Carbon Cliff", "Illinois", "IL"],
"61240": ["Coal Valley", "Illinois", "IL"],
"61241": ["Colona", "Illinois", "IL"],
"61242": ["Cordova", "Illinois", "IL"],
"61243": ["Deer Grove", "Illinois", "IL"],
"61244": ["East Moline", "Illinois", "IL"],
"61250": ["Erie", "Illinois", "IL"],
"61251": ["Fenton", "Illinois", "IL"],
"61252": ["Fulton", "Illinois", "IL"],
"61254": ["Geneseo", "Illinois", "IL"],
"61256": ["Hampton", "Illinois", "IL"],
"61257": ["Hillsdale", "Illinois", "IL"],
"61258": ["Hooppole", "Illinois", "IL"],
"61259": ["Illinois City", "Illinois", "IL"],
"61260": ["Joy", "Illinois", "IL"],
"61261": ["Lyndon", "Illinois", "IL"],
"61262": ["Lynn Center", "Illinois", "IL"],
"61263": ["Matherville", "Illinois", "IL"],
"61264": ["Milan", "Illinois", "IL"],
"61265": ["Moline", "Illinois", "IL"],
"61270": ["Morrison", "Illinois", "IL"],
"61272": ["New Boston", "Illinois", "IL"],
"61273": ["Orion", "Illinois", "IL"],
"61274": ["Osco", "Illinois", "IL"],
"61275": ["Port Byron", "Illinois", "IL"],
"61276": ["Preemption", "Illinois", "IL"],
"61277": ["Prophetstown", "Illinois", "IL"],
"61278": ["Rapids City", "Illinois", "IL"],
"61279": ["Reynolds", "Illinois", "IL"],
"61281": ["Sherrard", "Illinois", "IL"],
"61282": ["Silvis", "Illinois", "IL"],
"61283": ["Tampico", "Illinois", "IL"],
"61284": ["Taylor Ridge", "Illinois", "IL"],
"61285": ["Thomson", "Illinois", "IL"],
"61301": ["La Salle", "Illinois", "IL"],
"61310": ["Amboy", "Illinois", "IL"],
"61311": ["Ancona", "Illinois", "IL"],
"61312": ["Arlington", "Illinois", "IL"],
"61313": ["Blackstone", "Illinois", "IL"],
"61314": ["Buda", "Illinois", "IL"],
"61315": ["Bureau", "Illinois", "IL"],
"61316": ["Cedar Point", "Illinois", "IL"],
"61317": ["Cherry", "Illinois", "IL"],
"61318": ["Compton", "Illinois", "IL"],
"61319": ["Cornell", "Illinois", "IL"],
"61320": ["Dalzell", "Illinois", "IL"],
"61321": ["Dana", "Illinois", "IL"],
"61322": ["Depue", "Illinois", "IL"],
"61323": ["Dover", "Illinois", "IL"],
"61324": ["Eldena", "Illinois", "IL"],
"61325": ["Grand Ridge", "Illinois", "IL"],
"61326": ["Granville", "Illinois", "IL"],
"61327": ["Hennepin", "Illinois", "IL"],
"61328": ["Kasbeer", "Illinois", "IL"],
"61329": ["Ladd", "Illinois", "IL"],
"61330": ["La Moille", "Illinois", "IL"],
"61331": ["Lee Center", "Illinois", "IL"],
"61332": ["Leonore", "Illinois", "IL"],
"61333": ["Long Point", "Illinois", "IL"],
"61334": ["Lostant", "Illinois", "IL"],
"61335": ["McNabb", "Illinois", "IL"],
"61336": ["Magnolia", "Illinois", "IL"],
"61337": ["Malden", "Illinois", "IL"],
"61338": ["Manlius", "Illinois", "IL"],
"61340": ["Mark", "Illinois", "IL"],
"61341": ["Marseilles", "Illinois", "IL"],
"61342": ["Mendota", "Illinois", "IL"],
"61344": ["Mineral", "Illinois", "IL"],
"61345": ["Neponset", "Illinois", "IL"],
"61346": ["New Bedford", "Illinois", "IL"],
"61348": ["Oglesby", "Illinois", "IL"],
"61349": ["Ohio", "Illinois", "IL"],
"61350": ["Ottawa", "Illinois", "IL"],
"61353": ["Paw Paw", "Illinois", "IL"],
"61354": ["Peru", "Illinois", "IL"],
"61356": ["Princeton", "Illinois", "IL"],
"61358": ["Rutland", "Illinois", "IL"],
"61359": ["Seatonville", "Illinois", "IL"],
"61360": ["Seneca", "Illinois", "IL"],
"61361": ["Sheffield", "Illinois", "IL"],
"61362": ["Spring Valley", "Illinois", "IL"],
"61363": ["Standard", "Illinois", "IL"],
"61364": ["Streator", "Illinois", "IL"],
"61367": ["Sublette", "Illinois", "IL"],
"61368": ["Tiskilwa", "Illinois", "IL"],
"61369": ["Toluca", "Illinois", "IL"],
"61370": ["Tonica", "Illinois", "IL"],
"61371": ["Triumph", "Illinois", "IL"],
"61372": ["Troy Grove", "Illinois", "IL"],
"61373": ["Utica", "Illinois", "IL"],
"61374": ["Van Orin", "Illinois", "IL"],
"61375": ["Varna", "Illinois", "IL"],
"61376": ["Walnut", "Illinois", "IL"],
"61377": ["Wenona", "Illinois", "IL"],
"61378": ["West Brooklyn", "Illinois", "IL"],
"61379": ["Wyanet", "Illinois", "IL"],
"61401": ["Galesburg", "Illinois", "IL"],
"61402": ["Galesburg", "Illinois", "IL"],
"61410": ["Abingdon", "Illinois", "IL"],
"61411": ["Adair", "Illinois", "IL"],
"61412": ["Alexis", "Illinois", "IL"],
"61413": ["Alpha", "Illinois", "IL"],
"61414": ["Altona", "Illinois", "IL"],
"61415": ["Avon", "Illinois", "IL"],
"61416": ["Bardolph", "Illinois", "IL"],
"61417": ["Berwick", "Illinois", "IL"],
"61418": ["Biggsville", "Illinois", "IL"],
"61419": ["Bishop Hill", "Illinois", "IL"],
"61420": ["Blandinsville", "Illinois", "IL"],
"61421": ["Bradford", "Illinois", "IL"],
"61422": ["Bushnell", "Illinois", "IL"],
"61423": ["Cameron", "Illinois", "IL"],
"61424": ["Camp Grove", "Illinois", "IL"],
"61425": ["Carman", "Illinois", "IL"],
"61426": ["Castleton", "Illinois", "IL"],
"61427": ["Cuba", "Illinois", "IL"],
"61428": ["Dahinda", "Illinois", "IL"],
"61430": ["East Galesburg", "Illinois", "IL"],
"61431": ["Ellisville", "Illinois", "IL"],
"61432": ["Fairview", "Illinois", "IL"],
"61433": ["Fiatt", "Illinois", "IL"],
"61434": ["Galva", "Illinois", "IL"],
"61435": ["Gerlaw", "Illinois", "IL"],
"61436": ["Gilson", "Illinois", "IL"],
"61437": ["Gladstone", "Illinois", "IL"],
"61438": ["Good Hope", "Illinois", "IL"],
"61439": ["Henderson", "Illinois", "IL"],
"61440": ["Industry", "Illinois", "IL"],
"61441": ["Ipava", "Illinois", "IL"],
"61442": ["Keithsburg", "Illinois", "IL"],
"61443": ["Kewanee", "Illinois", "IL"],
"61447": ["Kirkwood", "Illinois", "IL"],
"61448": ["Knoxville", "Illinois", "IL"],
"61449": ["La Fayette", "Illinois", "IL"],
"61450": ["La Harpe", "Illinois", "IL"],
"61451": ["Laura", "Illinois", "IL"],
"61452": ["Littleton", "Illinois", "IL"],
"61453": ["Little York", "Illinois", "IL"],
"61454": ["Lomax", "Illinois", "IL"],
"61455": ["Macomb", "Illinois", "IL"],
"61458": ["Maquon", "Illinois", "IL"],
"61459": ["Marietta", "Illinois", "IL"],
"61460": ["Media", "Illinois", "IL"],
"61462": ["Monmouth", "Illinois", "IL"],
"61465": ["New Windsor", "Illinois", "IL"],
"61466": ["North Henderson", "Illinois", "IL"],
"61467": ["Oneida", "Illinois", "IL"],
"61468": ["Ophiem", "Illinois", "IL"],
"61469": ["Oquawka", "Illinois", "IL"],
"61470": ["Prairie City", "Illinois", "IL"],
"61471": ["Raritan", "Illinois", "IL"],
"61472": ["Rio", "Illinois", "IL"],
"61473": ["Roseville", "Illinois", "IL"],
"61474": ["Saint Augustine", "Illinois", "IL"],
"61475": ["Sciota", "Illinois", "IL"],
"61476": ["Seaton", "Illinois", "IL"],
"61477": ["Smithfield", "Illinois", "IL"],
"61478": ["Smithshire", "Illinois", "IL"],
"61479": ["Speer", "Illinois", "IL"],
"61480": ["Stronghurst", "Illinois", "IL"],
"61482": ["Table Grove", "Illinois", "IL"],
"61483": ["Toulon", "Illinois", "IL"],
"61484": ["Vermont", "VT", "Illinois", "IL"],
"61485": ["Victoria", "Illinois", "IL"],
"61486": ["Viola", "Illinois", "IL"],
"61488": ["Wataga", "Illinois", "IL"],
"61489": ["Williamsfield", "Illinois", "IL"],
"61490": ["Woodhull", "Illinois", "IL"],
"61491": ["Wyoming", "Illinois", "IL"],
"61501": ["Astoria", "Illinois", "IL"],
"61516": ["Benson", "Illinois", "IL"],
"61517": ["Brimfield", "Illinois", "IL"],
"61519": ["Bryant", "Illinois", "IL"],
"61520": ["Canton", "Illinois", "IL"],
"61523": ["Chillicothe", "Illinois", "IL"],
"61524": ["Dunfermline", "Illinois", "IL"],
"61525": ["Dunlap", "Illinois", "IL"],
"61526": ["Edelstein", "Illinois", "IL"],
"61528": ["Edwards", "Illinois", "IL"],
"61529": ["Elmwood", "Illinois", "IL"],
"61530": ["Eureka", "Illinois", "IL"],
"61531": ["Farmington", "Illinois", "IL"],
"61532": ["Forest City", "Illinois", "IL"],
"61533": ["Glasford", "Illinois", "IL"],
"61534": ["Green Valley", "Illinois", "IL"],
"61535": ["Groveland", "Illinois", "IL"],
"61536": ["Hanna City", "Illinois", "IL"],
"61537": ["Henry", "Illinois", "IL"],
"61539": ["Kingston Mines", "Illinois", "IL"],
"61540": ["Lacon", "Illinois", "IL"],
"61541": ["La Rose", "Illinois", "IL"],
"61542": ["Lewistown", "Illinois", "IL"],
"61543": ["Liverpool", "Illinois", "IL"],
"61544": ["London Mills", "Illinois", "IL"],
"61545": ["Lowpoint", "Illinois", "IL"],
"61546": ["Manito", "Illinois", "IL"],
"61547": ["Mapleton", "Illinois", "IL"],
"61548": ["Metamora", "Illinois", "IL"],
"61550": ["Morton", "Illinois", "IL"],
"61552": ["Mossville", "Illinois", "IL"],
"61553": ["Norris", "Illinois", "IL"],
"61554": ["Pekin", "Illinois", "IL"],
"61559": ["Princeville", "Illinois", "IL"],
"61560": ["Putnam", "Illinois", "IL"],
"61561": ["Roanoke", "Illinois", "IL"],
"61562": ["Rome", "Illinois", "IL"],
"61563": ["Saint David", "Illinois", "IL"],
"61564": ["South Pekin", "Illinois", "IL"],
"61565": ["Sparland", "Illinois", "IL"],
"61567": ["Topeka", "Illinois", "IL"],
"61568": ["Tremont", "Illinois", "IL"],
"61569": ["Trivoli", "Illinois", "IL"],
"61570": ["Washburn", "Illinois", "IL"],
"61571": ["Washington", "Illinois", "IL"],
"61572": ["Yates City", "Illinois", "IL"],
"61602": ["Peoria", "Illinois", "IL"],
"61603": ["Peoria", "Illinois", "IL"],
"61604": ["Peoria", "Illinois", "IL"],
"61605": ["Peoria", "Illinois", "IL"],
"61606": ["Peoria", "Illinois", "IL"],
"61607": ["Peoria", "Illinois", "IL"],
"61610": ["Creve Coeur", "Illinois", "IL"],
"61611": ["East Peoria", "Illinois", "IL"],
"61614": ["Peoria", "Illinois", "IL"],
"61615": ["Peoria", "Illinois", "IL"],
"61616": ["Peoria Heights", "Illinois", "IL"],
"61625": ["Peoria", "Illinois", "IL"],
"61701": ["Bloomington", "Illinois", "IL"],
"61704": ["Bloomington", "Illinois", "IL"],
"61705": ["Bloomington", "Illinois", "IL"],
"61720": ["Anchor", "Illinois", "IL"],
"61721": ["Armington", "Illinois", "IL"],
"61722": ["Arrowsmith", "Illinois", "IL"],
"61723": ["Atlanta", "Illinois", "IL"],
"61724": ["Bellflower", "Illinois", "IL"],
"61725": ["Carlock", "Illinois", "IL"],
"61726": ["Chenoa", "Illinois", "IL"],
"61727": ["Clinton", "Illinois", "IL"],
"61728": ["Colfax", "Illinois", "IL"],
"61729": ["Congerville", "Illinois", "IL"],
"61730": ["Cooksville", "Illinois", "IL"],
"61731": ["Cropsey", "Illinois", "IL"],
"61732": ["Danvers", "Illinois", "IL"],
"61733": ["Deer Creek", "Illinois", "IL"],
"61734": ["Delavan", "Illinois", "IL"],
"61735": ["Dewitt", "Illinois", "IL"],
"61736": ["Downs", "Illinois", "IL"],
"61737": ["Ellsworth", "Illinois", "IL"],
"61738": ["El Paso", "Illinois", "IL"],
"61739": ["Fairbury", "Illinois", "IL"],
"61740": ["Flanagan", "Illinois", "IL"],
"61741": ["Forrest", "Illinois", "IL"],
"61742": ["Goodfield", "Illinois", "IL"],
"61743": ["Graymont", "Illinois", "IL"],
"61744": ["Gridley", "Illinois", "IL"],
"61745": ["Heyworth", "Illinois", "IL"],
"61747": ["Hopedale", "Illinois", "IL"],
"61748": ["Hudson", "Illinois", "IL"],
"61749": ["Kenney", "Illinois", "IL"],
"61750": ["Lane", "Illinois", "IL"],
"61751": ["Lawndale", "Illinois", "IL"],
"61752": ["Le Roy", "Illinois", "IL"],
"61753": ["Lexington", "Illinois", "IL"],
"61754": ["McLean", "Illinois", "IL"],
"61755": ["Mackinaw", "Illinois", "IL"],
"61756": ["Maroa", "Illinois", "IL"],
"61759": ["Minier", "Illinois", "IL"],
"61760": ["Minonk", "Illinois", "IL"],
"61761": ["Normal", "Illinois", "IL"],
"61764": ["Pontiac", "Illinois", "IL"],
"61769": ["Saunemin", "Illinois", "IL"],
"61770": ["Saybrook", "Illinois", "IL"],
"61771": ["Secor", "Illinois", "IL"],
"61772": ["Shirley", "Illinois", "IL"],
"61773": ["Sibley", "Illinois", "IL"],
"61774": ["Stanford", "Illinois", "IL"],
"61775": ["Strawn", "Illinois", "IL"],
"61776": ["Towanda", "Illinois", "IL"],
"61777": ["Wapella", "Illinois", "IL"],
"61778": ["Waynesville", "Illinois", "IL"],
"61801": ["Urbana", "Illinois", "IL"],
"61802": ["Urbana", "Illinois", "IL"],
"61810": ["Allerton", "Illinois", "IL"],
"61811": ["Alvin", "Illinois", "IL"],
"61812": ["Armstrong", "Illinois", "IL"],
"61813": ["Bement", "Illinois", "IL"],
"61814": ["Bismarck", "Illinois", "IL"],
"61815": ["Bondville", "Illinois", "IL"],
"61816": ["Broadlands", "Illinois", "IL"],
"61817": ["Catlin", "Illinois", "IL"],
"61818": ["Cerro Gordo", "Illinois", "IL"],
"61820": ["Champaign", "Illinois", "IL"],
"61821": ["Champaign", "Illinois", "IL"],
"61822": ["Champaign", "Illinois", "IL"],
"61830": ["Cisco", "Illinois", "IL"],
"61831": ["Collison", "Illinois", "IL"],
"61832": ["Danville", "Illinois", "IL"],
"61833": ["Tilton", "Illinois", "IL"],
"61834": ["Danville", "Illinois", "IL"],
"61839": ["De Land", "Illinois", "IL"],
"61840": ["Dewey", "Illinois", "IL"],
"61841": ["Fairmount", "Illinois", "IL"],
"61842": ["Farmer City", "Illinois", "IL"],
"61843": ["Fisher", "Illinois", "IL"],
"61844": ["Fithian", "Illinois", "IL"],
"61845": ["Foosland", "Illinois", "IL"],
"61846": ["Georgetown", "Illinois", "IL"],
"61847": ["Gifford", "Illinois", "IL"],
"61848": ["Henning", "Illinois", "IL"],
"61849": ["Homer", "Illinois", "IL"],
"61850": ["Indianola", "Illinois", "IL"],
"61851": ["Ivesdale", "Illinois", "IL"],
"61852": ["Longview", "Illinois", "IL"],
"61853": ["Mahomet", "Illinois", "IL"],
"61854": ["Mansfield", "Illinois", "IL"],
"61855": ["Milmine", "Illinois", "IL"],
"61856": ["Monticello", "Illinois", "IL"],
"61857": ["Muncie", "Illinois", "IL"],
"61858": ["Oakwood", "Illinois", "IL"],
"61859": ["Ogden", "Illinois", "IL"],
"61862": ["Penfield", "Illinois", "IL"],
"61863": ["Pesotum", "Illinois", "IL"],
"61864": ["Philo", "Illinois", "IL"],
"61865": ["Potomac", "Illinois", "IL"],
"61866": ["Rantoul", "Illinois", "IL"],
"61870": ["Ridge Farm", "Illinois", "IL"],
"61871": ["Royal", "Illinois", "IL"],
"61872": ["Sadorus", "Illinois", "IL"],
"61873": ["Saint Joseph", "Illinois", "IL"],
"61874": ["Savoy", "Illinois", "IL"],
"61875": ["Seymour", "Illinois", "IL"],
"61876": ["Sidell", "Illinois", "IL"],
"61877": ["Sidney", "Illinois", "IL"],
"61878": ["Thomasboro", "Illinois", "IL"],
"61880": ["Tolono", "Illinois", "IL"],
"61882": ["Weldon", "Illinois", "IL"],
"61883": ["Westville", "Illinois", "IL"],
"61884": ["White Heath", "Illinois", "IL"],
"61910": ["Arcola", "Illinois", "IL"],
"61911": ["Arthur", "Illinois", "IL"],
"61912": ["Ashmore", "Illinois", "IL"],
"61913": ["Atwood", "Illinois", "IL"],
"61914": ["Bethany", "Illinois", "IL"],
"61917": ["Brocton", "Illinois", "IL"],
"61919": ["Camargo", "Illinois", "IL"],
"61920": ["Charleston", "Illinois", "IL"],
"61924": ["Chrisman", "Illinois", "IL"],
"61925": ["Dalton City", "Illinois", "IL"],
"61928": ["Gays", "Illinois", "IL"],
"61929": ["Hammond", "Illinois", "IL"],
"61930": ["Hindsboro", "Illinois", "IL"],
"61931": ["Humboldt", "Illinois", "IL"],
"61932": ["Hume", "Illinois", "IL"],
"61933": ["Kansas", "Illinois", "IL"],
"61936": ["La Place", "Illinois", "IL"],
"61937": ["Lovington", "Illinois", "IL"],
"61938": ["Mattoon", "Illinois", "IL"],
"61940": ["Metcalf", "Illinois", "IL"],
"61941": ["Murdock", "Illinois", "IL"],
"61942": ["Newman", "Illinois", "IL"],
"61943": ["Oakland", "Illinois", "IL"],
"61944": ["Paris", "Illinois", "IL"],
"61949": ["Redmon", "Illinois", "IL"],
"61951": ["Sullivan", "Illinois", "IL"],
"61953": ["Tuscola", "Illinois", "IL"],
"61955": ["Vermilion", "Illinois", "IL"],
"61956": ["Villa Grove", "Illinois", "IL"],
"61957": ["Windsor", "Illinois", "IL"],
"62001": ["Alhambra", "Illinois", "IL"],
"62002": ["Alton", "Illinois", "IL"],
"62006": ["Batchtown", "Illinois", "IL"],
"62009": ["Benld", "Illinois", "IL"],
"62010": ["Bethalto", "Illinois", "IL"],
"62011": ["Bingham", "Illinois", "IL"],
"62012": ["Brighton", "Illinois", "IL"],
"62013": ["Brussels", "Illinois", "IL"],
"62014": ["Bunker Hill", "Illinois", "IL"],
"62015": ["Butler", "Illinois", "IL"],
"62016": ["Carrollton", "Illinois", "IL"],
"62017": ["Coffeen", "Illinois", "IL"],
"62018": ["Cottage Hills", "Illinois", "IL"],
"62019": ["Donnellson", "Illinois", "IL"],
"62021": ["Dorsey", "Illinois", "IL"],
"62022": ["Dow", "Illinois", "IL"],
"62023": ["Eagarville", "Illinois", "IL"],
"62024": ["East Alton", "Illinois", "IL"],
"62025": ["Edwardsville", "Illinois", "IL"],
"62026": ["Edwardsville", "Illinois", "IL"],
"62027": ["Eldred", "Illinois", "IL"],
"62028": ["Elsah", "Illinois", "IL"],
"62030": ["Fidelity", "Illinois", "IL"],
"62031": ["Fieldon", "Illinois", "IL"],
"62032": ["Fillmore", "Illinois", "IL"],
"62033": ["Gillespie", "Illinois", "IL"],
"62034": ["Glen Carbon", "Illinois", "IL"],
"62035": ["Godfrey", "Illinois", "IL"],
"62036": ["Golden Eagle", "Illinois", "IL"],
"62037": ["Grafton", "Illinois", "IL"],
"62040": ["Granite City", "Illinois", "IL"],
"62044": ["Greenfield", "Illinois", "IL"],
"62045": ["Hamburg", "Illinois", "IL"],
"62046": ["Hamel", "Illinois", "IL"],
"62047": ["Hardin", "Illinois", "IL"],
"62048": ["Hartford", "Illinois", "IL"],
"62049": ["Hillsboro", "Illinois", "IL"],
"62050": ["Hillview", "Illinois", "IL"],
"62051": ["Irving", "Illinois", "IL"],
"62052": ["Jerseyville", "Illinois", "IL"],
"62053": ["Kampsville", "Illinois", "IL"],
"62054": ["Kane", "Illinois", "IL"],
"62056": ["Litchfield", "Illinois", "IL"],
"62058": ["Livingston", "Illinois", "IL"],
"62059": ["Lovejoy", "Illinois", "IL"],
"62060": ["Madison", "Illinois", "IL"],
"62061": ["Marine", "Illinois", "IL"],
"62062": ["Maryville", "Illinois", "IL"],
"62063": ["Medora", "Illinois", "IL"],
"62067": ["Moro", "Illinois", "IL"],
"62069": ["Mount Olive", "Illinois", "IL"],
"62074": ["New Douglas", "Illinois", "IL"],
"62075": ["Nokomis", "Illinois", "IL"],
"62076": ["Ohlman", "Illinois", "IL"],
"62077": ["Panama", "Illinois", "IL"],
"62078": ["Patterson", "Illinois", "IL"],
"62079": ["Piasa", "Illinois", "IL"],
"62080": ["Ramsey", "Illinois", "IL"],
"62081": ["Rockbridge", "Illinois", "IL"],
"62082": ["Roodhouse", "Illinois", "IL"],
"62083": ["Rosamond", "Illinois", "IL"],
"62084": ["Roxana", "Illinois", "IL"],
"62085": ["Sawyerville", "Illinois", "IL"],
"62086": ["Sorento", "Illinois", "IL"],
"62087": ["South Roxana", "Illinois", "IL"],
"62088": ["Staunton", "Illinois", "IL"],
"62089": ["Taylor Springs", "Illinois", "IL"],
"62090": ["Venice", "Illinois", "IL"],
"62091": ["Walshville", "Illinois", "IL"],
"62092": ["White Hall", "Illinois", "IL"],
"62093": ["Wilsonville", "Illinois", "IL"],
"62094": ["Witt", "Illinois", "IL"],
"62095": ["Wood River", "Illinois", "IL"],
"62097": ["Worden", "Illinois", "IL"],
"62098": ["Wrights", "Illinois", "IL"],
"62201": ["East Saint Louis", "Illinois", "IL"],
"62203": ["East Saint Louis", "Illinois", "IL"],
"62204": ["East Saint Louis", "Illinois", "IL"],
"62205": ["East Saint Louis", "Illinois", "IL"],
"62206": ["East Saint Louis", "Illinois", "IL"],
"62207": ["East Saint Louis", "Illinois", "IL"],
"62208": ["Fairview Heights", "Illinois", "IL"],
"62214": ["Addieville", "Illinois", "IL"],
"62215": ["Albers", "Illinois", "IL"],
"62216": ["Aviston", "Illinois", "IL"],
"62217": ["Baldwin", "Illinois", "IL"],
"62218": ["Bartelso", "Illinois", "IL"],
"62219": ["Beckemeyer", "Illinois", "IL"],
"62220": ["Belleville", "Illinois", "IL"],
"62221": ["Belleville", "Illinois", "IL"],
"62223": ["Belleville", "Illinois", "IL"],
"62225": ["Scott Air Force Base", "Illinois", "IL"],
"62226": ["Belleville", "Illinois", "IL"],
"62230": ["Breese", "Illinois", "IL"],
"62231": ["Carlyle", "Illinois", "IL"],
"62232": ["Caseyville", "Illinois", "IL"],
"62233": ["Chester", "Illinois", "IL"],
"62234": ["Collinsville", "Illinois", "IL"],
"62236": ["Columbia", "Illinois", "IL"],
"62237": ["Coulterville", "Illinois", "IL"],
"62238": ["Cutler", "Illinois", "IL"],
"62239": ["Dupo", "Illinois", "IL"],
"62240": ["East Carondelet", "Illinois", "IL"],
"62241": ["Ellis Grove", "Illinois", "IL"],
"62242": ["Evansville", "Illinois", "IL"],
"62243": ["Freeburg", "Illinois", "IL"],
"62244": ["Fults", "Illinois", "IL"],
"62245": ["Germantown", "Illinois", "IL"],
"62246": ["Greenville", "Illinois", "IL"],
"62248": ["Hecker", "Illinois", "IL"],
"62249": ["Highland", "Illinois", "IL"],
"62250": ["Hoffman", "Illinois", "IL"],
"62253": ["Keyesport", "Illinois", "IL"],
"62254": ["Lebanon", "Illinois", "IL"],
"62255": ["Lenzburg", "Illinois", "IL"],
"62256": ["Maeystown", "Illinois", "IL"],
"62257": ["Marissa", "Illinois", "IL"],
"62258": ["Mascoutah", "Illinois", "IL"],
"62260": ["Millstadt", "Illinois", "IL"],
"62261": ["Modoc", "Illinois", "IL"],
"62262": ["Mulberry Grove", "Illinois", "IL"],
"62263": ["Nashville", "Illinois", "IL"],
"62264": ["New Athens", "Illinois", "IL"],
"62265": ["New Baden", "Illinois", "IL"],
"62266": ["New Memphis", "Illinois", "IL"],
"62268": ["Oakdale", "Illinois", "IL"],
"62269": ["O'Fallon", "Illinois", "IL"],
"62271": ["Okawville", "Illinois", "IL"],
"62272": ["Percy", "Illinois", "IL"],
"62273": ["Pierron", "Illinois", "IL"],
"62274": ["Pinckneyville", "Illinois", "IL"],
"62275": ["Pocahontas", "Illinois", "IL"],
"62277": ["Prairie Du Rocher", "Illinois", "IL"],
"62278": ["Red Bud", "Illinois", "IL"],
"62279": ["Renault", "Illinois", "IL"],
"62280": ["Rockwood", "Illinois", "IL"],
"62281": ["Saint Jacob", "Illinois", "IL"],
"62282": ["Saint Libory", "Illinois", "IL"],
"62284": ["Smithboro", "Illinois", "IL"],
"62285": ["Smithton", "Illinois", "IL"],
"62286": ["Sparta", "Illinois", "IL"],
"62288": ["Steeleville", "Illinois", "IL"],
"62289": ["Summerfield", "Illinois", "IL"],
"62292": ["Tilden", "Illinois", "IL"],
"62293": ["Trenton", "Illinois", "IL"],
"62294": ["Troy", "Illinois", "IL"],
"62295": ["Valmeyer", "Illinois", "IL"],
"62297": ["Walsh", "Illinois", "IL"],
"62298": ["Waterloo", "Illinois", "IL"],
"62301": ["Quincy", "Illinois", "IL"],
"62305": ["Quincy", "Illinois", "IL"],
"62311": ["Augusta", "Illinois", "IL"],
"62312": ["Barry", "Illinois", "IL"],
"62313": ["Basco", "Illinois", "IL"],
"62314": ["Baylis", "Illinois", "IL"],
"62316": ["Bowen", "Illinois", "IL"],
"62319": ["Camden", "Illinois", "IL"],
"62320": ["Camp Point", "Illinois", "IL"],
"62321": ["Carthage", "Illinois", "IL"],
"62323": ["Chambersburg", "Illinois", "IL"],
"62324": ["Clayton", "Illinois", "IL"],
"62325": ["Coatsburg", "Illinois", "IL"],
"62326": ["Colchester", "Illinois", "IL"],
"62330": ["Dallas City", "Illinois", "IL"],
"62334": ["Elvaston", "Illinois", "IL"],
"62336": ["Ferris", "Illinois", "IL"],
"62338": ["Fowler", "Illinois", "IL"],
"62339": ["Golden", "Illinois", "IL"],
"62340": ["Griggsville", "Illinois", "IL"],
"62341": ["Hamilton", "Illinois", "IL"],
"62343": ["Hull", "Illinois", "IL"],
"62344": ["Huntsville", "Illinois", "IL"],
"62345": ["Kinderhook", "Illinois", "IL"],
"62346": ["La Prairie", "Illinois", "IL"],
"62347": ["Liberty", "Illinois", "IL"],
"62348": ["Lima", "Illinois", "IL"],
"62349": ["Loraine", "Illinois", "IL"],
"62351": ["Mendon", "Illinois", "IL"],
"62352": ["Milton", "Illinois", "IL"],
"62353": ["Mount Sterling", "Illinois", "IL"],
"62354": ["Nauvoo", "Illinois", "IL"],
"62355": ["Nebo", "Illinois", "IL"],
"62356": ["New Canton", "Illinois", "IL"],
"62357": ["New Salem", "Illinois", "IL"],
"62358": ["Niota", "Illinois", "IL"],
"62359": ["Paloma", "Illinois", "IL"],
"62360": ["Payson", "Illinois", "IL"],
"62361": ["Pearl", "Illinois", "IL"],
"62362": ["Perry", "Illinois", "IL"],
"62363": ["Pittsfield", "Illinois", "IL"],
"62365": ["Plainville", "Illinois", "IL"],
"62366": ["Pleasant Hill", "Illinois", "IL"],
"62367": ["Plymouth", "Illinois", "IL"],
"62370": ["Rockport", "Illinois", "IL"],
"62373": ["Sutter", "Illinois", "IL"],
"62374": ["Tennessee", "Illinois", "IL"],
"62375": ["Timewell", "Illinois", "IL"],
"62376": ["Ursa", "Illinois", "IL"],
"62378": ["Versailles", "Illinois", "IL"],
"62379": ["Warsaw", "Illinois", "IL"],
"62380": ["West Point", "Illinois", "IL"],
"62401": ["Effingham", "Illinois", "IL"],
"62410": ["Allendale", "Illinois", "IL"],
"62411": ["Altamont", "Illinois", "IL"],
"62413": ["Annapolis", "Illinois", "IL"],
"62414": ["Beecher City", "Illinois", "IL"],
"62417": ["Bridgeport", "Illinois", "IL"],
"62418": ["Brownstown", "Illinois", "IL"],
"62419": ["Calhoun", "Illinois", "IL"],
"62420": ["Casey", "Illinois", "IL"],
"62421": ["Claremont", "Illinois", "IL"],
"62422": ["Cowden", "Illinois", "IL"],
"62423": ["Dennison", "Illinois", "IL"],
"62424": ["Dieterich", "Illinois", "IL"],
"62425": ["Dundas", "Illinois", "IL"],
"62426": ["Edgewood", "Illinois", "IL"],
"62427": ["Flat Rock", "Illinois", "IL"],
"62428": ["Greenup", "Illinois", "IL"],
"62431": ["Herrick", "Illinois", "IL"],
"62432": ["Hidalgo", "Illinois", "IL"],
"62433": ["Hutsonville", "Illinois", "IL"],
"62434": ["Ingraham", "Illinois", "IL"],
"62436": ["Jewett", "Illinois", "IL"],
"62438": ["Lakewood", "Illinois", "IL"],
"62439": ["Lawrenceville", "Illinois", "IL"],
"62440": ["Lerna", "Illinois", "IL"],
"62441": ["Marshall", "Illinois", "IL"],
"62442": ["Martinsville", "Illinois", "IL"],
"62443": ["Mason", "Illinois", "IL"],
"62444": ["Mode", "Illinois", "IL"],
"62445": ["Montrose", "Illinois", "IL"],
"62446": ["Mount Erie", "Illinois", "IL"],
"62447": ["Neoga", "Illinois", "IL"],
"62448": ["Newton", "Illinois", "IL"],
"62449": ["Oblong", "Illinois", "IL"],
"62450": ["Olney", "Illinois", "IL"],
"62451": ["Palestine", "Illinois", "IL"],
"62452": ["Parkersburg", "Illinois", "IL"],
"62454": ["Robinson", "Illinois", "IL"],
"62458": ["Saint Elmo", "Illinois", "IL"],
"62459": ["Sainte Marie", "Illinois", "IL"],
"62460": ["Saint Francisville", "Illinois", "IL"],
"62461": ["Shumway", "Illinois", "IL"],
"62462": ["Sigel", "Illinois", "IL"],
"62463": ["Stewardson", "Illinois", "IL"],
"62464": ["Stoy", "Illinois", "IL"],
"62465": ["Strasburg", "Illinois", "IL"],
"62466": ["Sumner", "Illinois", "IL"],
"62467": ["Teutopolis", "Illinois", "IL"],
"62468": ["Toledo", "Illinois", "IL"],
"62469": ["Trilla", "Illinois", "IL"],
"62471": ["Vandalia", "Illinois", "IL"],
"62473": ["Watson", "Illinois", "IL"],
"62474": ["Westfield", "Illinois", "IL"],
"62475": ["West Liberty", "Illinois", "IL"],
"62476": ["West Salem", "Illinois", "IL"],
"62477": ["West Union", "Illinois", "IL"],
"62478": ["West York", "Illinois", "IL"],
"62479": ["Wheeler", "Illinois", "IL"],
"62480": ["Willow Hill", "Illinois", "IL"],
"62481": ["Yale", "Illinois", "IL"],
"62501": ["Argenta", "Illinois", "IL"],
"62510": ["Assumption", "Illinois", "IL"],
"62512": ["Beason", "Illinois", "IL"],
"62513": ["Blue Mound", "Illinois", "IL"],
"62514": ["Boody", "Illinois", "IL"],
"62515": ["Buffalo", "Illinois", "IL"],
"62517": ["Bulpitt", "Illinois", "IL"],
"62518": ["Chestnut", "Illinois", "IL"],
"62519": ["Cornland", "Illinois", "IL"],
"62520": ["Dawson", "Illinois", "IL"],
"62521": ["Decatur", "Illinois", "IL"],
"62522": ["Decatur", "Illinois", "IL"],
"62523": ["Decatur", "Illinois", "IL"],
"62526": ["Decatur", "Illinois", "IL"],
"62530": ["Divernon", "Illinois", "IL"],
"62531": ["Edinburg", "Illinois", "IL"],
"62532": ["Elwin", "Illinois", "IL"],
"62533": ["Farmersville", "Illinois", "IL"],
"62534": ["Findlay", "Illinois", "IL"],
"62535": ["Forsyth", "Illinois", "IL"],
"62536": ["Glenarm", "Illinois", "IL"],
"62537": ["Harristown", "Illinois", "IL"],
"62538": ["Harvel", "Illinois", "IL"],
"62539": ["Illiopolis", "Illinois", "IL"],
"62540": ["Kincaid", "Illinois", "IL"],
"62541": ["Lake Fork", "Illinois", "IL"],
"62543": ["Latham", "Illinois", "IL"],
"62544": ["Macon", "Illinois", "IL"],
"62545": ["Mechanicsburg", "Illinois", "IL"],
"62546": ["Morrisonville", "Illinois", "IL"],
"62547": ["Mount Auburn", "Illinois", "IL"],
"62548": ["Mount Pulaski", "Illinois", "IL"],
"62549": ["Mt Zion", "Illinois", "IL"],
"62550": ["Moweaqua", "Illinois", "IL"],
"62551": ["Niantic", "Illinois", "IL"],
"62553": ["Oconee", "Illinois", "IL"],
"62554": ["Oreana", "Illinois", "IL"],
"62555": ["Owaneco", "Illinois", "IL"],
"62556": ["Palmer", "Illinois", "IL"],
"62557": ["Pana", "Illinois", "IL"],
"62558": ["Pawnee", "Illinois", "IL"],
"62560": ["Raymond", "Illinois", "IL"],
"62561": ["Riverton", "Illinois", "IL"],
"62563": ["Rochester", "Illinois", "IL"],
"62565": ["Shelbyville", "Illinois", "IL"],
"62567": ["Stonington", "Illinois", "IL"],
"62568": ["Taylorville", "Illinois", "IL"],
"62570": ["Tovey", "Illinois", "IL"],
"62571": ["Tower Hill", "Illinois", "IL"],
"62572": ["Waggoner", "Illinois", "IL"],
"62573": ["Warrensburg", "Illinois", "IL"],
"62601": ["Alexander", "Illinois", "IL"],
"62610": ["Alsey", "Illinois", "IL"],
"62611": ["Arenzville", "Illinois", "IL"],
"62612": ["Ashland", "Illinois", "IL"],
"62613": ["Athens", "Illinois", "IL"],
"62615": ["Auburn", "Illinois", "IL"],
"62617": ["Bath", "Illinois", "IL"],
"62618": ["Beardstown", "Illinois", "IL"],
"62621": ["Bluffs", "Illinois", "IL"],
"62622": ["Bluff Springs", "Illinois", "IL"],
"62624": ["Browning", "Illinois", "IL"],
"62625": ["Cantrall", "Illinois", "IL"],
"62626": ["Carlinville", "Illinois", "IL"],
"62627": ["Chandlerville", "Illinois", "IL"],
"62628": ["Chapin", "Illinois", "IL"],
"62629": ["Chatham", "Illinois", "IL"],
"62630": ["Chesterfield", "Illinois", "IL"],
"62631": ["Concord", "Illinois", "IL"],
"62633": ["Easton", "Illinois", "IL"],
"62634": ["Elkhart", "Illinois", "IL"],
"62635": ["Emden", "Illinois", "IL"],
"62638": ["Franklin", "Illinois", "IL"],
"62639": ["Frederick", "Illinois", "IL"],
"62640": ["Girard", "Illinois", "IL"],
"62642": ["Greenview", "Illinois", "IL"],
"62643": ["Hartsburg", "Illinois", "IL"],
"62644": ["Havana", "Illinois", "IL"],
"62649": ["Hettick", "Illinois", "IL"],
"62650": ["Jacksonville", "Illinois", "IL"],
"62655": ["Kilbourne", "Illinois", "IL"],
"62656": ["Lincoln", "Illinois", "IL"],
"62661": ["Loami", "Illinois", "IL"],
"62663": ["Manchester", "Illinois", "IL"],
"62664": ["Mason City", "Illinois", "IL"],
"62665": ["Meredosia", "Illinois", "IL"],
"62666": ["Middletown", "Illinois", "IL"],
"62667": ["Modesto", "Illinois", "IL"],
"62668": ["Murrayville", "Illinois", "IL"],
"62670": ["New Berlin", "Illinois", "IL"],
"62671": ["New Holland", "Illinois", "IL"],
"62672": ["Nilwood", "Illinois", "IL"],
"62673": ["Oakford", "Illinois", "IL"],
"62674": ["Palmyra", "Illinois", "IL"],
"62675": ["Petersburg", "Illinois", "IL"],
"62677": ["Pleasant Plains", "Illinois", "IL"],
"62681": ["Rushville", "Illinois", "IL"],
"62682": ["San Jose", "Illinois", "IL"],
"62684": ["Sherman", "Illinois", "IL"],
"62685": ["Shipman", "Illinois", "IL"],
"62688": ["Tallula", "Illinois", "IL"],
"62689": ["Thayer", "Illinois", "IL"],
"62690": ["Virden", "Illinois", "IL"],
"62691": ["Virginia", "Illinois", "IL"],
"62692": ["Waverly", "Illinois", "IL"],
"62693": ["Williamsville", "Illinois", "IL"],
"62694": ["Winchester", "Illinois", "IL"],
"62695": ["Woodson", "Illinois", "IL"],
"62701": ["Springfield", "Illinois", "IL"],
"62702": ["Springfield", "Illinois", "IL"],
"62703": ["Springfield", "Illinois", "IL"],
"62704": ["Springfield", "Illinois", "IL"],
"62706": ["Springfield", "Illinois", "IL"],
"62707": ["Springfield", "Illinois", "IL"],
"62711": ["Springfield", "Illinois", "IL"],
"62712": ["Springfield", "Illinois", "IL"],
"62801": ["Centralia", "Illinois", "IL"],
"62803": ["Hoyleton", "Illinois", "IL"],
"62806": ["Albion", "Illinois", "IL"],
"62807": ["Alma", "Illinois", "IL"],
"62808": ["Ashley", "Illinois", "IL"],
"62809": ["Barnhill", "Illinois", "IL"],
"62810": ["Belle Rive", "Illinois", "IL"],
"62811": ["Bellmont", "Illinois", "IL"],
"62812": ["Benton", "Illinois", "IL"],
"62814": ["Bluford", "Illinois", "IL"],
"62815": ["Bone Gap", "Illinois", "IL"],
"62816": ["Bonnie", "Illinois", "IL"],
"62817": ["Broughton", "Illinois", "IL"],
"62818": ["Browns", "Illinois", "IL"],
"62819": ["Buckner", "Illinois", "IL"],
"62820": ["Burnt Prairie", "Illinois", "IL"],
"62821": ["Carmi", "Illinois", "IL"],
"62822": ["Christopher", "Illinois", "IL"],
"62823": ["Cisne", "Illinois", "IL"],
"62824": ["Clay City", "Illinois", "IL"],
"62825": ["Coello", "Illinois", "IL"],
"62827": ["Crossville", "Illinois", "IL"],
"62828": ["Dahlgren", "Illinois", "IL"],
"62829": ["Dale", "Illinois", "IL"],
"62830": ["Dix", "Illinois", "IL"],
"62831": ["Du Bois", "Illinois", "IL"],
"62832": ["Du Quoin", "Illinois", "IL"],
"62833": ["Ellery", "Illinois", "IL"],
"62835": ["Enfield", "Illinois", "IL"],
"62836": ["Ewing", "Illinois", "IL"],
"62837": ["Fairfield", "Illinois", "IL"],
"62838": ["Farina", "Illinois", "IL"],
"62839": ["Flora", "Illinois", "IL"],
"62841": ["Freeman Spur", "Illinois", "IL"],
"62842": ["Geff", "Illinois", "IL"],
"62843": ["Golden Gate", "Illinois", "IL"],
"62844": ["Grayville", "Illinois", "IL"],
"62846": ["Ina", "Illinois", "IL"],
"62848": ["Irvington", "Illinois", "IL"],
"62849": ["Iuka", "Illinois", "IL"],
"62850": ["Johnsonville", "Illinois", "IL"],
"62851": ["Keenes", "Illinois", "IL"],
"62852": ["Keensburg", "Illinois", "IL"],
"62853": ["Kell", "Illinois", "IL"],
"62854": ["Kinmundy", "Illinois", "IL"],
"62856": ["Logan", "Illinois", "IL"],
"62858": ["Louisville", "Illinois", "IL"],
"62859": ["McLeansboro", "Illinois", "IL"],
"62860": ["Macedonia", "Illinois", "IL"],
"62861": ["Maunie", "Illinois", "IL"],
"62862": ["Mill Shoals", "Illinois", "IL"],
"62863": ["Mount Carmel", "Illinois", "IL"],
"62864": ["Mount Vernon", "Illinois", "IL"],
"62865": ["Mulkeytown", "Illinois", "IL"],
"62867": ["New Haven", "Illinois", "IL"],
"62868": ["Noble", "Illinois", "IL"],
"62869": ["Norris City", "Illinois", "IL"],
"62870": ["Odin", "Illinois", "IL"],
"62871": ["Omaha", "Illinois", "IL"],
"62872": ["Opdyke", "Illinois", "IL"],
"62874": ["Orient", "Illinois", "IL"],
"62875": ["Patoka", "Illinois", "IL"],
"62876": ["Radom", "Illinois", "IL"],
"62877": ["Richview", "Illinois", "IL"],
"62878": ["Rinard", "Illinois", "IL"],
"62879": ["Sailor Springs", "Illinois", "IL"],
"62880": ["Saint Peter", "Illinois", "IL"],
"62881": ["Salem", "Illinois", "IL"],
"62882": ["Sandoval", "Illinois", "IL"],
"62883": ["Scheller", "Illinois", "IL"],
"62884": ["Sesser", "Illinois", "IL"],
"62885": ["Shobonier", "Illinois", "IL"],
"62886": ["Sims", "Illinois", "IL"],
"62887": ["Springerton", "Illinois", "IL"],
"62888": ["Tamaroa", "Illinois", "IL"],
"62889": ["Texico", "Illinois", "IL"],
"62890": ["Thompsonville", "Illinois", "IL"],
"62891": ["Valier", "Illinois", "IL"],
"62892": ["Vernon", "Illinois", "IL"],
"62893": ["Walnut Hill", "Illinois", "IL"],
"62894": ["Waltonville", "Illinois", "IL"],
"62895": ["Wayne City", "Illinois", "IL"],
"62896": ["West Frankfort", "Illinois", "IL"],
"62897": ["Whittington", "Illinois", "IL"],
"62898": ["Woodlawn", "Illinois", "IL"],
"62899": ["Xenia", "Illinois", "IL"],
"62901": ["Carbondale", "Illinois", "IL"],
"62902": ["Carbondale", "Illinois", "IL"],
"62903": ["Carbondale", "Illinois", "IL"],
"62905": ["Alto Pass", "Illinois", "IL"],
"62906": ["Anna", "Illinois", "IL"],
"62907": ["Ava", "Illinois", "IL"],
"62908": ["Belknap", "Illinois", "IL"],
"62910": ["Brookport", "Illinois", "IL"],
"62912": ["Buncombe", "Illinois", "IL"],
"62914": ["Cairo", "Illinois", "IL"],
"62915": ["Cambria", "Illinois", "IL"],
"62916": ["Campbell Hill", "Illinois", "IL"],
"62917": ["Carrier Mills", "Illinois", "IL"],
"62918": ["Carterville", "Illinois", "IL"],
"62919": ["Cave In Rock", "Illinois", "IL"],
"62920": ["Cobden", "Illinois", "IL"],
"62921": ["Colp", "Illinois", "IL"],
"62922": ["Creal Springs", "Illinois", "IL"],
"62923": ["Cypress", "Illinois", "IL"],
"62924": ["De Soto", "Illinois", "IL"],
"62926": ["Dongola", "Illinois", "IL"],
"62927": ["Dowell", "Illinois", "IL"],
"62928": ["Eddyville", "Illinois", "IL"],
"62930": ["Eldorado", "Illinois", "IL"],
"62931": ["Elizabethtown", "Illinois", "IL"],
"62932": ["Elkville", "Illinois", "IL"],
"62933": ["Energy", "Illinois", "IL"],
"62934": ["Equality", "Illinois", "IL"],
"62935": ["Galatia", "Illinois", "IL"],
"62938": ["Golconda", "Illinois", "IL"],
"62939": ["Goreville", "Illinois", "IL"],
"62940": ["Gorham", "Illinois", "IL"],
"62941": ["Grand Chain", "Illinois", "IL"],
"62942": ["Grand Tower", "Illinois", "IL"],
"62943": ["Grantsburg", "Illinois", "IL"],
"62946": ["Harrisburg", "Illinois", "IL"],
"62947": ["Herod", "Illinois", "IL"],
"62948": ["Herrin", "Illinois", "IL"],
"62949": ["Hurst", "Illinois", "IL"],
"62950": ["Jacob", "Illinois", "IL"],
"62951": ["Johnston City", "Illinois", "IL"],
"62952": ["Jonesboro", "Illinois", "IL"],
"62953": ["Joppa", "Illinois", "IL"],
"62954": ["Junction", "Illinois", "IL"],
"62956": ["Karnak", "Illinois", "IL"],
"62957": ["McClure", "Illinois", "IL"],
"62958": ["Makanda", "Illinois", "IL"],
"62959": ["Marion", "Illinois", "IL"],
"62960": ["Metropolis", "Illinois", "IL"],
"62961": ["Millcreek", "Illinois", "IL"],
"62962": ["Miller City", "Illinois", "IL"],
"62963": ["Mound City", "Illinois", "IL"],
"62964": ["Mounds", "Illinois", "IL"],
"62965": ["Muddy", "Illinois", "IL"],
"62966": ["Murphysboro", "Illinois", "IL"],
"62967": ["New Burnside", "Illinois", "IL"],
"62969": ["Olive Branch", "Illinois", "IL"],
"62970": ["Olmsted", "Illinois", "IL"],
"62972": ["Ozark", "Illinois", "IL"],
"62974": ["Pittsburg", "Illinois", "IL"],
"62975": ["Pomona", "Illinois", "IL"],
"62976": ["Pulaski", "Illinois", "IL"],
"62977": ["Raleigh", "Illinois", "IL"],
"62979": ["Ridgway", "Illinois", "IL"],
"62982": ["Rosiclare", "Illinois", "IL"],
"62983": ["Royalton", "Illinois", "IL"],
"62984": ["Shawneetown", "Illinois", "IL"],
"62985": ["Simpson", "Illinois", "IL"],
"62987": ["Stonefort", "Illinois", "IL"],
"62988": ["Tamms", "Illinois", "IL"],
"62990": ["Thebes", "Illinois", "IL"],
"62992": ["Ullin", "Illinois", "IL"],
"62994": ["Vergennes", "Illinois", "IL"],
"62995": ["Vienna", "Illinois", "IL"],
"62996": ["Villa Ridge", "Illinois", "IL"],
"62997": ["Willisville", "Illinois", "IL"],
"62998": ["Wolf Lake", "Illinois", "IL"],
"62999": ["Zeigler", "Illinois", "IL"],
"63005": ["Chesterfield", "Missouri", "MO"],
"63010": ["Arnold", "Missouri", "MO"],
"63011": ["Ballwin", "Missouri", "MO"],
"63012": ["Barnhart", "Missouri", "MO"],
"63013": ["Beaufort", "Missouri", "MO"],
"63014": ["Berger", "Missouri", "MO"],
"63015": ["Catawissa", "Missouri", "MO"],
"63016": ["Cedar Hill", "Missouri", "MO"],
"63017": ["Chesterfield", "Missouri", "MO"],
"63019": ["Crystal City", "Missouri", "MO"],
"63020": ["De Soto", "Missouri", "MO"],
"63021": ["Ballwin", "Missouri", "MO"],
"63023": ["Dittmer", "Missouri", "MO"],
"63025": ["Eureka", "Missouri", "MO"],
"63026": ["Fenton", "Missouri", "MO"],
"63028": ["Festus", "Missouri", "MO"],
"63030": ["Fletcher", "Missouri", "MO"],
"63031": ["Florissant", "Missouri", "MO"],
"63033": ["Florissant", "Missouri", "MO"],
"63034": ["Florissant", "Missouri", "MO"],
"63036": ["French Village", "Missouri", "MO"],
"63037": ["Gerald", "Missouri", "MO"],
"63038": ["Wildwood", "Missouri", "MO"],
"63039": ["Gray Summit", "Missouri", "MO"],
"63040": ["Wildwood", "Missouri", "MO"],
"63041": ["Grubville", "Missouri", "MO"],
"63042": ["Hazelwood", "Missouri", "MO"],
"63043": ["Maryland Heights", "Missouri", "MO"],
"63044": ["Bridgeton", "Missouri", "MO"],
"63045": ["Earth City", "Missouri", "MO"],
"63047": ["Hematite", "Missouri", "MO"],
"63048": ["Herculaneum", "Missouri", "MO"],
"63049": ["High Ridge", "Missouri", "MO"],
"63050": ["Hillsboro", "Missouri", "MO"],
"63051": ["House Springs", "Missouri", "MO"],
"63052": ["Imperial", "Missouri", "MO"],
"63053": ["Kimmswick", "Missouri", "MO"],
"63055": ["Labadie", "Missouri", "MO"],
"63056": ["Leslie", "Missouri", "MO"],
"63060": ["Lonedell", "Missouri", "MO"],
"63061": ["Luebbering", "Missouri", "MO"],
"63068": ["New Haven", "Missouri", "MO"],
"63069": ["Pacific", "Missouri", "MO"],
"63070": ["Pevely", "Missouri", "MO"],
"63071": ["Richwoods", "Missouri", "MO"],
"63072": ["Robertsville", "Missouri", "MO"],
"63073": ["Saint Albans", "Missouri", "MO"],
"63074": ["Saint Ann", "Missouri", "MO"],
"63077": ["Saint Clair", "Missouri", "MO"],
"63079": ["Stanton", "Missouri", "MO"],
"63080": ["Sullivan", "Missouri", "MO"],
"63084": ["Union", "Missouri", "MO"],
"63087": ["Valles Mines", "Missouri", "MO"],
"63088": ["Valley Park", "Missouri", "MO"],
"63089": ["Villa Ridge", "Missouri", "MO"],
"63090": ["Washington", "Missouri", "MO"],
"63091": ["Rosebud", "Missouri", "MO"],
"63101": ["Saint Louis", "Missouri", "MO"],
"63102": ["Saint Louis", "Missouri", "MO"],
"63103": ["Saint Louis", "Missouri", "MO"],
"63104": ["Saint Louis", "Missouri", "MO"],
"63105": ["Saint Louis", "Missouri", "MO"],
"63106": ["Saint Louis", "Missouri", "MO"],
"63107": ["Saint Louis", "Missouri", "MO"],
"63108": ["Saint Louis", "Missouri", "MO"],
"63109": ["Saint Louis", "Missouri", "MO"],
"63110": ["Saint Louis", "Missouri", "MO"],
"63111": ["Saint Louis", "Missouri", "MO"],
"63112": ["Saint Louis", "Missouri", "MO"],
"63113": ["Saint Louis", "Missouri", "MO"],
"63114": ["Saint Louis", "Missouri", "MO"],
"63115": ["Saint Louis", "Missouri", "MO"],
"63116": ["Saint Louis", "Missouri", "MO"],
"63117": ["Saint Louis", "Missouri", "MO"],
"63118": ["Saint Louis", "Missouri", "MO"],
"63119": ["Saint Louis", "Missouri", "MO"],
"63120": ["Saint Louis", "Missouri", "MO"],
"63121": ["Saint Louis", "Missouri", "MO"],
"63122": ["Saint Louis", "Missouri", "MO"],
"63123": ["Saint Louis", "Missouri", "MO"],
"63124": ["Saint Louis", "Missouri", "MO"],
"63125": ["Saint Louis", "Missouri", "MO"],
"63126": ["Saint Louis", "Missouri", "MO"],
"63127": ["Saint Louis", "Missouri", "MO"],
"63128": ["Saint Louis", "Missouri", "MO"],
"63129": ["Saint Louis", "Missouri", "MO"],
"63130": ["Saint Louis", "Missouri", "MO"],
"63131": ["Saint Louis", "Missouri", "MO"],
"63132": ["Saint Louis", "Missouri", "MO"],
"63133": ["Saint Louis", "Missouri", "MO"],
"63134": ["Saint Louis", "Missouri", "MO"],
"63135": ["Saint Louis", "Missouri", "MO"],
"63136": ["Saint Louis", "Missouri", "MO"],
"63137": ["Saint Louis", "Missouri", "MO"],
"63138": ["Saint Louis", "Missouri", "MO"],
"63139": ["Saint Louis", "Missouri", "MO"],
"63140": ["Saint Louis", "Missouri", "MO"],
"63141": ["Saint Louis", "Missouri", "MO"],
"63143": ["Saint Louis", "Missouri", "MO"],
"63144": ["Saint Louis", "Missouri", "MO"],
"63146": ["Saint Louis", "Missouri", "MO"],
"63147": ["Saint Louis", "Missouri", "MO"],
"63155": ["Saint Louis", "Missouri", "MO"],
"63301": ["Saint Charles", "Missouri", "MO"],
"63303": ["Saint Charles", "Missouri", "MO"],
"63304": ["Saint Charles", "Missouri", "MO"],
"63330": ["Annada", "Missouri", "MO"],
"63332": ["Augusta", "Missouri", "MO"],
"63333": ["Bellflower", "Missouri", "MO"],
"63334": ["Bowling Green", "Missouri", "MO"],
"63336": ["Clarksville", "Missouri", "MO"],
"63339": ["Curryville", "Missouri", "MO"],
"63341": ["Defiance", "Missouri", "MO"],
"63343": ["Elsberry", "Missouri", "MO"],
"63344": ["Eolia", "Missouri", "MO"],
"63345": ["Farber", "Missouri", "MO"],
"63347": ["Foley", "Missouri", "MO"],
"63348": ["Foristell", "Missouri", "MO"],
"63349": ["Hawk Point", "Missouri", "MO"],
"63350": ["High Hill", "Missouri", "MO"],
"63351": ["Jonesburg", "Missouri", "MO"],
"63352": ["Laddonia", "Missouri", "MO"],
"63353": ["Louisiana", "Missouri", "MO"],
"63357": ["Marthasville", "Missouri", "MO"],
"63359": ["Middletown", "Missouri", "MO"],
"63361": ["Montgomery City", "Missouri", "MO"],
"63362": ["Moscow Mills", "Missouri", "MO"],
"63363": ["New Florence", "Missouri", "MO"],
"63365": ["New Melle", "Missouri", "MO"],
"63366": ["O'Fallon", "Missouri", "MO"],
"63367": ["Lake Saint Louis", "Missouri", "MO"],
"63368": ["O'Fallon", "Missouri", "MO"],
"63369": ["Old Monroe", "Missouri", "MO"],
"63373": ["Portage Des Sioux", "Missouri", "MO"],
"63376": ["Saint Peters", "Missouri", "MO"],
"63377": ["Silex", "Missouri", "MO"],
"63379": ["Troy", "Missouri", "MO"],
"63380": ["Truesdale", "Missouri", "MO"],
"63381": ["Truxton", "Missouri", "MO"],
"63382": ["Vandalia", "Missouri", "MO"],
"63383": ["Warrenton", "Missouri", "MO"],
"63384": ["Wellsville", "Missouri", "MO"],
"63385": ["Wentzville", "Missouri", "MO"],
"63386": ["West Alton", "Missouri", "MO"],
"63387": ["Whiteside", "Missouri", "MO"],
"63388": ["Williamsburg", "Missouri", "MO"],
"63389": ["Winfield", "Missouri", "MO"],
"63390": ["Wright City", "Missouri", "MO"],
"63401": ["Hannibal", "Missouri", "MO"],
"63430": ["Alexandria", "Missouri", "MO"],
"63431": ["Anabel", "Missouri", "MO"],
"63432": ["Arbela", "Missouri", "MO"],
"63433": ["Ashburn", "Missouri", "MO"],
"63434": ["Bethel", "Missouri", "MO"],
"63435": ["Canton", "Missouri", "MO"],
"63436": ["Center", "Missouri", "MO"],
"63437": ["Clarence", "Missouri", "MO"],
"63438": ["Durham", "Missouri", "MO"],
"63439": ["Emden", "Missouri", "MO"],
"63440": ["Ewing", "Missouri", "MO"],
"63441": ["Frankford", "Missouri", "MO"],
"63443": ["Hunnewell", "Missouri", "MO"],
"63445": ["Kahoka", "Missouri", "MO"],
"63446": ["Knox City", "Missouri", "MO"],
"63447": ["La Belle", "Missouri", "MO"],
"63448": ["La Grange", "Missouri", "MO"],
"63450": ["Lentner", "Missouri", "MO"],
"63451": ["Leonard", "Missouri", "MO"],
"63452": ["Lewistown", "Missouri", "MO"],
"63453": ["Luray", "Missouri", "MO"],
"63454": ["Maywood", "Missouri", "MO"],
"63456": ["Monroe City", "Missouri", "MO"],
"63457": ["Monticello", "Missouri", "MO"],
"63458": ["Newark", "Missouri", "MO"],
"63459": ["New London", "Missouri", "MO"],
"63460": ["Novelty", "Missouri", "MO"],
"63461": ["Palmyra", "Missouri", "MO"],
"63462": ["Perry", "Missouri", "MO"],
"63463": ["Philadelphia", "Missouri", "MO"],
"63465": ["Revere", "Missouri", "MO"],
"63466": ["Saint Patrick", "Missouri", "MO"],
"63467": ["Saverton", "Missouri", "MO"],
"63468": ["Shelbina", "Missouri", "MO"],
"63469": ["Shelbyville", "Missouri", "MO"],
"63471": ["Taylor", "Missouri", "MO"],
"63472": ["Wayland", "Missouri", "MO"],
"63473": ["Williamstown", "Missouri", "MO"],
"63474": ["Wyaconda", "Missouri", "MO"],
"63501": ["Kirksville", "Missouri", "MO"],
"63530": ["Atlanta", "Missouri", "MO"],
"63531": ["Baring", "Missouri", "MO"],
"63532": ["Bevier", "Missouri", "MO"],
"63533": ["Brashear", "Missouri", "MO"],
"63534": ["Callao", "Missouri", "MO"],
"63535": ["Coatsville", "Missouri", "MO"],
"63536": ["Downing", "Missouri", "MO"],
"63537": ["Edina", "Missouri", "MO"],
"63538": ["Elmer", "Missouri", "MO"],
"63539": ["Ethel", "Missouri", "MO"],
"63540": ["Gibbs", "Missouri", "MO"],
"63541": ["Glenwood", "Missouri", "MO"],
"63543": ["Gorin", "Missouri", "MO"],
"63544": ["Green Castle", "Missouri", "MO"],
"63545": ["Green City", "Missouri", "MO"],
"63546": ["Greentop", "Missouri", "MO"],
"63547": ["Hurdland", "Missouri", "MO"],
"63548": ["Lancaster", "Missouri", "MO"],
"63549": ["La Plata", "Missouri", "MO"],
"63551": ["Livonia", "Missouri", "MO"],
"63552": ["Macon", "Missouri", "MO"],
"63555": ["Memphis", "Missouri", "MO"],
"63556": ["Milan", "Missouri", "MO"],
"63557": ["New Boston", "Missouri", "MO"],
"63558": ["New Cambria", "Missouri", "MO"],
"63559": ["Novinger", "Missouri", "MO"],
"63560": ["Pollock", "Missouri", "MO"],
"63561": ["Queen City", "Missouri", "MO"],
"63563": ["Rutledge", "Missouri", "MO"],
"63565": ["Unionville", "Missouri", "MO"],
"63566": ["Winigan", "Missouri", "MO"],
"63567": ["Worthington", "Missouri", "MO"],
"63601": ["Park Hills", "Missouri", "MO"],
"63620": ["Annapolis", "Missouri", "MO"],
"63621": ["Arcadia", "Missouri", "MO"],
"63622": ["Belgrade", "Missouri", "MO"],
"63623": ["Belleview", "Missouri", "MO"],
"63624": ["Bismarck", "Missouri", "MO"],
"63625": ["Black", "Missouri", "MO"],
"63626": ["Blackwell", "Missouri", "MO"],
"63627": ["Bloomsdale", "Missouri", "MO"],
"63628": ["Bonne Terre", "Missouri", "MO"],
"63629": ["Bunker", "Missouri", "MO"],
"63630": ["Cadet", "Missouri", "MO"],
"63631": ["Caledonia", "Missouri", "MO"],
"63633": ["Centerville", "Missouri", "MO"],
"63636": ["Des Arc", "Missouri", "MO"],
"63637": ["Doe Run", "Missouri", "MO"],
"63638": ["Ellington", "Missouri", "MO"],
"63640": ["Farmington", "Missouri", "MO"],
"63645": ["Fredericktown", "Missouri", "MO"],
"63648": ["Irondale", "Missouri", "MO"],
"63650": ["Ironton", "Missouri", "MO"],
"63653": ["Leadwood", "Missouri", "MO"],
"63654": ["Lesterville", "Missouri", "MO"],
"63655": ["Marquand", "Missouri", "MO"],
"63656": ["Middle Brook", "Missouri", "MO"],
"63660": ["Mineral Point", "Missouri", "MO"],
"63662": ["Patton", "Missouri", "MO"],
"63663": ["Pilot Knob", "Missouri", "MO"],
"63664": ["Potosi", "Missouri", "MO"],
"63665": ["Redford", "Missouri", "MO"],
"63670": ["Sainte Genevieve", "Missouri", "MO"],
"63673": ["Saint Mary", "Missouri", "MO"],
"63674": ["Tiff", "Missouri", "MO"],
"63675": ["Vulcan", "Missouri", "MO"],
"63701": ["Cape Girardeau", "Missouri", "MO"],
"63703": ["Cape Girardeau", "Missouri", "MO"],
"63730": ["Advance", "Missouri", "MO"],
"63732": ["Altenburg", "Missouri", "MO"],
"63735": ["Bell City", "Missouri", "MO"],
"63736": ["Benton", "Missouri", "MO"],
"63738": ["Brownwood", "Missouri", "MO"],
"63739": ["Burfordville", "Missouri", "MO"],
"63740": ["Chaffee", "Missouri", "MO"],
"63742": ["Commerce", "Missouri", "MO"],
"63743": ["Daisy", "Missouri", "MO"],
"63744": ["Delta", "Missouri", "MO"],
"63745": ["Dutchtown", "Missouri", "MO"],
"63746": ["Farrar", "Missouri", "MO"],
"63747": ["Friedheim", "Missouri", "MO"],
"63748": ["Frohna", "Missouri", "MO"],
"63750": ["Gipsy", "Missouri", "MO"],
"63751": ["Glenallen", "Missouri", "MO"],
"63755": ["Jackson", "Missouri", "MO"],
"63758": ["Kelso", "Missouri", "MO"],
"63760": ["Leopold", "Missouri", "MO"],
"63763": ["McGee", "Missouri", "MO"],
"63764": ["Marble Hill", "Missouri", "MO"],
"63766": ["Millersville", "Missouri", "MO"],
"63767": ["Morley", "Missouri", "MO"],
"63769": ["Oak Ridge", "Missouri", "MO"],
"63770": ["Old Appleton", "Missouri", "MO"],
"63771": ["Oran", "Missouri", "MO"],
"63774": ["Perkins", "Missouri", "MO"],
"63775": ["Perryville", "Missouri", "MO"],
"63780": ["Scott City", "Missouri", "MO"],
"63781": ["Sedgewickville", "Missouri", "MO"],
"63782": ["Sturdivant", "Missouri", "MO"],
"63783": ["Uniontown", "Missouri", "MO"],
"63784": ["Vanduser", "Missouri", "MO"],
"63785": ["Whitewater", "Missouri", "MO"],
"63787": ["Zalma", "Missouri", "MO"],
"63801": ["Sikeston", "Missouri", "MO"],
"63820": ["Anniston", "Missouri", "MO"],
"63821": ["Arbyrd", "Missouri", "MO"],
"63822": ["Bernie", "Missouri", "MO"],
"63823": ["Bertrand", "Missouri", "MO"],
"63824": ["Blodgett", "Missouri", "MO"],
"63825": ["Bloomfield", "Missouri", "MO"],
"63826": ["Braggadocio", "Missouri", "MO"],
"63827": ["Bragg City", "Missouri", "MO"],
"63828": ["Canalou", "Missouri", "MO"],
"63829": ["Cardwell", "Missouri", "MO"],
"63830": ["Caruthersville", "Missouri", "MO"],
"63833": ["Catron", "Missouri", "MO"],
"63834": ["Charleston", "Missouri", "MO"],
"63837": ["Clarkton", "Missouri", "MO"],
"63839": ["Cooter", "Missouri", "MO"],
"63840": ["Deering", "Missouri", "MO"],
"63841": ["Dexter", "Missouri", "MO"],
"63845": ["East Prairie", "Missouri", "MO"],
"63846": ["Essex", "Missouri", "MO"],
"63847": ["Gibson", "Missouri", "MO"],
"63848": ["Gideon", "Missouri", "MO"],
"63849": ["Gobler", "Missouri", "MO"],
"63850": ["Grayridge", "Missouri", "MO"],
"63851": ["Hayti", "Missouri", "MO"],
"63852": ["Holcomb", "Missouri", "MO"],
"63853": ["Holland", "Missouri", "MO"],
"63855": ["Hornersville", "Missouri", "MO"],
"63857": ["Kennett", "Missouri", "MO"],
"63860": ["Kewanee", "Missouri", "MO"],
"63862": ["Lilbourn", "Missouri", "MO"],
"63863": ["Malden", "Missouri", "MO"],
"63866": ["Marston", "Missouri", "MO"],
"63867": ["Matthews", "Missouri", "MO"],
"63868": ["Morehouse", "Missouri", "MO"],
"63869": ["New Madrid", "Missouri", "MO"],
"63870": ["Parma", "Missouri", "MO"],
"63873": ["Portageville", "Missouri", "MO"],
"63874": ["Risco", "Missouri", "MO"],
"63876": ["Senath", "Missouri", "MO"],
"63877": ["Steele", "Missouri", "MO"],
"63878": ["Tallapoosa", "Missouri", "MO"],
"63879": ["Wardell", "Missouri", "MO"],
"63880": ["Whiteoak", "Missouri", "MO"],
"63882": ["Wyatt", "Missouri", "MO"],
"63901": ["Poplar Bluff", "Missouri", "MO"],
"63932": ["Broseley", "Missouri", "MO"],
"63933": ["Campbell", "Missouri", "MO"],
"63934": ["Clubb", "Missouri", "MO"],
"63935": ["Doniphan", "Missouri", "MO"],
"63936": ["Dudley", "Missouri", "MO"],
"63937": ["Ellsinore", "Missouri", "MO"],
"63939": ["Fairdealing", "Missouri", "MO"],
"63940": ["Fisk", "Missouri", "MO"],
"63941": ["Fremont", "Missouri", "MO"],
"63942": ["Gatewood", "Missouri", "MO"],
"63943": ["Grandin", "Missouri", "MO"],
"63944": ["Greenville", "Missouri", "MO"],
"63945": ["Harviell", "Missouri", "MO"],
"63950": ["Lodi", "Missouri", "MO"],
"63951": ["Lowndes", "Missouri", "MO"],
"63952": ["Mill Spring", "Missouri", "MO"],
"63953": ["Naylor", "Missouri", "MO"],
"63954": ["Neelyville", "Missouri", "MO"],
"63955": ["Oxly", "Missouri", "MO"],
"63956": ["Patterson", "Missouri", "MO"],
"63957": ["Piedmont", "Missouri", "MO"],
"63960": ["Puxico", "Missouri", "MO"],
"63961": ["Qulin", "Missouri", "MO"],
"63962": ["Rombauer", "Missouri", "MO"],
"63964": ["Silva", "Missouri", "MO"],
"63965": ["Van Buren", "Missouri", "MO"],
"63966": ["Wappapello", "Missouri", "MO"],
"63967": ["Williamsville", "Missouri", "MO"],
"64001": ["Alma", "Missouri", "MO"],
"64011": ["Bates City", "Missouri", "MO"],
"64012": ["Belton", "Missouri", "MO"],
"64014": ["Blue Springs", "Missouri", "MO"],
"64015": ["Blue Springs", "Missouri", "MO"],
"64016": ["Buckner", "Missouri", "MO"],
"64017": ["Camden", "Missouri", "MO"],
"64018": ["Camden Point", "Missouri", "MO"],
"64019": ["Centerview", "Missouri", "MO"],
"64020": ["Concordia", "Missouri", "MO"],
"64021": ["Corder", "Missouri", "MO"],
"64022": ["Dover", "Missouri", "MO"],
"64024": ["Excelsior Springs", "Missouri", "MO"],
"64028": ["Farley", "Missouri", "MO"],
"64029": ["Grain Valley", "Missouri", "MO"],
"64030": ["Grandview", "Missouri", "MO"],
"64034": ["Greenwood", "Missouri", "MO"],
"64035": ["Hardin", "Missouri", "MO"],
"64036": ["Henrietta", "Missouri", "MO"],
"64037": ["Higginsville", "Missouri", "MO"],
"64040": ["Holden", "Missouri", "MO"],
"64048": ["Holt", "Missouri", "MO"],
"64050": ["Independence", "Missouri", "MO"],
"64052": ["Independence", "Missouri", "MO"],
"64053": ["Independence", "Missouri", "MO"],
"64054": ["Independence", "Missouri", "MO"],
"64055": ["Independence", "Missouri", "MO"],
"64056": ["Independence", "Missouri", "MO"],
"64057": ["Independence", "Missouri", "MO"],
"64058": ["Independence", "Missouri", "MO"],
"64060": ["Kearney", "Missouri", "MO"],
"64061": ["Kingsville", "Missouri", "MO"],
"64062": ["Lawson", "Missouri", "MO"],
"64063": ["Lees Summit", "Missouri", "MO"],
"64064": ["Lees Summit", "Missouri", "MO"],
"64065": ["Lees Summit", "Missouri", "MO"],
"64066": ["Levasy", "Missouri", "MO"],
"64067": ["Lexington", "Missouri", "MO"],
"64068": ["Liberty", "Missouri", "MO"],
"64070": ["Lone Jack", "Missouri", "MO"],
"64071": ["Mayview", "Missouri", "MO"],
"64072": ["Missouri City", "Missouri", "MO"],
"64074": ["Napoleon", "Missouri", "MO"],
"64075": ["Oak Grove", "Missouri", "MO"],
"64076": ["Odessa", "Missouri", "MO"],
"64077": ["Orrick", "Missouri", "MO"],
"64078": ["Peculiar", "Missouri", "MO"],
"64079": ["Platte City", "Missouri", "MO"],
"64080": ["Pleasant Hill", "Missouri", "MO"],
"64081": ["Lees Summit", "Missouri", "MO"],
"64082": ["Lees Summit", "Missouri", "MO"],
"64083": ["Raymore", "Missouri", "MO"],
"64084": ["Rayville", "Missouri", "MO"],
"64085": ["Richmond", "Missouri", "MO"],
"64086": ["Lees Summit", "Missouri", "MO"],
"64088": ["Sibley", "Missouri", "MO"],
"64089": ["Smithville", "Missouri", "MO"],
"64090": ["Strasburg", "Missouri", "MO"],
"64092": ["Waldron", "Missouri", "MO"],
"64093": ["Warrensburg", "Missouri", "MO"],
"64096": ["Waverly", "Missouri", "MO"],
"64097": ["Wellington", "Missouri", "MO"],
"64098": ["Weston", "Missouri", "MO"],
"64101": ["Kansas City", "Missouri", "MO"],
"64102": ["Kansas City", "Missouri", "MO"],
"64105": ["Kansas City", "Missouri", "MO"],
"64106": ["Kansas City", "Missouri", "MO"],
"64108": ["Kansas City", "Missouri", "MO"],
"64109": ["Kansas City", "Missouri", "MO"],
"64110": ["Kansas City", "Missouri", "MO"],
"64111": ["Kansas City", "Missouri", "MO"],
"64112": ["Kansas City", "Missouri", "MO"],
"64113": ["Kansas City", "Missouri", "MO"],
"64114": ["Kansas City", "Missouri", "MO"],
"64116": ["Kansas City", "Missouri", "MO"],
"64117": ["Kansas City", "Missouri", "MO"],
"64118": ["Kansas City", "Missouri", "MO"],
"64119": ["Kansas City", "Missouri", "MO"],
"64120": ["Kansas City", "Missouri", "MO"],
"64123": ["Kansas City", "Missouri", "MO"],
"64124": ["Kansas City", "Missouri", "MO"],
"64125": ["Kansas City", "Missouri", "MO"],
"64126": ["Kansas City", "Missouri", "MO"],
"64127": ["Kansas City", "Missouri", "MO"],
"64128": ["Kansas City", "Missouri", "MO"],
"64129": ["Kansas City", "Missouri", "MO"],
"64130": ["Kansas City", "Missouri", "MO"],
"64131": ["Kansas City", "Missouri", "MO"],
"64132": ["Kansas City", "Missouri", "MO"],
"64133": ["Kansas City", "Missouri", "MO"],
"64134": ["Kansas City", "Missouri", "MO"],
"64136": ["Kansas City", "Missouri", "MO"],
"64137": ["Kansas City", "Missouri", "MO"],
"64138": ["Kansas City", "Missouri", "MO"],
"64139": ["Kansas City", "Missouri", "MO"],
"64145": ["Kansas City", "Missouri", "MO"],
"64146": ["Kansas City", "Missouri", "MO"],
"64147": ["Kansas City", "Missouri", "MO"],
"64149": ["Kansas City", "Missouri", "MO"],
"64150": ["Riverside", "Missouri", "MO"],
"64151": ["Kansas City", "Missouri", "MO"],
"64152": ["Kansas City", "Missouri", "MO"],
"64153": ["Kansas City", "Missouri", "MO"],
"64154": ["Kansas City", "Missouri", "MO"],
"64155": ["Kansas City", "Missouri", "MO"],
"64156": ["Kansas City", "Missouri", "MO"],
"64157": ["Kansas City", "Missouri", "MO"],
"64158": ["Kansas City", "Missouri", "MO"],
"64161": ["Kansas City", "Missouri", "MO"],
"64163": ["Kansas City", "Missouri", "MO"],
"64164": ["Kansas City", "Missouri", "MO"],
"64165": ["Kansas City", "Missouri", "MO"],
"64166": ["Kansas City", "Missouri", "MO"],
"64167": ["Kansas City", "Missouri", "MO"],
"64401": ["Agency", "Missouri", "MO"],
"64402": ["Albany", "Missouri", "MO"],
"64420": ["Allendale", "Missouri", "MO"],
"64421": ["Amazonia", "Missouri", "MO"],
"64422": ["Amity", "Missouri", "MO"],
"64423": ["Barnard", "Missouri", "MO"],
"64424": ["Bethany", "Missouri", "MO"],
"64426": ["Blythedale", "Missouri", "MO"],
"64427": ["Bolckow", "Missouri", "MO"],
"64428": ["Burlington Junction", "Missouri", "MO"],
"64429": ["Cameron", "Missouri", "MO"],
"64430": ["Clarksdale", "Missouri", "MO"],
"64431": ["Clearmont", "Missouri", "MO"],
"64432": ["Clyde", "Missouri", "MO"],
"64433": ["Conception", "Missouri", "MO"],
"64434": ["Conception Junction", "Missouri", "MO"],
"64436": ["Cosby", "Missouri", "MO"],
"64437": ["Craig", "Missouri", "MO"],
"64438": ["Darlington", "Missouri", "MO"],
"64439": ["Dearborn", "Missouri", "MO"],
"64440": ["De Kalb", "Missouri", "MO"],
"64441": ["Denver", "Missouri", "MO"],
"64442": ["Eagleville", "Missouri", "MO"],
"64443": ["Easton", "Missouri", "MO"],
"64444": ["Edgerton", "Missouri", "MO"],
"64445": ["Elmo", "Missouri", "MO"],
"64446": ["Fairfax", "Missouri", "MO"],
"64448": ["Faucett", "Missouri", "MO"],
"64449": ["Fillmore", "Missouri", "MO"],
"64451": ["Forest City", "Missouri", "MO"],
"64453": ["Gentry", "Missouri", "MO"],
"64454": ["Gower", "Missouri", "MO"],
"64455": ["Graham", "Missouri", "MO"],
"64456": ["Grant City", "Missouri", "MO"],
"64457": ["Guilford", "Missouri", "MO"],
"64458": ["Hatfield", "Missouri", "MO"],
"64459": ["Helena", "Missouri", "MO"],
"64461": ["Hopkins", "Missouri", "MO"],
"64463": ["King City", "Missouri", "MO"],
"64465": ["Lathrop", "Missouri", "MO"],
"64466": ["Maitland", "Missouri", "MO"],
"64467": ["Martinsville", "Missouri", "MO"],
"64468": ["Maryville", "Missouri", "MO"],
"64469": ["Maysville", "Missouri", "MO"],
"64470": ["Mound City", "Missouri", "MO"],
"64471": ["New Hampton", "Missouri", "MO"],
"64473": ["Oregon", "Missouri", "MO"],
"64474": ["Osborn", "Missouri", "MO"],
"64475": ["Parnell", "Missouri", "MO"],
"64476": ["Pickering", "Missouri", "MO"],
"64477": ["Plattsburg", "Missouri", "MO"],
"64479": ["Ravenwood", "Missouri", "MO"],
"64480": ["Rea", "Missouri", "MO"],
"64481": ["Ridgeway", "Missouri", "MO"],
"64482": ["Rock Port", "Missouri", "MO"],
"64483": ["Rosendale", "Missouri", "MO"],
"64484": ["Rushville", "Missouri", "MO"],
"64485": ["Savannah", "Missouri", "MO"],
"64486": ["Sheridan", "Missouri", "MO"],
"64487": ["Skidmore", "Missouri", "MO"],
"64489": ["Stanberry", "Missouri", "MO"],
"64490": ["Stewartsville", "Missouri", "MO"],
"64491": ["Tarkio", "Missouri", "MO"],
"64492": ["Trimble", "Missouri", "MO"],
"64493": ["Turney", "Missouri", "MO"],
"64494": ["Union Star", "Missouri", "MO"],
"64496": ["Watson", "Missouri", "MO"],
"64497": ["Weatherby", "Missouri", "MO"],
"64498": ["Westboro", "Missouri", "MO"],
"64499": ["Worth", "Missouri", "MO"],
"64501": ["Saint Joseph", "Missouri", "MO"],
"64503": ["Saint Joseph", "Missouri", "MO"],
"64504": ["Saint Joseph", "Missouri", "MO"],
"64505": ["Saint Joseph", "Missouri", "MO"],
"64506": ["Saint Joseph", "Missouri", "MO"],
"64507": ["Saint Joseph", "Missouri", "MO"],
"64601": ["Chillicothe", "Missouri", "MO"],
"64620": ["Altamont", "Missouri", "MO"],
"64622": ["Bogard", "Missouri", "MO"],
"64623": ["Bosworth", "Missouri", "MO"],
"64624": ["Braymer", "Missouri", "MO"],
"64625": ["Breckenridge", "Missouri", "MO"],
"64628": ["Brookfield", "Missouri", "MO"],
"64630": ["Browning", "Missouri", "MO"],
"64631": ["Bucklin", "Missouri", "MO"],
"64632": ["Cainsville", "Missouri", "MO"],
"64633": ["Carrollton", "Missouri", "MO"],
"64635": ["Chula", "Missouri", "MO"],
"64636": ["Coffey", "Missouri", "MO"],
"64637": ["Cowgill", "Missouri", "MO"],
"64638": ["Dawn", "Missouri", "MO"],
"64639": ["De Witt", "Missouri", "MO"],
"64640": ["Gallatin", "Missouri", "MO"],
"64641": ["Galt", "Missouri", "MO"],
"64642": ["Gilman City", "Missouri", "MO"],
"64643": ["Hale", "Missouri", "MO"],
"64644": ["Hamilton", "Missouri", "MO"],
"64645": ["Harris", "Missouri", "MO"],
"64646": ["Humphreys", "Missouri", "MO"],
"64647": ["Jameson", "Missouri", "MO"],
"64648": ["Jamesport", "Missouri", "MO"],
"64649": ["Kidder", "Missouri", "MO"],
"64650": ["Kingston", "Missouri", "MO"],
"64651": ["Laclede", "Missouri", "MO"],
"64652": ["Laredo", "Missouri", "MO"],
"64653": ["Linneus", "Missouri", "MO"],
"64654": ["Lock Springs", "Missouri", "MO"],
"64655": ["Lucerne", "Missouri", "MO"],
"64656": ["Ludlow", "Missouri", "MO"],
"64657": ["McFall", "Missouri", "MO"],
"64658": ["Marceline", "Missouri", "MO"],
"64659": ["Meadville", "Missouri", "MO"],
"64660": ["Mendon", "Missouri", "MO"],
"64661": ["Mercer", "Missouri", "MO"],
"64664": ["Mooresville", "Missouri", "MO"],
"64667": ["Newtown", "Missouri", "MO"],
"64668": ["Norborne", "Missouri", "MO"],
"64670": ["Pattonsburg", "Missouri", "MO"],
"64671": ["Polo", "Missouri", "MO"],
"64672": ["Powersville", "Missouri", "MO"],
"64673": ["Princeton", "Missouri", "MO"],
"64674": ["Purdin", "Missouri", "MO"],
"64676": ["Rothville", "Missouri", "MO"],
"64679": ["Spickard", "Missouri", "MO"],
"64681": ["Sumner", "Missouri", "MO"],
"64682": ["Tina", "Missouri", "MO"],
"64683": ["Trenton", "Missouri", "MO"],
"64686": ["Utica", "Missouri", "MO"],
"64688": ["Wheeling", "Missouri", "MO"],
"64689": ["Winston", "Missouri", "MO"],
"64701": ["Harrisonville", "Missouri", "MO"],
"64720": ["Adrian", "Missouri", "MO"],
"64722": ["Amoret", "Missouri", "MO"],
"64723": ["Amsterdam", "Missouri", "MO"],
"64724": ["Appleton City", "Missouri", "MO"],
"64725": ["Archie", "Missouri", "MO"],
"64726": ["Blairstown", "Missouri", "MO"],
"64728": ["Bronaugh", "Missouri", "MO"],
"64730": ["Butler", "Missouri", "MO"],
"64733": ["Chilhowee", "Missouri", "MO"],
"64734": ["Cleveland", "Missouri", "MO"],
"64735": ["Clinton", "Missouri", "MO"],
"64738": ["Collins", "Missouri", "MO"],
"64739": ["Creighton", "Missouri", "MO"],
"64740": ["Deepwater", "Missouri", "MO"],
"64741": ["Deerfield", "Missouri", "MO"],
"64742": ["Drexel", "Missouri", "MO"],
"64743": ["East Lynne", "Missouri", "MO"],
"64744": ["El Dorado Springs", "Missouri", "MO"],
"64745": ["Foster", "Missouri", "MO"],
"64746": ["Freeman", "Missouri", "MO"],
"64747": ["Garden City", "Missouri", "MO"],
"64748": ["Golden City", "Missouri", "MO"],
"64750": ["Harwood", "Missouri", "MO"],
"64752": ["Hume", "Missouri", "MO"],
"64755": ["Jasper", "Missouri", "MO"],
"64756": ["Jerico Springs", "Missouri", "MO"],
"64759": ["Lamar", "Missouri", "MO"],
"64761": ["Leeton", "Missouri", "MO"],
"64762": ["Liberal", "Missouri", "MO"],
"64763": ["Lowry City", "Missouri", "MO"],
"64765": ["Metz", "Missouri", "MO"],
"64767": ["Milo", "Missouri", "MO"],
"64769": ["Mindenmines", "Missouri", "MO"],
"64770": ["Montrose", "Missouri", "MO"],
"64771": ["Moundville", "Missouri", "MO"],
"64772": ["Nevada", "Missouri", "MO"],
"64776": ["Osceola", "Missouri", "MO"],
"64778": ["Richards", "Missouri", "MO"],
"64779": ["Rich Hill", "Missouri", "MO"],
"64780": ["Rockville", "Missouri", "MO"],
"64781": ["Roscoe", "Missouri", "MO"],
"64783": ["Schell City", "Missouri", "MO"],
"64784": ["Sheldon", "Missouri", "MO"],
"64788": ["Urich", "Missouri", "MO"],
"64790": ["Walker", "Missouri", "MO"],
"64801": ["Joplin", "Missouri", "MO"],
"64804": ["Joplin", "Missouri", "MO"],
"64830": ["Alba", "Missouri", "MO"],
"64831": ["Anderson", "Missouri", "MO"],
"64832": ["Asbury", "Missouri", "MO"],
"64833": ["Avilla", "Missouri", "MO"],
"64834": ["Carl Junction", "Missouri", "MO"],
"64835": ["Carterville", "Missouri", "MO"],
"64836": ["Carthage", "Missouri", "MO"],
"64840": ["Diamond", "Missouri", "MO"],
"64841": ["Duenweg", "Missouri", "MO"],
"64842": ["Fairview", "Missouri", "MO"],
"64843": ["Goodman", "Missouri", "MO"],
"64844": ["Granby", "Missouri", "MO"],
"64847": ["Lanagan", "Missouri", "MO"],
"64848": ["La Russell", "Missouri", "MO"],
"64849": ["Neck City", "Missouri", "MO"],
"64850": ["Neosho", "Missouri", "MO"],
"64854": ["Noel", "Missouri", "MO"],
"64855": ["Oronogo", "Missouri", "MO"],
"64856": ["Pineville", "Missouri", "MO"],
"64857": ["Purcell", "Missouri", "MO"],
"64858": ["Racine", "Missouri", "MO"],
"64859": ["Reeds", "Missouri", "MO"],
"64861": ["Rocky Comfort", "Missouri", "MO"],
"64862": ["Sarcoxie", "Missouri", "MO"],
"64863": ["South West City", "Missouri", "MO"],
"64865": ["Seneca", "Missouri", "MO"],
"64866": ["Stark City", "Missouri", "MO"],
"64867": ["Stella", "Missouri", "MO"],
"64868": ["Tiff City", "Missouri", "MO"],
"64870": ["Webb City", "Missouri", "MO"],
"64873": ["Wentworth", "Missouri", "MO"],
"64874": ["Wheaton", "Missouri", "MO"],
"65001": ["Argyle", "Missouri", "MO"],
"65010": ["Ashland", "Missouri", "MO"],
"65011": ["Barnett", "Missouri", "MO"],
"65013": ["Belle", "Missouri", "MO"],
"65014": ["Bland", "Missouri", "MO"],
"65016": ["Bonnots Mill", "Missouri", "MO"],
"65017": ["Brumley", "Missouri", "MO"],
"65018": ["California", "Missouri", "MO"],
"65020": ["Camdenton", "Missouri", "MO"],
"65023": ["Centertown", "Missouri", "MO"],
"65024": ["Chamois", "Missouri", "MO"],
"65025": ["Clarksburg", "Missouri", "MO"],
"65026": ["Eldon", "Missouri", "MO"],
"65032": ["Eugene", "Missouri", "MO"],
"65034": ["Fortuna", "Missouri", "MO"],
"65035": ["Freeburg", "Missouri", "MO"],
"65037": ["Gravois Mills", "Missouri", "MO"],
"65039": ["Hartsburg", "Missouri", "MO"],
"65040": ["Henley", "Missouri", "MO"],
"65041": ["Hermann", "Missouri", "MO"],
"65043": ["Holts Summit", "Missouri", "MO"],
"65046": ["Jamestown", "Missouri", "MO"],
"65047": ["Kaiser", "Missouri", "MO"],
"65048": ["Koeltztown", "Missouri", "MO"],
"65049": ["Lake Ozark", "Missouri", "MO"],
"65050": ["Latham", "Missouri", "MO"],
"65051": ["Linn", "Missouri", "MO"],
"65052": ["Linn Creek", "Missouri", "MO"],
"65053": ["Lohman", "Missouri", "MO"],
"65054": ["Loose Creek", "Missouri", "MO"],
"65058": ["Meta", "Missouri", "MO"],
"65059": ["Mokane", "Missouri", "MO"],
"65061": ["Morrison", "Missouri", "MO"],
"65062": ["Mount Sterling", "Missouri", "MO"],
"65063": ["New Bloomfield", "Missouri", "MO"],
"65064": ["Olean", "Missouri", "MO"],
"65065": ["Osage Beach", "Missouri", "MO"],
"65066": ["Owensville", "Missouri", "MO"],
"65067": ["Portland", "Missouri", "MO"],
"65068": ["Prairie Home", "Missouri", "MO"],
"65069": ["Rhineland", "Missouri", "MO"],
"65072": ["Rocky Mount", "Missouri", "MO"],
"65074": ["Russellville", "Missouri", "MO"],
"65075": ["Saint Elizabeth", "Missouri", "MO"],
"65076": ["Saint Thomas", "Missouri", "MO"],
"65077": ["Steedman", "Missouri", "MO"],
"65078": ["Stover", "Missouri", "MO"],
"65079": ["Sunrise Beach", "Missouri", "MO"],
"65080": ["Tebbetts", "Missouri", "MO"],
"65081": ["Tipton", "Missouri", "MO"],
"65082": ["Tuscumbia", "Missouri", "MO"],
"65083": ["Ulman", "Missouri", "MO"],
"65084": ["Versailles", "Missouri", "MO"],
"65085": ["Westphalia", "Missouri", "MO"],
"65101": ["Jefferson City", "Missouri", "MO"],
"65109": ["Jefferson City", "Missouri", "MO"],
"65201": ["Columbia", "Missouri", "MO"],
"65202": ["Columbia", "Missouri", "MO"],
"65203": ["Columbia", "Missouri", "MO"],
"65215": ["Columbia", "Missouri", "MO"],
"65216": ["Columbia", "Missouri", "MO"],
"65230": ["Armstrong", "Missouri", "MO"],
"65231": ["Auxvasse", "Missouri", "MO"],
"65232": ["Benton City", "Missouri", "MO"],
"65233": ["Boonville", "Missouri", "MO"],
"65236": ["Brunswick", "Missouri", "MO"],
"65237": ["Bunceton", "Missouri", "MO"],
"65239": ["Cairo", "Missouri", "MO"],
"65240": ["Centralia", "Missouri", "MO"],
"65243": ["Clark", "Missouri", "MO"],
"65244": ["Clifton Hill", "Missouri", "MO"],
"65246": ["Dalton", "Missouri", "MO"],
"65247": ["Excello", "Missouri", "MO"],
"65248": ["Fayette", "Missouri", "MO"],
"65250": ["Franklin", "Missouri", "MO"],
"65251": ["Fulton", "Missouri", "MO"],
"65254": ["Glasgow", "Missouri", "MO"],
"65255": ["Hallsville", "Missouri", "MO"],
"65256": ["Harrisburg", "Missouri", "MO"],
"65257": ["Higbee", "Missouri", "MO"],
"65258": ["Holliday", "Missouri", "MO"],
"65259": ["Huntsville", "Missouri", "MO"],
"65260": ["Jacksonville", "Missouri", "MO"],
"65261": ["Keytesville", "Missouri", "MO"],
"65262": ["Kingdom City", "Missouri", "MO"],
"65263": ["Madison", "Missouri", "MO"],
"65264": ["Martinsburg", "Missouri", "MO"],
"65265": ["Mexico", "Missouri", "MO"],
"65270": ["Moberly", "Missouri", "MO"],
"65274": ["New Franklin", "Missouri", "MO"],
"65275": ["Paris", "Missouri", "MO"],
"65276": ["Pilot Grove", "Missouri", "MO"],
"65278": ["Renick", "Missouri", "MO"],
"65279": ["Rocheport", "Missouri", "MO"],
"65280": ["Rush Hill", "Missouri", "MO"],
"65281": ["Salisbury", "Missouri", "MO"],
"65282": ["Santa Fe", "Missouri", "MO"],
"65283": ["Stoutsville", "Missouri", "MO"],
"65284": ["Sturgeon", "Missouri", "MO"],
"65285": ["Thompson", "Missouri", "MO"],
"65286": ["Triplett", "Missouri", "MO"],
"65287": ["Wooldridge", "Missouri", "MO"],
"65301": ["Sedalia", "Missouri", "MO"],
"65305": ["Whiteman Air Force Base", "Missouri", "MO"],
"65320": ["Arrow Rock", "Missouri", "MO"],
"65321": ["Blackburn", "Missouri", "MO"],
"65322": ["Blackwater", "Missouri", "MO"],
"65323": ["Calhoun", "Missouri", "MO"],
"65324": ["Climax Springs", "Missouri", "MO"],
"65325": ["Cole Camp", "Missouri", "MO"],
"65326": ["Edwards", "Missouri", "MO"],
"65327": ["Emma", "Missouri", "MO"],
"65329": ["Florence", "Missouri", "MO"],
"65330": ["Gilliam", "Missouri", "MO"],
"65332": ["Green Ridge", "Missouri", "MO"],
"65333": ["Houstonia", "Missouri", "MO"],
"65334": ["Hughesville", "Missouri", "MO"],
"65335": ["Ionia", "Missouri", "MO"],
"65336": ["Knob Noster", "Missouri", "MO"],
"65337": ["La Monte", "Missouri", "MO"],
"65338": ["Lincoln", "Missouri", "MO"],
"65339": ["Malta Bend", "Missouri", "MO"],
"65340": ["Marshall", "Missouri", "MO"],
"65344": ["Miami", "Missouri", "MO"],
"65345": ["Mora", "Missouri", "MO"],
"65347": ["Nelson", "Missouri", "MO"],
"65348": ["Otterville", "Missouri", "MO"],
"65349": ["Slater", "Missouri", "MO"],
"65350": ["Smithton", "Missouri", "MO"],
"65351": ["Sweet Springs", "Missouri", "MO"],
"65354": ["Syracuse", "Missouri", "MO"],
"65355": ["Warsaw", "Missouri", "MO"],
"65360": ["Windsor", "Missouri", "MO"],
"65401": ["Rolla", "Missouri", "MO"],
"65409": ["Rolla", "Missouri", "MO"],
"65436": ["Beulah", "Missouri", "MO"],
"65438": ["Birch Tree", "Missouri", "MO"],
"65439": ["Bixby", "Missouri", "MO"],
"65440": ["Boss", "Missouri", "MO"],
"65441": ["Bourbon", "Missouri", "MO"],
"65443": ["Brinktown", "Missouri", "MO"],
"65444": ["Bucyrus", "Missouri", "MO"],
"65446": ["Cherryville", "Missouri", "MO"],
"65449": ["Cook Sta", "Missouri", "MO"],
"65452": ["Crocker", "Missouri", "MO"],
"65453": ["Cuba", "Missouri", "MO"],
"65456": ["Davisville", "Missouri", "MO"],
"65457": ["Devils Elbow", "Missouri", "MO"],
"65459": ["Dixon", "Missouri", "MO"],
"65461": ["Duke", "Missouri", "MO"],
"65462": ["Edgar Springs", "Missouri", "MO"],
"65463": ["Eldridge", "Missouri", "MO"],
"65464": ["Elk Creek", "Missouri", "MO"],
"65466": ["Eminence", "Missouri", "MO"],
"65468": ["Eunice", "Missouri", "MO"],
"65470": ["Falcon", "Missouri", "MO"],
"65473": ["Fort Leonard Wood", "Missouri", "MO"],
"65479": ["Hartshorn", "Missouri", "MO"],
"65483": ["Houston", "Missouri", "MO"],
"65484": ["Huggins", "Missouri", "MO"],
"65486": ["Iberia", "Missouri", "MO"],
"65501": ["Jadwin", "Missouri", "MO"],
"65529": ["Jerome", "Missouri", "MO"],
"65534": ["Laquey", "Missouri", "MO"],
"65535": ["Leasburg", "Missouri", "MO"],
"65536": ["Lebanon", "Missouri", "MO"],
"65541": ["Lenox", "Missouri", "MO"],
"65542": ["Licking", "Missouri", "MO"],
"65543": ["Lynchburg", "Missouri", "MO"],
"65548": ["Mountain View", "Missouri", "MO"],
"65550": ["Newburg", "Missouri", "MO"],
"65552": ["Plato", "Missouri", "MO"],
"65555": ["Raymondville", "Missouri", "MO"],
"65556": ["Richland", "Missouri", "MO"],
"65557": ["Roby", "Missouri", "MO"],
"65559": ["Saint James", "Missouri", "MO"],
"65560": ["Salem", "Missouri", "MO"],
"65564": ["Solo", "Missouri", "MO"],
"65565": ["Steelville", "Missouri", "MO"],
"65566": ["Viburnum", "Missouri", "MO"],
"65567": ["Stoutland", "Missouri", "MO"],
"65570": ["Success", "Missouri", "MO"],
"65571": ["Summersville", "Missouri", "MO"],
"65580": ["Vichy", "Missouri", "MO"],
"65582": ["Vienna", "Missouri", "MO"],
"65583": ["Waynesville", "Missouri", "MO"],
"65584": ["Saint Robert", "Missouri", "MO"],
"65586": ["Wesco", "Missouri", "MO"],
"65588": ["Winona", "Missouri", "MO"],
"65589": ["Yukon", "Missouri", "MO"],
"65590": ["Long Lane", "Missouri", "MO"],
"65591": ["Montreal", "Missouri", "MO"],
"65601": ["Aldrich", "Missouri", "MO"],
"65603": ["Arcola", "Missouri", "MO"],
"65604": ["Ash Grove", "Missouri", "MO"],
"65605": ["Aurora", "Missouri", "MO"],
"65606": ["Alton", "Missouri", "MO"],
"65608": ["Ava", "Missouri", "MO"],
"65609": ["Bakersfield", "Missouri", "MO"],
"65610": ["Billings", "Missouri", "MO"],
"65611": ["Blue Eye", "Missouri", "MO"],
"65612": ["Bois D Arc", "Missouri", "MO"],
"65613": ["Bolivar", "Missouri", "MO"],
"65614": ["Bradleyville", "Missouri", "MO"],
"65616": ["Branson", "Missouri", "MO"],
"65617": ["Brighton", "Missouri", "MO"],
"65618": ["Brixey", "Missouri", "MO"],
"65619": ["Brookline", "Missouri", "MO"],
"65620": ["Bruner", "Missouri", "MO"],
"65622": ["Buffalo", "Missouri", "MO"],
"65623": ["Butterfield", "Missouri", "MO"],
"65624": ["Cape Fair", "Missouri", "MO"],
"65625": ["Cassville", "Missouri", "MO"],
"65626": ["Caulfield", "Missouri", "MO"],
"65627": ["Cedarcreek", "Missouri", "MO"],
"65629": ["Chadwick", "Missouri", "MO"],
"65630": ["Chestnutridge", "Missouri", "MO"],
"65631": ["Clever", "Missouri", "MO"],
"65632": ["Conway", "Missouri", "MO"],
"65633": ["Crane", "Missouri", "MO"],
"65634": ["Cross Timbers", "Missouri", "MO"],
"65635": ["Dadeville", "Missouri", "MO"],
"65637": ["Dora", "Missouri", "MO"],
"65638": ["Drury", "Missouri", "MO"],
"65640": ["Dunnegan", "Missouri", "MO"],
"65641": ["Eagle Rock", "Missouri", "MO"],
"65644": ["Elkland", "Missouri", "MO"],
"65646": ["Everton", "Missouri", "MO"],
"65647": ["Exeter", "Missouri", "MO"],
"65648": ["Fair Grove", "Missouri", "MO"],
"65649": ["Fair Play", "Missouri", "MO"],
"65650": ["Flemington", "Missouri", "MO"],
"65652": ["Fordland", "Missouri", "MO"],
"65653": ["Forsyth", "Missouri", "MO"],
"65654": ["Freistatt", "Missouri", "MO"],
"65655": ["Gainesville", "Missouri", "MO"],
"65656": ["Galena", "Missouri", "MO"],
"65657": ["Garrison", "Missouri", "MO"],
"65658": ["Golden", "Missouri", "MO"],
"65660": ["Graff", "Missouri", "MO"],
"65661": ["Greenfield", "Missouri", "MO"],
"65662": ["Grovespring", "Missouri", "MO"],
"65663": ["Half Way", "Missouri", "MO"],
"65664": ["Halltown", "Missouri", "MO"],
"65666": ["Hardenville", "Missouri", "MO"],
"65667": ["Hartville", "Missouri", "MO"],
"65668": ["Hermitage", "Missouri", "MO"],
"65669": ["Highlandville", "Missouri", "MO"],
"65672": ["Hollister", "Missouri", "MO"],
"65674": ["Humansville", "Missouri", "MO"],
"65675": ["Hurley", "Missouri", "MO"],
"65676": ["Isabella", "Missouri", "MO"],
"65679": ["Kirbyville", "Missouri", "MO"],
"65680": ["Kissee Mills", "Missouri", "MO"],
"65681": ["Lampe", "Missouri", "MO"],
"65682": ["Lockwood", "Missouri", "MO"],
"65685": ["Louisburg", "Missouri", "MO"],
"65686": ["Kimberling City", "Missouri", "MO"],
"65688": ["Brandsville", "Missouri", "MO"],
"65689": ["Cabool", "Missouri", "MO"],
"65690": ["Couch", "Missouri", "MO"],
"65692": ["Koshkonong", "Missouri", "MO"],
"65702": ["Macomb", "Missouri", "MO"],
"65704": ["Mansfield", "Missouri", "MO"],
"65705": ["Marionville", "Missouri", "MO"],
"65706": ["Marshfield", "Missouri", "MO"],
"65707": ["Miller", "Missouri", "MO"],
"65708": ["Monett", "Missouri", "MO"],
"65710": ["Morrisville", "Missouri", "MO"],
"65711": ["Mountain Grove", "Missouri", "MO"],
"65712": ["Mount Vernon", "Missouri", "MO"],
"65713": ["Niangua", "Missouri", "MO"],
"65714": ["Nixa", "Missouri", "MO"],
"65715": ["Noble", "Missouri", "MO"],
"65717": ["Norwood", "Missouri", "MO"],
"65720": ["Oldfield", "Missouri", "MO"],
"65721": ["Ozark", "Missouri", "MO"],
"65722": ["Phillipsburg", "Missouri", "MO"],
"65723": ["Pierce City", "Missouri", "MO"],
"65724": ["Pittsburg", "Missouri", "MO"],
"65725": ["Pleasant Hope", "Missouri", "MO"],
"65726": ["Point Lookout", "Missouri", "MO"],
"65727": ["Polk", "Missouri", "MO"],
"65728": ["Ponce De Leon", "Missouri", "MO"],
"65729": ["Pontiac", "Missouri", "MO"],
"65730": ["Powell", "Missouri", "MO"],
"65731": ["Powersite", "Missouri", "MO"],
"65732": ["Preston", "Missouri", "MO"],
"65733": ["Protem", "Missouri", "MO"],
"65734": ["Purdy", "Missouri", "MO"],
"65735": ["Quincy", "Missouri", "MO"],
"65737": ["Reeds Spring", "Missouri", "MO"],
"65738": ["Republic", "Missouri", "MO"],
"65739": ["Ridgedale", "Missouri", "MO"],
"65740": ["Rockaway Beach", "Missouri", "MO"],
"65741": ["Rockbridge", "Missouri", "MO"],
"65742": ["Rogersville", "Missouri", "MO"],
"65744": ["Rueter", "Missouri", "MO"],
"65745": ["Seligman", "Missouri", "MO"],
"65746": ["Seymour", "Missouri", "MO"],
"65747": ["Shell Knob", "Missouri", "MO"],
"65752": ["South Greenfield", "Missouri", "MO"],
"65753": ["Sparta", "Missouri", "MO"],
"65754": ["Spokane", "Missouri", "MO"],
"65755": ["Squires", "Missouri", "MO"],
"65756": ["Stotts City", "Missouri", "MO"],
"65757": ["Strafford", "Missouri", "MO"],
"65759": ["Taneyville", "Missouri", "MO"],
"65760": ["Tecumseh", "Missouri", "MO"],
"65761": ["Theodosia", "Missouri", "MO"],
"65762": ["Thornfield", "Missouri", "MO"],
"65764": ["Tunas", "Missouri", "MO"],
"65766": ["Udall", "Missouri", "MO"],
"65767": ["Urbana", "Missouri", "MO"],
"65768": ["Vanzant", "Missouri", "MO"],
"65769": ["Verona", "Missouri", "MO"],
"65770": ["Walnut Grove", "Missouri", "MO"],
"65771": ["Walnut Shade", "Missouri", "MO"],
"65772": ["Washburn", "Missouri", "MO"],
"65773": ["Wasola", "Missouri", "MO"],
"65774": ["Weaubleau", "Missouri", "MO"],
"65775": ["West Plains", "Missouri", "MO"],
"65777": ["Moody", "Missouri", "MO"],
"65778": ["Myrtle", "Missouri", "MO"],
"65779": ["Wheatland", "Missouri", "MO"],
"65781": ["Willard", "Missouri", "MO"],
"65783": ["Windyville", "Missouri", "MO"],
"65784": ["Zanoni", "Missouri", "MO"],
"65785": ["Stockton", "Missouri", "MO"],
"65786": ["Macks Creek", "Missouri", "MO"],
"65787": ["Roach", "Missouri", "MO"],
"65788": ["Peace Valley", "Missouri", "MO"],
"65789": ["Pomona", "Missouri", "MO"],
"65790": ["Pottersville", "Missouri", "MO"],
"65791": ["Thayer", "Missouri", "MO"],
"65793": ["Willow Springs", "Missouri", "MO"],
"65801": ["Springfield", "Missouri", "MO"],
"65802": ["Springfield", "Missouri", "MO"],
"65803": ["Springfield", "Missouri", "MO"],
"65804": ["Springfield", "Missouri", "MO"],
"65806": ["Springfield", "Missouri", "MO"],
"65807": ["Springfield", "Missouri", "MO"],
"65809": ["Springfield", "Missouri", "MO"],
"65810": ["Springfield", "Missouri", "MO"],
"65897": ["Springfield", "Missouri", "MO"],
"66002": ["Atchison", "Kansas", "KS"],
"66006": ["Baldwin City", "Kansas", "KS"],
"66007": ["Basehor", "Kansas", "KS"],
"66008": ["Bendena", "Kansas", "KS"],
"66010": ["Blue Mound", "Kansas", "KS"],
"66012": ["Bonner Springs", "Kansas", "KS"],
"66013": ["Bucyrus", "Kansas", "KS"],
"66014": ["Centerville", "Kansas", "KS"],
"66015": ["Colony", "Kansas", "KS"],
"66016": ["Cummings", "Kansas", "KS"],
"66017": ["Denton", "Kansas", "KS"],
"66018": ["De Soto", "Kansas", "KS"],
"66020": ["Easton", "Kansas", "KS"],
"66021": ["Edgerton", "Kansas", "KS"],
"66023": ["Effingham", "Kansas", "KS"],
"66024": ["Elwood", "Kansas", "KS"],
"66025": ["Eudora", "Kansas", "KS"],
"66026": ["Fontana", "Kansas", "KS"],
"66027": ["Fort Leavenworth", "Kansas", "KS"],
"66030": ["Gardner", "Kansas", "KS"],
"66031": ["New Century", "Kansas", "KS"],
"66032": ["Garnett", "Kansas", "KS"],
"66033": ["Greeley", "Kansas", "KS"],
"66035": ["Highland", "Kansas", "KS"],
"66039": ["Kincaid", "Kansas", "KS"],
"66040": ["La Cygne", "Kansas", "KS"],
"66041": ["Lancaster", "Kansas", "KS"],
"66042": ["Lane", "Kansas", "KS"],
"66043": ["Lansing", "Kansas", "KS"],
"66044": ["Lawrence", "Kansas", "KS"],
"66045": ["Lawrence", "Kansas", "KS"],
"66046": ["Lawrence", "Kansas", "KS"],
"66047": ["Lawrence", "Kansas", "KS"],
"66048": ["Leavenworth", "Kansas", "KS"],
"66049": ["Lawrence", "Kansas", "KS"],
"66050": ["Lecompton", "Kansas", "KS"],
"66052": ["Linwood", "Kansas", "KS"],
"66053": ["Louisburg", "Kansas", "KS"],
"66054": ["McLouth", "Kansas", "KS"],
"66056": ["Mound City", "Kansas", "KS"],
"66058": ["Muscotah", "Kansas", "KS"],
"66060": ["Nortonville", "Kansas", "KS"],
"66061": ["Olathe", "Kansas", "KS"],
"66062": ["Olathe", "Kansas", "KS"],
"66064": ["Osawatomie", "Kansas", "KS"],
"66066": ["Oskaloosa", "Kansas", "KS"],
"66067": ["Ottawa", "Kansas", "KS"],
"66070": ["Ozawkie", "Kansas", "KS"],
"66071": ["Paola", "Kansas", "KS"],
"66072": ["Parker", "Kansas", "KS"],
"66073": ["Perry", "Kansas", "KS"],
"66075": ["Pleasanton", "Kansas", "KS"],
"66076": ["Pomona", "Kansas", "KS"],
"66078": ["Princeton", "Kansas", "KS"],
"66079": ["Rantoul", "Kansas", "KS"],
"66080": ["Richmond", "Kansas", "KS"],
"66083": ["Spring Hill", "Kansas", "KS"],
"66085": ["Stilwell", "Kansas", "KS"],
"66086": ["Tonganoxie", "Kansas", "KS"],
"66087": ["Troy", "Kansas", "KS"],
"66088": ["Valley Falls", "Kansas", "KS"],
"66090": ["Wathena", "Kansas", "KS"],
"66091": ["Welda", "Kansas", "KS"],
"66092": ["Wellsville", "Kansas", "KS"],
"66093": ["Westphalia", "Kansas", "KS"],
"66094": ["White Cloud", "Kansas", "KS"],
"66095": ["Williamsburg", "Kansas", "KS"],
"66097": ["Winchester", "Kansas", "KS"],
"66101": ["Kansas City", "Kansas", "KS"],
"66102": ["Kansas City", "Kansas", "KS"],
"66103": ["Kansas City", "Kansas", "KS"],
"66104": ["Kansas City", "Kansas", "KS"],
"66105": ["Kansas City", "Kansas", "KS"],
"66106": ["Kansas City", "Kansas", "KS"],
"66109": ["Kansas City", "Kansas", "KS"],
"66111": ["Kansas City", "Kansas", "KS"],
"66112": ["Kansas City", "Kansas", "KS"],
"66115": ["Kansas City", "Kansas", "KS"],
"66118": ["Kansas City", "Kansas", "KS"],
"66160": ["Kansas City", "Kansas", "KS"],
"66202": ["Mission", "Kansas", "KS"],
"66203": ["Shawnee", "Kansas", "KS"],
"66204": ["Overland Park", "Kansas", "KS"],
"66205": ["Mission", "Kansas", "KS"],
"66206": ["Leawood", "Kansas", "KS"],
"66207": ["Overland Park", "Kansas", "KS"],
"66208": ["Prairie Village", "Kansas", "KS"],
"66209": ["Leawood", "Kansas", "KS"],
"66210": ["Overland Park", "Kansas", "KS"],
"66211": ["Leawood", "Kansas", "KS"],
"66212": ["Overland Park", "Kansas", "KS"],
"66213": ["Overland Park", "Kansas", "KS"],
"66214": ["Overland Park", "Kansas", "KS"],
"66215": ["Lenexa", "Kansas", "KS"],
"66216": ["Shawnee", "Kansas", "KS"],
"66217": ["Shawnee", "Kansas", "KS"],
"66218": ["Shawnee", "Kansas", "KS"],
"66219": ["Lenexa", "Kansas", "KS"],
"66220": ["Lenexa", "Kansas", "KS"],
"66221": ["Overland Park", "Kansas", "KS"],
"66223": ["Overland Park", "Kansas", "KS"],
"66224": ["Overland Park", "Kansas", "KS"],
"66226": ["Shawnee", "Kansas", "KS"],
"66227": ["Lenexa", "Kansas", "KS"],
"66251": ["Overland Park", "Kansas", "KS"],
"66401": ["Alma", "Kansas", "KS"],
"66402": ["Auburn", "Kansas", "KS"],
"66403": ["Axtell", "Kansas", "KS"],
"66404": ["Baileyville", "Kansas", "KS"],
"66406": ["Beattie", "Kansas", "KS"],
"66407": ["Belvue", "Kansas", "KS"],
"66408": ["Bern", "Kansas", "KS"],
"66409": ["Berryton", "Kansas", "KS"],
"66411": ["Blue Rapids", "Kansas", "KS"],
"66412": ["Bremen", "Kansas", "KS"],
"66413": ["Burlingame", "Kansas", "KS"],
"66414": ["Carbondale", "Kansas", "KS"],
"66415": ["Centralia", "Kansas", "KS"],
"66416": ["Circleville", "Kansas", "KS"],
"66417": ["Corning", "Kansas", "KS"],
"66418": ["Delia", "Kansas", "KS"],
"66419": ["Denison", "Kansas", "KS"],
"66422": ["Emmett", "Kansas", "KS"],
"66423": ["Eskridge", "Kansas", "KS"],
"66424": ["Everest", "Kansas", "KS"],
"66425": ["Fairview", "Kansas", "KS"],
"66427": ["Frankfort", "Kansas", "KS"],
"66428": ["Goff", "Kansas", "KS"],
"66429": ["Grantville", "Kansas", "KS"],
"66431": ["Harveyville", "Kansas", "KS"],
"66432": ["Havensville", "Kansas", "KS"],
"66434": ["Hiawatha", "Kansas", "KS"],
"66436": ["Holton", "Kansas", "KS"],
"66438": ["Home", "Kansas", "KS"],
"66439": ["Horton", "Kansas", "KS"],
"66440": ["Hoyt", "Kansas", "KS"],
"66441": ["Junction City", "Kansas", "KS"],
"66442": ["Fort Riley", "Kansas", "KS"],
"66449": ["Leonardville", "Kansas", "KS"],
"66451": ["Lyndon", "Kansas", "KS"],
"66501": ["McFarland", "Kansas", "KS"],
"66502": ["Manhattan", "Kansas", "KS"],
"66503": ["Manhattan", "Kansas", "KS"],
"66506": ["Manhattan", "Kansas", "KS"],
"66507": ["Maple Hill", "Kansas", "KS"],
"66508": ["Marysville", "Kansas", "KS"],
"66509": ["Mayetta", "Kansas", "KS"],
"66510": ["Melvern", "Kansas", "KS"],
"66512": ["Meriden", "Kansas", "KS"],
"66514": ["Milford", "Kansas", "KS"],
"66515": ["Morrill", "Kansas", "KS"],
"66516": ["Netawaka", "Kansas", "KS"],
"66517": ["Ogden", "Kansas", "KS"],
"66518": ["Oketo", "Kansas", "KS"],
"66520": ["Olsburg", "Kansas", "KS"],
"66521": ["Onaga", "Kansas", "KS"],
"66522": ["Oneida", "Kansas", "KS"],
"66523": ["Osage City", "Kansas", "KS"],
"66524": ["Overbrook", "Kansas", "KS"],
"66526": ["Paxico", "Kansas", "KS"],
"66527": ["Powhattan", "Kansas", "KS"],
"66528": ["Quenemo", "Kansas", "KS"],
"66531": ["Riley", "Kansas", "KS"],
"66532": ["Robinson", "Kansas", "KS"],
"66533": ["Rossville", "Kansas", "KS"],
"66534": ["Sabetha", "Kansas", "KS"],
"66535": ["Saint George", "Kansas", "KS"],
"66536": ["Saint Marys", "Kansas", "KS"],
"66537": ["Scranton", "Kansas", "KS"],
"66538": ["Seneca", "Kansas", "KS"],
"66539": ["Silver Lake", "Kansas", "KS"],
"66540": ["Soldier", "Kansas", "KS"],
"66541": ["Summerfield", "Kansas", "KS"],
"66542": ["Tecumseh", "Kansas", "KS"],
"66543": ["Vassar", "Kansas", "KS"],
"66544": ["Vermillion", "Kansas", "KS"],
"66546": ["Wakarusa", "Kansas", "KS"],
"66547": ["Wamego", "Kansas", "KS"],
"66548": ["Waterville", "Kansas", "KS"],
"66549": ["Westmoreland", "Kansas", "KS"],
"66550": ["Wetmore", "Kansas", "KS"],
"66552": ["Whiting", "Kansas", "KS"],
"66554": ["Randolph", "Kansas", "KS"],
"66603": ["Topeka", "Kansas", "KS"],
"66604": ["Topeka", "Kansas", "KS"],
"66605": ["Topeka", "Kansas", "KS"],
"66606": ["Topeka", "Kansas", "KS"],
"66607": ["Topeka", "Kansas", "KS"],
"66608": ["Topeka", "Kansas", "KS"],
"66609": ["Topeka", "Kansas", "KS"],
"66610": ["Topeka", "Kansas", "KS"],
"66611": ["Topeka", "Kansas", "KS"],
"66612": ["Topeka", "Kansas", "KS"],
"66614": ["Topeka", "Kansas", "KS"],
"66615": ["Topeka", "Kansas", "KS"],
"66616": ["Topeka", "Kansas", "KS"],
"66617": ["Topeka", "Kansas", "KS"],
"66618": ["Topeka", "Kansas", "KS"],
"66619": ["Topeka", "Kansas", "KS"],
"66621": ["Topeka", "Kansas", "KS"],
"66622": ["Topeka", "Kansas", "KS"],
"66701": ["Fort Scott", "Kansas", "KS"],
"66710": ["Altoona", "Kansas", "KS"],
"66711": ["Arcadia", "Kansas", "KS"],
"66712": ["Arma", "Kansas", "KS"],
"66713": ["Baxter Springs", "Kansas", "KS"],
"66714": ["Benedict", "Kansas", "KS"],
"66716": ["Bronson", "Kansas", "KS"],
"66717": ["Buffalo", "Kansas", "KS"],
"66720": ["Chanute", "Kansas", "KS"],
"66724": ["Cherokee", "Kansas", "KS"],
"66725": ["Columbus", "Kansas", "KS"],
"66732": ["Elsmore", "Kansas", "KS"],
"66733": ["Erie", "Kansas", "KS"],
"66734": ["Farlington", "Kansas", "KS"],
"66735": ["Franklin", "Kansas", "KS"],
"66736": ["Fredonia", "Kansas", "KS"],
"66738": ["Fulton", "Kansas", "KS"],
"66739": ["Galena", "Kansas", "KS"],
"66740": ["Galesburg", "Kansas", "KS"],
"66741": ["Garland", "Kansas", "KS"],
"66742": ["Gas", "Kansas", "KS"],
"66743": ["Girard", "Kansas", "KS"],
"66746": ["Hepler", "Kansas", "KS"],
"66748": ["Humboldt", "Kansas", "KS"],
"66749": ["Iola", "Kansas", "KS"],
"66751": ["La Harpe", "Kansas", "KS"],
"66753": ["McCune", "Kansas", "KS"],
"66754": ["Mapleton", "Kansas", "KS"],
"66755": ["Moran", "Kansas", "KS"],
"66756": ["Mulberry", "Kansas", "KS"],
"66757": ["Neodesha", "Kansas", "KS"],
"66758": ["Neosho Falls", "Kansas", "KS"],
"66760": ["Opolis", "Kansas", "KS"],
"66761": ["Piqua", "Kansas", "KS"],
"66762": ["Pittsburg", "Kansas", "KS"],
"66763": ["Frontenac", "Kansas", "KS"],
"66767": ["Prescott", "Kansas", "KS"],
"66769": ["Redfield", "Kansas", "KS"],
"66770": ["Riverton", "Kansas", "KS"],
"66771": ["Saint Paul", "Kansas", "KS"],
"66772": ["Savonburg", "Kansas", "KS"],
"66773": ["Scammon", "Kansas", "KS"],
"66775": ["Stark", "Kansas", "KS"],
"66776": ["Thayer", "Kansas", "KS"],
"66777": ["Toronto", "Kansas", "KS"],
"66778": ["Treece", "Kansas", "KS"],
"66779": ["Uniontown", "Kansas", "KS"],
"66780": ["Walnut", "Kansas", "KS"],
"66781": ["Weir", "Kansas", "KS"],
"66782": ["West Mineral", "Kansas", "KS"],
"66783": ["Yates Center", "Kansas", "KS"],
"66801": ["Emporia", "Kansas", "KS"],
"66830": ["Admire", "Kansas", "KS"],
"66833": ["Allen", "Kansas", "KS"],
"66834": ["Alta Vista", "Kansas", "KS"],
"66835": ["Americus", "Kansas", "KS"],
"66838": ["Burdick", "Kansas", "KS"],
"66839": ["Burlington", "Kansas", "KS"],
"66840": ["Burns", "Kansas", "KS"],
"66842": ["Cassoday", "Kansas", "KS"],
"66843": ["Cedar Point", "Kansas", "KS"],
"66845": ["Cottonwood Falls", "Kansas", "KS"],
"66846": ["Council Grove", "Kansas", "KS"],
"66849": ["Dwight", "Kansas", "KS"],
"66850": ["Elmdale", "Kansas", "KS"],
"66851": ["Florence", "Kansas", "KS"],
"66852": ["Gridley", "Kansas", "KS"],
"66853": ["Hamilton", "Kansas", "KS"],
"66854": ["Hartford", "Kansas", "KS"],
"66856": ["Lebo", "Kansas", "KS"],
"66857": ["Le Roy", "Kansas", "KS"],
"66858": ["Lincolnville", "Kansas", "KS"],
"66859": ["Lost Springs", "Kansas", "KS"],
"66860": ["Madison", "Kansas", "KS"],
"66861": ["Marion", "Kansas", "KS"],
"66862": ["Matfield Green", "Kansas", "KS"],
"66863": ["Neal", "Kansas", "KS"],
"66864": ["Neosho Rapids", "Kansas", "KS"],
"66865": ["Olpe", "Kansas", "KS"],
"66866": ["Peabody", "Kansas", "KS"],
"66868": ["Reading", "Kansas", "KS"],
"66869": ["Strong City", "Kansas", "KS"],
"66870": ["Virgil", "Kansas", "KS"],
"66871": ["Waverly", "Kansas", "KS"],
"66872": ["White City", "Kansas", "KS"],
"66873": ["Wilsey", "Kansas", "KS"],
"66901": ["Concordia", "Kansas", "KS"],
"66930": ["Agenda", "Kansas", "KS"],
"66932": ["Athol", "Kansas", "KS"],
"66933": ["Barnes", "Kansas", "KS"],
"66935": ["Belleville", "Kansas", "KS"],
"66936": ["Burr Oak", "Kansas", "KS"],
"66937": ["Clifton", "Kansas", "KS"],
"66938": ["Clyde", "Kansas", "KS"],
"66939": ["Courtland", "Kansas", "KS"],
"66940": ["Cuba", "Kansas", "KS"],
"66941": ["Esbon", "Kansas", "KS"],
"66942": ["Formoso", "Kansas", "KS"],
"66943": ["Greenleaf", "Kansas", "KS"],
"66944": ["Haddam", "Kansas", "KS"],
"66945": ["Hanover", "Kansas", "KS"],
"66946": ["Hollenberg", "Kansas", "KS"],
"66948": ["Jamestown", "Kansas", "KS"],
"66949": ["Jewell", "Kansas", "KS"],
"66951": ["Kensington", "Kansas", "KS"],
"66952": ["Lebanon", "Kansas", "KS"],
"66953": ["Linn", "Kansas", "KS"],
"66955": ["Mahaska", "Kansas", "KS"],
"66956": ["Mankato", "Kansas", "KS"],
"66958": ["Morrowville", "Kansas", "KS"],
"66959": ["Munden", "Kansas", "KS"],
"66960": ["Narka", "Kansas", "KS"],
"66961": ["Norway", "Kansas", "KS"],
"66962": ["Palmer", "Kansas", "KS"],
"66963": ["Randall", "Kansas", "KS"],
"66964": ["Republic", "Kansas", "KS"],
"66966": ["Scandia", "Kansas", "KS"],
"66967": ["Smith Center", "Kansas", "KS"],
"66968": ["Washington", "Kansas", "KS"],
"66970": ["Webber", "Kansas", "KS"],
"67001": ["Andale", "Kansas", "KS"],
"67002": ["Andover", "Kansas", "KS"],
"67003": ["Anthony", "Kansas", "KS"],
"67004": ["Argonia", "Kansas", "KS"],
"67005": ["Arkansas City", "Kansas", "KS"],
"67008": ["Atlanta", "Kansas", "KS"],
"67009": ["Attica", "Kansas", "KS"],
"67010": ["Augusta", "Kansas", "KS"],
"67012": ["Beaumont", "Kansas", "KS"],
"67013": ["Belle Plaine", "Kansas", "KS"],
"67016": ["Bentley", "Kansas", "KS"],
"67017": ["Benton", "Kansas", "KS"],
"67018": ["Bluff City", "Kansas", "KS"],
"67019": ["Burden", "Kansas", "KS"],
"67020": ["Burrton", "Kansas", "KS"],
"67021": ["Byers", "Kansas", "KS"],
"67022": ["Caldwell", "Kansas", "KS"],
"67023": ["Cambridge", "Kansas", "KS"],
"67024": ["Cedar Vale", "Kansas", "KS"],
"67025": ["Cheney", "Kansas", "KS"],
"67026": ["Clearwater", "Kansas", "KS"],
"67028": ["Coats", "Kansas", "KS"],
"67029": ["Coldwater", "Kansas", "KS"],
"67030": ["Colwich", "Kansas", "KS"],
"67031": ["Conway Springs", "Kansas", "KS"],
"67035": ["Cunningham", "Kansas", "KS"],
"67036": ["Danville", "Kansas", "KS"],
"67037": ["Derby", "Kansas", "KS"],
"67038": ["Dexter", "Kansas", "KS"],
"67039": ["Douglass", "Kansas", "KS"],
"67041": ["Elbing", "Kansas", "KS"],
"67042": ["El Dorado", "Kansas", "KS"],
"67045": ["Eureka", "Kansas", "KS"],
"67047": ["Fall River", "Kansas", "KS"],
"67049": ["Freeport", "Kansas", "KS"],
"67050": ["Garden Plain", "Kansas", "KS"],
"67051": ["Geuda Springs", "Kansas", "KS"],
"67052": ["Goddard", "Kansas", "KS"],
"67053": ["Goessel", "Kansas", "KS"],
"67054": ["Greensburg", "Kansas", "KS"],
"67055": ["Greenwich", "Kansas", "KS"],
"67056": ["Halstead", "Kansas", "KS"],
"67057": ["Hardtner", "Kansas", "KS"],
"67058": ["Harper", "Kansas", "KS"],
"67059": ["Haviland", "Kansas", "KS"],
"67060": ["Haysville", "Kansas", "KS"],
"67061": ["Hazelton", "Kansas", "KS"],
"67062": ["Hesston", "Kansas", "KS"],
"67063": ["Hillsboro", "Kansas", "KS"],
"67065": ["Isabel", "Kansas", "KS"],
"67066": ["Iuka", "Kansas", "KS"],
"67067": ["Kechi", "Kansas", "KS"],
"67068": ["Kingman", "Kansas", "KS"],
"67070": ["Kiowa", "Kansas", "KS"],
"67071": ["Lake City", "Kansas", "KS"],
"67072": ["Latham", "Kansas", "KS"],
"67073": ["Lehigh", "Kansas", "KS"],
"67074": ["Leon", "Kansas", "KS"],
"67101": ["Maize", "Kansas", "KS"],
"67102": ["Maple City", "Kansas", "KS"],
"67103": ["Mayfield", "Kansas", "KS"],
"67104": ["Medicine Lodge", "Kansas", "KS"],
"67105": ["Milan", "Kansas", "KS"],
"67106": ["Milton", "Kansas", "KS"],
"67107": ["Moundridge", "Kansas", "KS"],
"67108": ["Mount Hope", "Kansas", "KS"],
"67109": ["Mullinville", "Kansas", "KS"],
"67110": ["Mulvane", "Kansas", "KS"],
"67111": ["Murdock", "Kansas", "KS"],
"67112": ["Nashville", "Kansas", "KS"],
"67114": ["Newton", "Kansas", "KS"],
"67117": ["North Newton", "Kansas", "KS"],
"67118": ["Norwich", "Kansas", "KS"],
"67119": ["Oxford", "Kansas", "KS"],
"67120": ["Peck", "Kansas", "KS"],
"67122": ["Piedmont", "Kansas", "KS"],
"67123": ["Potwin", "Kansas", "KS"],
"67124": ["Pratt", "Kansas", "KS"],
"67127": ["Protection", "Kansas", "KS"],
"67131": ["Rock", "Kansas", "KS"],
"67132": ["Rosalia", "Kansas", "KS"],
"67133": ["Rose Hill", "Kansas", "KS"],
"67134": ["Sawyer", "Kansas", "KS"],
"67135": ["Sedgwick", "Kansas", "KS"],
"67137": ["Severy", "Kansas", "KS"],
"67138": ["Sharon", "Kansas", "KS"],
"67140": ["South Haven", "Kansas", "KS"],
"67142": ["Spivey", "Kansas", "KS"],
"67143": ["Sun City", "Kansas", "KS"],
"67144": ["Towanda", "Kansas", "KS"],
"67146": ["Udall", "Kansas", "KS"],
"67147": ["Valley Center", "Kansas", "KS"],
"67149": ["Viola", "Kansas", "KS"],
"67150": ["Waldron", "Kansas", "KS"],
"67151": ["Walton", "Kansas", "KS"],
"67152": ["Wellington", "Kansas", "KS"],
"67154": ["Whitewater", "Kansas", "KS"],
"67155": ["Wilmore", "Kansas", "KS"],
"67156": ["Winfield", "Kansas", "KS"],
"67159": ["Zenda", "Kansas", "KS"],
"67202": ["Wichita", "Kansas", "KS"],
"67203": ["Wichita", "Kansas", "KS"],
"67204": ["Wichita", "Kansas", "KS"],
"67205": ["Wichita", "Kansas", "KS"],
"67206": ["Wichita", "Kansas", "KS"],
"67207": ["Wichita", "Kansas", "KS"],
"67208": ["Wichita", "Kansas", "KS"],
"67209": ["Wichita", "Kansas", "KS"],
"67210": ["Wichita", "Kansas", "KS"],
"67211": ["Wichita", "Kansas", "KS"],
"67212": ["Wichita", "Kansas", "KS"],
"67213": ["Wichita", "Kansas", "KS"],
"67214": ["Wichita", "Kansas", "KS"],
"67215": ["Wichita", "Kansas", "KS"],
"67216": ["Wichita", "Kansas", "KS"],
"67217": ["Wichita", "Kansas", "KS"],
"67218": ["Wichita", "Kansas", "KS"],
"67219": ["Wichita", "Kansas", "KS"],
"67220": ["Wichita", "Kansas", "KS"],
"67221": ["Mcconnell Afb", "Kansas", "KS"],
"67223": ["Wichita", "Kansas", "KS"],
"67226": ["Wichita", "Kansas", "KS"],
"67227": ["Wichita", "Kansas", "KS"],
"67228": ["Wichita", "Kansas", "KS"],
"67230": ["Wichita", "Kansas", "KS"],
"67232": ["Wichita", "Kansas", "KS"],
"67235": ["Wichita", "Kansas", "KS"],
"67260": ["Wichita", "Kansas", "KS"],
"67301": ["Independence", "Kansas", "KS"],
"67330": ["Altamont", "Kansas", "KS"],
"67332": ["Bartlett", "Kansas", "KS"],
"67333": ["Caney", "Kansas", "KS"],
"67334": ["Chautauqua", "Kansas", "KS"],
"67335": ["Cherryvale", "Kansas", "KS"],
"67336": ["Chetopa", "Kansas", "KS"],
"67337": ["Coffeyville", "Kansas", "KS"],
"67340": ["Dearing", "Kansas", "KS"],
"67341": ["Dennis", "Kansas", "KS"],
"67342": ["Edna", "Kansas", "KS"],
"67344": ["Elk City", "Kansas", "KS"],
"67345": ["Elk Falls", "Kansas", "KS"],
"67346": ["Grenola", "Kansas", "KS"],
"67347": ["Havana", "Kansas", "KS"],
"67349": ["Howard", "Kansas", "KS"],
"67351": ["Liberty", "Kansas", "KS"],
"67352": ["Longton", "Kansas", "KS"],
"67353": ["Moline", "Kansas", "KS"],
"67354": ["Mound Valley", "Kansas", "KS"],
"67355": ["Niotaze", "Kansas", "KS"],
"67356": ["Oswego", "Kansas", "KS"],
"67357": ["Parsons", "Kansas", "KS"],
"67360": ["Peru", "Kansas", "KS"],
"67361": ["Sedan", "Kansas", "KS"],
"67363": ["Sycamore", "Kansas", "KS"],
"67364": ["Tyro", "Kansas", "KS"],
"67401": ["Salina", "Kansas", "KS"],
"67410": ["Abilene", "Kansas", "KS"],
"67416": ["Assaria", "Kansas", "KS"],
"67417": ["Aurora", "Kansas", "KS"],
"67418": ["Barnard", "Kansas", "KS"],
"67420": ["Beloit", "Kansas", "KS"],
"67422": ["Bennington", "Kansas", "KS"],
"67423": ["Beverly", "Kansas", "KS"],
"67425": ["Brookville", "Kansas", "KS"],
"67427": ["Bushton", "Kansas", "KS"],
"67428": ["Canton", "Kansas", "KS"],
"67430": ["Cawker City", "Kansas", "KS"],
"67431": ["Chapman", "Kansas", "KS"],
"67432": ["Clay Center", "Kansas", "KS"],
"67436": ["Delphos", "Kansas", "KS"],
"67437": ["Downs", "Kansas", "KS"],
"67438": ["Durham", "Kansas", "KS"],
"67439": ["Ellsworth", "Kansas", "KS"],
"67441": ["Enterprise", "Kansas", "KS"],
"67442": ["Falun", "Kansas", "KS"],
"67443": ["Galva", "Kansas", "KS"],
"67444": ["Geneseo", "Kansas", "KS"],
"67445": ["Glasco", "Kansas", "KS"],
"67446": ["Glen Elder", "Kansas", "KS"],
"67447": ["Green", "Kansas", "KS"],
"67448": ["Gypsum", "Kansas", "KS"],
"67449": ["Herington", "Kansas", "KS"],
"67450": ["Holyrood", "Kansas", "KS"],
"67451": ["Hope", "Kansas", "KS"],
"67452": ["Hunter", "Kansas", "KS"],
"67454": ["Kanopolis", "Kansas", "KS"],
"67455": ["Lincoln", "Kansas", "KS"],
"67456": ["Lindsborg", "Kansas", "KS"],
"67457": ["Little River", "Kansas", "KS"],
"67458": ["Longford", "Kansas", "KS"],
"67459": ["Lorraine", "Kansas", "KS"],
"67460": ["Mcpherson", "Kansas", "KS"],
"67464": ["Marquette", "Kansas", "KS"],
"67466": ["Miltonvale", "Kansas", "KS"],
"67467": ["Minneapolis", "Kansas", "KS"],
"67468": ["Morganville", "Kansas", "KS"],
"67470": ["New Cambria", "Kansas", "KS"],
"67473": ["Osborne", "Kansas", "KS"],
"67474": ["Portis", "Kansas", "KS"],
"67475": ["Ramona", "Kansas", "KS"],
"67476": ["Roxbury", "Kansas", "KS"],
"67478": ["Simpson", "Kansas", "KS"],
"67480": ["Solomon", "Kansas", "KS"],
"67481": ["Sylvan Grove", "Kansas", "KS"],
"67482": ["Talmage", "Kansas", "KS"],
"67483": ["Tampa", "Kansas", "KS"],
"67484": ["Tescott", "Kansas", "KS"],
"67485": ["Tipton", "Kansas", "KS"],
"67487": ["Wakefield", "Kansas", "KS"],
"67490": ["Wilson", "Kansas", "KS"],
"67491": ["Windom", "Kansas", "KS"],
"67492": ["Woodbine", "Kansas", "KS"],
"67501": ["Hutchinson", "Kansas", "KS"],
"67502": ["Hutchinson", "Kansas", "KS"],
"67505": ["South Hutchinson", "Kansas", "KS"],
"67510": ["Abbyville", "Kansas", "KS"],
"67511": ["Albert", "Kansas", "KS"],
"67512": ["Alden", "Kansas", "KS"],
"67513": ["Alexander", "Kansas", "KS"],
"67514": ["Arlington", "Kansas", "KS"],
"67515": ["Arnold", "Kansas", "KS"],
"67516": ["Bazine", "Kansas", "KS"],
"67518": ["Beeler", "Kansas", "KS"],
"67519": ["Belpre", "Kansas", "KS"],
"67520": ["Bison", "Kansas", "KS"],
"67521": ["Brownell", "Kansas", "KS"],
"67522": ["Buhler", "Kansas", "KS"],
"67523": ["Burdett", "Kansas", "KS"],
"67524": ["Chase", "Kansas", "KS"],
"67525": ["Claflin", "Kansas", "KS"],
"67526": ["Ellinwood", "Kansas", "KS"],
"67529": ["Garfield", "Kansas", "KS"],
"67530": ["Great Bend", "Kansas", "KS"],
"67543": ["Haven", "Kansas", "KS"],
"67544": ["Hoisington", "Kansas", "KS"],
"67545": ["Hudson", "Kansas", "KS"],
"67546": ["Inman", "Kansas", "KS"],
"67547": ["Kinsley", "Kansas", "KS"],
"67548": ["La Crosse", "Kansas", "KS"],
"67550": ["Larned", "Kansas", "KS"],
"67552": ["Lewis", "Kansas", "KS"],
"67553": ["Liebenthal", "Kansas", "KS"],
"67554": ["Lyons", "Kansas", "KS"],
"67556": ["McCracken", "Kansas", "KS"],
"67557": ["Macksville", "Kansas", "KS"],
"67559": ["Nekoma", "Kansas", "KS"],
"67560": ["Ness City", "Kansas", "KS"],
"67561": ["Nickerson", "Kansas", "KS"],
"67563": ["Offerle", "Kansas", "KS"],
"67564": ["Olmitz", "Kansas", "KS"],
"67565": ["Otis", "Kansas", "KS"],
"67566": ["Partridge", "Kansas", "KS"],
"67567": ["Pawnee Rock", "Kansas", "KS"],
"67568": ["Plevna", "Kansas", "KS"],
"67570": ["Pretty Prairie", "Kansas", "KS"],
"67572": ["Ransom", "Kansas", "KS"],
"67573": ["Raymond", "Kansas", "KS"],
"67574": ["Rozel", "Kansas", "KS"],
"67575": ["Rush Center", "Kansas", "KS"],
"67576": ["St John", "Kansas", "KS"],
"67578": ["Stafford", "Kansas", "KS"],
"67579": ["Sterling", "Kansas", "KS"],
"67581": ["Sylvia", "Kansas", "KS"],
"67583": ["Turon", "Kansas", "KS"],
"67584": ["Utica", "Kansas", "KS"],
"67601": ["Hays", "Kansas", "KS"],
"67621": ["Agra", "Kansas", "KS"],
"67622": ["Almena", "Kansas", "KS"],
"67623": ["Alton", "Kansas", "KS"],
"67625": ["Bogue", "Kansas", "KS"],
"67626": ["Bunker Hill", "Kansas", "KS"],
"67627": ["Catharine", "Kansas", "KS"],
"67628": ["Cedar", "Kansas", "KS"],
"67629": ["Clayton", "Kansas", "KS"],
"67631": ["Collyer", "Kansas", "KS"],
"67632": ["Damar", "Kansas", "KS"],
"67634": ["Dorrance", "Kansas", "KS"],
"67635": ["Dresden", "Kansas", "KS"],
"67637": ["Ellis", "Kansas", "KS"],
"67638": ["Gaylord", "Kansas", "KS"],
"67639": ["Glade", "Kansas", "KS"],
"67640": ["Gorham", "Kansas", "KS"],
"67642": ["Hill City", "Kansas", "KS"],
"67643": ["Jennings", "Kansas", "KS"],
"67644": ["Kirwin", "Kansas", "KS"],
"67645": ["Lenora", "Kansas", "KS"],
"67646": ["Logan", "Kansas", "KS"],
"67647": ["Long Island", "Kansas", "KS"],
"67648": ["Lucas", "Kansas", "KS"],
"67649": ["Luray", "Kansas", "KS"],
"67650": ["Morland", "Kansas", "KS"],
"67651": ["Natoma", "Kansas", "KS"],
"67653": ["Norcatur", "Kansas", "KS"],
"67654": ["Norton", "Kansas", "KS"],
"67656": ["Ogallah", "Kansas", "KS"],
"67657": ["Palco", "Kansas", "KS"],
"67658": ["Paradise", "Kansas", "KS"],
"67659": ["Penokee", "Kansas", "KS"],
"67660": ["Pfeifer", "Kansas", "KS"],
"67661": ["Phillipsburg", "Kansas", "KS"],
"67663": ["Plainville", "Kansas", "KS"],
"67664": ["Prairie View", "Kansas", "KS"],
"67665": ["Russell", "Kansas", "KS"],
"67667": ["Schoenchen", "Kansas", "KS"],
"67669": ["Stockton", "Kansas", "KS"],
"67671": ["Victoria", "Kansas", "KS"],
"67672": ["Wakeeney", "Kansas", "KS"],
"67673": ["Waldo", "Kansas", "KS"],
"67674": ["Walker", "Kansas", "KS"],
"67675": ["Woodston", "Kansas", "KS"],
"67701": ["Colby", "Kansas", "KS"],
"67730": ["Atwood", "Kansas", "KS"],
"67731": ["Bird City", "Kansas", "KS"],
"67732": ["Brewster", "Kansas", "KS"],
"67733": ["Edson", "Kansas", "KS"],
"67734": ["Gem", "Kansas", "KS"],
"67735": ["Goodland", "Kansas", "KS"],
"67736": ["Gove", "Kansas", "KS"],
"67737": ["Grainfield", "Kansas", "KS"],
"67738": ["Grinnell", "Kansas", "KS"],
"67739": ["Herndon", "Kansas", "KS"],
"67740": ["Hoxie", "Kansas", "KS"],
"67741": ["Kanorado", "Kansas", "KS"],
"67743": ["Levant", "Kansas", "KS"],
"67744": ["Ludell", "Kansas", "KS"],
"67745": ["McDonald", "Kansas", "KS"],
"67747": ["Monument", "Kansas", "KS"],
"67748": ["Oakley", "Kansas", "KS"],
"67749": ["Oberlin", "Kansas", "KS"],
"67751": ["Park", "Kansas", "KS"],
"67752": ["Quinter", "Kansas", "KS"],
"67753": ["Rexford", "Kansas", "KS"],
"67756": ["Saint Francis", "Kansas", "KS"],
"67757": ["Selden", "Kansas", "KS"],
"67758": ["Sharon Springs", "Kansas", "KS"],
"67761": ["Wallace", "Kansas", "KS"],
"67762": ["Weskan", "Kansas", "KS"],
"67764": ["Winona", "Kansas", "KS"],
"67801": ["Dodge City", "Kansas", "KS"],
"67831": ["Ashland", "Kansas", "KS"],
"67834": ["Bucklin", "Kansas", "KS"],
"67835": ["Cimarron", "Kansas", "KS"],
"67836": ["Coolidge", "Kansas", "KS"],
"67837": ["Copeland", "Kansas", "KS"],
"67838": ["Deerfield", "Kansas", "KS"],
"67839": ["Dighton", "Kansas", "KS"],
"67840": ["Englewood", "Kansas", "KS"],
"67841": ["Ensign", "Kansas", "KS"],
"67842": ["Ford", "Kansas", "KS"],
"67843": ["Fort Dodge", "Kansas", "KS"],
"67844": ["Fowler", "Kansas", "KS"],
"67846": ["Garden City", "Kansas", "KS"],
"67849": ["Hanston", "Kansas", "KS"],
"67850": ["Healy", "Kansas", "KS"],
"67851": ["Holcomb", "Kansas", "KS"],
"67853": ["Ingalls", "Kansas", "KS"],
"67854": ["Jetmore", "Kansas", "KS"],
"67855": ["Johnson", "Kansas", "KS"],
"67857": ["Kendall", "Kansas", "KS"],
"67859": ["Kismet", "Kansas", "KS"],
"67860": ["Lakin", "Kansas", "KS"],
"67861": ["Leoti", "Kansas", "KS"],
"67862": ["Manter", "Kansas", "KS"],
"67863": ["Marienthal", "Kansas", "KS"],
"67864": ["Meade", "Kansas", "KS"],
"67865": ["Minneola", "Kansas", "KS"],
"67867": ["Montezuma", "Kansas", "KS"],
"67868": ["Pierceville", "Kansas", "KS"],
"67869": ["Plains", "Kansas", "KS"],
"67870": ["Satanta", "Kansas", "KS"],
"67871": ["Scott City", "Kansas", "KS"],
"67876": ["Spearville", "Kansas", "KS"],
"67877": ["Sublette", "Kansas", "KS"],
"67878": ["Syracuse", "Kansas", "KS"],
"67879": ["Tribune", "Kansas", "KS"],
"67880": ["Ulysses", "Kansas", "KS"],
"67882": ["Wright", "Kansas", "KS"],
"67901": ["Liberal", "Kansas", "KS"],
"67950": ["Elkhart", "Kansas", "KS"],
"67951": ["Hugoton", "Kansas", "KS"],
"67952": ["Moscow", "Kansas", "KS"],
"67953": ["Richfield", "Kansas", "KS"],
"67954": ["Rolla", "Kansas", "KS"],
"68001": ["Abie", "Nebraska", "NE"],
"68002": ["Arlington", "Nebraska", "NE"],
"68003": ["Ashland", "Nebraska", "NE"],
"68004": ["Bancroft", "Nebraska", "NE"],
"68005": ["Bellevue", "Nebraska", "NE"],
"68007": ["Bennington", "Nebraska", "NE"],
"68008": ["Blair", "Nebraska", "NE"],
"68010": ["Boys Town", "Nebraska", "NE"],
"68014": ["Bruno", "Nebraska", "NE"],
"68015": ["Cedar Bluffs", "Nebraska", "NE"],
"68016": ["Cedar Creek", "Nebraska", "NE"],
"68017": ["Ceresco", "Nebraska", "NE"],
"68018": ["Colon", "Nebraska", "NE"],
"68019": ["Craig", "Nebraska", "NE"],
"68020": ["Decatur", "Nebraska", "NE"],
"68022": ["Elkhorn", "Nebraska", "NE"],
"68023": ["Fort Calhoun", "Nebraska", "NE"],
"68025": ["Fremont", "Nebraska", "NE"],
"68028": ["Gretna", "Nebraska", "NE"],
"68029": ["Herman", "Nebraska", "NE"],
"68030": ["Homer", "Nebraska", "NE"],
"68031": ["Hooper", "Nebraska", "NE"],
"68033": ["Ithaca", "Nebraska", "NE"],
"68034": ["Kennard", "Nebraska", "NE"],
"68036": ["Linwood", "Nebraska", "NE"],
"68037": ["Louisville", "Nebraska", "NE"],
"68038": ["Lyons", "Nebraska", "NE"],
"68039": ["Macy", "Nebraska", "NE"],
"68040": ["Malmo", "Nebraska", "NE"],
"68041": ["Mead", "Nebraska", "NE"],
"68042": ["Memphis", "Nebraska", "NE"],
"68044": ["Nickerson", "Nebraska", "NE"],
"68045": ["Oakland", "Nebraska", "NE"],
"68046": ["Papillion", "Nebraska", "NE"],
"68047": ["Pender", "Nebraska", "NE"],
"68048": ["Plattsmouth", "Nebraska", "NE"],
"68050": ["Prague", "Nebraska", "NE"],
"68055": ["Rosalie", "Nebraska", "NE"],
"68056": ["St Columbans", "Nebraska", "NE"],
"68057": ["Scribner", "Nebraska", "NE"],
"68058": ["South Bend", "Nebraska", "NE"],
"68059": ["Springfield", "Nebraska", "NE"],
"68061": ["Tekamah", "Nebraska", "NE"],
"68062": ["Thurston", "Nebraska", "NE"],
"68063": ["Uehling", "Nebraska", "NE"],
"68064": ["Valley", "Nebraska", "NE"],
"68065": ["Valparaiso", "Nebraska", "NE"],
"68066": ["Wahoo", "Nebraska", "NE"],
"68067": ["Walthill", "Nebraska", "NE"],
"68068": ["Washington", "Nebraska", "NE"],
"68069": ["Waterloo", "Nebraska", "NE"],
"68070": ["Weston", "Nebraska", "NE"],
"68071": ["Winnebago", "Nebraska", "NE"],
"68072": ["Winslow", "Nebraska", "NE"],
"68073": ["Yutan", "Nebraska", "NE"],
"68102": ["Omaha", "Nebraska", "NE"],
"68104": ["Omaha", "Nebraska", "NE"],
"68105": ["Omaha", "Nebraska", "NE"],
"68106": ["Omaha", "Nebraska", "NE"],
"68107": ["Omaha", "Nebraska", "NE"],
"68108": ["Omaha", "Nebraska", "NE"],
"68110": ["Omaha", "Nebraska", "NE"],
"68111": ["Omaha", "Nebraska", "NE"],
"68112": ["Omaha", "Nebraska", "NE"],
"68113": ["Offutt Afb", "Nebraska", "NE"],
"68114": ["Omaha", "Nebraska", "NE"],
"68116": ["Omaha", "Nebraska", "NE"],
"68117": ["Omaha", "Nebraska", "NE"],
"68118": ["Omaha", "Nebraska", "NE"],
"68122": ["Omaha", "Nebraska", "NE"],
"68123": ["Bellevue", "Nebraska", "NE"],
"68124": ["Omaha", "Nebraska", "NE"],
"68127": ["Omaha", "Nebraska", "NE"],
"68128": ["La Vista", "Nebraska", "NE"],
"68130": ["Omaha", "Nebraska", "NE"],
"68131": ["Omaha", "Nebraska", "NE"],
"68132": ["Omaha", "Nebraska", "NE"],
"68133": ["Papillion", "Nebraska", "NE"],
"68134": ["Omaha", "Nebraska", "NE"],
"68135": ["Omaha", "Nebraska", "NE"],
"68136": ["Omaha", "Nebraska", "NE"],
"68137": ["Omaha", "Nebraska", "NE"],
"68138": ["Omaha", "Nebraska", "NE"],
"68142": ["Omaha", "Nebraska", "NE"],
"68144": ["Omaha", "Nebraska", "NE"],
"68147": ["Bellevue", "Nebraska", "NE"],
"68152": ["Omaha", "Nebraska", "NE"],
"68154": ["Omaha", "Nebraska", "NE"],
"68157": ["Omaha", "Nebraska", "NE"],
"68164": ["Omaha", "Nebraska", "NE"],
"68178": ["Omaha", "Nebraska", "NE"],
"68182": ["Omaha", "Nebraska", "NE"],
"68183": ["Omaha", "Nebraska", "NE"],
"68301": ["Adams", "Nebraska", "NE"],
"68303": ["Alexandria", "Nebraska", "NE"],
"68304": ["Alvo", "Nebraska", "NE"],
"68305": ["Auburn", "Nebraska", "NE"],
"68307": ["Avoca", "Nebraska", "NE"],
"68309": ["Barneston", "Nebraska", "NE"],
"68310": ["Beatrice", "Nebraska", "NE"],
"68313": ["Beaver Crossing", "Nebraska", "NE"],
"68314": ["Bee", "Nebraska", "NE"],
"68315": ["Belvidere", "Nebraska", "NE"],
"68316": ["Benedict", "Nebraska", "NE"],
"68317": ["Bennet", "Nebraska", "NE"],
"68318": ["Blue Springs", "Nebraska", "NE"],
"68319": ["Bradshaw", "Nebraska", "NE"],
"68320": ["Brock", "Nebraska", "NE"],
"68321": ["Brownville", "Nebraska", "NE"],
"68322": ["Bruning", "Nebraska", "NE"],
"68323": ["Burchard", "Nebraska", "NE"],
"68324": ["Burr", "Nebraska", "NE"],
"68325": ["Byron", "Nebraska", "NE"],
"68326": ["Carleton", "Nebraska", "NE"],
"68327": ["Chester", "Nebraska", "NE"],
"68328": ["Clatonia", "Nebraska", "NE"],
"68329": ["Cook", "Nebraska", "NE"],
"68330": ["Cordova", "Nebraska", "NE"],
"68331": ["Cortland", "Nebraska", "NE"],
"68332": ["Crab Orchard", "Nebraska", "NE"],
"68333": ["Crete", "Nebraska", "NE"],
"68335": ["Davenport", "Nebraska", "NE"],
"68336": ["Davey", "Nebraska", "NE"],
"68337": ["Dawson", "Nebraska", "NE"],
"68338": ["Daykin", "Nebraska", "NE"],
"68339": ["Denton", "Nebraska", "NE"],
"68340": ["Deshler", "Nebraska", "NE"],
"68341": ["De Witt", "Nebraska", "NE"],
"68342": ["Diller", "Nebraska", "NE"],
"68343": ["Dorchester", "Nebraska", "NE"],
"68344": ["Douglas", "Nebraska", "NE"],
"68345": ["Du Bois", "Nebraska", "NE"],
"68346": ["Dunbar", "Nebraska", "NE"],
"68347": ["Eagle", "Nebraska", "NE"],
"68348": ["Elk Creek", "Nebraska", "NE"],
"68349": ["Elmwood", "Nebraska", "NE"],
"68350": ["Endicott", "Nebraska", "NE"],
"68351": ["Exeter", "Nebraska", "NE"],
"68352": ["Fairbury", "Nebraska", "NE"],
"68354": ["Fairmont", "Nebraska", "NE"],
"68355": ["Falls City", "Nebraska", "NE"],
"68357": ["Filley", "Nebraska", "NE"],
"68358": ["Firth", "Nebraska", "NE"],
"68359": ["Friend", "Nebraska", "NE"],
"68360": ["Garland", "Nebraska", "NE"],
"68361": ["Geneva", "Nebraska", "NE"],
"68362": ["Gilead", "Nebraska", "NE"],
"68364": ["Goehner", "Nebraska", "NE"],
"68365": ["Grafton", "Nebraska", "NE"],
"68366": ["Greenwood", "Nebraska", "NE"],
"68367": ["Gresham", "Nebraska", "NE"],
"68368": ["Hallam", "Nebraska", "NE"],
"68370": ["Hebron", "Nebraska", "NE"],
"68371": ["Henderson", "Nebraska", "NE"],
"68372": ["Hickman", "Nebraska", "NE"],
"68375": ["Hubbell", "Nebraska", "NE"],
"68376": ["Humboldt", "Nebraska", "NE"],
"68377": ["Jansen", "Nebraska", "NE"],
"68378": ["Johnson", "Nebraska", "NE"],
"68379": ["Julian", "Nebraska", "NE"],
"68380": ["Lewiston", "Nebraska", "NE"],
"68381": ["Liberty", "Nebraska", "NE"],
"68382": ["Lorton", "Nebraska", "NE"],
"68401": ["McCool Junction", "Nebraska", "NE"],
"68402": ["Malcolm", "Nebraska", "NE"],
"68403": ["Manley", "Nebraska", "NE"],
"68404": ["Martell", "Nebraska", "NE"],
"68405": ["Milford", "Nebraska", "NE"],
"68406": ["Milligan", "Nebraska", "NE"],
"68407": ["Murdock", "Nebraska", "NE"],
"68409": ["Murray", "Nebraska", "NE"],
"68410": ["Nebraska City", "Nebraska", "NE"],
"68413": ["Nehawka", "Nebraska", "NE"],
"68414": ["Nemaha", "Nebraska", "NE"],
"68415": ["Odell", "Nebraska", "NE"],
"68416": ["Ohiowa", "Nebraska", "NE"],
"68417": ["Otoe", "Nebraska", "NE"],
"68418": ["Palmyra", "Nebraska", "NE"],
"68419": ["Panama", "Nebraska", "NE"],
"68420": ["Pawnee City", "Nebraska", "NE"],
"68421": ["Peru", "Nebraska", "NE"],
"68422": ["Pickrell", "Nebraska", "NE"],
"68423": ["Pleasant Dale", "Nebraska", "NE"],
"68424": ["Plymouth", "Nebraska", "NE"],
"68428": ["Raymond", "Nebraska", "NE"],
"68429": ["Reynolds", "Nebraska", "NE"],
"68430": ["Roca", "Nebraska", "NE"],
"68431": ["Rulo", "Nebraska", "NE"],
"68433": ["Salem", "Nebraska", "NE"],
"68434": ["Seward", "Nebraska", "NE"],
"68436": ["Shickley", "Nebraska", "NE"],
"68437": ["Shubert", "Nebraska", "NE"],
"68438": ["Sprague", "Nebraska", "NE"],
"68439": ["Staplehurst", "Nebraska", "NE"],
"68440": ["Steele City", "Nebraska", "NE"],
"68441": ["Steinauer", "Nebraska", "NE"],
"68442": ["Stella", "Nebraska", "NE"],
"68443": ["Sterling", "Nebraska", "NE"],
"68444": ["Strang", "Nebraska", "NE"],
"68445": ["Swanton", "Nebraska", "NE"],
"68446": ["Syracuse", "Nebraska", "NE"],
"68447": ["Table Rock", "Nebraska", "NE"],
"68448": ["Talmage", "Nebraska", "NE"],
"68450": ["Tecumseh", "Nebraska", "NE"],
"68452": ["Ong", "Nebraska", "NE"],
"68453": ["Tobias", "Nebraska", "NE"],
"68454": ["Unadilla", "Nebraska", "NE"],
"68455": ["Union", "Nebraska", "NE"],
"68456": ["Utica", "Nebraska", "NE"],
"68457": ["Verdon", "Nebraska", "NE"],
"68458": ["Virginia", "Nebraska", "NE"],
"68460": ["Waco", "Nebraska", "NE"],
"68461": ["Walton", "Nebraska", "NE"],
"68462": ["Waverly", "Nebraska", "NE"],
"68463": ["Weeping Water", "Nebraska", "NE"],
"68464": ["Western", "Nebraska", "NE"],
"68465": ["Wilber", "Nebraska", "NE"],
"68466": ["Wymore", "Nebraska", "NE"],
"68467": ["York", "Nebraska", "NE"],
"68502": ["Lincoln", "Nebraska", "NE"],
"68503": ["Lincoln", "Nebraska", "NE"],
"68504": ["Lincoln", "Nebraska", "NE"],
"68505": ["Lincoln", "Nebraska", "NE"],
"68506": ["Lincoln", "Nebraska", "NE"],
"68507": ["Lincoln", "Nebraska", "NE"],
"68508": ["Lincoln", "Nebraska", "NE"],
"68510": ["Lincoln", "Nebraska", "NE"],
"68512": ["Lincoln", "Nebraska", "NE"],
"68514": ["Lincoln", "Nebraska", "NE"],
"68516": ["Lincoln", "Nebraska", "NE"],
"68517": ["Lincoln", "Nebraska", "NE"],
"68520": ["Lincoln", "Nebraska", "NE"],
"68521": ["Lincoln", "Nebraska", "NE"],
"68522": ["Lincoln", "Nebraska", "NE"],
"68523": ["Lincoln", "Nebraska", "NE"],
"68524": ["Lincoln", "Nebraska", "NE"],
"68526": ["Lincoln", "Nebraska", "NE"],
"68527": ["Lincoln", "Nebraska", "NE"],
"68528": ["Lincoln", "Nebraska", "NE"],
"68531": ["Lincoln", "Nebraska", "NE"],
"68532": ["Lincoln", "Nebraska", "NE"],
"68588": ["Lincoln", "Nebraska", "NE"],
"68601": ["Columbus", "Nebraska", "NE"],
"68620": ["Albion", "Nebraska", "NE"],
"68621": ["Ames", "Nebraska", "NE"],
"68622": ["Bartlett", "Nebraska", "NE"],
"68623": ["Belgrade", "Nebraska", "NE"],
"68624": ["Bellwood", "Nebraska", "NE"],
"68626": ["Brainard", "Nebraska", "NE"],
"68627": ["Cedar Rapids", "Nebraska", "NE"],
"68628": ["Clarks", "Nebraska", "NE"],
"68629": ["Clarkson", "Nebraska", "NE"],
"68631": ["Creston", "Nebraska", "NE"],
"68632": ["David City", "Nebraska", "NE"],
"68633": ["Dodge", "Nebraska", "NE"],
"68634": ["Duncan", "Nebraska", "NE"],
"68635": ["Dwight", "Nebraska", "NE"],
"68636": ["Elgin", "Nebraska", "NE"],
"68637": ["Ericson", "Nebraska", "NE"],
"68638": ["Fullerton", "Nebraska", "NE"],
"68640": ["Genoa", "Nebraska", "NE"],
"68641": ["Howells", "Nebraska", "NE"],
"68642": ["Humphrey", "Nebraska", "NE"],
"68643": ["Leigh", "Nebraska", "NE"],
"68644": ["Lindsay", "Nebraska", "NE"],
"68647": ["Monroe", "Nebraska", "NE"],
"68648": ["Morse Bluff", "Nebraska", "NE"],
"68649": ["North Bend", "Nebraska", "NE"],
"68651": ["Osceola", "Nebraska", "NE"],
"68652": ["Petersburg", "Nebraska", "NE"],
"68653": ["Platte Center", "Nebraska", "NE"],
"68654": ["Polk", "Nebraska", "NE"],
"68655": ["Primrose", "Nebraska", "NE"],
"68658": ["Rising City", "Nebraska", "NE"],
"68659": ["Rogers", "Nebraska", "NE"],
"68660": ["Saint Edward", "Nebraska", "NE"],
"68661": ["Schuyler", "Nebraska", "NE"],
"68662": ["Shelby", "Nebraska", "NE"],
"68663": ["Silver Creek", "Nebraska", "NE"],
"68664": ["Snyder", "Nebraska", "NE"],
"68665": ["Spalding", "Nebraska", "NE"],
"68666": ["Stromsburg", "Nebraska", "NE"],
"68667": ["Surprise", "Nebraska", "NE"],
"68669": ["Ulysses", "Nebraska", "NE"],
"68701": ["Norfolk", "Nebraska", "NE"],
"68710": ["Allen", "Nebraska", "NE"],
"68711": ["Amelia", "Nebraska", "NE"],
"68713": ["Atkinson", "Nebraska", "NE"],
"68714": ["Bassett", "Nebraska", "NE"],
"68715": ["Battle Creek", "Nebraska", "NE"],
"68716": ["Beemer", "Nebraska", "NE"],
"68717": ["Belden", "Nebraska", "NE"],
"68718": ["Bloomfield", "Nebraska", "NE"],
"68719": ["Bristow", "Nebraska", "NE"],
"68720": ["Brunswick", "Nebraska", "NE"],
"68722": ["Butte", "Nebraska", "NE"],
"68723": ["Carroll", "Nebraska", "NE"],
"68724": ["Center", "Nebraska", "NE"],
"68725": ["Chambers", "Nebraska", "NE"],
"68726": ["Clearwater", "Nebraska", "NE"],
"68727": ["Coleridge", "Nebraska", "NE"],
"68728": ["Concord", "Nebraska", "NE"],
"68729": ["Creighton", "Nebraska", "NE"],
"68730": ["Crofton", "Nebraska", "NE"],
"68731": ["Dakota City", "Nebraska", "NE"],
"68732": ["Dixon", "Nebraska", "NE"],
"68733": ["Emerson", "Nebraska", "NE"],
"68734": ["Emmet", "Nebraska", "NE"],
"68735": ["Ewing", "Nebraska", "NE"],
"68736": ["Fordyce", "Nebraska", "NE"],
"68739": ["Hartington", "Nebraska", "NE"],
"68740": ["Hoskins", "Nebraska", "NE"],
"68741": ["Hubbard", "Nebraska", "NE"],
"68742": ["Inman", "Nebraska", "NE"],
"68743": ["Jackson", "Nebraska", "NE"],
"68745": ["Laurel", "Nebraska", "NE"],
"68746": ["Lynch", "Nebraska", "NE"],
"68747": ["Mclean", "Nebraska", "NE"],
"68748": ["Madison", "Nebraska", "NE"],
"68749": ["Magnet", "Nebraska", "NE"],
"68751": ["Maskell", "Nebraska", "NE"],
"68752": ["Meadow Grove", "Nebraska", "NE"],
"68753": ["Mills", "Nebraska", "NE"],
"68755": ["Naper", "Nebraska", "NE"],
"68756": ["Neligh", "Nebraska", "NE"],
"68757": ["Newcastle", "Nebraska", "NE"],
"68758": ["Newman Grove", "Nebraska", "NE"],
"68759": ["Newport", "Nebraska", "NE"],
"68760": ["Niobrara", "Nebraska", "NE"],
"68761": ["Oakdale", "Nebraska", "NE"],
"68763": ["Oneill", "Nebraska", "NE"],
"68764": ["Orchard", "Nebraska", "NE"],
"68765": ["Osmond", "Nebraska", "NE"],
"68766": ["Page", "Nebraska", "NE"],
"68767": ["Pierce", "Nebraska", "NE"],
"68768": ["Pilger", "Nebraska", "NE"],
"68769": ["Plainview", "Nebraska", "NE"],
"68770": ["Ponca", "Nebraska", "NE"],
"68771": ["Randolph", "Nebraska", "NE"],
"68773": ["Royal", "Nebraska", "NE"],
"68774": ["Saint Helena", "Nebraska", "NE"],
"68776": ["South Sioux City", "Nebraska", "NE"],
"68777": ["Spencer", "Nebraska", "NE"],
"68778": ["Springview", "Nebraska", "NE"],
"68779": ["Stanton", "Nebraska", "NE"],
"68780": ["Stuart", "Nebraska", "NE"],
"68781": ["Tilden", "Nebraska", "NE"],
"68783": ["Verdigre", "Nebraska", "NE"],
"68784": ["Wakefield", "Nebraska", "NE"],
"68785": ["Waterbury", "Nebraska", "NE"],
"68786": ["Wausa", "Nebraska", "NE"],
"68787": ["Wayne", "Nebraska", "NE"],
"68788": ["West Point", "Nebraska", "NE"],
"68789": ["Winnetoon", "Nebraska", "NE"],
"68790": ["Winside", "Nebraska", "NE"],
"68791": ["Wisner", "Nebraska", "NE"],
"68792": ["Wynot", "Nebraska", "NE"],
"68801": ["Grand Island", "Nebraska", "NE"],
"68803": ["Grand Island", "Nebraska", "NE"],
"68810": ["Alda", "Nebraska", "NE"],
"68812": ["Amherst", "Nebraska", "NE"],
"68813": ["Anselmo", "Nebraska", "NE"],
"68814": ["Ansley", "Nebraska", "NE"],
"68815": ["Arcadia", "Nebraska", "NE"],
"68816": ["Archer", "Nebraska", "NE"],
"68817": ["Ashton", "Nebraska", "NE"],
"68818": ["Aurora", "Nebraska", "NE"],
"68820": ["Boelus", "Nebraska", "NE"],
"68821": ["Brewster", "Nebraska", "NE"],
"68822": ["Broken Bow", "Nebraska", "NE"],
"68823": ["Burwell", "Nebraska", "NE"],
"68824": ["Cairo", "Nebraska", "NE"],
"68825": ["Callaway", "Nebraska", "NE"],
"68826": ["Central City", "Nebraska", "NE"],
"68827": ["Chapman", "Nebraska", "NE"],
"68828": ["Comstock", "Nebraska", "NE"],
"68831": ["Dannebrog", "Nebraska", "NE"],
"68832": ["Doniphan", "Nebraska", "NE"],
"68833": ["Dunning", "Nebraska", "NE"],
"68834": ["Eddyville", "Nebraska", "NE"],
"68835": ["Elba", "Nebraska", "NE"],
"68836": ["Elm Creek", "Nebraska", "NE"],
"68837": ["Elyria", "Nebraska", "NE"],
"68838": ["Farwell", "Nebraska", "NE"],
"68840": ["Gibbon", "Nebraska", "NE"],
"68841": ["Giltner", "Nebraska", "NE"],
"68842": ["Greeley", "Nebraska", "NE"],
"68843": ["Hampton", "Nebraska", "NE"],
"68844": ["Hazard", "Nebraska", "NE"],
"68845": ["Kearney", "Nebraska", "NE"],
"68846": ["Hordville", "Nebraska", "NE"],
"68847": ["Kearney", "Nebraska", "NE"],
"68849": ["Kearney", "Nebraska", "NE"],
"68850": ["Lexington", "Nebraska", "NE"],
"68852": ["Litchfield", "Nebraska", "NE"],
"68853": ["Loup City", "Nebraska", "NE"],
"68854": ["Marquette", "Nebraska", "NE"],
"68855": ["Mason City", "Nebraska", "NE"],
"68856": ["Merna", "Nebraska", "NE"],
"68858": ["Miller", "Nebraska", "NE"],
"68859": ["North Loup", "Nebraska", "NE"],
"68860": ["Oconto", "Nebraska", "NE"],
"68861": ["Odessa", "Nebraska", "NE"],
"68862": ["Ord", "Nebraska", "NE"],
"68863": ["Overton", "Nebraska", "NE"],
"68864": ["Palmer", "Nebraska", "NE"],
"68865": ["Phillips", "Nebraska", "NE"],
"68866": ["Pleasanton", "Nebraska", "NE"],
"68869": ["Ravenna", "Nebraska", "NE"],
"68870": ["Riverdale", "Nebraska", "NE"],
"68871": ["Rockville", "Nebraska", "NE"],
"68872": ["Saint Libory", "Nebraska", "NE"],
"68873": ["Saint Paul", "Nebraska", "NE"],
"68874": ["Sargent", "Nebraska", "NE"],
"68875": ["Scotia", "Nebraska", "NE"],
"68876": ["Shelton", "Nebraska", "NE"],
"68878": ["Sumner", "Nebraska", "NE"],
"68879": ["Taylor", "Nebraska", "NE"],
"68881": ["Westerville", "Nebraska", "NE"],
"68882": ["Wolbach", "Nebraska", "NE"],
"68883": ["Wood River", "Nebraska", "NE"],
"68901": ["Hastings", "Nebraska", "NE"],
"68920": ["Alma", "Nebraska", "NE"],
"68922": ["Arapahoe", "Nebraska", "NE"],
"68923": ["Atlanta", "Nebraska", "NE"],
"68924": ["Axtell", "Nebraska", "NE"],
"68925": ["Ayr", "Nebraska", "NE"],
"68926": ["Beaver City", "Nebraska", "NE"],
"68927": ["Bertrand", "Nebraska", "NE"],
"68928": ["Bladen", "Nebraska", "NE"],
"68929": ["Bloomington", "Nebraska", "NE"],
"68930": ["Blue Hill", "Nebraska", "NE"],
"68932": ["Campbell", "Nebraska", "NE"],
"68933": ["Clay Center", "Nebraska", "NE"],
"68934": ["Deweese", "Nebraska", "NE"],
"68935": ["Edgar", "Nebraska", "NE"],
"68936": ["Edison", "Nebraska", "NE"],
"68937": ["Elwood", "Nebraska", "NE"],
"68938": ["Fairfield", "Nebraska", "NE"],
"68939": ["Franklin", "Nebraska", "NE"],
"68940": ["Funk", "Nebraska", "NE"],
"68941": ["Glenvil", "Nebraska", "NE"],
"68942": ["Guide Rock", "Nebraska", "NE"],
"68943": ["Hardy", "Nebraska", "NE"],
"68944": ["Harvard", "Nebraska", "NE"],
"68945": ["Heartwell", "Nebraska", "NE"],
"68946": ["Hendley", "Nebraska", "NE"],
"68947": ["Hildreth", "Nebraska", "NE"],
"68948": ["Holbrook", "Nebraska", "NE"],
"68949": ["Holdrege", "Nebraska", "NE"],
"68950": ["Holstein", "Nebraska", "NE"],
"68952": ["Inavale", "Nebraska", "NE"],
"68954": ["Inland", "Nebraska", "NE"],
"68955": ["Juniata", "Nebraska", "NE"],
"68956": ["Kenesaw", "Nebraska", "NE"],
"68957": ["Lawrence", "Nebraska", "NE"],
"68958": ["Loomis", "Nebraska", "NE"],
"68959": ["Minden", "Nebraska", "NE"],
"68960": ["Naponee", "Nebraska", "NE"],
"68961": ["Nelson", "Nebraska", "NE"],
"68964": ["Oak", "Nebraska", "NE"],
"68966": ["Orleans", "Nebraska", "NE"],
"68967": ["Oxford", "Nebraska", "NE"],
"68970": ["Red Cloud", "Nebraska", "NE"],
"68971": ["Republican City", "Nebraska", "NE"],
"68972": ["Riverton", "Nebraska", "NE"],
"68973": ["Roseland", "Nebraska", "NE"],
"68974": ["Ruskin", "Nebraska", "NE"],
"68975": ["Saronville", "Nebraska", "NE"],
"68976": ["Smithfield", "Nebraska", "NE"],
"68977": ["Stamford", "Nebraska", "NE"],
"68978": ["Superior", "Nebraska", "NE"],
"68979": ["Sutton", "Nebraska", "NE"],
"68980": ["Trumbull", "Nebraska", "NE"],
"68981": ["Upland", "Nebraska", "NE"],
"68982": ["Wilcox", "Nebraska", "NE"],
"69001": ["McCook", "Nebraska", "NE"],
"69020": ["Bartley", "Nebraska", "NE"],
"69021": ["Benkelman", "Nebraska", "NE"],
"69022": ["Cambridge", "Nebraska", "NE"],
"69023": ["Champion", "Nebraska", "NE"],
"69024": ["Culbertson", "Nebraska", "NE"],
"69025": ["Curtis", "Nebraska", "NE"],
"69026": ["Danbury", "Nebraska", "NE"],
"69027": ["Enders", "Nebraska", "NE"],
"69028": ["Eustis", "Nebraska", "NE"],
"69029": ["Farnam", "Nebraska", "NE"],
"69030": ["Haigler", "Nebraska", "NE"],
"69032": ["Hayes Center", "Nebraska", "NE"],
"69033": ["Imperial", "Nebraska", "NE"],
"69034": ["Indianola", "Nebraska", "NE"],
"69036": ["Lebanon", "Nebraska", "NE"],
"69037": ["Max", "Nebraska", "NE"],
"69038": ["Maywood", "Nebraska", "NE"],
"69039": ["Moorefield", "Nebraska", "NE"],
"69040": ["Palisade", "Nebraska", "NE"],
"69041": ["Parks", "Nebraska", "NE"],
"69042": ["Stockville", "Nebraska", "NE"],
"69043": ["Stratton", "Nebraska", "NE"],
"69044": ["Trenton", "Nebraska", "NE"],
"69045": ["Wauneta", "Nebraska", "NE"],
"69046": ["Wilsonville", "Nebraska", "NE"],
"69101": ["North Platte", "Nebraska", "NE"],
"69120": ["Arnold", "Nebraska", "NE"],
"69121": ["Arthur", "Nebraska", "NE"],
"69122": ["Big Springs", "Nebraska", "NE"],
"69123": ["Brady", "Nebraska", "NE"],
"69125": ["Broadwater", "Nebraska", "NE"],
"69127": ["Brule", "Nebraska", "NE"],
"69128": ["Bushnell", "Nebraska", "NE"],
"69129": ["Chappell", "Nebraska", "NE"],
"69130": ["Cozad", "Nebraska", "NE"],
"69131": ["Dalton", "Nebraska", "NE"],
"69132": ["Dickens", "Nebraska", "NE"],
"69133": ["Dix", "Nebraska", "NE"],
"69134": ["Elsie", "Nebraska", "NE"],
"69135": ["Elsmere", "Nebraska", "NE"],
"69138": ["Gothenburg", "Nebraska", "NE"],
"69140": ["Grant", "Nebraska", "NE"],
"69141": ["Gurley", "Nebraska", "NE"],
"69142": ["Halsey", "Nebraska", "NE"],
"69143": ["Hershey", "Nebraska", "NE"],
"69144": ["Keystone", "Nebraska", "NE"],
"69145": ["Kimball", "Nebraska", "NE"],
"69146": ["Lemoyne", "Nebraska", "NE"],
"69147": ["Lewellen", "Nebraska", "NE"],
"69148": ["Lisco", "Nebraska", "NE"],
"69149": ["Lodgepole", "Nebraska", "NE"],
"69150": ["Madrid", "Nebraska", "NE"],
"69151": ["Maxwell", "Nebraska", "NE"],
"69152": ["Mullen", "Nebraska", "NE"],
"69153": ["Ogallala", "Nebraska", "NE"],
"69154": ["Oshkosh", "Nebraska", "NE"],
"69155": ["Paxton", "Nebraska", "NE"],
"69156": ["Potter", "Nebraska", "NE"],
"69157": ["Purdum", "Nebraska", "NE"],
"69161": ["Seneca", "Nebraska", "NE"],
"69162": ["Sidney", "Nebraska", "NE"],
"69163": ["Stapleton", "Nebraska", "NE"],
"69165": ["Sutherland", "Nebraska", "NE"],
"69166": ["Thedford", "Nebraska", "NE"],
"69167": ["Tryon", "Nebraska", "NE"],
"69168": ["Venango", "Nebraska", "NE"],
"69169": ["Wallace", "Nebraska", "NE"],
"69170": ["Wellfleet", "Nebraska", "NE"],
"69171": ["Willow Island", "Nebraska", "NE"],
"69201": ["Valentine", "Nebraska", "NE"],
"69210": ["Ainsworth", "Nebraska", "NE"],
"69211": ["Cody", "Nebraska", "NE"],
"69212": ["Crookston", "Nebraska", "NE"],
"69214": ["Johnstown", "Nebraska", "NE"],
"69216": ["Kilgore", "Nebraska", "NE"],
"69217": ["Long Pine", "Nebraska", "NE"],
"69218": ["Merriman", "Nebraska", "NE"],
"69219": ["Nenzel", "Nebraska", "NE"],
"69220": ["Sparks", "Nebraska", "NE"],
"69221": ["Wood Lake", "Nebraska", "NE"],
"69301": ["Alliance", "Nebraska", "NE"],
"69331": ["Angora", "Nebraska", "NE"],
"69333": ["Ashby", "Nebraska", "NE"],
"69334": ["Bayard", "Nebraska", "NE"],
"69335": ["Bingham", "Nebraska", "NE"],
"69336": ["Bridgeport", "Nebraska", "NE"],
"69337": ["Chadron", "Nebraska", "NE"],
"69339": ["Crawford", "Nebraska", "NE"],
"69340": ["Ellsworth", "Nebraska", "NE"],
"69341": ["Gering", "Nebraska", "NE"],
"69343": ["Gordon", "Nebraska", "NE"],
"69345": ["Harrisburg", "Nebraska", "NE"],
"69346": ["Harrison", "Nebraska", "NE"],
"69347": ["Hay Springs", "Nebraska", "NE"],
"69348": ["Hemingford", "Nebraska", "NE"],
"69350": ["Hyannis", "Nebraska", "NE"],
"69351": ["Lakeside", "Nebraska", "NE"],
"69352": ["Lyman", "Nebraska", "NE"],
"69353": ["Mcgrew", "Nebraska", "NE"],
"69354": ["Marsland", "Nebraska", "NE"],
"69355": ["Melbeta", "Nebraska", "NE"],
"69356": ["Minatare", "Nebraska", "NE"],
"69357": ["Mitchell", "Nebraska", "NE"],
"69358": ["Morrill", "Nebraska", "NE"],
"69360": ["Rushville", "Nebraska", "NE"],
"69361": ["Scottsbluff", "Nebraska", "NE"],
"69365": ["Whiteclay", "Nebraska", "NE"],
"69366": ["Whitman", "Nebraska", "NE"],
"69367": ["Whitney", "Nebraska", "NE"],
"70001": ["Metairie", "Louisiana", "LA"],
"70002": ["Metairie", "Louisiana", "LA"],
"70003": ["Metairie", "Louisiana", "LA"],
"70005": ["Metairie", "Louisiana", "LA"],
"70006": ["Metairie", "Louisiana", "LA"],
"70030": ["Des Allemands", "Louisiana", "LA"],
"70031": ["Ama", "Louisiana", "LA"],
"70032": ["Arabi", "Louisiana", "LA"],
"70036": ["Barataria", "Louisiana", "LA"],
"70037": ["Belle Chasse", "Louisiana", "LA"],
"70038": ["Boothville", "Louisiana", "LA"],
"70039": ["Boutte", "Louisiana", "LA"],
"70040": ["Braithwaite", "Louisiana", "LA"],
"70041": ["Buras", "Louisiana", "LA"],
"70043": ["Chalmette", "Louisiana", "LA"],
"70047": ["Destrehan", "Louisiana", "LA"],
"70049": ["Edgard", "Louisiana", "LA"],
"70050": ["Empire", "Louisiana", "LA"],
"70051": ["Garyville", "Louisiana", "LA"],
"70052": ["Gramercy", "Louisiana", "LA"],
"70053": ["Gretna", "Louisiana", "LA"],
"70056": ["Gretna", "Louisiana", "LA"],
"70057": ["Hahnville", "Louisiana", "LA"],
"70058": ["Harvey", "Louisiana", "LA"],
"70062": ["Kenner", "Louisiana", "LA"],
"70065": ["Kenner", "Louisiana", "LA"],
"70067": ["Lafitte", "Louisiana", "LA"],
"70068": ["La Place", "Louisiana", "LA"],
"70070": ["Luling", "Louisiana", "LA"],
"70071": ["Lutcher", "Louisiana", "LA"],
"70072": ["Marrero", "Louisiana", "LA"],
"70075": ["Meraux", "Louisiana", "LA"],
"70076": ["Mount Airy", "Louisiana", "LA"],
"70079": ["Norco", "Louisiana", "LA"],
"70080": ["Paradis", "Louisiana", "LA"],
"70082": ["Pointe A La Hache", "Louisiana", "LA"],
"70083": ["Port Sulphur", "Louisiana", "LA"],
"70084": ["Reserve", "Louisiana", "LA"],
"70085": ["Saint Bernard", "Louisiana", "LA"],
"70086": ["Saint James", "Louisiana", "LA"],
"70087": ["Saint Rose", "Louisiana", "LA"],
"70090": ["Vacherie", "Louisiana", "LA"],
"70091": ["Venice", "Louisiana", "LA"],
"70092": ["Violet", "Louisiana", "LA"],
"70094": ["Westwego", "Louisiana", "LA"],
"70112": ["New Orleans", "Louisiana", "LA"],
"70113": ["New Orleans", "Louisiana", "LA"],
"70114": ["New Orleans", "Louisiana", "LA"],
"70115": ["New Orleans", "Louisiana", "LA"],
"70116": ["New Orleans", "Louisiana", "LA"],
"70117": ["New Orleans", "Louisiana", "LA"],
"70118": ["New Orleans", "Louisiana", "LA"],
"70119": ["New Orleans", "Louisiana", "LA"],
"70121": ["New Orleans", "Louisiana", "LA"],
"70122": ["New Orleans", "Louisiana", "LA"],
"70123": ["New Orleans", "Louisiana", "LA"],
"70124": ["New Orleans", "Louisiana", "LA"],
"70125": ["New Orleans", "Louisiana", "LA"],
"70126": ["New Orleans", "Louisiana", "LA"],
"70127": ["New Orleans", "Louisiana", "LA"],
"70128": ["New Orleans", "Louisiana", "LA"],
"70129": ["New Orleans", "Louisiana", "LA"],
"70130": ["New Orleans", "Louisiana", "LA"],
"70131": ["New Orleans", "Louisiana", "LA"],
"70139": ["New Orleans", "Louisiana", "LA"],
"70148": ["New Orleans", "Louisiana", "LA"],
"70163": ["New Orleans", "Louisiana", "LA"],
"70301": ["Thibodaux", "Louisiana", "LA"],
"70310": ["Thibodaux", "Louisiana", "LA"],
"70339": ["Pierre Part", "Louisiana", "LA"],
"70340": ["Amelia", "Louisiana", "LA"],
"70341": ["Belle Rose", "Louisiana", "LA"],
"70342": ["Berwick", "Louisiana", "LA"],
"70343": ["Bourg", "Louisiana", "LA"],
"70344": ["Chauvin", "Louisiana", "LA"],
"70345": ["Cut Off", "Louisiana", "LA"],
"70346": ["Donaldsonville", "Louisiana", "LA"],
"70352": ["Donner", "Louisiana", "LA"],
"70353": ["Dulac", "Louisiana", "LA"],
"70354": ["Galliano", "Louisiana", "LA"],
"70355": ["Gheens", "Louisiana", "LA"],
"70356": ["Gibson", "Louisiana", "LA"],
"70357": ["Golden Meadow", "Louisiana", "LA"],
"70358": ["Grand Isle", "Louisiana", "LA"],
"70359": ["Gray", "Louisiana", "LA"],
"70360": ["Houma", "Louisiana", "LA"],
"70363": ["Houma", "Louisiana", "LA"],
"70364": ["Houma", "Louisiana", "LA"],
"70372": ["Labadieville", "Louisiana", "LA"],
"70373": ["Larose", "Louisiana", "LA"],
"70374": ["Lockport", "Louisiana", "LA"],
"70375": ["Mathews", "Louisiana", "LA"],
"70377": ["Montegut", "Louisiana", "LA"],
"70380": ["Morgan City", "Louisiana", "LA"],
"70390": ["Napoleonville", "Louisiana", "LA"],
"70391": ["Paincourtville", "Louisiana", "LA"],
"70392": ["Patterson", "Louisiana", "LA"],
"70393": ["Plattenville", "Louisiana", "LA"],
"70394": ["Raceland", "Louisiana", "LA"],
"70395": ["Schriever", "Louisiana", "LA"],
"70397": ["Theriot", "Louisiana", "LA"],
"70401": ["Hammond", "Louisiana", "LA"],
"70402": ["Hammond", "Louisiana", "LA"],
"70403": ["Hammond", "Louisiana", "LA"],
"70420": ["Abita Springs", "Louisiana", "LA"],
"70422": ["Amite", "Louisiana", "LA"],
"70426": ["Angie", "Louisiana", "LA"],
"70427": ["Bogalusa", "Louisiana", "LA"],
"70431": ["Bush", "Louisiana", "LA"],
"70433": ["Covington", "Louisiana", "LA"],
"70435": ["Covington", "Louisiana", "LA"],
"70436": ["Fluker", "Louisiana", "LA"],
"70437": ["Folsom", "Louisiana", "LA"],
"70438": ["Franklinton", "Louisiana", "LA"],
"70441": ["Greensburg", "Louisiana", "LA"],
"70442": ["Husser", "Louisiana", "LA"],
"70443": ["Independence", "Louisiana", "LA"],
"70444": ["Kentwood", "Louisiana", "LA"],
"70445": ["Lacombe", "Louisiana", "LA"],
"70446": ["Loranger", "Louisiana", "LA"],
"70447": ["Madisonville", "Louisiana", "LA"],
"70448": ["Mandeville", "Louisiana", "LA"],
"70449": ["Maurepas", "Louisiana", "LA"],
"70450": ["Mount Hermon", "Louisiana", "LA"],
"70451": ["Natalbany", "Louisiana", "LA"],
"70452": ["Pearl River", "Louisiana", "LA"],
"70453": ["Pine Grove", "Louisiana", "LA"],
"70454": ["Ponchatoula", "Louisiana", "LA"],
"70455": ["Robert", "Louisiana", "LA"],
"70456": ["Roseland", "Louisiana", "LA"],
"70457": ["Saint Benedict", "Louisiana", "LA"],
"70458": ["Slidell", "Louisiana", "LA"],
"70460": ["Slidell", "Louisiana", "LA"],
"70461": ["Slidell", "Louisiana", "LA"],
"70462": ["Springfield", "Louisiana", "LA"],
"70463": ["Sun", "Louisiana", "LA"],
"70464": ["Talisheek", "Louisiana", "LA"],
"70465": ["Tangipahoa", "Louisiana", "LA"],
"70466": ["Tickfaw", "Louisiana", "LA"],
"70471": ["Mandeville", "Louisiana", "LA"],
"70501": ["Lafayette", "Louisiana", "LA"],
"70503": ["Lafayette", "Louisiana", "LA"],
"70506": ["Lafayette", "Louisiana", "LA"],
"70507": ["Lafayette", "Louisiana", "LA"],
"70508": ["Lafayette", "Louisiana", "LA"],
"70510": ["Abbeville", "Louisiana", "LA"],
"70512": ["Arnaudville", "Louisiana", "LA"],
"70513": ["Avery Island", "Louisiana", "LA"],
"70514": ["Baldwin", "Louisiana", "LA"],
"70515": ["Basile", "Louisiana", "LA"],
"70516": ["Branch", "Louisiana", "LA"],
"70517": ["Breaux Bridge", "Louisiana", "LA"],
"70518": ["Broussard", "Louisiana", "LA"],
"70519": ["Cade", "Louisiana", "LA"],
"70520": ["Carencro", "Louisiana", "LA"],
"70522": ["Centerville", "Louisiana", "LA"],
"70523": ["Charenton", "Louisiana", "LA"],
"70524": ["Chataignier", "Louisiana", "LA"],
"70525": ["Church Point", "Louisiana", "LA"],
"70526": ["Crowley", "Louisiana", "LA"],
"70528": ["Delcambre", "Louisiana", "LA"],
"70529": ["Duson", "Louisiana", "LA"],
"70531": ["Egan", "Louisiana", "LA"],
"70532": ["Elton", "Louisiana", "LA"],
"70533": ["Erath", "Louisiana", "LA"],
"70534": ["Estherwood", "Louisiana", "LA"],
"70535": ["Eunice", "Louisiana", "LA"],
"70537": ["Evangeline", "Louisiana", "LA"],
"70538": ["Franklin", "Louisiana", "LA"],
"70541": ["Grand Coteau", "Louisiana", "LA"],
"70542": ["Gueydan", "Louisiana", "LA"],
"70543": ["Iota", "Louisiana", "LA"],
"70544": ["Jeanerette", "Louisiana", "LA"],
"70546": ["Jennings", "Louisiana", "LA"],
"70548": ["Kaplan", "Louisiana", "LA"],
"70549": ["Lake Arthur", "Louisiana", "LA"],
"70550": ["Lawtell", "Louisiana", "LA"],
"70551": ["Leonville", "Louisiana", "LA"],
"70552": ["Loreauville", "Louisiana", "LA"],
"70554": ["Mamou", "Louisiana", "LA"],
"70555": ["Maurice", "Louisiana", "LA"],
"70556": ["Mermentau", "Louisiana", "LA"],
"70558": ["Milton", "Louisiana", "LA"],
"70559": ["Morse", "Louisiana", "LA"],
"70560": ["New Iberia", "Louisiana", "LA"],
"70563": ["New Iberia", "Louisiana", "LA"],
"70570": ["Opelousas", "Louisiana", "LA"],
"70575": ["Perry", "Louisiana", "LA"],
"70576": ["Pine Prairie", "Louisiana", "LA"],
"70577": ["Port Barre", "Louisiana", "LA"],
"70578": ["Rayne", "Louisiana", "LA"],
"70580": ["Reddell", "Louisiana", "LA"],
"70581": ["Roanoke", "Louisiana", "LA"],
"70582": ["Saint Martinville", "Louisiana", "LA"],
"70583": ["Scott", "Louisiana", "LA"],
"70584": ["Sunset", "Louisiana", "LA"],
"70585": ["Turkey Creek", "Louisiana", "LA"],
"70586": ["Ville Platte", "Louisiana", "LA"],
"70589": ["Washington", "Louisiana", "LA"],
"70591": ["Welsh", "Louisiana", "LA"],
"70592": ["Youngsville", "Louisiana", "LA"],
"70601": ["Lake Charles", "Louisiana", "LA"],
"70605": ["Lake Charles", "Louisiana", "LA"],
"70607": ["Lake Charles", "Louisiana", "LA"],
"70609": ["Lake Charles", "Louisiana", "LA"],
"70611": ["Lake Charles", "Louisiana", "LA"],
"70615": ["Lake Charles", "Louisiana", "LA"],
"70629": ["Lake Charles", "Louisiana", "LA"],
"70630": ["Bell City", "Louisiana", "LA"],
"70631": ["Cameron", "Louisiana", "LA"],
"70632": ["Creole", "Louisiana", "LA"],
"70633": ["Dequincy", "Louisiana", "LA"],
"70634": ["Deridder", "Louisiana", "LA"],
"70637": ["Dry Creek", "Louisiana", "LA"],
"70638": ["Elizabeth", "Louisiana", "LA"],
"70639": ["Evans", "Louisiana", "LA"],
"70640": ["Fenton", "Louisiana", "LA"],
"70643": ["Grand Chenier", "Louisiana", "LA"],
"70644": ["Grant", "Louisiana", "LA"],
"70645": ["Hackberry", "Louisiana", "LA"],
"70646": ["Hayes", "Louisiana", "LA"],
"70647": ["Iowa", "Louisiana", "LA"],
"70648": ["Kinder", "Louisiana", "LA"],
"70650": ["Lacassine", "Louisiana", "LA"],
"70651": ["Leblanc", "Louisiana", "LA"],
"70652": ["Longville", "Louisiana", "LA"],
"70653": ["Merryville", "Louisiana", "LA"],
"70654": ["Mittie", "Louisiana", "LA"],
"70655": ["Oberlin", "Louisiana", "LA"],
"70656": ["Pitkin", "Louisiana", "LA"],
"70657": ["Ragley", "Louisiana", "LA"],
"70658": ["Reeves", "Louisiana", "LA"],
"70659": ["Rosepine", "Louisiana", "LA"],
"70660": ["Singer", "Louisiana", "LA"],
"70661": ["Starks", "Louisiana", "LA"],
"70662": ["Sugartown", "Louisiana", "LA"],
"70663": ["Sulphur", "Louisiana", "LA"],
"70665": ["Sulphur", "Louisiana", "LA"],
"70668": ["Vinton", "Louisiana", "LA"],
"70669": ["Westlake", "Louisiana", "LA"],
"70706": ["Denham Springs", "Louisiana", "LA"],
"70710": ["Addis", "Louisiana", "LA"],
"70711": ["Albany", "Louisiana", "LA"],
"70712": ["Angola", "Louisiana", "LA"],
"70714": ["Baker", "Louisiana", "LA"],
"70715": ["Batchelor", "Louisiana", "LA"],
"70719": ["Brusly", "Louisiana", "LA"],
"70721": ["Carville", "Louisiana", "LA"],
"70722": ["Clinton", "Louisiana", "LA"],
"70723": ["Convent", "Louisiana", "LA"],
"70725": ["Darrow", "Louisiana", "LA"],
"70726": ["Denham Springs", "Louisiana", "LA"],
"70729": ["Erwinville", "Louisiana", "LA"],
"70730": ["Ethel", "Louisiana", "LA"],
"70732": ["Fordoche", "Louisiana", "LA"],
"70733": ["French Settlement", "Louisiana", "LA"],
"70734": ["Geismar", "Louisiana", "LA"],
"70736": ["Glynn", "Louisiana", "LA"],
"70737": ["Gonzales", "Louisiana", "LA"],
"70739": ["Greenwell Springs", "Louisiana", "LA"],
"70740": ["Grosse Tete", "Louisiana", "LA"],
"70743": ["Hester", "Louisiana", "LA"],
"70744": ["Holden", "Louisiana", "LA"],
"70747": ["Innis", "Louisiana", "LA"],
"70748": ["Jackson", "Louisiana", "LA"],
"70749": ["Jarreau", "Louisiana", "LA"],
"70750": ["Krotz Springs", "Louisiana", "LA"],
"70752": ["Lakeland", "Louisiana", "LA"],
"70753": ["Lettsworth", "Louisiana", "LA"],
"70754": ["Livingston", "Louisiana", "LA"],
"70755": ["Livonia", "Louisiana", "LA"],
"70756": ["Lottie", "Louisiana", "LA"],
"70757": ["Maringouin", "Louisiana", "LA"],
"70759": ["Morganza", "Louisiana", "LA"],
"70760": ["New Roads", "Louisiana", "LA"],
"70761": ["Norwood", "Louisiana", "LA"],
"70762": ["Oscar", "Louisiana", "LA"],
"70763": ["Paulina", "Louisiana", "LA"],
"70764": ["Plaquemine", "Louisiana", "LA"],
"70767": ["Port Allen", "Louisiana", "LA"],
"70769": ["Prairieville", "Louisiana", "LA"],
"70770": ["Pride", "Louisiana", "LA"],
"70772": ["Rosedale", "Louisiana", "LA"],
"70773": ["Rougon", "Louisiana", "LA"],
"70774": ["Saint Amant", "Louisiana", "LA"],
"70775": ["Saint Francisville", "Louisiana", "LA"],
"70776": ["Saint Gabriel", "Louisiana", "LA"],
"70777": ["Slaughter", "Louisiana", "LA"],
"70778": ["Sorrento", "Louisiana", "LA"],
"70780": ["Sunshine", "Louisiana", "LA"],
"70782": ["Tunica", "Louisiana", "LA"],
"70783": ["Ventress", "Louisiana", "LA"],
"70785": ["Walker", "Louisiana", "LA"],
"70786": ["Watson", "Louisiana", "LA"],
"70787": ["Weyanoke", "Louisiana", "LA"],
"70788": ["White Castle", "Louisiana", "LA"],
"70789": ["Wilson", "Louisiana", "LA"],
"70791": ["Zachary", "Louisiana", "LA"],
"70801": ["Baton Rouge", "Louisiana", "LA"],
"70802": ["Baton Rouge", "Louisiana", "LA"],
"70803": ["Baton Rouge", "Louisiana", "LA"],
"70805": ["Baton Rouge", "Louisiana", "LA"],
"70806": ["Baton Rouge", "Louisiana", "LA"],
"70807": ["Baton Rouge", "Louisiana", "LA"],
"70808": ["Baton Rouge", "Louisiana", "LA"],
"70809": ["Baton Rouge", "Louisiana", "LA"],
"70810": ["Baton Rouge", "Louisiana", "LA"],
"70811": ["Baton Rouge", "Louisiana", "LA"],
"70812": ["Baton Rouge", "Louisiana", "LA"],
"70813": ["Baton Rouge", "Louisiana", "LA"],
"70814": ["Baton Rouge", "Louisiana", "LA"],
"70815": ["Baton Rouge", "Louisiana", "LA"],
"70816": ["Baton Rouge", "Louisiana", "LA"],
"70817": ["Baton Rouge", "Louisiana", "LA"],
"70818": ["Baton Rouge", "Louisiana", "LA"],
"70819": ["Baton Rouge", "Louisiana", "LA"],
"70820": ["Baton Rouge", "Louisiana", "LA"],
"70825": ["Baton Rouge", "Louisiana", "LA"],
"70836": ["Baton Rouge", "Louisiana", "LA"],
"71001": ["Arcadia", "Louisiana", "LA"],
"71002": ["Ashland", "Louisiana", "LA"],
"71003": ["Athens", "Louisiana", "LA"],
"71004": ["Belcher", "Louisiana", "LA"],
"71006": ["Benton", "Louisiana", "LA"],
"71007": ["Bethany", "Louisiana", "LA"],
"71008": ["Bienville", "Louisiana", "LA"],
"71009": ["Blanchard", "Louisiana", "LA"],
"71016": ["Castor", "Louisiana", "LA"],
"71018": ["Cotton Valley", "Louisiana", "LA"],
"71019": ["Coushatta", "Louisiana", "LA"],
"71021": ["Cullen", "Louisiana", "LA"],
"71023": ["Doyline", "Louisiana", "LA"],
"71024": ["Dubberly", "Louisiana", "LA"],
"71027": ["Frierson", "Louisiana", "LA"],
"71028": ["Gibsland", "Louisiana", "LA"],
"71029": ["Gilliam", "Louisiana", "LA"],
"71030": ["Gloster", "Louisiana", "LA"],
"71031": ["Goldonna", "Louisiana", "LA"],
"71032": ["Grand Cane", "Louisiana", "LA"],
"71033": ["Greenwood", "Louisiana", "LA"],
"71034": ["Hall Summit", "Louisiana", "LA"],
"71037": ["Haughton", "Louisiana", "LA"],
"71038": ["Haynesville", "Louisiana", "LA"],
"71039": ["Heflin", "Louisiana", "LA"],
"71040": ["Homer", "Louisiana", "LA"],
"71043": ["Hosston", "Louisiana", "LA"],
"71044": ["Ida", "Louisiana", "LA"],
"71045": ["Jamestown", "Louisiana", "LA"],
"71046": ["Keatchie", "Louisiana", "LA"],
"71047": ["Keithville", "Louisiana", "LA"],
"71048": ["Lisbon", "Louisiana", "LA"],
"71049": ["Logansport", "Louisiana", "LA"],
"71051": ["Elm Grove", "Louisiana", "LA"],
"71052": ["Mansfield", "Louisiana", "LA"],
"71055": ["Minden", "Louisiana", "LA"],
"71060": ["Mooringsport", "Louisiana", "LA"],
"71061": ["Oil City", "Louisiana", "LA"],
"71063": ["Pelican", "Louisiana", "LA"],
"71064": ["Plain Dealing", "Louisiana", "LA"],
"71065": ["Pleasant Hill", "Louisiana", "LA"],
"71066": ["Powhatan", "Louisiana", "LA"],
"71067": ["Princeton", "Louisiana", "LA"],
"71068": ["Ringgold", "Louisiana", "LA"],
"71069": ["Rodessa", "Louisiana", "LA"],
"71070": ["Saline", "Louisiana", "LA"],
"71071": ["Sarepta", "Louisiana", "LA"],
"71072": ["Shongaloo", "Louisiana", "LA"],
"71073": ["Sibley", "Louisiana", "LA"],
"71075": ["Springhill", "Louisiana", "LA"],
"71078": ["Stonewall", "Louisiana", "LA"],
"71079": ["Summerfield", "Louisiana", "LA"],
"71082": ["Vivian", "Louisiana", "LA"],
"71101": ["Shreveport", "Louisiana", "LA"],
"71103": ["Shreveport", "Louisiana", "LA"],
"71104": ["Shreveport", "Louisiana", "LA"],
"71105": ["Shreveport", "Louisiana", "LA"],
"71106": ["Shreveport", "Louisiana", "LA"],
"71107": ["Shreveport", "Louisiana", "LA"],
"71108": ["Shreveport", "Louisiana", "LA"],
"71109": ["Shreveport", "Louisiana", "LA"],
"71110": ["Barksdale Afb", "Louisiana", "LA"],
"71111": ["Bossier City", "Louisiana", "LA"],
"71112": ["Bossier City", "Louisiana", "LA"],
"71115": ["Shreveport", "Louisiana", "LA"],
"71118": ["Shreveport", "Louisiana", "LA"],
"71119": ["Shreveport", "Louisiana", "LA"],
"71129": ["Shreveport", "Louisiana", "LA"],
"71201": ["Monroe", "Louisiana", "LA"],
"71202": ["Monroe", "Louisiana", "LA"],
"71203": ["Monroe", "Louisiana", "LA"],
"71209": ["Monroe", "Louisiana", "LA"],
"71212": ["Monroe", "Louisiana", "LA"],
"71218": ["Archibald", "Louisiana", "LA"],
"71219": ["Baskin", "Louisiana", "LA"],
"71220": ["Bastrop", "Louisiana", "LA"],
"71222": ["Bernice", "Louisiana", "LA"],
"71223": ["Bonita", "Louisiana", "LA"],
"71225": ["Calhoun", "Louisiana", "LA"],
"71226": ["Chatham", "Louisiana", "LA"],
"71227": ["Choudrant", "Louisiana", "LA"],
"71229": ["Collinston", "Louisiana", "LA"],
"71230": ["Crowville", "Louisiana", "LA"],
"71232": ["Delhi", "Louisiana", "LA"],
"71233": ["Delta", "Louisiana", "LA"],
"71234": ["Downsville", "Louisiana", "LA"],
"71235": ["Dubach", "Louisiana", "LA"],
"71237": ["Epps", "Louisiana", "LA"],
"71238": ["Eros", "Louisiana", "LA"],
"71241": ["Farmerville", "Louisiana", "LA"],
"71242": ["Forest", "Louisiana", "LA"],
"71243": ["Fort Necessity", "Louisiana", "LA"],
"71245": ["Grambling", "Louisiana", "LA"],
"71247": ["Hodge", "Louisiana", "LA"],
"71250": ["Jones", "Louisiana", "LA"],
"71251": ["Jonesboro", "Louisiana", "LA"],
"71253": ["Kilbourne", "Louisiana", "LA"],
"71254": ["Lake Providence", "Louisiana", "LA"],
"71256": ["Lillie", "Louisiana", "LA"],
"71259": ["Mangham", "Louisiana", "LA"],
"71260": ["Marion", "Louisiana", "LA"],
"71261": ["Mer Rouge", "Louisiana", "LA"],
"71263": ["Oak Grove", "Louisiana", "LA"],
"71264": ["Oak Ridge", "Louisiana", "LA"],
"71266": ["Pioneer", "Louisiana", "LA"],
"71268": ["Quitman", "Louisiana", "LA"],
"71269": ["Rayville", "Louisiana", "LA"],
"71270": ["Ruston", "Louisiana", "LA"],
"71272": ["Ruston", "Louisiana", "LA"],
"71275": ["Simsboro", "Louisiana", "LA"],
"71276": ["Sondheimer", "Louisiana", "LA"],
"71277": ["Spearsville", "Louisiana", "LA"],
"71279": ["Start", "Louisiana", "LA"],
"71280": ["Sterlington", "Louisiana", "LA"],
"71282": ["Tallulah", "Louisiana", "LA"],
"71286": ["Transylvania", "Louisiana", "LA"],
"71291": ["West Monroe", "Louisiana", "LA"],
"71292": ["West Monroe", "Louisiana", "LA"],
"71295": ["Winnsboro", "Louisiana", "LA"],
"71301": ["Alexandria", "Louisiana", "LA"],
"71302": ["Alexandria", "Louisiana", "LA"],
"71303": ["Alexandria", "Louisiana", "LA"],
"71316": ["Acme", "Louisiana", "LA"],
"71320": ["Bordelonville", "Louisiana", "LA"],
"71322": ["Bunkie", "Louisiana", "LA"],
"71323": ["Center Point", "Louisiana", "LA"],
"71325": ["Cheneyville", "Louisiana", "LA"],
"71326": ["Clayton", "Louisiana", "LA"],
"71327": ["Cottonport", "Louisiana", "LA"],
"71328": ["Deville", "Louisiana", "LA"],
"71330": ["Echo", "Louisiana", "LA"],
"71331": ["Effie", "Louisiana", "LA"],
"71333": ["Evergreen", "Louisiana", "LA"],
"71334": ["Ferriday", "Louisiana", "LA"],
"71336": ["Gilbert", "Louisiana", "LA"],
"71339": ["Hamburg", "Louisiana", "LA"],
"71340": ["Harrisonburg", "Louisiana", "LA"],
"71341": ["Hessmer", "Louisiana", "LA"],
"71342": ["Jena", "Louisiana", "LA"],
"71343": ["Jonesville", "Louisiana", "LA"],
"71345": ["Lebeau", "Louisiana", "LA"],
"71346": ["Lecompte", "Louisiana", "LA"],
"71350": ["Mansura", "Louisiana", "LA"],
"71351": ["Marksville", "Louisiana", "LA"],
"71353": ["Melville", "Louisiana", "LA"],
"71354": ["Monterey", "Louisiana", "LA"],
"71355": ["Moreauville", "Louisiana", "LA"],
"71356": ["Morrow", "Louisiana", "LA"],
"71357": ["Newellton", "Louisiana", "LA"],
"71358": ["Palmetto", "Louisiana", "LA"],
"71359": ["Pineville", "Louisiana", "LA"],
"71360": ["Pineville", "Louisiana", "LA"],
"71362": ["Plaucheville", "Louisiana", "LA"],
"71366": ["Saint Joseph", "Louisiana", "LA"],
"71367": ["Saint Landry", "Louisiana", "LA"],
"71368": ["Sicily Island", "Louisiana", "LA"],
"71369": ["Simmesport", "Louisiana", "LA"],
"71371": ["Trout", "Louisiana", "LA"],
"71373": ["Vidalia", "Louisiana", "LA"],
"71375": ["Waterproof", "Louisiana", "LA"],
"71378": ["Wisner", "Louisiana", "LA"],
"71401": ["Aimwell", "Louisiana", "LA"],
"71403": ["Anacoco", "Louisiana", "LA"],
"71404": ["Atlanta", "Louisiana", "LA"],
"71405": ["Ball", "Louisiana", "LA"],
"71406": ["Belmont", "Louisiana", "LA"],
"71407": ["Bentley", "Louisiana", "LA"],
"71409": ["Boyce", "Louisiana", "LA"],
"71410": ["Calvin", "Louisiana", "LA"],
"71411": ["Campti", "Louisiana", "LA"],
"71414": ["Clarence", "Louisiana", "LA"],
"71415": ["Clarks", "Louisiana", "LA"],
"71416": ["Cloutierville", "Louisiana", "LA"],
"71417": ["Colfax", "Louisiana", "LA"],
"71418": ["Columbia", "Louisiana", "LA"],
"71419": ["Converse", "Louisiana", "LA"],
"71422": ["Dodson", "Louisiana", "LA"],
"71423": ["Dry Prong", "Louisiana", "LA"],
"71424": ["Elmer", "Louisiana", "LA"],
"71425": ["Enterprise", "Louisiana", "LA"],
"71426": ["Fisher", "Louisiana", "LA"],
"71427": ["Flatwoods", "Louisiana", "LA"],
"71428": ["Flora", "Louisiana", "LA"],
"71429": ["Florien", "Louisiana", "LA"],
"71430": ["Forest Hill", "Louisiana", "LA"],
"71432": ["Georgetown", "Louisiana", "LA"],
"71433": ["Glenmora", "Louisiana", "LA"],
"71434": ["Gorum", "Louisiana", "LA"],
"71435": ["Grayson", "Louisiana", "LA"],
"71438": ["Hineston", "Louisiana", "LA"],
"71439": ["Hornbeck", "Louisiana", "LA"],
"71441": ["Kelly", "Louisiana", "LA"],
"71446": ["Leesville", "Louisiana", "LA"],
"71447": ["Lena", "Louisiana", "LA"],
"71449": ["Many", "Louisiana", "LA"],
"71450": ["Marthaville", "Louisiana", "LA"],
"71452": ["Melrose", "Louisiana", "LA"],
"71454": ["Montgomery", "Louisiana", "LA"],
"71455": ["Mora", "Louisiana", "LA"],
"71456": ["Natchez", "Louisiana", "LA"],
"71457": ["Natchitoches", "Louisiana", "LA"],
"71459": ["Fort Polk", "Louisiana", "LA"],
"71461": ["New Llano", "Louisiana", "LA"],
"71462": ["Noble", "Louisiana", "LA"],
"71463": ["Oakdale", "Louisiana", "LA"],
"71465": ["Olla", "Louisiana", "LA"],
"71466": ["Otis", "Louisiana", "LA"],
"71467": ["Pollock", "Louisiana", "LA"],
"71468": ["Provencal", "Louisiana", "LA"],
"71469": ["Robeline", "Louisiana", "LA"],
"71472": ["Sieper", "Louisiana", "LA"],
"71473": ["Sikes", "Louisiana", "LA"],
"71474": ["Simpson", "Louisiana", "LA"],
"71475": ["Slagle", "Louisiana", "LA"],
"71479": ["Tullos", "Louisiana", "LA"],
"71480": ["Urania", "Louisiana", "LA"],
"71483": ["Winnfield", "Louisiana", "LA"],
"71485": ["Woodworth", "Louisiana", "LA"],
"71486": ["Zwolle", "Louisiana", "LA"],
"71497": ["Natchitoches", "Louisiana", "LA"],
"71601": ["Pine Bluff", "Arkansas", "AR"],
"71602": ["White Hall", "Arkansas", "AR"],
"71603": ["Pine Bluff", "Arkansas", "AR"],
"71630": ["Arkansas City", "Arkansas", "AR"],
"71631": ["Banks", "Arkansas", "AR"],
"71635": ["Crossett", "Arkansas", "AR"],
"71638": ["Dermott", "Arkansas", "AR"],
"71639": ["Dumas", "Arkansas", "AR"],
"71640": ["Eudora", "Arkansas", "AR"],
"71642": ["Fountain Hill", "Arkansas", "AR"],
"71643": ["Gould", "Arkansas", "AR"],
"71644": ["Grady", "Arkansas", "AR"],
"71646": ["Hamburg", "Arkansas", "AR"],
"71647": ["Hermitage", "Arkansas", "AR"],
"71651": ["Jersey", "Arkansas", "AR"],
"71652": ["Kingsland", "Arkansas", "AR"],
"71653": ["Lake Village", "Arkansas", "AR"],
"71654": ["McGehee", "Arkansas", "AR"],
"71655": ["Monticello", "Arkansas", "AR"],
"71658": ["Montrose", "Arkansas", "AR"],
"71659": ["Moscow", "Arkansas", "AR"],
"71660": ["New Edinburg", "Arkansas", "AR"],
"71661": ["Parkdale", "Arkansas", "AR"],
"71662": ["Pickens", "Arkansas", "AR"],
"71663": ["Portland", "Arkansas", "AR"],
"71665": ["Rison", "Arkansas", "AR"],
"71666": ["Rohwer", "Arkansas", "AR"],
"71667": ["Star City", "Arkansas", "AR"],
"71670": ["Tillar", "Arkansas", "AR"],
"71671": ["Warren", "Arkansas", "AR"],
"71674": ["Watson", "Arkansas", "AR"],
"71675": ["Wilmar", "Arkansas", "AR"],
"71676": ["Wilmot", "Arkansas", "AR"],
"71677": ["Winchester", "Arkansas", "AR"],
"71678": ["Yorktown", "Arkansas", "AR"],
"71701": ["Camden", "Arkansas", "AR"],
"71720": ["Bearden", "Arkansas", "AR"],
"71721": ["Beirne", "Arkansas", "AR"],
"71722": ["Bluff City", "Arkansas", "AR"],
"71724": ["Calion", "Arkansas", "AR"],
"71725": ["Carthage", "Arkansas", "AR"],
"71726": ["Chidester", "Arkansas", "AR"],
"71728": ["Curtis", "Arkansas", "AR"],
"71730": ["El Dorado", "Arkansas", "AR"],
"71740": ["Emerson", "Arkansas", "AR"],
"71742": ["Fordyce", "Arkansas", "AR"],
"71743": ["Gurdon", "Arkansas", "AR"],
"71744": ["Hampton", "Arkansas", "AR"],
"71745": ["Harrell", "Arkansas", "AR"],
"71747": ["Huttig", "Arkansas", "AR"],
"71748": ["Ivan", "Arkansas", "AR"],
"71749": ["Junction City", "Arkansas", "AR"],
"71751": ["Louann", "Arkansas", "AR"],
"71752": ["McNeil", "Arkansas", "AR"],
"71753": ["Magnolia", "Arkansas", "AR"],
"71758": ["Mount Holly", "Arkansas", "AR"],
"71759": ["Norphlet", "Arkansas", "AR"],
"71762": ["Smackover", "Arkansas", "AR"],
"71763": ["Sparkman", "Arkansas", "AR"],
"71764": ["Stephens", "Arkansas", "AR"],
"71765": ["Strong", "Arkansas", "AR"],
"71766": ["Thornton", "Arkansas", "AR"],
"71770": ["Waldo", "Arkansas", "AR"],
"71772": ["Whelen Springs", "Arkansas", "AR"],
"71801": ["Hope", "Arkansas", "AR"],
"71820": ["Alleene", "Arkansas", "AR"],
"71822": ["Ashdown", "Arkansas", "AR"],
"71823": ["Ben Lomond", "Arkansas", "AR"],
"71825": ["Blevins", "Arkansas", "AR"],
"71826": ["Bradley", "Arkansas", "AR"],
"71827": ["Buckner", "Arkansas", "AR"],
"71831": ["Columbus", "Arkansas", "AR"],
"71832": ["De Queen", "Arkansas", "AR"],
"71833": ["Dierks", "Arkansas", "AR"],
"71834": ["Doddridge", "Arkansas", "AR"],
"71835": ["Emmet", "Arkansas", "AR"],
"71836": ["Foreman", "Arkansas", "AR"],
"71837": ["Fouke", "Arkansas", "AR"],
"71838": ["Fulton", "Arkansas", "AR"],
"71839": ["Garland City", "Arkansas", "AR"],
"71841": ["Gillham", "Arkansas", "AR"],
"71842": ["Horatio", "Arkansas", "AR"],
"71845": ["Lewisville", "Arkansas", "AR"],
"71846": ["Lockesburg", "Arkansas", "AR"],
"71847": ["McCaskill", "Arkansas", "AR"],
"71851": ["Mineral Springs", "Arkansas", "AR"],
"71852": ["Nashville", "Arkansas", "AR"],
"71853": ["Ogden", "Arkansas", "AR"],
"71854": ["Texarkana", "Arkansas", "AR"],
"71855": ["Ozan", "Arkansas", "AR"],
"71857": ["Prescott", "Arkansas", "AR"],
"71858": ["Rosston", "Arkansas", "AR"],
"71859": ["Saratoga", "Arkansas", "AR"],
"71860": ["Stamps", "Arkansas", "AR"],
"71861": ["Taylor", "Arkansas", "AR"],
"71862": ["Washington", "Arkansas", "AR"],
"71864": ["Willisville", "Arkansas", "AR"],
"71865": ["Wilton", "Arkansas", "AR"],
"71866": ["Winthrop", "Arkansas", "AR"],
"71901": ["Hot Springs National Park", "Arkansas", "AR"],
"71909": ["Hot Springs Village", "Arkansas", "AR"],
"71913": ["Hot Springs National Park", "Arkansas", "AR"],
"71921": ["Amity", "Arkansas", "AR"],
"71922": ["Antoine", "Arkansas", "AR"],
"71923": ["Arkadelphia", "Arkansas", "AR"],
"71929": ["Bismarck", "Arkansas", "AR"],
"71933": ["Bonnerdale", "Arkansas", "AR"],
"71935": ["Caddo Gap", "Arkansas", "AR"],
"71937": ["Cove", "Arkansas", "AR"],
"71940": ["Delight", "Arkansas", "AR"],
"71941": ["Donaldson", "Arkansas", "AR"],
"71942": ["Friendship", "Arkansas", "AR"],
"71943": ["Glenwood", "Arkansas", "AR"],
"71944": ["Grannis", "Arkansas", "AR"],
"71945": ["Hatfield", "Arkansas", "AR"],
"71949": ["Jessieville", "Arkansas", "AR"],
"71950": ["Kirby", "Arkansas", "AR"],
"71952": ["Langley", "Arkansas", "AR"],
"71953": ["Mena", "Arkansas", "AR"],
"71956": ["Mountain Pine", "Arkansas", "AR"],
"71957": ["Mount Ida", "Arkansas", "AR"],
"71958": ["Murfreesboro", "Arkansas", "AR"],
"71959": ["Newhope", "Arkansas", "AR"],
"71960": ["Norman", "Arkansas", "AR"],
"71961": ["Oden", "Arkansas", "AR"],
"71962": ["Okolona", "Arkansas", "AR"],
"71964": ["Pearcy", "Arkansas", "AR"],
"71965": ["Pencil Bluff", "Arkansas", "AR"],
"71968": ["Royal", "Arkansas", "AR"],
"71969": ["Sims", "Arkansas", "AR"],
"71970": ["Story", "Arkansas", "AR"],
"71971": ["Umpire", "Arkansas", "AR"],
"71972": ["Vandervoort", "Arkansas", "AR"],
"71973": ["Wickes", "Arkansas", "AR"],
"71998": ["Arkadelphia", "Arkansas", "AR"],
"71999": ["Arkadelphia", "Arkansas", "AR"],
"72001": ["Adona", "Arkansas", "AR"],
"72002": ["Alexander", "Arkansas", "AR"],
"72003": ["Almyra", "Arkansas", "AR"],
"72004": ["Altheimer", "Arkansas", "AR"],
"72005": ["Amagon", "Arkansas", "AR"],
"72006": ["Augusta", "Arkansas", "AR"],
"72007": ["Austin", "Arkansas", "AR"],
"72010": ["Bald Knob", "Arkansas", "AR"],
"72011": ["Bauxite", "Arkansas", "AR"],
"72012": ["Beebe", "Arkansas", "AR"],
"72013": ["Bee Branch", "Arkansas", "AR"],
"72014": ["Beedeville", "Arkansas", "AR"],
"72015": ["Benton", "Arkansas", "AR"],
"72016": ["Bigelow", "Arkansas", "AR"],
"72017": ["Biscoe", "Arkansas", "AR"],
"72019": ["Benton", "Arkansas", "AR"],
"72020": ["Bradford", "Arkansas", "AR"],
"72021": ["Brinkley", "Arkansas", "AR"],
"72022": ["Bryant", "Arkansas", "AR"],
"72023": ["Cabot", "Arkansas", "AR"],
"72024": ["Carlisle", "Arkansas", "AR"],
"72025": ["Casa", "Arkansas", "AR"],
"72026": ["Casscoe", "Arkansas", "AR"],
"72027": ["Center Ridge", "Arkansas", "AR"],
"72028": ["Choctaw", "Arkansas", "AR"],
"72029": ["Clarendon", "Arkansas", "AR"],
"72030": ["Cleveland", "Arkansas", "AR"],
"72031": ["Clinton", "Arkansas", "AR"],
"72032": ["Conway", "Arkansas", "AR"],
"72034": ["Conway", "Arkansas", "AR"],
"72035": ["Conway", "Arkansas", "AR"],
"72036": ["Cotton Plant", "Arkansas", "AR"],
"72038": ["Crocketts Bluff", "Arkansas", "AR"],
"72039": ["Damascus", "Arkansas", "AR"],
"72040": ["Des Arc", "Arkansas", "AR"],
"72041": ["De Valls Bluff", "Arkansas", "AR"],
"72042": ["De Witt", "Arkansas", "AR"],
"72044": ["Edgemont", "Arkansas", "AR"],
"72045": ["El Paso", "Arkansas", "AR"],
"72046": ["England", "Arkansas", "AR"],
"72047": ["Enola", "Arkansas", "AR"],
"72048": ["Ethel", "Arkansas", "AR"],
"72051": ["Fox", "Arkansas", "AR"],
"72052": ["Garner", "Arkansas", "AR"],
"72053": ["College Station", "Arkansas", "AR"],
"72055": ["Gillett", "Arkansas", "AR"],
"72057": ["Grapevine", "Arkansas", "AR"],
"72058": ["Greenbrier", "Arkansas", "AR"],
"72059": ["Gregory", "Arkansas", "AR"],
"72060": ["Griffithville", "Arkansas", "AR"],
"72061": ["Guy", "Arkansas", "AR"],
"72063": ["Hattieville", "Arkansas", "AR"],
"72064": ["Hazen", "Arkansas", "AR"],
"72065": ["Hensley", "Arkansas", "AR"],
"72066": ["Hickory Plains", "Arkansas", "AR"],
"72067": ["Higden", "Arkansas", "AR"],
"72068": ["Higginson", "Arkansas", "AR"],
"72069": ["Holly Grove", "Arkansas", "AR"],
"72070": ["Houston", "Arkansas", "AR"],
"72072": ["Humnoke", "Arkansas", "AR"],
"72073": ["Humphrey", "Arkansas", "AR"],
"72074": ["Hunter", "Arkansas", "AR"],
"72075": ["Jacksonport", "Arkansas", "AR"],
"72076": ["Jacksonville", "Arkansas", "AR"],
"72079": ["Jefferson", "Arkansas", "AR"],
"72080": ["Jerusalem", "Arkansas", "AR"],
"72081": ["Judsonia", "Arkansas", "AR"],
"72082": ["Kensett", "Arkansas", "AR"],
"72083": ["Keo", "Arkansas", "AR"],
"72084": ["Leola", "Arkansas", "AR"],
"72085": ["Letona", "Arkansas", "AR"],
"72086": ["Lonoke", "Arkansas", "AR"],
"72087": ["Lonsdale", "Arkansas", "AR"],
"72088": ["Fairfield Bay", "Arkansas", "AR"],
"72099": ["Little Rock Air Force Base", "Arkansas", "AR"],
"72101": ["McCrory", "Arkansas", "AR"],
"72102": ["McRae", "Arkansas", "AR"],
"72103": ["Mabelvale", "Arkansas", "AR"],
"72104": ["Malvern", "Arkansas", "AR"],
"72106": ["Mayflower", "Arkansas", "AR"],
"72107": ["Menifee", "Arkansas", "AR"],
"72108": ["Monroe", "Arkansas", "AR"],
"72110": ["Morrilton", "Arkansas", "AR"],
"72111": ["Mount Vernon", "Arkansas", "AR"],
"72112": ["Newport", "Arkansas", "AR"],
"72113": ["Maumelle", "Arkansas", "AR"],
"72114": ["North Little Rock", "Arkansas", "AR"],
"72116": ["North Little Rock", "Arkansas", "AR"],
"72117": ["North Little Rock", "Arkansas", "AR"],
"72118": ["North Little Rock", "Arkansas", "AR"],
"72119": ["North Little Rock", "Arkansas", "AR"],
"72120": ["Sherwood", "Arkansas", "AR"],
"72121": ["Pangburn", "Arkansas", "AR"],
"72122": ["Paron", "Arkansas", "AR"],
"72123": ["Patterson", "Arkansas", "AR"],
"72125": ["Perry", "Arkansas", "AR"],
"72126": ["Perryville", "Arkansas", "AR"],
"72127": ["Plumerville", "Arkansas", "AR"],
"72128": ["Poyen", "Arkansas", "AR"],
"72129": ["Prattsville", "Arkansas", "AR"],
"72130": ["Prim", "Arkansas", "AR"],
"72131": ["Quitman", "Arkansas", "AR"],
"72132": ["Redfield", "Arkansas", "AR"],
"72133": ["Reydell", "Arkansas", "AR"],
"72134": ["Roe", "Arkansas", "AR"],
"72135": ["Roland", "Arkansas", "AR"],
"72136": ["Romance", "Arkansas", "AR"],
"72137": ["Rose Bud", "Arkansas", "AR"],
"72139": ["Russell", "Arkansas", "AR"],
"72140": ["Saint Charles", "Arkansas", "AR"],
"72141": ["Scotland", "Arkansas", "AR"],
"72142": ["Scott", "Arkansas", "AR"],
"72143": ["Searcy", "Arkansas", "AR"],
"72150": ["Sheridan", "Arkansas", "AR"],
"72152": ["Sherrill", "Arkansas", "AR"],
"72153": ["Shirley", "Arkansas", "AR"],
"72156": ["Solgohachia", "Arkansas", "AR"],
"72157": ["Springfield", "Arkansas", "AR"],
"72160": ["Stuttgart", "Arkansas", "AR"],
"72165": ["Thida", "Arkansas", "AR"],
"72166": ["Tichnor", "Arkansas", "AR"],
"72167": ["Traskwood", "Arkansas", "AR"],
"72168": ["Tucker", "Arkansas", "AR"],
"72169": ["Tupelo", "Arkansas", "AR"],
"72170": ["Ulm", "Arkansas", "AR"],
"72173": ["Vilonia", "Arkansas", "AR"],
"72175": ["Wabbaseka", "Arkansas", "AR"],
"72176": ["Ward", "Arkansas", "AR"],
"72178": ["West Point", "Arkansas", "AR"],
"72179": ["Wilburn", "Arkansas", "AR"],
"72180": ["Woodson", "Arkansas", "AR"],
"72181": ["Wooster", "Arkansas", "AR"],
"72182": ["Wright", "Arkansas", "AR"],
"72183": ["Wrightsville", "Arkansas", "AR"],
"72199": ["North Little Rock", "Arkansas", "AR"],
"72201": ["Little Rock", "Arkansas", "AR"],
"72202": ["Little Rock", "Arkansas", "AR"],
"72204": ["Little Rock", "Arkansas", "AR"],
"72205": ["Little Rock", "Arkansas", "AR"],
"72206": ["Little Rock", "Arkansas", "AR"],
"72207": ["Little Rock", "Arkansas", "AR"],
"72209": ["Little Rock", "Arkansas", "AR"],
"72210": ["Little Rock", "Arkansas", "AR"],
"72211": ["Little Rock", "Arkansas", "AR"],
"72212": ["Little Rock", "Arkansas", "AR"],
"72223": ["Little Rock", "Arkansas", "AR"],
"72227": ["Little Rock", "Arkansas", "AR"],
"72301": ["West Memphis", "Arkansas", "AR"],
"72311": ["Aubrey", "Arkansas", "AR"],
"72313": ["Bassett", "Arkansas", "AR"],
"72315": ["Blytheville", "Arkansas", "AR"],
"72320": ["Brickeys", "Arkansas", "AR"],
"72322": ["Caldwell", "Arkansas", "AR"],
"72324": ["Cherry Valley", "Arkansas", "AR"],
"72325": ["Clarkedale", "Arkansas", "AR"],
"72326": ["Colt", "Arkansas", "AR"],
"72327": ["Crawfordsville", "Arkansas", "AR"],
"72328": ["Crumrod", "Arkansas", "AR"],
"72329": ["Driver", "Arkansas", "AR"],
"72330": ["Dyess", "Arkansas", "AR"],
"72331": ["Earle", "Arkansas", "AR"],
"72332": ["Edmondson", "Arkansas", "AR"],
"72333": ["Elaine", "Arkansas", "AR"],
"72335": ["Forrest City", "Arkansas", "AR"],
"72338": ["Frenchmans Bayou", "Arkansas", "AR"],
"72339": ["Gilmore", "Arkansas", "AR"],
"72340": ["Goodwin", "Arkansas", "AR"],
"72341": ["Haynes", "Arkansas", "AR"],
"72342": ["Helena", "Arkansas", "AR"],
"72346": ["Heth", "Arkansas", "AR"],
"72347": ["Hickory Ridge", "Arkansas", "AR"],
"72348": ["Hughes", "Arkansas", "AR"],
"72350": ["Joiner", "Arkansas", "AR"],
"72351": ["Keiser", "Arkansas", "AR"],
"72352": ["La Grange", "Arkansas", "AR"],
"72353": ["Lambrook", "Arkansas", "AR"],
"72354": ["Lepanto", "Arkansas", "AR"],
"72355": ["Lexa", "Arkansas", "AR"],
"72358": ["Luxora", "Arkansas", "AR"],
"72359": ["Madison", "Arkansas", "AR"],
"72360": ["Marianna", "Arkansas", "AR"],
"72364": ["Marion", "Arkansas", "AR"],
"72365": ["Marked Tree", "Arkansas", "AR"],
"72366": ["Marvell", "Arkansas", "AR"],
"72367": ["Mellwood", "Arkansas", "AR"],
"72368": ["Moro", "Arkansas", "AR"],
"72369": ["Oneida", "Arkansas", "AR"],
"72370": ["Osceola", "Arkansas", "AR"],
"72372": ["Palestine", "Arkansas", "AR"],
"72373": ["Parkin", "Arkansas", "AR"],
"72374": ["Poplar Grove", "Arkansas", "AR"],
"72376": ["Proctor", "Arkansas", "AR"],
"72377": ["Rivervale", "Arkansas", "AR"],
"72379": ["Snow Lake", "Arkansas", "AR"],
"72383": ["Turner", "Arkansas", "AR"],
"72384": ["Turrell", "Arkansas", "AR"],
"72386": ["Tyronza", "Arkansas", "AR"],
"72387": ["Vanndale", "Arkansas", "AR"],
"72389": ["Wabash", "Arkansas", "AR"],
"72390": ["West Helena", "Arkansas", "AR"],
"72391": ["West Ridge", "Arkansas", "AR"],
"72392": ["Wheatley", "Arkansas", "AR"],
"72394": ["Widener", "Arkansas", "AR"],
"72395": ["Wilson", "Arkansas", "AR"],
"72396": ["Wynne", "Arkansas", "AR"],
"72401": ["Jonesboro", "Arkansas", "AR"],
"72404": ["Jonesboro", "Arkansas", "AR"],
"72405": ["Jonesboro", "Arkansas", "AR"],
"72410": ["Alicia", "Arkansas", "AR"],
"72411": ["Bay", "Arkansas", "AR"],
"72412": ["Beech Grove", "Arkansas", "AR"],
"72413": ["Biggers", "Arkansas", "AR"],
"72414": ["Black Oak", "Arkansas", "AR"],
"72415": ["Black Rock", "Arkansas", "AR"],
"72416": ["Bono", "Arkansas", "AR"],
"72417": ["Brookland", "Arkansas", "AR"],
"72419": ["Caraway", "Arkansas", "AR"],
"72421": ["Cash", "Arkansas", "AR"],
"72422": ["Corning", "Arkansas", "AR"],
"72424": ["Datto", "Arkansas", "AR"],
"72425": ["Delaplaine", "Arkansas", "AR"],
"72426": ["Dell", "Arkansas", "AR"],
"72427": ["Egypt", "Arkansas", "AR"],
"72428": ["Etowah", "Arkansas", "AR"],
"72429": ["Fisher", "Arkansas", "AR"],
"72430": ["Greenway", "Arkansas", "AR"],
"72431": ["Grubbs", "Arkansas", "AR"],
"72432": ["Harrisburg", "Arkansas", "AR"],
"72433": ["Hoxie", "Arkansas", "AR"],
"72434": ["Imboden", "Arkansas", "AR"],
"72435": ["Knobel", "Arkansas", "AR"],
"72436": ["Lafe", "Arkansas", "AR"],
"72437": ["Lake City", "Arkansas", "AR"],
"72438": ["Leachville", "Arkansas", "AR"],
"72440": ["Lynn", "Arkansas", "AR"],
"72441": ["McDougal", "Arkansas", "AR"],
"72442": ["Manila", "Arkansas", "AR"],
"72443": ["Marmaduke", "Arkansas", "AR"],
"72444": ["Maynard", "Arkansas", "AR"],
"72445": ["Minturn", "Arkansas", "AR"],
"72447": ["Monette", "Arkansas", "AR"],
"72449": ["O'Kean", "Arkansas", "AR"],
"72450": ["Paragould", "Arkansas", "AR"],
"72453": ["Peach Orchard", "Arkansas", "AR"],
"72454": ["Piggott", "Arkansas", "AR"],
"72455": ["Pocahontas", "Arkansas", "AR"],
"72456": ["Pollard", "Arkansas", "AR"],
"72457": ["Portia", "Arkansas", "AR"],
"72458": ["Powhatan", "Arkansas", "AR"],
"72459": ["Ravenden", "Arkansas", "AR"],
"72460": ["Ravenden Springs", "Arkansas", "AR"],
"72461": ["Rector", "Arkansas", "AR"],
"72462": ["Reyno", "Arkansas", "AR"],
"72464": ["Saint Francis", "Arkansas", "AR"],
"72466": ["Smithville", "Arkansas", "AR"],
"72467": ["State University", "Arkansas", "AR"],
"72469": ["Strawberry", "Arkansas", "AR"],
"72470": ["Success", "Arkansas", "AR"],
"72471": ["Swifton", "Arkansas", "AR"],
"72472": ["Trumann", "Arkansas", "AR"],
"72473": ["Tuckerman", "Arkansas", "AR"],
"72476": ["Walnut Ridge", "Arkansas", "AR"],
"72478": ["Warm Springs", "Arkansas", "AR"],
"72479": ["Weiner", "Arkansas", "AR"],
"72482": ["Williford", "Arkansas", "AR"],
"72501": ["Batesville", "Arkansas", "AR"],
"72512": ["Horseshoe Bend", "Arkansas", "AR"],
"72513": ["Ash Flat", "Arkansas", "AR"],
"72515": ["Bexar", "Arkansas", "AR"],
"72517": ["Brockwell", "Arkansas", "AR"],
"72519": ["Calico Rock", "Arkansas", "AR"],
"72520": ["Camp", "Arkansas", "AR"],
"72521": ["Cave City", "Arkansas", "AR"],
"72522": ["Charlotte", "Arkansas", "AR"],
"72523": ["Concord", "Arkansas", "AR"],
"72524": ["Cord", "Arkansas", "AR"],
"72526": ["Cushman", "Arkansas", "AR"],
"72527": ["Desha", "Arkansas", "AR"],
"72528": ["Dolph", "Arkansas", "AR"],
"72529": ["Cherokee Village", "Arkansas", "AR"],
"72530": ["Drasco", "Arkansas", "AR"],
"72531": ["Elizabeth", "Arkansas", "AR"],
"72532": ["Evening Shade", "Arkansas", "AR"],
"72533": ["Fifty Six", "Arkansas", "AR"],
"72534": ["Floral", "Arkansas", "AR"],
"72536": ["Franklin", "Arkansas", "AR"],
"72537": ["Gamaliel", "Arkansas", "AR"],
"72538": ["Gepp", "Arkansas", "AR"],
"72539": ["Glencoe", "Arkansas", "AR"],
"72540": ["Guion", "Arkansas", "AR"],
"72542": ["Hardy", "Arkansas", "AR"],
"72543": ["Heber Springs", "Arkansas", "AR"],
"72544": ["Henderson", "Arkansas", "AR"],
"72546": ["Ida", "Arkansas", "AR"],
"72550": ["Locust Grove", "Arkansas", "AR"],
"72553": ["Magness", "Arkansas", "AR"],
"72554": ["Mammoth Spring", "Arkansas", "AR"],
"72555": ["Marcella", "Arkansas", "AR"],
"72556": ["Melbourne", "Arkansas", "AR"],
"72560": ["Mountain View", "Arkansas", "AR"],
"72561": ["Mount Pleasant", "Arkansas", "AR"],
"72562": ["Newark", "Arkansas", "AR"],
"72564": ["Oil Trough", "Arkansas", "AR"],
"72565": ["Oxford", "Arkansas", "AR"],
"72566": ["Pineville", "Arkansas", "AR"],
"72567": ["Pleasant Grove", "Arkansas", "AR"],
"72568": ["Pleasant Plains", "Arkansas", "AR"],
"72569": ["Poughkeepsie", "Arkansas", "AR"],
"72571": ["Rosie", "Arkansas", "AR"],
"72572": ["Saffell", "Arkansas", "AR"],
"72573": ["Sage", "Arkansas", "AR"],
"72576": ["Salem", "Arkansas", "AR"],
"72577": ["Sidney", "Arkansas", "AR"],
"72578": ["Sturkie", "Arkansas", "AR"],
"72579": ["Sulphur Rock", "Arkansas", "AR"],
"72581": ["Tumbling Shoals", "Arkansas", "AR"],
"72583": ["Viola", "Arkansas", "AR"],
"72584": ["Violet Hill", "Arkansas", "AR"],
"72585": ["Wideman", "Arkansas", "AR"],
"72587": ["Wiseman", "Arkansas", "AR"],
"72601": ["Harrison", "Arkansas", "AR"],
"72611": ["Alpena", "Arkansas", "AR"],
"72615": ["Bergman", "Arkansas", "AR"],
"72616": ["Berryville", "Arkansas", "AR"],
"72617": ["Big Flat", "Arkansas", "AR"],
"72619": ["Bull Shoals", "Arkansas", "AR"],
"72623": ["Clarkridge", "Arkansas", "AR"],
"72624": ["Compton", "Arkansas", "AR"],
"72626": ["Cotter", "Arkansas", "AR"],
"72628": ["Deer", "Arkansas", "AR"],
"72629": ["Dennard", "Arkansas", "AR"],
"72630": ["Diamond City", "Arkansas", "AR"],
"72631": ["Eureka Springs", "Arkansas", "AR"],
"72632": ["Eureka Springs", "Arkansas", "AR"],
"72633": ["Everton", "Arkansas", "AR"],
"72634": ["Flippin", "Arkansas", "AR"],
"72635": ["Gassville", "Arkansas", "AR"],
"72636": ["Gilbert", "Arkansas", "AR"],
"72638": ["Green Forest", "Arkansas", "AR"],
"72639": ["Harriet", "Arkansas", "AR"],
"72640": ["Hasty", "Arkansas", "AR"],
"72641": ["Jasper", "Arkansas", "AR"],
"72642": ["Lakeview", "Arkansas", "AR"],
"72644": ["Lead Hill", "Arkansas", "AR"],
"72645": ["Leslie", "Arkansas", "AR"],
"72648": ["Marble Falls", "Arkansas", "AR"],
"72650": ["Marshall", "Arkansas", "AR"],
"72651": ["Midway", "Arkansas", "AR"],
"72653": ["Mountain Home", "Arkansas", "AR"],
"72655": ["Mount Judea", "Arkansas", "AR"],
"72658": ["Norfork", "Arkansas", "AR"],
"72660": ["Oak Grove", "Arkansas", "AR"],
"72661": ["Oakland", "Arkansas", "AR"],
"72662": ["Omaha", "Arkansas", "AR"],
"72663": ["Onia", "Arkansas", "AR"],
"72666": ["Parthenon", "Arkansas", "AR"],
"72668": ["Peel", "Arkansas", "AR"],
"72669": ["Pindall", "Arkansas", "AR"],
"72670": ["Ponca", "Arkansas", "AR"],
"72672": ["Pyatt", "Arkansas", "AR"],
"72675": ["Saint Joe", "Arkansas", "AR"],
"72677": ["Summit", "Arkansas", "AR"],
"72679": ["Tilly", "Arkansas", "AR"],
"72680": ["Timbo", "Arkansas", "AR"],
"72682": ["Valley Springs", "Arkansas", "AR"],
"72683": ["Vendor", "Arkansas", "AR"],
"72685": ["Western Grove", "Arkansas", "AR"],
"72686": ["Witts Springs", "Arkansas", "AR"],
"72687": ["Yellville", "Arkansas", "AR"],
"72701": ["Fayetteville", "Arkansas", "AR"],
"72703": ["Fayetteville", "Arkansas", "AR"],
"72704": ["Fayetteville", "Arkansas", "AR"],
"72712": ["Bentonville", "Arkansas", "AR"],
"72713": ["Bentonville", "Arkansas", "AR"],
"72714": ["Bella Vista", "Arkansas", "AR"],
"72715": ["Bella Vista", "Arkansas", "AR"],
"72717": ["Canehill", "Arkansas", "AR"],
"72718": ["Cave Springs", "Arkansas", "AR"],
"72719": ["Centerton", "Arkansas", "AR"],
"72721": ["Combs", "Arkansas", "AR"],
"72722": ["Decatur", "Arkansas", "AR"],
"72727": ["Elkins", "Arkansas", "AR"],
"72729": ["Evansville", "Arkansas", "AR"],
"72730": ["Farmington", "Arkansas", "AR"],
"72732": ["Garfield", "Arkansas", "AR"],
"72734": ["Gentry", "Arkansas", "AR"],
"72736": ["Gravette", "Arkansas", "AR"],
"72738": ["Hindsville", "Arkansas", "AR"],
"72739": ["Hiwasse", "Arkansas", "AR"],
"72740": ["Huntsville", "Arkansas", "AR"],
"72742": ["Kingston", "Arkansas", "AR"],
"72744": ["Lincoln", "Arkansas", "AR"],
"72745": ["Lowell", "Arkansas", "AR"],
"72747": ["Maysville", "Arkansas", "AR"],
"72749": ["Morrow", "Arkansas", "AR"],
"72751": ["Pea Ridge", "Arkansas", "AR"],
"72752": ["Pettigrew", "Arkansas", "AR"],
"72753": ["Prairie Grove", "Arkansas", "AR"],
"72756": ["Rogers", "Arkansas", "AR"],
"72758": ["Rogers", "Arkansas", "AR"],
"72760": ["Saint Paul", "Arkansas", "AR"],
"72761": ["Siloam Springs", "Arkansas", "AR"],
"72762": ["Springdale", "Arkansas", "AR"],
"72764": ["Springdale", "Arkansas", "AR"],
"72768": ["Sulphur Springs", "Arkansas", "AR"],
"72769": ["Summers", "Arkansas", "AR"],
"72773": ["Wesley", "Arkansas", "AR"],
"72774": ["West Fork", "Arkansas", "AR"],
"72776": ["Witter", "Arkansas", "AR"],
"72801": ["Russellville", "Arkansas", "AR"],
"72802": ["Russellville", "Arkansas", "AR"],
"72821": ["Altus", "Arkansas", "AR"],
"72823": ["Atkins", "Arkansas", "AR"],
"72824": ["Belleville", "Arkansas", "AR"],
"72826": ["Blue Mountain", "Arkansas", "AR"],
"72827": ["Bluffton", "Arkansas", "AR"],
"72828": ["Briggsville", "Arkansas", "AR"],
"72829": ["Centerville", "Arkansas", "AR"],
"72830": ["Clarksville", "Arkansas", "AR"],
"72832": ["Coal Hill", "Arkansas", "AR"],
"72833": ["Danville", "Arkansas", "AR"],
"72834": ["Dardanelle", "Arkansas", "AR"],
"72835": ["Delaware", "Arkansas", "AR"],
"72837": ["Dover", "Arkansas", "AR"],
"72838": ["Gravelly", "Arkansas", "AR"],
"72839": ["Hagarville", "Arkansas", "AR"],
"72840": ["Hartman", "Arkansas", "AR"],
"72841": ["Harvey", "Arkansas", "AR"],
"72842": ["Havana", "Arkansas", "AR"],
"72843": ["Hector", "Arkansas", "AR"],
"72845": ["Knoxville", "Arkansas", "AR"],
"72846": ["Lamar", "Arkansas", "AR"],
"72847": ["London", "Arkansas", "AR"],
"72851": ["New Blaine", "Arkansas", "AR"],
"72852": ["Oark", "Arkansas", "AR"],
"72853": ["Ola", "Arkansas", "AR"],
"72854": ["Ozone", "Arkansas", "AR"],
"72855": ["Paris", "Arkansas", "AR"],
"72856": ["Pelsor", "Arkansas", "AR"],
"72857": ["Plainview", "Arkansas", "AR"],
"72858": ["Pottsville", "Arkansas", "AR"],
"72860": ["Rover", "Arkansas", "AR"],
"72863": ["Scranton", "Arkansas", "AR"],
"72865": ["Subiaco", "Arkansas", "AR"],
"72901": ["Fort Smith", "Arkansas", "AR"],
"72903": ["Fort Smith", "Arkansas", "AR"],
"72904": ["Fort Smith", "Arkansas", "AR"],
"72905": ["Fort Smith", "Arkansas", "AR"],
"72908": ["Fort Smith", "Arkansas", "AR"],
"72916": ["Fort Smith", "Arkansas", "AR"],
"72921": ["Alma", "Arkansas", "AR"],
"72923": ["Barling", "Arkansas", "AR"],
"72926": ["Boles", "Arkansas", "AR"],
"72927": ["Booneville", "Arkansas", "AR"],
"72928": ["Branch", "Arkansas", "AR"],
"72930": ["Cecil", "Arkansas", "AR"],
"72932": ["Cedarville", "Arkansas", "AR"],
"72933": ["Charleston", "Arkansas", "AR"],
"72934": ["Chester", "Arkansas", "AR"],
"72935": ["Dyer", "Arkansas", "AR"],
"72936": ["Greenwood", "Arkansas", "AR"],
"72937": ["Hackett", "Arkansas", "AR"],
"72938": ["Hartford", "Arkansas", "AR"],
"72940": ["Huntington", "Arkansas", "AR"],
"72941": ["Lavaca", "Arkansas", "AR"],
"72943": ["Magazine", "Arkansas", "AR"],
"72944": ["Mansfield", "Arkansas", "AR"],
"72945": ["Midland", "Arkansas", "AR"],
"72946": ["Mountainburg", "Arkansas", "AR"],
"72947": ["Mulberry", "Arkansas", "AR"],
"72948": ["Natural Dam", "Arkansas", "AR"],
"72949": ["Ozark", "Arkansas", "AR"],
"72950": ["Parks", "Arkansas", "AR"],
"72951": ["Ratcliff", "Arkansas", "AR"],
"72952": ["Rudy", "Arkansas", "AR"],
"72955": ["Uniontown", "Arkansas", "AR"],
"72956": ["Van Buren", "Arkansas", "AR"],
"72958": ["Waldron", "Arkansas", "AR"],
"72959": ["Winslow", "Arkansas", "AR"],
"73001": ["Albert", "Oklahoma", "OK"],
"73002": ["Alex", "Oklahoma", "OK"],
"73003": ["Edmond", "Oklahoma", "OK"],
"73004": ["Amber", "Oklahoma", "OK"],
"73005": ["Anadarko", "Oklahoma", "OK"],
"73006": ["Apache", "Oklahoma", "OK"],
"73007": ["Arcadia", "Oklahoma", "OK"],
"73008": ["Bethany", "Oklahoma", "OK"],
"73009": ["Binger", "Oklahoma", "OK"],
"73010": ["Blanchard", "Oklahoma", "OK"],
"73011": ["Bradley", "Oklahoma", "OK"],
"73012": ["Edmond", "Oklahoma", "OK"],
"73013": ["Edmond", "Oklahoma", "OK"],
"73014": ["Calumet", "Oklahoma", "OK"],
"73015": ["Carnegie", "Oklahoma", "OK"],
"73016": ["Cashion", "Oklahoma", "OK"],
"73017": ["Cement", "Oklahoma", "OK"],
"73018": ["Chickasha", "Oklahoma", "OK"],
"73019": ["Norman", "Oklahoma", "OK"],
"73020": ["Choctaw", "Oklahoma", "OK"],
"73021": ["Colony", "Oklahoma", "OK"],
"73022": ["Concho", "Oklahoma", "OK"],
"73024": ["Corn", "Oklahoma", "OK"],
"73025": ["Edmond", "Oklahoma", "OK"],
"73026": ["Norman", "Oklahoma", "OK"],
"73027": ["Coyle", "Oklahoma", "OK"],
"73028": ["Crescent", "Oklahoma", "OK"],
"73029": ["Cyril", "Oklahoma", "OK"],
"73030": ["Davis", "Oklahoma", "OK"],
"73031": ["Dibble", "Oklahoma", "OK"],
"73032": ["Dougherty", "Oklahoma", "OK"],
"73033": ["Eakly", "Oklahoma", "OK"],
"73034": ["Edmond", "Oklahoma", "OK"],
"73036": ["El Reno", "Oklahoma", "OK"],
"73038": ["Fort Cobb", "Oklahoma", "OK"],
"73040": ["Geary", "Oklahoma", "OK"],
"73041": ["Gotebo", "Oklahoma", "OK"],
"73042": ["Gracemont", "Oklahoma", "OK"],
"73043": ["Greenfield", "Oklahoma", "OK"],
"73044": ["Guthrie", "Oklahoma", "OK"],
"73045": ["Harrah", "Oklahoma", "OK"],
"73047": ["Hinton", "Oklahoma", "OK"],
"73048": ["Hydro", "Oklahoma", "OK"],
"73049": ["Jones", "Oklahoma", "OK"],
"73050": ["Langston", "Oklahoma", "OK"],
"73051": ["Lexington", "Oklahoma", "OK"],
"73052": ["Lindsay", "Oklahoma", "OK"],
"73053": ["Lookeba", "Oklahoma", "OK"],
"73054": ["Luther", "Oklahoma", "OK"],
"73055": ["Marlow", "Oklahoma", "OK"],
"73056": ["Marshall", "Oklahoma", "OK"],
"73057": ["Maysville", "Oklahoma", "OK"],
"73058": ["Meridian", "Oklahoma", "OK"],
"73059": ["Minco", "Oklahoma", "OK"],
"73061": ["Morrison", "Oklahoma", "OK"],
"73062": ["Mountain View", "Oklahoma", "OK"],
"73063": ["Mulhall", "Oklahoma", "OK"],
"73064": ["Mustang", "Oklahoma", "OK"],
"73065": ["Newcastle", "Oklahoma", "OK"],
"73066": ["Nicoma Park", "Oklahoma", "OK"],
"73067": ["Ninnekah", "Oklahoma", "OK"],
"73068": ["Noble", "Oklahoma", "OK"],
"73069": ["Norman", "Oklahoma", "OK"],
"73070": ["Norman", "Oklahoma", "OK"],
"73071": ["Norman", "Oklahoma", "OK"],
"73072": ["Norman", "Oklahoma", "OK"],
"73073": ["Orlando", "Oklahoma", "OK"],
"73074": ["Paoli", "Oklahoma", "OK"],
"73075": ["Pauls Valley", "Oklahoma", "OK"],
"73077": ["Perry", "Oklahoma", "OK"],
"73078": ["Piedmont", "Oklahoma", "OK"],
"73079": ["Pocasset", "Oklahoma", "OK"],
"73080": ["Purcell", "Oklahoma", "OK"],
"73082": ["Rush Springs", "Oklahoma", "OK"],
"73084": ["Spencer", "Oklahoma", "OK"],
"73086": ["Sulphur", "Oklahoma", "OK"],
"73089": ["Tuttle", "Oklahoma", "OK"],
"73090": ["Union City", "Oklahoma", "OK"],
"73092": ["Verden", "Oklahoma", "OK"],
"73093": ["Washington", "Oklahoma", "OK"],
"73095": ["Wayne", "Oklahoma", "OK"],
"73096": ["Weatherford", "Oklahoma", "OK"],
"73097": ["Wheatland", "Oklahoma", "OK"],
"73098": ["Wynnewood", "Oklahoma", "OK"],
"73099": ["Yukon", "Oklahoma", "OK"],
"73102": ["Oklahoma City", "Oklahoma", "OK"],
"73103": ["Oklahoma City", "Oklahoma", "OK"],
"73104": ["Oklahoma City", "Oklahoma", "OK"],
"73105": ["Oklahoma City", "Oklahoma", "OK"],
"73106": ["Oklahoma City", "Oklahoma", "OK"],
"73107": ["Oklahoma City", "Oklahoma", "OK"],
"73108": ["Oklahoma City", "Oklahoma", "OK"],
"73109": ["Oklahoma City", "Oklahoma", "OK"],
"73110": ["Oklahoma City", "Oklahoma", "OK"],
"73111": ["Oklahoma City", "Oklahoma", "OK"],
"73112": ["Oklahoma City", "Oklahoma", "OK"],
"73114": ["Oklahoma City", "Oklahoma", "OK"],
"73115": ["Oklahoma City", "Oklahoma", "OK"],
"73116": ["Oklahoma City", "Oklahoma", "OK"],
"73117": ["Oklahoma City", "Oklahoma", "OK"],
"73118": ["Oklahoma City", "Oklahoma", "OK"],
"73119": ["Oklahoma City", "Oklahoma", "OK"],
"73120": ["Oklahoma City", "Oklahoma", "OK"],
"73121": ["Oklahoma City", "Oklahoma", "OK"],
"73122": ["Oklahoma City", "Oklahoma", "OK"],
"73127": ["Oklahoma City", "Oklahoma", "OK"],
"73128": ["Oklahoma City", "Oklahoma", "OK"],
"73129": ["Oklahoma City", "Oklahoma", "OK"],
"73130": ["Oklahoma City", "Oklahoma", "OK"],
"73131": ["Oklahoma City", "Oklahoma", "OK"],
"73132": ["Oklahoma City", "Oklahoma", "OK"],
"73134": ["Oklahoma City", "Oklahoma", "OK"],
"73135": ["Oklahoma City", "Oklahoma", "OK"],
"73139": ["Oklahoma City", "Oklahoma", "OK"],
"73141": ["Oklahoma City", "Oklahoma", "OK"],
"73142": ["Oklahoma City", "Oklahoma", "OK"],
"73145": ["Oklahoma City", "Oklahoma", "OK"],
"73149": ["Oklahoma City", "Oklahoma", "OK"],
"73150": ["Oklahoma City", "Oklahoma", "OK"],
"73151": ["Oklahoma City", "Oklahoma", "OK"],
"73159": ["Oklahoma City", "Oklahoma", "OK"],
"73160": ["Oklahoma City", "Oklahoma", "OK"],
"73162": ["Oklahoma City", "Oklahoma", "OK"],
"73165": ["Oklahoma City", "Oklahoma", "OK"],
"73169": ["Oklahoma City", "Oklahoma", "OK"],
"73170": ["Oklahoma City", "Oklahoma", "OK"],
"73173": ["Oklahoma City", "Oklahoma", "OK"],
"73179": ["Oklahoma City", "Oklahoma", "OK"],
"73401": ["Ardmore", "Oklahoma", "OK"],
"73425": ["Countyline", "Oklahoma", "OK"],
"73430": ["Burneyville", "Oklahoma", "OK"],
"73432": ["Coleman", "Oklahoma", "OK"],
"73433": ["Elmore City", "Oklahoma", "OK"],
"73434": ["Foster", "Oklahoma", "OK"],
"73435": ["Fox", "Oklahoma", "OK"],
"73436": ["Gene Autry", "Oklahoma", "OK"],
"73437": ["Graham", "Oklahoma", "OK"],
"73438": ["Healdton", "Oklahoma", "OK"],
"73439": ["Kingston", "Oklahoma", "OK"],
"73440": ["Lebanon", "Oklahoma", "OK"],
"73441": ["Leon", "Oklahoma", "OK"],
"73442": ["Loco", "Oklahoma", "OK"],
"73443": ["Lone Grove", "Oklahoma", "OK"],
"73444": ["Hennepin", "Oklahoma", "OK"],
"73446": ["Madill", "Oklahoma", "OK"],
"73447": ["Mannsville", "Oklahoma", "OK"],
"73448": ["Marietta", "Oklahoma", "OK"],
"73449": ["Mead", "Oklahoma", "OK"],
"73450": ["Milburn", "Oklahoma", "OK"],
"73453": ["Overbrook", "Oklahoma", "OK"],
"73455": ["Ravia", "Oklahoma", "OK"],
"73456": ["Ringling", "Oklahoma", "OK"],
"73458": ["Springer", "Oklahoma", "OK"],
"73459": ["Thackerville", "Oklahoma", "OK"],
"73460": ["Tishomingo", "Oklahoma", "OK"],
"73461": ["Wapanucka", "Oklahoma", "OK"],
"73463": ["Wilson", "Oklahoma", "OK"],
"73481": ["Ratliff City", "Oklahoma", "OK"],
"73487": ["Tatums", "Oklahoma", "OK"],
"73488": ["Tussy", "Oklahoma", "OK"],
"73491": ["Velma", "Oklahoma", "OK"],
"73501": ["Lawton", "Oklahoma", "OK"],
"73503": ["Fort Sill", "Oklahoma", "OK"],
"73505": ["Lawton", "Oklahoma", "OK"],
"73507": ["Lawton", "Oklahoma", "OK"],
"73520": ["Addington", "Oklahoma", "OK"],
"73521": ["Altus", "Oklahoma", "OK"],
"73523": ["Altus Afb", "Oklahoma", "OK"],
"73526": ["Blair", "Oklahoma", "OK"],
"73527": ["Cache", "Oklahoma", "OK"],
"73528": ["Chattanooga", "Oklahoma", "OK"],
"73529": ["Comanche", "Oklahoma", "OK"],
"73530": ["Davidson", "Oklahoma", "OK"],
"73531": ["Devol", "Oklahoma", "OK"],
"73532": ["Duke", "Oklahoma", "OK"],
"73533": ["Duncan", "Oklahoma", "OK"],
"73537": ["Eldorado", "Oklahoma", "OK"],
"73538": ["Elgin", "Oklahoma", "OK"],
"73539": ["Elmer", "Oklahoma", "OK"],
"73540": ["Faxon", "Oklahoma", "OK"],
"73541": ["Fletcher", "Oklahoma", "OK"],
"73542": ["Frederick", "Oklahoma", "OK"],
"73543": ["Geronimo", "Oklahoma", "OK"],
"73544": ["Gould", "Oklahoma", "OK"],
"73546": ["Grandfield", "Oklahoma", "OK"],
"73547": ["Granite", "Oklahoma", "OK"],
"73548": ["Hastings", "Oklahoma", "OK"],
"73549": ["Headrick", "Oklahoma", "OK"],
"73550": ["Hollis", "Oklahoma", "OK"],
"73551": ["Hollister", "Oklahoma", "OK"],
"73552": ["Indiahoma", "Oklahoma", "OK"],
"73553": ["Loveland", "Oklahoma", "OK"],
"73554": ["Mangum", "Oklahoma", "OK"],
"73555": ["Manitou", "Oklahoma", "OK"],
"73556": ["Martha", "Oklahoma", "OK"],
"73557": ["Medicine Park", "Oklahoma", "OK"],
"73559": ["Mountain Park", "Oklahoma", "OK"],
"73560": ["Olustee", "Oklahoma", "OK"],
"73562": ["Randlett", "Oklahoma", "OK"],
"73564": ["Roosevelt", "Oklahoma", "OK"],
"73565": ["Ryan", "Oklahoma", "OK"],
"73566": ["Snyder", "Oklahoma", "OK"],
"73567": ["Sterling", "Oklahoma", "OK"],
"73568": ["Temple", "Oklahoma", "OK"],
"73569": ["Terral", "Oklahoma", "OK"],
"73570": ["Tipton", "Oklahoma", "OK"],
"73571": ["Vinson", "Oklahoma", "OK"],
"73572": ["Walters", "Oklahoma", "OK"],
"73573": ["Waurika", "Oklahoma", "OK"],
"73601": ["Clinton", "Oklahoma", "OK"],
"73620": ["Arapaho", "Oklahoma", "OK"],
"73622": ["Bessie", "Oklahoma", "OK"],
"73624": ["Burns Flat", "Oklahoma", "OK"],
"73625": ["Butler", "Oklahoma", "OK"],
"73626": ["Canute", "Oklahoma", "OK"],
"73627": ["Carter", "Oklahoma", "OK"],
"73628": ["Cheyenne", "Oklahoma", "OK"],
"73632": ["Cordell", "Oklahoma", "OK"],
"73638": ["Crawford", "Oklahoma", "OK"],
"73639": ["Custer City", "Oklahoma", "OK"],
"73641": ["Dill City", "Oklahoma", "OK"],
"73642": ["Durham", "Oklahoma", "OK"],
"73644": ["Elk City", "Oklahoma", "OK"],
"73645": ["Erick", "Oklahoma", "OK"],
"73646": ["Fay", "Oklahoma", "OK"],
"73647": ["Foss", "Oklahoma", "OK"],
"73650": ["Hammon", "Oklahoma", "OK"],
"73651": ["Hobart", "Oklahoma", "OK"],
"73654": ["Leedey", "Oklahoma", "OK"],
"73655": ["Lone Wolf", "Oklahoma", "OK"],
"73658": ["Oakwood", "Oklahoma", "OK"],
"73659": ["Putnam", "Oklahoma", "OK"],
"73660": ["Reydon", "Oklahoma", "OK"],
"73661": ["Rocky", "Oklahoma", "OK"],
"73662": ["Sayre", "Oklahoma", "OK"],
"73663": ["Seiling", "Oklahoma", "OK"],
"73664": ["Sentinel", "Oklahoma", "OK"],
"73666": ["Sweetwater", "Oklahoma", "OK"],
"73667": ["Taloga", "Oklahoma", "OK"],
"73668": ["Texola", "Oklahoma", "OK"],
"73669": ["Thomas", "Oklahoma", "OK"],
"73673": ["Willow", "Oklahoma", "OK"],
"73701": ["Enid", "Oklahoma", "OK"],
"73703": ["Enid", "Oklahoma", "OK"],
"73705": ["Enid", "Oklahoma", "OK"],
"73716": ["Aline", "Oklahoma", "OK"],
"73717": ["Alva", "Oklahoma", "OK"],
"73718": ["Ames", "Oklahoma", "OK"],
"73719": ["Amorita", "Oklahoma", "OK"],
"73720": ["Bison", "Oklahoma", "OK"],
"73722": ["Burlington", "Oklahoma", "OK"],
"73724": ["Canton", "Oklahoma", "OK"],
"73726": ["Carmen", "Oklahoma", "OK"],
"73727": ["Carrier", "Oklahoma", "OK"],
"73728": ["Cherokee", "Oklahoma", "OK"],
"73729": ["Cleo Springs", "Oklahoma", "OK"],
"73730": ["Covington", "Oklahoma", "OK"],
"73731": ["Dacoma", "Oklahoma", "OK"],
"73733": ["Douglas", "Oklahoma", "OK"],
"73734": ["Dover", "Oklahoma", "OK"],
"73735": ["Drummond", "Oklahoma", "OK"],
"73736": ["Fairmont", "Oklahoma", "OK"],
"73737": ["Fairview", "Oklahoma", "OK"],
"73738": ["Garber", "Oklahoma", "OK"],
"73739": ["Goltry", "Oklahoma", "OK"],
"73741": ["Helena", "Oklahoma", "OK"],
"73742": ["Hennessey", "Oklahoma", "OK"],
"73743": ["Hillsdale", "Oklahoma", "OK"],
"73744": ["Hitchcock", "Oklahoma", "OK"],
"73746": ["Hopeton", "Oklahoma", "OK"],
"73747": ["Isabella", "Oklahoma", "OK"],
"73749": ["Jet", "Oklahoma", "OK"],
"73750": ["Kingfisher", "Oklahoma", "OK"],
"73753": ["Kremlin", "Oklahoma", "OK"],
"73754": ["Lahoma", "Oklahoma", "OK"],
"73755": ["Longdale", "Oklahoma", "OK"],
"73756": ["Loyal", "Oklahoma", "OK"],
"73757": ["Lucien", "Oklahoma", "OK"],
"73758": ["Manchester", "Oklahoma", "OK"],
"73759": ["Medford", "Oklahoma", "OK"],
"73760": ["Meno", "Oklahoma", "OK"],
"73761": ["Nash", "Oklahoma", "OK"],
"73762": ["Okarche", "Oklahoma", "OK"],
"73763": ["Okeene", "Oklahoma", "OK"],
"73764": ["Omega", "Oklahoma", "OK"],
"73766": ["Pond Creek", "Oklahoma", "OK"],
"73768": ["Ringwood", "Oklahoma", "OK"],
"73770": ["Southard", "Oklahoma", "OK"],
"73771": ["Wakita", "Oklahoma", "OK"],
"73772": ["Watonga", "Oklahoma", "OK"],
"73773": ["Waukomis", "Oklahoma", "OK"],
"73801": ["Woodward", "Oklahoma", "OK"],
"73832": ["Arnett", "Oklahoma", "OK"],
"73834": ["Buffalo", "Oklahoma", "OK"],
"73835": ["Camargo", "Oklahoma", "OK"],
"73838": ["Chester", "Oklahoma", "OK"],
"73840": ["Fargo", "Oklahoma", "OK"],
"73841": ["Fort Supply", "Oklahoma", "OK"],
"73842": ["Freedom", "Oklahoma", "OK"],
"73843": ["Gage", "Oklahoma", "OK"],
"73844": ["Gate", "Oklahoma", "OK"],
"73848": ["Laverne", "Oklahoma", "OK"],
"73851": ["May", "Oklahoma", "OK"],
"73852": ["Mooreland", "Oklahoma", "OK"],
"73853": ["Mutual", "Oklahoma", "OK"],
"73855": ["Rosston", "Oklahoma", "OK"],
"73857": ["Sharon", "Oklahoma", "OK"],
"73858": ["Shattuck", "Oklahoma", "OK"],
"73859": ["Vici", "Oklahoma", "OK"],
"73860": ["Waynoka", "Oklahoma", "OK"],
"73901": ["Adams", "Oklahoma", "OK"],
"73931": ["Balko", "Oklahoma", "OK"],
"73932": ["Beaver", "Oklahoma", "OK"],
"73933": ["Boise City", "Oklahoma", "OK"],
"73937": ["Felt", "Oklahoma", "OK"],
"73938": ["Forgan", "Oklahoma", "OK"],
"73939": ["Goodwell", "Oklahoma", "OK"],
"73942": ["Guymon", "Oklahoma", "OK"],
"73944": ["Hardesty", "Oklahoma", "OK"],
"73945": ["Hooker", "Oklahoma", "OK"],
"73946": ["Kenton", "Oklahoma", "OK"],
"73947": ["Keyes", "Oklahoma", "OK"],
"73949": ["Texhoma", "Oklahoma", "OK"],
"73950": ["Turpin", "Oklahoma", "OK"],
"73951": ["Tyrone", "Oklahoma", "OK"],
"73960": ["Texhoma", "Texas", "TX"],
"74001": ["Avant", "Oklahoma", "OK"],
"74002": ["Barnsdall", "Oklahoma", "OK"],
"74003": ["Bartlesville", "Oklahoma", "OK"],
"74006": ["Bartlesville", "Oklahoma", "OK"],
"74008": ["Bixby", "Oklahoma", "OK"],
"74010": ["Bristow", "Oklahoma", "OK"],
"74011": ["Broken Arrow", "Oklahoma", "OK"],
"74012": ["Broken Arrow", "Oklahoma", "OK"],
"74014": ["Broken Arrow", "Oklahoma", "OK"],
"74015": ["Catoosa", "Oklahoma", "OK"],
"74016": ["Chelsea", "Oklahoma", "OK"],
"74017": ["Claremore", "Oklahoma", "OK"],
"74019": ["Claremore", "Oklahoma", "OK"],
"74020": ["Cleveland", "Oklahoma", "OK"],
"74021": ["Collinsville", "Oklahoma", "OK"],
"74022": ["Copan", "Oklahoma", "OK"],
"74023": ["Cushing", "Oklahoma", "OK"],
"74026": ["Davenport", "Oklahoma", "OK"],
"74027": ["Delaware", "Oklahoma", "OK"],
"74028": ["Depew", "Oklahoma", "OK"],
"74029": ["Dewey", "Oklahoma", "OK"],
"74030": ["Drumright", "Oklahoma", "OK"],
"74032": ["Glencoe", "Oklahoma", "OK"],
"74033": ["Glenpool", "Oklahoma", "OK"],
"74034": ["Hallett", "Oklahoma", "OK"],
"74035": ["Hominy", "Oklahoma", "OK"],
"74036": ["Inola", "Oklahoma", "OK"],
"74037": ["Jenks", "Oklahoma", "OK"],
"74038": ["Jennings", "Oklahoma", "OK"],
"74039": ["Kellyville", "Oklahoma", "OK"],
"74041": ["Kiefer", "Oklahoma", "OK"],
"74042": ["Lenapah", "Oklahoma", "OK"],
"74044": ["Mannford", "Oklahoma", "OK"],
"74045": ["Maramec", "Oklahoma", "OK"],
"74046": ["Milfay", "Oklahoma", "OK"],
"74047": ["Mounds", "Oklahoma", "OK"],
"74048": ["Nowata", "Oklahoma", "OK"],
"74050": ["Oakhurst", "Oklahoma", "OK"],
"74051": ["Ochelata", "Oklahoma", "OK"],
"74052": ["Oilton", "Oklahoma", "OK"],
"74053": ["Oologah", "Oklahoma", "OK"],
"74054": ["Osage", "Oklahoma", "OK"],
"74055": ["Owasso", "Oklahoma", "OK"],
"74056": ["Pawhuska", "Oklahoma", "OK"],
"74058": ["Pawnee", "Oklahoma", "OK"],
"74059": ["Perkins", "Oklahoma", "OK"],
"74060": ["Prue", "Oklahoma", "OK"],
"74061": ["Ramona", "Oklahoma", "OK"],
"74062": ["Ripley", "Oklahoma", "OK"],
"74063": ["Sand Springs", "Oklahoma", "OK"],
"74066": ["Sapulpa", "Oklahoma", "OK"],
"74068": ["Shamrock", "Oklahoma", "OK"],
"74070": ["Skiatook", "Oklahoma", "OK"],
"74071": ["Slick", "Oklahoma", "OK"],
"74072": ["S Coffeyville", "Oklahoma", "OK"],
"74073": ["Sperry", "Oklahoma", "OK"],
"74074": ["Stillwater", "Oklahoma", "OK"],
"74075": ["Stillwater", "Oklahoma", "OK"],
"74077": ["Stillwater", "Oklahoma", "OK"],
"74078": ["Stillwater", "Oklahoma", "OK"],
"74079": ["Stroud", "Oklahoma", "OK"],
"74080": ["Talala", "Oklahoma", "OK"],
"74081": ["Terlton", "Oklahoma", "OK"],
"74082": ["Vera", "Oklahoma", "OK"],
"74083": ["Wann", "Oklahoma", "OK"],
"74084": ["Wynona", "Oklahoma", "OK"],
"74085": ["Yale", "Oklahoma", "OK"],
"74103": ["Tulsa", "Oklahoma", "OK"],
"74104": ["Tulsa", "Oklahoma", "OK"],
"74105": ["Tulsa", "Oklahoma", "OK"],
"74106": ["Tulsa", "Oklahoma", "OK"],
"74107": ["Tulsa", "Oklahoma", "OK"],
"74108": ["Tulsa", "Oklahoma", "OK"],
"74110": ["Tulsa", "Oklahoma", "OK"],
"74112": ["Tulsa", "Oklahoma", "OK"],
"74114": ["Tulsa", "Oklahoma", "OK"],
"74115": ["Tulsa", "Oklahoma", "OK"],
"74116": ["Tulsa", "Oklahoma", "OK"],
"74117": ["Tulsa", "Oklahoma", "OK"],
"74119": ["Tulsa", "Oklahoma", "OK"],
"74120": ["Tulsa", "Oklahoma", "OK"],
"74126": ["Tulsa", "Oklahoma", "OK"],
"74127": ["Tulsa", "Oklahoma", "OK"],
"74128": ["Tulsa", "Oklahoma", "OK"],
"74129": ["Tulsa", "Oklahoma", "OK"],
"74130": ["Tulsa", "Oklahoma", "OK"],
"74131": ["Tulsa", "Oklahoma", "OK"],
"74132": ["Tulsa", "Oklahoma", "OK"],
"74133": ["Tulsa", "Oklahoma", "OK"],
"74134": ["Tulsa", "Oklahoma", "OK"],
"74135": ["Tulsa", "Oklahoma", "OK"],
"74136": ["Tulsa", "Oklahoma", "OK"],
"74137": ["Tulsa", "Oklahoma", "OK"],
"74145": ["Tulsa", "Oklahoma", "OK"],
"74146": ["Tulsa", "Oklahoma", "OK"],
"74171": ["Tulsa", "Oklahoma", "OK"],
"74301": ["Vinita", "Oklahoma", "OK"],
"74330": ["Adair", "Oklahoma", "OK"],
"74331": ["Afton", "Oklahoma", "OK"],
"74332": ["Big Cabin", "Oklahoma", "OK"],
"74333": ["Bluejacket", "Oklahoma", "OK"],
"74337": ["Chouteau", "Oklahoma", "OK"],
"74338": ["Colcord", "Oklahoma", "OK"],
"74339": ["Commerce", "Oklahoma", "OK"],
"74340": ["Disney", "Oklahoma", "OK"],
"74342": ["Eucha", "Oklahoma", "OK"],
"74343": ["Fairland", "Oklahoma", "OK"],
"74344": ["Grove", "Oklahoma", "OK"],
"74346": ["Jay", "Oklahoma", "OK"],
"74347": ["Kansas", "Oklahoma", "OK"],
"74349": ["Ketchum", "Oklahoma", "OK"],
"74350": ["Langley", "Oklahoma", "OK"],
"74352": ["Locust Grove", "Oklahoma", "OK"],
"74354": ["Miami", "Oklahoma", "OK"],
"74358": ["North Miami", "Oklahoma", "OK"],
"74359": ["Oaks", "Oklahoma", "OK"],
"74360": ["Picher", "Oklahoma", "OK"],
"74361": ["Pryor", "Oklahoma", "OK"],
"74363": ["Quapaw", "Oklahoma", "OK"],
"74364": ["Rose", "Oklahoma", "OK"],
"74365": ["Salina", "Oklahoma", "OK"],
"74366": ["Spavinaw", "Oklahoma", "OK"],
"74367": ["Strang", "Oklahoma", "OK"],
"74368": ["Twin Oaks", "Oklahoma", "OK"],
"74369": ["Welch", "Oklahoma", "OK"],
"74370": ["Wyandotte", "Oklahoma", "OK"],
"74401": ["Muskogee", "Oklahoma", "OK"],
"74403": ["Muskogee", "Oklahoma", "OK"],
"74421": ["Beggs", "Oklahoma", "OK"],
"74422": ["Boynton", "Oklahoma", "OK"],
"74423": ["Braggs", "Oklahoma", "OK"],
"74425": ["Canadian", "Oklahoma", "OK"],
"74426": ["Checotah", "Oklahoma", "OK"],
"74427": ["Cookson", "Oklahoma", "OK"],
"74428": ["Council Hill", "Oklahoma", "OK"],
"74429": ["Coweta", "Oklahoma", "OK"],
"74430": ["Crowder", "Oklahoma", "OK"],
"74431": ["Dewar", "Oklahoma", "OK"],
"74432": ["Eufaula", "Oklahoma", "OK"],
"74434": ["Fort Gibson", "Oklahoma", "OK"],
"74435": ["Gore", "Oklahoma", "OK"],
"74436": ["Haskell", "Oklahoma", "OK"],
"74437": ["Henryetta", "Oklahoma", "OK"],
"74438": ["Hitchita", "Oklahoma", "OK"],
"74441": ["Hulbert", "Oklahoma", "OK"],
"74442": ["Indianola", "Oklahoma", "OK"],
"74445": ["Morris", "Oklahoma", "OK"],
"74446": ["Okay", "Oklahoma", "OK"],
"74447": ["Okmulgee", "Oklahoma", "OK"],
"74450": ["Oktaha", "Oklahoma", "OK"],
"74451": ["Park Hill", "Oklahoma", "OK"],
"74452": ["Peggs", "Oklahoma", "OK"],
"74454": ["Porter", "Oklahoma", "OK"],
"74455": ["Porum", "Oklahoma", "OK"],
"74456": ["Preston", "Oklahoma", "OK"],
"74457": ["Proctor", "Oklahoma", "OK"],
"74458": ["Redbird", "Oklahoma", "OK"],
"74459": ["Rentiesville", "Oklahoma", "OK"],
"74460": ["Schulter", "Oklahoma", "OK"],
"74462": ["Stigler", "Oklahoma", "OK"],
"74463": ["Taft", "Oklahoma", "OK"],
"74464": ["Tahlequah", "Oklahoma", "OK"],
"74467": ["Wagoner", "Oklahoma", "OK"],
"74468": ["Wainwright", "Oklahoma", "OK"],
"74469": ["Warner", "Oklahoma", "OK"],
"74470": ["Webbers Falls", "Oklahoma", "OK"],
"74471": ["Welling", "Oklahoma", "OK"],
"74472": ["Whitefield", "Oklahoma", "OK"],
"74477": ["Wagoner", "Oklahoma", "OK"],
"74501": ["Mcalester", "Oklahoma", "OK"],
"74521": ["Albion", "Oklahoma", "OK"],
"74522": ["Alderson", "Oklahoma", "OK"],
"74523": ["Antlers", "Oklahoma", "OK"],
"74525": ["Atoka", "Oklahoma", "OK"],
"74528": ["Blanco", "Oklahoma", "OK"],
"74530": ["Bromide", "Oklahoma", "OK"],
"74531": ["Calvin", "Oklahoma", "OK"],
"74533": ["Caney", "Oklahoma", "OK"],
"74534": ["Centrahoma", "Oklahoma", "OK"],
"74535": ["Clarita", "Oklahoma", "OK"],
"74536": ["Clayton", "Oklahoma", "OK"],
"74538": ["Coalgate", "Oklahoma", "OK"],
"74540": ["Daisy", "Oklahoma", "OK"],
"74543": ["Finley", "Oklahoma", "OK"],
"74546": ["Haileyville", "Oklahoma", "OK"],
"74547": ["Hartshorne", "Oklahoma", "OK"],
"74549": ["Honobia", "Oklahoma", "OK"],
"74552": ["Kinta", "Oklahoma", "OK"],
"74553": ["Kiowa", "Oklahoma", "OK"],
"74554": ["Krebs", "Oklahoma", "OK"],
"74555": ["Lane", "Oklahoma", "OK"],
"74556": ["Lehigh", "Oklahoma", "OK"],
"74557": ["Moyers", "Oklahoma", "OK"],
"74558": ["Nashoba", "Oklahoma", "OK"],
"74560": ["Pittsburg", "Oklahoma", "OK"],
"74561": ["Quinton", "Oklahoma", "OK"],
"74562": ["Rattan", "Oklahoma", "OK"],
"74563": ["Red Oak", "Oklahoma", "OK"],
"74565": ["Savanna", "Oklahoma", "OK"],
"74567": ["Snow", "Oklahoma", "OK"],
"74569": ["Stringtown", "Oklahoma", "OK"],
"74570": ["Stuart", "Oklahoma", "OK"],
"74571": ["Talihina", "Oklahoma", "OK"],
"74572": ["Tupelo", "Oklahoma", "OK"],
"74574": ["Tuskahoma", "Oklahoma", "OK"],
"74576": ["Wardville", "Oklahoma", "OK"],
"74577": ["Whitesboro", "Oklahoma", "OK"],
"74578": ["Wilburton", "Oklahoma", "OK"],
"74601": ["Ponca City", "Oklahoma", "OK"],
"74604": ["Ponca City", "Oklahoma", "OK"],
"74630": ["Billings", "Oklahoma", "OK"],
"74631": ["Blackwell", "Oklahoma", "OK"],
"74632": ["Braman", "Oklahoma", "OK"],
"74633": ["Burbank", "Oklahoma", "OK"],
"74636": ["Deer Creek", "Oklahoma", "OK"],
"74637": ["Fairfax", "Oklahoma", "OK"],
"74640": ["Hunter", "Oklahoma", "OK"],
"74641": ["Kaw City", "Oklahoma", "OK"],
"74643": ["Lamont", "Oklahoma", "OK"],
"74644": ["Marland", "Oklahoma", "OK"],
"74646": ["Nardin", "Oklahoma", "OK"],
"74647": ["Newkirk", "Oklahoma", "OK"],
"74650": ["Ralston", "Oklahoma", "OK"],
"74651": ["Red Rock", "Oklahoma", "OK"],
"74652": ["Shidler", "Oklahoma", "OK"],
"74653": ["Tonkawa", "Oklahoma", "OK"],
"74701": ["Durant", "Oklahoma", "OK"],
"74720": ["Achille", "Oklahoma", "OK"],
"74721": ["Albany", "Oklahoma", "OK"],
"74722": ["Battiest", "Oklahoma", "OK"],
"74723": ["Bennington", "Oklahoma", "OK"],
"74724": ["Bethel", "Oklahoma", "OK"],
"74726": ["Bokchito", "Oklahoma", "OK"],
"74727": ["Boswell", "Oklahoma", "OK"],
"74728": ["Broken Bow", "Oklahoma", "OK"],
"74729": ["Caddo", "Oklahoma", "OK"],
"74730": ["Calera", "Oklahoma", "OK"],
"74731": ["Cartwright", "Oklahoma", "OK"],
"74733": ["Colbert", "Oklahoma", "OK"],
"74734": ["Eagletown", "Oklahoma", "OK"],
"74735": ["Fort Towson", "Oklahoma", "OK"],
"74736": ["Garvin", "Oklahoma", "OK"],
"74737": ["Golden", "Oklahoma", "OK"],
"74738": ["Grant", "Oklahoma", "OK"],
"74740": ["Haworth", "Oklahoma", "OK"],
"74741": ["Hendrix", "Oklahoma", "OK"],
"74743": ["Hugo", "Oklahoma", "OK"],
"74745": ["Idabel", "Oklahoma", "OK"],
"74747": ["Kemp", "Oklahoma", "OK"],
"74748": ["Kenefic", "Oklahoma", "OK"],
"74750": ["Millerton", "Oklahoma", "OK"],
"74753": ["Platter", "Oklahoma", "OK"],
"74754": ["Ringold", "Oklahoma", "OK"],
"74755": ["Rufe", "Oklahoma", "OK"],
"74756": ["Sawyer", "Oklahoma", "OK"],
"74759": ["Soper", "Oklahoma", "OK"],
"74760": ["Spencerville", "Oklahoma", "OK"],
"74761": ["Swink", "Oklahoma", "OK"],
"74764": ["Valliant", "Oklahoma", "OK"],
"74766": ["Wright City", "Oklahoma", "OK"],
"74801": ["Shawnee", "Oklahoma", "OK"],
"74804": ["Shawnee", "Oklahoma", "OK"],
"74820": ["Ada", "Oklahoma", "OK"],
"74824": ["Agra", "Oklahoma", "OK"],
"74825": ["Allen", "Oklahoma", "OK"],
"74826": ["Asher", "Oklahoma", "OK"],
"74827": ["Atwood", "Oklahoma", "OK"],
"74829": ["Boley", "Oklahoma", "OK"],
"74830": ["Bowlegs", "Oklahoma", "OK"],
"74831": ["Byars", "Oklahoma", "OK"],
"74832": ["Carney", "Oklahoma", "OK"],
"74833": ["Castle", "Oklahoma", "OK"],
"74834": ["Chandler", "Oklahoma", "OK"],
"74836": ["Connerville", "Oklahoma", "OK"],
"74837": ["Cromwell", "Oklahoma", "OK"],
"74839": ["Dustin", "Oklahoma", "OK"],
"74840": ["Earlsboro", "Oklahoma", "OK"],
"74842": ["Fittstown", "Oklahoma", "OK"],
"74843": ["Fitzhugh", "Oklahoma", "OK"],
"74844": ["Francis", "Oklahoma", "OK"],
"74845": ["Hanna", "Oklahoma", "OK"],
"74848": ["Holdenville", "Oklahoma", "OK"],
"74849": ["Konawa", "Oklahoma", "OK"],
"74850": ["Lamar", "Oklahoma", "OK"],
"74851": ["Mcloud", "Oklahoma", "OK"],
"74852": ["Macomb", "Oklahoma", "OK"],
"74854": ["Maud", "Oklahoma", "OK"],
"74855": ["Meeker", "Oklahoma", "OK"],
"74856": ["Mill Creek", "Oklahoma", "OK"],
"74857": ["Newalla", "Oklahoma", "OK"],
"74859": ["Okemah", "Oklahoma", "OK"],
"74860": ["Paden", "Oklahoma", "OK"],
"74864": ["Prague", "Oklahoma", "OK"],
"74865": ["Roff", "Oklahoma", "OK"],
"74866": ["Saint Louis", "Oklahoma", "OK"],
"74867": ["Sasakwa", "Oklahoma", "OK"],
"74868": ["Seminole", "Oklahoma", "OK"],
"74869": ["Sparks", "Oklahoma", "OK"],
"74871": ["Stonewall", "Oklahoma", "OK"],
"74872": ["Stratford", "Oklahoma", "OK"],
"74873": ["Tecumseh", "Oklahoma", "OK"],
"74875": ["Tryon", "Oklahoma", "OK"],
"74878": ["Wanette", "Oklahoma", "OK"],
"74880": ["Weleetka", "Oklahoma", "OK"],
"74881": ["Wellston", "Oklahoma", "OK"],
"74883": ["Wetumka", "Oklahoma", "OK"],
"74884": ["Wewoka", "Oklahoma", "OK"],
"74901": ["Arkoma", "Oklahoma", "OK"],
"74902": ["Pocola", "Oklahoma", "OK"],
"74930": ["Bokoshe", "Oklahoma", "OK"],
"74931": ["Bunch", "Oklahoma", "OK"],
"74932": ["Cameron", "Oklahoma", "OK"],
"74935": ["Fanshawe", "Oklahoma", "OK"],
"74936": ["Gans", "Oklahoma", "OK"],
"74937": ["Heavener", "Oklahoma", "OK"],
"74939": ["Hodgen", "Oklahoma", "OK"],
"74940": ["Howe", "Oklahoma", "OK"],
"74941": ["Keota", "Oklahoma", "OK"],
"74942": ["Leflore", "Oklahoma", "OK"],
"74943": ["Lequire", "Oklahoma", "OK"],
"74944": ["Mccurtain", "Oklahoma", "OK"],
"74945": ["Marble City", "Oklahoma", "OK"],
"74946": ["Moffett", "Oklahoma", "OK"],
"74947": ["Monroe", "Oklahoma", "OK"],
"74948": ["Muldrow", "Oklahoma", "OK"],
"74949": ["Muse", "Oklahoma", "OK"],
"74951": ["Panama", "Oklahoma", "OK"],
"74953": ["Poteau", "Oklahoma", "OK"],
"74954": ["Roland", "Oklahoma", "OK"],
"74955": ["Sallisaw", "Oklahoma", "OK"],
"74956": ["Shady Point", "Oklahoma", "OK"],
"74957": ["Smithville", "Oklahoma", "OK"],
"74959": ["Spiro", "Oklahoma", "OK"],
"74960": ["Stilwell", "Oklahoma", "OK"],
"74962": ["Vian", "Oklahoma", "OK"],
"74963": ["Watson", "Oklahoma", "OK"],
"74964": ["Watts", "Oklahoma", "OK"],
"74965": ["Westville", "Oklahoma", "OK"],
"74966": ["Wister", "Oklahoma", "OK"],
"75001": ["Addison", "Texas", "TX"],
"75002": ["Allen", "Texas", "TX"],
"75006": ["Carrollton", "Texas", "TX"],
"75007": ["Carrollton", "Texas", "TX"],
"75009": ["Celina", "Texas", "TX"],
"75010": ["Carrollton", "Texas", "TX"],
"75013": ["Allen", "Texas", "TX"],
"75019": ["Coppell", "Texas", "TX"],
"75020": ["Denison", "Texas", "TX"],
"75021": ["Denison", "Texas", "TX"],
"75022": ["Flower Mound", "Texas", "TX"],
"75023": ["Plano", "Texas", "TX"],
"75024": ["Plano", "Texas", "TX"],
"75025": ["Plano", "Texas", "TX"],
"75028": ["Flower Mound", "Texas", "TX"],
"75032": ["Rockwall", "Texas", "TX"],
"75033": ["Frisco", "Texas", "TX"],
"75034": ["Frisco", "Texas", "TX"],
"75035": ["Frisco", "Texas", "TX"],
"75036": ["Frisco", "Texas", "TX"],
"75038": ["Irving", "Texas", "TX"],
"75039": ["Irving", "Texas", "TX"],
"75040": ["Garland", "Texas", "TX"],
"75041": ["Garland", "Texas", "TX"],
"75042": ["Garland", "Texas", "TX"],
"75043": ["Garland", "Texas", "TX"],
"75044": ["Garland", "Texas", "TX"],
"75048": ["Sachse", "Texas", "TX"],
"75050": ["Grand Prairie", "Texas", "TX"],
"75051": ["Grand Prairie", "Texas", "TX"],
"75052": ["Grand Prairie", "Texas", "TX"],
"75054": ["Grand Prairie", "Texas", "TX"],
"75056": ["The Colony", "Texas", "TX"],
"75057": ["Lewisville", "Texas", "TX"],
"75058": ["Gunter", "Texas", "TX"],
"75060": ["Irving", "Texas", "TX"],
"75061": ["Irving", "Texas", "TX"],
"75062": ["Irving", "Texas", "TX"],
"75063": ["Irving", "Texas", "TX"],
"75065": ["Lake Dallas", "Texas", "TX"],
"75067": ["Lewisville", "Texas", "TX"],
"75068": ["Little Elm", "Texas", "TX"],
"75069": ["Mckinney", "Texas", "TX"],
"75070": ["Mckinney", "Texas", "TX"],
"75071": ["Mckinney", "Texas", "TX"],
"75072": ["Mckinney", "Texas", "TX"],
"75074": ["Plano", "Texas", "TX"],
"75075": ["Plano", "Texas", "TX"],
"75076": ["Pottsboro", "Texas", "TX"],
"75077": ["Lewisville", "Texas", "TX"],
"75078": ["Prosper", "Texas", "TX"],
"75080": ["Richardson", "Texas", "TX"],
"75081": ["Richardson", "Texas", "TX"],
"75082": ["Richardson", "Texas", "TX"],
"75087": ["Rockwall", "Texas", "TX"],
"75088": ["Rowlett", "Texas", "TX"],
"75089": ["Rowlett", "Texas", "TX"],
"75090": ["Sherman", "Texas", "TX"],
"75092": ["Sherman", "Texas", "TX"],
"75093": ["Plano", "Texas", "TX"],
"75094": ["Plano", "Texas", "TX"],
"75098": ["Wylie", "Texas", "TX"],
"75101": ["Bardwell", "Texas", "TX"],
"75102": ["Barry", "Texas", "TX"],
"75103": ["Canton", "Texas", "TX"],
"75104": ["Cedar Hill", "Texas", "TX"],
"75105": ["Chatfield", "Texas", "TX"],
"75109": ["Corsicana", "Texas", "TX"],
"75110": ["Corsicana", "Texas", "TX"],
"75114": ["Crandall", "Texas", "TX"],
"75115": ["Desoto", "Texas", "TX"],
"75116": ["Duncanville", "Texas", "TX"],
"75117": ["Edgewood", "Texas", "TX"],
"75119": ["Ennis", "Texas", "TX"],
"75124": ["Eustace", "Texas", "TX"],
"75125": ["Ferris", "Texas", "TX"],
"75126": ["Forney", "Texas", "TX"],
"75127": ["Fruitvale", "Texas", "TX"],
"75132": ["Fate", "Texas", "TX"],
"75134": ["Lancaster", "Texas", "TX"],
"75135": ["Caddo Mills", "Texas", "TX"],
"75137": ["Duncanville", "Texas", "TX"],
"75140": ["Grand Saline", "Texas", "TX"],
"75141": ["Hutchins", "Texas", "TX"],
"75142": ["Kaufman", "Texas", "TX"],
"75143": ["Kemp", "Texas", "TX"],
"75144": ["Kerens", "Texas", "TX"],
"75146": ["Lancaster", "Texas", "TX"],
"75147": ["Mabank", "Texas", "TX"],
"75148": ["Malakoff", "Texas", "TX"],
"75149": ["Mesquite", "Texas", "TX"],
"75150": ["Mesquite", "Texas", "TX"],
"75152": ["Palmer", "Texas", "TX"],
"75153": ["Powell", "Texas", "TX"],
"75154": ["Red Oak", "Texas", "TX"],
"75155": ["Rice", "Texas", "TX"],
"75156": ["Mabank", "Texas", "TX"],
"75157": ["Rosser", "Texas", "TX"],
"75158": ["Scurry", "Texas", "TX"],
"75159": ["Seagoville", "Texas", "TX"],
"75160": ["Terrell", "Texas", "TX"],
"75161": ["Terrell", "Texas", "TX"],
"75163": ["Trinidad", "Texas", "TX"],
"75164": ["Josephine", "Texas", "TX"],
"75165": ["Waxahachie", "Texas", "TX"],
"75166": ["Lavon", "Texas", "TX"],
"75167": ["Waxahachie", "Texas", "TX"],
"75169": ["Wills Point", "Texas", "TX"],
"75172": ["Wilmer", "Texas", "TX"],
"75173": ["Nevada", "Texas", "TX"],
"75180": ["Balch Springs", "Texas", "TX"],
"75181": ["Mesquite", "Texas", "TX"],
"75182": ["Sunnyvale", "Texas", "TX"],
"75189": ["Royse City", "Texas", "TX"],
"75201": ["Dallas", "Texas", "TX"],
"75202": ["Dallas", "Texas", "TX"],
"75203": ["Dallas", "Texas", "TX"],
"75204": ["Dallas", "Texas", "TX"],
"75205": ["Dallas", "Texas", "TX"],
"75206": ["Dallas", "Texas", "TX"],
"75207": ["Dallas", "Texas", "TX"],
"75208": ["Dallas", "Texas", "TX"],
"75209": ["Dallas", "Texas", "TX"],
"75210": ["Dallas", "Texas", "TX"],
"75211": ["Dallas", "Texas", "TX"],
"75212": ["Dallas", "Texas", "TX"],
"75214": ["Dallas", "Texas", "TX"],
"75215": ["Dallas", "Texas", "TX"],
"75216": ["Dallas", "Texas", "TX"],
"75217": ["Dallas", "Texas", "TX"],
"75218": ["Dallas", "Texas", "TX"],
"75219": ["Dallas", "Texas", "TX"],
"75220": ["Dallas", "Texas", "TX"],
"75223": ["Dallas", "Texas", "TX"],
"75224": ["Dallas", "Texas", "TX"],
"75225": ["Dallas", "Texas", "TX"],
"75226": ["Dallas", "Texas", "TX"],
"75227": ["Dallas", "Texas", "TX"],
"75228": ["Dallas", "Texas", "TX"],
"75229": ["Dallas", "Texas", "TX"],
"75230": ["Dallas", "Texas", "TX"],
"75231": ["Dallas", "Texas", "TX"],
"75232": ["Dallas", "Texas", "TX"],
"75233": ["Dallas", "Texas", "TX"],
"75234": ["Dallas", "Texas", "TX"],
"75235": ["Dallas", "Texas", "TX"],
"75236": ["Dallas", "Texas", "TX"],
"75237": ["Dallas", "Texas", "TX"],
"75238": ["Dallas", "Texas", "TX"],
"75240": ["Dallas", "Texas", "TX"],
"75241": ["Dallas", "Texas", "TX"],
"75243": ["Dallas", "Texas", "TX"],
"75244": ["Dallas", "Texas", "TX"],
"75246": ["Dallas", "Texas", "TX"],
"75247": ["Dallas", "Texas", "TX"],
"75248": ["Dallas", "Texas", "TX"],
"75249": ["Dallas", "Texas", "TX"],
"75251": ["Dallas", "Texas", "TX"],
"75252": ["Dallas", "Texas", "TX"],
"75253": ["Dallas", "Texas", "TX"],
"75254": ["Dallas", "Texas", "TX"],
"75261": ["Dallas", "Texas", "TX"],
"75270": ["Dallas", "Texas", "TX"],
"75287": ["Dallas", "Texas", "TX"],
"75390": ["Dallas", "Texas", "TX"],
"75401": ["Greenville", "Texas", "TX"],
"75402": ["Greenville", "Texas", "TX"],
"75407": ["Princeton", "Texas", "TX"],
"75409": ["Anna", "Texas", "TX"],
"75410": ["Alba", "Texas", "TX"],
"75411": ["Arthur City", "Texas", "TX"],
"75412": ["Bagwell", "Texas", "TX"],
"75413": ["Bailey", "Texas", "TX"],
"75414": ["Bells", "Texas", "TX"],
"75415": ["Ben Franklin", "Texas", "TX"],
"75416": ["Blossom", "Texas", "TX"],
"75417": ["Bogata", "Texas", "TX"],
"75418": ["Bonham", "Texas", "TX"],
"75420": ["Brashear", "Texas", "TX"],
"75421": ["Brookston", "Texas", "TX"],
"75422": ["Campbell", "Texas", "TX"],
"75423": ["Celeste", "Texas", "TX"],
"75424": ["Blue Ridge", "Texas", "TX"],
"75426": ["Clarksville", "Texas", "TX"],
"75428": ["Commerce", "Texas", "TX"],
"75429": ["Commerce", "Texas", "TX"],
"75431": ["Como", "Texas", "TX"],
"75432": ["Cooper", "Texas", "TX"],
"75433": ["Cumby", "Texas", "TX"],
"75434": ["Cunningham", "Texas", "TX"],
"75435": ["Deport", "Texas", "TX"],
"75436": ["Detroit", "Texas", "TX"],
"75437": ["Dike", "Texas", "TX"],
"75438": ["Dodd City", "Texas", "TX"],
"75439": ["Ector", "Texas", "TX"],
"75440": ["Emory", "Texas", "TX"],
"75441": ["Enloe", "Texas", "TX"],
"75442": ["Farmersville", "Texas", "TX"],
"75446": ["Honey Grove", "Texas", "TX"],
"75447": ["Ivanhoe", "Texas", "TX"],
"75448": ["Klondike", "Texas", "TX"],
"75449": ["Ladonia", "Texas", "TX"],
"75450": ["Lake Creek", "Texas", "TX"],
"75451": ["Leesburg", "Texas", "TX"],
"75452": ["Leonard", "Texas", "TX"],
"75453": ["Lone Oak", "Texas", "TX"],
"75454": ["Melissa", "Texas", "TX"],
"75455": ["Mount Pleasant", "Texas", "TX"],
"75457": ["Mount Vernon", "Texas", "TX"],
"75459": ["Howe", "Texas", "TX"],
"75460": ["Paris", "Texas", "TX"],
"75462": ["Paris", "Texas", "TX"],
"75468": ["Pattonville", "Texas", "TX"],
"75469": ["Pecan Gap", "Texas", "TX"],
"75470": ["Petty", "Texas", "TX"],
"75471": ["Pickton", "Texas", "TX"],
"75472": ["Point", "Texas", "TX"],
"75473": ["Powderly", "Texas", "TX"],
"75474": ["Quinlan", "Texas", "TX"],
"75475": ["Randolph", "Texas", "TX"],
"75476": ["Ravenna", "Texas", "TX"],
"75477": ["Roxton", "Texas", "TX"],
"75478": ["Saltillo", "Texas", "TX"],
"75479": ["Savoy", "Texas", "TX"],
"75480": ["Scroggins", "Texas", "TX"],
"75481": ["Sulphur Bluff", "Texas", "TX"],
"75482": ["Sulphur Springs", "Texas", "TX"],
"75486": ["Sumner", "Texas", "TX"],
"75487": ["Talco", "Texas", "TX"],
"75488": ["Telephone", "Texas", "TX"],
"75489": ["Tom Bean", "Texas", "TX"],
"75490": ["Trenton", "Texas", "TX"],
"75491": ["Whitewright", "Texas", "TX"],
"75492": ["Windom", "Texas", "TX"],
"75493": ["Winfield", "Texas", "TX"],
"75494": ["Winnsboro", "Texas", "TX"],
"75495": ["Van Alstyne", "Texas", "TX"],
"75496": ["Wolfe City", "Texas", "TX"],
"75497": ["Yantis", "Texas", "TX"],
"75501": ["Texarkana", "Texas", "TX"],
"75503": ["Texarkana", "Texas", "TX"],
"75550": ["Annona", "Texas", "TX"],
"75551": ["Atlanta", "Texas", "TX"],
"75554": ["Avery", "Texas", "TX"],
"75555": ["Bivins", "Texas", "TX"],
"75556": ["Bloomburg", "Texas", "TX"],
"75558": ["Cookville", "Texas", "TX"],
"75559": ["De Kalb", "Texas", "TX"],
"75560": ["Douglassville", "Texas", "TX"],
"75561": ["Hooks", "Texas", "TX"],
"75562": ["Kildare", "Texas", "TX"],
"75563": ["Linden", "Texas", "TX"],
"75564": ["Lodi", "Texas", "TX"],
"75565": ["McLeod", "Texas", "TX"],
"75566": ["Marietta", "Texas", "TX"],
"75567": ["Maud", "Texas", "TX"],
"75568": ["Naples", "Texas", "TX"],
"75569": ["Nash", "Texas", "TX"],
"75570": ["New Boston", "Texas", "TX"],
"75571": ["Omaha", "Texas", "TX"],
"75572": ["Queen City", "Texas", "TX"],
"75573": ["Redwater", "Texas", "TX"],
"75574": ["Simms", "Texas", "TX"],
"75601": ["Longview", "Texas", "TX"],
"75602": ["Longview", "Texas", "TX"],
"75603": ["Longview", "Texas", "TX"],
"75604": ["Longview", "Texas", "TX"],
"75605": ["Longview", "Texas", "TX"],
"75630": ["Avinger", "Texas", "TX"],
"75631": ["Beckville", "Texas", "TX"],
"75633": ["Carthage", "Texas", "TX"],
"75638": ["Daingerfield", "Texas", "TX"],
"75639": ["De Berry", "Texas", "TX"],
"75640": ["Diana", "Texas", "TX"],
"75641": ["Easton", "Texas", "TX"],
"75642": ["Elysian Fields", "Texas", "TX"],
"75643": ["Gary", "Texas", "TX"],
"75644": ["Gilmer", "Texas", "TX"],
"75645": ["Gilmer", "Texas", "TX"],
"75647": ["Gladewater", "Texas", "TX"],
"75650": ["Hallsville", "Texas", "TX"],
"75651": ["Harleton", "Texas", "TX"],
"75652": ["Henderson", "Texas", "TX"],
"75654": ["Henderson", "Texas", "TX"],
"75656": ["Hughes Springs", "Texas", "TX"],
"75657": ["Jefferson", "Texas", "TX"],
"75658": ["Joinerville", "Texas", "TX"],
"75659": ["Jonesville", "Texas", "TX"],
"75661": ["Karnack", "Texas", "TX"],
"75662": ["Kilgore", "Texas", "TX"],
"75667": ["Laneville", "Texas", "TX"],
"75668": ["Lone Star", "Texas", "TX"],
"75669": ["Long Branch", "Texas", "TX"],
"75670": ["Marshall", "Texas", "TX"],
"75671": ["Marshall", "Texas", "TX"],
"75672": ["Marshall", "Texas", "TX"],
"75680": ["Minden", "Texas", "TX"],
"75681": ["Mount Enterprise", "Texas", "TX"],
"75682": ["New London", "Texas", "TX"],
"75683": ["Ore City", "Texas", "TX"],
"75684": ["Overton", "Texas", "TX"],
"75686": ["Pittsburg", "Texas", "TX"],
"75687": ["Price", "Texas", "TX"],
"75689": ["Selman City", "Texas", "TX"],
"75691": ["Tatum", "Texas", "TX"],
"75692": ["Waskom", "Texas", "TX"],
"75693": ["White Oak", "Texas", "TX"],
"75694": ["Woodlawn", "Texas", "TX"],
"75701": ["Tyler", "Texas", "TX"],
"75702": ["Tyler", "Texas", "TX"],
"75703": ["Tyler", "Texas", "TX"],
"75704": ["Tyler", "Texas", "TX"],
"75705": ["Tyler", "Texas", "TX"],
"75706": ["Tyler", "Texas", "TX"],
"75707": ["Tyler", "Texas", "TX"],
"75708": ["Tyler", "Texas", "TX"],
"75709": ["Tyler", "Texas", "TX"],
"75711": ["Tyler", "Texas", "TX"],
"75750": ["Arp", "Texas", "TX"],
"75751": ["Athens", "Texas", "TX"],
"75752": ["Athens", "Texas", "TX"],
"75754": ["Ben Wheeler", "Texas", "TX"],
"75755": ["Big Sandy", "Texas", "TX"],
"75756": ["Brownsboro", "Texas", "TX"],
"75757": ["Bullard", "Texas", "TX"],
"75758": ["Chandler", "Texas", "TX"],
"75759": ["Cuney", "Texas", "TX"],
"75760": ["Cushing", "Texas", "TX"],
"75762": ["Flint", "Texas", "TX"],
"75763": ["Frankston", "Texas", "TX"],
"75764": ["Gallatin", "Texas", "TX"],
"75765": ["Hawkins", "Texas", "TX"],
"75766": ["Jacksonville", "Texas", "TX"],
"75770": ["Larue", "Texas", "TX"],
"75771": ["Lindale", "Texas", "TX"],
"75773": ["Mineola", "Texas", "TX"],
"75778": ["Murchison", "Texas", "TX"],
"75779": ["Neches", "Texas", "TX"],
"75780": ["New Summerfield", "Texas", "TX"],
"75782": ["Poynor", "Texas", "TX"],
"75783": ["Quitman", "Texas", "TX"],
"75784": ["Reklaw", "Texas", "TX"],
"75785": ["Rusk", "Texas", "TX"],
"75788": ["Sacul", "Texas", "TX"],
"75789": ["Troup", "Texas", "TX"],
"75790": ["Van", "Texas", "TX"],
"75791": ["Whitehouse", "Texas", "TX"],
"75792": ["Winona", "Texas", "TX"],
"75801": ["Palestine", "Texas", "TX"],
"75803": ["Palestine", "Texas", "TX"],
"75831": ["Buffalo", "Texas", "TX"],
"75833": ["Centerville", "Texas", "TX"],
"75835": ["Crockett", "Texas", "TX"],
"75838": ["Donie", "Texas", "TX"],
"75839": ["Elkhart", "Texas", "TX"],
"75840": ["Fairfield", "Texas", "TX"],
"75844": ["Grapeland", "Texas", "TX"],
"75845": ["Groveton", "Texas", "TX"],
"75846": ["Jewett", "Texas", "TX"],
"75847": ["Kennard", "Texas", "TX"],
"75848": ["Kirvin", "Texas", "TX"],
"75849": ["Latexo", "Texas", "TX"],
"75850": ["Leona", "Texas", "TX"],
"75851": ["Lovelady", "Texas", "TX"],
"75852": ["Midway", "Texas", "TX"],
"75853": ["Montalba", "Texas", "TX"],
"75855": ["Oakwood", "Texas", "TX"],
"75856": ["Pennington", "Texas", "TX"],
"75858": ["Ratcliff", "Texas", "TX"],
"75859": ["Streetman", "Texas", "TX"],
"75860": ["Teague", "Texas", "TX"],
"75861": ["Tennessee Colony", "Texas", "TX"],
"75862": ["Trinity", "Texas", "TX"],
"75865": ["Woodlake", "Texas", "TX"],
"75884": ["Tennessee Colony", "Texas", "TX"],
"75886": ["Tennessee Colony", "Texas", "TX"],
"75901": ["Lufkin", "Texas", "TX"],
"75903": ["Lufkin", "Texas", "TX"],
"75904": ["Lufkin", "Texas", "TX"],
"75925": ["Alto", "Texas", "TX"],
"75926": ["Apple Springs", "Texas", "TX"],
"75928": ["Bon Wier", "Texas", "TX"],
"75929": ["Broaddus", "Texas", "TX"],
"75930": ["Bronson", "Texas", "TX"],
"75931": ["Brookeland", "Texas", "TX"],
"75932": ["Burkeville", "Texas", "TX"],
"75933": ["Call", "Texas", "TX"],
"75934": ["Camden", "Texas", "TX"],
"75935": ["Center", "Texas", "TX"],
"75936": ["Chester", "Texas", "TX"],
"75937": ["Chireno", "Texas", "TX"],
"75938": ["Colmesneil", "Texas", "TX"],
"75939": ["Corrigan", "Texas", "TX"],
"75941": ["Diboll", "Texas", "TX"],
"75942": ["Doucette", "Texas", "TX"],
"75943": ["Douglass", "Texas", "TX"],
"75944": ["Etoile", "Texas", "TX"],
"75946": ["Garrison", "Texas", "TX"],
"75948": ["Hemphill", "Texas", "TX"],
"75949": ["Huntington", "Texas", "TX"],
"75951": ["Jasper", "Texas", "TX"],
"75954": ["Joaquin", "Texas", "TX"],
"75956": ["Kirbyville", "Texas", "TX"],
"75959": ["Milam", "Texas", "TX"],
"75960": ["Moscow", "Texas", "TX"],
"75961": ["Nacogdoches", "Texas", "TX"],
"75962": ["Nacogdoches", "Texas", "TX"],
"75964": ["Nacogdoches", "Texas", "TX"],
"75965": ["Nacogdoches", "Texas", "TX"],
"75966": ["Newton", "Texas", "TX"],
"75968": ["Pineland", "Texas", "TX"],
"75969": ["Pollok", "Texas", "TX"],
"75972": ["San Augustine", "Texas", "TX"],
"75973": ["Shelbyville", "Texas", "TX"],
"75974": ["Tenaha", "Texas", "TX"],
"75975": ["Timpson", "Texas", "TX"],
"75976": ["Wells", "Texas", "TX"],
"75977": ["Wiergate", "Texas", "TX"],
"75979": ["Woodville", "Texas", "TX"],
"75980": ["Zavalla", "Texas", "TX"],
"76001": ["Arlington", "Texas", "TX"],
"76002": ["Arlington", "Texas", "TX"],
"76005": ["Arlington", "Texas", "TX"],
"76006": ["Arlington", "Texas", "TX"],
"76008": ["Aledo", "Texas", "TX"],
"76009": ["Alvarado", "Texas", "TX"],
"76010": ["Arlington", "Texas", "TX"],
"76011": ["Arlington", "Texas", "TX"],
"76012": ["Arlington", "Texas", "TX"],
"76013": ["Arlington", "Texas", "TX"],
"76014": ["Arlington", "Texas", "TX"],
"76015": ["Arlington", "Texas", "TX"],
"76016": ["Arlington", "Texas", "TX"],
"76017": ["Arlington", "Texas", "TX"],
"76018": ["Arlington", "Texas", "TX"],
"76020": ["Azle", "Texas", "TX"],
"76021": ["Bedford", "Texas", "TX"],
"76022": ["Bedford", "Texas", "TX"],
"76023": ["Boyd", "Texas", "TX"],
"76028": ["Burleson", "Texas", "TX"],
"76031": ["Cleburne", "Texas", "TX"],
"76033": ["Cleburne", "Texas", "TX"],
"76034": ["Colleyville", "Texas", "TX"],
"76035": ["Cresson", "Texas", "TX"],
"76036": ["Crowley", "Texas", "TX"],
"76039": ["Euless", "Texas", "TX"],
"76040": ["Euless", "Texas", "TX"],
"76041": ["Forreston", "Texas", "TX"],
"76043": ["Glen Rose", "Texas", "TX"],
"76044": ["Godley", "Texas", "TX"],
"76048": ["Granbury", "Texas", "TX"],
"76049": ["Granbury", "Texas", "TX"],
"76050": ["Grandview", "Texas", "TX"],
"76051": ["Grapevine", "Texas", "TX"],
"76052": ["Haslet", "Texas", "TX"],
"76053": ["Hurst", "Texas", "TX"],
"76054": ["Hurst", "Texas", "TX"],
"76055": ["Itasca", "Texas", "TX"],
"76058": ["Joshua", "Texas", "TX"],
"76059": ["Keene", "Texas", "TX"],
"76060": ["Kennedale", "Texas", "TX"],
"76061": ["Lillian", "Texas", "TX"],
"76063": ["Mansfield", "Texas", "TX"],
"76064": ["Maypearl", "Texas", "TX"],
"76065": ["Midlothian", "Texas", "TX"],
"76066": ["Millsap", "Texas", "TX"],
"76067": ["Mineral Wells", "Texas", "TX"],
"76070": ["Nemo", "Texas", "TX"],
"76071": ["Newark", "Texas", "TX"],
"76073": ["Paradise", "Texas", "TX"],
"76077": ["Rainbow", "Texas", "TX"],
"76078": ["Rhome", "Texas", "TX"],
"76082": ["Springtown", "Texas", "TX"],
"76084": ["Venus", "Texas", "TX"],
"76085": ["Weatherford", "Texas", "TX"],
"76086": ["Weatherford", "Texas", "TX"],
"76087": ["Weatherford", "Texas", "TX"],
"76088": ["Weatherford", "Texas", "TX"],
"76092": ["Southlake", "Texas", "TX"],
"76093": ["Rio Vista", "Texas", "TX"],
"76102": ["Fort Worth", "Texas", "TX"],
"76103": ["Fort Worth", "Texas", "TX"],
"76104": ["Fort Worth", "Texas", "TX"],
"76105": ["Fort Worth", "Texas", "TX"],
"76106": ["Fort Worth", "Texas", "TX"],
"76107": ["Fort Worth", "Texas", "TX"],
"76108": ["Fort Worth", "Texas", "TX"],
"76109": ["Fort Worth", "Texas", "TX"],
"76110": ["Fort Worth", "Texas", "TX"],
"76111": ["Fort Worth", "Texas", "TX"],
"76112": ["Fort Worth", "Texas", "TX"],
"76114": ["Fort Worth", "Texas", "TX"],
"76115": ["Fort Worth", "Texas", "TX"],
"76116": ["Fort Worth", "Texas", "TX"],
"76117": ["Haltom City", "Texas", "TX"],
"76118": ["Fort Worth", "Texas", "TX"],
"76119": ["Fort Worth", "Texas", "TX"],
"76120": ["Fort Worth", "Texas", "TX"],
"76123": ["Fort Worth", "Texas", "TX"],
"76126": ["Fort Worth", "Texas", "TX"],
"76127": ["Naval Air Station Jrb", "Texas", "TX"],
"76129": ["Fort Worth", "Texas", "TX"],
"76131": ["Fort Worth", "Texas", "TX"],
"76132": ["Fort Worth", "Texas", "TX"],
"76133": ["Fort Worth", "Texas", "TX"],
"76134": ["Fort Worth", "Texas", "TX"],
"76135": ["Fort Worth", "Texas", "TX"],
"76137": ["Fort Worth", "Texas", "TX"],
"76140": ["Fort Worth", "Texas", "TX"],
"76148": ["Fort Worth", "Texas", "TX"],
"76155": ["Fort Worth", "Texas", "TX"],
"76164": ["Fort Worth", "Texas", "TX"],
"76177": ["Fort Worth", "Texas", "TX"],
"76179": ["Fort Worth", "Texas", "TX"],
"76180": ["North Richland Hills", "Texas", "TX"],
"76182": ["North Richland Hills", "Texas", "TX"],
"76201": ["Denton", "Texas", "TX"],
"76203": ["Denton", "Texas", "TX"],
"76205": ["Denton", "Texas", "TX"],
"76207": ["Denton", "Texas", "TX"],
"76208": ["Denton", "Texas", "TX"],
"76209": ["Denton", "Texas", "TX"],
"76210": ["Denton", "Texas", "TX"],
"76225": ["Alvord", "Texas", "TX"],
"76226": ["Argyle", "Texas", "TX"],
"76227": ["Aubrey", "Texas", "TX"],
"76228": ["Bellevue", "Texas", "TX"],
"76230": ["Bowie", "Texas", "TX"],
"76233": ["Collinsville", "Texas", "TX"],
"76234": ["Decatur", "Texas", "TX"],
"76238": ["Era", "Texas", "TX"],
"76239": ["Forestburg", "Texas", "TX"],
"76240": ["Gainesville", "Texas", "TX"],
"76241": ["Gainesville", "Texas", "TX"],
"76244": ["Keller", "Texas", "TX"],
"76245": ["Gordonville", "Texas", "TX"],
"76247": ["Justin", "Texas", "TX"],
"76248": ["Keller", "Texas", "TX"],
"76249": ["Krum", "Texas", "TX"],
"76250": ["Lindsay", "Texas", "TX"],
"76251": ["Montague", "Texas", "TX"],
"76252": ["Muenster", "Texas", "TX"],
"76253": ["Myra", "Texas", "TX"],
"76255": ["Nocona", "Texas", "TX"],
"76258": ["Pilot Point", "Texas", "TX"],
"76259": ["Ponder", "Texas", "TX"],
"76261": ["Ringgold", "Texas", "TX"],
"76262": ["Roanoke", "Texas", "TX"],
"76263": ["Rosston", "Texas", "TX"],
"76264": ["Sadler", "Texas", "TX"],
"76265": ["Saint Jo", "Texas", "TX"],
"76266": ["Sanger", "Texas", "TX"],
"76267": ["Slidell", "Texas", "TX"],
"76268": ["Southmayd", "Texas", "TX"],
"76270": ["Sunset", "Texas", "TX"],
"76271": ["Tioga", "Texas", "TX"],
"76272": ["Valley View", "Texas", "TX"],
"76273": ["Whitesboro", "Texas", "TX"],
"76301": ["Wichita Falls", "Texas", "TX"],
"76302": ["Wichita Falls", "Texas", "TX"],
"76305": ["Wichita Falls", "Texas", "TX"],
"76306": ["Wichita Falls", "Texas", "TX"],
"76308": ["Wichita Falls", "Texas", "TX"],
"76309": ["Wichita Falls", "Texas", "TX"],
"76310": ["Wichita Falls", "Texas", "TX"],
"76311": ["Sheppard Afb", "Texas", "TX"],
"76351": ["Archer City", "Texas", "TX"],
"76354": ["Burkburnett", "Texas", "TX"],
"76357": ["Byers", "Texas", "TX"],
"76360": ["Electra", "Texas", "TX"],
"76363": ["Goree", "Texas", "TX"],
"76364": ["Harrold", "Texas", "TX"],
"76365": ["Henrietta", "Texas", "TX"],
"76366": ["Holliday", "Texas", "TX"],
"76367": ["Iowa Park", "Texas", "TX"],
"76370": ["Megargel", "Texas", "TX"],
"76371": ["Munday", "Texas", "TX"],
"76372": ["Newcastle", "Texas", "TX"],
"76373": ["Oklaunion", "Texas", "TX"],
"76374": ["Olney", "Texas", "TX"],
"76377": ["Petrolia", "Texas", "TX"],
"76379": ["Scotland", "Texas", "TX"],
"76380": ["Seymour", "Texas", "TX"],
"76384": ["Vernon", "Texas", "TX"],
"76388": ["Weinert", "Texas", "TX"],
"76389": ["Windthorst", "Texas", "TX"],
"76401": ["Stephenville", "Texas", "TX"],
"76402": ["Stephenville", "Texas", "TX"],
"76424": ["Breckenridge", "Texas", "TX"],
"76426": ["Bridgeport", "Texas", "TX"],
"76427": ["Bryson", "Texas", "TX"],
"76429": ["Caddo", "Texas", "TX"],
"76430": ["Albany", "Texas", "TX"],
"76431": ["Chico", "Texas", "TX"],
"76432": ["Blanket", "Texas", "TX"],
"76433": ["Bluff Dale", "Texas", "TX"],
"76435": ["Carbon", "Texas", "TX"],
"76436": ["Carlton", "Texas", "TX"],
"76437": ["Cisco", "Texas", "TX"],
"76439": ["Dennis", "Texas", "TX"],
"76442": ["Comanche", "Texas", "TX"],
"76443": ["Cross Plains", "Texas", "TX"],
"76444": ["De Leon", "Texas", "TX"],
"76445": ["Desdemona", "Texas", "TX"],
"76446": ["Dublin", "Texas", "TX"],
"76448": ["Eastland", "Texas", "TX"],
"76449": ["Graford", "Texas", "TX"],
"76450": ["Graham", "Texas", "TX"],
"76452": ["Energy", "Texas", "TX"],
"76453": ["Gordon", "Texas", "TX"],
"76454": ["Gorman", "Texas", "TX"],
"76455": ["Gustine", "Texas", "TX"],
"76457": ["Hico", "Texas", "TX"],
"76458": ["Jacksboro", "Texas", "TX"],
"76459": ["Jermyn", "Texas", "TX"],
"76460": ["Loving", "Texas", "TX"],
"76462": ["Lipan", "Texas", "TX"],
"76463": ["Mingus", "Texas", "TX"],
"76464": ["Moran", "Texas", "TX"],
"76466": ["Olden", "Texas", "TX"],
"76469": ["Putnam", "Texas", "TX"],
"76470": ["Ranger", "Texas", "TX"],
"76471": ["Rising Star", "Texas", "TX"],
"76472": ["Santo", "Texas", "TX"],
"76474": ["Sidney", "Texas", "TX"],
"76475": ["Strawn", "Texas", "TX"],
"76476": ["Tolar", "Texas", "TX"],
"76481": ["South Bend", "Texas", "TX"],
"76483": ["Throckmorton", "Texas", "TX"],
"76484": ["Palo Pinto", "Texas", "TX"],
"76486": ["Perrin", "Texas", "TX"],
"76487": ["Poolville", "Texas", "TX"],
"76490": ["Whitt", "Texas", "TX"],
"76491": ["Woodson", "Texas", "TX"],
"76501": ["Temple", "Texas", "TX"],
"76502": ["Temple", "Texas", "TX"],
"76504": ["Temple", "Texas", "TX"],
"76511": ["Bartlett", "Texas", "TX"],
"76513": ["Belton", "Texas", "TX"],
"76518": ["Buckholts", "Texas", "TX"],
"76519": ["Burlington", "Texas", "TX"],
"76520": ["Cameron", "Texas", "TX"],
"76522": ["Copperas Cove", "Texas", "TX"],
"76523": ["Davilla", "Texas", "TX"],
"76524": ["Eddy", "Texas", "TX"],
"76525": ["Evant", "Texas", "TX"],
"76527": ["Florence", "Texas", "TX"],
"76528": ["Gatesville", "Texas", "TX"],
"76530": ["Granger", "Texas", "TX"],
"76531": ["Hamilton", "Texas", "TX"],
"76534": ["Holland", "Texas", "TX"],
"76537": ["Jarrell", "Texas", "TX"],
"76538": ["Jonesboro", "Texas", "TX"],
"76539": ["Kempner", "Texas", "TX"],
"76541": ["Killeen", "Texas", "TX"],
"76542": ["Killeen", "Texas", "TX"],
"76543": ["Killeen", "Texas", "TX"],
"76544": ["Fort Hood", "Texas", "TX"],
"76548": ["Harker Heights", "Texas", "TX"],
"76549": ["Killeen", "Texas", "TX"],
"76550": ["Lampasas", "Texas", "TX"],
"76554": ["Little River Academy", "Texas", "TX"],
"76556": ["Milano", "Texas", "TX"],
"76557": ["Moody", "Texas", "TX"],
"76559": ["Nolanville", "Texas", "TX"],
"76561": ["Oglesby", "Texas", "TX"],
"76565": ["Pottsville", "Texas", "TX"],
"76566": ["Purmela", "Texas", "TX"],
"76567": ["Rockdale", "Texas", "TX"],
"76569": ["Rogers", "Texas", "TX"],
"76570": ["Rosebud", "Texas", "TX"],
"76571": ["Salado", "Texas", "TX"],
"76573": ["Schwertner", "Texas", "TX"],
"76574": ["Taylor", "Texas", "TX"],
"76577": ["Thorndale", "Texas", "TX"],
"76578": ["Thrall", "Texas", "TX"],
"76579": ["Troy", "Texas", "TX"],
"76596": ["Gatesville", "Texas", "TX"],
"76597": ["Gatesville", "Texas", "TX"],
"76598": ["Gatesville", "Texas", "TX"],
"76599": ["Gatesville", "Texas", "TX"],
"76621": ["Abbott", "Texas", "TX"],
"76622": ["Aquilla", "Texas", "TX"],
"76623": ["Avalon", "Texas", "TX"],
"76624": ["Axtell", "Texas", "TX"],
"76626": ["Blooming Grove", "Texas", "TX"],
"76627": ["Blum", "Texas", "TX"],
"76628": ["Brandon", "Texas", "TX"],
"76629": ["Bremond", "Texas", "TX"],
"76630": ["Bruceville", "Texas", "TX"],
"76631": ["Bynum", "Texas", "TX"],
"76632": ["Chilton", "Texas", "TX"],
"76633": ["China Spring", "Texas", "TX"],
"76634": ["Clifton", "Texas", "TX"],
"76635": ["Coolidge", "Texas", "TX"],
"76636": ["Covington", "Texas", "TX"],
"76637": ["Cranfills Gap", "Texas", "TX"],
"76638": ["Crawford", "Texas", "TX"],
"76639": ["Dawson", "Texas", "TX"],
"76640": ["Elm Mott", "Texas", "TX"],
"76641": ["Frost", "Texas", "TX"],
"76642": ["Groesbeck", "Texas", "TX"],
"76643": ["Hewitt", "Texas", "TX"],
"76645": ["Hillsboro", "Texas", "TX"],
"76648": ["Hubbard", "Texas", "TX"],
"76649": ["Iredell", "Texas", "TX"],
"76650": ["Irene", "Texas", "TX"],
"76651": ["Italy", "Texas", "TX"],
"76652": ["Kopperl", "Texas", "TX"],
"76653": ["Kosse", "Texas", "TX"],
"76654": ["Leroy", "Texas", "TX"],
"76655": ["Lorena", "Texas", "TX"],
"76656": ["Lott", "Texas", "TX"],
"76657": ["McGregor", "Texas", "TX"],
"76660": ["Malone", "Texas", "TX"],
"76661": ["Marlin", "Texas", "TX"],
"76664": ["Mart", "Texas", "TX"],
"76665": ["Meridian", "Texas", "TX"],
"76666": ["Mertens", "Texas", "TX"],
"76667": ["Mexia", "Texas", "TX"],
"76670": ["Milford", "Texas", "TX"],
"76671": ["Morgan", "Texas", "TX"],
"76673": ["Mount Calm", "Texas", "TX"],
"76676": ["Penelope", "Texas", "TX"],
"76678": ["Prairie Hill", "Texas", "TX"],
"76679": ["Purdon", "Texas", "TX"],
"76680": ["Reagan", "Texas", "TX"],
"76681": ["Richland", "Texas", "TX"],
"76682": ["Riesel", "Texas", "TX"],
"76685": ["Satin", "Texas", "TX"],
"76686": ["Tehuacana", "Texas", "TX"],
"76687": ["Thornton", "Texas", "TX"],
"76689": ["Valley Mills", "Texas", "TX"],
"76690": ["Walnut Springs", "Texas", "TX"],
"76691": ["West", "Texas", "TX"],
"76692": ["Whitney", "Texas", "TX"],
"76693": ["Wortham", "Texas", "TX"],
"76701": ["Waco", "Texas", "TX"],
"76704": ["Waco", "Texas", "TX"],
"76705": ["Waco", "Texas", "TX"],
"76706": ["Waco", "Texas", "TX"],
"76707": ["Waco", "Texas", "TX"],
"76708": ["Waco", "Texas", "TX"],
"76710": ["Waco", "Texas", "TX"],
"76711": ["Waco", "Texas", "TX"],
"76712": ["Woodway", "Texas", "TX"],
"76798": ["Waco", "Texas", "TX"],
"76801": ["Brownwood", "Texas", "TX"],
"76802": ["Early", "Texas", "TX"],
"76820": ["Art", "Texas", "TX"],
"76821": ["Ballinger", "Texas", "TX"],
"76823": ["Bangs", "Texas", "TX"],
"76824": ["Bend", "Texas", "TX"],
"76825": ["Brady", "Texas", "TX"],
"76827": ["Brookesmith", "Texas", "TX"],
"76828": ["Burkett", "Texas", "TX"],
"76831": ["Castell", "Texas", "TX"],
"76832": ["Cherokee", "Texas", "TX"],
"76834": ["Coleman", "Texas", "TX"],
"76836": ["Doole", "Texas", "TX"],
"76837": ["Eden", "Texas", "TX"],
"76841": ["Fort McKavett", "Texas", "TX"],
"76842": ["Fredonia", "Texas", "TX"],
"76844": ["Goldthwaite", "Texas", "TX"],
"76845": ["Gouldbusk", "Texas", "TX"],
"76848": ["Hext", "Texas", "TX"],
"76849": ["Junction", "Texas", "TX"],
"76852": ["Lohn", "Texas", "TX"],
"76853": ["Lometa", "Texas", "TX"],
"76854": ["London", "Texas", "TX"],
"76856": ["Mason", "Texas", "TX"],
"76857": ["May", "Texas", "TX"],
"76858": ["Melvin", "Texas", "TX"],
"76859": ["Menard", "Texas", "TX"],
"76861": ["Miles", "Texas", "TX"],
"76862": ["Millersview", "Texas", "TX"],
"76864": ["Mullin", "Texas", "TX"],
"76865": ["Norton", "Texas", "TX"],
"76866": ["Paint Rock", "Texas", "TX"],
"76869": ["Pontotoc", "Texas", "TX"],
"76870": ["Priddy", "Texas", "TX"],
"76871": ["Richland Springs", "Texas", "TX"],
"76872": ["Rochelle", "Texas", "TX"],
"76873": ["Rockwood", "Texas", "TX"],
"76874": ["Roosevelt", "Texas", "TX"],
"76875": ["Rowena", "Texas", "TX"],
"76877": ["San Saba", "Texas", "TX"],
"76878": ["Santa Anna", "Texas", "TX"],
"76880": ["Star", "Texas", "TX"],
"76882": ["Talpa", "Texas", "TX"],
"76884": ["Valera", "Texas", "TX"],
"76885": ["Valley Spring", "Texas", "TX"],
"76887": ["Voca", "Texas", "TX"],
"76888": ["Voss", "Texas", "TX"],
"76890": ["Zephyr", "Texas", "TX"],
"76901": ["San Angelo", "Texas", "TX"],
"76902": ["San Angelo", "Texas", "TX"],
"76903": ["San Angelo", "Texas", "TX"],
"76904": ["San Angelo", "Texas", "TX"],
"76905": ["San Angelo", "Texas", "TX"],
"76908": ["Goodfellow Afb", "Texas", "TX"],
"76909": ["San Angelo", "Texas", "TX"],
"76930": ["Barnhart", "Texas", "TX"],
"76932": ["Big Lake", "Texas", "TX"],
"76933": ["Bronte", "Texas", "TX"],
"76934": ["Carlsbad", "Texas", "TX"],
"76935": ["Christoval", "Texas", "TX"],
"76936": ["Eldorado", "Texas", "TX"],
"76937": ["Eola", "Texas", "TX"],
"76939": ["Knickerbocker", "Texas", "TX"],
"76940": ["Mereta", "Texas", "TX"],
"76941": ["Mertzon", "Texas", "TX"],
"76943": ["Ozona", "Texas", "TX"],
"76945": ["Robert Lee", "Texas", "TX"],
"76950": ["Sonora", "Texas", "TX"],
"76951": ["Sterling City", "Texas", "TX"],
"76953": ["Tennyson", "Texas", "TX"],
"76955": ["Vancourt", "Texas", "TX"],
"76957": ["Wall", "Texas", "TX"],
"76958": ["Water Valley", "Texas", "TX"],
"77002": ["Houston", "Texas", "TX"],
"77003": ["Houston", "Texas", "TX"],
"77004": ["Houston", "Texas", "TX"],
"77005": ["Houston", "Texas", "TX"],
"77006": ["Houston", "Texas", "TX"],
"77007": ["Houston", "Texas", "TX"],
"77008": ["Houston", "Texas", "TX"],
"77009": ["Houston", "Texas", "TX"],
"77010": ["Houston", "Texas", "TX"],
"77011": ["Houston", "Texas", "TX"],
"77012": ["Houston", "Texas", "TX"],
"77013": ["Houston", "Texas", "TX"],
"77014": ["Houston", "Texas", "TX"],
"77015": ["Houston", "Texas", "TX"],
"77016": ["Houston", "Texas", "TX"],
"77017": ["Houston", "Texas", "TX"],
"77018": ["Houston", "Texas", "TX"],
"77019": ["Houston", "Texas", "TX"],
"77020": ["Houston", "Texas", "TX"],
"77021": ["Houston", "Texas", "TX"],
"77022": ["Houston", "Texas", "TX"],
"77023": ["Houston", "Texas", "TX"],
"77024": ["Houston", "Texas", "TX"],
"77025": ["Houston", "Texas", "TX"],
"77026": ["Houston", "Texas", "TX"],
"77027": ["Houston", "Texas", "TX"],
"77028": ["Houston", "Texas", "TX"],
"77029": ["Houston", "Texas", "TX"],
"77030": ["Houston", "Texas", "TX"],
"77031": ["Houston", "Texas", "TX"],
"77032": ["Houston", "Texas", "TX"],
"77033": ["Houston", "Texas", "TX"],
"77034": ["Houston", "Texas", "TX"],
"77035": ["Houston", "Texas", "TX"],
"77036": ["Houston", "Texas", "TX"],
"77037": ["Houston", "Texas", "TX"],
"77038": ["Houston", "Texas", "TX"],
"77039": ["Houston", "Texas", "TX"],
"77040": ["Houston", "Texas", "TX"],
"77041": ["Houston", "Texas", "TX"],
"77042": ["Houston", "Texas", "TX"],
"77043": ["Houston", "Texas", "TX"],
"77044": ["Houston", "Texas", "TX"],
"77045": ["Houston", "Texas", "TX"],
"77046": ["Houston", "Texas", "TX"],
"77047": ["Houston", "Texas", "TX"],
"77048": ["Houston", "Texas", "TX"],
"77049": ["Houston", "Texas", "TX"],
"77050": ["Houston", "Texas", "TX"],
"77051": ["Houston", "Texas", "TX"],
"77053": ["Houston", "Texas", "TX"],
"77054": ["Houston", "Texas", "TX"],
"77055": ["Houston", "Texas", "TX"],
"77056": ["Houston", "Texas", "TX"],
"77057": ["Houston", "Texas", "TX"],
"77058": ["Houston", "Texas", "TX"],
"77059": ["Houston", "Texas", "TX"],
"77060": ["Houston", "Texas", "TX"],
"77061": ["Houston", "Texas", "TX"],
"77062": ["Houston", "Texas", "TX"],
"77063": ["Houston", "Texas", "TX"],
"77064": ["Houston", "Texas", "TX"],
"77065": ["Houston", "Texas", "TX"],
"77066": ["Houston", "Texas", "TX"],
"77067": ["Houston", "Texas", "TX"],
"77068": ["Houston", "Texas", "TX"],
"77069": ["Houston", "Texas", "TX"],
"77070": ["Houston", "Texas", "TX"],
"77071": ["Houston", "Texas", "TX"],
"77072": ["Houston", "Texas", "TX"],
"77073": ["Houston", "Texas", "TX"],
"77074": ["Houston", "Texas", "TX"],
"77075": ["Houston", "Texas", "TX"],
"77076": ["Houston", "Texas", "TX"],
"77077": ["Houston", "Texas", "TX"],
"77078": ["Houston", "Texas", "TX"],
"77079": ["Houston", "Texas", "TX"],
"77080": ["Houston", "Texas", "TX"],
"77081": ["Houston", "Texas", "TX"],
"77082": ["Houston", "Texas", "TX"],
"77083": ["Houston", "Texas", "TX"],
"77084": ["Houston", "Texas", "TX"],
"77085": ["Houston", "Texas", "TX"],
"77086": ["Houston", "Texas", "TX"],
"77087": ["Houston", "Texas", "TX"],
"77088": ["Houston", "Texas", "TX"],
"77089": ["Houston", "Texas", "TX"],
"77090": ["Houston", "Texas", "TX"],
"77091": ["Houston", "Texas", "TX"],
"77092": ["Houston", "Texas", "TX"],
"77093": ["Houston", "Texas", "TX"],
"77094": ["Houston", "Texas", "TX"],
"77095": ["Houston", "Texas", "TX"],
"77096": ["Houston", "Texas", "TX"],
"77098": ["Houston", "Texas", "TX"],
"77099": ["Houston", "Texas", "TX"],
"77204": ["Houston", "Texas", "TX"],
"77301": ["Conroe", "Texas", "TX"],
"77302": ["Conroe", "Texas", "TX"],
"77303": ["Conroe", "Texas", "TX"],
"77304": ["Conroe", "Texas", "TX"],
"77306": ["Conroe", "Texas", "TX"],
"77316": ["Montgomery", "Texas", "TX"],
"77318": ["Willis", "Texas", "TX"],
"77320": ["Huntsville", "Texas", "TX"],
"77326": ["Ace", "Texas", "TX"],
"77327": ["Cleveland", "Texas", "TX"],
"77328": ["Cleveland", "Texas", "TX"],
"77331": ["Coldspring", "Texas", "TX"],
"77334": ["Dodge", "Texas", "TX"],
"77335": ["Goodrich", "Texas", "TX"],
"77336": ["Huffman", "Texas", "TX"],
"77338": ["Humble", "Texas", "TX"],
"77339": ["Kingwood", "Texas", "TX"],
"77340": ["Huntsville", "Texas", "TX"],
"77341": ["Huntsville", "Texas", "TX"],
"77342": ["Huntsville", "Texas", "TX"],
"77345": ["Kingwood", "Texas", "TX"],
"77346": ["Humble", "Texas", "TX"],
"77350": ["Leggett", "Texas", "TX"],
"77351": ["Livingston", "Texas", "TX"],
"77354": ["Magnolia", "Texas", "TX"],
"77355": ["Magnolia", "Texas", "TX"],
"77356": ["Montgomery", "Texas", "TX"],
"77357": ["New Caney", "Texas", "TX"],
"77358": ["New Waverly", "Texas", "TX"],
"77359": ["Oakhurst", "Texas", "TX"],
"77360": ["Onalaska", "Texas", "TX"],
"77362": ["Pinehurst", "Texas", "TX"],
"77363": ["Plantersville", "Texas", "TX"],
"77364": ["Pointblank", "Texas", "TX"],
"77365": ["Porter", "Texas", "TX"],
"77367": ["Riverside", "Texas", "TX"],
"77368": ["Romayor", "Texas", "TX"],
"77369": ["Rye", "Texas", "TX"],
"77371": ["Shepherd", "Texas", "TX"],
"77372": ["Splendora", "Texas", "TX"],
"77373": ["Spring", "Texas", "TX"],
"77374": ["Thicket", "Texas", "TX"],
"77375": ["Tomball", "Texas", "TX"],
"77376": ["Votaw", "Texas", "TX"],
"77377": ["Tomball", "Texas", "TX"],
"77378": ["Willis", "Texas", "TX"],
"77379": ["Spring", "Texas", "TX"],
"77380": ["Spring", "Texas", "TX"],
"77381": ["Spring", "Texas", "TX"],
"77382": ["Spring", "Texas", "TX"],
"77384": ["Conroe", "Texas", "TX"],
"77385": ["Conroe", "Texas", "TX"],
"77386": ["Spring", "Texas", "TX"],
"77388": ["Spring", "Texas", "TX"],
"77389": ["Spring", "Texas", "TX"],
"77396": ["Humble", "Texas", "TX"],
"77401": ["Bellaire", "Texas", "TX"],
"77406": ["Richmond", "Texas", "TX"],
"77407": ["Richmond", "Texas", "TX"],
"77412": ["Altair", "Texas", "TX"],
"77414": ["Bay City", "Texas", "TX"],
"77415": ["Cedar Lane", "Texas", "TX"],
"77417": ["Beasley", "Texas", "TX"],
"77418": ["Bellville", "Texas", "TX"],
"77419": ["Blessing", "Texas", "TX"],
"77420": ["Boling", "Texas", "TX"],
"77422": ["Brazoria", "Texas", "TX"],
"77423": ["Brookshire", "Texas", "TX"],
"77426": ["Chappell Hill", "Texas", "TX"],
"77428": ["Collegeport", "Texas", "TX"],
"77429": ["Cypress", "Texas", "TX"],
"77430": ["Damon", "Texas", "TX"],
"77431": ["Danciger", "Texas", "TX"],
"77432": ["Danevang", "Texas", "TX"],
"77433": ["Cypress", "Texas", "TX"],
"77434": ["Eagle Lake", "Texas", "TX"],
"77435": ["East Bernard", "Texas", "TX"],
"77436": ["Egypt", "Texas", "TX"],
"77437": ["El Campo", "Texas", "TX"],
"77440": ["Elmaton", "Texas", "TX"],
"77441": ["Fulshear", "Texas", "TX"],
"77442": ["Garwood", "Texas", "TX"],
"77443": ["Glen Flora", "Texas", "TX"],
"77444": ["Guy", "Texas", "TX"],
"77445": ["Hempstead", "Texas", "TX"],
"77446": ["Prairie View", "Texas", "TX"],
"77447": ["Hockley", "Texas", "TX"],
"77448": ["Hungerford", "Texas", "TX"],
"77449": ["Katy", "Texas", "TX"],
"77450": ["Katy", "Texas", "TX"],
"77451": ["Kendleton", "Texas", "TX"],
"77453": ["Lane City", "Texas", "TX"],
"77454": ["Lissie", "Texas", "TX"],
"77455": ["Louise", "Texas", "TX"],
"77456": ["Markham", "Texas", "TX"],
"77457": ["Matagorda", "Texas", "TX"],
"77458": ["Midfield", "Texas", "TX"],
"77459": ["Missouri City", "Texas", "TX"],
"77460": ["Nada", "Texas", "TX"],
"77461": ["Needville", "Texas", "TX"],
"77464": ["Orchard", "Texas", "TX"],
"77465": ["Palacios", "Texas", "TX"],
"77466": ["Pattison", "Texas", "TX"],
"77467": ["Pierce", "Texas", "TX"],
"77468": ["Pledger", "Texas", "TX"],
"77469": ["Richmond", "Texas", "TX"],
"77470": ["Rock Island", "Texas", "TX"],
"77471": ["Rosenberg", "Texas", "TX"],
"77473": ["San Felipe", "Texas", "TX"],
"77474": ["Sealy", "Texas", "TX"],
"77475": ["Sheridan", "Texas", "TX"],
"77476": ["Simonton", "Texas", "TX"],
"77477": ["Stafford", "Texas", "TX"],
"77478": ["Sugar Land", "Texas", "TX"],
"77479": ["Sugar Land", "Texas", "TX"],
"77480": ["Sweeny", "Texas", "TX"],
"77481": ["Thompsons", "Texas", "TX"],
"77482": ["Van Vleck", "Texas", "TX"],
"77483": ["Wadsworth", "Texas", "TX"],
"77484": ["Waller", "Texas", "TX"],
"77485": ["Wallis", "Texas", "TX"],
"77486": ["West Columbia", "Texas", "TX"],
"77488": ["Wharton", "Texas", "TX"],
"77489": ["Missouri City", "Texas", "TX"],
"77493": ["Katy", "Texas", "TX"],
"77494": ["Katy", "Texas", "TX"],
"77498": ["Sugar Land", "Texas", "TX"],
"77502": ["Pasadena", "Texas", "TX"],
"77503": ["Pasadena", "Texas", "TX"],
"77504": ["Pasadena", "Texas", "TX"],
"77505": ["Pasadena", "Texas", "TX"],
"77506": ["Pasadena", "Texas", "TX"],
"77507": ["Pasadena", "Texas", "TX"],
"77510": ["Santa Fe", "Texas", "TX"],
"77511": ["Alvin", "Texas", "TX"],
"77514": ["Anahuac", "Texas", "TX"],
"77515": ["Angleton", "Texas", "TX"],
"77517": ["Santa Fe", "Texas", "TX"],
"77518": ["Bacliff", "Texas", "TX"],
"77519": ["Batson", "Texas", "TX"],
"77520": ["Baytown", "Texas", "TX"],
"77521": ["Baytown", "Texas", "TX"],
"77523": ["Baytown", "Texas", "TX"],
"77530": ["Channelview", "Texas", "TX"],
"77531": ["Clute", "Texas", "TX"],
"77532": ["Crosby", "Texas", "TX"],
"77533": ["Daisetta", "Texas", "TX"],
"77534": ["Danbury", "Texas", "TX"],
"77535": ["Dayton", "Texas", "TX"],
"77536": ["Deer Park", "Texas", "TX"],
"77538": ["Devers", "Texas", "TX"],
"77539": ["Dickinson", "Texas", "TX"],
"77541": ["Freeport", "Texas", "TX"],
"77545": ["Fresno", "Texas", "TX"],
"77546": ["Friendswood", "Texas", "TX"],
"77547": ["Galena Park", "Texas", "TX"],
"77550": ["Galveston", "Texas", "TX"],
"77551": ["Galveston", "Texas", "TX"],
"77554": ["Galveston", "Texas", "TX"],
"77555": ["Galveston", "Texas", "TX"],
"77560": ["Hankamer", "Texas", "TX"],
"77561": ["Hardin", "Texas", "TX"],
"77562": ["Highlands", "Texas", "TX"],
"77563": ["Hitchcock", "Texas", "TX"],
"77564": ["Hull", "Texas", "TX"],
"77565": ["Kemah", "Texas", "TX"],
"77566": ["Lake Jackson", "Texas", "TX"],
"77568": ["La Marque", "Texas", "TX"],
"77571": ["La Porte", "Texas", "TX"],
"77573": ["League City", "Texas", "TX"],
"77575": ["Liberty", "Texas", "TX"],
"77577": ["Liverpool", "Texas", "TX"],
"77578": ["Manvel", "Texas", "TX"],
"77580": ["Mont Belvieu", "Texas", "TX"],
"77581": ["Pearland", "Texas", "TX"],
"77582": ["Raywood", "Texas", "TX"],
"77583": ["Rosharon", "Texas", "TX"],
"77584": ["Pearland", "Texas", "TX"],
"77585": ["Saratoga", "Texas", "TX"],
"77586": ["Seabrook", "Texas", "TX"],
"77587": ["South Houston", "Texas", "TX"],
"77590": ["Texas City", "Texas", "TX"],
"77591": ["Texas City", "Texas", "TX"],
"77597": ["Wallisville", "Texas", "TX"],
"77598": ["Webster", "Texas", "TX"],
"77611": ["Bridge City", "Texas", "TX"],
"77612": ["Buna", "Texas", "TX"],
"77613": ["China", "Texas", "TX"],
"77614": ["Deweyville", "Texas", "TX"],
"77615": ["Evadale", "Texas", "TX"],
"77616": ["Fred", "Texas", "TX"],
"77617": ["Gilchrist", "Texas", "TX"],
"77619": ["Groves", "Texas", "TX"],
"77622": ["Hamshire", "Texas", "TX"],
"77623": ["High Island", "Texas", "TX"],
"77624": ["Hillister", "Texas", "TX"],
"77625": ["Kountze", "Texas", "TX"],
"77627": ["Nederland", "Texas", "TX"],
"77629": ["Nome", "Texas", "TX"],
"77630": ["Orange", "Texas", "TX"],
"77632": ["Orange", "Texas", "TX"],
"77640": ["Port Arthur", "Texas", "TX"],
"77642": ["Port Arthur", "Texas", "TX"],
"77650": ["Port Bolivar", "Texas", "TX"],
"77651": ["Port Neches", "Texas", "TX"],
"77655": ["Sabine Pass", "Texas", "TX"],
"77656": ["Silsbee", "Texas", "TX"],
"77657": ["Lumberton", "Texas", "TX"],
"77659": ["Sour Lake", "Texas", "TX"],
"77660": ["Spurger", "Texas", "TX"],
"77661": ["Stowell", "Texas", "TX"],
"77662": ["Vidor", "Texas", "TX"],
"77663": ["Village Mills", "Texas", "TX"],
"77664": ["Warren", "Texas", "TX"],
"77665": ["Winnie", "Texas", "TX"],
"77701": ["Beaumont", "Texas", "TX"],
"77702": ["Beaumont", "Texas", "TX"],
"77703": ["Beaumont", "Texas", "TX"],
"77705": ["Beaumont", "Texas", "TX"],
"77706": ["Beaumont", "Texas", "TX"],
"77707": ["Beaumont", "Texas", "TX"],
"77708": ["Beaumont", "Texas", "TX"],
"77713": ["Beaumont", "Texas", "TX"],
"77801": ["Bryan", "Texas", "TX"],
"77802": ["Bryan", "Texas", "TX"],
"77803": ["Bryan", "Texas", "TX"],
"77807": ["Bryan", "Texas", "TX"],
"77808": ["Bryan", "Texas", "TX"],
"77830": ["Anderson", "Texas", "TX"],
"77831": ["Bedias", "Texas", "TX"],
"77833": ["Brenham", "Texas", "TX"],
"77835": ["Burton", "Texas", "TX"],
"77836": ["Caldwell", "Texas", "TX"],
"77837": ["Calvert", "Texas", "TX"],
"77840": ["College Station", "Texas", "TX"],
"77843": ["College Station", "Texas", "TX"],
"77845": ["College Station", "Texas", "TX"],
"77852": ["Deanville", "Texas", "TX"],
"77853": ["Dime Box", "Texas", "TX"],
"77855": ["Flynn", "Texas", "TX"],
"77856": ["Franklin", "Texas", "TX"],
"77857": ["Gause", "Texas", "TX"],
"77859": ["Hearne", "Texas", "TX"],
"77861": ["Iola", "Texas", "TX"],
"77863": ["Lyons", "Texas", "TX"],
"77864": ["Madisonville", "Texas", "TX"],
"77865": ["Marquez", "Texas", "TX"],
"77866": ["Millican", "Texas", "TX"],
"77867": ["Mumford", "Texas", "TX"],
"77868": ["Navasota", "Texas", "TX"],
"77871": ["Normangee", "Texas", "TX"],
"77872": ["North Zulch", "Texas", "TX"],
"77873": ["Richards", "Texas", "TX"],
"77875": ["Roans Prairie", "Texas", "TX"],
"77876": ["Shiro", "Texas", "TX"],
"77878": ["Snook", "Texas", "TX"],
"77879": ["Somerville", "Texas", "TX"],
"77880": ["Washington", "Texas", "TX"],
"77882": ["Wheelock", "Texas", "TX"],
"77901": ["Victoria", "Texas", "TX"],
"77904": ["Victoria", "Texas", "TX"],
"77905": ["Victoria", "Texas", "TX"],
"77950": ["Austwell", "Texas", "TX"],
"77951": ["Bloomington", "Texas", "TX"],
"77954": ["Cuero", "Texas", "TX"],
"77957": ["Edna", "Texas", "TX"],
"77960": ["Fannin", "Texas", "TX"],
"77961": ["Francitas", "Texas", "TX"],
"77962": ["Ganado", "Texas", "TX"],
"77963": ["Goliad", "Texas", "TX"],
"77964": ["Hallettsville", "Texas", "TX"],
"77968": ["Inez", "Texas", "TX"],
"77969": ["La Salle", "Texas", "TX"],
"77970": ["La Ward", "Texas", "TX"],
"77971": ["Lolita", "Texas", "TX"],
"77973": ["Mcfaddin", "Texas", "TX"],
"77974": ["Meyersville", "Texas", "TX"],
"77975": ["Moulton", "Texas", "TX"],
"77976": ["Nursery", "Texas", "TX"],
"77977": ["Placedo", "Texas", "TX"],
"77978": ["Point Comfort", "Texas", "TX"],
"77979": ["Port Lavaca", "Texas", "TX"],
"77982": ["Port O'Connor", "Texas", "TX"],
"77983": ["Seadrift", "Texas", "TX"],
"77984": ["Shiner", "Texas", "TX"],
"77986": ["Sublime", "Texas", "TX"],
"77987": ["Sweet Home", "Texas", "TX"],
"77988": ["Telferner", "Texas", "TX"],
"77990": ["Tivoli", "Texas", "TX"],
"77991": ["Vanderbilt", "Texas", "TX"],
"77993": ["Weesatche", "Texas", "TX"],
"77994": ["Westhoff", "Texas", "TX"],
"77995": ["Yoakum", "Texas", "TX"],
"78001": ["Artesia Wells", "Texas", "TX"],
"78002": ["Atascosa", "Texas", "TX"],
"78003": ["Bandera", "Texas", "TX"],
"78004": ["Bergheim", "Texas", "TX"],
"78005": ["Bigfoot", "Texas", "TX"],
"78006": ["Boerne", "Texas", "TX"],
"78007": ["Calliham", "Texas", "TX"],
"78008": ["Campbellton", "Texas", "TX"],
"78009": ["Castroville", "Texas", "TX"],
"78010": ["Center Point", "Texas", "TX"],
"78011": ["Charlotte", "Texas", "TX"],
"78012": ["Christine", "Texas", "TX"],
"78013": ["Comfort", "Texas", "TX"],
"78014": ["Cotulla", "Texas", "TX"],
"78015": ["Boerne", "Texas", "TX"],
"78016": ["Devine", "Texas", "TX"],
"78017": ["Dilley", "Texas", "TX"],
"78019": ["Encinal", "Texas", "TX"],
"78021": ["Fowlerton", "Texas", "TX"],
"78022": ["George West", "Texas", "TX"],
"78023": ["Helotes", "Texas", "TX"],
"78024": ["Hunt", "Texas", "TX"],
"78025": ["Ingram", "Texas", "TX"],
"78026": ["Jourdanton", "Texas", "TX"],
"78027": ["Kendalia", "Texas", "TX"],
"78028": ["Kerrville", "Texas", "TX"],
"78029": ["Kerrville", "Texas", "TX"],
"78039": ["La Coste", "Texas", "TX"],
"78040": ["Laredo", "Texas", "TX"],
"78041": ["Laredo", "Texas", "TX"],
"78043": ["Laredo", "Texas", "TX"],
"78044": ["Laredo", "Texas", "TX"],
"78045": ["Laredo", "Texas", "TX"],
"78046": ["Laredo", "Texas", "TX"],
"78050": ["Leming", "Texas", "TX"],
"78052": ["Lytle", "Texas", "TX"],
"78055": ["Medina", "Texas", "TX"],
"78056": ["Mico", "Texas", "TX"],
"78057": ["Moore", "Texas", "TX"],
"78058": ["Mountain Home", "Texas", "TX"],
"78059": ["Natalia", "Texas", "TX"],
"78061": ["Pearsall", "Texas", "TX"],
"78062": ["Peggy", "Texas", "TX"],
"78063": ["Pipe Creek", "Texas", "TX"],
"78064": ["Pleasanton", "Texas", "TX"],
"78065": ["Poteet", "Texas", "TX"],
"78066": ["Rio Medina", "Texas", "TX"],
"78067": ["San Ygnacio", "Texas", "TX"],
"78069": ["Somerset", "Texas", "TX"],
"78070": ["Spring Branch", "Texas", "TX"],
"78071": ["Three Rivers", "Texas", "TX"],
"78072": ["Tilden", "Texas", "TX"],
"78073": ["Von Ormy", "Texas", "TX"],
"78074": ["Waring", "Texas", "TX"],
"78075": ["Whitsett", "Texas", "TX"],
"78076": ["Zapata", "Texas", "TX"],
"78101": ["Adkins", "Texas", "TX"],
"78102": ["Beeville", "Texas", "TX"],
"78104": ["Beeville", "Texas", "TX"],
"78107": ["Berclair", "Texas", "TX"],
"78108": ["Cibolo", "Texas", "TX"],
"78109": ["Converse", "Texas", "TX"],
"78111": ["Ecleto", "Texas", "TX"],
"78112": ["Elmendorf", "Texas", "TX"],
"78113": ["Falls City", "Texas", "TX"],
"78114": ["Floresville", "Texas", "TX"],
"78116": ["Gillett", "Texas", "TX"],
"78117": ["Hobson", "Texas", "TX"],
"78118": ["Karnes City", "Texas", "TX"],
"78119": ["Kenedy", "Texas", "TX"],
"78121": ["La Vernia", "Texas", "TX"],
"78122": ["Leesville", "Texas", "TX"],
"78123": ["McQueeney", "Texas", "TX"],
"78124": ["Marion", "Texas", "TX"],
"78125": ["Mineral", "Texas", "TX"],
"78130": ["New Braunfels", "Texas", "TX"],
"78132": ["New Braunfels", "Texas", "TX"],
"78133": ["Canyon Lake", "Texas", "TX"],
"78140": ["Nixon", "Texas", "TX"],
"78141": ["Nordheim", "Texas", "TX"],
"78142": ["Normanna", "Texas", "TX"],
"78143": ["Pandora", "Texas", "TX"],
"78144": ["Panna Maria", "Texas", "TX"],
"78145": ["Pawnee", "Texas", "TX"],
"78146": ["Pettus", "Texas", "TX"],
"78147": ["Poth", "Texas", "TX"],
"78148": ["Universal City", "Texas", "TX"],
"78150": ["Jbsa Randolph", "Texas", "TX"],
"78151": ["Runge", "Texas", "TX"],
"78152": ["Saint Hedwig", "Texas", "TX"],
"78154": ["Schertz", "Texas", "TX"],
"78155": ["Seguin", "Texas", "TX"],
"78159": ["Smiley", "Texas", "TX"],
"78160": ["Stockdale", "Texas", "TX"],
"78161": ["Sutherland Springs", "Texas", "TX"],
"78162": ["Tuleta", "Texas", "TX"],
"78163": ["Bulverde", "Texas", "TX"],
"78164": ["Yorktown", "Texas", "TX"],
"78201": ["San Antonio", "Texas", "TX"],
"78202": ["San Antonio", "Texas", "TX"],
"78203": ["San Antonio", "Texas", "TX"],
"78204": ["San Antonio", "Texas", "TX"],
"78205": ["San Antonio", "Texas", "TX"],
"78207": ["San Antonio", "Texas", "TX"],
"78208": ["San Antonio", "Texas", "TX"],
"78209": ["San Antonio", "Texas", "TX"],
"78210": ["San Antonio", "Texas", "TX"],
"78211": ["San Antonio", "Texas", "TX"],
"78212": ["San Antonio", "Texas", "TX"],
"78213": ["San Antonio", "Texas", "TX"],
"78214": ["San Antonio", "Texas", "TX"],
"78215": ["San Antonio", "Texas", "TX"],
"78216": ["San Antonio", "Texas", "TX"],
"78217": ["San Antonio", "Texas", "TX"],
"78218": ["San Antonio", "Texas", "TX"],
"78219": ["San Antonio", "Texas", "TX"],
"78220": ["San Antonio", "Texas", "TX"],
"78221": ["San Antonio", "Texas", "TX"],
"78222": ["San Antonio", "Texas", "TX"],
"78223": ["San Antonio", "Texas", "TX"],
"78224": ["San Antonio", "Texas", "TX"],
"78225": ["San Antonio", "Texas", "TX"],
"78226": ["San Antonio", "Texas", "TX"],
"78227": ["San Antonio", "Texas", "TX"],
"78228": ["San Antonio", "Texas", "TX"],
"78229": ["San Antonio", "Texas", "TX"],
"78230": ["San Antonio", "Texas", "TX"],
"78231": ["San Antonio", "Texas", "TX"],
"78232": ["San Antonio", "Texas", "TX"],
"78233": ["San Antonio", "Texas", "TX"],
"78234": ["Jbsa Ft Sam Houston", "Texas", "TX"],
"78235": ["San Antonio", "Texas", "TX"],
"78236": ["Jbsa Lackland", "Texas", "TX"],
"78237": ["San Antonio", "Texas", "TX"],
"78238": ["San Antonio", "Texas", "TX"],
"78239": ["San Antonio", "Texas", "TX"],
"78240": ["San Antonio", "Texas", "TX"],
"78242": ["San Antonio", "Texas", "TX"],
"78243": ["San Antonio", "Texas", "TX"],
"78244": ["San Antonio", "Texas", "TX"],
"78245": ["San Antonio", "Texas", "TX"],
"78247": ["San Antonio", "Texas", "TX"],
"78248": ["San Antonio", "Texas", "TX"],
"78249": ["San Antonio", "Texas", "TX"],
"78250": ["San Antonio", "Texas", "TX"],
"78251": ["San Antonio", "Texas", "TX"],
"78252": ["San Antonio", "Texas", "TX"],
"78253": ["San Antonio", "Texas", "TX"],
"78254": ["San Antonio", "Texas", "TX"],
"78255": ["San Antonio", "Texas", "TX"],
"78256": ["San Antonio", "Texas", "TX"],
"78257": ["San Antonio", "Texas", "TX"],
"78258": ["San Antonio", "Texas", "TX"],
"78259": ["San Antonio", "Texas", "TX"],
"78260": ["San Antonio", "Texas", "TX"],
"78261": ["San Antonio", "Texas", "TX"],
"78263": ["San Antonio", "Texas", "TX"],
"78264": ["San Antonio", "Texas", "TX"],
"78266": ["San Antonio", "Texas", "TX"],
"78330": ["Agua Dulce", "Texas", "TX"],
"78332": ["Alice", "Texas", "TX"],
"78336": ["Aransas Pass", "Texas", "TX"],
"78338": ["Armstrong", "Texas", "TX"],
"78339": ["Banquete", "Texas", "TX"],
"78340": ["Bayside", "Texas", "TX"],
"78341": ["Benavides", "Texas", "TX"],
"78342": ["Ben Bolt", "Texas", "TX"],
"78343": ["Bishop", "Texas", "TX"],
"78344": ["Bruni", "Texas", "TX"],
"78347": ["Chapman Ranch", "Texas", "TX"],
"78349": ["Concepcion", "Texas", "TX"],
"78351": ["Driscoll", "Texas", "TX"],
"78352": ["Edroy", "Texas", "TX"],
"78353": ["Encino", "Texas", "TX"],
"78355": ["Falfurrias", "Texas", "TX"],
"78357": ["Freer", "Texas", "TX"],
"78358": ["Fulton", "Texas", "TX"],
"78359": ["Gregory", "Texas", "TX"],
"78360": ["Guerra", "Texas", "TX"],
"78361": ["Hebbronville", "Texas", "TX"],
"78362": ["Ingleside", "Texas", "TX"],
"78363": ["Kingsville", "Texas", "TX"],
"78368": ["Mathis", "Texas", "TX"],
"78369": ["Mirando City", "Texas", "TX"],
"78370": ["Odem", "Texas", "TX"],
"78371": ["Oilton", "Texas", "TX"],
"78372": ["Orange Grove", "Texas", "TX"],
"78373": ["Port Aransas", "Texas", "TX"],
"78374": ["Portland", "Texas", "TX"],
"78375": ["Premont", "Texas", "TX"],
"78376": ["Realitos", "Texas", "TX"],
"78377": ["Refugio", "Texas", "TX"],
"78379": ["Riviera", "Texas", "TX"],
"78380": ["Robstown", "Texas", "TX"],
"78382": ["Rockport", "Texas", "TX"],
"78383": ["Sandia", "Texas", "TX"],
"78384": ["San Diego", "Texas", "TX"],
"78385": ["Sarita", "Texas", "TX"],
"78387": ["Sinton", "Texas", "TX"],
"78389": ["Skidmore", "Texas", "TX"],
"78390": ["Taft", "Texas", "TX"],
"78391": ["Tynan", "Texas", "TX"],
"78393": ["Woodsboro", "Texas", "TX"],
"78401": ["Corpus Christi", "Texas", "TX"],
"78402": ["Corpus Christi", "Texas", "TX"],
"78404": ["Corpus Christi", "Texas", "TX"],
"78405": ["Corpus Christi", "Texas", "TX"],
"78406": ["Corpus Christi", "Texas", "TX"],
"78407": ["Corpus Christi", "Texas", "TX"],
"78408": ["Corpus Christi", "Texas", "TX"],
"78409": ["Corpus Christi", "Texas", "TX"],
"78410": ["Corpus Christi", "Texas", "TX"],
"78411": ["Corpus Christi", "Texas", "TX"],
"78412": ["Corpus Christi", "Texas", "TX"],
"78413": ["Corpus Christi", "Texas", "TX"],
"78414": ["Corpus Christi", "Texas", "TX"],
"78415": ["Corpus Christi", "Texas", "TX"],
"78416": ["Corpus Christi", "Texas", "TX"],
"78417": ["Corpus Christi", "Texas", "TX"],
"78418": ["Corpus Christi", "Texas", "TX"],
"78419": ["Corpus Christi", "Texas", "TX"],
"78501": ["Mcallen", "Texas", "TX"],
"78503": ["Mcallen", "Texas", "TX"],
"78504": ["Mcallen", "Texas", "TX"],
"78516": ["Alamo", "Texas", "TX"],
"78520": ["Brownsville", "Texas", "TX"],
"78521": ["Brownsville", "Texas", "TX"],
"78526": ["Brownsville", "Texas", "TX"],
"78535": ["Combes", "Texas", "TX"],
"78536": ["Delmita", "Texas", "TX"],
"78537": ["Donna", "Texas", "TX"],
"78538": ["Edcouch", "Texas", "TX"],
"78539": ["Edinburg", "Texas", "TX"],
"78540": ["Edinburg", "Texas", "TX"],
"78541": ["Edinburg", "Texas", "TX"],
"78542": ["Edinburg", "Texas", "TX"],
"78543": ["Elsa", "Texas", "TX"],
"78545": ["Falcon Heights", "Texas", "TX"],
"78547": ["Garciasville", "Texas", "TX"],
"78548": ["Grulla", "Texas", "TX"],
"78549": ["Hargill", "Texas", "TX"],
"78550": ["Harlingen", "Texas", "TX"],
"78552": ["Harlingen", "Texas", "TX"],
"78557": ["Hidalgo", "Texas", "TX"],
"78558": ["La Blanca", "Texas", "TX"],
"78559": ["La Feria", "Texas", "TX"],
"78560": ["La Joya", "Texas", "TX"],
"78561": ["Lasara", "Texas", "TX"],
"78562": ["La Villa", "Texas", "TX"],
"78563": ["Linn", "Texas", "TX"],
"78564": ["Lopeno", "Texas", "TX"],
"78565": ["Los Ebanos", "Texas", "TX"],
"78566": ["Los Fresnos", "Texas", "TX"],
"78567": ["Los Indios", "Texas", "TX"],
"78569": ["Lyford", "Texas", "TX"],
"78570": ["Mercedes", "Texas", "TX"],
"78572": ["Mission", "Texas", "TX"],
"78573": ["Mission", "Texas", "TX"],
"78574": ["Mission", "Texas", "TX"],
"78575": ["Olmito", "Texas", "TX"],
"78576": ["Penitas", "Texas", "TX"],
"78577": ["Pharr", "Texas", "TX"],
"78578": ["Port Isabel", "Texas", "TX"],
"78579": ["Progreso", "Texas", "TX"],
"78580": ["Raymondville", "Texas", "TX"],
"78582": ["Rio Grande City", "Texas", "TX"],
"78583": ["Rio Hondo", "Texas", "TX"],
"78584": ["Roma", "Texas", "TX"],
"78585": ["Salineno", "Texas", "TX"],
"78586": ["San Benito", "Texas", "TX"],
"78588": ["San Isidro", "Texas", "TX"],
"78589": ["San Juan", "Texas", "TX"],
"78590": ["San Perlita", "Texas", "TX"],
"78591": ["Santa Elena", "Texas", "TX"],
"78592": ["Santa Maria", "Texas", "TX"],
"78593": ["Santa Rosa", "Texas", "TX"],
"78594": ["Sebastian", "Texas", "TX"],
"78595": ["Sullivan City", "Texas", "TX"],
"78596": ["Weslaco", "Texas", "TX"],
"78597": ["South Padre Island", "Texas", "TX"],
"78598": ["Port Mansfield", "Texas", "TX"],
"78599": ["Weslaco", "Texas", "TX"],
"78602": ["Bastrop", "Texas", "TX"],
"78605": ["Bertram", "Texas", "TX"],
"78606": ["Blanco", "Texas", "TX"],
"78607": ["Bluffton", "Texas", "TX"],
"78608": ["Briggs", "Texas", "TX"],
"78609": ["Buchanan Dam", "Texas", "TX"],
"78610": ["Buda", "Texas", "TX"],
"78611": ["Burnet", "Texas", "TX"],
"78612": ["Cedar Creek", "Texas", "TX"],
"78613": ["Cedar Park", "Texas", "TX"],
"78614": ["Cost", "Texas", "TX"],
"78615": ["Coupland", "Texas", "TX"],
"78616": ["Dale", "Texas", "TX"],
"78617": ["Del Valle", "Texas", "TX"],
"78618": ["Doss", "Texas", "TX"],
"78619": ["Driftwood", "Texas", "TX"],
"78620": ["Dripping Springs", "Texas", "TX"],
"78621": ["Elgin", "Texas", "TX"],
"78622": ["Fentress", "Texas", "TX"],
"78623": ["Fischer", "Texas", "TX"],
"78624": ["Fredericksburg", "Texas", "TX"],
"78626": ["Georgetown", "Texas", "TX"],
"78628": ["Georgetown", "Texas", "TX"],
"78629": ["Gonzales", "Texas", "TX"],
"78631": ["Harper", "Texas", "TX"],
"78632": ["Harwood", "Texas", "TX"],
"78633": ["Georgetown", "Texas", "TX"],
"78634": ["Hutto", "Texas", "TX"],
"78635": ["Hye", "Texas", "TX"],
"78636": ["Johnson City", "Texas", "TX"],
"78638": ["Kingsbury", "Texas", "TX"],
"78639": ["Kingsland", "Texas", "TX"],
"78640": ["Kyle", "Texas", "TX"],
"78641": ["Leander", "Texas", "TX"],
"78642": ["Liberty Hill", "Texas", "TX"],
"78643": ["Llano", "Texas", "TX"],
"78644": ["Lockhart", "Texas", "TX"],
"78645": ["Leander", "Texas", "TX"],
"78648": ["Luling", "Texas", "TX"],
"78650": ["McDade", "Texas", "TX"],
"78652": ["Manchaca", "Texas", "TX"],
"78653": ["Manor", "Texas", "TX"],
"78654": ["Marble Falls", "Texas", "TX"],
"78655": ["Martindale", "Texas", "TX"],
"78656": ["Maxwell", "Texas", "TX"],
"78657": ["Horseshoe Bay", "Texas", "TX"],
"78658": ["Ottine", "Texas", "TX"],
"78659": ["Paige", "Texas", "TX"],
"78660": ["Pflugerville", "Texas", "TX"],
"78661": ["Prairie Lea", "Texas", "TX"],
"78662": ["Red Rock", "Texas", "TX"],
"78663": ["Round Mountain", "Texas", "TX"],
"78664": ["Round Rock", "Texas", "TX"],
"78665": ["Round Rock", "Texas", "TX"],
"78666": ["San Marcos", "Texas", "TX"],
"78669": ["Spicewood", "Texas", "TX"],
"78670": ["Staples", "Texas", "TX"],
"78671": ["Stonewall", "Texas", "TX"],
"78672": ["Tow", "Texas", "TX"],
"78674": ["Weir", "Texas", "TX"],
"78675": ["Willow City", "Texas", "TX"],
"78676": ["Wimberley", "Texas", "TX"],
"78677": ["Wrightsboro", "Texas", "TX"],
"78681": ["Round Rock", "Texas", "TX"],
"78701": ["Austin", "Texas", "TX"],
"78702": ["Austin", "Texas", "TX"],
"78703": ["Austin", "Texas", "TX"],
"78704": ["Austin", "Texas", "TX"],
"78705": ["Austin", "Texas", "TX"],
"78712": ["Austin", "Texas", "TX"],
"78717": ["Austin", "Texas", "TX"],
"78719": ["Austin", "Texas", "TX"],
"78721": ["Austin", "Texas", "TX"],
"78722": ["Austin", "Texas", "TX"],
"78723": ["Austin", "Texas", "TX"],
"78724": ["Austin", "Texas", "TX"],
"78725": ["Austin", "Texas", "TX"],
"78726": ["Austin", "Texas", "TX"],
"78727": ["Austin", "Texas", "TX"],
"78728": ["Austin", "Texas", "TX"],
"78729": ["Austin", "Texas", "TX"],
"78730": ["Austin", "Texas", "TX"],
"78731": ["Austin", "Texas", "TX"],
"78732": ["Austin", "Texas", "TX"],
"78733": ["Austin", "Texas", "TX"],
"78734": ["Austin", "Texas", "TX"],
"78735": ["Austin", "Texas", "TX"],
"78736": ["Austin", "Texas", "TX"],
"78737": ["Austin", "Texas", "TX"],
"78738": ["Austin", "Texas", "TX"],
"78739": ["Austin", "Texas", "TX"],
"78741": ["Austin", "Texas", "TX"],
"78742": ["Austin", "Texas", "TX"],
"78744": ["Austin", "Texas", "TX"],
"78745": ["Austin", "Texas", "TX"],
"78746": ["Austin", "Texas", "TX"],
"78747": ["Austin", "Texas", "TX"],
"78748": ["Austin", "Texas", "TX"],
"78749": ["Austin", "Texas", "TX"],
"78750": ["Austin", "Texas", "TX"],
"78751": ["Austin", "Texas", "TX"],
"78752": ["Austin", "Texas", "TX"],
"78753": ["Austin", "Texas", "TX"],
"78754": ["Austin", "Texas", "TX"],
"78756": ["Austin", "Texas", "TX"],
"78757": ["Austin", "Texas", "TX"],
"78758": ["Austin", "Texas", "TX"],
"78759": ["Austin", "Texas", "TX"],
"78801": ["Uvalde", "Texas", "TX"],
"78802": ["Uvalde", "Texas", "TX"],
"78827": ["Asherton", "Texas", "TX"],
"78828": ["Barksdale", "Texas", "TX"],
"78829": ["Batesville", "Texas", "TX"],
"78830": ["Big Wells", "Texas", "TX"],
"78832": ["Brackettville", "Texas", "TX"],
"78833": ["Camp Wood", "Texas", "TX"],
"78834": ["Carrizo Springs", "Texas", "TX"],
"78836": ["Catarina", "Texas", "TX"],
"78837": ["Comstock", "Texas", "TX"],
"78838": ["Concan", "Texas", "TX"],
"78839": ["Crystal City", "Texas", "TX"],
"78840": ["Del Rio", "Texas", "TX"],
"78841": ["Del Rio", "Texas", "TX"],
"78842": ["Del Rio", "Texas", "TX"],
"78843": ["Laughlin Afb", "Texas", "TX"],
"78850": ["D Hanis", "Texas", "TX"],
"78851": ["Dryden", "Texas", "TX"],
"78852": ["Eagle Pass", "Texas", "TX"],
"78860": ["El Indio", "Texas", "TX"],
"78861": ["Hondo", "Texas", "TX"],
"78870": ["Knippa", "Texas", "TX"],
"78871": ["Langtry", "Texas", "TX"],
"78872": ["La Pryor", "Texas", "TX"],
"78873": ["Leakey", "Texas", "TX"],
"78877": ["Quemado", "Texas", "TX"],
"78879": ["Rio Frio", "Texas", "TX"],
"78880": ["Rocksprings", "Texas", "TX"],
"78881": ["Sabinal", "Texas", "TX"],
"78883": ["Tarpley", "Texas", "TX"],
"78884": ["Utopia", "Texas", "TX"],
"78885": ["Vanderpool", "Texas", "TX"],
"78886": ["Yancey", "Texas", "TX"],
"78931": ["Bleiblerville", "Texas", "TX"],
"78932": ["Carmine", "Texas", "TX"],
"78933": ["Cat Spring", "Texas", "TX"],
"78934": ["Columbus", "Texas", "TX"],
"78935": ["Alleyton", "Texas", "TX"],
"78938": ["Ellinger", "Texas", "TX"],
"78940": ["Fayetteville", "Texas", "TX"],
"78941": ["Flatonia", "Texas", "TX"],
"78942": ["Giddings", "Texas", "TX"],
"78943": ["Glidden", "Texas", "TX"],
"78944": ["Industry", "Texas", "TX"],
"78945": ["La Grange", "Texas", "TX"],
"78946": ["Ledbetter", "Texas", "TX"],
"78947": ["Lexington", "Texas", "TX"],
"78948": ["Lincoln", "Texas", "TX"],
"78949": ["Muldoon", "Texas", "TX"],
"78950": ["New Ulm", "Texas", "TX"],
"78951": ["Oakland", "Texas", "TX"],
"78953": ["Rosanky", "Texas", "TX"],
"78954": ["Round Top", "Texas", "TX"],
"78956": ["Schulenburg", "Texas", "TX"],
"78957": ["Smithville", "Texas", "TX"],
"78959": ["Waelder", "Texas", "TX"],
"78962": ["Weimar", "Texas", "TX"],
"78963": ["West Point", "Texas", "TX"],
"79001": ["Adrian", "Texas", "TX"],
"79002": ["Alanreed", "Texas", "TX"],
"79003": ["Allison", "Texas", "TX"],
"79005": ["Booker", "Texas", "TX"],
"79007": ["Borger", "Texas", "TX"],
"79008": ["Borger", "Texas", "TX"],
"79009": ["Bovina", "Texas", "TX"],
"79010": ["Boys Ranch", "Texas", "TX"],
"79011": ["Briscoe", "Texas", "TX"],
"79012": ["Bushland", "Texas", "TX"],
"79013": ["Cactus", "Texas", "TX"],
"79014": ["Canadian", "Texas", "TX"],
"79015": ["Canyon", "Texas", "TX"],
"79016": ["Canyon", "Texas", "TX"],
"79018": ["Channing", "Texas", "TX"],
"79019": ["Claude", "Texas", "TX"],
"79021": ["Cotton Center", "Texas", "TX"],
"79022": ["Dalhart", "Texas", "TX"],
"79024": ["Darrouzett", "Texas", "TX"],
"79025": ["Dawn", "Texas", "TX"],
"79027": ["Dimmitt", "Texas", "TX"],
"79029": ["Dumas", "Texas", "TX"],
"79031": ["Earth", "Texas", "TX"],
"79032": ["Edmonson", "Texas", "TX"],
"79033": ["Farnsworth", "Texas", "TX"],
"79034": ["Follett", "Texas", "TX"],
"79035": ["Friona", "Texas", "TX"],
"79036": ["Fritch", "Texas", "TX"],
"79039": ["Groom", "Texas", "TX"],
"79040": ["Gruver", "Texas", "TX"],
"79041": ["Hale Center", "Texas", "TX"],
"79042": ["Happy", "Texas", "TX"],
"79043": ["Hart", "Texas", "TX"],
"79044": ["Hartley", "Texas", "TX"],
"79045": ["Hereford", "Texas", "TX"],
"79046": ["Higgins", "Texas", "TX"],
"79051": ["Kerrick", "Texas", "TX"],
"79052": ["Kress", "Texas", "TX"],
"79053": ["Lazbuddie", "Texas", "TX"],
"79054": ["Lefors", "Texas", "TX"],
"79056": ["Lipscomb", "Texas", "TX"],
"79057": ["Mclean", "Texas", "TX"],
"79058": ["Masterson", "Texas", "TX"],
"79059": ["Miami", "Texas", "TX"],
"79061": ["Mobeetie", "Texas", "TX"],
"79062": ["Morse", "Texas", "TX"],
"79063": ["Nazareth", "Texas", "TX"],
"79064": ["Olton", "Texas", "TX"],
"79065": ["Pampa", "Texas", "TX"],
"79066": ["Pampa", "Texas", "TX"],
"79068": ["Panhandle", "Texas", "TX"],
"79070": ["Perryton", "Texas", "TX"],
"79072": ["Plainview", "Texas", "TX"],
"79073": ["Plainview", "Texas", "TX"],
"79078": ["Sanford", "Texas", "TX"],
"79079": ["Shamrock", "Texas", "TX"],
"79080": ["Skellytown", "Texas", "TX"],
"79081": ["Spearman", "Texas", "TX"],
"79082": ["Springlake", "Texas", "TX"],
"79083": ["Stinnett", "Texas", "TX"],
"79084": ["Stratford", "Texas", "TX"],
"79085": ["Summerfield", "Texas", "TX"],
"79086": ["Sunray", "Texas", "TX"],
"79087": ["Texline", "Texas", "TX"],
"79088": ["Tulia", "Texas", "TX"],
"79091": ["Umbarger", "Texas", "TX"],
"79092": ["Vega", "Texas", "TX"],
"79093": ["Waka", "Texas", "TX"],
"79094": ["Wayside", "Texas", "TX"],
"79095": ["Wellington", "Texas", "TX"],
"79096": ["Wheeler", "Texas", "TX"],
"79097": ["White Deer", "Texas", "TX"],
"79098": ["Wildorado", "Texas", "TX"],
"79101": ["Amarillo", "Texas", "TX"],
"79102": ["Amarillo", "Texas", "TX"],
"79103": ["Amarillo", "Texas", "TX"],
"79104": ["Amarillo", "Texas", "TX"],
"79106": ["Amarillo", "Texas", "TX"],
"79107": ["Amarillo", "Texas", "TX"],
"79108": ["Amarillo", "Texas", "TX"],
"79109": ["Amarillo", "Texas", "TX"],
"79110": ["Amarillo", "Texas", "TX"],
"79111": ["Amarillo", "Texas", "TX"],
"79118": ["Amarillo", "Texas", "TX"],
"79119": ["Amarillo", "Texas", "TX"],
"79121": ["Amarillo", "Texas", "TX"],
"79124": ["Amarillo", "Texas", "TX"],
"79201": ["Childress", "Texas", "TX"],
"79220": ["Afton", "Texas", "TX"],
"79223": ["Cee Vee", "Texas", "TX"],
"79225": ["Chillicothe", "Texas", "TX"],
"79226": ["Clarendon", "Texas", "TX"],
"79227": ["Crowell", "Texas", "TX"],
"79229": ["Dickens", "Texas", "TX"],
"79230": ["Dodson", "Texas", "TX"],
"79231": ["Dougherty", "Texas", "TX"],
"79233": ["Estelline", "Texas", "TX"],
"79234": ["Flomot", "Texas", "TX"],
"79235": ["Floydada", "Texas", "TX"],
"79236": ["Guthrie", "Texas", "TX"],
"79237": ["Hedley", "Texas", "TX"],
"79239": ["Lakeview", "Texas", "TX"],
"79240": ["Lelia Lake", "Texas", "TX"],
"79241": ["Lockney", "Texas", "TX"],
"79243": ["Mcadoo", "Texas", "TX"],
"79244": ["Matador", "Texas", "TX"],
"79245": ["Memphis", "Texas", "TX"],
"79247": ["Odell", "Texas", "TX"],
"79248": ["Paducah", "Texas", "TX"],
"79250": ["Petersburg", "Texas", "TX"],
"79251": ["Quail", "Texas", "TX"],
"79252": ["Quanah", "Texas", "TX"],
"79255": ["Quitaque", "Texas", "TX"],
"79256": ["Roaring Springs", "Texas", "TX"],
"79257": ["Silverton", "Texas", "TX"],
"79258": ["South Plains", "Texas", "TX"],
"79259": ["Tell", "Texas", "TX"],
"79261": ["Turkey", "Texas", "TX"],
"79311": ["Abernathy", "Texas", "TX"],
"79312": ["Amherst", "Texas", "TX"],
"79313": ["Anton", "Texas", "TX"],
"79314": ["Bledsoe", "Texas", "TX"],
"79316": ["Brownfield", "Texas", "TX"],
"79322": ["Crosbyton", "Texas", "TX"],
"79323": ["Denver City", "Texas", "TX"],
"79324": ["Enochs", "Texas", "TX"],
"79325": ["Farwell", "Texas", "TX"],
"79326": ["Fieldton", "Texas", "TX"],
"79329": ["Idalou", "Texas", "TX"],
"79330": ["Justiceburg", "Texas", "TX"],
"79331": ["Lamesa", "Texas", "TX"],
"79336": ["Levelland", "Texas", "TX"],
"79339": ["Littlefield", "Texas", "TX"],
"79342": ["Loop", "Texas", "TX"],
"79343": ["Lorenzo", "Texas", "TX"],
"79344": ["Maple", "Texas", "TX"],
"79345": ["Meadow", "Texas", "TX"],
"79346": ["Morton", "Texas", "TX"],
"79347": ["Muleshoe", "Texas", "TX"],
"79350": ["New Deal", "Texas", "TX"],
"79351": ["Odonnell", "Texas", "TX"],
"79353": ["Pep", "Texas", "TX"],
"79355": ["Plains", "Texas", "TX"],
"79356": ["Post", "Texas", "TX"],
"79357": ["Ralls", "Texas", "TX"],
"79358": ["Ropesville", "Texas", "TX"],
"79359": ["Seagraves", "Texas", "TX"],
"79360": ["Seminole", "Texas", "TX"],
"79363": ["Shallowater", "Texas", "TX"],
"79364": ["Slaton", "Texas", "TX"],
"79366": ["Ransom Canyon", "Texas", "TX"],
"79367": ["Smyer", "Texas", "TX"],
"79369": ["Spade", "Texas", "TX"],
"79370": ["Spur", "Texas", "TX"],
"79371": ["Sudan", "Texas", "TX"],
"79372": ["Sundown", "Texas", "TX"],
"79373": ["Tahoka", "Texas", "TX"],
"79376": ["Tokio", "Texas", "TX"],
"79377": ["Welch", "Texas", "TX"],
"79378": ["Wellman", "Texas", "TX"],
"79379": ["Whiteface", "Texas", "TX"],
"79380": ["Whitharral", "Texas", "TX"],
"79381": ["Wilson", "Texas", "TX"],
"79382": ["Wolfforth", "Texas", "TX"],
"79383": ["New Home", "Texas", "TX"],
"79401": ["Lubbock", "Texas", "TX"],
"79402": ["Lubbock", "Texas", "TX"],
"79403": ["Lubbock", "Texas", "TX"],
"79404": ["Lubbock", "Texas", "TX"],
"79406": ["Lubbock", "Texas", "TX"],
"79407": ["Lubbock", "Texas", "TX"],
"79410": ["Lubbock", "Texas", "TX"],
"79411": ["Lubbock", "Texas", "TX"],
"79412": ["Lubbock", "Texas", "TX"],
"79413": ["Lubbock", "Texas", "TX"],
"79414": ["Lubbock", "Texas", "TX"],
"79415": ["Lubbock", "Texas", "TX"],
"79416": ["Lubbock", "Texas", "TX"],
"79423": ["Lubbock", "Texas", "TX"],
"79424": ["Lubbock", "Texas", "TX"],
"79501": ["Anson", "Texas", "TX"],
"79502": ["Aspermont", "Texas", "TX"],
"79503": ["Avoca", "Texas", "TX"],
"79504": ["Baird", "Texas", "TX"],
"79505": ["Benjamin", "Texas", "TX"],
"79506": ["Blackwell", "Texas", "TX"],
"79508": ["Buffalo Gap", "Texas", "TX"],
"79510": ["Clyde", "Texas", "TX"],
"79511": ["Coahoma", "Texas", "TX"],
"79512": ["Colorado City", "Texas", "TX"],
"79517": ["Fluvanna", "Texas", "TX"],
"79518": ["Girard", "Texas", "TX"],
"79519": ["Goldsboro", "Texas", "TX"],
"79520": ["Hamlin", "Texas", "TX"],
"79521": ["Haskell", "Texas", "TX"],
"79525": ["Hawley", "Texas", "TX"],
"79526": ["Hermleigh", "Texas", "TX"],
"79527": ["Ira", "Texas", "TX"],
"79528": ["Jayton", "Texas", "TX"],
"79529": ["Knox City", "Texas", "TX"],
"79530": ["Lawn", "Texas", "TX"],
"79532": ["Loraine", "Texas", "TX"],
"79533": ["Lueders", "Texas", "TX"],
"79534": ["McCaulley", "Texas", "TX"],
"79535": ["Maryneal", "Texas", "TX"],
"79536": ["Merkel", "Texas", "TX"],
"79537": ["Nolan", "Texas", "TX"],
"79538": ["Novice", "Texas", "TX"],
"79539": ["O'Brien", "Texas", "TX"],
"79540": ["Old Glory", "Texas", "TX"],
"79541": ["Ovalo", "Texas", "TX"],
"79543": ["Roby", "Texas", "TX"],
"79544": ["Rochester", "Texas", "TX"],
"79545": ["Roscoe", "Texas", "TX"],
"79546": ["Rotan", "Texas", "TX"],
"79547": ["Rule", "Texas", "TX"],
"79548": ["Rule", "Texas", "TX"],
"79549": ["Snyder", "Texas", "TX"],
"79553": ["Stamford", "Texas", "TX"],
"79556": ["Sweetwater", "Texas", "TX"],
"79560": ["Sylvester", "Texas", "TX"],
"79561": ["Trent", "Texas", "TX"],
"79562": ["Tuscola", "Texas", "TX"],
"79563": ["Tye", "Texas", "TX"],
"79565": ["Westbrook", "Texas", "TX"],
"79566": ["Wingate", "Texas", "TX"],
"79567": ["Winters", "Texas", "TX"],
"79601": ["Abilene", "Texas", "TX"],
"79602": ["Abilene", "Texas", "TX"],
"79603": ["Abilene", "Texas", "TX"],
"79605": ["Abilene", "Texas", "TX"],
"79606": ["Abilene", "Texas", "TX"],
"79607": ["Dyess Afb", "Texas", "TX"],
"79699": ["Abilene", "Texas", "TX"],
"79701": ["Midland", "Texas", "TX"],
"79703": ["Midland", "Texas", "TX"],
"79705": ["Midland", "Texas", "TX"],
"79706": ["Midland", "Texas", "TX"],
"79707": ["Midland", "Texas", "TX"],
"79713": ["Ackerly", "Texas", "TX"],
"79714": ["Andrews", "Texas", "TX"],
"79718": ["Balmorhea", "Texas", "TX"],
"79719": ["Barstow", "Texas", "TX"],
"79720": ["Big Spring", "Texas", "TX"],
"79721": ["Big Spring", "Texas", "TX"],
"79730": ["Coyanosa", "Texas", "TX"],
"79731": ["Crane", "Texas", "TX"],
"79733": ["Forsan", "Texas", "TX"],
"79734": ["Fort Davis", "Texas", "TX"],
"79735": ["Fort Stockton", "Texas", "TX"],
"79738": ["Gail", "Texas", "TX"],
"79739": ["Garden City", "Texas", "TX"],
"79741": ["Goldsmith", "Texas", "TX"],
"79742": ["Grandfalls", "Texas", "TX"],
"79743": ["Imperial", "Texas", "TX"],
"79744": ["Iraan", "Texas", "TX"],
"79745": ["Kermit", "Texas", "TX"],
"79748": ["Knott", "Texas", "TX"],
"79749": ["Lenorah", "Texas", "TX"],
"79752": ["McCamey", "Texas", "TX"],
"79754": ["Mentone", "Texas", "TX"],
"79755": ["Midkiff", "Texas", "TX"],
"79756": ["Monahans", "Texas", "TX"],
"79758": ["Gardendale", "Texas", "TX"],
"79759": ["Notrees", "Texas", "TX"],
"79761": ["Odessa", "Texas", "TX"],
"79762": ["Odessa", "Texas", "TX"],
"79763": ["Odessa", "Texas", "TX"],
"79764": ["Odessa", "Texas", "TX"],
"79765": ["Odessa", "Texas", "TX"],
"79766": ["Odessa", "Texas", "TX"],
"79769": ["Odessa", "Texas", "TX"],
"79770": ["Orla", "Texas", "TX"],
"79772": ["Pecos", "Texas", "TX"],
"79776": ["Penwell", "Texas", "TX"],
"79777": ["Pyote", "Texas", "TX"],
"79778": ["Rankin", "Texas", "TX"],
"79780": ["Saragosa", "Texas", "TX"],
"79781": ["Sheffield", "Texas", "TX"],
"79782": ["Stanton", "Texas", "TX"],
"79783": ["Tarzan", "Texas", "TX"],
"79785": ["Toyah", "Texas", "TX"],
"79788": ["Wickett", "Texas", "TX"],
"79789": ["Wink", "Texas", "TX"],
"79821": ["Anthony", "Texas", "TX"],
"79830": ["Alpine", "Texas", "TX"],
"79832": ["Alpine", "Texas", "TX"],
"79834": ["Big Bend National Park", "Texas", "TX"],
"79835": ["Canutillo", "Texas", "TX"],
"79836": ["Clint", "Texas", "TX"],
"79837": ["Dell City", "Texas", "TX"],
"79838": ["Fabens", "Texas", "TX"],
"79839": ["Fort Hancock", "Texas", "TX"],
"79842": ["Marathon", "Texas", "TX"],
"79843": ["Marfa", "Texas", "TX"],
"79845": ["Presidio", "Texas", "TX"],
"79846": ["Redford", "Texas", "TX"],
"79847": ["Salt Flat", "Texas", "TX"],
"79848": ["Sanderson", "Texas", "TX"],
"79849": ["San Elizario", "Texas", "TX"],
"79851": ["Sierra Blanca", "Texas", "TX"],
"79852": ["Terlingua", "Texas", "TX"],
"79853": ["Tornillo", "Texas", "TX"],
"79854": ["Valentine", "Texas", "TX"],
"79855": ["Van Horn", "Texas", "TX"],
"79901": ["El Paso", "Texas", "TX"],
"79902": ["El Paso", "Texas", "TX"],
"79903": ["El Paso", "Texas", "TX"],
"79904": ["El Paso", "Texas", "TX"],
"79905": ["El Paso", "Texas", "TX"],
"79906": ["El Paso", "Texas", "TX"],
"79907": ["El Paso", "Texas", "TX"],
"79908": ["El Paso", "Texas", "TX"],
"79911": ["El Paso", "Texas", "TX"],
"79912": ["El Paso", "Texas", "TX"],
"79915": ["El Paso", "Texas", "TX"],
"79916": ["Fort Bliss", "Texas", "TX"],
"79918": ["Fort Bliss", "Texas", "TX"],
"79920": ["El Paso", "Texas", "TX"],
"79922": ["El Paso", "Texas", "TX"],
"79924": ["El Paso", "Texas", "TX"],
"79925": ["El Paso", "Texas", "TX"],
"79927": ["El Paso", "Texas", "TX"],
"79928": ["El Paso", "Texas", "TX"],
"79930": ["El Paso", "Texas", "TX"],
"79932": ["El Paso", "Texas", "TX"],
"79934": ["El Paso", "Texas", "TX"],
"79935": ["El Paso", "Texas", "TX"],
"79936": ["El Paso", "Texas", "TX"],
"79938": ["El Paso", "Texas", "TX"],
"80002": ["Arvada", "Colorado", "CO"],
"80003": ["Arvada", "Colorado", "CO"],
"80004": ["Arvada", "Colorado", "CO"],
"80005": ["Arvada", "Colorado", "CO"],
"80007": ["Arvada", "Colorado", "CO"],
"80010": ["Aurora", "Colorado", "CO"],
"80011": ["Aurora", "Colorado", "CO"],
"80012": ["Aurora", "Colorado", "CO"],
"80013": ["Aurora", "Colorado", "CO"],
"80014": ["Aurora", "Colorado", "CO"],
"80015": ["Aurora", "Colorado", "CO"],
"80016": ["Aurora", "Colorado", "CO"],
"80017": ["Aurora", "Colorado", "CO"],
"80018": ["Aurora", "Colorado", "CO"],
"80019": ["Aurora", "Colorado", "CO"],
"80020": ["Broomfield", "Colorado", "CO"],
"80021": ["Broomfield", "Colorado", "CO"],
"80022": ["Commerce City", "Colorado", "CO"],
"80023": ["Broomfield", "Colorado", "CO"],
"80024": ["Dupont", "Colorado", "CO"],
"80025": ["Eldorado Springs", "Colorado", "CO"],
"80026": ["Lafayette", "Colorado", "CO"],
"80027": ["Louisville", "Colorado", "CO"],
"80030": ["Westminster", "Colorado", "CO"],
"80031": ["Westminster", "Colorado", "CO"],
"80033": ["Wheat Ridge", "Colorado", "CO"],
"80045": ["Aurora", "Colorado", "CO"],
"80101": ["Agate", "Colorado", "CO"],
"80102": ["Bennett", "Colorado", "CO"],
"80103": ["Byers", "Colorado", "CO"],
"80104": ["Castle Rock", "Colorado", "CO"],
"80105": ["Deer Trail", "Colorado", "CO"],
"80106": ["Elbert", "Colorado", "CO"],
"80107": ["Elizabeth", "Colorado", "CO"],
"80108": ["Castle Rock", "Colorado", "CO"],
"80109": ["Castle Rock", "Colorado", "CO"],
"80110": ["Englewood", "Colorado", "CO"],
"80111": ["Englewood", "Colorado", "CO"],
"80112": ["Englewood", "Colorado", "CO"],
"80113": ["Englewood", "Colorado", "CO"],
"80116": ["Franktown", "Colorado", "CO"],
"80117": ["Kiowa", "Colorado", "CO"],
"80118": ["Larkspur", "Colorado", "CO"],
"80120": ["Littleton", "Colorado", "CO"],
"80121": ["Littleton", "Colorado", "CO"],
"80122": ["Littleton", "Colorado", "CO"],
"80123": ["Littleton", "Colorado", "CO"],
"80124": ["Lone Tree", "Colorado", "CO"],
"80125": ["Littleton", "Colorado", "CO"],
"80126": ["Littleton", "Colorado", "CO"],
"80127": ["Littleton", "Colorado", "CO"],
"80128": ["Littleton", "Colorado", "CO"],
"80129": ["Littleton", "Colorado", "CO"],
"80130": ["Littleton", "Colorado", "CO"],
"80131": ["Louviers", "Colorado", "CO"],
"80132": ["Monument", "Colorado", "CO"],
"80133": ["Palmer Lake", "Colorado", "CO"],
"80134": ["Parker", "Colorado", "CO"],
"80135": ["Sedalia", "Colorado", "CO"],
"80136": ["Strasburg", "Colorado", "CO"],
"80137": ["Watkins", "Colorado", "CO"],
"80138": ["Parker", "Colorado", "CO"],
"80202": ["Denver", "Colorado", "CO"],
"80203": ["Denver", "Colorado", "CO"],
"80204": ["Denver", "Colorado", "CO"],
"80205": ["Denver", "Colorado", "CO"],
"80206": ["Denver", "Colorado", "CO"],
"80207": ["Denver", "Colorado", "CO"],
"80209": ["Denver", "Colorado", "CO"],
"80210": ["Denver", "Colorado", "CO"],
"80211": ["Denver", "Colorado", "CO"],
"80212": ["Denver", "Colorado", "CO"],
"80214": ["Denver", "Colorado", "CO"],
"80215": ["Denver", "Colorado", "CO"],
"80216": ["Denver", "Colorado", "CO"],
"80218": ["Denver", "Colorado", "CO"],
"80219": ["Denver", "Colorado", "CO"],
"80220": ["Denver", "Colorado", "CO"],
"80221": ["Denver", "Colorado", "CO"],
"80222": ["Denver", "Colorado", "CO"],
"80223": ["Denver", "Colorado", "CO"],
"80224": ["Denver", "Colorado", "CO"],
"80226": ["Denver", "Colorado", "CO"],
"80227": ["Denver", "Colorado", "CO"],
"80228": ["Denver", "Colorado", "CO"],
"80229": ["Denver", "Colorado", "CO"],
"80230": ["Denver", "Colorado", "CO"],
"80231": ["Denver", "Colorado", "CO"],
"80232": ["Denver", "Colorado", "CO"],
"80233": ["Denver", "Colorado", "CO"],
"80234": ["Denver", "Colorado", "CO"],
"80235": ["Denver", "Colorado", "CO"],
"80236": ["Denver", "Colorado", "CO"],
"80237": ["Denver", "Colorado", "CO"],
"80238": ["Denver", "Colorado", "CO"],
"80239": ["Denver", "Colorado", "CO"],
"80241": ["Thornton", "Colorado", "CO"],
"80246": ["Denver", "Colorado", "CO"],
"80247": ["Denver", "Colorado", "CO"],
"80249": ["Denver", "Colorado", "CO"],
"80260": ["Denver", "Colorado", "CO"],
"80264": ["Denver", "Colorado", "CO"],
"80266": ["Denver", "Colorado", "CO"],
"80290": ["Denver", "Colorado", "CO"],
"80293": ["Denver", "Colorado", "CO"],
"80294": ["Denver", "Colorado", "CO"],
"80301": ["Boulder", "Colorado", "CO"],
"80302": ["Boulder", "Colorado", "CO"],
"80303": ["Boulder", "Colorado", "CO"],
"80304": ["Boulder", "Colorado", "CO"],
"80305": ["Boulder", "Colorado", "CO"],
"80310": ["Boulder", "Colorado", "CO"],
"80401": ["Golden", "Colorado", "CO"],
"80403": ["Golden", "Colorado", "CO"],
"80419": ["Golden", "Colorado", "CO"],
"80420": ["Alma", "Colorado", "CO"],
"80421": ["Bailey", "Colorado", "CO"],
"80422": ["Black Hawk", "Colorado", "CO"],
"80423": ["Bond", "Colorado", "CO"],
"80424": ["Breckenridge", "Colorado", "CO"],
"80425": ["Buffalo Creek", "Colorado", "CO"],
"80426": ["Burns", "Colorado", "CO"],
"80427": ["Central City", "Colorado", "CO"],
"80428": ["Clark", "Colorado", "CO"],
"80430": ["Coalmont", "Colorado", "CO"],
"80432": ["Como", "Colorado", "CO"],
"80433": ["Conifer", "Colorado", "CO"],
"80434": ["Cowdrey", "Colorado", "CO"],
"80435": ["Dillon", "Colorado", "CO"],
"80436": ["Dumont", "Colorado", "CO"],
"80438": ["Empire", "Colorado", "CO"],
"80439": ["Evergreen", "Colorado", "CO"],
"80440": ["Fairplay", "Colorado", "CO"],
"80442": ["Fraser", "Colorado", "CO"],
"80443": ["Frisco", "Colorado", "CO"],
"80444": ["Georgetown", "Colorado", "CO"],
"80446": ["Granby", "Colorado", "CO"],
"80447": ["Grand Lake", "Colorado", "CO"],
"80448": ["Grant", "Colorado", "CO"],
"80449": ["Hartsel", "Colorado", "CO"],
"80451": ["Hot Sulphur Springs", "Colorado", "CO"],
"80452": ["Idaho Springs", "Colorado", "CO"],
"80453": ["Idledale", "Colorado", "CO"],
"80454": ["Indian Hills", "Colorado", "CO"],
"80455": ["Jamestown", "Colorado", "CO"],
"80456": ["Jefferson", "Colorado", "CO"],
"80457": ["Kittredge", "Colorado", "CO"],
"80459": ["Kremmling", "Colorado", "CO"],
"80461": ["Leadville", "Colorado", "CO"],
"80463": ["McCoy", "Colorado", "CO"],
"80465": ["Morrison", "Colorado", "CO"],
"80466": ["Nederland", "Colorado", "CO"],
"80467": ["Oak Creek", "Colorado", "CO"],
"80468": ["Parshall", "Colorado", "CO"],
"80469": ["Phippsburg", "Colorado", "CO"],
"80470": ["Pine", "Colorado", "CO"],
"80471": ["Pinecliffe", "Colorado", "CO"],
"80473": ["Rand", "Colorado", "CO"],
"80474": ["Rollinsville", "Colorado", "CO"],
"80475": ["Shawnee", "Colorado", "CO"],
"80476": ["Silver Plume", "Colorado", "CO"],
"80478": ["Tabernash", "Colorado", "CO"],
"80479": ["Toponas", "Colorado", "CO"],
"80480": ["Walden", "Colorado", "CO"],
"80481": ["Ward", "Colorado", "CO"],
"80482": ["Winter Park", "Colorado", "CO"],
"80483": ["Yampa", "Colorado", "CO"],
"80487": ["Steamboat Springs", "Colorado", "CO"],
"80498": ["Silverthorne", "Colorado", "CO"],
"80501": ["Longmont", "Colorado", "CO"],
"80503": ["Longmont", "Colorado", "CO"],
"80504": ["Longmont", "Colorado", "CO"],
"80510": ["Allenspark", "Colorado", "CO"],
"80512": ["Bellvue", "Colorado", "CO"],
"80513": ["Berthoud", "Colorado", "CO"],
"80514": ["Dacono", "Colorado", "CO"],
"80515": ["Drake", "Colorado", "CO"],
"80516": ["Erie", "Colorado", "CO"],
"80517": ["Estes Park", "Colorado", "CO"],
"80520": ["Firestone", "Colorado", "CO"],
"80521": ["Fort Collins", "Colorado", "CO"],
"80524": ["Fort Collins", "Colorado", "CO"],
"80525": ["Fort Collins", "Colorado", "CO"],
"80526": ["Fort Collins", "Colorado", "CO"],
"80528": ["Fort Collins", "Colorado", "CO"],
"80530": ["Frederick", "Colorado", "CO"],
"80532": ["Glen Haven", "Colorado", "CO"],
"80534": ["Johnstown", "Colorado", "CO"],
"80535": ["Laporte", "Colorado", "CO"],
"80536": ["Livermore", "Colorado", "CO"],
"80537": ["Loveland", "Colorado", "CO"],
"80538": ["Loveland", "Colorado", "CO"],
"80540": ["Lyons", "Colorado", "CO"],
"80542": ["Mead", "Colorado", "CO"],
"80543": ["Milliken", "Colorado", "CO"],
"80544": ["Niwot", "Colorado", "CO"],
"80545": ["Red Feather Lakes", "Colorado", "CO"],
"80546": ["Severance", "Colorado", "CO"],
"80547": ["Timnath", "Colorado", "CO"],
"80549": ["Wellington", "Colorado", "CO"],
"80550": ["Windsor", "Colorado", "CO"],
"80601": ["Brighton", "Colorado", "CO"],
"80602": ["Brighton", "Colorado", "CO"],
"80603": ["Brighton", "Colorado", "CO"],
"80610": ["Ault", "Colorado", "CO"],
"80611": ["Briggsdale", "Colorado", "CO"],
"80612": ["Carr", "Colorado", "CO"],
"80615": ["Eaton", "Colorado", "CO"],
"80620": ["Evans", "Colorado", "CO"],
"80621": ["Fort Lupton", "Colorado", "CO"],
"80622": ["Galeton", "Colorado", "CO"],
"80623": ["Gilcrest", "Colorado", "CO"],
"80624": ["Gill", "Colorado", "CO"],
"80631": ["Greeley", "Colorado", "CO"],
"80634": ["Greeley", "Colorado", "CO"],
"80640": ["Henderson", "Colorado", "CO"],
"80642": ["Hudson", "Colorado", "CO"],
"80643": ["Keenesburg", "Colorado", "CO"],
"80644": ["Kersey", "Colorado", "CO"],
"80645": ["La Salle", "Colorado", "CO"],
"80646": ["Lucerne", "Colorado", "CO"],
"80648": ["Nunn", "Colorado", "CO"],
"80649": ["Orchard", "Colorado", "CO"],
"80650": ["Pierce", "Colorado", "CO"],
"80651": ["Platteville", "Colorado", "CO"],
"80652": ["Roggen", "Colorado", "CO"],
"80653": ["Weldona", "Colorado", "CO"],
"80654": ["Wiggins", "Colorado", "CO"],
"80701": ["Fort Morgan", "Colorado", "CO"],
"80705": ["Log Lane Village", "Colorado", "CO"],
"80720": ["Akron", "Colorado", "CO"],
"80721": ["Amherst", "Colorado", "CO"],
"80722": ["Atwood", "Colorado", "CO"],
"80723": ["Brush", "Colorado", "CO"],
"80726": ["Crook", "Colorado", "CO"],
"80727": ["Eckley", "Colorado", "CO"],
"80728": ["Fleming", "Colorado", "CO"],
"80729": ["Grover", "Colorado", "CO"],
"80731": ["Haxtun", "Colorado", "CO"],
"80732": ["Hereford", "Colorado", "CO"],
"80733": ["Hillrose", "Colorado", "CO"],
"80734": ["Holyoke", "Colorado", "CO"],
"80735": ["Idalia", "Colorado", "CO"],
"80736": ["Iliff", "Colorado", "CO"],
"80737": ["Julesburg", "Colorado", "CO"],
"80740": ["Lindon", "Colorado", "CO"],
"80741": ["Merino", "Colorado", "CO"],
"80742": ["New Raymer", "Colorado", "CO"],
"80743": ["Otis", "Colorado", "CO"],
"80744": ["Ovid", "Colorado", "CO"],
"80745": ["Padroni", "Colorado", "CO"],
"80746": ["Paoli", "Colorado", "CO"],
"80747": ["Peetz", "Colorado", "CO"],
"80749": ["Sedgwick", "Colorado", "CO"],
"80750": ["Snyder", "Colorado", "CO"],
"80751": ["Sterling", "Colorado", "CO"],
"80754": ["Stoneham", "Colorado", "CO"],
"80755": ["Vernon", "Colorado", "CO"],
"80757": ["Woodrow", "Colorado", "CO"],
"80758": ["Wray", "Colorado", "CO"],
"80759": ["Yuma", "Colorado", "CO"],
"80801": ["Anton", "Colorado", "CO"],
"80802": ["Arapahoe", "Colorado", "CO"],
"80804": ["Arriba", "Colorado", "CO"],
"80805": ["Bethune", "Colorado", "CO"],
"80807": ["Burlington", "Colorado", "CO"],
"80808": ["Calhan", "Colorado", "CO"],
"80809": ["Cascade", "Colorado", "CO"],
"80810": ["Cheyenne Wells", "Colorado", "CO"],
"80812": ["Cope", "Colorado", "CO"],
"80813": ["Cripple Creek", "Colorado", "CO"],
"80814": ["Divide", "Colorado", "CO"],
"80815": ["Flagler", "Colorado", "CO"],
"80816": ["Florissant", "Colorado", "CO"],
"80817": ["Fountain", "Colorado", "CO"],
"80818": ["Genoa", "Colorado", "CO"],
"80819": ["Green Mountain Falls", "Colorado", "CO"],
"80820": ["Guffey", "Colorado", "CO"],
"80821": ["Hugo", "Colorado", "CO"],
"80822": ["Joes", "Colorado", "CO"],
"80823": ["Karval", "Colorado", "CO"],
"80824": ["Kirk", "Colorado", "CO"],
"80825": ["Kit Carson", "Colorado", "CO"],
"80826": ["Limon", "Colorado", "CO"],
"80827": ["Lake George", "Colorado", "CO"],
"80828": ["Limon", "Colorado", "CO"],
"80829": ["Manitou Springs", "Colorado", "CO"],
"80830": ["Matheson", "Colorado", "CO"],
"80831": ["Peyton", "Colorado", "CO"],
"80832": ["Ramah", "Colorado", "CO"],
"80833": ["Rush", "Colorado", "CO"],
"80834": ["Seibert", "Colorado", "CO"],
"80835": ["Simla", "Colorado", "CO"],
"80836": ["Stratton", "Colorado", "CO"],
"80840": ["Usaf Academy", "Colorado", "CO"],
"80860": ["Victor", "Colorado", "CO"],
"80861": ["Vona", "Colorado", "CO"],
"80862": ["Wild Horse", "Colorado", "CO"],
"80863": ["Woodland Park", "Colorado", "CO"],
"80864": ["Yoder", "Colorado", "CO"],
"80902": ["Colorado Springs", "Colorado", "CO"],
"80903": ["Colorado Springs", "Colorado", "CO"],
"80904": ["Colorado Springs", "Colorado", "CO"],
"80905": ["Colorado Springs", "Colorado", "CO"],
"80906": ["Colorado Springs", "Colorado", "CO"],
"80907": ["Colorado Springs", "Colorado", "CO"],
"80908": ["Colorado Springs", "Colorado", "CO"],
"80909": ["Colorado Springs", "Colorado", "CO"],
"80910": ["Colorado Springs", "Colorado", "CO"],
"80911": ["Colorado Springs", "Colorado", "CO"],
"80913": ["Colorado Springs", "Colorado", "CO"],
"80914": ["Colorado Springs", "Colorado", "CO"],
"80915": ["Colorado Springs", "Colorado", "CO"],
"80916": ["Colorado Springs", "Colorado", "CO"],
"80917": ["Colorado Springs", "Colorado", "CO"],
"80918": ["Colorado Springs", "Colorado", "CO"],
"80919": ["Colorado Springs", "Colorado", "CO"],
"80920": ["Colorado Springs", "Colorado", "CO"],
"80921": ["Colorado Springs", "Colorado", "CO"],
"80922": ["Colorado Springs", "Colorado", "CO"],
"80923": ["Colorado Springs", "Colorado", "CO"],
"80924": ["Colorado Springs", "Colorado", "CO"],
"80925": ["Colorado Springs", "Colorado", "CO"],
"80926": ["Colorado Springs", "Colorado", "CO"],
"80927": ["Colorado Springs", "Colorado", "CO"],
"80928": ["Colorado Springs", "Colorado", "CO"],
"80929": ["Colorado Springs", "Colorado", "CO"],
"80930": ["Colorado Springs", "Colorado", "CO"],
"80938": ["Colorado Springs", "Colorado", "CO"],
"80939": ["Colorado Springs", "Colorado", "CO"],
"80951": ["Colorado Springs", "Colorado", "CO"],
"81001": ["Pueblo", "Colorado", "CO"],
"81003": ["Pueblo", "Colorado", "CO"],
"81004": ["Pueblo", "Colorado", "CO"],
"81005": ["Pueblo", "Colorado", "CO"],
"81006": ["Pueblo", "Colorado", "CO"],
"81007": ["Pueblo", "Colorado", "CO"],
"81008": ["Pueblo", "Colorado", "CO"],
"81019": ["Colorado City", "Colorado", "CO"],
"81020": ["Aguilar", "Colorado", "CO"],
"81021": ["Arlington", "Colorado", "CO"],
"81022": ["Avondale", "Colorado", "CO"],
"81023": ["Beulah", "Colorado", "CO"],
"81024": ["Boncarbo", "Colorado", "CO"],
"81025": ["Boone", "Colorado", "CO"],
"81027": ["Branson", "Colorado", "CO"],
"81029": ["Campo", "Colorado", "CO"],
"81030": ["Cheraw", "Colorado", "CO"],
"81033": ["Crowley", "Colorado", "CO"],
"81036": ["Eads", "Colorado", "CO"],
"81039": ["Fowler", "Colorado", "CO"],
"81040": ["Gardner", "Colorado", "CO"],
"81041": ["Granada", "Colorado", "CO"],
"81043": ["Hartman", "Colorado", "CO"],
"81044": ["Hasty", "Colorado", "CO"],
"81045": ["Haswell", "Colorado", "CO"],
"81046": ["Hoehne", "Colorado", "CO"],
"81047": ["Holly", "Colorado", "CO"],
"81049": ["Kim", "Colorado", "CO"],
"81050": ["La Junta", "Colorado", "CO"],
"81052": ["Lamar", "Colorado", "CO"],
"81054": ["Las Animas", "Colorado", "CO"],
"81055": ["La Veta", "Colorado", "CO"],
"81057": ["McClave", "Colorado", "CO"],
"81058": ["Manzanola", "Colorado", "CO"],
"81059": ["Model", "Colorado", "CO"],
"81062": ["Olney Springs", "Colorado", "CO"],
"81063": ["Ordway", "Colorado", "CO"],
"81064": ["Pritchett", "Colorado", "CO"],
"81067": ["Rocky Ford", "Colorado", "CO"],
"81069": ["Rye", "Colorado", "CO"],
"81071": ["Sheridan Lake", "Colorado", "CO"],
"81073": ["Springfield", "Colorado", "CO"],
"81076": ["Sugar City", "Colorado", "CO"],
"81077": ["Swink", "Colorado", "CO"],
"81081": ["Trinchera", "Colorado", "CO"],
"81082": ["Trinidad", "Colorado", "CO"],
"81084": ["Two Buttes", "Colorado", "CO"],
"81087": ["Vilas", "Colorado", "CO"],
"81089": ["Walsenburg", "Colorado", "CO"],
"81090": ["Walsh", "Colorado", "CO"],
"81091": ["Weston", "Colorado", "CO"],
"81092": ["Wiley", "Colorado", "CO"],
"81101": ["Alamosa", "Colorado", "CO"],
"81120": ["Antonito", "Colorado", "CO"],
"81121": ["Arboles", "Colorado", "CO"],
"81122": ["Bayfield", "Colorado", "CO"],
"81123": ["Blanca", "Colorado", "CO"],
"81124": ["Capulin", "Colorado", "CO"],
"81125": ["Center", "Colorado", "CO"],
"81126": ["Chama", "Colorado", "CO"],
"81128": ["Chromo", "Colorado", "CO"],
"81129": ["Conejos", "Colorado", "CO"],
"81130": ["Creede", "Colorado", "CO"],
"81131": ["Crestone", "Colorado", "CO"],
"81132": ["Del Norte", "Colorado", "CO"],
"81133": ["Fort Garland", "Colorado", "CO"],
"81136": ["Hooper", "Colorado", "CO"],
"81137": ["Ignacio", "Colorado", "CO"],
"81138": ["Jaroso", "Colorado", "CO"],
"81140": ["La Jara", "Colorado", "CO"],
"81141": ["Manassa", "Colorado", "CO"],
"81143": ["Moffat", "Colorado", "CO"],
"81144": ["Monte Vista", "Colorado", "CO"],
"81146": ["Mosca", "Colorado", "CO"],
"81147": ["Pagosa Springs", "Colorado", "CO"],
"81148": ["Romeo", "Colorado", "CO"],
"81149": ["Saguache", "Colorado", "CO"],
"81151": ["Sanford", "Colorado", "CO"],
"81152": ["San Luis", "Colorado", "CO"],
"81154": ["South Fork", "Colorado", "CO"],
"81155": ["Villa Grove", "Colorado", "CO"],
"81201": ["Salida", "Colorado", "CO"],
"81210": ["Almont", "Colorado", "CO"],
"81211": ["Buena Vista", "Colorado", "CO"],
"81212": ["Canon City", "Colorado", "CO"],
"81220": ["Cimarron", "Colorado", "CO"],
"81221": ["Coal Creek", "Colorado", "CO"],
"81222": ["Coaldale", "Colorado", "CO"],
"81223": ["Cotopaxi", "Colorado", "CO"],
"81224": ["Crested Butte", "Colorado", "CO"],
"81225": ["Crested Butte", "Colorado", "CO"],
"81226": ["Florence", "Colorado", "CO"],
"81227": ["Monarch", "Colorado", "CO"],
"81228": ["Granite", "Colorado", "CO"],
"81230": ["Gunnison", "Colorado", "CO"],
"81232": ["Hillside", "Colorado", "CO"],
"81233": ["Howard", "Colorado", "CO"],
"81235": ["Lake City", "Colorado", "CO"],
"81236": ["Nathrop", "Colorado", "CO"],
"81237": ["Ohio City", "Colorado", "CO"],
"81239": ["Parlin", "Colorado", "CO"],
"81240": ["Penrose", "Colorado", "CO"],
"81241": ["Pitkin", "Colorado", "CO"],
"81242": ["Poncha Springs", "Colorado", "CO"],
"81243": ["Powderhorn", "Colorado", "CO"],
"81244": ["Rockvale", "Colorado", "CO"],
"81248": ["Sargents", "Colorado", "CO"],
"81251": ["Twin Lakes", "Colorado", "CO"],
"81252": ["Westcliffe", "Colorado", "CO"],
"81253": ["Wetmore", "Colorado", "CO"],
"81301": ["Durango", "Colorado", "CO"],
"81303": ["Durango", "Colorado", "CO"],
"81320": ["Cahone", "Colorado", "CO"],
"81321": ["Cortez", "Colorado", "CO"],
"81323": ["Dolores", "Colorado", "CO"],
"81324": ["Dove Creek", "Colorado", "CO"],
"81325": ["Egnar", "Colorado", "CO"],
"81326": ["Hesperus", "Colorado", "CO"],
"81327": ["Lewis", "Colorado", "CO"],
"81328": ["Mancos", "Colorado", "CO"],
"81330": ["Mesa Verde National Park", "Colorado", "CO"],
"81331": ["Pleasant View", "Colorado", "CO"],
"81332": ["Rico", "Colorado", "CO"],
"81334": ["Towaoc", "Colorado", "CO"],
"81335": ["Yellow Jacket", "Colorado", "CO"],
"81401": ["Montrose", "Colorado", "CO"],
"81403": ["Montrose", "Colorado", "CO"],
"81410": ["Austin", "Colorado", "CO"],
"81411": ["Bedrock", "Colorado", "CO"],
"81413": ["Cedaredge", "Colorado", "CO"],
"81415": ["Crawford", "Colorado", "CO"],
"81416": ["Delta", "Colorado", "CO"],
"81418": ["Eckert", "Colorado", "CO"],
"81419": ["Hotchkiss", "Colorado", "CO"],
"81422": ["Naturita", "Colorado", "CO"],
"81423": ["Norwood", "Colorado", "CO"],
"81424": ["Nucla", "Colorado", "CO"],
"81425": ["Olathe", "Colorado", "CO"],
"81426": ["Ophir", "Colorado", "CO"],
"81427": ["Ouray", "Colorado", "CO"],
"81428": ["Paonia", "Colorado", "CO"],
"81429": ["Paradox", "Colorado", "CO"],
"81430": ["Placerville", "Colorado", "CO"],
"81431": ["Redvale", "Colorado", "CO"],
"81432": ["Ridgway", "Colorado", "CO"],
"81433": ["Silverton", "Colorado", "CO"],
"81434": ["Somerset", "Colorado", "CO"],
"81435": ["Telluride", "Colorado", "CO"],
"81501": ["Grand Junction", "Colorado", "CO"],
"81503": ["Grand Junction", "Colorado", "CO"],
"81504": ["Grand Junction", "Colorado", "CO"],
"81505": ["Grand Junction", "Colorado", "CO"],
"81506": ["Grand Junction", "Colorado", "CO"],
"81507": ["Grand Junction", "Colorado", "CO"],
"81520": ["Clifton", "Colorado", "CO"],
"81521": ["Fruita", "Colorado", "CO"],
"81522": ["Gateway", "Colorado", "CO"],
"81523": ["Glade Park", "Colorado", "CO"],
"81524": ["Loma", "Colorado", "CO"],
"81525": ["Mack", "Colorado", "CO"],
"81526": ["Palisade", "Colorado", "CO"],
"81527": ["Whitewater", "Colorado", "CO"],
"81601": ["Glenwood Springs", "Colorado", "CO"],
"81610": ["Dinosaur", "Colorado", "CO"],
"81611": ["Aspen", "Colorado", "CO"],
"81612": ["Aspen", "Colorado", "CO"],
"81615": ["Snowmass Village", "Colorado", "CO"],
"81620": ["Avon", "Colorado", "CO"],
"81621": ["Basalt", "Colorado", "CO"],
"81623": ["Carbondale", "Colorado", "CO"],
"81624": ["Collbran", "Colorado", "CO"],
"81625": ["Craig", "Colorado", "CO"],
"81630": ["De Beque", "Colorado", "CO"],
"81631": ["Eagle", "Colorado", "CO"],
"81632": ["Edwards", "Colorado", "CO"],
"81633": ["Dinosaur", "Colorado", "CO"],
"81635": ["Parachute", "Colorado", "CO"],
"81637": ["Gypsum", "Colorado", "CO"],
"81638": ["Hamilton", "Colorado", "CO"],
"81639": ["Hayden", "Colorado", "CO"],
"81640": ["Maybell", "Colorado", "CO"],
"81641": ["Meeker", "Colorado", "CO"],
"81642": ["Meredith", "Colorado", "CO"],
"81643": ["Mesa", "Colorado", "CO"],
"81645": ["Minturn", "Colorado", "CO"],
"81646": ["Molina", "Colorado", "CO"],
"81647": ["New Castle", "Colorado", "CO"],
"81648": ["Rangely", "Colorado", "CO"],
"81649": ["Red Cliff", "Colorado", "CO"],
"81650": ["Rifle", "Colorado", "CO"],
"81652": ["Silt", "Colorado", "CO"],
"81653": ["Slater", "Colorado", "CO"],
"81654": ["Snowmass", "Colorado", "CO"],
"81655": ["Wolcott", "Colorado", "CO"],
"81656": ["Woody Creek", "Colorado", "CO"],
"81657": ["Vail", "Colorado", "CO"],
"82001": ["Cheyenne", "Wyoming", "WY"],
"82005": ["Fe Warren Afb", "Wyoming", "WY"],
"82007": ["Cheyenne", "Wyoming", "WY"],
"82009": ["Cheyenne", "Wyoming", "WY"],
"82050": ["Albin", "Wyoming", "WY"],
"82051": ["Bosler", "Wyoming", "WY"],
"82052": ["Buford", "Wyoming", "WY"],
"82053": ["Burns", "Wyoming", "WY"],
"82054": ["Carpenter", "Wyoming", "WY"],
"82055": ["Centennial", "Wyoming", "WY"],
"82058": ["Garrett", "Wyoming", "WY"],
"82059": ["Granite Canon", "Wyoming", "WY"],
"82060": ["Hillsdale", "Wyoming", "WY"],
"82061": ["Horse Creek", "Wyoming", "WY"],
"82063": ["Jelm", "Wyoming", "WY"],
"82070": ["Laramie", "Wyoming", "WY"],
"82072": ["Laramie", "Wyoming", "WY"],
"82073": ["Laramie", "Wyoming", "WY"],
"82081": ["Meriden", "Wyoming", "WY"],
"82082": ["Pine Bluffs", "Wyoming", "WY"],
"82083": ["Rock River", "Wyoming", "WY"],
"82084": ["Tie Siding", "Wyoming", "WY"],
"82190": ["Yellowstone National Park", "Wyoming", "WY"],
"82201": ["Wheatland", "Wyoming", "WY"],
"82210": ["Chugwater", "Wyoming", "WY"],
"82212": ["Fort Laramie", "Wyoming", "WY"],
"82213": ["Glendo", "Wyoming", "WY"],
"82214": ["Guernsey", "Wyoming", "WY"],
"82215": ["Hartville", "Wyoming", "WY"],
"82217": ["Hawk Springs", "Wyoming", "WY"],
"82218": ["Huntley", "Wyoming", "WY"],
"82219": ["Jay Em", "Wyoming", "WY"],
"82221": ["Lagrange", "Wyoming", "WY"],
"82222": ["Lance Creek", "Wyoming", "WY"],
"82223": ["Lingle", "Wyoming", "WY"],
"82224": ["Lost Springs", "Wyoming", "WY"],
"82225": ["Lusk", "Wyoming", "WY"],
"82227": ["Manville", "Wyoming", "WY"],
"82229": ["Shawnee", "Wyoming", "WY"],
"82240": ["Torrington", "Wyoming", "WY"],
"82242": ["Van Tassell", "Wyoming", "WY"],
"82243": ["Veteran", "Wyoming", "WY"],
"82244": ["Yoder", "Wyoming", "WY"],
"82301": ["Rawlins", "Wyoming", "WY"],
"82310": ["Jeffrey City", "Wyoming", "WY"],
"82321": ["Baggs", "Wyoming", "WY"],
"82322": ["Bairoil", "Wyoming", "WY"],
"82323": ["Dixon", "Wyoming", "WY"],
"82324": ["Elk Mountain", "Wyoming", "WY"],
"82325": ["Encampment", "Wyoming", "WY"],
"82327": ["Hanna", "Wyoming", "WY"],
"82329": ["Medicine Bow", "Wyoming", "WY"],
"82331": ["Saratoga", "Wyoming", "WY"],
"82332": ["Savery", "Wyoming", "WY"],
"82334": ["Sinclair", "Wyoming", "WY"],
"82335": ["Walcott", "Wyoming", "WY"],
"82336": ["Wamsutter", "Wyoming", "WY"],
"82401": ["Worland", "Wyoming", "WY"],
"82410": ["Basin", "Wyoming", "WY"],
"82411": ["Burlington", "Wyoming", "WY"],
"82412": ["Byron", "Wyoming", "WY"],
"82414": ["Cody", "Wyoming", "WY"],
"82420": ["Cowley", "Wyoming", "WY"],
"82421": ["Deaver", "Wyoming", "WY"],
"82422": ["Emblem", "Wyoming", "WY"],
"82423": ["Frannie", "Wyoming", "WY"],
"82426": ["Greybull", "Wyoming", "WY"],
"82428": ["Hyattville", "Wyoming", "WY"],
"82430": ["Kirby", "Wyoming", "WY"],
"82431": ["Lovell", "Wyoming", "WY"],
"82432": ["Manderson", "Wyoming", "WY"],
"82433": ["Meeteetse", "Wyoming", "WY"],
"82434": ["Otto", "Wyoming", "WY"],
"82435": ["Powell", "Wyoming", "WY"],
"82440": ["Ralston", "Wyoming", "WY"],
"82441": ["Shell", "Wyoming", "WY"],
"82442": ["Ten Sleep", "Wyoming", "WY"],
"82443": ["Thermopolis", "Wyoming", "WY"],
"82450": ["Wapiti", "Wyoming", "WY"],
"82501": ["Riverton", "Wyoming", "WY"],
"82510": ["Arapahoe", "Wyoming", "WY"],
"82512": ["Crowheart", "Wyoming", "WY"],
"82513": ["Dubois", "Wyoming", "WY"],
"82514": ["Fort Washakie", "Wyoming", "WY"],
"82515": ["Hudson", "Wyoming", "WY"],
"82516": ["Kinnear", "Wyoming", "WY"],
"82520": ["Lander", "Wyoming", "WY"],
"82523": ["Pavillion", "Wyoming", "WY"],
"82601": ["Casper", "Wyoming", "WY"],
"82604": ["Casper", "Wyoming", "WY"],
"82609": ["Casper", "Wyoming", "WY"],
"82620": ["Alcova", "Wyoming", "WY"],
"82630": ["Arminto", "Wyoming", "WY"],
"82633": ["Douglas", "Wyoming", "WY"],
"82635": ["Edgerton", "Wyoming", "WY"],
"82636": ["Evansville", "Wyoming", "WY"],
"82637": ["Glenrock", "Wyoming", "WY"],
"82638": ["Hiland", "Wyoming", "WY"],
"82639": ["Kaycee", "Wyoming", "WY"],
"82640": ["Linch", "Wyoming", "WY"],
"82642": ["Lysite", "Wyoming", "WY"],
"82643": ["Midwest", "Wyoming", "WY"],
"82644": ["Mills", "Wyoming", "WY"],
"82646": ["Natrona", "Wyoming", "WY"],
"82648": ["Powder River", "Wyoming", "WY"],
"82649": ["Shoshoni", "Wyoming", "WY"],
"82701": ["Newcastle", "Wyoming", "WY"],
"82710": ["Aladdin", "Wyoming", "WY"],
"82711": ["Alva", "Wyoming", "WY"],
"82712": ["Beulah", "Wyoming", "WY"],
"82714": ["Devils Tower", "Wyoming", "WY"],
"82716": ["Gillette", "Wyoming", "WY"],
"82718": ["Gillette", "Wyoming", "WY"],
"82720": ["Hulett", "Wyoming", "WY"],
"82721": ["Moorcroft", "Wyoming", "WY"],
"82723": ["Osage", "Wyoming", "WY"],
"82725": ["Recluse", "Wyoming", "WY"],
"82727": ["Rozet", "Wyoming", "WY"],
"82729": ["Sundance", "Wyoming", "WY"],
"82730": ["Upton", "Wyoming", "WY"],
"82731": ["Weston", "Wyoming", "WY"],
"82732": ["Wright", "Wyoming", "WY"],
"82801": ["Sheridan", "Wyoming", "WY"],
"82831": ["Arvada", "Wyoming", "WY"],
"82832": ["Banner", "Wyoming", "WY"],
"82833": ["Big Horn", "Wyoming", "WY"],
"82834": ["Buffalo", "Wyoming", "WY"],
"82835": ["Clearmont", "Wyoming", "WY"],
"82836": ["Dayton", "Wyoming", "WY"],
"82837": ["Leiter", "Wyoming", "WY"],
"82838": ["Parkman", "Wyoming", "WY"],
"82839": ["Ranchester", "Wyoming", "WY"],
"82842": ["Story", "Wyoming", "WY"],
"82844": ["Wolf", "Wyoming", "WY"],
"82845": ["Wyarno", "Wyoming", "WY"],
"82901": ["Rock Springs", "Wyoming", "WY"],
"82922": ["Bondurant", "Wyoming", "WY"],
"82923": ["Boulder", "Wyoming", "WY"],
"82925": ["Cora", "Wyoming", "WY"],
"82929": ["Little America", "Wyoming", "WY"],
"82930": ["Evanston", "Wyoming", "WY"],
"82932": ["Farson", "Wyoming", "WY"],
"82933": ["Fort Bridger", "Wyoming", "WY"],
"82934": ["Granger", "Wyoming", "WY"],
"82935": ["Green River", "Wyoming", "WY"],
"82936": ["Lonetree", "Wyoming", "WY"],
"82937": ["Lyman", "Wyoming", "WY"],
"82938": ["McKinnon", "Wyoming", "WY"],
"82939": ["Mountain View", "Wyoming", "WY"],
"82941": ["Pinedale", "Wyoming", "WY"],
"82942": ["Point Of Rocks", "Wyoming", "WY"],
"82943": ["Reliance", "Wyoming", "WY"],
"82944": ["Robertson", "Wyoming", "WY"],
"82945": ["Superior", "Wyoming", "WY"],
"83001": ["Jackson", "Wyoming", "WY"],
"83011": ["Kelly", "Wyoming", "WY"],
"83012": ["Moose", "Wyoming", "WY"],
"83013": ["Moran", "Wyoming", "WY"],
"83014": ["Wilson", "Wyoming", "WY"],
"83025": ["Teton Village", "Wyoming", "WY"],
"83101": ["Kemmerer", "Wyoming", "WY"],
"83110": ["Afton", "Wyoming", "WY"],
"83111": ["Auburn", "Wyoming", "WY"],
"83112": ["Bedford", "Wyoming", "WY"],
"83113": ["Big Piney", "Wyoming", "WY"],
"83114": ["Cokeville", "Wyoming", "WY"],
"83115": ["Daniel", "Wyoming", "WY"],
"83116": ["Diamondville", "Wyoming", "WY"],
"83118": ["Etna", "Wyoming", "WY"],
"83119": ["Fairview", "Wyoming", "WY"],
"83120": ["Freedom", "Wyoming", "WY"],
"83121": ["Frontier", "Wyoming", "WY"],
"83122": ["Grover", "Wyoming", "WY"],
"83123": ["La Barge", "Wyoming", "WY"],
"83124": ["Opal", "Wyoming", "WY"],
"83126": ["Smoot", "Wyoming", "WY"],
"83127": ["Thayne", "Wyoming", "WY"],
"83128": ["Alpine", "Wyoming", "WY"],
"83201": ["Pocatello", "Idaho", "ID"],
"83202": ["Pocatello", "Idaho", "ID"],
"83203": ["Fort Hall", "Idaho", "ID"],
"83204": ["Pocatello", "Idaho", "ID"],
"83209": ["Pocatello", "Idaho", "ID"],
"83210": ["Aberdeen", "Idaho", "ID"],
"83211": ["American Falls", "Idaho", "ID"],
"83212": ["Arbon", "Idaho", "ID"],
"83213": ["Arco", "Idaho", "ID"],
"83214": ["Arimo", "Idaho", "ID"],
"83215": ["Atomic City", "Idaho", "ID"],
"83217": ["Bancroft", "Idaho", "ID"],
"83218": ["Basalt", "Idaho", "ID"],
"83220": ["Bern", "Idaho", "ID"],
"83221": ["Blackfoot", "Idaho", "ID"],
"83223": ["Bloomington", "Idaho", "ID"],
"83226": ["Challis", "Idaho", "ID"],
"83227": ["Clayton", "Idaho", "ID"],
"83228": ["Clifton", "Idaho", "ID"],
"83232": ["Dayton", "Idaho", "ID"],
"83233": ["Dingle", "Idaho", "ID"],
"83234": ["Downey", "Idaho", "ID"],
"83235": ["Ellis", "Idaho", "ID"],
"83236": ["Firth", "Idaho", "ID"],
"83237": ["Franklin", "Idaho", "ID"],
"83238": ["Geneva", "Idaho", "ID"],
"83239": ["Georgetown", "Idaho", "ID"],
"83241": ["Grace", "Idaho", "ID"],
"83243": ["Holbrook", "Idaho", "ID"],
"83244": ["Howe", "Idaho", "ID"],
"83245": ["Inkom", "Idaho", "ID"],
"83246": ["Lava Hot Springs", "Idaho", "ID"],
"83250": ["Mccammon", "Idaho", "ID"],
"83251": ["Mackay", "Idaho", "ID"],
"83252": ["Malad City", "Idaho", "ID"],
"83253": ["May", "Idaho", "ID"],
"83254": ["Montpelier", "Idaho", "ID"],
"83255": ["Moore", "Idaho", "ID"],
"83261": ["Paris", "Idaho", "ID"],
"83262": ["Pingree", "Idaho", "ID"],
"83263": ["Preston", "Idaho", "ID"],
"83271": ["Rockland", "Idaho", "ID"],
"83272": ["Saint Charles", "Idaho", "ID"],
"83274": ["Shelley", "Idaho", "ID"],
"83276": ["Soda Springs", "Idaho", "ID"],
"83277": ["Springfield", "Idaho", "ID"],
"83278": ["Stanley", "Idaho", "ID"],
"83281": ["Swanlake", "Idaho", "ID"],
"83283": ["Thatcher", "Idaho", "ID"],
"83285": ["Wayan", "Idaho", "ID"],
"83286": ["Weston", "Idaho", "ID"],
"83287": ["Fish Haven", "Idaho", "ID"],
"83301": ["Twin Falls", "Idaho", "ID"],
"83302": ["Rogerson", "Idaho", "ID"],
"83311": ["Albion", "Idaho", "ID"],
"83312": ["Almo", "Idaho", "ID"],
"83313": ["Bellevue", "Idaho", "ID"],
"83314": ["Bliss", "Idaho", "ID"],
"83316": ["Buhl", "Idaho", "ID"],
"83318": ["Burley", "Idaho", "ID"],
"83320": ["Carey", "Idaho", "ID"],
"83321": ["Castleford", "Idaho", "ID"],
"83322": ["Corral", "Idaho", "ID"],
"83323": ["Declo", "Idaho", "ID"],
"83324": ["Dietrich", "Idaho", "ID"],
"83325": ["Eden", "Idaho", "ID"],
"83327": ["Fairfield", "Idaho", "ID"],
"83328": ["Filer", "Idaho", "ID"],
"83330": ["Gooding", "Idaho", "ID"],
"83332": ["Hagerman", "Idaho", "ID"],
"83333": ["Hailey", "Idaho", "ID"],
"83334": ["Hansen", "Idaho", "ID"],
"83335": ["Hazelton", "Idaho", "ID"],
"83336": ["Heyburn", "Idaho", "ID"],
"83337": ["Hill City", "Idaho", "ID"],
"83338": ["Jerome", "Idaho", "ID"],
"83340": ["Ketchum", "Idaho", "ID"],
"83341": ["Kimberly", "Idaho", "ID"],
"83342": ["Malta", "Idaho", "ID"],
"83344": ["Murtaugh", "Idaho", "ID"],
"83346": ["Oakley", "Idaho", "ID"],
"83347": ["Paul", "Idaho", "ID"],
"83348": ["Picabo", "Idaho", "ID"],
"83349": ["Richfield", "Idaho", "ID"],
"83350": ["Rupert", "Idaho", "ID"],
"83352": ["Shoshone", "Idaho", "ID"],
"83353": ["Sun Valley", "Idaho", "ID"],
"83354": ["Sun Valley", "Idaho", "ID"],
"83355": ["Wendell", "Idaho", "ID"],
"83401": ["Idaho Falls", "Idaho", "ID"],
"83402": ["Idaho Falls", "Idaho", "ID"],
"83404": ["Idaho Falls", "Idaho", "ID"],
"83406": ["Idaho Falls", "Idaho", "ID"],
"83414": ["Alta", "Wyoming", "WY"],
"83420": ["Ashton", "Idaho", "ID"],
"83421": ["Chester", "Idaho", "ID"],
"83422": ["Driggs", "Idaho", "ID"],
"83423": ["Dubois", "Idaho", "ID"],
"83424": ["Felt", "Idaho", "ID"],
"83425": ["Hamer", "Idaho", "ID"],
"83427": ["Iona", "Idaho", "ID"],
"83428": ["Irwin", "Idaho", "ID"],
"83429": ["Island Park", "Idaho", "ID"],
"83431": ["Lewisville", "Idaho", "ID"],
"83433": ["Macks Inn", "Idaho", "ID"],
"83434": ["Menan", "Idaho", "ID"],
"83435": ["Monteview", "Idaho", "ID"],
"83436": ["Newdale", "Idaho", "ID"],
"83438": ["Parker", "Idaho", "ID"],
"83440": ["Rexburg", "Idaho", "ID"],
"83442": ["Rigby", "Idaho", "ID"],
"83443": ["Ririe", "Idaho", "ID"],
"83444": ["Roberts", "Idaho", "ID"],
"83445": ["Saint Anthony", "Idaho", "ID"],
"83446": ["Spencer", "Idaho", "ID"],
"83448": ["Sugar City", "Idaho", "ID"],
"83449": ["Swan Valley", "Idaho", "ID"],
"83450": ["Terreton", "Idaho", "ID"],
"83451": ["Teton", "Idaho", "ID"],
"83452": ["Tetonia", "Idaho", "ID"],
"83454": ["Ucon", "Idaho", "ID"],
"83455": ["Victor", "Idaho", "ID"],
"83460": ["Rexburg", "Idaho", "ID"],
"83462": ["Carmen", "Idaho", "ID"],
"83463": ["Gibbonsville", "Idaho", "ID"],
"83464": ["Leadore", "Idaho", "ID"],
"83465": ["Lemhi", "Idaho", "ID"],
"83466": ["North Fork", "Idaho", "ID"],
"83467": ["Salmon", "Idaho", "ID"],
"83468": ["Tendoy", "Idaho", "ID"],
"83469": ["Shoup", "Idaho", "ID"],
"83501": ["Lewiston", "Idaho", "ID"],
"83520": ["Ahsahka", "Idaho", "ID"],
"83522": ["Cottonwood", "Idaho", "ID"],
"83523": ["Craigmont", "Idaho", "ID"],
"83524": ["Culdesac", "Idaho", "ID"],
"83525": ["Elk City", "Idaho", "ID"],
"83526": ["Ferdinand", "Idaho", "ID"],
"83530": ["Grangeville", "Idaho", "ID"],
"83533": ["Greencreek", "Idaho", "ID"],
"83535": ["Juliaetta", "Idaho", "ID"],
"83536": ["Kamiah", "Idaho", "ID"],
"83537": ["Kendrick", "Idaho", "ID"],
"83539": ["Kooskia", "Idaho", "ID"],
"83540": ["Lapwai", "Idaho", "ID"],
"83541": ["Lenore", "Idaho", "ID"],
"83542": ["Lucile", "Idaho", "ID"],
"83543": ["Nezperce", "Idaho", "ID"],
"83544": ["Orofino", "Idaho", "ID"],
"83545": ["Peck", "Idaho", "ID"],
"83546": ["Pierce", "Idaho", "ID"],
"83547": ["Pollock", "Idaho", "ID"],
"83548": ["Reubens", "Idaho", "ID"],
"83549": ["Riggins", "Idaho", "ID"],
"83552": ["Stites", "Idaho", "ID"],
"83553": ["Weippe", "Idaho", "ID"],
"83554": ["White Bird", "Idaho", "ID"],
"83555": ["Winchester", "Idaho", "ID"],
"83602": ["Banks", "Idaho", "ID"],
"83604": ["Bruneau", "Idaho", "ID"],
"83605": ["Caldwell", "Idaho", "ID"],
"83607": ["Caldwell", "Idaho", "ID"],
"83610": ["Cambridge", "Idaho", "ID"],
"83611": ["Cascade", "Idaho", "ID"],
"83612": ["Council", "Idaho", "ID"],
"83615": ["Donnelly", "Idaho", "ID"],
"83616": ["Eagle", "Idaho", "ID"],
"83617": ["Emmett", "Idaho", "ID"],
"83619": ["Fruitland", "Idaho", "ID"],
"83622": ["Garden Valley", "Idaho", "ID"],
"83623": ["Glenns Ferry", "Idaho", "ID"],
"83624": ["Grand View", "Idaho", "ID"],
"83626": ["Greenleaf", "Idaho", "ID"],
"83627": ["Hammett", "Idaho", "ID"],
"83628": ["Homedale", "Idaho", "ID"],
"83629": ["Horseshoe Bend", "Idaho", "ID"],
"83631": ["Idaho City", "Idaho", "ID"],
"83632": ["Indian Valley", "Idaho", "ID"],
"83633": ["King Hill", "Idaho", "ID"],
"83634": ["Kuna", "Idaho", "ID"],
"83636": ["Letha", "Idaho", "ID"],
"83637": ["Lowman", "Idaho", "ID"],
"83638": ["Mccall", "Idaho", "ID"],
"83639": ["Marsing", "Idaho", "ID"],
"83641": ["Melba", "Idaho", "ID"],
"83642": ["Meridian", "Idaho", "ID"],
"83643": ["Mesa", "Idaho", "ID"],
"83644": ["Middleton", "Idaho", "ID"],
"83645": ["Midvale", "Idaho", "ID"],
"83646": ["Meridian", "Idaho", "ID"],
"83647": ["Mountain Home", "Idaho", "ID"],
"83648": ["Mountain Home Afb", "Idaho", "ID"],
"83650": ["Murphy", "Idaho", "ID"],
"83651": ["Nampa", "Idaho", "ID"],
"83654": ["New Meadows", "Idaho", "ID"],
"83655": ["New Plymouth", "Idaho", "ID"],
"83656": ["Notus", "Idaho", "ID"],
"83657": ["Ola", "Idaho", "ID"],
"83660": ["Parma", "Idaho", "ID"],
"83661": ["Payette", "Idaho", "ID"],
"83666": ["Placerville", "Idaho", "ID"],
"83669": ["Star", "Idaho", "ID"],
"83670": ["Sweet", "Idaho", "ID"],
"83672": ["Weiser", "Idaho", "ID"],
"83676": ["Wilder", "Idaho", "ID"],
"83677": ["Yellow Pine", "Idaho", "ID"],
"83686": ["Nampa", "Idaho", "ID"],
"83687": ["Nampa", "Idaho", "ID"],
"83702": ["Boise", "Idaho", "ID"],
"83703": ["Boise", "Idaho", "ID"],
"83704": ["Boise", "Idaho", "ID"],
"83705": ["Boise", "Idaho", "ID"],
"83706": ["Boise", "Idaho", "ID"],
"83709": ["Boise", "Idaho", "ID"],
"83712": ["Boise", "Idaho", "ID"],
"83713": ["Boise", "Idaho", "ID"],
"83714": ["Garden City", "Idaho", "ID"],
"83716": ["Boise", "Idaho", "ID"],
"83720": ["Boise", "Idaho", "ID"],
"83725": ["Boise", "Idaho", "ID"],
"83801": ["Athol", "Idaho", "ID"],
"83802": ["Avery", "Idaho", "ID"],
"83803": ["Bayview", "Idaho", "ID"],
"83804": ["Blanchard", "Idaho", "ID"],
"83805": ["Bonners Ferry", "Idaho", "ID"],
"83806": ["Bovill", "Idaho", "ID"],
"83808": ["Calder", "Idaho", "ID"],
"83809": ["Careywood", "Idaho", "ID"],
"83810": ["Cataldo", "Idaho", "ID"],
"83811": ["Clark Fork", "Idaho", "ID"],
"83812": ["Clarkia", "Idaho", "ID"],
"83813": ["Cocolalla", "Idaho", "ID"],
"83814": ["Coeur D Alene", "Idaho", "ID"],
"83815": ["Coeur D Alene", "Idaho", "ID"],
"83821": ["Coolin", "Idaho", "ID"],
"83822": ["Oldtown", "Idaho", "ID"],
"83823": ["Deary", "Idaho", "ID"],
"83824": ["Desmet", "Idaho", "ID"],
"83825": ["Dover", "Idaho", "ID"],
"83826": ["Eastport", "Idaho", "ID"],
"83827": ["Elk River", "Idaho", "ID"],
"83830": ["Fernwood", "Idaho", "ID"],
"83832": ["Genesee", "Idaho", "ID"],
"83833": ["Harrison", "Idaho", "ID"],
"83834": ["Harvard", "Idaho", "ID"],
"83835": ["Hayden", "Idaho", "ID"],
"83836": ["Hope", "Idaho", "ID"],
"83837": ["Kellogg", "Idaho", "ID"],
"83839": ["Kingston", "Idaho", "ID"],
"83840": ["Kootenai", "Idaho", "ID"],
"83841": ["Laclede", "Idaho", "ID"],
"83842": ["Medimont", "Idaho", "ID"],
"83843": ["Moscow", "Idaho", "ID"],
"83844": ["Moscow", "Idaho", "ID"],
"83845": ["Moyie Springs", "Idaho", "ID"],
"83846": ["Mullan", "Idaho", "ID"],
"83847": ["Naples", "Idaho", "ID"],
"83848": ["Nordman", "Idaho", "ID"],
"83849": ["Osburn", "Idaho", "ID"],
"83850": ["Pinehurst", "Idaho", "ID"],
"83851": ["Plummer", "Idaho", "ID"],
"83852": ["Ponderay", "Idaho", "ID"],
"83854": ["Post Falls", "Idaho", "ID"],
"83855": ["Potlatch", "Idaho", "ID"],
"83856": ["Priest River", "Idaho", "ID"],
"83857": ["Princeton", "Idaho", "ID"],
"83858": ["Rathdrum", "Idaho", "ID"],
"83860": ["Sagle", "Idaho", "ID"],
"83861": ["Saint Maries", "Idaho", "ID"],
"83864": ["Sandpoint", "Idaho", "ID"],
"83866": ["Santa", "Idaho", "ID"],
"83867": ["Silverton", "Idaho", "ID"],
"83868": ["Smelterville", "Idaho", "ID"],
"83869": ["Spirit Lake", "Idaho", "ID"],
"83870": ["Tensed", "Idaho", "ID"],
"83871": ["Troy", "Idaho", "ID"],
"83872": ["Viola", "Idaho", "ID"],
"83873": ["Wallace", "Idaho", "ID"],
"83874": ["Murray", "Idaho", "ID"],
"83876": ["Worley", "Idaho", "ID"],
"84001": ["Altamont", "Utah", "UT"],
"84002": ["Altonah", "Utah", "UT"],
"84003": ["American Fork", "Utah", "UT"],
"84004": ["Alpine", "Utah", "UT"],
"84005": ["Eagle Mountain", "Utah", "UT"],
"84006": ["Bingham Canyon", "Utah", "UT"],
"84007": ["Bluebell", "Utah", "UT"],
"84008": ["Bonanza", "Utah", "UT"],
"84009": ["South Jordan", "Utah", "UT"],
"84010": ["Bountiful", "Utah", "UT"],
"84013": ["Cedar Valley", "Utah", "UT"],
"84014": ["Centerville", "Utah", "UT"],
"84015": ["Clearfield", "Utah", "UT"],
"84017": ["Coalville", "Utah", "UT"],
"84018": ["Croydon", "Utah", "UT"],
"84020": ["Draper", "Utah", "UT"],
"84021": ["Duchesne", "Utah", "UT"],
"84022": ["Dugway", "Utah", "UT"],
"84023": ["Dutch John", "Utah", "UT"],
"84024": ["Echo", "Utah", "UT"],
"84025": ["Farmington", "Utah", "UT"],
"84026": ["Fort Duchesne", "Utah", "UT"],
"84027": ["Fruitland", "Utah", "UT"],
"84028": ["Garden City", "Utah", "UT"],
"84029": ["Grantsville", "Utah", "UT"],
"84031": ["Hanna", "Utah", "UT"],
"84032": ["Heber City", "Utah", "UT"],
"84033": ["Henefer", "Utah", "UT"],
"84034": ["Ibapah", "Utah", "UT"],
"84035": ["Jensen", "Utah", "UT"],
"84036": ["Kamas", "Utah", "UT"],
"84037": ["Kaysville", "Utah", "UT"],
"84038": ["Laketown", "Utah", "UT"],
"84039": ["Lapoint", "Utah", "UT"],
"84040": ["Layton", "Utah", "UT"],
"84041": ["Layton", "Utah", "UT"],
"84042": ["Lindon", "Utah", "UT"],
"84043": ["Lehi", "Utah", "UT"],
"84044": ["Magna", "Utah", "UT"],
"84045": ["Saratoga Springs", "Utah", "UT"],
"84046": ["Manila", "Utah", "UT"],
"84047": ["Midvale", "Utah", "UT"],
"84049": ["Midway", "Utah", "UT"],
"84050": ["Morgan", "Utah", "UT"],
"84051": ["Mountain Home", "Utah", "UT"],
"84052": ["Myton", "Utah", "UT"],
"84053": ["Neola", "Utah", "UT"],
"84054": ["North Salt Lake", "Utah", "UT"],
"84055": ["Oakley", "Utah", "UT"],
"84056": ["Hill Afb", "Utah", "UT"],
"84057": ["Orem", "Utah", "UT"],
"84058": ["Orem", "Utah", "UT"],
"84059": ["Vineyard", "Utah", "UT"],
"84060": ["Park City", "Utah", "UT"],
"84061": ["Peoa", "Utah", "UT"],
"84062": ["Pleasant Grove", "Utah", "UT"],
"84063": ["Randlett", "Utah", "UT"],
"84064": ["Randolph", "Utah", "UT"],
"84065": ["Riverton", "Utah", "UT"],
"84066": ["Roosevelt", "Utah", "UT"],
"84067": ["Roy", "Utah", "UT"],
"84069": ["Rush Valley", "Utah", "UT"],
"84070": ["Sandy", "Utah", "UT"],
"84071": ["Stockton", "Utah", "UT"],
"84072": ["Tabiona", "Utah", "UT"],
"84073": ["Talmage", "Utah", "UT"],
"84074": ["Tooele", "Utah", "UT"],
"84075": ["Syracuse", "Utah", "UT"],
"84076": ["Tridell", "Utah", "UT"],
"84078": ["Vernal", "Utah", "UT"],
"84080": ["Vernon", "Utah", "UT"],
"84081": ["West Jordan", "Utah", "UT"],
"84082": ["Wallsburg", "Utah", "UT"],
"84083": ["Wendover", "Utah", "UT"],
"84084": ["West Jordan", "Utah", "UT"],
"84085": ["Whiterocks", "Utah", "UT"],
"84086": ["Woodruff", "Utah", "UT"],
"84087": ["Woods Cross", "Utah", "UT"],
"84088": ["West Jordan", "Utah", "UT"],
"84092": ["Sandy", "Utah", "UT"],
"84093": ["Sandy", "Utah", "UT"],
"84094": ["Sandy", "Utah", "UT"],
"84095": ["South Jordan", "Utah", "UT"],
"84096": ["Herriman", "Utah", "UT"],
"84097": ["Orem", "Utah", "UT"],
"84098": ["Park City", "Utah", "UT"],
"84101": ["Salt Lake City", "Utah", "UT"],
"84102": ["Salt Lake City", "Utah", "UT"],
"84103": ["Salt Lake City", "Utah", "UT"],
"84104": ["Salt Lake City", "Utah", "UT"],
"84105": ["Salt Lake City", "Utah", "UT"],
"84106": ["Salt Lake City", "Utah", "UT"],
"84107": ["Salt Lake City", "Utah", "UT"],
"84108": ["Salt Lake City", "Utah", "UT"],
"84109": ["Salt Lake City", "Utah", "UT"],
"84111": ["Salt Lake City", "Utah", "UT"],
"84112": ["Salt Lake City", "Utah", "UT"],
"84113": ["Salt Lake City", "Utah", "UT"],
"84114": ["Salt Lake City", "Utah", "UT"],
"84115": ["Salt Lake City", "Utah", "UT"],
"84116": ["Salt Lake City", "Utah", "UT"],
"84117": ["Salt Lake City", "Utah", "UT"],
"84118": ["Salt Lake City", "Utah", "UT"],
"84119": ["West Valley City", "Utah", "UT"],
"84120": ["West Valley City", "Utah", "UT"],
"84121": ["Salt Lake City", "Utah", "UT"],
"84123": ["Salt Lake City", "Utah", "UT"],
"84124": ["Salt Lake City", "Utah", "UT"],
"84128": ["West Valley City", "Utah", "UT"],
"84129": ["Salt Lake City", "Utah", "UT"],
"84138": ["Salt Lake City", "Utah", "UT"],
"84150": ["Salt Lake City", "Utah", "UT"],
"84180": ["Salt Lake City", "Utah", "UT"],
"84301": ["Bear River City", "Utah", "UT"],
"84302": ["Brigham City", "Utah", "UT"],
"84304": ["Cache Junction", "Utah", "UT"],
"84305": ["Clarkston", "Utah", "UT"],
"84306": ["Collinston", "Utah", "UT"],
"84307": ["Corinne", "Utah", "UT"],
"84308": ["Cornish", "Utah", "UT"],
"84309": ["Deweyville", "Utah", "UT"],
"84310": ["Eden", "Utah", "UT"],
"84311": ["Fielding", "Utah", "UT"],
"84312": ["Garland", "Utah", "UT"],
"84313": ["Grouse Creek", "Utah", "UT"],
"84314": ["Honeyville", "Utah", "UT"],
"84315": ["Hooper", "Utah", "UT"],
"84316": ["Howell", "Utah", "UT"],
"84317": ["Huntsville", "Utah", "UT"],
"84318": ["Hyde Park", "Utah", "UT"],
"84319": ["Hyrum", "Utah", "UT"],
"84320": ["Lewiston", "Utah", "UT"],
"84321": ["Logan", "Utah", "UT"],
"84322": ["Logan", "Utah", "UT"],
"84324": ["Mantua", "Utah", "UT"],
"84325": ["Mendon", "Utah", "UT"],
"84326": ["Millville", "Utah", "UT"],
"84327": ["Newton", "Utah", "UT"],
"84328": ["Paradise", "Utah", "UT"],
"84329": ["Park Valley", "Utah", "UT"],
"84330": ["Plymouth", "Utah", "UT"],
"84331": ["Portage", "Utah", "UT"],
"84332": ["Providence", "Utah", "UT"],
"84333": ["Richmond", "Utah", "UT"],
"84334": ["Riverside", "Utah", "UT"],
"84335": ["Smithfield", "Utah", "UT"],
"84336": ["Snowville", "Utah", "UT"],
"84337": ["Tremonton", "Utah", "UT"],
"84338": ["Trenton", "Utah", "UT"],
"84339": ["Wellsville", "Utah", "UT"],
"84340": ["Willard", "Utah", "UT"],
"84341": ["Logan", "Utah", "UT"],
"84401": ["Ogden", "Utah", "UT"],
"84403": ["Ogden", "Utah", "UT"],
"84404": ["Ogden", "Utah", "UT"],
"84405": ["Ogden", "Utah", "UT"],
"84408": ["Ogden", "Utah", "UT"],
"84414": ["Ogden", "Utah", "UT"],
"84501": ["Price", "Utah", "UT"],
"84511": ["Blanding", "Utah", "UT"],
"84512": ["Bluff", "Utah", "UT"],
"84513": ["Castle Dale", "Utah", "UT"],
"84515": ["Cisco", "Utah", "UT"],
"84516": ["Clawson", "Utah", "UT"],
"84518": ["Cleveland", "Utah", "UT"],
"84520": ["East Carbon", "Utah", "UT"],
"84521": ["Elmo", "Utah", "UT"],
"84522": ["Emery", "Utah", "UT"],
"84523": ["Ferron", "Utah", "UT"],
"84525": ["Green River", "Utah", "UT"],
"84526": ["Helper", "Utah", "UT"],
"84528": ["Huntington", "Utah", "UT"],
"84529": ["Kenilworth", "Utah", "UT"],
"84530": ["La Sal", "Utah", "UT"],
"84531": ["Mexican Hat", "Utah", "UT"],
"84532": ["Moab", "Utah", "UT"],
"84533": ["Lake Powell", "Utah", "UT"],
"84534": ["Montezuma Creek", "Utah", "UT"],
"84535": ["Monticello", "Utah", "UT"],
"84536": ["Monument Valley", "Utah", "UT"],
"84537": ["Orangeville", "Utah", "UT"],
"84539": ["Sunnyside", "Utah", "UT"],
"84540": ["Thompson", "Utah", "UT"],
"84542": ["Wellington", "Utah", "UT"],
"84601": ["Provo", "Utah", "UT"],
"84602": ["Provo", "Utah", "UT"],
"84604": ["Provo", "Utah", "UT"],
"84606": ["Provo", "Utah", "UT"],
"84620": ["Aurora", "Utah", "UT"],
"84621": ["Axtell", "Utah", "UT"],
"84622": ["Centerfield", "Utah", "UT"],
"84623": ["Chester", "Utah", "UT"],
"84624": ["Delta", "Utah", "UT"],
"84626": ["Elberta", "Utah", "UT"],
"84627": ["Ephraim", "Utah", "UT"],
"84628": ["Eureka", "Utah", "UT"],
"84629": ["Fairview", "Utah", "UT"],
"84630": ["Fayette", "Utah", "UT"],
"84631": ["Fillmore", "Utah", "UT"],
"84632": ["Fountain Green", "Utah", "UT"],
"84633": ["Goshen", "Utah", "UT"],
"84634": ["Gunnison", "Utah", "UT"],
"84635": ["Hinckley", "Utah", "UT"],
"84636": ["Holden", "Utah", "UT"],
"84637": ["Kanosh", "Utah", "UT"],
"84638": ["Leamington", "Utah", "UT"],
"84639": ["Levan", "Utah", "UT"],
"84640": ["Lynndyl", "Utah", "UT"],
"84642": ["Manti", "Utah", "UT"],
"84643": ["Mayfield", "Utah", "UT"],
"84644": ["Meadow", "Utah", "UT"],
"84645": ["Mona", "Utah", "UT"],
"84646": ["Moroni", "Utah", "UT"],
"84647": ["Mount Pleasant", "Utah", "UT"],
"84648": ["Nephi", "Utah", "UT"],
"84649": ["Oak City", "Utah", "UT"],
"84651": ["Payson", "Utah", "UT"],
"84652": ["Redmond", "Utah", "UT"],
"84653": ["Salem", "Utah", "UT"],
"84654": ["Salina", "Utah", "UT"],
"84655": ["Santaquin", "Utah", "UT"],
"84656": ["Scipio", "Utah", "UT"],
"84657": ["Sigurd", "Utah", "UT"],
"84660": ["Spanish Fork", "Utah", "UT"],
"84662": ["Spring City", "Utah", "UT"],
"84663": ["Springville", "Utah", "UT"],
"84664": ["Mapleton", "Utah", "UT"],
"84665": ["Sterling", "Utah", "UT"],
"84667": ["Wales", "Utah", "UT"],
"84701": ["Richfield", "Utah", "UT"],
"84710": ["Alton", "Utah", "UT"],
"84711": ["Annabella", "Utah", "UT"],
"84712": ["Antimony", "Utah", "UT"],
"84713": ["Beaver", "Utah", "UT"],
"84714": ["Beryl", "Utah", "UT"],
"84715": ["Bicknell", "Utah", "UT"],
"84716": ["Boulder", "Utah", "UT"],
"84718": ["Cannonville", "Utah", "UT"],
"84719": ["Brian Head", "Utah", "UT"],
"84720": ["Cedar City", "Utah", "UT"],
"84721": ["Cedar City", "Utah", "UT"],
"84722": ["Central", "Utah", "UT"],
"84723": ["Circleville", "Utah", "UT"],
"84724": ["Elsinore", "Utah", "UT"],
"84725": ["Enterprise", "Utah", "UT"],
"84726": ["Escalante", "Utah", "UT"],
"84728": ["Garrison", "Utah", "UT"],
"84729": ["Glendale", "Utah", "UT"],
"84730": ["Glenwood", "Utah", "UT"],
"84731": ["Greenville", "Utah", "UT"],
"84732": ["Greenwich", "Utah", "UT"],
"84733": ["Gunlock", "Utah", "UT"],
"84734": ["Hanksville", "Utah", "UT"],
"84735": ["Hatch", "Utah", "UT"],
"84736": ["Henrieville", "Utah", "UT"],
"84737": ["Hurricane", "Utah", "UT"],
"84738": ["Ivins", "Utah", "UT"],
"84739": ["Joseph", "Utah", "UT"],
"84740": ["Junction", "Utah", "UT"],
"84741": ["Kanab", "Utah", "UT"],
"84742": ["Kanarraville", "Utah", "UT"],
"84743": ["Kingston", "Utah", "UT"],
"84744": ["Koosharem", "Utah", "UT"],
"84745": ["La Verkin", "Utah", "UT"],
"84746": ["Leeds", "Utah", "UT"],
"84747": ["Loa", "Utah", "UT"],
"84749": ["Lyman", "Utah", "UT"],
"84750": ["Marysvale", "Utah", "UT"],
"84751": ["Milford", "Utah", "UT"],
"84752": ["Minersville", "Utah", "UT"],
"84753": ["Modena", "Utah", "UT"],
"84754": ["Monroe", "Utah", "UT"],
"84755": ["Mount Carmel", "Utah", "UT"],
"84756": ["Newcastle", "Utah", "UT"],
"84757": ["New Harmony", "Utah", "UT"],
"84758": ["Orderville", "Utah", "UT"],
"84759": ["Panguitch", "Utah", "UT"],
"84760": ["Paragonah", "Utah", "UT"],
"84761": ["Parowan", "Utah", "UT"],
"84762": ["Duck Creek Village", "Utah", "UT"],
"84763": ["Rockville", "Utah", "UT"],
"84764": ["Bryce", "Utah", "UT"],
"84765": ["Santa Clara", "Utah", "UT"],
"84766": ["Sevier", "Utah", "UT"],
"84767": ["Springdale", "Utah", "UT"],
"84770": ["Saint George", "Utah", "UT"],
"84772": ["Summit", "Utah", "UT"],
"84773": ["Teasdale", "Utah", "UT"],
"84774": ["Toquerville", "Utah", "UT"],
"84775": ["Torrey", "Utah", "UT"],
"84776": ["Tropic", "Utah", "UT"],
"84779": ["Virgin", "Utah", "UT"],
"84780": ["Washington", "Utah", "UT"],
"84781": ["Pine Valley", "Utah", "UT"],
"84782": ["Veyo", "Utah", "UT"],
"84783": ["Dammeron Valley", "Utah", "UT"],
"84784": ["Hildale", "Utah", "UT"],
"84790": ["Saint George", "Utah", "UT"],
"85003": ["Phoenix", "Arizona", "AZ"],
"85004": ["Phoenix", "Arizona", "AZ"],
"85006": ["Phoenix", "Arizona", "AZ"],
"85007": ["Phoenix", "Arizona", "AZ"],
"85008": ["Phoenix", "Arizona", "AZ"],
"85009": ["Phoenix", "Arizona", "AZ"],
"85012": ["Phoenix", "Arizona", "AZ"],
"85013": ["Phoenix", "Arizona", "AZ"],
"85014": ["Phoenix", "Arizona", "AZ"],
"85015": ["Phoenix", "Arizona", "AZ"],
"85016": ["Phoenix", "Arizona", "AZ"],
"85017": ["Phoenix", "Arizona", "AZ"],
"85018": ["Phoenix", "Arizona", "AZ"],
"85019": ["Phoenix", "Arizona", "AZ"],
"85020": ["Phoenix", "Arizona", "AZ"],
"85021": ["Phoenix", "Arizona", "AZ"],
"85022": ["Phoenix", "Arizona", "AZ"],
"85023": ["Phoenix", "Arizona", "AZ"],
"85024": ["Phoenix", "Arizona", "AZ"],
"85026": ["Phoenix", "Arizona", "AZ"],
"85027": ["Phoenix", "Arizona", "AZ"],
"85028": ["Phoenix", "Arizona", "AZ"],
"85029": ["Phoenix", "Arizona", "AZ"],
"85031": ["Phoenix", "Arizona", "AZ"],
"85032": ["Phoenix", "Arizona", "AZ"],
"85033": ["Phoenix", "Arizona", "AZ"],
"85034": ["Phoenix", "Arizona", "AZ"],
"85035": ["Phoenix", "Arizona", "AZ"],
"85037": ["Phoenix", "Arizona", "AZ"],
"85040": ["Phoenix", "Arizona", "AZ"],
"85041": ["Phoenix", "Arizona", "AZ"],
"85042": ["Phoenix", "Arizona", "AZ"],
"85043": ["Phoenix", "Arizona", "AZ"],
"85044": ["Phoenix", "Arizona", "AZ"],
"85045": ["Phoenix", "Arizona", "AZ"],
"85048": ["Phoenix", "Arizona", "AZ"],
"85050": ["Phoenix", "Arizona", "AZ"],
"85051": ["Phoenix", "Arizona", "AZ"],
"85053": ["Phoenix", "Arizona", "AZ"],
"85054": ["Phoenix", "Arizona", "AZ"],
"85083": ["Phoenix", "Arizona", "AZ"],
"85085": ["Phoenix", "Arizona", "AZ"],
"85086": ["Phoenix", "Arizona", "AZ"],
"85087": ["New River", "Arizona", "AZ"],
"85118": ["Gold Canyon", "Arizona", "AZ"],
"85119": ["Apache Junction", "Arizona", "AZ"],
"85120": ["Apache Junction", "Arizona", "AZ"],
"85121": ["Bapchule", "Arizona", "AZ"],
"85122": ["Casa Grande", "Arizona", "AZ"],
"85123": ["Arizona City", "Arizona", "AZ"],
"85128": ["Coolidge", "Arizona", "AZ"],
"85131": ["Eloy", "Arizona", "AZ"],
"85132": ["Florence", "Arizona", "AZ"],
"85135": ["Hayden", "Arizona", "AZ"],
"85137": ["Kearny", "Arizona", "AZ"],
"85138": ["Maricopa", "Arizona", "AZ"],
"85139": ["Maricopa", "Arizona", "AZ"],
"85140": ["San Tan Valley", "Arizona", "AZ"],
"85141": ["Picacho", "Arizona", "AZ"],
"85142": ["Queen Creek", "Arizona", "AZ"],
"85143": ["San Tan Valley", "Arizona", "AZ"],
"85145": ["Red Rock", "Arizona", "AZ"],
"85147": ["Sacaton", "Arizona", "AZ"],
"85172": ["Stanfield", "Arizona", "AZ"],
"85173": ["Superior", "Arizona", "AZ"],
"85191": ["Valley Farms", "Arizona", "AZ"],
"85192": ["Winkelman", "Arizona", "AZ"],
"85193": ["Casa Grande", "Arizona", "AZ"],
"85194": ["Casa Grande", "Arizona", "AZ"],
"85201": ["Mesa", "Arizona", "AZ"],
"85202": ["Mesa", "Arizona", "AZ"],
"85203": ["Mesa", "Arizona", "AZ"],
"85204": ["Mesa", "Arizona", "AZ"],
"85205": ["Mesa", "Arizona", "AZ"],
"85206": ["Mesa", "Arizona", "AZ"],
"85207": ["Mesa", "Arizona", "AZ"],
"85208": ["Mesa", "Arizona", "AZ"],
"85209": ["Mesa", "Arizona", "AZ"],
"85210": ["Mesa", "Arizona", "AZ"],
"85212": ["Mesa", "Arizona", "AZ"],
"85213": ["Mesa", "Arizona", "AZ"],
"85215": ["Mesa", "Arizona", "AZ"],
"85224": ["Chandler", "Arizona", "AZ"],
"85225": ["Chandler", "Arizona", "AZ"],
"85226": ["Chandler", "Arizona", "AZ"],
"85233": ["Gilbert", "Arizona", "AZ"],
"85234": ["Gilbert", "Arizona", "AZ"],
"85236": ["Higley", "Arizona", "AZ"],
"85248": ["Chandler", "Arizona", "AZ"],
"85249": ["Chandler", "Arizona", "AZ"],
"85250": ["Scottsdale", "Arizona", "AZ"],
"85251": ["Scottsdale", "Arizona", "AZ"],
"85253": ["Paradise Valley", "Arizona", "AZ"],
"85254": ["Scottsdale", "Arizona", "AZ"],
"85255": ["Scottsdale", "Arizona", "AZ"],
"85256": ["Scottsdale", "Arizona", "AZ"],
"85257": ["Scottsdale", "Arizona", "AZ"],
"85258": ["Scottsdale", "Arizona", "AZ"],
"85259": ["Scottsdale", "Arizona", "AZ"],
"85260": ["Scottsdale", "Arizona", "AZ"],
"85262": ["Scottsdale", "Arizona", "AZ"],
"85263": ["Rio Verde", "Arizona", "AZ"],
"85264": ["Fort Mcdowell", "Arizona", "AZ"],
"85266": ["Scottsdale", "Arizona", "AZ"],
"85268": ["Fountain Hills", "Arizona", "AZ"],
"85281": ["Tempe", "Arizona", "AZ"],
"85282": ["Tempe", "Arizona", "AZ"],
"85283": ["Tempe", "Arizona", "AZ"],
"85284": ["Tempe", "Arizona", "AZ"],
"85286": ["Chandler", "Arizona", "AZ"],
"85295": ["Gilbert", "Arizona", "AZ"],
"85296": ["Gilbert", "Arizona", "AZ"],
"85297": ["Gilbert", "Arizona", "AZ"],
"85298": ["Gilbert", "Arizona", "AZ"],
"85301": ["Glendale", "Arizona", "AZ"],
"85302": ["Glendale", "Arizona", "AZ"],
"85303": ["Glendale", "Arizona", "AZ"],
"85304": ["Glendale", "Arizona", "AZ"],
"85305": ["Glendale", "Arizona", "AZ"],
"85306": ["Glendale", "Arizona", "AZ"],
"85307": ["Glendale", "Arizona", "AZ"],
"85308": ["Glendale", "Arizona", "AZ"],
"85309": ["Luke Air Force Base", "Arizona", "AZ"],
"85310": ["Glendale", "Arizona", "AZ"],
"85320": ["Aguila", "Arizona", "AZ"],
"85321": ["Ajo", "Arizona", "AZ"],
"85322": ["Arlington", "Arizona", "AZ"],
"85323": ["Avondale", "Arizona", "AZ"],
"85324": ["Black Canyon City", "Arizona", "AZ"],
"85325": ["Bouse", "Arizona", "AZ"],
"85326": ["Buckeye", "Arizona", "AZ"],
"85328": ["Cibola", "Arizona", "AZ"],
"85329": ["Cashion", "Arizona", "AZ"],
"85331": ["Cave Creek", "Arizona", "AZ"],
"85332": ["Congress", "Arizona", "AZ"],
"85333": ["Dateland", "Arizona", "AZ"],
"85334": ["Ehrenberg", "Arizona", "AZ"],
"85335": ["El Mirage", "Arizona", "AZ"],
"85336": ["San Luis", "Arizona", "AZ"],
"85337": ["Gila Bend", "Arizona", "AZ"],
"85338": ["Goodyear", "Arizona", "AZ"],
"85339": ["Laveen", "Arizona", "AZ"],
"85340": ["Litchfield Park", "Arizona", "AZ"],
"85341": ["Lukeville", "Arizona", "AZ"],
"85342": ["Morristown", "Arizona", "AZ"],
"85343": ["Palo Verde", "Arizona", "AZ"],
"85344": ["Parker", "Arizona", "AZ"],
"85345": ["Peoria", "Arizona", "AZ"],
"85346": ["Quartzsite", "Arizona", "AZ"],
"85347": ["Roll", "Arizona", "AZ"],
"85348": ["Salome", "Arizona", "AZ"],
"85349": ["San Luis", "Arizona", "AZ"],
"85350": ["Somerton", "Arizona", "AZ"],
"85351": ["Sun City", "Arizona", "AZ"],
"85352": ["Tacna", "Arizona", "AZ"],
"85353": ["Tolleson", "Arizona", "AZ"],
"85354": ["Tonopah", "Arizona", "AZ"],
"85355": ["Waddell", "Arizona", "AZ"],
"85356": ["Wellton", "Arizona", "AZ"],
"85357": ["Wenden", "Arizona", "AZ"],
"85360": ["Wikieup", "Arizona", "AZ"],
"85361": ["Wittmann", "Arizona", "AZ"],
"85362": ["Yarnell", "Arizona", "AZ"],
"85363": ["Youngtown", "Arizona", "AZ"],
"85364": ["Yuma", "Arizona", "AZ"],
"85365": ["Yuma", "Arizona", "AZ"],
"85367": ["Yuma", "Arizona", "AZ"],
"85371": ["Poston", "Arizona", "AZ"],
"85373": ["Sun City", "Arizona", "AZ"],
"85374": ["Surprise", "Arizona", "AZ"],
"85375": ["Sun City West", "Arizona", "AZ"],
"85377": ["Carefree", "Arizona", "AZ"],
"85378": ["Surprise", "Arizona", "AZ"],
"85379": ["Surprise", "Arizona", "AZ"],
"85381": ["Peoria", "Arizona", "AZ"],
"85382": ["Peoria", "Arizona", "AZ"],
"85383": ["Peoria", "Arizona", "AZ"],
"85387": ["Surprise", "Arizona", "AZ"],
"85388": ["Surprise", "Arizona", "AZ"],
"85390": ["Wickenburg", "Arizona", "AZ"],
"85392": ["Avondale", "Arizona", "AZ"],
"85395": ["Goodyear", "Arizona", "AZ"],
"85396": ["Buckeye", "Arizona", "AZ"],
"85501": ["Globe", "Arizona", "AZ"],
"85530": ["Bylas", "Arizona", "AZ"],
"85531": ["Central", "Arizona", "AZ"],
"85532": ["Claypool", "Arizona", "AZ"],
"85533": ["Clifton", "Arizona", "AZ"],
"85534": ["Duncan", "Arizona", "AZ"],
"85535": ["Eden", "Arizona", "AZ"],
"85536": ["Fort Thomas", "Arizona", "AZ"],
"85539": ["Miami", "Arizona", "AZ"],
"85540": ["Morenci", "Arizona", "AZ"],
"85541": ["Payson", "Arizona", "AZ"],
"85542": ["Peridot", "Arizona", "AZ"],
"85543": ["Pima", "Arizona", "AZ"],
"85544": ["Pine", "Arizona", "AZ"],
"85545": ["Roosevelt", "Arizona", "AZ"],
"85546": ["Safford", "Arizona", "AZ"],
"85550": ["San Carlos", "Arizona", "AZ"],
"85551": ["Solomon", "Arizona", "AZ"],
"85552": ["Thatcher", "Arizona", "AZ"],
"85553": ["Tonto Basin", "Arizona", "AZ"],
"85554": ["Young", "Arizona", "AZ"],
"85601": ["Arivaca", "Arizona", "AZ"],
"85602": ["Benson", "Arizona", "AZ"],
"85603": ["Bisbee", "Arizona", "AZ"],
"85605": ["Bowie", "Arizona", "AZ"],
"85606": ["Cochise", "Arizona", "AZ"],
"85607": ["Douglas", "Arizona", "AZ"],
"85608": ["Douglas", "Arizona", "AZ"],
"85609": ["Dragoon", "Arizona", "AZ"],
"85610": ["Elfrida", "Arizona", "AZ"],
"85611": ["Elgin", "Arizona", "AZ"],
"85613": ["Fort Huachuca", "Arizona", "AZ"],
"85614": ["Green Valley", "Arizona", "AZ"],
"85615": ["Hereford", "Arizona", "AZ"],
"85616": ["Huachuca City", "Arizona", "AZ"],
"85617": ["McNeal", "Arizona", "AZ"],
"85618": ["Mammoth", "Arizona", "AZ"],
"85619": ["Mount Lemmon", "Arizona", "AZ"],
"85620": ["Naco", "Arizona", "AZ"],
"85621": ["Nogales", "Arizona", "AZ"],
"85622": ["Green Valley", "Arizona", "AZ"],
"85623": ["Oracle", "Arizona", "AZ"],
"85624": ["Patagonia", "Arizona", "AZ"],
"85625": ["Pearce", "Arizona", "AZ"],
"85626": ["Pirtleville", "Arizona", "AZ"],
"85627": ["Pomerene", "Arizona", "AZ"],
"85629": ["Sahuarita", "Arizona", "AZ"],
"85630": ["Saint David", "Arizona", "AZ"],
"85631": ["San Manuel", "Arizona", "AZ"],
"85632": ["San Simon", "Arizona", "AZ"],
"85633": ["Sasabe", "Arizona", "AZ"],
"85634": ["Sells", "Arizona", "AZ"],
"85635": ["Sierra Vista", "Arizona", "AZ"],
"85637": ["Sonoita", "Arizona", "AZ"],
"85638": ["Tombstone", "Arizona", "AZ"],
"85639": ["Topawa", "Arizona", "AZ"],
"85640": ["Tumacacori", "Arizona", "AZ"],
"85641": ["Vail", "Arizona", "AZ"],
"85643": ["Willcox", "Arizona", "AZ"],
"85645": ["Amado", "Arizona", "AZ"],
"85646": ["Tubac", "Arizona", "AZ"],
"85648": ["Rio Rico", "Arizona", "AZ"],
"85650": ["Sierra Vista", "Arizona", "AZ"],
"85653": ["Marana", "Arizona", "AZ"],
"85654": ["Rillito", "Arizona", "AZ"],
"85658": ["Marana", "Arizona", "AZ"],
"85701": ["Tucson", "Arizona", "AZ"],
"85704": ["Tucson", "Arizona", "AZ"],
"85705": ["Tucson", "Arizona", "AZ"],
"85706": ["Tucson", "Arizona", "AZ"],
"85707": ["Tucson", "Arizona", "AZ"],
"85708": ["Tucson", "Arizona", "AZ"],
"85709": ["Tucson", "Arizona", "AZ"],
"85710": ["Tucson", "Arizona", "AZ"],
"85711": ["Tucson", "Arizona", "AZ"],
"85712": ["Tucson", "Arizona", "AZ"],
"85713": ["Tucson", "Arizona", "AZ"],
"85714": ["Tucson", "Arizona", "AZ"],
"85715": ["Tucson", "Arizona", "AZ"],
"85716": ["Tucson", "Arizona", "AZ"],
"85718": ["Tucson", "Arizona", "AZ"],
"85719": ["Tucson", "Arizona", "AZ"],
"85721": ["Tucson", "Arizona", "AZ"],
"85723": ["Tucson", "Arizona", "AZ"],
"85724": ["Tucson", "Arizona", "AZ"],
"85726": ["Tucson", "Arizona", "AZ"],
"85730": ["Tucson", "Arizona", "AZ"],
"85734": ["Tucson", "Arizona", "AZ"],
"85735": ["Tucson", "Arizona", "AZ"],
"85736": ["Tucson", "Arizona", "AZ"],
"85737": ["Tucson", "Arizona", "AZ"],
"85739": ["Tucson", "Arizona", "AZ"],
"85741": ["Tucson", "Arizona", "AZ"],
"85742": ["Tucson", "Arizona", "AZ"],
"85743": ["Tucson", "Arizona", "AZ"],
"85745": ["Tucson", "Arizona", "AZ"],
"85746": ["Tucson", "Arizona", "AZ"],
"85747": ["Tucson", "Arizona", "AZ"],
"85748": ["Tucson", "Arizona", "AZ"],
"85749": ["Tucson", "Arizona", "AZ"],
"85750": ["Tucson", "Arizona", "AZ"],
"85755": ["Tucson", "Arizona", "AZ"],
"85756": ["Tucson", "Arizona", "AZ"],
"85757": ["Tucson", "Arizona", "AZ"],
"85901": ["Show Low", "Arizona", "AZ"],
"85902": ["Show Low", "Arizona", "AZ"],
"85911": ["Cibecue", "Arizona", "AZ"],
"85912": ["White Mountain Lake", "Arizona", "AZ"],
"85920": ["Alpine", "Arizona", "AZ"],
"85922": ["Blue", "Arizona", "AZ"],
"85923": ["Clay Springs", "Arizona", "AZ"],
"85924": ["Concho", "Arizona", "AZ"],
"85925": ["Eagar", "Arizona", "AZ"],
"85926": ["Fort Apache", "Arizona", "AZ"],
"85927": ["Greer", "Arizona", "AZ"],
"85928": ["Heber", "Arizona", "AZ"],
"85929": ["Lakeside", "Arizona", "AZ"],
"85930": ["Mcnary", "Arizona", "AZ"],
"85931": ["Forest Lakes", "Arizona", "AZ"],
"85932": ["Nutrioso", "Arizona", "AZ"],
"85933": ["Overgaard", "Arizona", "AZ"],
"85934": ["Pinedale", "Arizona", "AZ"],
"85935": ["Pinetop", "Arizona", "AZ"],
"85936": ["Saint Johns", "Arizona", "AZ"],
"85937": ["Snowflake", "Arizona", "AZ"],
"85938": ["Springerville", "Arizona", "AZ"],
"85939": ["Taylor", "Arizona", "AZ"],
"85940": ["Vernon", "Arizona", "AZ"],
"85941": ["Whiteriver", "Arizona", "AZ"],
"85942": ["Woodruff", "Arizona", "AZ"],
"86001": ["Flagstaff", "Arizona", "AZ"],
"86003": ["Flagstaff", "Arizona", "AZ"],
"86004": ["Flagstaff", "Arizona", "AZ"],
"86005": ["Flagstaff", "Arizona", "AZ"],
"86011": ["Flagstaff", "Arizona", "AZ"],
"86015": ["Bellemont", "Arizona", "AZ"],
"86016": ["Gray Mountain", "Arizona", "AZ"],
"86017": ["Munds Park", "Arizona", "AZ"],
"86018": ["Parks", "Arizona", "AZ"],
"86020": ["Cameron", "Arizona", "AZ"],
"86021": ["Colorado City", "Arizona", "AZ"],
"86022": ["Fredonia", "Arizona", "AZ"],
"86023": ["Grand Canyon", "Arizona", "AZ"],
"86024": ["Happy Jack", "Arizona", "AZ"],
"86025": ["Holbrook", "Arizona", "AZ"],
"86028": ["Petrified Forest Natl Pk", "Arizona", "AZ"],
"86029": ["Sun Valley", "Arizona", "AZ"],
"86030": ["Hotevilla", "Arizona", "AZ"],
"86031": ["Indian Wells", "Arizona", "AZ"],
"86032": ["Joseph City", "Arizona", "AZ"],
"86033": ["Kayenta", "Arizona", "AZ"],
"86034": ["Keams Canyon", "Arizona", "AZ"],
"86035": ["Leupp", "Arizona", "AZ"],
"86036": ["Marble Canyon", "Arizona", "AZ"],
"86038": ["Mormon Lake", "Arizona", "AZ"],
"86039": ["Kykotsmovi Village", "Arizona", "AZ"],
"86040": ["Page", "Arizona", "AZ"],
"86042": ["Polacca", "Arizona", "AZ"],
"86043": ["Second Mesa", "Arizona", "AZ"],
"86044": ["Tonalea", "Arizona", "AZ"],
"86045": ["Tuba City", "Arizona", "AZ"],
"86046": ["Williams", "Arizona", "AZ"],
"86047": ["Winslow", "Arizona", "AZ"],
"86052": ["North Rim", "Arizona", "AZ"],
"86053": ["Kaibeto", "Arizona", "AZ"],
"86054": ["Shonto", "Arizona", "AZ"],
"86301": ["Prescott", "Arizona", "AZ"],
"86303": ["Prescott", "Arizona", "AZ"],
"86305": ["Prescott", "Arizona", "AZ"],
"86313": ["Prescott", "Arizona", "AZ"],
"86314": ["Prescott Valley", "Arizona", "AZ"],
"86315": ["Prescott Valley", "Arizona", "AZ"],
"86320": ["Ash Fork", "Arizona", "AZ"],
"86321": ["Bagdad", "Arizona", "AZ"],
"86322": ["Camp Verde", "Arizona", "AZ"],
"86323": ["Chino Valley", "Arizona", "AZ"],
"86324": ["Clarkdale", "Arizona", "AZ"],
"86325": ["Cornville", "Arizona", "AZ"],
"86326": ["Cottonwood", "Arizona", "AZ"],
"86327": ["Dewey", "Arizona", "AZ"],
"86329": ["Humboldt", "Arizona", "AZ"],
"86331": ["Jerome", "Arizona", "AZ"],
"86332": ["Kirkland", "Arizona", "AZ"],
"86333": ["Mayer", "Arizona", "AZ"],
"86334": ["Paulden", "Arizona", "AZ"],
"86335": ["Rimrock", "Arizona", "AZ"],
"86336": ["Sedona", "Arizona", "AZ"],
"86337": ["Seligman", "Arizona", "AZ"],
"86338": ["Skull Valley", "Arizona", "AZ"],
"86343": ["Crown King", "Arizona", "AZ"],
"86351": ["Sedona", "Arizona", "AZ"],
"86401": ["Kingman", "Arizona", "AZ"],
"86403": ["Lake Havasu City", "Arizona", "AZ"],
"86404": ["Lake Havasu City", "Arizona", "AZ"],
"86406": ["Lake Havasu City", "Arizona", "AZ"],
"86409": ["Kingman", "Arizona", "AZ"],
"86411": ["Hackberry", "Arizona", "AZ"],
"86413": ["Golden Valley", "Arizona", "AZ"],
"86426": ["Fort Mohave", "Arizona", "AZ"],
"86429": ["Bullhead City", "Arizona", "AZ"],
"86431": ["Chloride", "Arizona", "AZ"],
"86432": ["Littlefield", "Arizona", "AZ"],
"86433": ["Oatman", "Arizona", "AZ"],
"86434": ["Peach Springs", "Arizona", "AZ"],
"86435": ["Supai", "Arizona", "AZ"],
"86436": ["Topock", "Arizona", "AZ"],
"86437": ["Valentine", "Arizona", "AZ"],
"86438": ["Yucca", "Arizona", "AZ"],
"86440": ["Mohave Valley", "Arizona", "AZ"],
"86441": ["Dolan Springs", "Arizona", "AZ"],
"86442": ["Bullhead City", "Arizona", "AZ"],
"86443": ["Temple Bar Marina", "Arizona", "AZ"],
"86444": ["Meadview", "Arizona", "AZ"],
"86445": ["Willow Beach", "Arizona", "AZ"],
"86502": ["Chambers", "Arizona", "AZ"],
"86503": ["Chinle", "Arizona", "AZ"],
"86504": ["Fort Defiance", "Arizona", "AZ"],
"86505": ["Ganado", "Arizona", "AZ"],
"86506": ["Houck", "Arizona", "AZ"],
"86507": ["Lukachukai", "Arizona", "AZ"],
"86508": ["Lupton", "Arizona", "AZ"],
"86510": ["Pinon", "Arizona", "AZ"],
"86511": ["Saint Michaels", "Arizona", "AZ"],
"86512": ["Sanders", "Arizona", "AZ"],
"86514": ["Teec Nos Pos", "Arizona", "AZ"],
"86515": ["Window Rock", "Arizona", "AZ"],
"86520": ["Blue Gap", "Arizona", "AZ"],
"86535": ["Dennehotso", "Arizona", "AZ"],
"86538": ["Many Farms", "Arizona", "AZ"],
"86540": ["Nazlini", "Arizona", "AZ"],
"86544": ["Red Valley", "Arizona", "AZ"],
"86545": ["Rock Point", "Arizona", "AZ"],
"86547": ["Round Rock", "Arizona", "AZ"],
"86556": ["Tsaile", "Arizona", "AZ"],
"87001": ["Algodones", "New Mexico", "NM"],
"87002": ["Belen", "New Mexico", "NM"],
"87004": ["Bernalillo", "New Mexico", "NM"],
"87005": ["Bluewater", "New Mexico", "NM"],
"87006": ["Bosque", "New Mexico", "NM"],
"87007": ["Casa Blanca", "New Mexico", "NM"],
"87008": ["Cedar Crest", "New Mexico", "NM"],
"87010": ["Cerrillos", "New Mexico", "NM"],
"87011": ["Claunch", "New Mexico", "NM"],
"87012": ["Coyote", "New Mexico", "NM"],
"87013": ["Cuba", "New Mexico", "NM"],
"87014": ["Cubero", "New Mexico", "NM"],
"87015": ["Edgewood", "New Mexico", "NM"],
"87016": ["Estancia", "New Mexico", "NM"],
"87017": ["Gallina", "New Mexico", "NM"],
"87018": ["Counselor", "New Mexico", "NM"],
"87020": ["Grants", "New Mexico", "NM"],
"87021": ["Milan", "New Mexico", "NM"],
"87022": ["Isleta", "New Mexico", "NM"],
"87023": ["Jarales", "New Mexico", "NM"],
"87024": ["Jemez Pueblo", "New Mexico", "NM"],
"87025": ["Jemez Springs", "New Mexico", "NM"],
"87026": ["Laguna", "New Mexico", "NM"],
"87027": ["La Jara", "New Mexico", "NM"],
"87028": ["La Joya", "New Mexico", "NM"],
"87029": ["Lindrith", "New Mexico", "NM"],
"87031": ["Los Lunas", "New Mexico", "NM"],
"87032": ["Mcintosh", "New Mexico", "NM"],
"87034": ["Pueblo Of Acoma", "New Mexico", "NM"],
"87035": ["Moriarty", "New Mexico", "NM"],
"87036": ["Mountainair", "New Mexico", "NM"],
"87037": ["Nageezi", "New Mexico", "NM"],
"87038": ["New Laguna", "New Mexico", "NM"],
"87040": ["Paguate", "New Mexico", "NM"],
"87041": ["Pena Blanca", "New Mexico", "NM"],
"87042": ["Peralta", "New Mexico", "NM"],
"87043": ["Placitas", "New Mexico", "NM"],
"87044": ["Ponderosa", "New Mexico", "NM"],
"87045": ["Prewitt", "New Mexico", "NM"],
"87046": ["Regina", "New Mexico", "NM"],
"87047": ["Sandia Park", "New Mexico", "NM"],
"87048": ["Corrales", "New Mexico", "NM"],
"87049": ["San Fidel", "New Mexico", "NM"],
"87051": ["San Rafael", "New Mexico", "NM"],
"87052": ["Santo Domingo Pueblo", "New Mexico", "NM"],
"87053": ["San Ysidro", "New Mexico", "NM"],
"87056": ["Stanley", "New Mexico", "NM"],
"87059": ["Tijeras", "New Mexico", "NM"],
"87060": ["Tome", "New Mexico", "NM"],
"87061": ["Torreon", "New Mexico", "NM"],
"87062": ["Veguita", "New Mexico", "NM"],
"87063": ["Willard", "New Mexico", "NM"],
"87064": ["Youngsville", "New Mexico", "NM"],
"87068": ["Bosque Farms", "New Mexico", "NM"],
"87070": ["Clines Corners", "New Mexico", "NM"],
"87072": ["Cochiti Pueblo", "New Mexico", "NM"],
"87083": ["Cochiti Lake", "New Mexico", "NM"],
"87101": ["Albuquerque", "New Mexico", "NM"],
"87102": ["Albuquerque", "New Mexico", "NM"],
"87104": ["Albuquerque", "New Mexico", "NM"],
"87105": ["Albuquerque", "New Mexico", "NM"],
"87106": ["Albuquerque", "New Mexico", "NM"],
"87107": ["Albuquerque", "New Mexico", "NM"],
"87108": ["Albuquerque", "New Mexico", "NM"],
"87109": ["Albuquerque", "New Mexico", "NM"],
"87110": ["Albuquerque", "New Mexico", "NM"],
"87111": ["Albuquerque", "New Mexico", "NM"],
"87112": ["Albuquerque", "New Mexico", "NM"],
"87113": ["Albuquerque", "New Mexico", "NM"],
"87114": ["Albuquerque", "New Mexico", "NM"],
"87116": ["Albuquerque", "New Mexico", "NM"],
"87117": ["Kirtland Afb", "New Mexico", "NM"],
"87120": ["Albuquerque", "New Mexico", "NM"],
"87121": ["Albuquerque", "New Mexico", "NM"],
"87122": ["Albuquerque", "New Mexico", "NM"],
"87123": ["Albuquerque", "New Mexico", "NM"],
"87124": ["Rio Rancho", "New Mexico", "NM"],
"87144": ["Rio Rancho", "New Mexico", "NM"],
"87151": ["Albuquerque", "New Mexico", "NM"],
"87301": ["Gallup", "New Mexico", "NM"],
"87305": ["Gallup", "New Mexico", "NM"],
"87310": ["Brimhall", "New Mexico", "NM"],
"87311": ["Church Rock", "New Mexico", "NM"],
"87312": ["Continental Divide", "New Mexico", "NM"],
"87313": ["Crownpoint", "New Mexico", "NM"],
"87315": ["Fence Lake", "New Mexico", "NM"],
"87316": ["Fort Wingate", "New Mexico", "NM"],
"87317": ["Gamerco", "New Mexico", "NM"],
"87319": ["Mentmore", "New Mexico", "NM"],
"87320": ["Mexican Springs", "New Mexico", "NM"],
"87321": ["Ramah", "New Mexico", "NM"],
"87322": ["Rehoboth", "New Mexico", "NM"],
"87323": ["Thoreau", "New Mexico", "NM"],
"87325": ["Tohatchi", "New Mexico", "NM"],
"87326": ["Vanderwagen", "New Mexico", "NM"],
"87327": ["Zuni", "New Mexico", "NM"],
"87328": ["Navajo", "New Mexico", "NM"],
"87347": ["Jamestown", "New Mexico", "NM"],
"87357": ["Pinehill", "New Mexico", "NM"],
"87364": ["Sheep Springs", "New Mexico", "NM"],
"87365": ["Smith Lake", "New Mexico", "NM"],
"87375": ["Yatahey", "New Mexico", "NM"],
"87401": ["Farmington", "New Mexico", "NM"],
"87402": ["Farmington", "New Mexico", "NM"],
"87410": ["Aztec", "New Mexico", "NM"],
"87412": ["Blanco", "New Mexico", "NM"],
"87413": ["Bloomfield", "New Mexico", "NM"],
"87415": ["Flora Vista", "New Mexico", "NM"],
"87416": ["Fruitland", "New Mexico", "NM"],
"87417": ["Kirtland", "New Mexico", "NM"],
"87418": ["La Plata", "New Mexico", "NM"],
"87419": ["Navajo Dam", "New Mexico", "NM"],
"87420": ["Shiprock", "New Mexico", "NM"],
"87421": ["Waterflow", "New Mexico", "NM"],
"87455": ["Newcomb", "New Mexico", "NM"],
"87461": ["Sanostee", "New Mexico", "NM"],
"87499": ["Farmington", "New Mexico", "NM"],
"87501": ["Santa Fe", "New Mexico", "NM"],
"87505": ["Santa Fe", "New Mexico", "NM"],
"87506": ["Santa Fe", "New Mexico", "NM"],
"87507": ["Santa Fe", "New Mexico", "NM"],
"87508": ["Santa Fe", "New Mexico", "NM"],
"87510": ["Abiquiu", "New Mexico", "NM"],
"87511": ["Alcalde", "New Mexico", "NM"],
"87512": ["Amalia", "New Mexico", "NM"],
"87513": ["Arroyo Hondo", "New Mexico", "NM"],
"87514": ["Arroyo Seco", "New Mexico", "NM"],
"87515": ["Canjilon", "New Mexico", "NM"],
"87516": ["Canones", "New Mexico", "NM"],
"87517": ["Carson", "New Mexico", "NM"],
"87518": ["Cebolla", "New Mexico", "NM"],
"87519": ["Cerro", "New Mexico", "NM"],
"87520": ["Chama", "New Mexico", "NM"],
"87521": ["Chamisal", "New Mexico", "NM"],
"87522": ["Chimayo", "New Mexico", "NM"],
"87523": ["Cordova", "New Mexico", "NM"],
"87524": ["Costilla", "New Mexico", "NM"],
"87525": ["Taos Ski Valley", "New Mexico", "NM"],
"87527": ["Dixon", "New Mexico", "NM"],
"87528": ["Dulce", "New Mexico", "NM"],
"87529": ["El Prado", "New Mexico", "NM"],
"87530": ["El Rito", "New Mexico", "NM"],
"87531": ["Embudo", "New Mexico", "NM"],
"87532": ["Espanola", "New Mexico", "NM"],
"87535": ["Glorieta", "New Mexico", "NM"],
"87537": ["Hernandez", "New Mexico", "NM"],
"87538": ["Ilfeld", "New Mexico", "NM"],
"87539": ["La Madera", "New Mexico", "NM"],
"87540": ["Lamy", "New Mexico", "NM"],
"87543": ["Llano", "New Mexico", "NM"],
"87544": ["Los Alamos", "New Mexico", "NM"],
"87547": ["White Rock", "New Mexico", "NM"],
"87548": ["Medanales", "New Mexico", "NM"],
"87549": ["Ojo Caliente", "New Mexico", "NM"],
"87551": ["Los Ojos", "New Mexico", "NM"],
"87552": ["Pecos", "New Mexico", "NM"],
"87553": ["Penasco", "New Mexico", "NM"],
"87554": ["Petaca", "New Mexico", "NM"],
"87556": ["Questa", "New Mexico", "NM"],
"87557": ["Ranchos De Taos", "New Mexico", "NM"],
"87558": ["Red River", "New Mexico", "NM"],
"87560": ["Ribera", "New Mexico", "NM"],
"87562": ["Rowe", "New Mexico", "NM"],
"87564": ["San Cristobal", "New Mexico", "NM"],
"87565": ["San Jose", "New Mexico", "NM"],
"87566": ["Ohkay Owingeh", "New Mexico", "NM"],
"87567": ["Santa Cruz", "New Mexico", "NM"],
"87569": ["Serafina", "New Mexico", "NM"],
"87571": ["Taos", "New Mexico", "NM"],
"87573": ["Tererro", "New Mexico", "NM"],
"87575": ["Tierra Amarilla", "New Mexico", "NM"],
"87577": ["Tres Piedras", "New Mexico", "NM"],
"87578": ["Truchas", "New Mexico", "NM"],
"87579": ["Vadito", "New Mexico", "NM"],
"87580": ["Valdez", "New Mexico", "NM"],
"87581": ["Vallecitos", "New Mexico", "NM"],
"87582": ["Velarde", "New Mexico", "NM"],
"87583": ["Villanueva", "New Mexico", "NM"],
"87701": ["Las Vegas", "New Mexico", "NM"],
"87710": ["Angel Fire", "New Mexico", "NM"],
"87711": ["Anton Chico", "New Mexico", "NM"],
"87712": ["Buena Vista", "New Mexico", "NM"],
"87713": ["Chacon", "New Mexico", "NM"],
"87714": ["Cimarron", "New Mexico", "NM"],
"87715": ["Cleveland", "New Mexico", "NM"],
"87718": ["Eagle Nest", "New Mexico", "NM"],
"87722": ["Guadalupita", "New Mexico", "NM"],
"87723": ["Holman", "New Mexico", "NM"],
"87724": ["La Loma", "New Mexico", "NM"],
"87728": ["Maxwell", "New Mexico", "NM"],
"87729": ["Miami", "New Mexico", "NM"],
"87730": ["Mills", "New Mexico", "NM"],
"87731": ["Montezuma", "New Mexico", "NM"],
"87732": ["Mora", "New Mexico", "NM"],
"87733": ["Mosquero", "New Mexico", "NM"],
"87734": ["Ocate", "New Mexico", "NM"],
"87735": ["Ojo Feliz", "New Mexico", "NM"],
"87736": ["Rainsville", "New Mexico", "NM"],
"87740": ["Raton", "New Mexico", "NM"],
"87742": ["Rociada", "New Mexico", "NM"],
"87743": ["Roy", "New Mexico", "NM"],
"87745": ["Sapello", "New Mexico", "NM"],
"87746": ["Solano", "New Mexico", "NM"],
"87747": ["Springer", "New Mexico", "NM"],
"87749": ["Ute Park", "New Mexico", "NM"],
"87750": ["Valmora", "New Mexico", "NM"],
"87752": ["Wagon Mound", "New Mexico", "NM"],
"87753": ["Watrous", "New Mexico", "NM"],
"87801": ["Socorro", "New Mexico", "NM"],
"87820": ["Aragon", "New Mexico", "NM"],
"87821": ["Datil", "New Mexico", "NM"],
"87823": ["Lemitar", "New Mexico", "NM"],
"87824": ["Luna", "New Mexico", "NM"],
"87825": ["Magdalena", "New Mexico", "NM"],
"87827": ["Pie Town", "New Mexico", "NM"],
"87828": ["Polvadera", "New Mexico", "NM"],
"87829": ["Quemado", "New Mexico", "NM"],
"87830": ["Reserve", "New Mexico", "NM"],
"87831": ["San Acacia", "New Mexico", "NM"],
"87832": ["San Antonio", "New Mexico", "NM"],
"87901": ["Truth Or Consequences", "New Mexico", "NM"],
"87930": ["Arrey", "New Mexico", "NM"],
"87931": ["Caballo", "New Mexico", "NM"],
"87933": ["Derry", "New Mexico", "NM"],
"87935": ["Elephant Butte", "New Mexico", "NM"],
"87936": ["Garfield", "New Mexico", "NM"],
"87937": ["Hatch", "New Mexico", "NM"],
"87939": ["Monticello", "New Mexico", "NM"],
"87940": ["Rincon", "New Mexico", "NM"],
"87941": ["Salem", "New Mexico", "NM"],
"87942": ["Williamsburg", "New Mexico", "NM"],
"87943": ["Winston", "New Mexico", "NM"],
"88001": ["Las Cruces", "New Mexico", "NM"],
"88002": ["White Sands Missile Range", "New Mexico", "NM"],
"88003": ["Las Cruces", "New Mexico", "NM"],
"88004": ["Las Cruces", "New Mexico", "NM"],
"88005": ["Las Cruces", "New Mexico", "NM"],
"88007": ["Las Cruces", "New Mexico", "NM"],
"88008": ["Santa Teresa", "New Mexico", "NM"],
"88009": ["Playas", "New Mexico", "NM"],
"88011": ["Las Cruces", "New Mexico", "NM"],
"88012": ["Las Cruces", "New Mexico", "NM"],
"88020": ["Animas", "New Mexico", "NM"],
"88021": ["Anthony", "New Mexico", "NM"],
"88022": ["Arenas Valley", "New Mexico", "NM"],
"88023": ["Bayard", "New Mexico", "NM"],
"88024": ["Berino", "New Mexico", "NM"],
"88025": ["Buckhorn", "New Mexico", "NM"],
"88026": ["Santa Clara", "New Mexico", "NM"],
"88027": ["Chamberino", "New Mexico", "NM"],
"88028": ["Cliff", "New Mexico", "NM"],
"88029": ["Columbus", "New Mexico", "NM"],
"88030": ["Deming", "New Mexico", "NM"],
"88034": ["Faywood", "New Mexico", "NM"],
"88038": ["Gila", "New Mexico", "NM"],
"88039": ["Glenwood", "New Mexico", "NM"],
"88040": ["Hachita", "New Mexico", "NM"],
"88041": ["Hanover", "New Mexico", "NM"],
"88042": ["Hillsboro", "New Mexico", "NM"],
"88043": ["Hurley", "New Mexico", "NM"],
"88044": ["La Mesa", "New Mexico", "NM"],
"88045": ["Lordsburg", "New Mexico", "NM"],
"88046": ["Mesilla", "New Mexico", "NM"],
"88047": ["Mesilla Park", "New Mexico", "NM"],
"88048": ["Mesquite", "New Mexico", "NM"],
"88049": ["Mimbres", "New Mexico", "NM"],
"88051": ["Mule Creek", "New Mexico", "NM"],
"88052": ["Organ", "New Mexico", "NM"],
"88053": ["Pinos Altos", "New Mexico", "NM"],
"88055": ["Redrock", "New Mexico", "NM"],
"88056": ["Rodeo", "New Mexico", "NM"],
"88061": ["Silver City", "New Mexico", "NM"],
"88063": ["Sunland Park", "New Mexico", "NM"],
"88065": ["Tyrone", "New Mexico", "NM"],
"88072": ["Vado", "New Mexico", "NM"],
"88081": ["Chaparral", "New Mexico", "NM"],
"88101": ["Clovis", "New Mexico", "NM"],
"88103": ["Cannon Afb", "New Mexico", "NM"],
"88112": ["Broadview", "New Mexico", "NM"],
"88113": ["Causey", "New Mexico", "NM"],
"88114": ["Crossroads", "New Mexico", "NM"],
"88115": ["Dora", "New Mexico", "NM"],
"88116": ["Elida", "New Mexico", "NM"],
"88118": ["Floyd", "New Mexico", "NM"],
"88119": ["Fort Sumner", "New Mexico", "NM"],
"88120": ["Grady", "New Mexico", "NM"],
"88121": ["House", "New Mexico", "NM"],
"88124": ["Melrose", "New Mexico", "NM"],
"88125": ["Milnesand", "New Mexico", "NM"],
"88126": ["Pep", "New Mexico", "NM"],
"88130": ["Portales", "New Mexico", "NM"],
"88132": ["Rogers", "New Mexico", "NM"],
"88134": ["Taiban", "New Mexico", "NM"],
"88135": ["Texico", "New Mexico", "NM"],
"88136": ["Yeso", "New Mexico", "NM"],
"88201": ["Roswell", "New Mexico", "NM"],
"88203": ["Roswell", "New Mexico", "NM"],
"88210": ["Artesia", "New Mexico", "NM"],
"88220": ["Carlsbad", "New Mexico", "NM"],
"88230": ["Dexter", "New Mexico", "NM"],
"88231": ["Eunice", "New Mexico", "NM"],
"88232": ["Hagerman", "New Mexico", "NM"],
"88240": ["Hobbs", "New Mexico", "NM"],
"88242": ["Hobbs", "New Mexico", "NM"],
"88244": ["Hobbs", "New Mexico", "NM"],
"88250": ["Hope", "New Mexico", "NM"],
"88252": ["Jal", "New Mexico", "NM"],
"88253": ["Lake Arthur", "New Mexico", "NM"],
"88254": ["Lakewood", "New Mexico", "NM"],
"88255": ["Loco Hills", "New Mexico", "NM"],
"88256": ["Loving", "New Mexico", "NM"],
"88260": ["Lovington", "New Mexico", "NM"],
"88262": ["Mcdonald", "New Mexico", "NM"],
"88263": ["Malaga", "New Mexico", "NM"],
"88264": ["Maljamar", "New Mexico", "NM"],
"88265": ["Monument", "New Mexico", "NM"],
"88267": ["Tatum", "New Mexico", "NM"],
"88268": ["Whites City", "New Mexico", "NM"],
"88301": ["Carrizozo", "New Mexico", "NM"],
"88310": ["Alamogordo", "New Mexico", "NM"],
"88311": ["Alamogordo", "New Mexico", "NM"],
"88312": ["Alto", "New Mexico", "NM"],
"88314": ["Bent", "New Mexico", "NM"],
"88316": ["Capitan", "New Mexico", "NM"],
"88317": ["Cloudcroft", "New Mexico", "NM"],
"88318": ["Corona", "New Mexico", "NM"],
"88321": ["Encino", "New Mexico", "NM"],
"88323": ["Fort Stanton", "New Mexico", "NM"],
"88324": ["Glencoe", "New Mexico", "NM"],
"88325": ["High Rolls Mountain Park", "New Mexico", "NM"],
"88330": ["Holloman Air Force Base", "New Mexico", "NM"],
"88336": ["Hondo", "New Mexico", "NM"],
"88337": ["La Luz", "New Mexico", "NM"],
"88338": ["Lincoln", "New Mexico", "NM"],
"88339": ["Mayhill", "New Mexico", "NM"],
"88340": ["Mescalero", "New Mexico", "NM"],
"88341": ["Nogal", "New Mexico", "NM"],
"88342": ["Orogrande", "New Mexico", "NM"],
"88343": ["Picacho", "New Mexico", "NM"],
"88344": ["Pinon", "New Mexico", "NM"],
"88345": ["Ruidoso", "New Mexico", "NM"],
"88346": ["Ruidoso Downs", "New Mexico", "NM"],
"88347": ["Sacramento", "New Mexico", "NM"],
"88348": ["San Patricio", "New Mexico", "NM"],
"88349": ["Sunspot", "New Mexico", "NM"],
"88350": ["Timberon", "New Mexico", "NM"],
"88351": ["Tinnie", "New Mexico", "NM"],
"88352": ["Tularosa", "New Mexico", "NM"],
"88353": ["Vaughn", "New Mexico", "NM"],
"88354": ["Weed", "New Mexico", "NM"],
"88355": ["Ruidoso", "New Mexico", "NM"],
"88401": ["Tucumcari", "New Mexico", "NM"],
"88410": ["Amistad", "New Mexico", "NM"],
"88411": ["Bard", "New Mexico", "NM"],
"88414": ["Capulin", "New Mexico", "NM"],
"88415": ["Clayton", "New Mexico", "NM"],
"88416": ["Conchas Dam", "New Mexico", "NM"],
"88417": ["Cuervo", "New Mexico", "NM"],
"88418": ["Des Moines", "New Mexico", "NM"],
"88419": ["Folsom", "New Mexico", "NM"],
"88421": ["Garita", "New Mexico", "NM"],
"88422": ["Gladstone", "New Mexico", "NM"],
"88424": ["Grenville", "New Mexico", "NM"],
"88426": ["Logan", "New Mexico", "NM"],
"88427": ["Mcalister", "New Mexico", "NM"],
"88430": ["Nara Visa", "New Mexico", "NM"],
"88431": ["Newkirk", "New Mexico", "NM"],
"88434": ["San Jon", "New Mexico", "NM"],
"88435": ["Santa Rosa", "New Mexico", "NM"],
"88436": ["Sedan", "New Mexico", "NM"],
"88439": ["Trementina", "New Mexico", "NM"],
"89001": ["Alamo", "Nevada", "NV"],
"89002": ["Henderson", "Nevada", "NV"],
"89003": ["Beatty", "Nevada", "NV"],
"89004": ["Blue Diamond", "Nevada", "NV"],
"89005": ["Boulder City", "Nevada", "NV"],
"89007": ["Bunkerville", "Nevada", "NV"],
"89008": ["Caliente", "Nevada", "NV"],
"89010": ["Dyer", "Nevada", "NV"],
"89011": ["Henderson", "Nevada", "NV"],
"89012": ["Henderson", "Nevada", "NV"],
"89013": ["Goldfield", "Nevada", "NV"],
"89014": ["Henderson", "Nevada", "NV"],
"89015": ["Henderson", "Nevada", "NV"],
"89017": ["Hiko", "Nevada", "NV"],
"89018": ["Indian Springs", "Nevada", "NV"],
"89019": ["Jean", "Nevada", "NV"],
"89020": ["Amargosa Valley", "Nevada", "NV"],
"89021": ["Logandale", "Nevada", "NV"],
"89022": ["Manhattan", "Nevada", "NV"],
"89025": ["Moapa", "Nevada", "NV"],
"89026": ["Jean", "Nevada", "NV"],
"89027": ["Mesquite", "Nevada", "NV"],
"89029": ["Laughlin", "Nevada", "NV"],
"89030": ["North Las Vegas", "Nevada", "NV"],
"89031": ["North Las Vegas", "Nevada", "NV"],
"89032": ["North Las Vegas", "Nevada", "NV"],
"89034": ["Mesquite", "Nevada", "NV"],
"89039": ["Cal Nev Ari", "Nevada", "NV"],
"89040": ["Overton", "Nevada", "NV"],
"89042": ["Panaca", "Nevada", "NV"],
"89043": ["Pioche", "Nevada", "NV"],
"89044": ["Henderson", "Nevada", "NV"],
"89045": ["Round Mountain", "Nevada", "NV"],
"89046": ["Searchlight", "Nevada", "NV"],
"89047": ["Silverpeak", "Nevada", "NV"],
"89048": ["Pahrump", "Nevada", "NV"],
"89049": ["Tonopah", "Nevada", "NV"],
"89052": ["Henderson", "Nevada", "NV"],
"89054": ["Sloan", "Nevada", "NV"],
"89060": ["Pahrump", "Nevada", "NV"],
"89061": ["Pahrump", "Nevada", "NV"],
"89070": ["Indian Springs", "Nevada", "NV"],
"89074": ["Henderson", "Nevada", "NV"],
"89081": ["North Las Vegas", "Nevada", "NV"],
"89084": ["North Las Vegas", "Nevada", "NV"],
"89085": ["North Las Vegas", "Nevada", "NV"],
"89086": ["North Las Vegas", "Nevada", "NV"],
"89101": ["Las Vegas", "Nevada", "NV"],
"89102": ["Las Vegas", "Nevada", "NV"],
"89103": ["Las Vegas", "Nevada", "NV"],
"89104": ["Las Vegas", "Nevada", "NV"],
"89106": ["Las Vegas", "Nevada", "NV"],
"89107": ["Las Vegas", "Nevada", "NV"],
"89108": ["Las Vegas", "Nevada", "NV"],
"89109": ["Las Vegas", "Nevada", "NV"],
"89110": ["Las Vegas", "Nevada", "NV"],
"89113": ["Las Vegas", "Nevada", "NV"],
"89115": ["Las Vegas", "Nevada", "NV"],
"89117": ["Las Vegas", "Nevada", "NV"],
"89118": ["Las Vegas", "Nevada", "NV"],
"89119": ["Las Vegas", "Nevada", "NV"],
"89120": ["Las Vegas", "Nevada", "NV"],
"89121": ["Las Vegas", "Nevada", "NV"],
"89122": ["Las Vegas", "Nevada", "NV"],
"89123": ["Las Vegas", "Nevada", "NV"],
"89124": ["Las Vegas", "Nevada", "NV"],
"89128": ["Las Vegas", "Nevada", "NV"],
"89129": ["Las Vegas", "Nevada", "NV"],
"89130": ["Las Vegas", "Nevada", "NV"],
"89131": ["Las Vegas", "Nevada", "NV"],
"89134": ["Las Vegas", "Nevada", "NV"],
"89135": ["Las Vegas", "Nevada", "NV"],
"89138": ["Las Vegas", "Nevada", "NV"],
"89139": ["Las Vegas", "Nevada", "NV"],
"89141": ["Las Vegas", "Nevada", "NV"],
"89142": ["Las Vegas", "Nevada", "NV"],
"89143": ["Las Vegas", "Nevada", "NV"],
"89144": ["Las Vegas", "Nevada", "NV"],
"89145": ["Las Vegas", "Nevada", "NV"],
"89146": ["Las Vegas", "Nevada", "NV"],
"89147": ["Las Vegas", "Nevada", "NV"],
"89148": ["Las Vegas", "Nevada", "NV"],
"89149": ["Las Vegas", "Nevada", "NV"],
"89156": ["Las Vegas", "Nevada", "NV"],
"89158": ["Las Vegas", "Nevada", "NV"],
"89161": ["Las Vegas", "Nevada", "NV"],
"89166": ["Las Vegas", "Nevada", "NV"],
"89169": ["Las Vegas", "Nevada", "NV"],
"89178": ["Las Vegas", "Nevada", "NV"],
"89179": ["Las Vegas", "Nevada", "NV"],
"89183": ["Las Vegas", "Nevada", "NV"],
"89191": ["Nellis Afb", "Nevada", "NV"],
"89301": ["Ely", "Nevada", "NV"],
"89310": ["Austin", "Nevada", "NV"],
"89311": ["Baker", "Nevada", "NV"],
"89314": ["Duckwater", "Nevada", "NV"],
"89315": ["Ely", "Nevada", "NV"],
"89316": ["Eureka", "Nevada", "NV"],
"89317": ["Lund", "Nevada", "NV"],
"89318": ["McGill", "Nevada", "NV"],
"89319": ["Ruth", "Nevada", "NV"],
"89402": ["Crystal Bay", "Nevada", "NV"],
"89403": ["Dayton", "Nevada", "NV"],
"89404": ["Denio", "Nevada", "NV"],
"89405": ["Empire", "Nevada", "NV"],
"89406": ["Fallon", "Nevada", "NV"],
"89408": ["Fernley", "Nevada", "NV"],
"89409": ["Gabbs", "Nevada", "NV"],
"89410": ["Gardnerville", "Nevada", "NV"],
"89411": ["Genoa", "Nevada", "NV"],
"89412": ["Gerlach", "Nevada", "NV"],
"89413": ["Glenbrook", "Nevada", "NV"],
"89414": ["Golconda", "Nevada", "NV"],
"89415": ["Hawthorne", "Nevada", "NV"],
"89418": ["Imlay", "Nevada", "NV"],
"89419": ["Lovelock", "Nevada", "NV"],
"89420": ["Luning", "Nevada", "NV"],
"89421": ["McDermitt", "Nevada", "NV"],
"89422": ["Mina", "Nevada", "NV"],
"89423": ["Minden", "Nevada", "NV"],
"89424": ["Nixon", "Nevada", "NV"],
"89425": ["Orovada", "Nevada", "NV"],
"89426": ["Paradise Valley", "Nevada", "NV"],
"89427": ["Schurz", "Nevada", "NV"],
"89428": ["Silver City", "Nevada", "NV"],
"89429": ["Silver Springs", "Nevada", "NV"],
"89430": ["Smith", "Nevada", "NV"],
"89431": ["Sparks", "Nevada", "NV"],
"89433": ["Sun Valley", "Nevada", "NV"],
"89434": ["Sparks", "Nevada", "NV"],
"89436": ["Sparks", "Nevada", "NV"],
"89437": ["Sparks", "Nevada", "NV"],
"89438": ["Valmy", "Nevada", "NV"],
"89439": ["Verdi", "Nevada", "NV"],
"89440": ["Virginia City", "Nevada", "NV"],
"89441": ["Sparks", "Nevada", "NV"],
"89442": ["Wadsworth", "Nevada", "NV"],
"89444": ["Wellington", "Nevada", "NV"],
"89445": ["Winnemucca", "Nevada", "NV"],
"89446": ["Winnemucca", "Nevada", "NV"],
"89447": ["Yerington", "Nevada", "NV"],
"89448": ["Zephyr Cove", "Nevada", "NV"],
"89449": ["Stateline", "Nevada", "NV"],
"89450": ["Incline Village", "Nevada", "NV"],
"89451": ["Incline Village", "Nevada", "NV"],
"89460": ["Gardnerville", "Nevada", "NV"],
"89496": ["Fallon", "Nevada", "NV"],
"89501": ["Reno", "Nevada", "NV"],
"89502": ["Reno", "Nevada", "NV"],
"89503": ["Reno", "Nevada", "NV"],
"89506": ["Reno", "Nevada", "NV"],
"89508": ["Reno", "Nevada", "NV"],
"89509": ["Reno", "Nevada", "NV"],
"89510": ["Reno", "Nevada", "NV"],
"89511": ["Reno", "Nevada", "NV"],
"89512": ["Reno", "Nevada", "NV"],
"89519": ["Reno", "Nevada", "NV"],
"89521": ["Reno", "Nevada", "NV"],
"89523": ["Reno", "Nevada", "NV"],
"89557": ["Reno", "Nevada", "NV"],
"89701": ["Carson City", "Nevada", "NV"],
"89703": ["Carson City", "Nevada", "NV"],
"89704": ["Washoe Valley", "Nevada", "NV"],
"89705": ["Carson City", "Nevada", "NV"],
"89706": ["Carson City", "Nevada", "NV"],
"89801": ["Elko", "Nevada", "NV"],
"89815": ["Spring Creek", "Nevada", "NV"],
"89820": ["Battle Mountain", "Nevada", "NV"],
"89821": ["Crescent Valley", "Nevada", "NV"],
"89822": ["Carlin", "Nevada", "NV"],
"89823": ["Deeth", "Nevada", "NV"],
"89825": ["Jackpot", "Nevada", "NV"],
"89826": ["Jarbidge", "Nevada", "NV"],
"89828": ["Lamoille", "Nevada", "NV"],
"89830": ["Montello", "Nevada", "NV"],
"89831": ["Mountain City", "Nevada", "NV"],
"89832": ["Owyhee", "Nevada", "NV"],
"89833": ["Ruby Valley", "Nevada", "NV"],
"89834": ["Tuscarora", "Nevada", "NV"],
"89835": ["Wells", "Nevada", "NV"],
"89883": ["West Wendover", "Nevada", "NV"],
"90001": ["Los Angeles", "California", "CA"],
"90002": ["Los Angeles", "California", "CA"],
"90003": ["Los Angeles", "California", "CA"],
"90004": ["Los Angeles", "California", "CA"],
"90005": ["Los Angeles", "California", "CA"],
"90006": ["Los Angeles", "California", "CA"],
"90007": ["Los Angeles", "California", "CA"],
"90008": ["Los Angeles", "California", "CA"],
"90010": ["Los Angeles", "California", "CA"],
"90011": ["Los Angeles", "California", "CA"],
"90012": ["Los Angeles", "California", "CA"],
"90013": ["Los Angeles", "California", "CA"],
"90014": ["Los Angeles", "California", "CA"],
"90015": ["Los Angeles", "California", "CA"],
"90016": ["Los Angeles", "California", "CA"],
"90017": ["Los Angeles", "California", "CA"],
"90018": ["Los Angeles", "California", "CA"],
"90019": ["Los Angeles", "California", "CA"],
"90020": ["Los Angeles", "California", "CA"],
"90021": ["Los Angeles", "California", "CA"],
"90022": ["Los Angeles", "California", "CA"],
"90023": ["Los Angeles", "California", "CA"],
"90024": ["Los Angeles", "California", "CA"],
"90025": ["Los Angeles", "California", "CA"],
"90026": ["Los Angeles", "California", "CA"],
"90027": ["Los Angeles", "California", "CA"],
"90028": ["Los Angeles", "California", "CA"],
"90029": ["Los Angeles", "California", "CA"],
"90031": ["Los Angeles", "California", "CA"],
"90032": ["Los Angeles", "California", "CA"],
"90033": ["Los Angeles", "California", "CA"],
"90034": ["Los Angeles", "California", "CA"],
"90035": ["Los Angeles", "California", "CA"],
"90036": ["Los Angeles", "California", "CA"],
"90037": ["Los Angeles", "California", "CA"],
"90038": ["Los Angeles", "California", "CA"],
"90039": ["Los Angeles", "California", "CA"],
"90040": ["Los Angeles", "California", "CA"],
"90041": ["Los Angeles", "California", "CA"],
"90042": ["Los Angeles", "California", "CA"],
"90043": ["Los Angeles", "California", "CA"],
"90044": ["Los Angeles", "California", "CA"],
"90045": ["Los Angeles", "California", "CA"],
"90046": ["Los Angeles", "California", "CA"],
"90047": ["Los Angeles", "California", "CA"],
"90048": ["Los Angeles", "California", "CA"],
"90049": ["Los Angeles", "California", "CA"],
"90052": ["Los Angeles", "California", "CA"],
"90056": ["Los Angeles", "California", "CA"],
"90057": ["Los Angeles", "California", "CA"],
"90058": ["Los Angeles", "California", "CA"],
"90059": ["Los Angeles", "California", "CA"],
"90061": ["Los Angeles", "California", "CA"],
"90062": ["Los Angeles", "California", "CA"],
"90063": ["Los Angeles", "California", "CA"],
"90064": ["Los Angeles", "California", "CA"],
"90065": ["Los Angeles", "California", "CA"],
"90066": ["Los Angeles", "California", "CA"],
"90067": ["Los Angeles", "California", "CA"],
"90068": ["Los Angeles", "California", "CA"],
"90069": ["West Hollywood", "California", "CA"],
"90071": ["Los Angeles", "California", "CA"],
"90073": ["Los Angeles", "California", "CA"],
"90077": ["Los Angeles", "California", "CA"],
"90079": ["Los Angeles", "California", "CA"],
"90089": ["Los Angeles", "California", "CA"],
"90094": ["Playa Vista", "California", "CA"],
"90095": ["Los Angeles", "California", "CA"],
"90201": ["Bell Gardens", "California", "CA"],
"90210": ["Beverly Hills", "California", "CA"],
"90211": ["Beverly Hills", "California", "CA"],
"90212": ["Beverly Hills", "California", "CA"],
"90220": ["Compton", "California", "CA"],
"90221": ["Compton", "California", "CA"],
"90222": ["Compton", "California", "CA"],
"90230": ["Culver City", "California", "CA"],
"90232": ["Culver City", "California", "CA"],
"90240": ["Downey", "California", "CA"],
"90241": ["Downey", "California", "CA"],
"90242": ["Downey", "California", "CA"],
"90245": ["El Segundo", "California", "CA"],
"90247": ["Gardena", "California", "CA"],
"90248": ["Gardena", "California", "CA"],
"90249": ["Gardena", "California", "CA"],
"90250": ["Hawthorne", "California", "CA"],
"90254": ["Hermosa Beach", "California", "CA"],
"90255": ["Huntington Park", "California", "CA"],
"90260": ["Lawndale", "California", "CA"],
"90262": ["Lynwood", "California", "CA"],
"90263": ["Malibu", "California", "CA"],
"90265": ["Malibu", "California", "CA"],
"90266": ["Manhattan Beach", "California", "CA"],
"90270": ["Maywood", "California", "CA"],
"90272": ["Pacific Palisades", "California", "CA"],
"90274": ["Palos Verdes Peninsula", "California", "CA"],
"90275": ["Rancho Palos Verdes", "California", "CA"],
"90277": ["Redondo Beach", "California", "CA"],
"90278": ["Redondo Beach", "California", "CA"],
"90280": ["South Gate", "California", "CA"],
"90290": ["Topanga", "California", "CA"],
"90291": ["Venice", "California", "CA"],
"90292": ["Marina Del Rey", "California", "CA"],
"90293": ["Playa Del Rey", "California", "CA"],
"90301": ["Inglewood", "California", "CA"],
"90302": ["Inglewood", "California", "CA"],
"90303": ["Inglewood", "California", "CA"],
"90304": ["Inglewood", "California", "CA"],
"90305": ["Inglewood", "California", "CA"],
"90401": ["Santa Monica", "California", "CA"],
"90402": ["Santa Monica", "California", "CA"],
"90403": ["Santa Monica", "California", "CA"],
"90404": ["Santa Monica", "California", "CA"],
"90405": ["Santa Monica", "California", "CA"],
"90501": ["Torrance", "California", "CA"],
"90502": ["Torrance", "California", "CA"],
"90503": ["Torrance", "California", "CA"],
"90504": ["Torrance", "California", "CA"],
"90505": ["Torrance", "California", "CA"],
"90506": ["Torrance", "California", "CA"],
"90601": ["Whittier", "California", "CA"],
"90602": ["Whittier", "California", "CA"],
"90603": ["Whittier", "California", "CA"],
"90604": ["Whittier", "California", "CA"],
"90605": ["Whittier", "California", "CA"],
"90606": ["Whittier", "California", "CA"],
"90620": ["Buena Park", "California", "CA"],
"90621": ["Buena Park", "California", "CA"],
"90623": ["La Palma", "California", "CA"],
"90630": ["Cypress", "California", "CA"],
"90631": ["La Habra", "California", "CA"],
"90638": ["La Mirada", "California", "CA"],
"90639": ["La Mirada", "California", "CA"],
"90640": ["Montebello", "California", "CA"],
"90650": ["Norwalk", "California", "CA"],
"90660": ["Pico Rivera", "California", "CA"],
"90670": ["Santa Fe Springs", "California", "CA"],
"90680": ["Stanton", "California", "CA"],
"90701": ["Artesia", "California", "CA"],
"90703": ["Cerritos", "California", "CA"],
"90704": ["Avalon", "California", "CA"],
"90706": ["Bellflower", "California", "CA"],
"90710": ["Harbor City", "California", "CA"],
"90712": ["Lakewood", "California", "CA"],
"90713": ["Lakewood", "California", "CA"],
"90715": ["Lakewood", "California", "CA"],
"90716": ["Hawaiian Gardens", "California", "CA"],
"90717": ["Lomita", "California", "CA"],
"90720": ["Los Alamitos", "California", "CA"],
"90723": ["Paramount", "California", "CA"],
"90731": ["San Pedro", "California", "CA"],
"90732": ["San Pedro", "California", "CA"],
"90740": ["Seal Beach", "California", "CA"],
"90742": ["Sunset Beach", "California", "CA"],
"90743": ["Surfside", "California", "CA"],
"90744": ["Wilmington", "California", "CA"],
"90745": ["Carson", "California", "CA"],
"90746": ["Carson", "California", "CA"],
"90747": ["Carson", "California", "CA"],
"90755": ["Signal Hill", "California", "CA"],
"90802": ["Long Beach", "California", "CA"],
"90803": ["Long Beach", "California", "CA"],
"90804": ["Long Beach", "California", "CA"],
"90805": ["Long Beach", "California", "CA"],
"90806": ["Long Beach", "California", "CA"],
"90807": ["Long Beach", "California", "CA"],
"90808": ["Long Beach", "California", "CA"],
"90810": ["Long Beach", "California", "CA"],
"90813": ["Long Beach", "California", "CA"],
"90814": ["Long Beach", "California", "CA"],
"90815": ["Long Beach", "California", "CA"],
"90822": ["Long Beach", "California", "CA"],
"90831": ["Long Beach", "California", "CA"],
"90840": ["Long Beach", "California", "CA"],
"91001": ["Altadena", "California", "CA"],
"91006": ["Arcadia", "California", "CA"],
"91007": ["Arcadia", "California", "CA"],
"91008": ["Duarte", "California", "CA"],
"91010": ["Duarte", "California", "CA"],
"91011": ["La Canada Flintridge", "California", "CA"],
"91016": ["Monrovia", "California", "CA"],
"91020": ["Montrose", "California", "CA"],
"91024": ["Sierra Madre", "California", "CA"],
"91030": ["South Pasadena", "California", "CA"],
"91040": ["Sunland", "California", "CA"],
"91042": ["Tujunga", "California", "CA"],
"91046": ["Verdugo City", "California", "CA"],
"91101": ["Pasadena", "California", "CA"],
"91103": ["Pasadena", "California", "CA"],
"91104": ["Pasadena", "California", "CA"],
"91105": ["Pasadena", "California", "CA"],
"91106": ["Pasadena", "California", "CA"],
"91107": ["Pasadena", "California", "CA"],
"91108": ["San Marino", "California", "CA"],
"91125": ["Pasadena", "California", "CA"],
"91201": ["Glendale", "California", "CA"],
"91202": ["Glendale", "California", "CA"],
"91203": ["Glendale", "California", "CA"],
"91204": ["Glendale", "California", "CA"],
"91205": ["Glendale", "California", "CA"],
"91206": ["Glendale", "California", "CA"],
"91207": ["Glendale", "California", "CA"],
"91208": ["Glendale", "California", "CA"],
"91210": ["Glendale", "California", "CA"],
"91214": ["La Crescenta", "California", "CA"],
"91301": ["Agoura Hills", "California", "CA"],
"91302": ["Calabasas", "California", "CA"],
"91303": ["Canoga Park", "California", "CA"],
"91304": ["Canoga Park", "California", "CA"],
"91306": ["Winnetka", "California", "CA"],
"91307": ["West Hills", "California", "CA"],
"91311": ["Chatsworth", "California", "CA"],
"91316": ["Encino", "California", "CA"],
"91320": ["Newbury Park", "California", "CA"],
"91321": ["Newhall", "California", "CA"],
"91324": ["Northridge", "California", "CA"],
"91325": ["Northridge", "California", "CA"],
"91326": ["Porter Ranch", "California", "CA"],
"91330": ["Northridge", "California", "CA"],
"91331": ["Pacoima", "California", "CA"],
"91335": ["Reseda", "California", "CA"],
"91340": ["San Fernando", "California", "CA"],
"91342": ["Sylmar", "California", "CA"],
"91343": ["North Hills", "California", "CA"],
"91344": ["Granada Hills", "California", "CA"],
"91345": ["Mission Hills", "California", "CA"],
"91350": ["Santa Clarita", "California", "CA"],
"91351": ["Canyon Country", "California", "CA"],
"91352": ["Sun Valley", "California", "CA"],
"91354": ["Valencia", "California", "CA"],
"91355": ["Valencia", "California", "CA"],
"91356": ["Tarzana", "California", "CA"],
"91360": ["Thousand Oaks", "California", "CA"],
"91361": ["Westlake Village", "California", "CA"],
"91362": ["Thousand Oaks", "California", "CA"],
"91364": ["Woodland Hills", "California", "CA"],
"91367": ["Woodland Hills", "California", "CA"],
"91371": ["Woodland Hills", "California", "CA"],
"91377": ["Oak Park", "California", "CA"],
"91381": ["Stevenson Ranch", "California", "CA"],
"91384": ["Castaic", "California", "CA"],
"91387": ["Canyon Country", "California", "CA"],
"91390": ["Santa Clarita", "California", "CA"],
"91401": ["Van Nuys", "California", "CA"],
"91402": ["Panorama City", "California", "CA"],
"91403": ["Sherman Oaks", "California", "CA"],
"91405": ["Van Nuys", "California", "CA"],
"91406": ["Van Nuys", "California", "CA"],
"91411": ["Van Nuys", "California", "CA"],
"91423": ["Sherman Oaks", "California", "CA"],
"91436": ["Encino", "California", "CA"],
"91501": ["Burbank", "California", "CA"],
"91502": ["Burbank", "California", "CA"],
"91504": ["Burbank", "California", "CA"],
"91505": ["Burbank", "California", "CA"],
"91506": ["Burbank", "California", "CA"],
"91601": ["North Hollywood", "California", "CA"],
"91602": ["North Hollywood", "California", "CA"],
"91604": ["Studio City", "California", "CA"],
"91605": ["North Hollywood", "California", "CA"],
"91606": ["North Hollywood", "California", "CA"],
"91607": ["Valley Village", "California", "CA"],
"91608": ["Universal City", "California", "CA"],
"91701": ["Rancho Cucamonga", "California", "CA"],
"91702": ["Azusa", "California", "CA"],
"91706": ["Baldwin Park", "California", "CA"],
"91708": ["Chino", "California", "CA"],
"91709": ["Chino Hills", "California", "CA"],
"91710": ["Chino", "California", "CA"],
"91711": ["Claremont", "California", "CA"],
"91722": ["Covina", "California", "CA"],
"91723": ["Covina", "California", "CA"],
"91724": ["Covina", "California", "CA"],
"91730": ["Rancho Cucamonga", "California", "CA"],
"91731": ["El Monte", "California", "CA"],
"91732": ["El Monte", "California", "CA"],
"91733": ["South El Monte", "California", "CA"],
"91737": ["Rancho Cucamonga", "California", "CA"],
"91739": ["Rancho Cucamonga", "California", "CA"],
"91740": ["Glendora", "California", "CA"],
"91741": ["Glendora", "California", "CA"],
"91744": ["La Puente", "California", "CA"],
"91745": ["Hacienda Heights", "California", "CA"],
"91746": ["La Puente", "California", "CA"],
"91748": ["Rowland Heights", "California", "CA"],
"91750": ["La Verne", "California", "CA"],
"91752": ["Mira Loma", "California", "CA"],
"91754": ["Monterey Park", "California", "CA"],
"91755": ["Monterey Park", "California", "CA"],
"91759": ["Mt Baldy", "California", "CA"],
"91761": ["Ontario", "California", "CA"],
"91762": ["Ontario", "California", "CA"],
"91763": ["Montclair", "California", "CA"],
"91764": ["Ontario", "California", "CA"],
"91765": ["Diamond Bar", "California", "CA"],
"91766": ["Pomona", "California", "CA"],
"91767": ["Pomona", "California", "CA"],
"91768": ["Pomona", "California", "CA"],
"91770": ["Rosemead", "California", "CA"],
"91773": ["San Dimas", "California", "CA"],
"91775": ["San Gabriel", "California", "CA"],
"91776": ["San Gabriel", "California", "CA"],
"91780": ["Temple City", "California", "CA"],
"91784": ["Upland", "California", "CA"],
"91786": ["Upland", "California", "CA"],
"91789": ["Walnut", "California", "CA"],
"91790": ["West Covina", "California", "CA"],
"91791": ["West Covina", "California", "CA"],
"91792": ["West Covina", "California", "CA"],
"91801": ["Alhambra", "California", "CA"],
"91803": ["Alhambra", "California", "CA"],
"91901": ["Alpine", "California", "CA"],
"91902": ["Bonita", "California", "CA"],
"91905": ["Boulevard", "California", "CA"],
"91906": ["Campo", "California", "CA"],
"91910": ["Chula Vista", "California", "CA"],
"91911": ["Chula Vista", "California", "CA"],
"91913": ["Chula Vista", "California", "CA"],
"91914": ["Chula Vista", "California", "CA"],
"91915": ["Chula Vista", "California", "CA"],
"91916": ["Descanso", "California", "CA"],
"91917": ["Dulzura", "California", "CA"],
"91931": ["Guatay", "California", "CA"],
"91932": ["Imperial Beach", "California", "CA"],
"91934": ["Jacumba", "California", "CA"],
"91935": ["Jamul", "California", "CA"],
"91941": ["La Mesa", "California", "CA"],
"91942": ["La Mesa", "California", "CA"],
"91945": ["Lemon Grove", "California", "CA"],
"91948": ["Mount Laguna", "California", "CA"],
"91950": ["National City", "California", "CA"],
"91962": ["Pine Valley", "California", "CA"],
"91963": ["Potrero", "California", "CA"],
"91977": ["Spring Valley", "California", "CA"],
"91978": ["Spring Valley", "California", "CA"],
"91980": ["Tecate", "California", "CA"],
"92003": ["Bonsall", "California", "CA"],
"92004": ["Borrego Springs", "California", "CA"],
"92007": ["Cardiff By The Sea", "California", "CA"],
"92008": ["Carlsbad", "California", "CA"],
"92009": ["Carlsbad", "California", "CA"],
"92010": ["Carlsbad", "California", "CA"],
"92011": ["Carlsbad", "California", "CA"],
"92014": ["Del Mar", "California", "CA"],
"92019": ["El Cajon", "California", "CA"],
"92020": ["El Cajon", "California", "CA"],
"92021": ["El Cajon", "California", "CA"],
"92024": ["Encinitas", "California", "CA"],
"92025": ["Escondido", "California", "CA"],
"92026": ["Escondido", "California", "CA"],
"92027": ["Escondido", "California", "CA"],
"92028": ["Fallbrook", "California", "CA"],
"92029": ["Escondido", "California", "CA"],
"92036": ["Julian", "California", "CA"],
"92037": ["La Jolla", "California", "CA"],
"92040": ["Lakeside", "California", "CA"],
"92054": ["Oceanside", "California", "CA"],
"92055": ["Camp Pendleton", "California", "CA"],
"92056": ["Oceanside", "California", "CA"],
"92057": ["Oceanside", "California", "CA"],
"92058": ["Oceanside", "California", "CA"],
"92059": ["Pala", "California", "CA"],
"92060": ["Palomar Mountain", "California", "CA"],
"92061": ["Pauma Valley", "California", "CA"],
"92064": ["Poway", "California", "CA"],
"92065": ["Ramona", "California", "CA"],
"92066": ["Ranchita", "California", "CA"],
"92067": ["Rancho Santa Fe", "California", "CA"],
"92069": ["San Marcos", "California", "CA"],
"92070": ["Santa Ysabel", "California", "CA"],
"92071": ["Santee", "California", "CA"],
"92075": ["Solana Beach", "California", "CA"],
"92078": ["San Marcos", "California", "CA"],
"92081": ["Vista", "California", "CA"],
"92082": ["Valley Center", "California", "CA"],
"92083": ["Vista", "California", "CA"],
"92084": ["Vista", "California", "CA"],
"92086": ["Warner Springs", "California", "CA"],
"92091": ["Rancho Santa Fe", "California", "CA"],
"92092": ["La Jolla", "California", "CA"],
"92093": ["La Jolla", "California", "CA"],
"92096": ["San Marcos", "California", "CA"],
"92101": ["San Diego", "California", "CA"],
"92102": ["San Diego", "California", "CA"],
"92103": ["San Diego", "California", "CA"],
"92104": ["San Diego", "California", "CA"],
"92105": ["San Diego", "California", "CA"],
"92106": ["San Diego", "California", "CA"],
"92107": ["San Diego", "California", "CA"],
"92108": ["San Diego", "California", "CA"],
"92109": ["San Diego", "California", "CA"],
"92110": ["San Diego", "California", "CA"],
"92111": ["San Diego", "California", "CA"],
"92113": ["San Diego", "California", "CA"],
"92114": ["San Diego", "California", "CA"],
"92115": ["San Diego", "California", "CA"],
"92116": ["San Diego", "California", "CA"],
"92117": ["San Diego", "California", "CA"],
"92118": ["Coronado", "California", "CA"],
"92119": ["San Diego", "California", "CA"],
"92120": ["San Diego", "California", "CA"],
"92121": ["San Diego", "California", "CA"],
"92122": ["San Diego", "California", "CA"],
"92123": ["San Diego", "California", "CA"],
"92124": ["San Diego", "California", "CA"],
"92126": ["San Diego", "California", "CA"],
"92127": ["San Diego", "California", "CA"],
"92128": ["San Diego", "California", "CA"],
"92129": ["San Diego", "California", "CA"],
"92130": ["San Diego", "California", "CA"],
"92131": ["San Diego", "California", "CA"],
"92132": ["San Diego", "California", "CA"],
"92134": ["San Diego", "California", "CA"],
"92135": ["San Diego", "California", "CA"],
"92136": ["San Diego", "California", "CA"],
"92139": ["San Diego", "California", "CA"],
"92140": ["San Diego", "California", "CA"],
"92145": ["San Diego", "California", "CA"],
"92147": ["San Diego", "California", "CA"],
"92154": ["San Diego", "California", "CA"],
"92155": ["San Diego", "California", "CA"],
"92161": ["San Diego", "California", "CA"],
"92173": ["San Ysidro", "California", "CA"],
"92179": ["San Diego", "California", "CA"],
"92182": ["San Diego", "California", "CA"],
"92201": ["Indio", "California", "CA"],
"92203": ["Indio", "California", "CA"],
"92210": ["Indian Wells", "California", "CA"],
"92211": ["Palm Desert", "California", "CA"],
"92220": ["Banning", "California", "CA"],
"92222": ["Bard", "California", "CA"],
"92223": ["Beaumont", "California", "CA"],
"92225": ["Blythe", "California", "CA"],
"92227": ["Brawley", "California", "CA"],
"92230": ["Cabazon", "California", "CA"],
"92231": ["Calexico", "California", "CA"],
"92233": ["Calipatria", "California", "CA"],
"92234": ["Cathedral City", "California", "CA"],
"92236": ["Coachella", "California", "CA"],
"92239": ["Desert Center", "California", "CA"],
"92240": ["Desert Hot Springs", "California", "CA"],
"92241": ["Desert Hot Springs", "California", "CA"],
"92242": ["Earp", "California", "CA"],
"92243": ["El Centro", "California", "CA"],
"92249": ["Heber", "California", "CA"],
"92250": ["Holtville", "California", "CA"],
"92251": ["Imperial", "California", "CA"],
"92252": ["Joshua Tree", "California", "CA"],
"92253": ["La Quinta", "California", "CA"],
"92254": ["Mecca", "California", "CA"],
"92256": ["Morongo Valley", "California", "CA"],
"92257": ["Niland", "California", "CA"],
"92258": ["North Palm Springs", "California", "CA"],
"92259": ["Ocotillo", "California", "CA"],
"92260": ["Palm Desert", "California", "CA"],
"92262": ["Palm Springs", "California", "CA"],
"92264": ["Palm Springs", "California", "CA"],
"92266": ["Palo Verde", "California", "CA"],
"92267": ["Parker Dam", "California", "CA"],
"92268": ["Pioneertown", "California", "CA"],
"92270": ["Rancho Mirage", "California", "CA"],
"92273": ["Seeley", "California", "CA"],
"92274": ["Thermal", "California", "CA"],
"92275": ["Salton City", "California", "CA"],
"92276": ["Thousand Palms", "California", "CA"],
"92277": ["Twentynine Palms", "California", "CA"],
"92278": ["Twentynine Palms", "California", "CA"],
"92280": ["Vidal", "California", "CA"],
"92281": ["Westmorland", "California", "CA"],
"92282": ["Whitewater", "California", "CA"],
"92283": ["Winterhaven", "California", "CA"],
"92284": ["Yucca Valley", "California", "CA"],
"92285": ["Landers", "California", "CA"],
"92301": ["Adelanto", "California", "CA"],
"92304": ["Amboy", "California", "CA"],
"92305": ["Angelus Oaks", "California", "CA"],
"92307": ["Apple Valley", "California", "CA"],
"92308": ["Apple Valley", "California", "CA"],
"92309": ["Baker", "California", "CA"],
"92310": ["Fort Irwin", "California", "CA"],
"92311": ["Barstow", "California", "CA"],
"92313": ["Grand Terrace", "California", "CA"],
"92314": ["Big Bear City", "California", "CA"],
"92315": ["Big Bear Lake", "California", "CA"],
"92316": ["Bloomington", "California", "CA"],
"92317": ["Blue Jay", "California", "CA"],
"92320": ["Calimesa", "California", "CA"],
"92321": ["Cedar Glen", "California", "CA"],
"92322": ["Cedarpines Park", "California", "CA"],
"92324": ["Colton", "California", "CA"],
"92325": ["Crestline", "California", "CA"],
"92327": ["Daggett", "California", "CA"],
"92328": ["Death Valley", "California", "CA"],
"92332": ["Essex", "California", "CA"],
"92333": ["Fawnskin", "California", "CA"],
"92335": ["Fontana", "California", "CA"],
"92336": ["Fontana", "California", "CA"],
"92337": ["Fontana", "California", "CA"],
"92338": ["Ludlow", "California", "CA"],
"92339": ["Forest Falls", "California", "CA"],
"92341": ["Green Valley Lake", "California", "CA"],
"92342": ["Helendale", "California", "CA"],
"92344": ["Hesperia", "California", "CA"],
"92345": ["Hesperia", "California", "CA"],
"92346": ["Highland", "California", "CA"],
"92347": ["Hinkley", "California", "CA"],
"92350": ["Loma Linda", "California", "CA"],
"92352": ["Lake Arrowhead", "California", "CA"],
"92354": ["Loma Linda", "California", "CA"],
"92356": ["Lucerne Valley", "California", "CA"],
"92358": ["Lytle Creek", "California", "CA"],
"92359": ["Mentone", "California", "CA"],
"92363": ["Needles", "California", "CA"],
"92364": ["Nipton", "California", "CA"],
"92365": ["Newberry Springs", "California", "CA"],
"92368": ["Oro Grande", "California", "CA"],
"92369": ["Patton", "California", "CA"],
"92371": ["Phelan", "California", "CA"],
"92372": ["Pinon Hills", "California", "CA"],
"92373": ["Redlands", "California", "CA"],
"92374": ["Redlands", "California", "CA"],
"92376": ["Rialto", "California", "CA"],
"92377": ["Rialto", "California", "CA"],
"92378": ["Rimforest", "California", "CA"],
"92382": ["Running Springs", "California", "CA"],
"92384": ["Shoshone", "California", "CA"],
"92385": ["Skyforest", "California", "CA"],
"92386": ["Sugarloaf", "California", "CA"],
"92389": ["Tecopa", "California", "CA"],
"92391": ["Twin Peaks", "California", "CA"],
"92392": ["Victorville", "California", "CA"],
"92394": ["Victorville", "California", "CA"],
"92395": ["Victorville", "California", "CA"],
"92397": ["Wrightwood", "California", "CA"],
"92398": ["Yermo", "California", "CA"],
"92399": ["Yucaipa", "California", "CA"],
"92401": ["San Bernardino", "California", "CA"],
"92404": ["San Bernardino", "California", "CA"],
"92405": ["San Bernardino", "California", "CA"],
"92407": ["San Bernardino", "California", "CA"],
"92408": ["San Bernardino", "California", "CA"],
"92410": ["San Bernardino", "California", "CA"],
"92411": ["San Bernardino", "California", "CA"],
"92415": ["San Bernardino", "California", "CA"],
"92501": ["Riverside", "California", "CA"],
"92503": ["Riverside", "California", "CA"],
"92504": ["Riverside", "California", "CA"],
"92505": ["Riverside", "California", "CA"],
"92506": ["Riverside", "California", "CA"],
"92507": ["Riverside", "California", "CA"],
"92508": ["Riverside", "California", "CA"],
"92509": ["Jurupa Valley", "California", "CA"],
"92518": ["March Air Reserve Base", "California", "CA"],
"92521": ["Riverside", "California", "CA"],
"92530": ["Lake Elsinore", "California", "CA"],
"92532": ["Lake Elsinore", "California", "CA"],
"92536": ["Aguanga", "California", "CA"],
"92539": ["Anza", "California", "CA"],
"92543": ["Hemet", "California", "CA"],
"92544": ["Hemet", "California", "CA"],
"92545": ["Hemet", "California", "CA"],
"92548": ["Homeland", "California", "CA"],
"92549": ["Idyllwild", "California", "CA"],
"92551": ["Moreno Valley", "California", "CA"],
"92553": ["Moreno Valley", "California", "CA"],
"92555": ["Moreno Valley", "California", "CA"],
"92557": ["Moreno Valley", "California", "CA"],
"92561": ["Mountain Center", "California", "CA"],
"92562": ["Murrieta", "California", "CA"],
"92563": ["Murrieta", "California", "CA"],
"92567": ["Nuevo", "California", "CA"],
"92570": ["Perris", "California", "CA"],
"92571": ["Perris", "California", "CA"],
"92582": ["San Jacinto", "California", "CA"],
"92583": ["San Jacinto", "California", "CA"],
"92584": ["Menifee", "California", "CA"],
"92585": ["Menifee", "California", "CA"],
"92586": ["Menifee", "California", "CA"],
"92587": ["Menifee", "California", "CA"],
"92590": ["Temecula", "California", "CA"],
"92591": ["Temecula", "California", "CA"],
"92592": ["Temecula", "California", "CA"],
"92595": ["Wildomar", "California", "CA"],
"92596": ["Winchester", "California", "CA"],
"92602": ["Irvine", "California", "CA"],
"92603": ["Irvine", "California", "CA"],
"92604": ["Irvine", "California", "CA"],
"92606": ["Irvine", "California", "CA"],
"92610": ["Foothill Ranch", "California", "CA"],
"92612": ["Irvine", "California", "CA"],
"92614": ["Irvine", "California", "CA"],
"92617": ["Irvine", "California", "CA"],
"92618": ["Irvine", "California", "CA"],
"92620": ["Irvine", "California", "CA"],
"92624": ["Capistrano Beach", "California", "CA"],
"92625": ["Corona Del Mar", "California", "CA"],
"92626": ["Costa Mesa", "California", "CA"],
"92627": ["Costa Mesa", "California", "CA"],
"92629": ["Dana Point", "California", "CA"],
"92630": ["Lake Forest", "California", "CA"],
"92637": ["Laguna Woods", "California", "CA"],
"92646": ["Huntington Beach", "California", "CA"],
"92647": ["Huntington Beach", "California", "CA"],
"92648": ["Huntington Beach", "California", "CA"],
"92649": ["Huntington Beach", "California", "CA"],
"92651": ["Laguna Beach", "California", "CA"],
"92653": ["Laguna Hills", "California", "CA"],
"92655": ["Midway City", "California", "CA"],
"92656": ["Aliso Viejo", "California", "CA"],
"92657": ["Newport Coast", "California", "CA"],
"92660": ["Newport Beach", "California", "CA"],
"92661": ["Newport Beach", "California", "CA"],
"92662": ["Newport Beach", "California", "CA"],
"92663": ["Newport Beach", "California", "CA"],
"92672": ["San Clemente", "California", "CA"],
"92673": ["San Clemente", "California", "CA"],
"92675": ["San Juan Capistrano", "California", "CA"],
"92676": ["Silverado", "California", "CA"],
"92677": ["Laguna Niguel", "California", "CA"],
"92678": ["Trabuco Canyon", "California", "CA"],
"92679": ["Trabuco Canyon", "California", "CA"],
"92683": ["Westminster", "California", "CA"],
"92688": ["Rancho Santa Margarita", "California", "CA"],
"92691": ["Mission Viejo", "California", "CA"],
"92692": ["Mission Viejo", "California", "CA"],
"92694": ["Ladera Ranch", "California", "CA"],
"92697": ["Irvine", "California", "CA"],
"92701": ["Santa Ana", "California", "CA"],
"92703": ["Santa Ana", "California", "CA"],
"92704": ["Santa Ana", "California", "CA"],
"92705": ["Santa Ana", "California", "CA"],
"92706": ["Santa Ana", "California", "CA"],
"92707": ["Santa Ana", "California", "CA"],
"92708": ["Fountain Valley", "California", "CA"],
"92780": ["Tustin", "California", "CA"],
"92782": ["Tustin", "California", "CA"],
"92801": ["Anaheim", "California", "CA"],
"92802": ["Anaheim", "California", "CA"],
"92804": ["Anaheim", "California", "CA"],
"92805": ["Anaheim", "California", "CA"],
"92806": ["Anaheim", "California", "CA"],
"92807": ["Anaheim", "California", "CA"],
"92808": ["Anaheim", "California", "CA"],
"92821": ["Brea", "California", "CA"],
"92823": ["Brea", "California", "CA"],
"92831": ["Fullerton", "California", "CA"],
"92832": ["Fullerton", "California", "CA"],
"92833": ["Fullerton", "California", "CA"],
"92835": ["Fullerton", "California", "CA"],
"92840": ["Garden Grove", "California", "CA"],
"92841": ["Garden Grove", "California", "CA"],
"92843": ["Garden Grove", "California", "CA"],
"92844": ["Garden Grove", "California", "CA"],
"92845": ["Garden Grove", "California", "CA"],
"92860": ["Norco", "California", "CA"],
"92861": ["Villa Park", "California", "CA"],
"92865": ["Orange", "California", "CA"],
"92866": ["Orange", "California", "CA"],
"92867": ["Orange", "California", "CA"],
"92868": ["Orange", "California", "CA"],
"92869": ["Orange", "California", "CA"],
"92870": ["Placentia", "California", "CA"],
"92879": ["Corona", "California", "CA"],
"92880": ["Corona", "California", "CA"],
"92881": ["Corona", "California", "CA"],
"92882": ["Corona", "California", "CA"],
"92883": ["Corona", "California", "CA"],
"92886": ["Yorba Linda", "California", "CA"],
"92887": ["Yorba Linda", "California", "CA"],
"93001": ["Ventura", "California", "CA"],
"93003": ["Ventura", "California", "CA"],
"93004": ["Ventura", "California", "CA"],
"93010": ["Camarillo", "California", "CA"],
"93012": ["Camarillo", "California", "CA"],
"93013": ["Carpinteria", "California", "CA"],
"93015": ["Fillmore", "California", "CA"],
"93021": ["Moorpark", "California", "CA"],
"93022": ["Oak View", "California", "CA"],
"93023": ["Ojai", "California", "CA"],
"93030": ["Oxnard", "California", "CA"],
"93033": ["Oxnard", "California", "CA"],
"93035": ["Oxnard", "California", "CA"],
"93036": ["Oxnard", "California", "CA"],
"93040": ["Piru", "California", "CA"],
"93041": ["Port Hueneme", "California", "CA"],
"93042": ["Point Mugu Nawc", "California", "CA"],
"93043": ["Port Hueneme Cbc Base", "California", "CA"],
"93060": ["Santa Paula", "California", "CA"],
"93063": ["Simi Valley", "California", "CA"],
"93064": ["Brandeis", "California", "CA"],
"93065": ["Simi Valley", "California", "CA"],
"93066": ["Somis", "California", "CA"],
"93067": ["Summerland", "California", "CA"],
"93101": ["Santa Barbara", "California", "CA"],
"93103": ["Santa Barbara", "California", "CA"],
"93105": ["Santa Barbara", "California", "CA"],
"93106": ["Santa Barbara", "California", "CA"],
"93108": ["Santa Barbara", "California", "CA"],
"93109": ["Santa Barbara", "California", "CA"],
"93110": ["Santa Barbara", "California", "CA"],
"93111": ["Santa Barbara", "California", "CA"],
"93117": ["Goleta", "California", "CA"],
"93201": ["Alpaugh", "California", "CA"],
"93202": ["Armona", "California", "CA"],
"93203": ["Arvin", "California", "CA"],
"93204": ["Avenal", "California", "CA"],
"93205": ["Bodfish", "California", "CA"],
"93206": ["Buttonwillow", "California", "CA"],
"93207": ["California Hot Springs", "California", "CA"],
"93208": ["Camp Nelson", "California", "CA"],
"93210": ["Coalinga", "California", "CA"],
"93212": ["Corcoran", "California", "CA"],
"93215": ["Delano", "California", "CA"],
"93218": ["Ducor", "California", "CA"],
"93219": ["Earlimart", "California", "CA"],
"93220": ["Edison", "California", "CA"],
"93221": ["Exeter", "California", "CA"],
"93222": ["Pine Mountain Club", "California", "CA"],
"93223": ["Farmersville", "California", "CA"],
"93224": ["Fellows", "California", "CA"],
"93225": ["Frazier Park", "California", "CA"],
"93226": ["Glennville", "California", "CA"],
"93227": ["Goshen", "California", "CA"],
"93230": ["Hanford", "California", "CA"],
"93234": ["Huron", "California", "CA"],
"93235": ["Ivanhoe", "California", "CA"],
"93237": ["Kaweah", "California", "CA"],
"93238": ["Kernville", "California", "CA"],
"93239": ["Kettleman City", "California", "CA"],
"93240": ["Lake Isabella", "California", "CA"],
"93241": ["Lamont", "California", "CA"],
"93242": ["Laton", "California", "CA"],
"93243": ["Lebec", "California", "CA"],
"93244": ["Lemon Cove", "California", "CA"],
"93245": ["Lemoore", "California", "CA"],
"93246": ["Lemoore", "California", "CA"],
"93247": ["Lindsay", "California", "CA"],
"93249": ["Lost Hills", "California", "CA"],
"93250": ["McFarland", "California", "CA"],
"93251": ["McKittrick", "California", "CA"],
"93252": ["Maricopa", "California", "CA"],
"93254": ["New Cuyama", "California", "CA"],
"93255": ["Onyx", "California", "CA"],
"93256": ["Pixley", "California", "CA"],
"93257": ["Porterville", "California", "CA"],
"93258": ["Porterville", "California", "CA"],
"93260": ["Posey", "California", "CA"],
"93261": ["Richgrove", "California", "CA"],
"93262": ["Sequoia National Park", "California", "CA"],
"93263": ["Shafter", "California", "CA"],
"93265": ["Springville", "California", "CA"],
"93266": ["Stratford", "California", "CA"],
"93267": ["Strathmore", "California", "CA"],
"93268": ["Taft", "California", "CA"],
"93270": ["Terra Bella", "California", "CA"],
"93271": ["Three Rivers", "California", "CA"],
"93272": ["Tipton", "California", "CA"],
"93274": ["Tulare", "California", "CA"],
"93276": ["Tupman", "California", "CA"],
"93277": ["Visalia", "California", "CA"],
"93280": ["Wasco", "California", "CA"],
"93283": ["Weldon", "California", "CA"],
"93285": ["Wofford Heights", "California", "CA"],
"93286": ["Woodlake", "California", "CA"],
"93287": ["Woody", "California", "CA"],
"93291": ["Visalia", "California", "CA"],
"93292": ["Visalia", "California", "CA"],
"93301": ["Bakersfield", "California", "CA"],
"93304": ["Bakersfield", "California", "CA"],
"93305": ["Bakersfield", "California", "CA"],
"93306": ["Bakersfield", "California", "CA"],
"93307": ["Bakersfield", "California", "CA"],
"93308": ["Bakersfield", "California", "CA"],
"93309": ["Bakersfield", "California", "CA"],
"93311": ["Bakersfield", "California", "CA"],
"93312": ["Bakersfield", "California", "CA"],
"93313": ["Bakersfield", "California", "CA"],
"93314": ["Bakersfield", "California", "CA"],
"93401": ["San Luis Obispo", "California", "CA"],
"93402": ["Los Osos", "California", "CA"],
"93405": ["San Luis Obispo", "California", "CA"],
"93407": ["San Luis Obispo", "California", "CA"],
"93408": ["San Luis Obispo", "California", "CA"],
"93409": ["San Luis Obispo", "California", "CA"],
"93410": ["San Luis Obispo", "California", "CA"],
"93420": ["Arroyo Grande", "California", "CA"],
"93422": ["Atascadero", "California", "CA"],
"93424": ["Avila Beach", "California", "CA"],
"93426": ["Bradley", "California", "CA"],
"93427": ["Buellton", "California", "CA"],
"93428": ["Cambria", "California", "CA"],
"93429": ["Casmalia", "California", "CA"],
"93430": ["Cayucos", "California", "CA"],
"93432": ["Creston", "California", "CA"],
"93433": ["Grover Beach", "California", "CA"],
"93434": ["Guadalupe", "California", "CA"],
"93436": ["Lompoc", "California", "CA"],
"93437": ["Lompoc", "California", "CA"],
"93440": ["Los Alamos", "California", "CA"],
"93441": ["Los Olivos", "California", "CA"],
"93442": ["Morro Bay", "California", "CA"],
"93444": ["Nipomo", "California", "CA"],
"93445": ["Oceano", "California", "CA"],
"93446": ["Paso Robles", "California", "CA"],
"93449": ["Pismo Beach", "California", "CA"],
"93450": ["San Ardo", "California", "CA"],
"93451": ["San Miguel", "California", "CA"],
"93452": ["San Simeon", "California", "CA"],
"93453": ["Santa Margarita", "California", "CA"],
"93454": ["Santa Maria", "California", "CA"],
"93455": ["Santa Maria", "California", "CA"],
"93458": ["Santa Maria", "California", "CA"],
"93460": ["Santa Ynez", "California", "CA"],
"93461": ["Shandon", "California", "CA"],
"93463": ["Solvang", "California", "CA"],
"93465": ["Templeton", "California", "CA"],
"93501": ["Mojave", "California", "CA"],
"93505": ["California City", "California", "CA"],
"93510": ["Acton", "California", "CA"],
"93512": ["Benton", "California", "CA"],
"93513": ["Big Pine", "California", "CA"],
"93514": ["Bishop", "California", "CA"],
"93516": ["Boron", "California", "CA"],
"93517": ["Bridgeport", "California", "CA"],
"93518": ["Caliente", "California", "CA"],
"93519": ["Cantil", "California", "CA"],
"93522": ["Darwin", "California", "CA"],
"93523": ["Edwards", "California", "CA"],
"93524": ["Edwards", "California", "CA"],
"93526": ["Independence", "California", "CA"],
"93527": ["Inyokern", "California", "CA"],
"93528": ["Johannesburg", "California", "CA"],
"93529": ["June Lake", "California", "CA"],
"93530": ["Keeler", "California", "CA"],
"93531": ["Keene", "California", "CA"],
"93532": ["Lake Hughes", "California", "CA"],
"93534": ["Lancaster", "California", "CA"],
"93535": ["Lancaster", "California", "CA"],
"93536": ["Lancaster", "California", "CA"],
"93541": ["Lee Vining", "California", "CA"],
"93543": ["Littlerock", "California", "CA"],
"93544": ["Llano", "California", "CA"],
"93545": ["Lone Pine", "California", "CA"],
"93546": ["Mammoth Lakes", "California", "CA"],
"93549": ["Olancha", "California", "CA"],
"93550": ["Palmdale", "California", "CA"],
"93551": ["Palmdale", "California", "CA"],
"93552": ["Palmdale", "California", "CA"],
"93553": ["Pearblossom", "California", "CA"],
"93554": ["Randsburg", "California", "CA"],
"93555": ["Ridgecrest", "California", "CA"],
"93558": ["Red Mountain", "California", "CA"],
"93560": ["Rosamond", "California", "CA"],
"93561": ["Tehachapi", "California", "CA"],
"93562": ["Trona", "California", "CA"],
"93563": ["Valyermo", "California", "CA"],
"93581": ["Tehachapi", "California", "CA"],
"93591": ["Palmdale", "California", "CA"],
"93601": ["Ahwahnee", "California", "CA"],
"93602": ["Auberry", "California", "CA"],
"93603": ["Badger", "California", "CA"],
"93604": ["Bass Lake", "California", "CA"],
"93605": ["Big Creek", "California", "CA"],
"93606": ["Biola", "California", "CA"],
"93608": ["Cantua Creek", "California", "CA"],
"93609": ["Caruthers", "California", "CA"],
"93610": ["Chowchilla", "California", "CA"],
"93611": ["Clovis", "California", "CA"],
"93612": ["Clovis", "California", "CA"],
"93614": ["Coarsegold", "California", "CA"],
"93615": ["Cutler", "California", "CA"],
"93616": ["Del Rey", "California", "CA"],
"93618": ["Dinuba", "California", "CA"],
"93619": ["Clovis", "California", "CA"],
"93620": ["Dos Palos", "California", "CA"],
"93621": ["Dunlap", "California", "CA"],
"93622": ["Firebaugh", "California", "CA"],
"93623": ["Fish Camp", "California", "CA"],
"93624": ["Five Points", "California", "CA"],
"93625": ["Fowler", "California", "CA"],
"93626": ["Friant", "California", "CA"],
"93627": ["Helm", "California", "CA"],
"93628": ["Hume", "California", "CA"],
"93630": ["Kerman", "California", "CA"],
"93631": ["Kingsburg", "California", "CA"],
"93633": ["Kings Canyon National Pk", "California", "CA"],
"93634": ["Lakeshore", "California", "CA"],
"93635": ["Los Banos", "California", "CA"],
"93636": ["Madera", "California", "CA"],
"93637": ["Madera", "California", "CA"],
"93638": ["Madera", "California", "CA"],
"93640": ["Mendota", "California", "CA"],
"93641": ["Miramonte", "California", "CA"],
"93643": ["North Fork", "California", "CA"],
"93644": ["Oakhurst", "California", "CA"],
"93645": ["O'Neals", "California", "CA"],
"93646": ["Orange Cove", "California", "CA"],
"93647": ["Orosi", "California", "CA"],
"93648": ["Parlier", "California", "CA"],
"93650": ["Fresno", "California", "CA"],
"93651": ["Prather", "California", "CA"],
"93652": ["Raisin City", "California", "CA"],
"93653": ["Raymond", "California", "CA"],
"93654": ["Reedley", "California", "CA"],
"93656": ["Riverdale", "California", "CA"],
"93657": ["Sanger", "California", "CA"],
"93660": ["San Joaquin", "California", "CA"],
"93662": ["Selma", "California", "CA"],
"93664": ["Shaver Lake", "California", "CA"],
"93665": ["South Dos Palos", "California", "CA"],
"93666": ["Sultana", "California", "CA"],
"93667": ["Tollhouse", "California", "CA"],
"93668": ["Tranquillity", "California", "CA"],
"93669": ["Wishon", "California", "CA"],
"93670": ["Yettem", "California", "CA"],
"93673": ["Traver", "California", "CA"],
"93675": ["Squaw Valley", "California", "CA"],
"93701": ["Fresno", "California", "CA"],
"93702": ["Fresno", "California", "CA"],
"93703": ["Fresno", "California", "CA"],
"93704": ["Fresno", "California", "CA"],
"93705": ["Fresno", "California", "CA"],
"93706": ["Fresno", "California", "CA"],
"93710": ["Fresno", "California", "CA"],
"93711": ["Fresno", "California", "CA"],
"93720": ["Fresno", "California", "CA"],
"93721": ["Fresno", "California", "CA"],
"93722": ["Fresno", "California", "CA"],
"93723": ["Fresno", "California", "CA"],
"93725": ["Fresno", "California", "CA"],
"93726": ["Fresno", "California", "CA"],
"93727": ["Fresno", "California", "CA"],
"93728": ["Fresno", "California", "CA"],
"93730": ["Fresno", "California", "CA"],
"93737": ["Fresno", "California", "CA"],
"93740": ["Fresno", "California", "CA"],
"93741": ["Fresno", "California", "CA"],
"93901": ["Salinas", "California", "CA"],
"93905": ["Salinas", "California", "CA"],
"93906": ["Salinas", "California", "CA"],
"93907": ["Salinas", "California", "CA"],
"93908": ["Salinas", "California", "CA"],
"93920": ["Big Sur", "California", "CA"],
"93921": ["Carmel By The Sea", "California", "CA"],
"93923": ["Carmel", "California", "CA"],
"93924": ["Carmel Valley", "California", "CA"],
"93925": ["Chualar", "California", "CA"],
"93926": ["Gonzales", "California", "CA"],
"93927": ["Greenfield", "California", "CA"],
"93928": ["Jolon", "California", "CA"],
"93930": ["King City", "California", "CA"],
"93932": ["Lockwood", "California", "CA"],
"93933": ["Marina", "California", "CA"],
"93940": ["Monterey", "California", "CA"],
"93943": ["Monterey", "California", "CA"],
"93944": ["Monterey", "California", "CA"],
"93950": ["Pacific Grove", "California", "CA"],
"93953": ["Pebble Beach", "California", "CA"],
"93954": ["San Lucas", "California", "CA"],
"93955": ["Seaside", "California", "CA"],
"93960": ["Soledad", "California", "CA"],
"93962": ["Spreckels", "California", "CA"],
"94002": ["Belmont", "California", "CA"],
"94005": ["Brisbane", "California", "CA"],
"94010": ["Burlingame", "California", "CA"],
"94014": ["Daly City", "California", "CA"],
"94015": ["Daly City", "California", "CA"],
"94018": ["El Granada", "California", "CA"],
"94019": ["Half Moon Bay", "California", "CA"],
"94020": ["La Honda", "California", "CA"],
"94021": ["Loma Mar", "California", "CA"],
"94022": ["Los Altos", "California", "CA"],
"94024": ["Los Altos", "California", "CA"],
"94025": ["Menlo Park", "California", "CA"],
"94027": ["Atherton", "California", "CA"],
"94028": ["Portola Valley", "California", "CA"],
"94030": ["Millbrae", "California", "CA"],
"94037": ["Montara", "California", "CA"],
"94038": ["Moss Beach", "California", "CA"],
"94040": ["Mountain View", "California", "CA"],
"94041": ["Mountain View", "California", "CA"],
"94043": ["Mountain View", "California", "CA"],
"94044": ["Pacifica", "California", "CA"],
"94060": ["Pescadero", "California", "CA"],
"94061": ["Redwood City", "California", "CA"],
"94062": ["Redwood City", "California", "CA"],
"94063": ["Redwood City", "California", "CA"],
"94065": ["Redwood City", "California", "CA"],
"94066": ["San Bruno", "California", "CA"],
"94070": ["San Carlos", "California", "CA"],
"94074": ["San Gregorio", "California", "CA"],
"94080": ["South San Francisco", "California", "CA"],
"94085": ["Sunnyvale", "California", "CA"],
"94086": ["Sunnyvale", "California", "CA"],
"94087": ["Sunnyvale", "California", "CA"],
"94089": ["Sunnyvale", "California", "CA"],
"94102": ["San Francisco", "California", "CA"],
"94103": ["San Francisco", "California", "CA"],
"94104": ["San Francisco", "California", "CA"],
"94105": ["San Francisco", "California", "CA"],
"94107": ["San Francisco", "California", "CA"],
"94108": ["San Francisco", "California", "CA"],
"94109": ["San Francisco", "California", "CA"],
"94110": ["San Francisco", "California", "CA"],
"94111": ["San Francisco", "California", "CA"],
"94112": ["San Francisco", "California", "CA"],
"94114": ["San Francisco", "California", "CA"],
"94115": ["San Francisco", "California", "CA"],
"94116": ["San Francisco", "California", "CA"],
"94117": ["San Francisco", "California", "CA"],
"94118": ["San Francisco", "California", "CA"],
"94121": ["San Francisco", "California", "CA"],
"94122": ["San Francisco", "California", "CA"],
"94123": ["San Francisco", "California", "CA"],
"94124": ["San Francisco", "California", "CA"],
"94127": ["San Francisco", "California", "CA"],
"94128": ["San Francisco", "California", "CA"],
"94129": ["San Francisco", "California", "CA"],
"94130": ["San Francisco", "California", "CA"],
"94131": ["San Francisco", "California", "CA"],
"94132": ["San Francisco", "California", "CA"],
"94133": ["San Francisco", "California", "CA"],
"94134": ["San Francisco", "California", "CA"],
"94158": ["San Francisco", "California", "CA"],
"94188": ["San Francisco", "California", "CA"],
"94301": ["Palo Alto", "California", "CA"],
"94303": ["Palo Alto", "California", "CA"],
"94304": ["Palo Alto", "California", "CA"],
"94305": ["Stanford", "California", "CA"],
"94306": ["Palo Alto", "California", "CA"],
"94401": ["San Mateo", "California", "CA"],
"94402": ["San Mateo", "California", "CA"],
"94403": ["San Mateo", "California", "CA"],
"94404": ["San Mateo", "California", "CA"],
"94501": ["Alameda", "California", "CA"],
"94502": ["Alameda", "California", "CA"],
"94503": ["American Canyon", "California", "CA"],
"94505": ["Discovery Bay", "California", "CA"],
"94506": ["Danville", "California", "CA"],
"94507": ["Alamo", "California", "CA"],
"94508": ["Angwin", "California", "CA"],
"94509": ["Antioch", "California", "CA"],
"94510": ["Benicia", "California", "CA"],
"94511": ["Bethel Island", "California", "CA"],
"94512": ["Birds Landing", "California", "CA"],
"94513": ["Brentwood", "California", "CA"],
"94514": ["Byron", "California", "CA"],
"94515": ["Calistoga", "California", "CA"],
"94516": ["Canyon", "California", "CA"],
"94517": ["Clayton", "California", "CA"],
"94518": ["Concord", "California", "CA"],
"94519": ["Concord", "California", "CA"],
"94520": ["Concord", "California", "CA"],
"94521": ["Concord", "California", "CA"],
"94523": ["Pleasant Hill", "California", "CA"],
"94525": ["Crockett", "California", "CA"],
"94526": ["Danville", "California", "CA"],
"94528": ["Diablo", "California", "CA"],
"94530": ["El Cerrito", "California", "CA"],
"94531": ["Antioch", "California", "CA"],
"94533": ["Fairfield", "California", "CA"],
"94534": ["Fairfield", "California", "CA"],
"94535": ["Travis Afb", "California", "CA"],
"94536": ["Fremont", "California", "CA"],
"94538": ["Fremont", "California", "CA"],
"94539": ["Fremont", "California", "CA"],
"94541": ["Hayward", "California", "CA"],
"94542": ["Hayward", "California", "CA"],
"94544": ["Hayward", "California", "CA"],
"94545": ["Hayward", "California", "CA"],
"94546": ["Castro Valley", "California", "CA"],
"94547": ["Hercules", "California", "CA"],
"94548": ["Knightsen", "California", "CA"],
"94549": ["Lafayette", "California", "CA"],
"94550": ["Livermore", "California", "CA"],
"94551": ["Livermore", "California", "CA"],
"94552": ["Castro Valley", "California", "CA"],
"94553": ["Martinez", "California", "CA"],
"94555": ["Fremont", "California", "CA"],
"94556": ["Moraga", "California", "CA"],
"94558": ["Napa", "California", "CA"],
"94559": ["Napa", "California", "CA"],
"94560": ["Newark", "California", "CA"],
"94561": ["Oakley", "California", "CA"],
"94562": ["Oakville", "California", "CA"],
"94563": ["Orinda", "California", "CA"],
"94564": ["Pinole", "California", "CA"],
"94565": ["Pittsburg", "California", "CA"],
"94566": ["Pleasanton", "California", "CA"],
"94567": ["Pope Valley", "California", "CA"],
"94568": ["Dublin", "California", "CA"],
"94569": ["Port Costa", "California", "CA"],
"94571": ["Rio Vista", "California", "CA"],
"94572": ["Rodeo", "California", "CA"],
"94573": ["Rutherford", "California", "CA"],
"94574": ["Saint Helena", "California", "CA"],
"94575": ["Moraga", "California", "CA"],
"94576": ["Deer Park", "California", "CA"],
"94577": ["San Leandro", "California", "CA"],
"94578": ["San Leandro", "California", "CA"],
"94579": ["San Leandro", "California", "CA"],
"94580": ["San Lorenzo", "California", "CA"],
"94582": ["San Ramon", "California", "CA"],
"94583": ["San Ramon", "California", "CA"],
"94585": ["Suisun City", "California", "CA"],
"94586": ["Sunol", "California", "CA"],
"94587": ["Union City", "California", "CA"],
"94588": ["Pleasanton", "California", "CA"],
"94589": ["Vallejo", "California", "CA"],
"94590": ["Vallejo", "California", "CA"],
"94591": ["Vallejo", "California", "CA"],
"94592": ["Vallejo", "California", "CA"],
"94595": ["Walnut Creek", "California", "CA"],
"94596": ["Walnut Creek", "California", "CA"],
"94597": ["Walnut Creek", "California", "CA"],
"94598": ["Walnut Creek", "California", "CA"],
"94599": ["Yountville", "California", "CA"],
"94601": ["Oakland", "California", "CA"],
"94602": ["Oakland", "California", "CA"],
"94603": ["Oakland", "California", "CA"],
"94605": ["Oakland", "California", "CA"],
"94606": ["Oakland", "California", "CA"],
"94607": ["Oakland", "California", "CA"],
"94608": ["Emeryville", "California", "CA"],
"94609": ["Oakland", "California", "CA"],
"94610": ["Oakland", "California", "CA"],
"94611": ["Oakland", "California", "CA"],
"94612": ["Oakland", "California", "CA"],
"94613": ["Oakland", "California", "CA"],
"94618": ["Oakland", "California", "CA"],
"94619": ["Oakland", "California", "CA"],
"94621": ["Oakland", "California", "CA"],
"94702": ["Berkeley", "California", "CA"],
"94703": ["Berkeley", "California", "CA"],
"94704": ["Berkeley", "California", "CA"],
"94705": ["Berkeley", "California", "CA"],
"94706": ["Albany", "California", "CA"],
"94707": ["Berkeley", "California", "CA"],
"94708": ["Berkeley", "California", "CA"],
"94709": ["Berkeley", "California", "CA"],
"94710": ["Berkeley", "California", "CA"],
"94720": ["Berkeley", "California", "CA"],
"94801": ["Richmond", "California", "CA"],
"94803": ["El Sobrante", "California", "CA"],
"94804": ["Richmond", "California", "CA"],
"94805": ["Richmond", "California", "CA"],
"94806": ["San Pablo", "California", "CA"],
"94901": ["San Rafael", "California", "CA"],
"94903": ["San Rafael", "California", "CA"],
"94904": ["Greenbrae", "California", "CA"],
"94920": ["Belvedere Tiburon", "California", "CA"],
"94922": ["Bodega", "California", "CA"],
"94923": ["Bodega Bay", "California", "CA"],
"94924": ["Bolinas", "California", "CA"],
"94925": ["Corte Madera", "California", "CA"],
"94928": ["Rohnert Park", "California", "CA"],
"94929": ["Dillon Beach", "California", "CA"],
"94930": ["Fairfax", "California", "CA"],
"94931": ["Cotati", "California", "CA"],
"94933": ["Forest Knolls", "California", "CA"],
"94937": ["Inverness", "California", "CA"],
"94938": ["Lagunitas", "California", "CA"],
"94939": ["Larkspur", "California", "CA"],
"94940": ["Marshall", "California", "CA"],
"94941": ["Mill Valley", "California", "CA"],
"94945": ["Novato", "California", "CA"],
"94946": ["Nicasio", "California", "CA"],
"94947": ["Novato", "California", "CA"],
"94949": ["Novato", "California", "CA"],
"94950": ["Olema", "California", "CA"],
"94951": ["Penngrove", "California", "CA"],
"94952": ["Petaluma", "California", "CA"],
"94954": ["Petaluma", "California", "CA"],
"94956": ["Point Reyes Station", "California", "CA"],
"94957": ["Ross", "California", "CA"],
"94960": ["San Anselmo", "California", "CA"],
"94963": ["San Geronimo", "California", "CA"],
"94964": ["San Quentin", "California", "CA"],
"94965": ["Sausalito", "California", "CA"],
"94970": ["Stinson Beach", "California", "CA"],
"94971": ["Tomales", "California", "CA"],
"94972": ["Valley Ford", "California", "CA"],
"94973": ["Woodacre", "California", "CA"],
"95002": ["Alviso", "California", "CA"],
"95003": ["Aptos", "California", "CA"],
"95004": ["Aromas", "California", "CA"],
"95005": ["Ben Lomond", "California", "CA"],
"95006": ["Boulder Creek", "California", "CA"],
"95007": ["Brookdale", "California", "CA"],
"95008": ["Campbell", "California", "CA"],
"95010": ["Capitola", "California", "CA"],
"95012": ["Castroville", "California", "CA"],
"95013": ["Coyote", "California", "CA"],
"95014": ["Cupertino", "California", "CA"],
"95017": ["Davenport", "California", "CA"],
"95018": ["Felton", "California", "CA"],
"95019": ["Freedom", "California", "CA"],
"95020": ["Gilroy", "California", "CA"],
"95023": ["Hollister", "California", "CA"],
"95030": ["Los Gatos", "California", "CA"],
"95032": ["Los Gatos", "California", "CA"],
"95033": ["Los Gatos", "California", "CA"],
"95035": ["Milpitas", "California", "CA"],
"95037": ["Morgan Hill", "California", "CA"],
"95039": ["Moss Landing", "California", "CA"],
"95041": ["Mount Hermon", "California", "CA"],
"95043": ["Paicines", "California", "CA"],
"95045": ["San Juan Bautista", "California", "CA"],
"95046": ["San Martin", "California", "CA"],
"95050": ["Santa Clara", "California", "CA"],
"95051": ["Santa Clara", "California", "CA"],
"95053": ["Santa Clara", "California", "CA"],
"95054": ["Santa Clara", "California", "CA"],
"95060": ["Santa Cruz", "California", "CA"],
"95062": ["Santa Cruz", "California", "CA"],
"95064": ["Santa Cruz", "California", "CA"],
"95065": ["Santa Cruz", "California", "CA"],
"95066": ["Scotts Valley", "California", "CA"],
"95070": ["Saratoga", "California", "CA"],
"95073": ["Soquel", "California", "CA"],
"95075": ["Tres Pinos", "California", "CA"],
"95076": ["Watsonville", "California", "CA"],
"95110": ["San Jose", "California", "CA"],
"95111": ["San Jose", "California", "CA"],
"95112": ["San Jose", "California", "CA"],
"95113": ["San Jose", "California", "CA"],
"95116": ["San Jose", "California", "CA"],
"95117": ["San Jose", "California", "CA"],
"95118": ["San Jose", "California", "CA"],
"95119": ["San Jose", "California", "CA"],
"95120": ["San Jose", "California", "CA"],
"95121": ["San Jose", "California", "CA"],
"95122": ["San Jose", "California", "CA"],
"95123": ["San Jose", "California", "CA"],
"95124": ["San Jose", "California", "CA"],
"95125": ["San Jose", "California", "CA"],
"95126": ["San Jose", "California", "CA"],
"95127": ["San Jose", "California", "CA"],
"95128": ["San Jose", "California", "CA"],
"95129": ["San Jose", "California", "CA"],
"95130": ["San Jose", "California", "CA"],
"95131": ["San Jose", "California", "CA"],
"95132": ["San Jose", "California", "CA"],
"95133": ["San Jose", "California", "CA"],
"95134": ["San Jose", "California", "CA"],
"95135": ["San Jose", "California", "CA"],
"95136": ["San Jose", "California", "CA"],
"95138": ["San Jose", "California", "CA"],
"95139": ["San Jose", "California", "CA"],
"95140": ["Mount Hamilton", "California", "CA"],
"95148": ["San Jose", "California", "CA"],
"95202": ["Stockton", "California", "CA"],
"95203": ["Stockton", "California", "CA"],
"95204": ["Stockton", "California", "CA"],
"95205": ["Stockton", "California", "CA"],
"95206": ["Stockton", "California", "CA"],
"95207": ["Stockton", "California", "CA"],
"95209": ["Stockton", "California", "CA"],
"95210": ["Stockton", "California", "CA"],
"95211": ["Stockton", "California", "CA"],
"95212": ["Stockton", "California", "CA"],
"95215": ["Stockton", "California", "CA"],
"95219": ["Stockton", "California", "CA"],
"95220": ["Acampo", "California", "CA"],
"95221": ["Altaville", "California", "CA"],
"95222": ["Angels Camp", "California", "CA"],
"95223": ["Arnold", "California", "CA"],
"95224": ["Avery", "California", "CA"],
"95225": ["Burson", "California", "CA"],
"95226": ["Campo Seco", "California", "CA"],
"95227": ["Clements", "California", "CA"],
"95228": ["Copperopolis", "California", "CA"],
"95230": ["Farmington", "California", "CA"],
"95231": ["French Camp", "California", "CA"],
"95232": ["Glencoe", "California", "CA"],
"95233": ["Hathaway Pines", "California", "CA"],
"95236": ["Linden", "California", "CA"],
"95237": ["Lockeford", "California", "CA"],
"95240": ["Lodi", "California", "CA"],
"95242": ["Lodi", "California", "CA"],
"95245": ["Mokelumne Hill", "California", "CA"],
"95246": ["Mountain Ranch", "California", "CA"],
"95247": ["Murphys", "California", "CA"],
"95248": ["Rail Road Flat", "California", "CA"],
"95249": ["San Andreas", "California", "CA"],
"95251": ["Vallecito", "California", "CA"],
"95252": ["Valley Springs", "California", "CA"],
"95253": ["Victor", "California", "CA"],
"95254": ["Wallace", "California", "CA"],
"95255": ["West Point", "California", "CA"],
"95257": ["Wilseyville", "California", "CA"],
"95258": ["Woodbridge", "California", "CA"],
"95301": ["Atwater", "California", "CA"],
"95303": ["Ballico", "California", "CA"],
"95304": ["Tracy", "California", "CA"],
"95305": ["Big Oak Flat", "California", "CA"],
"95306": ["Catheys Valley", "California", "CA"],
"95307": ["Ceres", "California", "CA"],
"95309": ["Chinese Camp", "California", "CA"],
"95310": ["Columbia", "California", "CA"],
"95311": ["Coulterville", "California", "CA"],
"95312": ["Cressey", "California", "CA"],
"95313": ["Crows Landing", "California", "CA"],
"95315": ["Delhi", "California", "CA"],
"95316": ["Denair", "California", "CA"],
"95317": ["El Nido", "California", "CA"],
"95318": ["El Portal", "California", "CA"],
"95319": ["Empire", "California", "CA"],
"95320": ["Escalon", "California", "CA"],
"95321": ["Groveland", "California", "CA"],
"95322": ["Gustine", "California", "CA"],
"95323": ["Hickman", "California", "CA"],
"95324": ["Hilmar", "California", "CA"],
"95325": ["Hornitos", "California", "CA"],
"95326": ["Hughson", "California", "CA"],
"95327": ["Jamestown", "California", "CA"],
"95328": ["Keyes", "California", "CA"],
"95329": ["La Grange", "California", "CA"],
"95330": ["Lathrop", "California", "CA"],
"95333": ["Le Grand", "California", "CA"],
"95334": ["Livingston", "California", "CA"],
"95335": ["Long Barn", "California", "CA"],
"95336": ["Manteca", "California", "CA"],
"95337": ["Manteca", "California", "CA"],
"95338": ["Mariposa", "California", "CA"],
"95340": ["Merced", "California", "CA"],
"95341": ["Merced", "California", "CA"],
"95345": ["Midpines", "California", "CA"],
"95346": ["Mi Wuk Village", "California", "CA"],
"95348": ["Merced", "California", "CA"],
"95350": ["Modesto", "California", "CA"],
"95351": ["Modesto", "California", "CA"],
"95354": ["Modesto", "California", "CA"],
"95355": ["Modesto", "California", "CA"],
"95356": ["Modesto", "California", "CA"],
"95357": ["Modesto", "California", "CA"],
"95358": ["Modesto", "California", "CA"],
"95360": ["Newman", "California", "CA"],
"95361": ["Oakdale", "California", "CA"],
"95363": ["Patterson", "California", "CA"],
"95364": ["Pinecrest", "California", "CA"],
"95365": ["Planada", "California", "CA"],
"95366": ["Ripon", "California", "CA"],
"95367": ["Riverbank", "California", "CA"],
"95368": ["Salida", "California", "CA"],
"95369": ["Snelling", "California", "CA"],
"95370": ["Sonora", "California", "CA"],
"95372": ["Soulsbyville", "California", "CA"],
"95374": ["Stevinson", "California", "CA"],
"95375": ["Strawberry", "California", "CA"],
"95376": ["Tracy", "California", "CA"],
"95377": ["Tracy", "California", "CA"],
"95379": ["Tuolumne", "California", "CA"],
"95380": ["Turlock", "California", "CA"],
"95382": ["Turlock", "California", "CA"],
"95383": ["Twain Harte", "California", "CA"],
"95385": ["Vernalis", "California", "CA"],
"95386": ["Waterford", "California", "CA"],
"95387": ["Westley", "California", "CA"],
"95388": ["Winton", "California", "CA"],
"95389": ["Yosemite National Park", "California", "CA"],
"95391": ["Tracy", "California", "CA"],
"95401": ["Santa Rosa", "California", "CA"],
"95403": ["Santa Rosa", "California", "CA"],
"95404": ["Santa Rosa", "California", "CA"],
"95405": ["Santa Rosa", "California", "CA"],
"95407": ["Santa Rosa", "California", "CA"],
"95409": ["Santa Rosa", "California", "CA"],
"95410": ["Albion", "California", "CA"],
"95412": ["Annapolis", "California", "CA"],
"95415": ["Boonville", "California", "CA"],
"95417": ["Branscomb", "California", "CA"],
"95419": ["Camp Meeker", "California", "CA"],
"95420": ["Caspar", "California", "CA"],
"95421": ["Cazadero", "California", "CA"],
"95422": ["Clearlake", "California", "CA"],
"95423": ["Clearlake Oaks", "California", "CA"],
"95425": ["Cloverdale", "California", "CA"],
"95426": ["Cobb", "California", "CA"],
"95427": ["Comptche", "California", "CA"],
"95428": ["Covelo", "California", "CA"],
"95429": ["Dos Rios", "California", "CA"],
"95430": ["Duncans Mills", "California", "CA"],
"95431": ["Eldridge", "California", "CA"],
"95432": ["Elk", "California", "CA"],
"95435": ["Finley", "California", "CA"],
"95436": ["Forestville", "California", "CA"],
"95437": ["Fort Bragg", "California", "CA"],
"95439": ["Fulton", "California", "CA"],
"95441": ["Geyserville", "California", "CA"],
"95442": ["Glen Ellen", "California", "CA"],
"95443": ["Glenhaven", "California", "CA"],
"95444": ["Graton", "California", "CA"],
"95445": ["Gualala", "California", "CA"],
"95446": ["Guerneville", "California", "CA"],
"95448": ["Healdsburg", "California", "CA"],
"95449": ["Hopland", "California", "CA"],
"95450": ["Jenner", "California", "CA"],
"95451": ["Kelseyville", "California", "CA"],
"95452": ["Kenwood", "California", "CA"],
"95453": ["Lakeport", "California", "CA"],
"95454": ["Laytonville", "California", "CA"],
"95456": ["Little River", "California", "CA"],
"95457": ["Lower Lake", "California", "CA"],
"95458": ["Lucerne", "California", "CA"],
"95459": ["Manchester", "California", "CA"],
"95460": ["Mendocino", "California", "CA"],
"95461": ["Middletown", "California", "CA"],
"95462": ["Monte Rio", "California", "CA"],
"95463": ["Navarro", "California", "CA"],
"95464": ["Nice", "California", "CA"],
"95465": ["Occidental", "California", "CA"],
"95466": ["Philo", "California", "CA"],
"95467": ["Hidden Valley Lake", "California", "CA"],
"95468": ["Point Arena", "California", "CA"],
"95469": ["Potter Valley", "California", "CA"],
"95470": ["Redwood Valley", "California", "CA"],
"95471": ["Rio Nido", "California", "CA"],
"95472": ["Sebastopol", "California", "CA"],
"95476": ["Sonoma", "California", "CA"],
"95480": ["Stewarts Point", "California", "CA"],
"95482": ["Ukiah", "California", "CA"],
"95485": ["Upper Lake", "California", "CA"],
"95486": ["Villa Grande", "California", "CA"],
"95488": ["Westport", "California", "CA"],
"95490": ["Willits", "California", "CA"],
"95492": ["Windsor", "California", "CA"],
"95493": ["Witter Springs", "California", "CA"],
"95494": ["Yorkville", "California", "CA"],
"95497": ["The Sea Ranch", "California", "CA"],
"95501": ["Eureka", "California", "CA"],
"95503": ["Eureka", "California", "CA"],
"95511": ["Alderpoint", "California", "CA"],
"95514": ["Blocksburg", "California", "CA"],
"95519": ["Mckinleyville", "California", "CA"],
"95521": ["Arcata", "California", "CA"],
"95524": ["Bayside", "California", "CA"],
"95525": ["Blue Lake", "California", "CA"],
"95526": ["Bridgeville", "California", "CA"],
"95527": ["Burnt Ranch", "California", "CA"],
"95528": ["Carlotta", "California", "CA"],
"95531": ["Crescent City", "California", "CA"],
"95536": ["Ferndale", "California", "CA"],
"95537": ["Fields Landing", "California", "CA"],
"95538": ["Fort Dick", "California", "CA"],
"95540": ["Fortuna", "California", "CA"],
"95542": ["Garberville", "California", "CA"],
"95543": ["Gasquet", "California", "CA"],
"95545": ["Honeydew", "California", "CA"],
"95546": ["Hoopa", "California", "CA"],
"95547": ["Hydesville", "California", "CA"],
"95548": ["Klamath", "California", "CA"],
"95549": ["Kneeland", "California", "CA"],
"95550": ["Korbel", "California", "CA"],
"95551": ["Loleta", "California", "CA"],
"95552": ["Mad River", "California", "CA"],
"95553": ["Miranda", "California", "CA"],
"95554": ["Myers Flat", "California", "CA"],
"95555": ["Orick", "California", "CA"],
"95556": ["Orleans", "California", "CA"],
"95558": ["Petrolia", "California", "CA"],
"95559": ["Phillipsville", "California", "CA"],
"95560": ["Redway", "California", "CA"],
"95562": ["Rio Dell", "California", "CA"],
"95563": ["Salyer", "California", "CA"],
"95564": ["Samoa", "California", "CA"],
"95565": ["Scotia", "California", "CA"],
"95567": ["Smith River", "California", "CA"],
"95568": ["Somes Bar", "California", "CA"],
"95569": ["Redcrest", "California", "CA"],
"95570": ["Trinidad", "California", "CA"],
"95571": ["Weott", "California", "CA"],
"95573": ["Willow Creek", "California", "CA"],
"95585": ["Leggett", "California", "CA"],
"95587": ["Piercy", "California", "CA"],
"95589": ["Whitethorn", "California", "CA"],
"95595": ["Zenia", "California", "CA"],
"95601": ["Amador City", "California", "CA"],
"95602": ["Auburn", "California", "CA"],
"95603": ["Auburn", "California", "CA"],
"95604": ["Auburn", "California", "CA"],
"95605": ["West Sacramento", "California", "CA"],
"95606": ["Brooks", "California", "CA"],
"95607": ["Capay", "California", "CA"],
"95608": ["Carmichael", "California", "CA"],
"95610": ["Citrus Heights", "California", "CA"],
"95612": ["Clarksburg", "California", "CA"],
"95613": ["Coloma", "California", "CA"],
"95614": ["Cool", "California", "CA"],
"95615": ["Courtland", "California", "CA"],
"95616": ["Davis", "California", "CA"],
"95618": ["Davis", "California", "CA"],
"95619": ["Diamond Springs", "California", "CA"],
"95620": ["Dixon", "California", "CA"],
"95621": ["Citrus Heights", "California", "CA"],
"95623": ["El Dorado", "California", "CA"],
"95624": ["Elk Grove", "California", "CA"],
"95625": ["Elmira", "California", "CA"],
"95626": ["Elverta", "California", "CA"],
"95627": ["Esparto", "California", "CA"],
"95628": ["Fair Oaks", "California", "CA"],
"95629": ["Fiddletown", "California", "CA"],
"95630": ["Folsom", "California", "CA"],
"95631": ["Foresthill", "California", "CA"],
"95632": ["Galt", "California", "CA"],
"95633": ["Garden Valley", "California", "CA"],
"95634": ["Georgetown", "California", "CA"],
"95635": ["Greenwood", "California", "CA"],
"95636": ["Grizzly Flats", "California", "CA"],
"95637": ["Guinda", "California", "CA"],
"95638": ["Herald", "California", "CA"],
"95639": ["Hood", "California", "CA"],
"95640": ["Ione", "California", "CA"],
"95641": ["Isleton", "California", "CA"],
"95642": ["Jackson", "California", "CA"],
"95645": ["Knights Landing", "California", "CA"],
"95646": ["Kirkwood", "California", "CA"],
"95648": ["Lincoln", "California", "CA"],
"95650": ["Loomis", "California", "CA"],
"95651": ["Lotus", "California", "CA"],
"95652": ["Mcclellan", "California", "CA"],
"95653": ["Madison", "California", "CA"],
"95655": ["Mather", "California", "CA"],
"95658": ["Newcastle", "California", "CA"],
"95659": ["Nicolaus", "California", "CA"],
"95660": ["North Highlands", "California", "CA"],
"95661": ["Roseville", "California", "CA"],
"95662": ["Orangevale", "California", "CA"],
"95663": ["Penryn", "California", "CA"],
"95664": ["Pilot Hill", "California", "CA"],
"95665": ["Pine Grove", "California", "CA"],
"95666": ["Pioneer", "California", "CA"],
"95667": ["Placerville", "California", "CA"],
"95668": ["Pleasant Grove", "California", "CA"],
"95669": ["Plymouth", "California", "CA"],
"95670": ["Rancho Cordova", "California", "CA"],
"95671": ["Represa", "California", "CA"],
"95672": ["Rescue", "California", "CA"],
"95673": ["Rio Linda", "California", "CA"],
"95674": ["Rio Oso", "California", "CA"],
"95675": ["River Pines", "California", "CA"],
"95676": ["Robbins", "California", "CA"],
"95677": ["Rocklin", "California", "CA"],
"95678": ["Roseville", "California", "CA"],
"95679": ["Rumsey", "California", "CA"],
"95680": ["Ryde", "California", "CA"],
"95681": ["Sheridan", "California", "CA"],
"95682": ["Shingle Springs", "California", "CA"],
"95683": ["Sloughhouse", "California", "CA"],
"95684": ["Somerset", "California", "CA"],
"95685": ["Sutter Creek", "California", "CA"],
"95686": ["Thornton", "California", "CA"],
"95687": ["Vacaville", "California", "CA"],
"95688": ["Vacaville", "California", "CA"],
"95689": ["Volcano", "California", "CA"],
"95690": ["Walnut Grove", "California", "CA"],
"95691": ["West Sacramento", "California", "CA"],
"95692": ["Wheatland", "California", "CA"],
"95693": ["Wilton", "California", "CA"],
"95694": ["Winters", "California", "CA"],
"95695": ["Woodland", "California", "CA"],
"95697": ["Yolo", "California", "CA"],
"95698": ["Zamora", "California", "CA"],
"95699": ["Drytown", "California", "CA"],
"95701": ["Alta", "California", "CA"],
"95703": ["Applegate", "California", "CA"],
"95709": ["Camino", "California", "CA"],
"95713": ["Colfax", "California", "CA"],
"95714": ["Dutch Flat", "California", "CA"],
"95715": ["Emigrant Gap", "California", "CA"],
"95717": ["Gold Run", "California", "CA"],
"95720": ["Kyburz", "California", "CA"],
"95721": ["Echo Lake", "California", "CA"],
"95722": ["Meadow Vista", "California", "CA"],
"95726": ["Pollock Pines", "California", "CA"],
"95728": ["Soda Springs", "California", "CA"],
"95735": ["Twin Bridges", "California", "CA"],
"95736": ["Weimar", "California", "CA"],
"95742": ["Rancho Cordova", "California", "CA"],
"95746": ["Granite Bay", "California", "CA"],
"95747": ["Roseville", "California", "CA"],
"95757": ["Elk Grove", "California", "CA"],
"95758": ["Elk Grove", "California", "CA"],
"95762": ["El Dorado Hills", "California", "CA"],
"95765": ["Rocklin", "California", "CA"],
"95776": ["Woodland", "California", "CA"],
"95811": ["Sacramento", "California", "CA"],
"95814": ["Sacramento", "California", "CA"],
"95815": ["Sacramento", "California", "CA"],
"95816": ["Sacramento", "California", "CA"],
"95817": ["Sacramento", "California", "CA"],
"95818": ["Sacramento", "California", "CA"],
"95819": ["Sacramento", "California", "CA"],
"95820": ["Sacramento", "California", "CA"],
"95821": ["Sacramento", "California", "CA"],
"95822": ["Sacramento", "California", "CA"],
"95823": ["Sacramento", "California", "CA"],
"95824": ["Sacramento", "California", "CA"],
"95825": ["Sacramento", "California", "CA"],
"95826": ["Sacramento", "California", "CA"],
"95827": ["Sacramento", "California", "CA"],
"95828": ["Sacramento", "California", "CA"],
"95829": ["Sacramento", "California", "CA"],
"95830": ["Sacramento", "California", "CA"],
"95831": ["Sacramento", "California", "CA"],
"95832": ["Sacramento", "California", "CA"],
"95833": ["Sacramento", "California", "CA"],
"95834": ["Sacramento", "California", "CA"],
"95835": ["Sacramento", "California", "CA"],
"95837": ["Sacramento", "California", "CA"],
"95838": ["Sacramento", "California", "CA"],
"95841": ["Sacramento", "California", "CA"],
"95842": ["Sacramento", "California", "CA"],
"95843": ["Antelope", "California", "CA"],
"95864": ["Sacramento", "California", "CA"],
"95901": ["Marysville", "California", "CA"],
"95903": ["Beale Afb", "California", "CA"],
"95910": ["Alleghany", "California", "CA"],
"95912": ["Arbuckle", "California", "CA"],
"95913": ["Artois", "California", "CA"],
"95914": ["Bangor", "California", "CA"],
"95915": ["Belden", "California", "CA"],
"95916": ["Berry Creek", "California", "CA"],
"95917": ["Biggs", "California", "CA"],
"95918": ["Browns Valley", "California", "CA"],
"95919": ["Brownsville", "California", "CA"],
"95920": ["Butte City", "California", "CA"],
"95922": ["Camptonville", "California", "CA"],
"95923": ["Canyon Dam", "California", "CA"],
"95925": ["Challenge", "California", "CA"],
"95926": ["Chico", "California", "CA"],
"95928": ["Chico", "California", "CA"],
"95929": ["Chico", "California", "CA"],
"95930": ["Clipper Mills", "California", "CA"],
"95932": ["Colusa", "California", "CA"],
"95934": ["Crescent Mills", "California", "CA"],
"95935": ["Dobbins", "California", "CA"],
"95936": ["Downieville", "California", "CA"],
"95937": ["Dunnigan", "California", "CA"],
"95938": ["Durham", "California", "CA"],
"95939": ["Elk Creek", "California", "CA"],
"95941": ["Forbestown", "California", "CA"],
"95942": ["Forest Ranch", "California", "CA"],
"95943": ["Glenn", "California", "CA"],
"95944": ["Goodyears Bar", "California", "CA"],
"95945": ["Grass Valley", "California", "CA"],
"95946": ["Penn Valley", "California", "CA"],
"95947": ["Greenville", "California", "CA"],
"95948": ["Gridley", "California", "CA"],
"95949": ["Grass Valley", "California", "CA"],
"95950": ["Grimes", "California", "CA"],
"95951": ["Hamilton City", "California", "CA"],
"95953": ["Live Oak", "California", "CA"],
"95954": ["Magalia", "California", "CA"],
"95955": ["Maxwell", "California", "CA"],
"95956": ["Meadow Valley", "California", "CA"],
"95957": ["Meridian", "California", "CA"],
"95958": ["Nelson", "California", "CA"],
"95959": ["Nevada City", "California", "CA"],
"95960": ["North San Juan", "California", "CA"],
"95961": ["Olivehurst", "California", "CA"],
"95962": ["Oregon House", "California", "CA"],
"95963": ["Orland", "California", "CA"],
"95965": ["Oroville", "California", "CA"],
"95966": ["Oroville", "California", "CA"],
"95968": ["Palermo", "California", "CA"],
"95969": ["Paradise", "California", "CA"],
"95970": ["Princeton", "California", "CA"],
"95971": ["Quincy", "California", "CA"],
"95972": ["Rackerby", "California", "CA"],
"95973": ["Chico", "California", "CA"],
"95974": ["Richvale", "California", "CA"],
"95975": ["Rough And Ready", "California", "CA"],
"95977": ["Smartsville", "California", "CA"],
"95978": ["Stirling City", "California", "CA"],
"95979": ["Stonyford", "California", "CA"],
"95981": ["Strawberry Valley", "California", "CA"],
"95982": ["Sutter", "California", "CA"],
"95983": ["Taylorsville", "California", "CA"],
"95984": ["Twain", "California", "CA"],
"95986": ["Washington", "California", "CA"],
"95987": ["Williams", "California", "CA"],
"95988": ["Willows", "California", "CA"],
"95991": ["Yuba City", "California", "CA"],
"95993": ["Yuba City", "California", "CA"],
"96001": ["Redding", "California", "CA"],
"96002": ["Redding", "California", "CA"],
"96003": ["Redding", "California", "CA"],
"96006": ["Adin", "California", "CA"],
"96007": ["Anderson", "California", "CA"],
"96008": ["Bella Vista", "California", "CA"],
"96009": ["Bieber", "California", "CA"],
"96010": ["Big Bar", "California", "CA"],
"96011": ["Big Bend", "California", "CA"],
"96013": ["Burney", "California", "CA"],
"96014": ["Callahan", "California", "CA"],
"96015": ["Canby", "California", "CA"],
"96016": ["Cassel", "California", "CA"],
"96017": ["Castella", "California", "CA"],
"96019": ["Shasta Lake", "California", "CA"],
"96020": ["Chester", "California", "CA"],
"96021": ["Corning", "California", "CA"],
"96022": ["Cottonwood", "California", "CA"],
"96023": ["Dorris", "California", "CA"],
"96024": ["Douglas City", "California", "CA"],
"96025": ["Dunsmuir", "California", "CA"],
"96027": ["Etna", "California", "CA"],
"96028": ["Fall River Mills", "California", "CA"],
"96029": ["Flournoy", "California", "CA"],
"96031": ["Forks Of Salmon", "California", "CA"],
"96032": ["Fort Jones", "California", "CA"],
"96033": ["French Gulch", "California", "CA"],
"96034": ["Gazelle", "California", "CA"],
"96035": ["Gerber", "California", "CA"],
"96037": ["Greenview", "California", "CA"],
"96038": ["Grenada", "California", "CA"],
"96039": ["Happy Camp", "California", "CA"],
"96040": ["Hat Creek", "California", "CA"],
"96041": ["Hayfork", "California", "CA"],
"96044": ["Hornbrook", "California", "CA"],
"96046": ["Hyampom", "California", "CA"],
"96047": ["Igo", "California", "CA"],
"96048": ["Junction City", "California", "CA"],
"96050": ["Klamath River", "California", "CA"],
"96051": ["Lakehead", "California", "CA"],
"96052": ["Lewiston", "California", "CA"],
"96054": ["Lookout", "California", "CA"],
"96055": ["Los Molinos", "California", "CA"],
"96056": ["Mcarthur", "California", "CA"],
"96057": ["Mccloud", "California", "CA"],
"96058": ["Macdoel", "California", "CA"],
"96059": ["Manton", "California", "CA"],
"96061": ["Mill Creek", "California", "CA"],
"96062": ["Millville", "California", "CA"],
"96063": ["Mineral", "California", "CA"],
"96064": ["Montague", "California", "CA"],
"96065": ["Montgomery Creek", "California", "CA"],
"96067": ["Mount Shasta", "California", "CA"],
"96068": ["Nubieber", "California", "CA"],
"96069": ["Oak Run", "California", "CA"],
"96071": ["Old Station", "California", "CA"],
"96073": ["Palo Cedro", "California", "CA"],
"96074": ["Paskenta", "California", "CA"],
"96075": ["Paynes Creek", "California", "CA"],
"96076": ["Platina", "California", "CA"],
"96078": ["Proberta", "California", "CA"],
"96080": ["Red Bluff", "California", "CA"],
"96084": ["Round Mountain", "California", "CA"],
"96085": ["Scott Bar", "California", "CA"],
"96086": ["Seiad Valley", "California", "CA"],
"96087": ["Shasta", "California", "CA"],
"96088": ["Shingletown", "California", "CA"],
"96090": ["Tehama", "California", "CA"],
"96091": ["Trinity Center", "California", "CA"],
"96092": ["Vina", "California", "CA"],
"96093": ["Weaverville", "California", "CA"],
"96094": ["Weed", "California", "CA"],
"96096": ["Whitmore", "California", "CA"],
"96097": ["Yreka", "California", "CA"],
"96101": ["Alturas", "California", "CA"],
"96103": ["Blairsden Graeagle", "California", "CA"],
"96104": ["Cedarville", "California", "CA"],
"96105": ["Chilcoot", "California", "CA"],
"96106": ["Clio", "California", "CA"],
"96107": ["Coleville", "California", "CA"],
"96108": ["Davis Creek", "California", "CA"],
"96109": ["Doyle", "California", "CA"],
"96110": ["Eagleville", "California", "CA"],
"96111": ["Floriston", "California", "CA"],
"96112": ["Fort Bidwell", "California", "CA"],
"96113": ["Herlong", "California", "CA"],
"96114": ["Janesville", "California", "CA"],
"96115": ["Lake City", "California", "CA"],
"96116": ["Likely", "California", "CA"],
"96117": ["Litchfield", "California", "CA"],
"96118": ["Loyalton", "California", "CA"],
"96119": ["Madeline", "California", "CA"],
"96120": ["Markleeville", "California", "CA"],
"96121": ["Milford", "California", "CA"],
"96122": ["Portola", "California", "CA"],
"96123": ["Ravendale", "California", "CA"],
"96124": ["Calpine", "California", "CA"],
"96125": ["Sierra City", "California", "CA"],
"96126": ["Sierraville", "California", "CA"],
"96128": ["Standish", "California", "CA"],
"96129": ["Beckwourth", "California", "CA"],
"96130": ["Susanville", "California", "CA"],
"96132": ["Termo", "California", "CA"],
"96133": ["Topaz", "California", "CA"],
"96134": ["Tulelake", "California", "CA"],
"96135": ["Vinton", "California", "CA"],
"96136": ["Wendel", "California", "CA"],
"96137": ["Westwood", "California", "CA"],
"96140": ["Carnelian Bay", "California", "CA"],
"96141": ["Homewood", "California", "CA"],
"96142": ["Tahoma", "California", "CA"],
"96143": ["Kings Beach", "California", "CA"],
"96145": ["Tahoe City", "California", "CA"],
"96146": ["Olympic Valley", "California", "CA"],
"96148": ["Tahoe Vista", "California", "CA"],
"96150": ["South Lake Tahoe", "California", "CA"],
"96155": ["South Lake Tahoe", "California", "CA"],
"96161": ["Truckee", "California", "CA"],
"96701": ["Aiea", "Hawaii", "HI"],
"96703": ["Anahola", "Hawaii", "HI"],
"96704": ["Captain Cook", "Hawaii", "HI"],
"96705": ["Eleele", "Hawaii", "HI"],
"96706": ["Ewa Beach", "Hawaii", "HI"],
"96707": ["Kapolei", "Hawaii", "HI"],
"96708": ["Haiku", "Hawaii", "HI"],
"96710": ["Hakalau", "Hawaii", "HI"],
"96712": ["Haleiwa", "Hawaii", "HI"],
"96713": ["Hana", "Hawaii", "HI"],
"96714": ["Hanalei", "Hawaii", "HI"],
"96716": ["Hanapepe", "Hawaii", "HI"],
"96717": ["Hauula", "Hawaii", "HI"],
"96719": ["Hawi", "Hawaii", "HI"],
"96720": ["Hilo", "Hawaii", "HI"],
"96722": ["Princeville", "Hawaii", "HI"],
"96725": ["Holualoa", "Hawaii", "HI"],
"96726": ["Honaunau", "Hawaii", "HI"],
"96727": ["Honokaa", "Hawaii", "HI"],
"96728": ["Honomu", "Hawaii", "HI"],
"96729": ["Hoolehua", "Hawaii", "HI"],
"96730": ["Kaaawa", "Hawaii", "HI"],
"96731": ["Kahuku", "Hawaii", "HI"],
"96732": ["Kahului", "Hawaii", "HI"],
"96734": ["Kailua", "Hawaii", "HI"],
"96737": ["Ocean View", "Hawaii", "HI"],
"96738": ["Waikoloa", "Hawaii", "HI"],
"96740": ["Kailua Kona", "Hawaii", "HI"],
"96741": ["Kalaheo", "Hawaii", "HI"],
"96742": ["Kalaupapa", "Hawaii", "HI"],
"96743": ["Kamuela", "Hawaii", "HI"],
"96744": ["Kaneohe", "Hawaii", "HI"],
"96746": ["Kapaa", "Hawaii", "HI"],
"96747": ["Kaumakani", "Hawaii", "HI"],
"96748": ["Kaunakakai", "Hawaii", "HI"],
"96749": ["Keaau", "Hawaii", "HI"],
"96750": ["Kealakekua", "Hawaii", "HI"],
"96751": ["Kealia", "Hawaii", "HI"],
"96752": ["Kekaha", "Hawaii", "HI"],
"96753": ["Kihei", "Hawaii", "HI"],
"96754": ["Kilauea", "Hawaii", "HI"],
"96755": ["Kapaau", "Hawaii", "HI"],
"96756": ["Koloa", "Hawaii", "HI"],
"96757": ["Kualapuu", "Hawaii", "HI"],
"96759": ["Kunia", "Hawaii", "HI"],
"96760": ["Kurtistown", "Hawaii", "HI"],
"96761": ["Lahaina", "Hawaii", "HI"],
"96762": ["Laie", "Hawaii", "HI"],
"96763": ["Lanai City", "Hawaii", "HI"],
"96764": ["Laupahoehoe", "Hawaii", "HI"],
"96765": ["Lawai", "Hawaii", "HI"],
"96766": ["Lihue", "Hawaii", "HI"],
"96768": ["Makawao", "Hawaii", "HI"],
"96769": ["Makaweli", "Hawaii", "HI"],
"96770": ["Maunaloa", "Hawaii", "HI"],
"96771": ["Mountain View", "Hawaii", "HI"],
"96772": ["Naalehu", "Hawaii", "HI"],
"96773": ["Ninole", "Hawaii", "HI"],
"96774": ["Ookala", "Hawaii", "HI"],
"96776": ["Paauilo", "Hawaii", "HI"],
"96777": ["Pahala", "Hawaii", "HI"],
"96778": ["Pahoa", "Hawaii", "HI"],
"96779": ["Paia", "Hawaii", "HI"],
"96780": ["Papaaloa", "Hawaii", "HI"],
"96781": ["Papaikou", "Hawaii", "HI"],
"96782": ["Pearl City", "Hawaii", "HI"],
"96783": ["Pepeekeo", "Hawaii", "HI"],
"96785": ["Volcano", "Hawaii", "HI"],
"96786": ["Wahiawa", "Hawaii", "HI"],
"96789": ["Mililani", "Hawaii", "HI"],
"96790": ["Kula", "Hawaii", "HI"],
"96791": ["Waialua", "Hawaii", "HI"],
"96792": ["Waianae", "Hawaii", "HI"],
"96793": ["Wailuku", "Hawaii", "HI"],
"96795": ["Waimanalo", "Hawaii", "HI"],
"96796": ["Waimea", "Hawaii", "HI"],
"96797": ["Waipahu", "Hawaii", "HI"],
"96799": ["Pago Pago", "American Samoa"],
"96813": ["Honolulu", "Hawaii", "HI"],
"96814": ["Honolulu", "Hawaii", "HI"],
"96815": ["Honolulu", "Hawaii", "HI"],
"96816": ["Honolulu", "Hawaii", "HI"],
"96817": ["Honolulu", "Hawaii", "HI"],
"96818": ["Honolulu", "Hawaii", "HI"],
"96819": ["Honolulu", "Hawaii", "HI"],
"96820": ["Honolulu", "Hawaii", "HI"],
"96821": ["Honolulu", "Hawaii", "HI"],
"96822": ["Honolulu", "Hawaii", "HI"],
"96825": ["Honolulu", "Hawaii", "HI"],
"96826": ["Honolulu", "Hawaii", "HI"],
"96848": ["Honolulu", "Hawaii", "HI"],
"96850": ["Honolulu", "Hawaii", "HI"],
"96853": ["Jbphh", "Hawaii", "HI"],
"96857": ["Schofield Barracks", "Hawaii", "HI"],
"96858": ["Fort Shafter", "Hawaii", "HI"],
"96859": ["Tripler Army Medical Center", "Hawaii", "HI"],
"96860": ["Jbphh", "Hawaii", "HI"],
"96863": ["Mcbh Kaneohe Bay", "Hawaii", "HI"],
"96910": ["Hagatna", "Guam", "GU"],
"96913": ["Barrigada", "Guam", "GU"],
"96915": ["Santa Rita", "Guam", "GU"],
"96916": ["Merizo", "Guam", "GU"],
"96917": ["Inarajan", "Guam", "GU"],
"96928": ["Agat", "Guam", "GU"],
"96929": ["Yigo", "Guam", "GU"],
"96950": ["Saipan", "Northern Mariana Islands", "MP"],
"96951": ["Rota", "Northern Mariana Islands", "MP"],
"96952": ["Tinian", "Northern Mariana Islands", "MP"],
"97001": ["Antelope", "Oregon", "OR"],
"97002": ["Aurora", "Oregon", "OR"],
"97003": ["Beaverton", "Oregon", "OR"],
"97004": ["Beavercreek", "Oregon", "OR"],
"97005": ["Beaverton", "Oregon", "OR"],
"97006": ["Beaverton", "Oregon", "OR"],
"97007": ["Beaverton", "Oregon", "OR"],
"97008": ["Beaverton", "Oregon", "OR"],
"97009": ["Boring", "Oregon", "OR"],
"97011": ["Brightwood", "Oregon", "OR"],
"97013": ["Canby", "Oregon", "OR"],
"97014": ["Cascade Locks", "Oregon", "OR"],
"97015": ["Clackamas", "Oregon", "OR"],
"97016": ["Clatskanie", "Oregon", "OR"],
"97017": ["Colton", "Oregon", "OR"],
"97018": ["Columbia City", "Oregon", "OR"],
"97019": ["Corbett", "Oregon", "OR"],
"97020": ["Donald", "Oregon", "OR"],
"97021": ["Dufur", "Oregon", "OR"],
"97022": ["Eagle Creek", "Oregon", "OR"],
"97023": ["Estacada", "Oregon", "OR"],
"97024": ["Fairview", "Oregon", "OR"],
"97026": ["Gervais", "Oregon", "OR"],
"97027": ["Gladstone", "Oregon", "OR"],
"97028": ["Government Camp", "Oregon", "OR"],
"97029": ["Grass Valley", "Oregon", "OR"],
"97030": ["Gresham", "Oregon", "OR"],
"97031": ["Hood River", "Oregon", "OR"],
"97032": ["Hubbard", "Oregon", "OR"],
"97033": ["Kent", "Oregon", "OR"],
"97034": ["Lake Oswego", "Oregon", "OR"],
"97035": ["Lake Oswego", "Oregon", "OR"],
"97037": ["Maupin", "Oregon", "OR"],
"97038": ["Molalla", "Oregon", "OR"],
"97039": ["Moro", "Oregon", "OR"],
"97040": ["Mosier", "Oregon", "OR"],
"97041": ["Mount Hood Parkdale", "Oregon", "OR"],
"97042": ["Mulino", "Oregon", "OR"],
"97045": ["Oregon City", "Oregon", "OR"],
"97048": ["Rainier", "Oregon", "OR"],
"97049": ["Rhododendron", "Oregon", "OR"],
"97050": ["Rufus", "Oregon", "OR"],
"97051": ["Saint Helens", "Oregon", "OR"],
"97053": ["Warren", "Oregon", "OR"],
"97054": ["Deer Island", "Oregon", "OR"],
"97055": ["Sandy", "Oregon", "OR"],
"97056": ["Scappoose", "Oregon", "OR"],
"97057": ["Shaniko", "Oregon", "OR"],
"97058": ["The Dalles", "Oregon", "OR"],
"97060": ["Troutdale", "Oregon", "OR"],
"97062": ["Tualatin", "Oregon", "OR"],
"97063": ["Tygh Valley", "Oregon", "OR"],
"97064": ["Vernonia", "Oregon", "OR"],
"97065": ["Wasco", "Oregon", "OR"],
"97067": ["Welches", "Oregon", "OR"],
"97068": ["West Linn", "Oregon", "OR"],
"97070": ["Wilsonville", "Oregon", "OR"],
"97071": ["Woodburn", "Oregon", "OR"],
"97078": ["Beaverton", "Oregon", "OR"],
"97080": ["Gresham", "Oregon", "OR"],
"97086": ["Happy Valley", "Oregon", "OR"],
"97089": ["Damascus", "Oregon", "OR"],
"97101": ["Amity", "Oregon", "OR"],
"97102": ["Arch Cape", "Oregon", "OR"],
"97103": ["Astoria", "Oregon", "OR"],
"97106": ["Banks", "Oregon", "OR"],
"97107": ["Bay City", "Oregon", "OR"],
"97108": ["Beaver", "Oregon", "OR"],
"97109": ["Buxton", "Oregon", "OR"],
"97110": ["Cannon Beach", "Oregon", "OR"],
"97111": ["Carlton", "Oregon", "OR"],
"97112": ["Cloverdale", "Oregon", "OR"],
"97113": ["Cornelius", "Oregon", "OR"],
"97114": ["Dayton", "Oregon", "OR"],
"97115": ["Dundee", "Oregon", "OR"],
"97116": ["Forest Grove", "Oregon", "OR"],
"97117": ["Gales Creek", "Oregon", "OR"],
"97118": ["Garibaldi", "Oregon", "OR"],
"97119": ["Gaston", "Oregon", "OR"],
"97121": ["Hammond", "Oregon", "OR"],
"97122": ["Hebo", "Oregon", "OR"],
"97123": ["Hillsboro", "Oregon", "OR"],
"97124": ["Hillsboro", "Oregon", "OR"],
"97125": ["Manning", "Oregon", "OR"],
"97127": ["Lafayette", "Oregon", "OR"],
"97128": ["Mcminnville", "Oregon", "OR"],
"97130": ["Manzanita", "Oregon", "OR"],
"97131": ["Nehalem", "Oregon", "OR"],
"97132": ["Newberg", "Oregon", "OR"],
"97133": ["North Plains", "Oregon", "OR"],
"97134": ["Oceanside", "Oregon", "OR"],
"97135": ["Pacific City", "Oregon", "OR"],
"97136": ["Rockaway Beach", "Oregon", "OR"],
"97137": ["Saint Paul", "Oregon", "OR"],
"97138": ["Seaside", "Oregon", "OR"],
"97140": ["Sherwood", "Oregon", "OR"],
"97141": ["Tillamook", "Oregon", "OR"],
"97144": ["Timber", "Oregon", "OR"],
"97145": ["Tolovana Park", "Oregon", "OR"],
"97146": ["Warrenton", "Oregon", "OR"],
"97147": ["Wheeler", "Oregon", "OR"],
"97148": ["Yamhill", "Oregon", "OR"],
"97149": ["Neskowin", "Oregon", "OR"],
"97201": ["Portland", "Oregon", "OR"],
"97202": ["Portland", "Oregon", "OR"],
"97203": ["Portland", "Oregon", "OR"],
"97204": ["Portland", "Oregon", "OR"],
"97205": ["Portland", "Oregon", "OR"],
"97206": ["Portland", "Oregon", "OR"],
"97208": ["Portland", "Oregon", "OR"],
"97209": ["Portland", "Oregon", "OR"],
"97210": ["Portland", "Oregon", "OR"],
"97211": ["Portland", "Oregon", "OR"],
"97212": ["Portland", "Oregon", "OR"],
"97213": ["Portland", "Oregon", "OR"],
"97214": ["Portland", "Oregon", "OR"],
"97215": ["Portland", "Oregon", "OR"],
"97216": ["Portland", "Oregon", "OR"],
"97217": ["Portland", "Oregon", "OR"],
"97218": ["Portland", "Oregon", "OR"],
"97219": ["Portland", "Oregon", "OR"],
"97220": ["Portland", "Oregon", "OR"],
"97221": ["Portland", "Oregon", "OR"],
"97222": ["Portland", "Oregon", "OR"],
"97223": ["Portland", "Oregon", "OR"],
"97224": ["Portland", "Oregon", "OR"],
"97225": ["Portland", "Oregon", "OR"],
"97227": ["Portland", "Oregon", "OR"],
"97229": ["Portland", "Oregon", "OR"],
"97230": ["Portland", "Oregon", "OR"],
"97231": ["Portland", "Oregon", "OR"],
"97232": ["Portland", "Oregon", "OR"],
"97233": ["Portland", "Oregon", "OR"],
"97236": ["Portland", "Oregon", "OR"],
"97239": ["Portland", "Oregon", "OR"],
"97266": ["Portland", "Oregon", "OR"],
"97267": ["Portland", "Oregon", "OR"],
"97301": ["Salem", "Oregon", "OR"],
"97302": ["Salem", "Oregon", "OR"],
"97303": ["Salem", "Oregon", "OR"],
"97304": ["Salem", "Oregon", "OR"],
"97305": ["Salem", "Oregon", "OR"],
"97306": ["Salem", "Oregon", "OR"],
"97310": ["Salem", "Oregon", "OR"],
"97317": ["Salem", "Oregon", "OR"],
"97321": ["Albany", "Oregon", "OR"],
"97322": ["Albany", "Oregon", "OR"],
"97324": ["Alsea", "Oregon", "OR"],
"97325": ["Aumsville", "Oregon", "OR"],
"97326": ["Blodgett", "Oregon", "OR"],
"97327": ["Brownsville", "Oregon", "OR"],
"97329": ["Cascadia", "Oregon", "OR"],
"97330": ["Corvallis", "Oregon", "OR"],
"97331": ["Corvallis", "Oregon", "OR"],
"97333": ["Corvallis", "Oregon", "OR"],
"97335": ["Crabtree", "Oregon", "OR"],
"97336": ["Crawfordsville", "Oregon", "OR"],
"97338": ["Dallas", "Oregon", "OR"],
"97341": ["Depoe Bay", "Oregon", "OR"],
"97342": ["Detroit", "Oregon", "OR"],
"97343": ["Eddyville", "Oregon", "OR"],
"97344": ["Falls City", "Oregon", "OR"],
"97345": ["Foster", "Oregon", "OR"],
"97346": ["Gates", "Oregon", "OR"],
"97347": ["Grand Ronde", "Oregon", "OR"],
"97348": ["Halsey", "Oregon", "OR"],
"97350": ["Idanha", "Oregon", "OR"],
"97351": ["Independence", "Oregon", "OR"],
"97352": ["Jefferson", "Oregon", "OR"],
"97355": ["Lebanon", "Oregon", "OR"],
"97357": ["Logsden", "Oregon", "OR"],
"97358": ["Lyons", "Oregon", "OR"],
"97360": ["Mill City", "Oregon", "OR"],
"97361": ["Monmouth", "Oregon", "OR"],
"97362": ["Mount Angel", "Oregon", "OR"],
"97364": ["Neotsu", "Oregon", "OR"],
"97365": ["Newport", "Oregon", "OR"],
"97366": ["South Beach", "Oregon", "OR"],
"97367": ["Lincoln City", "Oregon", "OR"],
"97368": ["Otis", "Oregon", "OR"],
"97369": ["Otter Rock", "Oregon", "OR"],
"97370": ["Philomath", "Oregon", "OR"],
"97371": ["Rickreall", "Oregon", "OR"],
"97373": ["Saint Benedict", "Oregon", "OR"],
"97374": ["Scio", "Oregon", "OR"],
"97375": ["Scotts Mills", "Oregon", "OR"],
"97376": ["Seal Rock", "Oregon", "OR"],
"97377": ["Shedd", "Oregon", "OR"],
"97378": ["Sheridan", "Oregon", "OR"],
"97380": ["Siletz", "Oregon", "OR"],
"97381": ["Silverton", "Oregon", "OR"],
"97383": ["Stayton", "Oregon", "OR"],
"97384": ["Mehama", "Oregon", "OR"],
"97385": ["Sublimity", "Oregon", "OR"],
"97386": ["Sweet Home", "Oregon", "OR"],
"97388": ["Gleneden Beach", "Oregon", "OR"],
"97389": ["Tangent", "Oregon", "OR"],
"97390": ["Tidewater", "Oregon", "OR"],
"97391": ["Toledo", "Oregon", "OR"],
"97392": ["Turner", "Oregon", "OR"],
"97394": ["Waldport", "Oregon", "OR"],
"97396": ["Willamina", "Oregon", "OR"],
"97401": ["Eugene", "Oregon", "OR"],
"97402": ["Eugene", "Oregon", "OR"],
"97403": ["Eugene", "Oregon", "OR"],
"97404": ["Eugene", "Oregon", "OR"],
"97405": ["Eugene", "Oregon", "OR"],
"97406": ["Agness", "Oregon", "OR"],
"97408": ["Eugene", "Oregon", "OR"],
"97410": ["Azalea", "Oregon", "OR"],
"97411": ["Bandon", "Oregon", "OR"],
"97412": ["Blachly", "Oregon", "OR"],
"97413": ["Blue River", "Oregon", "OR"],
"97414": ["Broadbent", "Oregon", "OR"],
"97415": ["Brookings", "Oregon", "OR"],
"97416": ["Camas Valley", "Oregon", "OR"],
"97417": ["Canyonville", "Oregon", "OR"],
"97419": ["Cheshire", "Oregon", "OR"],
"97420": ["Coos Bay", "Oregon", "OR"],
"97423": ["Coquille", "Oregon", "OR"],
"97424": ["Cottage Grove", "Oregon", "OR"],
"97426": ["Creswell", "Oregon", "OR"],
"97429": ["Days Creek", "Oregon", "OR"],
"97430": ["Deadwood", "Oregon", "OR"],
"97431": ["Dexter", "Oregon", "OR"],
"97432": ["Dillard", "Oregon", "OR"],
"97434": ["Dorena", "Oregon", "OR"],
"97435": ["Drain", "Oregon", "OR"],
"97436": ["Elkton", "Oregon", "OR"],
"97437": ["Elmira", "Oregon", "OR"],
"97438": ["Fall Creek", "Oregon", "OR"],
"97439": ["Florence", "Oregon", "OR"],
"97441": ["Gardiner", "Oregon", "OR"],
"97442": ["Glendale", "Oregon", "OR"],
"97443": ["Glide", "Oregon", "OR"],
"97444": ["Gold Beach", "Oregon", "OR"],
"97446": ["Harrisburg", "Oregon", "OR"],
"97447": ["Idleyld Park", "Oregon", "OR"],
"97448": ["Junction City", "Oregon", "OR"],
"97449": ["Lakeside", "Oregon", "OR"],
"97450": ["Langlois", "Oregon", "OR"],
"97451": ["Lorane", "Oregon", "OR"],
"97452": ["Lowell", "Oregon", "OR"],
"97453": ["Mapleton", "Oregon", "OR"],
"97454": ["Marcola", "Oregon", "OR"],
"97455": ["Pleasant Hill", "Oregon", "OR"],
"97456": ["Monroe", "Oregon", "OR"],
"97457": ["Myrtle Creek", "Oregon", "OR"],
"97458": ["Myrtle Point", "Oregon", "OR"],
"97459": ["North Bend", "Oregon", "OR"],
"97461": ["Noti", "Oregon", "OR"],
"97462": ["Oakland", "Oregon", "OR"],
"97463": ["Oakridge", "Oregon", "OR"],
"97465": ["Port Orford", "Oregon", "OR"],
"97466": ["Powers", "Oregon", "OR"],
"97467": ["Reedsport", "Oregon", "OR"],
"97469": ["Riddle", "Oregon", "OR"],
"97470": ["Roseburg", "Oregon", "OR"],
"97471": ["Roseburg", "Oregon", "OR"],
"97473": ["Scottsburg", "Oregon", "OR"],
"97476": ["Sixes", "Oregon", "OR"],
"97477": ["Springfield", "Oregon", "OR"],
"97478": ["Springfield", "Oregon", "OR"],
"97479": ["Sutherlin", "Oregon", "OR"],
"97480": ["Swisshome", "Oregon", "OR"],
"97481": ["Tenmile", "Oregon", "OR"],
"97484": ["Tiller", "Oregon", "OR"],
"97486": ["Umpqua", "Oregon", "OR"],
"97487": ["Veneta", "Oregon", "OR"],
"97488": ["Vida", "Oregon", "OR"],
"97489": ["Walterville", "Oregon", "OR"],
"97490": ["Walton", "Oregon", "OR"],
"97492": ["Westfir", "Oregon", "OR"],
"97493": ["Westlake", "Oregon", "OR"],
"97494": ["Wilbur", "Oregon", "OR"],
"97495": ["Winchester", "Oregon", "OR"],
"97496": ["Winston", "Oregon", "OR"],
"97497": ["Wolf Creek", "Oregon", "OR"],
"97498": ["Yachats", "Oregon", "OR"],
"97499": ["Yoncalla", "Oregon", "OR"],
"97501": ["Medford", "Oregon", "OR"],
"97502": ["Central Point", "Oregon", "OR"],
"97503": ["White City", "Oregon", "OR"],
"97504": ["Medford", "Oregon", "OR"],
"97520": ["Ashland", "Oregon", "OR"],
"97522": ["Butte Falls", "Oregon", "OR"],
"97523": ["Cave Junction", "Oregon", "OR"],
"97524": ["Eagle Point", "Oregon", "OR"],
"97525": ["Gold Hill", "Oregon", "OR"],
"97526": ["Grants Pass", "Oregon", "OR"],
"97527": ["Grants Pass", "Oregon", "OR"],
"97530": ["Jacksonville", "Oregon", "OR"],
"97531": ["Kerby", "Oregon", "OR"],
"97532": ["Merlin", "Oregon", "OR"],
"97534": ["O'Brien", "Oregon", "OR"],
"97535": ["Phoenix", "Oregon", "OR"],
"97536": ["Prospect", "Oregon", "OR"],
"97537": ["Rogue River", "Oregon", "OR"],
"97538": ["Selma", "Oregon", "OR"],
"97539": ["Shady Cove", "Oregon", "OR"],
"97540": ["Talent", "Oregon", "OR"],
"97541": ["Trail", "Oregon", "OR"],
"97543": ["Wilderville", "Oregon", "OR"],
"97544": ["Williams", "Oregon", "OR"],
"97601": ["Klamath Falls", "Oregon", "OR"],
"97603": ["Klamath Falls", "Oregon", "OR"],
"97604": ["Crater Lake", "Oregon", "OR"],
"97620": ["Adel", "Oregon", "OR"],
"97621": ["Beatty", "Oregon", "OR"],
"97622": ["Bly", "Oregon", "OR"],
"97623": ["Bonanza", "Oregon", "OR"],
"97624": ["Chiloquin", "Oregon", "OR"],
"97625": ["Dairy", "Oregon", "OR"],
"97626": ["Fort Klamath", "Oregon", "OR"],
"97627": ["Keno", "Oregon", "OR"],
"97630": ["Lakeview", "Oregon", "OR"],
"97632": ["Malin", "Oregon", "OR"],
"97633": ["Merrill", "Oregon", "OR"],
"97634": ["Midland", "Oregon", "OR"],
"97635": ["New Pine Creek", "Oregon", "OR"],
"97636": ["Paisley", "Oregon", "OR"],
"97637": ["Plush", "Oregon", "OR"],
"97638": ["Silver Lake", "Oregon", "OR"],
"97639": ["Sprague River", "Oregon", "OR"],
"97640": ["Summer Lake", "Oregon", "OR"],
"97641": ["Christmas Valley", "Oregon", "OR"],
"97701": ["Bend", "Oregon", "OR"],
"97702": ["Bend", "Oregon", "OR"],
"97703": ["Bend", "Oregon", "OR"],
"97707": ["Bend", "Oregon", "OR"],
"97709": ["Bend", "Oregon", "OR"],
"97710": ["Fields", "Oregon", "OR"],
"97711": ["Ashwood", "Oregon", "OR"],
"97712": ["Brothers", "Oregon", "OR"],
"97720": ["Burns", "Oregon", "OR"],
"97721": ["Princeton", "Oregon", "OR"],
"97722": ["Diamond", "Oregon", "OR"],
"97730": ["Camp Sherman", "Oregon", "OR"],
"97731": ["Chemult", "Oregon", "OR"],
"97732": ["Crane", "Oregon", "OR"],
"97733": ["Crescent", "Oregon", "OR"],
"97734": ["Culver", "Oregon", "OR"],
"97735": ["Fort Rock", "Oregon", "OR"],
"97736": ["Frenchglen", "Oregon", "OR"],
"97737": ["Gilchrist", "Oregon", "OR"],
"97738": ["Hines", "Oregon", "OR"],
"97739": ["La Pine", "Oregon", "OR"],
"97741": ["Madras", "Oregon", "OR"],
"97750": ["Mitchell", "Oregon", "OR"],
"97751": ["Paulina", "Oregon", "OR"],
"97752": ["Post", "Oregon", "OR"],
"97753": ["Powell Butte", "Oregon", "OR"],
"97754": ["Prineville", "Oregon", "OR"],
"97756": ["Redmond", "Oregon", "OR"],
"97758": ["Riley", "Oregon", "OR"],
"97759": ["Sisters", "Oregon", "OR"],
"97760": ["Terrebonne", "Oregon", "OR"],
"97761": ["Warm Springs", "Oregon", "OR"],
"97801": ["Pendleton", "Oregon", "OR"],
"97810": ["Adams", "Oregon", "OR"],
"97812": ["Arlington", "Oregon", "OR"],
"97813": ["Athena", "Oregon", "OR"],
"97814": ["Baker City", "Oregon", "OR"],
"97817": ["Bates", "Oregon", "OR"],
"97818": ["Boardman", "Oregon", "OR"],
"97819": ["Bridgeport", "Oregon", "OR"],
"97820": ["Canyon City", "Oregon", "OR"],
"97823": ["Condon", "Oregon", "OR"],
"97824": ["Cove", "Oregon", "OR"],
"97825": ["Dayville", "Oregon", "OR"],
"97826": ["Echo", "Oregon", "OR"],
"97827": ["Elgin", "Oregon", "OR"],
"97828": ["Enterprise", "Oregon", "OR"],
"97830": ["Fossil", "Oregon", "OR"],
"97833": ["Haines", "Oregon", "OR"],
"97834": ["Halfway", "Oregon", "OR"],
"97835": ["Helix", "Oregon", "OR"],
"97836": ["Heppner", "Oregon", "OR"],
"97837": ["Hereford", "Oregon", "OR"],
"97838": ["Hermiston", "Oregon", "OR"],
"97839": ["Lexington", "Oregon", "OR"],
"97840": ["Oxbow", "Oregon", "OR"],
"97841": ["Imbler", "Oregon", "OR"],
"97842": ["Imnaha", "Oregon", "OR"],
"97843": ["Ione", "Oregon", "OR"],
"97844": ["Irrigon", "Oregon", "OR"],
"97845": ["John Day", "Oregon", "OR"],
"97846": ["Joseph", "Oregon", "OR"],
"97848": ["Kimberly", "Oregon", "OR"],
"97850": ["La Grande", "Oregon", "OR"],
"97856": ["Long Creek", "Oregon", "OR"],
"97857": ["Lostine", "Oregon", "OR"],
"97859": ["Meacham", "Oregon", "OR"],
"97862": ["Milton Freewater", "Oregon", "OR"],
"97864": ["Monument", "Oregon", "OR"],
"97865": ["Mount Vernon", "Oregon", "OR"],
"97867": ["North Powder", "Oregon", "OR"],
"97868": ["Pilot Rock", "Oregon", "OR"],
"97869": ["Prairie City", "Oregon", "OR"],
"97870": ["Richland", "Oregon", "OR"],
"97873": ["Seneca", "Oregon", "OR"],
"97874": ["Spray", "Oregon", "OR"],
"97875": ["Stanfield", "Oregon", "OR"],
"97876": ["Summerville", "Oregon", "OR"],
"97877": ["Sumpter", "Oregon", "OR"],
"97880": ["Ukiah", "Oregon", "OR"],
"97882": ["Umatilla", "Oregon", "OR"],
"97883": ["Union", "Oregon", "OR"],
"97884": ["Unity", "Oregon", "OR"],
"97885": ["Wallowa", "Oregon", "OR"],
"97886": ["Weston", "Oregon", "OR"],
"97901": ["Adrian", "Oregon", "OR"],
"97902": ["Arock", "Oregon", "OR"],
"97903": ["Brogan", "Oregon", "OR"],
"97904": ["Drewsey", "Oregon", "OR"],
"97905": ["Durkee", "Oregon", "OR"],
"97906": ["Harper", "Oregon", "OR"],
"97907": ["Huntington", "Oregon", "OR"],
"97908": ["Ironside", "Oregon", "OR"],
"97909": ["Jamieson", "Oregon", "OR"],
"97910": ["Jordan Valley", "Oregon", "OR"],
"97911": ["Juntura", "Oregon", "OR"],
"97913": ["Nyssa", "Oregon", "OR"],
"97914": ["Ontario", "Oregon", "OR"],
"97917": ["Riverside", "Oregon", "OR"],
"97918": ["Vale", "Oregon", "OR"],
"97920": ["Westfall", "Oregon", "OR"],
"98001": ["Auburn", "Washington", "WA"],
"98002": ["Auburn", "Washington", "WA"],
"98003": ["Federal Way", "Washington", "WA"],
"98004": ["Bellevue", "Washington", "WA"],
"98005": ["Bellevue", "Washington", "WA"],
"98006": ["Bellevue", "Washington", "WA"],
"98007": ["Bellevue", "Washington", "WA"],
"98008": ["Bellevue", "Washington", "WA"],
"98010": ["Black Diamond", "Washington", "WA"],
"98011": ["Bothell", "Washington", "WA"],
"98012": ["Bothell", "Washington", "WA"],
"98014": ["Carnation", "Washington", "WA"],
"98019": ["Duvall", "Washington", "WA"],
"98020": ["Edmonds", "Washington", "WA"],
"98021": ["Bothell", "Washington", "WA"],
"98022": ["Enumclaw", "Washington", "WA"],
"98023": ["Federal Way", "Washington", "WA"],
"98024": ["Fall City", "Washington", "WA"],
"98025": ["Hobart", "Washington", "WA"],
"98026": ["Edmonds", "Washington", "WA"],
"98027": ["Issaquah", "Washington", "WA"],
"98028": ["Kenmore", "Washington", "WA"],
"98029": ["Issaquah", "Washington", "WA"],
"98030": ["Kent", "Washington", "WA"],
"98031": ["Kent", "Washington", "WA"],
"98032": ["Kent", "Washington", "WA"],
"98033": ["Kirkland", "Washington", "WA"],
"98034": ["Kirkland", "Washington", "WA"],
"98036": ["Lynnwood", "Washington", "WA"],
"98037": ["Lynnwood", "Washington", "WA"],
"98038": ["Maple Valley", "Washington", "WA"],
"98039": ["Medina", "Washington", "WA"],
"98040": ["Mercer Island", "Washington", "WA"],
"98042": ["Kent", "Washington", "WA"],
"98043": ["Mountlake Terrace", "Washington", "WA"],
"98045": ["North Bend", "Washington", "WA"],
"98047": ["Pacific", "Washington", "WA"],
"98050": ["Preston", "Washington", "WA"],
"98051": ["Ravensdale", "Washington", "WA"],
"98052": ["Redmond", "Washington", "WA"],
"98053": ["Redmond", "Washington", "WA"],
"98055": ["Renton", "Washington", "WA"],
"98056": ["Renton", "Washington", "WA"],
"98057": ["Renton", "Washington", "WA"],
"98058": ["Renton", "Washington", "WA"],
"98059": ["Renton", "Washington", "WA"],
"98065": ["Snoqualmie", "Washington", "WA"],
"98068": ["Snoqualmie Pass", "Washington", "WA"],
"98070": ["Vashon", "Washington", "WA"],
"98072": ["Woodinville", "Washington", "WA"],
"98074": ["Sammamish", "Washington", "WA"],
"98075": ["Sammamish", "Washington", "WA"],
"98077": ["Woodinville", "Washington", "WA"],
"98087": ["Lynnwood", "Washington", "WA"],
"98092": ["Auburn", "Washington", "WA"],
"98101": ["Seattle", "Washington", "WA"],
"98102": ["Seattle", "Washington", "WA"],
"98103": ["Seattle", "Washington", "WA"],
"98104": ["Seattle", "Washington", "WA"],
"98105": ["Seattle", "Washington", "WA"],
"98106": ["Seattle", "Washington", "WA"],
"98107": ["Seattle", "Washington", "WA"],
"98108": ["Seattle", "Washington", "WA"],
"98109": ["Seattle", "Washington", "WA"],
"98110": ["Bainbridge Island", "Washington", "WA"],
"98112": ["Seattle", "Washington", "WA"],
"98115": ["Seattle", "Washington", "WA"],
"98116": ["Seattle", "Washington", "WA"],
"98117": ["Seattle", "Washington", "WA"],
"98118": ["Seattle", "Washington", "WA"],
"98119": ["Seattle", "Washington", "WA"],
"98121": ["Seattle", "Washington", "WA"],
"98122": ["Seattle", "Washington", "WA"],
"98125": ["Seattle", "Washington", "WA"],
"98126": ["Seattle", "Washington", "WA"],
"98133": ["Seattle", "Washington", "WA"],
"98134": ["Seattle", "Washington", "WA"],
"98136": ["Seattle", "Washington", "WA"],
"98144": ["Seattle", "Washington", "WA"],
"98146": ["Seattle", "Washington", "WA"],
"98148": ["Seattle", "Washington", "WA"],
"98154": ["Seattle", "Washington", "WA"],
"98155": ["Seattle", "Washington", "WA"],
"98158": ["Seattle", "Washington", "WA"],
"98164": ["Seattle", "Washington", "WA"],
"98166": ["Seattle", "Washington", "WA"],
"98168": ["Seattle", "Washington", "WA"],
"98174": ["Seattle", "Washington", "WA"],
"98177": ["Seattle", "Washington", "WA"],
"98178": ["Seattle", "Washington", "WA"],
"98188": ["Seattle", "Washington", "WA"],
"98195": ["Seattle", "Washington", "WA"],
"98198": ["Seattle", "Washington", "WA"],
"98199": ["Seattle", "Washington", "WA"],
"98201": ["Everett", "Washington", "WA"],
"98203": ["Everett", "Washington", "WA"],
"98204": ["Everett", "Washington", "WA"],
"98207": ["Everett", "Washington", "WA"],
"98208": ["Everett", "Washington", "WA"],
"98220": ["Acme", "Washington", "WA"],
"98221": ["Anacortes", "Washington", "WA"],
"98222": ["Blakely Island", "Washington", "WA"],
"98223": ["Arlington", "Washington", "WA"],
"98224": ["Baring", "Washington", "WA"],
"98225": ["Bellingham", "Washington", "WA"],
"98226": ["Bellingham", "Washington", "WA"],
"98229": ["Bellingham", "Washington", "WA"],
"98230": ["Blaine", "Washington", "WA"],
"98232": ["Bow", "Washington", "WA"],
"98233": ["Burlington", "Washington", "WA"],
"98235": ["Clearlake", "Washington", "WA"],
"98236": ["Clinton", "Washington", "WA"],
"98237": ["Concrete", "Washington", "WA"],
"98238": ["Conway", "Washington", "WA"],
"98239": ["Coupeville", "Washington", "WA"],
"98240": ["Custer", "Washington", "WA"],
"98241": ["Darrington", "Washington", "WA"],
"98243": ["Deer Harbor", "Washington", "WA"],
"98244": ["Deming", "Washington", "WA"],
"98245": ["Eastsound", "Washington", "WA"],
"98247": ["Everson", "Washington", "WA"],
"98248": ["Ferndale", "Washington", "WA"],
"98249": ["Freeland", "Washington", "WA"],
"98250": ["Friday Harbor", "Washington", "WA"],
"98251": ["Gold Bar", "Washington", "WA"],
"98252": ["Granite Falls", "Washington", "WA"],
"98253": ["Greenbank", "Washington", "WA"],
"98255": ["Hamilton", "Washington", "WA"],
"98256": ["Index", "Washington", "WA"],
"98257": ["La Conner", "Washington", "WA"],
"98258": ["Lake Stevens", "Washington", "WA"],
"98260": ["Langley", "Washington", "WA"],
"98261": ["Lopez Island", "Washington", "WA"],
"98262": ["Lummi Island", "Washington", "WA"],
"98263": ["Lyman", "Washington", "WA"],
"98264": ["Lynden", "Washington", "WA"],
"98266": ["Maple Falls", "Washington", "WA"],
"98267": ["Marblemount", "Washington", "WA"],
"98270": ["Marysville", "Washington", "WA"],
"98271": ["Marysville", "Washington", "WA"],
"98272": ["Monroe", "Washington", "WA"],
"98273": ["Mount Vernon", "Washington", "WA"],
"98274": ["Mount Vernon", "Washington", "WA"],
"98275": ["Mukilteo", "Washington", "WA"],
"98276": ["Nooksack", "Washington", "WA"],
"98277": ["Oak Harbor", "Washington", "WA"],
"98278": ["Oak Harbor", "Washington", "WA"],
"98279": ["Olga", "Washington", "WA"],
"98280": ["Orcas", "Washington", "WA"],
"98281": ["Point Roberts", "Washington", "WA"],
"98282": ["Camano Island", "Washington", "WA"],
"98283": ["Rockport", "Washington", "WA"],
"98284": ["Sedro Woolley", "Washington", "WA"],
"98286": ["Shaw Island", "Washington", "WA"],
"98288": ["Skykomish", "Washington", "WA"],
"98290": ["Snohomish", "Washington", "WA"],
"98292": ["Stanwood", "Washington", "WA"],
"98294": ["Sultan", "Washington", "WA"],
"98295": ["Sumas", "Washington", "WA"],
"98296": ["Snohomish", "Washington", "WA"],
"98297": ["Waldron", "Washington", "WA"],
"98303": ["Anderson Island", "Washington", "WA"],
"98304": ["Ashford", "Washington", "WA"],
"98305": ["Beaver", "Washington", "WA"],
"98310": ["Bremerton", "Washington", "WA"],
"98311": ["Bremerton", "Washington", "WA"],
"98312": ["Bremerton", "Washington", "WA"],
"98314": ["Bremerton", "Washington", "WA"],
"98315": ["Silverdale", "Washington", "WA"],
"98320": ["Brinnon", "Washington", "WA"],
"98321": ["Buckley", "Washington", "WA"],
"98323": ["Carbonado", "Washington", "WA"],
"98325": ["Chimacum", "Washington", "WA"],
"98326": ["Clallam Bay", "Washington", "WA"],
"98327": ["Dupont", "Washington", "WA"],
"98328": ["Eatonville", "Washington", "WA"],
"98329": ["Gig Harbor", "Washington", "WA"],
"98330": ["Elbe", "Washington", "WA"],
"98331": ["Forks", "Washington", "WA"],
"98332": ["Gig Harbor", "Washington", "WA"],
"98333": ["Fox Island", "Washington", "WA"],
"98335": ["Gig Harbor", "Washington", "WA"],
"98336": ["Glenoma", "Washington", "WA"],
"98337": ["Bremerton", "Washington", "WA"],
"98338": ["Graham", "Washington", "WA"],
"98339": ["Port Hadlock", "Washington", "WA"],
"98340": ["Hansville", "Washington", "WA"],
"98342": ["Indianola", "Washington", "WA"],
"98345": ["Keyport", "Washington", "WA"],
"98346": ["Kingston", "Washington", "WA"],
"98349": ["Lakebay", "Washington", "WA"],
"98350": ["La Push", "Washington", "WA"],
"98351": ["Longbranch", "Washington", "WA"],
"98353": ["Manchester", "Washington", "WA"],
"98354": ["Milton", "Washington", "WA"],
"98355": ["Mineral", "Washington", "WA"],
"98356": ["Morton", "Washington", "WA"],
"98357": ["Neah Bay", "Washington", "WA"],
"98358": ["Nordland", "Washington", "WA"],
"98359": ["Olalla", "Washington", "WA"],
"98360": ["Orting", "Washington", "WA"],
"98361": ["Packwood", "Washington", "WA"],
"98362": ["Port Angeles", "Washington", "WA"],
"98363": ["Port Angeles", "Washington", "WA"],
"98364": ["Port Gamble", "Washington", "WA"],
"98365": ["Port Ludlow", "Washington", "WA"],
"98366": ["Port Orchard", "Washington", "WA"],
"98367": ["Port Orchard", "Washington", "WA"],
"98368": ["Port Townsend", "Washington", "WA"],
"98370": ["Poulsbo", "Washington", "WA"],
"98371": ["Puyallup", "Washington", "WA"],
"98372": ["Puyallup", "Washington", "WA"],
"98373": ["Puyallup", "Washington", "WA"],
"98374": ["Puyallup", "Washington", "WA"],
"98375": ["Puyallup", "Washington", "WA"],
"98376": ["Quilcene", "Washington", "WA"],
"98377": ["Randle", "Washington", "WA"],
"98380": ["Seabeck", "Washington", "WA"],
"98381": ["Sekiu", "Washington", "WA"],
"98382": ["Sequim", "Washington", "WA"],
"98383": ["Silverdale", "Washington", "WA"],
"98385": ["South Prairie", "Washington", "WA"],
"98387": ["Spanaway", "Washington", "WA"],
"98388": ["Steilacoom", "Washington", "WA"],
"98390": ["Sumner", "Washington", "WA"],
"98391": ["Bonney Lake", "Washington", "WA"],
"98392": ["Suquamish", "Washington", "WA"],
"98394": ["Vaughn", "Washington", "WA"],
"98396": ["Wilkeson", "Washington", "WA"],
"98402": ["Tacoma", "Washington", "WA"],
"98403": ["Tacoma", "Washington", "WA"],
"98404": ["Tacoma", "Washington", "WA"],
"98405": ["Tacoma", "Washington", "WA"],
"98406": ["Tacoma", "Washington", "WA"],
"98407": ["Tacoma", "Washington", "WA"],
"98408": ["Tacoma", "Washington", "WA"],
"98409": ["Tacoma", "Washington", "WA"],
"98416": ["Tacoma", "Washington", "WA"],
"98418": ["Tacoma", "Washington", "WA"],
"98421": ["Tacoma", "Washington", "WA"],
"98422": ["Tacoma", "Washington", "WA"],
"98424": ["Fife", "Washington", "WA"],
"98430": ["Camp Murray", "Washington", "WA"],
"98433": ["Tacoma", "Washington", "WA"],
"98438": ["Mcchord Afb", "Washington", "WA"],
"98439": ["Lakewood", "Washington", "WA"],
"98443": ["Tacoma", "Washington", "WA"],
"98444": ["Tacoma", "Washington", "WA"],
"98445": ["Tacoma", "Washington", "WA"],
"98446": ["Tacoma", "Washington", "WA"],
"98447": ["Tacoma", "Washington", "WA"],
"98465": ["Tacoma", "Washington", "WA"],
"98466": ["Tacoma", "Washington", "WA"],
"98467": ["University Place", "Washington", "WA"],
"98498": ["Lakewood", "Washington", "WA"],
"98499": ["Lakewood", "Washington", "WA"],
"98501": ["Olympia", "Washington", "WA"],
"98502": ["Olympia", "Washington", "WA"],
"98503": ["Lacey", "Washington", "WA"],
"98505": ["Olympia", "Washington", "WA"],
"98506": ["Olympia", "Washington", "WA"],
"98512": ["Olympia", "Washington", "WA"],
"98513": ["Olympia", "Washington", "WA"],
"98516": ["Olympia", "Washington", "WA"],
"98520": ["Aberdeen", "Washington", "WA"],
"98524": ["Allyn", "Washington", "WA"],
"98526": ["Amanda Park", "Washington", "WA"],
"98527": ["Bay Center", "Washington", "WA"],
"98528": ["Belfair", "Washington", "WA"],
"98530": ["Bucoda", "Washington", "WA"],
"98531": ["Centralia", "Washington", "WA"],
"98532": ["Chehalis", "Washington", "WA"],
"98533": ["Cinebar", "Washington", "WA"],
"98535": ["Copalis Beach", "Washington", "WA"],
"98536": ["Copalis Crossing", "Washington", "WA"],
"98537": ["Cosmopolis", "Washington", "WA"],
"98538": ["Curtis", "Washington", "WA"],
"98539": ["Doty", "Washington", "WA"],
"98541": ["Elma", "Washington", "WA"],
"98542": ["Ethel", "Washington", "WA"],
"98544": ["Galvin", "Washington", "WA"],
"98546": ["Grapeview", "Washington", "WA"],
"98547": ["Grayland", "Washington", "WA"],
"98548": ["Hoodsport", "Washington", "WA"],
"98550": ["Hoquiam", "Washington", "WA"],
"98552": ["Humptulips", "Washington", "WA"],
"98555": ["Lilliwaup", "Washington", "WA"],
"98556": ["Littlerock", "Washington", "WA"],
"98557": ["Mccleary", "Washington", "WA"],
"98558": ["Mckenna", "Washington", "WA"],
"98559": ["Malone", "Washington", "WA"],
"98560": ["Matlock", "Washington", "WA"],
"98562": ["Moclips", "Washington", "WA"],
"98563": ["Montesano", "Washington", "WA"],
"98564": ["Mossyrock", "Washington", "WA"],
"98565": ["Napavine", "Washington", "WA"],
"98566": ["Neilton", "Washington", "WA"],
"98568": ["Oakville", "Washington", "WA"],
"98569": ["Ocean Shores", "Washington", "WA"],
"98570": ["Onalaska", "Washington", "WA"],
"98571": ["Pacific Beach", "Washington", "WA"],
"98572": ["Pe Ell", "Washington", "WA"],
"98575": ["Quinault", "Washington", "WA"],
"98576": ["Rainier", "Washington", "WA"],
"98577": ["Raymond", "Washington", "WA"],
"98579": ["Rochester", "Washington", "WA"],
"98580": ["Roy", "Washington", "WA"],
"98581": ["Ryderwood", "Washington", "WA"],
"98582": ["Salkum", "Washington", "WA"],
"98583": ["Satsop", "Washington", "WA"],
"98584": ["Shelton", "Washington", "WA"],
"98585": ["Silver Creek", "Washington", "WA"],
"98586": ["South Bend", "Washington", "WA"],
"98587": ["Taholah", "Washington", "WA"],
"98588": ["Tahuya", "Washington", "WA"],
"98589": ["Tenino", "Washington", "WA"],
"98590": ["Tokeland", "Washington", "WA"],
"98591": ["Toledo", "Washington", "WA"],
"98592": ["Union", "Washington", "WA"],
"98593": ["Vader", "Washington", "WA"],
"98595": ["Westport", "Washington", "WA"],
"98596": ["Winlock", "Washington", "WA"],
"98597": ["Yelm", "Washington", "WA"],
"98601": ["Amboy", "Washington", "WA"],
"98602": ["Appleton", "Washington", "WA"],
"98603": ["Ariel", "Washington", "WA"],
"98604": ["Battle Ground", "Washington", "WA"],
"98605": ["Bingen", "Washington", "WA"],
"98606": ["Brush Prairie", "Washington", "WA"],
"98607": ["Camas", "Washington", "WA"],
"98609": ["Carrolls", "Washington", "WA"],
"98610": ["Carson", "Washington", "WA"],
"98611": ["Castle Rock", "Washington", "WA"],
"98612": ["Cathlamet", "Washington", "WA"],
"98613": ["Centerville", "Washington", "WA"],
"98614": ["Chinook", "Washington", "WA"],
"98616": ["Cougar", "Washington", "WA"],
"98617": ["Dallesport", "Washington", "WA"],
"98619": ["Glenwood", "Washington", "WA"],
"98620": ["Goldendale", "Washington", "WA"],
"98621": ["Grays River", "Washington", "WA"],
"98623": ["Husum", "Washington", "WA"],
"98624": ["Ilwaco", "Washington", "WA"],
"98625": ["Kalama", "Washington", "WA"],
"98626": ["Kelso", "Washington", "WA"],
"98628": ["Klickitat", "Washington", "WA"],
"98629": ["La Center", "Washington", "WA"],
"98631": ["Long Beach", "Washington", "WA"],
"98632": ["Longview", "Washington", "WA"],
"98635": ["Lyle", "Washington", "WA"],
"98638": ["Naselle", "Washington", "WA"],
"98639": ["North Bonneville", "Washington", "WA"],
"98640": ["Ocean Park", "Washington", "WA"],
"98641": ["Oysterville", "Washington", "WA"],
"98642": ["Ridgefield", "Washington", "WA"],
"98643": ["Rosburg", "Washington", "WA"],
"98644": ["Seaview", "Washington", "WA"],
"98645": ["Silverlake", "Washington", "WA"],
"98647": ["Skamokawa", "Washington", "WA"],
"98648": ["Stevenson", "Washington", "WA"],
"98649": ["Toutle", "Washington", "WA"],
"98650": ["Trout Lake", "Washington", "WA"],
"98651": ["Underwood", "Washington", "WA"],
"98660": ["Vancouver", "Washington", "WA"],
"98661": ["Vancouver", "Washington", "WA"],
"98662": ["Vancouver", "Washington", "WA"],
"98663": ["Vancouver", "Washington", "WA"],
"98664": ["Vancouver", "Washington", "WA"],
"98665": ["Vancouver", "Washington", "WA"],
"98670": ["Wahkiacus", "Washington", "WA"],
"98671": ["Washougal", "Washington", "WA"],
"98672": ["White Salmon", "Washington", "WA"],
"98673": ["Wishram", "Washington", "WA"],
"98674": ["Woodland", "Washington", "WA"],
"98675": ["Yacolt", "Washington", "WA"],
"98682": ["Vancouver", "Washington", "WA"],
"98683": ["Vancouver", "Washington", "WA"],
"98684": ["Vancouver", "Washington", "WA"],
"98685": ["Vancouver", "Washington", "WA"],
"98686": ["Vancouver", "Washington", "WA"],
"98801": ["Wenatchee", "Washington", "WA"],
"98802": ["East Wenatchee", "Washington", "WA"],
"98811": ["Ardenvoir", "Washington", "WA"],
"98812": ["Brewster", "Washington", "WA"],
"98813": ["Bridgeport", "Washington", "WA"],
"98814": ["Carlton", "Washington", "WA"],
"98815": ["Cashmere", "Washington", "WA"],
"98816": ["Chelan", "Washington", "WA"],
"98817": ["Chelan Falls", "Washington", "WA"],
"98819": ["Conconully", "Washington", "WA"],
"98821": ["Dryden", "Washington", "WA"],
"98822": ["Entiat", "Washington", "WA"],
"98823": ["Ephrata", "Washington", "WA"],
"98824": ["George", "Washington", "WA"],
"98826": ["Leavenworth", "Washington", "WA"],
"98827": ["Loomis", "Washington", "WA"],
"98828": ["Malaga", "Washington", "WA"],
"98829": ["Malott", "Washington", "WA"],
"98830": ["Mansfield", "Washington", "WA"],
"98831": ["Manson", "Washington", "WA"],
"98832": ["Marlin", "Washington", "WA"],
"98833": ["Mazama", "Washington", "WA"],
"98834": ["Methow", "Washington", "WA"],
"98836": ["Monitor", "Washington", "WA"],
"98837": ["Moses Lake", "Washington", "WA"],
"98840": ["Okanogan", "Washington", "WA"],
"98841": ["Omak", "Washington", "WA"],
"98843": ["Orondo", "Washington", "WA"],
"98844": ["Oroville", "Washington", "WA"],
"98845": ["Palisades", "Washington", "WA"],
"98846": ["Pateros", "Washington", "WA"],
"98847": ["Peshastin", "Washington", "WA"],
"98848": ["Quincy", "Washington", "WA"],
"98849": ["Riverside", "Washington", "WA"],
"98850": ["Rock Island", "Washington", "WA"],
"98851": ["Soap Lake", "Washington", "WA"],
"98852": ["Stehekin", "Washington", "WA"],
"98853": ["Stratford", "Washington", "WA"],
"98855": ["Tonasket", "Washington", "WA"],
"98856": ["Twisp", "Washington", "WA"],
"98857": ["Warden", "Washington", "WA"],
"98858": ["Waterville", "Washington", "WA"],
"98859": ["Wauconda", "Washington", "WA"],
"98860": ["Wilson Creek", "Washington", "WA"],
"98862": ["Winthrop", "Washington", "WA"],
"98901": ["Yakima", "Washington", "WA"],
"98902": ["Yakima", "Washington", "WA"],
"98903": ["Yakima", "Washington", "WA"],
"98908": ["Yakima", "Washington", "WA"],
"98921": ["Buena", "Washington", "WA"],
"98922": ["Cle Elum", "Washington", "WA"],
"98923": ["Cowiche", "Washington", "WA"],
"98925": ["Easton", "Washington", "WA"],
"98926": ["Ellensburg", "Washington", "WA"],
"98930": ["Grandview", "Washington", "WA"],
"98932": ["Granger", "Washington", "WA"],
"98933": ["Harrah", "Washington", "WA"],
"98934": ["Kittitas", "Washington", "WA"],
"98935": ["Mabton", "Washington", "WA"],
"98936": ["Moxee", "Washington", "WA"],
"98937": ["Naches", "Washington", "WA"],
"98938": ["Outlook", "Washington", "WA"],
"98939": ["Parker", "Washington", "WA"],
"98940": ["Ronald", "Washington", "WA"],
"98941": ["Roslyn", "Washington", "WA"],
"98942": ["Selah", "Washington", "WA"],
"98943": ["South Cle Elum", "Washington", "WA"],
"98944": ["Sunnyside", "Washington", "WA"],
"98946": ["Thorp", "Washington", "WA"],
"98947": ["Tieton", "Washington", "WA"],
"98948": ["Toppenish", "Washington", "WA"],
"98950": ["Vantage", "Washington", "WA"],
"98951": ["Wapato", "Washington", "WA"],
"98952": ["White Swan", "Washington", "WA"],
"98953": ["Zillah", "Washington", "WA"],
"99001": ["Airway Heights", "Washington", "WA"],
"99003": ["Chattaroy", "Washington", "WA"],
"99004": ["Cheney", "Washington", "WA"],
"99005": ["Colbert", "Washington", "WA"],
"99006": ["Deer Park", "Washington", "WA"],
"99008": ["Edwall", "Washington", "WA"],
"99009": ["Elk", "Washington", "WA"],
"99011": ["Fairchild Air Force Base", "Washington", "WA"],
"99012": ["Fairfield", "Washington", "WA"],
"99013": ["Ford", "Washington", "WA"],
"99014": ["Four Lakes", "Washington", "WA"],
"99016": ["Greenacres", "Washington", "WA"],
"99017": ["Lamont", "Washington", "WA"],
"99018": ["Latah", "Washington", "WA"],
"99019": ["Liberty Lake", "Washington", "WA"],
"99020": ["Marshall", "Washington", "WA"],
"99021": ["Mead", "Washington", "WA"],
"99022": ["Medical Lake", "Washington", "WA"],
"99023": ["Mica", "Washington", "WA"],
"99025": ["Newman Lake", "Washington", "WA"],
"99026": ["Nine Mile Falls", "Washington", "WA"],
"99027": ["Otis Orchards", "Washington", "WA"],
"99029": ["Reardan", "Washington", "WA"],
"99030": ["Rockford", "Washington", "WA"],
"99031": ["Spangle", "Washington", "WA"],
"99032": ["Sprague", "Washington", "WA"],
"99033": ["Tekoa", "Washington", "WA"],
"99034": ["Tumtum", "Washington", "WA"],
"99036": ["Valleyford", "Washington", "WA"],
"99037": ["Veradale", "Washington", "WA"],
"99039": ["Waverly", "Washington", "WA"],
"99040": ["Wellpinit", "Washington", "WA"],
"99101": ["Addy", "Washington", "WA"],
"99102": ["Albion", "Washington", "WA"],
"99103": ["Almira", "Washington", "WA"],
"99105": ["Benge", "Washington", "WA"],
"99109": ["Chewelah", "Washington", "WA"],
"99110": ["Clayton", "Washington", "WA"],
"99111": ["Colfax", "Washington", "WA"],
"99113": ["Colton", "Washington", "WA"],
"99114": ["Colville", "Washington", "WA"],
"99115": ["Coulee City", "Washington", "WA"],
"99116": ["Coulee Dam", "Washington", "WA"],
"99117": ["Creston", "Washington", "WA"],
"99118": ["Curlew", "Washington", "WA"],
"99119": ["Cusick", "Washington", "WA"],
"99121": ["Danville", "Washington", "WA"],
"99122": ["Davenport", "Washington", "WA"],
"99123": ["Electric City", "Washington", "WA"],
"99124": ["Elmer City", "Washington", "WA"],
"99125": ["Endicott", "Washington", "WA"],
"99126": ["Evans", "Washington", "WA"],
"99128": ["Farmington", "Washington", "WA"],
"99129": ["Fruitland", "Washington", "WA"],
"99130": ["Garfield", "Washington", "WA"],
"99131": ["Gifford", "Washington", "WA"],
"99133": ["Grand Coulee", "Washington", "WA"],
"99134": ["Harrington", "Washington", "WA"],
"99135": ["Hartline", "Washington", "WA"],
"99136": ["Hay", "Washington", "WA"],
"99137": ["Hunters", "Washington", "WA"],
"99138": ["Inchelium", "Washington", "WA"],
"99139": ["Ione", "Washington", "WA"],
"99140": ["Keller", "Washington", "WA"],
"99141": ["Kettle Falls", "Washington", "WA"],
"99143": ["Lacrosse", "Washington", "WA"],
"99144": ["Lamona", "Washington", "WA"],
"99146": ["Laurier", "Washington", "WA"],
"99147": ["Lincoln", "Washington", "WA"],
"99148": ["Loon Lake", "Washington", "WA"],
"99149": ["Malden", "Washington", "WA"],
"99150": ["Malo", "Washington", "WA"],
"99151": ["Marcus", "Washington", "WA"],
"99152": ["Metaline", "Washington", "WA"],
"99153": ["Metaline Falls", "Washington", "WA"],
"99154": ["Mohler", "Washington", "WA"],
"99155": ["Nespelem", "Washington", "WA"],
"99156": ["Newport", "Washington", "WA"],
"99157": ["Northport", "Washington", "WA"],
"99158": ["Oakesdale", "Washington", "WA"],
"99159": ["Odessa", "Washington", "WA"],
"99160": ["Orient", "Washington", "WA"],
"99161": ["Palouse", "Washington", "WA"],
"99163": ["Pullman", "Washington", "WA"],
"99166": ["Republic", "Washington", "WA"],
"99167": ["Rice", "Washington", "WA"],
"99169": ["Ritzville", "Washington", "WA"],
"99170": ["Rosalia", "Washington", "WA"],
"99171": ["Saint John", "Washington", "WA"],
"99173": ["Springdale", "Washington", "WA"],
"99174": ["Steptoe", "Washington", "WA"],
"99176": ["Thornton", "Washington", "WA"],
"99179": ["Uniontown", "Washington", "WA"],
"99180": ["Usk", "Washington", "WA"],
"99181": ["Valley", "Washington", "WA"],
"99185": ["Wilbur", "Washington", "WA"],
"99201": ["Spokane", "Washington", "WA"],
"99202": ["Spokane", "Washington", "WA"],
"99203": ["Spokane", "Washington", "WA"],
"99204": ["Spokane", "Washington", "WA"],
"99205": ["Spokane", "Washington", "WA"],
"99206": ["Spokane", "Washington", "WA"],
"99207": ["Spokane", "Washington", "WA"],
"99208": ["Spokane", "Washington", "WA"],
"99212": ["Spokane", "Washington", "WA"],
"99216": ["Spokane", "Washington", "WA"],
"99217": ["Spokane", "Washington", "WA"],
"99218": ["Spokane", "Washington", "WA"],
"99223": ["Spokane", "Washington", "WA"],
"99224": ["Spokane", "Washington", "WA"],
"99251": ["Spokane", "Washington", "WA"],
"99258": ["Spokane", "Washington", "WA"],
"99301": ["Pasco", "Washington", "WA"],
"99320": ["Benton City", "Washington", "WA"],
"99321": ["Beverly", "Washington", "WA"],
"99322": ["Bickleton", "Washington", "WA"],
"99323": ["Burbank", "Washington", "WA"],
"99324": ["College Place", "Washington", "WA"],
"99326": ["Connell", "Washington", "WA"],
"99328": ["Dayton", "Washington", "WA"],
"99329": ["Dixie", "Washington", "WA"],
"99330": ["Eltopia", "Washington", "WA"],
"99333": ["Hooper", "Washington", "WA"],
"99335": ["Kahlotus", "Washington", "WA"],
"99336": ["Kennewick", "Washington", "WA"],
"99337": ["Kennewick", "Washington", "WA"],
"99338": ["Kennewick", "Washington", "WA"],
"99341": ["Lind", "Washington", "WA"],
"99343": ["Mesa", "Washington", "WA"],
"99344": ["Othello", "Washington", "WA"],
"99345": ["Paterson", "Washington", "WA"],
"99346": ["Plymouth", "Washington", "WA"],
"99347": ["Pomeroy", "Washington", "WA"],
"99348": ["Prescott", "Washington", "WA"],
"99349": ["Mattawa", "Washington", "WA"],
"99350": ["Prosser", "Washington", "WA"],
"99352": ["Richland", "Washington", "WA"],
"99353": ["West Richland", "Washington", "WA"],
"99354": ["Richland", "Washington", "WA"],
"99356": ["Roosevelt", "Washington", "WA"],
"99357": ["Royal City", "Washington", "WA"],
"99359": ["Starbuck", "Washington", "WA"],
"99360": ["Touchet", "Washington", "WA"],
"99361": ["Waitsburg", "Washington", "WA"],
"99362": ["Walla Walla", "Washington", "WA"],
"99363": ["Wallula", "Washington", "WA"],
"99371": ["Washtucna", "Washington", "WA"],
"99401": ["Anatone", "Washington", "WA"],
"99402": ["Asotin", "Washington", "WA"],
"99403": ["Clarkston", "Washington", "WA"],
"99501": ["Anchorage", "Alaska", "AK"],
"99502": ["Anchorage", "Alaska", "AK"],
"99503": ["Anchorage", "Alaska", "AK"],
"99504": ["Anchorage", "Alaska", "AK"],
"99505": ["Jber", "Alaska", "AK"],
"99506": ["Jber", "Alaska", "AK"],
"99507": ["Anchorage", "Alaska", "AK"],
"99508": ["Anchorage", "Alaska", "AK"],
"99513": ["Anchorage", "Alaska", "AK"],
"99515": ["Anchorage", "Alaska", "AK"],
"99516": ["Anchorage", "Alaska", "AK"],
"99517": ["Anchorage", "Alaska", "AK"],
"99518": ["Anchorage", "Alaska", "AK"],
"99519": ["Anchorage", "Alaska", "AK"],
"99530": ["Anchorage", "Alaska", "AK"],
"99540": ["Indian", "Alaska", "AK"],
"99546": ["Adak", "Alaska", "AK"],
"99547": ["Atka", "Alaska", "AK"],
"99548": ["Chignik Lake", "Alaska", "AK"],
"99549": ["Port Heiden", "Alaska", "AK"],
"99550": ["Port Lions", "Alaska", "AK"],
"99551": ["Akiachak", "Alaska", "AK"],
"99552": ["Akiak", "Alaska", "AK"],
"99553": ["Akutan", "Alaska", "AK"],
"99554": ["Alakanuk", "Alaska", "AK"],
"99555": ["Aleknagik", "Alaska", "AK"],
"99556": ["Anchor Point", "Alaska", "AK"],
"99557": ["Aniak", "Alaska", "AK"],
"99558": ["Anvik", "Alaska", "AK"],
"99559": ["Bethel", "Alaska", "AK"],
"99561": ["Chefornak", "Alaska", "AK"],
"99563": ["Chevak", "Alaska", "AK"],
"99564": ["Chignik", "Alaska", "AK"],
"99565": ["Chignik Lagoon", "Alaska", "AK"],
"99566": ["Chitina", "Alaska", "AK"],
"99567": ["Chugiak", "Alaska", "AK"],
"99568": ["Clam Gulch", "Alaska", "AK"],
"99569": ["Clarks Point", "Alaska", "AK"],
"99571": ["Cold Bay", "Alaska", "AK"],
"99572": ["Cooper Landing", "Alaska", "AK"],
"99573": ["Copper Center", "Alaska", "AK"],
"99574": ["Cordova", "Alaska", "AK"],
"99575": ["Crooked Creek", "Alaska", "AK"],
"99576": ["Dillingham", "Alaska", "AK"],
"99577": ["Eagle River", "Alaska", "AK"],
"99578": ["Eek", "Alaska", "AK"],
"99579": ["Egegik", "Alaska", "AK"],
"99580": ["Ekwok", "Alaska", "AK"],
"99581": ["Emmonak", "Alaska", "AK"],
"99583": ["False Pass", "Alaska", "AK"],
"99585": ["Marshall", "Alaska", "AK"],
"99586": ["Gakona", "Alaska", "AK"],
"99587": ["Girdwood", "Alaska", "AK"],
"99588": ["Glennallen", "Alaska", "AK"],
"99589": ["Goodnews Bay", "Alaska", "AK"],
"99590": ["Grayling", "Alaska", "AK"],
"99591": ["Saint George Island", "Alaska", "AK"],
"99602": ["Holy Cross", "Alaska", "AK"],
"99603": ["Homer", "Alaska", "AK"],
"99604": ["Hooper Bay", "Alaska", "AK"],
"99605": ["Hope", "Alaska", "AK"],
"99606": ["Iliamna", "Alaska", "AK"],
"99607": ["Kalskag", "Alaska", "AK"],
"99608": ["Karluk", "Alaska", "AK"],
"99609": ["Kasigluk", "Alaska", "AK"],
"99610": ["Kasilof", "Alaska", "AK"],
"99611": ["Kenai", "Alaska", "AK"],
"99612": ["King Cove", "Alaska", "AK"],
"99613": ["King Salmon", "Alaska", "AK"],
"99614": ["Kipnuk", "Alaska", "AK"],
"99615": ["Kodiak", "Alaska", "AK"],
"99620": ["Kotlik", "Alaska", "AK"],
"99621": ["Kwethluk", "Alaska", "AK"],
"99622": ["Kwigillingok", "Alaska", "AK"],
"99623": ["Wasilla", "Alaska", "AK"],
"99624": ["Larsen Bay", "Alaska", "AK"],
"99625": ["Levelock", "Alaska", "AK"],
"99626": ["Lower Kalskag", "Alaska", "AK"],
"99627": ["McGrath", "Alaska", "AK"],
"99628": ["Manokotak", "Alaska", "AK"],
"99630": ["Mekoryuk", "Alaska", "AK"],
"99631": ["Moose Pass", "Alaska", "AK"],
"99632": ["Mountain Village", "Alaska", "AK"],
"99633": ["Naknek", "Alaska", "AK"],
"99634": ["Napakiak", "Alaska", "AK"],
"99635": ["Nikiski", "Alaska", "AK"],
"99636": ["New Stuyahok", "Alaska", "AK"],
"99637": ["Toksook Bay", "Alaska", "AK"],
"99638": ["Nikolski", "Alaska", "AK"],
"99639": ["Ninilchik", "Alaska", "AK"],
"99640": ["Nondalton", "Alaska", "AK"],
"99641": ["Nunapitchuk", "Alaska", "AK"],
"99643": ["Old Harbor", "Alaska", "AK"],
"99644": ["Ouzinkie", "Alaska", "AK"],
"99645": ["Palmer", "Alaska", "AK"],
"99647": ["Pedro Bay", "Alaska", "AK"],
"99648": ["Perryville", "Alaska", "AK"],
"99649": ["Pilot Point", "Alaska", "AK"],
"99650": ["Pilot Station", "Alaska", "AK"],
"99651": ["Platinum", "Alaska", "AK"],
"99652": ["Big Lake", "Alaska", "AK"],
"99653": ["Port Alsworth", "Alaska", "AK"],
"99654": ["Wasilla", "Alaska", "AK"],
"99655": ["Quinhagak", "Alaska", "AK"],
"99656": ["Red Devil", "Alaska", "AK"],
"99657": ["Russian Mission", "Alaska", "AK"],
"99658": ["Saint Marys", "Alaska", "AK"],
"99659": ["Saint Michael", "Alaska", "AK"],
"99660": ["Saint Paul Island", "Alaska", "AK"],
"99661": ["Sand Point", "Alaska", "AK"],
"99662": ["Scammon Bay", "Alaska", "AK"],
"99663": ["Seldovia", "Alaska", "AK"],
"99664": ["Seward", "Alaska", "AK"],
"99665": ["Shageluk", "Alaska", "AK"],
"99666": ["Nunam Iqua", "Alaska", "AK"],
"99667": ["Skwentna", "Alaska", "AK"],
"99668": ["Sleetmute", "Alaska", "AK"],
"99669": ["Soldotna", "Alaska", "AK"],
"99670": ["South Naknek", "Alaska", "AK"],
"99671": ["Stebbins", "Alaska", "AK"],
"99672": ["Sterling", "Alaska", "AK"],
"99674": ["Sutton", "Alaska", "AK"],
"99675": ["Takotna", "Alaska", "AK"],
"99676": ["Talkeetna", "Alaska", "AK"],
"99677": ["Tatitlek", "Alaska", "AK"],
"99678": ["Togiak", "Alaska", "AK"],
"99679": ["Tuluksak", "Alaska", "AK"],
"99680": ["Tuntutuliak", "Alaska", "AK"],
"99681": ["Tununak", "Alaska", "AK"],
"99682": ["Tyonek", "Alaska", "AK"],
"99683": ["Trapper Creek", "Alaska", "AK"],
"99684": ["Unalakleet", "Alaska", "AK"],
"99685": ["Unalaska", "Alaska", "AK"],
"99686": ["Valdez", "Alaska", "AK"],
"99688": ["Willow", "Alaska", "AK"],
"99689": ["Yakutat", "Alaska", "AK"],
"99690": ["Nightmute", "Alaska", "AK"],
"99691": ["Nikolai", "Alaska", "AK"],
"99692": ["Dutch Harbor", "Alaska", "AK"],
"99693": ["Whittier", "Alaska", "AK"],
"99694": ["Houston", "Alaska", "AK"],
"99695": ["Anchorage", "Alaska", "AK"],
"99701": ["Fairbanks", "Alaska", "AK"],
"99702": ["Eielson Afb", "Alaska", "AK"],
"99703": ["Fort Wainwright", "Alaska", "AK"],
"99704": ["Clear", "Alaska", "AK"],
"99705": ["North Pole", "Alaska", "AK"],
"99706": ["Fairbanks", "Alaska", "AK"],
"99707": ["Fairbanks", "Alaska", "AK"],
"99709": ["Fairbanks", "Alaska", "AK"],
"99712": ["Fairbanks", "Alaska", "AK"],
"99714": ["Salcha", "Alaska", "AK"],
"99720": ["Allakaket", "Alaska", "AK"],
"99721": ["Anaktuvuk Pass", "Alaska", "AK"],
"99722": ["Arctic Village", "Alaska", "AK"],
"99723": ["Barrow", "Alaska", "AK"],
"99724": ["Beaver", "Alaska", "AK"],
"99725": ["Ester", "Alaska", "AK"],
"99726": ["Bettles Field", "Alaska", "AK"],
"99727": ["Buckland", "Alaska", "AK"],
"99729": ["Cantwell", "Alaska", "AK"],
"99730": ["Central", "Alaska", "AK"],
"99732": ["Chicken", "Alaska", "AK"],
"99733": ["Circle", "Alaska", "AK"],
"99734": ["Prudhoe Bay", "Alaska", "AK"],
"99736": ["Deering", "Alaska", "AK"],
"99737": ["Delta Junction", "Alaska", "AK"],
"99738": ["Eagle", "Alaska", "AK"],
"99739": ["Elim", "Alaska", "AK"],
"99740": ["Fort Yukon", "Alaska", "AK"],
"99741": ["Galena", "Alaska", "AK"],
"99742": ["Gambell", "Alaska", "AK"],
"99743": ["Healy", "Alaska", "AK"],
"99744": ["Anderson", "Alaska", "AK"],
"99745": ["Hughes", "Alaska", "AK"],
"99746": ["Huslia", "Alaska", "AK"],
"99747": ["Kaktovik", "Alaska", "AK"],
"99748": ["Kaltag", "Alaska", "AK"],
"99749": ["Kiana", "Alaska", "AK"],
"99750": ["Kivalina", "Alaska", "AK"],
"99751": ["Kobuk", "Alaska", "AK"],
"99752": ["Kotzebue", "Alaska", "AK"],
"99753": ["Koyuk", "Alaska", "AK"],
"99754": ["Koyukuk", "Alaska", "AK"],
"99755": ["Denali National Park", "Alaska", "AK"],
"99756": ["Manley Hot Springs", "Alaska", "AK"],
"99757": ["Lake Minchumina", "Alaska", "AK"],
"99758": ["Minto", "Alaska", "AK"],
"99759": ["Point Lay", "Alaska", "AK"],
"99760": ["Nenana", "Alaska", "AK"],
"99761": ["Noatak", "Alaska", "AK"],
"99762": ["Nome", "Alaska", "AK"],
"99763": ["Noorvik", "Alaska", "AK"],
"99764": ["Northway", "Alaska", "AK"],
"99765": ["Nulato", "Alaska", "AK"],
"99766": ["Point Hope", "Alaska", "AK"],
"99767": ["Rampart", "Alaska", "AK"],
"99768": ["Ruby", "Alaska", "AK"],
"99769": ["Savoonga", "Alaska", "AK"],
"99770": ["Selawik", "Alaska", "AK"],
"99771": ["Shaktoolik", "Alaska", "AK"],
"99772": ["Shishmaref", "Alaska", "AK"],
"99773": ["Shungnak", "Alaska", "AK"],
"99774": ["Stevens Village", "Alaska", "AK"],
"99775": ["Fairbanks", "Alaska", "AK"],
"99776": ["Tanacross", "Alaska", "AK"],
"99777": ["Tanana", "Alaska", "AK"],
"99778": ["Teller", "Alaska", "AK"],
"99780": ["Tok", "Alaska", "AK"],
"99781": ["Venetie", "Alaska", "AK"],
"99782": ["Wainwright", "Alaska", "AK"],
"99783": ["Wales", "Alaska", "AK"],
"99784": ["White Mountain", "Alaska", "AK"],
"99785": ["Brevig Mission", "Alaska", "AK"],
"99786": ["Ambler", "Alaska", "AK"],
"99788": ["Chalkyitsik", "Alaska", "AK"],
"99789": ["Nuiqsut", "Alaska", "AK"],
"99790": ["Fairbanks", "Alaska", "AK"],
"99791": ["Atqasuk", "Alaska", "AK"],
"99801": ["Juneau", "Alaska", "AK"],
"99820": ["Angoon", "Alaska", "AK"],
"99824": ["Douglas", "Alaska", "AK"],
"99825": ["Elfin Cove", "Alaska", "AK"],
"99826": ["Gustavus", "Alaska", "AK"],
"99827": ["Haines", "Alaska", "AK"],
"99829": ["Hoonah", "Alaska", "AK"],
"99830": ["Kake", "Alaska", "AK"],
"99832": ["Pelican", "Alaska", "AK"],
"99833": ["Petersburg", "Alaska", "AK"],
"99835": ["Sitka", "Alaska", "AK"],
"99836": ["Port Alexander", "Alaska", "AK"],
"99840": ["Skagway", "Alaska", "AK"],
"99841": ["Tenakee Springs", "Alaska", "AK"],
"99850": ["Juneau", "Alaska", "AK"],
"99901": ["Ketchikan", "Alaska", "AK"],
"99903": ["Meyers Chuck", "Alaska", "AK"],
"99918": ["Coffman Cove", "Alaska", "AK"],
"99919": ["Thorne Bay", "Alaska", "AK"],
"99921": ["Craig", "Alaska", "AK"],
"99922": ["Hydaburg", "Alaska", "AK"],
"99923": ["Hyder", "Alaska", "AK"],
"99925": ["Klawock", "Alaska", "AK"],
"99926": ["Metlakatla", "Alaska", "AK"],
"99927": ["Point Baker", "Alaska", "AK"],
"99929": ["Wrangell", "Alaska", "AK"],
};
export const zipToCityState = (zip) => zipStates[zip] || [];
