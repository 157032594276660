import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const MaxWidth = (props) => {

  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx    = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentMaxWidth = currentBlockStyles?.maxWidth?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  // console.log("currentMaxWidth", currentMaxWidth);

  const units = [
    { value: 'px',    label: 'px'   },
    { value: 'rem',   label: 'rem'  },
    { value: '%',     label: '%'    },
    { value: 'vh',    label: 'vh'   },
    { value: 'vw',    label: 'vw'   },
    { value: 'auto',  label: 'auto'   },
  ];

  const [maxWidth, setMaxWidth]       = useState(currentMaxWidth?.[0] || "auto");
  const [unit, setUnit]               = useState(currentMaxWidth?.[1] || "px");
  const [finalValue, setFinalValue]   = useState("");

  if(maxWidth !== "auto") localStorage.setItem("prevMaxWidth", maxWidth);
  
  const handleUnitChange = (e) => {
    // console.log("unit change event", e.target.value);
    if (!e.target.value) setMaxWidth("auto");
    else {
      setUnit(e.target.value);
      if(localStorage.getItem("prevMaxWidth")) {
        setMaxWidth(localStorage.getItem("prevMaxWidth"));
      }
    }
  };

  const updateMaxWidthInDB = (maxWidth) => {
    let payload = {
      id: blockId,
      value: maxWidth,
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/maxWidth', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating maxWidth payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update maxWidth...", err);
    });
  }

  useEffect(() => {
    if (maxWidth === "auto") {
      setFinalValue("auto");
    } else if (maxWidth && maxWidth !== "auto" && unit) {
      if (maxWidth !== currentMaxWidth?.[0] || unit !== currentMaxWidth?.[1]) {
        setFinalValue(`${maxWidth}${unit}`);
      }
    }
  }, [currentMaxWidth, maxWidth, unit]);

  useEffect(() => {
    let lastFinalVal = "";
    if(!currentMaxWidth?.[1]) lastFinalVal = "auto";
    else lastFinalVal = currentMaxWidth[0] + currentMaxWidth[1];
    // console.log("lastFinalVal", lastFinalVal);
    // console.log("maxWidth unit", maxWidth, unit);
    const timer = setTimeout(() => {
      if (finalValue && finalValue !== lastFinalVal) {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            let newStyles = { ...block.styling };
            newStyles.maxWidth = finalValue;
            block.styling = { ...newStyles };
            updateMaxWidthInDB(finalValue)
            return block;
          } else return block;
        }))
      }
    }, 500);
    return () => clearTimeout(timer);

  }, [blockId, finalValue]);


  // console.log("blockId", blockId);
  // console.log("maxWidth", maxWidth);
  // console.log("unit", unit);
  // console.log("finalValue maxWidth", finalValue);

  return (
    <div className="width tool">
      {props.deleteModal}
      <TextField id="width-number" className="width-input styling-input" type="number" variant="filled"
        InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling?.maxWidth + ""} value={maxWidth} onChange={(e) => {
          if (e.target.value) setMaxWidth(e.target.value);
          else setMaxWidth("auto");
        }} />

      <TextField id="select-style-unit" className="width-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }} placeholder={currentContentBlock.styling?.maxWidth + ""} value={unit} onChange={(e) => {
        console.log("targetVal", e.target.value);
        handleUnitChange(e);
      }}
      // label="Select unit" helperText="Select unit"
      >
        {units?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>
  )
}


export default MaxWidth;
