import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";

import { Delete, Edit, PeopleAlt } from "@mui/icons-material";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";

import DrawerContext from "../../store/DrawerContext.js";
import ModalContext from "../../store/ModalContext.js";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    color: "#747d8c",
  },
});


function ClientsAdminTable(props) {
  let navigate = useNavigate();
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [loadedAccs, setLoadedAccs] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    if (modalCtx.open === false)
      setRefresh((prev) => {
        return !prev;
      });
  }, [modalCtx.open]);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + "/v1/accounts/" + id + "/administrators", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        setLoadedAccs(data.data);
      });
  }, [drawerCtx.open, refresh]);

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  if (isLoading) {
    return (
      <section>
        <CircularProgress />
      </section>
    );
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadedAccs.map((data) => (
              <TableRow key={data.id}>
                <TableCell>{data.name}</TableCell>
                <TableCell>{data.email}</TableCell>
                <TableCell align="right">
                  {/* <PeopleAlt className="table-tool" /> */}
                  <Edit className="table-tool" onClick={() => { toggleDrawerHandler("edit-client-administrator", data); }} />
                  <Delete className="table-tool" onClick={() => { toggleModalHandler("delete-client-administrator", data); }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ClientsAdminTable;
