import { useState, useContext, useEffect } from "react";
import axios from "axios";
import { Button } from '@material-ui/core';

import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import { CircularProgress, FormControl, FormGroup, TextField } from "@mui/material";

const DataMiningWebScraper = (props) => {
  
  const { details } = props;
  console.log("DataMiningWebScraper props:", props.details.toggleRefresh);
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  // console.log("ModalContext props:", modalCtx);
  const [isLoading, setIsLoading]             = useState("");
  const [requestError, setRequestError]       = useState(false);
  const [websiteURL, setWebsiteURL]           = useState("");
  const [websiteTitle, setWebsiteTitle]       = useState("");
  const [scrapeWebsiteID, setScrapeWebsiteID] = useState("");

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  useEffect(() => {
    // clean up existing modal data if any on modal launch
    modalCtx.setModalData(null);
  }, []);

  useEffect(() => {
    console.log(JSON.stringify({ website: websiteURL, title: websiteTitle }));
  }, [websiteURL, websiteTitle]);

  const handleScrapeWebsite = (e) => {
    e.preventDefault();
    if(websiteURL && websiteTitle) {
      setIsLoading(true);
      // axios.post(process.env.REACT_APP_API_URI + '/v2/datamining/websites', {
      fetch(process.env.REACT_APP_API_URI + '/v2/datamining/websites', {
        method: 'POST',
        mode: 'cors',      
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify({ website: websiteURL, title: websiteTitle }),
      })
      .then(response => response.json())
      .then(result => {
        console.log("result", result);
        if (result.status === "success") {
          setScrapeWebsiteID(result?.id || "");
          setIsLoading(false);
          setRequestError(false);
          modalCtx.setModalData(result?.id || "no ID returned");
          props.details.setToggleRefresh(!props.details.toggleRefresh);
          console.log("props toggleRefresh", props.details.toggleRefresh);
          handleCloseModal();

        } else {
          console.log("failed to scrape website");
        }
      }).catch(err => {
        console.error("failed to scrape website...", err);
        setIsLoading(false);
        setRequestError(true);
      });
    }
  }

  useEffect(() => {
    // this function probably needs to be renamed as it is now a POST request 
    const getScrapedData = () => {
      setIsLoading(true);
      // axios.get(process.env.REACT_APP_API_URI + '/v2/datamining/websites/' + scrapeWebsiteID + '/scrape', {
      fetch(process.env.REACT_APP_API_URI + '/v2/datamining/websites/' + scrapeWebsiteID + '/scrape', {
        method: 'POST',
        mode: 'cors',
        // headers: { 'Content-Type': 'application/json' },
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      })
      .then(response => response.json())
      .then(result => {
        console.log(result);
        if (result.status === "success") {
          setScrapeWebsiteID(result?.id || "");
          setRequestError(false);
          setIsLoading(false);
          handleCloseModal();
        } else {
          console.log("failed to scrape website");
        }
      }).catch(err => {
        console.error("failed to scrape website...", err);
        setIsLoading(false);
        setRequestError(true);
      });
    }
    if(scrapeWebsiteID) {
      getScrapedData();
    }
  }, [authCtx.token, scrapeWebsiteID]);


  return (
    <div className="modal">

      {isLoading ? 
        <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></section> 
        :
        <>
        {requestError && <p style={{ color: "crimson", margin: "5px auto" }}>Scrape request failed, please try again.</p>}
        <p>Please add details of the website you wish to scrape.</p>
        <form>
          <FormGroup>
            <div className="double-column-form">
              <FormControl fullWidth margin="dense" className="form-control">
                <TextField required value={websiteTitle} onChange={(e) => setWebsiteTitle(e.target.value)} label="Website Title" variant="outlined">
                </TextField>
              </FormControl>
              <FormControl fullWidth margin="dense" className="form-control">
                <TextField required value={websiteURL} onChange={(e) => setWebsiteURL(e.target.value)} label="Website URL" variant="outlined">
                  </TextField>
              </FormControl>
            </div>
          </FormGroup>
          <div className="footer" style={{ margin: "15px auto 0" }}>
            <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
            <Button type="submit" className="confirm-btn" onClick={(e) => { handleScrapeWebsite(e) }}>Scrape</Button>
            {/* <Button type="submit" className="confirm-btn" onClick={() => { modalCtx.setModalData({ title: websiteTitle, website: websiteURL }) }}>Scrape</Button> */}
          </div>
        </form>
        </>
      }

    </div>
  )
}




export default DataMiningWebScraper;
