import React, { useEffect, useState, useContext } from 'react';

import Image from "./Components/Image";
import SideBar from "./Components/SideBar";
import DetailsDrawer from "./Components/DetailsDrawer";
import DetailsSubDrawer from './Components/DetailsSubDrawer';
import DetailsModal from "./Components/DetailsModal";

// Pages
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Overview from "./Pages/Overview";
import Users from "./Pages/Users";
import Clients from "./Pages/Clients";
import Email from './Pages/Email';
import ClientsAdmin from "./Pages/ClientsAdmin";
import Assessments from "./Pages/Assessments";
import AssessmentsLeaders from "./Pages/AssessmentsLeaders";
import AssessmentsMembers from "./Pages/AssessmentsMembers";
import AssessmentFields from './Pages/AssessmentFields';
import AssessmentAutomation from './Pages/AssessmentAutomation';

import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import AuthContext from './store/AuthContext.js';
import { DrawerContextProvider } from './store/DrawerContext.js';
import { SubDrawerContextProvider } from './store/SubDrawerContext';
import { ModalContextProvider } from './store/ModalContext.js';
import { BuilderContextProvider } from './store/BuilderContext';
import { DataGridContextProvider } from './store/DataGridContext';
import AssessmentsQuestions from './Pages/AssessmentsQuestions';
import AssessmentsSections from './Pages/AssessmentsSections';
import AssessmentPage from './Pages/AssessmentPage';
import AssessmentPageV2 from './Pages/AssessmentPageV2/AssessmentPageV2';
import Engagement from './Pages/Engagement';
import AssessmentBuilder from './Pages/AssessmentBuilder';
import DataMining from './Pages/Datamining';
import { UsersContextProvider } from './store/UsersContext';
import Pages from './Pages/Page';
import SidebarContext from './store/SidebarContext.js';
import AssesmentPage from './Pages/AssesmentPage.jsx';
import SetupAssessment from './Pages/SetupAssessment.jsx';
// import AssessmentDashboardPage from './Pages/AssessmentDashboardPage';

function App() {

	const authCtx = useContext(AuthContext);
	const sidebarCtx = useContext(SidebarContext);
	const [name, setName] = useState('');
	console.log(authCtx);

	const env = process.env.NODE_ENV;
	const API = process.env.REACT_APP_API_URI;

	useEffect(() => {
		if (env === "development" && API === "https://api.bleat.church") {
			console.log("%c\nYou are in development mode using the production API.\n", "color: red; font-size: 16px;");
		}
	}, [env, API]);


	return (
		<div className="App">

			<BrowserRouter>
				<UsersContextProvider>
					<DrawerContextProvider>
						<SubDrawerContextProvider>
							<ModalContextProvider>
								<BuilderContextProvider>
									<DataGridContextProvider>

										{authCtx.isLoggedIn ? <SideBar /> : null}
										<main className={sidebarCtx.isOpen ? "main main-closed" : "main"}>

											<Routes>
												<Route path="/" exact index element={!authCtx.isLoggedIn ? <Navigate to='/login' /> : <Navigate to='/overview' />} />
												<Route path="/login" exact index element={!authCtx.isLoggedIn ? <Login setName={setName} /> : <Navigate to='/overview' />} />
												<Route path="/register" exact element={!authCtx.isLoggedIn ? <Register /> : <Navigate to='/login' />} />
												<Route path="/overview" exact element={authCtx.isLoggedIn ? <Overview /> : <Navigate to='/login' />} />
												<Route path="/clients" exact element={authCtx.isLoggedIn ? <Clients /> : <Navigate to='/login' />} />
												<Route path="/clients/:id/administrators" exact element={authCtx.isLoggedIn ? <ClientsAdmin /> : <Navigate to='/login' />} />
												<Route path="/assessmentsPage" exact element={authCtx.isLoggedIn ? <Assessments /> : <Navigate to='/login' />} />
												<Route path="/emails/:id/" exact element={authCtx.isLoggedIn ? <Email /> : <Navigate to='/login' />} />
												<Route path="/assessments/:id/" exact element={authCtx.isLoggedIn ? <AssessmentPage /> : <Navigate to='/login' />} />
												<Route path="/assessmentsv2/:id/" exact element={authCtx.isLoggedIn ? <AssessmentPageV2 /> : <Navigate to='/login' />} />
												<Route path="/assessments/:id/leaders" exact element={authCtx.isLoggedIn ? <AssessmentsLeaders /> : <Navigate to='/login' />} />
												{/* <Route path="/assessments/:id/dashboard" exact element={authCtx.isLoggedIn ? <AssessmentDashboardPage/> : <Navigate to='/login'/>} /> */}
												<Route path="/assessments/:id/members" exact element={authCtx.isLoggedIn ? <AssessmentsMembers /> : <Navigate to='/login' />} />
												<Route path="/assessments/:id/questions" exact element={authCtx.isLoggedIn ? <AssessmentsQuestions /> : <Navigate to='/login' />} />
												<Route path="/assessments/:id/sections" exact element={authCtx.isLoggedIn ? <AssessmentsSections /> : <Navigate to='/login' />} />
												<Route path="/assessments/:id/fields" exact element={authCtx.isLoggedIn ? <AssessmentFields /> : <Navigate to='/login' />} />
												<Route path="/assessments/:id/automation" exact element={authCtx.isLoggedIn ? <AssessmentAutomation /> : <Navigate to='/login' />} />
												<Route path="/assessments/:id/pages" exact element={authCtx.isLoggedIn ? <Pages /> : <Navigate to='/login' />} />

												<Route path="/engagement" exact element={authCtx.isLoggedIn ? <Engagement /> : <Navigate to='/login' />} />

												<Route path="/datamining" exact element={authCtx.isLoggedIn ? <DataMining /> : <Navigate to='/login' />} />

												<Route path="/users" exact element={authCtx.isLoggedIn ? <Users /> : <Navigate to='/login' />} />

												<Route path="/builder/:id/" exact element={authCtx.isLoggedIn ? <AssessmentBuilder /> : <Navigate to='/login' />} />
												<Route path="/assessments" exact element={authCtx.isLoggedIn ? <AssesmentPage /> : <Navigate to='/login' />} />
												<Route path="/setup/:id" exact element={authCtx.isLoggedIn ? <SetupAssessment /> : <Navigate to='/login' />} />


											</Routes>
										</main>
										<DetailsDrawer />
										<DetailsSubDrawer />
										<DetailsModal />

									</DataGridContextProvider>
								</BuilderContextProvider>
							</ModalContextProvider>
						</SubDrawerContextProvider>
					</DrawerContextProvider>
				</UsersContextProvider>
			</BrowserRouter>

		</div>
	);
}

export default App;
