import React, { useState, useContext, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import "../../App.scss";
import { TextField, MenuItem, FormControl, Switch, InputLabel, Button, Checkbox, FormGroup } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DrawerContext from "../../store/DrawerContext";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import { Clear, Delete, Done, ExitToApp, Phone, Search, SearchOutlined, Close } from '@material-ui/icons';
import ModeEditOutlinedIcon from '@mui/icons-material/ModeEditOutlined';
import Select from 'react-select/creatable';
import PaperPin from '../../assets/img/paperpin-image.svg';
import { useDropzone } from "react-dropzone";
import { set } from "lodash-es";
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { useNavigate, useParams, NavLink } from "react-router-dom";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import DragIcon from '../../assets/img/Question Builder/DragIndicator.svg';
import deleteIcon from '../../assets/img/Question Builder/DelIcon.svg'

function CreateAssessmentDrawer(props) {
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  let navigate = useNavigate();

  const [assessmentTopic, setAssessmentTopic] = useState("");
  const [assessmentName, setAssessmentName] = useState("");
  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState("");
  const [URL, setURL] = useState("");
  const [nameType, setNameType] = useState("");
  const [totalScore, setTotalScore] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [audienceAssessmentIsFor, setAudienceAssessmentIsFor] = useState("");
  const [assessmentMeasure, setAssessmentMeasure] = useState("");
  const [searchTerm, setSearchTerm]         = useState("");
  const [counter, setCounter] = useState(0);
  const [clientsMenu, setClientsMenu] = useState([]);
  const [clientName, setClientName] = useState("");
  const [totalCounter, setTotalCounter] = useState(0);
  const [automation, setAutomation] = useState("no");
  const [automationType, setAutomationType] = useState("default");
  const [additional, setAdditional] = useState("no");
  const [callFeature, setCallFeature] = useState("no");
  const [callFeatureType, setCallFeatureType] = useState("no");
  const [buyAResource, setBuyAResource] = useState("no");
  const [buyAResourceLink, setBuyAResourceLink] = useState("");
  const [showDragAndDropLogo, setShowDragAndDropLogo] = useState(false);
  const [referralType, setReferralType] = useState("team");
  const [abTesting, setAbTesting] = useState("no");
  const [builder, setBuilder] = useState("custom");
  const [assessmentId, setAssessmentId] = useState("");
  const [assessmentType, setAssessmentType] = useState("Team");
  const [collectionType, setCollectionType] = useState("Main Assessment");
  const [collectionDetail, setCollectionDetail] = useState("Collect User Details");
  const [columnArray , setColumnArray] = useState([]);
  const [sections, setSections] = useState([ ]);
  const [disabledTitles, setDisabledTitles] = useState([]);
  
  const [imageType, setImageType] = useState(modalCtx?.details?.imageType)
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [image, setImage] = useState();
  const [finalImage, setFinalImage] = useState();
  const [isUploading, setIsUploading] = useState(false);

  // console.log(clients);
  const [newAssessment, setNewAssessment] = useState({
    title: assessmentTopic,
    clientID: clientID,
    assessmentName: assessmentName,
    audienceAssessmentIsFor: audienceAssessmentIsFor,
    assessmentMeasure: assessmentMeasure,
    referralType: referralType,
    automation: automation,
    automationType: automationType,
    additional: additional,
    totalScore: totalScore,
    callFeature: callFeature,
    callFeatureType: callFeatureType,
    buyAResource: buyAResource,
    abTesting: abTesting,
    builder: builder
  });

  const drawerCtx = useContext(DrawerContext);

  const switchAutomation = {
    inputProps: { "aria-label": "Has automation" },
  };
  const switchAdditional = {
    inputProps: { "aria-label": "Has additional assessment" },
  };
  const switchCallFeature = {
    inputProps: { "aria-label": "Has book a call feature" },
  };
  const switchBuyAResource = {
    inputProps: { "aria-label": "Buy a book/resource/course" },
  };
  const switchAbTesting = {
    inputProps: { "aria-label": "A/B Testing" },
  };
  const switchBuilder = {
    inputProps: { "aria-label": "Builder" },
  };

  let initialArray = [
    { id: 1, value:'First Name', label: '', placeholder: '' },
    { id: 2,value:'Last Name', label: '', placeholder: ''},
    { id: 3,value:'Full Name', label: '', placeholder: ''},
    { id: 4,value:'Mobile Number', label: '', placeholder: ''},
    { id: 5,value:'Email', label: '', placeholder: ''},
    { id: 6,value:'Church Name', label: '', placeholder: ''},
    { id: 7,value:'City', label: '', placeholder: ''},
    { id: 8,value:'Zip Code', label: '', placeholder: ''},
    { id: 9,value:'Average Weekly Attendance', label: '', placeholder: ''},
    { id: 10,value:'Job Title', label: '', placeholder: ''},
    { id: 11,value:'Church Website', label: '', placeholder: ''},
    { id: 12,value:'Organisation Website', label: '', placeholder: ''},
  ]


  useEffect(()=>{
      setColumnArray(initialArray)
  },[])

  useEffect(() => {
    let idsIncluded = [];
    let idsDisabled = [];
    sections.some(element => {
      idsIncluded.push(element.id);
    });
    if(idsIncluded.includes(3)){
      idsDisabled = [...idsDisabled, 1, 2];
    }
    else if(idsIncluded.includes(1) || idsIncluded.includes(2)){
      idsDisabled = [...idsDisabled, 3];
    }
    setDisabledTitles(ids => idsDisabled);
  }, [sections]);




  useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + "/v3/accounts", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => response.json())
    .then((data) => {
      setIsLoading(false);
      setClients([...data.data]);
      data.data.map(client =>
        setClientsMenu(prev => {
          return !clientsMenu.includes({label: client.title, value: client.id}) 
          && [...prev, {label: client.title, value: client.id}]
        })
      )
    });
  }, [drawerCtx.open]);

  
  useEffect(() => {
    callFeatureType == "yes" ?
    setNewAssessment((prev) => {
      return {
        ...prev,
        title: assessmentTopic,
        clientID: clientID,
        assessmentName: assessmentName,
        audienceAssessmentIsFor: audienceAssessmentIsFor,
        assessmentMeasure: assessmentMeasure,
        referralType: referralType,
        automation: automation,
        automationType: automationType,
        additional: additional,
        totalScore: totalScore,
        callFeature: "no",
        callFeatureType: callFeatureType,
        buyAResource: buyAResource,
        abTesting: abTesting,
        builder: builder
      };
    }) :
    setNewAssessment((prev) => {
      return {
        ...prev,
        title: assessmentTopic,
        clientID: clientID,
        assessmentName: assessmentName,
        audienceAssessmentIsFor: audienceAssessmentIsFor,
        assessmentMeasure: assessmentMeasure,
        referralType: referralType,
        automation: automation,
        automationType: automationType,
        additional: additional,
        totalScore: totalScore,
        callFeature: callFeature,
        callFeatureType: callFeatureType,
        buyAResource: buyAResource,
        abTesting: abTesting,
        builder: builder
      };
    });
  }, [assessmentTopic, assessmentName, audienceAssessmentIsFor, assessmentMeasure, referralType, callFeature, callFeatureType, buyAResource, automationType, clientID, nameType, automation, additional, totalScore, abTesting, builder]);

  const handleSubmit = (e) => {
    // e.preventDefault();
    console.log(newAssessment);
    console.log("Form Submitted");
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/assessment", {
      method: "POST",
      body: JSON.stringify(newAssessment),
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setAssessmentId(data.id);
      setCounter(13);
    });
  };

  const handleCreateClient = (meta) => {
    console.log(meta);
    setShowDragAndDropLogo(true);
    setCounter(1);
   
    fetch(process.env.REACT_APP_API_URI + "/v1/accounts", {
      method: "POST",
      body: JSON.stringify({
        title: meta.option.label,
      }),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setClientID(data.id);
        setClientsMenu(prev => {
          return !clientsMenu.includes({label: meta.option.label, value: data.id}) 
          && [...prev, {label: meta.option.label, value: data.id}]
        })
        data.status == "success" && setShowDragAndDropLogo(true) && setCounter(1);
      });
  }
  // console.log(clientsMenu);

  useEffect(() => {
    setTotalCounter(12);
  }, [automation, automationType, callFeature, callFeatureType, buyAResource]);

  const [showFinish, setShowFinish] = useState(false);
  
  const handlePrev = () => {
    setCounter(prev => prev > 0 ? prev - 1 : prev);
    // counter == 2 && setCounter(showDragAndDropLogo ? 1 : 0);

    counter == 10 && automation == "no" && setCounter(7);
    counter == 10 && automation == "yes" && automationType == "default" && setCounter(8);
    counter == 10 && automation == "yes" && automationType == "custom" && setCounter(9);

    counter == 12 && callFeature == "yes" && setCounter(10);
    counter == 12 && callFeatureType == "yes" && setCounter(11);
    counter == 12 && callFeatureType == "no" && setCounter(10);
    counter == 12 && callFeature == "no" && automationType == "custom" && setCounter(9);
    counter == 12 && callFeature == "no" && automationType == "default" && setCounter(8);
    counter == 12 && callFeature == "no" && automation == "no" && setCounter(7);
    setShowFinish(false);
  }

  const handleNext = () => {
    setCounter(prev => prev < 12 ? prev + 1 : prev);

    // counter == 7 && automation == "no" && callFeature == "no" && buyAResource == "no" && setCounter(7) && setShowFinish(true);
    // counter == 7 && automation == "no" && callFeature == "no" && buyAResource == "yes" && setCounter(12) && setShowFinish(true);
    // counter == 7 && automation == "yes" && automationType == "default" && callFeature == "no" && buyAResource == "no" && setCounter(8) && setShowFinish(true);
    // counter == 7 && automation == "no" && callFeature == "yes" && setCounter(10);
    // counter == 8 && automationType == "default" && callFeature == "no" && buyAResource == "no" && setShowFinish(true);

    // counter == 8 && automationType == "default" && callFeature == "no" && buyAResource == "no" && setCounter(8) && setShowFinish(true);
    // counter == 8 && automationType == "default" && callFeature == "yes" && buyAResource == "no" && setCounter(10);
    // counter == 8 && automationType == "default" && callFeature == "yes" && buyAResource == "yes" && setCounter(10);
    // counter == 8 && automationType == "default" && callFeature == "no" && buyAResource == "yes" && setCounter(12);

    // counter == 9 && callFeature == "no" && buyAResource == "no" && setCounter(9) && setShowFinish(true);
    // counter == 9 && callFeature == "no" && buyAResource == "yes" && setCounter(12);
    // counter == 9 && callFeature == "yes" && setCounter(10);

    // counter == 10 && callFeatureType == "no" && buyAResource == "yes" && setCounter(12);
    // counter == 10 && callFeatureType == "no" && buyAResource == "no" && setCounter(10) && setShowFinish(true);
    
    // counter == 11 && callFeatureType == "yes" && buyAResource == "no" && setCounter(11) && setShowFinish(true);
    // counter == 12 && setShowFinish(true);
      
    
  }  
  // console.log(counter, showFinish);

  const handleSecondPage = (meta) => {
    setShowDragAndDropLogo(false);
    meta.action == 'select-option' && setTimeout(() => {
      setCounter(1);
    }, 1000);
    meta.action == 'create-option' && handleCreateClient(meta);
  }

  useEffect(() => {

    if(automation == "no") setAutomationType("default");
    if(callFeature == "no") setCallFeatureType("no");

    if(counter == 7){
      if(automation == "no"){
        if(callFeature == "no"){
          if(buyAResource == "no")
            setShowFinish(true);
          else setShowFinish(false);
        } else setShowFinish(false);
      } else setShowFinish(false);
    }

    if(counter == 8){
      if(callFeature == "no" && buyAResource == "no"){
        if(automationType == "default") setShowFinish(true);
        else setShowFinish(false);
      } else setShowFinish(false);
    }

    if(counter == 9){
      if(callFeature == "no" && buyAResource == "no") setShowFinish(true);
      else setShowFinish(false);
    }
    
    if(counter == 10){
      if(buyAResource == "no"){
        if(callFeatureType == "no") setShowFinish(true);
        else setShowFinish(false);
      } else setShowFinish(false);
    }

    if(counter == 11){
      if(buyAResource == "no") setShowFinish(true);
      else setShowFinish(false);
    }

    if(counter == 12) setShowFinish(true);

  }, [counter, automation, automationType, callFeature, callFeatureType, buyAResource])

  const handleCLick = (id) => {
    const indexToRemove = columnArray.findIndex(item => item.id === id);
  
    if (indexToRemove !== -1) {
      // Remove the element from columnArray
      const removedElement = columnArray.splice(indexToRemove, 1)[0];
  
      setSections(prevSections => [...prevSections, removedElement]);
      setColumnArray(prevArray => columnArray.slice());
    }
  }
  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Item was not dropped in a valid destination
    }

    // Reorder the sections in the state based on the drag-and-drop result
    const updatedSections = [...sections];
    const [movedSection] = updatedSections.splice(result.source.index, 1); // Remove the dragged item
    updatedSections.splice(result.destination.index, 0, movedSection); // Insert it at the new position

    // Update the sections state with the reordered array
    setSections(updatedSections)
    changeSectionsOrder(updatedSections);
  };
  const changeSectionsOrder=(updatedOrder)=>{
    let payload = {
      "sectionOrder":
        updatedOrder.map((el,index)=>{
          return {
            "sectionId": el.id,
            "orderIndex": index+1
          }
        })
    }
  };
  const removeSelectedField = (id) => {
    let sec = [...sections];
    const indexToRemove = sec.findIndex(item => item.id === id);
  
    if (indexToRemove !== -1) {
      // Remove the element from sectionArray
      const removedElement = sec.splice(indexToRemove, 1)[0];
  
      setSections(prevArray => sec.slice());
      setColumnArray(prevArray => [...prevArray, removedElement]);
    }
  }
  
  
  const switchNext = (counter) => {
    switch (counter) {
      case 0: 
        return 'Assessment Name';
        break;
      case 1: 
        return 'What type of assessment is this?';
        break;
      case 2: 
        return 'Will This Assessment Be Anonymous Or Collect User Details?';
        break;
      case 3: 
        return 'What Information Would Be Collected From The User?';
        break;
      case 4: 
        return 'Do You Want To Ask A Set Of Additional Questions?';
        break;
      case 5: 
        return 'Assessment Topic | Drag and Drop your Logo';
        break;
      case 6: 
        return 'Audience Assessment is for';
        break;
      case 7: 
        return 'What are you measuring?';
        break;

      default:
        break;
    }
  }
  // console.log(counter);

  const [showApiKeyField, setShowApiKeyField] = useState(false);
  const [activeCampaignApiKey, setActiveCampaignApiKey] = useState("");
  const [showCalendlyLink, setShowCalendlyLink] = useState(false);
  const [calendlyLink, setCalendlyLink] = useState("");

  const ApiKeyField = (showApiKeyField) => {
    return (
      showApiKeyField && <Box sx={{marginLeft: '-5px', width: '300px', height: '75px', }}>
        <TextField fullwidth
            value={activeCampaignApiKey}
            onChange={e => setActiveCampaignApiKey(e.target.value)}
            fullWidth
            className="form-control-add-question" InputLabelProps={{ shrink: true }} variant="outlined" required>
        </TextField>
      </Box>
    );
  }
  const calendlyLinkField = (showCalendlyLink) => {
    return (
      showCalendlyLink && <Box sx={{marginLeft: '-5px', width: '300px', height: '75px', }}>
        <TextField fullwidth
            value={calendlyLink}
            onChange={e => setCalendlyLink(e.target.value)}
            fullWidth
            className="form-control-add-question" InputLabelProps={{ shrink: true }} variant="outlined" required>
        </TextField>
      </Box>
    );
  }


  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);  
  

  function Dropzone({ onDrop, accept, open }) {
    const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
      useDropzone({
        accept,
        onDrop,
      });
  
    const files = acceptedFiles.map((file) => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  
    return (
      <Box sx={{width: '100%', height: '400px', backgroundColor: '#f5f7fb', marginTop: '-10vh', borderRadius: '12px', border: '2px dashed', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '5px 50px 30px', boxSizing: 'border-box' }}>
        <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}} {...getRootProps({ className: "dropzone" })}>
          <input className="input-zone" {...getInputProps()} />
          <div className="text-center" style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
            {isDragActive ? (
              <p className="dropzone-content">
                Release to drop the files here
              </p>
            ) : (
              <>
              <h1 style={{ fontSize: '30px'}}>Drag and drop your logo here</h1>
              <img src={PaperPin} style={{height: '150px', marginBottom: '20px'}} alt="" />
              <span style={{fontSize: '20px', fontWeight: '600'}}>Drag an image here or <span style={{color: '#222', textDecoration: 'underline'}}>Browse</span> for an image to upload</span>
              </>
            )}
            {/* <Button type="button" onClick={open} className="btn">Click to select Image </Button> */}
          </div>
        </div>
      </Box>
    );
  }

  const editInputFieldDetails = (data) => {
    modalCtx.openModal();
    modalCtx.setDetails("edit-input-field-details", data);
  }

  useEffect(() => {
    if(modalCtx.details?.id){
      let id = modalCtx.details?.id;
      let sec = [...sections];
      const indexToRemove = sec.findIndex(item => item.id === id);
      
      if (indexToRemove !== -1) {
        // Remove the element from sectionArray
        const removedElement = sec.splice(indexToRemove, 1)[0];
        sec = [...sec, modalCtx.details];
        setSections(prevArray => sec);
      }
    }
  }, [modalCtx]) 

  useEffect(() => {
    console.log(sections);
  }, [sections])
/* <Box sx={{width: '100%', height: '400px', backgroundColor: '#f5f7fb', marginTop: '-10vh', borderRadius: '12px', border: '2px dashed', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '5px 50px 30px', boxSizing: 'border-box' }}>
    <h1 style={{ fontSize: '30px'}}>Drag and drop your logo here</h1>
    <img src={PaperPin} style={{height: '150px', marginBottom: '20px'}} alt="" />
    <span style={{fontSize: '20px', fontWeight: '600'}}>Drag an image here or <a style={{color: '#222'}} href="">Browse</a> for an image to upload</span>
   </Box> */








  // console.log(automationType);
  return (
    <div className="container">
      <Box className="drawer create-assessment-drawer">
        <div style={{ position: "absolute", left: "30px", top: "15px", height: "40px", width: "40px", display: "flex", alignItems: "center", justifyContent: "center", cursor: "pointer", zIndex: 999 }}>
          <Clear onClick={drawerCtx.closeDrawer} />
        </div>

        {counter == 0 && (
          <header style={{ height: "75px", position: "absolute", top: 0, left: 0 }}>
            <h1 style={{ textAlign: "center", fontFamily: "'Montserrat', sans-serif" }} data-aos="fade-right" data-aos-delay="500">
              Let's get started
            </h1>
          </header>
        )}

        <Box className="add-question-drawer" sx={{ boxSizing: "border-box", width: "95%", height: "calc(100vh - 100px)", margin: "0 auto", padding: "0", backgroundColor: "white", display: "flex", flexDirection: "column", overflow: "hidden" }}>
          {counter == 0 && (
            <div className="center-flex">
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Client Name</h2>
              <Select
                classNamePrefix="react-select"
                className="form-details-text react-select"
                options={clientsMenu}
                // label={clientName}
                onChange={(e, meta) => {
                  // (meta.action === 'create-option') && handleCreateClient(meta); //&& setShowDragAndDropLogo(true);
                  // (meta.action === 'select-option') && setShowDragAndDropLogo(false);
                  handleSecondPage(meta);
                  setClientID(e.value);
                  setClientName(e.label);
                  // setTimeout(() => handleNext(), 500);
                }}
                placeholder={"Start typing or select"}
              />
            </div>
          )}

          {counter == 1 && (
            <div className="center-flex">
              {" "}
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Assessment Name</h2>
              <TextField
                fullWidth
                className="form-control-add-question"
                value={assessmentName}
                onChange={(e) => {
                  setAssessmentName(e.target.value);
                }}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                required
              />
            </div>
          )}

          {counter == 2 && (
            <div className="start-flex" style={{overflowY: "auto", paddingBottom: '50px'}}>
              {" "}
              <h2 style={{ textAlign: "center" }}>What type of assessment is this?</h2>
              <div>
                <div className="type-assessment">
                  <input type="radio" name="assessment-type" id="assessment-type" value={"Simple"} onChange={(e) => setAssessmentType(e.currentTarget.value)} checked={assessmentType === "Simple"} />
                  <div className="assessment-info">
                    <h3>Simple</h3>
                    <div>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam.</div>
                  </div>
                </div>
                <div className="type-assessment">
                  <input type="radio" name="assessment-type" id="assessment-type" value={"Team"} onChange={(e) => setAssessmentType(e.currentTarget.value)} checked={assessmentType === "Team"} />
                  <div className="assessment-info">
                    <h3>Team</h3>
                    <div>Team assessments allow leaders to share with the team among a closed group.</div>
                  </div>
                </div>
                <div className="type-assessment">
                  <input type="radio" name="assessment-type" id="assessment-type" value={"Referral"} onChange={(e) => setAssessmentType(e.currentTarget.value)} checked={assessmentType === "Referral"} />
                  <div className="assessment-info">
                    <h3>Referral</h3>
                    <div>Referral assessments are open to be shared by anyone that does the assessment.</div>
                  </div>
                </div>
                <div className="type-assessment">
                  <input type="radio" name="assessment-type" id="assessment-type" value={"Multilevel Assessment"} onChange={(e) => setAssessmentType(e.currentTarget.value)} checked={assessmentType === "Multilevel Assessment"} />
                  <div className="assessment-info">
                    <h3>Multilevel Assessment</h3>
                    <div>Multilevel Assessment is a assessment which has multiple levels of users.</div>
                  </div>
                </div>
                <div className="type-assessment">
                  <input type="radio" name="assessment-type" id="assessment-type" value={"Collection"} onChange={(e) => setAssessmentType(e.currentTarget.value)} checked={assessmentType === "Collection"} />
                  <div className="assessment-info">
                    <h3>Collection</h3>
                    <div>Collection is a set of multiple sub - assessments under a main assessment.</div>
                  </div>
                </div>
              </div>
              {assessmentType === "Collection" && (
                <>
                  <h2 style={{ textAlign: "center", margin: "25px 0" }}>Is this a main assessment or a sub - assessment of another main assessment?</h2>
                  <div style={{ textAlign: "center" }}>
                    Main Assessment &nbsp;
                    <Switch {...switchBuyAResource} checked={collectionType == "Main Assessment" ? false : true} onChange={(e) => setCollectionType(e.target.checked ? "Sub Assessment" : "Main Assessment")} />
                    &nbsp; Sub Assessment
                  </div>
                  <div>
                    <Select
                      classNamePrefix="react-select"
                      className="form-details-text react-select"
                      options={clientsMenu}
                      // label={clientName}
                      onChange={(e, meta) => {
                        console.log(meta);
                        // (meta.action === 'create-option') && handleCreateClient(meta); //&& setShowDragAndDropLogo(true);
                        // (meta.action === 'select-option') && setShowDragAndDropLogo(false);
                        handleSecondPage(meta);
                        setClientID(e.value);
                        setClientName(e.label);
                        // setTimeout(() => handleNext(), 500);
                      }}
                      placeholder={"Start typing or select"}
                    />
                  </div>
                </>
              )}
            </div>
          )}

          {counter == 3 && (
            <div className="center-flex">
              {" "}
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Will this assessment be anonymous or collect user details?</h2>
              <div className="assessment-collection-details">
                <div className="detail">
                  <input type="radio" name="assessment-collection-detail" id="assessment-collection-detail" value={"Anonymous"} onChange={(e) => setCollectionDetail(e.currentTarget.value)} checked={collectionDetail === "Anonymous"} />
                  <h4>Anonymous</h4>
                </div>
                <div className="detail">
                  <input type="radio" name="assessment-collection-detail" id="assessment-collection-detail" value={"Collect User Details"} onChange={(e) => setCollectionDetail(e.currentTarget.value)} checked={collectionDetail === "Collect User Details"} />
                  <h4>Collect User Details</h4>
                </div>
              </div>
            </div>
          )}

          {counter == 4 && (
            <div className="start-flex" style={{maxWidth: "100%", display: "flex", flexDirection: "column"}}>
              <h2 style={{ textAlign: "center", marginBottom: "10px", height: "35px" }}>What Information Would Be Collected From The User?</h2>
              <Box sx={{ width: "100%", height: "calc(100% - 55px)", flexShrink: 1, overflowY: 'hidden' }}>

                <div className="columns">
                  <h3>Input Field List</h3>
                  <h3>Selected Input Field List</h3>
                </div>
                <div className="columns" style={{ height: 'calc(100% - 65px)', flexShrink: 1}}>
                  <div className="columns-list-content">
                    {columnArray.map((item) => (
                      <div key={item.id} className={`list-content ${disabledTitles.includes(item.id) ? 'disabled-list-content' : ''}`}>
                        <h3>{item.value}</h3>
                        {
                          !(disabledTitles.includes(item.id)) && 
                          <KeyboardArrowRightIcon
                            className="arrow"
                            onClick={() => {
                              handleCLick(item.id);
                            }}
                          />
                        }
                      </div>
                    ))}
                  </div>
                  <div className="selected-coulmn-list">
                    <DragDropContext onDragEnd={onDragEnd}>
                      <Droppable droppableId="setups" direction="horizontal">
                        {(provided) => (
                          <div className="columns-list-content" style={sections?.length == 0 ? { overflow: "hidden" } : {}} ref={provided.innerRef} {...provided.droppableProps}>
                            {sections?.map((data, index) => (
                              <Draggable key={data.id.toString()} draggableId={data.id.toString()} index={index}>
                                {(provided) => (
                                  <div className="columns-list-child" key={data.id} {...provided.draggableProps} ref={provided.innerRef}>
                                    <div className="order-no-and-drag-section-name-icon">
                                      <div className="drag-section">
                                        <div className="order-drag-icon" {...provided.dragHandleProps}>
                                          <img src={DragIcon} alt="dragicon" />
                                        </div>
                                        <div className="order-no">{index + 1}</div>
                                      </div>

                                      <div>
                                        <h3 className="section-name" dangerouslySetInnerHTML={{ __html: data?.value }} />
                                        <p><b>Label:</b> {data.label !=='' ? data.label : '__________' }</p>
                                        <p><b>Placeholder:</b> {data.placeholder !== '' ? data.placeholder : '__________'}</p>
                                      </div>
                                    </div>

                                    <div className="icons">
                                      <ModeEditOutlinedIcon onClick={() => editInputFieldDetails(data)} />
                                      <Close onClick={() => removeSelectedField(data.id)} />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              </Box>
            </div>
          )}

          {counter == 5 && (
            <div className="start-flex additional-questions" style={{overflowY: "auto", paddingBottom: '50px'}}>
              <h2 style={{ textAlign: "center" }}>Do you want to ask a set of additional questions?</h2>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "auto" }}>
                  <div style={{ width: "65%" }}>
                    <div style={{ fontSize: "18px" }}>Integrate email integration automation?</div>
                    <div style={{ fontSize: "14px" }}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                  </div>

                  <div>
                    No&nbsp;
                    <Switch
                      checked={automation == "yes" ? true : false}
                      {...switchAutomation}
                      onChange={(e) => {
                        setAutomation(e.target.checked ? "yes" : "no");
                      }}
                    />
                    &nbsp; Yes
                  </div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "auto" }}>
                  <div style={{ width: "65%" }}>
                    <div style={{ fontSize: "18px" }}>Add additional questions:</div>
                    <div style={{ fontSize: "14px" }}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                  </div>
                  <div>
                    No&nbsp;
                    <Switch checked={additional == "yes" ? true : false} {...switchAdditional} onChange={(e) => setAdditional(e.target.checked ? "yes" : "no")} />
                    &nbsp; Yes
                  </div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "auto" }}>
                  <div style={{ width: "65%" }}>
                    <div style={{ fontSize: "18px" }}>Include a 'request a call' feature:</div>
                    <div style={{ fontSize: "14px" }}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                  </div>

                  <div>
                    No&nbsp;
                    <Switch checked={callFeature == "yes" ? true : false} {...switchCallFeature} onChange={(e) => setCallFeature(e.target.checked ? "yes" : "no")} />
                    &nbsp; Yes
                  </div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "auto" }}>
                  <div style={{ width: "65%" }}>
                    <div style={{ fontSize: "18px" }}>Include a 'buy a book/resource/course' feature:</div>
                    <div style={{ fontSize: "14px" }}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                  </div>

                  <div>
                    No&nbsp;
                    <Switch checked={buyAResource == "yes" ? true : false} {...switchBuyAResource} onChange={(e) => setBuyAResource(e.target.checked ? "yes" : "no")} />
                    &nbsp; Yes
                  </div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "auto" }}>
                  <div style={{ width: "65%" }}>
                    <div style={{ fontSize: "18px" }}>Add A/B testing groups:</div>
                    <div style={{ fontSize: "14px" }}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                  </div>

                  <div>
                    No&nbsp;
                    <Switch checked={abTesting == "yes" ? true : false} {...switchAbTesting} onChange={(e) => setAbTesting(e.target.checked ? "yes" : "no")} />
                    &nbsp; Yes
                  </div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
                <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", margin: "auto" }}>
                  <div style={{ width: "65%" }}>
                    <div style={{ fontSize: "18px" }}>Create with builder:</div>
                    <div style={{ fontSize: "14px" }}>Select this if you're building this assessment with the Builder</div>
                  </div>

                  <div>
                    Custom&nbsp;
                    <Switch checked={builder == "builder" ? true : false} {...switchBuilder} onChange={(e) => setBuilder(e.target.checked ? "builder" : "custom")} />
                    &nbsp; Builder
                  </div>
                </div>
              </FormControl>
            </div>
          )}

          {counter == 6 && (
            <div className="center-flex">
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Assessment Topic</h2>
              <TextField fullWidth className="form-control-add-question" value={assessmentTopic} onChange={(e) => setAssessmentTopic(e.target.value)} variant="outlined" InputLabelProps={{ shrink: true }} required />{" "}
            </div>
          )}

          {counter == 7 && (
            <div className="center-flex">
              {" "}
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}>Audience Assessment is for</h2>
              <TextField fullWidth className="form-control-add-question" value={audienceAssessmentIsFor} onChange={(e) => setAudienceAssessmentIsFor(e.target.value)} variant="outlined" InputLabelProps={{ shrink: true }} required />{" "}
            </div>
          )}

          {counter == 8 && (
            <div className="center-flex">
              {" "}
              <h2 style={{ textAlign: "center", marginBottom: "10px" }}>What is this assessment helping users measure?</h2>
              <TextField fullWidth className="form-control-add-question" value={assessmentMeasure} onChange={(e) => setAssessmentMeasure(e.target.value)} variant="outlined" InputLabelProps={{ shrink: true }} required />{" "}
            </div>
          )}

          {/* {counter == 2 && showDragAndDropLogo && <>
              <Box sx={{width: '100%', height: '400px', backgroundColor: '#f5f7fb', marginTop: '-10vh', borderRadius: '12px', border: '2px dashed', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'space-evenly', padding: '5px 50px 30px', boxSizing: 'border-box' }}>
                <h1 style={{ fontSize: '30px'}}>Drag and drop your logo here</h1>
                <img src={PaperPin} style={{height: '150px', marginBottom: '20px'}} alt="" />
                <span style={{fontSize: '20px', fontWeight: '600'}}>Drag an image here or <a style={{color: '#222'}} href="">Browse</a> for an image to upload</span>
              </Box>
              <Dropzone onDrop={onDrop} accept={"image/*"} />
            </>} */}

          {/* {counter == 3 && <> <h2 style={{textAlign: 'center'}}>Assessment Topic</h2>
            <TextField
              fullWidth
              className="form-control-add-question"
              value={assessmentTopic}
              onChange={(e) => setAssessmentTopic(e.target.value)}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              required
            /> </>} */}

          {/* {counter == 3 && <> <h2 style={{textAlign: 'center'}}>Assessment Name</h2>
              <TextField
              fullWidth
              className="form-control-add-question"
              value={assessmentName}
              onChange={(e) => {
                setAssessmentName(e.target.value);
              }}
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              required
              />
            </>} */}

          {/* {counter == 6 && <> <h2 style={{textAlign: 'center'}}>What type of assessment is this?</h2>
            <div style={{textAlign: 'center'}}>Team &nbsp;<Switch {...switchBuyAResource} checked={referralType == "team" ? false : true} onChange={(e) => setReferralType(e.target.checked ? "referal" : "team")} />&nbsp; Referral</div>
            <div style={{position: 'absolute', bottom: '200px', width: '50%', textAlign: 'center'}}>* Team assessments allow leaders to share with the team among a closed group<br />* Referral assessments are open to be shared by anyone that does the assessment</div>
            </>} */}

          {/* {counter == 7 && <Box sx={{marginTop: '-20vh'}}> <h2 style={{textAlign: 'center'}}>Do you want to ask a set of additional questions?</h2>

              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '70%'}}>
                  <div style={{fontSize: '18px'}}>Integrate email integration automation?</div>
                  <div style={{fontSize: '14px'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                </div>
                
                <div>No&nbsp;<Switch checked={automation == "yes" ? true : false} {...switchAutomation} onChange={(e) => {
                    setAutomation(e.target.checked ? "yes" : "no");
                  }}
                />&nbsp; Yes</div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle" >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '70%'}}>
                  <div style={{fontSize: '18px'}}>Add additional questions:</div>
                  <div style={{fontSize: '14px'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                </div>
                <div>No&nbsp;
                <Switch checked={additional == "yes" ? true : false} {...switchAdditional} onChange={(e) => setAdditional(e.target.checked ? "yes" : "no")} /> 
                &nbsp; Yes</div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle" >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '70%'}}>
                  <div style={{fontSize: '18px'}}>Include a 'request a call' feature:</div>
                  <div style={{fontSize: '14px'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                </div>
                
                  <div>No&nbsp;
                    <Switch checked={callFeature == "yes" ? true : false} {...switchCallFeature} onChange={(e) => setCallFeature(e.target.checked ? "yes" : "no")} /> 
                  &nbsp; Yes</div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle" >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '70%'}}>
                  <div style={{fontSize: '18px'}}>Include a 'buy a book/resource/course' feature:</div>
                  <div style={{fontSize: '14px'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                </div>
                
                  <div>No&nbsp;
                  <Switch checked={buyAResource == "yes" ? true : false} {...switchBuyAResource} onChange={(e) => setBuyAResource(e.target.checked ? "yes" : "no")} /> 
                  &nbsp; Yes</div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle" >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '70%'}}>
                  <div style={{fontSize: '18px'}}>Add A/B testing groups:</div>
                  <div style={{fontSize: '14px'}}>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Lorem, ipsum.</div>
                </div>
                
                  <div>No&nbsp;
                  <Switch checked={abTesting == "yes" ? true : false} {...switchAbTesting} onChange={(e) => setAbTesting(e.target.checked ? "yes" : "no")} /> 
                  &nbsp; Yes</div>
                </div>
              </FormControl>
              <FormControl fullWidth className="form-control-add-question question-mandatory-toggle" >
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                <div style={{width: '70%'}}>
                  <div style={{fontSize: '18px'}}>Create with builder:</div>
                  <div style={{fontSize: '14px'}}>Select this if you're building this assessment with the Builder</div>
                </div>
                
                  <div>Custom&nbsp;
                  <Switch checked={builder == "builder" ? true : false} {...switchBuilder} onChange={(e) => setBuilder(e.target.checked ? "builder" : "custom")} /> 
                  &nbsp; Builder</div>
                </div>
              </FormControl>
            </Box>} */}

          {automation == "yes" && counter == 8 && (
            <Box>
              {" "}
              <h2 style={{ textAlign: "center" }}>Would you like us to setup the default automation or use your own?</h2>
              <div style={{ textAlign: "center" }}>
                Default &nbsp;
                <Switch
                  {...switchAutomation}
                  checked={automationType == "default" ? false : true}
                  onChange={(e) => {
                    setAutomationType(e.target.checked ? "custom" : "default");
                  }}
                />
                &nbsp; I have my own automation
              </div>
            </Box>
          )}
          {automationType == "custom" && counter == 9 && (
            <Box>
              {" "}
              <h2 style={{ textAlign: "center" }}>Please authorize use of your Active Campaign account.</h2>
              <div style={{ justifyContent: "center", cursor: "pointer", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "300px" }}>
                  <Button className="complete-create-assessment" variant="contained" sx={{ minWidth: "70px" }} onClick={() => setShowApiKeyField(false)}>
                    Cancel
                  </Button>
                  <Button className="complete-create-assessment" variant="contained" color="primary" sx={{ minWidth: "70px" }} onClick={() => setShowApiKeyField(true)}>
                    Give Access
                  </Button>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>{ApiKeyField(showApiKeyField)}</div>
              </div>
            </Box>
          )}

          {callFeature == "yes" && counter == 10 && (
            <Box>
              {" "}
              <h2 style={{ textAlign: "center" }}>Would you like to integrate calendly?</h2>
              <div style={{ textAlign: "center" }}>
                No &nbsp;
                <Switch
                  {...switchCallFeature}
                  checked={callFeatureType == "no" ? false : true}
                  onChange={(e) => {
                    setCallFeatureType(e.target.checked ? "yes" : "no");
                  }}
                />
                &nbsp; Yes
              </div>
            </Box>
          )}

          {callFeatureType == "yes" && counter == 11 && (
            <Box>
              {" "}
              <h2 style={{ textAlign: "center" }}>Please authorize the app to allow us to link the assessment.</h2>
              <div style={{ justifyContent: "center", cursor: "pointer", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "space-between", width: "300px" }}>
                  <Button
                    className="complete-create-assessment"
                    variant="contained"
                    sx={{ minWidth: "70px" }}
                    onClick={() => {
                      setCalendlyLink("");
                      setShowCalendlyLink(false);
                    }}
                  >
                    Do it later
                  </Button>
                  <Button className="complete-create-assessment" variant="contained" color="primary" sx={{ minWidth: "70px" }} onClick={() => setShowCalendlyLink(true)}>
                    Authorize
                  </Button>
                </div>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center", marginTop: "20px" }}>{calendlyLinkField(showCalendlyLink)}</div>
              </div>
            </Box>
          )}

          {buyAResource == "yes" && counter == 12 && (
            // What link would you like to send the buy book to cta to?
            <Box>
              {" "}
              <h2 style={{ textAlign: "center" }}>What link would you like to send the buy book to cta to?</h2>
              <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                <Box sx={{ marginLeft: "-5px", width: "300px", height: "75px" }}>
                  <TextField fullWidth className="form-control-add-question" value={buyAResourceLink} onChange={(e) => setBuyAResourceLink(e.target.value)} variant="outlined" InputLabelProps={{ shrink: true }} required />
                </Box>
              </div>
            </Box>
          )}

          {counter == 13 && (
            <Box className="complete-create-assessment">
              <div style={{ marginTop: "-50px", display: "flex", justifyContent: "center" }}>
                <CheckCircleIcon color="action" />
              </div>
              <h2 style={{ textAlign: "center" }}>
                Well done, you've setup the assessment. <br />
                {builder == "builder" ? <span>You can now create the assessment in the builder.</span> : null}
              </h2>
              <div style={{ justifyContent: "center", cursor: "pointer", display: "flex", alignItems: "center", flexDirection: "column" }}>
                <div style={{ display: "flex", justifyContent: "center", width: "300px", height: "45px" }}>
                  <Button
                    className="complete-create-assessment"
                    variant="contained"
                    sx={{ minWidth: "70px" }}
                    onClick={() => {
                      drawerCtx.closeDrawer();
                    }}
                  >
                    Close
                  </Button>
                  {builder == "builder" ? (
                    <Button
                      className="complete-create-assessment"
                      variant="contained"
                      color="primary"
                      style={{ marginLeft: "20px" }}
                      sx={{ minWidth: "70px" }}
                      onClick={() => {
                        navigate("/builder/" + assessmentId, { replace: true });
                        drawerCtx.closeDrawer();
                      }}
                    >
                      Assessment Builder
                    </Button>
                  ) : null}
                </div>
              </div>
            </Box>
          )}

          {/* <h2 style={{textAlign: 'center', marginBottom: '20px'}}>Field name</h2>
            <TextField fullWidth className="form-control-add-question" variant="outlined" InputLabelProps={{ shrink: true }} required
                value={totalScore === 0 ? null : totalScore}
                onChange={(e) => {
                  setTotalScore(e.target.value);
                }}
              /> */}
        </Box>

        <Box className="buttons" sx={{ boxSizing: "border-box", display: "flex", justifyContent: "space-between", width: "100%", padding: "10px 10%", height: "100px", textAlign: "center", fontSize: "22px", backgroundColor: "#eee" }}>
          {counter > 0 && counter < 13 ? (
            <Box onClick={handlePrev} sx={{ width: "100px", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px", cursor: "pointer" }}>
              <ChevronLeft />
              Back
            </Box>
          ) : (
            <Box sx={{ minWidth: "120px" }}></Box>
          )}

          {counter < 8 ? (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", padding: "10px", cursor: "pointer" }}>
              <span style={{ fontSize: "18px" }}>Next step</span>
              {switchNext(counter)}
            </Box>
          ) : (
            <Box sx={{ minWidth: "300px" }}></Box>
          )}

          {counter <= 7 && counter > 0 ? (
            <Box onClick={handleNext} sx={{ width: "100px", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px", cursor: "pointer" }}>
              Continue
              <ChevronRight />
            </Box>
          ) : (
            counter === 0 && <Box sx={{ minWidth: "120px" }}></Box>
          )}

          {/* {counter === 3 && (assessmentName !== "" && assessmentName !== null) ? <Box onClick={handleNext} sx={{width: '100px', display: 'flex', alignItems: 'center', justifyContent: 'center', padding: '10px', cursor: 'pointer'}}>Continue<ChevronRight /></Box> : counter > 3 || counter < 3 ? null : <Box sx={{width: '120px'}}></Box>
          }  */}

          {counter > 7 && counter <= 12 ? (
            showFinish ? (
              <Box onClick={handleSubmit} sx={{ width: "100px", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px", cursor: "pointer" }}>
                Finish
                <ChevronRight />
              </Box>
            ) : (
              <Box onClick={handleNext} sx={{ width: "100px", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px", cursor: "pointer" }}>
                Continue
                <ChevronRight />
              </Box>
            )
          ) : null}
        </Box>
      </Box>
    </div>
  );
}
export default CreateAssessmentDrawer;
