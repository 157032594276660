import React, { useContext, useState } from 'react'
import ModalContext from '../../store/ModalContext';
import AuthContext from '../../store/AuthContext';
import cross from '../../../src/assets/img/cross.png';
import { Button, CircularProgress, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import DrawerContext from '../../store/DrawerContext';


const AddCharts = (props) => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const [chartType, setChartType] = useState("pie-chart");
  const [chartSize, setChartSize] = useState("100%");
  const [isLoading, setIsLoading] = useState(false);

  const assessmentId = props.details?.assessmentId;

  console.log("AddCharts props", props);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  function toggleDrawerHandler(drawer , chartId) {
    drawerCtx.openDrawer();
    const obj = {
      ...props?.details,
      chartId
    }
    drawerCtx.setDetails(drawer, obj);
  }

  const handleAddChart = () => {

    const payload = {
      title: " ",
      chartType: chartType,
      size: chartSize,
      timeFrame: new Date(),
    }
    if (chartType && chartSize) {
      setIsLoading(true)
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/chart', {
        method: 'POST', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
        .then(data => {
          setIsLoading(false)
          if (data.status === "success") {
            console.log(" successfully", data);
            handleCloseModal()
            if (chartType == "pie-chart") {
              toggleDrawerHandler("add-pie-chart", data.chartId)
            } else {
              toggleDrawerHandler("add-bar-graph", data.chartId)
            }

          }
        }).catch((err) => {
          console.error("Failed to add chart...", err);
          setIsLoading(false)
          handleCloseModal()

        });
    }
  }


  return (
    <div className="modal add-chart-container">
      <div className="modal-content">
        <div className="heading-container">
          <h3>Add Chart</h3>
          <img src={cross} onClick={() => { handleCloseModal() }} alt="cross" />
        </div>
        <div className="items">
          <div className="label">Type of Chart</div>
          <div className="row-section">
            <RadioGroup aria-labelledby="row-radio-buttons" name="row-buttons" value={chartType}
              onChange={(e) => { setChartType(e.target.value) }}>
              <FormControlLabel value="bar-chart" control={<Radio />} label="Bar Chart" />
              <FormControlLabel value="pie-chart" control={<Radio />} label="Pie Chart" />
            </RadioGroup>
          </div>
        </div>
        <div className="items">
          <div className="label">Size of Chart</div>
          <div className="row-section">
            <RadioGroup aria-labelledby="row-radio-buttons" name="row-buttons" className='size-chart-sec'
              value={chartSize}
              onChange={(e) => setChartSize(e.target.value)}>
              <FormControlLabel value="100%" control={<Radio />} label="100%" />
              <FormControlLabel value="50%" control={<Radio />} label="50%" />
              <FormControlLabel value="30%" control={<Radio />} label="30%" />
            </RadioGroup>
          </div>
        </div>
        {isLoading ?
          <Button className="add-btn" style={{background:'#fff', border:'1px solid #122845'}} disabled><CircularProgress style={{ 'color': '#122845' }} size={25} /></Button> :
          <Button className="add-btn" onClick={() => handleAddChart()}>ADD</Button>
        }

      </div>
    </div>
  )
}

export default AddCharts;

