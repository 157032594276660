import React, { useEffect, useState, useContext } from 'react';
import { ChevronRight } from '@mui/icons-material';
import { ExpandMore } from '@material-ui/icons';
import { TreeItem, TreeView } from '@material-ui/lab';
import BuilderContext from '../../../store/BuilderContext';

const NavigatorList = ({ contentBlocksByParentContainer }) => {
  
  const { contentBlocks, updateCurrentBlock } = useContext(BuilderContext);

  const [contentArray, setContentArray]     = useState([]);
  const [activeTreeItem, setActiveTreeItem] = useState()

  const findChildren = (nodeId) => {
    return contentBlocks?.filter(item => item.parentContainer === nodeId);
  }

  const handleActiveTreeItem = (nodeId) => {
    const selectedBlock = contentBlocks?.find( item => item.id === nodeId )
    updateCurrentBlock(selectedBlock)
    setActiveTreeItem(nodeId)
  }

  const renderTree = (nodes) => (
    <TreeItem key={nodes.id} nodeId={nodes.id.toString()} label={
      <div className={ activeTreeItem == nodes.id ? 'navigator-list-container active' : 'navigator-list-container' } onClick={()=>{handleActiveTreeItem(nodes.id)}}>
        <p>{nodes.contentType}</p>
        <p>{nodes.id}</p>
      </div>
    }>
      {findChildren(nodes.id).map((childNode) => (
        renderTree(childNode)
      ))}
    </TreeItem>
  );

  useEffect(() => {
    const rootNodes = contentBlocks.filter(node => node.parentContainer === 0);
    setContentArray(rootNodes);
  }, [contentBlocks]);

  // useEffect(() => {
  //   console.log('activeTreeItem',activeTreeItem)
  // }, [activeTreeItem]);
  

  return (
    <div className="navigator-list">
      <TreeView
        aria-label="navigator"
        defaultCollapseIcon={<ExpandMore />}
        defaultExpandIcon={<ChevronRight />}
      >
        {contentArray.map((node) => renderTree(node))}
      </TreeView>
    </div>
  );
}

export default NavigatorList;
