import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const Width = (props) => {

  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = props?.screenType === 'desktop' ? contentBlocks?.find(block => block.id === blockId)?.styling : contentBlocks?.find(block => block.id === blockId)?.mobileStyling
  const currentWidth = currentBlockStyles?.width?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  // console.log("currentWidth", currentWidth);

  const units = [
    { value: 'px',    label: 'px'   },
    { value: 'rem',   label: 'rem'  },
    { value: '%',     label: '%'    },
    { value: 'vh',    label: 'vh'   },
    { value: 'vw',    label: 'vw'   },
  ];

  const [width, setWidth]             = useState(currentWidth?.[0] || "auto");
  const [unit, setUnit]               = useState(currentWidth?.[1] || "px");
  const [finalValue, setFinalValue]   = useState("");

  if(width !== "auto") localStorage.setItem("prevWidth", width);
  
  const handleUnitChange = (e) => {
    // console.log("unit change event", e.target.value);
    if (!e.target.value) setWidth("auto");
    else {
      setUnit(e.target.value);
      if(localStorage.getItem("prevWidth")) {
        setWidth(localStorage.getItem("prevWidth"));
      }
    }
  };

  const updateWidth = (widthValue) => {
    let payload = {
      id: blockId,
      value: widthValue,
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/width', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating width payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update width...", err);
    });
  }

  useEffect(() => {
    if (width === "auto") {
      setFinalValue("auto");
    } else if (width && width !== "auto" && unit) {
      if (width !== currentWidth?.[0] || unit !== currentWidth?.[1]) {
        setFinalValue(`${width}${unit}`);
      }
    }
  }, [currentWidth, width, unit]);

  useEffect(() => {
    let lastFinalVal = "";
    if(!currentWidth?.[1]) lastFinalVal = "auto";
    else lastFinalVal = currentWidth?.[0] + currentWidth?.[1];
    // console.log("lastFinalVal", lastFinalVal);
    // console.log("width unit", width, unit);
    const timer = setTimeout(() => {
      if (finalValue && finalValue !== lastFinalVal) {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            let newStyles = { ...block.styling };
            newStyles.width = finalValue;
            block.styling = { ...newStyles };
            updateWidth(finalValue)
            return block;
          } else return block;
        }))
      }
    }, 500);
    return () => clearTimeout(timer);

  }, [blockId, finalValue]);


  // console.log("blockId", blockId);
  // console.log("width", width);
  // console.log("type width", typeof width);
  // console.log("unit", unit);
  // console.log("finalValue Width", finalValue);

  // let testVal = "32px 16% 8vh 4rem 2vw";
  // console.log("REGEX:\n", testVal.match(/[a-zA-Z]+|[0-9]+|[%]/g));

  return (
    <div className="width tool">
      {props.deleteModal}
      <TextField id="width-number" className="width-input styling-input" type="number" variant="filled"
        InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling?.width + ""} value={width} onChange={(e) => {
          if (e.target.value) setWidth(e.target.value);
          else setWidth("auto");
        }} />

      <TextField id="select-style-unit" className="width-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }} placeholder={currentContentBlock.styling?.width + ""} value={unit} onChange={(e) => {
        console.log("targetVal", e.target.value);
        handleUnitChange(e);
      }}
      // label="Select unit" helperText="Select unit"
      >
        {units?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>
  )
}


export default Width;
