import React, { useContext, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';


import cross from '../../../src/assets/img/cross.png';
import AuthContext from '../../store/AuthContext.js';
import DrawerContext from '../../store/DrawerContext.js';



const DeleteScoringModal = (props) => {
    
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const id = props?.details?.row?.assessmentId
  const emailId= props?.details?.id

  const [isCreating, setIsCreating] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  
  const handleDeleteScoring = () => {
    console.log('scoringDetails',modalCtx?.details?.scoringDetails)
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/score/"+ modalCtx?.details?.scoringDetails?.id, {
      method: "DELETE",
      headers: { "Content-Type": "application/json" , "Authorization": "Bearer " + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if(data.status == "success") {
        if(modalCtx?.details?.type==="Sub")
        {
          handleCloseModal()
          modalCtx?.details?.updateCondition('logicalOperator', modalCtx?.details?.i, modalCtx?.details?.value);
          modalCtx?.details?.deleteCondition(modalCtx?.details?.i + 1);
          modalCtx?.details?.editSubCondLine(modalCtx?.details?.editCond)
          //modalCtx?.details?.updateScores()
        }
        else{
          handleCloseModal()
          modalCtx?.details?.updateScores()
        }
      }
    })
    .catch((err) => console.error(err));
  }


  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  return (
    <div className='modal'>
      <div className="delete-popup-content">
        <div className="main-content">
          <div className="heading-container">
            <div className="heading">
                Are you sure you want to delete this scoring ?
            </div>
            <img src={cross} onClick={() => { handleCloseModal() }} alt="cross" />
          </div>

          {isLoading ?
              <button className="delete-btn" disabled> <CircularProgress /></button> :
              <button className="delete-btn" onClick={()=>{handleDeleteScoring()}} >Delete</button>
          }
          
          <button className="cancel-btn" onClick={() => { handleCloseModal() }}>Cancel</button>

        </div>
      </div>
    </div>
  )

}

export default DeleteScoringModal;
