import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';

const TextTransform = (props) => {
  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);

  const { contentBlocks, updateContentBlocks } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentTextTransform = currentBlockStyles?.textTransform;

  // CSS text-transform property options
  const transformOptions = [
    { value: 'none',       label: 'None'       },
    { value: 'capitalize', label: 'Capitalize' },
    { value: 'uppercase',  label: 'Uppercase'  },
    { value: 'lowercase',  label: 'Lowercase'  }
  ];

  const [textTransform, setTextTransform] = useState(currentTextTransform || "none");

  const handleChange = (e) => {
    setTextTransform(e.target.value);
  }

  useEffect(() => {
    const timer = setTimeout(() => {
      if (textTransform && textTransform !== currentTextTransform) {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            let newStyles = { ...block.styling };
            newStyles.textTransform = textTransform;
            block.styling = { ...newStyles };
            return block;
          } else {
            return block;
          }
        }));
      }
    }, 1000);

    return () => clearTimeout(timer);

  }, [blockId, textTransform]);

  return (
    <div className="text-transform tool">
      <div className="text-transform-select">
        {props.deleteModal}
        <TextField className="position-unit styling-select"
          select variant="standard" style={{ width: "120px" }}
          InputProps={{ disableUnderline: true }}
          value={textTransform} onChange={handleChange}
        >
          {transformOptions.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>
    </div>
  );
}

export default TextTransform;
