import { useState, useContext, useEffect } from 'react';
import { useParams } from "react-router-dom";

import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Tooltip, Modal, CircularProgress } from '@mui/material';

import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import { Delete, HelpOutline } from '@mui/icons-material';

import infoIcon from "../../assets/img/infoIcon.svg";
import warningIcon from "../../assets/img/warningIcon.svg";
import cross from "../../assets/img/cross.png";


const AssessmentLeaderInfo = (props) => {
  
  console.log("AssessmentLeaderInfo props", props);
  const { details } = props;
  const { id } = useParams();

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  const [isDeleting, setIsDeleting]   = useState(false);
  const [errorWhileDeleting, setErrorWhileDeleting] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const confirmDeletion = () => {

    setConfirmOpen(false); // close the delete confirmation modal
    setErrorWhileDeleting(false);
    setIsDeleting(true);
    fetch(process.env.REACT_APP_API_URI + `/v3/admin/assessment/${details?.id}/leader/${details?.assessmentId}`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        console.log("Successfully deleted abandoned assessment...");
        modalCtx.setPageUpdate({ page: "assessmentsUsers" });
        setIsDeleting(false);
        setErrorWhileDeleting(false);
        // handleCloseModal();
      } else {
        console.log("Failed to delete abandoned assessment...");
      }
    })
    .catch((err) => {
      console.error("Failed to delete abandoned assessment...", err);
      setIsDeleting(false);
      setErrorWhileDeleting(true);
    });
  }

  

  return (
    <div className="modal" style={{ "borderRadius": "10px" }}>
      <div className='header-leaderinfo'>
      <h1 className='assesment_leader'>What is an Assessment Leader?</h1>
        <img src={cross} alt='cross' onClick={(e) => { handleCloseModal() }} />
      </div>

      <div className='heading-popup-leader'  >
        <img src={infoIcon} alt='info' />
        <div>
          <p>The Assessment Leader is an autogenerated user in the backend, which is added when a leader is deleted without deleting the members.</p>
          {details?.referrals?.length > 0 && <>
            <p>Below you will find a list of members who are listed under the Assessment Leader.</p>
            <p style={{ fontWeight: 700 }}>Note: If you delete the Assessment Leader, these members will be deleted too.</p>
          </>}
      </div>
      </div>
      {details?.referrals?.length > 0 ?
        <div className="assessment-leader-info-table">
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead>
                <TableRow>
                  <TableCell component="th" align="left">No.</TableCell>
                  <TableCell component="th" align="left">Date</TableCell>
                  <TableCell component="th" align="left">Name</TableCell>
                  <TableCell component="th" align="left">Email</TableCell>
                  <TableCell component="th" align="left">Assessment Status</TableCell>
                  <TableCell component="th" align="left" style={{ display: "flex", alignItems: "center" }}>Action&nbsp; <HelpOutline /> </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {details?.referrals?.map((ref, idx) => {
                  return (
                    <TableRow key={ref.id}>
                      <TableCell component="th" scope="row">{idx + 1}</TableCell>
                      <TableCell scope="row">{ref.assessmentCompletedAt}</TableCell>
                      <TableCell scope="row">{ref.name}</TableCell>
                      <TableCell scope="row">{ref.email}</TableCell>
                      <TableCell scope="row" style={{ color: ref.assessmentCompleted == "yes" ? "green" : "orange" }}>{ref.assessmentCompleted == "yes" ? "Completed" : "Pending"}</TableCell>
                      <TableCell scope="row" style={{ display: "flex", alignItems: "center" }}>
                        <Button className="black-btn">Copy Link</Button>
                        <Tooltip title="Delete"><Delete className="table-tool" onClick={() => { }} /></Tooltip>
                      </TableCell>
                    </TableRow>
                  )
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div> : <p style={{ textAlign: "center", fontWeight: 700 }}>0 members/referrals in this list currently. Only Assessment Leader will be deleted.</p>}

      <div className="footer-leaderInfo" style={{ margin: "15px auto 0" }}>
        {/* <Button className="confirm-btn" onClick={(e) => { handleCloseModal() }}>OK</Button> */}
        <img src={warningIcon} alt='warning' />
        <p>Warning! By deleting this Assessment Leader you will delete all the members listed as well.</p>
        <Button className="red-btn" onClick={(e) => { setConfirmOpen(true) }}>{isDeleting ? <CircularProgress size={20} style={{ color: "white" }} /> : "Delete"}</Button>
      </div>
      {errorWhileDeleting && <div className="error-text" style={{ color: "#E14949", textAlign: "center" }}>
        <p>There was an error while deleting the listed user/s. Please try again after refreshing this page.</p>
      </div>}
      <Modal open={confirmOpen}>
        <div className='modal'>
          <div className='delete-popup-content leaderInfo-popup' style={{padding: "20px", width: "400px", minHeight: "232px"}}>
            <div className='heading-container'>
             <div className='heading'>Are you sure you want to delete these members and the assessment leader?</div>
              <img src={cross} alt='cross' onClick={(e) => {setConfirmOpen(false) }} />
              </div>

            <div className="footer-container" style={{ margin: "15px auto 0" }}>
              <Button type="submit" className="delete-btn" onClick={(e) => { confirmDeletion() }}>Delete</Button>
              <Button className="cancel-btn" onClick={(e) => { setConfirmOpen(false) }}>Cancel</Button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
    
  
  )
}


export default AssessmentLeaderInfo;

