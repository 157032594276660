import { useState, useContext, useEffect } from 'react';
import { Modal, Box } from '@material-ui/core';

import ModalContext from '../store/ModalContext.js';

import ClientDelete from './Modals/ClientDelete.jsx';
import QuestionDelete from './Modals/QuestionDelete.jsx';
import AssessmentDelete from './Modals/AssessmentDelete.jsx';
import AssessmentAutomationDelete from './Modals/AssessmentAutomationDelete.jsx';
import UsersBulkDeleteConfirmation from './Modals/UsersBulkDeleteConfirmation.jsx';
import ImageCropper from './Modals/ImageCropper/ImageCropper.jsx';
import ClientAdminDelete from './Modals/ClientAdminDelete.jsx';
import CreatePage from './Modals/CreatePage.jsx';
import DataMiningWebScraper from './Modals/DataMiningWebScraper.jsx';
import EditWebsiteDetails from './Modals/EditWebsiteDetails.jsx';
import EditContactDetails from './Modals/EditContactDetails.jsx';
import EditURLDetails from './Modals/EditURLDetails.jsx';
import EditAssessmentSection from './Modals/EditAssessmentSection.jsx';
import DeleteWebsiteDetails from './Modals/DeleteWebsiteDetails.jsx';
import DeleteContactDetails from './Modals/DeleteContactDetails.jsx';
import DeleteURLDetails from './Modals/DeleteURLDetails.jsx';
import BulkDeleteURLs from './Modals/BulkDeleteURLs.jsx';
import DeleteScores from './Modals/DeleteScores.jsx';
import UpdateResultsText from './Modals/UpdateResultsText.jsx';
import ResultsHeader from './Modals/ResultsHeader.jsx';
import ShowAddSections from './Modals/ShowAddSections.jsx';
import CreateAssessmentPage from './Modals/CreateAssessmentPage.jsx';
import DeleteAssessmentPage from './Modals/DeleteAssessmentPage.jsx';
import SectionDelete from './Modals/SectionDelete.jsx';
import DeleteInvite from './Modals/DeleteInvite.jsx';
import DeleteEmailNotification from './Modals/DeleteEmailNotification.jsx';
import EditEmailNotification from './Modals/EditEmailNotification.jsx';
import DeleteAbandonedAssessment from './Modals/DeleteAbandonedAssessment.jsx';
import AssessmentLeaderInfo from './Modals/AssessmentLeaderInfo.jsx';
import DeleteScoringModal from './Modals/DeleteScoringModal.jsx';
import AddNewStyle from './Modals/AddNewStyle.jsx';
import AddNewProperty from './Modals/AddNewProperty.jsx';
import DeleteLogic from './Modals/DeleteLogic.jsx';
import ColorSelectionModal from './Modals/ColorSelectionModal.jsx';
import AddCharts from './Modals/AddCharts.jsx';
import AddNewBlock from './Modals/AddNewBlock.jsx';
import DeleteStyles from './Modals/DeleteStyles.jsx';
import EditInputFieldDetails from './Modals/EditInputFieldDetails.jsx';
import DuplicateStyle from './Modals/DuplicateStyle.jsx';
import NavigatorModal from './Modals/NavigatorModal/NavigatorModal.jsx';
import AddTabsModal from './Modals/AddTabsModal.jsx';
import EditTabsModal from './Modals/EditTabsModal.jsx';
import DeleteTabs from './Modals/DeleteTabs.jsx';
import UsersDeleteConfirmation from './Modals/UsersDeleteConfirmation.jsx';
import WrapContainerModal from './Modals/WrapContainerModal.jsx';
import DeleteChartsModal from './Modals/DeleteChartsModal.jsx';
import EditCharts from './Modals/EditCharts.jsx';
import DeleteChartSegment from './Modals/DeleteChartSegment.jsx';


const DetailsModal = () => {

  const modalCtx = useContext(ModalContext);

  const boxStyles = {
    position: 'absolute', top: '50%', left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper', boxShadow: 24, p: 4,
    // width: 800, height: 700, overflow: "scroll"
  };
  
  const widerModalStyles = {
    position: 'absolute', top: '50%', left: '50%',
    transform: 'translate(-50%, -50%)', width: "80%",
    bgcolor: 'background.paper', boxShadow: 24, p: 4,
  };

  const editAssessmentStyles = {
    position: 'absolute', top: '50%', left: '50%',
    transform: 'translate(-50%, -50%)', 
    bgcolor: 'background.paper', boxShadow: 24, p: 4,
  };

  const imgCropperBoxStyles = { ...boxStyles, width: 900 };

  const currentModal = () => {
		switch(modalCtx.modal) {
      case "assessment-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AssessmentDelete details={modalCtx.details} /></Box></Modal>;
      case "client-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><ClientDelete details={modalCtx.details} /></Box></Modal>;
      case "delete-client-administrator": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><ClientAdminDelete details={modalCtx.details} /></Box></Modal>;
      case "question-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox question-delete-modal"><Box sx={boxStyles}><QuestionDelete details={modalCtx.details} /></Box></Modal>;
      case "category-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox category-delete-modal"><Box sx={boxStyles}><SectionDelete  details={modalCtx.details} /></Box></Modal>;
      case "assessment-automation-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AssessmentAutomationDelete details={modalCtx.details} /></Box></Modal>;
      case "image-cropper": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={imgCropperBoxStyles}><ImageCropper /></Box></Modal>;
      case "create-page": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles} className="create-page-modal-container"><CreatePage details={modalCtx.details} /></Box></Modal>;
      case "create-assessment-page": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles} className="create-assessment-page-modal-container"><CreateAssessmentPage details={modalCtx.details} /></Box></Modal>;
      case "delete-assessment-page": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles} className="delete-assessment-page-modal-container"><DeleteAssessmentPage details={modalCtx.details} /></Box></Modal>;
      case "website-scraper": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DataMiningWebScraper details={modalCtx.details} /></Box></Modal>;
      
      case "users-bulk-delete-confirmation": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UsersBulkDeleteConfirmation details={modalCtx.details} /></Box></Modal>;
      case "users-delete-confirmation": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UsersDeleteConfirmation details={modalCtx.details} /></Box></Modal>;

      case "website-details-edit": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditWebsiteDetails details={modalCtx.details} /></Box></Modal>;
      // case "URL-details-edit": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditURLDetails details={modalCtx.details} /></Box></Modal>;
      case "contact-details-edit": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditContactDetails details={modalCtx.details} /></Box></Modal>;
      case "website-details-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteWebsiteDetails details={modalCtx.details} /></Box></Modal>;
      case "URL-details-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteURLDetails details={modalCtx.details} /></Box></Modal>;
      case "contact-details-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteContactDetails details={modalCtx.details} /></Box></Modal>;
      case "bulk-delete": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><BulkDeleteURLs details={modalCtx.details} /></Box></Modal>;
      case "delete-abandoned-assessments": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteAbandonedAssessment details={modalCtx.details} /></Box></Modal>;
      case "delete-member-scores": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteScores details={modalCtx.details} /></Box></Modal>;
      case "delete-user-invite": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteInvite details={modalCtx.details} /></Box></Modal>;
      case "edit-assessment-section-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={editAssessmentStyles}><EditAssessmentSection details={modalCtx.details} /></Box></Modal>;
      case "results-header-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><ResultsHeader details={modalCtx.details} /></Box></Modal>;
      case "update-results-text-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><UpdateResultsText details={modalCtx.details} /></Box></Modal>;
      case "show-add-sections-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><ShowAddSections details={modalCtx.details} /></Box></Modal>;
      case "show-assessment-leader-info-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={widerModalStyles}><AssessmentLeaderInfo details={modalCtx.details} /></Box></Modal>;
      case "page-builder-add-new-style-mmodal": return <Modal open={modalCtx.open} className="add-goal-lightbox add-new-style-modal-parent"><Box sx={widerModalStyles}><AddNewStyle details={modalCtx.details}/></Box></Modal>;
      case "page-builder-add-new-property-mmodal": return <Modal open={modalCtx.open} className="add-goal-lightbox add-new-property-modal-parent"><Box sx={widerModalStyles}><AddNewProperty details={modalCtx.details}/></Box></Modal>;
      case "page-builder-add-new-block-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox add-new-property-modal-parent"><Box sx={widerModalStyles}><AddNewBlock details={modalCtx.details}/></Box></Modal>;
      case "page-builder-delete-styles-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox add-new-property-modal-parent"><Box sx={widerModalStyles}><DeleteStyles details={modalCtx.details}/></Box></Modal>;
      case "page-builder-duplicated-styles": return <Modal open={modalCtx.open} className="add-goal-lightbox add-new-property-modal-parent"><Box sx={widerModalStyles}><DuplicateStyle details={modalCtx.details}/></Box></Modal>;
      case "page-builder-navigator-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox add-new-property-modal-parent"><Box sx={widerModalStyles}><NavigatorModal details={modalCtx.details}/></Box></Modal>;

      case "delete-email-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteEmailNotification details={modalCtx.details} /></Box></Modal>;
      case "edit-email-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditEmailNotification details={modalCtx.details} /></Box></Modal>;

      case "delete-scoring-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteScoringModal details={modalCtx.details} /></Box></Modal>;
      case "delete-logic-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteLogic details={modalCtx.details} /></Box></Modal>;
     
      case "color-selection-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><ColorSelectionModal details={modalCtx.details} /></Box></Modal>;
      case "add-chart-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AddCharts details={modalCtx.details} /></Box></Modal>;
      case "edit-chart-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditCharts details={modalCtx.details} /></Box></Modal>;

      
      case "edit-input-field-details": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditInputFieldDetails details={modalCtx.details} /></Box></Modal>;
      case "add-tabs-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><AddTabsModal details={modalCtx.details} /></Box></Modal>;
      case "edit-tabs-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><EditTabsModal details={modalCtx.details} /></Box></Modal>;
      case "delete-tabs-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteTabs details={modalCtx.details} /></Box></Modal>;
      case "wrap-container-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><WrapContainerModal details={modalCtx.details} /></Box></Modal>;

      case "delete-charts-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteChartsModal details={modalCtx.details} /></Box></Modal>;
      case "delete-chart-segment-modal": return <Modal open={modalCtx.open} className="add-goal-lightbox"><Box sx={boxStyles}><DeleteChartSegment details={modalCtx.details} /></Box></Modal>;
      
      
      default: return null;
    }
	}

  return (
    <div>{ currentModal() }</div>
  );

}

export default DetailsModal;
