import { useState, useEffect, useContext, useMemo } from "react";

import { Add, ChevronLeft, Navigation, Settings, SettingsOutlined } from "@material-ui/icons";
import { v4 as uuidv4 } from 'uuid';

import BuilderContext from "../../../store/BuilderContext";
import HeaderContainerWidget from "./HeaderContainerWidget";
import ImageWidget from "./ImageWidget/ImageWidget";
import BackgroundWidget from "./BackgroundWidget";
import ContainerWidget from "./ContainerWidget";
import StartPageImgVideoWidget from "./StartPageImgVideoWidget";
import DefaultSelectedWidget from "./DefaultSelectedWidget";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TextEditWidget from "./TextEditWidget";
import { showSidebar } from "../../../globalFunctions";
import SectionWidget from "./SectionWidget";
import QuestionWidget from "./QuestionWidget";
import SectionProgressBarWidget from "./SectionProgressBarWidget";
import AnswerSliderWidget from "./AnswerSliderWidget";
import AnswerSliderOptionsWidget from "./AnswerSliderOptionsWidget";
import ProgressIndicatorWidget from "./ProgressIndicatorWidget";
import PrevBtnWidget from "./PrevBtnWidget";
import NextBtnWidget from "./NextBtnWidget";
import AnswerTextareaWidget from "./AnswerTextareaWidget";
import AnswerTextareaOptionsWidget from "./AnswerTextareaOptionsWidget";
import AnswerRadioWidget from "./AnswerRadioWidget";
import ResultsTemperatureScaleWidget from "./ResultsTemperatureScaleWidget";
import StartPageTextWidget from "./StartPageTextWidget";
import ButtonWidget from "./ButtonWidget";
import desktopIcon from "../../../assets/img/builder-images/desktop-icon.svg";
import horizontalTabIcon from "../../../assets/img/builder-images/horizontal-tab.svg";
import verticalTabIcon from "../../../assets/img/builder-images/vertical-tab.svg";
import phoneIcon from "../../../assets/img/builder-images/phone-icon.svg";
import desktopIconActive from "../../../assets/img/builder-images/desktop-icon-active.svg";
import horizontalTabIconActive from "../../../assets/img/builder-images/horizontal-tab-active.svg";
import verticalTabIconActive from "../../../assets/img/builder-images/vertical-tab-active.svg";
import phoneIconActive from "../../../assets/img/builder-images/phone-icon-active.svg";
import { Button } from "@mui/material";
import ModalContext from "../../../store/ModalContext";
import DrawerContext from "../../../store/DrawerContext";

const Tools = () => {

  const builderCtx = useContext(BuilderContext);
  const modalCtx   = useContext(ModalContext);
  const drawerCtx  = useContext(DrawerContext);
  const navigate   = useNavigate();
  const [currentBtn, setCurrentBtn] = useState("Desktop")
  const { startPage, setStartPage, startPageSections, navbarProps, setNavbarProps, globalProps, contentBlocks, updateContentBlocks, currentContentBlock, currentPage, isCtrlPressed, selectMultipleBlocks} = builderCtx;
  const [showHidePopup, setShowHidePopup]                 = useState("")
  const { id } = useParams()

  // const updateNavbarPropsCtx = (id, targetVal) => {
  //   setNavbarProps((prev) => {
  //     prev[id] = targetVal;
  //     return { ...prev }
  //   })
  // }

  // const updateStartPageCtx = (id, targetVal) => {
  //   setStartPage((prev) => {
  //     prev[id] = targetVal;
  //     return { ...prev }
  //   })
  // }

  // const setCurrentObjStyles = (e, id, property, unit) => {
  //   builderCtx.updateContentBlocks(contentBlocks?.map(block => {
  //     if(block.id === id) {
  //       let newStyles = { ...block.styling };
  //       newStyles[property] = e.target.value;
  //       block.styling = { ...newStyles };
  //       return block;
  //     } else return block;
  //   }))
  // }

  const setCurrentProperty = (e, property, id) => {
    builderCtx.setContentBlocks((prevBlocks) => {
      return prevBlocks.map((block) => {
        if (block.id === id) {
          return { ...block, styling: { ...block.styling, [property]: e.target.value } };
        }
        return block;
      });
    });
  };


  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };
  

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  useEffect(()=>{
    builderCtx.setCurrentDeviceView(currentBtn)
  },[currentBtn])

  // console.log(globalProps);
  // console.log("builderCtx.currentContentBlock", builderCtx?.currentContentBlock);

  const showCurrentTool = useMemo(() => {
    
    if (currentContentBlock?.contentType) {
      switch(currentContentBlock?.contentType) {
  
        case "headerContainer": return <HeaderContainerWidget setCurrentProperty={setCurrentProperty} />;
        case "Background": return <BackgroundWidget />;
        case "Container": return <ContainerWidget />;
        case "StartPageImgVideo": return <StartPageImgVideoWidget />;
        case "StartPageText": return <StartPageTextWidget />
        
        case "LogoText":      return <TextEditWidget key="start-logo-text" />;
        case "LogoSubtext":   return "LogoSubtext";  
        case "Image":         return <ImageWidget key="start-image" setCurrentProperty={setCurrentProperty} />;
        case "BleatLogo":     return <ImageWidget key="bleat-logo" setCurrentProperty={setCurrentProperty} />;
  
        case "heading":       return <TextEditWidget key="heading" />;
        // case "paragraph":return <TextWidget setCurrentProperty={setCurrentProperty} />;
        case "paragraph":     return <TextEditWidget key="paragraph" />;
        case "duration":     return <TextEditWidget key="start-duration" />;
        case "SectionsBar":   return <SectionWidget />;
        case "ProgressIndicator":     return <ProgressIndicatorWidget />;
        case "SectionProgressBar":    return <SectionProgressBarWidget />;
        case "Question":              return <QuestionWidget />;
        case "AnswerSlider":          return <AnswerSliderWidget />;
        case "AnswerSliderOptions":   return <AnswerSliderOptionsWidget />;
        // not really needed as AnswerTextareaOptions has everything for now 
        // case "AnswerTextarea":        return <AnswerTextareaWidget />;
        case "AnswerTextareaOptions": return <AnswerTextareaOptionsWidget />;
        case "AnswerRadio":           return <AnswerRadioWidget />;
        case "AnswerRadioOptions":    return <AnswerRadioWidget />;
        case "PrevButton":    return <PrevBtnWidget />;
        case "NextButton":    return <NextBtnWidget />;
        case "ResultsHeading": return <TextEditWidget key="results-heading" />;
        case "ResultsTemperatureScale": return <ResultsTemperatureScaleWidget />;
        case "button":        return <ButtonWidget />;
        
        // default: console.log("check contentType");
        // default: return <DefaultSelectedWidget />;
        default: return <></>;
      }
    // } else return <DefaultSelectedWidget />;
  } else return <></>;

  }, [currentContentBlock?.contentType]);

  return (
    <div>
      <div className="back-button" onClick={() => {
        showSidebar();
        navigate("/assessments", { replace: true })
        }} style={{ color: "#dedede", textAlign: "center", display: "flex", justifyContent: "center", alignItems: "center", textTransform: "uppercase", fontWeight: "700", fontSize: "16px", cursor: "pointer" }}>
        <ChevronLeft style={{ fontSize: "28px" }} /> Back to Dashboard
      </div>
      <div className="device-view-toggle-options-container">
        <button style={{ background: currentBtn === "Desktop" ? "#122845" : "#E6E6E6", color: currentBtn === "Desktop" ? "#fff" : "#000" }} className="desktop-btn" onClick={() => { setCurrentBtn("Desktop") }}>
          <img src={currentBtn === "Desktop" ? desktopIconActive : desktopIcon} alt="" />
          <p>Desktop</p>
        </button>
        <button style={{ background: currentBtn === "Horizontal" ? "#122845" : "#E6E6E6", color: currentBtn === "Horizontal" ? "#fff" : "#000" }} className="horizontal-tab-btn" onClick={() => { setCurrentBtn("Horizontal") }}>
          <img src={ currentBtn === "Horizontal" ? horizontalTabIconActive : horizontalTabIcon } alt="" />
          <p>Horizontal Tablet</p>
        </button>
        <button style={{ background: currentBtn === "Vertical" ? "#122845" : "#E6E6E6", color: currentBtn === "Vertical" ? "#fff" : "#000" }} className="vertical-tab-btn" onClick={() => { setCurrentBtn("Vertical") }}>
          <img src={ currentBtn === "Vertical" ? verticalTabIconActive : verticalTabIcon } alt="" />
          <p>Vertical Tablet</p>
        </button>
        <button style={{ background: currentBtn === "Mobile" ? "#122845" : "#E6E6E6", color: currentBtn === "Mobile" ? "#fff" : "#000" }} className="mobile-btn" onClick={() => { setCurrentBtn("Mobile") }}>
          <img src={ currentBtn === "Mobile" ? phoneIconActive : phoneIcon } alt="" />
          <p>Mobile</p>
        </button>
      </div>
      <div className="widget-heading">
        <p style={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "700" }}>{currentContentBlock?.contentType}</p>
        <div className="add-settings-icon-container">
          <Button className="settings-icon"
          onBlur={()=>{
            setTimeout(() => {
              setShowHidePopup("")
            }, 100);
          }}
           onClick={(()=>{
            showHidePopup === 'header-confirmation' ? setShowHidePopup("") : setShowHidePopup("header-confirmation");
          })}>
            <SettingsOutlined/>
          </Button>
          {
            showHidePopup === 'header-confirmation' ? <div className="header-confirmation">
              <div className="arrow-container">
                <div className="arrow"></div>
              </div>
              <div className="header-setting-options-list">
                <p onClick={()=>{
                  toggleModalHandler('page-builder-duplicated-styles')
                }}>Duplicate styles for all headers</p>
              </div>
            </div> : <></>
          }
          <Button className="add-icon" 
            onBlur={()=>{
             setTimeout(() => {
               setShowHidePopup("")
             }, 100);
            }}
            onClick={(()=>{ showHidePopup === "add-properties" ? setShowHidePopup("") : setShowHidePopup("add-properties"); })}>
            <Add/>
          </Button>
        </div>
        {
          showHidePopup === "add-properties" ? <div className="new-style-property-popup">
                <ul>
                  <li onClick={()=>{
                    toggleModalHandler("page-builder-add-new-style-mmodal",{id}); 
                  }}>Create New Style</li>
                  <li onClick={()=>{
                    toggleModalHandler("page-builder-add-new-property-mmodal"); 
                  }}>Create New Property</li>
                  <li onClick={()=>{
                    toggleModalHandler("page-builder-add-new-block-modal"); 
                  }}>Create New Block</li>
                  <div className="arrow"></div>
                </ul>
          </div> : <></>
        }
      </div>
      <div className="tools-container">
        {
          selectMultipleBlocks.length !==0 ? <div className="wrap-a-container-around">
            <h3>Wrap a container around content blocks below : </h3>
            <ul>
              {
                selectMultipleBlocks.map((el)=>{
                  return <li>{el.contentType}</li>
                })
              }
            </ul>
            <button onClick={()=>{
              toggleModalHandler("wrap-container-modal",{id});    
            }}>Add a container around</button>
          </div> : <>{showCurrentTool}</>
        }
      </div>
      <div className="question-builder-and-settings-container">
        <div className="question-builder-container">
          <Button className="question-builder-drawer-btn"
            onClick={()=>{
              toggleDrawerHandler("open-questions-builder", { id })
            }}
          >
            QUESTION BUILDER
          </Button>
            {/* {
              showHidePopup ? <div className="new-style-property-popup">
                <ul>
                  <li onClick={()=>{
                    toggleModalHandler("page-builder-add-new-style-mmodal",{id}); 
                  }}>Create New Style</li>
                  <li onClick={()=>{
                    toggleModalHandler("page-builder-add-new-property-mmodal"); 
                  }}>Create New Property</li>
                  <div className="arrow"></div>
                </ul>
              </div> : <></>
            } */}
          <Button className="create-new-style-modal" onClick={(()=>{
            showHidePopup ? setShowHidePopup(false) : setShowHidePopup(true);
          })}>
            <SettingsOutlined/>
          </Button>
        </div>
      </div>
    </div>
  );

  // return (
  //   <div>
  //     {startPageSections?.general && <div className="general">
  //       <ul className="unstyled">
  //         <li>Background Color: <input type="text" value={globalProps?.bgColor} onChange={(event) => setGlobalProps((prev, e = event) => {
  //           return { ...prev, bgColor: e.target.value || "#FFFFFF" }
  //         })} /></li>
  //         <li>Text Color: <input type="text" value={globalProps?.textColor} onChange={(event) => setGlobalProps((prev, e = event) => {
  //           return { ...prev, textColor: e.target.value || "#212121" }
  //         })} /></li>
  //       </ul>
  //     </div>}

  //     {startPageSections?.logoText && <div className="header-tools">
  //       <ul className="unstyled">
  //         <li>Logo Text: <input type="text" value={navbarProps?.logoText} onChange={(e) => { setNavbarProps((prev) => ({ ...prev, logoText: e.target.value })) }} /></li>
  //         <li>Logo Subtext: <input type="text" value={navbarProps?.logoSubtext} onChange={(e) => updateNavbarPropsCtx("logoSubtext", e.target.value)} /></li>
  //       </ul>
  //     </div>}

  //     {startPageSections?.leftLogo && <div className="left-logo-tools">
  //       <ul className="unstyled">
  //         <li>Width (pixels): <input type="text" value={navbarProps?.leftLogo.width} onChange={(e) => setNavbarProps((prev) => {
  //           prev.leftLogo.width = e.target.value;
  //           return { ...prev }
  //         })} /></li>
  //         <li>Height (pixels): <input type="text" value={navbarProps?.leftLogo.height} onChange={(e) => setNavbarProps((prev) => {
  //           prev.leftLogo.height = e.target.value;
  //           return { ...prev }
  //         })} /></li>
  //         <li>Change logo <input type="file" name="" onChange={(e) => updateNavbarPropsCtx("leftLogoImg", URL.createObjectURL(e.target.files[0]))} /></li>
  //       </ul>
  //     </div>}

  //     {startPageSections?.rightLogo && <div className="right-logo-tools">
  //       <ul className="unstyled">
  //         <li>Width (pixels): <input type="text" value={navbarProps?.rightLogo.width} onChange={(e) => setNavbarProps((prev) => {
  //           prev.rightLogo.width = e.target.value;
  //           return { ...prev };
  //         })}
  //         /></li>
  //         <li>Height (pixels): <input type="text" value={navbarProps?.rightLogo.height} onChange={(e) => setNavbarProps((prev) => {
  //           prev.rightLogo.height = e.target.value;
  //           return { ...prev };
  //         })} /></li>
  //         <li>Change logo <input type="file" name="" onChange={(e) => updateNavbarPropsCtx("rightLogoImg", URL.createObjectURL(e.target.files[0]))} /></li>
  //       </ul>
  //     </div>}

  //     {startPageSections?.info && <div className="info-tools">
  //       <ul className="unstyled">

  //         <li>Header Text: <input type="text" value={startPage?.header} onChange={(e) => updateStartPageCtx("header", e.target.value)} /></li>
  //         <li>Info Text: <input type="text" value={startPage?.text} onChange={(e) => updateStartPageCtx("text", e.target.value)} /></li>
  //         <li>Video URL: <input type="text" value={startPage?.vidURL} onChange={(e) => updateStartPageCtx("vidURL", e.target.value)} /></li>
  //         <li>Button Text: <input type="text" value={startPage?.btnText} onChange={(e) => updateStartPageCtx("btnText", e.target.value)} /></li>
  //         <li>Thumbnail Image: <input type="file" onChange={(e) => updateStartPageCtx("thumbnailImg", URL.createObjectURL(e.target.files[0]))} /></li>
  //         <li>Play Button Image: <input type="file" onChange={(e) => updateStartPageCtx("playBtnImg", URL.createObjectURL(e.target.files[0]))} /></li>

  //       </ul>
  //     </div>}
  //   </div>
  // )
}



export default Tools;
