import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const BorderRadius = (props) => {

  const { blockId, propertyName, name = "" } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx    = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentBorderRadius = currentBlockStyles?.borderRadius;

  const [borderRadius, setBorderRadius] = useState(currentBorderRadius || "0");

  const borderRadiusValues = [
    { value: '0',   label: '0'   },
    { value: '4px', label: '4px' },
    { value: '5px', label: '5px' },
    { value: '6px', label: '6px' },
    { value: '8px', label: '8px' },
    { value: '10px', label: '10px' },
    { value: '12px', label: '12px' },
    { value: '15px', label: '15px' },
    { value: '20px', label: '20px' },
    { value: '25px', label: '25px' },
    { value: '30px', label: '30px' },
  ];

  const handleRadiusChange = (e) => setBorderRadius(e.target.value);

  const updateBorderRadiusInDB = (borderRadiusVal) => {

    let payload = {
      id: blockId,
      value: borderRadiusVal,
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/borderRadius', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating color payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update color...", err);
    });
  }

  useEffect(() => {

    updateContentBlocks(contentBlocks?.map(block => {
      if (block.id === blockId) {
        let newStyles = { ...block.styling };
        newStyles.borderRadius = borderRadius;
        block.styling = { ...newStyles };
        updateBorderRadiusInDB(borderRadius)
        return block;
      } else return block;
    }))

  }, [blockId, borderRadius]);


  return (
    <div className="border-styles tool">

      {props.deleteModal}
      <TextField style={{ width: "100px" }} id="select-border-radius" className="radius-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }} placeholder={currentContentBlock.styling?.borderRadius + ""} value={borderRadius} onChange={(e) => { handleRadiusChange(e); }} >
        {borderRadiusValues?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>
  )
}

export default BorderRadius;
