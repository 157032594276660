import React, { useEffect, useState, useRef, useContext } from 'react';
import { FormControl, TextField, MenuItem, Button, Autocomplete, CircularProgress } from '@mui/material';
import { Draggable, Droppable, DragDropContext } from 'react-beautiful-dnd';
import { DragIndicator, DeleteOutlined, EditOutlined } from '@mui/icons-material';
import pageIcon from '../../../assets/img/pageIcon.svg'
import AuthContext from "../../../store/AuthContext";
import editIcon from '../../../assets/img/Question Builder/editIcon.svg'
import delIcon from '../../../assets/img/Question Builder/DelIcon.svg'
import CloseIcon from '../../../assets/img/closeIcon.svg';
import { useParams } from 'react-router-dom';
import DrawerContext from '../../../store/DrawerContext';
import './AssessmentScoringDrawer.scss';
import ModalContext from '../../../store/ModalContext';
import SubDrawerContext from '../../../store/SubDrawerContext';

export default function AssessmentScoringDrawer(props) {

  const authCtx = useContext(AuthContext);

  const [ScoringType, setScoringType] = useState([
    "Type of Scoring",
    "Average",
    "Mean",
    "Condition",
    "Formula",
    "Sum",
    "Multiply",
    "Recoded",
    "Comment",
  ])
  const[editId,setEditId]=useState(0)
  const [selectedScoringType, setSelectedScoringType] = useState('Type of Scoring');
  const [variables, setVariables] = useState([]);
  const [fetchedVars,setFetchedVars]=useState([]);
  const [FormulaVars, setFormulaVars] = useState([]) //did for testing
  // variable

  const [variablesData, setVariablesData] = useState([]) //did for testing



  const [addedVars, setAddedVars] = useState([]);

  const [SignsArray, setSignsArray] = useState([
    { 'option': '==' },
    { 'option': '!=' },
    { 'option': '>' },
    { 'option': '<', },
    { 'option': '<=', },
    { 'option': '>=', },
  ])
  const [editItem,setEditItem]=useState({})
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingLines, setIsLoadingLines] = useState(false);
  const [selectedSignValue, setselectedSignValue] = useState('==')
  const [AddFormulaPlaceholder, setAddFormulaPlaceholder] = useState(true)
  const [ShowEditDel, setShowEditDel] = useState(null)
  const [IsHoverEditdel, setIsHoverEditdel] = useState(false)
  const blockquoteRef = useRef(null);
  const [selectedVariablesData, setSelectedVariablesData] = useState([]);
  const [selectedInputType, setSelectedInputType] = useState('Number')
  const [selectedInputTypeArray, setSelectedInputTypeArray] = useState([
    { type: 'Number' },
    { type: 'Variable' },
  ]);
  const [selectedComparisonValue, setSelectedComparisonValue] = useState(0);
  const [isAdding,setIsAdding]=useState(false)
  const [selectedComparisonVariable, setSelectedComparisonVariable] = useState("");
  const [selectedVariable, setSelectedVariable] = useState('Variable');
  const [computedVariable, setComputedVariable] = useState("");
  const [recodedValue, setRecodedValue] = useState(0);
  const [lines, setLines] = useState([]);
  const [selectedVariablesArray, setSelectedVariablesArray] = useState([])
  const [VariableSuggestionsArray, setVariableSuggestionsArray] = useState([])
  const [ShowSuggestionBox, setShowSuggestionBox] = useState(false)
  const [comment, setComment] = useState("")
  const [pages, setPages] = useState([{ page_type: "Choose Page ID" }])
  const { id } = useParams()
  const [selectedPage, setSelectedPage] = useState("Choose Page ID")
  const drawerCtx = useContext(DrawerContext)
  const subdrawerCtx = useContext(SubDrawerContext)
  const modalCtx = useContext(ModalContext)
  const [pagesData, setPagesData] = useState([])
  const [pageId, setPageId] = useState([])
  const [numberOfConditions, setNumberOfConditions] = useState(0);
  const [editMode, setEditMode] = useState(false);
  const [varLenWarn,setVarLenWarn]=useState(false)
  
  const conditionFormat = {
    codingFrom: '',
    operator: '==',
    compareType: [
      { type: 'Number' },
      { type: 'Variable' },
    ],
    compareValue: 0,
    compareVariable: '',
    logicalOperator: ''
  };
  const [conditions, setConditions] = useState([{
    codingFrom: '',
    operator: '==',
    compareType: "",
    compareValue: 0,
    compareVariable: '',
    logicalOperator: ''
  }]);
  const isConditionValid = () => {
    if(selectedScoringType==="Recoded")
    {
      return conditions[0].codingFrom.length&&conditions[0].operator.length&&conditions[0].compareType.length&&(conditions[0].compareType==="Variable"?conditions[0].compareVariable.length:true)
    }
    else if(selectedScoringType==="Condition")
    {
      return computedVariable.length&&conditions[0].codingFrom.length&&conditions[0].operator.length&&conditions[0].compareType.length&&(conditions[0].compareType==="Variable"?conditions[0].compareVariable.length:true)
    }
  }

  const isValidSubCond=(subcond)=>{
    if(selectedScoringType==="Recoded")
    {
      return subcond.codingFrom.length&&subcond.operator.length&&(subcond.compareType==="Variable"||subcond.compareType==="Number")&&(subcond.compareType==="Variable"?subcond.compareVariable.length:true)
    }
    else if(selectedScoringType==="Condition")
    {
      return subcond.codingFrom.length&&subcond.operator.length&&(subcond.compareType==="Variable"||subcond.compareType==="Number")&&(subcond.compareType==="Variable"?subcond.compareVariable.length:true)
    }
  }

  const isVarType=()=>{
    return selectedScoringType==="Average"||selectedScoringType==="Mean"||selectedScoringType==="Sum"||selectedScoringType==="Multiply"
  }
  const addLineController=()=>{
    if(selectedPage === "Choose Page ID" || selectedScoringType === 'Type of Scoring'||((selectedScoringType==="Condition"||selectedScoringType==="Recoded")&&(!isConditionValid()||!isValidSubCond(conditions[conditions.length-1])))||(isVarType()&&(addedVars.length<2||computedVariable.length===0))||(selectedScoringType==="Formula"&&(selectedVariable==="Variable"||computedVariable.length===0))||(selectedScoringType==="Comment"&&comment.length===0))
    {
      return true
    }
    return false
  }

  function isNumber(str) {
    if(selectedScoringType==="Condition"||selectedScoringType==="Recoded")
      {
      if (str.trim() === '') {
        return false;
      }
    
      return !isNaN(str);
    }
    return true
  }

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(ScoringType);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setScoringType(items);
  }

  const getPages = async () => {

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/assessment/${drawerCtx?.details?.id}/pages`)
      const data = await res.json();

      if (data.status === "success") {
        let sortedPages = data.pages?.sort((a, b) => a.order_no - b.order_no);
        setPages([...pages, ...sortedPages]);
      }
    } catch (error) {
      console.log('error getting pages : ', error)
    }
  }

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  const toggleDrawerHandler = (drawer, user) => {
    subdrawerCtx.openDrawer();
    subdrawerCtx.setDetails(drawer, user);
  }


  useEffect(() => {
    getPages()
  }, [])

  useEffect(() => {
    console.log('Pages-----', pages)
  }, [pages])

  useEffect(() => {
    const keyArray = pagesData.map(item => Object.keys(item)[0]);
    setPageId(keyArray)
    console.log('keyArray', keyArray)
  }, [pagesData])

  useEffect(() => {
    console.log('drawerCtx', drawerCtx)
  }, [drawerCtx])

  useEffect(() => {
    console.log(conditions);
  }, [conditions]);


  let getLines = () => {
    setIsLoading(true);
    setIsLoadingLines(true);

    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/" + props.details.id + "/scores", {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('all lines data', data);
        setPagesData(data?.data)
        let variables = data.data.filter(obj => obj.variable);
        // console.log("variables setting 1", variables);
        // setVariables(variables);
        //setFormulaVars(variables);
        setIsLoading(false);
      });

    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/" + props.details.id + "/scores", {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLines(data.data);
        setIsLoadingLines(false);
      });

    // for variables
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/" + props.details.id + "/variables", {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("variables setting 2", data?.data);
        const filteredData=data?.data.filter(item=>{
          return item?.variable.length!==0
        })
        setVariables(filteredData)
        setFetchedVars(filteredData)
        setIsLoadingLines(false);
      });
  }

  let getUpdates = () => {
    setIsLoadingLines(true);

    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/" + props.details.id + "/scores", {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('all lines data', data);
        setPagesData(data?.data)
        let variables = data.data.filter(obj => obj.variable);
        // console.log("variables setting 1", variables);
        // setVariables(variables);
        //setFormulaVars(variables);
        setIsLoading(false);
      });

    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/" + props.details.id + "/scores", {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setLines(data.data);
        setIsLoadingLines(false);
      });

    // for variables
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/" + props.details.id + "/variables", {
      method: "GET",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log("variables setting 2", data?.data);
        const filteredData=data?.data.filter(item=>{
          return item?.variable.length!==0
        })
        setVariables(filteredData)
        setFetchedVars(filteredData)
        setIsLoadingLines(false);
      });
  }

  useEffect(() => {
    getLines()
  }, [])

  useEffect(() => {
    console.log('lines', lines)
  }, [lines])


  const chooseVariable = (e) => {
    let val = e.target.value;
    if (val !== 'Variable') {
      const updateVarArray = variables.filter(item => item.variable != val);
      console.log(updateVarArray)
      setVariables(updateVarArray);
      setAddedVars([...addedVars, { "variable": val }]);
      // setSelectedVariablesData([...selectedVariablesData,{"variable": val}])
      setSelectedVariable('Variable');
    }
  }

  const getPageHeading = (pageId) => {
    const page = pages.find(item => item.id === pageId);

    // console.log("page",page)

    if (page) { return page?.page_type; }

    return null;
  }

  useEffect(() => {
    console.log('AddedVars', addedVars)
  }, [addedVars])

  const removeVariable = (val) => {
    console.log(val)
    const updateVarArray = addedVars.filter(item => {
      return item.variable != val
    })
    const added=updateVarArray.map(item=>{
      return item.variable
    })
    // setSelectedVariablesData(updateVarArray);
    let menuVars=fetchedVars.filter(item=>{
      return !added.includes(item.variable)
    })
    setVariables(menuVars)
    setAddedVars(updateVarArray);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  const moveCursorToEnd = () => {
    if (blockquoteRef.current) {
      const blockquote = blockquoteRef.current;
      const range = document.createRange();
      const selection = window.getSelection();

      range.selectNodeContents(blockquote);
      range.collapse(false); // Set the range to the end of the blockquote
      selection.removeAllRanges();
      selection.addRange(range);
    }
  };

  const handleEditableDiv = () => {
    const blockquoteElement = blockquoteRef.current;
    const textContent = blockquoteElement.textContent;
    setSelectedVariable(textContent);

    // const updatedContent = textContent.replace(
    //     /(col1|col2|col3|col4|flx1|flx2|flx3|flx4)(?=\s)/g,
    //     '<span class="variable-tag">$1</span>'
    // );
    const formattedString = variables.map(item => item.variable).join('|');

    const updatedContent = textContent.replace(
      new RegExp(`(${formattedString})(?=\\s)`, 'g'),
      '<span class="variable-tag">$1</span>'
    );

    console.log('updatedContent', updatedContent)

    blockquoteElement.innerHTML = updatedContent;

    const words = blockquoteElement.textContent.split(/\s+/);

    const recentWord = words[words.length - 1];

    console.log('words', words)

    console.log('recentWord', recentWord)

    //const filtered = FormulaVars
    //  .filter((item) =>
    //    item.variable.toLowerCase().includes(recentWord.toLowerCase())
    //  )

    //setVariableSuggestionsArray(filtered)
    setShowSuggestionBox(true)
    moveCursorToEnd()
  };


  const addSuggestionVariable = (args) => {
    const blockquoteElement = blockquoteRef.current;

    const formattedString = variables.map(item => item.variable).join('|');
    const words = blockquoteElement.textContent.split(/\s+/);

    words[words.length - 1] = args

    console.log('words pop', words)

    const newTextContent = words.join(' ');

    blockquoteElement.textContent = newTextContent;

    const updatedContent = blockquoteElement.textContent.replace(
      new RegExp(`(${formattedString})`, 'g'),
      '<span class="variable-tag">$1</span>'
    );

    blockquoteElement.innerHTML = updatedContent;
    setShowSuggestionBox(true)
  }

  const getPageId = (pageName) => {
    let pageObj = pages.find(p => p.page_type === pageName);

    return pageObj.id;
  }

  const addLine = (pageName) => {
    setIsAdding(true)
    const subconditions = (selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions.slice(1):[];
    const payload = {
      functionType: selectedScoringType,
      variables: addedVars,
      pageId: getPageId(pageName),
      computedVariable: computedVariable,
      codingFrom:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].codingFrom:selectedVariable,
      operator:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].operator:selectedSignValue,
      compareType:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].compareType:selectedInputType,
      compareValue:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].compareValue:selectedComparisonValue,
      compareVariable:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].compareVariable:selectedComparisonVariable,
      computedValue: recodedValue,
      comment: comment,
      mathRaw: selectedVariable,
      logicalOperator:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].logicalOperator:"",
      subConditions:subconditions
    }
    console.log('payload', payload);

    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/" + props.details.id + "/score", {
      method: "POST",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAdding(false)
        console.log(data);
        setSelectedPage("Choose Page ID");
        setComputedVariable("");
        setSelectedScoringType('Type of Scoring');
        setSelectedVariable('Variable');
        setselectedSignValue('==');
        setSelectedInputType('Number');
        setSelectedComparisonValue(0);
        setSelectedComparisonVariable("");
        setRecodedValue(0);
        setEditMode(false);
        setComment("")
        setAddedVars([])
        setConditions([{
          codingFrom: '',
          operator: '==',
          compareType: "",
          compareValue: 0,
          compareVariable: '',
          logicalOperator: ''
        }])
        setVarLenWarn(false)
        getUpdates()
      })
      .catch(err=>{})
      }
  const editLine = (pageName) => {
    setIsAdding(true)
    const subconditions = (selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions.slice(1):[];
    const paysubarray = subconditions.map(({
      id: subConditionId,
      ...rest
    }) => ({
      subConditionId,
      ...rest
    }));
    const payload = {
      functionType: selectedScoringType,
      variables: addedVars,
      pageId: getPageId(pageName),
      assessmentId: conditions[0].assessmentId,
      computedVariable: computedVariable,
      codingFrom:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].codingFrom:selectedVariable,
      operator:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].operator:selectedSignValue,
      compareType:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].compareType:selectedInputType,
      compareValue:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].compareValue:selectedComparisonValue,
      compareVariable:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].compareVariable:selectedComparisonVariable,
      computedValue: recodedValue,
      comment: comment,
      mathRaw: selectedVariable,
      logicalOperator:(selectedScoringType==="Condition"||selectedScoringType==="Recoded")?conditions[0].logicalOperator:"",
      subConditions:paysubarray
    }
    console.log('payload', payload);
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/score/" + editId, {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        setIsAdding(false)
        console.log(data);
        setSelectedPage("Choose Page ID");
        setComputedVariable("");
        setSelectedScoringType('Type of Scoring');
        setSelectedVariable('Variable');
        setselectedSignValue('==');
        setSelectedInputType('Number');
        setSelectedComparisonValue(0);
        setSelectedComparisonVariable("");
        setRecodedValue(0);
        setEditMode(false);
        setComment("")
        setAddedVars([])
        setVarLenWarn(false)
        getUpdates()
      })
      .catch(err=>{})
      setConditions([{
        codingFrom: '',
        operator: '==',
        compareType: "",
        compareValue: 0,
        compareVariable: '',
        logicalOperator: ''
      }])

  }

  const editSubCondLine = (condobj) => {
    const payload = {
        functionType: condobj.functionType,
        orderNo: condobj.orderNo,
        mathFormulaRaw: condobj.mathFormulaRaw,
        assessmentId: condobj.assessmentId,
        codingFrom: condobj.codingFrom,
        operator: condobj.operator,
        compareType: condobj.compareType,
        compareValue: condobj.compareValue,
        compareVariable: condobj.compareVariable,
        comment: condobj.comment,
        pageId: condobj.pageId,
        logicalOperator: "",
        subConditions:[],
        computedVariable:condobj.computedVariable
    }
    console.log('payload', payload);
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/questions/score/" + condobj.id, {
      method: "PUT",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + authCtx.token },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        /*setSelectedPage("Choose Page ID");
        setComputedVariable("");
        setSelectedScoringType('Type of Scoring');
        setSelectedVariable('Variable');
        setselectedSignValue('==');
        setSelectedInputType('Number');
        setSelectedComparisonValue(0);
        setSelectedComparisonVariable("");
        setRecodedValue(0);
        setEditMode(false);
        setComment("")
        setAddedVars([])
        setVarLenWarn(false)*/
        getUpdates()
      })
      .catch(err=>{})
      /*setConditions([{
        codingFrom: '',
        operator: '==',
        compareType: "",
        compareValue: 0,
        compareVariable: '',
        logicalOperator: ''
      }])*/
  }

  const onCancelEdit = () => {
    setSelectedPage("Choose Page ID");
    setComputedVariable("");
    setSelectedScoringType('Type of Scoring');
    setSelectedVariable('Variable');
    setselectedSignValue('==');
    setSelectedInputType('Number');
    setSelectedComparisonValue(0);
    setSelectedComparisonVariable("");
    setRecodedValue(0);
    setEditMode(false);
    setAddedVars([])
    setConditions([{
      codingFrom: '',
      operator: '==',
      compareType: "",
      compareValue: 0,
      compareVariable: '',
      logicalOperator: ''
    }])
    setVariables(fetchedVars)
    setVarLenWarn(false)
    //getLines()
  }

  useEffect(() => {
    console.log('selectedScoringType', selectedScoringType)
    console.log('selectedPage', selectedPage)
  }, [selectedScoringType, selectedPage])


  // useEffect(()=>{
  //   console.log('formulaTagInnerHtml',formulaTagInnerHtml)
  // },[formulaTagInnerHtml]);

  // useEffect(()=>{
  //   console.log('selectedVariablesData',selectedVariablesData)
  //   console.log('variables',variables)
  // },[variables,selectedVariablesData]);

  const addedVariables = () => {
    return <div className="selected-variable-container">
      {
        addedVars?.map((el) => {
          return (
            <div className="selected-variable-tag">{el?.variable}
              <img src={CloseIcon} alt="" style={{ cursor: "pointer" }} onClick={() => { removeVariable(el?.variable) }} />
            </div>
          )
        })
      }
    </div>;
  }

  const updateCondition = (type, index, value) => {
    let x = [...conditions];
    (x[index])[type] = value;
    setConditions(conditions => [...x]);
    console.log(conditions)
  }
  const deleteCondition = (i) => {
    let x = [...conditions];
    x.splice(i, 1);
    setConditions(conditions => [...x]);
  }
  const addCondition = () => {
    let x = [...conditions];
    x.push(conditionFormat);
    setConditions(conditions => [...x]);
  }
  
  const showScoringMethod = () => {
    switch (selectedScoringType) {
      case 'Type of Scoring':
        return <></>;

      case 'Average':
        return <>
          {addedVariables()}
          <div className="variable-input-box">
            <TextField value={selectedVariable} onChange={(e) => { chooseVariable(e); }} select variant="outlined" className='choose-variable-select-tag' >
              {variables?.map((item, index) => {
                return (
                  <MenuItem key={index} className="section-name" value={item.variable}>
                    {item.variable}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        </>;

      case 'Mean':
        return <>
          {addedVariables()}
          <div className="variable-input-box">
            <TextField value={selectedVariable} onChange={(e) => { setSelectedVariable(e.target.value); chooseVariable(e); }} select variant="outlined" className='choose-variable-select-tag' >
              {variables?.map((item) => {
                return (
                  <MenuItem className="section-name" value={item.variable}>
                    {item.variable}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        </>;

      case 'Multiply':
        return <>
          {addedVariables()}
          <div className="variable-input-box">
            <TextField value={selectedVariable} onChange={(e) => { setSelectedVariable(e.target.value); chooseVariable(e); }} select variant="outlined" className='choose-variable-select-tag' >
              {variables?.map((item) => {
                return (
                  <MenuItem className="section-name" value={item.variable}>
                    {item.variable}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        </>;

      case 'Sum':
        return <>
          {addedVariables()}
          <div className="variable-input-box">
            <TextField value={selectedVariable} onChange={(e) => { setSelectedVariable(e.target.value); chooseVariable(e); }} select variant="outlined" className='choose-variable-select-tag' >
              {variables?.map((item) => {
                return (
                  <MenuItem className="section-name" value={item.variable}>
                    {item.variable}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        </>;

        case 'Condition':
          return <div className='condition-box'>
            {
              conditions.map((condition, i) => {
                return (
                  <div className="variable-input-box recode-and-condition-input-container">
                    <p>If</p>
                    <div className="input-box">
                      <TextField value={condition.codingFrom} onChange={(e) => { updateCondition("codingFrom", i, e.target.value) }} select variant="outlined" className='choose-variable-select-tag'>
                        {
                          variables?.map((item, index) => {
                            return <MenuItem key={index} className="section-name" value={item.variable}>{item.variable}</MenuItem>
                          }
                          )}
                      </TextField>
                    </div>
                    <div className="input-box">
                      <TextField value={condition.operator} onChange={(e) => { updateCondition("operator", i, e.target.value) }} select variant="outlined" className='choose-sign-select-tag'>
                        {
                          SignsArray?.map((item) => {
                            return <MenuItem className="section-name" value={item.option}>{item.option}</MenuItem>
                          }
                          )}
                      </TextField>
                    </div>
                    <div className="input-box">
                      <TextField value={condition.compareType} onChange={(e) => { updateCondition("compareType", i, e.target.value); console.log(e.target.value) }} select variant="outlined" className='choose-sign-select-tag'>
                        {
                          selectedInputTypeArray?.map((item) => {
                            return <MenuItem className="section-name" value={item.type}>{item.type}</MenuItem>
                          }
                          )}
                        </TextField>
                    </div>
                    {
                      condition.compareType === 'Number' ? <>
                        <div className="number-variable-box">
                          <input type="number" placeholder='' value={condition.compareValue} onChange={(e) => { updateCondition("compareValue", i, e.target.value) }} />
                        </div>
                      </> : <>
                        <div className="input-box">
                          <TextField value={condition.compareVariable} onChange={(e) => { updateCondition("compareVariable", i, e.target.value) }} select variant="outlined" className='choose-variable-select-tag'>
                            {
                              variables?.map((item) => {
                                return <MenuItem className="section-name" value={item.variable}>{item.variable}</MenuItem>
                              }
                              )}
                          </TextField>
                        </div>
                      </>
                    }
                    <div className="input-box">
                      <TextField value={condition.logicalOperator} onChange={(e) => {
                        console.log(e.target.value);
                        if (e.target.value === "&&" || e.target.value === "||") {
                          if(conditions[i].logicalOperator===""&&isValidSubCond(conditions[i]))
                          {

                            updateCondition('logicalOperator', i, e.target.value);
                            addCondition()
                          }
                          else if(isValidSubCond(conditions[i]))
                          {
                            updateCondition('logicalOperator', i, e.target.value);
                          }
                          // setNumberOfConditions(i => i+1);
                        }
                        else {
                          if(i===conditions.length-2)
                          {
                            toggleModalHandler("delete-scoring-modal", { "scoringDetails": { ...conditions[i+1] }, updateScores: getUpdates,updateCondition:updateCondition,i:i,value:e.target.value,deleteCondition:deleteCondition,editSubCondLine:editSubCondLine,editCond:conditions[i],type:"Sub" });
                          }
                        }
                      }} select variant="outlined" className='choose-sign-select-tag'>
                        <MenuItem className="section-name" value={""}>{"-"}</MenuItem>
                        <MenuItem className="section-name" value={"&&"}>{"AND"}</MenuItem>
                        <MenuItem className="section-name" value={"||"}>{"OR"}</MenuItem>
                      </TextField>
                    </div>
                    {
                      (i === (conditions.length - 1)) &&
                      <>
                        <p>then</p>
                        <div className="selected-variable-tag">{computedVariable}</div>
                        <p>=</p>
                        <div className="new-variable-input-box input-box"><input type="text" placeholder='' value={recodedValue} onChange={(e) => {setRecodedValue(e.target.value)}} />
                        </div>
                      </>
                    }
                  </div>
                )
              })
            }
          </div>;

      case 'Recoded':
        return <>
          {/*<div className="variable-input-box recode-and-condition-input-container">
            <p>If</p>
            <div className="input-box">
              <TextField value={selectedVariable} onChange={(e) => { setSelectedVariable(e.target.value); }} select variant="outlined" className='choose-variable-select-tag'>
                {
                  variables?.map((item, index) => {
                    return <MenuItem key={index} className="section-name" value={item.variable}>{item.variable}</MenuItem>
                  }
                  )}
              </TextField>
            </div>
            <div className="input-box">
              <TextField value={selectedSignValue} onChange={(e) => { setselectedSignValue(e.target.value) }} select variant="outlined" className='choose-sign-select-tag'>
                {
                  SignsArray?.map((item) => {
                    return <MenuItem className="section-name" value={item.option}>{item.option}</MenuItem>
                  }
                  )}
              </TextField>
            </div>
            <div className="input-box">
              <TextField value={selectedInputType} onChange={(e) => { setSelectedInputType(e.target.value) }} select variant="outlined" className='choose-sign-select-tag'>
                {
                  selectedInputTypeArray?.map((item) => {
                    return <MenuItem className="section-name" value={item.type}>{item.type}</MenuItem>
                  }
                  )}
              </TextField>
            </div>
            {
              selectedInputType === 'Number' ? <>
                <div className="number-variable-box">
                  <input type="text" placeholder='' value={selectedComparisonValue} onChange={(e) => setSelectedComparisonValue(e.target.value)} />
                </div>
              </> : <>
                <div className="input-box">
                  <TextField value={selectedComparisonVariable} onChange={(e) => { setSelectedComparisonVariable(e.target.value) }} select variant="outlined" className='choose-variable-select-tag'>
                    {
                      variables?.map((item) => {
                        return <MenuItem className="section-name" value={item.variable}>{item.variable}</MenuItem>
                      }
                      )}
                  </TextField>
                </div>
              </>
            }
            <p>then</p>
            <div className="selected-variable-tag">{selectedVariable}</div>
            <p>=</p>
            <div className="new-variable-input-box input-box"><input type="text" placeholder='' value={recodedValue} onChange={(e) => { setRecodedValue(e.target.value) }} /></div>
          </div>*/}
          <div className='condition-box'>
            {
              conditions.map((condition, i) => {
                return (
                  <div className="variable-input-box recode-and-condition-input-container">
                    <p>If</p>
                    <div className="input-box">
                      <TextField value={condition.codingFrom} onChange={(e) => { updateCondition("codingFrom", i, e.target.value) }} select variant="outlined" className='choose-variable-select-tag'>
                        {
                          variables?.map((item, index) => {
                            return <MenuItem key={index} className="section-name" value={item.variable}>{item.variable}</MenuItem>
                          }
                          )}
                      </TextField>
                    </div>
                    <div className="input-box">
                      <TextField value={condition.operator} onChange={(e) => { updateCondition("operator", i, e.target.value) }} select variant="outlined" className='choose-sign-select-tag'>
                        {
                          SignsArray?.map((item) => {
                            return <MenuItem className="section-name" value={item.option}>{item.option}</MenuItem>
                          }
                          )}
                      </TextField>
                    </div>
                    <div className="input-box">
                      <TextField value={condition.compareType} onChange={(e) => { updateCondition("compareType", i, e.target.value); console.log(e.target.value) }} select variant="outlined" className='choose-sign-select-tag'>
                        {
                          selectedInputTypeArray?.map((item) => {
                            return <MenuItem className="section-name" value={item.type}>{item.type}</MenuItem>
                          }
                          )}
                        </TextField>
                    </div>
                    {
                      condition.compareType === 'Number' ? <>
                        <div className="number-variable-box">
                          <input type="number" placeholder='' value={condition.compareValue} onChange={(e) => { updateCondition("compareValue", i, e.target.value) }} />
                        </div>
                      </> : <>
                        <div className="input-box">
                          <TextField value={condition.compareVariable} onChange={(e) => { updateCondition("compareVariable", i, e.target.value) }} select variant="outlined" className='choose-variable-select-tag'>
                            {
                              variables?.map((item) => {
                                return <MenuItem className="section-name" value={item.variable}>{item.variable}</MenuItem>
                              }
                              )}
                          </TextField>
                        </div>
                      </>
                    }
                    <div className="input-box">
                      <TextField value={condition.logicalOperator} onChange={(e) => {
                        if (e.target.value === "&&" || e.target.value === "||") {
                          if(conditions[i].logicalOperator===""&&isValidSubCond(conditions[i]))
                          {

                            updateCondition('logicalOperator', i, e.target.value);
                            addCondition()
                          }
                          else if(isValidSubCond(conditions[i]))
                          {
                            updateCondition('logicalOperator', i, e.target.value);
                          }
                          
                          // setNumberOfConditions(i => i+1);
                        }
                        else {
                          if(i===conditions.length-2)
                          {
                            toggleModalHandler("delete-scoring-modal", { "scoringDetails": { ...conditions[i+1] }, updateScores: getUpdates,updateCondition:updateCondition,i:i,value:e.target.value,deleteCondition:deleteCondition,editSubCondLine:editSubCondLine,editCond:conditions[i],type:"Sub" });
                          }
                        }
                      }} select variant="outlined" className='choose-sign-select-tag'>
                        <MenuItem className="section-name" value={""}>{"-"}</MenuItem>
                        <MenuItem className="section-name" value={"&&"}>{"AND"}</MenuItem>
                        <MenuItem className="section-name" value={"||"}>{"OR"}</MenuItem>
                      </TextField>
                    </div>
                    {
                      (i === (conditions.length - 1)) &&
                      <>
                        <p>then</p>
                        <div className="selected-variable-tag">{conditions[0].codingFrom}</div>
                        <p>=</p>
                        <div className="new-variable-input-box input-box"><input maxLength={16} type="text" placeholder='' value={recodedValue} onChange={(e) => {setRecodedValue(e.target.value)}} /></div>
                      </>
                    }
                  </div>
                )
              })
            }
          </div>;
        </>
      case 'Comment':
        return <>
          <div className="variable-input-box comment-section-input-container">
            <div className="input-box">
              <TextField value={comment} type='text' onChange={(e) => setComment(e.target.value)} />
            </div>
          </div>
        </>

      case "Formula":
        return <>
          <div className="variable-input-box">
            <div contentEditable
              onInput={handleEditableDiv}
              onFocus={() => {
                // const blockquoteElement = blockquoteRef.current;
                // if(blockquoteElement.innerHTML="Add Formula"){
                //   console.log(blockquoteElement.innerHTML)
                //   blockquoteElement.innerHTML=""
                // }
                setShowSuggestionBox(true)
                setAddFormulaPlaceholder(false)
              }}
              onBlur={() => {
                // const blockquoteElement = blockquoteRef.current;
                // console.log(blockquoteElement.innerHTML)
                setAddFormulaPlaceholder(true)
                setTimeout(() => {
                  setShowSuggestionBox(false)
                }, 100);
              }}
              ref={blockquoteRef}
              className='edit-formula'
            >
              {/* <p className='add-formula-placeholder' style={AddFormulaPlaceholder?{display:"none"}:{display:"block"}}>Add Formula</p> */}
            </div>
            {
              ShowSuggestionBox ? <div  className="variable-input-suggestion-box">
                {
                  variables?.length !== 0 ? <>
                    {
                      variables?.map((el) => {
                        return <p className="suggestions" onClick={(e) => { addSuggestionVariable(e.target.textContent) }}>{el.variable}</p>
                      })
                    }
                  </> : <>
                    <p className="suggestions">No variables found</p>
                  </>
                }
              </div> : <></>
            }
          </div>
        </>

      default:
        return '';
    }
  }

  const showScoringDetails = (line) => {
    switch (line.functionType) {
      case 'Type of Scoring':
        return <></>;

      case 'Formula':
        return <>{line.mathFormulaRaw}</>;

      case 'Condition':
        return line?.parentId===0&&<>If {line?.codingFrom} {line?.operator} {line?.compareType==="Variable"?line?.compareVariable:line?.compareValue} {line?.logicalOperator} {line?.subConditions.map(item=>{
          return(
            <>
              If {item?.codingFrom} {item?.operator} {item?.compareType==="Variable"?item?.compareVariable:item?.compareValue} {item?.logicalOperator}
            </>
          )
        })} then {line?.computedVariable} = {line?.computedValue} </>

      case 'Recoded':
        return line?.parentId===0&&<>If {line?.codingFrom} {line?.operator} {line?.compareType==="Variable"?line?.compareVariable:line?.compareValue} {line?.logicalOperator} {line?.subConditions.map(item=>{
          return(
            <>
              If {item?.codingFrom} {item?.operator} {item?.compareType==="Variable"?item?.compareVariable:item?.compareValue} {item?.logicalOperator}
            </>
          )
        })} then {line?.codingFrom} = {line?.computedValue} </>

      case 'Comment':
        return <><p style={{color:"#9B9B9B", margin:0,padding:0}}>{line?.comment}</p></>

      default:
        return '';
    }
  }

  const editScoring = (item,index) => {
    let condTemp=[]
    if(item.functionType==="Condition"||item.functionType==="Recoded"){
      condTemp=[/*{
        codingFrom:item.codingFrom,
        operator:item.operator,
        compareType:item.compareType,
        compareValue:item.compareValue,
        compareVariable:item.compareVariable,
        logicalOperator:item.logicalOperator
      }*/JSON.parse(JSON.stringify(item))]
      const arr = JSON.parse(JSON.stringify(item.subConditions));
      condTemp.push(...arr)
      setConditions(condTemp)
    }
    setComment(item.comment)
    setEditId(item.id)
    setEditMode(true);
    setSelectedPage(getPageHeading(item.pageId));
    setComputedVariable(item.computedVariable);
    setSelectedScoringType(item.functionType);
    setTimeout(()=>{
      const blockquoteElement =blockquoteRef.current;
      if(blockquoteElement)
      {
        blockquoteElement.textContent=item.mathFormulaRaw;
        handleEditableDiv()
        
      }
    })
    setSelectedVariable(item.codingFrom);
    setselectedSignValue(item.operator);
    setSelectedInputType(item.compareType);
    setSelectedComparisonValue(item.compareValue);
    setSelectedComparisonVariable(item.compareVariable);
    setRecodedValue(item.computedValue);
    const added=[]
    let addedTemp=item?.functionVariables?.map((el, index) => {
      added.push(el?.variable);
      return {"variable":el?.variable};
    })
    let menuVars=variables.filter(item=>{
      return !added.includes(item.variable)
    })
    setVariables(menuVars)
    setAddedVars(addedTemp)
  }

  return (
    <div className='container scoring-container'>
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500" className='add-scoring-heading'>{props?.details?.title} {`>`} Scoring</h1>
          <div style={{overflowX:`${selectedScoringType!=="Formula"?"scroll":""}`}} className="form form-control-add-question">
            <div className="input-container">
              <div className="selected-page-input-box">
                <TextField value={selectedPage} onChange={(e) => setSelectedPage(e.target.value)} select variant="outlined" className='scoring-type-select-tag'>
                  {
                    pages.map((item, index) => {
                      return <MenuItem key={index} className="section-name" value={item?.page_type}>{item?.page_type}</MenuItem>
                    })
                  }
                </TextField>
              </div>
              {
                selectedScoringType == 'Recoded' || selectedScoringType == 'Comment' ? <></> : <div className="new-variable-input-box">
                  <input type="text" placeholder='New Variable' value={computedVariable} onChange={(e) => {if(e.target.value.length===16){setVarLenWarn(true)}else{setVarLenWarn(false);setComputedVariable(e.target.value)}}}  />
                  {varLenWarn && <div style={{color:"red",fontSize:"12px",marginTop:"5px"}} className="error">Value must be less than 17 characters.</div>}
                </div>
              }
              <div className="scoring-type-input-box">
                <TextField value={selectedScoringType} onChange={(e) => setSelectedScoringType(e.target.value)} select variant="outlined" className='scoring-type-select-tag'>
                  {
                    ScoringType.map((item, index) => {
                      return <MenuItem key={index} className="section-name" value={item}>{item}</MenuItem>
                    })
                  }
                </TextField>
              </div>
              {showScoringMethod()}
            </div>
            <div style={{display:"flex",alignItems:"center",gap:"5px"}} className="submit-btn-container">
              {
                editMode ?
                  <>
                    <>{isAdding ?<Button style={{ background: addLineController() ? "#696a6a" : "#000" }} className="delete-btn" disabled> <CircularProgress size="1rem" sx={{color:"white"}} /></Button> :<Button onClick={() => { editLine(selectedPage)}} style={{ background: addLineController() ? "#696a6a" : "#000" }} disabled={addLineController()} >SAVE CHANGES</Button>}</>
                    <Button onClick={() => {
                      //drawerCtx.setDetails({});
                      //drawerCtx.closeDrawer();
                      onCancelEdit()
                    }} style={{ background: "White",border:"2px solid #122845",color:"#122845", marginLeft: 10 }}>Cancel</Button>
                    <div style={{display:"flex",justifyContent:"center",alignItems:"center",cursor:"pointer"}} className="del-icon"onClick={() => {
                                          toggleModalHandler("delete-scoring-modal", { "scoringDetails": { ...editItem }, updateScores: getUpdates });
                                          onCancelEdit()
                                        }} >
                    
                                          <img src={delIcon} alt="" />
                                        </div>
                  </> :
                  <>{isAdding ?<Button style={{ background: addLineController()? "#696a6a" : "#000" }} className="delete-btn" disabled> <CircularProgress size="1rem" sx={{color:"white"}} /></Button> :<Button onClick={() => { addLine(selectedPage) }} style={{ background:addLineController() ? "#696a6a" : "#000" }} disabled={addLineController()} >ADD LINE</Button>}
                  <Button onClick={() => {
                      //drawerCtx.setDetails({});
                      //drawerCtx.closeDrawer();
                      onCancelEdit()
                    }}style={{ background: "White",border:"2px solid #122845",color:"#122845", marginLeft: 10 }}>Reset</Button>
                  </>
              }
            </div>
          </div>
        </header>

        <section className="add-scoring-section">
          {/* {console.log("pages data",pages)}
          {console.log("all pages",pagesData)} */}
          {
            isLoading ? <>
              <CircularProgress />
            </> : pagesData?.map((el, index) => {
              return (
                <div className="scoring-page-name" key={index}>
                  <div className="scoring-page-heading-and-icon-container">
                    <div className="page-icon">
                      <img src={pageIcon} alt="" />
                    </div>
                    {/* {console.log('el', el[pageId[index]][0].pageId)} */}
                    <h3 className="page-heading">
                      {getPageHeading(el[pageId[index]][0]?.pageId)}
                    </h3>
                  </div>
                  <div className="drag-and-drop-container">
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                      <Droppable droppableId="scoring">
                        {(provided) => (
                          <ul className="scoring-list"  {...provided.droppableProps} ref={provided.innerRef}>
                            {/* {console.log('el[pageId[index]]', el[pageId[index]])} */}
                            {el[pageId[index]]?.map((item, index) => {
                              return (item.type !== 'Type of Scoring'&&item.parentId==0) ? (
                                <Draggable key={item.id} draggableId={'scoring-list-item-' + item.id} index={index}>
                                  {(provided) => (
                                    <li
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      onMouseEnter={() => {
                                        setIsHoverEditdel(true);
                                        setShowEditDel(item?.id);
                                      }}
                                      onMouseLeave={() => {
                                        setIsHoverEditdel(false);
                                        setShowEditDel(null);
                                      }}
                                    >
                                      <div className='scoring-type-dnd-child'>
                                        <div className="left-container">
                                          <div className="drag-icon-and-scoring-container">
                                            <div className="drag-icon" {...provided.dragHandleProps}>
                                              <DragIndicator sx={{color:`${item?.functionType==="Comment"?"#D0D4DA":""}`}} />
                                            </div>
                                            <div style={{color:`${item?.functionType==="Comment"?"#9B9B9B":""}`}} className="order">{index + 1}</div>
                                          </div>
                                          <p style={{display:`${(item?.functionType==="Comment"||item?.functionType==="Recoded")?"none":""}`}} className='computed-variable'>{item?.computedVariable}</p>
                                          <div className="scroring-type-indicator">
                                            <p style={{background:`${item?.functionType==="Comment"?"#D0D4DA":""}`}}>{item?.functionType}</p>
                                          </div>
                                        </div>
                                        <div className="variable-name-container" onClick={() => { console.log(item) }}>
                                          {showScoringDetails(item)}
                                          {item?.functionVariables?.map((el, index) => {
                                            return <><span className="variable-name" key={el?.id}>{el?.variable}</span>{index===item.functionVariables.length-1?"":<span>|</span>}</>;
                                          })}
                                        </div>
                                      </div>
                                      <div className="scoring-types-crud" style={IsHoverEditdel && item?.id === ShowEditDel ? { display: "flex" } : { display: 'none' }}>
                                        <div className="edit-icon" onClick={() => {
                                          // toggleDrawerHandler('scoring-edit-subdrawer')
                                          // console.log(getPageHeading(item.pageId));
                                          console.log(item);
                                          setEditItem(item)
                                          editScoring(item,index);
                                          console.log(editItem);
                                        }}>
                                          <img src={editIcon} alt="" />
                                        </div>
                                        <div className="del-icon" onClick={() => {
                                          toggleModalHandler("delete-scoring-modal", { "scoringDetails": { ...item }, updateScores: getUpdates })
                                        }}>
                                          <img src={delIcon} alt="" />
                                        </div>
                                      </div>
                                    </li>
                                  )}
                                </Draggable>
                              ) : null;
                            })}
                            {provided.placeholder}
                          </ul>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              );
            })
          }
        </section>
      </div>
    </div>
  )
}