import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import {  TextField,  Select,  MenuItem,  FormControl,  InputLabel,  Button,  Checkbox} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import CircularProgress from '@material-ui/core/CircularProgress';

function EditClientDrawer({ details }) {

  console.log("props", details);

  const [client, setClient] = useState(details.title || "");
  const [isLoading, setIsLoading] = useState(false);
  
	const drawerCtx = useContext(DrawerContext);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    
    fetch(process.env.REACT_APP_API_URI + "/v1/accounts", {
      method: "PUT",
      body: JSON.stringify({
        clientID: details?.id,
        title: client,
      }),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .then(setIsLoading(false))
      .catch(err => {
        if(err) {
          console.log(err);
          setIsLoading(false);
        }
      });
  };

  return (
    <div className="drawer">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
          Edit Client
        </h1>
      </header>

      <section>
        <div className="double-column-form">
          <TextField
            label="Client Name"
            value={client}
            onChange={(e) => {
              setClient(e.target.value);
            }}
            placeholder="Client Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
        </div>

        <div className="double-column-form aligned-right margin-top">
          {isLoading ? <CircularProgress /> :
            <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            component="label"
            disabled={client === "" || client === null}
            >
              Save Changes
            </Button>
          }
        </div>
      </section>
    </div>
  );
}
export default EditClientDrawer;
