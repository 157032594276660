import React, { useContext } from 'react'
import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';


const ShowAddSections = (props) => {

  // console.log("ShowAddSections props", props);

  const modalCtx = useContext(ModalContext);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  return (
    <div className='modal'>
      <p style={{ fontSize: "18px", margin: "10px auto 20px" }}>Add sections before adding questions.</p>
      <div className="footer" style={{ margin: "15px auto 0" }}>
        <Button className="green-btn" style={{ backgroundColor: "#00a5b8", color: "#f5f5f5", fontWeight: 600, padding: "5px 10px" }}
          onClick={(e) => handleCloseModal()}>Okay</Button>
      </div>
    </div>
  )
}

export default ShowAddSections;

