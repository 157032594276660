import { ArrowDropDown, CloseOutlined, RadioButtonChecked } from "@material-ui/icons";
import { Button, Checkbox } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ModalContext from "../../store/ModalContext";
import allCssJsonData from '../../assets/json/AllCssJson.json'
import BuilderContext from "../../store/BuilderContext";
import AuthContext from "../../store/AuthContext";
import { Select, MenuItem, InputLabel } from "@material-ui/core";

const AddNewStyle = (props) => {  

  // states
  const [property, setProperty]                              = useState("");
  const [value, setValue]                                    = useState("");
  const [showProperty, setShowProperty]                      = useState(false);
  const [builderConfirmation, setBuilderConfirmation]        = useState(false)
  const [blockID, setBlockID]                                = useState();
  const [showBlockID, setShowBlockID]                        = useState(false);
  const [filteredBlockIDs, setFilteredBlockIDs]              = useState([]);
  const [selectedScreenOption, setSelectedScreenOption]      = useState("")
  const [showScreenOptions, setShowScreenOptions]            = useState(false);

  // contexts 
  const modalCtx                                             = useContext(ModalContext);
  const authCtx                                              = useContext(AuthContext);
  const builderCtx                                           = useContext(BuilderContext);

  const { assessmentId, contentBlocks, updateCurrentBlock, updateContentBlocks, newUpdateCurrentBlock, currentContentBlock, currentDeviceView }  = builderCtx;

  const screenOptions = [
    { "screen" : "desktop" },
    { "screen" : "horizontal_tablet" },
    { "screen" : "vertical_tablet" },
    { "screen" : "mobile" }
  ]
  

  const filteredSuggestions = allCssJsonData.filter(suggestion =>
    suggestion.name.toLowerCase().includes(property?.toLowerCase())
  );

  const filterBlockIDs = (input) => {
    
    const filtered = contentBlocks.filter(blockID => 
    blockID.id.toString().includes(input.toString()))

    setFilteredBlockIDs(filtered)
  };

  const capitalizeAndBreakWords = (input) => {
    const words = input.split('_');
    
    const capitalizedWords = words.map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
  
    return capitalizedWords.join(' ');
  }

  const handleInputChange = (e) => {
    setSelectedScreenOption(e.target.value);
    setShowScreenOptions(true);
  };

  const handleOptionClick = (suggestion) => {
    setSelectedScreenOption(suggestion.screen);
    setShowScreenOptions(false);
  };

  const setScreenOption = (screen) => {
    console.log('screen',screen)
    switch (screen) {
      case 'horizontal_tablet': return "horizantal";
      case 'vertical_tablet': return "veritical";
      case 'mobile': return "mobile";
      default: return "desktop";
    }
  }

  const updateDataAccordingToDevice = () => {
    newUpdateCurrentBlock(property,value,selectedScreenOption)
    modalCtx.closeModal(); 
  }

  // useEffect(()=>{console.log("filteredBlockIDs",filteredBlockIDs)},[filteredBlockIDs])
  
  const addStyle = () => {
    const payload = {
      "screenType": setScreenOption(selectedScreenOption),
      value,
      property
    }

    console.log('payload',payload, 'blockID',blockID)

    fetch(process.env.REACT_APP_API_URI + `/v3/admin/assessment/${assessmentId}/content/${blockID}/style`, {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token},
      body: JSON.stringify(payload)
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if ( data.status === 'success' ) {
        updateDataAccordingToDevice()
      }
      // console.log('data',data)
    });
  }
  
  return (
    <div className="modal create-new style-modal" style={{ "borderRadius": "10px" }}>
     <div className="heading-and-close-btn-container">
        <h1>Create New Style</h1>
        <Button onClick={()=>{modalCtx.closeModal()}}>
          <CloseOutlined/>
        </Button>
     </div>
     <div className="style-property-input-container">
        <h3>Style Property</h3>
        <input type="text" placeholder="Style Property" value={property} onChange={(e)=>{
          setProperty(e.target.value)
          setShowProperty(true)
        }}
        onFocus={()=>{
          setShowProperty(true)
        }}
        onBlur={()=>{
          setTimeout(() => {
            setShowProperty(false)
          }, 200);
        }}
        />
        {
          showProperty ? <div className="new-style-suggestionbox-container">
          {
            filteredSuggestions.map((suggestion, index) => (
              <p key={index} onClick={()=>{
                setProperty(suggestion.name)
                setShowProperty(false)
              }}>{suggestion.name}</p>
            ))
          }
        </div> : <></>
        }
     </div> 
     <div className="block-id-input-container">
      <h3>Content Block</h3>
      <input
        type="text"
        placeholder="Content Block"
        value={blockID}
        onChange={(e) => {
          setBlockID(e.target.value);
          setShowBlockID(true);
          filterBlockIDs(e.target.value);
        }}
        onFocus={() => setShowBlockID(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowBlockID(false);
          }, 200);
        }}
      />
      {showBlockID ? <>
        {
          !blockID ? <div className="block-id-suggestionbox-container">
          {contentBlocks.map((el, index) => (
            <p
              key={index}
              onClick={() => {
                setBlockID(el.id);
                setShowBlockID(false);
              }}
            >
              <span>{el.id}</span>
              <span>{el.contentType}</span>
            </p>
          ))}
        </div> : <div className="block-id-suggestionbox-container">
          {filteredBlockIDs.map((el, index) => (
            <p
              key={index}
              onClick={() => {
                setBlockID(el.id);
                setShowBlockID(false);
              }}
            >
              <span>{el.id}</span>
              <span>{el.contentType}</span>
            </p>
          ))}
        </div>
        }
      </> : <></>}
    </div>
    <div className="style-property-input-container">
      <h3>Screen Options</h3>
      <input
        type="text"
        placeholder="Select Screen Option"
        value={capitalizeAndBreakWords(selectedScreenOption)}
        onChange={handleInputChange}
        onFocus={() => setShowScreenOptions(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowScreenOptions(false);
          }, 200);
        }}
      />
      {showScreenOptions && (
        <div className="new-style-suggestionbox-container">
          {screenOptions.map((suggestion, index) => (
            <p key={index} onClick={() => handleOptionClick(suggestion)}>
              {capitalizeAndBreakWords(suggestion.screen)}
            </p>
          ))}
        </div>
      )}
    </div>
     <div className="value-input-container">
        <h3>Value</h3>
        <input type="text" placeholder="Value" onChange={(e)=>{setValue(e.target.value)}}/>
     </div>
     <div className="all-builders-confirmation">
        <Checkbox checked={builderConfirmation} onChange={()=>{
          builderConfirmation ? setBuilderConfirmation(false) : setBuilderConfirmation(true)
        }} inputProps={{ 'aria-label': 'controlled' }} />
        <h3 className="checkbox-label">Add this to all default builders</h3>
     </div>
     <div className="create-btn-container">
      <Button onClick={addStyle}>Create</Button>
     </div>
    </div>
  )
}


export default AddNewStyle;

