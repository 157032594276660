import { useState, useEffect, useContext } from "react";

import { Tabs, Tab, Box } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import AddImage from "../AddImage/AddImage";
import Height from "../StylingComponents/Height";
import Width from "../StylingComponents/Width";
import Padding from "../StylingComponents/Padding";
import Margin from "../StylingComponents/Margin";
import BackgroundColor from "../StylingComponents/BackgroundColor";
import MaxWidth from "../StylingComponents/MaxWidth";
import DynamicStylingTool from "../DynamicStylingTool/DynamicStylingTool";
import ImageWidgetTabs from "./ImageWidgetTabs";
import { Add } from "@material-ui/icons";
import ModalContext from "../../../../store/ModalContext";
import { useParams } from "react-router-dom";


function TabPanel(props) {

  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0, margin: "auto" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const ImageTab = (props) => {

  const { currentContentBlock } = useContext(BuilderContext);
  const { id }                  = useParams()
  const modalCtx                = useContext(ModalContext);

  // console.log('currentContentBlock', currentContentBlock)

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  return <>
    <AddImage blockId={currentContentBlock.id} key={currentContentBlock.id} />
    {/* <BackgroundColor blockId={currentContentBlock.id} />
    <Width blockId={currentContentBlock.id} />
    <Height blockId={currentContentBlock.id} />
    <MaxWidth blockId={currentContentBlock.id} /> */}
  </>
}

const PositioningTab = (props) => {
  
  const { currentContentBlock } = useContext(BuilderContext);

  return <>
    {/* <Padding blockId={currentContentBlock.id} />
    <Margin blockId={currentContentBlock.id} /> */}
  </>;
}

const ImageWidget = (props) => {

  const builderCtx = useContext(BuilderContext);
  const { currentContentBlock, updateContentBlocks, contentBlocks, setCurrentObjStyles } = builderCtx;
  const { styling } = currentContentBlock;

  const [imageWidgetTabValue, setImageWidgetTabValue] = useState(0);
  
  const handleImageWidgetTabChange = (event, newValue) => {
    setImageWidgetTabValue(newValue);
  };

  return (
    <div>
      <>
        {/* onChange={(e) => props?.setCurrentProperty(e, "paddingLeft", builderCtx?.currentContentBlock.id)}  */}
      </>
      <DynamicStylingTool widgetName={"ImageWidget"} imageTab={<ImageTab/>} positioningTab={<PositioningTab/>} />
    </div>
  )
}


export default ImageWidget;

