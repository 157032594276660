import { CloseOutlined } from "@material-ui/icons";
import { Button, Checkbox } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ModalContext from "../../store/ModalContext";
import BuilderContext from "../../store/BuilderContext";
import AuthContext from "../../store/AuthContext";

const WrapContainerModal = (props) => {  
  
  const [contentType, setContentType]                 = useState("");
  const [builderConfirmation, setBuilderConfirmation] = useState(false);
  const [pageId, setPageId]                           = useState()
  const [newContainerID, setnewContainerID]           = useState()

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const builderCtx = useContext(BuilderContext);

  const { currentPage, pages, assessmentId, selectMultipleBlocks } = builderCtx;

 useEffect(()=>{
  getPages()
 },[])

 useEffect(() => {
  if ( newContainerID ) {
    const tempSelectMultipleBlocks = selectMultipleBlocks.map(el => ({
      ...el,
      parentContainer: newContainerID
    }));
  
    console.log('tempSelectMultipleBlocks',tempSelectMultipleBlocks)
  }
}, [selectMultipleBlocks, newContainerID]);

 

 useEffect(()=>{
  const temp = pages.find( item => item.page_type === currentPage.name )
  setPageId(temp?.id)
 },[pages,currentPage])

 const getPages = async () => {

  try {
    const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/assessment/${assessmentId}/pages`)
    const data = await res.json();

    if (data.status === "success") {
      builderCtx.updatePages(data.pages?.sort((a, b) => a.order_no - b.order_no))
    }
  } catch (error) {
    console.log('error getting pages : ', error)
  }
}

  const addWrapContainer = async () => {

    const payload = {
      "pageType": currentPage?.name,
       pageId,
      "contentType": contentType,
      "parentContainer": selectMultipleBlocks[0]?.parentContainer,
      "pageArea": selectMultipleBlocks[0]?.pageArea
    }

    console.log("payload",payload)
    console.log("payload selectMultipleBlocks",selectMultipleBlocks)

    fetch(process.env.REACT_APP_API_URI + `/v3/admin/assessment/${assessmentId}/contentBlock`, {
      method: 'POST', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("success",data)
          setnewContainerID(data.id)
          modalCtx.closeModal();
        }
      }).catch((err) => {
        console.error("Failed to create content block", err);
      })
  }

  return (
    <div className="modal wrap-container-modal" style={{ "borderRadius": "10px" }}>
     <div className="heading-and-close-btn-container">
        <h1>Add Wrap Container</h1>
        <Button onClick={() => {modalCtx.closeModal()}}>
          <CloseOutlined/>
        </Button>
     </div>
     <div className="input-and-labels-container">
        <div className="input-container">
            <h3>Content Type</h3>
            <input type="text" placeholder="Enter Content Type" value={contentType} onChange={(e) => setContentType(e.target.value)} />
        </div>
     </div>
     <div className="all-builders-confirmation">
        <Checkbox checked={builderConfirmation} onChange={() => setBuilderConfirmation(!builderConfirmation)} inputProps={{ 'aria-label': 'controlled' }} />
        <h3 className="checkbox-label">Add this to all default builders</h3>
     </div>
     <div className="create-btn-container">
        <Button onClick={addWrapContainer}>Create</Button>
     </div>
    </div>
  )
}

export default WrapContainerModal;