// React
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

//context
import ModalContext from "../store/ModalContext";
import AuthContext from "../store/AuthContext";

// drag and drop
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// MUI
import { TableCell, TableContainer, Table, TableRow, TableBody, TableHead, Paper, Button } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';

// Assets
import Additional from "../assets/img/additional-form.svg";
import Start from "../assets/img/start.svg";
import Assessment from "../assets/img/assessment.svg";
import Details from "../assets/img/details-form.svg";
import Results from "../assets/img/results-page.svg";
import Invite from "../assets/img/invite.svg";
import InviteThankYou from "../assets/img/invite-thanks.svg";
import Dashboard from "../assets/img/dashboard.svg";
import { useContext } from "react";
import BuilderContext from "../store/BuilderContext";


const Pages = () => {

  // misc
  const { id }     = useParams();
  const modalCtx   = useContext(ModalContext)
  const authCtx    = useContext(AuthContext)
  const builderCtx = useContext(BuilderContext);

  // States
  const [pages, setPages] = useState([]);
  const [assessment, setAssessment] = useState([]);

  // Effects
  useEffect(() => {
    getPages()
    getAssessment()
  }, [])

  useEffect(() => {
    getPages()
  }, [modalCtx])


  // Functions
  // Handle reordering
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(pages);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setPages(items);
    getPageOrder(items)
  };

  const handleModal = (modal, data) => {
    modalCtx.setDetails(modal, data)
    modalCtx.openModal()
  }

  const getPageOrder = (pages) => {

    let tempPageOrder = {
      pageOrder: []
    }

    pages?.map((item, index) => {
      return tempPageOrder?.pageOrder?.push({ pageId: item.id, orderIndex: index + 1 })
    })

    reOrderEpisodes(tempPageOrder)
  }

  const decideImage = (pageType) => {
    switch (pageType) {
      case 'start':
        return <img src={Start} alt="page" srcset="" height={45} />
      case 'generic':
        return <img src={Start} alt="page" srcset="" height={45} />
      case 'assessment':
        return <img src={Assessment} alt="page" srcset="" height={45} />
      case 'additional':
        return <img src={Additional} alt="page" srcset="" height={45} />
      case 'details':
        return <img src={Details} alt="page" srcset="" height={45} />
      case 'results':
        return <img src={Results} alt="page" srcset="" height={45} />
      case 'invite':
        return <img src={Invite} alt="page" srcset="" height={45} />
      case 'invite-thank-you':
        return <img src={InviteThankYou} alt="page" srcset="" height={45} />
      case 'dashboard':
        return <img src={Dashboard} alt="page" srcset="" height={45} />

      default:
        return;
    }
  }

  // API request
  // Get pages
  const getPages = async () => {

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/assessment/${id}/pages`)
      const data = await res.json();

      if (data.status === "success") {
        builderCtx.updatePages(data.pages?.sort((a, b) => a.order_no - b.order_no))
        setPages(data.pages?.sort((a, b) => a.order_no - b.order_no));
      }
    } catch (error) {
      console.log('error getting pages : ', error)
    }
  }

  const getAssessment = async () => {

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/admin/assessment/${id}`, {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
      })
      const data = await res.json();

      if (data.status === "success") {
        setAssessment(data.details);
        console.log(data)
      }
    } catch (error) {
      console.log('error getting pages : ', error)
    }
  }

  const reOrderEpisodes = async (order) => {

    try {

      const res = await fetch(`${process.env.REACT_APP_API_URI}/v3/admin/assessment/${id}/page/order`, {
        method: 'PUT',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', Authorization: "Bearer " + authCtx.token },
        body: JSON.stringify(order)
      })
      const data = await res.json()

      if (data.status === 'success') {
        // getPages()
        console.log('success', data)
      }

    } catch (error) {
      console.log(error)

    }

  }


  return (
    <div className="assessment-page" >
      <div className="page-header">
        <h1>Assessments &gt; {assessment?.title} &gt;  Pages</h1>
        <Button className="create-btn" onClick={() => handleModal('create-assessment-page', id)} >Create</Button>
      </div>
      <TableContainer component={Paper} style={{ borderRadius: "8px" }}>
        <Table>

          <TableHead style={{ background: '#f1f1f1' }} >
            <TableRow>
              <TableCell style={{ width: 70 }} >Order No.</TableCell>
              <TableCell style={{ width: 50 }} ></TableCell>
              <TableCell align="left">Page Type</TableCell>
              <TableCell align="center">Page Type ID</TableCell>
              <TableCell style={{ width: 70 }} align="right"></TableCell>
            </TableRow>
          </TableHead>

          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="table">
              {(provided) => (
                <TableBody {...provided.droppableProps} ref={provided.innerRef}>
                  {
                    pages?.map((row, index) => (
                      <Draggable key={row.id} draggableId={row.id.toString()} index={index}>
                        {(provided) => (
                          <TableRow {...provided.draggableProps} ref={provided.innerRef}>

                            <TableCell style={{ width: 70 }} align="center" ><span>{index + 1}</span></TableCell>
                            <TableCell style={{ width: 50 }}  {...provided.dragHandleProps}><DragIndicatorIcon /> </TableCell>
                            <TableCell align="left">
                              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem', textTransform: 'capitalize' }}>
                                {decideImage(row?.page_type)}
                                {row.page_type?.split('-').join(" ")}
                              </div>
                            </TableCell>
                            <TableCell align="center">{row.id}</TableCell>
                            <TableCell style={{ width: 70 }} align="right">
                              <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                                <DeleteIcon onClick={() => handleModal('delete-assessment-page', { id, pageId: row.id })} id={row.id.toString()} style={{ cursor: "pointer" }} />
                                <AppRegistrationIcon style={{ cursor: "pointer" }} />
                              </div>
                            </TableCell>

                          </TableRow>
                        )}
                      </Draggable>
                    ))
                  }
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>

        </Table>
      </TableContainer>
    </div>
  );

}

export default Pages;