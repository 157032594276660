import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, NavLink } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';

import DrawerContext from '../store/DrawerContext.js';

import AssessmentsLeadersTable from "../Components/Tables/AssessmentsLeadersTable";

const AssessmentsLeaders = () => {
  const { id } = useParams();
	const drawerCtx = useContext(DrawerContext);

	const [isLoading, setIsLoading] = useState(true);
	const [title, setTitle] = useState("");
	const [url, setUrl] = useState("");
	const [inviteCount, setInviteCount] = useState(0);
	const [invitedSomeCount, setInvitedSomeCount]	= useState(0);
	const [invitedAllCount, setInvitedAllCount]	= useState(0);
	const [invitedNoneCount, setInvitedNoneCount]	= useState(0);
	const [notInvitedCount, setNotInvitedCount]	= useState(0);
	const [leaders, setLeaders] = useState([]);

	useEffect(() => {

		fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/statistics/leaders', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			setTitle(data.details.title);
			setInviteCount(data.details.invited);
			setInvitedSomeCount(data.details.invitedSome);
			setInvitedAllCount(data.details.invitedAll);
			setInvitedNoneCount(data.details.invitedNone);
			setNotInvitedCount(data.details.notInvited);
			setLeaders(data.leaders);
			setUrl(data.details.url);
			setIsLoading(false);
		});

	}, []);

	return (
		<div className="container statistics">
			<header>
				<div data-aos="fade-right">
				{ isLoading ? <h1><NavLink to="/assessments">Assessments</NavLink> Leaders</h1> : <h1><NavLink to="/assessments">Assessments</NavLink> &#8250; {title}'s Leaders ({leaders.length})</h1> }
				</div>
			</header>			

			{ isLoading ? <CircularProgress /> :
				<>
					<div className="stats-container">
						<div className="stats-box">
							<span className="count" data-aos="fade-up">{ inviteCount }</span>
							<span className="info">Leaders who invited team members</span>
						</div>

						<div className="stats-box">
							<span className="count" data-aos="fade-up" data-aos-delay="100">{ invitedSomeCount }</span>
							<span className="info">Leaders who invited team members & some team members took the assessment</span>
						</div>

						<div className="stats-box">
							<span className="count" data-aos="fade-up" data-aos-delay="200">{ invitedAllCount }</span>
							<span className="info">Leaders who invited team members & ALL team members have taken the assessment</span>
						</div>

						<div className="stats-box">
							<span className="count" data-aos="fade-up" data-aos-delay="300">{ invitedNoneCount }</span>
							<span className="info">Leaders who invited team members, and who do not have a single team member who has taken the assessment</span>
						</div>

						<div className="stats-box">
							<span className="count" data-aos="fade-up" data-aos-delay="400">{ notInvitedCount }</span>
							<span className="info">Leaders who did not invite team members</span>					
						</div>
					</div>
					<AssessmentsLeadersTable leaders={leaders} url={url} />
				</>

			}
			
		</div>
	);

}
export default AssessmentsLeaders;