import React, { useContext, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import cross from '../../../src/assets/img/cross.png';
import AuthContext from '../../store/AuthContext.js';



const DeleteChartsModal = (props) => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [isDeleting, setIsDeleting] = useState(false);
  const [errorWhileDeleting, setErrorWhileDeleting] = useState(false);

  const assessmentId = props?.details?.assessmentId;
  const chartId = props?.details?.id;

  console.log("DeleteChartsModal props", props);

  const handleDeleteChart = () => {

    setIsDeleting(true);
    setErrorWhileDeleting(false);
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/chart/' + chartId, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    }).then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        setIsDeleting(false);
        setErrorWhileDeleting(false);
        modalCtx.setDetails(null);
        modalCtx.closeModal();
        props.details.setIsUpdating(!props.details.isUpdating)
        props.details.setTabValue(0);
      } else {
        console.error("failed to delete chart...");
        setIsDeleting(false);
        setErrorWhileDeleting(true);
      }
    })
    .catch((err) => {
      console.error("failed to delete chart...", err);
      setIsDeleting(false);
      setErrorWhileDeleting(true);
    });
  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  return (
    <div className='modal'>
      <div className="delete-popup-content">
        <div className="main-content">
          <div className="heading-container">
            <div className="heading">
              Are you sure you want to delete this Chart?
            </div>
            <img src={cross} onClick={() => handleCloseModal()} alt="cross" />
          </div>

          {errorWhileDeleting && <div className="error-message">
            <p style={{ color: "crimson", margin: "5px auto" }}>Something went wrong. Please try again later.</p>
          </div>}

          <button className="delete-btn" disabled={isDeleting} onClick={() => handleDeleteChart()}>
            {isDeleting ? <CircularProgress /> : "Delete"}
          </button>

          <button className="cancel-btn" onClick={() => handleCloseModal()}>Cancel</button>

        </div>
      </div>
    </div>
  )
}

export default DeleteChartsModal;
