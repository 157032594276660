import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';

const LetterSpacing = (props) => {
  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentLetterSpacing = currentBlockStyles?.width?.match(/[a-zA-Z]+|[0-9]+|[%]/g);

  const values = [
    { value: "px", label: "px" },
    { value: "inherit", label: "inherit" },
    { value: "normal", label: "normal" },
    { value: "unset", label: "unset" },
  ];

  const [letterSpacing, setLetterSpacing] = useState(currentLetterSpacing || "normal");
  const [unit, setUnit] = useState("normal");
  const [finalValue, setFinalValue]   = useState("");

  const handleUnitChange = (e) => {
    setUnit(e.target.value);
  };

  useEffect(() => {
    if (letterSpacing === "normal") {
      setFinalValue("normal");
    } else if (letterSpacing && letterSpacing !== "auto" && unit) {
      if (letterSpacing !== currentLetterSpacing?.[0] || unit !== currentLetterSpacing?.[1]) {
        setFinalValue(`${letterSpacing}${unit}`);
      }
    }
  }, [currentLetterSpacing, letterSpacing, unit]);

  useEffect(() => {
    let lastFinalVal = "";
    if(!currentLetterSpacing?.[1]) lastFinalVal = "normal";
    else lastFinalVal = currentLetterSpacing?.[0] + currentLetterSpacing?.[1];
    // console.log("lastFinalVal", lastFinalVal);
    // console.log("width unit", width, unit);
    const timer = setTimeout(() => {
      if (finalValue && finalValue !== lastFinalVal) {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            let newStyles = { ...block.styling };
            newStyles.letterSpacing = finalValue;
            block.styling = { ...newStyles };
            return block;
          } else return block;
        }))
      }
    }, 500);
    return () => clearTimeout(timer);

  }, [blockId, finalValue]);
  

  return (
    <div className="letter-spacing tool">
      {props.deleteModal}
      <TextField className="letter-spacing-input styling-input"
        type="number" variant="filled"
        InputLabelProps={{ shrink: true }}
        placeholder={currentContentBlock?.styling?.letterSpacing + ''}
        value={letterSpacing} onChange={(e) => setLetterSpacing(e.target.value)}
      />

      <TextField className="letter-spacing-unit styling-select"
        select variant="standard" style={{ width: "120px" }}
        InputProps={{ disableUnderline: true }}
        placeholder={currentContentBlock.styling?.letterSpacing + ''}
        value={unit} onChange={handleUnitChange}
      >
        {values?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
};

export default LetterSpacing;
