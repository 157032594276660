import React, { useState, useEffect, useContext, useRef } from "react";
import { Editor } from '@tinymce/tinymce-react';
import BuilderContext from "../../../store/BuilderContext";
import AuthContext from "../../../store/AuthContext";

const ParagraphWidget = (props) => {
  const blockId = props?.blockId;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);

  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlock = contentBlocks?.find(block => block.id === blockId);
  const currentBlockStyles = currentBlock?.styling;

  const editorRef = useRef(null);
  const [value, setValue] = useState(currentBlock?.properties?.html);
  const [editorHtml, setEditorHtml] = useState(currentBlock?.properties?.html);

  useEffect(() => {
    if (!value && currentBlock?.properties?.html) setValue(currentBlock?.properties?.html);
    if (!editorHtml && currentBlock?.properties?.html) setEditorHtml(currentBlock?.properties?.html);
  }, [currentBlock?.properties?.html, currentBlock?.properties?.text, editorHtml, value]);

  useEffect(()=>{
    console.log("props",props)
  },[props])

  useEffect(() => {
    const timer = setTimeout(() => {
      updateContentBlocks((prevBlocks) => prevBlocks?.map(block => {
        if (block.id === blockId) {
          block.properties.html = editorHtml;
          block.properties.text = value;
        }
        return block;
      }))
    }, 1000);

    updateText()
    return () => clearTimeout(timer);
  }, [blockId, editorHtml, value]);

  const updateText = () => {
    if (editorRef.current) {
      let html = editorRef.current.getContent();
      let div = document.createElement("div");
      div.innerHTML = html;
      var text = div.textContent || div.innerText || "";
      let payload = {
        id: blockId,
        text: text,
        html: html
      }
      console.log('paragraph widget payload',payload)
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/content/paragraph', {
        method: 'PUT', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
        .then(data => {
          if (data.status === "success") {
            console.log("paragraph success",data)
          }
        }).catch((err) => {
          console.error("Failed to update paragraph text...", err);
        })
    }
  };

  const handleUpdateTextBlur = () => {
    updateText();
  };

  if (currentBlock?.properties?.text) {
    return (
      <>
        <Editor className="tiny-mce-editor"
          apiKey='hz2zqvulixa2qdtp85x6mth4a6m1zq6e7qmckxi7gof0gds4'
          onInit={(evt, editor) => editorRef.current = editor}
          initialValue={value}
          init={{
            setup: (editor) => {
              editor.on('blur', handleUpdateTextBlur);
            },
            menubar: false,
            inline: true,
            plugins: [
              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
              'insertdatetime'
            ],
            toolbar: 'undo redo | bold italic forecolor | alignleft aligncenter alignright alignjustify',
            toolbar_mode: "scrolling",
            content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; }'
          }}
        />
      </>
    )
  }

  return null;
}

export default ParagraphWidget;
