import React, { useContext, useState } from "react";
import { Button } from '@material-ui/core';
import { CircularProgress} from "@mui/material";
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { getCentralDateTime } from "../../globalFunctions.js";
import UsersContext from "../../store/UsersContext.js";

const DeleteInvite = (props) => {

  console.log("DeleteInvite props", props);
  // console.log("urlDetails", urlDetails);

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const usersCtx = useContext(UsersContext);

  const [isLoading, setIsLoading] = useState("");
  const [error, setError]         = useState(false);
  const referralId = props?.details?.id;
  const referral = props?.details;

  console.log(referral);
  
  const deleteUserFromCtx = (userId, parentUserId) => {
    if (userId && parentUserId) {
      const newUsers = usersCtx.allUsers?.filter(u => u.id !== userId)?.map(u => {
        if (u.id === parentUserId) {
          const updatedReferrals = u.referrals?.filter(ref => ref.id !== userId) || [];
          return { ...u, referrals: updatedReferrals, };
        }
        return u;
      }) || [];
      usersCtx.setAllUsers(newUsers);
    }
  }
  
  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const assessmentCompleted = referral?.status?.toLowerCase() === "completed" || referral?.status?.toLowerCase() === "complete" || referral?.assessmentCompleted?.toLowerCase() === "yes";
  console.log("assessmentCompleted", assessmentCompleted);

  const handleDeleteInvite = (e) => {
    e.preventDefault();
    setIsLoading(true);

    let hash = referral?.id || referral?.userId;
    let url = process.env.REACT_APP_API_URI + '/v3/admin/user/' + hash;
    if (assessmentCompleted) {
      hash = referral?.uniqueId || referral?.hash;
      url = process.env.REACT_APP_API_URI + '/v3/admin/user/assessment/' + hash;
    }
    
    const userId = referral?.id || referral?.userId;
    const parentUserId = referral?.parentUserId || referral?.leaderUserId;

    if (hash)
    fetch(url, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        console.log("Successfully deleted invite/user...");
        deleteUserFromCtx(userId, parentUserId);
        setIsLoading(false);
        setError(false);
        handleCloseModal();
        // props?.details.setToggleRefresh((prev) => !prev);
        // modalCtx.setPageUpdate({ page: "userDetailsDrawer" });
      } else {
        setError(true);
        setIsLoading(false);
        console.log("Failed to delete invite/user...");
      }
    })
    .catch((err) => {
      console.error("Failed to delete invite/user...", err);
      setError(true);
      setIsLoading(false);
    });

  }

  return (
    <div className='modal'>
      {isLoading ? 
      <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center", minWidth: "350px" }}><CircularProgress /></section> 
      :
      error ? <>
        <p style={{ margin: "5px auto 15px" }}>An error occurred while trying to delete this invite. Try again?</p>
        <div className="footer" style={{ margin: "15px auto 0" }}>
          <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
          <Button type="submit" className="confirm-btn" onClick={(e) => handleDeleteInvite(e)}>Delete</Button>
        </div>
      </> :
      <>
        <p style={{ margin: "5px auto 15px" }}>Are you sure you want to delete this invite?</p>
        <p style={{ margin: "5px auto 30px" }}>{(referral?.name || (referral?.firstName + " " + referral?.lastName)) + " at " + getCentralDateTime(referral?.createdAt || referral?.date) + " (CT)"}</p>
        <div className="footer" style={{ margin: "15px auto 0" }}>
          <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
          <Button type="submit" className="confirm-btn" onClick={(e) => handleDeleteInvite(e)}>Delete</Button>
        </div>
      </>
    }
    </div>
  )
}


export default DeleteInvite;

