import React from 'react'
import CreateAssessmentSectionDrawer from './CreateAssessmentSectionDrawer'

export default function AddCategoryDrawer(props) {
  console.log(props.userDetails)
  return (
    <div className='add-category-drawer'>
        <CreateAssessmentSectionDrawer userDetails={props.userDetails}/>
    </div>
  )
}
