import { useContext, useEffect, useState } from "react";
import { ChromePicker } from 'react-color';
import ColorPicker, { useColorPicker } from 'react-best-gradient-color-picker';
import { TextField } from '@mui/material';
import AuthContext from '../../../../store/AuthContext';
import BuilderContext from '../../../../store/BuilderContext';

const BackgroundColor = (props) => {
  const { blockId, propertyName, name = "" } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks } = builderCtx;

  const currentBlock = contentBlocks?.find(block => block.id === blockId);
  const currentBlockStyles = currentBlock?.styling;
  const currentBgColor = currentBlockStyles?.background || "#ffffff";

  const [bgColorHex, setBgColorHex] = useState(currentBlockStyles?.background || "#ffffff");
  const [finalValue, setFinalValue] = useState(currentBlockStyles?.background || "#ffffff");
  const [pickerOpen, setPickerOpen] = useState(false);
  const [color, setColor]           = useState(currentBlockStyles?.background || "#ffffff");

  useEffect(() => {
    const timer = setTimeout(() => {
      setFinalValue(color);
    }, 200);
    return () => clearTimeout(timer);
  }, [color]);

  useEffect(() => {
    const lastFinalVal = currentBgColor || "#ffffff";
    if (finalValue !== lastFinalVal) {
      updateContentBlocks(prevBlocks => prevBlocks?.map(block => {
        if (block.id === blockId) {
          if (propertyName) {
            return { ...block, properties: { ...block.properties, [propertyName]: finalValue } };
          } else {
            const payload = {
              id: blockId,
              value: finalValue,
              screenType: props?.screenType
            };

            fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/background', {
              method: 'PUT',
              mode: 'cors',
              headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authCtx.token
              },
              body: JSON.stringify(payload)
            })
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
                // console.log("updated backgroundColor", data);
              }
            }).catch((err) => {
              console.error("Failed to update backgroundColor...", err);
            });
            return { ...block, styling: { ...block.styling, background: finalValue } };
          }
        } else {
          return block;
        }
      }));
    }
  }, [authCtx.token, blockId, finalValue, propertyName, currentBgColor]);

  return (
    <div className="bgcolor tool">
      {props.deleteModal}
      <TextField
        className="bgcolor-input styling-input"
        type="text"
        variant="filled"
        InputLabelProps={{ shrink: true }}
        placeholder="Type in hex value or select color"
        value={bgColorHex}
        onChange={(e) => {
          setBgColorHex(e.target.value);
          setFinalValue(e.target.value);
        }}
      />
      <input
        type="color"
        className="color-box"
        value={bgColorHex}
        onClick={() => setPickerOpen(!pickerOpen)}
        onChange={(e) => console.log("onchange", e.target.value)}
      />
      {pickerOpen && <ColorPicker value={color} onChange={setColor} />}
    </div>
  );
};

export default BackgroundColor;
