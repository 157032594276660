import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const FlexDirection = (props) => {
  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentFlexDirection = currentBlockStyles?.flexDirection || "row";

  const [flexDirection, setFlexDirection] = useState(currentFlexDirection);

  const handleFlexDirectionChange = (e) => {
    const value = e.target.value;
    setFlexDirection(value);

    const payload = {
      id: blockId,
      value: value,
      screenType: props?.screenType
    };
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/flexDirection', {
      method: 'PUT',
      mode: 'cors',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer ' + authCtx.token 
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        console.log("Updating flexDirection payload", payload);
      }
    })
    .catch((err) => {
      console.error("Failed to update flexDirection...", err);
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (flexDirection !== currentFlexDirection) {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            return { ...block, styling: { ...block.styling, flexDirection: flexDirection } };
          } else {
            return block;
          }
        }));
      }
    }, 500);
    
    return () => clearTimeout(timer);
  }, [blockId, flexDirection]);

  return (
    <div className="flex-direction tool">
      {props.deleteModal}
      <TextField
        id="select-flex-direction"
        className="flex-direction-select styling-select"
        select
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={flexDirection}
        onChange={handleFlexDirectionChange}
      >
        <MenuItem value="row">Row</MenuItem>
        <MenuItem value="row-reverse">Row Reverse</MenuItem>
        <MenuItem value="column">Column</MenuItem>
        <MenuItem value="column-reverse">Column Reverse</MenuItem>
      </TextField>
    </div>
  );
};

export default FlexDirection;