import React, { useContext, useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import "../App.scss";

import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { CircularProgress } from '@mui/material';
import AuthContext from '../store/AuthContext.js';
import { Delete } from "@material-ui/icons";
import ModalContext from "../store/ModalContext.js";

// ..
AOS.init();

// import '../App.scss';

const AssessmentDashboardPage = () => {
  // const drawerCtx = useContext(DrawerContext);

  // function toggleDrawerHandler(drawer, user) {
  //   drawerCtx.openDrawer();
  //   drawerCtx.setDetails(drawer, user);
  // }
  const { id }                              = useParams();
  const authCtx                             = useContext(AuthContext);
  const modalCtx                            = useContext(ModalContext);                              

  const [assessDetails, setAssessDetails]   = useState([]);
  const [isLoading, setIsLoading]           = useState(false);
  const [assessmentStarted, setAssessmentStarted]         = useState(0);
  const [assessmentCompleted, setAssessmentCompleted]     = useState(0);
  const [teamMembersInvited, setTeamMembersInvited]       = useState(0);
  const [teamMembersCompleted, setTeamMembersCompleted]   = useState(0);
  const [isExporting, setIsExporting]                     = useState(false);
  const [allUsers, setAllUsers]                           = useState([]);
  const [members, setMembers]                             = useState([]);


  const exportCSV = () => {

    setIsExporting(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/export', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({users: allUsers}),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setIsExporting(false);
      if (data.status === "success") {
        window.location.href = data.exportURL
      } else {
        console.log("failed to delete");
      }
    })
    .catch(err => {
      if(err) {
        console.log(err);
        setIsExporting(false);
      }
    });

  }

  useEffect(() => {
    const getData = () => {
      setIsLoading(true);
      let options = {
        method: 'GET',
        mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      }

      const envURI = process.env.REACT_APP_API_URI;

      Promise.all([
        fetch(envURI + '/v1/admin/assessment/' + id, options).then(response => response.json()),
        fetch(envURI + '/v1/admin/assessment/' + id + '/statistics', options).then(response => response.json()),
        // fetch(envURI + '/v1/admin/assessment/' + id + '/statistics/members', options)
        //   .then(response => response.json()),
        // fetch(envURI + "/v1/assessment/" + id + "/assessments-started", options)
        //   .then(response => response.json()),
        // fetch(envURI + "/v1/assessment/"+id+"/assessments-completed", options)
        //   .then(response => response.json()),
      ]).then((data) => {
        console.log("API data\n",data);
  
        if(data[0].status === "success") {
          setAssessDetails(data[0]?.details);
        }
        if(data[1].status === "success") {
          setAssessmentStarted(data[1]?.leadersStarted);
          setAssessmentCompleted(data[1]?.leadersCompleted);
          setTeamMembersInvited(data[1]?.membersInvited);
          setTeamMembersCompleted(data[1]?.membersCompleted);
          setAllUsers(data[1]?.allUsers);
        }
        // if(data[2].status === "success") {
        //   setMembers(data[2]?.members);
        // }
        // if(data[3].status === "success") {
        //   setAssessmentStarted(data[3]?.data[0]?.assessment_started);
        // }
        // if(data[4].status === "success") {
        //   setAssessmentCompleted(data[4]?.data[0]?.assessment_completions);
        // }
        setIsLoading(false);
      }).catch(err => console.error(err));
    }

    getData();

  }, [authCtx.token, id]);
  
  const leaderCompletionRate = Math.round((assessmentCompleted/assessmentStarted) * 100);
  const membersWhoCompleted = members?.filter((obj) => obj.uniqueId);
  const memberCompletionRate = Math.round((teamMembersCompleted/teamMembersInvited) * 100);

  console.log("assessDetails", assessDetails);

  let membersOrReferrals = assessDetails?.assessmentType === "team" ? "Team members" : "Referrals";
  let invitedText = assessDetails?.assessmentType === "team" ? "Team members invited" : "Referrals invited";

  const statsCardsLeaders = [
    { num: assessmentStarted || 0, num2: "", text: "Assessments started" },
    { num: assessmentCompleted || 0, num2: "", text: "Assessment completions" },
    { num: leaderCompletionRate ? leaderCompletionRate + "%" : "-", num2: "", text: "Completion rate" }
  ]

  const statsCardsMembers = [
    { num: teamMembersInvited || 0, num2: "", text: invitedText },
    { num: teamMembersCompleted || 0, num2: "", text: "Assessment completions" },
    { num: memberCompletionRate ? memberCompletionRate + "%" : "-", num2: "", text: "Completion rate" }
  ]

  if (isLoading) {
		return <section className="loader-section"><CircularProgress /></section>
	}



  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  return (
    <>
    {/* <div className="assessment-name">
    <div data-aos="fade-right">
      <h1><a style={{ textDecoration: "none", }}  href={"/assessments/" + id + "/dashboard"}>{assessDetails.title} Assessment</a></h1>
      {assessDetails?.url && <a className="assessment-link" href={assessDetails?.url + "/assessment/"} target="_blank" rel="noopener noreferrer">{assessDetails?.url + "/assessment/"}</a>}
    </div>
    <div data-aos="fade-down" data-aos-delay="400">
      { isExporting ? <a className="header-cta"><CircularProgress style={{'color': '#fff'}} size={16} /></a> :
        <a className="header-cta" onClick={() => { exportCSV(); }}><SystemUpdateAltIcon /> Download</a>
      }
    </div>
  </div> */}
    <div className="container assessment-dashboard-page">
      
      <div className="dashboard-table">

        <h1>Leaders</h1>
        <div className="statistics-cards">
          {statsCardsLeaders?.map((card, index) => {
            return (
              <div key={index} className="card">
                <p className="big-text">{card.num}</p>
                <p className="percent-text">{card.num2}</p>
                <p className="info-text">{card.text} {index == 0 ? <div className="delete-icon-container" onClick={()=>{toggleModalHandler("delete-abandoned-assessments",{id})}}><Delete/></div> : null }</p>
              </div>
            )
          })}
        </div>

        <h1>{membersOrReferrals}</h1>
        <div className="statistics-cards">
          {statsCardsMembers?.map((card, index) => {
            return (
              <div key={index} className="card">
                <p className="big-text">{card.num}</p>
                <p className="percent-text">{card.num2}</p>
                <p className="info-text">{card.text}</p>
              </div>
            )
          })}
        </div>

      </div>
    </div>
    </>
  );
};
export default AssessmentDashboardPage;
