import React, { useContext, useState } from 'react'
import AuthContext from '../../store/AuthContext';
import ModalContext from '../../store/ModalContext';
import cross from '../../../src/assets/img/cross.png';
import { ChromePicker, SketchPicker } from 'react-color';


const ColorSelectionModal = (props) => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [colorHex, setColorHex] = useState('#212A42');
  const [colorRgb, setColorRgb] = useState({ r: 120, g: 20, b: 180 });

  console.log("ColorSelectionModal props", props);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const handleApply = () => {
    const combinedColorHex = colorHex;
    props.details.handleColorChange(combinedColorHex)
    handleCloseModal()
  };

  const handleColorChange = (color) => {
    setColorHex(color.hex);
    setColorRgb(color.rgb);
  }


  return (
    <div className="modal color-selection-container">
      <>
        {/* <ChromePicker color={colorRgb} onChange={(color) => handleColorChange(color)} /> */}
        <SketchPicker color={colorRgb} onChange={(color) => handleColorChange(color)} />
        <button className="create-btn" onClick={handleApply} >Apply</button>
      </>
    </div>
  )
}

export default ColorSelectionModal;

