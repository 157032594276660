
import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Checkbox, FormControlLabel, Radio, RadioGroup, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SubDrawerContext from "../../store/SubDrawerContext";
import ModalContext from "../../store/ModalContext";
import AddIcon from '../../assets/img/addIcon.png';
import dropIcon from '../../assets/img/dropIcon.png';
import AuthContext from "../../store/AuthContext";
import deleteIcon from '../../assets/img/Question Builder/DelIcon.svg'



const AddBarGraph = (props) => {
  const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);
  const subDrawerCTx = useContext(SubDrawerContext);
  const modalCtx = useContext(ModalContext);
  const [duration, setDuration] = useState('')
  const [isLoading, setIsLoading] = useState(false);

  const assessmentId =props?.details.assessmentId;


  const initialData = [
    { label: 'Segment?', variable: 'segment', color: '#212A42 ' }
  ];

  const [data, setData] = useState(initialData);

  const handleTitleChange = (index, newValue) => {
    const newData = [...data];
    newData[index].label = newValue;
    setData(newData);
  };
  const handleCountChange = (index, newValue) => {
    const newData = [...data];
    newData[index].variable = newValue;
    setData(newData);
  };
  const handleAddChart = () => {
    const newChart = { label: 'Segment?', variable: 'segment', color: '#000000' };
    setData([...data, newChart]);
  };

  const closeDrawer = () => {
    drawerCtx.closeDrawer();
  }
  const openColorModal = () => {
    modalCtx.openModal();
    modalCtx.setDetails("color-selection-modal",);

  }

  const fncAddBarGraph = () => {
    const payload = {
      title: " ",
      chartType: "bar-chart",
      size: props.details?.size,
      timeFrame: new Date(),
      chartValues: data
    }
    if (data.length) {
      setIsLoading(true)
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/chart', {
        method: 'POST', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
        .then(data => {
          setIsLoading(false)
          if (data.status === "success") {
            console.log(" successfully", data);
            closeDrawer()
          }
        }).catch((err) => {
          console.error("Failed to update s...", err);
          setIsLoading(false)

        });
    }
  }


  const handleRemove = (index) => {
    data.splice(index, 1)
    setData([...data]);
  }
  return (
    <div className="drawer add-bargraph-section">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
          Add Bar Graph
        </h1>
      </header>
      <div className="items">
        <div className="label">Choose Bar Graph Duration</div>
        <div className="row-section">
          <RadioGroup aria-labelledby="row-radio-buttons" name="row-buttons" className='size-chart-sec'
            value={duration}
            onChange={(e) => { setDuration(e.target.value) }}>
            <FormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
            <FormControlLabel value="Monthly" control={<Radio />} label="Monthly" />

          </RadioGroup>
        </div>
      </div>
      <div className="bar-contents">
        <div className="bar-section">
          {data.map((item, index) => (
            <div key={index} className="bar-segments">
              <div className="form-group">
                <div className="delIcon" style={{ marginRight: '0px' }} onClick={() => { handleRemove(index) }} >
                  <img src={deleteIcon} alt="" />
                </div>
                <label htmlFor="title">Segment Title</label>
                <input type="text" className="text-box" value={item.label} onChange={(e) => handleTitleChange(index, e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="count">Add the Count</label>
                <input type="text" className="text-box" value={item.variable} onChange={(e) => handleCountChange(index, e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="count">Choose Color</label>
                <div className="box-container">
                  <input
                    type="color"
                    className="color-box"
                    defaultValue={item.color}
                    onClick={(e) => {
                      e.preventDefault();
                      openColorModal()
                    }}
                  />
                  <img className='drop-icon' src={dropIcon} alt='drop' onClick={(e) => {
                    e.preventDefault();
                    openColorModal()
                  }} />
                </div>
              </div>
            </div>
          ))}
          <div className="bar-segments add-more-bargraphs">
            <div className="add-graph" onClick={handleAddChart}>
              <img src={AddIcon} alt='add' />
              <h1>Add More Segments</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-btns">
        <Button className="cancel-btn" onClick={closeDrawer}>Cancel</Button>
        {isLoading ?
          <Button className="cancel-btn"><CircularProgress style={{ 'color': '#122845' }} size={25} /></Button> :
          <Button className="save-btn" onClick={fncAddBarGraph}> Save</Button>
        }


      </div>
    </div>


  )
}

export default AddBarGraph