import { useState, useEffect, useRef, useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Switch, Button, FormGroup, CircularProgress } from '@material-ui/core';
// import { useParams } from "react-router-dom";
import plusIcon from '../../assets/img/Question Builder/PlusIcon.svg'
import { Delete as DeleteIcon } from '@material-ui/icons';
import AuthContext from '../../store/AuthContext';
import { useParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import delIcon from '../../assets/img/Question Builder/DelIcon.svg'
import SubDrawerContext from '../../store/SubDrawerContext';

const AddQuestionDrawer = props => {
  console.log(props);

  const questionRef = useRef(null);
  
  const { id }          											= useParams();
  const sections                              = props?.questionDetails?.sections;
  const clientId                              = props?.questionDetails?.clientId;  
  const authCtx                               = useContext(AuthContext)
  const subdrawerCtx                          = useContext(SubDrawerContext)
  const [isSubmitting, setIsSubmitting]       = useState(false);
  const [sectionName, setSectionName]         = useState("");
  const [typeNewQuestion, setTypeNewQuestion] = useState("");
  const [responseType, setResponseType]       = useState("");
  const [mandatory, setMandatory]             = useState(true);
  const [notApplicable, setNotApplicable]     = useState(false);
  const [applicable, setApplicable]           = useState(true);
  const [showAnswers, setShowAnswers]         = useState(true);
  const [questions, setQuestions]                 = useState([]);
  const [answers, setAnswers]                 = useState([]);
  const [isAdditional, setIsAdditional]       = useState(false);
  const [newQuestion, setNewQuestion]         = useState(null);
  const [pages, setPages]                     = useState([]);
  const [pageName, setPageName]               = useState("");
  const [pageId, setPageId]                   = useState("");

  useEffect(() => {

    let sectionId = sections?.find((section) => section.section === sectionName)?.id;
    // console.log("sectionId", sectionId);
    // console.log("responseType", responseType);
    // console.log("mandatory", mandatory);
    // console.log("answers", answers);
    // console.log("isAdditional", isAdditional);
    // let determineMandatory = (mandatory === true || mandatory === "yes") ? true : false;
    let determineMandatory = mandatory ? "yes" : "no"
    let determineNotApplicable = notApplicable ? "yes" : "no"

   
    setNewQuestion((prev) => {

      return {
        // ...prev,
        clientId: clientId,
        sectionId: sectionId || 0,
        responseType: responseType,
        isAdditional: isAdditional,
        question: typeNewQuestion,
        // mandatory: mandatory,
        mandatory: determineMandatory,
        notApplicable: determineNotApplicable,
        notApplicableValue :"",
        answerType:responseType,
        answers: answers,
        pageId: pageId,
        variable: ""
      }

    })
  }, [answers, clientId, isAdditional, mandatory, responseType, sectionName, sections, typeNewQuestion, pageId, notApplicable]);
  console.log("jsonBody", newQuestion);
  
  useEffect(() => {

    // let answer = [
    //   {
    //     id: 1,
    //     orderNo: 1,
    //     answerDesktop: "Strongly Disagree",
    //     answerMobile: "Strongly Disagree",
    //     value: 1
    //   },
    //   {
    //     id: 2,
    //     orderNo: 2,
    //     answerDesktop: "Disagree",
    //     answerMobile: "Disagree",
    //     value: 2
    //   },
    //   {
    //     id: 3,
    //     orderNo: 3,
    //     answerDesktop: "Neutral",
    //     answerMobile: "Neutral",
    //     value: 3
    //   },
    //   {
    //     id: 4,
    //     orderNo: 4,
    //     answerDesktop: "Agree",
    //     answerMobile: "Agree",
    //     value: 4
    //   },
    //   {
    //     id: 5,
    //     orderNo: 5,
    //     answerDesktop: "Strongly Agree",
    //     answerMobile: "Strongly Agree",
    //     value: 5
    //   },
    // ]

    let answer = [{
      id: 1,
      orderNo: 1,
      answerDesktop: "",
      answerMobile: "",
      value: 0
    },
    {
      id: 2,
      orderNo: 2,
      answerDesktop: "",
      answerMobile: "",
      value: 0
    }]
    setAnswers(answer);

  }, [])

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 16,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(19px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 16,
      height: 16,
      borderRadius: 10,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const switchLabel = { inputProps: { 'aria-label': 'Mandatory Question Toggle Switch' } };

  const updateQuestionType = (value) => {
    setResponseType(value);
    if(value == "textarea") {
      setShowAnswers(false);
    } else {
      setShowAnswers(true);
    }
  }

  const addAnswer = () => {
    
    let id        = 2;
    if(answers.length > 0) {
      id          = answers[answers.length - 1].id + 1;
    }
    
    let answer = {
      id: id,
      orderNo: id,
      answerDesktop: "",
      answerMobile: "",
      value: 0
    }
    setAnswers([...answers, answer]);

  };  

  const deleteAnswer = (a) => {    
    setAnswers(answers.filter((answer) => {
      return answer.id !== a
    }));
  };

  const updateAnswerDesktop = (e, id) => {
    let newAnswers = [];
    answers.forEach(answer => {
      if(answer.id == id) {
				answer.answerDesktop = e.target.value;
			}
      newAnswers.push(answer);
    });
    setAnswers(newAnswers);
  }

  const updateAnswerMobile = (e, id) => {
    let newAnswers = [];
    answers.forEach(answer => {
      if(answer.id == id) {
				answer.answerMobile = e.target.value;
			}
      newAnswers.push(answer);
    });
    setAnswers(newAnswers);
    // let currentAnswer = answers.find(function (obj) { return obj.id == id; });
    // currentAnswer.answerMobile = e.target.value;
  }

  const updateAnswerValue = (e, id) => {
    let newAnswers = [];
    answers.forEach(answer => {
      if(answer.id == id) {
				answer.value = e.target.value;
			}
      newAnswers.push(answer);
    });
    setAnswers(newAnswers);
    // let currentAnswer = answers.find(function (obj) { return obj.id == id; });
    // currentAnswer.value = e.target.value;
  }

  const getPages = async () => {

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/assessment/${clientId}/pages`)
      const data = await res.json();

      if (data.status === "success") {
        setPages(data.pages?.sort((a, b) => a.order_no - b.order_no));
      }
    } catch (error) {
      console.log('error getting pages : ', error)
    }
  }

  const getPageName = (id) => {
    const page = pages.find(page => page.id === id);
    console.log('PageId getPageName ',page)
    setPageName(page?.page_type)
  };

  const updateQuestionsOrder = (data) =>{
    let newQuestionsPayload = {
      "questionOrder":
        data.map((item,index)=>{
          return {
            "questionId": item.id,
            "orderIndex": index+1
          }
        })
    } 

    console.log("payload--------------------",newQuestionsPayload)

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/question/update/order`, {
      method: 'PUT',
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authCtx.token 
      },
      body: JSON.stringify(newQuestionsPayload), // Convert the data object to a JSON string
    })
    .then((response) => response.json())
	  .then((data) => {
		console.log('Updated question order',data);
      if (data.status === "success") {
        console.log("Successfully updated the question order...");
        // questionOrderUpdateRender()
      }
	  })
	  .catch((err) => console.error("Failed to update the resource...", err));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsSubmitting(true);
    console.log("Submitting New Question");
    console.log(newQuestion);
    newQuestion.mandatory = (mandatory === true || mandatory === "yes") ? "yes" : "no";
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/question", {
      method: "POST",
      body: JSON.stringify(newQuestion),
      headers: { "Content-Type": "application/json" , "Authorization": "Bearer " + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if(data.status == "success") {
        setIsSubmitting(false);
        // setSectionName("");
        // setResponseType("");
        // setIsAdditional(false);
        setTypeNewQuestion("");
        questionRef.current.focus();
        subdrawerCtx.setPageUpdate({ "type":"addQuestionSubdrawer", 'toggleUpdate': subdrawerCtx.pageUpdate.hasOwnProperty('toggleUpdate') ? (subdrawerCtx.PageUpdate?.toggleUpdate ? false : true) : true });
      } else {
        setIsSubmitting(false);

      }
    }).catch((err) => {
        setIsSubmitting(false);
       console.error("Failed to add new question......",err)
      });

    // let answer = [{
    //   id: 1,
    //   orderNo: 1,
    //   answerDesktop: "",
    //   answerMobile: "",
    //   value: 0
    // },
    // {
    //   id: 2,
    //   orderNo: 2,
    //   answerDesktop: "",
    //   answerMobile: "",
    //   value: 0
    // }]
    // setAnswers(answer);
    

  }


  useEffect(()=>{
    getPages()
  },[])

  useEffect(()=>{
    setPageId(props.questionDetails?.pageId)
  },[props.questionDetails?.pageId])

  useEffect(()=>{
    console.log('props.questionDetails',props.questionDetails)
    setQuestions(props.questionDetails?.questions)
  },[props.questionDetails])

  useEffect(()=>{
    console.log('questions',questions)
    setQuestions(props.questionDetails?.questions)
  },[props.questionDetails])

  useEffect(()=>{
    console.log('Pages',pages)
    console.log('pageId',pageId)
    getPageName(pageId)
  },[pages, pageId])

  useEffect(()=>{ console.log('newQuestion:',newQuestion) },[newQuestion])

  return (
    <div className="add-question-drawer drawer">
      
      <header>
        <h2>Add Question</h2>
      </header>

      <section>
        
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormGroup>
            <div className="double-column-form">
              
              <FormControl fullWidth className="form-control-add-question">
                <label>Category{ isAdditional ? "" : "*" }</label>
                <TextField required={isAdditional ? false : true} value={sectionName} onChange={(e) => setSectionName(e.target.value)} select variant="outlined">
                  { 
                    sections?.map((section, index) => {
                      return <MenuItem key={index} className="section-name" value={section?.section ? section.section : ""}>{section.section}</MenuItem>
                    })
                  }
                </TextField>
              </FormControl>

              <FormControl fullWidth className="form-control-add-question">
                <label>Response Type*</label>
                <TextField required value={responseType} onChange={(e) => updateQuestionType(e.target.value)} select variant="outlined">
                  <MenuItem className="question-type" value="slider">Slider</MenuItem>
                  <MenuItem className="question-type" value="textarea">Multiline Textbox</MenuItem>
                  <MenuItem className="question-type" value="radio">Radio Buttons</MenuItem>
                  <MenuItem className="question-type" value="multiple_choice">Multiple Choice</MenuItem>
                  <MenuItem className="question-type" value="month_year">Month</MenuItem>
                  <MenuItem className="question-type" value="select">Drop-down List</MenuItem>
                  <MenuItem className="question-type" value="checkbox">Checkbox</MenuItem>
                </TextField>
              </FormControl>

              <FormControl fullWidth className="form-control-add-question">
                <label>Display On*</label>
                <TextField required value={isAdditional} onChange={(e) => setIsAdditional(e.target.value)} select variant="outlined">
                  <MenuItem className="question-type" value={false}>Assessment</MenuItem>
                  <MenuItem className="question-type" value={true}>Additional</MenuItem>
                </TextField>
              </FormControl>

              <FormControl fullWidth className="form-control-add-question">
                <label>Choose Page Type*</label>
                <TextField required value={pageId} onChange={(e)=>{
                  setPageId(e.target.value)
                }} select variant="outlined">
                  {
                    pages?.map((el,index)=>{
                      return <MenuItem className="question-type" value={el?.id} setPageName onClick={()=>{setPageId(el?.id) }}> <div className="pageid-pagename-container"> <span className="page-name">{el?.page_type}</span> <span className="page-id">{el?.id}</span> </div> </MenuItem>
                    })
                  }
                </TextField>
              </FormControl>

            </div>

            <FormControl fullWidth className="form-control-add-question" ref={questionRef}>
              <label >Question*</label>
              <TextField required variant="outlined" placeholder="Question" value={typeNewQuestion} onChange={(e) => setTypeNewQuestion(e.target.value)} multiline minRows={3} maxRows={6} />
            </FormControl>

            <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
              <span>Is The Question Mandatory: &nbsp;</span> <AntSwitch color="primary" className="mandatory-switch" {...switchLabel} checked={mandatory} onChange={(e) => setMandatory(e.target.checked)} /> { mandatory ? "YES" : "NO" }
            </FormControl>
            <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
              <span>Add A “Not Applicable” Option:: &nbsp;</span> <AntSwitch color="primary" className="mandatory-switch" {...switchLabel} checked={notApplicable} onChange={(e) => setNotApplicable(e.target.checked)} /> {notApplicable ? "YES" : "NO" }
            </FormControl>

            <FormControl fullWidth className="form-control-add-question question-applicable-toggle">
              <span>Add A Non Applicable Option: &nbsp;</span> <AntSwitch color="primary" className="applicable-switch" {...switchLabel} checked={applicable} onChange={(e) => setApplicable(e.target.checked)} /> { applicable ? "YES" : "NO" }
            </FormControl>

            { showAnswers ? 
              <>
                <div className="add-options-and-heading-container">
                  <h3>Answers Available For This Question:</h3>
                  <FormControl>
                    <Button className="option-btn" onClick={() => addAnswer()}>
                      <span className="add-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g id="Group_4492" data-name="Group 4492" transform="translate(-1584 -327)">
                            <g id="add" transform="translate(1586.688 322.798)">
                              <path id="Path_12994" data-name="Path 12994" d="M17.98,16.2a1.433,1.433,0,0,1-1.433,1.433H10.77v5.778a1.433,1.433,0,0,1-2.864,0V17.635H2.125a1.433,1.433,0,1,1,0-2.866H7.9V8.991a1.433,1.433,0,0,1,2.864,0v5.778h5.78A1.433,1.433,0,0,1,17.98,16.2Z" fill="#9b9b9b"/>
                            </g>
                            <rect id="Rectangle_1264" data-name="Rectangle 1264" width="24" height="24" transform="translate(1584 327)" fill="none"/>
                          </g>
                        </svg>  
                      </span>
                      <span>Add Option</span>
                    </Button>
                  </FormControl>
                </div>
                {
                  answers.map((answer, index) => {
                    let answerdesktop = "Answer " + (index + 1) + " (Desktop)";
                    let answermobile = "Answer " + (index + 1) + " (Mobile)";
                    let answerscore = "Score value " + (index + 1);
                    return (
                      <div className="double-column-form" key={answer.id}>
                        <FormControl fullWidth className="form-control-add-question">
                          <label htmlFor="">{answerdesktop}*</label>
                          <TextField required onChange={(e) => updateAnswerDesktop(e, answer.id)} value={answer.answerDesktop} variant="outlined" placeholder={answerdesktop} multiline maxRows={2} />
                        </FormControl>
                        <FormControl fullWidth className="form-control-add-question">
                          <label htmlFor="">{answermobile}*</label>
                          <TextField required onChange={(e) => updateAnswerMobile(e, answer.id)} value={answer.answerMobile} variant="outlined" placeholder={answermobile} multiline maxRows={2} />
                        </FormControl>
                        <FormControl className="form-control-add-question" style={{minWidth: "160px"}}>
                          <label htmlFor="">{answerscore}*</label>
                          <TextField required onChange={(e) => updateAnswerValue(e, answer.id)} value={answer.value} variant="outlined" placeholder={answerscore} multiline maxRows={2} />
                        </FormControl>
                        <div className="delete-icon" onClick={() => deleteAnswer(answer.id)}>
                          <img src={delIcon} alt="Delete Icon" />
                        </div>
                      </div>
                    );
                  })
                }

              </>
              : null
            }
            
            <div className="cancel-btn-and-submit-btn">
              <Button color="primary" className="cancel-btn" type='button' onClick={subdrawerCtx.closeDrawer}>Cancel</Button>             
              { isSubmitting ? 
                <Button color="primary" className="add-question-btn"><CircularProgress style={{'color': '#fff'}} size={25} /></Button> :
                <Button color="primary" className="add-question-btn" type="submit">Submit New Question</Button> 
              }
            </div>
            
          </FormGroup>
        </form>
      </section>
    </div>
  )
}


export default AddQuestionDrawer;
