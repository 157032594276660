import { useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import AddImage from "./AddImage/AddImage";
import BackgroundColor from './StylingComponents/BackgroundColor';
import Color from "./StylingComponents/Color";
import Font from "./StylingComponents/Font";
import Margin from "./StylingComponents/Margin";
import Padding from "./StylingComponents/Padding";
import Width from "./StylingComponents/Width";
import LineHeight from "./StylingComponents/LineHeight";
import Border from "./StylingComponents/Border";
import BorderRadius from "./StylingComponents/BorderRadius";
import TextTransform from "./StylingComponents/TextTransform";
import LetterSpacing from "./StylingComponents/LetterSpacing";
import AddText from "./AddText/AddText";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const ButtonWidget = () => {

  const builderCtx = useContext(BuilderContext);

  const { currentContentBlock } = builderCtx;
  // console.log("currentContentBlock", currentContentBlock);

  return (
    <div>

      <p style={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "700", textAlign: "center", color: "#122845" }}>Button Widget</p>
      {/* <AddText blockId={currentContentBlock?.id} />
      <BackgroundColor blockId={currentContentBlock?.id} />
      <Color blockId={currentContentBlock?.id} />
      <Font blockId={currentContentBlock?.id} />
      <Margin blockId={currentContentBlock?.id} />
      <Padding blockId={currentContentBlock?.id} />
      <Width blockId={currentContentBlock?.id} />
      <LineHeight blockId={currentContentBlock?.id} />
      <Border blockId={currentContentBlock?.id} />
      <BorderRadius blockId={currentContentBlock?.id} />
      <TextTransform blockId={currentContentBlock?.id} />
      <LetterSpacing blockId={currentContentBlock?.id} /> */}
      <DynamicStylingTool/>
    </div>
  )
}

export default ButtonWidget