import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Checkbox, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SubDrawerContext from "../../store/SubDrawerContext";
import ModalContext from "../../store/ModalContext";
import AddIcon from '../../assets/img/addIcon.png';
import dropIcon from '../../assets/img/dropIcon.png';
import AuthContext from "../../store/AuthContext";
import deleteIcon from '../../assets/img/Question Builder/DelIcon.svg'


const EditPieChartDrawer = (props) => {
  const drawerCtx = useContext(DrawerContext);
  const subDrawerCTx = useContext(SubDrawerContext);
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [signsArray, setSignsArray] = useState([
    { 'option': '==' },
    { 'option': '!=' },
    { 'option': '>' },
    { 'option': '<', },
    { 'option': '<=', },
    { 'option': '>=', },
  ])


  const assessmentId = props.details?.assessmentId;
  const valueId =props.details?.values?.length>0?  props.details?.values[0]?.id : ""
  const [data, setData] = useState(props?.details.values || []);

  console.log("editchartdrawer props", props);

  const handleTitleChange = (index, newValue) => {
    const newData = [...data];
    newData[index].label = newValue;
    setData(newData);
  };
  const handleCountChange = (index, newValue) => {
    const newData = [...data];
    newData[index].variable = newValue;
    setData(newData);
  };
  const handleValueChange = (index, newValue) => {
    const newData = [...data];
    newData[index].value = newValue;
    setData(newData);
  };
  const handleSignChange = (index, newValue) => {
    const newData = [...data];
    newData[index].operator = newValue;
    setData(newData);
  };
  const handleColorChange = (index, newValue) => {
    const newData = [...data];
    newData[index].color = newValue;
    setData(newData);
  };


//   const handleAddChart = () => {
//     const newChart = {
//       label: 'Segment?', variable: 'segment', color: '#000000', operator: "==",
//       value: "1"
//     };
//     setData([...data, newChart]);
//   };

  const closeDrawer = () => {
    drawerCtx.closeDrawer();
  }
  const openColorModal = (index) => {

    const details = {
      handleColorChange,
      index
    }
    modalCtx.openModal();
    modalCtx.setDetails("color-selection-modal", details);

  }

  const handleRemove = (index) => {
    data.splice(index, 1)
    setData([...data]);
  }


  const fncEditPieChart = () => {
    const payload = {
      title: " ",
      chartType: "pie-chart",
      size: props.details?.size,
      timeFrame: new Date(),
      chartValues: data
    }
    if (data.length) {
      setIsLoading(true)
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/chart/value/'+ valueId, {
        method: 'POST', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
        .then(data => {
          setIsLoading(false)
          if (data.status === "success") {
            console.log(" successfully", data);
            props.details?.setIsUpdating(!props.details?.isUpdating)
            closeDrawer()
          }
        }).catch((err) => {
          console.error("Failed to update s...", err);
          setIsLoading(false)
          props.details?.setIsUpdating(!props.details?.isUpdating)

        });
    }
  }


  return (
    <div className="drawer add-section add-piechart-section">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
          Edit Pie Chart
        </h1>
      </header>
      <div className="chart-contents">
        <div className="chart-section">
          {data.map((item, index) => (
            <div key={index} className="chart-segment">
              <div className="form-group">
                <div className="delIcon" style={{ marginRight: '0px' }} onClick={() => { handleRemove(index) }} >
                  <img src={deleteIcon} alt="" />
                </div>
                <label htmlFor="title">Segment Title</label>
                <input type="text" className="text-box" value={item.label} onChange={(e) => handleTitleChange(index, e.target.value)} />
              </div>
              <div className="form-group">
                <label htmlFor="count">Calculation of variable</label>
                <div className="text-container">
                  <input type="text" className="text-box-variable" value={item.variable} onChange={(e) => handleCountChange(index, e.target.value)} />
                  <div className="variable-input-box">
                    <TextField select variant="outlined" className='choose-variable-select-tag' value={item.operator} onChange={(e) => { handleSignChange(index, e.target.value) }} >
                      {
                        signsArray?.map((item, idx) => {
                          return <MenuItem className="section-name" key={idx} value={item.option}>{item.option}</MenuItem>
                        })
                      }
                    </TextField>
                  </div>
                  <input type="text" className="text-box-variable" value={item.value} onChange={(e) => handleValueChange(index, e.target.value)} />
                </div>
              </div>
              <div className="form-group">
                <label htmlFor="count">Choose Color</label>
                <div className="box-container">
                  <input
                    type="color"
                    className="color-box"
                    defaultValue={item.color}
                    onClick={(e) => {
                      e.preventDefault();
                      openColorModal(index)
                    }}
                  />
                  <img className='drop-icon' src={dropIcon} alt='drop' onClick={(e) => {
                    e.preventDefault();
                    openColorModal(index)
                  }} />
                </div>
              </div>
            </div>
          ))}
          {/* <div className="chart-segment add-more-charts">
            <div className="add-chart" onClick={handleAddChart}>
              <img src={AddIcon} alt='add' />
              <h1>Add More Segments</h1>
            </div>
          </div> */}
        </div>
      </div>
      <div className="footer-btns">
        <Button className="cancel-btn" onClick={closeDrawer}>Cancel</Button>
        {isLoading ?
          <Button className="cancel-btn"><CircularProgress style={{ 'color': '#122845' }} size={25} /></Button> :
          <Button className="save-btn" onClick={fncEditPieChart}> Save</Button>
        }
      </div>
    </div>
  );
}
export default EditPieChartDrawer;
