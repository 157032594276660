import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Construction, List, Person as PersonIcon, MoreVert as MoreVertIcon, Assessment as AssessmentIcon, People as PeopleIcon, Delete as DeleteIcon, PeopleAlt as PeopleAltIcon, Help as HelpIcon, VerticalSplit as VerticalSplitIcon, Edit as EditIcon, ManageHistory, FormatListBulleted, Tune, Mail as MailIcon} from '@mui/icons-material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import PostAddIcon from '@mui/icons-material/PostAdd';
import UsersDrawer from "../DetailsDrawer";
import DrawerContext from "../../store/DrawerContext.js";
import ModalContext from "../../store/ModalContext.js";
import { hideSidebar } from "../../globalFunctions";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    color: "#747d8c",
  },
});

function UsersTable(props) {
  let navigate = useNavigate();
  const { id } = useParams();
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);
  const classes = useStyles();
  const [state, setState] = React.useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [assessments, setAssessments] = useState([]);

  const [refresh, setRefresh] = useState(false);

  

  useEffect(() => {
    setIsLoading(true);
    getAssessments();
  }, [drawerCtx.open, refresh]);

  const getAssessments = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/admin/assessments", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      console.log(data);
      setIsLoading(false);
      setAssessments(data.data);
    });
  }
  
  if(modalCtx.pageUpdate.page == "assessments") {
    modalCtx.setPageUpdate({page: ""});
    getAssessments();
  }

  function toggleDrawerHandler(drawer, id) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, id);
  }

  const toggleModalHandler = (modal, id) => {
    let details = {
      id: id,
      imageType: 'client-logo'
    }
    modalCtx.setDetails(modal, details);
    modalCtx.openModal();
  };

  const updateAutomation = (id) => {
		let assess = [];
    assessments.forEach(assessment => {
      if(assessment.id == id) {
				assessment.hasAutomation = (assessment.hasAutomation === "yes") ? "no" : "yes";
			}
      assess.push(assessment);
    });
    setAssessments(assess);
    
    console.log(assessments);
  };

	const updateAdditional = (id) => {
		let assess = [];
    assessments.forEach(assessment => {
      if(assessment.id == id) {
				assessment.hasAdditional = (assessment.hasAdditional === "yes") ? "no" : "yes";
			}
      assess.push(assessment);
    });
    setAssessments(assess);
    
    console.log(assessments);
  };

  if(isLoading) {
    return (
      <section><CircularProgress /></section>
    );
  }
  console.log(assessments);

  return (
    <>
      {/* <TableContainer component={Paper}>
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Assessment</TableCell>
							<TableCell>Stage</TableCell>
							<TableCell>Automation</TableCell>
							<TableCell>Additional</TableCell>
							<TableCell align="right" style={{ minWidth: "115px" }}>&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
					{
						assessments.map((data) => (
							<TableRow key={ data.id }>
								<TableCell width="55%">
                  <h3 className="assessment-link" onClick={() => navigate("/assessments/" + data.id, { replace: true })}>{ data.title }</h3>
                  <a href={ data.url } target="_blank">{ data.url }</a><br/>
                  <NavLink to={"/assessments/" + data.id + "/leaders"} className="no-underline"><PersonIcon className="table-tool-small" />{ data.leaderCount } Leaders</NavLink>&nbsp;/&nbsp;
                  <NavLink to={"/assessments/" + data.id + "/members"} className="no-underline"><PeopleIcon className="table-tool-small" />{ data.memberCount } Members</NavLink>
                </TableCell>
								<TableCell><Chip label={ data.stage } style={ data.stage == "live" ? {backgroundColor: '#78e08f'} : {backgroundColor: '#dfe4ea'} }/></TableCell>
								<TableCell align="center"><Chip onClick={() => updateAutomation(data.id)} label={ data.hasAutomation } style={ data.hasAutomation == "yes" ? {backgroundColor: '#78e08f'} : {backgroundColor: '#dfe4ea'}} /></TableCell>
								<TableCell align="center"><Chip onClick={() => updateAdditional(data.id)} label={ data.hasAdditional } style={ data.hasAdditional == "yes" ? {backgroundColor: '#78e08f'} : {backgroundColor: '#dfe4ea'} }/></TableCell>
								<TableCell align="right">
                  {data.hasAutomation === "yes" && <Tooltip title="Setup Automation"><EditIcon className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/automation", { replace: true });}} /></Tooltip>}
                  <Tooltip title="Details"><EditIcon className="table-tool" onClick={() => {toggleDrawerHandler('edit-assessment', data)}} /></Tooltip>
                  <Tooltip title="Fields"><EditIcon className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/fields", { replace: true });}} /></Tooltip>
									<Tooltip title="Sections"><VerticalSplitIcon className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/sections", { replace: true });}} /></Tooltip>
									<Tooltip title="Questions"><HelpIcon className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/questions", { replace: true });}} /></Tooltip>
									<Tooltip title="Delete"><DeleteIcon className="table-tool" onClick={() => {toggleModalHandler('assessment-delete', data)}} /></Tooltip>
								</TableCell>
							</TableRow>
						))
					}
					</TableBody>
				</Table>
			</TableContainer>
      <UsersDrawer openTheDrawer={state} /> */}

      <div className="card-container">
        {assessments.map(data => (
          <Table key={data.id} component={Paper}>
            <div className="card">
              <div onClick={() => {toggleModalHandler('image-cropper', data.id)}} className="logo"></div>
              <div className="card-title">{data.title}</div>
              <p title="Client Name" style={{ margin: "0 auto 10px", cursor: "help" }}>{data.clientName || "-"}</p>
              <div className="card-stats">
                <div className="stat" title="All Users" style={{ cursor: "help" }}>{data?.leaderCount + data?.memberCount}</div>
                <div className="stat" title="Leaders" style={{ cursor: "help" }}>{data?.leaderCount}</div>
                <div className="stat" title="Members" style={{ cursor: "help" }}>{data?.memberCount}</div>
              </div>
              <div className="icons">
                <Tooltip title="Builder"><Construction className="table-tool" onClick={() => {
                  hideSidebar();
                  navigate("/builder/" + data.id, { replace: true });}} /></Tooltip>
                {data.hasAutomation === "yes" && <Tooltip title="Setup Automation"><ManageHistory className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/automation", { replace: true });}} /></Tooltip>}
                <Tooltip title="Pages"><PostAddIcon className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/pages", { replace: true });}} /></Tooltip>
                <Tooltip title="Details"><Tune className="table-tool" onClick={() => {toggleDrawerHandler('edit-assessment', data)}} /></Tooltip>
                <Tooltip title="Fields"><FormatListBulleted className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/fields", { replace: true });}} /></Tooltip>
                <Tooltip title="Sections"><VerticalSplitIcon className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/sections", { replace: true });}} /></Tooltip>
                {/* <Tooltip title="Questions"><HelpIcon className="table-tool" onClick={() => {navigate("/assessments/" + data.id + "/questions", { replace: true });}} /></Tooltip> */}
                <Tooltip title="Questions"><HelpIcon className="table-tool" onClick={() => {toggleDrawerHandler("open-questions-builder", {id: data.id, assessmentName: data.title})}} /></Tooltip>
                <Tooltip title="Email"><MailIcon className="table-tool" onClick={() => { navigate("/emails/" + data.id, { replace: true });}} /></Tooltip>
                <Tooltip title="Delete"><DeleteIcon className="table-tool" onClick={() => {toggleModalHandler('assessment-delete', data)}} /></Tooltip>
                <Tooltip title="Scoring"><DescriptionOutlinedIcon className="table-tool" onClick={() => toggleDrawerHandler('assessment-scoring', data)} /></Tooltip>
              </div>
            </div>
          </Table>
        ))}
      </div>
    </>
  );
}

export default UsersTable;