import React, { useContext, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import cross from '../../../src/assets/img/cross.png';
import AuthContext from '../../store/AuthContext.js';



const DeleteTabs = (props) => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [isCreating, setIsCreating] = useState(false);

  const assessmentId = props?.details?.assessmentId;
  const tabId = props?.details?.id;
  const title = props?.details?.title;

  console.log("DeleteTabs props", props);

  const handleDeleteTab = () => {

    setIsCreating(true)
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/tab/' + tabId, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    }).then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        setIsCreating(false)
        modalCtx.setDetails(null);
        modalCtx.closeModal();
        props.details.setIsUpdating(!props.details.isUpdating)
        props.details.setTabValue(0);
      } else {
        console.log("failed to delete tab");
        props.details.setIsUpdating(!props.details.isUpdating)
      }
    })
    .catch((err) => {
      console.log(err);
    });
  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  return (
    <div className='modal'>
      <div className="delete-popup-content">
        <div className="main-content">
          <div className="heading-container">
            <div className="heading">
              Are you sure you want to delete this "{title}" tab?
            </div>
            <img src={cross} onClick={() => handleCloseModal()} alt="cross" />
          </div>

          {isCreating ?
            <button className="delete-btn" disabled> <CircularProgress /></button> :
            <button className="delete-btn" onClick={() => handleDeleteTab()}>Delete</button>
          }

          <button className="cancel-btn" onClick={() => handleCloseModal()}>Cancel</button>

        </div>
      </div>
    </div>
  )
}

export default DeleteTabs;
