import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import {  TextField,  Select,  MenuItem,  FormControl,  InputLabel,  Button,  Checkbox} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function CreateClientDrawer(props) {
  const [client, setClient] = useState("");

	const drawerCtx = useContext(DrawerContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");
    fetch(process.env.REACT_APP_API_URI + "/v1/accounts", {
      method: "POST",
      body: JSON.stringify({
        title: client,
      }),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
    drawerCtx.closeDrawer();
  };

  return (
    <div className="drawer">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
          Add Client
        </h1>
      </header>

      <section>
        <div className="double-column-form">
          <TextField
            label="Client Name"
            value={client}
            onChange={(e) => {
              setClient(e.target.value);
            }}
            placeholder="Client Name"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            required
          />
        </div>

        <div className="double-column-form aligned-right margin-top">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            component="label"
            disabled={client === "" || client === null}
          >
            Add Client
          </Button>
        </div>
      </section>
    </div>
  );
}
export default CreateClientDrawer;
