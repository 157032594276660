import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, NavLink, useSearchParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import redEditIcon from '../../assets/img/builder-images/red-edit-icon.png'
import deleteIcon from '../../assets/img/Question Builder/DelIcon.svg'
import EditIcon from '../../assets/img/Question Builder/editIcon.svg'
import SliderIcon from '../../assets/img/Question Builder/Slider.svg'
import TextIcon from '../../assets/img/Question Builder/textIcon.svg'
import DragIcon from '../../assets/img/Question Builder/DragIndicator.svg'
import JumpIcon from '../../assets/img/jump.svg'
import DisplayIcon from '../../assets/img/display.svg'
import RadioListIcon from '../../assets/img/Question Builder/radioListIcon.svg'
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Person as PersonIcon, People as PeopleIcon, Help as HelpIcon, VerticalSplit as VerticalSplitIcon, Update, } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { List } from "@material-ui/icons";
import redStar from '../../assets/img/redStar.svg'
import GrayStar from '../../assets/img/graystar.svg'
import UsersDrawer from "../DetailsDrawer";
import DrawerContext from "../../store/DrawerContext.js";
import SubDrawerContext from "../../store/SubDrawerContext";
import ModalContext from "../../store/ModalContext.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AuthContext from "../../store/AuthContext";
import { FlashOnOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Alert } from "@material-ui/lab";
import { Snackbar, TextField } from "@mui/material";
import BuilderContext from "../../store/BuilderContext.js";
import { MenuItem } from "@material-ui/core";
import setHours from "date-fns/setHours/index.js";

const useStyles = makeStyles({
  table: { minWidth: 650, color: "#747d8c", },
});

function AssessmentsQuestionsTable(props) {
  let navigate = useNavigate();
  const { id } = useParams();
  const builderCtx = useContext(BuilderContext);
  const drawerCtx = useContext(DrawerContext);
  let authCtx = useContext(AuthContext)
  const subdrawerCtx = useContext(SubDrawerContext);
  const modalCtx = useContext(ModalContext);
  const classes = useStyles();
  const [state, setState] = useState(false);
  const [isMandatoryVisible, setisMandatoryVisible] = useState(false)
  const [mandatoryIndex, setMandatoryIndex] = useState()
  const [isLoading, setIsLoading] = useState(false);
  // Replace props with useState for questions and additionalQuestions
  const [questions, setQuestions] = useState(props.questions || []);
  const [additionalQuestions, setAdditionalQuestions] = useState(props.additionalQuestions || []);
  const [isAdditional, setIsAdditional] = useState(props.isAdditional || false);
  const [sections, setSections] = useState([]);
  const [toggleVariableBtn, settoggleVariableBtn] = useState(false);
  const [toggleVariableBtnID, settoggleVariableBtnID] = useState();
  const [variableValue, setvariableValue] = useState()
  const [refresh, setRefresh] = useState(false);
  const [ShowDuplicateVariableSnackbar, setShowDuplicateVariableSnackbar] = useState(false);
  const [showJump, setShowJump] = useState(false);
  const [showJumpId, setShowJumpId] = useState('');
  const [showDisplay, setShowDisplay] = useState(false);
  const [showDisplayId, setShowDisplayId] = useState('');
  const [logicType, setLogicType] = useState('');
  const [questionId, setQuestionId] = useState('');
  const [variables, setVariables] = useState([]);
  const [logics, setLogics] = useState([]);
  const [jumpLogic, setJumpLogic] = useState([]);
  const [isSubmittingLogic, setIsSubmittingLogic] = useState(false);
  const [questionsData, setQuestionsData] = useState([])
  const [isGettingQuestions, setIsGettingQuestions] = useState(false)
  const [currentIndex, setCurrentIndex] = useState('')

  const [signsArray, setSignsArray] = useState([
    { 'option': '==' },
    { 'option': '!=' },
    { 'option': '>' },
    { 'option': '<', },
    { 'option': '<=', },
    { 'option': '>=', },
  ])


  const subDrawer = props?.subDrawer || false;

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  function toggleSubDrawerHandler(drawer, user) {
    subdrawerCtx.openDrawer();
    subdrawerCtx.setDetails(drawer, user);
  }

  const drawerHandler = (user) => {
    console.log('sections:',props.sections)
    console.log('props',props)
    user = {...user, sections:props.sections}
    console.log('user',user)
    if (subDrawer) {
      toggleSubDrawerHandler("question-edit-subdrawer", user);
    } else {
      toggleDrawerHandler("question-edit", user);
    }
  };

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  const showTypeIcons = (type) => {
    if (type === "slider") {
      return <img src={SliderIcon} alt="" />;
    } else if (type === "textarea") {
      return <img src={TextIcon} alt="" />;
    }
    else if (type === 'radio') {
      return <img src={RadioListIcon} alt="" />;
    }
  };

  // to toggle variable and input Box
  const editVariable = (id, data) => {
    setvariableValue(data?.variable)
    settoggleVariableBtn(true);
    settoggleVariableBtnID(id);
  };

  const handleJump = (id, data) => {
    setShowJumpId(id)
    setQuestionId(id)
    props?.setIsUpdatedLogic(!props?.isUpdatedLogic)
   
      setShowDisplay(false);
      setShowJump(!showJump);

  }
  const handleDisplay = (id, data) => {
   setShowDisplayId(id)
    setQuestionId(id)
    props?.setIsUpdatedLogic(!props?.isUpdatedLogic)
    setShowJump(false);
    setShowDisplay(!showDisplay)
  }

  const handlelogic = (id, value) => {
     
    if (value == "jump") {
      setShowDisplay(false);
      setShowJump(!showJump);
      setShowJumpId(id)
      
    } else {
      setShowJump(false);
      setShowDisplay(!showDisplay)
      setShowDisplayId(id)
    }
  }

  const variableUpdate = (data, index) => {
    const newQuestions = [...questions]
    const prevValue = newQuestions[index].variable;
    newQuestions[index] = {
      ...newQuestions[index],
      variable: variableValue
    }
    const payload = newQuestions[index]
    setIsLoading(true)
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/question/' + data.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {

        if (data.status == 'success') {
          setQuestions(newQuestions)
          setIsLoading(false)
        }
        else if (data.status == 'failure') {
          setShowDuplicateVariableSnackbar(true)
          setIsLoading(false)
          setvariableValue(prevValue)
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error', error);
      })

    setTimeout(() => {
      settoggleVariableBtn(false)
    }, 1000);

  }

  const handleClose = () => {
    setShowDuplicateVariableSnackbar(false)
  }

  const mandatoryToggle = (mandatoryData, index) => {
    const newQuestions = [...questions]
    newQuestions[index] = {
      ...newQuestions[index],
      mandatory: mandatoryData?.mandatory == 'yes' ? 'no' : 'yes'
    }
    const payload = newQuestions[index]
    console.log('newQuestions', newQuestions)
    console.log('payload', payload)
    setQuestions(newQuestions)

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/question/' + mandatoryData.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    })
    .then((response) => response.json())
    .then((data) => { console.log('data', data) })
    .catch((error) => {
      console.log('error', error)
    })
  }


 
  const updateQuestionsOrder = (data) =>{
    let newQuestionsPayload = {
      "questionOrder":
        data.map((item,index)=>{
          return {
            "questionId": item.id,
            "orderIndex": item.orderNo
          }
        })
    } 

    console.log("payload--------------------",newQuestionsPayload)
    console.log("payload data--------------------",data)

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/question/update/order`, {
      method: 'PUT',
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authCtx.token 
      },
      body: JSON.stringify(newQuestionsPayload), // Convert the data object to a JSON string
    })
    .then((response) => response.json())
	  .then((data) => {
		console.log('Updated question order',data);
      if (data.status === "success") {
        console.log("Successfully updated the question order...");
        // getQuestions()
        drawerCtx.setPageUpdate({'type' : 'questionOrder','toggle':drawerCtx.pageUpdate?.toggle ? false : true})
      }
	  })
	  .catch((err) => console.error("Failed to update the resource...", err));
  }

  useEffect(()=>{
    
    if ( subdrawerCtx?.drawer === "question-add-subdrawer" || modalCtx?.modal === "question-delete" ){
      let forLocalState = [];
      const newQuestions = [...questions];

      newQuestions.map((el,index)=>{
        forLocalState.push({...el, orderNo:index+1})
      })  
      setQuestions(forLocalState)
      updateQuestionsOrder(forLocalState)
      console.log('forLocalState',forLocalState)
    }

  }, [modalCtx, subdrawerCtx])
  

  useEffect(() => {
    setQuestionsData(props?.questions)
  }, [props?.questions])


  useEffect(() => {
    setSections(props.sections)
  }, [props.sections])

  useEffect(() => {
    let filteredQuestions = props.questions;
    filteredQuestions.sort((a, b) => a.orderNo - b.orderNo);
    setQuestions(filteredQuestions)
    setAdditionalQuestions(props.additionalQuestions)
  }, [props.additionalQuestions, props.questions]);



  useEffect(() => {
    
  
    
    const filteredLogic = questionsData?.filter(item => item?.id == questionId) || [];

    if (filteredLogic && filteredLogic?.length != 0) {
      const filteredJumpLogic = filteredLogic[0]?.jumpLogic?.filter(item => item?.logicType == "jump");
      const filteredDisplayLogic = filteredLogic[0]?.jumpLogic?.filter(item => item?.logicType == "display");
      setLogics(filteredDisplayLogic );
      setJumpLogic( filteredJumpLogic);
    }
 

  }, [showDisplay, setShowDisplay, setShowJump, showJump,  questionsData, questionId ])

  const fncAddMoreLogic = (logictype) => {
    const targetArray = logictype === "jump" ? jumpLogic : logics;
    const id = targetArray.length > 0 ? targetArray.length + 1 : 1;

    const logicsArray = { id, logicType: logictype, variable: "", operator: "", value: "", additional: "", jumpTo: "", subLogics: [],  };

    logictype === "jump" ? setJumpLogic([...targetArray, logicsArray]) : setLogics([...targetArray, logicsArray]);
  };


  useEffect(() => {
   
    const logicsArray = [{
      id: 1,
      logicType: "jump",
      variable: "",
      operator: "",
      value: "",
      additional: "",
      jumpTo: "",
      subLogics: [],
    }]

    setJumpLogic(logicsArray);
  

 },[jumpLogic?.length == 0])

 useEffect(() => {
   
  const logicsArray = [{
    id: 1,
    logicType: "display",
    variable: "",
    operator: "",
    value: "",
    additional: "",
    jumpTo: "",
    subLogics: [],
  }]

  setLogics(logicsArray);


},[logics?.length ==0])





const deleteDisplayLogic = (a) => {
    
    setLogics(logics.filter((answer) => {
      return answer.id !== a
    }));
};

  const deleteJumpLogic = (a) => {
  setJumpLogic(jumpLogic.filter((answer) => {
      return answer.id !== a
    }));
  };





  const handleLogicUpdate = (logictype, value, id, updateProperty, isublogic, subId) => {
     
    const targetArray = logictype === "jump" ? jumpLogic : logics;

    let logicsArray = targetArray.map(item => {
      if (item.id == id) {
        if (isublogic) {
          item.subLogics = item?.subLogics?.map(subLogic => {
            if(subLogic.id == subId){
            subLogic[updateProperty] = value;
            }
            return subLogic;

          });
        } else {
          item[updateProperty] = value;
        }
      }
      return item;
    });

    logictype === "jump" ? setJumpLogic(logicsArray) : setLogics(logicsArray);
  };
  
  const handleChangeSigns = (value, id, logictype, isublogic, subId) => {
    handleLogicUpdate(logictype, value, id, "operator", isublogic, subId);
  };

  const handleInputDisplay = (value, id, logictype, isublogic, subId) => {
    handleLogicUpdate(logictype, value, id, "value", isublogic, subId);
  };

  const handleJumpQuestion = (value, id,) => {
    handleLogicUpdate('jump', value, id, "jumpTo");
  };
  const handleChangeMoreVariable = (value, id, logictype, isublogic, subId) => {
    handleLogicUpdate(logictype, value, id, "variable", isublogic, subId);
   };


  const submitLogic = (e, logictype, index) => {
    setCurrentIndex(index)
    e.preventDefault();
    let logicId;
    let postCallPayload;
    if (logictype === 'display') {
     postCallPayload = {
      logics: logics?.map((item, idx) => {
        if (idx === index ) {
          logicId = item?.id;
        const {id, subLogics, parentId,orderNo, updatedAt, questionId, createdAt,...itemWithoutId } = item;
        return itemWithoutId 
        } return null; 
      }).filter(Boolean)
    }
    }else{
    postCallPayload = {
        logics: jumpLogic?.map((item, idx) => {
          if (idx === index ) {
            logicId = item?.id;

            const {id, subLogics,orderNo, parentId, updatedAt, questionId, createdAt,...itemWithoutId } = item;
            return {
              ...itemWithoutId,
              subLogics: subLogics?.map(subItem => {
                const {  id,parentId, updatedAt, questionId, orderNo,subLogicId, createdAt, ...subItemWithoutId } = subItem;
               
                return {
                  ...subItemWithoutId,
                }
              })
            };
          }
          return null; 
        }).filter(Boolean)
       }
      }
    
    const isValid = postCallPayload?.logics?.every(item => {
      if (item?.logicType === 'display') {
        return (
          item?.operator &&   item?.logicType!='' &&
          item?.variable!='' &&
          item?.operator!='' &&
          item?.value !== undefined && item?.value !== ""
        );
      } else {
        return (
          item?.logicType &&
          item?.variable &&
          item?.operator &&   item?.logicType!='' &&
          item?.variable!='' &&
          item?.operator !='' &&
          item?.value !== undefined && item?.value !== "" &&
          item?.jumpTo !== undefined && item?.jumpTo !== ""  &&
        ( item?.subLogics?.length === 0  || (item?.subLogics?.length > 0 && item?.subLogics.every(subItem => subItem?.variable && subItem?.operator && subItem?.variable!='' && subItem?.operator!='' &&subItem?.value != '' && subItem?.value !== undefined)))
        );
      }
    });

    let updatePayload

    if (logicType === 'display') {
      updatePayload = {
        logicType: postCallPayload?.logics[0]?.logicType,
        variable: postCallPayload?.logics[0]?.variable,
        operator: postCallPayload?.logics[0]?.operator,
        value: postCallPayload?.logics[0]?.value,

      }
     }else{
     updatePayload = {
      logicType: postCallPayload?.logics[0]?.logicType,
      variable: postCallPayload?.logics[0]?.variable,
      operator: postCallPayload?.logics[0]?.operator,
      value: postCallPayload?.logics[0]?.value,
      additional: postCallPayload?.logics[0]?.additional,
      jumpTo: postCallPayload?.logics[0]?.jumpTo,
      subLogics: jumpLogic?.map((item, idx) => {
        if (idx === index) {
          const { subLogics, ...itemWithoutId } = item;
          return [
            ...(subLogics?.map(({ subItemWithoutId, sublogicId, id, parentId, updatedAt, questionId, orderNo, createdAt, ...rest }) => ({
              ...rest,
            
              subLogicId: orderNo != undefined ? id.toString() :""
            })) || [])
          ];
        }
        return null;
      }).filter(Boolean).flat(1)
    };
  }
  let isUpdateCall;
 if(logictype === 'jump'){
     isUpdateCall = jumpLogic?.map((item, idx) => {
      if (idx == index) {
        return item?.orderNo !== undefined; 
      }
    });
  }else{
   isUpdateCall = logics?.map((item, idx) => {
      if (idx == index) {
        return item?.orderNo !== undefined; 
      }
    });
  }

          
 


    if (isValid &&  isUpdateCall?.some((value) => value== false) ) {
      setIsSubmittingLogic(true)
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/question/${questionId}/logic`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(postCallPayload),
      })
      .then((response) => response.json())
      .then((data) => {
        console.log('logic updated ', data);
        if (data.status === "success") {
          setIsSubmittingLogic(false);
          // props?.setIsUpdatedLogic(!props?.isUpdatedLogic)
         console.log("Successfully updated logic...");
          }
        })
        .catch((err) => {
          setIsSubmittingLogic(false);
          console.error("Failed to update the resource...", err)
      })
    };

    if (isValid &&  isUpdateCall?.some((value) => value== true) ) {
      setIsSubmittingLogic(true)
      fetch(`${process.env.REACT_APP_API_URI}/v3/admin/questions/logic/${logicId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + authCtx.token
        },
        body: JSON.stringify(updatePayload),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log('logic updated ', data);
          if (data.status === "success") {
            setIsSubmittingLogic(false);
            // props?.setIsUpdatedLogic(!props?.isUpdatedLogic)
            console.log("Successfully updated logic...");
          }
        })
        .catch((err) => {
          setIsSubmittingLogic(false);
          // props?.setIsUpdatedLogic(!props?.isUpdatedLogic)
          console.error("Failed to update the resource...", err)
        })
    
    }

    
  } 


 
const isValidItem=(logictype, index)=>{
  let logicId;
  let postCallPayload;
  if (logictype === 'display') {
   postCallPayload = {
    logics: logics?.map((item, idx) => {
      if (idx === index ) {
        logicId = item?.id;
      const {id, subLogics, parentId,orderNo, updatedAt, questionId, createdAt,...itemWithoutId } = item;
      return itemWithoutId 
      } return null; 
    }).filter(Boolean)
  }
  }else{
  postCallPayload = {
      logics: jumpLogic?.map((item, idx) => {
        if (idx === index ) {
          logicId = item?.id;

          const {id, subLogics,orderNo, parentId, updatedAt, questionId, createdAt,...itemWithoutId } = item;
          return {
            ...itemWithoutId,
            subLogics: subLogics?.map(subItem => {
              const {  id,parentId, updatedAt, questionId, orderNo,subLogicId, createdAt, ...subItemWithoutId } = subItem;
             
              return {
                ...subItemWithoutId,
              }
            })
          };
        }
        return null; 
      }).filter(Boolean)
     }
    }

  const isValid = postCallPayload?.logics?.every(item => {
    if (item?.logicType === 'display') {
      return (
        item?.operator &&   item?.logicType!='' &&
        item?.variable!='' &&
        item?.operator!='' &&
        item?.value !== undefined && item?.value !== ""
      );
    } else {
      return (
        item?.logicType &&
        item?.variable &&
        item?.operator && item?.logicType!='' &&
        item?.variable!='' &&
        item?.operator !='' &&
        item?.value !== undefined && item?.value !== "" &&
        item?.jumpTo !== undefined && item?.jumpTo !== ""  &&
        ( item?.subLogics?.length === 0  || (item?.subLogics?.length > 0 && item?.subLogics.every(subItem => subItem?.variable && subItem?.operator && subItem?.variable!='' && subItem?.operator!='' &&subItem?.value != '' && subItem?.value !== undefined)))
      );
    }
  });
  return isValid
}


  
  const showQuestionLogicComponent = (data,index) => {
    const componentsJump = [];
    const componentsDisplay=[]
    if (showDisplay && showDisplayId == data?.id) {
    
      componentsDisplay.push(  <QuestionDisplayLogic data={data} signsArray={signsArray}
          logicType={logicType} setLogicType={setLogicType}
          handleLogic={handlelogic}
          variables={variables}
          handleChangeMorevariable={handleChangeMoreVariable}
          handleChangeSigns={handleChangeSigns}
          handleInputDisplay={handleInputDisplay}
          submitLogic={submitLogic}
          isValidItem={isValidItem}
          fncAddMoreLogic={fncAddMoreLogic}
          deleteDisplayLogic={deleteDisplayLogic}
          logics={logics}
          isSubmittingLogic={isSubmittingLogic}
          isGettingQuestions={isGettingQuestions}
          toggleModalHandler={toggleModalHandler}
          setIsUpdatedLogic={props?.setIsUpdatedLogic}
          isUpdatedLogic={props?.isUpdatedLogic}
          currentIndex={currentIndex}
          currentRowIndex={index}
          questions={questions}




        />
        );
      return componentsDisplay;

    }

    if (showJump && showJumpId == data?.id) {
      componentsJump.push(
        <QuestionJumpLogic data={data} signsArray={signsArray}
          currentRowIndex={index}
          logicType={logicType}
          setLogicType={setLogicType}
          handleChangeMoreVariable={handleChangeMoreVariable}
          handleLogic={handlelogic}
          handleChangeSigns={handleChangeSigns}
          questions={questions}
          variables={variables}
          submitLogic={submitLogic}
          isValidItem={isValidItem}
          fncAddMoreLogic={fncAddMoreLogic}
          deleteJumpLogic={deleteJumpLogic}
          handleJumpQuestion={handleJumpQuestion}
          jumpLogic={jumpLogic}
          handleInputDisplay={handleInputDisplay}
          setJumpLogic={setJumpLogic}
          isSubmittingLogic={isSubmittingLogic}
          isGettingQuestions={isGettingQuestions}
          toggleModalHandler={toggleModalHandler}
          setIsUpdatedLogic={props?.setIsUpdatedLogic}
          currentIndex={currentIndex}
          isUpdatedLogic= { props?.isUpdatedLogic }

        />
      );
      return componentsJump;
    }

  }

  return (
    <>
      <TableContainer component={Paper}>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              // If the item was dropped outside of a valid drop target, do nothing
              return;
            }

            const sourceIndex = result.source.index;
            const destinationIndex = result.destination.index;

            if (result.source.droppableId === "tasks") {
              // Handle reordering of main questions
              const newQuestions = [...questions]; // Use local state
              const [draggedItem] = newQuestions.splice(sourceIndex, 1);
              newQuestions.splice(destinationIndex, 0, draggedItem);
              console.log( "New Order (Main Questions):", newQuestions);
              
              let forLocalState = [];
              newQuestions.map((el,index)=>{
                forLocalState.push({...el, orderNo:index+1})
              })
              setQuestions(forLocalState); // Update local state

              updateQuestionsOrder(forLocalState);

            } else if (result.source.droppableId === "additional-questions") {
              // Handle reordering of additional questions
              const newAdditionalQuestions = [...additionalQuestions]; // Use local state
              const [draggedItem] = newAdditionalQuestions.splice(sourceIndex, 1);
              newAdditionalQuestions.splice(destinationIndex, 0, draggedItem);
              console.log("New Order (Additional Questions):", newAdditionalQuestions.map((item) => item.id));
              setAdditionalQuestions(newAdditionalQuestions); // Update local state
            }

          }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead onClick={() => {settoggleVariableBtn(false)}}>
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Questions</TableCell>
                <TableCell>Logic</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Variable</TableCell>
                <TableCell align="right" style={{ minWidth: "60px" }}>&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="tasks">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {questions?.map((data, index) => (
                    data.pageId === props.pageId ? <>
                      <Draggable key={data.id.toString()} index={index}
                        draggableId={data.id.toString()} // Use a unique identifier as the draggableId
                      >
                        {(provided) => (
                          // <TableRow key={data.id} ref={provided.innerRef}
                          <ExpandableTableRow key={data.id} innerRef={provided.innerRef}
                            {...provided.draggableProps}
                            onMouseEnter={() => {
                              setisMandatoryVisible(true)
                              setMandatoryIndex(index)
                            }}
                            onMouseLeave={() => {
                              setisMandatoryVisible(false)
                              setMandatoryIndex(null)
                            }}
                            style={{ position: "relative", }}
                            isGettingQuestions={isGettingQuestions}
                            isExpanded={(showDisplay && (showDisplayId == data?.id)) || (showJump && (showJumpId == data?.id))}
                            expandComponent={
                            <TableCell colSpan="7" key={data?.id}>
                              {showQuestionLogicComponent(data,index)}
                            </TableCell>}
                          >
                            <TableCell onClick={() => {settoggleVariableBtn(false)}}>
                              <div className="order-and-drag-container" {...provided.dragHandleProps}>
                              <img src={DragIcon} alt="Drag icon" />
                              {data?.orderNo}
                              </div>
                            </TableCell>
                            <TableCell className="question-text"  onClick={()=>{ settoggleVariableBtn(false)}}>
                              <div className="question-text-icon-container">
                                {data?.mandatory == 'yes' ? 
                                <div className="mandatory-question-indicator">
                                  <img src={data?.mandatory == 'yes' ? redStar : GrayStar} alt="" onClick={()=>{mandatoryToggle(data,index)}} />
                                </div> : 
                                  <>
                                    {isMandatoryVisible && mandatoryIndex==index ? <div className="mandatory-question-indicator">
                                      <img src={data?.mandatory == 'yes' ? redStar : GrayStar} alt="" onClick={() => {mandatoryToggle(data,index)}} />
                                      </div> : <div className="mandatory-question-indicator"></div>
                                    }
                                  </>
                                }
                                <div className="question-text-container">
                                  {data.question}
                                  <br />
                                  { data.answers?.length > 0 && data.type !== "textarea" ? <span>Answer Options: </span> : <></> }
                                  {data.type !== "textarea" &&
                                    data.answers?.map((answer, index) => {
                                      if (answer?.desktopText?.includes("<br/>")) {
                                        answer.desktopText = answer.desktopText.split("<br/>").join(" ").trim();
                                      }
                                      if (data?.answers?.length - 1 === index) {
                                        return (
                                          <span key={answer.id}>
                                            {answer?.desktopText}
                                          </span>
                                        );
                                      } else
                                        return (
                                          <span key={answer.id}>
                                            {answer?.desktopText + " . "}
                                          </span>
                                        );
                                    })}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell >
                              <div className="logic-icons">
                                <img src={JumpIcon} onClick={() => { handleJump(data.id)}} alt="Jump Logic Button" title="Jump Logic" style={{
                                  boxShadow: showJump && (data.id == showJumpId) ? "0 1px 8px 2px rgba(0, 0, 0, 0.3)" : "none", 
                                  borderRadius: showJump && (data.id == showJumpId) ? "30px" : "none",
                                }} />
                                <img src={DisplayIcon} onClick={() => { handleDisplay(data.id)}} alt="Display Logic Button" title="Display Logic" style={{
                                  boxShadow: showDisplay && (data.id == showDisplayId) ? "0 1px 8px 2px rgba(0, 0, 0, 0.3)" : "none", 
                                  borderRadius: showDisplay && (data.id == showDisplayId) ? "30px" : "none",
                                }} />
                              </div>
                              
                              {/* {showQuestionLogicComponent(data)} */}
                             
                            </TableCell>
                            <TableCell  onClick={()=>{ 
                              settoggleVariableBtn(false) 
                              console.log('data',data)
                            }}>
                              <div className="category-txt">
                                {sections?.length != 0 ? (
                                  sections.map((section) =>
                                    section?.id === data?.sectionId ? (
                                      <span key={section.id} dangerouslySetInnerHTML={{ __html: section?.section }} />
                                    ) : null
                                  )
                                ) : (
                                  <span>None</span>
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="center"  onClick={()=>{ settoggleVariableBtn(false)}}>
                              <div className="type-txt">
                                {showTypeIcons(data?.type)}
                                <p>{data?.type}</p>
                              </div>
                            </TableCell>
                            <TableCell align="center"  onClick={()=>{ }}>
                              <div className="variables-container">
                              <Snackbar open={ShowDuplicateVariableSnackbar} autoHideDuration={2000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                    variable already exists
                                  </Alert>
                              </Snackbar>
                                {toggleVariableBtn && toggleVariableBtnID == data?.id ? (
                                  <>
                                    <div className="ip-and-edit-cta">
                                      <input type="text" name="" id="" value={variableValue} onChange={(e)=>{
                                        let payload = questions[index];
                                        console.log('payload',payload)
                                        setvariableValue(e.target.value)
                                      }} />
                                      {
                                        <button
                                        style={{cursor:"pointer", border: "none"}}
                                        onClick={() => {
                                            !isLoading && variableUpdate(data,index)
                                          }}
                                        >
                                          {isLoading ? <CircularProgress color="white" style={{height:"10px",width:"10px"}}/> : 'Apply' }
                                        </button>
                                      }
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="text-icon-container">
                                      <p className="text">{data?.variable}</p>
                                      <img src={redEditIcon} alt="Edit Icon"
                                        onClick={() => {editVariable(data?.id,data)}}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="right" onClick={()=>{ settoggleVariableBtn(false)}}>
                              <div className="edit-and-trash-icon">
                                <Tooltip title="Edit Question">
                                  <img src={EditIcon} alt="Edit Icon" className="table-tool"
                                    onClick={() => drawerHandler({
                                      questionDetails: data, // clientId: id,
                                      clientId: sections[0].clientId, sections: sections, additional: false,
                                    })}
                                  />
                                </Tooltip>
                                <Tooltip title="Delete Question">
                                  <img src={deleteIcon} alt="Delete Icon" className="table-tool"
                                    onClick={() =>
                                      toggleModalHandler("question-delete", {
                                        questionDetails: data, 
                                        clientId: builderCtx.assessmentId, isAdditional:props.isAdditional
                                      })
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>

                          {/* </TableRow> */}
                          </ExpandableTableRow>
                        )}
                      </Draggable>
                    </> : <></>
                    // <Draggable
                    //   key={data.id.toString()}
                    //   draggableId={data.id.toString()} // Use a unique identifier as the draggableId
                    //   index={index}
                    // >
                    //   {(provided) => (
                    //     <TableRow
                    //       key={data.id}
                    //       {...provided.draggableProps}
                    //       ref={provided.innerRef}
                    //       onMouseEnter={()=>{
                    //         setisMandatoryVisible(true)
                    //         setMandatoryIndex(index)
                    //       }}
                    //       onMouseLeave={()=>{
                    //         setisMandatoryVisible(false)
                    //         setMandatoryIndex(null)
                    //       }}
                    //     >
                    //       <TableCell  onClick={()=>{ settoggleVariableBtn(false)}}>
                    //         <div className="order-and-drag-container" {...provided.dragHandleProps}>
                    //         <img src={DragIcon}/>
                    //         {index+1}
                    //         </div>
                    //       </TableCell>
                    //       <TableCell className="question-text"  onClick={()=>{ settoggleVariableBtn(false)}}>
                    //         <div className="question-text-icon-container">
                    //           {data?.mandatory == 'yes' ? 
                    //           <div className="mandatory-question-indicator">
                    //             <img src={data?.mandatory == 'yes' ? redStar : GrayStar} alt="" onClick={()=>{mandatoryToggle(data,index)}} />
                    //           </div> : 
                    //             <>
                    //               {
                    //                 isMandatoryVisible && mandatoryIndex==index ? <div className="mandatory-question-indicator">
                    //                   <img src={data?.mandatory == 'yes' ? redStar : GrayStar} alt="" onClick={()=>{mandatoryToggle(data,index)}} />
                    //                 </div> : <div className="mandatory-question-indicator"></div>
                    //               }
                    //             </>
                    //           }
                    //           <div className="question-text-container">
                    //             {data.question}
                    //             <br />
                    //             { data.answers?.length > 0 && data.type !== "textarea" ? <span>Answer Options: </span> : <></> }
                    //             {data.type !== "textarea" &&
                    //               data.answers?.map((answer, index) => {
                    //                 if (answer?.desktopText?.includes("<br/>")) {
                    //                   answer.desktopText = answer.desktopText
                    //                     .split("<br/>")
                    //                     .join(" ")
                    //                     .trim();
                    //                 }
                    //                 if (data?.answers?.length - 1 === index) {
                    //                   return (
                    //                     <span key={answer.id}>
                    //                       {answer?.desktopText}
                    //                     </span>
                    //                   );
                    //                 } else
                    //                   return (
                    //                     <span key={answer.id}>
                    //                       {answer?.desktopText + " . "}
                    //                     </span>
                    //                   );
                    //               })}
                    //           </div>
                    //         </div>
                    //       </TableCell>
                    //       <TableCell  onClick={()=>{ 
                    //         settoggleVariableBtn(false) 
                    //         console.log('data',data)
                    //       }}>
                    //         <div className="category-txt">
                    //           {sections?.length != 0 ? (
                    //             sections.map((section) =>
                    //               section?.id === data?.sectionId ? (
                    //                 <span key={section.id} dangerouslySetInnerHTML={{ __html: section?.section }} />
                    //               ) : null
                    //             )
                    //           ) : (
                    //             <span>None</span>
                    //           )}
                    //         </div>
                    //       </TableCell>
                    //       <TableCell align="center"  onClick={()=>{ settoggleVariableBtn(false)}}>
                    //         <div className="type-txt">
                    //           {showTypeIcons(data?.type)}
                    //           <p>{data?.type}</p>
                    //         </div>
                    //       </TableCell>
                    //       <TableCell align="center"  onClick={()=>{ }}>
                    //         <div className="variables-container">
                    //         <Snackbar open={ShowDuplicateVariableSnackbar} autoHideDuration={2000} onClose={handleClose}>
                    //           <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    //               variable already exists
                    //             </Alert>
                    //         </Snackbar>
                    //           {toggleVariableBtn && toggleVariableBtnID == data?.id ? (
                    //             <>
                    //               <div className="ip-and-edit-cta">
                    //                 <input type="text" name="" id="" value={variableValue} onChange={(e)=>{
                    //                   let payload = questions[index];
                    //                   console.log('payload',payload)
                    //                   setvariableValue(e.target.value)
                    //                 }} />
                    //                 {
                    //                   <button
                    //                   style={{cursor:"pointer"}}
                    //                   onClick={() => {
                    //                       variableUpdate(data,index)
                    //                     }}
                    //                   >
                    //                     {isLoading ? <CircularProgress color="white" style={{height:"10px",width:"10px"}}/> : 'Apply' }
                    //                   </button>
                    //                 }
                    //               </div>
                    //             </>
                    //           ) : (
                    //             <>
                    //               <div className="text-icon-container">
                    //                 <p className="text">{data?.variable}</p>
                    //                 <img src={redEditIcon}
                    //                   onClick={() => {
                    //                     editVariable(data?.id,data);
                    //                   }}
                    //                 />
                    //               </div>
                    //             </>
                    //           )}
                    //         </div>
                    //       </TableCell>
                    //       <TableCell align="right"  onClick={()=>{ settoggleVariableBtn(false)}}>
                    //         <div className="edit-and-trash-icon">
                    //           <Tooltip title="Edit Question">
                    //             <img src={EditIcon}
                    //               className="table-tool"
                    //               onClick={() =>
                    //                 drawerHandler({
                    //                   questionDetails: data,
                    //                   // clientId: id,
                    //                   clientId: sections[0].clientId,
                    //                   sections: sections,
                    //                   additional: false,
                    //                 })
                    //               }
                    //             />
                    //           </Tooltip>
                    //           <Tooltip title="Delete Question">
                    //             <img src={deleteIcon}
                    //               className="table-tool"
                    //               onClick={() =>
                    //                 toggleModalHandler("question-delete", {
                    //                   questionDetails: data,
                    //                   clientId: builderCtx.assessmentId,
                    //                   isAdditional:props.isAdditional
                    //                 })
                    //               }
                    //             />
                    //           </Tooltip>
                    //         </div>
                    //       </TableCell>
                    //     </TableRow>
                    //   )}
                    // </Draggable>
                  ))}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              // If the item was dropped outside of a valid drop target, do nothing
              return;
            }

            const sourceIndex = result.source.index;
            const destinationIndex = result.destination.index;

            if (result.source.droppableId === "tasks") {
              // Handle reordering of main questions
              const newQuestions = [...additionalQuestions]; // Use local state
              const [draggedItem] = newQuestions.splice(sourceIndex, 1);
              newQuestions.splice(destinationIndex, 0, draggedItem);
              console.log("New Order (Additional Questions):", newQuestions.map((item) => item.id));
              setAdditionalQuestions(newQuestions); // Update local state
            }
          }}
        >
        </DragDropContext>
      </TableContainer>
    </>
  );
}

export default AssessmentsQuestionsTable;



const ExpandableTableRow = ({ children, expandComponent, ...otherProps }) => {
  
  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    setIsExpanded(otherProps.isExpanded);
  }, [otherProps.isExpanded]);



  return (
    <>
      <TableRow {...otherProps} innerRef={otherProps.innerRef}>
        {children}
      </TableRow>
      {isExpanded && (
        <TableRow>
          <TableCell padding="checkbox" />
          {expandComponent}
        </TableRow>
      )}
    </>
  );
};


const QuestionDisplayLogic = (props) => {  

 return (
    <>
      {props?.logics?.map((data, index) => {
        return (<MoreQuestionsDisplayLogic key={data.id} logicData={data} index={index} {...props}  id={data.id} deleteDisplayLogic={props?.deleteDisplayLogic} handleChangeMorevariable={props?.handleChangeMorevariable} handleInputDisplay={props?.handleInputDisplay} handleChangeSigns={props?.handleChangeSigns} fncAddMoreLogic={() => props?.fncAddMoreLogic('display')} submitLogic={props?.submitLogic} />
        )})
      }

    </>
  );

}


// display row

const MoreQuestionsDisplayLogic = (props) => {

  console.log(props , "logicedata")
  const { id, logicData, deleteDisplayLogic, data , handleChangeMorevariable, handleChangeSigns, handleInputDisplay, fncAddMoreLogic, submitLogic } = props;
  if (props?.isGettingQuestions) {
    return <section className="loader-section" style={{minHeight:"10px"}}><CircularProgress /></section>
  }


  const handleDeleteDisplayLogic = (id, orderNo) => {
    if (orderNo) {
      props.toggleModalHandler('delete-logic-modal', {
        deleteDisplayLogic: () => props?.deleteDisplayLogic(id),
        id: id,
        logicType: "display",
        setIsUpdatedLogic: props?.setIsUpdatedLogic,
        isUpdatedLogic: props?.isUpdatedLogic


      })
    } else {
      props?.deleteDisplayLogic(id);
    }
  }

  const variablesArray = props?.questions.slice(0, props?.currentRowIndex+1).map(question => question?.variable);



  return (
    <div className={`${props?.index !== 0 ? 'break-line' : ''}`} key={logicData.id}> 
      <div className="display-container " >
        <div className="display-section extra-margin" >
           <div className="variable-input-box">
           <TextField select variant="outlined" className='choose-variable-select-tag' defaultValue="display" onChange={(e) => props.handleLogic(data?.id, e.target.value)}>
              <MenuItem className="section-name" value="display">Display</MenuItem>
            </TextField>
          </div>
         <div className="border-section">
          <div className="jump-section">
            <p>If</p>
           <div className="variable-input-box">
                <TextField select variant="outlined" className='choose-variable-select-tag' value={logicData?.variable} required onChange={(e) => handleChangeMorevariable(e.target.value, id,'display')} >

                {variablesArray?.filter((item)=>item != '').map((item, idx) => {
                  return <MenuItem className="section-name" key={idx} value={item}>{item}</MenuItem>
                })
                }

            </TextField>
          </div>
          <div className="variable-input-box">
                <TextField select variant="outlined" className='choose-variable-select-tag' value={logicData?.operator} required onChange={(e) => handleChangeSigns(e.target.value, id, 'display')} >
              {props.signsArray?.map((item,idx) => {
                return <MenuItem className="section-name" key={idx} value={item.option}>{item.option}</MenuItem>
              })}
            </TextField>
          </div>

          <div className="variable-input-box">
                <TextField variant="outlined" className='choose-variable-select-tag' required value={logicData?.value} onChange={(e)=>handleInputDisplay(e.target.value, id, 'display')}  ></TextField>
            </div>
          </div>
         </div>
           <p>then do not display question</p>
        </div>

        <div className="display-btns">
          <img src={deleteIcon} alt="delete" onClick={(e) => { handleDeleteDisplayLogic(id, logicData?.orderNo)}} />
          
          <div className="btn-group">
              {props?.isSubmittingLogic && props?.currentIndex == props?.index ?
                <Button color="primary" className="addmore-btn"><CircularProgress style={{ 'color': '#122845' }} size={25} /></Button> :
                <Button className="save-btn" type="submit"  disabled={!props.isValidItem('display', props?.index)} onClick={(e) => { props.submitLogic(e, 'display', props?.index) }} >Save Changes </Button>
              }
            {props.index == props?.logics?.length - 1 ?
                <Button className="addmore-btn" onClick={props.fncAddMoreLogic}>
                  + Add More
                </Button> :""
              }
          </div>
    

     
        </div>
      </div>
    </div>
  )
}


const findItemById = (array, id) => {
   
  for (const item of array) {
    if (item.id == id) {
      return item;
    }
    if (item.subLogics) {
      const subItem = findItemById(item.subLogics, id);
      if (subItem) {
        return subItem;
      }
    }
  }
  return null;
};
//to generate random ids for sublogics
const generateRandomId = (length) => {
  const characters = '123456789';
  let randomId = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomId += characters[randomIndex];
  }

  return randomId;
};


//jump logics
const QuestionJumpLogic = (props) => {

  let { jumpLogic, setJumpLogic } = props;

  const handleChangeOperator = (value, id, type, isSublogic, subId) => {
  
    let itemToUpdate;
    if (isSublogic) {
      itemToUpdate = findItemById(jumpLogic, subId);
    } else {
      itemToUpdate = findItemById(jumpLogic, id);
    }
  
    let subLogicsArray = jumpLogic?.map(item => {
      if (item.id == id) {
        return item.subLogics;
      }
    });
  
    if (itemToUpdate?.additional === "" || subLogicsArray?.length === 0) {
      const logicsArray = {
        id: generateRandomId(3),
        parentId: id,
        logicType: 'jump',
        variable: "",
        operator: "",
        value: "",
        additional: '',
        jumpTo: "",
      };
  
      if (isSublogic) {
        logicsArray.parentId = subId;
      }
  
      itemToUpdate.additional = value;
  
      const updatedJumpLogic = jumpLogic.map(item => {
        if (item.id === id) {
          item.subLogics = [...(item.subLogics || []), logicsArray];
        }
        return item;
      });
  
      setJumpLogic([...updatedJumpLogic]);
    } else {
      // Update the value only if it's not initially empty
      if (value !== "") {
        const updatedJumpLogic = jumpLogic.map(item => {
          if (!isSublogic && item.id == id) {
            item.additional = value;
          } else if (isSublogic && item.subLogics) {
            item.subLogics = item.subLogics.map(subLogic => {
              if (subLogic.id == subId) {
                subLogic.additional = value;
              }
              return subLogic;
            });
          }
          return item;
        });
  
        setJumpLogic([...updatedJumpLogic]);
      }
    }
  };
  

  if (props?.isGettingQuestions) {
    return <section className="loader-section" style={{ minHeight: "10px" }}><CircularProgress /></section>
  }

  const handleDeleteSubLogic = (parentId, id, subLogicId) => {
    setJumpLogic(prevJumpLogic => {
      return prevJumpLogic.map(parentLogic => {
        if (parentLogic.subLogics && parentLogic.subLogics.length > 0 && parentLogic.id == parentId || parentLogic?.id == id) {
          const updatedSubLogics = parentLogic.subLogics.filter(subLogic => subLogic.id !== subLogicId);

          parentLogic.subLogics.forEach((subLogic, subIndex) => {
            subLogic.additional = subIndex === updatedSubLogics.length - 1 ? '' :subLogic.additional ;
          });
          parentLogic.additional = updatedSubLogics.length === 0 ? '' : parentLogic.additional;
  
          const updatedParentLogic = { ...parentLogic, subLogics: updatedSubLogics, };

          return updatedParentLogic;
        }
        return parentLogic;
      });
    });
  };





  const questionsArray = Array.from({ length: Math.min(props?.questions?.length || 0) }, (_, index) => `${index+ 1}`);


  return (
    <>
      {props?.jumpLogic?.map((data, index) => {
      
      
        return (
          <MoreQuestionsJumpLogic key={index} jumpLogicData={data} index={index} {...props} id={data.id} questionsArray={questionsArray} handleChangeOperator={handleChangeOperator} handleDeleteSubLogic={handleDeleteSubLogic}  />
        )
      })}
    </>
  );
}


// jump logic row
const MoreQuestionsJumpLogic = (props) => {
  const { id, jumpLogicData, data, questionsArray, handleChangeMoreVariable, handleChangeSigns, handleInputDisplay  } = props;

  const handleDeleteJumpLogic = (id, orderNo) => {

    if (orderNo) {
      props.toggleModalHandler('delete-logic-modal', {
        deleteJumpLogic: () => props.deleteJumpLogic(id),
        id: id,
        logicType: "jump",
        setIsUpdatedLogic: props?.setIsUpdatedLogic,
        isUpdatedLogic: props?.isUpdatedLogic
      })
    } else {
      props.deleteJumpLogic(id);
    }
  }
  


  const variablesArray = props?.questions.slice(0, props?.currentRowIndex+1).map(question => question.variable);

 return (
   <div className={` ${props?.index !== 0 ? 'break-line' : ''}`} key={props?.index}>
     <div className="jump-container"> 
       <div className="jump-section extra-margin">
        <div>
          <div className="variable-input-box">

           <TextField select variant="outlined" className='choose-variable-select-tag' defaultValue='jump' required onChange={(e) => props.handleLogic(data?.id, e.target.value, true)}>
              <MenuItem className="section-name" value="jump">Jump</MenuItem>

            </TextField>
          </div>
        </div>
        <div className="border-section">
          <div className="jump-section">
            <span>If</span>
            <div className="variable-input-box">
               <TextField select variant="outlined" className='choose-variable-select-tag' value={jumpLogicData?.variable} required onChange={(e) =>handleChangeMoreVariable(e.target.value, id, 'jump')} >

                 {variablesArray?.filter((item)=>item != '').map((item, idx) => {
                  return <MenuItem className="section-name" key={idx} value={item}>{item}</MenuItem>
                })
                }

              </TextField>
            </div>
            <div className="variable-input-box">
               <TextField select variant="outlined" className='choose-variable-select-tag' value={jumpLogicData?.operator} required onChange={(e) => handleChangeSigns(e.target.value, id, 'jump')} >

                {
                  props.signsArray?.map((item, idx) => {
                    return <MenuItem className="section-name" key={idx} value={item.option}>{item.option}</MenuItem>
                  }
                  )}
              </TextField>
            </div>

            <div className="variable-input-box">
               <TextField variant="outlined" className='choose-variable-select-tag' value={jumpLogicData?.value} required onChange={(e) => {handleInputDisplay(e.target.value, id, 'jump') }} ></TextField>
            </div>
            <div className="variable-input-box">
               <TextField select variant="outlined" className='choose-variable-select-tag' value={jumpLogicData?.additional} onChange={(e) => props.handleChangeOperator(e.target.value, id, 'jump')} >
                <MenuItem className="section-name" value="AND">And</MenuItem>
               <MenuItem className="section-name" value="OR">Or</MenuItem>

              </TextField>
            </div>
          </div>
           {[jumpLogicData]?.map((data, index) => (
             data?.subLogics?.length > 0 &&
             data?.subLogics?.map((subLogic, subIndex) => (
               <React.Fragment key={subIndex}>
                 <AddMoreJumpConditions {...props}  variablesArray={variablesArray} subId={subLogic?.id} parentId={subLogic?.parentId} id={id} subLogic={subLogic} subIndex={subIndex} />
                 {subIndex === data?.subLogics?.length - 1 && (
                   <div className="btn-groups">
                     <img
                       src={deleteIcon}
                       alt="delete"
                       onClick={() => { props?.handleDeleteSubLogic(subLogic?.parentId, id, subLogic?.id) }}
                     />
                   </div>
                 )}
               </React.Fragment>
             ))
           )
           )}

       </div>

       </div>
      <div className="jump-section">
        <span style={{marginLeft:"0px"}}>then jump to</span>
        <div className="variable-input-box">
           <TextField select variant="outlined" className='choose-variable-select-tag' value={jumpLogicData?.jumpTo} onChange={(e) => { props.handleJumpQuestion(e.target.value, id) }} >
            {questionsArray.filter((item, idx)=>idx > props?.currentRowIndex).map((question, index) => (
              <MenuItem key={index} className="section-name" value={question}>
                Q{question}
              </MenuItem>
            ))}

          </TextField>
        </div>
      </div>

      <div className="jump-btns">
         <img src={deleteIcon} alt="delete" onClick={(e) => { handleDeleteJumpLogic(id,jumpLogicData?.orderNo)}} />

           <div className="btn-group">
           {props?.isSubmittingLogic && props?.currentIndex == props?.index ?
            <Button color="primary" className="addmore-btn"><CircularProgress style={{ 'color': '#122845' }} size={25} /></Button> :
             <Button className="save-btn" type="submit"  disabled={!props.isValidItem('jump', props?.index)} onClick={(e) => { props.submitLogic(e, 'jump', props?.index) }}>Save Changes </Button>
             }
           {props.index == props?.jumpLogic?.length - 1 ?

             <Button className="addmore-btn" onClick={(e) => props.fncAddMoreLogic('jump')}>
               + Add More
             </Button>
             :
             <></>
           }
          </div>
      </div>
     </div>

      </div>
  )
}

//subjumplogic rows
const AddMoreJumpConditions = (props) => {

  const { id, subId, subLogic, handleDeleteSubLogic, parentId } = props;
   return <div className="jump-section " key={props?.subIndex}>
    <div className="variable-input-box">
      <TextField select variant="outlined" className='choose-variable-select-tag' value={subLogic?.variable} required onChange={(e) => props.handleChangeMoreVariable(e.target.value, id, 'jump', 'sublogic', subId )} >

        {props?.variablesArray?.filter((item)=>item != '').map((item, idx) => {
          return <MenuItem className="section-name" value={item}>{item}</MenuItem>
        })
        }
      </TextField>
    </div> 
    <div className="variable-input-box">
      <TextField select variant="outlined" className='choose-variable-select-tag' value={subLogic?.operator} required onChange={(e) => props.handleChangeSigns(e.target.value, id, 'jump', 'sublogic', subId)} >
      {
          props.signsArray?.map((item) => {
            return <MenuItem className="section-name" value={item.option}>{item.option}</MenuItem>
          }
          )}
      </TextField>
    </div>

    <div className="variable-input-box">
      <TextField variant="outlined" className='choose-variable-select-tag' required value={subLogic?.value} onChange={(e) => { props.handleInputDisplay(e.target.value, id, 'jump', 'sublogic', subId ) }} ></TextField>
    </div>
    <div className="variable-input-box">
      <TextField select variant="outlined" className='choose-variable-select-tag' value={subLogic?.additional} onChange={(e) => props.handleChangeOperator(e.target.value, id, 'jump', true, subId)} >

        <MenuItem className="section-name" value="AND">And</MenuItem>
        <MenuItem className="section-name" value="OR">Or</MenuItem>
      </TextField>
    </div>    
  </div>
}