import { useState, useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import { CircularProgress } from '@mui/material';
import axios from 'axios';

import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import UsersContext from '../../store/UsersContext.js';

const UsersBulkDeleteConfirmation = (props) => {
  const { details } = props;
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const usersCtx = useContext(UsersContext);

  console.log("del modalCtx", modalCtx);
  console.log("del props", props);

  const [isDeleting, setIsDeleting] = useState(false);
  const [errorWhileDeleting, setErrorWhileDeleting] = useState(false);
  const userIDsToDelete = modalCtx?.details?.users;
  

  const handleUserBulkDelete = () => {

    setIsDeleting(true);
    setErrorWhileDeleting(false);

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/users/bulkDelete', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({users: modalCtx?.details?.users}),
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setIsDeleting(false);
      if (data.status === "success") {
        setErrorWhileDeleting(false);
        modalCtx.closeModal();
        modalCtx.setDetails(null);
        details.setAllUsersDetails((prev) => prev.filter((u) => !userIDsToDelete.includes(u.id)));
        usersCtx.updateUsersAfterDelete(userIDsToDelete);
        modalCtx.setPageUpdate({ page: "assessmentsUsers" });
        modalCtx.details?.setRefreshPageToggle((prev) => !prev);
      } else {
        console.log("failed to delete user/s...");
      }
    })
    .catch((err) => {
      console.error("failed to delete user/s...", err);
      setIsDeleting(false);
      setErrorWhileDeleting(true);
    });
    
  }

  const getUsersText = () => {
    if(modalCtx?.details.users.length == 1) {
      //return "this user?";
      return "this 1 user?";
    } else {
      return "these " + modalCtx?.details.users.length + " users?";
    }
  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  return (
    <div className="modal">
      
      <p>Are you sure you want to delete {getUsersText()}<br/>This process cannot be undone.</p>
      
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        { isDeleting ? 
          <Button className="confirm-btn"><CircularProgress style={{'color': '#fff'}} size={16} /></Button> : 
          <Button className="confirm-btn" onClick={() => { handleUserBulkDelete() }}>Yes</Button>
        }
      </div>
      {errorWhileDeleting && <div className="error-text" style={{ color: "#E14949", textAlign: "center" }}>
        <p>There was an error while deleting the user/s. Please try again after refreshing this page.</p>
        <Button className="green-btn" 
          onClick={() => {
            modalCtx.setPageUpdate({ page: "assessmentsUsers" });
            handleCloseModal();
          }}>Refresh</Button>
      </div>}
    </div>
  )
}

export default UsersBulkDeleteConfirmation;
