
import React, { useEffect, useRef, useState } from "react";
import * as d3 from "d3";

const AssessmentPieChart = (props) => {
  console.log(props)

  let chartData = props.data
  const data = [{ label: 'Assessment Started', count: 10 }, { label: 'Assessment Completed', count: 90 }];

//    [{ value: 10, label: 'lol1' }, { value: 20, label: 'lol2' }, { value: 20, label: 'lol3' }, { value: 30, label: 'lol4' }, { value: 20, label: 'lol5' }]

  let width = 500
  let height = 500
  let innerRadius = 70
  let outerRadius = 100

  const createPie = d3
    .pie()
    .value(d => +d.count)
    .sort(null);

  //arc for texts
  const createArc2 = d3
    .arc()
    .innerRadius(170)
    .outerRadius(190);

  //arc for lines
  const createWhiteArc = d3
    .arc()
    .innerRadius(0)
    .outerRadius(70);

  //color range
  var colorScale = d3.scaleOrdinal()
    .domain(data.map(i => i.label))
    .range(['#57606F', '#212A42', '#F2D466', '#E14949', "#B5E1EE",]);

  //ref
  const chartLabelRef = useRef(null)


  //states
  const [pieData, setPieData] = useState(null)
  const [hoverData, setHoverData] = useState(null)

  useEffect(() => {
    if (data !== null) {
      setPieData(createPie(data))
    }
  }, []);


  const testFunction = (test) => {
    console.log(test)
  }

  const getArcPath = (d) => {
    // if (d.data.label === hoverData?.data.label) {
    //   outerRadius = 110;
    // } else {
    //   outerRadius = 105;
    // }
    const createArc = d3
      .arc()
      .innerRadius(innerRadius)
      .outerRadius(outerRadius)
      .cornerRadius(.7)
      .padAngle(hoverData && 0.005)

    return <path style={{ transition: '300ms ease-in-out' }} className="arc" d={createArc(d)} fill={hoverEffect(d)} ></path>
  }

  const getWhiteArcPath = (d) => {
    return <path d={createWhiteArc({ endAngle: 100, index: 0, padAngle: 0, startAngle: 0 })} fill={"#F1F1F1"} ></path>
  }

  const hoverEffect = (d) => {
    if (hoverData && d.data.label !== hoverData?.data.label) {
      return '#ddd'
    } else if (d.data.label == hoverData?.data.label) {
      return colorScale(d.data.label)
    } else {
      return colorScale(d.data.label)
    }
  }

  const hoverEffectText = (d) => {
    if (hoverData && d.data.label !== hoverData?.data.label) {
      return { fontSize: '12px', fontWeight: '600', color: "#ddd", textAlign: 'center', width: '100%', transition: '700ms ease-in-out' }
    } else {
      return { fontSize: '12px', fontWeight: '600', color: `${colorScale(d.data.label)}`, textAlign: 'center', width: '100%', transition: '700ms ease-in-out' }
    }
  }
  const hoverEffectText2 = (d) => {
    if (hoverData && d.data.label !== hoverData?.data.label) {
      return { height: '50px', width: '50px', background: "#ddd", fontSize: '12px', fontWeight: '600', color: '#FFF', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: '700ms ease-in-out' }
    } else {
      return { height: '50px', width: '50px', background: `${colorScale(d.data.label)}`, fontSize: '12px', fontWeight: '600', color: '#FFF', borderRadius: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', transition: '700ms ease-in-out' }
    }
  }
  const hoverEffectText3 = (d) => {
    if (hoverData && d.data.label !== hoverData?.data.label) {
      return { height: '5px', width: '60px', background: "#ddd", transition: '700ms ease-in-out' }
    } else {
      return { height: '5px', width: '60px', background: `${colorScale(d.data.label)}`, transition: '700ms ease-in-out' }
    }
  }



  return (
    <svg id="chart-svg" width={width} height={height} style={{ overflow: 'visible', position: 'relative' }}>
      <g transform={`translate(${height / 2} ${width / 2})`}>
        {
          pieData
            ? pieData?.map((d, i) => {
              var midAngle = d.endAngle < Math.PI ? d.startAngle / 2 + d.endAngle / 2 : d.startAngle / 2 + d.endAngle / 2 + Math.PI;

              return <g className="arc" key={i} onMouseEnter={() => setHoverData(d)} onMouseLeave={() => setHoverData(null)} >
                <line style={{ transition: '700ms ease-in-out' }} stroke={hoverData && d.data.label !== hoverData?.data.label ? "#ddd" : colorScale(d.data.label)} strokeWidth={4} x1={createArc2.centroid(d)[0]} y1={createArc2.centroid(d)[1]} x2={0} y2={0} />
                {getArcPath(d)}
                {/* <text textAnchor="middle" alignmentBaseline="middle" transform={`translate(${createArc2.centroid(d)[0]}, ${createArc2.centroid(d)[1] - 30} )`} style={hoverEffectText(d)}>{d.data.label}</text> */}
                {/* <text textAnchor="middle" alignmentBaseline="middle" transform={`translate(${createArc2.centroid(d)[0]}, ${createArc2.centroid(d)[1]} )`} style={hoverEffectText2(d)}>{d.data.count}%</text> */}
                <foreignObject x="-25" y="-20" transform={`translate(${createArc2.centroid(d)[0]}, ${createArc2.centroid(d)[1]} )`} width="50" height="50">
                  <div className="chart-text" style={hoverEffectText2(d)} >
                    {d.data.count}
                  </div>
                </foreignObject>
                {/* <foreignObject x="-25" y="0" transform={`translate(${createArc3.centroid(d)[0]}, ${createArc3.centroid(d)[1]} )  rotate(-90) rotate(${(midAngle * 180 / Math.PI)})`} width="60" height="5">
                  <div className="chart-text" style={hoverEffectText3(d)} >
                  </div>
                </foreignObject> */}
                <foreignObject ref={chartLabelRef} x="-50" y={d.data.label?.length > 14 ? "-70" : "-50"} transform={`translate(${createArc2.centroid(d)[0]}, ${createArc2.centroid(d)[1]} )`} width="100" height="70">
                  <p className="chart-label" style={hoverEffectText(d)} >{d.data.label}</p>
                </foreignObject>
                {getWhiteArcPath(d)}
              </g>
            })
            : null
        }

      </g>
    </svg>
  );

};

export default AssessmentPieChart;