import React, { useContext, useState } from 'react'
import ModalContext from '../../store/ModalContext';
import { CircularProgress, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';



import cross from '../../../src/assets/img/cross.png';
import AuthContext from '../../store/AuthContext';


const EditEmailNotification = (props) => {
    const modalCtx = useContext(ModalContext);
    const authCtx = useContext(AuthContext);

    const [upLoadingImage, setUpLoadingImage] = useState(false);
    const [senderName, setSenderName] = useState(props?.details?.row?.sendersName);
    const [senderEmail, setSenderEmail] = useState(props?.details?.row?.sendersEmail);
    const [subject, setSubject] = useState(props?.details?.row?.subject);
    const [emailType, setEmailType] = useState(props?.details?.row?.emailType);
    const [awsImageUrl, setAwsImageUrl] = useState(props?.details?.row?.headerImage);
    const [isCreating, setIsCreating] = useState(false);


    const handleCloseModal = () => {
        modalCtx.setDetails(null);
        modalCtx.closeModal();
    }


  const handleSubmit=(e)=>{
    e.preventDefault();
    setIsCreating(true)

    if (awsImageUrl && senderName && senderEmail && emailType && subject) {
      const bodydata = {
        "templateId": 1,
        "subject": subject,
        "sendersName": senderName ,
        "sendersEmail": senderEmail ,
        "emailType": emailType,
        "headerImage": awsImageUrl
         }
        fetch(process.env.REACT_APP_API_URI + "/v3/admin/assessment/" + props?.details?.row?.assessmentId + "/email/" + props?.details?.row?.id +"/update" , {
        method: 'PUT',
        headers: { 'Content-Type':'application/json',  'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(bodydata),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          setIsCreating(false);
          if (data.status === "success") {
            console.log("successfully added ...");
            fncGetEmailData();
            }
            handleCloseModal()
        })
        .catch((err) => console.error("failed to add...", err));
    }


  }
    
    
  const getImageUrl = (e) => {
    e.stopPropagation();
    setUpLoadingImage(true)

    const formData = new FormData();
    formData.append('image', e.target.files[0]);

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + props?.details?.row?.assessmentId + '/updateImage', {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: formData,

    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          setAwsImageUrl(data?.imagePath)
            setUpLoadingImage(false)

        }
      }).catch(err => console.error(err));

  }
    
    
    const fncGetEmailData =() => {
        fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + props?.details?.row?.assessmentId + '/emails', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authCtx.token}`,
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        //   setNotificationDataRow(data?.data)
          props?.details?.api?.setRows(data?.data);
      } else {
      }
    });

   }
    
  return (
      <div className="modal edit-notification-container">
          <div className="modal-content">
              <div className="main-content">

                  <div className="heading-container">
                      <div className="heading">Edit Notification</div>
                      <img src={cross} onClick={() => { handleCloseModal() }} alt="cross" />
                  </div>
                  <form onSubmit={handleSubmit}>

                  <div className="items">
                      <div className="label">Header Image</div>
                      <label className="upload-container">
                    <input type="file" onChange={(e)=>{getImageUrl(e)}}  />
                      {upLoadingImage ? <section className="loader-section-uploaing"><CircularProgress /> Uploading</section> : <img src={awsImageUrl} alt="upload" /> }
                </label>
                  </div>
                  <div className="items">
                      <div className="label">Senders Name</div>
                      <input className="text-box" type={"text"} value={senderName} placeholder={"Senders Name"} onChange={(e) => { setSenderName(e.target.value) }} />
                  </div>
                  <div className="items">
                      <div className="label">Senders Email</div>
                      <input className="text-box" type={"text"}  value={senderEmail} placeholder={"Senders Email"} onChange={(e) => { setSenderEmail(e.target.value) }} />
                  </div>

                  <div className="items">
                      <div className="label">Subject</div>
                      <input className="text-box" type={"text"} placeholder={"Subject"} value={subject} onChange={(e) => { setSubject(e.target.value) }} />
                  </div>
                  <div className="items">
                      <div className="label">Type of Email</div>
                      <div className="row-section">
                          <RadioGroup
                              aria-labelledby="row-radio-buttons"
                              name="row-buttons"
                              value={emailType}
                              onChange={(e) => { setEmailType(e.target.value) }}
                          >
                              <FormControlLabel value="Custom Notification" control={<Radio />} label="Custom Notification" />
                              <FormControlLabel value="Email Summary" control={<Radio />} label="Email Summary" />
                          </RadioGroup>
                      </div>
                  </div>
                      {isCreating ?
                         <button className="create-btn-submit" disabled> <CircularProgress /></button> :
                          <button className="create-btn-submit" type="submit" >Save Changes</button>
                      }
                  </form>
              </div>
          </div>
      </div>
  )
}

export default EditEmailNotification
