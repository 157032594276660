import { useEffect, useState, useContext, useRef, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { format, utcToZonedTime } from 'date-fns-tz';

import { Close, Delete, HelpOutline } from '@mui/icons-material';
import { Box, Button, IconButton, Paper, Snackbar, Tab, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tabs, TextField, Tooltip } from '@mui/material';
import { styled } from "@mui/material/styles";

import CopyLinkBtn from '../CopyLinkBtn';

import DrawerContext from '../../store/DrawerContext';
import ModalContext from '../../store/ModalContext';
import SubDrawerContext from '../../store/SubDrawerContext';
import UsersContext from '../../store/UsersContext';

import { getCentralDateTime } from '../../globalFunctions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



// const UserDetailsDrawer = ({ userDetails }) => {
const UserDetailsDrawer = (props) => {

  const StyledTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    zIndex: theme.zIndex.tooltip + 1,
    //margin: 4,
    [`& .MuiTooltip-tooltip`]: {
      // maxWidth: 200, height: 100, color: "deepskyblue", // see sx value
      // margin: 4, padding: 8, border: "solid yellow 1px"
      fontFamily: "Lato, Helvetica", fontSize: "15px", color: "#212A42", 
      backgroundColor: "rgba(0,0,0,0.04)", whiteSpace: "pre-line",
    }
  }));

  console.log("UserDetailsProps", props); console.log("props?.userDetails", props?.userDetails);
  const modalCtx = useContext(ModalContext);
  const usersCtx = useContext(UsersContext);
  const drawerCtx = useContext(DrawerContext);
  const subdrawerCtx = useContext(SubDrawerContext);
  
  const [tabValue, setTabValue]                   = useState(0);
  const [assessmentDetails, setAssessmentDetails] = useState(null);
  const [copySuccess, setCopySuccess]             = useState("");
  const copyLinkRef                               = useRef(null);
  const [showCopied, setShowCopied]               = useState(false);
  const [openSnackbar, setOpenSnackbar]           = useState(false);
  const [snackbarMessage, setSnackbarMessage]     = useState("Copied link to clipboard");
  const [showIframe, setShowIframe]               = useState(true);
  const [sections, setSections]                   = useState([]);
  const [questions, setQuestions]                 = useState([]);
  const [additionalFormData, setAdditionalFormData] = useState([]);
  const [additionalQnA, setAdditionalQnA]         = useState(null);
  const [additionalFormQnA, setAdditionalFormQnA] = useState(null);
  const [feedbackQnA, setFeedbackQnA]             = useState(null);
  const [assessments, setAssessments]             = useState([]);
  const [reloadAssessments, setReloadAssessments] = useState(false);
  const [isLoading, setIsLoading]                 = useState(false);
  const [currentUser, setCurrentUser]             = useState(usersCtx.allUsers?.find(u => u.id === props?.userDetails));
  
  let userDetails = {
    userInfo: currentUser, 
    mainAssessmentId: usersCtx?.assessmentId, 
    hasAdditional: usersCtx?.assessmentDetails?.hasAdditional, 
    assessmentType: usersCtx?.assessmentDetails?.assessmentType || "", 
    url: usersCtx?.assessmentDetails?.url || "", 
    assessmentTitle: usersCtx?.assessmentDetails?.title, 
    // tabValue: 0
  }
  
  const [userInfoData, setUserInfoData]           = useState(userDetails?.userInfo);
  const [additionalQs, setAdditionalQs]           = useState(userDetails?.additionalQuestions || null);
  const { assessmentTitle, hasAdditional, url, assessmentType, userInfo, leaderInfo, mainAssessmentId } = userDetails;
  const userType = currentUser?.userType;
  
  const userAnswers                               = userInfoData?.answers;
  const userAdditionalAnswers                     = userInfoData?.addAnswers;
  const assessmentId                              = userInfoData?.assessmentId;
  
  useEffect(() => {
    let currUser = usersCtx.allUsers?.find(u => u.id === props?.userDetails);
    if (!currUser) {
      currUser = usersCtx.allUsers?.find((u) => (u.id === assessments?.[0]?.userId || u.id === assessments?.[0]?.id));
    }
    currUser && setCurrentUser(currUser);
    // setUserInfoData(currUser);
    if (assessments?.length > 1) {
      setShowIframe(false);
    } else if ((assessments?.length == 1) && (assessments?.[0]?.status?.toLowerCase() == "pending")) {
      setShowIframe(false);
    } else if ((assessments?.length == 1) && ((assessments?.[0]?.status?.toLowerCase() == "complete") || (assessments?.[0]?.status?.toLowerCase() == "completed"))) {
      setShowIframe(true);
    }
  }, [assessments, assessments?.length, usersCtx.allUsers, usersCtx.allUsers?.length, props?.userDetails]);

  useEffect(() => {
    const propsIdInUsersCtx = usersCtx.allUsers?.find(u => u.id === props?.userDetails);
    if (props?.userDetails && propsIdInUsersCtx) {
      let newUser = usersCtx.allUsers?.find(u => u.id === props?.userDetails);
      newUser && setUserInfoData(newUser);
    } else {
      let currUser = usersCtx.allUsers?.find((u) => (u.id === assessments?.[0]?.userId || u.id === assessments?.[0]?.id));
      currUser && setUserInfoData(currUser);
    }
    
    (tabValue == 1 && assessments?.length <= 1) && setTabValue(0);
    (tabValue == 2 && userInfoData?.referrals?.length <= 1) && setTabValue(0);

  }, [assessments, assessments?.length, userInfoData?.referrals?.length, props?.userDetails, usersCtx.allUsers, usersCtx.allUsers.length]);
  
  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };
  
  // console.log("userInfo", userInfo, "leaderInfo", leaderInfo);
  // const assessmentsWithAdditionalForm = [17, 18, 23, 25]; // Bleat ChMS, TP1, TP2, TP3

  const assessmentsWithAdditionalForm = [23]; // TP2 
  const showAdditionalFormData = assessmentsWithAdditionalForm.includes(parseInt(mainAssessmentId));
  // console.log("mainAssessmentId", mainAssessmentId); console.log("showAdditionalFormData", showAdditionalFormData);
  console.log("additionalQnA Additional Questions", additionalQnA);
  console.log("additionalQs", additionalQs);

  useEffect(() => {
    if(modalCtx.pageUpdate?.page === "userDetailsDrawer") {
      console.log("\n\nRefetching assessments...");
      setReloadAssessments((prevVal) => !prevVal);
      modalCtx.setPageUpdate({ page: "" });
    }
  }, [modalCtx, modalCtx.pageUpdate?.page])

  useEffect(() => {
    if(hasAdditional === true || hasAdditional === "yes") {
      setIsLoading(true);
      fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + mainAssessmentId + '/additional', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        if(data.status === "success") {
          console.log("Additional Qs API data", data);
          setIsLoading(false);
          setAdditionalQs(data?.questions);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.error("Failed to fetch additional questions", err);
      });
    
    } 
    
    // Show Questions and Answers from Feedback Section in Assessment Questions 
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + mainAssessmentId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        console.log("Questions API data", data);
        setIsLoading(false);
        setQuestions(data?.questions);
        setSections(data?.sections);
      }
    })
    .catch((err) => {
      setIsLoading(false);
      console.error("Failed to fetch questions", err);
    });

  }, [hasAdditional, mainAssessmentId]);

  useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v2/user/' + userInfoData?.id + '/assessment/' + mainAssessmentId, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        console.log("Assessments", data);
        setIsLoading(false);
        setAssessments(data.data);
      }
      // setAdditionalQs(data?.questions);
    })
    .catch((err) => {
      setIsLoading(false);
      console.error("Failed to fetch assessments", err);
    });

  }, [userInfoData, mainAssessmentId, reloadAssessments]);

  useEffect(() => {
    const newAssessments = assessments?.filter((obj) => usersCtx.allUsers.some(u => (u.id === obj.userId || u.id === obj.id)));
    const moreDatafromUsers = usersCtx.allUsers?.filter(u => newAssessments?.some(a => (a.userId === u.id || a.id === u.id)));
    const moreMembersDataFromUsers = moreDatafromUsers?.filter(u => u.userType !== "leader");
    setAssessments(moreMembersDataFromUsers);
  }, []);

  useEffect(() => {

    const additionalQnA = userAdditionalAnswers?.map((ans, idx) => {
      const additionalQuestion = additionalQs?.find(q => q.id === ans.questionId);
      let answer = ans?.answerTxt;
      if(!ans.answerTxt && ans?.answer) {
        answer = additionalQuestion?.answers?.find(a => a.value == ans?.answer)?.desktop_text;
      }
      ans.answerTxt = answer;
      ans.question = additionalQuestion?.question;
      return ans;
    });
    // console.log("userAdditionalAnswers", userAdditionalAnswers);

    setAdditionalQnA(additionalQnA);

  }, [additionalQs, userAdditionalAnswers, userAdditionalAnswers?.length]);

  // Show Questions and Answers from Feedback Section in Assessment Questions 
  useEffect(() => {
    
    const feedbackSection = sections?.find(sec => sec.section?.toLowerCase() === "feedback");
    // console.log("feedbackSection", feedbackSection);
    const feedbackQsWithOptions = questions?.filter(q => q.sectionId === feedbackSection?.id);
    
    feedbackQsWithOptions?.forEach((fq, idx) => {
      const matchingQ = userAnswers?.find((a) => a.questionId === fq.id);
      let answer = matchingQ?.answerTxt;
      if(!matchingQ.answerTxt && matchingQ?.answer) {
        answer = fq?.answers?.find(a => a.value == matchingQ?.answer)?.desktop_text;
      }
      fq.answer = matchingQ.answer || 0;
      fq.questionId = fq.id;
      fq.answerTxt = answer || "";
    })
    // console.log("feedbackQsWithOptions", feedbackQsWithOptions);

    setFeedbackQnA(feedbackQsWithOptions);

  }, [sections, questions, userAnswers]);

  useEffect(() => {
    
    if(showAdditionalFormData) {
      setIsLoading(true);
      fetch(process.env.REACT_APP_API_URI + '/v2/user/assessment/' + assessmentId + '/additionalFormDetails', {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        console.log("Additional Form data", data);
        setIsLoading(false);
        setAdditionalFormData(data?.pages);
      })
      .catch(err => {
        setIsLoading(false);
        console.error("Failed to fetch additional form data", err);
      });
    }
 
  }, [showAdditionalFormData, assessmentId]);

  useEffect(() => {
    const additionalFormQuestionsArr = ["Current ChMS", "Number of staff members using ChMS", "Current weekend attendance", "Weekend attendance goal in 1 year", "Annual church budget ($)", "Annual payment to ChMS ($)"];
    if(additionalFormData?.length) {
      const formQnA = additionalFormData?.map((item, idx) => {
        item.question = additionalFormQuestionsArr[idx];
        return item;
      });
      setAdditionalFormQnA(formQnA);
    }
  }, [additionalFormData]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if(showCopied) {
        setShowCopied(false);
        setOpenSnackbar(false);
      };
    }, 3000);
    return () => clearTimeout(timer);
  }, [showCopied]);

  function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};
  
  function toggleSubDrawerHandler(drawer, user) {
		subdrawerCtx.openDrawer();
		subdrawerCtx.setDetails(drawer, user);
	};

  console.log("\n\ndrawerCtx", drawerCtx.drawer, drawerCtx.open);
  console.log("subdrawerCtx", subdrawerCtx.drawer, subdrawerCtx.open, "\n\n");

  function handleOpenDrawers(user) {
    
    const drawer    = "user-details";
    const subdrawer = "user-details-subdrawer";

    if(!drawerCtx.open && !subdrawerCtx.open) {
      toggleDrawerHandler(drawer, user);
    } else if(drawerCtx.open && !subdrawerCtx.open) {
      toggleSubDrawerHandler(subdrawer, user);
    } else if(drawerCtx.open && subdrawerCtx.open) {
      toggleDrawerHandler(drawer, user);
      subdrawerCtx.closeDrawer();
    } else {
      toggleDrawerHandler(drawer, user);
      subdrawerCtx.closeDrawer();
    }

  }

  function handleCloseDrawers() {
    if(drawerCtx.open && subdrawerCtx.open) {
      subdrawerCtx.closeDrawer();
    } else if(drawerCtx.open && !subdrawerCtx.open) {
      drawerCtx.closeDrawer();
    } else if(!drawerCtx.open && subdrawerCtx.open) {
      subdrawerCtx.closeDrawer();
    } else {
      drawerCtx.closeDrawer();
      subdrawerCtx.closeDrawer();
    }
  }

  useEffect(() => {
    // /assessment/:id
    if(userInfoData?.uniqueId) {
      fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + userInfoData?.uniqueId, {
        method: 'GET',
        headers: { 'Content-Type': 'application/json' }
      })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        setAssessmentDetails(data);
      })
      .catch(err => console.error(err));
    }
    
  }, [userInfoData?.uniqueId])

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleDeleteInvite = (e, referral) => {
    // console.log("referral", referral);
    toggleModalHandler("delete-user-invite", referral);
  }

  const referralsOrTeam = assessmentType === "team" ? "Team Members" : "Referrals";

  const showIframeResults = (resultLink) => {

    if(showIframe) {
      return <div className="iframe-results" style={{ height: "76vh" }}>
      <iframe src={resultLink} title={resultLink} frameBorder="0" height="100%" width="100%" />
    </div>
    } else return null;
  }

  const getResultsUrl = () => {
    // console.log("url", url);
    console.log("Results Page URL: \n", url.trim() + "/results/" + userInfoData?.uniqueId);
    return url.trim() + "/results/" + userInfoData?.uniqueId;
  }
  
  const getDashboardUrl = () => {
    // console.log("url", url);
    console.log("Dashboard Page URL: \n", url.trim() + "/dashboard/" + userInfoData?.uniqueId);
    return url.trim() + "/dashboard/" + userInfoData?.uniqueId;
  }

  let showReferralsTab = assessmentType === "referal" || (assessmentType === "team" && userInfoData?.userType === "leader");
  
  // let showScoresTab = userInfoData?.userType === "leader" || (userInfoData?.userType === "member" && userInfoData?.uniqueId) || (assessments?.length > 1) || (assessments?.length === 1 && assessments?.[0].status === "complete");

  const [showScoresTab, setShowScoresTab] = useState(false);

  let showMemberReferralLink = userInfoData?.userType === "member" && !userInfoData?.uniqueId;
  
  let showGroupDashboardBtn = (assessmentType === "team" && userInfoData?.userType === "leader");

  let hideForMembers = userInfoData?.userType !== "member";

  let leaderInviteLink = url?.trim() + "/invite-team/" + userInfoData?.uniqueId;

  let usersReferralLink = url?.trim() + "/?leader=" + userInfoData?.leaderInfo?.uniqueId + "&user=" + userInfoData?.id;


  useEffect(() => {
    let showScoresTab = userInfoData?.userType === "leader" || (userInfoData?.userType === "member" && userInfoData?.uniqueId) || (assessments?.length > 1) || (assessments?.length == 1 && ((assessments?.[0].status?.toLowerCase() === "complete") || (assessments?.[0].status?.toLowerCase() === "completed")));
    setShowScoresTab(showScoresTab);
  }, [userInfoData, userInfoData?.userType, assessments, assessments?.length]);


  const enDash = <>&#8211;</>;
  const centralTimeZone = 'America/Chicago';
  const pacificTimeZone = 'America/Los_Angeles';

  useEffect(() => {
    const newAssessments = assessments?.filter((obj) => usersCtx.allUsers.some(u => (u.id === obj.userId || u.id === obj.id)));
    const moreDatafromUsers = usersCtx.allUsers?.filter(u => newAssessments?.some(a => (a.userId === u.id || a.id === u.id)));
    const moreMembersDataFromUsers = moreDatafromUsers?.filter(u => u.userType !== "leader");
    setAssessments(moreMembersDataFromUsers);
  }, [usersCtx.allUsers, usersCtx.allUsers.length]);

  return (
    <div className="user-details drawer">

      <header className="user-details-header">
        <h1>{userInfoData?.name}</h1>
        <Close className="close-user-details-drawer" onClick={() => handleCloseDrawers()} />
      </header>

      <section>

        <div className="tab-container">

          <Box sx={{ width: '100%' }}>
            <Box className="box" sx={{ borderBottom: 1, borderColor: '#EDEDED' }}>
              <Tabs TabIndicatorProps={{ style: { background: '#000000' } }} value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab className="tab-title" label="User Details" {...a11yProps(0)} />
                {showScoresTab && <Tab className="tab-title" label="Assessment Scores" {...a11yProps(1)} />}
                { showReferralsTab && <Tab className="tab-title" label={`${referralsOrTeam} - ${userInfoData?.referrals?.length || 0}`} {...a11yProps(2)} /> }
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0}>
                {/* {userDetails.tabValue == 2 && ( */}
                <div className="user-referrals display-block">
                  {showMemberReferralLink && <div className="referral-link-container">
                    <p className="referral-link-text">{leaderInfo?.name ? leaderInfo.name.split(" ")[0] + "'s" : "User's"} assessment link: </p>
                    <TextField ref={copyLinkRef} className="referral-link" label="" variant="outlined" value={usersReferralLink} readOnly disabled />
                    <CopyLinkBtn btnText="COPY" link={usersReferralLink} clsName="copy-referral-btn" />
                    
                  </div>}
                </div>
                {/* )} */}
              <div className="info-section">
                <p className="info-title">Email</p>
                <p className="info-value">{userInfoData?.email}</p>
              </div>
              <div className="user-personal-container">
                <div className="info-section">
                  <p className="info-title">Job Title</p>
                  <p className="info-value">{userInfoData?.jobTitle || enDash}</p>
                </div>
                { hideForMembers && <>
                <div className="info-section">
                  <p className="info-title">{userInfoData?.churchName?.trim() ? "Church Name" : "Organization Name"}</p>
                  <p className="info-value">{userInfoData?.churchName || userInfoData?.orgName || enDash}</p>
                </div>
                <div className="info-section">
                  <p className="info-title">ZIP Code</p>
                  <p className="info-value">{userInfoData?.zipCode || enDash}</p>
                </div>
                <div className="info-section">
                  <p className="info-title">City</p>
                  <p className="info-value">{userInfoData?.city || enDash}</p>
                </div>
                <div className="info-section">
                  <p className="info-title">State</p>
                  <p className="info-value">{userInfoData?.state || enDash}</p>
                </div>
                <div className="info-section">
                  <p className="info-title">Country</p>
                  {/* <p className="info-value">{userInfoData?.zipCode ? "United States" : enDash}</p> */}
                  <p className="info-value">{userInfoData?.country || "United States"}</p>
                </div>
                </>}
                {userInfoData?.churchWebsite && <div className="info-section">
                  <p className="info-title">Church Website</p>
                  <p className="info-value">{userInfoData?.churchWebsite || enDash}</p>
                </div>}
                {userInfoData?.avgWeeklyAttendance && <div className="info-section">
                  <p className="info-title">Average Weekly Attendance</p>
                  <p className="info-value">{userInfoData?.avgWeeklyAttendance || enDash}</p>
                </div>}
                {userInfoData?.chms && <div className="info-section">
                  <p className="info-title">Current ChMS</p>
                  <p className="info-value">{userInfoData?.chms || enDash}</p>
                </div>}
                {userInfoData?.denomination && <div className="info-section">
                  <p className="info-title">Denomination</p>
                  <p className="info-value">{userInfoData?.denomination || enDash}</p>
                </div>}
              </div>

            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <div className="user-score-details user-referrals">
              {
                // only show table when there are more than 1 assessments per user 
                // otherwise show results for single assessment 
                assessments?.length > 1 && userInfoData?.userType === "member" ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }} aria-label="Referrals Table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">No.</TableCell>
                          <TableCell align="left">Invited By</TableCell>
                          <TableCell align="left">Date</TableCell>
                          <TableCell align="left">Time (CT)</TableCell>
                          <TableCell align="left">Assessment Status</TableCell>
                          <TableCell align="left">Actions</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {assessments?.map((assessment, index) => {
                          // console.log("assessment", assessment); console.log("url", url);
                          let dateTime = assessment?.date;
                          // adding Z is for zero offset since datetime is already in UTC 
                          // let utcDate = new Date(dateTime?.replace(/ /g,"T") + "Z").toISOString(); // UTC Time
                          let utcDate;
                          if (dateTime && !dateTime.includes('T') && !dateTime.includes('Z')) {
                            utcDate = new Date(dateTime.replace(/ /g, 'T') + 'Z').toISOString();
                          } else {
                            // Handle the case when the date string is already in the UTC format
                            utcDate = dateTime;
                          }
                          // let localeTime = new Date(utcDate).toLocaleTimeString([], { hour12: false });
                          let localeDateTime = "Local " + (new Date(utcDate).toLocaleString([], { hour12: false }));
                          // console.log("localeDateTime", localeDateTime);
                          // const formattedPTDate = format(pacificTimeDate, 'MMMM dd, yyyy hh:mm:ss a');
                          // const pacificTimeDate = utcToZonedTime(utcDate, pacificTimeZone);
                          const centralTimeDate = utcToZonedTime(utcDate, centralTimeZone);
                          // const datePT = format(pacificTimeDate, 'MMM dd, yyyy');
                          // const timePT = format(pacificTimeDate, 'HH:mm:ss'); // 24 hour format
                          const dateCT = format(centralTimeDate, 'MMM dd, yyyy');
                          const timeCT = format(centralTimeDate, 'HH:mm:ss'); // 24 hour format
                          // console.log("UTC Time", time, "\nPacific Date", datePT, "\nPacific Time", timePT);
                          let status = assessment?.status?.toLowerCase() === "complete" || assessment?.status?.toLowerCase() === "completed" ? "Completed" : "Pending";
                          let statusClasses = assessment?.status?.toLowerCase() === "complete" || assessment?.status?.toLowerCase() === "completed" ? "assessment-status assessment-complete" : "assessment-status assessment-pending";
                          let resultLink = url.trim() + "/results/" + assessment?.hash;
                          let referralLink = url.trim() + "/?leader=" + assessment?.leaderHash + "&user=" + (assessment?.userId || assessment?.id);
                          
                          return (
                            <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }} key={index}>
                              <TableCell component="th" scope="row">{index + 1}</TableCell>
                              <TableCell align="left">
                                <Link to="#" className="referral-info-link" style={{ textDecoration: "underline", color: "#1F2A44" }}
                                onClick={() => {
                                  if(assessment?.leaderUserId) handleOpenDrawers(assessment?.leaderUserId);
                                  // setTabValue(0);
                                }}>{assessment.leaderName || assessment.leader || assessment?.leaderInfo?.name || enDash}</Link>
                              </TableCell>
                              <TableCell align="left">{dateCT}</TableCell>
                              <TableCell align="left" title={localeDateTime} style={{ cursor: "default" }}>{timeCT}</TableCell>
                              <TableCell align="left" className={statusClasses}>{status}</TableCell>
                              <TableCell align="left">
                                {assessment?.status === "complete" || assessment?.status === "completed" ? <a className="view-results-link" href={resultLink} target="_blank" title={resultLink} rel="noreferrer"><Button>View Results</Button></a> : <CopyLinkBtn btnText="Copy Invite Link" link={referralLink} clsName="copy-referral-btn" />}
                                <Tooltip title="Delete Invite">
                                  <IconButton onClick={(e) => handleDeleteInvite(e, assessment)}>
                                    <Delete style={{ width: "28px", height: "auto", cursor: "pointer", color: "#212A42" }} />
                                  </IconButton>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                          )
                        })
                        }

                      </TableBody>
                    </Table>
                  </TableContainer>
                  ) : (
                    <>
                      <a href={getResultsUrl()} target="_blank" rel="noreferrer"><Button className="view-results-btn">View Results Page</Button></a>
                      {showGroupDashboardBtn && <a href={getDashboardUrl()} target="_blank" rel="noreferrer"><Button className="view-results-btn">View Group Dashboard</Button></a>}
                      {showIframe && showIframeResults(getResultsUrl())}
                    </>
                  )
                }
                {/* {showGroupDashboardBtn && <a href={getDashboardUrl()} target="_blank" rel="noreferrer"><Button className="view-results-btn">View Group Dashboard</Button></a>} */}                
                {/* <Button className="view-results-btn" onClick={() => setShowIframe(!showIframe)}>View Results Page</Button> */}
                

                {/* Show Additional Questions and Answers */}
                {additionalQnA?.length ? <>
                  <hr style={{ margin: "30px auto 15px" }} />
                  <div className="additional-questions-answers">
                    <h3>Additional Questions</h3>
                    {additionalQnA?.map((qna, idx) => {
                      return <div className="qa-container" key={qna?.questionId}>
                        <p className="question"><span className="left-text">{`Q${idx + 1}`}:</span> {qna?.question}</p>
                        <p className="answer"><span className="left-text">A:</span> {qna.answerTxt || qna.answer || <>&#8212;</>}</p>
                      </div>
                    })}
                  </div>
                </> : null}

                {/* Show Additional Form (ChMS Context) Questions and Answers */}
                {additionalFormQnA?.length ? <>
                  <hr style={{ margin: "30px auto 15px" }} />
                  <div className="additional-questions-answers">
                    <h3>ChMS/Church Context</h3>
                    {additionalFormQnA?.map((qna, idx) => {
                      return <div className="qa-container" key={qna?.id}>
                        <p className="question"><span className="left-text">{`Q${idx + 1}`}:</span> {qna?.question}</p>
                        <p className="answer"><span className="left-text">A:</span> {qna.value || <>&#8212;</>}</p>
                      </div>
                    })}
                  </div>
                </> : null}
                
                {/* Show Feedback section Questions and Answers */}
                {feedbackQnA?.length ? <>
                  <hr style={{ margin: "30px auto 15px" }} />
                  <div className="additional-questions-answers">
                    <h3>Feedback</h3>
                    {feedbackQnA?.map((qna, idx) => {
                      return <div className="qa-container" key={qna?.id}>
                        <p className="question"><span className="left-text">{`Q${idx + 1}`}:</span> {qna?.question}</p>
                        <p className="answer"><span className="left-text">A:</span> {qna.answerTxt || <>&#8212;</>}</p>
                      </div>
                    })}
                  </div>
                </> : null}

              </div>

              {/* <div className="user-score-container">
                {assessmentDetails?.sections?.map((section, index) => {
                  return (
                    <div key={section.section} className="score-section">
                      <p className="score-title">{section?.section}</p>
                      <p className="score-value">{assessmentDetails[`subScore${index + 1}`]}%</p>
                    </div>
                  )
                })}
              </div> */}

              {/* <div className="user-score-details">
                <div className="score-section">
                  <p className="score-title">Overall Score</p> 
                  <p className="score-value">{userInfoData?.id}</p>
                </div>
                {hasAdditional !== "yes" ? null : <div className="score-section">
                  <p className="score-title">Barriers</p>
                  <p className="score-value">{userInfoData?.id}</p>
                </div>}
                { hasAdditional !== "yes" ? null : <div className="score-section">
                  <p className="score-title">Enablers</p>
                  <p className="score-value">{userInfoData?.id}</p>
                </div> }
                
              </div> */}

            </TabPanel>
            {userDetails.tabValue !== 2 && (

            <TabPanel value={tabValue} index={2}>
              <div className="user-referrals">
                <div className="referral-link-container">
                  <p className="referral-link-text">Leader invite link: </p>
                  <TextField ref={copyLinkRef} className="referral-link" label="" variant="outlined" value={leaderInviteLink} readOnly disabled />
                  <CopyLinkBtn btnText="COPY" link={leaderInviteLink} clsName="copy-referral-btn" />
                </div>
                {userInfoData?.referrals?.length ? (
                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 350 }} aria-label="Referrals Table">
                      <TableHead>
                        <TableRow>
                          <TableCell align="left">No.</TableCell>
                          <TableCell align="left">Date (CT)</TableCell>
                          <TableCell align="left">Name</TableCell>
                          <TableCell align="left">Email</TableCell>
                          <TableCell align="left">Assessment Status</TableCell>
                          <TableCell align="left">Action <StyledTooltip placement="top" title={<><p>Click the "Copy Link" button to copy a team member's unique assessment URL to your clipboard.</p> <p>To view a team member's assessment report, click the "View Report" button.</p></>}>
                            <IconButton><HelpOutline style={{ verticalAlign: "sub" }} /></IconButton>
                          </StyledTooltip></TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>

                        {userInfoData?.referrals?.map((referral, index) => {
                          // console.log("referral", referral); console.log("url", url);
                          referral.userType = "member";
                          let resultLink = url.trim() + "/results/" + referral.uniqueId;
                          let referralLink = url.trim() + "/?leader=" + userInfo.uniqueId + "&user=" + referral.id;
                          let status = referral?.assessmentCompleted === "yes" ? "Completed" : "Pending";
                          let statusClasses = referral?.assessmentCompleted === "yes" ? "assessment-status assessment-complete" : "assessment-status assessment-pending";
                          return (
                            <TableRow
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">{index + 1}</TableCell>
                              <TableCell scope="row" title="Central Time" style={{ cursor: "help" }}>{getCentralDateTime(referral.createdAt)}</TableCell>
                              <TableCell component="th" scope="row">
                                {/* <Link to={resultLink}>{referral?.name}</Link> */}
                                <Link to="#" className="referral-info-link" onClick={() => {
                                  if(referral?.id) handleOpenDrawers(referral?.id);
                                  // setTabValue(0);
                                  }}>{referral?.name}</Link>
                              </TableCell>
                              <TableCell align="left">{referral?.email}</TableCell>
                              <TableCell align="left" className={statusClasses}>
                                {/* {status === "Completed" ? 
                                  (<a href={resultLink} title={resultLink} target="_blank" rel="noreferrer">{status}</a>) : 
                                  (<a href={referralLink} title={referralLink} target="_blank" rel="noreferrer">{status}</a>)
                                } */}
                                {status}
                              </TableCell>
                              <TableCell align="left">
                                <div className="cta-container" style={{ display: "flex", alignItems: "center" }}>
                                  {status === "Completed" ? <a className="view-results-link" href={resultLink} target="_blank" title={resultLink} rel="noreferrer"><Button>View Results</Button></a> : 
                                  <CopyLinkBtn btnText="Copy link" link={referralLink} clsName="copy-referral-btn" />}
                                  <Tooltip title="Delete Invite">
                                    <IconButton onClick={(e) => handleDeleteInvite(e, referral)}>
                                      <Delete style={{ width: "28px", height: "auto", cursor: "pointer", color: "#212A42" }} />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              </TableCell>
                            </TableRow>
                          )
                        })
                        }

                      </TableBody>
                    </Table>
                  </TableContainer>) : (assessmentType === "team" ? <p>No team members to show...</p> : <p>No referrals to show...</p>)
                }

              </div>
            </TabPanel>) }
          </Box>

        </div>
        <Snackbar className="link-copied-snackbar" anchorOrigin={{ vertical: "top", horizontal: "center"}} open={openSnackbar} autoHideDuration={1000} message={snackbarMessage}></Snackbar>

      </section>
    </div>
  )
}


export default UserDetailsDrawer;
