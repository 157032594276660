import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, NavLink } from "react-router-dom";

import CircularProgress from '@material-ui/core/CircularProgress';

import DrawerContext from '../store/DrawerContext.js';

import AssessmentsMembersTable from "../Components/Tables/AssessmentsMembersTable";

const AssessmentsMembers = () => {

  const { id } = useParams();
	const drawerCtx = useContext(DrawerContext);

	const [isLoading, setIsLoading] = useState(true);
	const [title, setTitle] = useState("");
	const [doneAssessment, setDoneAssessment] = useState(0);
	const [invitedNoAssessment, setInvitedNoAssessment]	= useState(0);
	const [members, setMembers] = useState([]);
	const [url, setUrl] = useState("");

	useEffect(() => {

		fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/statistics/members', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			setTitle(data.details.title);
			setUrl(data.details.url);
			setDoneAssessment(data.details.doneAssessment);
			setInvitedNoAssessment(data.details.invitedNoAssessment);
			setMembers(data.members);
			setIsLoading(false);
		});

	}, []);

	return (
		<div className="container statistics">
			<header>
				<div data-aos="fade-right">
				{ isLoading ? <h1><NavLink to="/assessments">Assessments</NavLink> Members</h1> : <h1><NavLink to="/assessments">Assessments</NavLink> &#8250; {title}'s Members ({members.length})</h1> }
				</div>
			</header>			

			{ isLoading ? <CircularProgress /> :
				<>
					<div className="stats-container">
						<div className="stats-box" style={{flex: "0 1 49%"}}>
							<span className="count" data-aos="fade-up">{ doneAssessment }</span>
							<span className="info">Team members who took the assessment</span>
						</div>

						<div className="stats-box" style={{flex: "0 1 49%"}}>
							<span className="count" data-aos="fade-up" data-aos-delay="100">{ invitedNoAssessment }</span>
							<span className="info">Team members who were invited, but did not take the assessment</span>
						</div>
					</div>
					<AssessmentsMembersTable members={members} url={url} />
				</>

			}
			
		</div>
	);

}
export default AssessmentsMembers;