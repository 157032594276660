import { PixelCrop } from 'react-image-crop'

const TO_RADIANS = Math.PI / 180

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
    image.src = url
  })

export async function canvasPreview(imageSrc, crop, scale = 1, rotate = 0) {
  
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context')
  }


  const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    
    canvas.width = crop.width;
    canvas.height = crop.height;

    console.log(scaleX)
    console.log(scaleY)
    console.log(canvas.width)
    console.log(canvas.height)
    console.log(image.naturalWidth)
    console.log(scaleY)
  
    // const pixelRatio = 3;
    // canvas.width = crop.width * pixelRatio;
    // canvas.height = crop.height * pixelRatio;
    // ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
  
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height,
    );






  // const scaleX = image.naturalWidth / image.width
  // const scaleY = image.naturalHeight / image.height
  // // devicePixelRatio slightly increases sharpness on retina devices
  // // at the expense of slightly slower render times and needing to
  // // size the image back down if you want to download/upload and be
  // // true to the images natural size.
  // const pixelRatio = window.devicePixelRatio
  // // const pixelRatio = 1

  // canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
  // canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

  // ctx.scale(pixelRatio, pixelRatio)
  // ctx.imageSmoothingQuality = 'high'

  // const cropX = crop.x * scaleX * 10
  // const cropY = crop.y * scaleY * 10

  // const rotateRads = rotate * TO_RADIANS
  // const centerX = image.naturalWidth / 2
  // const centerY = image.naturalHeight / 2

  // ctx.save()

  // // 5) Move the crop origin to the canvas origin (0,0)
  // ctx.translate(-cropX, -cropY)
  // // 4) Move the origin to the center of the original position
  // ctx.translate(centerX, centerY)
  // // 3) Rotate around the origin
  // ctx.rotate(rotateRads)
  // // 2) Scale the image
  // ctx.scale(scale, scale)
  // // 1) Move the center of the image to the origin (0,0)
  // ctx.translate(-centerX, -centerY)
  // ctx.drawImage(
  //   image,
  //   0,
  //   0,
  //   image.naturalWidth,
  //   image.naturalHeight,
  //   0,
  //   0,
  //   image.naturalWidth,
  //   image.naturalHeight,
  // )

  // ctx.restore()

  return canvas.toDataURL('image/png');

  
}

