import { useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import AddImage from "./AddImage/AddImage";
import BackgroundColor from './StylingComponents/BackgroundColor';
import Width from "./StylingComponents/Width";
import BoxSizing from "./StylingComponents/BoxSizing";
import Padding from "./StylingComponents/Padding";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const ContainerWidget = () => {

  const builderCtx = useContext(BuilderContext);

  const { currentContentBlock } = builderCtx;

  console.log("currentContentBlock", currentContentBlock);

  return (
    <div>
      {/* <BackgroundColor blockId={currentContentBlock?.id} />
      <Width blockId={currentContentBlock?.id} />
      <BoxSizing blockId={currentContentBlock?.id} />
      <Padding blockId={currentContentBlock?.id} /> */}
      <DynamicStylingTool/>
      <AddImage blockId={currentContentBlock?.id} background={true} />
    </div>
  )
}



export default ContainerWidget;

