import React, { useState } from 'react'
import sampleDashboardTeamImg from '../../assets/img/sample-dashboard-team.png';
import sampleDashboardIndividualImg from '../../assets/img/sample-dashboard-team.png';
import { Switch } from '@mui/material';

export default function SampleDashboard() {
  
  const [currentView, setCurrentView] = useState(0);
  const [toggleIndividual, setToggleIndividual] = useState(1);

  const toggleTeamIndividual = (tab) => {
    if (tab == 1) {
      setCurrentView(0);
      setToggleIndividual(1);
    } else {
      setCurrentView(1);
      setToggleIndividual(0);
    }
  }

  const toggleIndividualChange = (e) => {
    // setToggleSection(!e.target.checked);
    if (e.target.checked) {
      toggleTeamIndividual(0);
    } else {
      toggleTeamIndividual(1)
    }
  }

  return <>
    <div className="sample-dashboard-text">
      <div className="right-line"></div>
      <div className="left-line"></div>
    </div>
    <div className="individual-group">
      <button onClick={() => toggleTeamIndividual(1)} className={currentView == 0 ? "active left" : "left"}>TEAM</button>
      <button onClick={() => toggleTeamIndividual(0)} className={currentView == 1 ? "active right" : "right"}>INDIVIDUAL</button>
    </div>
    <div className="dashboard-report-img-container">
      {
        <><img src={currentView == 0 ? sampleDashboardTeamImg : sampleDashboardIndividualImg} alt="sample team dashboard" srcset="" /></>
      }
    </div>
  </>
}
