import { useContext } from "react";
import ModalContext from "../../store/ModalContext";
import BuilderContext from '../../store/BuilderContext';
import { CloseOutlined } from "@material-ui/icons";
import AuthContext from "../../store/AuthContext";

const DeleteStyles = (props) => {  

  const modalCtx    = useContext(ModalContext);
  const builderCtx  = useContext(BuilderContext);
  const authCtx     = useContext(AuthContext);

  const { currentContentBlock } = builderCtx;
  const { name }                = props?.details;

  const handleDelete = () => {

    fetch(process.env.REACT_APP_API_URI + `/v3/admin/assessment/styling/${currentContentBlock?.id}/${name}`, {
      method: "DELETE",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token},
    })
    .then((response) => response.json())
    .then((data) => {
      if ( data.status === 'success' ) {
        updateCurrentBlockStylesLocally()
      }
    });
  }

  const updateCurrentBlockStylesLocally = () => {
    const updatedStylingObject = { ...currentContentBlock.styling };
    delete updatedStylingObject[name]
    const updatedCurrentContentBlock = {
      ...currentContentBlock,
      styling: updatedStylingObject
    }      
    builderCtx?.updateCurrentBlock(updatedCurrentContentBlock)
    modalCtx.closeModal();
  }

  return <div className="delete-style-modal-parent">
    <div className="question-close-icon-container">
      <h2> Are you sure you want to delete this style ? </h2>
      <div className="close-icon" onClick={()=>{modalCtx.closeModal()}}>
        <CloseOutlined/>
      </div>
    </div>
    <div className="delete-cancel-btn-container">
      <button className="cancel-btn" onClick={()=>{modalCtx.closeModal()}}>Cancel</button>
      <button className="delete-btn" onClick={handleDelete}>Delete</button>
    </div>
  </div>
}
export default DeleteStyles;