import React, { useContext } from 'react'
import ModalContext from '../../store/ModalContext'
import { CloseOutlined } from '@material-ui/icons';

export default function DuplicateStyle() {

  const modalCtx = useContext(ModalContext);

  return (
    <div className="duplicate-style-modal-parent">
      <div className="question-close-icon-container">
        <h2> Are you sure you want to duplicate this style for all headers ? </h2>
        <div className="close-icon" onClick={()=>{modalCtx.closeModal()}}>
          <CloseOutlined/>
        </div>
      </div>
      <div className="delete-cancel-btn-container">
        <button className="cancel-btn" onClick={()=>{modalCtx.closeModal()}}>Cancel</button>
        <button className="duplicate-btn">Ok</button>
      </div>
    </div>
  )
}
