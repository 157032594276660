import { useState, useEffect, useContext } from 'react';
import { FormControl, InputLabel, MenuItem, Select, TextField, Switch, Button, FormGroup } from '@material-ui/core';

import { Delete as DeleteIcon } from '@material-ui/icons';
import DrawerContext from '../../store/DrawerContext';
import SubDrawerContext from '../../store/SubDrawerContext';
import AuthContext from '../../store/AuthContext';
import { useParams, useSearchParams } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import delIcon from '../../assets/img/Question Builder/DelIcon.svg'
import BuilderContext from '../../store/BuilderContext';


const EditScoringDrawer = (props) => {
  console.log("EditQuestionDrawer Props", props);
  
  const drawerCtx                       = useContext(DrawerContext);
  const subdrawerCtx = useContext(SubDrawerContext);
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { questionDetails, sections }   = props?.questionDetails || [];
  const [sectionName, setSectionName]   = useState(sections?.find((section) => section.id === questionDetails.sectionId)?.section);
  const [responseType, setResponseType] = useState(questionDetails?.type);
  const [question, setQuestion]         = useState(questionDetails?.question);
  const [mandatory, setMandatory]       = useState(questionDetails?.mandatory.toLowerCase() === "yes" ? true : false);
  const [showAnswers, setShowAnswers]   = useState(questionDetails?.type == "textarea" ? false : true);
  const [answers, setAnswers]           = useState(questionDetails?.answers);
  const [isAdditional, setIsAdditional] = useState(props?.questionDetails?.additional);
  const [editedQuestion, setEditedQuestion] = useState(null);
  const [pages, setPages]               = useState([])
  const [pageName, setPageName]         = useState("")
  const questionId = questionDetails?.id;
  const [pageId, setPageId]             = useState(questionDetails?.pageId);
  const clientId = props?.questionDetails?.clientId || "";
  const { id } = useSearchParams();
  const currentPageUrl = window.location.href;

  console.log('editquestionId',id)
  console.log('drawerCtx',drawerCtx)


  const getPageUrlName = (url) => {
    const segments = url.split('/');
  
    const assessmentsIndex = segments.indexOf('assessments');
    const builderIndex = segments.indexOf('builder');
  
    const assessmentsValue = assessmentsIndex !== -1 ? segments[assessmentsIndex + 1] : null;
    const builderValue = builderIndex !== -1 ? segments[builderIndex + 1] : null;
  
    const currentPageUrlName = assessmentsIndex !== -1 ? 'assessments' : (builderIndex !== -1 ? 'builder' : null);
  
    return currentPageUrlName;
  };

  useEffect(() => {

    let sectionId = sections?.find((section) => section.section === sectionName)?.id;

    setEditedQuestion((prev) => {
      return {
        ...prev,
        clientId: clientId,
        sectionId: sectionId || 0,
        responseType: responseType,
        isAdditional: isAdditional ? "yes" : "no",
        question: question,
        mandatory: mandatory ? "yes" : "no",
        answerType: responseType,
        answers: answers,
        pageId: pageId,
        variable: props?.questionDetails?.variable
      }
    })
    console.log('clientId, answers, isAdditional, mandatory, question, questionId, responseType, sectionName, sections',clientId, answers, isAdditional, mandatory, question, questionId, responseType, sectionName, sections)
  }, [clientId, answers, isAdditional, mandatory, question, questionId, responseType, sectionName, sections, pageId]);

  console.log('props', props.questionDetails);

  console.log("answers", answers);
  
  const switchLabel = { inputProps: { 'aria-label': 'Mandatory Question Toggle Switch' } };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 44,
    height: 24,
    padding: 0,
    display: 'flex',
    '&:active': {
      '& .MuiSwitch-thumb': {
        width: 16,
      },
      '& .MuiSwitch-switchBase.Mui-checked': {
        transform: 'translateX(19px)',
      },
    },
    '& .MuiSwitch-switchBase': {
      padding: 2,
      '&.Mui-checked': {
        transform: 'translateX(19px)',
        color: '#fff',
        '& + .MuiSwitch-track': {
          opacity: 1,
          backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
        },
      },
    },
    '& .MuiSwitch-thumb': {
      boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
      width: 16,
      height: 16,
      borderRadius: 10,
      transition: theme.transitions.create(['width'], {
        duration: 200,
      }),
    },
    '& .MuiSwitch-track': {
      borderRadius: 26 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
      boxSizing: 'border-box',
    },
  }));

  const updateQuestionType = (value) => {
    setResponseType(value);
    if(value == "textarea") {
      setShowAnswers(false);
    } else {
      setShowAnswers(true);
    }
  }

  const getPageName = (id) => {
    const page = pages.find(page => page.id === id);
    console.log('PageId getPageName ',pageId)
    setPageName(page?.page_type)
  };

  const getPages = async () => {

    try {
      const res = await fetch(`${process.env.REACT_APP_API_URI}/v1/assessment/${clientId}/pages`)
      const data = await res.json();

      if (data.status === "success") {
        setPages(data.pages?.sort((a, b) => a.order_no - b.order_no));
      }
    } catch (error) {
      console.log('error getting pages : ', error)
    }
  }


  useEffect(()=>{
    getPages()
  },[])

  useEffect(()=>{
   console.log('edited question',editedQuestion)
  },[editedQuestion])


  useEffect(()=>{
    console.log('Pages',pages)
    console.log('pageName',pageName)
    console.log('clientId',clientId)
    getPageName(pageId);
  },[pages,clientId,pageName])

  const addAnswer = () => {
    console.log(clientId);
    
    let id        = 0;
    if(answers.length > 0) {
      id          = answers[answers.length - 1]?.id + 1;
    }
    let orderNo = 1; 
    if (answers[answers.length - 1]?.order_no) {
      orderNo = answers[answers.length - 1]?.order_no + 1;
    } else orderNo = answers.length + 1;
    
    let answer = {
      isNew: true,
      id: id,
      order_no: orderNo,
      answerDesktop: "",
      answerMobile: "",
      value: 0
    }

    setAnswers([...answers, answer]);

  };  

  const deleteAnswer = (a) => {    
    setAnswers(answers.filter((answer) => {
      return answer.id !== a
    }));
  };

  const updateAnswerDesktop = (e, id) => {

    setAnswers(answers?.map(answer => {
      if(answer.id === id) {
        answer.desktopText = e.target.value;
      }
      return answer;
    }));
  }

  const updateAnswerMobile = (e, id) => {

    setAnswers(answers?.map(answer => {
      if(answer.id === id) {
        answer.mobileText = e.target.value;
      }
      return answer;
    }));

  }

  const updateAnswerValue = (e, id) => {

    setAnswers(answers?.map(answer => {
      if(answer.id === id) {
        answer.value = e.target.value;
      }
      return answer;
    }));
    
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log("Submitting Edited Question");
    console.log("answers", answers);
    console.log("mandatory", mandatory);
    console.log("Payload editedQuestion",editedQuestion)

    const formattedAnswers = answers.map(answer => ({
      isNew: answer?.isNew ? true : false,
      id: answer.id,
      orderNo: answer.order_no,
      answerDesktop: answer.desktopText,
      answerMobile: answer.mobileText,
      value: answer.value,
    }));

    console.log('formattedAnswers',formattedAnswers)

    const payload = {
      ...editedQuestion,
      answers: formattedAnswers,
    };

    console.log('payload',payload)

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/question/${questionId}`, {
      method: "PUT",
      body: JSON.stringify(payload),
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data)
      if (data.status === "success") {
        console.log('getPageUrlName(currentPageUrl)', getPageUrlName(currentPageUrl))
        subdrawerCtx.closeDrawer();
        subdrawerCtx.setPageUpdate({ page: "questionsBuilderDrawer", id: questionId });
      }
    })
    .catch((err) => console.error(err));
  }

  return (
    <div className="add-question-drawer drawer">
      
      <header>
        <h2>Edit Scoring</h2>
      </header>

      <section>
        
        <form onSubmit={(e) => handleSubmit(e)}>
          <FormGroup>
            <div className="double-column-form">
              <FormControl fullWidth className="form-control-add-question">
                <label>Category{isAdditional ? "" : "*" }</label>
                <TextField required={isAdditional ? false : true} value={sectionName} onChange={(e) => setSectionName(e.target.value)} select variant="outlined">
                  { 
                    sections?.map((section, index) => {
                      return <MenuItem key={index} className="section-name" value={section?.section ? section.section : ""}>{section.section}</MenuItem>
                    })
                  }
                </TextField>
              </FormControl>

              <FormControl fullWidth className="form-control-add-question">
                <label>Response Type*</label>
                <TextField required value={responseType} onChange={(e) => updateQuestionType(e.target.value)} select variant="outlined">
                  <MenuItem className="question-type" value="slider">Slider</MenuItem>
                  <MenuItem className="question-type" value="textarea">Multiline Textbox</MenuItem>
                  <MenuItem className="question-type" value="radio">Radio Buttons</MenuItem>
                </TextField>
              </FormControl>
              
              <FormControl fullWidth className="form-control-add-question">
                <label>Display On*</label>
                <TextField required value={isAdditional} onChange={(e) => setIsAdditional(e.target.value)} select variant="outlined">
                  <MenuItem className="question-type" value={false}>Assessment</MenuItem>
                  <MenuItem className="question-type" value={true}>Additional</MenuItem>
                </TextField>
              </FormControl>

              <FormControl fullWidth className="form-control-add-question">
                <label>Choose Page Type*</label>
                <TextField required value={pageId} onChange={(e)=>{
                  setPageId(e.target.value)
                }} select variant="outlined">
                  {
                    pages?.map((el,index)=>{
                      return <MenuItem className="question-type" value={el?.id} setPageName onClick={()=>{setPageId(el?.id) }}> <div className="pageid-pagename-container"> <span className="page-name">{el?.page_type}</span> <span className="page-id">{el?.id}</span> </div> </MenuItem>
                    })
                  }
                </TextField>
              </FormControl>

            </div>

            <FormControl fullWidth className="form-control-add-question">
              <label>Question*</label>
              <TextField required variant="outlined" placeholder="Question" multiline minRows={3} maxRows={6} value={question} onChange={(e) => setQuestion(e.target.value)} />
            </FormControl>

            <FormControl fullWidth className="form-control-add-question question-mandatory-toggle">
              <span>Is The Question Mandatory: &nbsp;</span> <AntSwitch color="primary" className="mandatory-switch" {...switchLabel} checked={mandatory} onChange={(e) => setMandatory(e.target.checked)} /> { mandatory ? "YES" : "NO" }
            </FormControl>

            { showAnswers ? 
              <>
                <div className="add-options-and-heading-container">
                  <h3>Answers Available For This Question:</h3>
                  <FormControl>
                    <Button className="option-btn" onClick={() => addAnswer()}>
                      <span className="add-icon">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                          <g id="Group_4492" data-name="Group 4492" transform="translate(-1584 -327)">
                            <g id="add" transform="translate(1586.688 322.798)">
                              <path id="Path_12994" data-name="Path 12994" d="M17.98,16.2a1.433,1.433,0,0,1-1.433,1.433H10.77v5.778a1.433,1.433,0,0,1-2.864,0V17.635H2.125a1.433,1.433,0,1,1,0-2.866H7.9V8.991a1.433,1.433,0,0,1,2.864,0v5.778h5.78A1.433,1.433,0,0,1,17.98,16.2Z" fill="#9b9b9b"/>
                            </g>
                            <rect id="Rectangle_1264" data-name="Rectangle 1264" width="24" height="24" transform="translate(1584 327)" fill="none"/>
                          </g>
                        </svg>  
                      </span>
                      <span>Add Option</span>
                    </Button>
                  </FormControl>
                </div>
                {
                  answers?.map((answer, index) => {
                    let answerdesktop = "Answer " + (index + 1) + " (Desktop)";
                    let answermobile = "Answer " + (index + 1) + " (Mobile)";
                    let answerscore = "Score value " + (index + 1);
                    return (
                      <div className="double-column-form" key={answer.id}>
                        <FormControl fullWidth className="form-control-add-question">
                          <label htmlFor="">{answerdesktop}*</label>
                          <TextField required onChange={(e) => updateAnswerDesktop(e, answer.id)} value={answer?.desktopText} variant="outlined" placeholder={answerdesktop} multiline maxRows={2} />
                        </FormControl>
                        <FormControl fullWidth className="form-control-add-question">
                          <label htmlFor="">{answermobile}*</label>
                          <TextField required onChange={(e) => updateAnswerMobile(e, answer.id)} value={answer?.mobileText} variant="outlined" placeholder={answermobile} multiline maxRows={2} />
                        </FormControl>
                        <FormControl className="form-control-add-question" style={{minWidth: "160px"}}>
                          <label htmlFor="">{answerscore}*</label>
                          <TextField required onChange={(e) => updateAnswerValue(e, answer.id)} value={answer.value} variant="outlined" placeholder={answerscore} multiline maxRows={2} />
                        </FormControl>
                        <div className="delete-icon" onClick={() => deleteAnswer(answer.id)}>
                          <img src={delIcon} alt="Delete Icon" />
                        </div>
                      </div>
                    );
                  })
                }

              </>
              : null
            }
            
            <div className="button-container">
              <Button className="cancel-btn" onClick={()=>{
                subdrawerCtx.closeDrawer();
              }}>CANCEL</Button>            
              <Button className="add-question-btn" type="submit">Save Changes</Button>
            </div>
            
          </FormGroup>
        </form>
      </section>
    </div>
  )
}


export default EditScoringDrawer;
