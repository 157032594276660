import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const Margin = (props) => {

  const { blockId } = props;
  
  const builderCtx      = useContext(BuilderContext);
  const authCtx         = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;
  const [currentPosition, setCurrentPosition] = useState();

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentMarginT = currentBlockStyles?.marginTop?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  const currentMarginR = currentBlockStyles?.marginRight?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  const currentMarginB = currentBlockStyles?.marginBottom?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  const currentMarginL = currentBlockStyles?.marginLeft?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  
  // const currentmargins = [currentMarginT, currentMarginR, currentMarginB, currentMarginL];
  // console.log("currentBlockStyles", currentBlockStyles);
  // console.log("currentmargins", currentmargins);

  const units = [
    { value: 'px',    label: 'px'   },
    { value: 'rem',   label: 'rem'  },
    { value: '%',     label: '%'    },
    { value: 'vh',    label: 'vh'   },
    { value: 'vw',    label: 'vw'   },
    { value: 'auto',  label: 'Auto' },
  ];

 
  const [margins, setMargins] = useState({
    marginTop:    currentMarginT?.[0] || "0",
    marginRight:  currentMarginR?.[0] || "0",
    marginBottom: currentMarginB?.[0] || "0",
    marginLeft:   currentMarginL?.[0] || "0",
  })

  const [marginUnits, setMarginUnits] = useState({
    marginTop:    currentMarginT?.[0] === "auto" ? "auto" : currentMarginT?.[1] || "px",
    marginRight:  currentMarginR?.[0] === "auto" ? "auto" : currentMarginR?.[1] || "px",
    marginBottom: currentMarginB?.[0] === "auto" ? "auto" : currentMarginB?.[1] || "px",
    marginLeft:   currentMarginL?.[0] === "auto" ? "auto" : currentMarginL?.[1] || "px",
  })
  
  const [finalValue, setFinalValue]   = useState("");

  const handleChange = (e, property, position) => {

    const targetVal = e.target.value;
    const setterFunc = property === "margin" ? setMargins : property === "unit" ? setMarginUnits : null;

    // console.log(property, position); console.log("targetVal", targetVal);
    // console.log("margins", margins); console.log("marginUnits", marginUnits);

    setCurrentPosition(position)

    setterFunc((prev) => { 
      prev[position] = targetVal;
      return { ...prev };
    })

  }

  // console.log("\nmarginUnits", marginUnits);

  useEffect(() => {
    
    let allMargins = { ...margins };
    for (const [key, value] of Object.entries(marginUnits)) {
      console.log("key", key);
      console.log("value", value);
      console.log("allMargins[key]", allMargins[key]);
      if (allMargins[key] !== "auto" && allMargins[key] && value !== "auto") {
        allMargins[key] += marginUnits[key];
      } else if (value === "auto") {
        allMargins[key] = "auto";
      } else if (!allMargins[key]) {
        allMargins[key] = "0";
      } else {
        allMargins[key] = "0";
      }
    }

    // dont setFinalValue if previous margins === current margins 
    if(allMargins && (JSON.stringify(finalValue) !== (JSON.stringify(allMargins)))) {
      setFinalValue(allMargins)
    }

  }, [finalValue, marginUnits, margins])

  console.log("\n\nfinalValue Margins", finalValue, margins, "\n\n");

  useEffect(() => {

    let checkStyles = [];
    for (const key in finalValue) {
      if(currentBlockStyles?.[key] === finalValue[key]) {
        checkStyles.push(true);
      } else checkStyles.push(false);
    }
    let stylesAreSame = checkStyles?.every(val => val === true);

    const timer = setTimeout(() => {
      if(finalValue && !stylesAreSame) { 
        updateContentBlocks(contentBlocks?.map(block => {
          if(block.id === blockId) {
            let styles = { ...block.styling };
            for (const key in finalValue) {
              styles[key] = finalValue[key];
            }
            block.styling = styles;
            console.log('finalValue',finalValue)
            updateMargin(currentPosition)
            return block;
          } else return block;
        }))
      }
    }, 500);
    
    return () => clearTimeout(timer);
    
  }, [blockId, currentBlockStyles, finalValue]);

  const updateMargin = (marginDirection) => {

    console.log("marginUnits[marginDirection]",marginUnits[marginDirection])
    let payload = {
      id: blockId,
      value: finalValue[marginDirection],
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/'+marginDirection, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log(`updating ${marginDirection} payload & finalValue`, payload,finalValue);
      }
    }).catch((err) => {
      console.error("Failed to update marginDirection...", err);
    });
  }


  // console.log("blockId", blockId); console.log("margins", margins); console.log("units", marginUnits);
  // console.log("finalValue", finalValue);

  return (
    <div className="tool">
      {props.deleteModal}

      <div className="margin-section">
        <p className="subheaders">Top</p>
        <div className="input-and-unit-container">
          <TextField className="margin-input styling-input" type="number" variant="filled" min="0"
            InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling?.marginTop + ""} value={margins.marginTop} onChange={(e) => handleChange(e, "margin", "marginTop")} />

          <TextField className="margin-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          placeholder={currentContentBlock?.styling?.marginTop + ""} value={marginUnits?.marginTop || "0"} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            setCurrentPosition("marginTop")
            handleChange(e, "unit", "marginTop");
          }} >
            {units?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      
      <div className="margin-section">
        <p className="subheaders">Right</p>
        <div className="input-and-unit-container">
          <TextField className="margin-input styling-input" type="number" variant="filled"
            InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling?.marginRight + ""} value={margins.marginRight} onChange={(e) => handleChange(e, "margin", "marginRight")} />
          
          <TextField className="margin-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          placeholder={currentContentBlock?.styling?.marginRight + ""} value={marginUnits?.marginRight || "0"} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            setCurrentPosition("marginRight")
            handleChange(e, "unit", "marginRight");
          }} >
            {units?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      
      <div className="margin-section">
        <p className="subheaders">Bottom</p>
        <div className="input-and-unit-container">
          <TextField className="margin-input styling-input" type="number" variant="filled"
            InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling.marginBottom + ""} value={margins.marginBottom} onChange={(e) => handleChange(e, "margin", "marginBottom")} />
          
          <TextField className="margin-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          placeholder={currentContentBlock.styling.marginBottom + ""} value={marginUnits?.marginBottom || "0"} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            setCurrentPosition("marginBottom")
            handleChange(e, "unit", "marginBottom");
          }} >
            {units?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      
      <div className="margin-section">
        <p className="subheaders">Left</p>
        <div className="input-and-unit-container">
          <TextField className="margin-input styling-input" type="number" variant="filled"
            InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling.marginLeft + ""} value={margins.marginLeft} onChange={(e) => handleChange(e, "margin", "marginLeft")} />
          
          <TextField className="margin-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          placeholder={currentContentBlock.styling.marginLeft + ""} value={marginUnits?.marginLeft || "0"} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            setCurrentPosition("marginLeft")
            handleChange(e, "unit", "marginLeft");
          }} >
            {units?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

    </div>
  )
}


export default Margin;
