import { useState, useContext, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Checkbox, Radio, RadioGroup } from "@material-ui/core";
import axios from 'axios';

import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import BuilderContext from '../../store/BuilderContext.js';

import genericImg from "../../assets/img/builder-images/generic-page.png";
import questionsImg from "../../assets/img/builder-images/questions.png";
import additionalQuestionsImg from "../../assets/img/builder-images/additional-questions.png";
import additionalFormImg from "../../assets/img/builder-images/additional-form.png";
import detailsFormImg from "../../assets/img/builder-images/details-form.png";
import resultsImg from "../../assets/img/builder-images/results-page.png";
import inviteImg from "../../assets/img/builder-images/invite-page.png";
import inviteSuccessImg from "../../assets/img/builder-images/invite-success.png";
import dashboardImg from "../../assets/img/builder-images/dashboard.png";

const CreateAssessmentPage = () => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const builderCtx = useContext(BuilderContext);

  const pages = [
    { id: 1, text: "generic", image: genericImg },
    { id: 2, text: "assessment", image: questionsImg },
    { id: 3, text: "additional", image: additionalQuestionsImg },
    { id: 4, text: "form", image: additionalFormImg },
    { id: 5, text: "details", image: detailsFormImg },
    { id: 6, text: "results", image: resultsImg },
    { id: 7, text: "invite", image: inviteImg },
    { id: 8, text: "invite-thank-you", image: inviteSuccessImg },
    { id: 9, text: "dashboard", image: dashboardImg },
  ];

  const [page, setPage] = useState("generic");
  const [isCreating, setIsCreating] = useState(false);

  const handleAddPage = (value) => {
    setPage(value);
  };


  const createNewPage = () => {

    setIsCreating(true);
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + modalCtx?.details + '/page', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({ pageType: page }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          console.log("successfully created new page...");
          builderCtx.setRefreshPagesAndContent((prev) => !prev);
          setIsCreating(false);
          modalCtx.closeModal();
          modalCtx.setDetails(null);
          modalCtx.setPageUpdate({ page: "builder", pages: data.pages });
          // modalCtx.details?.setRefreshPageToggle((prev) => !prev);
        } else {
          console.error("failed to create new page...");
        }
      })
      .catch(err => {
        if (err) {
          console.error("failed to create new page...", err);
          setIsCreating(false);
        }
      });

  }


  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  return (
    <div className="modal">

      <h2 style={{ textAlign: "center" }}>Create New Page</h2>

      <div className="" onChange={(e) => {
        // setPage(e.target.value)
        console.log("e.target", e.target);
      }}
        style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", width: "100%", gap: '1rem' }}
      >
        {pages?.map((pg) => (
          <div key={pg.id}
            onClick={(e) => {
              handleAddPage(pg.text);
              console.log("e.target", e.target);
            }}
            style={{ border: page === pg.text ? "4px solid #007C89" : "3px solid #BEBEBE", width: "140px", maxWidth: "140px", height: "130px", display: "flex", flexDirection: "column", justifyContent: "center", textAlign: "center", boxSizing: "border-box", cursor: "pointer", borderRadius: '4px' }}
          >
            <img src={pg.image} alt={pg.text} height="auto" width="100%" style={{ margin: "0 auto", maxWidth: "60px" }} />
            <p style={{ textAlign: "center", textTransform: "capitalize", margin: "5px auto" }}>{pg.text}</p>
          </div>
        ))}
      </div>

      <br />
      <br />

      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
        {isCreating ?
          <Button className="confirm-btn"><CircularProgress style={{ 'color': '#fff' }} size={16} /></Button> :
          <Button className="confirm-btn" onClick={() => { createNewPage() }}>Create</Button>
        }
      </div>
    </div>
  )
}

export default CreateAssessmentPage;
