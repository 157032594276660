import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams, NavLink } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Chip, Tooltip, Button } from '@material-ui/core';
// import AssessmentIcon from '@material-ui/icons/Assessment';
// import { Person as PersonIcon, People as PeopleIcon, Help as HelpIcon, VerticalSplit as VerticalSplitIcon, MoreVert as MoreVertIcon } from '@material-ui/icons';

import DrawerContext from '../store/DrawerContext.js';

import AssessmentsQuestionsTable from "../Components/Tables/AssessmentsQuestionsTable";
import AuthContext from '../store/AuthContext.js';
import BuilderContext from '../store/BuilderContext.js';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
		color: "#747d8c"
	},
});


const AssessmentsQuestions = () => {

	const { id } = useParams();
	let navigate = useNavigate();

	const drawerCtx = useContext(DrawerContext);
	const authCtx = useContext(AuthContext);
	const builderCtx = useContext(BuilderContext);
	const [isLoading, setIsLoading] 						= useState(false);
	const [title, setTitle] 										= useState("");
	const [questions, setQuestions] 						= useState([]);
	const [sections, setSections] 							= useState([]);
	const [additionalQuestions, setAdditionalQuestions] = useState([]);
	
	const [assessments, setAssessments]     = useState([]);
	const [openDrawer, setOpenDrawer]       = useState(false);

	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	const getQuestions = () => {

    setIsLoading(true);

    const envURI = process.env.REACT_APP_API_URI;
    let options = { method: 'GET', headers: { 
      'Content-Type': 'application/json' ,
      'Authorization': 'Bearer ' + authCtx.token 
    },}
    Promise.all([
      fetch(envURI + '/v3/admin/questions/' + id , options).then(response => response.json()),
      fetch(envURI + '/v3/admin/questions/' + id + '/additionals', options).then(response => response.json()),
      // fetch(envURI + '/v1/questions/' + id, options).then(response => response.json()),
      // fetch(envURI + '/v1/questions/' + id + '/additional', options).then(response => response.json()),
    ]).then((data) => {
      console.log("API data---------------------------------", data);
      if (data[0].status === "success") {
        setTitle(data[0].title);
        setQuestions(data[0].questions);
        setSections(data[0].sections);
        builderCtx.setNumOfQuestions(data[0].questions.length)
        console.log('data[0].sections',data[0].sections)
      }
      if (data[1].status === "success") {
        setAdditionalQuestions(data[1].questions)
        console.log("Additional Questions API Data", data[1]);
      }
      if (data[0].status === "success" && data[1].status === "success") setIsLoading(false);
    }).catch(err => {
      console.error(err);
      setIsLoading(false);
    });
  }

	useEffect(()=>{ 
		console.log('drawerCtx.pageUpdate',drawerCtx.pageUpdate)
		getQuestions()
	 },[drawerCtx.pageUpdate])

	useEffect(() => {

		// const getQuestions = () => {

		// 	setIsLoading(true);
			
		// 	const envURI = process.env.REACT_APP_API_URI;
		// 	let options = { method: 'GET', headers: { 'Content-Type': 'application/json' } }
	
		// 	Promise.all([
		// 		fetch(envURI + '/v1/questions/' + id, options).then(response => response.json()),
		// 		fetch(envURI + '/v1/questions/' + id + '/additional', options).then(response => response.json()),
		// 	]).then((data) => {
		// 		console.log("API data\n",data);
		// 		if(data[0].status === "success") {
		// 			setTitle(data[0].title);
		// 			setQuestions(data[0].questions);
		// 			setSections(data[0].sections);
		// 		}
		// 		if(data[1].status === "success") {
		// 			setAdditionalQuestions(data[1].questions)
		// 			console.log("Additional Questions API Data", data[1]);
		// 		}
		// 		if(data[0].status === "success" && data[1].status === "success") setIsLoading(false);
		// 	}).catch(err => {
		// 		console.error(err);
		// 		setIsLoading(false);
		// 	});
	
		// 	// ============================================
		// 	// fetch(process.env.REACT_APP_API_URI + '/v1/questions/' + id, {
		// 	// 	method: 'GET',
		// 	// 	headers: { 'Content-Type': 'application/json' }
		// 	// })
		// 	// .then(response => {
		// 	//   // console.log(response);
		// 	//   return response.json();
		// 	// }).then(data => {
		// 	//   console.log(data);
		// 	//   if (data.status === "success") {
		// 	//     setTitle(data.title);
		// 	//     setQuestions(data.questions);
		// 	//     setSections(data.sections);
		// 	//     setIsLoading(false);
		// 	//   }
		// 	// }).catch(err => console.error(err));
	
		// }
    
		// getQuestions();
		!questions?.length && getQuestions();

  }, [id]);

  if (isLoading) {
		return <section className="loader-section"><CircularProgress /></section>
	}

	return (
		<div className="container questions">
      <header>
        <div data-aos="fade-right">
				{ isLoading ? <h1><NavLink to="/assessments">Assessment</NavLink> Questions</h1> : <h1><NavLink to="/assessments">Assessments</NavLink> &#8250; {title}'s Questions</h1> }
        </div>
        <div data-aos="fade-down" data-aos-delay="400">
          <a className="header-cta" onClick={() => toggleDrawerHandler('question-add', { sections: sections, clientId: id } )}> New Question</a>
        </div>
      </header>
			{ isLoading && !questions?.length ? <CircularProgress /> : <AssessmentsQuestionsTable questions={questions} sections={sections} additionalQuestions={additionalQuestions} /> }
    </div>
	);
}
export default AssessmentsQuestions;
