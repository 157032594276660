import { useState, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";

import { Button, LinearProgress } from '@mui/material';
import { Add, PlusOne } from '@material-ui/icons';

import BuilderContext from '../../store/BuilderContext';
import temperature from "../../assets/img/temperature.png";
import barchart from "../../assets/img/barchart.png";
import spiderweb from "../../assets/img/spiderweb.png";
import HeadingWidget from "./Tools/HeadingWidget";
import ParagraphWidget from "./Tools/ParagraphWidget";

import DrawerContext from "../../store/DrawerContext.js";
import ModalContext from "../../store/ModalContext";
import InviteInfo from "./InviteInfo.jsx";
import Testimonial from "./Testimonial.jsx";
import SampleDashboard from "./SampleDashboard.jsx";

function TemperatureScaleType() {

  const { id } = useParams();
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, changeCurrentPage } = builderCtx;
  

  const resultsContainer = contentBlocks?.find(block => block?.contentType === "ContentContainer" && block?.page === "results");
  const parentId = resultsContainer?.id;

  const score = resultsContainer?.properties?.score;

  const getLeftMargin = (score) => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  return (
    // <div className="results-container" onClick={(e) => accessToolsByPageArea("results", e)} style={setSectionStyle("results")}>
    <>
      {builderCtx.contentBlocks?.map((data, i) => {
        // console.log(data); console.log(parentId);
        if (data?.parentContainer === parentId && data?.pageArea === "results") {

          return <div key={i}>

            {data?.contentType === "ResultsTemperatureScale" && <>
              <div className="progress-bar" onClick={(e) => accessToolsById(e, data.id)} style={setStyle(data.id)}>
                <div className="si_ass_result">
                  <div className="si_ass_progress">
                    <div className="progress-line" style={{ left: score + '%' }}>
                      {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                    </div>
                    <span className="msi_score" style={getLeftMargin(score)}>{score}%</span>
                  </div>
                  <div className="pervalue">
                    <h4 className="nimp1" style={{ minWidth: '0%' }}></h4>
                    <h4 className="nimp" style={{ minWidth: '45%' }}></h4>
                    <h4 className="mexpe" style={{ minWidth: '25%' }}></h4>
                    <h4 className="eexpe" style={{ minWidth: '30%' }}></h4>
                  </div>


                  <div className="si_pro_header">
                    <h4 className="nimp" style={{ minWidth: '0%' }}></h4>
                    <h4 className="mexpe" style={{ minWidth: '45%' }}></h4>
                    <h4 className="eexpe" style={{ minWidth: '25%' }}></h4>
                  </div>

                  <div className="pervalue-text">
                    <p className="not-aligned" style={{ width: "45%", fontWeight: score <= 45 ? 700 : 400 }}>Needs<br />Improvement</p>
                    <p className="some-aligned" style={{ width: "25%", fontWeight: score > 45 && score <= 70 ? 700 : 400 }}>Meets<br />Expectations</p>
                    <p className="high-aligned" style={{ width: "30%", fontWeight: score > 70 ? 700 : 400 }}>Exceeds<br />Expectations</p>
                  </div>

                </div>
              </div>
            </>}
          </div>
        }
      })}
    </>
    // </div>
  );
}

const Results = () => {


  const { id } = useParams();
  const builderCtx = useContext(BuilderContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);
  const [showInvite, setShowInvite] = useState(false)
  const [testimonial, settTestimonial] = useState(false)
  const [showDashboard, setShowDashboard] = useState(false)
    
  const { contentBlocks, updateContentBlocks, accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, changeCurrentPage } = builderCtx;

  const [selectedContentBlockId, setSelectedContentBlockId] = useState(0);
  const [resultGraphType, setResultGraphType] = useState("");
  const [updateHeading, setUpdateHeading] = useState(false);

  
  useEffect(() => {
    changeCurrentPage("results");
  }, []);


  const resultsContainer = contentBlocks?.find(block => block?.contentType === "Container" && block?.page === "results");
  const parentId = resultsContainer?.id;

  // const parentId = contentBlocks?.find(block => block?.contentType === "ResultsContainer")?.id;
  // const resultsContainer = contentBlocks?.find(block => block?.contentType === "ResultsContainer");
  const resultsHeading = contentBlocks?.find(block => block?.contentType === "ResultsHeading");
  const resultsTextContainer = contentBlocks?.find(block => block?.contentType === "ResultsTextContainer");
  const resultsText = contentBlocks?.find(block => block?.contentType === "ResultsText");
  
  const resultsContainerStyles = resultsContainer?.styling;

  useEffect(() => {
    // TODO: Add to SQL and delete this 

    resultsContainer.properties.score = 77;

    resultsText.properties.leaderText = [
      { id: 0, text: "Auctor neque vitae tempus quam. Eu nisl nunc mi ipsum faucibus vitae."},
      { id: 1, text: "Tellus cras adipiscing enim eu turpis egestas pretium aenean. Felis eget nunc lobortis mattis aliquam faucibus."},
      { id: 2, text: "Ac turpis egestas integer eget aliquet nibh praesent tristique. Vel elit scelerisque mauris pellentesque pulvinar pellentesque."},
    ];

    resultsText.properties.memberText = [
      { id: 0, text: "Auctor neque vitae tempus quam. Eu nisl nunc mi ipsum faucibus vitae."},
      { id: 1, text: "Tellus cras adipiscing enim eu turpis egestas pretium aenean. Felis eget nunc lobortis mattis aliquam faucibus."},
      { id: 2, text: "Ac turpis egestas integer eget aliquet nibh praesent tristique. Vel elit scelerisque mauris pellentesque pulvinar pellentesque."},
    ];

  }, []);

  // console.log("currentContentBlock", currentContentBlock);

  const updateResultsType = (type) => {
    setResultGraphType(type);
    updateContentBlocks(contentBlocks?.map(block => {
      if (block.id === resultsContainer.id) {
        block.properties.resultsType = type;
        return block;
      } else return block;
    }))
  }

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  function toggleDrawerHandler(drawer, id) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, id);
  }

  function showUpdateResultsTextModal(data) {
    toggleModalHandler("update-results-text-modal", data);
  }
  
  function showUpdateResultsHeaderModal(id) {
    toggleModalHandler("results-header-modal", id);
  }

  const score = resultsContainer?.properties?.score;
  const resultsType = resultsContainer?.properties?.resultsType;

  let properties = {
    text: "Select your result page type:",
    resultsTypesArray: [
      { img: temperature, type: "temperature", title: "Gradient Line Bar", info: "Display the results on a gradient scale based on the calculation. Default is the percentage average" },
      { img: barchart, type: "barchart", title: "Category bars", info: "Display each category as a bar in relation to the entire represented categories" },
      { img: spiderweb, type: "spiderweb", title: "Spiderweb chart", info: "Display each category represented on a spoke of the spiderweb chart" },
    ],
  };

  const selectedTypeStyle = (type) => {
    if ( builderCtx.currentDeviceView === "Mobile" ) {
      return { borderRadius: "26px", textAlign: "center", display: "inline-block", cursor: "pointer", border: "4px solid #70707070", boxSizing: "border-box" }
    }
    else {
      if (resultsContainer?.properties?.resultsType === type) {
        return { minWidth: "300px", width: "31%", borderRadius: "26px", textAlign: "center", display: "inline-block", cursor: "pointer", border: "4px solid #70707070", boxShadow: "0 0 10px 1px #70707070", boxSizing: "border-box" }
      } 
      else {
        return { width: "31%", borderRadius: "26px", textAlign: "center", display: "inline-block", cursor: "pointer", border: "4px dashed #70707070", boxSizing: "border-box" }
      }
    }
  }



  console.log("builderCtx", builderCtx);
  console.log("builderCtx.contentBlocks", builderCtx.contentBlocks);
  
  console.log("resultsContainer", resultsContainer);
  console.log("result Styles", setSectionStyle("content"));

  const getLeftMargin = (score) => {
    if (window.innerWidth < 800 && score > 95) {
      return { left: 90 + "%" };
    } else if (window.innerWidth < 800 && score < 5) {
      return { left: 10 + "%" };
    } else {
      return { left: score + "%" };
    }
  };

  const showUpdateTextButton = () => {
    updateHeading ? setUpdateHeading(false) : setUpdateHeading(true);
  }

  return (

    <div className="results-container" onClick={(e) => accessToolsByPageArea("results", e)} style={setSectionStyle("results")}>

      <div className="result-page-type-container">
        <div className="update-scoring" onClick={() => toggleDrawerHandler('assessment-scoring', id)}>RESULTS CALCULATION</div>
        <div className="update-heading" onClick={() => showUpdateResultsHeaderModal(id)}>UPDATE COPY BASED ON SCORE</div>

        {
          builderCtx.contentBlocks?.map((data, i) => {
            if (data?.parentContainer === parentId && data?.pageArea === "results") {

              if (data?.contentType === "ResultsHeading") {
                console.log(data);
                return <h1 key={i} onClick={(e) => accessToolsById(e, data.id)} style={setStyle(data.id)}>Your result score and title updated based on score</h1>
              }
              
              if (data?.contentType === "ResultsText") {
                return <div></div>
              }
              
              if (data?.contentType === "ResultsSectionsGraphs") {
                return <div></div>
              }

              if (data?.contentType === "ResultsTemperatureScale") {
                return <div></div>

              }
              
            }
          })
        }
        {

          // <div className="results-header" 
          // // style={{ border: "2px dashed #77777777", display: "flex", alignItems: "center", position: "relative" }} 
          // // style={setStyle(resultsHeading.id)} 
          //   onFocus={(e) => {}}
          //   onClick={(e) => {accessToolsById(e, resultsHeading.id);showUpdateTextButton();}}>
          //   {/* <HeadingWidget blockId={resultsHeading.id} className="editable-text" /> */}
          //   <h1 style={setStyle(resultsHeading?.id)}>{resultsHeading?.properties?.text}</h1>
          // </div>

        }


          {resultGraphType === "temperature" ? <TemperatureScaleType /> : resultGraphType === "barchart" ? <TemperatureScaleType /> : resultGraphType === "spiderweb" ? <TemperatureScaleType /> : <>

            <h3 style={{ fontSize: "18px", color: "#212121", opacity: "0.5", textAlign: "center", margin: "60px auto 15px" }}>SELECT YOUR RESULT PAGE TYPE:</h3>

            <div className="results-types" style={{ display: "flex", justifyContent: "space-between", marginTop: "15px", marginRight: "auto", marginBottom: 0, marginLeft: "auto", padding: "15px", flexDirection: builderCtx.currentDeviceView === "Mobile" ? "column" : "row", gap: builderCtx.currentDeviceView === "Mobile" ? "20px" : "" }}>
              {properties?.resultsTypesArray?.map(type => {
                return (<div key={type.type} className="result-type" style={selectedTypeStyle(type.type)}
                  onClick={() => updateResultsType(type.type)} >

                  <div className="img-container" style={{ height: "120px", width: "300px", margin: "0 auto" }} >
                    <img src={type.img} alt={type.title} style={{ height: "auto", width: "auto", maxWidth: "85%", maxHeight: "100%", marginTop: "10px" }} />
                  </div>

                  <h3 style={{ color: "#212121", opacity: "0.5", fontSize: "23px" }}>{type.title}</h3>
                  <p style={{ color: "#212121", opacity: "0.5", fontSize: "18px", fontWeight: 600, maxWidth: "90%", margin: "15px auto 30px" }}>{type.info}</p>

                </div>)
              })}

            </div>

          

          </>}

          { <div className="results-text" style={{ position: "relative", margin: "5px auto", boxSizing: "border-box", borderRadius: "10px", bottom: "0px", top: "25px" }} onClick={(e) => accessToolsById(e, resultsTextContainer.id)}>
            {/* <div style={setStyle(resultsText.id)} onClick={(e) => accessToolsById(e, resultsText.id)}> */}
              {/* <ParagraphWidget blockId={resultsText.id} className="editable-text" /> */}
              {/* {resultsText.properties.memberText.length > 0 && resultsText.properties.memberText?.map((text) => { */}
                {/* return <p key={text.id} style={resultsText.styling}>{text.text}</p> */}
              {/* })} */}
            {/* </div> */}
            
            <div className="info" style={{ position: "absolute", right: "45px", color: "#21212177", fontSize: "12px", 
            fontWeight: 700, top: "-40px", padding: "8px", border: "1px solid gray", borderRadius: "4px", cursor: "pointer" }}
            onClick={() => showUpdateResultsTextModal(null)}>
              UPDATE COPY BASED ON SCORE
            </div>
            <div style={{ fontWeight: "700", border: "1px solid #707070", borderRadius: "8px", height: "56px", maxWidth: "92.5%", margin: "15px auto", textAlign: "start" }} className="temperature-heading">
                <h4 style={{ marginTop: "18px", marginLeft: "10px", color: "#212121", fontSize: "18px" }}>Congratulations! Based on your score...</h4>
              </div>
          </div>}

          {
            
            resultGraphType == "temperature" && <div className="temperatureScale">
                <div style={{ margin: "90px auto 5px", maxWidth: "90%" }} className="progress-bar">
                  <div className="si_ass_result">
                    <div style={{ position: "relative", maxWidth: "900px", height: "26px", borderRadius: "12px", width: "100%", backgroundImage: "linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%)" }} className="si_ass_progress">
                      <div style={{ zIndex: "2", width: "2px", height: "40px", background: "#343333", bottom: "0", position: "absolute", transition: "all 0.5s ease-in-out", left: "0%" }} className="progress-line">
                        {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                      </div>
                      <span style={{ position: "absolute", fontSize: "30px", fontWeight: "bold", color: "#4A4A4A", marginLeft: "-20px", left: "0%" }} className="msi_score">{Math.round(0)}%</span>
                    </div>
                    <div style={{ maxWidth: "900px", margin: "5px auto", display: "flex", color: "#4A4A4A", fontSize: "15px", position: "relative", top: "-20px" }} className="pervalue">
                      <h4 style={{ position: "relative",  minWidth: '0%', top: "5px" }} className="nimp1"></h4>
                      <h4 style={{ position: "relative", minWidth: '50%', top: "5px" }} className="nimp" ></h4>
                      <h4 style={{ position: "relative", minWidth: '30%', top: "5px" }} className="mexpe" ></h4>
                      <h4 style={{ position: "relative", minWidth: '20%', top: "5px" }} className="eexpe" ></h4>
                      {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                      {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                      {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                    </div>
                    
                    
                    <div style={{ maxWidth: "900px", margin: "10px auto 0", display: "flex", position: "relative", bottom: "88px" }} className="si_pro_header">
                      <h4 style={{ position: "relative", fontSize: "16px", fontWeight: "400", top: "7px", textAlign: "center", color: "#343333", textTransform: "capitalize", marginTop: "10px",  minWidth: '0%' }} className="nimp" ></h4>
                      <h4 style={{ position: "relative", fontSize: "16px", fontWeight: "400", top: "7px", textAlign: "center", color: "#343333", textTransform: "capitalize", marginTop: "10px", minWidth: '50%' }} className="mexpe" ></h4>
                      <h4 style={{ position: "relative", fontSize: "16px", fontWeight: "400", top: "7px", textAlign: "center", color: "#343333", textTransform: "capitalize", marginTop: "10px", minWidth: '30%' }} className="eexpe" ></h4>
                      {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                      {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
                    </div>
                    
                    {/* <div className="pervalue-text"> */}
                      {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                      {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                      {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                      {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                    {/* </div> */}
                  
                    <div style={{position: "relative", display: "flex", margin: "2px auto", width: "100%", maxWidth: "900px", bottom: "55px" }} className="pervalue-text">
                      <p className="not-aligned" style={{ width: "50%", fontWeight: score <= 50 ? 700 : 500, textAlign: "center", position: "relative", margin: "0 auto", top: "12px", fontSize: "20px", verticalAlign: "top", color: "#0F131D" }}>Not<br/>Engaging</p>
                      {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                      <p className="some-aligned" style={{ width: "30%", fontWeight: score > 50 && score <= 80 ? 700 : 500, textAlign: "center", position: "relative", margin: "0 auto", top: "12px", fontSize: "20px", verticalAlign: "top", color: "#0F131D" }}>Effectively<br/>Engaging</p>
                      {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                      <p className="high-aligned" style={{ width: "20%", fontWeight: score > 80 ? 700 : 500, textAlign: "center", position: "relative", margin: "0 auto", top: "12px", fontSize: "20px", verticalAlign: "top", color: "#0F131D" }}>Elevating<br/>Engagement</p>
                    </div>

                  </div>
              </div>

              <div style={{ fontWeight: "700", border: "1px solid #707070", borderRadius: "8px", height: "56px", maxWidth: "92.5%", margin: "15px auto", textAlign: "start" }} className="temperature-heading">
                <h4 style={{ marginTop: "18px", marginLeft: "10px", color: "#212121", fontSize: "18px" }}>Congratulations! Based on your score...</h4>
              </div>

              <div style={{ display: "grid", gridTemplateColumns: "repeat(5, 1fr)", gridTemplateRows: "repeat(1, 1fr)", justifyContent: "center", alignItems: "center", maxWidth: "900px", padding: "1rem 0 1rem", margin: "2rem auto 1rem", width: "95%"}} className="score-section-container">
                <div style={{ display: "flex", flexDirection: "column", width: "184px", textAlign: "start", color: "#343333", fontSize: "18px", fontWeight: "700", margin: "20px 10px", alignItems: "start"}} className="score-section">
                    <p style={{ fontSize: "21px", width: "100%", lineHeight: "25px", fontWeight: "700", transition: "all 0.4s linear", margin: "6px auto", maxWidth: "fit-content" }} className="score-txt">{Math.round(0)}%</p>
                    <div style={{ width: "100%", margin: "0 auto" }} className="score-slider"><LinearProgress style={{backgroundColor: "#E6E6E6", borderRadius: "40px", height: "10px" }} className="custom-bar" variant="determinate" value={Math.round(0)} />
                    </div>
                    <p style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", margin: "15px auto 10px", color: "#212121" }} className="score-name">COLLABORATION</p>        
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", width: "184px", textAlign: "start", color: "#343333", fontSize: "18px", fontWeight: "700", margin: "20px 10px", alignItems: "start"}} className="score-section">
                    <p style={{ fontSize: "21px", width: "100%", lineHeight: "25px", fontWeight: "700", transition: "all 0.4s linear", margin: "6px auto", maxWidth: "fit-content" }} className="score-txt">{Math.round(0)}%</p>
                    <div style={{ width: "100%", margin: "0 auto" }} className="score-slider"><LinearProgress style={{backgroundColor: "#E6E6E6", borderRadius: "40px", height: "10px" }} className="custom-bar" variant="determinate" value={Math.round(0)} />
                    </div>
                    <p style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", margin: "15px auto 10px", color: "#212121" }} className="score-name">COLLABORATION</p>        
                  </div>


                  <div style={{ display: "flex", flexDirection: "column", width: "184px", textAlign: "start", color: "#343333", fontSize: "18px", fontWeight: "700", margin: "20px 10px", alignItems: "start"}} className="score-section">
                    <p style={{ fontSize: "21px", width: "100%", lineHeight: "25px", fontWeight: "700", transition: "all 0.4s linear", margin: "6px auto", maxWidth: "fit-content" }} className="score-txt">{Math.round(0)}%</p>
                    <div style={{ width: "100%", margin: "0 auto" }} className="score-slider"><LinearProgress style={{backgroundColor: "#E6E6E6", borderRadius: "40px", height: "10px" }} className="custom-bar" variant="determinate" value={Math.round(0)} />
                    </div>
                    <p style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", margin: "15px auto 10px", color: "#212121" }} className="score-name">COLLABORATION</p>        
                  </div>


                  <div style={{ display: "flex", flexDirection: "column", width: "184px", textAlign: "start", color: "#343333", fontSize: "18px", fontWeight: "700", margin: "20px 10px", alignItems: "start"}} className="score-section">
                    <p style={{ fontSize: "21px", width: "100%", lineHeight: "25px", fontWeight: "700", transition: "all 0.4s linear", margin: "6px auto", maxWidth: "fit-content" }} className="score-txt">{Math.round(0)}%</p>
                    <div style={{ width: "100%", margin: "0 auto" }} className="score-slider"><LinearProgress style={{backgroundColor: "#E6E6E6", borderRadius: "40px", height: "10px" }} className="custom-bar" variant="determinate" value={Math.round(0)} />
                    </div>
                    <p style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", margin: "15px auto 10px", color: "#212121" }} className="score-name">COLLABORATION</p>        
                  </div>

                  <div style={{ display: "flex", flexDirection: "column", width: "184px", textAlign: "start", color: "#343333", fontSize: "18px", fontWeight: "700", margin: "20px 10px", alignItems: "start"}} className="score-section">
                    <p style={{ fontSize: "21px", width: "100%", lineHeight: "25px", fontWeight: "700", transition: "all 0.4s linear", margin: "6px auto", maxWidth: "fit-content" }} className="score-txt">{Math.round(0)}%</p>
                    <div style={{ width: "100%", margin: "0 auto" }} className="score-slider"><LinearProgress style={{backgroundColor: "#E6E6E6", borderRadius: "40px", height: "10px" }} className="custom-bar" variant="determinate" value={Math.round(0)} />
                    </div>
                    <p style={{ textAlign: "center", fontWeight: "700", fontSize: "18px", margin: "15px auto 10px", color: "#212121" }} className="score-name">COLLABORATION</p>        
                  </div>
              </div>

            </div>
          }

      </div>

      <div className="invite-team-add-section-container">
          <h1>INVITE TEAM</h1>
          <div className="invite-team-content"  onClick={(e)=>{accessToolsById(e,3978)}} style={setStyle(3978)}> {/* used unused background id here from results contentblocks */}
              { !showInvite ? <button onClick={()=>{setShowInvite(true)}}> <Add/> Add </button> : <></> }
              { showInvite ? <InviteInfo/> : <></> }
          </div>
      </div>

      <div className="testimonials-add-section-container">
          <h1>TESTIMONIALS</h1>
          <div className="testimonial-content">
              { !testimonial ? <button onClick={()=>{settTestimonial(true)}}> <Add/> Add </button> : <></> }
              { testimonial ? <Testimonial/> : <></> }
          </div>
      </div>

      <div className="sample-dashboard-section-container">
          <h1>SAMPLE DASHBOARD</h1>
          <div className="sample-dashboard-content">
              { !showDashboard ? <button onClick={()=>{setShowDashboard(true)}}> <Add/> Add </button> : <></> }
              { showDashboard ? <SampleDashboard/> : <></> }
          </div>
      </div>

    </div>


  )
}



export default Results;
