import { useState, useEffect, useContext } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from "../../../../store/BuilderContext";

const Position = (props) => {


  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);

  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;

  const currentPosition = currentBlockStyles?.position;


  // css position property 
  const positions = [
    { value: 'static',    label: 'static'   },
    { value: 'relative',  label: 'relative' },
    { value: 'fixed',     label: 'fixed'    },
    { value: 'absolute',  label: 'absolute' },
    { value: 'sticky',    label: 'sticky'   },
  ];


  const [position, setPosition] = useState(currentBlockStyles?.position || "static");

  const handlePositionChange = (e) => setPosition(e.target.value);


  useEffect(() => {

    const timer = setTimeout(() => {
      
      if(position !== currentPosition) {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            let newStyles = { ...block.styling };
            newStyles.position = position;
            block.styling = { ...newStyles };
            return block;
          } else return block;
        }))
      }
    
    }, 500);
    
    return () => clearTimeout(timer);

  }, [blockId, position]);


  // console.log("blockId", blockId);
  // console.log("position", position);


  return (
    <div className="positioning tool">

      <div className="position">
        {props.deleteModal}
        <TextField className="position-unit styling-select" select variant="standard"
          InputProps={{ disableUnderline: true }}
          // placeholder={currentContentBlock?.styling?.position + ""} 
          value={position} onChange={handlePositionChange} >
          {positions?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>

    </div>
  )


}

export default Position;

