import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const Padding = (props) => {

  const { blockId } = props;
  
  const builderCtx                                                  = useContext(BuilderContext);
  const authCtx                                                     = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;
  const [currentPosition, setCurrentPosition]                       = useState();

  const currentBlockStyles  = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentPaddingT     = currentBlockStyles?.paddingTop?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  const currentPaddingR     = currentBlockStyles?.paddingRight?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  const currentPaddingB     = currentBlockStyles?.paddingBottom?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  const currentPaddingL     = currentBlockStyles?.paddingLeft?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);

  const units = [
    { value: 'px',    label: 'px'   },
    { value: 'rem',   label: 'rem'  },
    { value: '%',     label: '%'    },
    { value: 'vh',    label: 'vh'   },
    { value: 'vw',    label: 'vw'   },
    { value: 'auto',  label: 'Auto' },
  ];

 
  const [paddings, setPaddings] = useState({
    paddingTop:    currentPaddingT?.[0] || "0",
    paddingRight:  currentPaddingR?.[0] || "0",
    paddingBottom: currentPaddingB?.[0] || "0",
    paddingLeft:   currentPaddingL?.[0] || "0",
  })

  const [paddingUnits, setPaddingUnits] = useState({
    paddingTop:    currentPaddingT?.[0] === "auto" ? "auto" : currentPaddingT?.[1] || "px",
    paddingRight:  currentPaddingR?.[0] === "auto" ? "auto" : currentPaddingR?.[1] || "px",
    paddingBottom: currentPaddingB?.[0] === "auto" ? "auto" : currentPaddingB?.[1] || "px",
    paddingLeft:   currentPaddingL?.[0] === "auto" ? "auto" : currentPaddingL?.[1] || "px",
  })
  
  const [finalValue, setFinalValue]   = useState("");

  const handleChange = (e, property, position) => {

    const targetVal = e.target.value;
    const setterFunc = property === "padding" ? setPaddings : property === "unit" ? setPaddingUnits : null;

    setCurrentPosition(position)

    setterFunc((prev) => { 
      prev[position] = targetVal;
      return { ...prev };
    })

  }

  useEffect(() => {
    
    let allPaddings = { ...paddings };
    for (const [key, value] of Object.entries(paddingUnits)) {
      console.log("key", key);
      console.log("value", value);
      console.log("allPaddings[key]", allPaddings[key]);
      if (allPaddings[key] !== "auto" && allPaddings[key] && value !== "auto") {
        allPaddings[key] += paddingUnits[key];
      } else if (value === "auto") {
        allPaddings[key] = "auto";
      } else if (!allPaddings[key]) {
        allPaddings[key] = "0";
      } else {
        allPaddings[key] = "0";
      }
    }

    if(allPaddings && (JSON.stringify(finalValue) !== (JSON.stringify(allPaddings)))) {
      setFinalValue(allPaddings)
    }

  }, [finalValue, paddingUnits, paddings])

  console.log("\n\nfinalValue paddings", finalValue, paddings, "\n\n");

  useEffect(() => {

    let checkStyles = [];
    for (const key in finalValue) {
      if(currentBlockStyles?.[key] === finalValue[key]) {
        checkStyles.push(true);
      } else checkStyles.push(false);
    }
    let stylesAreSame = checkStyles?.every(val => val === true);

    const timer = setTimeout(() => {
      if(finalValue && !stylesAreSame) { 
        updateContentBlocks(contentBlocks?.map(block => {
          if(block.id === blockId) {
            let styles = { ...block.styling };
            for (const key in finalValue) {
              styles[key] = finalValue[key];
            }
            block.styling = styles;
            console.log('finalValue',finalValue)
            updatePadding(currentPosition)
            return block;
          } else return block;
        }))
      }
    }, 500);
    
    return () => clearTimeout(timer);
    
  }, [blockId, currentBlockStyles, finalValue]);

  const updatePadding = (paddingDirection) => {

    console.log("paddingUnits[paddingDirection]",paddingUnits[paddingDirection])
    let payload = {
      id: blockId,
      value: finalValue[paddingDirection],
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/'+paddingDirection, {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log(`updating ${paddingDirection} payload & finalValue`, payload,finalValue);
      }
    }).catch((err) => {
      console.error("Failed to update paddingDirection...", err);
    });
  }


  // console.log("finalValue", finalValue);

  return (
    <div className="tool">
      {props.deleteModal}

      <div className="padding-section">
        <p className="subheaders">Top</p>
        <div className="input-and-unit-container">
          <TextField className="padding-input styling-input" type="number" variant="filled" min="0"
            InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling?.paddingTop+ ""} value={paddings.paddingTop} onChange={(e) => handleChange(e, "padding", "paddingTop")} />

          <TextField className="padding-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          placeholder={currentContentBlock?.styling?.paddingTop + ""} value={paddingUnits?.paddingTop || "0"} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            setCurrentPosition("paddingTop")
            handleChange(e, "unit", "paddingTop");
          }} >
            {units?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      
      <div className="padding-section">
        <p className="subheaders">Right</p>
        <div className="input-and-unit-container">
          <TextField className="padding-input styling-input" type="number" variant="filled"
            InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling?.paddingRight + ""} value={paddings.paddingRight} onChange={(e) => handleChange(e, "padding", "paddingRight")} />
          
          <TextField className="padding-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          placeholder={currentContentBlock?.styling?.paddingRight + ""} value={paddingUnits?.paddingRight || "0"} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            setCurrentPosition("paddingRight")
            handleChange(e, "unit", "paddingRight");
          }} >
            {units?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
        
      </div>
      
      <div className="padding-section">
        <p className="subheaders">Bottom</p>
        <div className="input-and-unit-container">
          <TextField className="padding-input styling-input" type="number" variant="filled"
            InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling.paddingBottom + ""} value={paddings.paddingBottom} onChange={(e) => handleChange(e, "padding", "paddingBottom")} />
          
          <TextField className="padding-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          placeholder={currentContentBlock.styling.paddingBottom + ""} value={paddingUnits?.paddingBottom || "0"} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            setCurrentPosition("paddingBottom")
            handleChange(e, "unit", "paddingBottom");
          }} >
            {units?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      
      <div className="padding-section">
        <p className="subheaders">Left</p>
        <div className="input-and-unit-container">
          <TextField className="padding-input styling-input" type="number" variant="filled"
            InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling.paddingLeft + ""} value={paddings.paddingLeft} onChange={(e) => handleChange(e, "padding", "paddingLeft")} />
          
          <TextField className="padding-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          placeholder={currentContentBlock.styling.paddingLeft + ""} value={paddingUnits?.paddingLeft || "0"} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            setCurrentPosition("paddingLeft")
            handleChange(e, "unit", "paddingLeft");
          }} >
            {units?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>

    </div>
  )
}


export default Padding;
