
import React,{useContext, useState} from 'react'


import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { CircularProgress} from "@mui/material";


const DeleteURLDetails = (props) => {

  console.log("DeleteURLDetails props", props);


  // console.log("urlDetails", urlDetails);

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading]= useState("");
  const urlDetails = props?.details.websiteDetails;
  const toggleRefresh = props?.details.toggleRefresh;

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const handleDeleteURL = (e) => {
    e.preventDefault();
    setIsLoading(true);

    fetch(process.env.REACT_APP_API_URI + '/v2/datamining/website/url/' + urlDetails?.id, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        console.log("Successfully deleted URL...");
        setIsLoading(false);
        props?.details.setToggleRefresh(!toggleRefresh);
        handleCloseModal();
        modalCtx.setPageUpdate({page: "datamining"});
      } else {
        console.log("Failed to delete URL...");
      }
    })
    .catch(err => console.error("Failed to delete URL...", err));

  }

  return (
    <div className='modal'>
      {isLoading ? 
      <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></section> 
      :
      <>
      <p style={{ margin: "5px auto 15px" }}>Are you sure you want to delete this URL?</p>
      <p style={{ margin: "5px auto 30px" }}>{urlDetails?.url || "-----"}</p>
      <div className="footer" style={{ margin: "15px auto 0" }}>
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
        <Button type="submit" className="confirm-btn" onClick={(e) => handleDeleteURL(e)}>Delete</Button>
      </div>
      </>
    }
    </div>
  )
}


export default DeleteURLDetails;
