import React, { useContext, useState, useEffect } from 'react'
import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { CircularProgress} from "@mui/material";

const DeleteAbandonedAssessment = (props) => {

  console.log("DeleteAbandonedAssessment props", props);

  const details = props?.details;

  console.log("details", details);

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [questions, setQuestions] = useState();
  const [additionalQuestions, setAdditionalQuestions] = useState();
  const [isLoading, setIsLoading]= useState("");
  const toggleRefresh = props?.details?.toggleRefresh;


  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  useEffect(()=>{getQuestions()},[])

  const getQuestions = () => {

    const envURI = process.env.REACT_APP_API_URI;
    let options = { method: 'GET', headers: { 
      'Content-Type': 'application/json' ,
      'Authorization': 'Bearer ' + authCtx.token 
    },}
    Promise.all([
      fetch(envURI + '/v3/admin/questions/' + details.id , options).then(response => response.json()),
      fetch(envURI + '/v3/admin/questions/' + details.id + '/additionals', options).then(response => response.json()),
      // fetch(envURI + '/v1/questions/' + id, options).then(response => response.json()),
      // fetch(envURI + '/v1/questions/' + id + '/additional', options).then(response => response.json()),
    ]).then((data) => {
      console.log("API data---------------------------------", data);
      if (data[0].status === "success") {
        setQuestions(data[0].questions);
      }
      if (data[1].status === "success") {
        setAdditionalQuestions(data[1].questions)
        console.log("Additional Questions API Data", data[1]);
      }
    }).catch(err => {
      console.error(err);
    });
  }

  const handleDeleteAbandonedAssessment = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const jsonBody = questions.map((el)=>{
      return {
        id:el.id,
        answer:el.answer,
        answerTxt:el.answerTxt
      }
    })

    console.log('jsonBody',jsonBody)

    fetch(process.env.REACT_APP_API_URI + `/v3/admin/user/assessment/${details?.id}/abandoned`, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(jsonBody),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          console.log("Successfully deleted abandoned assessment...");
          setIsLoading(false);
          handleCloseModal();
          modalCtx.setPageUpdate({ page: "abandoned-assessment" });
        } else {
          console.log("Failed to delete abandoned assessment...");
        }
      })
      .catch(err => console.error("Failed to delete abandoned assessment...", err));

  }

  return (
    <div className='modal'>      
      <>
        <p style={{ margin: "5px auto 15px" }}>Are you sure you wish to delete all the abandoned assessments?</p>
        <div className="footer" style={{ margin: "15px auto 0" }}>
          <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
          { isLoading ? <CircularProgress /> : <Button type="submit" className="confirm-btn" onClick={(e) => handleDeleteAbandonedAssessment(e)}>Delete</Button> }
        </div>
      </>
    </div>
    
  )
}

export default DeleteAbandonedAssessment;
