import { useState, useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import BackgroundColor from "./StylingComponents/BackgroundColor";
import Color from "./StylingComponents/Color";
import Height from "./StylingComponents/Height";
import Width from "./StylingComponents/Width";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";


const AnswerSliderWidget = (props) => {
  console.log("AnswerSliderWidget props", props);

  // const blockId = props?.blockId;
  // console.log("SectionWidget blockId", blockId);

  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  console.log("currentContentBlock", currentContentBlock);

  const currentBlock = contentBlocks?.find(block => block.contentType === "AnswerSlider");
  const blockId = currentBlock?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log(currentBlockStyles);
  console.log("currentBlock", currentBlock);

  return (
    <div>

      <Color blockId={blockId} propertyName="sliderColor" name="Slider" />
      <Color blockId={blockId} propertyName="sliderBackgroundColor" name="Slider Background" />
      {/* <Height blockId={blockId} />
      <Width blockId={blockId} /> */}
      <DynamicStylingTool/>
    </div>
  );
}

export default AnswerSliderWidget;

