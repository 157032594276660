import { useContext } from 'react';

import { Close } from '@material-ui/icons';
import { Button } from '@mui/material';
import calendlyLogo from "../../assets/img/calendly.svg";

import DrawerContext from '../../store/DrawerContext';

const CalendlyDrawer = props => {

  const drawerCtx = useContext(DrawerContext);

  const callDetails = props.userDetails?.callDetails;

  // console.log("CalendlyDrawerProps", props);
  console.log("callDetails", callDetails);

  return (
    <div className="user-details drawer">

      <header className="user-details-header">
        <h1>Calendly Details</h1>
        <Close className="close-user-details-drawer" onClick={drawerCtx.closeDrawer} />
      </header>

      <section>

        <p><strong>Name:</strong> {callDetails?.name || "Unavailable"}</p>
        <p><strong>Description:</strong> {callDetails?.description || "Unavailable"}</p>
        <p><strong>Starts at:</strong> {callDetails?.startAt ? new Date(callDetails?.startAt).toUTCString() : "Unavailable"}</p>
        <p><strong>Location:</strong> {callDetails?.location || "Unavailable"}</p>
        <p><strong>Event:</strong>&nbsp;&nbsp;

          {callDetails?.event ? <Button style={{ padding: "12px 24px" }}>
            <a style={{ fontSize: "14px", fontWeight: 600, textDecoration: "none", color: "#313434", display: "flex" }} href={callDetails?.event} target="_blank" rel="noreferrer">
              Join on&nbsp;&nbsp;<img src={calendlyLogo} height="24px" width="auto" alt="calendly link" />
            </a>
          </Button> : "Unavailable"}

        </p>

      </section>
    </div>
  )
}


export default CalendlyDrawer;
