import { CloseOutlined, RadioButtonChecked } from "@material-ui/icons";
import { Button, Checkbox } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ModalContext from "../../store/ModalContext";
import { CheckBox } from "@mui/icons-material";
import AuthContext from "../../store/AuthContext";
import BuilderContext from "../../store/BuilderContext";

const AddNewProperty = (props) => {  
  const [property, setProperty]                       = useState("");
  const [value, setValue]                             = useState("");
  const [showProperty, setShowProperty]               = useState(false);
  const [uniqueProperties, setUniqueProperties]       = useState();
  const [builderConfirmation, setBuilderConfirmation] = useState(false)
  const [blockID, setBlockID]                         = useState('');
  const [showBlockID, setShowBlockID]                 = useState(false);
  const [filteredBlockIDs, setFilteredBlockIDs]       = useState([]);


  const modalCtx                                             = useContext(ModalContext);
  const authCtx                                              = useContext(AuthContext);
  const builderCtx                                           = useContext(BuilderContext);
  const { assessmentId, contentBlocks }                      = builderCtx;
  
  const filterBlockIDs = (input) => {
    const filtered = contentBlocks.filter(blockID => 
    blockID.id.toString().includes(input.toString()))

    setFilteredBlockIDs(filtered)
  };

  const filteredSuggestions = uniqueProperties?.filter(suggestion =>
    suggestion.toLowerCase().includes(property?.toLowerCase())
  );

  const addProperty = () => {
    const payload = {
      "propType": property,
      "property": value,
    }

    console.log('payload',payload, 'blockID',blockID)
    
    fetch(process.env.REACT_APP_API_URI + `/v3/admin/assessment/${assessmentId}/content/${blockID}/property`, {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token},
      body: JSON.stringify(payload)
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
      if ( data.status === 'success' ) modalCtx.closeModal(); 
      // console.log('data',data)
    });
  }

  useEffect(()=>{
    const uniqueProperties = [...new Set(contentBlocks.flatMap(obj => Object.keys(obj.properties)))];

    setUniqueProperties(uniqueProperties)
  },[contentBlocks])

  useEffect(()=>{
    console.log('uniqueProperties',uniqueProperties)
  },[uniqueProperties])

  return (
    <div className="modal create-new property-modal" style={{ "borderRadius": "10px" }}>
     <div className="heading-and-close-btn-container">
        <h1>Create New Property</h1>
        <Button onClick={()=>{modalCtx.closeModal()}}>
          <CloseOutlined/>
        </Button>
     </div>
     <div className="style-property-input-container">
        <h3>Attribute</h3>
        <input type="text" placeholder="Style Property" value={property} onChange={(e)=>{
          setProperty(e.target.value)
          setShowProperty(true)
        }}
        onFocus={()=>{
          setShowProperty(true)
        }}
        onBlur={()=>{
          setTimeout(() => {
            setShowProperty(false)
          }, 200);
        }}
        />
        {
          showProperty ? <div className="new-style-suggestionbox-container">
          {
            filteredSuggestions.map((suggestion, index) => (
              <p key={index} onClick={()=>{
                setProperty(suggestion)
                setShowProperty(false)
              }}>{suggestion}</p>
            ))
          }
        </div> : <></>
        }
     </div> 
     <div className="block-id-input-container">
      <h3>Content Block</h3>
      <input
        type="text"
        placeholder="Content Block"
        value={blockID}
        onChange={(e) => {
          setBlockID(e.target.value);
          setShowBlockID(true);
          filterBlockIDs(e.target.value);
        }}
        onFocus={() => setShowBlockID(true)}
        onBlur={() => {
          setTimeout(() => {
            setShowBlockID(false);
          }, 200);
        }}
      />
      {showBlockID ? <>
        {
          !blockID ? <div className="block-id-suggestionbox-container">
            {contentBlocks.map((el, index) => (
              <p
                key={index}
                onClick={() => {
                  setBlockID(el.id);
                  setShowBlockID(false);
                }}
              >
                <span>{el.id}</span>
                <span>{el.contentType}</span>
              </p>
            ))}
          </div> : <div className="block-id-suggestionbox-container">
            {filteredBlockIDs.map((el, index) => (
              <p
                key={index}
                onClick={() => {
                  setBlockID(el.id);
                  setShowBlockID(false);
                }}
              >
                <span>{el.id}</span>
                <span>{el.contentType}</span>
              </p>
            ))}
          </div>
        }
      </> : <></>}
    </div>
     <div className="value-input-container">
        <h3>Value</h3>
        <input type="text" placeholder="Value" onChange={(e)=>{setValue(e.target.value)}}/>
     </div>
     <div className="all-builders-confirmation">
        <Checkbox checked={builderConfirmation} onChange={()=>{
          builderConfirmation ? setBuilderConfirmation(false) : setBuilderConfirmation(true)
        }} inputProps={{ 'aria-label': 'controlled' }} />
        <h3 className="checkbox-label">Add this to all default builders</h3>
     </div>
     <div className="create-btn-container">
      <Button onClick={addProperty}>Create</Button>
     </div>
    </div>
  )
}


export default AddNewProperty;