import { useContext, useState, useRef, useCallback } from 'react';
import Cropper from 'react-easy-crop';
import ReactCrop, {centerCrop, makeAspectCrop, Crop, PixelCrop } from 'react-image-crop';
import { Base64 } from 'js-base64';
import 'react-image-crop/src/ReactCrop.scss';

import { Button, CircularProgress, Slider } from '@mui/material';

import { CloseRounded } from '@mui/icons-material';

import ModalContext from '../../../store/ModalContext.js';
import AuthContext from '../../../store/AuthContext.js';
import Dropzone from './Dropzone';

import { canvasPreview } from './CropImage.js';

function centerAspectCrop(mediaWidth, mediaHeight, aspect) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}

const ImageCropper = () => {

  // const db = database;
  const ref = useRef()

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [imageType, setImageType] = useState(modalCtx?.details?.imageType)
  // const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [crop, setCrop] = useState({
    unit: 'px', // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 200,
    height: 50
  })
  const [zoom, setZoom] = useState(-1);
  const [completedCrop, setCompletedCrop] = useState();
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)
  const [croppedImage, setCroppedImage] = useState(null)
  const [image, setImage] = useState();
  const [finalImage, setFinalImage] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const [cropConfig, setCropConfig] = useState(
    // default crop config
    {
      unit: "%",
      width: 100,
      aspect: 4 / 1
    }
  );
  const [imageRef, setImageRef] = useState(null);

  // async function cropImage(crop) {
  //   if (imageRef && crop.width && crop.height) {
  //     const croppedImage = await getCroppedImage(
  //       imageRef,
  //       crop,
  //       "croppedImage.jpeg" // destination filename
  //     );

  //     // calling the props function to expose
  //     // croppedImage to the parent component
  //     onImageCropped(croppedImage);
  //   }
  // }


  // get dropped images
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
      };
      reader.readAsDataURL(file);
        // const { width, height } = e.target.result;
        // console.log(width);
        // setCrop(centerAspectCrop(width, height, 4 / 1))
        // setImageRef(file)
      return file;
    });
  }, []);  
  
  
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    console.log(croppedAreaPixels);
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  
  // get cropped image 
  const cropImage = async () => {
    try {
      console.log("Are we here");
      console.log(ref.current);
      console.log(crop.width);
      console.log(crop.height);
      console.log(cropConfig);
      if (ref.current && cropConfig.width && cropConfig.height) {
        const croppedImage = await getCroppedImage(
          ref.current,
          cropConfig,
          "croppedImage.jpeg" // destination filename
        );
  
        // calling the props function to expose
        // croppedImage to the parent component
        // onImageCropped(croppedImage);
        setCroppedImage(croppedImage);
      }

      // // const croppedImage = await getCroppedImg(image, crop, 0, zoom);
      // console.log(completedCrop);
      // const croppedImage = await canvasPreview(image, completedCrop, zoom, 0);
      // console.log(croppedImage);
      // setCroppedImage(croppedImage);
    } catch (e) {
      console.error(e)
    }
  }

  function getCroppedImage(sourceImage, cropConfig, fileName) {
    // creating the cropped image from the source image
    const canvas = document.createElement("canvas");
    const scaleX = sourceImage.naturalWidth / sourceImage.width;
    const scaleY = sourceImage.naturalHeight / sourceImage.height;
    canvas.width = cropConfig.width;
    canvas.height = cropConfig.height;
    const ctx = canvas.getContext("2d");

    console.log(cropConfig.x);

    ctx.drawImage(
      sourceImage,
      cropConfig.x * scaleX,
      cropConfig.y * scaleY,
      cropConfig.width * scaleX,
      cropConfig.height * scaleY,
      0,
      0,
      cropConfig.width,
      cropConfig.height
    );

    return canvas.toDataURL('image/png');

    // return new Promise((resolve, reject) => {
    //   canvas.toBlob((blob) => {
    //     // returning an error
    //     if (!blob) {
    //       reject(new Error("Canvas is empty"));
    //       return;
    //     }

    //     blob.name = fileName;
    //     // creating a Object URL representing the Blob object given
    //     const croppedImageUrl = window.URL.createObjectURL(blob);

    //     resolve(croppedImageUrl);
    //   }, "image/jpeg");
    // });
  }

  const resetCrop = () => {
    setCroppedImage(null);
  }
  
  // this gets a file object which is passed to firebase
  const baseToFile = (base) => {    
    if (base) {
      let arr = base?.split(",")    
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]
  
      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)
    
      while(n--)  {
        dataArr[n] = dataStr.charCodeAt(n)
      }
    
      let file = new File([dataArr], `img_cropper_${Date.now()}.png`, {type: mime})
      return file
    } else {
      return null
    }
  }
          
  // puts image into a state
  const uploadImg = () => {
    
    let img = baseToFile(croppedImage);        
    if(img) {
      console.log(img);
      // setFinalImage(img);
      setIsUploading(true);

      let formdata = new FormData();
      formdata.append("file", img);

      fetch(process.env.REACT_APP_API_URI + '/v1/accounts/' + modalCtx?.details?.id + '/uploadLogo', {
        method: 'POST',
        // headers: { 'Authorization': 'Bearer ' + authCtx.token, },
        body: formdata,
        redirect: 'follow'
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        setIsUploading(true);
        if(data.status == "success") {
          modalCtx.setDetails(null);
          modalCtx.closeModal();
        }
      }).catch(error => {
        console.log('error', error);
      });

    } else {
      console.log(img);
      console.log("no img");
    }

  }
    // colse image
  const onClose = useCallback(() => {
    setCroppedImage(null)
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }, []);

  const showTitle = () => {
    switch(imageType) {

      case "client-logo": return <h2>Upload Client Logo</h2>;
      case "results-page-image": return <h2>Upload Client Logo</h2>;
    }
  }

  function onImageLoad(e) {
    const { width, height } = e.currentTarget;
    console.log(e.currentTarget);
    setCrop(centerAspectCrop(width, height, 4 / 1))
  }

  console.log("cropConfig", cropConfig);

  return (
    <div className='crop-container'>
      <div className="title">
        { showTitle() }
        <CloseRounded onClick={() => onClose()} />
      </div>
      <div className='easy-crop'>
        {/* <input type="file" onChange={(e) => setFinalImage(e.target.files)} /> */}
        { 
          image ? <ReactCrop
            crop={cropConfig}
            ruleOfThirds
            // aspect={4 / 1}
            // onImageLoad={(imageRef) => setImageRef(imageRef)}
            // onComplete={(cropConfig) => cropImage(cropConfig)}
            onChange={(cropConfig) => setCropConfig(cropConfig)}
            crossorigin="anonymous" // to avoid CORS-related problems
          ><img src={image} ref={ref}/></ReactCrop>
          
          
          // <ReactCrop crop={crop} onChange={(c) => setCrop(c)} onComplete={(c) => setCompletedCrop(c)} aspect={4 / 1}>
          //   <img ref={ref} src={image} style={{ transform: `scale(${zoom})` }} />
          // </ReactCrop>
          // <Cropper
          //   image={image}
          //   crop={crop}
          //   zoom={zoom}
          //   showGrid={!croppedImage}
          //   aspect={3 / 1}
          //   onCropChange={setCrop}
          //   onCropComplete={onCropComplete}
          //   onZoomChange={setZoom}
          // />
          :
          <Dropzone onDrop={onDrop} accept={"image/*"} />
        }
      </div>
      <div className='crop-btns'>
        <div className="slider">
          <h3> Zoom </h3>
          <Slider value={zoom} min={-1} max={3} step={0.1} aria-labelledby="Zoom" onChange={(e, zoom) => setZoom(zoom)} />
        </div>

        {!croppedImage ? <Button onClick={(cropConfig) => cropImage(cropConfig)} disabled={!image}>Done Cropping</Button> : null }
        {croppedImage ? <Button onClick={resetCrop} style={{width: "100px"}}>Adjust</Button> : null }
        {croppedImage ? <Button onClick={uploadImg}>{isUploading ? <CircularProgress style={{color: "#fff"}} /> : "Upload"}</Button> : null }
      </div>

    </div>
  )
}

export default ImageCropper;
