import React, { useState, useContext, useEffect, Component } from "react";
import { Drawer } from "@material-ui/core";
import DrawerContext from "../store/DrawerContext.js";
import AddQuestionDrawer from "./Drawers/AddQuestionDrawer.jsx";
import CreateClientDrawer from "./Drawers/CreateClientDrawer.jsx";
import EditClientDrawer from "./Drawers/EditClientDrawer"
import EditAssessmentDrawer from "./Drawers/EditAssessmentDrawer"
import CreateAssessmentDrawer from "./Drawers/CreateAssessmentDrawer.jsx";
import UsersDrawer from "./Drawers/UsersDrawer.jsx";
import EditQuestionDrawer from "./Drawers/EditQuestionDrawer.jsx";
import UserDetailsDrawer from "./Drawers/UserDetailsDrawer.jsx";
import CalendlyDrawer from "./Drawers/CalendlyDrawer.jsx";
import CreateAssessmentSectionDrawer from "./Drawers/CreateAssessmentSectionDrawer.jsx";
import EditAssessmentSectionDrawer from "./Drawers/EditAssessmentSectionDrawer.jsx";
import CreateClientAdministratorDrawer from "./Drawers/CreateClientAdministratorDrawer.jsx";
import EditClientAdministratorDrawer from "./Drawers/EditClientAdministratorDrawer.jsx";
import CreateAssessmentFieldDrawer from "./Drawers/CreateAssessmentFieldDrawer.jsx";
import EditAssessmentFieldDrawer from "./Drawers/EditAssessmentFieldDrawer.jsx";
import CreateAssessmentAutomationDrawer from "./Drawers/CreateAssessmentAutomationDrawer.jsx";
import EditAssessmentAutomationDrawer from "./Drawers/EditAssessmentAutomationDrawer.jsx";
import EditAssessmentAutomationDrawerNew from "./Drawers/EditAssessmentAutomationDrawerNew.jsx";
import QuestionsBuilderDrawer from "./Drawers/QuestionsBuilderDrawer.jsx";
import AssessmentScoringDrawer from "./Drawers/Scoring/AssessmentScoringDrawer.jsx";
import AddPieChartDrawer from "./Drawers/AddPieChartDrawer.jsx";
import AddBarGraph from "./Drawers/AddBarGraph.jsx";
import EditPieChartDrawer from "./Drawers/EditPieChartDrawer.jsx";

function DetailsDrawer(props) {
  const drawerCtx = useContext(DrawerContext);

  function closeDrawer() {
    drawerCtx.closeDrawer();
  }

  console.log('drawerCtx.details',drawerCtx.details)

  const currentDrawer = () => {
    switch (drawerCtx.drawer) {
      
      case "client-create": return (<Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><CreateClientDrawer details={drawerCtx.details} /></Drawer>);
      case "client-edit": return (<Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><EditClientDrawer details={drawerCtx.details} /></Drawer>);
      case "create-assessment": return (<Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><CreateAssessmentDrawer details={drawerCtx.details} /></Drawer>);
      case "edit-assessment": return (<Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><EditAssessmentDrawer details={drawerCtx.details} /></Drawer>);
      case "assessment-scoring": return (<Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><AssessmentScoringDrawer details={drawerCtx.details} /></Drawer>);
      case "question-add": return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><AddQuestionDrawer questionDetails={drawerCtx.details} /></Drawer>);
      case "question-edit": return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><EditQuestionDrawer questionDetails={drawerCtx.details} /></Drawer>);
      case "user-details" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <UserDetailsDrawer userDetails={drawerCtx.details} /></Drawer>);
      case "calendly-details" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <CalendlyDrawer userDetails={drawerCtx.details} /></Drawer>);
      case "create-assessment-section" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <CreateAssessmentSectionDrawer userDetails={drawerCtx.details} /></Drawer>);
      case "edit-assessment-section" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <EditAssessmentSectionDrawer drawerDetails={drawerCtx.details} /></Drawer>);
      case "create-client-administrator" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <CreateClientAdministratorDrawer userDetails={drawerCtx.details} /></Drawer>);
      case "edit-client-administrator" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <EditClientAdministratorDrawer userDetails={drawerCtx.details} /></Drawer>);
      case "create-assessment-fields" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <CreateAssessmentFieldDrawer details={drawerCtx.details} /></Drawer>);
      case "edit-assessment-fields" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <EditAssessmentFieldDrawer userDetails={drawerCtx.details} /></Drawer>);
      case "create-assessment-automation" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <CreateAssessmentAutomationDrawer userDetails={drawerCtx.details} /></Drawer>);
      case "edit-assessment-automation" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <EditAssessmentAutomationDrawer details={drawerCtx.details} /></Drawer>);
      case "edit-assessment-automation1" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <EditAssessmentAutomationDrawerNew details={drawerCtx.details} /></Drawer>);
      case "open-questions-builder" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <QuestionsBuilderDrawer details={drawerCtx.details} /></Drawer>);
      case "add-pie-chart" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <AddPieChartDrawer details={drawerCtx.details} /></Drawer>);
      case "add-bar-graph" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <AddBarGraph details={drawerCtx.details} /></Drawer>);
      case "edit-pie-chart" : return ( <Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}> <EditPieChartDrawer details={drawerCtx.details} /></Drawer>);
      
     
      default: return (<Drawer anchor="right" open={drawerCtx.open} onClose={closeDrawer}><h1>No details here, sorry!</h1></Drawer>);
    }
  };

  return <div>{currentDrawer()}</div>;
}
export default DetailsDrawer;
