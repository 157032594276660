import React, { useContext, useState, useRef, useEffect } from 'react';
import { CircularProgress, FormControl, FormGroup, TextField, Button, IconButton, Slider, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { ExpandMore } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { Menu, MenuItem } from '@material-ui/core';
import { Editor } from '@tinymce/tinymce-react';

import BoldIcon from "../../../src/assets/img/bold.svg";
import ItalicIcon from "../../../src/assets/img/italic1.svg";
import UnderlineIcon from "../../../src/assets/img/underline.svg";
import Line1 from "../../../src/assets/img/line-1.svg";
import Line2 from "../../../src/assets/img/line-2.svg";
import Line3 from "../../../src/assets/img/line-3.svg";
import DropDown from  "../../../src/assets/img/dropdown.svg";
import LinkIcon from "../../../src/assets/img/link.svg";
import VideoIcon from "../../../src/assets/img/video.svg";
import PictureIcon from "../../../src/assets/img/picture.svg";
import CodeIcon from "../../../src/assets/img/code.svg";
import FxIcon from "../../../src/assets/img/fx.svg";
import LmIcon from "../../../src/assets/img/Im.svg";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';

import ModalContext from '../../store/ModalContext.js';

const ResultsHeader = (props) => {

  console.log("UpdateResultsHeader props:", props);

  const modalCtx = useContext(ModalContext);

  const editorRef = useRef(null);

  const [text, setText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [textFields, setTextFields] = useState([{ id: uuidv4(), value: "Needs Improvement", range: [0, 100] }]);
  const [value, setValue] = useState("");

  const [normalAnchorEl, setNormalAnchorEl] = React.useState(null);
  const [robotoAnchorEl, setRobotoAnchorEl] = React.useState(null);

  const handleNormalMenuOpen = (event) => {
    setNormalAnchorEl(event.currentTarget);
  };

  const handleNormalMenuClose = () => {
    setNormalAnchorEl(null);
  };

  const handleRobotoMenuOpen = (event) => {
    setRobotoAnchorEl(event.currentTarget);
  };

  const handleRobotoMenuClose = () => {
    setRobotoAnchorEl(null);
  };
  // console.log("modalCtx.details", modalCtx.details);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  const handleAddTextField = (e) => {
    e.preventDefault();

    const increaseCount = count + 1;
    setCount(increaseCount);
    const newArray = [];
    const rangeMin = 0;
    const rangeMax = 100 / increaseCount;
    // setTextFields([]);
    for(let i = 0; i < increaseCount; i++) {
      
      const id = uuidv4();
      const value = "NEEDD IMPROVEMENT";

      // if(i == 0) {
      //   // newArray.push([rangeMin, parseInt(rangeMax)]);
      //   newArray.push([rangeMin, parseInt(rangeMax)]);


      // } else if (i == (increaseCount - 1)) {
      //   newArray.push([newArray[i - 1][1], 100]);

      // } else {
      //   console.log("VALS", newArray[i - 1][0]);
      //   const prevFirst = newArray[i - 1][0];
      //   newArray.push([newArray[i - 1][1], parseInt(rangeMax) * 2 + prevFirst  ]);
        
      // }


      if(i == 0) {
        // newArray.push([rangeMin, parseInt(rangeMax)]);
        const data = {id, value, range:[rangeMin, parseInt(rangeMax)]};
        newArray.push(data);
      }else if (i == (increaseCount - 1)) {
        //   newArray.push([newArray[i - 1][1], 100]);
        const data = {id, value, range:[newArray[i - 1].range[1] + 1, 100]};
         newArray.push(data);
        }else {
            console.log("VALS", newArray[i - 1].range[1]);
          const prevFirst = newArray[i - 1].range[0];
        
          const data = {id, value, range:[newArray[i - 1].range[1] + 1, parseInt(rangeMax) * 2 + prevFirst - 1]};
          newArray.push(data);            
          }

      console.log("VAL");
      // // setNewArray(newArray);
    }
    
    console.log("Countingss", newArray);
    setTextFields(newArray);
    
   
    console.log("TEXT FIELDSs", textFields);
  };

  const handleDeleteTextField = (id) => {
    const updatedTextFields = textFields.filter((textField) => textField.id !== id);
    setTextFields(updatedTextFields);
  };

  const handleTextFieldChange = (id, value) => {
    const updatedTextFields = textFields.map((textField) =>
    textField.id === id ? { ...textField, value } : textField
    );
    setTextFields(updatedTextFields);
  };  
  
  const handleRangeChange = (e, newRange, textFieldId) => {
    console.log("Values Range", newRange, "Id", textFieldId);

    
    if(newRange[0] == newRange[1]) {
      newRange = [newRange[0], newRange[0] + 1];
      console.log("NEWSERST", newRange);
    }
    
    for (let i = 0; i < textFields.length; i++) {

      const nextMax = textFields[1].range[1];
      console.log("NEXTMAX", nextMax);
      if(textFields[0].id == textFieldId) {
        textFields[0].range = newRange;
        textFields[1].range = [newRange[1] + 1, textFields[1].range[1]];   
        console.log("RANGE1", textFields[1].range);     
      }

      if(textFields[textFields.length - 1].id == textFieldId) {
        textFields[textFields.length - 1].range = newRange;
        textFields[textFields.length - 2].range = [textFields[textFields.length - 2].range[0], newRange[0] - 1];
        console.log("TRUE-1");
        
      }

      if(textFields[i].id !== textFields[textFields.length - 1].id && textFields[i].id !== textFields[0].id && textFields[i].id == textFieldId)
      // // if(textFields[i].id == textFieldId && textFields[i].id !==  textFields[0].id && textFields[i].id !== textFields[textFields.length - 1].id )
      {
        const max = 100 - textFields.length + 2;
        console.log("MAX IS", max);
        textFields[i].range = newRange;
        const maxRange = newRange[1] < max ? newRange[1] + 1: max;
        textFields[i - 1].range = [textFields[i - 1].range[0], newRange[0] - 1];
        textFields[i + 1].range = [maxRange, textFields[i + 1].range[1]];
        console.log("TRUE-2");
      }
    }

    console.log("CHANGED TEXT FIELDSs", textFields);
    // console.log("NEW RANGE",range);
    const updatedTextFields = textFields.map((textField) =>
      textField.id === textFieldId ? { ...textField, range: newRange } : textField
    );
    setTextFields(updatedTextFields);
  };


  console.log("textFields", textFields);


  const handleUpdateResultsText = (e) => {
    e.preventDefault();
    // modalCtx.setDetails({websiteTitle});
    // setIsLoading(true);
    const jsonBody = { memberText: text, leaderText: text };

    // fetch(process.env.REACT_APP_API_URI + '/v2/', {
    //   method: 'PUT',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify(jsonBody),
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   console.log(data);
    //   if (data.status === "success") {
    //     console.log("Successfully updated results text...");
    //     setIsLoading(false);
    //     handleCloseModal();
    //   }
    // }).catch((err) => console.error("Failed to update results text...", err));

  }

  const handleUpdateTextBlur = () => {
    updateText();
  };

  const updateText = () => {
    if (editorRef.current) {
      let html = editorRef.current.getContent();
      let div = document.createElement("div");
      div.innerHTML = html;
      var text = div.textContent || div.innerText || "";
      let payload = {
        id: 0,
        text: text,
        html: html
      }

      // fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/content/heading', {
      //   method: 'PUT', mode: 'cors',
      //   headers: { 'Content-Type': 'application/json' },
      //   body: JSON.stringify(payload),
      // })
      // .then(response => response.json())
      // .then(data => {
      //   if (data.status === "success") {
      //     // Handle success
      //   }
      // }).catch((err) => {
      //   console.error("Failed to update heading text...", err);
      // })
    }
  };

  return (
    <div className='modal'>
      {
        isLoading ? <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></section> : <>
          <div style={{display: "flex", justifyContent:"space-between"}}>
            <h2 style={{ margin: "0 auto 15px" }}>Update Page Title Based on Score</h2>
            <div style={{display: "flex", justifyContent: "space-between"}}>
              {/* <Button style={{backgroundColor: "#122845", color: "#fff", borderRadius: "13px", width: "234px", height: "48px", marginRight: "10px" }}><AddIcon style={{marginRight: "5px"}}/> ADD NEW RANGE</Button> */}
              <CloseIcon onClick={(e) => handleCloseModal()}/>
            </div>
          </div>
          <form>

            <FormControl fullWidth>
              {textFields.map((textField) => (
                <>
                  <div style={{display: "flex", justifyContent: "center"}} key={textField.id}>
                    <div style={{ width: "100%" }}>
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", position: "relative" }}>
                        <p style={{ padding: "10px 15px", margin: "15px auto", border: "1px solid #BEBEBE", borderRadius: "8px", width: "4ch", textAlign: "center" }}>{textField.range[0]}</p>
                        <Slider getAriaLabel={() => 'Score range'} style={{ margin: "15px", color: "#122845" }} value={textField.range} onChange={(e, newRangeVal) => handleRangeChange(e, newRangeVal, textField.id)} valueLabelDisplay="auto" disableSwap />
                        <p style={{ padding: "10px 15px", margin: "15px auto", border: "1px solid #BEBEBE", borderRadius: "8px", width: "4ch", textAlign: "center" }}>{textField.range[1]}</p>
                      </div>
                      
                      <div style={{border: "1px solid #BEBEBE"}}>
                        <Editor className="tiny-mce-editor"
                          apiKey='hz2zqvulixa2qdtp85x6mth4a6m1zq6e7qmckxi7gof0gds4'
                          onInit={(evt, editor) => editorRef.current = editor}
                          initialValue={value}
                          init={{
                            setup: (editor) => {
                              editor.on('blur', handleUpdateTextBlur);
                            },
                            menubar: false,
                            inline: true,
                            plugins: [
                              'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                              'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                              'insertdatetime'
                            ],
                            toolbar: 'undo redo | blocks | bold italic forecolor | alignleft aligncenter alignright alignjustify',
                            toolbar_mode: "scrolling",
                            content_style: 'body { font-family: Helvetica, Arial, sans-serif; font-size: 14px; }'
                          }}
                        />
                      </div>
                      {/* <TextField fullWidth value={textField.value} onChange={(e) => handleTextFieldChange(textField.id, e.target.value)} ></TextField> */}
                    </div>
                    <div style={{position: "absolute", top: "10px", right: "0" }}><DeleteIcon onClick={() => handleDeleteTextField(textField.id)} /></div>
                  </div>
                  <hr style={{ margin: "30px auto 15px", width: "100%", borderBottom: "1px solid #BEBEBE" }} />
                </>
              ))}

              <Button variant="contained" onClick={handleAddTextField}
                style={{ backgroundColor: "#122845", fontSize: "18px", fontWeight: 600, fontFamily: "Montserrat" }}
              >Add New Range</Button>
            </FormControl>

            <div className="footer" style={{ margin: "15px auto 0" }}>
              <Button className="cancel-btn" onClick={(e) => handleCloseModal()}>CANCEL</Button>
              <Button type="submit" className="confirm-btn" onClick={(e) => { handleUpdateResultsText(e) }}>UPDATE</Button>
            </div>
          </form>
        </>
      }
    </div>
  )






}

export default ResultsHeader;


