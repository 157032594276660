import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
// import TableBody from "@material-ui/core/TableBody";
// import TableCell from "@material-ui/core/TableCell";
// import TableContainer from "@material-ui/core/TableContainer";
// import TableHead from "@material-ui/core/TableHead";
// import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CircularProgress } from "@mui/material";
import { Delete, Edit, PeopleAlt, Photo } from "@mui/icons-material";

import DrawerContext from "../../store/DrawerContext.js";
import ModalContext from "../../store/ModalContext.js";

const useStyles = makeStyles({
  table: {
    minWidth: 650,
    color: "#747d8c",
  },
});


function UsersTable(props) {
  let navigate = useNavigate();
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(true);
  const [loadedAccs, setLoadedAccs] = useState([]);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (modalCtx.open === false)
      setRefresh((prev) => {
        return !prev;
      });
  }, [modalCtx.open]);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + "/v3/accounts", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsLoading(false);
        setLoadedAccs(data.data);
      });
  }, [drawerCtx.open, refresh]);

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  const toggleImageCropper = (modal, id) => {
    let details = {
      id: id,
      imageType: 'client-logo'
    }
    modalCtx.setDetails(modal, details);
    modalCtx.openModal();
  };

  if (isLoading) {
    return (
      <section>
        <CircularProgress />
      </section>
    );
  }

  return (
    <>
      {/* <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Client</TableCell>
              <TableCell>Assessments</TableCell>
              <TableCell align="right">&nbsp;</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loadedAccs.map((data) => (
              <TableRow key={data.id}>
                <TableCell>{data.title}</TableCell>
                <TableCell>{data.assessmentCount}</TableCell>
                <TableCell align="right">
                  <PeopleAlt className="table-tool" onClick={() => navigate("/clients/" + data.id + "/administrators", { replace: true })} />
                  <Edit className="table-tool" onClick={() => { toggleDrawerHandler("client-edit", data); }} />
                  <Delete className="table-tool" onClick={() => { toggleModalHandler("client-delete", data); }} />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer> */}
      <div className="card-container">
        {loadedAccs.map(data => (
          <Table className="card-table" key={data.id} component={Paper}>
            <div className="card">
              <div className="card-title">{data.title}</div>
              {
                data.image ? <div onClick={() => {toggleImageCropper('image-cropper', data.id)}} className="logo-img">
                  <img src={data.image} alt={`${data.title} logo`} />
                </div> : <div onClick={() => {toggleImageCropper('image-cropper', data.id)}} className="logo"><Photo />No logo. Click to add a logo</div>
              }              
              <div className="card-stats">
                <div className="stat">{data.usersCount}</div>
                <div className="stat">{data.leaderCount}</div>
                <div className="stat">{data.memberCount}</div>
              </div>
              <div className="icons">
                <PeopleAlt className="table-tool" onClick={() => navigate("/clients/" + data.id + "/administrators", { replace: true })} />
                <Edit className="table-tool" onClick={() => { toggleDrawerHandler("client-edit", data); }} />
                <Delete className="table-tool" onClick={() => { toggleModalHandler("client-delete", data); }} />
              </div>
            </div>
          </Table>
        ))}
      </div>
    </>
  );
}

export default UsersTable;
