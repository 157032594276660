import React, { useState, useEffect, useContext } from "react";
import { CircularProgress, Pagination } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import {  TextField,  Select,  MenuItem,  FormControl,  InputLabel,  Button,  Checkbox, Radio, RadioGroup} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import AuthContext from '../../store/AuthContext';

function EditAssessmentAutomationDrawerNew({ details }) {

  console.log("Edit Automation 1 Props", details);

  const authCtx   = useContext(AuthContext);
	const drawerCtx = useContext(DrawerContext);

  const [isLoading, setLoading]   = useState(false);
  const [fieldName, setFieldName] = useState(details?.field_name || "");
  const [acId, setAcId]           = useState(0);
  const [fieldType, setFieldType] = useState("");
  const [afterAction, setAfterAction]       = useState(details?.section || "");
  const [campaignAction, setCampaignAction] = useState('');
  const [form, setForm]       = useState("");
  // const [fromAPI, setFromAPI] = useState(["From API", "Test One", "Test Two"]);
  const [fromAPI, setFromAPI] = useState([]);
  const [filter, setFilter]   = useState(fromAPI);
  const [search, setSearch]   = useState("");

  const select = [['Add Custom Field','field'],['Add Tag','tag'],['Add List Type', 'list'],['Add Automation Type', 'automation']];

  const id = details.client_id;
 {/*       Field (field_name on the database)
Active Campaign ID (reference_id on the database)
Type (Either field, assessment URL, Invite URL, tag, buy URL, call URL)
After action (section on the database) */}

  useEffect(() => {

    if(details) {
      // main 3 Active Campaign Fields = "ac_list", "ac_tag", "ac_automation" 
      // any other that start with "ac_" are custom Active Campaign fields 
      const hasAC = details.type?.startsWith("ac_");
      // console.log("hasAC", hasAC);

      if(hasAC) {
        setForm("active-campaign");
        let acAction = "";
        switch (details.type) {
          case "ac_tag": acAction = '2'; break;
          case "ac_list": acAction = '3'; break;
          case "ac_automation": acAction = '4'; break;
          // default: acAction = '1'; setFieldType("ac_field"); break;
          default: acAction = '1'; setFieldType(details.type); break;
        }
        // setCampaignAction(acAction); setSearch(""); setLoading(true);
        setCampaignAction(acAction); setLoading(true);
        // setSearch("");
        // setAcId(`${details.reference_id}`);
      }
      // "active-campaign"
      // "google-sheets"
      // "Calendly"
      
      // value='1' Add Custom Field
      // value='2' Tag
      // value='3' Add to List
      // value='4' Add to Automation
    }




  }, [details]);

  const findAction = fromAPI?.find(option => option.id == details.reference_id);
  useEffect(() => {
    setAcId(`${details.reference_id}`);
    
    console.log("fromAPI", fromAPI);
    
    const findAction = fromAPI?.find(option => option.id == details.reference_id);
    console.log("findAction", findAction);
    console.log("filter", filter);
    
    if(fromAPI?.length) {
      if (!findAction?.label) {
        setFilter(fromAPI);
        setSearch("");
      } else {
        setFilter([findAction?.label]);
        setSearch(findAction?.label);
      }
    }
    }, [details, campaignAction, fromAPI, findAction]);
  
  console.log("filter", filter); console.log("search", search);
  console.log("fieldName", fieldName); console.log("fieldType", fieldType);
  console.log("campaignAction", campaignAction);
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");
    fetch(process.env.REACT_APP_API_URI + "/v1/admin/assessment/" + id + "/automation", {
      method: "PUT",
      body: JSON.stringify({client_id: id, id: details.id, field_name: fieldName, reference_id: acId, type: fieldType, section: afterAction}),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
    drawerCtx.closeDrawer();
  };

  const getFromAPI = () => {
    if(campaignAction - 1 >= 0){
    fetch(process.env.REACT_APP_API_URI + '/v2/integrations/assessments/' + id + '/activeCampaignSearch?' + new URLSearchParams({type: select[campaignAction - 1][1], search: search==null? "":search}), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        console.log("apiData\n", data);
        let arr = [];
        data.data?.map((data) => {
          if(select[campaignAction - 1][1] == 'field'){
            arr.push({label: data.title, id: data.id})
          } else if (select[campaignAction - 1][1] == 'tag'){
            arr.push({label: data.tag, id: data.id})
          } else if (select[campaignAction - 1][1] == 'list'){
            arr.push({label: data.name, id: data.id});
          }
        });
        console.log(arr);
        setFromAPI(arr);
        setLoading(false);
      } else {
        console.log("failed to fetch");
      }
  })}

}

  const chooseForm = () => {
    if(form === "active-campaign")
      return getForm();
    return null;
  }

  const renderAddCustomField = () => {
    let toRender = false;
    if(search && campaignAction == '1'){
      if(fieldType == 'ac_field'){
        toRender = true;
      }
    }
    if(toRender)
    return <>
      <TextField fullWidth className="form-control-add-question margin-top"
        value={fieldName}
        onChange={(e) => {
          console.log("evt", e.target.value);
          setFieldName(e.target.value)}}
        placeholder={select[campaignAction - 1][0]}
        label={select[campaignAction - 1][0]}
        select
        variant="outlined"
        required
      >
        <MenuItem className="section-name" value='churchName'>Church Name</MenuItem>
        <MenuItem className="section-name" value='churchWebsite'>Church Website</MenuItem>
        <MenuItem className="section-name" value='orgName'>Organization</MenuItem>
        <MenuItem className="section-name" value='jobTitle'>Job Title</MenuItem>
        <MenuItem className="section-name" value='zipCode'>Zip Code</MenuItem>
        <MenuItem className="section-name" value='avgWeeklyAttendance'>Average Weekly Attendance</MenuItem>
        <MenuItem className="section-name" value='denomination'>Church Denomination</MenuItem>
        <MenuItem className="section-name" value='chms'>Current ChMS</MenuItem>
      </TextField>
    </>
  }

  const renderType = () => {
    let toRender = false;
    if(search && campaignAction == "1")
      toRender = true;
    if(toRender)
    return <>
      <TextField fullWidth className="form-control-add-question margin-top"
        value={fieldType}
        onChange={(e) => setFieldType(e.target.value)}
        placeholder="Type"
        label="Type" 
        select
        variant="outlined"
        required
      >
        {/* <MenuItem className="section-name" value='field'>Field</MenuItem>
        <MenuItem className="section-name" value='assessment_url'>Assessment URL</MenuItem>
        <MenuItem className="section-name" value='invite_url'>Invite URL</MenuItem>
        <MenuItem className="section-name" value='tag'>Tag</MenuItem>
        <MenuItem className="section-name" value='buy_url'>Buy URL</MenuItem>
        <MenuItem className="section-name" value='call_url'>Call URL</MenuItem> */}
        <MenuItem className="section-name" value='ac_field'>Connect form field</MenuItem>
        <MenuItem className="section-name" value='ac_field_assessment_url'>Report URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_invite_url'>Invite URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_referrer_name'>Referrer Name</MenuItem>
        <MenuItem className="section-name" value='ac_field_referrer_url'>Referral URL for member/referral</MenuItem>
        <MenuItem className="section-name" value='ac_field_buy_url'>Buy URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_call_url'>Call Request URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_dashboard_url'>Dashboard URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_debrief_url'>Debrief URL</MenuItem>
      </TextField>
    </>
  }

  const renderAfterAction = () => {
    let toRender = false;
    if(search){
      if(campaignAction != 1)
       toRender = true;
      else {
        if(fieldType == 'field'){
          if(acId)
            toRender = true;
        } else if(fieldType != '')
            toRender = true;
      }
    }
    if(toRender)
    return <>
      <TextField
        fullWidth
        className="form-control-add-question margin-top"
        label="After action"
        placeholder="After action"
        value={afterAction}
        onChange={(e) => setAfterAction(e.target.value)}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        select
        required
        >
        <MenuItem className="section-name" value='leader'>Leader completes the assessment</MenuItem>
        <MenuItem className="section-name" value='referral_leader'>Leader refers a member/referral</MenuItem>
        <MenuItem className="section-name" value='referral'>Member/Referral is added</MenuItem>
        <MenuItem className="section-name" value='member'>Member/Referral completes the assessment</MenuItem>
      </TextField>
    </>
  }

  const getForm = () => {
    return <>
        <TextField fullWidth className="form-control-add-question margin-top"
          value={campaignAction}
          onChange={(e) => {setCampaignAction(e.target.value); setSearch(""); setLoading(true);}}
          placeholder="Active Campaign Action"
          label="Active Campaign Action" 
          select
          variant="outlined"
          required
        >
          <MenuItem className="section-name" value='1'>Add Custom Field</MenuItem>
          <MenuItem className="section-name" value='2'>Tag</MenuItem>
          <MenuItem className="section-name" value='3'>Add to List</MenuItem>
          <MenuItem className="section-name" value='4'>Add to Automation</MenuItem>
        </TextField>
        {campaignAction == "" ? null: type_menu()}
        </>
  }

  useEffect(() => {
    getFromAPI();
  // }, [campaignAction, search]);
  }, [campaignAction]);


  const type_menu = () => {
    return <>
    {isLoading? <CircularProgress style={{'color': '#00A04F'}} />:
      <Autocomplete
        key={campaignAction}
        inputValue={search}
        // inputValue={"nnn"}
        onChange={(event, value) => {
          console.log("value", value);
          value?.id && setAcId(value?.id);
          value?.label && setSearch(value.label);
        }}
        onInputChange={(e = undefined, newValue = search) => {
          console.log("newValue", newValue, "event", e);
          if (e?.target?.value != null) { setSearch(e.target.value); }
          else if(newValue == "" && !e && search.length) setSearch(search);
          else if (newValue == "" && !e && !search.length) setSearch(newValue);
          console.log(newValue)
          if(!newValue) {
            setFilter(fromAPI);
          } else {
            newValue && setFilter([newValue]);
          }
        }}
        options={fromAPI}
        renderInput={(params) => <TextField {...params} className="form-control-add-question margin-top" variant="outlined" label="Search" />}
      />}
      {renderType()}
      {renderAddCustomField()}
      {renderAfterAction()}
    </>
  }

  const check_btn = () => {
    if(form == "" || campaignAction == "" || search == "" || afterAction == "")
      return true;
    else if(campaignAction == "1" && fieldType == "")
      return true;
    else if(campaignAction == "1" && (fieldType == "field" && !acId))
      return true;
    return false;
  }

  return (
    <div className="drawer">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
          Edit Automation New
        </h1>
      </header>

      <section>
        <RadioGroup
          row
          aria-labelledby="integration"
          name="integration"
          defaultValue="top"
        >
          <FormControlLabel value="active-campaign" control={<Radio />} checked={form === "active-campaign"} label="Active Campaign" 
            onClick= {(e) => {setForm(e.target.value)}} />
          <FormControlLabel value="google-sheets" control={<Radio />} checked={form === "google-sheets"} label="Google Sheets" 
            onClick= {(e) => {setForm(e.target.value)}} />
          <FormControlLabel value="Calendly" control={<Radio />} checked={form === "Calendly"} label="Calendly" 
            onClick= {(e) => {setForm(e.target.value)}} />
        </RadioGroup>
        {chooseForm()}

        <div className="double-column-form aligned-right margin-top">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            component="label"
            disabled={check_btn()}
          >
            Add Automation
          </Button>
        </div>
      </section>
    </div>
  );
}


export default EditAssessmentAutomationDrawerNew;

