import React, { useContext } from "react";
import AOS from "aos";

import "aos/dist/aos.css";

import DrawerContext from "../store/DrawerContext.js";

import AssessmentsTable from "../Components/Tables/AssessmentsTable";
import "../App.scss";

// ..
AOS.init();

// import '../App.scss';

const Assessments = () => {
  const drawerCtx = useContext(DrawerContext);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  return (
    <div className="container">
      <header>
        <div data-aos="fade-right">
          <h1>Assessments</h1>
        </div>
        <div data-aos="fade-down" data-aos-delay="400">
          <a className="header-cta" onClick={() => { toggleDrawerHandler("create-assessment", null); }}> Create</a>
        </div>
      </header>
      <AssessmentsTable />
    </div>
  );
};
export default Assessments;
