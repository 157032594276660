import { useState, useEffect, useContext } from 'react';
import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Box, Tabs, Tab, Chip, CircularProgress, Link, Button } from '@mui/material';
import AuthContext from '../store/AuthContext';
import DrawerContext from '../store/DrawerContext';
import { ExitToApp } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import { getCentralDate } from '../globalFunctions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Engagement = () => {

  const [isLoading, setIsLoading] = useState(false);
  const authCtx                   = useContext(AuthContext);
  const drawerCtx                 = useContext(DrawerContext);

  const [subscribers, setSubscribers]       = useState(null);
  const [selectionModel, setSelectionModel] = useState([]);
  const [tabValue, setTabValue]             = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const getDateISO = (createdAt) => new Date(createdAt.replace(/ /g,"T") + "Z").toISOString();

  useEffect(() => {
    fetch(process.env.REACT_APP_API_URI + "/v2/engagement/subscribers", {
      method: "GET",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        let finalSubscribers = data?.subscribers?.map(sub => {
          sub.date = getDateISO(sub.created_at);
          return sub;
        })
        setSubscribers(finalSubscribers);
        console.log(data);
        setIsLoading(false);
      })
      .catch(err => {
        console.error(err)
        // setIsLoading(false);
      });

  }, [authCtx.token]);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  const centerHeader = { headerClassName: "grid-header", headerAlign: "center", align: "center" };
  const centerAlign = { headerAlign: "center", align: "center" };

  const getUserCell = (params) => {
    // console.log("params.row", params.row);
    let userDetails = {
      userInfo: params.row,
      // assessmentTitle: assessDetails.title,
      // hasAdditional: assessDetails.hasAdditional,
      // url: assessDetails?.url || "",
      // assessmentType: assessDetails?.assessmentType || "",
    }
    // return <><Link className="user-info-link" onClick={() => { toggleDrawerHandler('user-details', userDetails); }}>{params.row.name}</Link></>
    return params.row.name;
  }

  const SeeMoreBtn = (props) => <Button className="see-more-btn" onClick={() => {
    // console.log("seemorebtn props", props);
    if(props?.row?.id) toggleDrawerHandler('user-details', props?.row?.id);
  }}>SEE MORE&nbsp;<ExitToApp /></Button>


  const columnsAllUsers = [

    { field: 'date', headerName: 'Date', type: "date", ...centerHeader, width: 120,
      renderCell: (params) => getCentralDate(params.row.created_at) },
    { field: 'name', headerName: 'Name', ...centerHeader, width: 220, 
      renderCell: (params) => getUserCell(params) },
    { field: 'email', headerName: 'Email', ...centerHeader, width: 280 },
    { field: 'amount', headerName: 'Payment Amount', ...centerHeader, width: 180,
      renderCell: (params) => `$${(params.row?.amount / 100)?.toFixed(2)}` },
    { field: 'status', headerName: 'Payment Status', ...centerHeader, width: 200, 
      renderCell: (params) => { // console.log(params.row);
        let paymentStatus = params.row?.status === "paid" ? "Paid" : "Pending";
        let paymentClasses = params.row?.status === "paid" ? "green chip" : "red chip";
        return <Chip label={paymentStatus} className={paymentClasses} />
      }},
    // { field: "actions", headerName: 'Actions', ...centerHeader, width: 180, sortable: false, 
    //   renderCell: (params) => <SeeMoreBtn row={params.row} /> },
  ];

  console.log("columnsAllUsers", columnsAllUsers);
  console.log("subscribers", subscribers);

  const calendlyColumns = [
    { field: 'date', headerName: 'Date', type: "date", ...centerHeader, width: 120, 
      // renderCell: (params) => getCentralDate(params.row.created_at) },
      renderCell: (params) => { 
        // console.log(params.row);
        return params.row.date} },
    { field: 'name', headerName: 'Name', ...centerHeader, width: 220, 
      // renderCell: (params) => getUserCell(params) },
      renderCell: (params) => params.row.name },
    { field: 'email', headerName: 'Email', ...centerHeader, width: 280 },
    { field: 'location', headerName: 'Location', ...centerHeader, width: 180,
      // renderCell: (params) => `$${(params.row?.amount / 100)?.toFixed(2)}` },
      renderCell: (params) => params.row.location },
    { field: 'appointment', headerName: 'Appointment Date', ...centerHeader, width: 200, 
      renderCell: (params) => { // console.log(params.row);
        return params.row.appointment;
    }},
    { field: "actions", headerName: 'Actions', ...centerHeader, width: 180, sortable: false, 
      renderCell: (params) => <SeeMoreBtn row={params.row} /> },
  ]

  const calendlyRows = [
    { id: 1, date: "Oct 12, 22", name: "Firstname Lastname", email: "firstname@email.com", location: "Mizu", appointment: "Oct 18, 22" },
    { id: 2, date: "Oct 10, 22", name: "Firstname Lastname", email: "firstname@email.com", location: "Narnia", appointment: "Oct 21, 22" },
    { id: 3, date: "Oct 05, 22", name: "Firstname Lastname", email: "firstname@email.com", location: "Konoha", appointment: "Oct 27, 22" },
  ]
  // console.log("call this page");
    console.log("subscribers",subscribers);
  return (
    <div className="engagement">
      <h1>Engagement Platform Signups</h1>
      <a className="engagement-link" href="https://40parables.com/bleat/" target="_blank" rel="noreferrer">https://40parables.com/bleat/</a>

      <div className="tab-container">

        <Box sx={{ width: '100%' }}>
          <Box className="box" sx={{ borderBottom: 1, borderColor: '#EDEDED' }}>
            <Tabs TabIndicatorProps={{ style: { background: '#F2D466', height: '4px' } }} value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab className="tab-title" label="Engagements" {...a11yProps(0)} />
              <Tab className="tab-title" label="Calendly CTA" {...a11yProps(1)} />s
              {/* <Tab className="tab-title" label="Engagements" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0} className="engagements-table grid">

            {(subscribers?.length && !isLoading) ? <DataGrid
              className="subscription-grid-container"
              rows={subscribers}
              columns={columnsAllUsers}
              // pageSize={5}
              // checkboxSelection
              // disables all advanced column sorting options available through menu 
              // disableColumnMenu
              disableSelectionOnClick
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              // rowsPerPageOptions={[5]}
              rowsPerPageOptions={[100]}
            /> : <section className="loader-section"><CircularProgress /></section>}

          </TabPanel>

          <TabPanel value={tabValue} index={1} className="all-engagements-table grid">
            
          {(subscribers?.length && !isLoading) ? <DataGrid
              className="subscription-grid-container"
              rows={calendlyRows}
              columns={calendlyColumns}
              // pageSize={5}
              // checkboxSelection
              // disables all advanced column sorting options available through menu 
              // disableColumnMenu
              disableSelectionOnClick
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              // rowsPerPageOptions={[5]}
              rowsPerPageOptions={[100]}
            /> : <section className="loader-section"><CircularProgress /></section>}

          </TabPanel>
          <TabPanel value={tabValue} index={2} className="referrals-table grid">
            
          {(subscribers?.length && !isLoading) ? <DataGrid
              className="subscription-grid-container"
              rows={subscribers}
              columns={columnsAllUsers}
              // pageSize={5}
              // checkboxSelection
              // disables all advanced column sorting options available through menu 
              disableColumnMenu
              disableSelectionOnClick
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              // rowsPerPageOptions={[5]}
              rowsPerPageOptions={[100]}
            /> : <section className="loader-section"><CircularProgress /></section>}

          </TabPanel>
        </Box>
      </div>

      {/* {isLoading ? <CircularProgress /> :
        <TableContainer className="subscribers-table-container">
          <Table sx={{ minWidth: 350 }} className="subscribers-table" aria-label="subscribers table">
            <TableHead>
              <TableRow>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Email</TableCell>
                <TableCell align="center">Date</TableCell>
                <TableCell align="center">Payment Amount</TableCell>
                <TableCell align="center">Payment Status</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {subscribers?.map(sub => {
                let paymentStatus = sub.status === "paid" ? "Paid" : "Pending";
                let paymentClasses = sub.status === "paid" ? "green chip" : "red chip";
                return (<TableRow
                  key={sub.id}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align="center">{sub.name}</TableCell>
                  <TableCell align="center">{sub.email}</TableCell>
                  <TableCell align="center">{getCentralDate(sub.created_at)}</TableCell>
                  <TableCell align="center">${(sub.amount / 100).toFixed(2)}</TableCell>
                  <TableCell align="center">
                    <Chip label={paymentStatus} className={paymentClasses} />
                  </TableCell>
                </TableRow>)
              })}
            </TableBody>
          </Table>
        </TableContainer>} */}

    </div>

  )
}


export default Engagement;
