import { useContext, useState, useEffect } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Chip, Tooltip, Button } from '@material-ui/core';
import DrawerContext from '../store/DrawerContext';
import EditIcon from "@material-ui/icons/Edit";

const AssessmentsSections = () => {

  const useStyles = makeStyles({
    table: {
      minWidth: 650,
      color: "#747d8c"
    },
  });

  const drawerCtx                         = useContext(DrawerContext);
  const { id }                            = useParams();
	let navigate      											= useNavigate();
	const [isLoading, setIsLoading]         = useState(true);
  const [apiData, setApiData]             = useState([]);
  const [sections, setSections]           = useState([]);
  const [assessments, setAssessments]     = useState([]);
  const [refresh, setRefresh]             = useState(false);

  useEffect(() => {
    if (drawerCtx.open == false) setRefresh(prev => !prev);
  }, [drawerCtx.open])

  const classes = useStyles();
  // console.log(id);

  useEffect(() => {

		setIsLoading(true);
		fetch(process.env.REACT_APP_API_URI + '/v1/admin/sections/', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
    .then(response => {
      // console.log(response);
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        setApiData(data);
        setSections(data.data);
        setIsLoading(false);
      }
    }).catch(err => console.error(err));

    setIsLoading(true);
		fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessments', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			if (data.status === "success") {
				setIsLoading(false);
				setAssessments(data.data);
			}
		}).catch(err => console.error(err));
  }, [refresh]);

  // console.log(sections);

  const currentAssessmentInfo = assessments.filter(assessment => assessment.id.toString() === id.toString());
  // console.log(currentAssessmentInfo);
  
	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};
  return (
    <>
      <div className="container questions">

        <header>
          <div data-aos="fade-up">
            <h1><span className="breadcrumb-navlink" onClick={() => { navigate("/assessments/", { replace: true }); }}>Assessments</span> &gt; {currentAssessmentInfo[0]?.title} &gt; Sections</h1>
            {/* <h1>Assessments &gt; Sections</h1> */}
          </div>
          <div data-aos="fade-down" data-aos-delay="400">
            <a className="header-cta" onClick={() => {toggleDrawerHandler('create-assessment-section', {client_id: id})}}>Create</a>
          </div>
        </header>

        <TableContainer component={Paper}>
          
          <Table className="table" aria-label="simple table" data-aos="fade-up">
            <TableHead>
              <TableRow>
                <TableCell>Title</TableCell>
                <TableCell>Order</TableCell>
                <TableCell>No. of Questions</TableCell>
                <TableCell>Multiplier</TableCell>
                <TableCell align="right">&nbsp;</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sections.map((data) => 
                data.client_id == id &&
                <TableRow key={data?.section}>
                  <TableCell width="58%">{data?.title}</TableCell>
                  <TableCell><Chip label={data?.order} /></TableCell>
                  <TableCell align="center"><Chip label={data?.order} /></TableCell>
                  <TableCell align="center"><Chip label={data?.multiplier} /></TableCell>
                  <TableCell align='right'><Tooltip title="Details"><EditIcon className="table-tool" onClick={() => {toggleDrawerHandler('edit-assessment-section', data)}} /></Tooltip></TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </>
  )
}

export default AssessmentsSections;
