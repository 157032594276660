import React, { useContext, useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';


import cross from '../../../src/assets/img/cross.png';
import AuthContext from '../../store/AuthContext.js';



const DeleteEmailNotification = (props) => {
    const modalCtx = useContext(ModalContext);
    const authCtx = useContext(AuthContext);
    const id = props?.details?.row?.assessmentId
    const emailId= props?.details?.id

    const [isCreating, setIsCreating] = useState(false);


    
    const handleDeleteEmail = () => {
        setIsCreating(true)
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/'+id+ '/email/'+emailId, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    setIsCreating(false)
                   
                    fncGetEmailData()
                    modalCtx.setDetails(null);
                    modalCtx.closeModal();

                } else {
                    console.log("failed to delete");
                }
            })
            .catch(err => {
                if (err) {
                    console.log(err);
                }
            });

    }



    const fncGetEmailData = () => {
        fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/emails', {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${authCtx.token}`,
            }
        }).then(response => {
            return response.json();
        }).then(data => {
            if (data.status == "success") {
            props?.details?.api?.setRows(data?.data);
               
            }

        });

    }

      const handleCloseModal = () => {
        modalCtx.setDetails(null);
        modalCtx.closeModal();
        }

       return (
             <div className='modal'>
                <div className="delete-popup-content">
                    <div className="main-content">
                        <div className="heading-container">
                            <div className="heading">
                                Are you sure you want to delete this email notification ?
                            </div>
                           <img src={cross} onClick={() => { handleCloseModal() }} alt="cross" />
                       </div>

                       {isCreating ?
                           <button className="delete-btn" disabled> <CircularProgress /></button> :
                           <button className="delete-btn" onClick={()=>{handleDeleteEmail()}} >Delete</button>
                       }
                       
                        <button className="cancel-btn" onClick={() => { handleCloseModal() }}>Cancel</button>

                    </div>
                </div>

            
             </div>
        )
 }

export default DeleteEmailNotification;
