import React, { useContext, useState, useEffect } from 'react'
import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { CircularProgress} from "@mui/material";

const BulkDeleteURLs = (props) => {

  console.log("BulkDeleteURLs props", props);

  const details = props?.details;

  console.log("details", details);
  console.log("details?.firstWebsiteID", details?.firstWebsiteID);

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const [isLoading, setIsLoading]= useState("");
  const toggleRefresh = props?.details.toggleRefresh;


  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const handleBulkDeleteURLs = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const jsonBody = { ids: details?.urlsToKeep };

    fetch(process.env.REACT_APP_API_URI + '/v2/datamining/website/batch/delete', {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(jsonBody),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          console.log("Successfully bulk deleted URLs...");
          setIsLoading(false);
          props?.details.setToggleRefresh(!toggleRefresh);
          handleCloseModal();
          modalCtx.setPageUpdate({ page: "datamining" });
        } else {
          console.log("Failed to bulk delete URLs...");
        }
      })
      .catch(err => console.error("Failed to bulk delete URLs...", err));

  }

  return (
    <div className='modal'>
      {isLoading ? 
      <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></section> 
      :
      <>
      <p style={{ margin: "5px auto 15px" }}>This will delete all the URLS except for the ones you selected. Are you sure you want to delete all the other (non-selected) URLs?</p>
      <div className="footer" style={{ margin: "15px auto 0" }}>
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
        <Button type="submit" className="confirm-btn" onClick={(e) => handleBulkDeleteURLs(e)}>Delete</Button>
      </div>
      </>
      }
    </div>
    
  )
}

export default BulkDeleteURLs;
