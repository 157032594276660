import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import CircularProgress from "@material-ui/core/CircularProgress";

import AuthContext from '../store/AuthContext.js';

const Overview = () => {

	const authCtx = useContext(AuthContext);
	const navigate = useNavigate();

	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
    setIsLoading(true);
    
		fetch(process.env.REACT_APP_API_URI + "/v1/admin/assessmentsByClient/" + authCtx.accountId, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => {
      return response.json();
    })
    .then((data) => {
			console.log(data);
			if(data.status == "success") {
				navigate("/assessments/" + data.data[0].id, { replace: true });
			}
      
      // setIsLoading(false);
      // setAssessments(data.data);
    });

  }, []);
	
	return (
		<div className="container overview">
			<div data-aos="fade-up">
				{/* <h1>Overview</h1> */}
			</div>
			<section>
        <CircularProgress />
      </section>
		</div>        
	);

}
export default Overview;