import { useState, useEffect, useContext } from "react";
import ContentEditable from "react-contenteditable";
import { useParams } from "react-router-dom";

import { Button, CircularProgress } from '@mui/material';
import { Add, ChevronLeft, ChevronRight, AddCircleOutline, SignalWifi4Bar, SignalCellular4Bar, BatteryFull, NearMeOutlined } from '@material-ui/icons';

import AuthContext from '../../store/AuthContext';
import BuilderContext from '../../store/BuilderContext';
import ModalContext from '../../store/ModalContext';

import EditableText from './EditableText';

import tempLogo from "../../assets/img/temp-logo.png";
import bleatLogo from "../../assets/img/logo-bleat.svg";
import barchart from "../../assets/img/barchart.png";
import spiderweb from "../../assets/img/spiderweb.png";
import temperature from "../../assets/img/temperature.png";
import playBtn from "../../assets/img/play-button.png";
import Navbar from "./Navbar";
import Start from "./Start";
import Assessment from "./Assessment";
import Details from "./Details";
import Results from "./Results";
import Invite from "./Invite";
import InviteSuccess from "./InviteSuccess";
import Dashboard from "./Dashboard";
import DetailsNew from "./DetailsNew";
import HeadingWidget from "./Tools/HeadingWidget";
import AssessmentAdditional from "./AssessmentAdditional";

import './Styles/Builder.scss';

let contentBlocksStart = [
  {
    id: 1,
    contentType: "headerContainer",
    pageArea: "header",
    parentContainer: 0,
    properties: {
      text: "header",
      tagName: "header",
    },
    styling: { backgroundColor: '#ffffff', color: '#000000', width: '100%', height: '120px', justifyContent: 'space-between', alignItems: 'center' }
  },
  {
    id: 2,
    contentType: "Image",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: "logoImg",
      alt: "logo",
      img: tempLogo,
    },
    styling: { width: 'auto', height: '50px', paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: '10px', marginTop: 0, marginRight: 0, marginBottom: 0, marginLeft: 0, }
  },
  {
    id: 3,
    contentType: "LogoText",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: "Assessment Title",
      img: 'logoText',
    },
    styling: { width: '100%', height: '100px', textAlign: 'center', fontSize: "24px", fontWeight: 700, marginTop: 0, marginRight: 0, marginBottom: 0, marginLeft: 0, paddingTop: 0, paddingRight: 0, paddingBottom: 0, paddingLeft: 0, }
  },
  {
    id: 5,
    contentType: "BleatLogo",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: 'logoImg',
      alt: 'bleat logo',
      img: bleatLogo,
    },
    styling: { width: '50px', height: '58px', paddingTop: 0, paddingRight: '20px', paddingBottom: 0, paddingLeft: '10px', marginTop: 0, marginRight: 0, marginBottom: 0, marginLeft: 0, }
  },
  {
    id: 6,
    contentType: "Background",
    pageArea: "background",
    parentContainer: 0,
    properties: {},
    styling: { backgroundColor: '#efefef' }
  },
  {
    id: 7,
    contentType: "ContentContainer",
    pageArea: "content",
    parentContainer: 0,
    properties: {
      text: 'Bleat Logo'
    },
    styling: { height: "670px", width: "auto", maxWidth: "1100px", marginTop: 0, marginRight: "auto", marginBottom: 0, marginLeft: "auto", backgroundPosition: "center", backgroundRepeat: "no-repeat", backgroundSize: "cover", }
  },
  {
    id: 8,
    contentType: "heading",
    pageArea: "content",
    parentContainer: 6,
    properties: {
      text: 'Lorem ipsum quis dolore elit?',
      html: '<h1>Lorem ipsum quis dolore elit?<h1>',
      tagName: 'h1',
    },
    styling: { fontSize: "42px", fontWeight: "700", lineHeight: "58px", marginTop: 0, marginRight: 0, marginBottom: "38px", marginLeft: 0, paddingTop: "10px", paddingRight: "5px", paddingBottom: "10px", paddingLeft: "5px", width: "auto", textAlign: "left", }
  },
  {
    id: 9,
    contentType: "paragraph",
    pageArea: "content",
    parentContainer: 6,
    properties: {
      text: 'Mollit elit est sunt nisi ipsum qui quis dolore elit laborum dolore. Est tempor dolor ad mollit dolore aliquip fugiat et in. Minim reprehenderit ut dolore sunt exercitation culpa exercitation veniam minim laboris ipsum.',
      html: 'Mollit elit est sunt nisi ipsum qui quis dolore elit laborum dolore. Est tempor dolor ad mollit dolore aliquip fugiat et in. Minim reprehenderit ut dolore sunt exercitation culpa exercitation veniam minim laboris ipsum.',
      tagName: 'h2',
    },
    styling: { fontSize: "24px", fontWeight: "300", lineHeight: "27px", marginTop: 0, marginRight: 0, marginBottom: "38px", marginLeft: 0, paddingTop: "10px", paddingRight: "5px", paddingBottom: "10px", paddingLeft: "5px", width: "auto", textAlign: "left", }
  },
  {
    id: 10,
    contentType: "button",
    pageArea: "content",
    parentContainer: 6,
    properties: {
      text: 'Button',
      tagName: 'button',
     },
    styling: { backgroundColor: '#212121', color: '#DEDEDE' }
  },
  {
    id: 11,
    contentType: "AssessmentContainer",
    pageArea: "assessment",
    parentContainer: 0,
    properties: {
      text: "Questions",
      backgroundColor: '#ffffff',
      color: '#000000',
      width: '100%',
      minHeight: '550px',
      tagName: "div",
    },
    styling: { maxWidth: "1100px", minHeight: "550px", margin: "0 auto", borderRadius: "15px" }
  },
  {
    id: 12,
    contentType: "AssessmentText",
    pageArea: "assessment",
    parentContainer: 11,
    properties: {
      text: "Type your category title",
      tagName: "p",
    },
    styling: { width: '100%', minHeight: '550px' }
  },
  {
    id: 13,
    contentType: "AssessmentText",
    pageArea: "assessment",
    parentContainer: 11,
    properties: {
      text: "Type your question here...",
      tagName: "p",
    },
    styling: { width: '100%', minHeight: '550px' }
  },
];

let contentBlocksAssessment = [
  {
    id: 1,
    contentType: "headerContainer",
    pageArea: "header",
    parentContainer: 0,
    properties: {
      text: "header",
      tagName: "header",
    },
    styling: { backgroundColor: '#ffffff', color: '#000000', width: '100%', height: '120px', justifyContent: 'space-between', alignItems: 'center' }
  },
  {
    id: 2,
    contentType: "Image",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: "logoImg",
      alt: "logo",
      img: tempLogo,
    },
    styling: { width: 'auto', height: '65px', paddingLeft: '30px' }
  },
  {
    id: 3,
    contentType: "LogoText",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: "Assessment Title",
      img: 'logoText',
    },
    styling: { width: '100%', height: '100px', textAlign: 'center', fontSize: "18px", }
  },
  {
    id: 5,
    contentType: "BleatLogo",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: 'logoImg',
      alt: 'bleat logo',
      img: bleatLogo,      
    },
    styling: { width: '50px', height: '58px', paddingRight: '20px' }
  },
  {
    id: 6,
    contentType: "Background",
    pageArea: "background",
    parentContainer: 0,
    properties: {},
    styling: { backgroundColor: '#efefef' }
  },
  {
    id: 7,
    contentType: "ContentContainer",
    pageArea: "content",
    parentContainer: 0,
    properties: {
      text: 'Bleat Logo',
    },
    styling: { display: "flex" }
  },
  {
    id: 11,
    contentType: "AssessmentContainer",
    pageArea: "assessment",
    parentContainer: 0,
    properties: {
      text: "Questions"
    },
    styling: { display: "flex", flexDirection: "column", minHeight: "550px", padding: "30px", width: "100%" }
  },
  {
    id: 12,
    contentType: "SectionsBar",
    pageArea: "assessment",
    parentContainer: 11,
    properties: {
      text: "Type your category title",
      activeSection: 0,
      sectionsArray: ["Category Title"],
      // sectionsArray: ["cat 1", "cat 2", "cat 3", "cat 4"],
      tagName: "div",
    },
    styling: { maxWidth: '100%', padding: "30px", boxSizing: "border-box", display: 'flex', justifyContent: 'space-between', flexDirection: 'row', backgroundColor: '#ffffff', color: '#000000' }
  },
  {
    id: 13,
    contentType: "ProgressIndicator",
    pageArea: "assessment",
    parentContainer: 11,
    properties: {
      showMultiProgressBars: true,
      text: "",
      tagName: "p",
    },
    styling: { width: '100%', textAlign: 'center', fontWeight: 700, fontSize: "18px", color: "#212121" }
  },
  {
    id: 14,
    contentType: "Question",
    pageArea: "assessment",
    parentContainer: 11,
    properties: {
      text: "Type your question here...",
      currentQuestionIndex: 0,
      questionsArray: [
        { question: "What is your question?", section: "Section Name", type: "", mandatory: "yes", active: true },
        // { question: "Question 1?", section: "Section Name", type: "", mandatory: "yes", active: true },
        // { question: "Question 2?", section: "Section Name", type: "", mandatory: "yes", active: false },
        // { question: "Question 3?", section: "Section Name", type: "", mandatory: "yes", active: false },
        // { question: "Question 4?", section: "Section Name", type: "", mandatory: "yes", active: false },
        // { question: "Question 5?", section: "Section Name", type: "", mandatory: "yes", active: false },
      ],
    },
    styling: { width: '100%', textAlign: 'center', fontSize: "24px", fontWeight: 700, color: "#212121", display: "flex" }
  }, 
  
  
  {
    id: 15,
    contentType: "Buttons",
    pageArea: "assessment",
    parentContainer: 11,
    properties: {},
    styling: { border: "2px dashed #77777777", width: "18ch", display: "inline-block", padding: "15px", margin: "15px", textAlign: "center", borderRadius: "15px", cursor: "pointer" }
  },

];

let contentBlocksResults = [
  {
    id: 1,
    contentType: "headerContainer",
    pageArea: "header",
    parentContainer: 0,
    properties: {
      text: "header",
      tagName: "header",
    },
    styling: { backgroundColor: '#ffffff', color: '#000000', width: '100%', height: '120px', justifyContent: 'space-between', alignItems: 'center' }
  },
  {
    id: 2,
    contentType: "Image",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: "logoImg",
      alt: "logo",
      img: tempLogo,
    },
    styling: { width: 'auto', height: '65px', paddingLeft: '30px' }
  },
  {
    id: 3,
    contentType: "LogoText",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: "Assessment Title",
      img: 'logoText',
    },
    styling: { width: '100%', height: '100px', textAlign: 'center', fontSize: "18px", }
  },
  {
    id: 5,
    contentType: "BleatLogo",
    pageArea: "header",
    parentContainer: 1,
    properties: {
      text: 'logoImg',
      alt: 'bleat logo',
      img: bleatLogo,      
    },
    styling: { width: '50px', height: '58px', paddingRight: '20px' }
  },
  {
    id: 6,
    contentType: "Background",
    pageArea: "background",
    parentContainer: 0,
    properties: {},
    styling: { backgroundColor: '#efefef' }
  },
  {
    id: 7,
    contentType: "ContentContainer",
    pageArea: "content",
    parentContainer: 0,
    properties: {
      text: '',
    },
    styling: { display: "flex" }
  },
  {
    id: 11,
    contentType: "ResultsContainer",
    pageArea: "results",
    parentContainer: 0,
    properties: {
      text: "Results",
      resultsType: "",
    },
    styling: { boxSizing: "border-box", display: "flex", flexDirection: "column", minHeight: "550px", padding: "30px", width: "100%" }
  },
  {
    id: 12,
    contentType: "ResultsHeading",
    pageArea: "results",
    parentContainer: 11,
    properties: {
      text: "It looks like your...",
      html: "<h2>It looks like your...</h2>",
      tagName: "h2",
    },
    styling: { maxWidth: '100%', boxSizing: "border-box", padding: "0px", textAlign: 'center', fontWeight: 700, fontSize: "26px", color: "#212121", marginTop: 0, marginRight: "auto", marginBottom: 0, marginLeft: "auto", }
  },
  {
    id: 13,
    contentType: "ResultsText",
    pageArea: "results",
    parentContainer: 11,
    properties: {
      text: "Congratulations! Based on your score...",
      html: "<h4>Congratulations! Based on your score...</h4>",
      tagName: "h4",
    },
    styling: { width: '100%', maxWidth: '100%', boxSizing: "border-box", padding: "0px", textAlign: 'center', fontWeight: 700, fontSize: "21px", color: "#212121", marginTop: 0, marginRight: "auto", marginBottom: 0, marginLeft: "auto", }
  }, 

];



const Builder = () => {

  const { id }        = useParams();
  const authCtx       = useContext(AuthContext);
  const builderCtx    = useContext(BuilderContext);
  const modalCtx      = useContext(ModalContext);

  const { accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, showMultipleBoxStyle, setStyle, contentBlocks, getStylesAccordingToDevice, isCtrlPressed, setIsCtrlPressed } = builderCtx;

  const [isLoading, setIsLoading] = useState(false);
  const [selectedContentBlockId, setSelectedContentBlockId] = useState(0);
  const [pageType, setPageType] = useState("start");
  const [pages, setPages] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allContentBlocks, setAllContentBlocks] = useState([]);
  const [time, setTime] = useState(getFormattedTime());
  const [headerContainer, setHeaderContainer] = useState({});
  const [footerContainer, setFooterContainer] = useState({});
  const [currentDeviceView, setCurrentDeviceView] = useState(builderCtx.currentDeviceView)

  const showNextPage = () => {
    
    // TODO: Add ability to add new pages in-between existing pages 
    
    // console.log("pages", pages); console.log("currentPage", currentPage);
    if(pages.length === 1 && currentPage === 1) {
      return <Button className="right-btn"><Add className="change-page-icon right" onClick={() => createPage()} /></Button>
    } 
    
    else if(pages.length > 1 && currentPage === 1) {
      return <>
        <Button disabled className="disabled-left"><ChevronLeft className="change-page-icon left" /></Button>
        <div className="page-name-counter-container">
          <p>{currentPage}/{pages.length}</p>
          <h3 className="page-name">{pages[currentPage-1]?.page_type} page</h3>
        </div>
        <Button className="right-btn" ><ChevronRight className="change-page-icon right" onClick={() => nextPage()} /></Button>
      </>
    }
    
    else if(pages.length > 1 && currentPage === pages[pages.length - 1]?.order_no) {
      return <>
        <Button><ChevronLeft className="change-page-icon left" onClick={() => prevPage()} /></Button>
        <div className="page-name-counter-container">
          <p>{currentPage}/{pages.length}</p>
          <h3 className="page-name">{pages[currentPage-1]?.page_type} page</h3>
        </div>
        <Button><Add className="change-page-icon right" onClick={() => createPage()} /></Button>
      </>
    }

    else return <>
      <Button><ChevronLeft className="change-page-icon left" onClick={() => prevPage()} /></Button>
        <div className="page-name-counter-container">
          <p>{currentPage}/{pages.length}</p>
          <h3 className="page-name">{pages[currentPage-1]?.page_type} page</h3>
        </div>
      <Button><ChevronRight className="change-page-icon right" onClick={() => nextPage()} /></Button>
    </>
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTime(getFormattedTime());
    }, 1000);

    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // console.log("pages", pages);
    // // console.log("pages builderCtx", builderCtx);
    setChangePageBtns(showNextPage());
  }, [pages, pages.length, currentPage, builderCtx.refreshPagesAndContent]);

  useEffect(() => {
    setIsLoading(true)
    let options = {
      method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }
    // console.log('id',id)
    Promise.all([
      fetch(process.env.REACT_APP_API_URI + '/v3/assessment/' + id + '/content', options).then(response => response.json()),
      fetch(process.env.REACT_APP_API_URI + '/v3/assessment/' + id + '/pages', options).then(response => response.json())
    ]).then((data) => {
      console.log("data",data);
      if(data[0].status === "success" && data[1].status === "success") {
        
        setPages(data[1].pages.sort((a,b) => a.order_no - b.order_no)); // I think we should add this to the builderCtx so it's accessible everywhere in the builder
        setAllContentBlocks(data[0].content); // I think we should add this to the builderCtx so it's accessible everywhere in the builder
        
        let pageData = data[1].pages.find(obj => obj.order_no == currentPage);
        let currentBlocks = data[0].content.filter(obj => obj.pageId == pageData.id);
        
        builderCtx.updateContentBlocks(currentBlocks);
        
        // let headerContainer = data[0].content.find(obj => obj.page == pageType && obj.contentType == "headerContainer"); ////// Header container needs to use page ID not page type. There can be multiple types of page types
        let headerContainer = data[0].content.find(obj => obj.pageId == pageData.id && obj.contentType == "headerContainer");
        
        setHeaderContainer(headerContainer);
        // let currentPageType = data[1].pages.find(obj => obj.order_no == currentPage);
        
      }
      setIsLoading(false);
    })
    .catch((err) => {
      console.log(err);
    });

    // fetch(process.env.REACT_APP_API_URI + '/v1/assessment/' + id + '/content', {
		// 	method: 'GET',
		// 	mode: 'cors',
		// 	headers: { 'Content-Type': 'application/json' }
		// }).then(response => {
		// 	return response.json();
		// }).then(data => {
    //   console.log(data);
      
		// 	if(data.status == "success") {

    //     // setPageType("start");
        

		// 		// setContentBlocks(data.content);
    //     // setIsLoading(false);
		// 	} else {
		// 		// setOpenSnackbar(true);
		// 	}
		// });

    // Delete after fetch is complete
    // let type;
    // var id = 1;
    // // var id = 3;
    // if(id == 1) {
    //   type = "start";
    // } else if(id == 2) {
    //   type = "generic-page";
    // } else if(id == 3) {
    //   type = "assessment";
    // } else if(id == 4) {
    //   type = "form";
    // } else if(id == 5) {
    //   type = "results";
    // } else if(id == 6) {
    //   type = "dashboard";
    // } else if(id == 7) {
    //   type = "invite";
    // }

    // fetch(process.env.REACT_APP_API_URI + "/v1/admin/assessment/builder/page/" + id, {
    //   method: "GET",
    //   mode: "cors",
    //   headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token }
    // })
    // .then(response => response.json())
    // .then((data) => {
    //   if(data.status === "success") {
    //     console.log(data);
          
           // Change this for now with the other pages
          // setPageType("results");
          // builderCtx.updateContentBlocks(contentBlocksResults); // Change this for now with the other pages
          // setPageType("assessment");
          // builderCtx.updateContentBlocks(contentBlocksAssessment); // Change this for now with the other pages
          

    //   } else {
    //     // setOpenSnackbar(true);
    //   }
    // })
    // .catch(err => console.error(err));
    console.log("ERE");
    
  }, [authCtx.token, id, builderCtx?.refreshPagesAndContent]);

  function getFormattedTime() {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();

    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

    return `${formattedHours}:${formattedMinutes}`;
  }

  

  
  const [changePageBtns, setChangePageBtns] = useState(showNextPage());

  

  // console.log("contentBlocks", contentBlocks);
  // console.log("questions", contentBlocks?.[9]?.properties?.questionsArray);

  

  

  useEffect(()=>{
    setCurrentDeviceView(builderCtx.currentDeviceView)
  },[builderCtx.currentDeviceView])

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'Control') {
        setIsCtrlPressed(true);
      }
    };

    const handleKeyUp = (event) => {
      if (event.key === 'Control') {
        setIsCtrlPressed(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const nextPage = () => {


    console.log("We are here");
    let currentPageType = pages.find(obj => obj.order_no == currentPage + 1);
    let currentBlocks = allContentBlocks.filter(obj => obj.page == currentPageType.page_type);
    console.log(currentPageType);
    console.log(currentBlocks);
    builderCtx.updateContentBlocks(currentBlocks);
    setCurrentPage(currentPageType?.order_no);
    setPageType(currentPageType.page_type);
    let headerContainer = allContentBlocks.find(obj => obj.page == currentPageType.page_type && obj.contentType == "headerContainer");
    setHeaderContainer(headerContainer);
  }
  
  const prevPage = () => {
    let currentPageType = pages.find(obj => obj.order_no == currentPage - 1);
    let currentBlocks = allContentBlocks.filter(obj => obj.page == currentPageType.page_type);
    builderCtx.updateContentBlocks(currentBlocks);
    setCurrentPage(currentPageType.order_no);
    setPageType(currentPageType.page_type);
    let headerContainer = allContentBlocks.find(obj => obj.page == currentPageType.page_type && obj.contentType == "headerContainer");
    setHeaderContainer(headerContainer);
  }


  const showContent = (content) => {
    console.log("show content", content);
    switch (content?.contentType) {

      case "headerContainer": return <div style={setStyle(content.id)} onClick={(e) => accessToolsById(e, content.id)}>
        {
          builderCtx?.contentBlocks?.map((data, index) => {
            if(data?.parentContainer == content.id) { return <>{ showContent(data) }</> }
          })
        }
      </div>
      
      case "Container": return <div style={setStyle(content.id)} onClick={(e) => accessToolsById(e, content.id)}>
        {
          builderCtx?.contentBlocks?.map((data, index) => {
            if(data?.parentContainer == content.id) { return <>{ showContent(data) }</> }
          })
        }
      </div>

      // case "Image": return <div className="image-container"><img alt={content.properties.text} src={content.properties.img} style={content.styling} /></div>
      case "Image": return <img alt={content.properties.text} src={content.properties.img} style={{...setStyle(content.id), ...showMultipleBoxStyle(content.id)}} onClick={(e) => accessToolsById(e, content.id)}/>;
      
      // case "LogoText": return <div className="logo-text"><p className="assessment-title" style={content.styling}>{content.properties.text}</p></div>
      case "LogoText": return <div onClick={(e) => accessToolsById(e, content.id)} style={{...setStyle(content.id), ...showMultipleBoxStyle(content.id)}}><HeadingWidget key={content.id} blockId={content.id} className="editable-text" /></div>;

      case "BleatLogo": return <img alt={content.properties.text} src={content.properties.img} style={{...setStyle(content.id), ...showMultipleBoxStyle(content.id)}} onClick={(e) => accessToolsById(e, content.id)}/>;

      default: return null;

    }
  }

  const startPageID = pages?.find(page => page.page_type === "start")?.id;
  const assessmentPageID = pages?.find(page => page.page_type === "assessment")?.id;
  const assessmentAdditionalPageID = pages?.find(page => page.page_type === "additional")?.id;
  const detailsPageID = pages?.find(page => page.page_type === "details")?.id;
  const resultsPageID = pages?.find(page => page.page_type === "results")?.id;
  const invitePageID = pages?.find(page => page.page_type === "invite")?.id;
  const inviteThankYouPageID = pages?.find(page => page.page_type === "invite-thank-you")?.id;
  const dashboardPageID = pages?.find(page => page.page_type === "dashboard")?.id;

  const displayCurrentPage = () => {
    switch (pageType) {

      case "start": return <Start pageId={startPageID} />;
      case "assessment": return <Assessment pageId={assessmentPageID} />;
      // case "additional": return <AssessmentAdditional pageId={assessmentAdditionalPageID} />;
      // case "details": return <Details />;
      case "details": return <DetailsNew pageId={detailsPageID} />;
      case "results": return <Results pageId={resultsPageID} />;
      case "invite": return <Invite pageId={invitePageID} />;
      case "invite-thank-you": return <InviteSuccess pageId={inviteThankYouPageID} />;
      case "dashboard": return <Dashboard pageId={dashboardPageID} />;
      
      // case "explainer": 
      //   return <Explainer />
      // case "additional": 
      //   return <Additional />
      // case "results": 
      //   return <Results />
      // case "invite":
      // return <Invite />
      // case "invitesuccess": 
      //   return <InviteSuccess />
      // case "dashboard": 
      //   return <Dashboard />
      default: return null;
      
    }
  }

  const createPage = () => {
    modalCtx.openModal();
    modalCtx.setDetails("create-page", id);
  }

  if(modalCtx.pageUpdate.page == "builder") {
    let pages = modalCtx.pageUpdate.pages;
    modalCtx.setPageUpdate({});
    console.log(pages);
    // getAssessments();
  }

  const getClassNameAccoringToDevice = () => {
    if ( currentDeviceView == "Desktop" ) {
      return 'parent-desktop-container'
    }
    else if ( currentDeviceView == "Horizontal" ) {
      return 'parent-horizontal-container parent-desktop-container'
    }
    else if ( currentDeviceView == "Vertical" ) {
      return 'parent-vertical-container parent-desktop-container'
    }
    else {
      return 'parent-mobile-container parent-desktop-container'
    }
  }

  const showByView = () => {
    switch (currentDeviceView) {

      case "Desktop": return <>
        <header>{ showContent(headerContainer) }</header>
        <section onClick={(e) => accessToolsByPageArea("background", e)} style={setSectionStyle("background")}>
          <div className="info-section" onClick={(e) => accessToolsByPageArea("content", e)} style={setSectionStyle("content")}>
            { displayCurrentPage() }
          </div>
        </section>
      </>

      case "Horizontal": return <>
        <div className={getClassNameAccoringToDevice()}>
          <div className="mobile-upper-bar">
            <SignalWifi4Bar/>
            <SignalCellular4Bar/>
            <BatteryFull/>
            <span className="time-container">{time}</span>
          </div>
          <div className="device-container">
            <header>{ showContent(headerContainer) }</header>
            <section onClick={(e) => accessToolsByPageArea("background", e)} style={setSectionStyle("background")}>
              <div className="info-section" onClick={(e) => accessToolsByPageArea("content", e)} style={setSectionStyle("content")}>
                { displayCurrentPage() }
              </div>
            </section>
          </div>
        </div>
      </>

      case "Vertical": return <>
        <div className={getClassNameAccoringToDevice()}>
          <div className="mobile-upper-bar">
            <SignalWifi4Bar/>
            <SignalCellular4Bar/>
            <BatteryFull/>
            <span className="time-container">{time}</span>
          </div>
          <div className="device-container">
            <header>{ showContent(headerContainer) }</header>
            <section onClick={(e) => accessToolsByPageArea("background", e)} style={setSectionStyle("background")}>
              <div className="info-section" onClick={(e) => accessToolsByPageArea("content", e)} style={setSectionStyle("content")}>
                { displayCurrentPage() }
              </div>
            </section>
          </div>
        </div>
      </>

      case "Mobile": return <>
        <div className={getClassNameAccoringToDevice()}>
          <div className="mobile-upper-bar">
            <SignalWifi4Bar/>
            <SignalCellular4Bar/>
            <BatteryFull/>
            <span className="time-container">{time}</span>
          </div>
          <div className="device-container">
            <header>{ showContent(headerContainer) }</header>
            <section onClick={(e) => accessToolsByPageArea("background", e)} style={setSectionStyle("background")}>
              <div className="info-section" onClick={(e) => accessToolsByPageArea("content", e)} style={setSectionStyle("content")}>
                { displayCurrentPage() }
              </div>
            </section>
          </div>
        </div>
      </>
      
    }
  }

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  return (
    <div style={{ position: "relative", height: "100vh" }}>
      {
        !isLoading ? <>
          <div className="change-page-container">
            <div className="change-page">{changePageBtns}</div>
            <Button className="navigator-btn" onClick={()=>{toggleModalHandler("page-builder-navigator-modal")}}>
              <NearMeOutlined/>
              <span>Navigator</span>
            </Button>
          </div>
          { showByView() }
        </> : <>
          <div className="builder-loading-spinner">
            <CircularProgress />
          </div>
        </>
      }      
    </div>
  )
}
export default Builder;



{/* <div className="logo logo-left" onClick={(e) => accessToolsById(e, leftLogo?.id)}>
            <img src={leftLogo?.properties?.img} alt="logo" width="auto" height={leftLogo?.properties?.height} />
          </div>

          <div className="assessment-title desktop" id="assessment-title">

            <ContentEditable className="editable-text logo-text" onClick={(e) => {
              console.log("onClick", logoText.id);
              accessToolsById(e, logoText.id)}} html={logoText?.properties?.text} onChange={(e) => updateContentText(e, logoText.id)} tagName="h2" />

            <ContentEditable className="editable-text logo-subtext" onClick={(e) => {
              console.log("onClick", logoSubtext.id);
              accessToolsById(e, logoSubtext.id)}} html={logoSubtext?.properties?.text} onChange={(e) => updateContentText(e, logoSubtext.id)} tagName="p" />

          </div>

          <div className="logo logo-right" onClick={(e) => accessToolsById(e, rightLogo?.id)}>
            <img src={rightLogo?.properties?.img} alt="logo" width="auto" height={rightLogo?.properties?.height} />
          </div> */}