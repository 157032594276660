import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';

const TextAlign = (props) => {

  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentAlignment   = currentBlockStyles?.textAlign;
  // console.log("currentAlignment", currentAlignment);
  
  const [alignment, setAlignment]   = useState(currentAlignment || "left");
  
  const alignments = [
    { value: 'left',   label: 'left'   },
    { value: 'center', label: 'center' },
    { value: 'right',  label: 'right'  },
  ];

  const handleAlignChange = (e) => setAlignment(e.target.value);

  useEffect(() => {
    
    updateContentBlocks(contentBlocks?.map(block => {
      if (block.id === blockId) {
        let newStyles = { ...block.styling };
        newStyles.textAlign = alignment;
        block.styling = { ...newStyles };
        return block;
      } else return block;
    }))

  }, [blockId, alignment]);


  // console.log("blockId", blockId);
  // console.log("alignment", alignment);

  return (
    <div className="font-styles tool">

      {props.deleteModal}
      <TextField id="select-style-unit" className="textalign-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }} placeholder={currentContentBlock.styling?.textAlign + ""} value={alignment} onChange={(e) => { handleAlignChange(e); }} >
        {alignments?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>

    </div>
  )
}


export default TextAlign;
