import React, { useContext } from "react";
import { Link, useParams } from "react-router-dom";
import Button from "@mui/material/Button";
import BuilderContext from "../../store/BuilderContext";
import analyzeIcon from "../../assets/img/analyze.png";
import alignIcon from "../../assets/img/align.png";
import advanceIcon from "../../assets/img/advance.png";

const InviteInfo = (props) => {
  const { id } = useParams();
  const builderCtx = useContext(BuilderContext)
  const { contentBlocks, updateContentBlocks, accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, changeCurrentPage } = builderCtx;
  const container = contentBlocks?.find(block => block?.contentType === "Container" && block?.pageArea === "results");

  return (
    <div className="invite-container" onClick={(e) => accessToolsById(container?.id)} style={setSectionStyle(container?.id)}>  
      <div className="invite-container-spacer">
        <h3 onClick={(e)=>{accessToolsById(e,3253)}} style={setStyle(3253)}>Invite your team to take this assessment</h3>

        <h5 onClick={(e)=>{accessToolsById(e,3986)}} style={setStyle(3986)}>Invite your team to take this assessment with you, and view group and individual results to find where you're aligned and where you need to align to maximize community engagement!</h5>
        <div className="invite-reasons" style={{ 'display': "flex" ,"gap": "98px" }}>
          <div className="reason">
            <img src={analyzeIcon} alt="insights icon"  />
            <br />
            <div className="height30"></div>
            <span>Lorem Ipsum</span>
            <br />
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
          </div>

          <div className="reason">
            <img src={alignIcon} alt="alignment icon" />
            <br />
            <div className="height30"></div>
            <span>Lorem Ipsum</span>
            <br />
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
          </div>

          <div className="reason">
            <img src={advanceIcon} alt="direction icon" />
            <br />
            <div className="height30"></div>
            <span>Lorem Ipsum</span>
            <br />
            Lorem ipsum dolor sit amet, consetetur sadipscing elitr.
          </div>
        </div>

        <div className="cta-btn" style={{ display:"flex" , justifyContent:"center"}}>
          {/* <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE MY TEAM</Button>
          </Link> */}
          <Link to={""} target="_blank" style={{ textDecoration: "none" }}>
            <Button style={{'background': '#ED2028'}}>INVITE MY TEAM</Button>
          </Link>
        </div>

        {/* <Testimonial /> */}
      </div>

      {/* <div className="testimonial-invite">
        <p>Invite your team to take this test along with you and begin to align around the vision for your church's mission!</p>
        <div className="cta-btn">
          <Link to={"/invite-team/" + id} target="_blank">
            <Button>INVITE YOUR TEAM</Button>
          </Link>
        </div>
      </div> */}
    </div>
  );
};

export default InviteInfo;
