import { useContext, useState, useCallback, useEffect, useRef } from 'react';
import Cropper from 'react-easy-crop';
import { useDropzone } from 'react-dropzone';
import { Base64 } from 'js-base64';

import { Button, CircularProgress, Slider } from '@mui/material';
import { CloseRounded } from '@mui/icons-material';

import getCroppedImg from './CropImage.js'
import BuilderContext from '../../../../store/BuilderContext.js';
import ModalContext from '../../../../store/ModalContext.js';
import AuthContext from '../../../../store/AuthContext.js';


function Dropzone({ onDrop, accept, open }) {
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({
      accept: { 'image/*': ['.jpeg', '.jpg', '.png'] },
      onDrop,
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));

  return (
    <div className="d-n-d">
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} {...getRootProps({ className: "dropzone" })}>
        <input className="input-zone" {...getInputProps()} />
        <div className="text-center" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
          {isDragActive ? (
            <p className="dropzone-content">
              Release to drop the files here
            </p>
          ) : (
            <div className="dropzone-content">
              <p>Upload Image</p>
              <p>Drag and drop or click to browse</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}


const AddImage = (props) => {

  // const db = database;

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const builderCtx = useContext(BuilderContext);

  const isMountedRef = useRef(true);

  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [image, setImage] = useState(null);
  const [isUploadingImg, setIsUploadingImg] = useState(false);
  const [uploadedImgUrl, setUploadedImgUrl] = useState("");
  const [finalImage, setFinalImage] = useState();
  // const [uploading, setUploading] = useState(false);

  useEffect(()=>{
    console.log('props',props)
    console.log('builderCtx',builderCtx)
},[props])

useEffect(()=>{
  if(uploadedImgUrl) {
    let payload = { id: props.blockId, image: uploadedImgUrl, }
    console.log('payload', payload)
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/content/image', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    }).then(response => response.json())
      .then(data => {
      if(data.status === "success") {
        console.log("updated image", data); 
      }
    }).catch((err) => {
      console.error("Failed to update image...", err);
    });
  }
},[uploadedImgUrl])

  useEffect(() => {
    // console.log("\ncurrentContentBlock", currentContentBlock);
    return () => {
      console.log("Unmounting AddImage!");
      isMountedRef.current = false;
    };
  }, []);

  useEffect(()=>{ console.log('currentContentBlock',currentContentBlock) },[currentContentBlock])

  // get dropped images
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onload = function (e) {
        setImage(e.target.result);
        // if (isMountedRef.current) {
        //   setImage(e.target.result);
        // }
      };
      reader.readAsDataURL(file);
      return file;
    });
  }, []);


  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])
  
  const leftLogoId = contentBlocks?.find(block => block.contentType === "Image")?.id;
  const backgroundId = contentBlocks?.find(block => block.contentType === "Background")?.id;
  const startPageImgVideoBlockId = contentBlocks?.find(block => block.contentType === "StartPageImgVideo")?.id;

  useEffect(() => {
    if (croppedImage) {
      // console.log("croppedImage", croppedImage);

      // setFinalImage(croppedImage);
      updateContentBlocks((prevBlocks) => prevBlocks?.map(block => {
        if ((block.id === props?.blockId) && !props?.background) {
          block.properties.img = croppedImage;
          return block;
        } else if ((block.id === props?.blockId) && props?.background) {
          let newStyles = { ...block.styling };
          newStyles.backgroundImage = `url(${croppedImage})`;
          newStyles.backgroundRepeat = "no-repeat";
          block.styling = { ...newStyles };
          return block;
        } else return block;
      }))
    } else {
      // console.log(croppedImage);
      console.warn("no img");
    }
    // }, [croppedImage, leftLogoId]);
  }, [croppedImage, props?.background, props?.blockId]);

  useEffect(() => {
    showCroppedImage();
    builderCtx.setLastUploadedMedia({ type: "image", url: croppedImage });
  }, [croppedImage, crop, croppedAreaPixels]);
  

  // get cropped image 
  const showCroppedImage = async () => {
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);
      setCroppedImage(croppedImage);
    } catch (err) {
      console.error(err);
    }
  }

  // this gets a file object which is passed to firebase
  const baseToFile = (base) => {
    if (base) {
      let arr = base?.split(",")
      let mime = arr[0].match(/:(.*?);/)[1]
      let data = arr[1]
      let dataStr = Base64.atob(data)
      let n = dataStr.length
      let dataArr = new Uint8Array(dataStr.length)
      while (n--) {
        dataArr[n] = dataStr.charCodeAt(n)
      }
      let file = new File([dataArr], `img_cropper_${Date.now()}.png`, { type: mime })
      return file;
    } else return null;
  }

  // puts image into a state
  const confirmImg = () => {
    showCroppedImage();
    // let img = baseToFile(croppedImage)
    uploadImg();
  }

  const uploadImg = () => {

    let img = baseToFile(croppedImage);
    if (img) {
      // console.log(img);
      // setFinalImage(img);

      let formdata = new FormData();
      formdata.append("file", img);
      setIsUploadingImg(true);
      fetch(process.env.REACT_APP_API_URI + '/v1/accounts/' + modalCtx?.details?.id + '/uploadLogo', {
        method: 'POST',
        headers: { 'Authorization': 'Bearer ' + authCtx.token },
        body: formdata,
        redirect: 'follow'
      }).then(response => {
        return response.json();
      }).then(data => {
        console.log('data',data);
        if (data.status == "success") {
          setIsUploadingImg(false);
          setUploadedImgUrl(data.imagePath);
          setCroppedImage(null);
          setImage(null);
          if (props?.blockId === startPageImgVideoBlockId) {
            builderCtx.setStartPageImageURL(data.imagePath);
            builderCtx.setLastUploadedMedia({ type: "image", url: data.imagePath });
            updateContentBlocks((prevBlocks) => prevBlocks?.map(block => {
              if (block.id === startPageImgVideoBlockId) {
                block.properties.image = data.imagePath;
              }
              return block;
            }));
          }
        }
      }).catch(error => {
        console.error("Error uploading image...", error);
        setIsUploadingImg(false);
      });

    } else {
      // console.log(img);
      console.warn("no img", img);
    }

  }
  // colse image
  // const onClose = useCallback(() => {
  //   setCroppedImage(null)
  //   closeHandle()
  // }, [])

  // console.log("image", image);

  return (
    <>
    <div className='crop-container' style={{ width: "auto", maxWidth: "270px", height: "320px", margin: "0 auto" }}>
      <div className="title">
      </div>
      <div className={ currentContentBlock.pageArea == 'header' ? `easy-crop easy-crop-header ${currentContentBlock.contentType}` : 'easy-crop' }>
        {
          image ?
            <Cropper image={image} crop={crop}
              zoom={zoom} 
              aspect={ currentContentBlock.contentType == 'Image' ? 3 : 1 }
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
            /> : <Dropzone onDrop={onDrop} />
        }
      </div>
      
      {image || croppedImage ? <div>
        <span>Drag to reposition the image</span>
        {image ? <div className='crop-btns' >
          <div className="slider">
            <h3> Zoom </h3>
            <Slider value={zoom} min={1} max={3}
              step={0.1} aria-labelledby="Zoom"
              onChange={(e, zoom) => setZoom(zoom)}
            />
          </div>
          <Button onClick={confirmImg} disabled={image ? false : true} >{(image && !croppedImage) ? "Confirm" : (croppedImage && !isUploadingImg) ? "Upload" : isUploadingImg ? <>
          <CircularProgress className="uploading" style={{ color: "#FFFFFF" }} /> &nbsp;Uploading...</> : "Confirm" }</Button>
        </div> : null}
      </div> : null}
    </div>
    
    {/* {uploadedImgUrl && <div style={{ margin: "15px auto" }}>
      <img src={uploadedImgUrl} height="auto" width="270px" alt="" style={{ display: "block", margin: "0 auto" }} />
      <p style={{ textAlign: "center" }}>Current image</p>
    </div>} */}
    </>
  )
}

export default AddImage;
