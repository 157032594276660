import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import AuthContext from "../../../../store/AuthContext";
import BuilderContext from '../../../../store/BuilderContext';

const BackgroundSize = (props) => {

  const { blockId } = props;
  const authCtx     = useContext(AuthContext);
  const builderCtx  = useContext(BuilderContext);

  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;

  const currentBgSize = currentBlockStyles?.backgroundSize;

  // css image background-repeat property 
  const sizes = [
    // repeat|repeat-x|repeat-y|no-repeat|initial
    { value: 'auto',    label: 'auto'    },
    { value: 'cover',   label: 'cover'   },
    { value: 'contain', label: 'contain' },
    { value: 'initial', label: 'initial' },
  ];

  const [bgSize, setBgSize] = useState(currentBgSize || "cover");


  const handleChange = (e) => {
    setBgSize(e.target.value);
  }

  useEffect(() => {
    let timer;
    if (bgSize && bgSize !== currentBgSize) {
      timer = setTimeout(() => {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            let payload = {
              id: blockId,
              value: bgSize,
              screenType: props?.screenType
            }
            fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/backgroundSize', {
              method: 'PUT', mode: 'cors',
              headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
              body: JSON.stringify(payload),
            })
            .then(response => response.json())
            .then(data => {
              if(data.status === "success") {
              // console.log("updated backgroundSize", data);
              }
            }).catch((err) => {
              console.error("Failed to update backgroundSize...", err);
            });

            return { ...block, backgroundSize: bgSize };
          } else return block;
        }))
      }, 1000);
    }

    return () => {
      if (timer) clearTimeout(timer);
    };

  }, [authCtx.token, blockId, bgSize]);

  // console.log("blockId", blockId);
  // console.log("bgSize", bgSize);

  return (
    <div className="backgroundimg tool">

      <div className="background-size">
        {props.deleteModal}
        <TextField className="position-unit styling-select" select variant="standard"
          InputProps={{ disableUnderline: true }}
          // placeholder={currentContentBlock?.styling?.backgroundSize + ""} 
          value={bgSize} onChange={handleChange} >
          {sizes?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>

    </div>
  )
}


export default BackgroundSize;


