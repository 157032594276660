import { useState, useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import axios from 'axios';

import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';

const AssessmentAutomationDelete = (props) => {
  const { details } = props;
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  console.log(details);
  

  const handleDeleteAutomation = () => {
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + details.id + "/automation", {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        modalCtx.closeModal();
        modalCtx.setDetails(null);
        modalCtx.setPageUpdate({page: "assessments-automation"});
      } else {
        console.log("failed to delete");
      }
    })
    .catch(err => {
      if(err) {
        console.log(err);
        // setIsLoading(false);
      }
    });
  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  return (
    <div className="modal">
      
      <p>Are you sure you want to delete this automation?</p>
      
      <div className="footer">
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>No</Button>
        <Button className="confirm-btn" onClick={() => { handleDeleteAutomation() }}>Yes</Button>
      </div>
    </div>
  )
}

export default AssessmentAutomationDelete;
