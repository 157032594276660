import { useState, useEffect, useContext } from 'react';
import { Paper, TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Box, Tabs, Tab, Chip, CircularProgress, Link, Button, Tooltip, checkboxClasses } from '@mui/material';
import AuthContext from '../store/AuthContext';
import DrawerContext from '../store/DrawerContext';
import ModalContext from '../store/ModalContext';
import { ExitToApp } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import axios from 'axios';
import { Add, Delete, Edit } from '@mui/icons-material';

import { Checkbox } from '@mui/material';
import { getCentralDate } from '../globalFunctions';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DataMining = () => {

  const [isLoading, setIsLoading]             = useState(false);
  const authCtx                               = useContext(AuthContext);
  const drawerCtx                             = useContext(DrawerContext);
  const modalCtx                              = useContext(ModalContext);

  const [allURLs, setAllURLs]                 = useState(null);
  const [filteredURLs, setFilteredURLs]       = useState(null);
  const [websites, setWebsites]               = useState("");
  const [filteredWebsites, setFilteredWebsites] = useState("");
  const [showFilteredURLs, setShowFilteredURLs] = useState(false);
  const [showFilteredWebsites, setShowFilteredWebsites] = useState(false);
  const [selectionModel, setSelectionModel]   = useState([]);
  const [isUpdatingScrape, setIsUpdatingScrape] = useState(false);
  const [currentCronState, setCurrentCronState] = useState("waiting");

  const [contacts, setContacts]               = useState("");
  const [tabValue, setTabValue]               = useState(0);

  const [websiteURL, setWebsiteURL]           = useState("");
  const [websiteTitle, setWebsiteTitle]       = useState("");
  const [scrapeWebsiteID, setScrapeWebsiteID] = useState("");
  const [toggleRefresh, setToggleRefresh] = useState(true);

  const [checkedRows, setCheckedRows] = useState({});
  let deleteIds=[];

  const [showBulkDeleteBtn, setShowBulkDeleteBtn] = useState(false);


  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  
  useEffect(() => {
    if(selectionModel?.length) setShowBulkDeleteBtn(true);
    else setShowBulkDeleteBtn(false);
  }, [selectionModel]);

  const firstSelectedURLsWebsiteID = selectionModel?.length ? allURLs?.find(url => url.id === selectionModel[0])?.website_id : null;
  // console.log("firstSelectedURLsWebsiteID", firstSelectedURLsWebsiteID);

  useEffect(() => {

    let options = {
      method: 'GET',
			mode: 'cors',
			headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    Promise.all([
      fetch(process.env.REACT_APP_API_URI + '/v2/datamining/websites', options).then(response => response.json()),
      fetch(process.env.REACT_APP_API_URI + '/v2/datamining/websites/urls', options).then(response => response.json()),
      fetch(process.env.REACT_APP_API_URI + '/v2/datamining/cronStatus', options).then(response => response.json()),
      // fetch(process.env.REACT_APP_API_URI + '/v2/datamining/websites/contacts', options).then(response => response.json())
    ]).then((data) => {
      console.log("all API data:", data);
      // if(data[0].status === "success" && data[1].status === "success" && data[2].status === "success") {
      if(data[0].status === "success" && data[1].status === "success") {
        setWebsites(data[0]?.data);
        setAllURLs(data[1]?.data);
        setCurrentCronState(data[2]?.data);
      }
      setIsLoading(false);
    })
    .catch((err) => {
      console.log("Failed to fetch websites, URLs, contacts", err);
      setIsLoading(false);
    });
    // console.log("HERE");
    
  }, [authCtx.token,toggleRefresh]);


  // console.log("allURLs", allURLs);
  // console.log("websites", websites);
  // console.log("contacts", contacts);

  // useEffect(() => {

  //   const handleScrapeWebsite = () => {
  //     // axios.post(process.env.REACT_APP_API_URI + '/v2/datamining/websites', {
  //     fetch(process.env.REACT_APP_API_URI + '/v2/datamining/websites', {
  //       method: 'POST',
  //       headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
  //       mode: "cors",
  //       body: JSON.stringify({ 
  //         title: title, 
  //         website: website, 
  //       }),
  //     })
  //     .then(response => {
  //       console.log("response", response);
  //       return response.json();
  //     })
  //     .then(result => {
  //       console.log("result", result);
  //       if (result.status === "success") {
  //         setScrapeWebsiteID(result?.id || "no ID returned");
  //         setIsLoading(false);
  //         // modalCtx.closeModal();
  //         // modalCtx.setDetails(null);
  //       } else {
  //         console.log("failed to scrape website");
  //       }
  //     }).catch(err => {
  //       console.error("failed to scrape website...", err);
  //       setIsLoading(false);
  //     });
  //   }

  //   if(title && website) {
  //     handleScrapeWebsite();
  //   }
  // }, [authCtx.token, modalCtx.modalData, website, title]);
  

  // useEffect(() => {
  //   // const scrapeWebsiteID = modalCtx.modalData;
  //   console.log("scrapeWebsiteID useEffect", scrapeWebsiteID);
    
  //   const getScrapedData = () => {
  //     axios.get(process.env.REACT_APP_API_URI + '/v2/datamining/websites/' + scrapeWebsiteID?.id + '/scrape', {
  //       mode: 'cors',
  //       // headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
  //       headers: { 'Content-Type': 'application/json' },
  //     })
  //     .then(response => response.data)
  //     .then(result => {
  //       console.log("scrape data", result);
  //       if (result.status === "success") {
  //         setScrapeWebsiteID(result?.id || "no ID returned");
  //         setIsLoading(false);
  //       } else {
  //         console.log("failed to scrape website");
  //       }
  //     }).catch(err => {
  //       console.error("failed to scrape website...", err);
  //       setIsLoading(false);
  //     });
  //   }

  //   if(scrapeWebsiteID) {
  //     getScrapedData();
  //   }
    
  // // }, [modalCtx.modalData]);
  // }, [scrapeWebsiteID]);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  function showScraperModal(toggleRefresh, setToggleRefresh) {
    toggleModalHandler("website-scraper", {toggleRefresh: toggleRefresh, setToggleRefresh: setToggleRefresh});

  }

  const startScraping = () => {

    setIsUpdatingScrape(true);
    
    fetch(process.env.REACT_APP_API_URI + '/v2/datamining/updateCronStatus', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      mode: "cors",
      body: JSON.stringify({ 
        status: "running"
      }),
    })
    .then(response => response.json())
    .then(result => {
      console.log("result", result);
      if (result.status === "success") {
        setIsUpdatingScrape(false);
        setCurrentCronState("running");
      } else {
        console.log("failed to update status");
      }
    }).catch(err => {
      console.error("failed to update status.", err);
      setIsUpdatingScrape(false);
    });

  }

  const stopScraping = () => {

    setIsUpdatingScrape(true);
    
    fetch(process.env.REACT_APP_API_URI + '/v2/datamining/updateCronStatus', {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      mode: "cors",
      body: JSON.stringify({ 
        status: "waiting"
      }),
    })
    .then(response => {
      console.log("response", response);
      return response.json();
    })
    .then(result => {
      console.log("result", result);
      if (result.status === "success") {
        setIsUpdatingScrape(false);
        setCurrentCronState("waiting");
      } else {
        console.log("failed to update status");
      }
    }).catch(err => {
      console.error("failed to update status.", err);
      setIsUpdatingScrape(false);
    });

  }

  function showEditWebsiteDetailsModal(websiteDetails, toggleRefresh, setToggleRefresh) {
    toggleModalHandler("website-details-edit", {websiteDetails: websiteDetails, toggleRefresh: toggleRefresh, setToggleRefresh: setToggleRefresh});
  }

  // function showEditURLDetailsModal(url,title){
  //   toggleModalHandler("URL-details-edit",{url:url,title:title});
  // }

  function showEditContactDetailsModal(url,title) {
    toggleModalHandler("contact-details-edit", {url:url, title:title});
  }

  function showDeleteWebsitesModal(websiteDetails,toggleRefresh, setToggleRefresh) {
    toggleModalHandler("website-details-delete", {websiteDetails: websiteDetails, toggleRefresh: toggleRefresh, setToggleRefresh: setToggleRefresh});
  }
  
  function showDeleteURLsModal(websiteDetails,toggleRefresh, setToggleRefresh) {
    toggleModalHandler("URL-details-delete", {websiteDetails: websiteDetails, toggleRefresh: toggleRefresh, setToggleRefresh: setToggleRefresh});
  }
  
  function showDeleteContactsModal() {
    toggleModalHandler("contact-details-delete", null);
  }
  
  function showBulkDeleteModal(toggleRefresh,setToggleRefresh) {
    toggleModalHandler("bulk-delete", { firstWebsiteID: firstSelectedURLsWebsiteID,  urlsToKeep: selectionModel, 
                                        toggleRefresh: toggleRefresh, setToggleRefresh: setToggleRefresh});
  }

  function deleteWebsitesHandler(url,title,id) {
    const newWebsites= websites.filter((website) => website.id !== id);
    // const newAllURLs = allURLs.filter((urls) => urls.website_id !== id);
    // const newContacts = contacts.filter((contact) => contact.website !== url);
    // console.log("newWebsites", newWebsites); 
    // console.log("newAllURLs", newAllURLs);
    // console.log("new contacts", newContacts);
    setWebsites(newWebsites);
    // setContacts(newContacts);
    // setAllURLs(newAllURLs);
  }

  function deleteURLsHandler(url,title,id) {
    console.log(allURLs);
    const newAllURLs = allURLs.filter((urls) => urls.id !== id);
    // console.log(newAllURLs);
    setAllURLs(newAllURLs);
  }

  function deleteContactsHandler(url,title,id) {
    const newContacts = contacts.filter((contact) => contact.id!==id);
    setContacts(newContacts);   
  }

  function filterFxn() {
    
    const filteredArray = allURLs.filter(url => url.status === 'pending');
    const filteredWebsites = websites.filter(website => website.status === 'pending');
    
    setFilteredURLs(filteredArray);
    setFilteredWebsites(filteredWebsites);

    setShowFilteredURLs(!showFilteredURLs);
    setShowFilteredWebsites(!showFilteredWebsites);

    console.log("filteredURLs", filteredURLs);
    console.log("filteredWebsites", filteredWebsites);
    
  }

  const getDateISO = (createdAt) => new Date(createdAt.replace(/ /g,"T") + "Z").toISOString();

  const centerHeader = { headerClassName: "grid-header", headerAlign: "center", align: "center" };
  const centerAlign = { headerAlign: "center", align: "center" };

  const SeeMoreBtn = (props) => <Button className="see-more-btn" onClick={() => {
    // console.log("seemorebtn props", props);
    toggleDrawerHandler('user-details', props?.row.id);
  }}>SEE MORE&nbsp;<ExitToApp /></Button>

  const formatURL = (url) => {
    let urlString = url.toString().trim();
    if(urlString?.startsWith("http://") || urlString?.startsWith("https://")) {
      return url;
    } else return "https://" + urlString;
  }

  const formatURL2 = (url) => {

    let urlString = url.toString().trim();
    let partsArray  = urlString.split("/");
    console.log("subdirectory: "+ partsArray[1]);
    if(urlString?.startsWith("http://") || urlString?.startsWith("https://")) {
      return "/" + partsArray[3];
    } else return "/"+ partsArray[1];
    
  }


  const handleShowBulkDeleteBtn = (idsSize) => {
    // console.log(idsSize);
    if(idsSize > 0){
      setShowBulkDeleteBtn(true);
    } 
    else{
      setShowBulkDeleteBtn(false);
    }
  }

  useEffect(()=> {
    const idsToDelete = Object.entries(checkedRows)
    .filter(([, isChecked]) => isChecked)
    .map(([id]) => id);
    deleteIds = idsToDelete;
    // console.log(checkedRows);
    // console.log(deleteIds);
    // console.log(deleteIds.length);
    handleShowBulkDeleteBtn(deleteIds.length);

  },[checkedRows])

  const handleCheckboxClick = (event) => {
    // console.log("id of clicked checkbox " + event.target.id);
    const rowId = event.currentTarget.id;
    console.log(rowId);
    const isChecked = event.currentTarget.checked;
    setCheckedRows((prevState) => ({ ...prevState, [rowId]: isChecked }));
    setShowBulkDeleteBtn(true);
  }


  const columnsWebsites = [
    { field: 'website_url', headerName: 'URLs', ...centerHeader, width: 320 , align: "left", 
      renderCell: (params) => {
        let href = formatURL(params.row?.website_url);
        return <a title={href} className="url" href={href} target="_blank" rel="noopener noreferrer">{params.row?.website_url}</a>
      } },
    { field: 'title', headerName: 'Title', ...centerHeader, width: 450 },
    { field: 'status', headerName: 'Status', ...centerHeader, width: 280, 
      renderCell: (params) => {
        let status = params.row?.status === "complete" ? "Complete" : "Pending";
        let chipClasses = params.row?.status === "complete" ? "green chip" : "red chip";
        return <Chip className={chipClasses} label={status} />} },
    { field: "actions", headerName: 'Edit/Delete URLs', ...centerHeader, width: 180, sortable: false, 
      renderCell: (params) => <div className="actions">
        <Tooltip title="Edit" onClick={() => {showEditWebsiteDetailsModal(params.row, toggleRefresh, setToggleRefresh)}}><Edit/></Tooltip>
        {/* <Tooltip title="Delete" onClick={()=> deleteWebsitesHandler(params.row?.website_url, params.row?.title, params.row?.id)}><Delete /></Tooltip> */}
        <Tooltip title="Delete" onClick={()=> showDeleteWebsitesModal(params.row, toggleRefresh, setToggleRefresh)}><Delete /></Tooltip>
        
      </div> },
  ];
  

  const columnsAllURLs = [
    // {
    //   field:'checkbox', headerName: '',...centerHeader, width:80, align:"center",
    //   renderCell: (params) => {
    //     const rowId = params.row?.id;
    //     const isChecked = checkedRows[rowId] || false;
    //       return (<div><Checkbox id={rowId} checked={isChecked} onChange={handleCheckboxClick}/></div>);
    //   }
    // },
    { field: 'url', headerName: 'URLs', ...centerHeader, width: 450, align: "left", 
      renderCell: (params) => {
        let href = formatURL(params.row?.url);
        return <a title={href} className="url" href={href} target="_blank" rel="noopener noreferrer">{params.row?.url}</a>
      } },
      { field: 'title', headerName: 'Title', ...centerHeader, width: 450 },
    
    { field: 'status', headerName: 'Status', ...centerHeader, width: 220, 
      renderCell: (params) => {
        let status = "waiting";
        if(params.row?.status === "complete") {
          status = "complete";
        } else if(params.row?.status === "pending") {
          status = "Pending";
        }
        let chipClasses = "orange chip";
        if(params.row?.status === "complete") {
          chipClasses = "green chip";
        } else if(params.row?.status === "pending") {
          chipClasses = "red chip";
        }
        return <Chip className={chipClasses} label={status} />} },
    { field: "actions", headerName: '', ...centerHeader, width: 180, sortable: false, 
      renderCell: (params) => <div className="actions">
        {/* <Tooltip title="Edit" onClick={() => {showEditURLDetailsModal(params.row?.website_url, params.row?.title)}}><Edit /></Tooltip> */}
        {/* <Tooltip title="Delete"  onClick={()=> deleteURLsHandler(params.row?.website_url, params.row?.title, params.row?.id)}><Delete /></Tooltip> */}
        <Tooltip title="Delete" onClick={()=> showDeleteURLsModal(params.row, toggleRefresh, setToggleRefresh)}><Delete /></Tooltip>
     
      </div> },
  ];


  const columnsContacts = [
    { field: 'website', headerName: 'Website', ...centerHeader, width: 320, align: "left", 
      renderCell: (params) => {
        let href = formatURL(params.row?.website);
        return <a title={href} className="url" href={href} target="_blank" rel="noopener noreferrer">{params.row?.website}</a>
      } },
    { field: 'url', headerName: 'URL', ...centerHeader, width: 350, align: "left", 
      renderCell: (params) => {
        let href = formatURL(params.row?.url);
        let test = formatURL2(params.row?.url);
        return <a title={href} className="url" href={href} target="_blank" rel="noopener noreferrer">{test}</a>
      } },
    { field: 'firstName', headerName: 'First Name', ...centerHeader, width: 280, renderCell: (params) => <span title={params.value}>{params.value || "-"}</span> },
    { field: 'lastName', headerName: 'Last Name', ...centerHeader, width: 280, renderCell: (params) => <span title={params.value}>{params.value || "-"}</span> },
    { field: 'jobTitle', headerName: 'Job Title', ...centerHeader, width: 280, renderCell: (params) => <span title={params.value}>{params.value || "-"}</span> },
    { field: 'email', headerName: 'Email', ...centerHeader, width: 280, renderCell: (params) => <span title={params.value}>{params.value}</span> },
    { field: "actions", headerName: '', ...centerHeader, width: 180, sortable: false, 
      renderCell: (params) => <div className="actions">
        <Tooltip title="Edit" onClick={() => {showEditContactDetailsModal(params.row?.website_url, params.row?.title)}}><Edit /></Tooltip>
        <Tooltip title="Add to Active Campaign"><Add /></Tooltip>
        {/* <Tooltip title="Delete"  onClick={()=> deleteContactsHandler(params.row?.website_url, params.row?.title, params.row?.id)}><Delete /></Tooltip> */}
        <Tooltip title="Delete"  onClick={()=> showDeleteContactsModal()}><Delete /></Tooltip>
      
      </div> },
  ];

  // console.log("columnsAllURLs", columnsAllURLs);
  // console.log("modalCtx.modalData", modalCtx.modalData);

  function Timer() {
    const [seconds, setSeconds] = useState(60);
  
    useEffect(() => {
      const intervalId = setInterval(() => {
        setSeconds((seconds) => {
          if (seconds === 1) {
            //refresh the data here
            setToggleRefresh(!toggleRefresh);
            return 60;
          } else {
            return seconds - 1;
          }
        });
      }, 1000);
  
      return () => clearInterval(intervalId);
    }, []);

    const resetTimer = () => {
      //refresh the data here
      setToggleRefresh(!toggleRefresh);

      setSeconds(60);
    };
  
    return (
      <div>
        auto refresh in {seconds} seconds.
        <Button onClick={resetTimer} className="red-btn" style={{ margin: "0 0 0 20px", backgroundColor: "#f5f5f5" ,color:"black", padding: "5px 10px", fontWeight: 600}}>Refresh now</Button>
      </div>
    );
  }

  const showScrapeButton = () => {
    let cronState = "loading"
    if(!isUpdatingScrape) {
      cronState = currentCronState;
    }
    switch (cronState) {
      case "loading": return <Button className="green-btn" style={{ margin: "0 0 0 20px", backgroundColor: "#00a5b8", color: "#f5f5f5", padding: "10px 10px", fontWeight: 600 }}><CircularProgress style={{'color': '#fff'}} size={16}/></Button>;
      case "waiting": return <Button className="green-btn" style={{ margin: "0 0 0 20px", backgroundColor: "#00a5b8", color: "#f5f5f5", padding: "5px 10px", fontWeight: 600 }} onClick={() => startScraping()}>SCRAPE</Button>;
      case "running": return <Button className="green-btn" style={{ margin: "0 0 0 20px", backgroundColor: "#00a5b8", color: "#f5f5f5", padding: "5px 10px", fontWeight: 600 }} onClick={() => stopScraping()}>PAUSE SCRAPE</Button>;
      default: return null;
    }

    

  }

  
  return (
    <div className="engagement">
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <h1 style={{ display: "inline-block", margin: "0 auto 0 0" }}>Data Mining</h1>
        {showBulkDeleteBtn ?(<div><Button onClickCapture = {() => {showBulkDeleteModal(toggleRefresh,setToggleRefresh)}}>Keep selected</Button></div>): null}
        <div style={{margin: "0 0 0 20px", padding: "5px 10px",}}>
          
          <Timer/>
        </div>
        {
          (showFilteredURLs) ? 
          <Button className="red-btn" style={{ margin: "0 0 0 20px", backgroundColor: "#f5f5f5" ,color:"#FF004F", padding: "5px 10px", fontWeight: 600}} onClick={() => filterFxn()}>SHOW ALL</Button>
          :
          <Button className="red-btn" style={{ margin: "0 0 0 20px", backgroundColor: "#FF004F" ,color:"#f5f5f5", padding: "5px 10px", fontWeight: 600}} onClick={() => filterFxn()}>SHOW PENDING</Button>

        }
        
        {/* <Button className="green-btn" style={{ margin: "0 0 0 20px", backgroundColor: "#00a5b8", color: "#f5f5f5", padding: "5px 10px", fontWeight: 600 }} onClick={() => showScraperModal(toggleRefresh, setToggleRefresh)}>SCRAPE</Button> */}
        {showScrapeButton()}
      </div>
      {/* <a className="engagement-link" href="https://40parables.com/bleat/" target="_blank" rel="noreferrer">https://40parables.com/bleat/</a> */}

      <div className="tab-container">

        <Box sx={{ width: '100%' }}>
          <Box className="box" sx={{ borderBottom: 1, borderColor: '#EDEDED' }}>
            <Tabs TabIndicatorProps={{ style: { background: '#F2D466', height: '4px' } }} value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab className="tab-title" label="Websites" {...a11yProps(1)} />
              <Tab className="tab-title" label="URLs" {...a11yProps(0)} />
              {/* <Tab className="tab-title" label="Contacts" {...a11yProps(1)} /> */}
              {/* <Tab className="tab-title" label="Engagements" {...a11yProps(2)} /> */}
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0} className="engagements-table grid">

            {(showFilteredWebsites ? filteredWebsites : websites)?.length && !isLoading ? <DataGrid
              className="subscription-grid-container"
              rows={(showFilteredWebsites ? filteredWebsites : websites)}
              columns={columnsWebsites}
              // pageSize={5}
              // checkboxSelection
              // disables all advanced column sorting options available through menu 
              disableColumnMenu
              disableSelectionOnClick
              // onSelectionModelChange={(newSelectionModel) => {
              //   setSelectionModel(newSelectionModel);
              // }}
              // selectionModel={selectionModel}
              // rowsPerPageOptions={[5]}
              rowsPerPageOptions={[100]}
            /> : <section className="loader-section"><CircularProgress /></section>}

          </TabPanel>

          <TabPanel value={tabValue} index={1} className="all-engagements-table grid">
            
          {((showFilteredURLs ? filteredURLs : allURLs)?.length)  && !isLoading ? <DataGrid
              className="subscription-grid-container"
              rows={(showFilteredURLs ? filteredURLs : allURLs)}
              columns={columnsAllURLs}
              // pageSize={5}
              checkboxSelection
              // disables all advanced column sorting options available through menu 
              disableColumnMenu
              disableSelectionOnClick
              onSelectionModelChange={(newSelectionModel) => {
                console.log("newSelectionModel", newSelectionModel);
                setSelectionModel(newSelectionModel);
              }}
              selectionModel={selectionModel}
              // rowsPerPageOptions={[5]}
              rowsPerPageOptions={[100]}/>
               : <section className="loader-section"><CircularProgress /></section>}

          </TabPanel>
          
          {/* <TabPanel value={tabValue} index={2} className="referrals-table grid">
            
          {(contacts?.length && !isLoading) ? <DataGrid
              className="subscription-grid-container"
              rows={contacts}
              columns={columnsContacts}
              // pageSize={5}
              // checkboxSelection
              // disables all advanced column sorting options available through menu 
              disableColumnMenu
              disableSelectionOnClick
              // onSelectionModelChange={(newSelectionModel) => {
              //   setSelectionModel(newSelectionModel);
              // }}
              // selectionModel={selectionModel}
              // rowsPerPageOptions={[5]}
              rowsPerPageOptions={[100]}
            /> : <section className="loader-section"><CircularProgress /></section>}

          </TabPanel> */}

        </Box>
      </div>

     
    </div>

  )
}


export default DataMining;
