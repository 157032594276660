import { Button } from '@mui/material'
import React, { useContext } from 'react'
import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import EditAssessmentSectionDrawer from '../Drawers/EditAssessmentSectionDrawer.jsx';

const EditAssessmentSection = (props) => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const drawerDetails = props.details;

  return (
    <div className="modal">
      {/* <p>Edit Section</p> */}

      <EditAssessmentSectionDrawer drawerDetails={drawerDetails} />

      <div className="footer">
        {/* <Button className="cancel-btn" style={{ margin: "-47px 0 15px", padding: "7px 15px" }} onClick={(e) => {handleCloseModal()}}>Cancel</Button> */}
        {/* <Button className="confirm-btn" onClick={() => {}}>Save</Button> */}
      </div>
    </div>
  )
}


export default EditAssessmentSection;
