import React, { useState, useEffect, useContext } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import { List } from '@material-ui/icons';

import UsersDrawer from './DetailsDrawer';
import DrawerContext from '../store/DrawerContext.js';

import '../App.scss';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
		color: "#747d8c"
	},
});


function showRegDate(date) {
	return date.substring(0, 10);
}

function showStatus(status) {
	if (status == 1) {
		return "active";
	}
	return "inactive";
}


function UsersTable(props) {

	const drawerCtx = useContext(DrawerContext);

	const classes = useStyles();
	const [isLoading, setIsLoading] = useState(true);
	const [loadedUsers, setUsersData] = useState([]);
	const [state, setState] = React.useState(false);
	
	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	useEffect(async () => {

		setIsLoading(true);
		fetch(process.env.REACT_APP_API_URI + '/v2/users', {
			method: 'GET',
			headers: { 'Content-Type': 'application/json' }
		})
		.then(response => {
			return response.json();
		}).then(data => {
			console.log(data);
			setIsLoading(false);
			setUsersData(data.data);
		});

	}, []);

	if (isLoading) {
		return <section><CircularProgress /></section>
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="simple table" data-aos="fade-up">
					<TableHead>
						<TableRow>
							<TableCell>Full Name</TableCell>
							<TableCell>Email</TableCell>
							<TableCell align="right">Registration Date</TableCell>
							<TableCell align="right">Status</TableCell>
							<TableCell align="right">&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{loadedUsers.map((data) => (
							<TableRow key={data.id}>
								<TableCell>{data.name}</TableCell>								
								<TableCell>{data.email}</TableCell>
								<TableCell align="right">&nbsp;</TableCell>
								<TableCell align="right">&nbsp;</TableCell>
								{/* <TableCell align="right">{showRegDate(data.registration_date)}</TableCell>
								<TableCell align="right">{showStatus(data.status)}</TableCell> */}
								<TableCell align="right"><MoreVertIcon className="table-tool" onClick={() => {toggleDrawerHandler('user-details', data)}} /></TableCell>
							</TableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			<UsersDrawer openTheDrawer={state}/>
		</>
	);
}

export default UsersTable;