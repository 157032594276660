import { useState, useEffect, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import ContentEditable from "react-contenteditable";

import { Button } from '@mui/material';
import { Add } from '@material-ui/icons';

import BuilderContext from '../../store/BuilderContext';

import AddVideo from "./Tools/AddVideo/AddVideo";
import HeadingWidget from "./Tools/HeadingWidget";
import ParagraphWidget from "./Tools/ParagraphWidget";
import { AccessTime } from "@mui/icons-material";


const Start = () => {


  const { id } = useParams();
  const builderCtx = useContext(BuilderContext);

  const { accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, contentBlocks, changeCurrentPage, showStartPageImgVidSection, setShowStartPageImgVidSection, startPageVideoURL, setStartPageVideoURL, startPageImageURL, setStartPageImageURL, lastUploadedMedia, setLastUploadedMedia } = builderCtx;

  const videoRef                            = useRef();
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [buttonStyles, setButtonStyles]     = useState();
  const [showImgOrVideo, setShowImgOrVideo] = useState("");
  const [selectedContentBlockId, setSelectedContentBlockId] = useState(0);
  
  const parentId = contentBlocks?.find(block => block?.contentType === "ContentContainer" && block?.page === "start")?.id;
  const startPageText = contentBlocks?.find(block => block?.contentType === "StartPageText");
  const startPageImgVideo = contentBlocks?.find(block => block?.contentType === "StartPageImgVideo");
  const startPageButton = contentBlocks?.find(block => block?.contentType === "button" && block?.page === "start");
  const startPageDuration = contentBlocks?.find(block => block?.contentType === "duration" && block?.page === "start");
  const startPageHeadingBlockID = contentBlocks?.find(block => block?.contentType === "heading" && block?.pageArea === "start-text-content")?.id;
  const startPageParagraphBlockID = contentBlocks?.find(block => block?.contentType === "paragraph" && block?.pageArea === "start-text-content")?.id;

  useEffect(() => {
    changeCurrentPage("start");
  }, []);
  
  useEffect(() => {
    if (startPageImgVideo?.properties?.video?.length) {
      setStartPageVideoURL(startPageImgVideo?.properties?.video);
    }
    if (startPageImgVideo?.properties?.image?.length) {
      setStartPageImageURL(startPageImgVideo?.properties?.image);
    }
  }, [startPageImgVideo?.properties?.video, startPageImgVideo?.properties?.image]);

  console.log("startPageVideoURL", startPageVideoURL);

  useEffect(() => {
    if (lastUploadedMedia?.type === "video") setShowImgOrVideo("video");
    else if (lastUploadedMedia?.type === "image") setShowImgOrVideo("image");
  }, [lastUploadedMedia?.type]);

  // handle video playback when switching between image and video or pages 
  useEffect(() => {
    if (isVideoPlaying) {
      videoRef?.current && videoRef?.current?.pause();
    }
    // pause the video when component unmounts
    return () => {
      videoRef?.current && videoRef?.current?.pause();
    };
  }, [showImgOrVideo]);

  const handleVideoClick = (e) => {
    // dont play video unless clicked on controls 
    e.preventDefault();
  };


  console.log("start contentBlocks", contentBlocks);

  const imgVidOverlay = (key) => <div key={key} className="upload" style={{ position: "absolute", backgroundColor: "transparent", zIndex: 2, maxWidth: "80%", maxHeight: "80%", width: "100%", height: "100%", top: "50%", left: "50%", transform: "translate(-52%, -52%)", boxSizing: "border-box" }}>
    <div>
      <h3>Click to Add a Video<br /> or an Image</h3>
    </div>
    <div className="image-or-remove">
      <Button variant="outlined" onClick={() => {
        setShowStartPageImgVidSection(false);
        if (videoRef?.current) videoRef?.current?.pause();
        let startPageButtonStyles = startPageButton.styling;
        startPageButton.styling = { ...startPageButtonStyles, marginLeft: "auto", }
      }}>Remove this block</Button>
    </div>
  </div>
   

  return <div className="start" style={ builderCtx.currentDeviceView == "Mobile" ? { flexDirection:"column" } : {}}>
    <div className="video-img-left" style={{...setStyle(4021), display: showStartPageImgVidSection ? "flex" : "none" }} onClick={(e) => accessToolsByPageArea("start-img-video-content", e)}>

      {!showImgOrVideo ? imgVidOverlay("empty")
         : showImgOrVideo === "video" ? <>
        {imgVidOverlay("video")}
        <video ref={videoRef} controls autoPlay={false}
        onClick={handleVideoClick}
        onPlay={() => setIsVideoPlaying(true)}
        onPause={() => setIsVideoPlaying(false)}
        onEnded={() => setIsVideoPlaying(false)}
        style={{ height: "100%", width: "auto", objectFit: "cover", overflow: "hidden", position: "relative" }} 
        >
          <source src={startPageVideoURL} type="" />
        </video>
      </> : showImgOrVideo === "image" ? <>
        {imgVidOverlay("image")}
          {/* {lastUploadedMedia?.url && <img src={lastUploadedMedia?.url} alt="img" style={{ width: "auto", height: "100%", maxHeight: "580px", maxWidth: "100%", objectFit: "cover", overflow: "hidden", position: "relative" }} />} */}
      </> : null}
        {/* {startPage?.thumbnailImg && <img className="thumbnail" src={startPage?.thumbnailImg} alt="thumbnail" />} */}

    </div>

    <div className="text-right" style={setSectionStyle("start-text-content")} onClick={(e) => accessToolsByPageArea("start-text-content", e)}>
      
      {builderCtx.contentBlocks.map((data, index) => {
        if (data.pageArea === "start-text-content" && data.parentContainer !== 0) {
          
          if (data.contentType === "heading") {
            return <div key={index} style={setStyle(data.id)} 
            onClick={(e) => accessToolsById(e, data.id)} 
            >
              <HeadingWidget key={startPageHeadingBlockID} blockId={startPageHeadingBlockID} className="editable-text" />
            </div>
          } else if (data.contentType === "paragraph") {
            return <div key={index} style={setStyle(data.id)} onClick={(e) => accessToolsById(e, data.id)} >
              <ParagraphWidget key={startPageParagraphBlockID} blockId={startPageParagraphBlockID} className="editable-text" />
            </div>
          } else if (data.contentType === "button") {
            return <div key={index} style={{ width: "100%", display: "flex", flexDirection: showStartPageImgVidSection ? "row" : "column", justifyContent: "space-between", alignItems: "center" }}
              // style={setStyle(data.id)} 
              // onClick={(e) => accessToolsById(e, data.id)} 
            >
              <button onClick={(e) => accessToolsById(e, data.id)} 
                style={startPageButton.styling}>
                {data.properties.text}
              </button>
              
              <div className="duration" onClick={(e) => accessToolsById(e, startPageDuration?.id)} style={startPageDuration?.styling}>
                <AccessTime />
                <ParagraphWidget key={startPageDuration?.id} blockId={startPageDuration?.id} className="editable-text" />
              </div>

            </div>
          }
          
        }
      })}


    </div>
  </div>;



  // return (
    // <div>
    //   <header>
    //     <div className="header-wrapper">
    //       <div className="logo logo-left" onClick={() => changeSection("leftLogo")}>
    //         <img src={navbarProps?.leftLogoImg} alt="logo" width={navbarProps?.leftLogo?.width} height={navbarProps?.leftLogo?.height} />
    //       </div>
    //       <div className="assessment-title desktop" id="assessment-title" onClick={() => changeSection("logoText")}>

    //         <EditableText classNameProps="logo-text" refProps={navbarProps?.logoText} textId={"navbar-logoText"} tagName="p" />

    //         <EditableText classNameProps="logo-subtext" refProps={navbarProps?.logoSubtext} textId={"navbar-logoSubtext"} tagName="p" />

    //       </div>
    //       <div className="logo logo-right" onClick={() => changeSection("rightLogo")}>
    //         <img src={navbarProps?.rightLogoImg} alt="logo" width={navbarProps?.rightLogo?.width} height={navbarProps?.rightLogo?.height} />
    //       </div>
    //     </div>
    //   </header>

    //   <div className="info-section" onClick={() => changeSection("info")}>

    //     <div className="video-img-left">

          {/* Check if video or thumbnail exists and show that instead */}

          {/* <video height="450px" width="100%">
            <source src={startPage?.vidURL} type="video/mp4" />
            <source src={startPage?.vidURL} type="video/webm" />
          </video> */}
          {/* <div className="upload">
            <Button onClick={() => { }}><Add className="add-icon" /></Button>
            <p className="upload-text">Upload Video</p>
            <div className="image-or-remove">
              <Button onClick={() => { }}>Add image only</Button>
              <Button onClick={() => { }}>Remove this block</Button>
            </div>
          </div>


          {startPage?.thumbnailImg && <img className="thumbnail" src={startPage?.thumbnailImg} alt="thumbnail" />}
        </div>
        <div className="text-right">

          <EditableText refProps={startPage?.header} textId={"start-header"} tagName="h2" />

          <EditableText refProps={startPage?.text} textId={"start-text"} tagName="p" />

          <Button className="get-started-cta">{startPage?.btnText || "Get Started"}</Button>
        </div>
      </div>
    </div>
  ) */}
}



export default Start;