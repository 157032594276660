import { useState, useEffect, useContext, Fragment } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { Tabs, Tab, Box, Button, Link, IconButton } from '@material-ui/core';
import { Clear, Close, Delete, Done, ExitToApp, Phone, Search, SearchOutlined, InfoOutlined } from '@mui/icons-material';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import GetAppIcon from '@mui/icons-material/GetApp';

import {
  DataGrid, useGridApiContext, gridFilterStateSelector,
  // GridSelectionModel, useGridApiRef, gridPaginatedVisibleSortedGridRowIdsSelector, gridPageSelector, gridPageCountSelector, useGridSelector, gridSortedRowIdsSelector, gridVisibleSortedRowIdsSelector, GridToolbar 
} from '@mui/x-data-grid';


import { CircularProgress, Pagination } from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import { zipToCityState } from '../zipcode_states_usa';

import AuthContext from '../store/AuthContext.js';
import ModalContext from '../store/ModalContext.js';
import UsersContext from '../store/UsersContext';
import DrawerContext from "../store/DrawerContext.js";
import DataGridContext from '../store/DataGridContext';
import AssessmentDashboardPage from './AssessmentDashboardPage';
import calendlyLogo from "../assets/img/calendly.svg";
import infoIcon from "../assets/img/infoIcon.svg";
import { getCentralDate } from '../globalFunctions';


const DataGridState = () => {
  const apiRef = useGridApiContext();
  const datagridCtx = useContext(DataGridContext);

  const filterState = gridFilterStateSelector(apiRef.current.state);
  // console.log("filterState", filterState?.filteredRowsLookup);
  console.log("filterState", filterState);

  useEffect(() => {
    let filteredIDs = filterState?.filteredRowsLookup;
    let filteredUserIDsArr = [];
    for (let key in filteredIDs) {
      if (filteredIDs[key] === true) {
        filteredUserIDsArr.push(parseInt(key))
      }
    }
    datagridCtx.updateFilteredDataGridUsers(filteredUserIDsArr);
    // console.log("userIDs", filteredUserIDsArr);
  }, [filterState, datagridCtx.tab])

  return null;
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} 
    aria-labelledby={`simple-tab-${index}`} {...other} >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const AssessmentPage = () => {

  // console.log(zipToCityState('99832'));
  // console.log(zipToCityState('11288'));

  const { id } = useParams();
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const usersCtx = useContext(UsersContext);
  const drawerCtx = useContext(DrawerContext);
  const datagridCtx = useContext(DataGridContext);

  // const [assessmentData, setAssessmentData] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isExporting, setIsExporting] = useState(false);
  const [title, setTitle] = useState("");
  const [allUsersDetails, setAllUsersDetails] = useState([]);
  const [assessDetails, setAssessDetails] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [allLeaders, setAllLeaders] = useState([]);
  const [allMembers, setAllMembers] = useState([]);
  const [filteredAllUsers, setFilteredAllUsers] = useState(allUsersDetails);
  const [filteredMembers, setFilteredMembers] = useState(allMembers);
  const [filteredLeaders, setFilteredLeaders] = useState(allLeaders);
  const [inviteDetails, setInviteDetails] = useState(null);

  // text that appears next to tabs 
  const [currentTabText, setCurrentTabText] = useState("Users");
  const [currentTabTextVal, setCurrentTabTextVal] = useState(allUsersDetails?.length);

  // Selected Rows in DataGrid 
  const [selectionModel, setSelectionModel] = useState([]);
  const [refreshPageToggle, setRefreshPageToggle] = useState(false);

  const [allUniqueChurches, setAllUniqueChurches] = useState([]);

  // to show question columns for all users with their answers 
  const [questions, setQuestions] = useState([]);

  const [datagridPage, setDatagridPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [totalMembers, setTotalMembers] = useState(0);
  const [totalLeaders, setTotalLeaders] = useState(0);
  // fetch page 2 users in the background after initial page 1 users have been fetched
  // const [fetchNextPageUsers, setFetchNextPageUsers] = useState(false);
  const [fetchNextPageUsers, setFetchNextPageUsers] = useState(false);
  const [fetchedAllUsers, setFetchedAllUsers] = useState(false);
  const [fetchingFirstPageUsers, setFetchingFirstPageUsers] = useState(true);

  // show org or church name on leaders and members tab for these assessment IDs 
  const assessmentsWithOrgNameOnLeadersTab = [16, 21, 22];
  const assessmentsWithOrgNameOnMembersTab = [21, 22];

  const assessmentsWhereMembersInheritLeadersOrgName = [21, 22];

  const [callRequestId, setCallRequestId] = useState('');


  useEffect(() => {
    usersCtx.setAllUsers(allUsersDetails);
    usersCtx.setAssessmentDetails(assessDetails);
    usersCtx.setAssessmentId(id);
  }, [allUsersDetails, assessDetails, id]);

  const updateUsersFromUsersContext = () => {
    setAllUsersDetails(usersCtx?.allUsers);
    setFilteredAllUsers(usersCtx?.allUsers);
  }

  useEffect(() => {
    if (usersCtx?.allUsers?.length < allUsersDetails?.length) {
      updateUsersFromUsersContext();
    }
  }, [usersCtx?.allUsers, usersCtx?.allUsers?.length]);

  useEffect(() => {
    if (id !== usersCtx?.assessmentId) {
      setAllUsersDetails([]);
      setFilteredAllUsers([]);
      setTotalRecords(0);
      setTotalLeaders(0);
      setTotalMembers(0);
      setFetchingFirstPageUsers(true);
      usersCtx.setAssessmentId(id);
    }
  }, [id, usersCtx?.assessmentId]);

  const updateCallRequest = (userid, currentStatus) => {
    console.log(callRequestId);
    console.log("currentCallStatus", currentStatus);
    // status is set between 1 and 2
    let callStatus = 0;
    if (currentStatus == 1) callStatus = 2;
    else if (currentStatus == 2) callStatus = 1;
    fetch(process.env.REACT_APP_API_URI + "/v1/assessment/" + userid + "/request-call", {
      method: "PUT",
      mode: "cors",
      headers: { "Content-Type": "application/json", Authorization: "Bearer " + localStorage.token },
      body: JSON.stringify({
        assessmentId: id,
        status: callStatus
      })
    })
      .then(response => response.json())
      .then((data) => {
        if (data.status === "success") {
          console.log(data);
          setAllUsersDetails(allUsersDetails.map(user => {
            if (user.userId === userid) {
              // console.log("user", user);
              user["callRequest"] = callStatus;
              return user;
            } else return user;
          }))
        } else {
          // setOpenSnackbar(true);
        }
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }

  const bulkDeleteUsers = () => {
    modalCtx.setDetails('users-bulk-delete-confirmation', { users: selectionModel, setRefreshPageToggle: setRefreshPageToggle, setAllUsersDetails: setAllUsersDetails });
    modalCtx.openModal();
    console.log("UserIDs to delete:\n", selectionModel);
  }

  // console.log("allUsersDetails", allUsersDetails);

  const assessmentDetails = {
    mainAssessmentId: id,
    hasAdditional: assessDetails.hasAdditional,
    assessmentType: assessDetails?.assessmentType || "",
    url: assessDetails?.url || "",
    assessmentTitle: assessDetails.title,
  };

  const getUserCell = (params) => {
    // console.log("params.row", params.row);
    // console.log("userType:", params.row.userType);
    let userDetails = {
      userInfo: params.row,
      mainAssessmentId: id,
      hasAdditional: assessDetails.hasAdditional,
      assessmentType: assessDetails?.assessmentType || "",
      url: assessDetails?.url || "",
      assessmentTitle: assessDetails.title,
      tabValue: 0
    }
    let capitalizedName = capitalizeWords(params.value);

    if (params.row.name?.trim()?.toLowerCase() === "assessment leader") {
      return <>
        <Link className={"user-info-link " + params.row?.userType} style={{ color: "#de4a4a" }} onClick={() => { toggleDrawerHandler('user-details', params.row?.id); }}>{getHighlightedText(capitalizedName, searchTerm)}</Link>
        &nbsp;<img src={infoIcon} alt='infoicon' style={{ fontSize: "21px", cursor: "pointer" }} onClick={() => {
          toggleModalHandler("show-assessment-leader-info-modal", params.row);
        }} />
      </>
    }

    else return <>
      {/* <Link className={"user-info-link " + params.row?.userType} onClick={() => {toggleDrawerHandler('user-details', params.row?.id);}}>{params.value}</Link>  */}
      <Link className={"user-info-link " + params.row?.userType} onClick={() => { toggleDrawerHandler('user-details', params.row?.id); }}>{getHighlightedText(capitalizedName, searchTerm)}</Link>

      {/* { params.row.callRequest > 0 ? <Link onClick={() => setCallRequestId(params.row.uniqueId)}><PhoneIcon color="success" /></Link> : null } */}
    </>

  }

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  const getDateISO = (createdAt) => new Date(createdAt.replace(/ /g, "T") + "Z").toISOString();

  const centerHeader = { headerClassName: "grid-header", headerAlign: "center", align: "center" };
  const leftHeader = { headerClassName: "grid-header", headerAlign: "start", align: "center" };
  const centerAlign = { headerAlign: "center", align: "center" };
  const leftAlign = { headerAlign: "start", align: "center" }

  const callBtnClasses = (callCurrentStatus) => {
    switch (callCurrentStatus) {
      case 0: return "hide call-btn";
      case 1: return "blue call-btn";
      case 2: return "done call-btn";
      default: return "call-btn";
    }
  }

  const showCallBtn = (row) => {
    // console.log("row", row); console.log("call request ID:", row.userId);
    let callReq = row.callRequest;
    let callClasses = callBtnClasses(row.callRequest);
    // return callReq === 1 ? 
    //   (<Phone className={callClasses} onClick={() => updateCallRequest(row.userId, row.callRequest)} />) 
    //   : callReq === 2 ? 
    //   (<Done className={callClasses} onClick={() => updateCallRequest(row.userId, row.callRequest)} />) 
    //   : null
    return callReq > 0 &&
      (<Done className={callClasses} onClick={() => updateCallRequest(row.userId, row.callRequest)} />);
  }

  const showCalendlyBtn = (row) => {
    // let callDetails = row.callDetails;
    return (<a href="#" className="calendly" style={{ textDecoration: "none", lineHeight: 1 }} onClick={() => toggleDrawerHandler('calendly-details', { ...row })}> <img src={calendlyLogo} height="22px" width="auto" maxWidth="80px" alt="calendly logo" /></a>);
  }

  const colName = assessDetails?.assessmentType === "team" ? "Leader Name" : "Referrer Name";

  const SeeMoreBtn = (props) => <Button className="see-more-btn" onClick={() => {
    // console.log("seemorebtn props", props);
    toggleDrawerHandler('user-details', props.row?.id);
  }}>SEE MORE&nbsp;<ExitToApp /></Button>


  let showCallColumn = assessDetails?.hasRequestCall === "yes" ? true : false;
  let showCalendlyColumn = false;
  let showOrgColumnForLeaders = assessmentsWithOrgNameOnLeadersTab?.includes(parseInt(id));
  let showOrgColumnForMembrs = assessmentsWithOrgNameOnMembersTab?.includes(parseInt(id));
  let allJobOptions = new Set();
  let allZipCodeOptionsSet = new Set();
  let allCallOptions = ["Call Requested", "None"];
  let allCalendlyOptions = ["Calendly", "None"];
  allUsersDetails?.forEach((u) => {
    u.churchName = capitalizeWords(u.churchName.trim());
    if (u.jobTitle.trim()) allJobOptions.add(u.jobTitle.trim());
    if (u.zipCode.trim()) {
      // by default consider country to be US so get city state even if country === ""
      if ((u.country?.toLowerCase().trim() === "united states") || (u.country === "")) {
        allZipCodeOptionsSet.add(JSON.stringify(["US", u.zipCode.toString()]));
      } else {
        allZipCodeOptionsSet.add(JSON.stringify(["Non-US", u.zipCode.toString()]));
      }
    }
    if (u.callCalendly === "yes") { u.callInfo = "Calendly"; showCalendlyColumn = true; }
    if (u.callRequest) u.call = "Call Requested";
    else { u.callInfo = "None"; u.call = "None"; }
  });
  allJobOptions = [...allJobOptions];

  let allZipCodeOptions = Array.from(allZipCodeOptionsSet).map((zipCodeOptionString) => JSON.parse(zipCodeOptionString));
  allZipCodeOptionsSet = null;

  // console.log("allZipCodeOptions", allZipCodeOptions);

  let allZipWithNameOptions = allZipCodeOptions?.map(z => {
    if (z[0] === "US") {
      let stateName = zipToCityState(z[1].toString());
      if (stateName?.length) return ({ value: z[1].trim(), label: `${stateName}, ${z[1].toString().trim()}` });
      else return ({ value: z[1].trim(), label: z[1].toString().trim() });
    } else {
      return ({ value: z[1].trim(), label: z[1].toString().trim() });
    }
  });

  // console.log("allUsersDetails", allUsersDetails); console.log("allCallOptions", allCallOptions);
  // console.log("allJobOptions", allJobOptions); console.log("allZipCodeOptions", allZipCodeOptions);
  // console.log("allUniqueChurches", allUniqueChurches); console.log("allZipWithNameOptions", allZipWithNameOptions); 

  // is either a church or an organization
  let isChurch = filteredLeaders?.some((leader) => leader?.churchName?.length > 0);

  let columnsAllUsers = [
    // { field: 'id', headerName: 'ID', width: 70 },
    { field: 'date', headerName: 'Date', ...leftHeader, width: 100, type: "date", renderCell: (params) => getCentralDate(params.row.createdAt) },
    { field: 'name', headerName: 'Name', ...leftHeader, width: 210, renderCell: (params) => getUserCell(params) },
    {
      field: 'jobTitle', headerName: 'Job Title', ...leftHeader, width: 280, type: "singleSelect", valueOptions: allJobOptions, renderCell: (params) => {
        let capitalizedName = capitalizeWords(params.row?.jobTitle);
        return <div title={params.row?.jobTitle} className="MuiDataGrid-cellContent">{getHighlightedText(capitalizedName, searchTerm) || ""}</div>
      }
    },
    {
      field: isChurch ? 'churchName' : 'orgName', headerName: isChurch ? 'Church' : 'Organization', ...leftHeader, width: 180, type: "singleSelect", valueOptions: allUniqueChurches, renderCell: (params) => {
        return <div title={isChurch ? params.row?.churchName : params.row.orgName} className="MuiDataGrid-cellContent">{isChurch ? getHighlightedText(params.row?.churchName, searchTerm) || "" : getHighlightedText(params.row?.orgName, searchTerm) || ""}</div>;
      }
    },
    {
      field: 'zipCode', headerName: 'State', ...leftHeader, width: 120, type: "singleSelect", valueOptions: allZipWithNameOptions, renderCell: (params) => { // console.log("zipCode", params.row);
        return params.row.stateShort || params.row.zipCode;
      }
    },
    {
      field: "call", headerName: "Call", ...leftHeader, width: 100, type: "singleSelect", valueOptions: allCallOptions,
      sortable: true, renderCell: (params) => {
        // console.log("callDetails", params.row); if(params.row.callCalendly === "yes") return showCalendlyBtn(params?.row)
        return showCallBtn(params?.row)
      }
    },
    {
      field: "callInfo", headerName: "Calendly", ...leftHeader, width: 100, type: "singleSelect", valueOptions: allCalendlyOptions, sortable: true, renderCell: (params) => { // console.log("callInfo", params.row);
        if (params.row.callCalendly === "yes") return showCalendlyBtn(params?.row);
      }
    },
    {
      field: "status", headerName: 'Assessment Completion', width: 250, ...leftAlign, type: "singleSelect",
      valueOptions: ["Pending", "Completed"], renderCell: (params) => <div style={{ color: params.row?.status == "Pending" ? "orange" : "green" }} title={params.row?.status}
        className="MuiDataGrid-cellContent">{getHighlightedText(params.row?.status, searchTerm) || ""}</div>
    },
    // { field: 'appointment', headerName: 'Appointment', ...centerHeader, width: 210, renderCell: (params) => "Oct 27, 22" },
    // { field: "actions", headerName: 'Actions', ...centerHeader, width: 180, sortable: false, renderCell: (params) => <SeeMoreBtn row={params.row} /> },
  ];

  // these changes are only for ChurchOS. change this id === 11 check later to something based on API that tells whether to show questions columns for the assessment in datagrid or not 
  // TODO: fetch assessment details from api instead of doing it like this 
  if (id.toString() === "11") {
    console.log("ChurchOS Assessment");
    let questionsAnswers = [];
    let questionsLen = 0;
    allUsersDetails?.forEach(u => {
      if (u.assessmentId) {
        questionsLen = u?.answers?.length;
        u?.answers?.forEach((a, idx) => {
          u[`q${idx + 1}`] = { answer: a.answer || "" };
        })
      }
    });
    for (let i = 0; i < questionsLen; i++) {
      questionsAnswers.push(({
        field: `q${i + 1}`, headerName: `Q${i + 1}`, ...leftHeader, width: 150,
        renderCell: ({ row }) => { // console.log(row);
          let answer = row[`q${i + 1}`]?.answer;
          switch (row[`q${i + 1}`]?.answer) {
            case 1:
              return <p className="color-coded-blocks" style={{ backgroundColor: "#D55D53" }} title="Broken">Broken</p>
            case 3:
              return <p className="color-coded-blocks" style={{ backgroundColor: "#F7CC4F" }} title="Needs Improvement">Needs Improvement</p>
            case 6:
              return <p className="color-coded-blocks" style={{ backgroundColor: "#73B76F" }} title="Activating Impact">Activating Impact</p>
            default:
              return row[`q${i + 1}`]?.answer || "";
          }
        }
      }))
    }
    // console.log("questionsLen", questionsLen); console.log("questionsAnswers", questionsAnswers);
    columnsAllUsers.push(...questionsAnswers);
  }

  const columnsLeaders = assessDetails.assessmentType === 'team' ?
    [
      // { field: 'id', headerName: 'ID', width: 70 }, { field: 'userId', headerName: 'User ID', width: 100 },
      { field: 'date', headerName: 'Date', width: 120, ...leftAlign, type: "date", renderCell: (params) => getCentralDate(params.row.createdAt) },
      {
        field: 'name', headerName: 'Name', width: 220, ...leftAlign,
        renderCell: (params) => {
          // console.log("params.row", params.row);
          let capitalizedName = capitalizeWords(params.value);
          return <Link className="user-info-link" onClick={() => {
            toggleDrawerHandler('user-details', params.row?.id);
          }}>{getHighlightedText(capitalizedName, searchTerm)}</Link>
        }
      },
      { field: 'memberCount', headerName: 'Team Members Invited', width: 240, ...leftAlign },
      { field: 'memberCompleted', headerName: 'Assessment Completions', width: 270, ...leftAlign },
      {
        field: isChurch ? 'churchName' : 'orgName', headerName: isChurch ? 'Church' : 'Organization', width: 320, ...leftAlign, renderCell: (params) => {
          return <div title={isChurch ? params.row?.churchName : params.row?.orgName} className="MuiDataGrid-cellContent">{isChurch ? getHighlightedText(params.row?.churchName, searchTerm) || "" : getHighlightedText(params.row?.orgName, searchTerm) || ""}</div>;
        }
      },
      // { field: "actions", headerName: 'Actions', width: 160, ...leftAlign, sortable: false, renderCell: (params) => <SeeMoreBtn row={params.row} userType="leader" /> },
    ] :
    [
      // { field: 'id', headerName: 'ID', width: 70 }, { field: 'userId', headerName: 'User ID', width: 100 },
      { field: 'date', headerName: 'Date', width: 120, ...leftAlign, type: "date", renderCell: (params) => getCentralDate(params.row.createdAt) },
      {
        field: 'name', headerName: 'Name', width: 220, ...leftAlign,
        renderCell: (params) => {
          let capitalizedName = capitalizeWords(params.value);
          return <Link className="user-info-link" onClick={() => {
            toggleDrawerHandler('user-details', params.row?.id);
          }}>{getHighlightedText(capitalizedName, searchTerm)}</Link>
        }
      },
      { field: 'memberCount', headerName: 'Referrals Invited', width: 180, ...leftAlign },
      { field: 'memberCompleted', headerName: 'Assessment Completions', width: 270, ...leftAlign },
      {
        field: isChurch ? 'churchName' : 'orgName', headerName: isChurch ? 'Church' : 'Organization', width: 320, ...leftAlign, renderCell: (params) => {
          return <div title={isChurch ? params.row?.churchName : params.row?.orgName} className="MuiDataGrid-cellContent">{isChurch ? getHighlightedText(params.row?.churchName, searchTerm) || "" : getHighlightedText(params.row?.orgName, searchTerm) || ""}</div>;
        }
      },
      // { field: "actions", headerName: 'Actions', width: 160, ...leftAlign, sortable: false, renderCell: (params) => <SeeMoreBtn row={params.row} userType="leader" /> },
    ]

  const columnsMembers = [
    // { field: 'id', headerName: 'ID', width: 70 },
    // { field: 'userId', headerName: 'User ID', width: 100 },
    { field: 'date', headerName: 'Date', width: 120, ...leftAlign, type: "date", renderCell: (params) => getCentralDate(params.row.createdAt) },
    {
      field: 'name', headerName: 'Name', width: 220, ...leftAlign,
      renderCell: (params) => {
        let capitalizedName = capitalizeWords(params.value);
        return <Link className="user-info-link" onClick={() => {
          // let referrals = allLeaders?.find(leader => leader?.id === params?.row?.parentUserId);
          toggleDrawerHandler('user-details', params.row?.id);
        }}>{getHighlightedText(capitalizedName, searchTerm)}</Link>
      }
    },
    {
      field: 'leader', headerName: colName, width: 250, ...leftAlign,
      renderCell: (params) => {
        // console.log("row", params.row);
        return getHighlightedText(params.row?.leaderInfo?.name, searchTerm) || getHighlightedText(params.row?.leader, searchTerm) || ""
      }
    },
    { field: 'status', headerName: 'Assessment Completion', width: 250, ...leftAlign, renderCell: (params) => <div title={params.row?.status} className="MuiDataGrid-cellContent">{getHighlightedText(params.row?.status, searchTerm) || ""}</div> },
    {
      field: 'churchName', headerName: 'Church', width: 320, ...leftAlign, renderCell: (params) => {
        let orgName = assessmentsWhereMembersInheritLeadersOrgName?.includes(parseInt(id)) ? params.row?.leaderInfo?.churchName : params.row?.churchName || params.row.orgName;
        return <div title={orgName} className="MuiDataGrid-cellContent">{orgName}</div>;
      }
    },
    // { field: "actions", headerName: 'Actions', width: 160, ...leftAlign, sortable: false, renderCell: (params) => <SeeMoreBtn row={params.row} userType="member" /> },
  ];

  const handleTabChange = (event, newValue) => {
    // console.log("newValue", newValue);
    let tab = "Users";
    let filteredDataGridUsers;
    setSelectionModel([]);

    switch (newValue) {
      case 1:
        tab = "Leaders";
        filteredDataGridUsers = allLeaders;
        break;
      case 2:
        tab = assessDetails.assessmentType === "team" ? "Members" : "Referrals";
        filteredDataGridUsers = allMembers;
        break;
      case 3:
        tab = "Dashboard";
        filteredDataGridUsers = allUsersDetails;
        break;
      default:
        tab = "Users";
        filteredDataGridUsers = allUsersDetails;
        break;
    }

    datagridCtx.updateFilteredDataGridUsers(filteredDataGridUsers);
    datagridCtx.updateTabName(tab);
    setTabValue(newValue);
    // setSearchTerm("");
  };

  useEffect(() => {
    // console.log("useEffect ran");
    switch (tabValue) {
      case 0:
        setCurrentTabText("Contacts");
        setCurrentTabTextVal(totalRecords);
        break;
      case 1:
        setCurrentTabText("Leaders");
        setCurrentTabTextVal(totalLeaders);
        break;
      case 2:
        setCurrentTabText(assessDetails.assessmentType === "team" ? " Team Members" : "Referrals");
        setCurrentTabTextVal(allMembers?.length);
        break;
      case 3:
        setCurrentTabText("Users");
        setCurrentTabTextVal(totalRecords);
        break;
      default:
        setCurrentTabText("Users");
        setCurrentTabTextVal(totalRecords);
        break;
    }
  }, [allUsersDetails?.length, totalRecords, totalLeaders, totalMembers, allMembers?.length, tabValue, assessDetails.assessmentType])

  const envURI = process.env.REACT_APP_API_URI;

  const getAssessmentDetails = () => {
    let options = {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    fetch(`${envURI}/v1/admin/assessment/${id}`, options)
      .then(response => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          setTitle(data?.details?.title);
          setAssessDetails(data?.details);
        }
        setIsLoading(false);
      }).catch((err) => {
        console.log("Failed to fetch assessment details...", err);
        setIsLoading(false);
      })

  }

  useEffect(() => {
    if (totalRecords === allUsersDetails?.length) {
      setFetchedAllUsers(true);
    } else setFetchedAllUsers(false);
  }, [totalPages, totalRecords, allUsersDetails]);

  const getUsers = async (page, fetchInBackground) => {

    if (id !== usersCtx?.assessmentId) {
      setAllUsersDetails([]);
      setFilteredAllUsers([]);
    }

    try {
      // this resets users to 0 so when assessment ID changes, page does not show previous assessment users
      // setAllUsersDetails([]);
      const options = {
        method: 'GET', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      };
      const fetchUsersURL = `${envURI}/v3/admin/assessment/${id}/statistics/allusers?page=${page}`;
      const response = await fetch(fetchUsersURL, options);
      const data = await response.json();

      console.log("/v3/allusers data", data);
      if (data.status === "success") {
        if (data.currentPage >= 1) {
          setFetchingFirstPageUsers(false);
        }
        if (id === usersCtx.assessmentId) {
          // console.log("users", data?.users);
          const users = [...allUsersDetails, ...data?.users];
          const uniqueAllUsers = users?.filter((user, index) => {
            return users.findIndex((u) => u.id === user.id) === index;
          });
          setAllUsersDetails(uniqueAllUsers);
        } else {
          setAllUsersDetails(data?.users);
        }
        setInviteDetails(data?.details);
        setTotalPages(data?.totalPages);
        setCurrentPage(data?.currentPage);
        setNextPage(data?.currentPage + 1);
        setTotalRecords(data?.totalRecords);
        setTotalLeaders(data?.totalLeaders);
        setTotalMembers(data?.totalMembers);
        if (data?.currentPage >= data?.totalPages) setFetchNextPageUsers(false);
        else setFetchNextPageUsers(true);
        return data;
      }
    } catch (err) {
      console.error("Failed to fetch users data...", err);
      setFetchingFirstPageUsers(false);
      if (id !== usersCtx.assessmentId) {
        setAllUsersDetails([]);
      }
      return null;
    }
  }

  console.log("\n\nallUsersDetails", allUsersDetails);
  console.log("filteredAllUsers", filteredAllUsers, "\n\n");

  useEffect(() => {
    setAllUsersDetails([]);
    setFilteredAllUsers([]);
    usersCtx.setAllUsers([]);
    usersCtx.setAssessmentId(id);
    const fetchInBackground = false;
    // getUsers(currentPage, fetchInBackground);
    setCurrentPage(1);
    getUsers(1, fetchInBackground);
    getAssessmentDetails();
  }, [id]);

  useEffect(() => {
    if (fetchNextPageUsers) {
      const fetchInBackground = true;
      getUsers(nextPage, fetchInBackground);
    }
  }, [fetchNextPageUsers, nextPage]);

  useEffect(() => {
    if (!totalPages || (currentPage >= totalPages)) {
      setFetchNextPageUsers(false);
    }
  }, [currentPage, totalPages]);


  useEffect(() => {
    if (modalCtx.pageUpdate?.page === "assessmentsUsers") {
      console.log("\n\nPage update requested. Refetching all users...");
      const fetchInBackground = false;
      // getUsers(currentPage, fetchInBackground);
      setAllUsersDetails([]);
      setFilteredAllUsers([]);
      setCurrentPage(1);
      getUsers(1, fetchInBackground);
      modalCtx.setPageUpdate({ page: "" });
      // setSelectionModel([]);
      setSearchTerm("");
    } else {
      console.log("No page update requests detected...");
    }
  }, [modalCtx, modalCtx.pageUpdate, modalCtx.pageUpdate?.page]);


  useEffect(() => {
    setAllMembers(allUsersDetails?.filter(u => u.userType === "member"));
  }, [allUsersDetails, allUsersDetails?.length, refreshPageToggle]);

  // console.log(allUsersDetails);
  useEffect(() => {
    if (allUsersDetails?.length >= 0) {
      let getAllLeaders = allUsersDetails?.filter(u => u.userType === "leader");
      if (allMembers?.length >= 0) {
        setAllLeaders(getAllLeaders?.map(leader => {
          let referralsArr = allMembers?.filter(mem => mem.parentUserId === leader.id);
          return { ...leader, referrals: referralsArr }
        }))
      } else {
        setAllLeaders(getAllLeaders);
        console.log("No members to add in leaders referrals");
      }
    }
  }, [allMembers, allMembers?.length, allUsersDetails, allUsersDetails?.length, refreshPageToggle, id]);

  // console.log("allUsersDetails", allUsersDetails);

  useEffect(() => {
    console.log("useEffect allUsersDetails");
    if (allUsersDetails?.length >= 0) {
      setAllUsersDetails(allUsersDetails?.map(user => {
        let referralsArr = [];
        let leaderInfo = [];
        if (user.userType === "leader") {
          referralsArr = allMembers?.filter(mem => mem.parentUserId === user.id);
        } else {
          leaderInfo = allLeaders?.find(lead => lead.id === user.parentUserId);
        }
        let assessmentStatus = user.assessmentCompleted === "yes" ? "Completed" : "Pending";
        let cityState = [];
        // by default consider country to be US so get city state even if country === ""
        if (user.country.toLowerCase().trim() === "united states" || user.country === "") {
          cityState = zipToCityState(user?.zipCode?.toString());
        }
        return ({
          ...user, date: getDateISO(user.createdAt),
          referrals: referralsArr,
          leaderInfo: leaderInfo, status: assessmentStatus,
          city: cityState[0] || "",
          state: cityState[1] || "",
          stateShort: cityState[2] || "",
        })
      }))
    }
  }, [allMembers?.length, allLeaders?.length, allUsersDetails?.length, refreshPageToggle, isLoading, id]);

  useEffect(() => {
    // console.log("useEffect ran");
    const debounceTimer = setTimeout(() => {
      if (searchTerm) {
        const filterAllUsersData = (term) => {
          return allUsersDetails?.filter((data) => {
            let search = Object.values(data)?.join('')?.toLowerCase()?.includes(term?.toLowerCase());
            // console.log(search);
            return search;
          })
        }
        const filterLeadersData = (term) => {
          return allLeaders?.filter((data) => {
            return Object.values(data)?.join('')?.toLowerCase()?.includes(term?.toLowerCase());
          })
        }
        const filterMembersData = (term) => {
          return allMembers?.filter((data) => {
            return Object.values(data)?.join('')?.toLowerCase()?.includes(term?.toLowerCase());
          })
        }
        setFilteredAllUsers(filterAllUsersData(searchTerm));
        setFilteredLeaders(filterLeadersData(searchTerm));
        setFilteredMembers(filterMembersData(searchTerm));
        // console.log("filteredUsers", filterAllUsersData(searchTerm));
        // console.log("filteredLeaders", filterLeadersData(searchTerm));
        // console.log("filteredMembers", filterMembersData(searchTerm));
      } else {
        setFilteredAllUsers(allUsersDetails);
        setFilteredMembers(allMembers);
        setFilteredLeaders(allLeaders);
      }
    }, 500);
    return () => clearTimeout(debounceTimer);
  }, [allUsersDetails, allUsersDetails?.length, allMembers, allMembers?.length, allLeaders, searchTerm, refreshPageToggle, id]);


  // conditional for which export CSV button to show depending on filters & active tabs 
  let showFilteredExportBtn = false;
  switch (datagridCtx.tab) {
    case "Users":
      if (allUsersDetails?.length === datagridCtx.filteredDataGridUsers?.length) {
        showFilteredExportBtn = false; break;
      } else showFilteredExportBtn = true; break;
    case "Leaders": showFilteredExportBtn = true; break;
    case "Referrals": showFilteredExportBtn = true; break;
    case "Members": showFilteredExportBtn = true; break;
    case "Dashboard": showFilteredExportBtn = false; break;
    default: showFilteredExportBtn = false; break;
  }
  console.log("\n\ntab change", datagridCtx.tab, datagridCtx.filteredDataGridUsers, "\n\n");

  const exportCSV = () => {

    setIsExporting(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessment/' + id + '/export', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({ users: allUsersDetails }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsExporting(false);
        if (data.status === "success") {
          window.location.href = data.exportURL
        } else {
          console.log("failed to export CSV");
        }
      })
      .catch(err => {
        if (err) {
          console.log(err);
          setIsExporting(false);
        }
      });

  }

  let filteredUsers = [];
  for (let i = 0; i < allUsersDetails?.length; i++) {
    if (datagridCtx.filteredDataGridUsers?.includes(allUsersDetails[i].id)) {
      filteredUsers.push(allUsersDetails[i]);
    }
  }

  const exportFilteredCSV = () => {

    setIsExporting(true);
    let filenameSuffix = "_users.csv";
    let filteredUsers = [];

    switch (datagridCtx.tab) {
      case "Users":
        if (totalRecords === datagridCtx.filteredDataGridUsers.length) filenameSuffix = "_all_contacts.csv";
        else filenameSuffix = "_contacts.csv";
        filteredUsers = filteredAllUsers;

        break;
      case "Leaders":
        if (totalLeaders === datagridCtx.filteredDataGridUsers.length) filenameSuffix = "_all_leaders.csv";
        else filenameSuffix = "_leaders.csv";
        filteredUsers = filteredLeaders;
        break;
      case "Members":
        if (totalMembers === datagridCtx.filteredDataGridUsers.length) filenameSuffix = "_all_members.csv";
        else filenameSuffix = "_members.csv";
        filteredUsers = filteredMembers;
        break;
      default:
        filenameSuffix = "_users.csv";
        break;
    }

    if (selectionModel?.length > 0) {
      filteredUsers = allUsersDetails?.filter(user => {
        return selectionModel.includes(user.id);
      });
    }
    else if (datagridCtx.filteredDataGridUsers.length > 0) {
      filteredUsers = allUsersDetails?.filter(user => {
        return datagridCtx.filteredDataGridUsers.includes(user.id)
      });
    }

    console.log("export filteredUsers", filteredUsers);
    let exportUsers = [];
    filteredUsers.forEach(user => {
      user.answers = [];
      exportUsers.push(user);
    });

    console.log(exportUsers);

    const userIDsToExport = filteredUsers?.map((user) => ({ id: user.id }));

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/exportFiltered', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      // body: JSON.stringify({ users: exportUsers, filenameSuffix: filenameSuffix }),
      body: JSON.stringify({ users: userIDsToExport, filenameSuffix: filenameSuffix }),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setIsExporting(false);
        if (data.status === "success") {
          window.location.href = data.exportURL
          console.log("exported members CSV");
        } else {
          console.log("failed to export members CSV");
        }
      })
      .catch((err) => {
        console.error("failed to export members CSV", err);
        setIsExporting(false);
      });
  };

  // console.log("GridSelectionModel", selectionModel);
  // console.log("datagridCtx", datagridCtx); console.log("datagridCtx.filteredDataGridUsers", datagridCtx.filteredDataGridUsers); 
  // console.log("allMembers", allMembers); 
  // console.log("allUsersDetails", allUsersDetails); 

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  const showSearchBar = () => (
    <div className="search-bar">
      <input type="search" name="" id="search-field" className="search-field" placeholder="Type to search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      {searchTerm && <Clear className="clear-search-icon" onClick={() => setSearchTerm("")} />}
    </div>
  );


  // useEffect(() => {
  //   let allChurches = [];
  //   for (let i = 0; i < allUsersDetails?.length; i++) {
  //     if(allUsersDetails[i].churchName && !allChurches.includes(allUsersDetails[i].churchName.trim())) {
  //       allChurches.push(allUsersDetails[i].churchName.trim());
  //     }
  //   }

  //   let uniqueChurches = [];
  //   allChurches.forEach(element => {
  //     if (!(uniqueChurches.includes(element.toLowerCase().trim()) || uniqueChurches.includes(element.trim()))) {
  //       uniqueChurches.push(element.trim());
  //     }
  //   });

  //   uniqueChurches?.sort((a, b) => a?.localeCompare(b));
  //   // console.log("uniqueChurches", uniqueChurches);

  //   setAllUniqueChurches(uniqueChurches);

  // }, [allUsersDetails]);

  useEffect(() => {

    const uniqueChurches = new Set();
    allUsersDetails?.forEach(user => {
      if (user.churchName) {
        uniqueChurches.add(user.churchName.trim());
      }
    });

    const sortedUniqueChurches = Array.from(uniqueChurches).sort((a, b) => a.localeCompare(b));
    setAllUniqueChurches(sortedUniqueChurches);

  }, [allUsersDetails]);

  // console.log("leadersMembersStats", leadersMembersStats);

  const [statsCards, setStatsCards] = useState([]);
  const [statsCardsMembers, setStatsCardsMembers] = useState([]);

  useEffect(() => {
    const invitedMembersPercent = (Math.round(((inviteDetails?.invited) / (inviteDetails?.invited + inviteDetails?.notInvited)).toFixed(2) * 100).toString()) + "%";

    const leadersMembersStatsFunc = () => {
      // invited members completion rates 
      let completionLessThan100 = 0;
      let completionEqualTo100 = 0;
      let completionEqualTo0 = 0;
      let membersWhoDidNotInvite = 0;

      for (let i = 0; i < allLeaders?.length; i++) {
        const leader = allLeaders?.[i];
        if (leader?.memberCount === 0) {
          membersWhoDidNotInvite += 1;
        }
        else if (leader?.memberCount > 0 && leader?.memberCompleted === 0) {
          completionEqualTo0 += 1;
        }
        else if (leader?.memberCount > 0 && (leader?.memberCompleted < leader?.memberCount)) {
          completionLessThan100 += 1;
        }
        else if (leader?.memberCount > 0 && (leader?.memberCompleted === leader?.memberCount)) {
          completionEqualTo100 += 1;
        }
      }
      return { completionEqualTo0, completionLessThan100, completionEqualTo100, membersWhoDidNotInvite }
    };
    const leadersMembersStats = leadersMembersStatsFunc();

    let invitedText = assessDetails?.assessmentType === "team" ? "Invited team members" : <>Invited<br />referrals</>;
    let compRateLessThan100 = assessDetails?.assessmentType === "team" ? "< 100% member completion rate" : "< 100% referral completion rate";
    let compRateEqual100 = assessDetails?.assessmentType === "team" ? "100% member completion rate" : "100% referral completion rate";
    let compRate0 = assessDetails?.assessmentType === "team" ? "0% member completion rate" : "0% referral completion rate";
    let didNotRefer = assessDetails?.assessmentType === "team" ? "Did not invite team members" : "Did not refer team members";

    setStatsCards(
      [
        { num: totalLeaders, num2: totalLeaders, text: "Total leaders", filter: "show-all", toggle: false },
        { num: inviteDetails?.invited, num2: invitedMembersPercent, text: invitedText, filter: "invited", toggle: false },
        { num: inviteDetails?.notInvited, num2: inviteDetails?.invited, text: didNotRefer, filter: "did-not-invite", toggle: false },
        { num: leadersMembersStats?.completionEqualTo100 || 0, num2: inviteDetails?.invited, text: compRateEqual100, filter: "equal-to-100", toggle: false },
        { num: leadersMembersStats?.completionLessThan100 || 0, num2: inviteDetails?.invited, text: compRateLessThan100, filter: "less-than-100", toggle: false },
        { num: leadersMembersStats?.completionEqualTo0, num2: inviteDetails?.invited, text: compRate0, filter: "equal-to-0", toggle: false },

      ]
    );
  }, [totalLeaders, allMembers, allMembers?.length, assessDetails?.assessmentType, inviteDetails?.invited, inviteDetails?.notInvited]);

  useEffect(() => {
    let membersWhoCompleted = 0;
    let membersWhoDidntComplete = 0;
    allMembers?.forEach(m => {
      if (m.assessmentCompleted === "no") membersWhoDidntComplete += 1;
      else if (m.assessmentCompleted === "yes") membersWhoCompleted += 1;
    })
    let totalText = assessDetails?.assessmentType === "team" ? <>Total team<br />members</> : <>Total<br />referrals</>;
    setStatsCardsMembers([
      { num: allMembers?.length, text: totalText, filter: "show-all", toggle: false },
      { num: membersWhoCompleted, text: <>Assessments<br />completed</>, filter: "show-complete", toggle: false },
      { num: membersWhoDidntComplete, text: <>Assessments<br />pending</>, filter: "show-incomplete", toggle: false },
    ])
  }, [allMembers, allMembers?.length, assessDetails?.assessmentType])


  useEffect(() => {
    const isFalse = (val) => val.toggle === false;
    let allTogglesOffLeaders = statsCards?.every(isFalse);
    let allTogglesOffMembers = statsCardsMembers?.every(isFalse);
    if (!searchTerm && allTogglesOffLeaders) setFilteredLeaders(allLeaders);
    if (!searchTerm && allTogglesOffMembers) setFilteredMembers(allMembers);
  }, [allLeaders, allMembers, searchTerm, statsCards, statsCardsMembers, id]);

  // clear stats cards filters on Leaders/Members tabs when using search
  useEffect(() => {
    if (searchTerm) {
      setStatsCards(statsCards?.map(card => ({ ...card, toggle: false })));
      setStatsCardsMembers(statsCardsMembers?.map(card => ({ ...card, toggle: false })));
    }
  }, [searchTerm]);

  const toggleCardsState = (filter, cardsState, cardsSetterFunc) => {
    const updatedCardsState = cardsState?.map(card => {
      if (card.filter === filter) {
        return { ...card, toggle: !card.toggle };
      } else {
        return { ...card, toggle: false };
      }
    });
    cardsSetterFunc(updatedCardsState);
  }

  // console.log("statsCards", statsCards?.forEach(c => console.log(c.filter, c.toggle)));

  const filterLeaders = (e, filter) => {
    // console.log(e.target); console.log("filter", filter);
    switch (filter) {
      case "show-all":
        setFilteredLeaders(allLeaders);
        toggleCardsState(filter, statsCards, setStatsCards);
        break;
      case "invited":
        setFilteredLeaders(allLeaders?.filter(l => l.referrals?.length > 0));
        toggleCardsState(filter, statsCards, setStatsCards);
        break;
      case "equal-to-100":
        setFilteredLeaders(allLeaders?.filter(l => {
          if (l.memberCount > 0) return l.memberCount === l.memberCompleted;
        }));
        toggleCardsState(filter, statsCards, setStatsCards);
        break;
      case "less-than-100":
        setFilteredLeaders(allLeaders?.filter(l => {
          if (l.memberCount && l.memberCompleted) { return l.memberCount > l.memberCompleted; }
        }));
        toggleCardsState(filter, statsCards, setStatsCards);
        break;
      case "equal-to-0":
        setFilteredLeaders(allLeaders?.filter(l => {
          if (l.memberCount > 0) return l.memberCompleted === 0;
        }));
        toggleCardsState(filter, statsCards, setStatsCards);
        break;
      case "did-not-invite":
        setFilteredLeaders(allLeaders?.filter(l => l.memberCount === 0));
        toggleCardsState(filter, statsCards, setStatsCards);
        break;
      default:
        setFilteredLeaders(allLeaders);
        break;
    }
  }

  const filterMembers = (e, filter) => {
    // console.log("statsCardsMembers", statsCardsMembers);
    switch (filter) {
      case "show-all":
        setFilteredMembers(allMembers);
        toggleCardsState(filter, statsCardsMembers, setStatsCardsMembers);
        break;
      case "show-complete":
        setFilteredMembers(allMembers?.filter(m => m?.assessmentCompleted === "yes"));
        toggleCardsState(filter, statsCardsMembers, setStatsCardsMembers);
        break;
      case "show-incomplete":
        setFilteredMembers(allMembers?.filter(m => m?.assessmentCompleted === "no"));
        toggleCardsState(filter, statsCardsMembers, setStatsCardsMembers);
        break;
      default:
        setFilteredMembers(allMembers);
        break;
    }
  }

  const getExportCount = () => {
    if (selectionModel?.length > 0) {
      return selectionModel.length;
    } else if (totalRecords > 0 && datagridCtx.tab == "Users") {
      return totalRecords;
      // return datagridCtx.filteredDataGridUsers?.length
    } else if (datagridCtx.tab == "Leaders") {
      return filteredLeaders?.length;
    } else if (datagridCtx.tab == "Members") {
      return filteredMembers?.length;
    } else if (datagridCtx.tab == "Users") {
      return filteredAllUsers?.length;
    } else if (datagridCtx.tab == "Users") {
      return 0;
    }
  }

  const authenticateSalesForce = () => {
    console.log("Authenticate");
    fetch(process.env.REACT_APP_API_URI + '/v3/integrations/salesforce/authenticate', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({ assessmentId: id }),
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status === "success") {
        console.log("apiData\n", data);
        var win = window.open(data.authUrl, '_blank');
        win.focus();
      } else {
        console.log("failed to fetch");
      }
    }).catch((err) => {
      console.error("Failed to authenticate SalesForce...", err);
    });
  }

  function getHighlightedText(text, highlight) {
    // Find the index of the match and the length of the highlight
    if (text?.length > 0) {
      const startIndex = text?.toLowerCase().indexOf(highlight.toLowerCase());
      const endIndex = startIndex + highlight.length;

      // If the highlight is not found, return the original text
      if (startIndex === -1) return text;
      // Otherwise, split the text and highlight the matching substring
      const parts = [
        text.substring(0, startIndex),
        text.substring(startIndex, endIndex),
        text.substring(endIndex),
      ];

      return (
        <Fragment>
          {parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={i} style={{ backgroundColor: "greenyellow" }}>{part}</span>
            ) : (<span key={i}>{part}</span>)
          )}
        </Fragment>
      );
    }
  };

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }

  let replace = /Assessment/gi;
  let assessmentName = title.toString().replace(replace, "").trim();

  if (isLoading && (allUsersDetails?.length >= 0)) {
    return <section className="loader-section"><CircularProgress /></section>
  }

  return (
    <>
      <div className="assessment-name">
        <div data-aos="fade-right">
          <h1><a style={{ textDecoration: "none", }} href={"/assessments/" + id + "/dashboard"}>{assessmentName} Assessment</a></h1>
          {assessDetails?.url && <p className="assessment-link-text">Link to assessment: <a className="assessment-link" href={assessDetails?.url} target="_blank" rel="noopener noreferrer">{assessDetails?.url}</a></p>}
        </div>
        <div data-aos="fade-down" data-aos-delay="400">
          {isExporting ? <a className="header-cta"><CircularProgress style={{ 'color': '#fff' }} size={16} /></a> :
            <>
              {!showFilteredExportBtn ? null : <Button className="header-cta" onClick={() => exportFilteredCSV()}>
                <IconButton aria-label="Download File">
                  <GetAppIcon />
                </IconButton> Export {getExportCount()} {getExportCount() === 1 ? datagridCtx.tab?.slice(0, -1) : datagridCtx.tab}</Button>}
                {/* </IconButton> Export {totalRecords} {getExportCount()} {getExportCount() === 1 ? datagridCtx.tab?.slice(0, -1) : datagridCtx.tab}</Button>} */}
              {/* <a className="header-cta" onClick={() => handleExport({ getRowsToExport: getFilteredRows })}><SystemUpdateAltIcon /> Download Members</a> */}
            </>
          }
          {/* {
            allUsersDetails?.length < totalRecords ? 
            // <p className="fetch-users-status"><CircularProgress style={{ "color": "#343333" }} size={16} /> Fetching {allUsersDetails?.length}/{totalRecords} users...</p> 
            null : <p className="fetch-users-status">Fetched {allUsersDetails?.length}/{totalRecords} users</p>
          } */}
        </div>
      </div>

      <div className="assessment-page">
        <div className="tab-container">

          <Box sx={{ width: '100%' }}>
            <Box className="box" sx={{ borderBottom: 1, borderColor: '#EDEDED' }}>
              <Tabs TabIndicatorProps={{ style: { background: '#F2D466', height: '4px' } }} value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                <Tab className="tab-title" label={`All Users (${totalRecords})`} {...a11yProps(0)} />
                <Tab className="tab-title" label={`Leaders (${totalLeaders})`} {...a11yProps(1)} />
                <Tab className="tab-title" label={assessDetails.assessmentType === "team" ? "Team Members" + " " + `(${allMembers?.length})` : "Referrals" + " " + `(${allMembers?.length})`} {...a11yProps(2)} />
                <Tab className="tab-title" label="Dashboard" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <TabPanel value={tabValue} index={0} className="users-table">
              {/* <TextField id="outlined-basic" label="Type to search..." variant="outlined" placeholder="Type to search..." /> */}

              <div className="search-and-delete">

                {showSearchBar()}

                {selectionModel.length > 0 ? <Button className="bulk-delete-btn" onClick={bulkDeleteUsers}><Delete /> {selectionModel.length === 1 ? "Delete" : "Bulk Delete"}</Button> : null}

                {id === "31" && <Button className="salesforce-btn" onClick={authenticateSalesForce}>Connect Salesforce</Button>}
              </div>

              {(allUsersDetails?.length >= 0 && filteredAllUsers !== null && !fetchingFirstPageUsers) ? <DataGrid
                initialState={{ sorting: { sortModel: [{ field: 'date', sort: 'desc' }] } }}
                // rows={allUsersDetails}
                rows={filteredAllUsers}
                columns={columnsAllUsers}
                components={{
                  Toolbar: DataGridState,
                }}
                pagination
                rowCount={totalRecords}
                pageSize={50}
                page={datagridPage}
                onPageChange={(newPage) => setDatagridPage(newPage)}
                onColumnVisibilityModelChange={(change) => console.log("columnVisibilityModelChange", change)}
                columnVisibilityModel={{
                  // Hide columns status and traderName, the other columns will remain visible
                  // date: false,
                  // actions: false,
                  call: showCallColumn,
                  callInfo: showCalendlyColumn,
                }}
                // apiRef={apiRef}
                // pageSize={5}
                checkboxSelection
                onFilterModelChange={(filter) => { console.log("filter", filter) }}
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelectionModel) => {
                  console.log("filter", newSelectionModel);
                  setSelectionModel(newSelectionModel);
                }}
                // disables all advanced column sorting options available through menu 
                // disableColumnMenu
                disableSelectionOnClick
                // rowsPerPageOptions={[5]}
                rowsPerPageOptions={[100]}
              /> : (<section className="loader-section"><CircularProgress /></section>)}

            </TabPanel>

            <TabPanel value={tabValue} index={1} className="leaders-table">

              <div className="statistics-cards">
                {statsCards?.map((card, index) => {
                  let cardClasses = card.toggle ? "active" : "inactive";
                  return (
                    <div key={index} className={"card " + cardClasses} onClick={(e) => filterLeaders(e, card.filter)}>
                      <p className="big-text">{card.num}</p>
                      {index === 0 ? <p className="info-text">Total<br />leaders</p> : <p className="info-text">{card.text}</p>}
                    </div>
                  )
                })}
              </div>

              <div className="search-and-delete">

                {showSearchBar()}

                {selectionModel.length > 0 ? <Button className="bulk-delete-btn" onClick={bulkDeleteUsers}><Delete /> {selectionModel.length === 1 ? "Delete" : "Bulk Delete"}</Button> : null}
              </div>

              {(allLeaders?.length >= 0 && !fetchingFirstPageUsers) ? (<DataGrid
                initialState={{ sorting: { sortModel: [{ field: 'date', sort: 'desc' }] } }}
                rows={filteredLeaders}
                columns={columnsLeaders}
                components={{
                  Toolbar: DataGridState,
                }}
                onColumnVisibilityModelChange={(change) => console.log("columnVisibilityModelChange", change)}
                columnVisibilityModel={{
                  churchName: showOrgColumnForLeaders,
                }}
                // pageSize={5}
                checkboxSelection
                onFilterModelChange={(filter) => { console.log("filter", filter) }}
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelectionModel) => {
                  console.log("filter", newSelectionModel);
                  setSelectionModel(newSelectionModel);
                }}
                disableSelectionOnClick
                // rowsPerPageOptions={[5]}
                rowsPerPageOptions={[100]}
              // disables all advanced column sorting options available through menu 
              // disableColumnMenu
              />) : (<section className="loader-section"><CircularProgress /></section>)}
            </TabPanel>

            <TabPanel value={tabValue} index={2} className="referrals-table">

              <div className="statistics-cards">
                {statsCardsMembers?.map((card, index) => {
                  let cardClasses = card.toggle ? "active" : "inactive";
                  return (
                    <div key={index} className={"card " + cardClasses} onClick={(e) => filterMembers(e, card.filter)}>
                      <p className="big-text">{card.num}</p>
                      {/* {index === 0 ? <p className="info-text">Total team<br/>members</p> : <p className="info-text">{card.text}</p>} */}
                      <p className="info-text">{card.text}</p>
                    </div>
                  )
                })}
              </div>

              <div className="search-and-delete">

                {showSearchBar()}

                {selectionModel.length > 0 ? <Button className="bulk-delete-btn" onClick={bulkDeleteUsers}><Delete /> {selectionModel.length === 1 ? "Delete" : "Bulk Delete"}</Button> : null}
              </div>

              {(allMembers?.length >= 0 && !fetchingFirstPageUsers) ? (<DataGrid
                initialState={{ sorting: { sortModel: [{ field: 'date', sort: 'desc' }] } }}
                rows={filteredMembers}
                columns={columnsMembers}
                components={{
                  Toolbar: DataGridState,
                }}
                onColumnVisibilityModelChange={(change) => console.log("columnVisibilityModelChange", change)}
                columnVisibilityModel={{
                  churchName: showOrgColumnForMembrs,
                }}
                checkboxSelection
                onFilterModelChange={(filter) => { console.log("filter", filter) }}
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelectionModel) => {
                  console.log("filter", newSelectionModel);
                  setSelectionModel(newSelectionModel);
                }}
                disableSelectionOnClick
                rowsPerPageOptions={[100]}
              // disables all advanced column sorting options available through menu 
              // disableColumnMenu
              />) : (<section className="loader-section"><CircularProgress /></section>)}

            </TabPanel>

            <TabPanel value={tabValue} index={3} className="statistics-dash">
              <AssessmentDashboardPage />
            </TabPanel>
          </Box>
          <p className="total-assessments">Total {currentTabText}: <strong>{currentTabTextVal}</strong></p>
        </div>
      </div>

    </>
  )
}


export default AssessmentPage;
