import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Tab, Tabs, Tooltip } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AuthContext from '../store/AuthContext';
import { useParams } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Button } from "@mui/material";
import DragIcon from '../assets/img/Question Builder/DragIndicator.svg';
import deleteIcon from '../assets/img/Question Builder/DelIcon.svg'
import DrawerContext from "../store/DrawerContext";
import ModalContext from "../store/ModalContext";
import UsersContext from "../store/UsersContext";
import AssessmentPieChart from "../Components/Charts/AssessmmentPieChart";
import AddIcon from '../assets/img/addIcon.png';
import editIcon from '../assets/img/Question Builder/editIcon.svg'
import { Delete, Edit, PieChart } from "@mui/icons-material";



const SetupAssessment = () => {

  const { id } = useParams();
  const authCtx = useContext(AuthContext);
  const drawerCtx = useContext(DrawerContext);
  const modalCtx = useContext(ModalContext);
  const usersCtx = useContext(UsersContext);

  const [title, setTitle] = useState("");
  const [assessDetails, setAssessDetails] = useState({});
  const [tabValue, setTabValue] = useState(0);
  const [sections, setSections] = useState([]);
  const [columnArray, setColumnArray] = useState([])
  const [questions, setQuestions] = useState([]);
  const [additionalQuestions, setAdditionalQuestions] = useState([]);
  const [addQuestionMatrix, setAddQuestionMatrix] = useState(false)
  const [tabs, setTabs] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false)
  const [currentTabId, setCurrentTabId] = useState();
  const [isSavingSelection, setIsSavingselection] = useState(false)
  const [selectedData, setSelectedData] = useState([])
  const [charts, setCharts] = useState([])

  // useEffects

  useEffect(() => {
    getAssessmentDetails()
    getQuestions()
  }, [])


  useEffect(() => {
    getTabsData()
    getCharts()

  }, [isUpdating])

  useEffect(() => {
    getSelectedSectionData(currentTabId)
  }, [currentTabId])

  useEffect(() => {
    const newArray = questions?.map((item, idx) => ({
      id: item.id,
      fieldId: item.id.toString(),
      field: 'Q' + (+idx + 1),
      label: item.question,
      dataType: 'question',
      width: '200',
    }));
    setColumnArray(prevArray => [...initialArray, ...newArray]);

  }, [questions])

  useEffect(() => {
    fncColumnListFilter()
  }, [sections])

  useEffect(() => {
    if (tabs.length > 0) {
      setCurrentTabId(tabs[0].id);
    }
  }, [tabs]);

  // handler functions

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setSections([])
    setCurrentTabId('')
  };

  function toggleDrawerHandler(drawer, charts) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, charts);
  }

  const handleDeleteField = (id) => {
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/tabField/' + id, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {


        } else {
          console.log("failed to delete");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }


  const toggleModal = (modal, tab) => {
    modalCtx.openModal();

    let details = {
      ...tab,
      isUpdating: isUpdating,
      setIsUpdating: setIsUpdating,
      setTabValue: setTabValue,
      assessmentId: id
    }
    modalCtx.setDetails(modal, details);

  }

  const envURI = process.env.REACT_APP_API_URI;

  // API requests

  const getAssessmentDetails = () => {
    let options = {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    fetch(`${envURI}/v1/admin/assessment/${id}`, options)
      .then(response => response.json())
      .then((data) => {
        if (data.status === "success") {
          setTitle(data?.details?.title);
          setAssessDetails(data?.details);
        }
      }).catch((err) => {
        console.log("Failed to fetch assessment details...", err);
      })
  }
  const getTabsData = () => {
    let options = {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    fetch(envURI + '/v3/admin/assessment/' + id + '/tab', options)
      .then(response => response.json())
      .then((data) => {
        if (data.status === "success") {
          setTabs(data?.data)
          setCurrentTabId(tabs[0]?.id)


        }
      }).catch((err) => {
        console.log("Failed to fetch tabs details...", err);
      })
  }

  const getCharts = () => {
    let options = {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    fetch(envURI + '/v3/admin/assessment/' + id + '/chart', options)
      .then(response => response.json())
      .then((data) => {
        if (data.status === "success") {
          setCharts(data?.data)
        }
      }).catch((err) => {
        console.log("Failed to fetch charts details...", err);
      })
  }


  const getSelectedSectionData = (currentTabId) => {
    let options = {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }
    if (currentTabId) {
      fetch(envURI + '/v3/admin/assessment/' + id + '/tab/' + currentTabId, options)
        .then(response => response.json())
        .then((data) => {
          if (data.status === "success") {
            setSections(data?.data[0]?.fields)
            setSelectedData(data?.data[0]?.fields)
          } else {
            console.log("Failed to fetch tabs details...");
          }
        }).catch((err) => {
          console.log("Failed to fetch tabs details...", err);
        })
    }

  }
  const getQuestions = () => {

    const envURI = process.env.REACT_APP_API_URI;
    let options = {
      method: 'GET', headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
    }
    Promise.all([
      fetch(envURI + '/v3/admin/questions/' + id, options).then(response => response.json()),
      fetch(envURI + '/v3/admin/questions/' + id + '/additionals', options).then(response => response.json()),
      fetch(envURI + '/v3/admin/assessment/' + id + '/tab', options).then(response => response.json()),
    ]).then((data) => {
      console.log("API data", data);
      if (data[0].status === "success") {
        setQuestions(data[0].questions);
        console.log('data[0].sections', data[0].sections)
      }
      if (data[1].status === "success") {
        setAdditionalQuestions(data[1].questions)
        console.log("Additional Questions API Data", data[1]);
      }
      if (data[0].status === "success" && data[1].status === "success") {
      }
    }).catch(err => {
      console.error(err);
    });
  }


  const fncAddTabFields = () => {

    setIsSavingselection(true);

    const payload = sections?.map(item => ({
      tabId: currentTabId.toString(),
      field: item.field,
      fieldId: item.fieldId,
      dataType: item.dataType || "",
      width: item.width || "100",
      classNames: "columns-list-content"
    }));

    let apiUrl;

    if (selectedData?.length > 0) {
      apiUrl = process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/tabField/' + currentTabId;
    } else {
      apiUrl = process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/tabField';
    }

    const method = selectedData?.length > 0 ? 'PUT' : 'POST';

    fetch(apiUrl, {
      method: method,
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: JSON.stringify({ fields: payload }),
    })
      .then(response => response.json())
      .then(data => {
        if (data.status === "success") {
          console.log("Successfully", data);
        } else {
          console.error("Failed to add data...");
        }
      })
      .catch((err) => {
        console.error("Failed to add data...", err);
      })
      .finally(() => {
        setIsSavingselection(false);
        getSelectedSectionData(currentTabId)
      });
  };


  let initialArray = [
    { id: 'col1', field: 'id', label: 'Unique ID (required)', dataType: 'user_assessment_id' },
    { id: 'col2', field: 'Date', label: 'User Created Date', dataType: 'user_created_date', width: 180 },
    { id: 'col3', field: 'Date', label: 'User Updated Date', dataType: 'user_updated_date', width: 180 },
    { id: 'col4', field: 'Date', label: 'User Assessment Started Date', dataType: 'user_assessment_created_date', width: 180 },
    { id: 'col5', field: 'Date', label: 'User Assessment Completed Date', dataType: 'user_assessment_completed_date', width: 180 },
    { id: 'col6', field: 'Status', label: "User's Assessment Status", dataType: 'user_assessment_status' },
    { id: 'col7', field: 'Started', label: "User's Assessment Started" },
    { id: 'col8', field: 'Progress', label: "User Assessment Progress", dataType: 'user_assessment_progress' },
    { id: 'col9', field: 'User ID', label: 'User ID', dataType: 'user_id' },
    { id: 'col10', field: 'User Assessment ID', label: 'User Assessment ID', dataType: 'user_assessment_id' },
    { id: 'col11', field: 'First Name', dataType: 'first_name' },
    { id: 'col12', field: 'Last Name', dataType: 'last_name' },
    { id: 'col13', field: 'Full Name', dataType: 'full_name' },
    { id: 'col14', field: 'Email', dataType: 'email' },
    { id: 'col15', field: 'Actions', label: 'Actions', dataType: 'user_actions', width: 100 },
  ]


  let replace = /Assessment/gi;
  let assessmentName = title.toString().replace(replace, "").trim();

  // custom or helper functions 

  const onDragEnd = (result) => {
    if (!result.destination) {
      return; // Item was not dropped in a valid destination
    }
    // Reorder the sections in the state based on the drag-and-drop result
    const updatedSections = [...sections];
    const [movedSection] = updatedSections.splice(result.source.index, 1); // Remove the dragged item
    updatedSections.splice(result.destination.index, 0, movedSection); // Insert it at the new position

    // Update the sections state with the reordered array
    setSections(updatedSections)
    changeSectionsOrder(updatedSections);
  };

  const changeSectionsOrder = (updatedOrder) => {
    let payload = {
      "sectionOrder":
        updatedOrder.map((el, index) => {
          return {
            "sectionId": el.id,
            "orderIndex": index + 1
          }
        })
    }
  };


  //helper functions

  const fncColumnListFilter = () => {
    let updatedArray = [...columnArray];

    updatedArray.forEach(item => {
      sections.forEach(removeItem => {
        if (removeItem.dataType !== 'question') {
          if (removeItem.dataType === item.dataType) {
            item.display = 'none';
          }

          if (removeItem.field === 'First Name' || removeItem.field === 'Last Name') {
            const fullNameIndex = updatedArray.findIndex(item => item.field === 'Full Name');
            if (fullNameIndex !== -1) {
              updatedArray[fullNameIndex].disabled = true;
            }
          }
          if (removeItem.field === 'Full Name') {
            const firstNameIndex = updatedArray.findIndex(item => item.field === 'First Name');
            if (firstNameIndex !== -1) {
              updatedArray[firstNameIndex].disabled = true;
            }
            const lastNameIndex = updatedArray.findIndex(item => item.field === 'Last Name');
            if (lastNameIndex !== -1) {
              updatedArray[lastNameIndex].disabled = true;
            }
          }
        }

        if (removeItem.dataType === 'question') {
          if (removeItem.field == item.field) {
            item.display = 'none';
          }
        }
      });
    });

    setColumnArray(updatedArray);
  }

  const handleSelectColumn = (id) => {
    setColumnArray(prevArray =>
      prevArray.map(item => {
        if (item.id === id) {
          return { ...item, display: 'none' };
        }
        return item;
      })
    );


    const removedElement = columnArray.find(item => item.id === id);

    if (removedElement) {
      const updatedColumnArray = columnArray
      setColumnArray(updatedColumnArray);

      setSections(prevSections => [...prevSections, removedElement]);

      if (removedElement.field === 'First Name' || removedElement.field === 'Last Name') {
        const fullNameIndex = updatedColumnArray.findIndex(item => item.field === 'Full Name');
        if (fullNameIndex !== -1) {
          updatedColumnArray[fullNameIndex].disabled = true;
        }
      }
      if (removedElement.field === 'Full Name') {
        const firstNameIndex = updatedColumnArray.findIndex(item => item.field === 'First Name');
        if (firstNameIndex !== -1) {
          updatedColumnArray[firstNameIndex].disabled = true;
        }

        const lastNameIndex = updatedColumnArray.findIndex(item => item.field === 'Last Name');
        if (lastNameIndex !== -1) {
          updatedColumnArray[lastNameIndex].disabled = true;
        }
      }
    }
  };


  const handleRemove = (data) => {
    if (data?.tabId) {
      handleDeleteField(data.id);

      setColumnArray(prevArray =>
        prevArray.map(item => {
          if (item.dataType !== 'question') {
            if (item.dataType === data.dataType) {
              return { ...item, display: 'flex' };
            }
          } else {
            if (item.field === data.field) {
              return { ...item, display: 'flex' };
            }
          }
          return item;
        })
      );
    } else {
      setColumnArray(prevArray =>
        prevArray.map(item => {
          if (item.id === data.id) {
            return { ...item, display: 'flex' };
          }
          return item;
        })
      );

    }



    const indexToRemove = sections.findIndex(item => item.id === data.id);

    if (indexToRemove !== -1) {
      const removedElement = sections.splice(indexToRemove, 1)[0];

      if (removedElement.field === 'First Name' || removedElement.field === 'Last Name') {
        const hasFirstName = sections.some(item => item.field === 'First Name');
        const hasLastName = sections.some(item => item.field === 'Last Name');
        if (!hasFirstName && !hasLastName) {
          const fullNameIndex = columnArray.findIndex(item => item.field === 'Full Name');
          if (fullNameIndex !== -1) {
            columnArray[fullNameIndex].disabled = false;
          }
        }
      } else if (removedElement.field === 'Full Name') {
        const firstNameIndex = columnArray.findIndex(item => item.field === 'First Name');
        if (firstNameIndex !== -1) {
          columnArray[firstNameIndex].disabled = false;
        }

        const lastNameIndex = columnArray.findIndex(item => item.field === 'Last Name');
        if (lastNameIndex !== -1) {
          columnArray[lastNameIndex].disabled = false;
        }
      }

      setSections([...sections]);
    }
  };


  return (
    <>
      <div className="assessment-name">
        <div data-aos="fade-right">
          <h1><a style={{ textDecoration: "none", }} href={"/assessments/" + id + "/dashboard"}>{assessmentName} Assessment</a></h1>
          {assessDetails?.url && <p className="assessment-link-text">Link to assessment: <a className="assessment-link" href={assessDetails?.url} target="_blank" rel="noopener noreferrer">{assessDetails?.url}</a></p>}
        </div>
      </div>

      <div className="setup-assesment assessment-page">
        <div className="tab-container">
          <Box sx={{ width: '100%' }}>
            <Box className="box" sx={{ borderBottom: 1, borderColor: '#EDEDED' }}>
              <Tabs TabIndicatorProps={{ style: tabValue === tabs.length ? { display: 'none' } : { display: 'block', background: '#F2D466', height: '4px' } }} value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
                {tabs?.map((item, index) => (
                  <Tab key={index} className="tab-title" label={item.title} icon={
                    <div className="delIcon">
                      <img src={editIcon} alt="" onClick={() => { toggleModal('edit-tabs-modal', item) }} />
                      <img src={deleteIcon} alt="" onClick={() => { toggleModal('delete-tabs-modal', item) }} />
                    </div>
                  }
                    iconposition="end" {...a11yProps(index + 1)} onClick={() => { setCurrentTabId(item.id) }} />
                ))}

                <Button className="tab-btn" onClick={() => { toggleModal('add-tabs-modal') }}> +Add Tabs </Button>

              </Tabs>
            </Box>

            {tabs?.map((item, index) => (
              <React.Fragment key={index}>
                {(item?.tabType?.toLowerCase() === "overview" || item?.title?.toLowerCase() === "overview") && (
                  <TabPanel value={tabValue} index={index} className="overview-table">
                    <div className="overview-contents">
                      <div className="chart-contents">
                        {
                          charts?.map((chart, index) => (
                            <div className="chart-section" key={index}>
                              <div className="chart-tools">
                                <Tooltip title="Segments">
                                  <PieChart className="table-tool edit-icon" onClick={() => {toggleDrawerHandler("add-pie-chart", chart)}} />
                                </Tooltip>
                                <Tooltip title="Edit">
                                  <Edit className="table-tool edit-icon" onClick={() => { toggleModal("edit-chart-modal", chart) }} />
                                </Tooltip>
                                <Tooltip title="Delete">
                                  <Delete className="table-tool delete-icon" onClick={() => { toggleModal("delete-charts-modal", chart) }} />
                                </Tooltip>
                              </div>
                              <AssessmentPieChart data={chart} />
                            </div>
                          ))
                        }

                        <div className="chart-section">
                          <div className="add-chart" onClick={() => { toggleModal("add-chart-modal") }}>
                            <img src={AddIcon} alt='add' />
                            <h1>Add Chart</h1>
                          </div>
                        </div>


                      </div>
                      <div className="chart-section">
                        {
                          !addQuestionMatrix && <div className="question-matrix-section ">
                            <div className="add-chart" onClick={() => { setAddQuestionMatrix(true) }} >
                              <img src={AddIcon} alt='add' />
                              <h1> Add Question Matrix </h1>
                            </div>
                          </div>
                        }

                        {addQuestionMatrix &&
                          <div className="question-matrix-contents">
                            {questions.map((section, index) => (
                              <div key={index}>
                                {/* Answers Section */}
                                <div className="contents">
                                  <h3></h3>
                                  {section?.answers.map((answer, idx) => (
                                    <React.Fragment key={idx}>
                                      <h3>{answer?.desktopText}</h3>
                                    </React.Fragment>
                                  ))}
                                </div>
                                {/* Questions Section */}
                                <div className="contents">
                                  <h3>{section.question}</h3>
                                  <h3>30</h3>
                                  <h3>40</h3>
                                  <h3>50</h3>
                                  <h3>50</h3>
                                  <h3>30</h3>

                                </div>
                              </div>
                            ))}
                          </div>

                        }
                      </div>
                    </div>
                  </TabPanel>)}
                {(item?.tabType?.toLowerCase() === "users" || item?.title?.toLowerCase() === "users") && (
                  <TabPanel value={tabValue} index={index
                  } key={index} className="users-table" >
                    <div className="user-tab">
                      <h1>Setup {item?.title} Tab</h1>
                      {isSavingSelection ?
                        <Button className="save-btn" disabled ><CircularProgress style={{ 'color': '#122845' }} size={25} /></Button> :
                        <Button className='btn' onClick={() => { fncAddTabFields() }}>Save Changes</Button>}
                    </div>
                    <div className="columns">
                      <h3>Column List  </h3>
                      <h3>Selected Column List</h3>
                    </div>
                    <div className="columns">
                      <div className="columns-list-content">
                        {columnArray.map((item, idx) => (
                          <div key={idx} className={`list-content ${item.disabled ? 'disabled' : ''}  ${item.display == 'none' ? 'hidden' : 'show'}`}>
                            <div className="list-label">
                              <h3>{item.field}</h3>
                              {item.label && <p>{item.label}</p>}
                            </div>
                            <KeyboardArrowRightIcon className="arrow" onClick={() => handleSelectColumn(item.id)} />
                          </div>))}
                      </div>
                      <div className="selected-coulmn-list">
                        <DragDropContext onDragEnd={onDragEnd}>
                          <Droppable droppableId="setups" direction="vertical">
                            {(provided) => (
                              <div className="columns-list-content" style={sections?.length == 0 ? { overflow: 'hidden' } : {}} ref={provided.innerRef} {...provided.droppableProps}>
                                {
                                  (sections?.map((data, index) =>
                                    <Draggable key={index} draggableId={data.id.toString()} index={index}>
                                      {(provided) => (
                                        <div className="columns-list-child" key={data.id} {...provided.draggableProps} ref={provided.innerRef}>
                                          <div className="order-no-and-drag-section-name-icon">
                                            <div className="drag-section" >
                                              <div className="order-drag-icon" {...provided.dragHandleProps}>
                                                <img src={DragIcon} alt="dragicon" />
                                              </div>
                                              <div className="order-no">{index + 1}</div>
                                            </div>
                                            <div className="list-content" style={{ marginBottom: '0px' }}>

                                              <div className="list-label">
                                                <h3 className="section-name" dangerouslySetInnerHTML={{ __html: data?.field }} />
                                                {data.label && <p>{data.label}</p>}

                                              </div>
                                            </div>

                                          </div>
                                          <div className="delIcon">
                                            <img src={editIcon} alt="" />
                                            < img src={deleteIcon} alt="" onClick={() => { handleRemove(data) }} />
                                          </div>
                                        </div>

                                      )}
                                    </Draggable>
                                  ))
                                }
                                {provided.placeholder}
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                      </div>
                    </div>
                  </TabPanel>
                )}
              </React.Fragment>
            ))}

          </Box>
        </div>
      </div>
    </>
  )
}

export default SetupAssessment;


function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

