import { useState, useContext, useEffect } from 'react';
import ModalContext from '../../store/ModalContext.js';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import cross from '../../../src/assets/img/cross.png';
import AuthContext from '../../store/AuthContext.js';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';


const EditTabsModal = (props) => {

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [label, setLabel] = useState(props?.details?.title);
  const [type, setType] = useState(props?.details?.tabType)
  const [publishStatus, setPublishStatus] = useState(props?.details?.status || "draft");
  const [isLoading, setIsLoading] = useState(false);

  const assessmentId = props?.details?.assessmentId;
  const tabId = props?.details?.id;

  console.log("EditTabsModal props", props);


  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const fncEditTab = () => {
    const payload = {
      title: label, orderNo: props.details.orderNo,
      tabType: type, status: publishStatus
    }
    if (label && type) {
      setIsLoading(true)
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/tab/' + tabId, {
        method: 'PUT', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
        .then(data => {
          setIsLoading(false)
          if (data.status === "success") {
            console.log("API data", data);
            handleCloseModal()
            props.details.setIsUpdating(!props.details.isUpdating)
          }
        }).catch((err) => {
          console.error("Failed to edit tab details...", err);
          setIsLoading(false)
        });
    }
  }

  return (
    <div className="modal add-chart-container">
      <div className="heading-container">
        <h3>Edit Tab</h3>
        <img src={cross} onClick={() => { handleCloseModal() }} alt="cross" />
      </div>
      <div className='modal-input-field'>
        <TextField required fullWidth label="Label" placeholder="Enter Label"
          value={label} variant="outlined"
          onChange={(e) => setLabel(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
        <TextField required fullWidth label="Tab Type" placeholder="Tab Type"
          value={type} variant="outlined"
          onChange={(e) => setType(e.target.value)}
          InputLabelProps={{ shrink: true }}
        />

        <FormControl fullWidth style={{ margin: "0 auto 30px" }}>
          <InputLabel id="tab-publish-select-label">Publish Status</InputLabel>
          <Select labelId="tab-publish-select-label" label="Publish Status"
            value={publishStatus}
            onChange={(e) => setPublishStatus(e.target.value)}
          >
            <MenuItem value={"draft"}>Draft</MenuItem>
            <MenuItem value={"published"}>Published</MenuItem>
          </Select>
        </FormControl>

        {isLoading ?
          <Button className="save-btn" style={{ background: '#FFF', border: '1px solid #122845' }}><CircularProgress style={{ 'color': '#122845' }} size={25} /></Button> :
          <Button className="save-btn" onClick={fncEditTab}> Save</Button>
        }
      </div>
    </div>
  )
}

export default EditTabsModal;

