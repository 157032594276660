import { Box, Button, CircularProgress, IconButton, MenuItem, Modal, TextField, makeStyles } from '@material-ui/core'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,  } from '@material-ui/core';
import AuthContext from '../store/AuthContext';
import { hideSidebar } from '../globalFunctions';
import { useNavigate, useParams } from 'react-router-dom';
import ModalContext from '../store/ModalContext';
import DrawerContext from '../store/DrawerContext';
import { Clear } from '@material-ui/icons';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import MoreVertIcon from '@mui/icons-material/MoreVert';



import delIcon from '../../src/assets/img/Delete.svg';
import automationIcon from '../../src/assets/img/automation.svg';
import builderIcon from '../../src/assets/img/builder-icon.svg';
import emailIcon from '../../src/assets/img/email-icon.svg';
import pageIcon from '../../src/assets/img/pagesicon.svg';
import QuestionIcon from '../../src/assets/img/questions-icon.svg';
import ScoringIcon from '../../src/assets/img/scoring.svg';
import formdetailsicon from '../../src/assets/img/form.svg';
import editdetails from '../../src/assets/img/edit.svg'
import cross from '../../src/assets/img/cross.png'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';


const AssesmentPage = () => {
  const navigate = useNavigate()


  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const drawerCtx = useContext(DrawerContext);

  const [assessments, setAssessments] = useState("")
  const [editClientDetails, setEditClientDetails] = useState(false)
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState({'searchTerm':'', 'assessmentType':false});
  const [title, setTitle] = useState("");
  const [clientName, setClientName] = useState("");
  const [sorting, setSorting] = useState({ column: null, direction: 'asc' });
  const [awsImageUrl, setAwsImageUrl] = useState("");
  const [upLoadingImage, setUpLoadingImage] = useState(false);
  const [editId, setEditId] = useState("");
  const [isCreating, setIsCreating] = useState(false);
  const [clientsData, setClientsData] = useState([]);
  const [hoveredItemId, setHoveredItemId] = useState(null);
  const [openChildren, setOpenChildren] = useState(null);


  const handleSort = (column) => {
    if (column !== 'actions') {
      setSorting((prevSorting) => ({
        column,
        direction: prevSorting?.column === column && prevSorting?.direction === 'asc' ? 'desc' : 'asc',
      }));
    }
  };
  const sortedIcon = (asc, title) => {
    return (
      // <IconButton aria-label="Sort" title="Sort" className={`arrow-updown-${title}`}>
      //   {asc == "asc" ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      // </IconButton>
      <>
        
      </>


    );
  };
  const toggleEditSection = (id) => {
    setHoveredItemId(id);
  };

  const sortedAssessments = [...assessments].sort((a, b) => {
    const isAsc = sorting.direction === 'asc';
    let comparison = 0;
    switch (sorting.column) {
      case 'title':
        comparison = a.title.localeCompare(b.title);
        break;
      case 'allUsers':
        comparison = (a.leaderCount + a.memberCount) - (b.leaderCount + b.memberCount);
        break;
      case 'leaders':
        comparison = a.leaderCount - b.leaderCount;
        break;
      case 'members':
        comparison = a.memberCount - b.memberCount;
        break;
      case 'date':
        comparison = new Date(a?.createdAt) - new Date(b?.createdAt);
        break;
      default:
        comparison = 0;
    }
    sortedIcon(sorting?.direction);
    return isAsc ? comparison : -comparison; 

  });

  function fncEditAssesment(data) {
    setEditClientDetails(true)
    setEditId(data?.id)
    setTitle(data?.title)
    getClients()
  }



  function getClients() {
    
    fetch(process.env.REACT_APP_API_URI + "/v3/accounts", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setClientsData(data?.data);
      });
  }
  




  function fncCross() {
    setEditClientDetails(false)
    setAwsImageUrl("")

  }
 
  function toggleDrawerHandler(drawer, id) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, id);
  }
  
  const toggleModalHandler = (modal, id) => {
    let details = {
      id: id,
      imageType: 'client-logo'
    }
    modalCtx.setDetails(modal, details);
    modalCtx.openModal();
  };


  useEffect(() => {
    setIsLoading(true)
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
      .then(response => {
        return response.json();
      }).then(data => {
        console.log(data);
        if (data.status === "success") {
          setIsLoading(false)
          setAssessments(data?.data);
          handleSort('date')
          handleSort('date')

        } else {
          setIsLoading(false)

        }
      }).catch(err => console.error(err));
  }, [])

  const getImageUrl = (e) => {
    e.stopPropagation();
    setUpLoadingImage(true)

    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + editId + '/updateImage', {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: formData,

    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          setAwsImageUrl(data?.imagePath)
          setUpLoadingImage(false)

        }
      }).catch(err => console.error(err));

  }
  
  const handleSubmit = (e) => {
    e.preventDefault();

    if (awsImageUrl && clientName && title ) {
      setIsCreating(true)

      const bodydata = {
        "clientName": clientName ,
        "assessmentTitle": title,
        "image": awsImageUrl
      }
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + editId + '/account/' + authCtx?.accountId , {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx?.token },
        body: JSON.stringify(bodydata),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          setIsCreating(false);
          if (data.status === "success") {
            console.log("successfully updated ...");
          }
        setEditClientDetails(false)

        })
        .catch((err) => console.error("failed to update...", err));
    }


  }




  const showSearchBar = () => (
    <div className="search-bar">
      <input type="search" name="" id="search-field" className="search-field" placeholder="Type to search..." value={searchTerm.searchTerm} onChange={(e) => setSearchTerm({"searchTerm":e.target.value, 'assessmentType':false})} />
      {searchTerm && <Clear className="clear-search-icon" onClick={() => { setSearchTerm({'searchTerm':'', 'assessmentType':false})}} />}
      <div className='assessment-team assessment-chip' onClick={() => setSearchTerm({'searchTerm':"team", 'assessmentType':true})}>Team</div>
      <div className='assessment-referal assessment-chip' onClick={() => setSearchTerm({'searchTerm':"referal", 'assessmentType':true})}>Referal</div>
      <div className='assessment-collection assessment-chip' onClick={() => setSearchTerm({'searchTerm':"collection", 'assessmentType':true})}>Collection</div>
      <div className='assessment-multi assessment-chip' onClick={() => setSearchTerm({'searchTerm':"multi", 'assessmentType':true})}>Multi</div>
      <div className='assessment-simple assessment-chip' onClick={() => setSearchTerm({'searchTerm':"simple", 'assessmentType':true})}>Simple</div>
    </div>
  );
  function getHighlightedText(text, highlight) {
    // Find the index of the match and the length of the highlight
    if (text?.length > 0) {
      const startIndex = text?.toLowerCase().indexOf(highlight.toLowerCase());
      const endIndex = startIndex + highlight.length;

      // If the highlight is not found, return the original text
      if (startIndex === -1) return text;
      // Otherwise, split the text and highlight the matching substring
      const parts = [
        text.substring(0, startIndex),
        text.substring(startIndex, endIndex),
        text.substring(endIndex),
      ];

      return (
        <Fragment>
          {parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={i} style={{ backgroundColor: "greenyellow" }}>{part}</span>
            ) : (<span key={i}>{part}</span>)
          )}
        </Fragment>
      );
    }
  };

  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }


    if(isLoading ){

      return <section className="loader-section"><CircularProgress /></section>
    }

  const filteredData= sortedAssessments?.filter((data) => {
      const lowerCaseTitle = data?.title?.toLowerCase();
      const lowerCaseName = data?.name?.toLowerCase();
      const lowerCaseSearchTerm = searchTerm?.searchTerm?.toLowerCase();
      if(searchTerm.assessmentType){
        const lowerCaseAssessmentType = data?.assessmentType?.toLowerCase();
        return lowerCaseAssessmentType.includes(lowerCaseSearchTerm);
      }
      return lowerCaseTitle && lowerCaseTitle?.includes(lowerCaseSearchTerm) || lowerCaseName && lowerCaseName?.includes(lowerCaseSearchTerm);
    })

  const noDataFound = filteredData?.length === 0;
  return (
      <div className="assessment-page-container" >
          <div className="page-header">
              <h1>Assessments </h1>
        <Button className="create-btn" onClick={() => { toggleDrawerHandler("create-assessment", null); }}  >Create</Button>
      </div>
      {showSearchBar()}
        
      <TableContainer component={Paper}>
        <Table aria-label="simple table" >
          <TableHead>
            <TableRow>
              <TableCell className='assessment-img'> </TableCell>
              {/* <TableCell onClick={() => handleSort('date')} className='assesment-date'>Date  {sortedIcon(sorting?.direction)}</TableCell> */}
              <TableCell onClick={() => handleSort('title')} className='assesment-head'>Assessment {sortedIcon(sorting?.direction, "head")} </TableCell>
              <TableCell onClick={() => handleSort('allUsers')} className='assesment-user'>All Users {sortedIcon(sorting?.direction, "user")}</TableCell>
              <TableCell onClick={() => handleSort('leaders')} className='assesment-leader'>Leaders  {sortedIcon(sorting?.direction, "leader")}</TableCell>
              <TableCell onClick={() => handleSort('members')} className='assesment-member'>Members  {sortedIcon(sorting?.direction, "member")}</TableCell>
              <TableCell style={{textAlign:'center'}}>Type</TableCell>
              <TableCell style={{textAlign:'center'}}>Stage</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((data) => {

              // console.log(data);
              return (
                <>
                  <TableRow key={data?.id} className='assessment-row'>
                    <TableCell className={`assesment-img ${openChildren === data?.id ? 'border-none': ''}`}><img src={`${data?.url}/favicon.ico`} alt="" onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)} /></TableCell>
                    {/* <TableCell className='assesment-date' onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)}>{data?.createdAt.slice(0, 10)} </TableCell> */}
                    <TableCell className={`assesment-body assesment-head ${openChildren === data?.id ? 'border-none': ''}`} onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)}>
                      <div className="assesment-name">
                        <div className='editdetails'>
                          <ExpandMoreIcon style={{ visibility: (data?.childAssessments?.length <= 0 || !data?.childAssessments) ? 'hidden' : '', transform: `rotate(${openChildren === data?.id ? '180deg' : '0deg'})`}} onClick={() => { setOpenChildren(openChildren === data?.id ? null : data?.id) }} />
                          <div>{getHighlightedText(capitalizeWords(data?.title), searchTerm.searchTerm) || ""}</div>
                          {/* <img src={editdetails} alt="edit" onClick={() => {fncEditAssesment(data) }} /> */}
                        </div>
                        <div style={{ fontWeight: 300, color: "#9B9B9B", paddingLeft: "28px", fontSize:'12px' }}>{data?.accountName}</div>
                      </div>
                    </TableCell>
                    <TableCell className={`assesment-user ${openChildren === data?.id ? 'border-none': ''}`} onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)}>{data?.leaderCount + data?.memberCount}</TableCell>
                    <TableCell className={`assesment-leader ${openChildren === data?.id ? 'border-none': ''}`} onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)}>{data?.leaderCount}</TableCell>
                    <TableCell className={`assesment-member ${openChildren === data?.id ? 'border-none': ''}`} onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)}>{data.memberCount}</TableCell>
                    <TableCell className={`assesment-leader ${openChildren === data?.id ? 'border-none': ''}`} onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)} align='center'><div className={`assessment-chip assessment-${data?.assessmentType}`}>{data?.assessmentType}</div></TableCell>
                    <TableCell className={`assesment-leader ${openChildren === data?.id ? 'border-none': ''}`} onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)} align='center'><div className={`assessment-chip assessment-stage-${data?.stage}`}>{data?.stage}</div></TableCell>
                    <TableCell className={`assesment-edit ${openChildren === data?.id ? 'border-none': ''}`}>
                      <div className='edit-container assesmentTooltip-parent '>
                        {/* <MoreVertIcon className='dot-icon' onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)} /> */}
                        <div className='assesmentTooltip' onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)}>
                          {hoveredItemId === data?.id && (
                            <div className='edit-se ction tooltip-bottom'>
                              <div className='edit-row'>
                                <div className='img-container' onClick={() => {
                                  hideSidebar();
                                  navigate("/builder/" + data.id, { replace: true });
                                }}>
                                  <img src={builderIcon} alt="builder" />
                                  <div>Builder</div>
                                </div>
                                <div className='img-container' onClick={() => { navigate("/assessments/" + data.id + "/automation", { replace: true }); }}>
                                  <img src={automationIcon} alt="automation" />
                                  <div>Automation</div>
                                </div>
                                <div className='img-container' onClick={() => { navigate("/assessments/" + data.id + "/pages", { replace: true }); }} >
                                  <img src={pageIcon} alt="page" />
                                  <div>Pages</div>
                                </div>
                                <div className='img-container' onClick={() => { toggleDrawerHandler('edit-assessment', data) }}>
                                  <img src={editdetails} alt="edit" />
                                  <div>Edit</div>
                                </div>
                                <div className='img-container'>
                                  <img src={formdetailsicon} alt="form" />
                                  <div>Form</div>
                                </div>
                                <div className='img-container' onClick={() => { toggleDrawerHandler("open-questions-builder", { id: data.id }) }}>
                                  <img src={QuestionIcon} alt="QuestionIcon" />
                                  <div>Questions</div>
                                </div>
                                <div className='img-container' onClick={() => { navigate("/emails/" + data.id, { replace: true }); }}>
                                  <img src={emailIcon} alt="emailIcon" />
                                  <div>Email</div>
                                </div>
                                <div className='img-container' onClick={() => toggleDrawerHandler('assessment-scoring', data)}>
                                  <img src={ScoringIcon} alt="scoring" />
                                  <div>Scoring</div>
                                </div>
                                <div className='img-container' onClick={() => { toggleModalHandler('assessment-delete', data) }}>
                                  <img src={delIcon} alt="delete" />
                                  <div>Delete</div>
                                </div>
                              </div>
                            </div>)}
                        </div>
                        <div className='view-btn' onClick={() => navigate("/assessments/" + data?.id, { replace: true })}>View</div>
                      </div>
                    </TableCell>
                  </TableRow>
                  <TableRow className='assessment-row'>
                    {openChildren === data?.id && data?.childAssessments?.map((child) =>
                      <>
                        <TableCell className='assessment-img'></TableCell>
                        <TableCell className='assessment-head assessment-body' style={{ paddingLeft: '28px', color:"0F131D", fontWeight:"500" }} onMouseEnter={() => toggleEditSection(child?.id)} onMouseLeave={() => toggleEditSection(null)}>{child?.title}</TableCell>
                        <TableCell className='assesment-user' onMouseEnter={() => toggleEditSection(child?.id)} onMouseLeave={() => toggleEditSection(null)}>{child?.leaderCount + child?.memberCount}</TableCell>
                        <TableCell className='assesment-leader' onMouseEnter={() => toggleEditSection(child?.id)} onMouseLeave={() => toggleEditSection(null)}>{child?.leaderCount}</TableCell>
                        <TableCell className='assesment-member' onMouseEnter={() => toggleEditSection(child?.id)} onMouseLeave={() => toggleEditSection(null)}>{child.memberCount}</TableCell>
                        <TableCell className='assesment-leader' onMouseEnter={() => toggleEditSection(child?.id)} onMouseLeave={() => toggleEditSection(null)} align='center'><div className={`assessment-chip assessment-${child?.assessmentType}`}>{child?.assessmentType}</div></TableCell>
                        <TableCell className='assesment-leader' onMouseEnter={() => toggleEditSection(child?.id)} onMouseLeave={() => toggleEditSection(null)} align='center'><div className={`assessment-chip assessment-${child?.stage}`}>{child?.stage}</div></TableCell>
                        <TableCell className='assesment-edit'>
                          <div className='edit-container assesmentTooltip-parent '>
                            {/* <MoreVertIcon className='dot-icon' onMouseEnter={() => toggleEditSection(data?.id)} onMouseLeave={() => toggleEditSection(null)} /> */}
                            <div className='assesmentTooltip' onMouseEnter={() => toggleEditSection(child?.id)} onMouseLeave={() => toggleEditSection(null)}>
                              {hoveredItemId === child?.id && (
                                <div className='edit-se ction tooltip-bottom'>
                                  <div className='edit-row'>
                                    <div className='img-container' onClick={() => {
                                      hideSidebar();
                                      navigate("/builder/" + child.id, { replace: true });
                                    }}>
                                      <img src={builderIcon} alt="builder" />
                                      <div>Builder</div>
                                    </div>
                                    <div className='img-container' onClick={() => { navigate("/assessments/" + child.id + "/automation", { replace: true }); }}>
                                      <img src={automationIcon} alt="automation" />
                                      <div>Automation</div>
                                    </div>
                                    <div className='img-container' onClick={() => { navigate("/assessments/" + child.id + "/pages", { replace: true }); }} >
                                      <img src={pageIcon} alt="page" />
                                      <div>Pages</div>
                                    </div>
                                    <div className='img-container' onClick={() => { toggleDrawerHandler('edit-assessment', child) }}>
                                      <img src={editdetails} alt="edit" />
                                      <div>Edit</div>
                                    </div>
                                    <div className='img-container'>
                                      <img src={formdetailsicon} alt="form" />
                                      <div>Form</div>
                                    </div>
                                    <div className='img-container' onClick={() => { toggleDrawerHandler("open-questions-builder", { id: child.id }) }}>
                                      <img src={QuestionIcon} alt="QuestionIcon" />
                                      <div>Questions</div>
                                    </div>
                                    <div className='img-container' onClick={() => { navigate("/emails/" + child.id, { replace: true }); }}>
                                      <img src={emailIcon} alt="emailIcon" />
                                      <div>Email</div>
                                    </div>
                                    <div className='img-container' onClick={() => toggleDrawerHandler('assessment-scoring', child)}>
                                      <img src={ScoringIcon} alt="scoring" />
                                      <div>Scoring</div>
                                    </div>
                                    <div className='img-container' onClick={() => { toggleModalHandler('assessment-delete', child) }}>
                                      <img src={delIcon} alt="delete" />
                                      <div>Delete</div>
                                    </div>
                                  </div>
                                </div>)}
                            </div>
                            <div className='view-btn' onClick={() => navigate("/assessments/" + child?.id, { replace: true })}>View</div>
                          </div>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                </>
              )
            })}
          </TableBody>

        </Table>
        {noDataFound && searchTerm.searchTerm && (
          <div className='no-data-found'>No data Found</div>
        )}
      </TableContainer>

      
      {editClientDetails && (
        <div className="assesment-container" >
          <div className="modal-content">
            <div className="main-content">
              <form onSubmit={handleSubmit}>
              <div className="heading-container">
                <div className="heading">Edit Assessment Details</div>
                <img src={cross} onClick={() => { fncCross() }} alt="cross" />
              </div>
              <div className="items">
                <div className="label">Fav Icon</div>
                <label className="upload-container">
                  <input type="file" onChange={(e) => { getImageUrl(e) }} />
                  <img src={awsImageUrl} alt="upload" />
                  {upLoadingImage ? <section className="loader-section-uploaing"><CircularProgress /> Uploading</section> : ""}
                </label>

              </div>
              <div className="items">
                <div className="label">Assessment Title</div>
                <input className="text-box" type={"text"} placeholder={"Assessment Title"} defaultValue={title} onChange={(e)=>{setTitle(e.target.value)}} />
              </div>
              <div className="items">
                <div className="label">Client Name</div>
                  <TextField fullWidth className="form-control-add-question margin-top"
                    placeholder="Client Name"
                    value={clientName}
                    onChange={(e) => { setClientName(e.target.value) }}
                    label=""
                    select
                    variant="outlined"
                    required
                  >
                    {clientsData && clientsData.map((data) => (
                      <MenuItem key={data?.title} className="section-name" value={data?.title}>
                        {data?.title}
                      </MenuItem>
                    ))}
                  </TextField>
              </div>
                {isCreating ?
                  <button className="create-btn-submit" disabled> <CircularProgress /></button> :
                  <button className="create-btn-submit" type="submit" >Save Changes</button>
                }
              </form>
            </div>
          </div>
        </div>
      
      )}
    </div>
  )
}

export default AssesmentPage
