import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PeopleIcon from '@material-ui/icons/People';
import DonutSmallIcon from '@material-ui/icons/DonutSmall';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import { List } from '@material-ui/icons';

import UsersDrawer from '../DetailsDrawer';
import DrawerContext from '../../store/DrawerContext.js';

const useStyles = makeStyles({
	table: {
		minWidth: 650,
		color: "#747d8c"
	},
});


function AssessmentLeadersTable(props) {

	console.log(props);

	let navigate      											= useNavigate();
  const { id }      											= useParams();
	const drawerCtx 												= useContext(DrawerContext);
	const classes 													= useStyles();
	const [state, setState] 								= React.useState(false);

	const [isLoading, setIsLoading] 				= useState(false);
	const [assessments, setAssessments] 		= useState([]);
	
	
	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	if (isLoading) {
		return <section><CircularProgress /></section>
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table className={classes.table} aria-label="simple table">
					<TableHead>
						<TableRow>
							<TableCell>Name & Email</TableCell>
							<TableCell align="center">Members</TableCell>
							<TableCell align="center">Links</TableCell>
							<TableCell align="right">&nbsp;</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{
							props.leaders.map((data) => (
								<TableRow key={ data.id }>
									<TableCell width="58%">{ data.name } ({ data.email })</TableCell>
									<TableCell align="center">{ data.memberCount }</TableCell>
									<TableCell align="center">
										<Tooltip title="Report Link"><a href={props.url + "/results/" + data.uniqueId} target="_blank"><AssessmentIcon className="table-tool" /></a></Tooltip>
										<Tooltip title="Invite Link"><a href={props.url + "/invite-team/" + data.uniqueId} target="_blank"><PeopleIcon className="table-tool" /></a></Tooltip>
										<Tooltip title="Dashboard Link"><a href={props.url + "/dashboard/" + data.uniqueId} target="_blank"><DonutSmallIcon className="table-tool" /></a></Tooltip>
									</TableCell>
									<TableCell align="right" width="15%">
										<Tooltip title="Details"><MoreVertIcon className="table-tool" onClick={() => {toggleDrawerHandler('user-details', data)}} /></Tooltip>
									</TableCell>
								</TableRow>
							))
						}
					</TableBody>
				</Table>
			</TableContainer>
			<UsersDrawer openTheDrawer={state}/>
		</>
	);
}

export default AssessmentLeadersTable;