import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import {  TextField,  Select,  MenuItem,  FormControl,  InputLabel,  Button,  Checkbox} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SubDrawerContext from "../../store/SubDrawerContext";

function CreateAssessmentSectionDrawer(props) {
  const [title, setTitle] = useState("");
  const [order, setOrder] = useState("");
  const [multiplier, setMultiplier] = useState("");
	const drawerCtx = useContext(DrawerContext);
	const subDrawerCTx = useContext(SubDrawerContext);

  console.log('subDrawerCTx',subDrawerCTx)
  
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(props)
    console.log("Form Submitted");
    fetch(process.env.REACT_APP_API_URI + "/v1/admin/sections", {
      method: "POST",
      body: JSON.stringify({client_id: props.userDetails.client_id, title: title, order: subDrawerCTx?.details?.order, multiplier: multiplier}),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
      subDrawerCTx.closeDrawer();
      if ( subDrawerCTx.pageUpdate?.pageUpdate === true ) {
        subDrawerCTx.setPageUpdate({ 'type': 'categoryAdd', 'pageUpdate':false})
      }
      else {
        subDrawerCTx.setPageUpdate({ 'type': 'categoryAdd', 'pageUpdate':true})
      }
  };

  const handleCancel = () => {
    subDrawerCTx.setDetails('add-category-subdrawer');
    subDrawerCTx.closeDrawer();
  }

  return (
    <div className="drawer add-section">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
         Add Category
        </h1>
      </header>

      <section style={{ minHeight: props?.userDetails?.customMinHeight || "calc(100vh - 130px)" }}>
        <TextField required fullWidth label="Title" placeholder="Title" variant="outlined" 
          className="form-control-add-question margin-top" InputLabelProps={{ shrink: true }} 
          value={title} onChange={(e) => setTitle(e.target.value)} />
 
        {/* <TextField required fullWidth label="Order" placeholder="Order" variant="outlined" 
          className="form-control-add-question margin-top" InputLabelProps={{ shrink: true }} 
          value={order} onChange={(e) => setOrder(e.target.value)} /> */}
 
        <TextField required fullWidth label="Multiplier" placeholder="Multiplier" variant="outlined" 
          className="form-control-add-question margin-top" InputLabelProps={{ shrink: true }} 
          value={multiplier} onChange={(e) => setMultiplier(e.target.value)} />
 
        <div className="double-column-form aligned-right margin-top">
        <Button type="submit" variant="contained" component="label" className="cancel-button-add-category-subdrawer"
            onClick={handleCancel}>
            Cancel
          </Button>
          <Button type="submit" variant="contained" component="label" className="submit-btn"
            onClick={handleSubmit}
            disabled={!title || !multiplier}>
            Add Category
          </Button>
        </div>
      </section>
    </div>
  );
}
export default CreateAssessmentSectionDrawer;
