import { useState, useContext, useEffect } from 'react';
import { Button } from '@material-ui/core';
import axios from 'axios';

import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';

const SectionDelete = (props) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);

  console.log("props",props)

  const handleSectionDelete = () => {
   fetch(`${process.env.REACT_APP_API_URI}/v3/admin/section/${props.details.id}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + authCtx.token
            },
            mode: 'cors'
       })
       .then(response => response.json())
       .then(result => {
            console.log(result);
            if (result.status === "success") {
                modalCtx.closeModal();
                modalCtx.setDetails({'type':'deleteCategory'});
                if(modalCtx.pageUpdate?.toggleUpdate){
                  modalCtx.setPageUpdate({'type': "deleteCategory", 'toggleUpdate': false});
                }
                else{
                modalCtx.setPageUpdate({'type': "deleteCategory", 'toggleUpdate': true});
                }
            } else {
                console.log("failed to delete question");
            }
       })
       .catch(err => console.error(err));
  }


  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  return (
    <div className="add-goal-form">
      <div className="heading-and-close-icon-container">
       <p>Are you sure you want to delete this question ?</p>
       <div className="close-icon" onClick={(e) => { handleCloseModal() }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="Group_4507" data-name="Group 4507" transform="translate(-4322 -2323)">
            <g id="close" transform="translate(4316.508 2317.506)">
              <g id="Group_353" data-name="Group 353" transform="translate(9.034 9.036)">
                <path id="Path_379" data-name="Path 379" d="M10.052,9.211a.6.6,0,1,0-.843.843l3.441,3.441L9.209,16.935a.6.6,0,1,0,.843.843l3.442-3.441,3.441,3.441a.6.6,0,1,0,.843-.843l-3.441-3.441,3.441-3.441a.6.6,0,1,0-.843-.843l-3.441,3.441L10.052,9.21Z" transform="translate(-9.034 -9.036)" fill="#fff"/>
              </g>
            </g>
            <rect id="Rectangle_102" data-name="Rectangle 102" width="16" height="16" transform="translate(4322 2323)" fill="none"/>
          </g>
        </svg>
       </div>
      </div>      
      <div className="form-btns">
        <Button className="delete-btn" onClick={() => { handleSectionDelete() }}>Delete</Button>
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
      </div>
    </div>
  )
}

export default SectionDelete;
