import { CloseOutlined } from "@material-ui/icons";
import { Button, Checkbox, Radio } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import ModalContext from "../../store/ModalContext";
import AuthContext from "../../store/AuthContext";
import BuilderContext from "../../store/BuilderContext";
import { HeaderContainerWidget, BackgroundWidget, ContainerWidget, StartPageImgVideoWidget, StartPageTextWidget, TextEditWidget, ImageWidget, SectionWidget, ProgressIndicatorWidget, SectionProgressBarWidget, QuestionWidget, AnswerSliderWidget, AnswerSliderOptionsWidget, AnswerTextareaOptionsWidget, AnswerRadioWidget, PrevBtnWidget, NextBtnWidget, ResultsTemperatureScaleWidget, ButtonWidget } from '../AssessmentBuilder/Tools';
import header from '../../assets/img/AddNewBlockImages/header.svg';
import bleatLogo from '../../assets/img/AddNewBlockImages/bleat-logo.svg';
import button from '../../assets/img/AddNewBlockImages/button.svg';
import container from '../../assets/img/AddNewBlockImages/container.svg';
import image from '../../assets/img/AddNewBlockImages/image.svg';
import paragraph from '../../assets/img/AddNewBlockImages/paragraph.svg';

const AddNewBlock = (props) => {  
  const [property, setProperty]                       = useState("");
  const [showProperty, setShowProperty]               = useState(false);
  const [uniqueProperties, setUniqueProperties]       = useState();
  const [builderConfirmation, setBuilderConfirmation] = useState(false)
  const [blockPosition, setBlockPosition]             = useState('');
  const [showBlockPosition, setShowBlockPosition]     = useState(false);
  const [filteredBlockPositions, setFilteredBlockPositions] = useState([]);

  const modalCtx     = useContext(ModalContext);
  const authCtx      = useContext(AuthContext);
  const builderCtx   = useContext(BuilderContext);
  const { assessmentId, contentBlocks, currentContentBlock } = builderCtx;
  const contentType  = currentContentBlock?.contentType; 

  const filterBlockPositions = (input) => {
    const filtered = contentBlocks.filter(blockPosition => 
      blockPosition.id.toString().includes(input.toString()));

    setFilteredBlockPositions(filtered);
  };

  const setCurrentProperty = (e, property, id) => {
    builderCtx.setContentBlocks((prevBlocks) => {
      return prevBlocks.map((block) => {
        if (block.id === id) {
          return { ...block, styling: { ...block.styling, [property]: e.target.value } };
        }
        return block;
      });
    });
  };

  const contentTypeList = [
    { type: "headerContainer", component: <HeaderContainerWidget setCurrentProperty={setCurrentProperty} /> },
    { type: "Background", component: <BackgroundWidget /> },
    { type: "Container", component: <ContainerWidget /> },
    { type: "StartPageImgVideo", component: <StartPageImgVideoWidget /> },
    { type: "StartPageText", component: <StartPageTextWidget /> },
    { type: "LogoText", component: <TextEditWidget key="start-logo-text" /> },
    { type: "LogoSubtext", component: "LogoSubtext" },
    { type: "Image", component: <ImageWidget key="start-image" setCurrentProperty={setCurrentProperty} /> },
    { type: "BleatLogo", component: <ImageWidget key="bleat-logo" setCurrentProperty={setCurrentProperty} /> },
    { type: "heading", component: <TextEditWidget key="heading" /> },
    { type: "paragraph", component: <TextEditWidget key="paragraph" /> },
    { type: "duration", component: <TextEditWidget key="start-duration" /> },
    { type: "SectionsBar", component: <SectionWidget /> },
    { type: "ProgressIndicator", component: <ProgressIndicatorWidget /> },
    { type: "SectionProgressBar", component: <SectionProgressBarWidget /> },
    { type: "Question", component: <QuestionWidget /> },
    { type: "AnswerSlider", component: <AnswerSliderWidget /> },
    { type: "AnswerSliderOptions", component: <AnswerSliderOptionsWidget /> },
    { type: "AnswerTextareaOptions", component: <AnswerTextareaOptionsWidget /> },
    { type: "AnswerRadio", component: <AnswerRadioWidget /> },
    { type: "AnswerRadioOptions", component: <AnswerRadioWidget /> },
    { type: "PrevButton", component: <PrevBtnWidget /> },
    { type: "NextButton", component: <NextBtnWidget /> },
    { type: "ResultsHeading", component: <TextEditWidget key="results-heading" /> },
    { type: "ResultsTemperatureScale", component: <ResultsTemperatureScaleWidget /> },
    { type: "button", component: <ButtonWidget /> },
  ];

  const filteredSuggestions = uniqueProperties?.filter(suggestion =>
    suggestion.toLowerCase().includes(property?.toLowerCase())
  );

  const addProperty = () => {
    const payload = {
      "propType": property,
      // "property": value,
    }

    fetch(process.env.REACT_APP_API_URI + `/v3/admin/assessment/${assessmentId}/content/${blockPosition}/property`, {
      method: "POST",
      headers: { "Content-Type": "application/json", 'Authorization': 'Bearer ' + authCtx.token},
      body: JSON.stringify(payload)
    })
    .then((response) => response.json())
    .then((data) => {
      if ( data.status === 'success' ) modalCtx.closeModal(); 
    });
  }

  const getContentTypeImage = (contentType) => {
    switch (contentType) {
      case 'header':
        return <img src={header} alt="Header" />;
      case 'paragraph':
        return <img src={paragraph} alt="Paragraph" />;
      case 'Container':
        return <img src={container} alt="Container" />;
      case 'Image':
        return <img src={image} alt="Image" />;
      case 'button':
        return <img src={button} alt="Button" />;
      case 'BleatLogo':
        return <img src={bleatLogo} alt="Bleat Logo" />;
      case 'headerContainer':
        return <img src={header} alt="Header Container" />;
      case 'Background':
        return <img src={image} alt="Background" />;
      case 'StartPageImgVideo':
        return <img src={image} alt="Start Page Image/Video" />;
      case 'StartPageText':
        return <img src={paragraph} alt="Start Page Text" />;
      case 'LogoText':
        return <img src={paragraph} alt="Logo Text" />;
      case 'LogoSubtext':
        return <img src={paragraph} alt="Logo Subtext" />;
      case 'duration':
        return <img src={paragraph} alt="Duration" />;
      case 'SectionsBar':
        return <img src={container} alt="Sections Bar" />;
      case 'ProgressIndicator':
        return <img src={container} alt="Progress Indicator" />;
      case 'SectionProgressBar':
        return <img src={container} alt="Section Progress Bar" />;
      case 'Question':
        return <img src={paragraph} alt="Question" />;
      case 'AnswerSlider':
        return <img src={container} alt="Answer Slider" />;
      case 'AnswerSliderOptions':
        return <img src={container} alt="Answer Slider Options" />;
      case 'AnswerTextareaOptions':
        return <img src={container} alt="Answer Textarea Options" />;
      case 'AnswerRadio':
        return <img src={container} alt="Answer Radio" />;
      case 'AnswerRadioOptions':
        return <img src={container} alt="Answer Radio Options" />;
      case 'PrevButton':
        return <img src={button} alt="Previous Button" />;
      case 'NextButton':
        return <img src={button} alt="Next Button" />;
      case 'ResultsHeading':
        return <img src={header} alt="Results Heading" />;
      case 'ResultsTemperatureScale':
        return <img src={container} alt="Results Temperature Scale" />;
      default:
        return null;
    }
  };

  useEffect(() => {
    const uniqueProperties = [...new Set(contentBlocks.flatMap(obj => Object.keys(obj.properties)))];
    setUniqueProperties(uniqueProperties);
  }, [contentBlocks]);

  return (
    <div className="modal create-new-block-modal" style={{ "borderRadius": "10px" }}>
      <div className="heading-and-close-btn-container">
        <h1>Create New Block</h1>
        <Button onClick={()=> modalCtx.closeModal()}>
          <CloseOutlined/>
        </Button>
      </div>
      <div className="block-position-input-container">
        <h3>Do you want to create it before or after the {contentType} ?</h3>
        <div className="before-after-option-container">
          <div>
            <Radio 
              checked={blockPosition === 'before'}
              onChange={(e) => {
                setBlockPosition('before');
                setShowBlockPosition(true);
                filterBlockPositions('before');
              }}
            />
            <label>Before {contentType}</label>
          </div>
          <div>
            <Radio 
              checked={blockPosition === 'after'}
              onChange={(e) => {
                setBlockPosition('after');
                setShowBlockPosition(true);
                filterBlockPositions('after');
              }}
            />
            <label>After {contentType}</label>
          </div>
        </div>
      </div>
      <div className="content-block-input-container">
        <h3>Type of block</h3>
          <div className="content-type-container">
            {
              contentTypeList.map((suggestion, index) => (
                <div
                  className="content-type-and-content-type-image-container"
                  onClick={() => {
                    setProperty(suggestion.type);
                    setShowProperty(false);
                  }}
                >
                  {getContentTypeImage(suggestion.type)}
                  <p key={index}>{suggestion.type}</p>
                </div>
              ))
            }
          </div>
      </div>
      <div className="all-builders-confirmation">
        <Checkbox 
          checked={builderConfirmation} 
          onChange={() => setBuilderConfirmation(prevState => !prevState)} 
          inputProps={{ 'aria-label': 'controlled' }} 
        />
        <h3 className="checkbox-label">Add this to all default builders</h3>
      </div>
      <div className="create-btn-container">
        <Button onClick={addProperty}>Create</Button>
      </div>
    </div>
  );
}

export default AddNewBlock;
