import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const Font = (props) => {

  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  console.log("currentBlockStyles", currentBlockStyles);
  const currentWeight      = currentBlockStyles?.fontWeight;
  const currentFontSize    = currentBlockStyles?.fontSize?.toString()?.match(/[a-zA-Z]+|[0-9]+|[%]/g);
  // const currentSize        = currentBlockStyles?.fontSize;
  // console.log("currentWeight", currentWeight);
  // console.log("currentSize", currentSize);
  // console.log("currentFontSize", currentFontSize);

  const units = [
    { value: 'px',    label: 'px'  },
    { value: 'rem',   label: 'rem' },
    { value: '%',     label: '%'   },
    { value: 'vh',    label: 'vh'  },
    { value: 'vw',    label: 'vw'  },
  ];

  const weights = [
    { value: '100', label: '100' },
    { value: '200', label: '200' },
    { value: '300', label: '300' },
    { value: '400', label: '400' },
    { value: '500', label: '500' },
    { value: '600', label: '600' },
    { value: '700', label: '700' },
    { value: '800', label: '800' },
    { value: '900', label: '900' },
  ];

  const [weight, setWeight]         = useState(currentWeight || "400");
  const [size, setSize]             = useState(currentFontSize?.[0] || "16");
  const [unit, setUnit]             = useState(currentFontSize?.[1] || "px");
  const [finalSize, setFinalSize]   = useState("");
  const authCtx                     = useContext(AuthContext);
  
  const handleWeightChange = (e) => setWeight(e.target.value);

  const handleSizeChange = (e) => setSize(e.target.value);

  const handleUnitChange = (e) => setUnit(e.target.value);

  const updateFontSizeInDB = () => {
    let payload = {
      id: blockId,
      value: finalSize,
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/fontSize', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating fontSize payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update fontSize...", err);
    });
  }

  const updateFontWeightInDB = () => {
    let payload = {
      id: blockId,
      value: weight,
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/fontWeight', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating fontWeight payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update fontWeight...", err);
    });
  }

  useEffect(() => {
    setFinalSize(size + unit);
  }, [size, unit])


  useEffect(() => {
    
    updateContentBlocks(contentBlocks?.map(block => {
      if (block.id === blockId) {
        let newStyles = { ...block.styling };
        newStyles.fontSize   = finalSize;
        newStyles.fontWeight = weight;
        block.styling = { ...newStyles };

        updateFontSizeInDB()
        updateFontWeightInDB()

        return block;
      } else return block;
    }))

  }, [blockId, finalSize, weight]);


  // console.log("blockId", blockId);
  // console.log("fontWeight", weight);
  // console.log("fontSize", size);
  

  return (
    <div className="font-styles tool">
      
    <div className="font-size-container">
       <div className="styling-text-delete-icon-container">
          <p className="styling-text">Font Size:</p>
          {props.deleteModal}
       </div>
       <div className="font-unit-and-value-container">
          <TextField id="weight-number" className="fontsize-input styling-input" type="number" variant="filled"
          InputLabelProps={{ shrink: true, }} placeholder={currentContentBlock?.styling?.fontSize + ""} value={size} onChange={handleSizeChange} />
          <TextField className="fontsize-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
            // placeholder={currentFontSize[1] + ""} 
            value={unit || "px"} onChange={(e) => {
              // console.log("targetVal", e.target.value);
              handleUnitChange(e);
            }} >
              {units?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
          </TextField>
       </div>
    </div>

    <div className="font-weight-container">
      <div className="styling-text-delete-icon-container">
        <p className="styling-text">Font Weight:</p>
        {props.deleteModal}
      </div>
      <TextField id="select-style-unit" className="fontweight-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }} placeholder={currentContentBlock.styling?.fontWeight + ""} value={weight} onChange={(e) => {
        // console.log("targetVal", e.target.value);
        handleWeightChange(e);
      }} >
        {weights?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </div>

    </div>
  )
}


export default Font;


