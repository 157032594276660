import { useState, useEffect, useContext, Fragment } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { CircularProgress, Pagination, Tabs, Tab, Box, Button, Link, Tooltip, IconButton } from '@mui/material';
import { Clear, Close, Delete, Info, Remove } from '@mui/icons-material';

import {
  DataGrid, useGridApiContext, gridFilterStateSelector,
  // GridSelectionModel, useGridApiRef, gridPaginatedVisibleSortedGridRowIdsSelector, gridPageSelector, gridPageCountSelector, useGridSelector, gridSortedRowIdsSelector, gridVisibleSortedRowIdsSelector, GridToolbar 
} from '@mui/x-data-grid';


import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import UsersContext from '../../store/UsersContext.js';
import DrawerContext from "../../store/DrawerContext.js";
import DataGridContext from '../../store/DataGridContext.js';
import AssessmentDashboardPage from '../AssessmentDashboardPage.jsx';
import calendlyLogo from "../../assets/img/calendly.svg";
import infoIcon from "../../assets/img/infoIcon.svg";
import { getCentralDate } from '../../globalFunctions.js';

const AssessmentUsers = (props) => {


  const { id } = useParams();
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const usersCtx = useContext(UsersContext);
  const drawerCtx = useContext(DrawerContext);
  const datagridCtx = useContext(DataGridContext);
  
  const envURI = process.env.REACT_APP_API_URI;
  const assessDetails = props.assessDetails;

  const [allUsersDetails, setAllUsersDetails] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredAllUsers, setFilteredAllUsers] = useState(allUsersDetails);
  const [tabValue, setTabValue] = useState(0);
  const [selectionModel, setSelectionModel] = useState([]);
  const [datagridPage, setDatagridPage] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [nextPage, setNextPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [totalRecords, setTotalRecords] = useState(0);
  const [allUniqueOrgs, setAllUniqueOrgs] = useState([]);
  // fetch page 2 users in the background after initial page 1 users have been fetched
  // const [fetchNextPageUsers, setFetchNextPageUsers] = useState(false);
  const [fetchNextPageUsers, setFetchNextPageUsers] = useState(false);
  const [fetchedAllUsers, setFetchedAllUsers] = useState(false);
  const [fetchingFirstPageUsers, setFetchingFirstPageUsers] = useState(true);
  const [currentTabText, setCurrentTabText] = useState("Users");
  const [currentTabTextVal, setCurrentTabTextVal] = useState(allUsersDetails?.length);
  const [refreshPageToggle, setRefreshPageToggle] = useState(false);
  const [inviteDetails, setInviteDetails] = useState(null);

  const [usersCols, setUsersCols] = useState([]);
  const [usersRows, setUsersRows] = useState([]);
  
  const [usersColsMod, setUsersColsMod] = useState([]); // usersCols modified
  const [usersRowsMod, setUsersRowsMod] = useState([]); // usersRows modified


  useEffect(() => {
    const customCols = usersCols.map((col) => {
      if (col.field === "Status") {
        return {
          ...col, cellClassName: "custom-status", headerClassName: "status-header",
          renderCell: (params) => params.row.Status?.toLowerCase() === "complete" ? <span className="status-complete">{params.row.Status}</span> : <span className="status-pending">{params.row.Status}</span>
        }
      }
      if (col.field === "Actions") {
        return {
          ...col, cellClassName: "custom-actions", headerClassName: "actions-header",
          renderCell: (params) => {
            return <>
            <Tooltip title="Delete User Details">
              <IconButton>
                <Delete className="action-btn" onClick={() => deleteIndividualUser(params.row.id)} />
              </IconButton>
            </Tooltip>
            </>
          }
        }
      }
      return col;
    })
    setUsersColsMod(customCols);

  }, [usersCols]);


  useEffect(() => {
    usersCtx.setAllUsers(allUsersDetails);
    usersCtx.setAssessmentDetails(assessDetails);
    usersCtx.setAssessmentId(id);
  }, [allUsersDetails, assessDetails, id]);


  useEffect(() => {
    if (usersCtx?.allUsers?.length < allUsersDetails?.length) {
      updateUsersFromUsersContext();
    }
  }, [usersCtx?.allUsers, usersCtx?.allUsers?.length]);

  useEffect(() => {
    if (id !== usersCtx?.assessmentId) {
      setAllUsersDetails([]);
      setFilteredAllUsers([]);
      setTotalRecords(0);
      setFetchingFirstPageUsers(true);
      usersCtx.setAssessmentId(id);
    }
  }, [id, usersCtx?.assessmentId]);

  useEffect(() => {
    if (totalRecords === allUsersDetails?.length) {
      setFetchedAllUsers(true);
    } else setFetchedAllUsers(false);
  }, [totalPages, totalRecords, allUsersDetails]);

  useEffect(() => {
    setAllUsersDetails([]);
    setFilteredAllUsers([]);
    usersCtx.setAllUsers([]);
    usersCtx.setAssessmentId(id);
    const fetchInBackground = false;
    // getUsers(currentPage, fetchInBackground);
    setCurrentPage(1);
    getUsers(1, fetchInBackground);
  }, [id]);

  useEffect(() => {
    if (fetchNextPageUsers) {
      const fetchInBackground = true;
      getUsers(nextPage, fetchInBackground);
    }
  }, [fetchNextPageUsers, nextPage]);

  useEffect(() => {
    if (!totalPages || (currentPage >= totalPages)) {
      setFetchNextPageUsers(false);
    }
  }, [currentPage, totalPages]);


  useEffect(() => {
    if (modalCtx.pageUpdate?.page === "assessmentsUsersV2") {
      console.log("\n\nPage update requested. Refetching all users...");
      const fetchInBackground = false;
      // getUsers(currentPage, fetchInBackground);
      setAllUsersDetails([]);
      setFilteredAllUsers([]);
      setCurrentPage(1);
      getUsers(1, fetchInBackground);
      modalCtx.setPageUpdate({ page: "" });
      // setSelectionModel([]);
      setSearchTerm("");
    } else {
      console.log("No page update requests detected...");
    }
  }, [modalCtx, modalCtx.pageUpdate, modalCtx.pageUpdate?.page]);





  // user management functions 

  const getUsers = async (page, fetchInBackground) => {

    if (id !== usersCtx?.assessmentId) {
      setAllUsersDetails([]);
      setFilteredAllUsers([]);
    }

    try {
      // this resets users to 0 so when assessment ID changes, page does not show previous assessment users
      // setAllUsersDetails([]);
      const options = {
        method: 'GET', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
      };
      const fetchUsersURL = `${envURI}/v3/admin/assessment/${id}/users`;
      const response = await fetch(fetchUsersURL, options);
      const data = await response.json();

      console.log("/v3/users data", data);
      if (data.status === "success") {
        if (data.currentPage >= 1) {
          setFetchingFirstPageUsers(false);
        }
        if (id === usersCtx.assessmentId) {
          // console.log("users", data?.users);
          const users = [...allUsersDetails, ...data?.data];
          const uniqueAllUsers = users?.filter((user, index) => {
            return users.findIndex((u) => u.id === user.id) === index;
          });
          setAllUsersDetails(uniqueAllUsers);
        } else {
          setAllUsersDetails(data?.data);
        }
        setInviteDetails(data?.details);
        setTotalPages(data?.totalPages);
        setCurrentPage(data?.currentPage);
        setNextPage(data?.currentPage + 1);
        // setTotalRecords(data?.totalRecords);
        setTotalRecords(data?.data?.length);
        setUsersCols(data?.fields);
        setUsersRows(data?.data);
        setUsersRowsMod(data?.data);
        setFetchingFirstPageUsers(false);
        if (data?.currentPage >= data?.totalPages) setFetchNextPageUsers(false);
        else setFetchNextPageUsers(true);
        return data;
      }
    } catch (err) {
      console.error("Failed to fetch users data...", err);
      setFetchingFirstPageUsers(false);
      if (id !== usersCtx.assessmentId) {
        setAllUsersDetails([]);
      }
      return null;
    }
  }

  console.log("\n\nallUsersDetails", allUsersDetails);
  console.log("filteredAllUsers", filteredAllUsers, "\n\n");


  function deleteIndividualUser(userId) {
    modalCtx.setDetails("users-delete-confirmation", { users: [userId], setRefreshPageToggle: setRefreshPageToggle, setAllUsersDetails: setAllUsersDetails, assessmentId: id });
    modalCtx.openModal();
    console.log("UserID to delete:\n", userId);
  }

  function bulkDeleteUsers() {
    modalCtx.setDetails("users-delete-confirmation", { users: selectionModel, setRefreshPageToggle: setRefreshPageToggle, setAllUsersDetails: setAllUsersDetails, assessmentId: id });
    modalCtx.openModal();
    console.log("UserIDs to delete:\n", selectionModel);
  }

  function updateUsersFromUsersContext() {
    setAllUsersDetails(usersCtx?.allUsers);
    setFilteredAllUsers(usersCtx?.allUsers);
  }


  // handler functions 

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  function toggleModalHandler(modal, user) {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  const tabChangeHandler = (event, newValue) => {
    // console.log("newValue", newValue);
    let tab = "Users";
    
  };



  return (
    <div className="assessment-page assessment-page-v2" style={{ padding: 0 }}>
      <div className="tab-container">

        <Box sx={{ width: '100%' }}>
          <Box className="box" sx={{ borderBottom: 1, borderColor: '#EDEDED' }}>
            <Tabs TabIndicatorProps={{ style: { background: '#F2D466', height: '4px' } }} value={tabValue} onChange={tabChangeHandler} aria-label="basic tabs example">
              {/* TODO: these tabs details will also come from API so update accordingly later */}
              <Tab className="tab-title" label={`All Users (${totalRecords})`} {...a11yProps(0)} />
              {selectionModel.length > 0 && <Button className="black-btn" style={{ padding: "5px 10px", maxHeight: "36px", alignSelf: "center" }} onClick={bulkDeleteUsers}><Delete /> Bulk Delete</Button>}
            </Tabs>
          </Box>
          <TabPanel value={tabValue} index={0} className="users-table">
            {/* <TextField id="outlined-basic" label="Type to search..." variant="outlined" placeholder="Type to search..." /> */}


            {(usersRows?.length >= 0 && !fetchingFirstPageUsers) ? <DataGrid
              initialState={{ sorting: { sortModel: [{ field: 'date', sort: 'desc' }] } }}
              // rows={filteredAllUsers}
              // columns={columnsAllUsersv2}
              rows={usersRowsMod}
              columns={usersColsMod}
              components={{
                Toolbar: DataGridState,
              }}
              pagination
              rowCount={totalRecords}
              pageSize={50}
              page={datagridPage}
              onPageChange={(newPage) => setDatagridPage(newPage)}
              onColumnVisibilityModelChange={(change) => console.log("columnVisibilityModelChange", change)}
              columnVisibilityModel={{
                // Hide columns status and traderName, the other columns will remain visible
                id: false,
              }}
              checkboxSelection
              onFilterModelChange={(filter) => { console.log("filter", filter) }}
              selectionModel={selectionModel}
              onSelectionModelChange={(newSelectionModel) => {
                console.log("filter", newSelectionModel);
                setSelectionModel(newSelectionModel);
              }}
              // disables all advanced column sorting options available through menu 
              // disableColumnMenu
              disableSelectionOnClick
              // rowsPerPageOptions={[5]}
              rowsPerPageOptions={[100]}
            /> : (<section className="loader-section"><CircularProgress /></section>)}

          </TabPanel>

        </Box>
        <p className="total-assessments">Total {currentTabText}: <strong>{totalRecords}</strong></p>
      </div>
    </div>
  )
}



export default AssessmentUsers;


const DataGridState = () => {
  const apiRef = useGridApiContext();
  const datagridCtx = useContext(DataGridContext);

  const filterState = gridFilterStateSelector(apiRef.current.state);
  // console.log("filterState", filterState?.filteredRowsLookup);
  console.log("filterState", filterState);

  useEffect(() => {
    let filteredIDs = filterState?.filteredRowsLookup;
    let filteredUserIDsArr = [];
    for (let key in filteredIDs) {
      if (filteredIDs[key] === true) {
        filteredUserIDsArr.push(parseInt(key))
      }
    }
    datagridCtx.updateFilteredDataGridUsers(filteredUserIDsArr);
    // console.log("userIDs", filteredUserIDsArr);
  }, [filterState, datagridCtx.tab])

  return null;
};


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} 
    aria-labelledby={`simple-tab-${index}`} {...other} >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

