import React, { useState, useEffect, useContext } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from "../../../../store/BuilderContext";
import AuthContext from "../../../../store/AuthContext";

const HoriVertiPositioning = (props) => {


  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx= useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;


  // justifyContent justify-content 
  const horizontal = [
    { value: 'initial', label: 'initial' },
    { value: 'inherit', label: 'inherit' },
    { value: 'flex-start', label: 'start' },
    { value: 'flex-end', label: 'end' },
    { value: 'center', label: 'center' },
    { value: 'left', label: 'left' },
    { value: 'right', label: 'right' },
    { value: 'space-around', label: 'space around' },
    { value: 'space-between', label: 'space between' },
    { value: 'space-evenly', label: 'space evenly' },
  ];

  // alignItems align-items 
  const vertical = [
    { value: 'initial', label: 'initial' },
    { value: 'inherit', label: 'inherit' },
    { value: 'stretch', label: 'stretch' },
    { value: 'baseline', label: 'baseline' },
    { value: 'center', label: 'center' },
    { value: 'flex-start', label: 'start' },
    { value: 'flex-end', label: 'end' },
    { value: 'revert', label: 'revert' },
  ];

  const [justifyContent, setJustifyContent] = useState(currentBlockStyles?.justifyContent || "flex-start");
  const [alignItems, setAlignItems] = useState(currentBlockStyles?.alignItems || "stretch");
  // const [finalSize, setFinalSize] = useState("");


  const handleHorizontalChange = (e) => setJustifyContent(e.target.value);

  const handleVerticalChange = (e) => setAlignItems(e.target.value);


  useEffect(() => {
    // setFinalSize();
    console.log('justifyContent',justifyContent)
  }, [alignItems, justifyContent]);

  const updateJustifyContent = (justifyContentProperty) => {
    let payload = {
      id: blockId,
      value: justifyContentProperty,
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/justifyContent', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating justifyContent payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update justifyContent...", err);
    });
  }

  const updateAlignItems = (alignItemsProperty) => {
    let payload = {
      id: blockId,
      value: alignItemsProperty,
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/alignItems', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating alignItems payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update alignItems...", err);
    });
  }

  useEffect(() => {

    updateContentBlocks(contentBlocks?.map(block => {
      if (block.id === blockId) {
        let newStyles = { ...block.styling };
        newStyles.alignItems = alignItems;
        newStyles.justifyContent = justifyContent;
        block.styling = { ...newStyles };

        updateJustifyContent(justifyContent)
        updateAlignItems(alignItems)

        return block;
      } else return block;
    }))

  }, [blockId, alignItems, justifyContent]);


  // console.log("blockId", blockId);
  // console.log("alignItems", alignItems);
  // console.log("justifyContent", justifyContent);


  return (
    <div className="positioning tool">

      <div className="object-spacing">
        <div className="styling-text-delete-icon-container">
          <p className="styling-text">Object Spacing:</p>
          {props.deleteModal}
        </div>
        <TextField className="justifycontent-unit styling-select" select variant="standard"
          InputProps={{ disableUnderline: true }}
          // placeholder={currentContentBlock?.styling?.justifyContent + ""} 
          value={justifyContent} onChange={handleHorizontalChange} >
          {horizontal?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>


      <div className="vertical-alignment">

        <div className="styling-text-delete-icon-container">
          <p className="styling-text">Vertical Alignment:</p>
          {props.deleteModal}
        </div>
        <TextField className="alignitems-unit styling-select" select variant="standard" InputProps={{ disableUnderline: true }}
          // placeholder={currentContentBlock?.styling?.alignItems + ""} 
          value={alignItems} onChange={(e) => {
            // console.log("targetVal", e.target.value);
            handleVerticalChange(e);
          }} >
          {vertical?.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </div>


    </div>
  )


}

export default HoriVertiPositioning;

