import React, { useContext, useState } from 'react';

import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { CircularProgress } from "@mui/material";
import { Delete } from '@mui/icons-material';

const DeleteAssessmentPage = (props) => {

  console.log("DeleteScores props", props);

  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);


  const [isLoading, setIsLoading] = useState("");
  const [error, setError] = useState(false);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const assessmentHash = props.details?.hash;

  const handleDeleteScores = (e) => {
    e.preventDefault();

    setIsLoading(true);
    const apiURL = `${process.env.REACT_APP_API_URI}/v3/admin/assessment/${props.details.id}/page/${props.details.pageId}`;

    fetch(apiURL, {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      if (data.status === "success") {
        console.log("Successfully deleted user's scores...");
        setIsLoading(false);
        setError(false);
        // console.log("setting pageUpdate to update all users table...");
        // modalCtx.setPageUpdate({ page: "assessmentsUsers" });
        modalCtx.setPageUpdate({ page: "userDetailsDrawer" });
        handleCloseModal();
      } else {
        console.log("Failed to delete user's scores...");
      }
    })
    .catch(err => {
      setIsLoading(false);
      setError(true);
      console.error("Failed to delete user's scores...", err)
    });

  }

  return (
    <div className='modal'>
      {isLoading ?
        <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></section>
        :
        <>
          <p style={{ margin: "5px auto 30px" }}>Are you sure? This will delete all the data related to this page.</p>
          {error ? <p style={{ color: "crimson", fontSize: "16px", margin: "-10px auto 30px" }}>Error deleting page. Please try again.</p> : null}
          <div className="footer" style={{ margin: "15px auto 0" }}>
            <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
            <Button type="submit" className="confirm-btn" onClick={(e) => handleDeleteScores(e)}><Delete style={{ height: "20px", width: "auto" }} /> &nbsp;Delete</Button>
          </div>
        </>
      }
    </div>
  )
}

export default DeleteAssessmentPage;
