import { useState, useEffect, useContext, Fragment } from 'react';
import { useNavigate, useParams } from "react-router-dom";

import { CircularProgress, Pagination, Tabs, Tab, Box, Button, Link, IconButton } from '@mui/material';
import { Clear, Close, Delete, Done, ExitToApp, Phone, Search, SearchOutlined, InfoOutlined } from '@mui/icons-material';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import GetAppIcon from '@mui/icons-material/GetApp';

import {
  DataGrid, useGridApiContext, gridFilterStateSelector,
  // GridSelectionModel, useGridApiRef, gridPaginatedVisibleSortedGridRowIdsSelector, gridPageSelector, gridPageCountSelector, useGridSelector, gridSortedRowIdsSelector, gridVisibleSortedRowIdsSelector, GridToolbar 
} from '@mui/x-data-grid';



import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import UsersContext from '../../store/UsersContext.js';
import DrawerContext from "../../store/DrawerContext.js";
import DataGridContext from '../../store/DataGridContext.js';
import AssessmentDashboardPage from '../AssessmentDashboardPage.jsx';
import calendlyLogo from "../../assets/img/calendly.svg";
import infoIcon from "../../assets/img/infoIcon.svg";
import { getCentralDate } from '../../globalFunctions.js';
import AssessmentUsers from './AssessmentUsers.jsx';




const AssessmentPageV2 = () => {


  const { id } = useParams();
  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext);
  const usersCtx = useContext(UsersContext);
  const drawerCtx = useContext(DrawerContext);
  const datagridCtx = useContext(DataGridContext);

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [assessDetails, setAssessDetails] = useState({});
  const [isExporting, setIsExporting] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  
  // Selected Rows in DataGrid 
  const [selectionModel, setSelectionModel] = useState([]);

  // to show question columns for all users with their answers 
  const [questions, setQuestions] = useState([]);


  useEffect(() => {
    getAssessmentDetails();
  }, [id]);

  

  // handler functions 

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  function toggleModalHandler(modal, user) {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  const tabChangeHandler = (event, newValue) => {
    // console.log("newValue", newValue);
    setTabValue(newValue);
  };

  const envURI = process.env.REACT_APP_API_URI;
  const getAssessmentDetails = () => {
    let options = {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    fetch(`${envURI}/v1/admin/assessment/${id}`, options)
      .then(response => response.json())
      .then((data) => {
        console.log(data);
        if (data.status === "success") {
          setTitle(data?.details?.title);
          setAssessDetails(data?.details);
        }
        setIsLoading(false);
      }).catch((err) => {
        console.log("Failed to fetch assessment details...", err);
        setIsLoading(false);
      })
  }


  let replace = /Assessment/gi;
  let assessmentName = title.toString().replace(replace, "").trim();

  if (isLoading && (usersCtx.allUsers?.length >= 0)) {
    return <section className="loader-section"><CircularProgress /></section>
  }

  return (
    <>
      <div className="assessment-name">
        <div data-aos="fade-right">
          <h1><a style={{ textDecoration: "none", }} href={"/assessments/" + id + "/dashboard"}>{assessmentName} Assessment</a></h1>
          {assessDetails?.url && <p className="assessment-link-text">Link to assessment: <a className="assessment-link" href={assessDetails?.url} target="_blank" rel="noopener noreferrer">{assessDetails?.url}</a></p>}
        </div>
      </div>

      <div className="tab-container">

        <Box className="box1" sx={{ width: '100%' }}>
          {/* <Box className="box" sx={{ borderBottom: 1, borderColor: '#EDEDED' }}>
            <Tabs TabIndicatorProps={{ style: { background: '#F2D466', height: '4px' } }} value={tabValue} onChange={tabChangeHandler} aria-label="basic tabs example">
              <Tab className="tab-title" label={`All Users`} {...a11yProps(0)} />
            </Tabs>
          </Box> */}
          {/* <TabPanel value={tabValue} index={0} className=""> */}
            <AssessmentUsers assessDetails={assessDetails} />
          {/* </TabPanel> */}
        </Box>

      </div>
    </>
  )
}


export default AssessmentPageV2;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} 
    aria-labelledby={`simple-tab-${index}`} {...other} >
      {value === index && (
        <Box sx={{ p: 3 }}>{children}</Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

