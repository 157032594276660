import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import CreatableSelect from "react-select/creatable";

import {  TextField,  Select,  MenuItem,  FormControl,  InputLabel,  Button,  Checkbox} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import DrawerContext from "../../store/DrawerContext";

function CreateAssessmentField(props) {

  console.log("CreateAssessmentField props", props);

	const drawerCtx                           = useContext(DrawerContext);

  const [fieldName, setFieldName]           = useState("");
  const [placeholder, setPlaceholder]       = useState("");
  const [fieldType, setFieldType]           = useState("");
  const [defaultValue, setDefaultValue]     = useState("");
  const [value, setValue]                   = useState("");
  const [width, setWidth]                   = useState("50");
  const [order, setOrder]                   = useState(0);
  const [label, setLabel]                   = useState("");
  const [acFieldID, setAcFieldID]           = useState("");
  const [showForMembers, setShowForMembers] = useState(false);

  const [selectedOption, setSelectedOption] = useState(null);
  const [selectOptions, setSelectOptions]   = useState([
    { value: "first_name", label: "first_name" },
    { value: "last_name", label: "last_name" },
    { value: "full_name", label: "full_name" },
    { value: "email", label: "email" },
    { value: "church_name", label: "church_name" },
    { value: "job_title", label: "job_title" },
    { value: "country", label: "country" },
    { value: "zip_code", label: "zip_code" },
  ]);

  const id = props.details.client_id;


  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");

    // client_id: id, 
    // field_name: fieldName, 
    // default_value: defaultValue, 

    const fieldArr = [{ 
      order: order, 
      label: label, 
      placeholder: placeholder, 
      value: value,
      fieldType: fieldType, 
      acFieldID: acFieldID, 
      showForMembers: showForMembers,
      width: width, 
    }];

    fetch(process.env.REACT_APP_API_URI + "/v2/admin/assessment/" + id + "/fields", {
      method: "POST",
      // body: JSON.stringify({ client_id: id, field_name: fieldName, placeholder: placeholder, field_type: fieldType, default_value: defaultValue, width: width, ac_field_id: acFieldID }),
      body: JSON.stringify({ assessment_id: id, page_id: 1, data: fieldArr }),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
    drawerCtx.closeDrawer();
  };

  const reactSelectStyles = {
    control: (provided, state) => ({
      ...provided, backgroundColor: '#fff', padding: '0 5px', margin: '15px auto 7px',
      '&:hover': { borderColor: '#aaa' }, // set hover border color
    }),
  };

  console.log("fieldName", fieldName);

  return (
    <div className="drawer">
      <header><h1 data-aos="fade-right" data-aos-delay="500">Add Field</h1></header>

      <section>

        {/* <TextField required fullWidth className="form-control-add-question margin-top"
          label="Field Name" placeholder="church_name" value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
          variant="outlined" InputLabelProps={{ shrink: true }} /> */}

        <p style={{ margin: "10px auto 0px" }}>Note: <strong>field_name</strong> cannot have spaces or other special characters except "_".</p>
        <CreatableSelect classNamePrefix="react-select" isClearable 
          onCreateOption={(val) => setSelectOptions((prev) => [...prev, { value: val, label: val }])}
          styles={reactSelectStyles} placeholder="Select a field_name or start typing to create one..."
          onChange={(option, meta) => {
            setSelectedOption(option);
            if(option?.value) setFieldName(option.value);
            else setFieldName("");
          }} 
          options={selectOptions} value={selectedOption}
        />
        
        <TextField required fullWidth className="form-control-add-question margin-top"
          label="Field Label" placeholder="example: Full Name" value={label}
          onChange={(e) => setLabel(e.target.value)}
          variant="outlined" InputLabelProps={{ shrink: true }} />
        
        <TextField required fullWidth type="number" className="form-control-add-question margin-top"
          label="Order" placeholder="Order" value={order}
          onChange={(e) => setOrder(e.target.value)}
          InputProps={{ inputProps: { min: 0 } }}
          variant="outlined" InputLabelProps={{ shrink: true }} />
 
        <TextField required fullWidth className="form-control-add-question margin-top"
          label="Placeholder" placeholder="example: John Doe" value={placeholder}
          onChange={(e) => setPlaceholder(e.target.value)}
          variant="outlined" InputLabelProps={{ shrink: true }} />
 
        <TextField required fullWidth className="form-control-add-question margin-top"
          select label="Field Type" placeholder="Field Type" value={fieldType}
          onChange={(e) => setFieldType(e.target.value)} variant="outlined" >
          
          <MenuItem className="section-name" value='text field'>Text Field</MenuItem>
          <MenuItem className="section-name" value='drop down selection'>Drop Down Selection</MenuItem>
          <MenuItem className="section-name" value='drop down selection with search'>Drop Down Selection With Search</MenuItem>
          <MenuItem className="section-name" value='toggle button'>Toggle Button</MenuItem>
          <MenuItem className="section-name" value='radio button'>Radio Button</MenuItem>

        </TextField>

        <TextField fullWidth className="form-control-add-question margin-top"
          label="Default Value" placeholder="Default Value" value={defaultValue}
          onChange={(e) => setDefaultValue(e.target.value)}
          variant="outlined" InputLabelProps={{ shrink: true }} />

        <TextField required fullWidth className="form-control-add-question margin-top"
          select label="Width" placeholder="Width" value={width}
          onChange={(e) => setWidth(e.target.value)} variant="outlined"
        >
          <MenuItem className="section-name" value='50'>50%</MenuItem>
          <MenuItem className="section-name" value='100'>100%</MenuItem>
        </TextField>

        <TextField fullWidth className="form-control-add-question margin-top"
          label="Active Campaign Field ID" placeholder="Active Campaign Field ID" value={acFieldID}
          onChange={(e) => setAcFieldID(e.target.value)}
          variant="outlined" InputLabelProps={{ shrink: true }} />

        {/* <TextField fullWidth className="form-control-add-question margin-top"
          label="Active Campaign Field ID" placeholder="Active Campaign Field ID"
          value={acFieldID}
          onChange={(e) => setAcFieldID(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        /> */}
 
        <div className="double-column-form aligned-right margin-top">
          <Button type="submit" 
            onClick={handleSubmit} variant="contained" component="label"
            // disabled={!fieldName || !placeholder || !fieldType || !width || !acFieldID}>
            disabled={!fieldName || !order || !placeholder || !fieldType || !width}>
            Add Field
          </Button>
        </div>
      </section>
    </div>
  );
}
export default CreateAssessmentField;
