import React, { useState, useEffect, useContext } from "react";
import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";
import { TextField, Select, MenuItem, FormControl, InputLabel, Button, Checkbox, CircularProgress } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import SubDrawerContext from "../../store/SubDrawerContext";
import ModalContext from "../../store/ModalContext";
import AddIcon from '../../assets/img/addIcon.png';
import dropIcon from '../../assets/img/dropIcon.png';
import AuthContext from "../../store/AuthContext";
import deleteIcon from '../../assets/img/Question Builder/DelIcon.svg'


const AddPieChartDrawer = (props) => {

  const drawerCtx = useContext(DrawerContext);
  const subDrawerCTx = useContext(SubDrawerContext);
  const modalCtx = useContext(ModalContext);
  const authCtx = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [segments, setSegments] = useState([]); // segments are chart segment values
  const [fetchSegments, setFetchSegments] = useState(true);
  const [signsArray, setSignsArray] = useState([
    { 'option': '==' },
    { 'option': '!=' },
    { 'option': '>' },
    { 'option': '<', },
    { 'option': '<=', },
    { 'option': '>=', },
  ])

  const assessmentId = props.details?.assessmentId;

  const chartId = props?.details?.id;

  const initialData = [{ label: '', variable: '', color: '#00', operator: "", value: "" }];

  console.log("AddPieChartDrawer props", props);


  useEffect(() => {
    if (fetchSegments) {
      getCharts();
      setFetchSegments(false);
    }
  }, [fetchSegments]);


  const getCharts = () => {
    let options = {
      method: 'GET', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    }

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/chart/' + chartId, options)
      .then(response => response.json())
      .then((data) => {
        if (data.status === "success") {
          // setCharts(data?.data)
          console.log("chart segments", data);
          setSegments(data?.data?.[0]?.values);
          setFetchSegments(false);
        }
      }).catch((err) => {
        console.log("Failed to fetch charts details...", err);
        setFetchSegments(false);
      })
  }

  console.log("segments", segments);

  const addPieChartSegment = (segmentData) => {

    const payload = segmentData;
    if (payload?.label && payload.variable && payload.operator && payload.value) {
      setIsLoading(true)

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/chart/' + chartId, {
        method: 'POST', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
      .then(data => {
        setIsLoading(false)
        if (data.status === "success") {
          console.log("successfully added chart", data);
          setFetchSegments(true);
        } else {
          console.error("Failed to add chart...", data);
          setIsLoading(false);
        }
      }).catch((err) => {
        console.error("Failed to add chart...", err);
        setIsLoading(false);
      });
    }
  }
  
  const updatePieChartSegment = (segmentData) => {

    const segmentId = segmentData?.id;

    const payload = segmentData;
    if (payload?.label && payload.variable && payload.operator && payload.value) {
      setIsLoading(true)

      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + assessmentId + '/chart/value/' + segmentId, {
        method: 'PUT', mode: 'cors',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(payload),
      }).then(response => response.json())
      .then(data => {
        setIsLoading(false)
        if (data.status === "success") {
          console.log("successfully updated chart segment...", data);
          setFetchSegments(true);
        } else {
          console.error("Failed to update chart segment...", data);
          setIsLoading(false);
        }
      }).catch((err) => {
        console.error("Failed to update chart segment...", err);
        setIsLoading(false);
      });
    }
  }

  const handleAddChartSegment = () => {
    const newChart = { chartId: chartId, color: '#343333', label: "", operator: ">", value: "", variable: "" };
    setSegments((prev) => [...prev, newChart]);
  };

  const closeDrawer = () => {
    drawerCtx.closeDrawer();
  }
  


  return (
    <div className="drawer add-section add-piechart-section">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">Add Pie Chart Segments</h1>
      </header>
      <div className="chart-contents">
        <div className="chart-section">

          {segments?.map((segment, index) => (
            <ChartSegment key={index} index={index} segment={segment} signsArray={signsArray} setSignsArray={setSignsArray} addPieChartSegment={addPieChartSegment} updatePieChartSegment={updatePieChartSegment} />
          ))}
          <div className="chart-segment add-more-charts">
            <div className="add-chart" onClick={handleAddChartSegment}>
              <img src={AddIcon} alt='add' />
              <h1>Add More Segments</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-btns">
        <Button className="cancel-btn" onClick={closeDrawer}>Cancel</Button>
        {/* {isLoading ?
          <Button className="cancel-btn"><CircularProgress style={{ 'color': '#122845' }} size={25} /></Button> :
          <Button className="save-btn" onClick={addPieChartSegment}> Save</Button>
        } */}
      </div>
    </div>
  );
}

export default AddPieChartDrawer;



const ChartSegment = (props) => {

  const { index, addPieChartSegment, updatePieChartSegment, signsArray } = props;

  const modalCtx = useContext(ModalContext);
  const [segmentData, setSegmentData] = useState(props.segment);

  useEffect(() => {
    setSegmentData(props.segment);
  }, [props.segment, index]);

  console.log("ChartSegment props", props);

  const openColorModal = () => {
    const details = { handleColorChange, segmentData }
    modalCtx.openModal();
    modalCtx.setDetails("color-selection-modal", details);
  }

  const handleTitleChange = (e) => {
    setSegmentData((prev) => ({ ...prev, label: e.target.value }));
  };
  
  const handleCountChange = (e) => {
    setSegmentData((prev) => ({ ...prev, variable: e.target.value }));
  };
  const handleValueChange = (newValue) => {
    setSegmentData((prev) => ({ ...prev, value: newValue }));
  };
  const handleSignChange = (newValue) => {
    setSegmentData((prev) => ({ ...prev, operator: newValue }));
  };
  const handleColorChange = (newValue) => {
    setSegmentData((prev) => ({ ...prev, color: newValue }));
  };

  const deleteChartSegmentModal = (modal, segment) => {
    modalCtx.openModal();
    modalCtx.setDetails("delete-chart-segment-modal", segmentData);
  };

  return (
    <div key={index} className="chart-segment">
      <div className="form-group">
        <div className="delIcon" style={{ marginRight: '0px' }}  >
          <img src={deleteIcon} alt="" onClick={deleteChartSegmentModal} />
          <Button className="save-btn-chart" onClick={() => {
            if (segmentData.id) updatePieChartSegment(segmentData)
            else addPieChartSegment(segmentData);
          }}>Save</Button>
        </div>
        <label htmlFor="title">Segment Title</label>
        <input required type="text" placeholder="Title" className="text-box" value={segmentData.label} onChange={(e) => handleTitleChange(e)} />
      </div>
      <div className="form-group">
        <label htmlFor="count">Calculation of variable</label>
        <div className="text-container">
          <input type="text" placeholder="Variable" className="text-box-variable" value={segmentData.variable} onChange={(e) => handleCountChange(e)} />
          <div className="variable-input-box">
            <TextField required select variant="outlined" className='choose-variable-select-tag' value={segmentData.operator || "=="} onChange={(e) => { handleSignChange(index, e.target.value) }} >
              {
                signsArray?.map((item, idx) => {
                  return <MenuItem className="section-name" key={idx} value={item.option}>{item.option}</MenuItem>
                })
              }
            </TextField>
          </div>
          <input type="text" required placeholder="value" className="text-box-variable" value={segmentData.value} onChange={(e) => handleValueChange(index, e.target.value)} />
        </div>
      </div>
      <div className="form-group">
        <label htmlFor="count">Choose Color</label>
        <div className="box-container">
          <input type="color" className="color-box"
            value={segmentData.color}
            onClick={(e) => {
              e.preventDefault();
              openColorModal(segmentData);
            }}
          />
          <img className='drop-icon' src={dropIcon} alt='drop' onClick={(e) => {
            e.preventDefault();
            openColorModal(segmentData);
          }} />
        </div>
      </div>
    </div>
  );
}

