import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import {  TextField,  Select,  MenuItem,  FormControl,  InputLabel,  Button,  Checkbox, CircularProgress} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import ModalContext from "../../store/ModalContext";
import AuthContext from "../../store/AuthContext";

function EditAssessmentSectionDrawer(props) {
  const [title, setTitle] = useState(props.drawerDetails.title || props.drawerDetails.section);
  const [order, setOrder] = useState(props.drawerDetails.order || props.drawerDetails.orderNo);
  const [multiplier, setMultiplier] = useState(props.drawerDetails.multiplier);
  const modalCtx = useContext(ModalContext)
	const drawerCtx = useContext(DrawerContext);
  const authCtx = useContext(AuthContext);
  const [isLoading,setIsLoading] = useState(false)

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }
  
  const handleSubmit = (e) => {
    setIsLoading(true)
    e.preventDefault();
    console.log("Form Submitted",props.drawerDetails);
    fetch(process.env.REACT_APP_API_URI + "/v3/admin/section/" + props.drawerDetails.id, {
      method: "PUT",
      body: JSON.stringify({
        "clientId":props.drawerDetails.clientId,
        "section":title,
        "multiplier":multiplier,
        "orderNo":order
      }),
      headers: { "Content-Type": "application/json; charset=UTF-8" , 'Authorization': 'Bearer ' + authCtx.token },
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.status==="success"){
          console.log(data)
          modalCtx.categories == false ? modalCtx.updateCategories(true) : modalCtx.updateCategories(false)
        }
        setIsLoading(false)
      });
      setTimeout(() => {
        modalCtx.closeModal()
      }, 1500);
  };

  return (
    <div className="drawer">
      <header>
        <h2 data-aos="fade-right" data-aos-delay="500">
          Edit Section
        </h2>
      </header>

      <section>
        <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="Title"
          placeholder="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />
 
        {/* <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="Order"
          placeholder="Order"
          value={order}
          onChange={(e) => setOrder(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        /> */}
 
        <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="Multiplier"
          placeholder="Multiplier"
          value={multiplier}
          onChange={(e) => setMultiplier(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />
 
        <div className="double-column-form aligned-right margin-top edit-category-btn-container">
          <Button className="cancel-btn" onClick={(e) => {handleCloseModal()}}>Cancel</Button>

          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            component="label"
            disabled={title === "" || title === null || order === "" || order === null || multiplier === null || multiplier === "" || (title === props.drawerDetails.title && order == props.drawerDetails.order && multiplier == props.drawerDetails.multiplier)}
          >
              {isLoading ? <CircularProgress size={20}/> : "Save Changes"}
          </Button>
        </div>
      </section>
    </div>
  );
}
export default EditAssessmentSectionDrawer;
