import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import {  TextField,  Select,  MenuItem,  FormControl,  InputLabel,  Button,  Checkbox} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function EditClientAdministratorDrawer(props) {
  const [name, setName] = useState(props.userDetails.name);
  const [email, setEmail] = useState(props.userDetails.email);
  // const [password, setPassword] = useState(props.userDetails.password);
  const [password, setPassword] = useState("");
  // console.log(props);
  const id = props.userDetails.id;
	const drawerCtx = useContext(DrawerContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");
    fetch(process.env.REACT_APP_API_URI + "/v1/accounts/" + id + "/administrators", {
      method: "PUT",
      body: JSON.stringify({ id: id, name: name, email: email, password: password}),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
    drawerCtx.closeDrawer();
  };

  return (
    <div className="drawer">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
          Edit Administrator
        </h1>
      </header>

      <section>
        <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="Name"
          placeholder="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />
 
        <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="Email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />
 
        <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="Password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />
 
        <div className="double-column-form aligned-right margin-top">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            component="label"
            disabled={name === "" || name === null || email === "" || email === null || password === null || password === "" || (name === props.userDetails.name && email == props.userDetails.email && password == props.userDetails.password)}
          >
            Edit Administrator
          </Button>
        </div>
      </section>
    </div>
  );
}
export default EditClientAdministratorDrawer;
