import React, { useContext, useEffect, useState } from "react";
import { Color, Display, BackgroundColor, Height, BorderRadius, Border, BackgroundPosition, BackgroundRepeat, BackgroundSize, Font, TextAlign, Width, BoxSizing, LetterSpacing, LineHeight, MaxWidth, Position, TextTransform, Padding, HoriVertiPositioning, Margin, FlexDirection, MinHeight } from "../StylingComponents";
import BuilderContext from "../../../../store/BuilderContext";
import allCssJson from '../../../../assets/json/AllCssJson.json'
import ModalContext from "../../../../store/ModalContext";
import { Add, Delete } from "@material-ui/icons";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@material-ui/lab";
import desktopIcon from "../../../../assets/img/builder-images/desktop-icon.svg";
import horizontalTabIcon from "../../../../assets/img/builder-images/horizontal-tab.svg";
import verticalTabIcon from "../../../../assets/img/builder-images/vertical-tab.svg";
import phoneIcon from "../../../../assets/img/builder-images/phone-icon.svg";
import ImageWidgetTabs from "../ImageWidget/ImageWidgetTabs";
import { useParams } from "react-router-dom";

const DynamicStylingTool = ( props ) => {
  const builderCtx = useContext(BuilderContext);
  const modalCtx   = useContext(ModalContext);

  const { contentBlocks, currentContentBlock, isCtrlPressed, setIsCtrlPressed, currentDeviceView } = builderCtx;

  const [currentBlock, setCurrentBlock]                         = useState(() => contentBlocks?.find(block => block.id === currentContentBlock?.id));
  const [blockId, setBlockId]                                   = useState(() => currentBlock?.id);
  const [currentBlockStyles, setCurrentBlockStyles]             = useState(() => currentBlock?.styling);
  const [currentBlockMobileStyles, setCurrentBlockMobileStyles] = useState(() => currentBlock?.mobileStyling);
  const [currentBlockStylesArray, setCurrentBlockStylesArray]   = useState(() => currentBlockStyles ? Object.keys(currentBlockStyles) : []);
  const [ComponentHistory, setComponentHistory]                 = useState([]);
  const [tabValue, setTabValue]                                 = useState('1');
  const { id }                                                  = useParams();

  const componentsMap = {
    Color,
    BackgroundColor,
    Height,
    Width,
    BorderRadius,
    Border,
    BackgroundPosition,
    BackgroundRepeat,
    BackgroundSize,
    Font,
    TextAlign,
    BoxSizing,
    LetterSpacing,
    LineHeight,
    MaxWidth,
    Position,
    TextTransform,
    FlexDirection,
    MinHeight,
    Display
  };

  const capitalize = (str) => {
    if (typeof str !== 'string' || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  const toggleModalHandler = (modal,data) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal,data);
  };

  const handleTabChange = (e,newValue) => {
    setTabValue(newValue)
  }

  const deleteBtn = (name) => {
    const filteredName = allCssJson.find(properties => properties.name === name);
    
    if ( name === 'alignItems' || name === 'fontWeight' ) {
      return <>
        <div className="delete-icon" onClick={()=>{toggleModalHandler('page-builder-delete-styles-modal',filteredName)}}><Delete/></div>
      </> 
    }
    
    return <>
      <div className="styling-text-delete-icon-container">
        <p className="styling-text dynamic-styling-text">{filteredName?.userFriendlyName}</p>
        <div className="delete-icon" onClick={()=>{toggleModalHandler('page-builder-delete-styles-modal',filteredName)}}><Delete/></div>
      </div>
    </>  
  }

  const stylingTab = () => {

    let widgetEdgeCases = null;

    switch ( props?.widgetName ) {
      case 'ImageWidget' :
        widgetEdgeCases = <ImageWidgetTabs>
          {{
            imageTab: props?.imageTab,
            positioningTab: props?.positioningTab,
          }}
      </ImageWidgetTabs>
      break;
      default :
        widgetEdgeCases = null;
    }

    return <>
      {widgetEdgeCases}
      {
        currentBlockStylesArray?.map((componentName, index) => {
              return renderComponent(componentName, index, 'desktop');
        })
      }
      {
        Object.keys(currentBlockMobileStyles).length !== 0 && (
          <>
            <p style={{ fontSize: "16px", textTransform: "uppercase", fontWeight: "700", textAlign: "center", color: "#122845" }}>Mobile Styles</p>
            {
              Object.keys(currentBlockMobileStyles).map((componentName, index) => {
                return renderComponent(componentName, index, 'mobile');
              })
            }
          </>
        )
      }
    </>
  }

  const currentDeviceNameAndIcon = () => {
    switch ( currentDeviceView ) {
      case "Horizontal" : 
        return <>
          <img src={horizontalTabIcon} alt="" srcset="" />
          <span>Horizontal Tablet Styling</span>
      </>;
      case "Vertical" :
        return <>
          <img src={verticalTabIcon} alt="" srcset="" />
          <span>Vertical Tablet Styling</span>
      </>
      case "Mobile" :
        return <>
          <img src={phoneIcon} alt="" srcset="" />
          <span>Mobile Styling</span>
      </>
      default: 
         return <>
          <img src={desktopIcon} alt="" srcset="" />
          <span>Desktop Styling</span>
      </>
    }
  }
  
  const handlePaddingEdgeCase = () => {
    const paddingProperties = ['paddingLeft', 'paddingRight', 'paddingTop', 'paddingBottom'];
    const filteredArray = currentBlockStylesArray.filter(property => !paddingProperties.includes(property));

    if ( !currentBlockStylesArray.includes('padding' )) {
      setCurrentBlockStylesArray([...filteredArray,'padding'])
      return;
    }
    else {
      setCurrentBlockStylesArray([...filteredArray])
    }
  }

  const handleMarginEdgeCase = () => {
    const marginProperties = ['marginLeft', 'marginRight', 'marginTop', 'marginBottom'];
    const filteredArray = currentBlockStylesArray.filter(property => !marginProperties.includes(property));

    if (!currentBlockStylesArray.includes('margin')) {
        setCurrentBlockStylesArray([...filteredArray, 'margin']);
    } 
    else {
        setCurrentBlockStylesArray([...filteredArray]);
    }
  }

  const renderComponent = (componentName, index, isMobile) => {
    const Component = componentsMap?.[capitalize(componentName)];
    const DeleteBtn = deleteBtn(componentName)

    if (Component) {
      return <Component key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
    }

    switch (componentName) {
      case 'alignItems':
        if ( !currentBlockStylesArray.includes('justifyContent') ) {
          return <HoriVertiPositioning key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
        }
        return;
      break;
      case 'justifyContent':
        return <HoriVertiPositioning key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      case 'paddingLeft':
        handlePaddingEdgeCase()
        return <Padding blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      case 'paddingTop':
        handlePaddingEdgeCase()
        return <Padding blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;     
      break;
      case 'paddingBottom':
        handlePaddingEdgeCase()
        return <Padding blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;     
      break;
      case 'paddingRight':
        handlePaddingEdgeCase()
        return <Padding blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;     
      break;
      case 'padding':
        // handlePaddingEdgeCase()
        return <Padding blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;     
      break;
      case 'marginLeft':
        handleMarginEdgeCase()
        return <Margin blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      case 'marginRight':
        handleMarginEdgeCase()
        return <Margin blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      case 'marginTop':
        handleMarginEdgeCase()
        return <Margin blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      case 'margin':
        // handleMarginEdgeCase()
        return <Margin blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      case 'marginBottom':
        handleMarginEdgeCase()
        return <Margin blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      case 'fontSize':
        if ( !currentBlockStylesArray.includes('fontWeight') ) {
          return <Font key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
        } 
        return;
      break;
      case 'fontWeight':
        return <Font key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      case 'borderColor':
        return <Border key={index} blockId={blockId} screenType={isMobile} deleteModal={DeleteBtn} />;
      break;
      default:
        return null;
    }

  };


  useEffect(() => {
    console.log('currentBlockStylesArray',currentBlockStylesArray)
  }, [currentBlockStylesArray])
  


  useEffect(()=>{
    // console.log('currentContentBlock from dynamicstyling',currentContentBlock)
    setCurrentBlock(currentContentBlock);
  },[currentContentBlock]);

  useEffect(() => {
    setCurrentBlockStyles(currentBlock?.styling);
    setCurrentBlockMobileStyles(currentBlock?.mobileStyling);
    setCurrentBlockStylesArray(currentBlockStyles ? Object.keys(currentBlockStyles) : []);
    setBlockId(currentBlock?.id);
  }, [currentBlock, currentBlockStyles]);

  return (
    <div className="dynamic-tools-container">
      <div className="add-styles-btn-container">
        <div className="current-device-view">
          {currentDeviceNameAndIcon()}
        </div>
        <button className="add-styles-btn" onClick={()=>{ 
          toggleModalHandler("page-builder-add-new-style-mmodal",{id}); 
        }}>
          <Add/>
          <span>Add Styling</span>
        </button>
      </div>
      {stylingTab()}
    </div>
  );
}

export default DynamicStylingTool;
