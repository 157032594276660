import React, { useContext, useState, useEffect } from 'react';
import ModalContext from '../../store/ModalContext.js';
import { CircularProgress, FormControl, FormGroup, TextField, Button, IconButton, Slider, Box } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuidv4 } from 'uuid';
import { Add } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';


const UpdateResultsText = (props) => {

  console.log("UpdateResultsText props:", props);

  const modalCtx = useContext(ModalContext);

  const [text, setText] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [textFields, setTextFields] = useState([{ id: uuidv4(), textArr: [{ id: uuidv4(), text: "Needs Improvement" }], range: [0, 100] }]);

  // console.log("modalCtx.details", modalCtx.details);
  console.log("textFields", textFields);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  // const handleAddTextField = () => {
  //   const newTextField = { id: textFields.length + 1, textArr: [{ id: textFields.length + 1, text: "Meets Expectations" }], range: [46, 75] };
  //   setTextFields([...textFields, newTextField]);
  // };

  const handleAddTextField = (e) => {
console.log("CLIKED");
    e.preventDefault();

    const increaseCount = count + 1;
    setCount(increaseCount);
    const newArray = [];
    const rangeMin = 0;
    const rangeMax = 100 / increaseCount;
    // setTextFields([]);
    for(let i = 0; i < increaseCount; i++) {
      
      const id = uuidv4();
      const value = [{ id: uuidv4(), text: "Needs Improvement" }];

      if(i == 0) {
        // newArray.push([rangeMin, parseInt(rangeMax)]);
        const data = {id, textArr: value, range:[rangeMin, parseInt(rangeMax)]};
        newArray.push(data);
      }else if (i == (increaseCount - 1)) {
        //   newArray.push([newArray[i - 1][1], 100]);
        const data = {id, textArr: value, range:[newArray[i - 1].range[1] + 1, 100]};
         newArray.push(data);
        }else {
            console.log("VALS", newArray[i - 1].range[1]);
          const prevFirst = newArray[i - 1].range[0];
        
          const data = {id, textArr: value, range:[newArray[i - 1].range[1] + 1, parseInt(rangeMax) * 2 + prevFirst - 1]};
          newArray.push(data);            
          }

      console.log("VAL");
      // // setNewArray(newArray);
    }
    
    console.log("Countingss", newArray);
    console.log("TEXT FIELDSs", textFields);

    setTextFields(newArray);
    // const newTextField = { id: uuidv4(), textArr: [{ id: uuidv4(), text: "Your score..." }], range: [46, 75] };
    // setTextFields([...textFields, newTextField]);
  };

  const handleDeleteTextField = (id) => {
    const updatedTextFields = textFields.filter((textField) => textField.id !== id);
    setTextFields(updatedTextFields);
  };

  const handleAddText = (id) => {
    const updatedTextFields = textFields.map((textField) => {
      if (textField.id === id) {
        const newText = { id: uuidv4(), text: "Your score..." };
        return { ...textField, textArr: [...textField.textArr, newText] };
      }
      return textField;
    });
    setTextFields(updatedTextFields);
  };

  const handleDeleteText = (textFieldId, textId) => {
    const updatedTextFields = textFields.map((textField) => {
      if (textField.id === textFieldId) {
        const updatedTextArr = textField.textArr.filter((text) => text.id !== textId);
        return { ...textField, textArr: updatedTextArr };
      }
      return textField;
    });
    setTextFields(updatedTextFields);
  };

  const handleTextChange = (textFieldId, textId, value) => {
   
   
    const updatedTextFields = textFields.map((textField) => {
      if (textField.id === textFieldId) {
        const updatedTextArr = textField.textArr.map((text) => {
          if (text.id === textId) {
            return { ...text, text: value };
          }
          return text;
        });
        return { ...textField, textArr: updatedTextArr };
      }
      return textField;
    });
    setTextFields(updatedTextFields);
  };

  const handleTextFieldChange = (id, value) => {
    const updatedTextFields = textFields.map((textField) =>
      textField.id === id ? { ...textField, value } : textField
    );
    setTextFields(updatedTextFields);
  };


  const handleRangeChange = (e, newRange, textFieldId) => {

    if(newRange[0] == newRange[1]) {
      newRange = [newRange[0], newRange[0] + 1];
      console.log("NEWSERST", newRange);
    }
    
    for (let i = 0; i < textFields.length; i++) {

      const nextMax = textFields[1].range[1];
      console.log("NEXTMAX", nextMax);
      if(textFields[0].id == textFieldId) {
        textFields[0].range = newRange;
        textFields[1].range = [newRange[1] + 1, textFields[1].range[1]];   
        console.log("RANGE1", textFields[1].range);     
      }

      if(textFields[textFields.length - 1].id == textFieldId) {
        textFields[textFields.length - 1].range = newRange;
        textFields[textFields.length - 2].range = [textFields[textFields.length - 2].range[0], newRange[0] - 1];
        console.log("TRUE-1");
        
      }

      if(textFields[i].id !== textFields[textFields.length - 1].id && textFields[i].id !== textFields[0].id && textFields[i].id == textFieldId)
      // // if(textFields[i].id == textFieldId && textFields[i].id !==  textFields[0].id && textFields[i].id !== textFields[textFields.length - 1].id )
      {
        const max = 100 - textFields.length + 2;
        console.log("MAX IS", max);
        textFields[i].range = newRange;
        const maxRange = newRange[1] < max ? newRange[1] + 1: max;
        textFields[i - 1].range = [textFields[i - 1].range[0], newRange[0] - 1];
        textFields[i + 1].range = [maxRange, textFields[i + 1].range[1]];
        console.log("TRUE-2");
      }
    }

    console.log("CHANGED TEXT FIELDSs", textFields);

    const updatedTextFields = textFields.map((textField) =>
      textField.id === textFieldId ? { ...textField, range: newRange } : textField
    );
    setTextFields(updatedTextFields);
  };


  console.log("textFields", textFields);


  const handleUpdateResultsText = (e) => {
    e.preventDefault();
    // modalCtx.setDetails({websiteTitle});
    // setIsLoading(true);
    const jsonBody = { memberText: text, leaderText: text };

    // fetch(process.env.REACT_APP_API_URI + '/v2/', {
    //   method: 'PUT',
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json' },
    //   body: JSON.stringify(jsonBody),
    // }).then(response => {
    //   return response.json();
    // }).then(data => {
    //   console.log(data);
    //   if (data.status === "success") {
    //     console.log("Successfully updated results text...");
    //     setIsLoading(false);
    //     handleCloseModal();
    //   }
    // }).catch((err) => console.error("Failed to update results text...", err));

  }

  return (
    <div className='modal'>
      {isLoading ?
        <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></section>
        :
        <>
        <div style={{display: "flex", justifyContent: "flex-end", marginRight: "25px" }}>
            <CloseIcon onClick={(e) => handleCloseModal()}/>
        </div>
          {/* <h2 style={{ margin: "0 auto 15px" }}>Update Result Text Based on Score</h2> */}
          <div style={{ margin: "90px auto 5px", maxWidth: "90%" }} className="progress-bar">
                <div style={{ maxHeight: "175px" }} className="si_ass_result">
                  <div style={{ position: "relative", maxWidth: "800px", height: "26px", borderRadius: "12px", width: "100%", backgroundImage: "linear-gradient(to right, #cc2c24, #ffca26 62%, #429653 100%)" }} className="si_ass_progress">
                    <div style={{ zIndex: "2", width: "2px", height: "40px", background: "#343333", bottom: "0", position: "absolute", transition: "all 0.5s ease-in-out", left: "0%" }} className="progress-line">
                      {/* <div className="progress-text" style={ windowWidth < 651 && score < 30 ? { left: '-50px', width: '10ch', top: '-85px' } : getBarTextLeft(score)}>{showProgressBarText(score)}</div> */}
                    </div>
                    <span style={{ position: "absolute", fontSize: "30px", fontWeight: "bold", color: "#4A4A4A", marginLeft: "-20px", left: "0%", bottom: "130%" }} className="msi_score">{Math.round(0)}%</span>
                  </div>
               
                  <div style={{ maxWidth: "800px", margin: "5px auto", display: "flex", color: "#4A4A4A", fontSize: "15px", position: "relative", top: "-20px" }} className="pervalue">
                      <h4 style={{ position: "relative",  minWidth: '0%', top: "5px" }} className="nimp1">0%</h4>
                    { textFields.map((field, idx) => {
                      const minWidth = idx == 0 && field.range[1];
                      // const leftMargin = field.range[1] - 8 * (idx + 1);

                      const leftMargin = textFields.length == 1 ? 94 : field.range[1] - 8 * idx;
                  console.log("fieldRange", field.range, "leftmargin", leftMargin);
                  return (
                    <>
                      <h4 style={{ position: "relative", minWidth: `${minWidth}`, top: "5px", left: `${leftMargin}%` }} className="nimp" >{ field.range[1] !== 100 && <span style={{ width: "2px", height: "28px", position: "absolute",bottom: "100%", left: "2%", color: "#fff" }}>|</span> }{field.range[1]}%</h4>
                      {/* <h4 style={{ position: "relative", minWidth: '30%', top: "5px", left: "11%" }} className="mexpe" ><span style={{ width: "2px", height: "28px", position: "absolute",bottom: "100%", left: "2%", color: "#fff" }}>|</span>70%</h4> */}
                      {/* <h4 style={{ position: "relative", minWidth: '20%', top: "5px", left: "17%" }} className="eexpe" ><span style={{ width: "2px", height: "28px", position: "absolute",bottom: "100%", left: "2%", color: "#fff" }}>|</span>100%</h4> */}
                      {/* <h4 className="eexpe1" style={{ minWidth: '20%' }}></h4> */}
                      {/* <h4 className="eexpe2" style={{ minWidth: '18%' }}></h4> */}
                      {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                    </>
                    )
                  })
                  
                } 

              </div>
                  
                  <div style={{ maxWidth: "800px", margin: "10px auto 0", display: "flex", position: "relative", bottom: "88px" }} className="si_pro_header">
                    <h4 style={{ position: "relative", fontSize: "16px", fontWeight: "400", top: "7px", textAlign: "center", color: "#343333", textTransform: "capitalize", marginTop: "10px",  minWidth: '0%' }} className="nimp" ></h4>
                    <h4 style={{ position: "relative", fontSize: "16px", fontWeight: "400", top: "7px", textAlign: "center", color: "#343333", textTransform: "capitalize", marginTop: "10px", minWidth: '50%' }} className="mexpe" ></h4>
                    <h4 style={{ position: "relative", fontSize: "16px", fontWeight: "400", top: "7px", textAlign: "center", color: "#343333", textTransform: "capitalize", marginTop: "10px", minWidth: '30%' }} className="eexpe" ></h4>
                    {/* <h4 className="eexpe1" style={{ minWidth: '30%' }}></h4> */}
                    {/* <h4 className="eexpe" style={{ minWidth: '30%' }}></h4> */}
                  </div>
                  
                  {/* <div className="pervalue-text"> */}
                    {/* <h4 className="nimp" style={{ minWidth: '45%',  }}></h4> */}
                    {/* <h4 className="mexpe" style={{ minWidth: '25%',  }}></h4> */}
                    {/* <h4 className="eexpe" style={{ minWidth: '30%', f }}></h4> */}
                    {/* <h4 className="vexpe" style={{ minWidth: '5%' }}></h4> */}
                  {/* </div> */}
                
              <div style={{position: "relative", display: "flex", margin: "2px auto", width: "100%", maxWidth: "700px", bottom: "75px" }} className="pervalue-text">
                { textFields.map((fields) => {
                  console.log("FIELDS", fields)
                  return (
                    <p className="not-aligned" style={{ width: "25%", fontWeight: 500, textAlign: "center", position: "relative", margin: "0 auto", top: "12px", fontSize: "20px", verticalAlign: "top", color: "#0F131D" }}>{fields.textArr[0].text} </p>)
                    {/* <p className="poor-aligned" style={{ marginLeft: '25%', fontWeight: score > 20 && score <= 40 ? 800 : 600 }}>Poorly<br />Aligned</p> */}
                    {/* <p className="some-aligned" style={{ width: "30%", fontWeight:  500, textAlign: "center", position: "relative", margin: "0 auto", top: "12px", fontSize: "20px", verticalAlign: "top", color: "#0F131D" }}>{fields.textArr[0].text}</p> */}
                    {/* <p className="well-aligned" style={{ marginLeft: '65%', fontWeight: score > 60 && score <= 80 ? 800 : 600 }}>Well<br />Aligned</p> */}
                    {/* <p className="high-aligned" style={{ width: "20%", fontWeight: 500, textAlign: "center", position: "relative", margin: "0 auto", top: "12px", fontSize: "20px", verticalAlign: "top", color: "#0F131D" }}>{fields.textArr[0].text}</p> */}
                  }   )}
              </div>

                </div>
            </div>
          <form>
        <div style={{display: "flex", justifyContent: "flex-end"}}>
          <Button style={{backgroundColor: "#122845", color: "#fff", borderRadius: "13px", width: "234px", height: "48px", marginRight: "10px" }}><AddIcon style={{marginRight: "5px"}}/> ADD NEW RANGE</Button>
          </div>
            <FormControl fullWidth>
              {textFields.map((textField) => (
                <div key={textField.id}>
                  <Box alignItems="center">

                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                      <p style={{ padding: "10px 15px", margin: "15px auto", border: "1px solid #BEBEBE", borderRadius: "8px", width: "4ch", textAlign: "center" }}>
                        {textField.range[0]}</p>
                      <Slider getAriaLabel={() => 'Score range'} style={{ margin: "15px", color: "#122845" }}
                        value={textField.range}
                        onChange={(e, newRangeVal) => handleRangeChange(e, newRangeVal, textField.id)}
                        valueLabelDisplay="auto" 
                      // getAriaValueText={valuetext} 
                      />
                      <p style={{ padding: "10px 15px", margin: "15px auto", border: "1px solid #BEBEBE", borderRadius: "8px", width: "4ch", textAlign: "center" }}>
                        {textField.range[1]}</p>
                       <IconButton onClick={() => handleDeleteText(textField.id, text.id)} >
                          <DeleteIcon />
                        </IconButton>
                    </div>

                    {textField.textArr.map((text, idx) => (
                      <Box key={text.id} alignItems="center">
                        <div style={{ display: "flex" }}>
                          <p>{`Result Text Line ${idx + 1}`}</p>
                          <TextField fullWidth variant="outlined" value={text.text} 
                            style={{ margin: "10px auto" }}
                            onChange={(e) => handleTextChange(textField.id, text.id, e.target.value)}
                          />
                         
                        </div>
                      </Box>
                    ))}
                  </Box>

                  <Box alignItems="center" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: "10px auto" }}>
                    <Button variant="contained" className="black-btn"  
                      onClick={() => handleAddText(textField.id)} >
                      Add Text
                    </Button>
                    <Button variant="contained" className="black-btn"  
                      onClick={() => handleDeleteTextField(textField.id)} >
                      <DeleteIcon /> Delete This Range
                    </Button>
                  </Box>
                  <hr style={{ margin: "20px auto 15px", width: "100%", borderBottom: "1px solid #BEBEBE" }} />
                </div>
              ))}

              <Button variant="contained" className="black-btn" 
                onClick={handleAddTextField} style={{ margin: "5px auto 15px", width: "100%" }}>
                <Add /> Add New Range
              </Button>
            </FormControl>

            <div className="footer" style={{ margin: "15px auto 0" }}>
              <Button className="cancel-btn" onClick={(e) => handleCloseModal()}>CANCEL</Button>
              <Button type="submit" className="confirm-btn" onClick={(e) => { handleUpdateResultsText(e) }}>UPDATE</Button>
            </div>
          </form>
        </>
      }
    </div>
  )
}

export default UpdateResultsText;

