import React, { useState, useEffect, useContext } from "react";

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import {  TextField,  Select,  MenuItem,  FormControl,  InputLabel,  Button,  Checkbox} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

function EditAssessmentAutomationDrawer({ details }) {
  console.log("Edit Automation Props", details);
  /* {
    "id": 1,
    "client_id": 6,
    "type": "ac_field",
    "field_name": "churchName",
    "reference_id": 2,
    "section": "leader",
    "created_at": "2022-03-28 06:48:49",
    "updated_at": "2022-03-28 06:48:49"
} */
function getType(type) {
  if(type.slice(0,2) == "ac"){
    if(type.slice(3,8) == 'field' && type.slice(3,8) == type.slice(3))
      return type.slice(3);
    else if(type.slice(3,8) == 'field' && type.slice(3,8) != type.slice(3))
      return type.slice(9);
    else
      return type.slice(3);
  }
  else 
  return type;
}

  const [fieldName, setFieldName] = useState(details.field_name);
  const [acId, setAcId] = useState(details.reference_id);
  const [fieldType, setFieldType] = useState(getType(details.type));
  const [afterAction, setAfterAction] = useState(details.section);
	const drawerCtx = useContext(DrawerContext);

  const id = details.client_id;

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");
    fetch(process.env.REACT_APP_API_URI + "/v1/admin/assessment/" + id + "/automation", {
      method: "PUT",
      body: JSON.stringify({client_id: id, id: details.id, field_name: fieldName, reference_id: acId, type: fieldType, section: afterAction}),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
    drawerCtx.closeDrawer();
  };

  return (
    <div className="drawer">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
          Edit Automation
        </h1>
      </header>

      <section>
        <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="Field"
          placeholder="Field"
          value={fieldName}
          onChange={(e) => setFieldName(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />
 
        <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="Active Campaign ID"
          placeholder="Active Campaign ID"
          value={acId} 
          onChange={(e) => setAcId(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />

        <TextField fullWidth className="form-control-add-question margin-top"
          value={fieldType}
          onChange={(e) => setFieldType(e.target.value)}
          placeholder="Type"
          label="Type" 
          select
          variant="outlined"
          required
        >
          <MenuItem className="section-name" value='field'>Field</MenuItem>
          <MenuItem className="section-name" value='assessment_url'>Assessment URL</MenuItem>
          <MenuItem className="section-name" value='invite_url'>Invite URL</MenuItem>
          <MenuItem className="section-name" value='tag'>Tag</MenuItem>
          <MenuItem className="section-name" value='buy_url'>Buy URL</MenuItem>
          <MenuItem className="section-name" value='call_url'>Call URL</MenuItem>
        </TextField>

        <TextField
          fullWidth
          className="form-control-add-question margin-top"
          label="After action"
          placeholder="After action"
          value={afterAction}
          onChange={(e) => setAfterAction(e.target.value)}
          variant="outlined"
          InputLabelProps={{ shrink: true }}
          required
        />

        <div className="double-column-form aligned-right margin-top">
          <Button
            onClick={handleSubmit}
            type="submit"
            variant="contained"
            component="label"
            disabled={!fieldName || !acId || !fieldType || !afterAction}
          >
            Edit Automation
          </Button>
        </div>
      </section>
    </div>
  );
}
export default EditAssessmentAutomationDrawer;
