import React, { useState, useEffect, useContext, useRef } from "react";
import { CircularProgress, Pagination } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import Autocomplete from '@mui/material/Autocomplete';

import PropTypes from "prop-types";
import DrawerContext from "../../store/DrawerContext";

import Modal from '@mui/material/Modal';

import {  TextField,  Select,  MenuItem, Box, Tabs, Tab,  FormControl,  InputLabel,  Button,  Checkbox, Radio, RadioGroup} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {Close, CheckCircleOutline, RadioButtonUnchecked} from '@mui/icons-material';
import "../../App.scss";
import AddIcon from '@mui/icons-material/Add';

import AuthContext from '../../store/AuthContext';
import { RadioButtonChecked } from "@material-ui/icons";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
//Google Sheets Columns Modal styles
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  zIndex:10
};

// Google Sheets Dummy Data //
const dummyColumnsData = [
  { field: "Email", id: 1, placeholder1: "abc@mail.com", placeholder2: "abc@mail.com",selected: false, placeholder3: "abc@mail.com" },
  { field: "Name", id: 2, placeholder1: "John Doe", placeholder2: "Jane Smith",selected: false, placeholder3: "Mike Johnson" },
  { field: "Church Name", id: 3, placeholder1: "Community Church", placeholder2: "Grace Chapel",selected: false, placeholder3: "Hope Fellowship" },
  { field: "Job Title", id: 4, placeholder1: "Pastor", placeholder2: "Youth Leader",selected: false, placeholder3: "Worship Director" },
  { field: "Q1 - How many people go to the church?", id: 5, placeholder1: "100", placeholder2: "250",selected: false, placeholder3: "500" },
  { field: "Q2 - How many people go to the church?", id: 6, placeholder1: "80", placeholder2: "200",selected: false, placeholder3: "400" },
  { field: "Q3 - How many people go to the church?", id: 7, placeholder1: "120", placeholder2: "180",selected: false, placeholder3: "300" },
  { field: "Category 1 - 50%", id: 8, placeholder1: "25%", placeholder2: "50%",selected: false, placeholder3: "75%" },
  { field: "Category 2 - 75%", id: 9, placeholder1: "10%", placeholder2: "30%",selected: false, placeholder3: "45%" },
  { field: "Category 3 - 80%", id: 10, placeholder1: "5%", placeholder2: "15%",selected: false, placeholder3: "25%" },
];

  
   
function CreateAssessmentAutomationDrawer(props) {
  const [isLoading, setLoading] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [acId, setAcId] = useState(0);
  const [fieldType, setFieldType] = useState("");
  const [afterAction, setAfterAction] = useState("");
  const [campaignAction, setCampaignAction] = useState('');
  const [form, setForm] = useState("");
  const [fromAPI, setFromAPI] = useState(["From API", "Test One", "Test Two"]);
  const [filter, setFilter] = useState(fromAPI);
  const [search, setSearch] = useState("");

  //Google sheets //

  const [activeTab, setActiveTab] = useState('one');
  const [addColumn, setAddColumn] = useState(16);
  const [upDateRow, setUpdateRow] = useState(false);  
  const [openColumnModal, setOpenColumnModal] = useState(false);
  // const [columnId, setColumnId] = useState(null);
  const [columnFields, setColumnFields] = useState({});
  const [columnFieldArray, setColumnFieldArray] = useState(dummyColumnsData);
  
   // array of alphabets from A to Z //
   
   const labels = [...Array(26)].map((_, i) => String.fromCharCode(65 + i));
   let arrayOfAlphabets = labels.map(label => ({ label: label, value: "" }));
   
   const [alphabetsArray, setAlphabetsArray] = useState(arrayOfAlphabets);
   const [selectedColumnField, setSelecetedColumnField] = useState([]);
  //  const [searchBarText, setSearchBarText] = useState("");
   const [saveBtn, setSaveBtn] = useState(false);
   console.log("ARRAY OF ALPHABETSs", alphabetsArray, arrayOfAlphabets);

  
  const drawerCtx = useContext(DrawerContext);
  const authCtx   = useContext(AuthContext);

  const select = [['Add Custom Field','field'],['Add Tag','tag'],['Add List Type', 'list'],['Add Automation Type', 'automation']]

  const id = props.userDetails.client_id;
 {/*       Field (field_name on the database)
Active Campaign ID (reference_id on the database)
Type (Either field, assessment URL, Invite URL, tag, buy URL, call URL)
After action (section on the database) */}
  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Submitted");
    console.log(campaignAction);
    let fieldTypeSet = fieldType;
    if(campaignAction == 2) {
      fieldTypeSet = "ac_tag";
    } else if(campaignAction == 3) {
      fieldTypeSet = "ac_list";
    } else if(campaignAction == 4) {
      fieldTypeSet = "ac_automation";
    }
    console.log(search);
    fetch(process.env.REACT_APP_API_URI + "/v1/admin/assessment/" + id + "/automation", {
      method: "POST",
      body: JSON.stringify({client_id: id, field_name: fieldName, reference_id: acId, type: fieldTypeSet, section: afterAction}),
      headers: { "Content-Type": "application/json; charset=UTF-8" },
    })
      .then((response) => response.json())
      .then((data) => console.log(data));
    drawerCtx.closeDrawer();
  };

  const getFromAPI = () => {
    if(campaignAction - 1 >= 0){
    fetch(process.env.REACT_APP_API_URI + '/v2/integrations/assessments/' + id + '/activeCampaignSearch?' + new URLSearchParams({type: select[campaignAction - 1][1], search: search==null? "":search}), {
      method: 'GET',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' }
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status == "success") {
        console.log("apiData\n", data);
        let arr = [];
        data.data?.map((data) => {
          if(select[campaignAction - 1][1] == 'field'){
            arr.push({label: data.title, id: data.id})
          } else if (select[campaignAction - 1][1] == 'tag'){
            arr.push({label: data.tag, id: data.id})
          } else if (select[campaignAction - 1][1] == 'list'){
            arr.push({label: data.name, id: data.id});
          }
        });
        console.log(arr);
        setFromAPI(arr);
        setLoading(false);
      } else {
        console.log("failed to fetch");
      }
  })}

  }

  const authenticateSalesForce = () => {
    console.log("Authenticate");
    fetch(process.env.REACT_APP_API_URI + '/v3/integrations/salesforce/authenticate', {
      method: 'POST',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify({assessmentId: id}),
    }).then(response => {
      return response.json();
    }).then(data => {
      if(data.status === "success") {
        console.log("apiData\n", data);
        var win = window.open(data.authUrl, '_blank');
        win.focus();
      } else {
        console.log("failed to fetch");
      }
    }).catch((err) => {
      console.error("Failed to authenticate SalesForce...", err);
    });
  }

  const chooseForm = () => {
    if(form === "active-campaign") {
      return getForm();
    }

    if(form === "google-sheets") {
      return getGoogleForm();
    }

    if(form === "sales-force") {
      return getSalesForceForm();
    }
    return null;
  }

  //Google-form tab handler
  const googleTabhandler = (e, value) => {
    console.log("EVENT", e.target.id);
    setActiveTab(e.target.id);
  }

  //Google-form columns handler

  const googleColumnHandler = (e) => {
    e.preventDefault();
    console.log("CLICKED",addColumn);
    if(addColumn < 26) {
      setAddColumn(addColumn + 1);
      // setAlphabetsArray(arrayOfAlphabets);
      console.log("Clicked", addColumn);
    }
  }

  const updateRowHandler = (e) => {
    e.preventDefault();
    setUpdateRow(true);
  }

  const newRowHandler = (e) => {
    e.preventDefault();
    setUpdateRow(false);
  }

  const columnFieldHandler = (e) => {
    console.log(e.target.id, "CLICKED ME");
   let selectedColumn = columnFieldArray.filter((field) => field.id == +e.target.id);  
   selectedColumn[0].selected = true;
   setSelecetedColumnField(selectedColumn);
   
   const findSelectedColumnIndex = columnFieldArray.findIndex((field) => field.id == +e.target.id );
   const newdummyColumnsData =   columnFieldArray.filter((field) => field.id !== +e.target.id).map((field) => {
     field.selected = false;
     return {...field};
    });
    
    newdummyColumnsData.splice(findSelectedColumnIndex,0,selectedColumn[0]);     
    console.log("DATA Is", newdummyColumnsData, findSelectedColumnIndex);
    setColumnFieldArray(newdummyColumnsData); 
    setSaveBtn(true);
  };

  const handleOpenColumn = (e) => {
    e.preventDefault();
    const id = e.target.id;
    setOpenColumnModal(true);
    const selectedColumn = alphabetsArray[+id];
    console.log("OPENED", e.target.id, e.target, selectedColumn);
    setColumnFields(selectedColumn);
  };

  // const modalSearchHandler = (e) => {
  //   e.preventDefault();
  //   console.log("Event", e.target);
  //   const newColumnFieldArray = columnFieldArray.filter((field) => field.field.toLowerCase().includes(searchBarText));
  //   console.log("COLUMN FIELD ARRAY", newColumnFieldArray);
  //   setColumnFieldArray(newColumnFieldArray);
  // }

  const handleCloseColumn = (e) => {
    e.preventDefault();
    setOpenColumnModal(false);
    setColumnFields({});
    setColumnFieldArray(dummyColumnsData);
  };

  const saveColumnDataHandler = (e) => {
    console.log("SELECTED", selectedColumnField, columnFields);

    const selectedColumnFieldIndex = alphabetsArray.findIndex((alphabet) => alphabet.label == columnFields.label);
    console.log("INDEX", selectedColumnFieldIndex, columnFields.label);

    const newAlphabetsArray = alphabetsArray.filter((alphabet) => alphabet.label !== columnFields.label);
    selectedColumnField[0].label = columnFields.label;
    newAlphabetsArray.splice(selectedColumnFieldIndex, 0, selectedColumnField[0]);
    setAlphabetsArray(newAlphabetsArray);
    setOpenColumnModal(false);
    console.log("NEW ALPHABETS ARRAY ss", newAlphabetsArray, selectedColumnField[0]);
    setColumnFieldArray(dummyColumnsData);
    console.log("CLOSED", columnFields);
  }

  const searchBarHandler = (e) => {
    console.log("SearchBarText", e.target.value);
    // setSearchBarText(e.target.value);
    const newColumnFieldArray = dummyColumnsData.filter((field) => field.field.toLowerCase().includes(e.target.value));
    console.log("COLUMN FIELD ARRAY", newColumnFieldArray);
    setColumnFieldArray(newColumnFieldArray);
  }
  
  const renderAddCustomField = () => {
    let toRender = false;
    if(search && campaignAction=='1'){
      if(fieldType == 'ac_field'){
        toRender = true;
      }
    }
    if(toRender)
    return <>
      <TextField fullWidth className="form-control-add-question margin-top"
        value={fieldName}
        onChange={(e) => setFieldName(e.target.value)}
        placeholder={select[campaignAction - 1][0]}
        label={select[campaignAction - 1][0]}
        select
        variant="outlined"
        required
      >
        <MenuItem className="section-name" value='churchName'>Church Name</MenuItem>
        <MenuItem className="section-name" value='churchWebsite'>Church Website</MenuItem>
        <MenuItem className="section-name" value='orgName'>Organization</MenuItem>
        <MenuItem className="section-name" value='jobTitle'>Job Title</MenuItem>
        <MenuItem className="section-name" value='zipCode'>Zip Code</MenuItem>
        <MenuItem className="section-name" value='avgWeeklyAttendance'>Average Weekly Attendance</MenuItem>
        <MenuItem className="section-name" value='denomination'>Church Denomination</MenuItem>
        <MenuItem className="section-name" value='chms'>Current ChMS</MenuItem>
      </TextField>
    </>
  }
  const renderType = () => {
    let toRender = false;
    if(search && campaignAction=="1")
      toRender = true;
    if(toRender)
    return <>
      <TextField fullWidth className="form-control-add-question margin-top"
        value={fieldType}
        onChange={(e) => setFieldType(e.target.value)}
        placeholder="Type"
        label="Type" 
        select
        variant="outlined"
        required
      >
        {/* <MenuItem className="section-name" value='field'>Field</MenuItem>
        <MenuItem className="section-name" value='assessment_url'>Assessment URL</MenuItem>
        <MenuItem className="section-name" value='invite_url'>Invite URL</MenuItem>
        <MenuItem className="section-name" value='tag'>Tag</MenuItem>
        <MenuItem className="section-name" value='buy_url'>Buy URL</MenuItem>
        <MenuItem className="section-name" value='call_url'>Call URL</MenuItem> */}
        <MenuItem className="section-name" value='ac_field'>Connect form field</MenuItem>
        <MenuItem className="section-name" value='ac_field_assessment_url'>Report URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_invite_url'>Invite URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_referrer_name'>Referrer Name</MenuItem>
        <MenuItem className="section-name" value='ac_field_referrer_url'>Referral URL for member/referral</MenuItem>
        <MenuItem className="section-name" value='ac_field_buy_url'>Buy URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_call_url'>Call Request URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_dashboard_url'>Dashboard URL</MenuItem>
        <MenuItem className="section-name" value='ac_field_debrief_url'>Debrief URL</MenuItem>
      </TextField>
    </>
  }
  const renderAfterAction = () => {
    let toRender = false;
    if(search){
      if(campaignAction != 1)
       toRender = true;
      else {
        if(fieldType == 'field'){
          if(acId)
            toRender = true;
        } else if(fieldType != '')
            toRender = true;
      }
    }
    if(toRender)
    return <>
      <TextField
        fullWidth
        className="form-control-add-question margin-top"
        label="After action"
        placeholder="After action"
        value={afterAction}
        onChange={(e) => setAfterAction(e.target.value)}
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        select
        required
        >
        <MenuItem className="section-name" value='leader'>Leader completes the assessment</MenuItem>
        <MenuItem className="section-name" value='referral_leader'>Leader refers a member/referral</MenuItem>
        <MenuItem className="section-name" value='referral'>Member/Referral is added</MenuItem>
        <MenuItem className="section-name" value='member'>Member/Referral completes the assessment</MenuItem>
      </TextField>
    </>
  }

  const getForm = () => {
    return <>
        <TextField fullWidth className="form-control-add-question margin-top"
          value={campaignAction}
          onChange={(e) => {setCampaignAction(e.target.value); setSearch(""); setLoading(true);}}
          placeholder="Active Campaign Action"
          label="Active Campaign Action" 
          select
          variant="outlined"
          required
        >
          <MenuItem className="section-name" value='1'>Add Custom Field</MenuItem>
          <MenuItem className="section-name" value='2'>Tag</MenuItem>
          <MenuItem className="section-name" value='3'>Add to List</MenuItem>
          <MenuItem className="section-name" value='4'>Add to Automation</MenuItem>
        </TextField>
        {campaignAction == "" ? null: type_menu()}
        </>
  }

  const getGoogleForm = () => {
    return <div className="google-sheets">
       <Box sx={{ width: '100%' }}>
      <form>
        <div className="form-headers">
          
          <div onClick={googleTabhandler} className={ activeTab == "one" ? "form-header-active" : "form-header" } id="one">
            <label id="one">LEADER</label>
            <small id="one">COMPLETE THE ASSESSMENT</small>
          </div>

          <div onClick={googleTabhandler} className={ activeTab == "two" ? "form-header-active" : "form-header" } id="two">
            <label id="two">LEADER</label>
            <small id="two">INVITE A MEMBER/REFERRAL</small>
          </div>

          <div onClick={googleTabhandler} className={ activeTab == "three" ? "form-header-active" : "form-header" } id="three">
            <label id="three">REFERRAL /MEMBER</label>
            <small id="three">CREATED</small>
          </div>

          <div onClick={googleTabhandler} className={ activeTab == "four" ? "form-header-active" : "form-header" } id="four">
            <label id="four">REFERRAL /MEMBER</label>
            <small id="four">COMPLETE THE ASSESSMENT</small>
          </div>

        </div>

        <div className="row-section">
          <RadioGroup
          aria-labelledby="row-radio-buttons"
          name="row-buttons"
        >
          <FormControlLabel value="new-row" control={<Radio onClick={newRowHandler} />} label="Create New Row" />
          <FormControlLabel value="update-row" control={<Radio onClick={updateRowHandler} />} label="Update Existing Row" />
        </RadioGroup>
        </div>

        <div className="columns">
         { upDateRow && <div className="column check-for">
            <label>Check for</label>
            <TextField id="check-for" type="text"></TextField>
          </div> }

         { upDateRow && <div className="column on-column">
            <label>On Column</label>
            <TextField id="on-column" type="text"></TextField>
          </div> }

          <div className="column google-sheet">
            <label>Google Sheet ID</label>
            <TextField id="google-sheet" type="text" placeholder="https://docs.google.com/spreadsheets/d/1qpy"></TextField>
          </div>

          <div className="column sheet-name">
            <label>Google Sheet Name</label>
            <TextField id="sheet-name" type="text" placeholder="Bleat"></TextField>
          </div>


        </div>

        <div className="field-and-columns">
          { alphabetsArray.slice(0,addColumn).map((field, idx) => {
            console.log("APSS", field?.field);
            return ( <div key={idx} className="column-data"> 
             <Button variant="outlined">{field.label }</Button>
            { field.field ? <Button variant="outlined" className="text-field" id={idx} onClick={handleOpenColumn}>{field.field}</Button> : <button className="add-text-field" id={idx}  onClick={handleOpenColumn} >+</button> }

            {/* { field.field ? <Button variant="outlined" className="text-field" id={idx} onClick={handleOpenColumn}>{field.field}</Button> : <Button className="add-text-field" variant="outlined" id={idx}  onClick={handleOpenColumn} ><AddIcon id={idx}/></Button> } */}
           {/* { field.field ? <TextField className="text-field" disabled style={{ cursor: "pointer" }} id={idx} onClick={handleOpenColumn} type="text" defaultValue={field.field }></TextField> : <TextField disabled style={{ cursor: "pointer" }} id={idx} onClick={handleOpenColumn} type="text" defaultValue={field.field || "+"}></TextField> } */}
            <TextField className="text-field-input" type="text" placeholder={field?.placeholder1}></TextField>
            <TextField className="text-field-input" type="text" placeholder={field?.placeholder2}></TextField>
            <TextField className="text-field-input" type="text" placeholder={field?.placeholder3}></TextField>
            {/* // adding modal  */}
        </div> )
          })  
            
      }

          <Button onClick={googleColumnHandler} className="add-columns" variant="outlined">+</Button>
              {/* <Button onClick={handleOpenColumn}>Open Child Modal {field.label}</Button> */}
         {openColumnModal && <div
            className="column-modal"
            aria-labelledby="column-data-modal"
            aria-describedby="column-data-description"
            style={{zIndex: 10}}
          >
          {/* <Modal disableEnforceFocus open={openColumnModal} > */}
            <Box className="column-data-modal" sx={{ ...style }}>
            <div className="column-header">
              <h2 id="child-modal-title">Assign Data to - Column {columnFields.label}</h2>
              <Button onClick={handleCloseColumn}><Close/></Button>
            </div> 
              <div className="search-container">
                <TextField inputRef={input => input && input.focus()} onChange={searchBarHandler} fullWidth name="search" placeholder="Type to Search"></TextField>
                {/* <Button onClick={modalSearchHandler}>Search</Button>  */}
              </div>
              <div className="modal-fields">
              <FormControl>
                <RadioGroup
                  aria-labelledby="column-fields"
                  // defaultValue="female"
                  name="column-group"
                >
                {
                  columnFieldArray?.map((data) => {
                    return (
                      data.selected ? <FormControlLabel style={{backgroundColor: "#E7EAEC"}} value={data.field} label={data.field} id={data.id} onClick={columnFieldHandler} control={<CheckCircleIcon id={data.id} onClick={columnFieldHandler} />} />  :  <FormControlLabel id={data.id} onClick={columnFieldHandler} value={data.field} label={data.field} control={<RadioButtonUnchecked id={data.id} onClick={columnFieldHandler} />} /> 
                    )
                    // if( !data.selected) {
                    //   return ( <FormControlLabel value={data.field} label={data.field} control={<RadioButtonUnchecked id={data.id} onClick={columnFieldHandler} />} /> )
                    // }else {
                    //   return ( <FormControlLabel style={{backgroundColor: "#E7EAEC"}} value={data.field} label={data.field} control={<RadioButtonChecked id={data.id} onClick={columnFieldHandler} />} /> )

                    // }
                  })
                }
                </RadioGroup>
              </FormControl>
              </div>
              <div className="modal-btns">
                <Button onClick={handleCloseColumn} className="cancel-btn">Cancel</Button>
              {saveBtn ? <Button onClick={saveColumnDataHandler} className="save-btn">Save</Button> : <Button disabled className="save-btn-disabled">Save</Button> }
            </div>
            </Box>
          {/* </Modal> */}
          </div> }
      
        </div>
      </form>
    </Box>
    </div>
  }

  const getSalesForceForm = () => {
    return (
      <div className="sales-force">
        <Box sx={{ width: '100%' }}>
          <form>
            <div className="form-headers">
              <Button onClick={authenticateSalesForce} className="save-btn">Connect Sales Force</Button>
            </div>
          </form>
        </Box>
      </div>
    )
  }
 
  useEffect(getFromAPI, [campaignAction, search]);

  console.log("filter", filter);

  const type_menu = () => {
    return <>
    {isLoading? <CircularProgress style={{'color': '#00A04F'}} />:
      <Autocomplete
        key={campaignAction}
        inputValue={search}
        onChange={(event, value) => {
          setAcId(value.id);
          console.log("value", value);
        }}
        onInputChange={(event, newValue) => {
          setSearch(newValue);
          console.log(newValue)
          if(newValue == null)
            setFilter(fromAPI);
          else
            setFilter([newValue]);
        }}
        options={fromAPI}
        renderInput={(params) => <TextField {...params} className="form-control-add-question margin-top" variant="outlined" label="Search" />}
      />}
      {renderType()}
      {renderAddCustomField()}
      {renderAfterAction()}
    </>
  }

  const check_btn = () => {
    if(form == "" || campaignAction == "" || search == "" || afterAction == "")
      return true;
    else if(campaignAction == "1" && fieldType == "")
      return true;
    else if(campaignAction == "1" && (fieldType == "field" && !acId))
      return true;
    return false;
  }

  return (
    <div className="drawer">
      <header>
        <h1 data-aos="fade-right" data-aos-delay="500">
          Add Automation
        </h1>
      </header>

      <section>
        <RadioGroup row aria-labelledby="integration" name="integration" defaultValue="top">
          <FormControlLabel value="active-campaign" control={<Radio />} label="Active Campaign" onClick= {(e) => {setForm(e.target.value)}} />
          <FormControlLabel value="google-sheets" control={<Radio />} label="Google Sheets" onClick= {(e) => {setForm(e.target.value)}} />
          <FormControlLabel value="Calendly" control={<Radio />} label="Calendly" onClick= {(e) => {setForm(e.target.value)}} />
          <FormControlLabel value="sales-force" control={<Radio />} label="Sales Force" onClick= {(e) => {setForm(e.target.value)}} />
        </RadioGroup>
        {chooseForm()}

        <div className="double-column-form aligned-right margin-top">
          <Button onClick={handleSubmit} type="submit" variant="contained" component="label" disabled={check_btn()}>
            Add Automation
          </Button>
        </div>
      </section>
    </div>
  );

  
}



export default CreateAssessmentAutomationDrawer;

