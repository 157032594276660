
import React,{useContext, useState, useEffect} from 'react'
import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import { CircularProgress, FormControl, FormGroup, TextField } from "@mui/material";


const EditContactDetails = (props) => {
  // const { details } = props;
  // console.log("EditWebsiteDetails props:", props.details);
  // console.log("props details are",props.details);
  const modalCtx = useContext(ModalContext);

  const [websiteURL, setWebsiteURL]           = useState(modalCtx.details.website);
  const [websiteTitle, setWebsiteTitle]       = useState(modalCtx.details.websiteTitle);

    // console.log("modal ctx details are", modalCtx.details);

    // useEffect(() => {
    //   modalCtx.setDetails(props.details);
    // }, [])
    
    const handleCloseModal = () => {
        modalCtx.setDetails(null);
        modalCtx.closeModal();
      }
    const handleEditWebsite = (e) => {
      e.preventDefault();
    //   console.log("title:"+websiteTitle + "  URL: " + websiteURL);
    // modalCtx.setDetails({websiteURL, websiteTitle});
    //   console.log("modal ctx details are oooooo", modalCtx.details);

    }
  return (
    <div className='modal'>
     <div>Edit Contact Details Here</div>
     <form>
          <FormGroup>
            <div className="double-column-form">
              <FormControl fullWidth margin="dense" className="form-control">
                <TextField required value={websiteTitle} onChange={(e) => setWebsiteTitle(e.target.value)} label="First Name" variant="outlined">
                </TextField>
              </FormControl>
              <FormControl fullWidth margin="dense" className="form-control">
                <TextField required value={websiteURL} onChange={(e) => setWebsiteURL(e.target.value)} label="Last Name" variant="outlined">
                  </TextField>
              </FormControl>
              <FormControl fullWidth margin="dense" className="form-control">
                <TextField required value={websiteTitle} onChange={(e) => setWebsiteTitle(e.target.value)} label="Job Title" variant="outlined">
                </TextField>
              </FormControl>
              <FormControl fullWidth margin="dense" className="form-control">
                <TextField required value={websiteURL} onChange={(e) => setWebsiteURL(e.target.value)} label="Email" variant="outlined">
                  </TextField>
              </FormControl>
            </div>
          </FormGroup>
          <div className="footer" style={{ margin: "15px auto 0" }}>
            <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
            <Button type="submit" className="confirm-btn" onClick={(e) => {handleEditWebsite(e)} }>Edit</Button>
          </div>
        </form>
    
    </div>
  )
}

export default EditContactDetails