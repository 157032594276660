import { useState, useContext, useEffect } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import axios from 'axios';
import closeIcon from '../../assets/img/closeIcon.svg'
import AuthContext from '../../store/AuthContext.js';
import ModalContext from '../../store/ModalContext.js';
import SubDrawerContext from '../../store/SubDrawerContext.js';

const QuestionDelete = (props) => {

  const authCtx = useContext(AuthContext);
  const modalCtx = useContext(ModalContext); 
  const subdrawerCtx = useContext(SubDrawerContext); 
  const [isLoading, setIsLoading] = useState(false);

  const handleQuestionDelete = () => {
    console.log('Payload',props)
    // axios.delete(process.env.REACT_APP_API_URI + '/v3/admin/question/' + modalCtx.details.id + '/delete', {
    //   mode: 'cors',
    //   headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token }
    // }).then(response => response.data)
    //   .then(result => {
    //     console.log(result);
    //     if (result.status === "success") {
    //       modalCtx.closeModal();
    //       modalCtx.setDetails(null);
    //       // modalCtx.setPageUpdate({page: "goals"});
    //     } else {
    //       console.log("failed to delete question");
    //     }
    //   }).catch(err => console.error(err));

    setIsLoading(true)

    const apiUrl = process.env.REACT_APP_API_URI + '/v3/admin/question/' + props?.details?.questionDetails?.id;
    let clientId = props.details.clientId;
    let sectionId = props.details.questionDetails.sectionId;
    let responseType = props.details.questionDetails.type;
    let isAdditional = props.details.isAdditional;
    let question = props.details.questionDetails.question;
    let mandatory = props.details.questionDetails.mandatory;
    let answerType = props.details.questionDetails.type;
    let answers = props.details.questionDetails.answers;
    let pageId = props.details.questionDetails.pageId;
    let variable = props.details.questionDetails.variable;

    console.log('clientId',clientId)
    console.log('sectionId',sectionId)
    console.log('responseType',responseType)
    console.log('isAdditional',isAdditional)
    console.log('question',question)
    console.log('mandatory',mandatory)
    console.log('answerType',answerType)
    console.log('answers',answers)

    const formattedAnswers = answers.map(answer => ({
      isNew: answer?.isNew ? true : false,
      id: answer.id,
      orderNo: answer.orderNo,
      answerDesktop: answer.desktopText,
      answerMobile: answer.mobileText,
      value: answer.value,
    }));

    console.log('formattedAnswers',formattedAnswers)


    const payload = {
      "clientId": parseInt(clientId),
      "sectionId": parseInt(sectionId),
      "responseType": responseType,
      "isAdditional": isAdditional,
      "question": question,
      "mandatory": mandatory,
      "answerType": answerType,
      "answers": formattedAnswers,
      "pageId": parseInt(pageId),
      "variable": variable
    }
    console.log('payload',payload)

    fetch(apiUrl, { 
      method: 'DELETE', 
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(result => {
      console.log(result);
      if (result.status === "success") {
        modalCtx.closeModal();
        modalCtx.setDetails(null);
        // modalCtx.setPageUpdate({page: "goals"});
        console.log("question deleted");
        if(subdrawerCtx.pageUpdate?.toggleUpdate){
          modalCtx.setPageUpdate({'type': "deleteQuestion", 'toggleUpdate': false});
        }
        else{
          modalCtx.setPageUpdate({'type': "deleteQuestion", 'toggleUpdate': true});
        }

        setIsLoading(false)

      } else {
        console.log("failed to delete question");
      }
    })
    .catch(err => {
      console.error("Error:", err);
      if (err.response) {
        console.error("Response status:", err.response.status);
        console.error("Response data:", err.response.data);
      }
    });    
  }

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }


  return (
    <div className="add-goal-form">
      <div className="heading-and-close-icon-container">
       <p>Are you sure you want to delete this question ?</p>
       <div className="close-icon" onClick={(e) => { handleCloseModal() }}>
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
          <g id="Group_4507" data-name="Group 4507" transform="translate(-4322 -2323)">
            <g id="close" transform="translate(4316.508 2317.506)">
              <g id="Group_353" data-name="Group 353" transform="translate(9.034 9.036)">
                <path id="Path_379" data-name="Path 379" d="M10.052,9.211a.6.6,0,1,0-.843.843l3.441,3.441L9.209,16.935a.6.6,0,1,0,.843.843l3.442-3.441,3.441,3.441a.6.6,0,1,0,.843-.843l-3.441-3.441,3.441-3.441a.6.6,0,1,0-.843-.843l-3.441,3.441L10.052,9.21Z" transform="translate(-9.034 -9.036)" fill="#fff"/>
              </g>
            </g>
            <rect id="Rectangle_102" data-name="Rectangle 102" width="16" height="16" transform="translate(4322 2323)" fill="none"/>
          </g>
        </svg>
       </div>
      </div>
      <div className="form-btns">
        <Button className="delete-btn" onClick={() => { handleQuestionDelete() }}>{isLoading ? <CircularProgress size={25} color='#fff'/> : "Delete"}</Button>
        <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
      </div>
    </div>
  )
}

export default QuestionDelete;
