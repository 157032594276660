import { Box, Tab, Tabs, FormControlLabel, RadioGroup, Radio, Button, CircularProgress, TextareaAutosize, Modal } from "@material-ui/core";
import { PropTypes } from "@material-ui/core";
import { Fragment, useContext, useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Clear, Delete as DeleteIcon, Edit as EditIcon } from "@mui/icons-material";
import AuthContext from '../store/AuthContext';
import { Autocomplete, TextField, Chip } from "@mui/material";

// import EditNoteIcon from "@mui/icons-material/EditNote";

import editIcon from '../../src/assets/img/edit.svg'
import delIcon from '../../src/assets/img/Delete.svg';
import SendEmailIcon from '../../src/assets/img/sendEmail.svg';
import codeIcon from '../../src/assets/img/code.svg';
import upload from '../../src/assets/img/upload.svg';
import cross from '../../src/assets/img/cross.png';
import { useParams } from "react-router-dom";
import ModalContext from "../store/ModalContext";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}


const emailSentDataColumns = [
  { field: 'date', headerName: "Date", width: 150, editable: true },
  { field: 'name', headerName: "Name", width: 150, editable: true },
  { field: 'email', headerName: "Email", width: 250, editable: true },
  { field: 'templateName', headerName: "Template Name", width: 250, editable: true },
  {
    field: 'status', headerName: "Status", width: 150, editable: true, renderCell: (params) => {
      if (params.value == 'sent') {
        return (
          <div style={{ background: "#1ABC9C", color: "white", padding: "2px 15px", borderRadius: "20px" }}>
            {params.value}
          </div>
        )
      } else {
        return (
          <div style={{ background: "#E14949", color: "white", padding: "2px 15px", borderRadius: "20px" }}>
            {params.value}
          </div>
        )
      }
    }
  },
  {
    field: 'edit', align: "right", headerName: "", width: 180, renderCell: (params) => {
      return (
        <>
          {/* <EditIcon /> 
     <DeleteIcon/> */}
          <img src={editIcon} alt="edit" />
          <img src={delIcon} alt="delete" />
        </>
      )
    }
  }
]

const emailSentDataRows = [
  { id: 1, date: "May 25, 2023", name: "Ryan", email: "ryan@gmail.com", templateName: "TemplateOne", status: "sent" },
  { id: 2, date: "May 26, 2023", name: "Cyan", email: "cyan@gmail.com", templateName: "TemplateTwo", status: "sent" },
  { id: 3, date: "May 27, 2023", name: "Zyan", email: "zyan@gmail.com", templateName: "TemplateThree", status: "pending" },
  { id: 4, date: "May 28, 2023", name: "Xyan", email: "xyan@gmail.com", templateName: "TemplateFour", status: "pending" },

]


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



const Email = () => {

  const authCtx = useContext(AuthContext);
  const { id } = useParams()


  const modalCtx = useContext(ModalContext);

  const [tabValue, setTabValue] = useState(0);
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [editHtmlCode, setEditHtmlCode] = useState(false);
  const [editNotification, setEditNotification] = useState(false);
  const [confirmSend, setConfirmSend] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [codeData, setCodeData] = useState([]);
  const [headerImage, setHeaderImage] = useState(upload);
  const [notificationDataRow, setNotificationDataRow] = useState([]);
  const [senderName, setSenderName] = useState("");
  const [senderEmail, setSenderEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [emailType, setEmailType] = useState("");
  const [assessment, setAssessment] = useState([]);
  const [awsImageUrl, setAwsImageUrl] = useState("");
  const [upLoadingImage, setUpLoadingImage] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [editCodeId, setEditCodeId] = useState("");
  const [leadersName, setLeadersName] = useState("test");
  const [memberName, setMemberName] = useState("test");
  const [inviteUrl, setInviteUrl] = useState("");
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [firstName, setFirstName] = useState("test");
  const [lastName, setLastName] = useState("test");
  const [email, setEmail] = useState([]);
  const [emailId, setEmailId] = useState("");


  console.log(email)

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };
  const openCreatePopup = () => {
    setPopupOpen(true);
  };

  const closeCreatePopup = () => {
    setPopupOpen(false);
    setHeaderImage(upload)
  };


  const handleSubmit = (e) => {
    e.preventDefault();

    if (awsImageUrl && senderName && senderEmail && emailType && subject) {
      setIsCreating(true)

      const bodydata = {
        "templateId": 1,
        "subject": subject,
        "sendersName": senderName,
        "sendersEmail": senderEmail,
        "emailType": emailType,
        "headerImage": awsImageUrl
      }
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/email', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(bodydata),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          setIsCreating(false);
          if (data.status === "success") {
            console.log("successfully added ...");
            fncGetEmailData();
          }
          setPopupOpen(false);

        })
        .catch((err) => console.error("failed to add...", err));
    }


  }

  const handleSendEmail = (e) => {
    e.preventDefault();

    if (email && firstName && lastName && leadersName && memberName && dashboardUrl && inviteUrl) {
      setIsCreating(true)

      const bodydata = {
        email: email,
        firstName: firstName,
        lastName: lastName,
        leadersName: leadersName,
        teamMemberName: memberName,
        inviteUrl: inviteUrl,
        dashboardUrl:dashboardUrl
      }
      fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/'+id+'/send/'+emailId+'/testEmail', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(bodydata),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          setIsCreating(false);
          if (data.status === "success") {
            console.log("successfully added ...");
            setConfirmSend(false)
          }

        })
        .catch((err) => console.error("failed to add...", err));
    }


  }

  const SubmitHtmlCode = (e) => {
    e.preventDefault();

    if (codeData) {
      setIsCreating(true)

      const bodydata = {
        "body": codeData
      }
      fetch(process.env.REACT_APP_API_URI + "/v3/admin/assessment/" + id + "/email/" + editCodeId + "/updateHtmlBody", {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        body: JSON.stringify(bodydata),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("data", data);
          setIsCreating(false);
          if (data.status === "success") {
            console.log("successfully added ...");
            fncGetEmailData();
          }
          setEditHtmlCode(false)


        })
        .catch((err) => console.error("failed to add...", err));
    }


  }


  useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + '/v1/admin/assessments/', {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' }
    })
      .then(response => {
        return response.json();
      }).then(data => {
        // console.log(data);
        if (data.status === "success") {
          setIsLoading(false);
          setAssessment(data.data);
   
        }
      }).catch(err => console.error(err));



    fncGetEmailData()

  }, [])

  const fncGetEmailData = () => {
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/emails', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authCtx.token}`,
      }
    }).then(response => {
      return response.json();
    }).then(data => {
      if (data.status == "success") {
        setNotificationDataRow(data?.data)
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    });

  }

  const getImageUrl = (e) => {
    e.stopPropagation();
    setUpLoadingImage(true)

    const formData = new FormData();
    formData.append('image', e.target.files[0]);

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/' + id + '/updateImage', {
      method: 'PUT',
      headers: {
        'Authorization': 'Bearer ' + authCtx.token
      },
      body: formData,

    })
      .then(response => {
        return response.json();
      }).then(data => {
        if (data.status === "success") {
          setAwsImageUrl(data?.imagePath)
          setHeaderImage(data?.imagePath)
          setTimeout(() => {
            setUpLoadingImage(false)

          }, 3000);

        }
      }).catch(err => console.error(err));

  }



  const fncPreview = (img) => {
    setPreview(true)
    setPreviewImage(img)

  }



  const currentAssessmentInfo = assessment.filter(assessment => assessment.id.toString() === id.toString());


  const notificationDataColumns = [
    {
      field: 'name', headerName: "Senders Name and Email", width: 250, editable: true, renderCell: (params) => {
        return (
          <div className="email-name">
            <div>
              {getHighlightedText(capitalizeWords(params.row?.sendersName ), searchTerm) || ""}
             </div>
            <div style={{ fontWeight: 300 }}>
              {getHighlightedText(capitalizeWords(params.row?.sendersEmail), searchTerm) || ""}
            </div>
          </div>
        )

      }
    },
    { field: 'headerImage', headerName: "Header Image", width: 280, editable: true, renderCell: (params) => <img style={{ height: "100%", objectFit: "cover" }} src={params.value} onClick={() => { fncPreview(params.value) }} /> },
    {
      field: 'subject', headerName: "Subject", width: 300, editable: true, renderCell: (params) => (getHighlightedText(capitalizeWords(params.row?.subject), searchTerm) || "" )
    },
    {
      field: 'emailType', headerName: "Email Type", width: 200, editable: true, renderCell: (params) => {

        return <div className="email-type" style={{ fontSize: "15px" }}>{getHighlightedText(capitalizeWords(params.row?.emailType), searchTerm) || ""}</div>
      }
    },
    {
      field: 'status', headerName: "Status", width: 100, editable: true, renderCell: (params) => {
        // if (params.value == 'Active') {
        //   return (
        //     <div style={{ background: "#1ABC9C", color: "white", padding: "2px 15px", borderRadius: "20px" }}>
        //       {params.value}
        //     </div>
        //   )
        // } else {
        //   return (
        //     <div style={{ background: "#C4C4C4", color: "white", padding: "2px 15px", borderRadius: "20px" }}>
        //       {params.value}
        //     </div>
        //   )
        // }
        return <div style={{ background: "#1ABC9C", color: "white", padding: "2px 15px", borderRadius: "20px" }}>
          Active</div>
      }
    },
    {
      field: 'edit', align: "right", headerName: "", width: 250, editable: true, renderCell: (params) => {
        return (

          <>
            <img src={SendEmailIcon} alt="send" onClick={() => {fncSendEmail(params) }} />
            <img src={codeIcon} alt="code" onClick={() => { fncEditCode(params) }} />
            <img src={editIcon} alt="edit" onClick={() => { fncEditEmailData(params) }} />
            <img src={delIcon} alt="delete" onClick={() => { fncDeleteEmail(params) }} />
          </>
        )
      }
    }

  ]
  const showSearchBar = () => (
    <div className="search-bar">
      <input type="search" name="" id="search-field" className="search-field" placeholder="Type to search..." value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
      {searchTerm && <Clear className="clear-search-icon" onClick={() => setSearchTerm("")} />}
    </div>
  );


  function getHighlightedText(text, highlight) {
    // Find the index of the match and the length of the highlight
    if (text?.length > 0) {
      const startIndex = text?.toLowerCase().indexOf(highlight.toLowerCase());
      const endIndex = startIndex + highlight.length;

      // If the highlight is not found, return the original text
      if (startIndex === -1) return text;
      // Otherwise, split the text and highlight the matching substring
      const parts = [
        text.substring(0, startIndex),
        text.substring(startIndex, endIndex),
        text.substring(endIndex),
      ];

      return (
        <Fragment>
          {parts.map((part, i) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={i} style={{ backgroundColor: "greenyellow" }}>{part}</span>
            ) : (<span key={i}>{part}</span>)
          )}
        </Fragment>
      );
    }
  };


  function capitalizeWords(str) {
    return str.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });
  }


  const handleCodeDataChange = (event) => {
    setCodeData(event.target.value);
  };


  const fncEditEmailData = (data) => {
    modalCtx.openModal();
    modalCtx.setDetails("edit-email-modal", data);

  }
  const fncDeleteEmail = (data) => {

    modalCtx.openModal();
    modalCtx.setDetails("delete-email-modal", data);
  }

  const fncEditCode = (params) => {
    setCodeData(params?.row?.body)
    setEditHtmlCode(true)
    setEditCodeId(params?.row?.id)
  }
  const fncSendEmail = (params) => {
    setEmailId(params?.row?.id)
    setConfirmSend(true)
  
  }
  const boxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    // width: 800,
    // height: 700,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    // overflow: "scroll"
  };
 
  useEffect(() => {
    if (currentAssessmentInfo?.length !== 0) {
      setDashboardUrl(currentAssessmentInfo[0]?.url + "/xxyyzz/dashboard")
      setInviteUrl(currentAssessmentInfo[0]?.url + "/xxyyzz/invite")
    }
  }, [currentAssessmentInfo]);
  if (isLoading) {

    return <section className="loader-section"><CircularProgress /></section>
  }
  return (

<> 
      <div className="assessment-name">
        <div data-aos="fade-right">
          <h1>{currentAssessmentInfo[0]?.title}</h1>
          {currentAssessmentInfo[0]?.url && <p className="assessment-link-text">Link to assessment: <a className="assessment-link" href={currentAssessmentInfo[0]?.url} target="_blank" rel="noopener noreferrer">{currentAssessmentInfo[0]?.url}</a></p>}
        </div>
      </div>

    <div className="email">
      <div className="email-container">
         <Box sx={{
          width: '100%', '& .super-app-theme--header': {
            backgroundColor: 'red',
          }
        }}>
          <Box className="box" sx={{ borderBottom: 1, borderTop: 1, borderColor: '#EDEDED' }}>
            <Tabs TabIndicatorProps={{ style: { background: '#F2D466', height: '4px' } }} value={tabValue} onChange={handleTabChange} aria-label="basic tabs example">
              <Tab className="tab-title" label="Email Notifications"{...a11yProps(0)} />
              <Tab className="tab-title" label="Email Sent" {...a11yProps(1)} />
            </Tabs>
          </Box>
          <div className="btn-container">
            {showSearchBar()}
            {tabValue == 0 ? <button className="create-btn" onClick={openCreatePopup}>CREATE</button> : ""}
          </div>

          <TabPanel value={tabValue} index={0} className="email-notification-table grid">
            <DataGrid style={{ height: "90vh", width: "100%" }} className="email-notification-grid-container" rows={notificationDataRow} columns={notificationDataColumns} disableSelectionOnClick={true} editMode={false} rowHeight={70} />

          </TabPanel>

          <TabPanel value={tabValue} index={1} className="email-sent-table grid">
            <DataGrid style={{ minHeight: "90vh", height: "100%", width: "100%" }} className="email-sent-grid-container" rows={emailSentDataRows} columns={emailSentDataColumns} disableSelectionOnClick={true} checkboxSelection editMode={false} />


          </TabPanel>
        </Box>

      </div>



      {isPopupOpen && (
        <Modal className="add-goal-lightbox" open={isPopupOpen}><Box sx={boxStyles}>
          <div className="create-notification-container">
            <div className="modal-content">
              <div className="main-content">

                <div className="heading-container">
                  <div className="heading">Create New Notification</div>
                  <img src={cross} onClick={closeCreatePopup} alt="cross" />
                </div>
                <form onSubmit={handleSubmit}>

                  <div className="items">
                    <div className="label">Header Image</div>
                    <label className="upload-container">
                      <input type="file" onChange={(e) => { getImageUrl(e) }} />
                     
                        {upLoadingImage ? <section className="loader-section-uploaing"><CircularProgress /> Uploading</section> : <img src={headerImage} alt="upload" />}
                    </label>

                  </div>
                  <div className="items">
                    <div className="label">Senders Name</div>
                    <input className="text-box" type={"text"} placeholder={"Senders Name"} onChange={(e) => { setSenderName(e.target.value) }} />
                  </div>
                  <div className="items">
                    <div className="label">Senders Email</div>
                    <input className="text-box" type={"email"} placeholder={"Senders Email"} onChange={(e) => { setSenderEmail(e.target.value) }} />
                  </div>

                  <div className="items">
                    <div className="label">Subject</div>
                    <input className="text-box" type={"text"} placeholder={"Subject"} onChange={(e) => { setSubject(e.target.value) }} />
                  </div>
                  <div className="items">
                    <div className="label">Type of Email</div>
                    <div className="row-section">
                      <RadioGroup
                        aria-labelledby="row-radio-buttons"
                        name="row-buttons"
                        onChange={(e) => { setEmailType(e.target.value) }}
                      >
                        <FormControlLabel value="Custom Notification" control={<Radio />} label="Custom Notification" />
                        <FormControlLabel value="Email Summary" control={<Radio />} label="Email Summary" />
                      </RadioGroup>
                    </div>
                  </div>

                  {isCreating ?
                    <button className="create-btn-submit" disabled> <CircularProgress /></button> :
                    <button className="create-btn-submit" type="submit" >CREATE</button>
                  }
                </form>

              </div>
            </div>

          </div>
        </Box></Modal>

      )}



      {editHtmlCode && (
        <Modal className="add-goal-lightbox" open={editHtmlCode}><Box sx={boxStyles}>
          <div className="edithtml-popup-modal">
            <div className="edithtml-popup-content">
              <div className="main-content">
                <div className="heading-container">
                  <div className="heading">
                    Edit Body HTML Code
                  </div>
                  <img src={cross} onClick={() => { setEditHtmlCode(false) }} alt="cross" />

                </div>
                <TextareaAutosize minRows={6} value={codeData || ""} onChange={handleCodeDataChange} />
                {isCreating ?
                  <button className="edit-btn" disabled> <CircularProgress /></button> :
                  <button className="edit-btn" onClick={(e) => { SubmitHtmlCode(e) }}>Save Changes</button>
                }
              </div>

            </div>
          </div>
        </Box></Modal>



      )}


      {confirmSend && (
        <Modal className="add-goal-lightbox" open={confirmSend}>
          <Box sx={boxStyles}>
            <div className="send-popup-modal">
              <div className="send-popup-content">
                <div className="main-content">
                  <div className="heading-container">
                    <div className="heading">
                      Do you want to send a test email?
                    </div>
                    <img src={cross} onClick={() => { setConfirmSend(false) }} alt="cross" />
                  </div>


                  <form onSubmit={handleSendEmail} >
                    <div className="form-group">
                      <label htmlFor="leadersName">Leaders Name</label>
                      <input type="text" defaultValue={leadersName} onChange={(e) => { setLeadersName(e.target.value) }} />
                    </div> <div className="form-group">
                      <label htmlFor="memberName">Member Name</label>
                      <input type="text" defaultValue={memberName} onChange={(e) => { setMemberName(e.target.value) }} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="inviteUrl">Invite URL</label>
                      <input type="url" defaultValue={inviteUrl} onChange={(e) => { setInviteUrl(e.target.value) }} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="dashboardUrl">Dashboard URL</label>
                      <input type="url" defaultValue={dashboardUrl} onChange={(e) => { setDashboardUrl(e.target.value) }} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="firstName">First Name</label>
                      <input type="text" defaultValue={firstName} onChange={(e) => { setFirstName(e.target.value) }} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="lastName">Last Name</label>
                      <input type="text" defaultValue={lastName} onChange={(e) => { setLastName(e.target.value) }} />
                    </div>
                    <div className="form-group">
                      <label htmlFor="email">Email</label>
                      <Autocomplete
                        multiple
                        id="tags-filled"
                        className="email_chip"
                        options={[]}
                        defaultValue={[]}
                        freeSolo
                        value={email}
                        onChange={(event, newValue) => setEmail(newValue)}
                        renderTags={(value, getTagProps) =>
                          value.map((option, index) => (
                            <Chip
                              key={index}
                              variant="outlined"
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            
                           
                            placeholder="Enter Email"
                          />
                        )}
                      />
                    
                    </div>
                    {isCreating ?
                      <button className="delete-btn" disabled> <CircularProgress /></button> :
                      <button className="delete-btn" type="submit" >Send</button>
                    }
                    <button className="cancel-btn" onClick={() => { setConfirmSend(false) }}>Cancel</button>
                  </form>
                </div>
              </div>

            </div>
          </Box></Modal>
      )}


      {preview &&
        <div className="preview">
          <div className="modal-content">
            <div className="main-content">
              <div className="heading-container">

                <img src={cross} onClick={() => { setPreview(false) }} alt="cross" />
              </div>



              <img src={previewImage} alt="" />

            </div>
          </div>
        </div>
      }

      </div>
    </>
  )
}




export default Email;