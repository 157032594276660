import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useParams, NavLink, useSearchParams } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import DeleteIcon from "@material-ui/icons/Delete";
import redEditIcon from '../../assets/img/builder-images/red-edit-icon.png'
import deleteIcon from '../../assets/img/Question Builder/DelIcon.svg'
import EditIcon from '../../assets/img/Question Builder/editIcon.svg'
import SliderIcon from '../../assets/img/Question Builder/Slider.svg'
import TextIcon from '../../assets/img/Question Builder/textIcon.svg'
import DragIcon from '../../assets/img/Question Builder/DragIndicator.svg'
import RadioListIcon from '../../assets/img/Question Builder/radioListIcon.svg'
import AssessmentIcon from "@material-ui/icons/Assessment";
import { Person as PersonIcon, People as PeopleIcon, Help as HelpIcon, VerticalSplit as VerticalSplitIcon, } from "@material-ui/icons";
import CircularProgress from "@material-ui/core/CircularProgress";
import Chip from "@material-ui/core/Chip";
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import { List } from "@material-ui/icons";
import redStar from '../../assets/img/redStar.svg'
import GrayStar from '../../assets/img/graystar.svg'
import UsersDrawer from "../DetailsDrawer";
import DrawerContext from "../../store/DrawerContext.js";
import SubDrawerContext from "../../store/SubDrawerContext";
import ModalContext from "../../store/ModalContext.js";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AuthContext from "../../store/AuthContext";
import { FlashOnOutlined } from "@mui/icons-material";
import { Alert } from "@material-ui/lab";
import { Snackbar } from "@mui/material";
import BuilderContext from "../../store/BuilderContext.js";

const useStyles = makeStyles({
  table: { minWidth: 650, color: "#747d8c", },
});

function AssessmentAdditionalQuestionTable(props) {
  let navigate = useNavigate();
  const { id } = useParams();
  const builderCtx = useContext(BuilderContext);
  const drawerCtx = useContext(DrawerContext);
  let authCtx = useContext(AuthContext)
  const subdrawerCtx = useContext(SubDrawerContext);
  const modalCtx = useContext(ModalContext);
  const classes = useStyles();
  const [state, setState] = useState(false);
  const [isMandatoryVisible, setisMandatoryVisible] = useState(false)
  const [mandatoryIndex, setMandatoryIndex] = useState()
  const [isLoading, setIsLoading] = useState(false);
  // Replace props with useState for questions and additionalQuestions
  const [questions, setQuestions] = useState(props.questions || []);
  const [additionalQuestions, setAdditionalQuestions] = useState(props.additionalQuestions || []);
  const [isAdditional, setIsAdditional] = useState(props.isAdditional || false);
  const [sections, setSections] = useState([]);
  const [toggleVariableBtn, settoggleVariableBtn] = useState(false);
  const [toggleVariableBtnID, settoggleVariableBtnID] = useState();
  const [variableValue, setvariableValue] = useState()
  const [refresh, setRefresh] = useState(false);
  const [ShowDuplicateVariableSnackbar, setShowDuplicateVariableSnackbar] = useState(false)

  const subDrawer = props?.subDrawer || false;

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  function toggleSubDrawerHandler(drawer, user) {
    subdrawerCtx.openDrawer();
    subdrawerCtx.setDetails(drawer, user);
  }

  const drawerHandler = (user) => {
    console.log('sections:',props.sections)
    console.log('props',props)
    user = {...user, sections:props.sections}
    console.log('user',user)
    if (subDrawer) {
      toggleSubDrawerHandler("question-edit-subdrawer", user);
    } else {
      toggleDrawerHandler("question-edit", user);
    }
  };

  const toggleModalHandler = (modal, user) => {
    modalCtx.openModal();
    modalCtx.setDetails(modal, user);
  };

  const showTypeIcons = (type) => {
    if (type === "slider") {
      return <img src={SliderIcon} alt="" />;
    } else if (type === "textarea") {
      return <img src={TextIcon} alt="" />;
    }
    else if (type === 'radio') {
      return <img src={RadioListIcon} alt="" />;
    }
  };

  // to toggle variable and input Box
  const editVariable = (id, data) => {
    setvariableValue(data?.variable)
    settoggleVariableBtn(true);
    settoggleVariableBtnID(id);
  };

  const variableUpdate = (data, index) => {
    const newQuestions = [...questions]
    const prevValue = newQuestions[index].variable;
    newQuestions[index] = {
      ...newQuestions[index],
      variable: variableValue
    }
    const payload = newQuestions[index]
    setIsLoading(true)
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/question/' + data.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('data', data)

        if (data.status == 'success') {
          setQuestions(newQuestions)
        }
        else if (data.status == 'failure') {
          setShowDuplicateVariableSnackbar(true)
          setIsLoading(false)
          setvariableValue(prevValue)
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error', error);
      })

    setTimeout(() => {
      settoggleVariableBtn(false)
    }, 1000);

  }

  const handleClose = () => {
    setShowDuplicateVariableSnackbar(false)
  }

  const mandatoryToggle = (mandatoryData, index) => {
    const newQuestions = [...questions]
    newQuestions[index] = {
      ...newQuestions[index],
      mandatory: mandatoryData?.mandatory == 'yes' ? 'no' : 'yes'
    }
    const payload = newQuestions[index]
    console.log('newQuestions', newQuestions)
    console.log('payload', payload)
    setQuestions(newQuestions)

    fetch(process.env.REACT_APP_API_URI + '/v3/admin/question/' + mandatoryData.id, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload)
    })
    .then((response) => response.json())
    .then((data) => { console.log('data', data) })
    .catch((error) => {
      console.log('error', error)
    })
  }

  const updateQuestionsOrder = (data) =>{
    let newQuestionsPayload = {
      "questionOrder":
        data.map((item,index)=>{
          return {
            "questionId": item.id,
            "orderIndex": item.orderNo
          }
        })
    } 

    console.log("payload--------------------",newQuestionsPayload)
    console.log("payload data--------------------",data)

    fetch(`${process.env.REACT_APP_API_URI}/v3/admin/question/update/order`, {
      method: 'PUT',
      headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + authCtx.token 
      },
      body: JSON.stringify(newQuestionsPayload), // Convert the data object to a JSON string
    })
    .then((response) => response.json())
	  .then((data) => {
		console.log('Updated question order',data);
      if (data.status === "success") {
        console.log("Successfully updated the question order...");
        // getQuestions()
        drawerCtx.setPageUpdate({'type' : 'questionOrder','toggle':drawerCtx.pageUpdate?.toggle ? false : true})
      }
	  })
	  .catch((err) => console.error("Failed to update the resource...", err));
  }

  useEffect(()=>{    
    if ( subdrawerCtx?.drawer === "question-add-subdrawer" || modalCtx?.modal === "question-delete" ){
      let forLocalState = [];
      const newQuestions = [...questions];

      newQuestions.map((el,index)=>{
        forLocalState.push({...el, orderNo:index+1})
      })  
      setQuestions(forLocalState)
      updateQuestionsOrder(forLocalState)
      console.log('forLocalState',forLocalState)
    }
  },[modalCtx,subdrawerCtx])

  useEffect(() => {
    setSections(props.sections)
  }, [props.sections])

  useEffect(() => {
    let filteredQuestions = props.questions;
    filteredQuestions.sort((a, b) => a.orderNo - b.orderNo);
    setQuestions(filteredQuestions)
    setAdditionalQuestions(props.additionalQuestions)
    console.log('filteredQuestions',filteredQuestions)
  }, [props.additionalQuestions, props.questions]);

  return (
    <>
      <TableContainer component={Paper}>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              // If the item was dropped outside of a valid drop target, do nothing
              return;
            }

            const sourceIndex = result.source.index;
            const destinationIndex = result.destination.index;

            if (result.source.droppableId === "tasks") {
              // Handle reordering of main questions
              const newQuestions = [...questions]; // Use local state
              const [draggedItem] = newQuestions.splice(sourceIndex, 1);
              newQuestions.splice(destinationIndex, 0, draggedItem);
              console.log( "New Order (Main Questions):", newQuestions);
              
              let forLocalState = [];
              newQuestions.map((el,index)=>{
                forLocalState.push({...el, orderNo:index+1})
              })
              setQuestions(forLocalState); // Update local state

              updateQuestionsOrder(forLocalState);

            } else if (result.source.droppableId === "additional-questions") {
              // Handle reordering of additional questions
              const newAdditionalQuestions = [...additionalQuestions]; // Use local state
              const [draggedItem] = newAdditionalQuestions.splice(sourceIndex, 1);
              newAdditionalQuestions.splice(destinationIndex, 0, draggedItem);
              console.log("New Order (Additional Questions):", newAdditionalQuestions.map((item) => item.id));
              setAdditionalQuestions(newAdditionalQuestions); // Update local state
            }

          }}
        >
          <Table className={classes.table} aria-label="simple table">
            <TableHead
             onClick={()=>{ settoggleVariableBtn(false) }}
            >
              <TableRow>
                <TableCell>Order</TableCell>
                <TableCell>Questions</TableCell>
                <TableCell>Category</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Variable</TableCell>
                <TableCell align="right" style={{ minWidth: "60px" }}>
                  &nbsp;
                </TableCell>
              </TableRow>
            </TableHead>
            <Droppable droppableId="tasks">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {questions?.map((data, index) => (
                    data.pageId === props.pageId ? <>
                      <Draggable
                        key={data.id.toString()}
                        draggableId={data.id.toString()} // Use a unique identifier as the draggableId
                        index={index}
                      >
                        {(provided) => (
                          <TableRow
                            key={data.id}
                            {...provided.draggableProps}
                            ref={provided.innerRef}
                            onMouseEnter={()=>{
                              setisMandatoryVisible(true)
                              setMandatoryIndex(index)
                            }}
                            onMouseLeave={()=>{
                              setisMandatoryVisible(false)
                              setMandatoryIndex(null)
                            }}
                          >
                            <TableCell  onClick={()=>{ settoggleVariableBtn(false)}}>
                              <div className="order-and-drag-container" {...provided.dragHandleProps}>
                              <img src={DragIcon}/>
                              {data?.orderNo}
                              </div>
                            </TableCell>
                            <TableCell className="question-text"  onClick={()=>{ settoggleVariableBtn(false)}}>
                              <div className="question-text-icon-container">
                                {data?.mandatory == 'yes' ? 
                                <div className="mandatory-question-indicator">
                                  <img src={data?.mandatory == 'yes' ? redStar : GrayStar} alt="" onClick={()=>{mandatoryToggle(data,index)}} />
                                </div> : 
                                  <>
                                    {
                                      isMandatoryVisible && mandatoryIndex==index ? <div className="mandatory-question-indicator">
                                        <img src={data?.mandatory == 'yes' ? redStar : GrayStar} alt="" onClick={()=>{mandatoryToggle(data,index)}} />
                                      </div> : <div className="mandatory-question-indicator"></div>
                                    }
                                  </>
                                }
                                <div className="question-text-container">
                                  {data.question}
                                  <br />
                                  { data.answers?.length > 0 && data.type !== "textarea" ? <span>Answer Options: </span> : <></> }
                                  {data.type !== "textarea" &&
                                    data.answers?.map((answer, index) => {
                                      if (answer?.desktopText?.includes("<br/>")) {
                                        answer.desktopText = answer.desktopText
                                          .split("<br/>")
                                          .join(" ")
                                          .trim();
                                      }
                                      if (data?.answers?.length - 1 === index) {
                                        return (
                                          <span key={answer.id}>
                                            {answer?.desktopText}
                                          </span>
                                        );
                                      } else
                                        return (
                                          <span key={answer.id}>
                                            {answer?.desktopText + " . "}
                                          </span>
                                        );
                                    })}
                                </div>
                              </div>
                            </TableCell>
                            <TableCell  onClick={()=>{ 
                              settoggleVariableBtn(false) 
                              console.log('data',data)
                            }}>
                              <div className="category-txt">
                                {sections?.length != 0 ? (
                                  sections.map((section) =>
                                    section?.id === data?.sectionId ? (
                                      <span key={section.id} dangerouslySetInnerHTML={{ __html: section?.section }} />
                                    ) : null
                                  )
                                ) : (
                                  <span>None</span>
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="center"  onClick={()=>{ settoggleVariableBtn(false)}}>
                              <div className="type-txt">
                                {showTypeIcons(data?.type)}
                                <p>{data?.type}</p>
                              </div>
                            </TableCell>
                            <TableCell align="center"  onClick={()=>{ }}>
                              <div className="variables-container">
                              <Snackbar open={ShowDuplicateVariableSnackbar} autoHideDuration={2000} onClose={handleClose}>
                                <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                                    variable already exists
                                  </Alert>
                              </Snackbar>
                                {toggleVariableBtn && toggleVariableBtnID == data?.id ? (
                                  <>
                                    <div className="ip-and-edit-cta">
                                      <input type="text" name="" id="" value={variableValue} onChange={(e)=>{
                                        let payload = questions[index];
                                        console.log('payload',payload)
                                        setvariableValue(e.target.value)
                                      }} />
                                      {
                                        <button
                                        style={{cursor:"pointer"}}
                                        onClick={() => {
                                            variableUpdate(data,index)
                                          }}
                                        >
                                          {isLoading ? <CircularProgress color="white" style={{height:"10px",width:"10px"}}/> : 'Apply' }
                                        </button>
                                      }
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <div className="text-icon-container">
                                      <p className="text">{data?.variable}</p>
                                      <img src={redEditIcon}
                                        onClick={() => {
                                          editVariable(data?.id,data);
                                        }}
                                      />
                                    </div>
                                  </>
                                )}
                              </div>
                            </TableCell>
                            <TableCell align="right"  onClick={()=>{ settoggleVariableBtn(false)}}>
                              <div className="edit-and-trash-icon">
                                <Tooltip title="Edit Question">
                                  <img src={EditIcon} alt="Edit Icon" className="table-tool"
                                    onClick={() => drawerHandler({
                                      questionDetails: data, // clientId: id,
                                      clientId: sections[0].clientId, sections: sections, additional: false,
                                    })}
                                  />
                                </Tooltip>
                                <Tooltip title="Delete Question">
                                  <img src={deleteIcon} alt="Delete Icon" className="table-tool"
                                    onClick={() =>
                                      toggleModalHandler("question-delete", {
                                        questionDetails: data, 
                                        clientId: builderCtx.assessmentId, isAdditional:props.isAdditional
                                      })
                                    }
                                  />
                                </Tooltip>
                              </div>
                            </TableCell>
                          </TableRow>
                        )}
                      </Draggable>
                    </> : <></>
                    // <Draggable
                    //   key={data.id.toString()}
                    //   draggableId={data.id.toString()} // Use a unique identifier as the draggableId
                    //   index={index}
                    // >
                    //   {(provided) => (
                    //     <TableRow
                    //       key={data.id}
                    //       {...provided.draggableProps}
                    //       ref={provided.innerRef}
                    //       onMouseEnter={()=>{
                    //         setisMandatoryVisible(true)
                    //         setMandatoryIndex(index)
                    //       }}
                    //       onMouseLeave={()=>{
                    //         setisMandatoryVisible(false)
                    //         setMandatoryIndex(null)
                    //       }}
                    //     >
                    //       <TableCell  onClick={()=>{ settoggleVariableBtn(false)}}>
                    //         <div className="order-and-drag-container" {...provided.dragHandleProps}>
                    //         <img src={DragIcon}/>
                    //         {index+1}
                    //         </div>
                    //       </TableCell>
                    //       <TableCell className="question-text"  onClick={()=>{ settoggleVariableBtn(false)}}>
                    //         <div className="question-text-icon-container">
                    //           {data?.mandatory == 'yes' ? 
                    //           <div className="mandatory-question-indicator">
                    //             <img src={data?.mandatory == 'yes' ? redStar : GrayStar} alt="" onClick={()=>{mandatoryToggle(data,index)}} />
                    //           </div> : 
                    //             <>
                    //               {
                    //                 isMandatoryVisible && mandatoryIndex==index ? <div className="mandatory-question-indicator">
                    //                   <img src={data?.mandatory == 'yes' ? redStar : GrayStar} alt="" onClick={()=>{mandatoryToggle(data,index)}} />
                    //                 </div> : <div className="mandatory-question-indicator"></div>
                    //               }
                    //             </>
                    //           }
                    //           <div className="question-text-container">
                    //             {data.question}
                    //             <br />
                    //             { data.answers?.length > 0 && data.type !== "textarea" ? <span>Answer Options: </span> : <></> }
                    //             {data.type !== "textarea" &&
                    //               data.answers?.map((answer, index) => {
                    //                 if (answer?.desktopText?.includes("<br/>")) {
                    //                   answer.desktopText = answer.desktopText
                    //                     .split("<br/>")
                    //                     .join(" ")
                    //                     .trim();
                    //                 }
                    //                 if (data?.answers?.length - 1 === index) {
                    //                   return (
                    //                     <span key={answer.id}>
                    //                       {answer?.desktopText}
                    //                     </span>
                    //                   );
                    //                 } else
                    //                   return (
                    //                     <span key={answer.id}>
                    //                       {answer?.desktopText + " . "}
                    //                     </span>
                    //                   );
                    //               })}
                    //           </div>
                    //         </div>
                    //       </TableCell>
                    //       <TableCell  onClick={()=>{ 
                    //         settoggleVariableBtn(false) 
                    //         console.log('data',data)
                    //       }}>
                    //         <div className="category-txt">
                    //           {sections?.length != 0 ? (
                    //             sections.map((section) =>
                    //               section?.id === data?.sectionId ? (
                    //                 <span key={section.id} dangerouslySetInnerHTML={{ __html: section?.section }} />
                    //               ) : null
                    //             )
                    //           ) : (
                    //             <span>None</span>
                    //           )}
                    //         </div>
                    //       </TableCell>
                    //       <TableCell align="center"  onClick={()=>{ settoggleVariableBtn(false)}}>
                    //         <div className="type-txt">
                    //           {showTypeIcons(data?.type)}
                    //           <p>{data?.type}</p>
                    //         </div>
                    //       </TableCell>
                    //       <TableCell align="center"  onClick={()=>{ }}>
                    //         <div className="variables-container">
                    //         <Snackbar open={ShowDuplicateVariableSnackbar} autoHideDuration={2000} onClose={handleClose}>
                    //           <Alert onClose={handleClose} severity="error" sx={{ width: '100%' }}>
                    //               variable already exists
                    //             </Alert>
                    //         </Snackbar>
                    //           {toggleVariableBtn && toggleVariableBtnID == data?.id ? (
                    //             <>
                    //               <div className="ip-and-edit-cta">
                    //                 <input type="text" name="" id="" value={variableValue} onChange={(e)=>{
                    //                   let payload = questions[index];
                    //                   console.log('payload',payload)
                    //                   setvariableValue(e.target.value)
                    //                 }} />
                    //                 {
                    //                   <button
                    //                   style={{cursor:"pointer"}}
                    //                   onClick={() => {
                    //                       variableUpdate(data,index)
                    //                     }}
                    //                   >
                    //                     {isLoading ? <CircularProgress color="white" style={{height:"10px",width:"10px"}}/> : 'Apply' }
                    //                   </button>
                    //                 }
                    //               </div>
                    //             </>
                    //           ) : (
                    //             <>
                    //               <div className="text-icon-container">
                    //                 <p className="text">{data?.variable}</p>
                    //                 <img src={redEditIcon}
                    //                   onClick={() => {
                    //                     editVariable(data?.id,data);
                    //                   }}
                    //                 />
                    //               </div>
                    //             </>
                    //           )}
                    //         </div>
                    //       </TableCell>
                    //       <TableCell align="right"  onClick={()=>{ settoggleVariableBtn(false)}}>
                    //         <div className="edit-and-trash-icon">
                    //           <Tooltip title="Edit Question">
                    //             <img src={EditIcon}
                    //               className="table-tool"
                    //               onClick={() =>
                    //                 drawerHandler({
                    //                   questionDetails: data,
                    //                   // clientId: id,
                    //                   clientId: sections[0].clientId,
                    //                   sections: sections,
                    //                   additional: false,
                    //                 })
                    //               }
                    //             />
                    //           </Tooltip>
                    //           <Tooltip title="Delete Question">
                    //             <img src={deleteIcon}
                    //               className="table-tool"
                    //               onClick={() =>
                    //                 toggleModalHandler("question-delete", {
                    //                   questionDetails: data,
                    //                   clientId: builderCtx.assessmentId,
                    //                   isAdditional:props.isAdditional
                    //                 })
                    //               }
                    //             />
                    //           </Tooltip>
                    //         </div>
                    //       </TableCell>
                    //     </TableRow>
                    //   )}
                    // </Draggable>
                  ))}
                </TableBody>
              )}
            </Droppable>
          </Table>
        </DragDropContext>
        <DragDropContext
          onDragEnd={(result) => {
            if (!result.destination) {
              // If the item was dropped outside of a valid drop target, do nothing
              return;
            }

            const sourceIndex = result.source.index;
            const destinationIndex = result.destination.index;

            if (result.source.droppableId === "tasks") {
              // Handle reordering of main questions
              const newQuestions = [...additionalQuestions]; // Use local state
              const [draggedItem] = newQuestions.splice(sourceIndex, 1);
              newQuestions.splice(destinationIndex, 0, draggedItem);
              console.log("New Order (Additional Questions):", newQuestions.map((item) => item.id));
              setAdditionalQuestions(newQuestions); // Update local state
            }
          }}
        >
        </DragDropContext>
      </TableContainer>
    </>
  );
}

export default AssessmentAdditionalQuestionTable;

