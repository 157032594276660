import { useState, useContext } from "react";
import BuilderContext from "../../../store/BuilderContext";
import BackgroundColor from "./StylingComponents/BackgroundColor";
import Color from "./StylingComponents/Color";
import Font from "./StylingComponents/Font";
import Padding from "./StylingComponents/Padding";
import Border from "./StylingComponents/Border";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const PrevBtnWidget = (props) => {

  console.log("PrevBtnWidget props", props);

  // const blockId = props?.blockId;
  // console.log("SectionWidget blockId", blockId);

  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  console.log("currentContentBlock", currentContentBlock);

  const currentBlock = contentBlocks?.find(block => block.contentType === "PrevButton");
  const blockId = currentBlock?.id;
  const currentBlockStyles = currentBlock?.styling;
  console.log("currentBlock", currentBlock);

  return (
    <div>
      <p>Prev Button Widget</p>
      {/* <BackgroundColor blockId={blockId} name="Button" />
      <Color blockId={blockId} name="Button Text" />
      <Font blockId={blockId} />
      <Padding blockId={blockId} />
      <Border blockId={blockId} /> */}
      <DynamicStylingTool/>
    </div>
  );
}

export default PrevBtnWidget;
