import { Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core'
import { Block, ChevronRight, Close } from '@material-ui/icons'
import React, { useContext, useEffect, useState } from 'react'
import { TreeItem, TreeView } from '@material-ui/lab';
import { GridExpandMoreIcon } from '@mui/x-data-grid';
import NavigatorList from './NavigatorList';
import ModalContext from '../../../store/ModalContext';
import BuilderContext from '../../../store/BuilderContext';

export default function NavigatorModal() {

  const modalctx   = useContext(ModalContext);
  const builderCtx = useContext(BuilderContext);

  const [currentContentBlock, setcurrentContentBlock]           = useState(builderCtx?.currentContentBlock)
  const [contentBlocks, setcontentBlocks]                       = useState(builderCtx?.contentBlocks)
  const [contentBlocksByParentContainer, setContentBlocksByParentContainer]   = useState(()=>{
    const blocks = contentBlocks.filter( block => block.parentContainer === currentContentBlock.id );
    return blocks;
  })
  const [breadCrumb, setBreadCrumb]                             = useState(()=>{
    const block = contentBlocks.find( block => block.id === currentContentBlock.parentContainer );
    return block;
  })

  // useEffect(()=>{
  //   console.log('navigatormodal contentBlocksByParentContainer',contentBlocksByParentContainer)
  //   console.log('navigatormodal contentBlocks',contentBlocks)
  //   console.log('navigatormodal currentContentBlock',currentContentBlock)
  //   console.log('navigatormodal breadCrumb',breadCrumb)
  // },[contentBlocksByParentContainer])

  const handleCurrentBlockClick = (selectedblock) => {
    // console.log('selected block',selectedblock)

    setcurrentContentBlock(selectedblock)
    builderCtx.updateCurrentBlock(selectedblock)
  }

  useEffect(()=>{
    setBreadCrumb(()=>{
      const block = contentBlocks.find( block => block.id === currentContentBlock.parentContainer );
      return block;
    })
    setContentBlocksByParentContainer(()=>{
      const blocks = contentBlocks.filter( block => block.parentContainer === currentContentBlock.id );
      return blocks;
    })
  },[currentContentBlock])

  return (
    <div className='navigator-modal-parent'>
      <div className="heading-close-btn-container">
        <div className="heading">
          <h1>Navigator</h1>
        </div>
        <div className="close-btn">
          <Button onClick={()=>{modalctx.closeModal()}}>
            <Close/>
          </Button>
        </div>
      </div>
      <div className="navigator-list">
        { <NavigatorList contentBlocksByParentContainer={contentBlocksByParentContainer} />}
      </div>
    </div>
  )
}
