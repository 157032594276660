import { useContext, useEffect, useState } from "react";
import { MenuItem, TextField } from '@mui/material';
import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const Display = (props) => {
  const { blockId } = props;
  const builderCtx = useContext(BuilderContext);
  const authCtx = useContext(AuthContext);
  const { contentBlocks, newUpdateCurrentBlock, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentDisplay = currentBlockStyles?.display;

  const [display, setDisplay] = useState(props?.screenType === 'desktop' ? currentContentBlock?.styling?.display : currentContentBlock?.mobileStyling?.display );

  const handleDisplayChange = (e) => {
    const value = e.target.value;
    setDisplay(value);

    const payload = {
      id: blockId,
      value: value,
      screenType: props?.screenType
    };
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/display', {
      method: 'PUT',
      mode: 'cors',
      headers: { 
        'Content-Type': 'application/json', 
        'Authorization': 'Bearer ' + authCtx.token 
      },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
        newUpdateCurrentBlock('display',value,props?.screenType)
        console.log("Updating display payload", payload);
      }
    })
    .catch((err) => {
      console.error("Failed to update display...", err);
    });
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      if (display !== currentDisplay) {
        updateContentBlocks(contentBlocks?.map(block => {
          if (block.id === blockId) {
            if ( props?.screenType === 'desktop' ) {
              return { ...block, styling: { ...block.styling, display: display } };
            }
            else { 
              return { ...block, mobileStyling: { ...block.mobileStyling, display: display } };
            }
          } else {
            return block;
          }
        }));
      }
    }, 500);
    
    return () => clearTimeout(timer);
  }, [blockId, display]);

  return (
    <div className="display tool">
      {props.deleteModal}
      <TextField
        id="select-display"
        className="display-select styling-select"
        select
        variant="standard"
        InputProps={{ disableUnderline: true }}
        value={display}
        onChange={handleDisplayChange}
      >
        <MenuItem value="block">Block</MenuItem>
        <MenuItem value="inline">Inline</MenuItem>
        <MenuItem value="inline-block">Inline Block</MenuItem>
        <MenuItem value="flex">Flex</MenuItem>
        <MenuItem value="grid">Grid</MenuItem>
        <MenuItem value="none">None</MenuItem>
      </TextField>
    </div>
  );
};

export default Display;