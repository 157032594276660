import { useState, useContext, useEffect } from 'react';

import ModalContext from '../../store/ModalContext.js';
import { TextField } from '@material-ui/core';
const EditInputFieldDetails = (props) => {

  const modalCtx      = useContext(ModalContext);

  const {details} = props;

  const [label, setLabel] = useState(details?.label);
  const [placeholder, setPlaceholder] = useState(details?.placeholder);

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const setFieldDetails = () => {
    let field = details;
    field.label = label;
    field.placeholder = placeholder;
    modalCtx.setDetails("edit-input-field-details", field);
    modalCtx.closeModal();
  }


  return (
    <div className="modal">
        <h2>{details.value} field details : </h2>
        <div className='modal-input-field'>
            <TextField
            fullWidth
            label="Label"
            placeholder="Enter Label"
            value={label}
            onChange={(e) => setLabel(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            required
            />
            <TextField
            fullWidth
            label="Placeholder"
            placeholder="Enter placeholder"
            value={placeholder}
            onChange={(e) => setPlaceholder(e.target.value)}
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            required
            />
            <button onClick={() => setFieldDetails()}>Edit</button>
        </div>
    </div>
  )
}

export default EditInputFieldDetails;
