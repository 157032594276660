import { useState, useEffect, useContext, Fragment } from "react";

import ContentEditable from "react-contenteditable";

import { Add, ChevronLeft, ChevronRight, LinearScale, RadioButtonChecked } from "@material-ui/icons";
import { Article } from "@mui/icons-material";
import { Button, LinearProgress, Link } from "@mui/material";

import BuilderContext from "../../store/BuilderContext";
import { useParams } from "react-router-dom";
import DrawerContext from "../../store/DrawerContext";
import Question from "./Questions";

const QuestionsProgress = (props) => {

  console.log("QuestionsProgress Props", props);

  const builderCtx = useContext(BuilderContext);
  
  const { updateContentText, updateContentBlocks, contentBlocks, changeCurrentPage, accessToolsById, setStyle } = builderCtx;
  
  const setActiveSection = () => updateContentBlocks(contentBlocks?.map(block => {
    console.log("activeSection", block.properties.activeSection);
    if(block.id == props?.id) {
      block.properties.activeSection = props?.idx;
      return block;
    } else return block;
  }))

  const updateSections = (e, id) => updateContentBlocks(contentBlocks?.map(block => {
    console.log("sectionsArray", block.properties.sectionsArray);
    if(block.id == props?.id) {
      block.properties.sectionsArray[props?.idx] = e.target.value;
      return block;
    } else return block;
  }))

  // const sectionProgressBar = contentBlocks?.find(block => block.contentType === "SectionsBar");
  const sectionProgressBar = contentBlocks?.find(block => block.contentType === "SectionProgressBar");
  console.log("sectionProgressBar", sectionProgressBar);

  // onClick={(e) => accessToolsById(e, data.id)} style={setStyle(data.id)}
  let { borderWidth, borderStyle, borderColor } = sectionProgressBar?.styling;
  // console.log(borderWidth, borderStyle, borderColor);
  
  return (
  <div className="progress" style={{ width: "100%", margin: "0 10px" }}>
    <LinearProgress className="progress-bar questions" 
    // color="primary" 
    // backgroundColor="secondary"
    variant="determinate" 
    // value={props?.progress} 
    value={50} 
    sx={{ 
      // backgroundColor: '#aaaaaa77', 
      backgroundColor: sectionProgressBar?.styling?.backgroundColor, 
      // border: "none", 
      border: "none !important", 
      borderWidth: borderWidth + " !important", borderStyle: borderStyle + " !important", borderColor: borderColor + " !important",
      borderRadius: "15px", height: "6px", 
      // "& .MuiLinearProgress-bar": { backgroundColor: '#4e8dff !important' }
      "& .MuiLinearProgress-bar": { backgroundColor: `${sectionProgressBar?.styling?.color} !important` }
    }} 
    onClick={(e) => accessToolsById(e, sectionProgressBar?.id)} style={setStyle(sectionProgressBar?.id)}
    />
    {/* <ContentEditable className="editable-text section-names" html={props?.data?.section || "No Category"} onClick={() => setActiveSection()} onChange={(e) => updateSections(e, props?.id)} tagName="h3" style={{ margin: "0 auto", textAlign: "center" }} /> */}
    <div className="section-names" onClick={() => setActiveSection()} onChange={(e) => updateSections(e, props?.id)} style={{ margin: "0 auto", textAlign: "center" }} >
      <p>{props?.data?.section || "No Category"}</p>
    </div>
  </div>
  )
}

const Questions = (props) => {

  console.log("Questions Props", props);

  const builderCtx = useContext(BuilderContext);
  // const { id } = useParams();

  // console.log("id", id);
  
  const { updateContentBlocks, contentBlocks } = builderCtx;
  // const [questions, setQuestions] = useState();
  // const [sections, setSections] = useState();
  
  // useEffect(() => {
    
  //   fetch(process.env.REACT_APP_API_URI + "/v1/questions/" + id, {
  //     method: "GET",
  //     headers: { "Content-Type": "application/json" },
  //   })
  //   .then((response) => response.json())
  //   .then((data) => {
  //     console.log(data);
  //     setQuestions(data.questions);
  //     setSections(data.sections);
  //   }).catch((err) => console.error(err));

  // }, [id]);

  // useEffect(() => {
  //   updateContentBlocks(contentBlocks?.map(block => {
  //     console.log("questionsArray", block.properties.questionsArray);
  //     if(block.id == props?.id) {
  //       block.properties.questionsArray = questions;
  //     }
  //     if (block.contentType === "SectionsBar") {
  //       block.properties.sectionsArray = sections;
  //     } 
  //     return block;
  //   }));


  // }, [props?.id, questions, sections]);

  const updateQuestions = (e, id) => updateContentBlocks(contentBlocks?.map(block => {
    console.log("questionsArray", block.properties.questionsArray);
    if(block.id == props?.id) {
      block.properties.questionsArray[props?.idx].question  = e.target.value;
      block.properties.questionsArray[props?.idx].section   = props?.currentSection;
      block.properties.questionsArray[props?.idx].active    = true;
      block.properties.questionsArray.forEach((quest, index) => {
        if(index !== props?.idx) quest.active = false;
      })
      return block;
    } else return block;
  }))

  const setCurrQuestionIndex = () => updateContentBlocks(contentBlocks?.map(block => {
    console.log("currentQuestionIndex", block.properties.currentQuestionIndex);
    if(block.id == props?.id) {
      block.properties.currentQuestionIndex = props?.idx;
      block.properties.questionsArray[props?.idx].active = true;
      block.properties.questionsArray.forEach((quest, index) => {
        if(index !== props?.idx) quest.active = false;
      })
      return block;
    } else return block;
  }))


  return (
    <div className="question" style={{ margin: "0 15px 30px 15px", width: "100%", justifyContent: "center" }}>
      {/* <ContentEditable className="editable-text section-names" html={props?.data?.question || "No questions added!"} onClick={() => setCurrQuestionIndex()} onChange={(e) => updateQuestions(e, props?.id)} tagName="p" /> */}
      <p className="section-names" onClick={() => setCurrQuestionIndex()}>
        {props?.data?.question || "No questions added!"}
      </p>
    </div>
  )
}

const ProgressIndicator = (props) => {
  
  // either show numbers or category name depending on user's choice 
  console.log(props);

  const builderCtx        = useContext(BuilderContext);
  const { contentBlocks, accessToolsById } = builderCtx;



  let progIndicatorBlock = contentBlocks?.find(block => (block?.contentType === "ProgressIndicator"));
  // let totalQ = sectionsBlock?.properties?.sectionsArray?.length;
  let currentQuestion = parseInt(props?.currentQ + 1) || 0;
  let totalQuestions = props?.totalQ || 0;
  
  const minTwoDigits = (n) => n < 10 ? '0' + n : n;

  return props?.showMultiProgBar ? <p 
    // onClick={(e) => accessToolsById(e, progIndicatorBlock?.id)} style={progIndicatorBlock?.styling} 
    className="progress-text">{minTwoDigits(currentQuestion)}/{minTwoDigits(totalQuestions)}</p> : <p 
    // onClick={(e) => accessToolsById(e, progIndicatorBlock?.id)} style={progIndicatorBlock?.styling}
    className="current-section">{props?.currentSection}</p>

}

const AnswerOptions = (props) => {

  console.log("AnswerOptions props", props);

  const builderCtx        = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks } = builderCtx;
  const currQIndex = props?.currentQuestionProps.currentQuestionIndex;
  let buttonsBlock = contentBlocks?.filter(block => (block.contentType === "Buttons"))?.[0];
  const paragraphStyles = { margin: "4px auto" };
  const selectedStyles = { ...buttonsBlock?.styling, border: "2px solid #55555577", boxShadow: "0 0 10px 1px #77777755" }
  let unSelectedStyles = buttonsBlock?.styling;
  const selection = props?.currentQuestion?.type;
  
  const updateOptionType = (type) => updateContentBlocks(contentBlocks?.map(block => {
    if(block.id === props?.questionsId) {
      block.properties.questionsArray[currQIndex].type = type;
      return block;
    } else return block;
  }))

  const applyStyles = (type) => selection === type ? selectedStyles : unSelectedStyles;

  return (<>
    <p style={{ textAlign: "center" }}>Select your question type</p>
    <div className="answer-options" style={{ margin: "0 auto" }}>
      <div className="slider" style={applyStyles("slider")} onClick={() => updateOptionType("slider")}>
        <LinearScale style={{ fontSize: "48px", color: "#777777aa",  }} />
        <p style={paragraphStyles}>Slider</p>
        <p style={paragraphStyles}>Multiple Choice</p>
      </div>
      <div className="radio" style={applyStyles("radio")} onClick={() => updateOptionType("radio")}>
        <RadioButtonChecked style={{ fontSize: "48px", color: "#777777aa",  }} />
        <p style={paragraphStyles}>Radio buttons</p>
        <p style={paragraphStyles}>Multiple Choice</p>
      </div>
      <div className="input-text" style={applyStyles("text")} onClick={() => updateOptionType("text")}>
        <Article style={{ fontSize: "48px", color: "#777777aa",  }} />
        <p style={paragraphStyles}>Paragraph</p>
        <p style={paragraphStyles}>Text Input</p>
      </div>
      {/* <div className="add" style={{ ...optionStyles, width: "5ch", height: "150px", display: "flex", alignItems: "center" }}>
        <Add style={{ fontSize: "48px", color: "#777777aa",  }} />
      </div> */}
    </div>
  </>)
}

const Assessment = (props) => {

  console.log("Assessment props", props);
  const builderCtx = useContext(BuilderContext);
  const { id }    = useParams();
  const drawerCtx = useContext(DrawerContext);
  const { contentBlocks, updateContentBlocks, accessToolsByPageArea, accessToolsById, updateContentText, setSectionStyle, setStyle, changeCurrentPage } = builderCtx;

  // const [selectedContentBlockId, setSelectedContentBlockId] = useState(0);
  
  const [showMultiProgBar, setShowMultiProgBar]   = useState(true);
  const [showProgSection, setShowProgSection]     = useState(false);

  const [questions, setQuestions]         = useState();
  const [questionCount, setQuestionCount] = useState();
  const [currentStep, setCurrentStep]     = useState(0);
  const [sections, setSections]           = useState();

  const currentQuestionProps = contentBlocks?.find(block => block?.contentType === "Question")?.properties;
  // const currentQuestion      = currentQuestionProps?.questionsArray?.[currentQuestionProps?.currentQuestionIndex];
  const currentQuestion      = questions?.[currentStep];
  const currentSectionProps  = contentBlocks?.find(block => block?.contentType === "SectionsBar")?.properties;
  // const currentSection       = currentSectionProps?.sectionsArray?.[currentSectionProps?.activeSection];
  
  const [currentSection, setCurrentSection] = useState([]);

  function toggleDrawerHandler(drawer, user) {
    drawerCtx.openDrawer();
    drawerCtx.setDetails(drawer, user);
  }

  useEffect(() => {
    // console.log(currentSectionProps?.sectionsArray); console.log(currentQuestion);
    // const currSection = currentSectionProps?.sectionsArray ? currentSectionProps?.sectionsArray?.find(sec => sec.id === currentQuestion?.sectionId) : null;
    // setCurrentSection(currSection);

    console.log(currentQuestion);
    
    const currSection = sections?.find(sec => sec.id === currentQuestion?.sectionId);
    // setCurrentSection(sections?.[0]);
    setCurrentSection(currSection);
  }, [currentQuestion, currentSectionProps?.sectionsArray, currentStep, sections]);

  console.log("currentSectionProps", currentSectionProps);
  console.log("currentSection", currentSection);
  console.log("currentQuestion", currentQuestion);
  console.log("sections", sections);

  console.log("contentBlocks", builderCtx?.contentBlocks);
  // console.log("currentSectionProps", currentSectionProps);
  // console.log("currentSection", currentSection);
  // console.log("currentQuestionProps", currentQuestionProps);
  // console.log("currentQuestion", currentQuestion);

  useEffect(() => {
    changeCurrentPage("assessment");
  }, []);

  useEffect(() => {
    if(showMultiProgBar) setShowProgSection(false);
    else if(showProgSection) setShowMultiProgBar(false);
  }, [showMultiProgBar, showProgSection]);
  
  const fetchQuestions = () => {
    fetch(process.env.REACT_APP_API_URI + "/v1/questions/" + id, {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
      setQuestions(data.questions);
      setQuestionCount(data.questions?.length);
      setSections(data.sections);
    }).catch((err) => console.error(err));
  }

  useEffect(() => {
    fetchQuestions();
  }, [id]);
  

  useEffect(() => {
    console.log(drawerCtx.open);
    console.log(drawerCtx.drawer);
    if(drawerCtx.drawer === "open-questions-builder" && drawerCtx.open === false) {
      fetchQuestions();
    }
  }, [drawerCtx]);


  useEffect(() => {
    updateContentBlocks(contentBlocks?.map(block => {
      console.log("questionsArray", block.properties.questionsArray);
      // console.log("block.id & props.id", block.id, props?.id);
      // if(block.id == props?.id) {
      if(block.contentType === "Question") {
        block.properties.questionsArray = questions;
      }
      if (block.contentType === "SectionsBar") {
        block.properties.sectionsArray = sections;
      } 
      return block;
    }));


  }, [props?.id, questions, sections]);

  const updateQuestions = (e, id) => updateContentBlocks(contentBlocks?.map(block => {
    console.log("questionsArray", block.properties.questionsArray);
    if(block.id == props?.id) {
      block.properties.questionsArray[props?.idx].question  = e.target.value;
      block.properties.questionsArray[props?.idx].section   = props?.currentSection;
      block.properties.questionsArray[props?.idx].active    = true;
      block.properties.questionsArray.forEach((quest, index) => {
        if(index !== props?.idx) quest.active = false;
      })
      return block;
    } else return block;
  }))

  const setCurrQuestionIndex = () => updateContentBlocks(contentBlocks?.map(block => {
    console.log("currentQuestionIndex", block.properties.currentQuestionIndex);
    if(block.id == props?.id) {
      block.properties.currentQuestionIndex = props?.idx;
      block.properties.questionsArray[props?.idx].active = true;
      block.properties.questionsArray.forEach((quest, index) => {
        if(index !== props?.idx) quest.active = false;
      })
      return block;
    } else return block;
  }))

  const parentId = contentBlocks?.find(block => block?.contentType === "Container")?.id;
  const sectionsBarId = contentBlocks?.find(block => block?.contentType === "SectionsBar")?.id;
  const questionsId = contentBlocks?.find(block => block?.contentType === "Question")?.id;
  const prevBtn = contentBlocks?.find(block => block?.contentType === "PrevButton");
  const nextBtn = contentBlocks?.find(block => block?.contentType === "NextButton");
  // const totalQuestionsLen = contentBlocks?.find(block => block?.contentType === "Question")?.properties?.questionsArray?.length;
  const totalQuestionsLen = questions?.length;

  // console.log("totalQuestionsLen", totalQuestionsLen);

  console.log("AssessmentContainer ParentID:", parentId);
  console.log("questionsId:", questionsId);  

  const nextQuestion = () => updateContentBlocks(contentBlocks?.map(block => {
    
    let newCurrentQIndex;
    let currentQIndex = currentQuestionProps?.currentQuestionIndex;
    let qArr = block.properties?.questionsArray;
    
    if(block.id === questionsId) {
      // index is positive & less than array length 
      if((currentQIndex >= 0) && ((parseInt(currentQIndex) + 1) < qArr?.length)) {
        newCurrentQIndex = parseInt(currentQIndex) + 1;
        block.properties.currentQuestionIndex = newCurrentQIndex;
        setCurrentStep(newCurrentQIndex);
        block.properties.questionsArray.forEach((quest, index) => {
          if(index !== newCurrentQIndex) quest.active = false;
          else if (index === newCurrentQIndex) quest.active = true;
        })
        return block;
      } else {
        // reset currentQuestionIndex to last element if index >= array length 
        newCurrentQIndex = qArr?.length - 1;
        block.properties.currentQuestionIndex = newCurrentQIndex;
        setCurrentStep(newCurrentQIndex);
        block.properties.questionsArray.forEach((quest, index) => {
          if(index === qArr?.length - 1) quest.active = true;
          else quest.active = false;
        })
        return block;
      }
    }
    else return block;
  }))
  
  const prevQuestion = () => {
    if(currentStep > 0) {
      updateContentBlocks(contentBlocks?.map(block => {
        let newCurrIndex = currentQuestionProps?.currentQuestionIndex ? currentQuestionProps?.currentQuestionIndex - 1 : 0;
        if(block.id === questionsId) {
          block.properties.currentQuestionIndex = newCurrIndex;
          setCurrentStep(newCurrIndex);
          block.properties.questionsArray.forEach((quest, index) => {
            if(index !== newCurrIndex) quest.active = false;
            else if (index === newCurrIndex) quest.active = true;
          })
          return block;
        } else return block;
      }))
    }
  }

  const getAnswerType = (question) => {
    switch(question?.type) {
      case "slider" : return "AnswerSlider"
    }
  }

  const getStepContent = (step,dataId) => {
    if(questions?.length) {
      const answerType = getAnswerType(questions[step])
      
      const selectedContentBlock = contentBlocks.find(contentBlock => contentBlock.contentType === answerType);

      const selectedHtml = selectedContentBlock?.properties?.html === "" ? `<p>${questions[step]?.question}</p>` : selectedContentBlock?.properties?.html;

      return <Question question={questions[step]} questionCount={questionCount} questionHtml={selectedHtml} answerType={answerType} blockId={selectedContentBlock?.id} dataId={dataId} />
    }
    // return ();
  };

  const prevButton = () => {
    if (currentStep <= 0) {
      return <Button style={{ backgroundColor: "#007C89", color: "#FFFFFF", fontWeight: 700, padding: "5px 10px" }} onClick={(e) => { 
        accessToolsByPageArea("assessment", e);
        toggleDrawerHandler("open-questions-builder", { id })
      }}><Add /></Button>
    } else {
      return (
        <Button className="left-add-btn add-button" onClick={(e) => {prevQuestion();}}><ChevronLeft /></Button>
      );
    }
  }
  

  const nextButton = () => {
    if((currentStep + 1) >= questions?.length) {
      return <Button style={{ backgroundColor: "#007C89", color: "#FFFFFF", fontWeight: 700, padding: "5px 10px" }} onClick={(e) => { 
        accessToolsByPageArea("assessment", e);
        toggleDrawerHandler("open-questions-builder", { id })
      }}><Add /></Button>
    } else {
      return <Button className="right-add-btn add-button" onClick={(e) => {nextQuestion();}}><ChevronRight /></Button>
    }
  }

  // contentBlocks?.forEach(c => console.log(c?.pageArea));

  console.log("builderCtx Assessment Page:", builderCtx);
  
  return (
    <>
      <div className="assessment-container">
        <div onClick={(e) => accessToolsByPageArea("assessment", e)} style={setSectionStyle("assessment")}>
          {
            builderCtx.contentBlocks?.map((data, i) => {
              // console.log(data); console.log(parentId);
              if(data?.parentContainer === parentId && data?.pageArea === "assessment") {
                if(data?.contentType === "SectionsBar") {
                  // UNDID THIS
                  // let sectionsArray = data?.properties?.sectionsArray;
                  let sectionsArray = typeof data?.properties?.sectionsArray === "string" ? JSON.parse(data?.properties?.sectionsArray) : data?.properties?.sectionsArray;
                  // let sectionsArray = JSON.parse(data?.properties.sectionsArray);
                  console.log(sectionsArray);
                  return <div key={i} onClick={(e) => accessToolsById(e, data.id)} style={setStyle(data.id)}>
                    {sectionsArray?.length > 0 && sectionsArray?.map((section, index) => {
                      return <QuestionsProgress key={index} data={section} idx={index} id={data.id} progress="25" />
                    })}
                  </div>
                } else if(data.contentType == "ProgressIndicator") {
                  return <div key={i} onClick={(e) => accessToolsById(e, data.id)} style={setStyle(data.id)}>
                    {/* Please create a component here which actually will be the current question / the total amount of questions */}
                    <ProgressIndicator currentQ={currentQuestionProps?.currentQuestionIndex} currentSection={currentSection} showMultiProgBar={showMultiProgBar} totalQ={totalQuestionsLen} />
                  </div>
                } else if(data.contentType == "Question") {
                  // return <div key={i} onClick={(e) => accessToolsById(e, data.id)} style={setStyle(data.id)} className="question-container">
                  //   { getStepContent(currentStep) }
                  // </div>
                } else if(data.contentType == "AnswerSlider") {
                  return <Fragment key={i}>
                    <div key={i} className="question-container">
                    {/* Please create a component here to create a question */}

                      {/* <Button style={{ border: "2px dashed #77777777" }} onClick={() => {addNewQuestionBefore()}}><Add style={{ fontSize: "32px", color: "#777777aa" }} /></Button>
                      <Questions onClick={(e) => accessToolsById(e, data.id)} data={currentQuestion} idx={currentQuestionProps?.currentQuestionIndex} id={data.id} currentSection={currentSection} />
                      <Button style={{ border: "2px dashed #77777777" }} onClick={() => {addNewQuestionAfter()}}><Add style={{ fontSize: "32px", color: "#777777aa" }} /></Button> */}
                      {getStepContent(currentStep,questionsId)}

                  </div>
                    
                    {/* <AnswerOptions currentQuestionProps={currentQuestionProps} currentQuestion={currentQuestion} questionsId={questionsId} /> */}
                    
                    <div className="add-question-btns" style={{ display: "flex", justifyContent: "space-between" }} 
                      // onClick={(e) => accessToolsByPageArea("assessment", e)}
                    >
                      <Button onClick={(e) => { 
                        // accessToolsByPageArea("assessment", e);
                        accessToolsById(e, prevBtn?.id);
                      }} style={setStyle(prevBtn?.id)}><ChevronLeft /> Prev</Button>

                      <Button onClick={(e) => { 
                        // accessToolsByPageArea("assessment", e);
                        accessToolsById(e, nextBtn?.id);
                      }} style={setStyle(nextBtn?.id)}>Next <ChevronRight /></Button>
                    </div>
                  </Fragment>
                }
              }
            })
          }
        </div>
        <div className="add-questions-btn-container-parent">
          <div className="add-questions-buttons-container">
            {prevButton()}
            {nextButton()}
          </div>
        </div>
      </div>
    </>
  )

}



export default Assessment;

