import React, { useState, useContext, useEffect } from "react";
import PropTypes from "prop-types";

import { TextField, Select, MenuItem, FormControl, Switch, InputLabel, Button, Checkbox, FormGroup } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import DrawerContext from "../../store/DrawerContext";
import CircularProgress from '@material-ui/core/CircularProgress';
import { isDOMComponent } from "react-dom/test-utils";

function EditAssessmentDrawer(props) {
  const [title, setTitle] = useState(props.details.title);
  const [clients, setClients] = useState([]);
  const [clientID, setClientID] = useState(props.details.clientID);
  const [URL, setURL] = useState(props.details.url);
  const [nameType, setNameType] = useState(props.details.nameType);
  const [automation, setAutomation] = useState(props.details.hasAutomation);
  const [additional, setAdditional] = useState(props.details.hasAdditional);
  const [totalScore, setTotalScore] = useState(props.details.answersTotal);
  const [isLoading, setIsLoading] = useState(false);
  
  const [assessment, setAssessment] = useState({
    id: props.details.id,
    title: title,
    clientID: clientID,
    URL: URL,
    nameType: nameType,
    automation: automation,
    additional: additional,
    totalScore: totalScore,
  });

  const drawerCtx = useContext(DrawerContext);

  const switchAutomation = {
    inputProps: { "aria-label": "Has automation" },
  };
  const switchAdditional = {
    inputProps: { "aria-label": "Has additional assessment" },
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + "/v3/accounts", {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => response.json())
    .then((data) => {
      setClients([...data.data]);
      setIsLoading(false);
    });
  }, [drawerCtx.open]);
  
  useEffect(() => {
    setAssessment((prev) => {
      return {
        ...prev,
        title: title,
        clientID: clientID,
        URL: URL,
        nameType: nameType,
        automation: automation,
        additional: additional,
        totalScore: totalScore,
      };
    });
  }, [title, clientID, URL, nameType, automation, additional, totalScore]);


  const handleSubmit = (e) => {
    e.preventDefault();
    
    setIsLoading(true);
    fetch(process.env.REACT_APP_API_URI + "/v1/admin/assessment", {
      method: "PUT",
      body: JSON.stringify(assessment),
      headers: { "Content-Type": "application/json" },
    })
    .then((response) => response.json())
    .then((data) => console.log(data))
    .then(setIsLoading(false))
    .catch(err => {
      if(err) {
        console.log(err);
        setIsLoading(false);
      }
    });
  };

  return (
    <div className="container">
      <div className="drawer">
        <header>
          <h1 data-aos="fade-right" data-aos-delay="500">
            Edit Assessment
          </h1>
        </header>

        <section className="add-question-drawer drawer">
          <form onSubmit={(e) => handleSubmit(e)}>
            <TextField
              fullWidth
              className="form-control-add-question"
              label="Title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Title"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              required
            />
            <FormControl fullWidth className="form-control-add-question">
              <TextField
                value={clientID}
                onChange={(e) => setClientID(e.target.value)}
                select
                label="Client"
                required
                variant="outlined"
              >
                {clients?.map((client) => {
                  return (
                    <MenuItem className="section-name" value={client.id}>
                      {client.title}
                    </MenuItem>
                  );
                })}
              </TextField>
            </FormControl>

            <TextField
              fullWidth
              className="form-control-add-question"
              label="URL"
              value={URL}
              onChange={(e) => {
                setURL(e.target.value);
              }}
              placeholder="URL"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              required
            />

            <FormControl fullWidth className="form-control-add-question">
              <TextField
                value={nameType}
                onChange={(e) => setNameType(e.target.value)}
                select
                label="Name Type"
                variant="outlined"
                required
              >
                <MenuItem className="question-type" value="both">
                  Both
                </MenuItem>
                <MenuItem className="question-type" value="full">
                  Full
                </MenuItem>
              </TextField>
            </FormControl>

            <FormControl
              fullWidth
              className="form-control-add-question question-mandatory-toggle"
            >
              Has Automation: &nbsp;No&nbsp;
              <Switch checked={automation === "yes" ? true : false}
                {...switchAutomation}
                onChange={(e) => {
                  setAutomation(e.target.checked ? "yes" : "no");
                }}
              />
              &nbsp; Yes
            </FormControl>

            <FormControl
              fullWidth
              className="form-control-add-question question-mandatory-toggle"
            >
              Has Additional Assessment: &nbsp;No&nbsp;
              <Switch checked={additional === "yes" ? true : false}
                {...switchAdditional}
                onChange={(e) => setAdditional(e.target.checked ? "yes" : "no")}
              />
              &nbsp; Yes
            </FormControl>

            <TextField
              fullWidth
              className="form-control-add-question"
              label="Total Score"
              value={totalScore}
              onChange={(e) => {
                setTotalScore(e.target.value);
              }}
              placeholder="Total Score"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              required
            />

            <div className="double-column-form aligned-right margin-top">
              {isLoading ? <CircularProgress /> :
                <Button
                type="submit"
                variant="contained"
                disabled={
                  assessment.URL === "" ||
                  assessment.title === "" ||
                  assessment.clientID === 0 ||
                  assessment.nameType === "" ||
                  assessment.totalScore === null ||
                  assessment.totalScore === ""
                }
                >
                  Save Changes
                </Button>
              }
            </div>
          </form>
        </section>
      </div>
    </div>
  );
}
export default EditAssessmentDrawer;
