import { useState, useEffect } from 'react';
import { Button } from '@mui/material';

const CopyLinkBtn = ({ btnText, link, clsName }) => {

  const [copied, setCopied] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (copied) setCopied(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [copied]);

  async function copyTextToClipboard(text) {
    try {
      if ('clipboard' in navigator) {
        console.log("copied text to clipboard:\n", text);
        setCopied(true);
        return await navigator.clipboard.writeText(text);
      } else {
        console.log("copied text to clipboard:\n", text);
        setCopied(true);
        return document.execCommand('copy', true, text);
      }
    } catch (err) {
      console.error("Error copying text to clipboard...");
      console.error(err);
    }

  }

  let copiedClass = copied ? "copied" : "";
  let btnClasses = clsName + " " + copiedClass;

  return (
    <Button title={link} className={btnClasses} onClick={(e) => copyTextToClipboard(link)}>{copied ? "Copied!" : btnText}</Button>
  )
}

export default CopyLinkBtn;
