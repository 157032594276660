import React, { useContext } from 'react';

import ClientsTable from "../Components/Tables/ClientsTable";

import DrawerContext from '../store/DrawerContext.js';

// import '../App.scss';

const Clients = () => {

	const drawerCtx = useContext(DrawerContext);

	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	return (
		<div className="container clients">
			<header>
				<div data-aos="fade-right">
					<h1>Clients</h1>
				</div>
				<div data-aos="fade-down" data-aos-delay="400">
					<a className="header-cta" onClick={() => {toggleDrawerHandler('client-create', null)}}>Create</a>
				</div>
			</header>
			<ClientsTable />
		</div>
	);

}
export default Clients;