import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import ClientsAdminTable from "../Components/Tables/ClientsAdminTable";

import DrawerContext from '../store/DrawerContext.js';

// import '../App.scss';

const ClientsAdmin = () => {
  const { id } = useParams();
	const drawerCtx = useContext(DrawerContext);

	function toggleDrawerHandler(drawer, user) {
		drawerCtx.openDrawer();
		drawerCtx.setDetails(drawer, user);
	};

	return (
		<div className="container clients">
			<header>
				<div data-aos="fade-right">
					<h1>Clients</h1>
				</div>
				<div data-aos="fade-down" data-aos-delay="400">
					<a className="header-cta" onClick={() => {toggleDrawerHandler('create-client-administrator', id)}}>Create</a>
				</div>
			</header>
			<ClientsAdminTable />
		</div>
	);

}
export default ClientsAdmin;