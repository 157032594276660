import { useState, useEffect, useContext } from "react";
import BuilderContext from '../../../store/BuilderContext';
import Font from "./StylingComponents/Font";
import Margin from "./StylingComponents/Margin";
import Padding from "./StylingComponents/Padding";
import TextAlign from "./StylingComponents/TextAlign";
import LineHeight from "./StylingComponents/LineHeight";
import DynamicStylingTool from "./DynamicStylingTool/DynamicStylingTool";

const TextEditWidget = (props) => {
  console.log("\n\n\nPROPS", props);
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  console.log("\n\n\ncurrentContentBlock", currentContentBlock);



  return (
    <div>
      {/* <Font blockId={currentContentBlock?.id} />
      <LineHeight blockId={currentContentBlock?.id} />
      <TextAlign blockId={currentContentBlock?.id} />
      <Margin blockId={currentContentBlock?.id} />
      <Padding blockId={currentContentBlock?.id} /> */}

      <DynamicStylingTool/>
    </div>
  )
}


export default TextEditWidget;
