import React, { useContext, useState, useEffect } from 'react'
import { Button } from '@material-ui/core';
import ModalContext from '../../store/ModalContext.js';
import { CircularProgress, FormControl, FormGroup, TextField } from "@mui/material";


const EditWebsiteDetails = (props) => {
  // const { details } = props;

  console.log("EditWebsiteDetails props:", props);

  const modalCtx = useContext(ModalContext);
  
  const websiteDetails = props?.details.websiteDetails;
  const toggleRefresh = props?.details.toggleRefresh;
  const [websiteTitle, setWebsiteTitle] = useState(websiteDetails?.title || "");
  const [isLoading, setIsLoading]             = useState("");


  // console.log("modalCtx.details", modalCtx.details);
  // useEffect(() => {
  //   console.log("website Title: ", websiteTitle);
  // }, [websiteTitle])

  const handleCloseModal = () => {
    modalCtx.setDetails(null);
    modalCtx.closeModal();
  }

  const handleEditWebsite = (e) => {
    e.preventDefault();
    // modalCtx.setDetails({websiteTitle});
    setIsLoading(true);
    const jsonBody = { title: websiteTitle };

    fetch(process.env.REACT_APP_API_URI + '/v2/datamining/website/' + websiteDetails?.id, {
      method: 'PUT',
      mode: 'cors',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(jsonBody),
    }).then(response => {
      return response.json();
    }).then(data => {
      console.log(data);
      if (data.status === "success") {
        console.log("Successfully edited website title...");
        setIsLoading(false);
        props?.details.setToggleRefresh(!toggleRefresh);
        handleCloseModal();
      }
    }).catch((err) => console.error("Failed to edit website title...", err));

  }

  return (
    <div className='modal'>
      {isLoading ? 
      <section style={{ minHeight: "250px", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></section> 
      :
      <>
      <div>Edit Website Title Here</div>

      <form>
        <FormGroup>
          <div className="double-column-form">
            <FormControl fullWidth margin="dense" className="form-control">
              <TextField required value={websiteTitle} onChange={(e) => setWebsiteTitle(e.target.value)} label="Website Title" variant="outlined" defaultValue={websiteTitle}>
              </TextField>
            </FormControl>
            {/* <FormControl fullWidth margin="dense" className="form-control">
                <TextField required value={websiteURL} onChange={(e) => setWebsiteURL(e.target.value)} label="Website URL" variant="outlined">
                  </TextField>
              </FormControl> */}
          </div>
        </FormGroup>
        <div className="footer" style={{ margin: "15px auto 0" }}>
          <Button className="cancel-btn" onClick={(e) => { handleCloseModal() }}>Cancel</Button>
          <Button type="submit" className="confirm-btn" onClick={(e) => { handleEditWebsite(e) }}>Edit</Button>
        </div>
      </form>
     </>
    }
    </div>
  )
}

export default EditWebsiteDetails;
