import { useContext, useEffect, useState } from "react";

import { MenuItem, TextField } from '@mui/material';

import BuilderContext from '../../../../store/BuilderContext';
import { ChromePicker } from "react-color";

const Border = (props) => {

  const { blockId, propertyName, name = "" } = props;
  const builderCtx = useContext(BuilderContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlockStyles = contentBlocks?.find(block => block.id === blockId)?.styling;
  const currentBorderWidth = currentBlockStyles?.borderWidth || "0px";
  const currentBorderStyle = currentBlockStyles?.borderStyle || "solid";
  const currentBorderColor = currentBlockStyles?.borderColor || "#000000";

  const [borderWidth, setBorderWidth] = useState(currentBorderWidth);
  const [borderStyle, setBorderStyle] = useState(currentBorderStyle);
  const [borderColor, setBorderColor] = useState(currentBorderColor);
  const [colorHex, setColorHex]       = useState(currentBorderColor);

  const [pickerOpen, setPickerOpen] = useState(false);

  const handleWidthChange = (e) => setBorderWidth(e.target.value + "px");
  const handleStyleChange = (e) => setBorderStyle(e.target.value);
  const handleColorChange = (e) => setColorHex(e.target.value);

  const handleChangeForColorPicker = (color) => {
    // console.log("color", color);
    setColorHex(color.hex);
    // setBorderColor(color.hex);
  }

  useEffect(() => {

    updateContentBlocks(contentBlocks?.map(block => {
      if (block.id === blockId) {
        let newStyles = { ...block.styling };
        block.styling = { ...newStyles, borderWidth, borderStyle, borderColor };
        return block;
      } else return block;
    }))

  }, [blockId, borderWidth, borderStyle, borderColor]);

  useEffect(() => {

    const timer = setTimeout(() => {
      if(colorHex !== borderColor) {
        setBorderColor(colorHex);
        // setPickerOpen(false);
      }
    }, 2000);
    return () => clearTimeout(timer);

  }, [colorHex, borderColor]);

  useEffect(() => {
    let lastFinalVal = "";
    if(!currentBorderColor) lastFinalVal = "#000000";
    else lastFinalVal = currentBorderColor;
    // console.log("lastFinalVal", lastFinalVal);
    if (borderColor && borderColor !== lastFinalVal) {
      updateContentBlocks(contentBlocks?.map(block => {
        if (block.id === blockId) {
          let newStyles = { ...block.styling };
          newStyles.borderColor = borderColor;
          block.styling = { ...newStyles };
          return block;
        } else return block;
      }))
    }
  }, [blockId, borderColor, currentBorderColor]);

  const borderStyles = [
    { value: 'solid', label: 'solid' },
    { value: 'dotted', label: 'dotted' },
    { value: 'dashed', label: 'dashed' },
    { value: 'double', label: 'double' },
    { value: 'groove', label: 'groove' },
    { value: 'ridge', label: 'ridge' },
    { value: 'inset', label: 'inset' },
    { value: 'outset', label: 'outset' },
    { value: 'none', label: 'none' },
  ];

  return (
    <div className="border-styles tool">
      {/* <p className="styling-text">Border Styles:</p> */}
      <div style={{ display: "flex", justifyContent: "flex-start" }}>
        <div style={{ margin: "0 15px 0 0" }}>
          {props.deleteModal}
          <TextField style={{ width: "100px" }} id="select-border-width" className="border-unit styling-select" 
          // label="Width" 
          type="number" value={parseInt(borderWidth?.replace("px", ""))} onChange={(e) => { handleWidthChange(e); }} InputProps={{ inputProps: { min: 0 } }} />
        </div>
        <div>
          <p className="styling-text">Border Style:</p>
          <TextField style={{ width: "100px" }} id="select-border-style" className="border-unit styling-select" select 
          // label="Style" 
          InputProps={{ disableUnderline: true }} placeholder={currentBlockStyles?.border?.style + ""} value={borderStyle} onChange={(e) => { handleStyleChange(e); }} >
            {borderStyles?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <p className="styling-text">{name ? (name + " ") : ""}Border Color:</p>
      
      <TextField className="bgcolor-input styling-input" type="text" variant="filled"
        InputLabelProps={{ shrink: true, }} placeholder="Type in hex value or select color" value={colorHex}
        onChange={(e) => handleColorChange(e)} />

      <input type="color" className="color-box" value={colorHex} onClick={(e) => {
        e.preventDefault();
        setPickerOpen(!pickerOpen);
      }} onChange={(e) => console.log("onchange", e.target.value)} />
      {
        pickerOpen && <ChromePicker color={colorHex} 
        onChange={handleChangeForColorPicker} 
        // onChangeComplete={handleChangeForColorPicker} 
        />
      }


    </div>
  )
}

export default Border;
