import { useContext, useEffect, useState } from "react";
import { TextField } from '@mui/material';

import { ChromePicker } from 'react-color';
import { colord, toHex, toRgb } from 'colord';

import BuilderContext from '../../../../store/BuilderContext';
import AuthContext from "../../../../store/AuthContext";

const Color = (props) => {

  const { blockId, propertyName, name = "" } = props;
  console.log("propertyName", propertyName);
  const builderCtx = useContext(BuilderContext);
  const authCtx    = useContext(AuthContext);
  const { contentBlocks, updateContentBlocks, currentContentBlock } = builderCtx;

  const currentBlock = contentBlocks?.find(block => block.id === blockId);
  const currentBlockStyles = currentBlock?.styling;
  const currentColor = currentBlockStyles?.color;
  const currentPropertyColor = currentBlock?.properties?.[propertyName];
  console.log("currentPropertyColor", currentPropertyColor);
  
  let currPropertyColorHex = "";
  let currPropertyColorRgb = "";
  if(propertyName && currentPropertyColor?.startsWith('#')) {
    currPropertyColorHex = currentPropertyColor;
    currPropertyColorRgb = colord(currentPropertyColor).toRgb();
  } else if (propertyName && currentPropertyColor?.startsWith('rgb')) {
    const hexColor = colord(currentPropertyColor).toHex();
    currPropertyColorHex = hexColor;
    currPropertyColorRgb = colord(hexColor).toRgb();
  }

  // console.log("currentColor", currentColor);
  // console.log("currPropertyColorHex", currPropertyColorHex);
  // console.log("currPropertyColorRgb", currPropertyColorRgb);
  
  const [colorHex, setColorHex] = useState(currPropertyColorHex || colord(currentColor).toHex() || "#212121");
  const [colorRgb, setColorRgb] = useState(currPropertyColorRgb || currentColor || "#212121");
  const [rgbaString, setRgbaString] = useState(currentColor);
  const [pickerOpen, setPickerOpen] = useState(false);
  const [finalValue, setFinalValue] = useState("");
  // console.log("colorHex", colorHex);
  // console.log("colorRgb", colorRgb);
  
  
  const handleChange = (color) => {

    // console.log("color", color);
    // console.log("\n\n", color.source, "\n\n");
    
    setColorHex(color.hex);

    setColorRgb(color.rgb);

    let { r, g, b, a } = color?.rgb;
    setRgbaString(`rgba(${r}, ${g}, ${b}, ${a})`);

  }

  useEffect(() => {

    const timer = setTimeout(() => {

      console.log("colorHex", colorHex); console.log("rgbaString", rgbaString);
      console.log("finalValue", finalValue);
    
      if (colorHex !== finalValue) {
        setFinalValue(rgbaString);
        // setPickerOpen(false);
      }

    }, 2000);
    return () => clearTimeout(timer);


  }, [colorHex, rgbaString, finalValue]);

  // console.log("pickerOpen", pickerOpen);

  const updateColorInDB = (colorVal) => {

    let payload = {
      id: blockId,
      value: colorVal,
      screenType: props?.screenType
    }
    
    fetch(process.env.REACT_APP_API_URI + '/v3/admin/assessment/styling/color', {
      method: 'PUT', mode: 'cors',
      headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
      body: JSON.stringify(payload),
    })
    .then(response => response.json())
    .then(data => {
      if(data.status === "success") {
      console.log("updating color payload", payload);
      }
    }).catch((err) => {
      console.error("Failed to update color...", err);
    });
  }

  useEffect(() => {
    let lastFinalVal = "";
    if (!currentColor) lastFinalVal = "#ffffff";
    else lastFinalVal = currentColor;
    console.log("finalValue", finalValue); console.log("lastFinalVal", lastFinalVal);
    updateColorInDB(lastFinalVal)
    if (finalValue && finalValue !== lastFinalVal) {
      updateContentBlocks(contentBlocks?.map(block => {
        if (block.id === blockId) {
          if(propertyName) {
            let newProperties = { ...block.properties }
            newProperties[propertyName] = finalValue;
            block.properties = { ...newProperties }
          } else {
            let newStyles = { ...block.styling };
            newStyles.color = finalValue;
            block.styling = { ...newStyles };
          } return block;
        } else return block;
      }))
    }
  }, [blockId, finalValue, propertyName, currentColor]);

  // console.log("blockId", blockId);
  // console.log("colorHex", colorHex);
  // console.log("colorRgb", colorRgb);
  // console.log("finalValue", finalValue);

  return (
    <div className="bgcolor tool">
      {/* <p className="styling-text">{(name + " ") || ""}Color:</p> */}
      {props.deleteModal}
      <TextField className="bgcolor-input styling-input" type="text" variant="filled"
        InputLabelProps={{ shrink: true, }} placeholder="Type in hex value or select color" value={currentPropertyColor || colorHex}
        onChange={(e) => {
          setColorHex(e.target.value);
          setColorRgb(e.target.value);
          setRgbaString(e.target.value);
        }} />

      {/* <br /> */}
      <input type="color" className="color-box" value={colorHex} onClick={(e) => {
        e.preventDefault();
        setPickerOpen(!pickerOpen);
      }} onChange={(e) => console.log("onchange", e.target.value)} />
      {
        pickerOpen && <ChromePicker color={colord(colorRgb).toRgb()}
          onChange={handleChange}
        // onChangeComplete={handleChange} 
        />
      }


    </div>
  )
}


export default Color;

