import React, { useContext, useState } from 'react';
import ModalContext from '../../store/ModalContext.js';
import AuthContext from '../../store/AuthContext.js';
import { CircularProgress } from "@mui/material";


import cross from '../../../src/assets/img/cross.png';


const DeleteLogic = (props) => {

    const logicId = props?.details?.id
    const isUpdatedLogic =props?.details?.isUpdatedLogic

    console.log("logic props", props);

    const modalCtx = useContext(ModalContext);
    const authCtx = useContext(AuthContext);


    const [isLoading, setIsLoading] = useState("");
    const [error, setError] = useState(false);


    const handleCloseModal = () => {
        modalCtx.setDetails(null);
        modalCtx.closeModal();
    }


    const handleDeleteLogic = (e) => {
        e.preventDefault();
        setIsLoading(true);


        if (props?.details?.logicType == "jump") {
            props?.details?.deleteJumpLogic();
        } else {
            props.details?.deleteDisplayLogic();
        }
        const apiURL = process.env.REACT_APP_API_URI + "/v3/admin/questions/logic/" + logicId;

        fetch(apiURL, {
            method: 'DELETE',
            headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + authCtx.token },
        })
            .then((response) => response.json())
            .then((data) => {
                console.log(data);
                if (data.status === "success") {
                    console.log("Successfully deleted logic...");
                    setIsLoading(false);
                    setError(false);
                    handleCloseModal();
                    // props.details.setIsUpdatedLogic(!isUpdatedLogic);

                } else {
                    console.log("Failed to delete logics...");
                }
            })
            .catch(err => {
                setIsLoading(false);
                setError(true);
                // props.details.setIsUpdatedLogic(!isUpdatedLogic);
                console.error("Failed to delete logic...", err)


            });

        
    }

    return (
        <div className='modal'>
            <div className="delete-popup-content">
                <div className="main-content">
                    <div className="heading-container">
                        <div className="heading">
                            Are you sure you want to delete this {props?.details?.logicType} Logic ?
                        </div>
                        <img src={cross} onClick={() => { handleCloseModal() }} alt="cross" />
                    </div>

                    {isLoading ?
                        <button className="delete-btn" disabled> <CircularProgress /></button> :
                        <button className="delete-btn" onClick={(e) => {handleDeleteLogic(e)}} >Delete</button>
                    }

                    <button className="cancel-btn" onClick={() => { handleCloseModal() }}>Cancel</button>

                </div>
            </div>


        </div>
    )
}

export default DeleteLogic;
